import {
  Box,
  useColorModeValue,
  Drawer,
  DrawerContent,
  useDisclosure,
  Stack,
  Container,
} from "@chakra-ui/react";

//Core file
import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";

//Service file
import * as getIntroductionData from "../../services/Introduction";
import * as getLandingMasterlistData from "../../services/SectionService";

//Component file
import CommonLeftSidebar from "../../FunctionalComponents/LeftSidebar/CommonLeftSidebar";
import { BottomMainMenu } from "../../CommonComponents/BottomMainMenu";
import Booklets from "./BodyOfWork/Booklets/Booklets";
import { IntegratedTimelineBody } from "./BodyOfWork/IntegratedTimeline";
import { WebpageComingSoon } from "../../CommonComponents/WebpageComingSoon";

//Language file
import { explore } from "../../constants/constants";
import TabMain from "../../FunctionalComponents/TabComponents/TabMain";


export default function BodyOFWork({ children }) {
  const { isOpen, onClose } = useDisclosure();

  const [introData, setIntroData] = useState([]);
  const [horislinkData, setHorislinkData] = useState([]);
  const [journalDetails, setJournalDetails] = useState({});
  const {alias} = useParams();
  const aliasAPI = alias;
  const currentEndpoint = window.location.pathname;
  const parts = currentEndpoint.split("/");
  const masterlistType = parts[1];

  React.useEffect(() => {
    const getIntroData = async () => {
      const result = await getIntroductionData.introductionByAlias(
        masterlistType,
        aliasAPI
      );
      if (result) {
        setHorislinkData([
          {
            link: `/research-categories/${
              result[0]?.find((item) => item?.key === "researchCategories")
                .value[0].link
            }`,
            title: result[0]?.find((item) => item?.key === "researchCategories")
              .value[0].value,
          },
          {
            link: `/categories/indian-cinema`,
            title: result[0]?.find((item) => item?.key === "subject")?.value,
          },
        ]);

        setJournalDetails(
          result?.reduce((acc, it) => {
            acc.title = it?.find((item) => item?.key === "name")?.value;
            acc.date_Range = it?.find(
              (item) => item?.key === "song_from"
            )?.value+' - '+ it?.find(
              (item) => item?.key === "song_till"
            )?.value;
            return acc;
          }, {})
        );
      }
    };
    const getLandingData = async () => {
      const result = await getLandingMasterlistData.getLandingPageInfoByAlias(
        aliasAPI
      );
      if (result) {
        setIntroData(
          result?.map((it) => ({
            text: it.find((item) => item?.key === "landing_page_title").value,
            to: `/${masterlistType}/${it.find((item) => item?.key === "masterlist").value}/${
              it.find((item) => item?.key === "landing_page_abbreviation").value
            }`,
            title: it.find((item) => item?.key === "landing_page_abbreviation")
              .value,
          }))
        );
      }
    };
    getIntroData();
    getLandingData();
  }, [masterlistType, aliasAPI]);

  const [readmoreNavigation, setReadmoreNavigation] = useState({});
  return (
    <Box minH="100%" bg={useColorModeValue("white", "gray.900")}>
      <SidebarContent
        onClose={() => onClose}
        display={{ base: "none", md: "block" }}
        height="calc(100vh - -64px)"
        readmoreNavigation={readmoreNavigation}
        setReadmoreNavigation={setReadmoreNavigation}
        introData={introData}
        aliasAPI={aliasAPI}
        horislinkData={horislinkData}
        journalDetails={journalDetails}
        masterlistType={masterlistType}
      />
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
      >
        <DrawerContent>
          <SidebarContent
            onClose={onClose}
            readmoreNavigation={readmoreNavigation}
            setReadmoreNavigation={setReadmoreNavigation}
            aliasAPI={aliasAPI}
            horislinkData={horislinkData}
            journalDetails={journalDetails}
            introData={introData}
            masterlistType={masterlistType}
          />
        </DrawerContent>
      </Drawer>

      <Box ml={{ base: 0, md: 52 }} bg="white">
        {children}
       {introData? <BodyOfWorkBody
          winnerName={aliasAPI}
          setReadmoreNavigation={setReadmoreNavigation}
          readmoreNavigation={readmoreNavigation}
        />
      :
      (
       <WebpageComingSoon />
      )
      }
      </Box>
    </Box>
  );
}

const SidebarContent = ({
  winnerName,
  onClose,
  aliasAPI,
  horislinkData,
  journalDetails,
  introData,
  masterlistType,
  ...rest
}) => {
  const navigate = useNavigate();
  const handleNavigation = () => {
      navigate(-1);
    }
    const oSections = [
      {
        to: `/${masterlistType}/${aliasAPI}/1`,
        title: "",
        text: explore.POSTS,
      },
      {
        to: `/${masterlistType}/${aliasAPI}/2`,
        title: "",
        text: explore.INSIGHTS,
      },
    ];
  return (
    <>
      <BottomMainMenu />
      <CommonLeftSidebar
        sections={introData}
        oSections={oSections}
        onClose={onClose}
        hMenu={horislinkData}
        journalDetails={journalDetails}
        leftFlag={true}
        handleNavigation={handleNavigation}
      />
    </>
  );
};

const BodyOfWorkBody = ({
  winnerName,
  setReadmoreNavigation,
  readmoreNavigation,
  hidden,
  setHidden,
  tableName,
  name,
}) => {
  const navigate = useNavigate();
  let { tabIndex } = useParams();
  const {alias} = useParams();
  const aliasAPI = alias;
  const masterlistType = "object-book-ssb"
  const [modalcard, setModalcard] = useState(false);

  useEffect(() => {
    if (tabIndex) {
      navigate(`/${masterlistType}/${aliasAPI}/body-of-work`, { replace: true });
    }
  }, [tabIndex, navigate,aliasAPI]);

  const [tabPanelData, setTabPanelData] = useState([]);

  function formatData() {
    return [
      {
        name: `${explore.BOOKLETS}`,
        component: <Booklets winnerName={winnerName} />,
      },
      {
        name: `${explore.INTEGRATED_TIMELINE}`,
        component: <IntegratedTimelineBody
        modalcard={modalcard}
        setModalcard={setModalcard}
        aliasAPI={aliasAPI}
       />,
      },
    ];
  }
  useEffect(() => {
    if (aliasAPI) {
      setTabPanelData(formatData());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [aliasAPI]);
  return (
    <Stack height={"100%"}>
      <Container maxW="auto" px={{ base: "4", md: "0" }} mx={"0"}>
        <Stack px={{ base: "0", md: "8" }}>
              {tabPanelData && tabPanelData.length > 0 && (
            <TabMain data={tabPanelData} />
          )}
          </Stack>
        </Container>
    </Stack>
  );
};
