import { Box, Container, HStack, Image, Stack, Text } from "@chakra-ui/react";

//Core file
import React, { useEffect, useState } from "react";
import { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { Link } from "react-router-dom";

//Service file
import { getMasterListsByResearchCategoryAlias } from "../../../services/ResearchCategoryDetails";
import { DataNotFound } from "../../../CommonComponents/DataNotFound";

function Masterlist({ rcAlias, setMasterlistViewAll }) {
  const [masterlistsData, setMasterlistsData] = useState([]);

  const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;
  const placeholderImg = `${IMAGE_BASE_PATH}/ui/DummyImage/small.png`;

  useEffect(() => {
    const getData = async () => {
      const result = await getMasterListsByResearchCategoryAlias("", rcAlias);

      if (result?.data?.length > 4) {
        setMasterlistViewAll(true);
      }

      if (result) {
        setMasterlistsData(result);
      }
    };
    getData();
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rcAlias]);

  return (
    <Stack>
      <Container px="0" maxW="auto" className="songs">
        <Stack flexDirection={"row"} gap={4} justifyContent="center">
          <HStack
            width={"100%"}
            position={"relative"}
            id="allslider"
            pt={{ base: "0", md: "8" }}
          >
            <Swiper
              slidesPerView={1}
              spaceBetween={10}
              navigation={true}
              breakpoints={{
                640: {
                  slidesPerView: 1,
                  spaceBetween: 20,
                },
                768: {
                  slidesPerView: 2,
                  spaceBetween: 40,
                },
                1024: {
                  slidesPerView: 3,
                  spaceBetween: 20,
                },
                1280: {
                  slidesPerView: 4,
                  spaceBetween: 20,
                },
                1440: {
                  slidesPerView: 4,
                  spaceBetween: 20,
                },
              }}
              modules={[Navigation]}
              className="mySwiper"
              bg="white"
            >
              {masterlistsData?.data?.length > 0 ? (
                masterlistsData?.data.map((it, index) => {
                  return (
                    <SwiperSlide key={index}>
                      <Stack className="content">
                        <Link
                          to={
                            it.link
                              ? it.link
                              : `/${
                                  it?.find(
                                    (nestedItem) =>
                                      nestedItem?.key === "route_type"
                                  ).value !== ""
                                    ? `${
                                        it?.find(
                                          (nestedItem) =>
                                            nestedItem?.key === "route_type"
                                        ).value
                                      }`
                                    : `generic`
                                }/${it?.find(
                                  (nestedItem) =>
                                    nestedItem?.key === "alias"
                                ).value}/`
                          }
                        >
                          <Stack className="content-overlay"></Stack>
                          <Box
                            width={{ base: "100%", md: "100%" }}
                            height={"292px"}
                            display={"flex"}
                            alignItems={"center"}
                          >
                            <Image
                              src={
                                it?.find(
                                  (nestedItem) => nestedItem?.key === "image"
                                ).value
                              }
                              className="content-image"
                              height="auto"
                              width="100%"
                              fallbackSrc={placeholderImg}
                            />
                          </Box>
                          <Text
                             py="1"
                             display={{base:"block",md:"none"}}
                              fontSize={"16px"}
                              fontWeight={"600"}
                              textStyle={"scondary"}
                              color={"color.blue"}
                            >
                              {
                                it?.find(
                                  (nestedItem) => nestedItem?.key === "name"
                                ).value
                              }
                            </Text>
                          <Stack
                            className="content-details fadeIn-bottom"
                            alignItems={"center"}
                          >
                            <Text
                              fontSize={"24px"}
                              fontWeight={"700"}
                              textStyle={"scondary"}
                              color={"white"}
                            >
                              {
                                it?.find(
                                  (nestedItem) => nestedItem?.key === "name"
                                ).value
                              }
                            </Text>
                          </Stack>
                        </Link>
                      </Stack>
                    </SwiperSlide>
                  );
                })
              ) : (
                <Stack alignItems={"center"} height={"100vh"}>
                  <DataNotFound />
                </Stack>
              )}
            </Swiper>
          </HStack>
        </Stack>
      </Container>
    </Stack>
  );
}
export default Masterlist;
