// import AsyncStorage from "@react-native-async-storage/async-storage";

const localStorage=window.localStorage;

const _storeData = async (key, data) => {
  try {
    
    await localStorage.setItem(
      key,
      data,
    );
    //console.log("store done", key, data);
    return key;
  } catch (error) {
    // Error saving data
    console.log("store Data", error)
    return null;
  }
};
const _retrieveData = async (key) => {
  try {
    const value = await localStorage.getItem(key);
    //console.log("retreived in func", value);
    return value;
  } catch (error) {
    // Error retrieving data
    console.log("retrieveData", error)
    return null;
  }
};

class TokenService {
  async getLocalRefreshToken() {
    const obj =await  _retrieveData("user");
    if(!obj) return null;
    const user=JSON.parse(obj);
    return user?.refreshToken;

  }

  async getLocalAccessToken() {

    //const user = JSON.parse(localStorage.getItem('user'));

    const obj =await  _retrieveData("user");
    //console.log("retrieve data", obj);
    if(!obj) return null;
    const user=JSON.parse(obj);

    return user?.accessToken;
  }

  async updateLocalAccessToken(tokenObj) {

    //const user = JSON.parse(localStorage.getItem('user'));

    const obj =await  _storeData("user", JSON.stringify(tokenObj));
    console.log("store data", obj);
    if(!obj) return null;

  }

  /*
  updateLocalAccessToken(token) {
    let user = JSON.parse(localStorage.getItem('user'));
    user.accessToken = token;
    localStorage.setItem('user', JSON.stringify(user));
  }
  */

  async getUser() {
    const obj =await  _retrieveData("user");
    //console.log("retrieve data", obj);
    if(!obj) return null;
    const user=JSON.parse(obj);

    return user;

  }

  setUser(user) {
    console.log(JSON.stringify(user));
    localStorage.setItem('user', JSON.stringify(user));
  }

  removeUser() {
    localStorage.removeItem('user');
  }
}

// Comment
// export default new TokenService();

const tokenService = new TokenService();
export default tokenService;
