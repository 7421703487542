import { Box, Container, Image, Stack, Text } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import React from "react";
import { getProjectsData } from "../services/Projects";
import Layout from "../components/sections/SidebarLayout";
import { WebpageComingSoon } from "../CommonComponents/WebpageComingSoon";
import { getImageURL } from "../util/getImage";
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;
const placeholderImg = `${IMAGE_BASE_PATH}/ui/DummyImage/large.png`;

function ProjectPage(props) {
  const { id } = useParams();
  const [project, setProject] = React.useState({});

  const baseUrl = `${process.env.REACT_APP_GRAPHQL_URL}`;

  React.useEffect(() => {
    const getData = async () => {
      const result = await getProjectsData();
      if (result) {
        const dataProjects = result;
        const dataProject = dataProjects.find((e) => Number(e.id) === Number(id));
        setProject(dataProject);
      }
    };
    getData();
    return () => {};
  }, [id, baseUrl]);
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Layout>
      <Stack py="32px" px="62px" className="mobileviewpadding">
        <Container maxW="auto">
          {project.length === 0 ? (
            <WebpageComingSoon/>
          ) : (
            <Box>
              <Box bg="gray.100" alignItems={"center"}>
                <Image
                  boxSize="auto"
                  w="100%"
                  objectPosition="center"
                  src={getImageURL(`${IMAGE_BASE_PATH}/${project?.image}`, '1188', '736')}
                  fallbackSrc={placeholderImg}
                  alt=""
                />
              </Box>
              <Box w="100%">
                <Text pt="4">{project?.display_date}</Text>
              </Box>
              <Box w="100%">
                <Text fontSize={"2xl"} fontWeight={"700"} pt="4">
                  {project?.name}
                </Text>
              </Box>
              <Box w="100%">
                <Text pt="4">{project?.venue}</Text>
              </Box>
            </Box>
          )}
        </Container>
      </Stack>
    </Layout>
  );
}
export default ProjectPage;
