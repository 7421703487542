import LeftVmenu from "../FunctionalComponents/LeftVmenu";
import { MdHomeFilled, MdMap } from "react-icons/md";
import { RiLayoutGridFill } from "@react-icons/all-files/ri/RiLayoutGridFill";
import { IoMdCalendar } from "react-icons/io";

export const BottomMainMenu = (member, handleNavigation, display, onClose) => {
  const LeftVmenuData = [
    {
      link: "/",
      id: "homeicon",
      icon: MdHomeFilled,
    },
    {
      link: "/explore",
      id: "exploreicon",
      icon: RiLayoutGridFill,
      active: true,
    },
    {
      link: "/calendar",
      id: "calendaricon",
      icon: IoMdCalendar,
    },
    {
      link: "/map",
      id: "mapicon",
      icon: MdMap,
    },
  ];
  return (
    <>
      <LeftVmenu
        handleNavigation={handleNavigation}
        backArrow={true}
        display={display}
        onClose={onClose}
        LeftVmenuData={LeftVmenuData}
      />
    </>
  );
};
