import {
  Box,
  Container,
  HStack,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Stack,
  Text,
} from "@chakra-ui/react";
// Core Files
import React, { useEffect, useRef, useState } from "react";

// Service Files
import { getEditorialData } from "../../../services/Editorial";
import { CopyPasteService } from "../../../services/CopyPaste";
import * as getFilmData from "../../../services/Film";

// Subsection Files
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCreative, EffectFade, Navigation, Pagination } from "swiper";
import { common, filmography } from "../../../constants/constants";
import LargeRegular from "../../../Theme/Typography/LargeRegular";
import { isArray } from "lodash";
import Medium from "../../../Theme/Typography/Medium";

//S3 Bucket osw-dev-images
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;

const closeIcon = `${IMAGE_BASE_PATH}/ui/Chevron/closeicon.svg`

function Editorial({ setReadMoreFlag, readmoreNavigation, masterlist, masterlistType }) {
  const [imgData, setImgData] = useState([]);
  const [desc, setDesc] = useState("");
  const [fullImgData, setFullImgData] = useState([]);
  const [selectedImg, setSelectedImg] = useState({
    image: "",
    desc: "",
    index: 0,
    slideImage: "",
  });
  const [clickedImageIndex, setClickedImageIndex] = useState(null);
  const [posterFullScreenImage, setPosterFullScreenImage] = useState(false);
  const [posterImgData, setposterImgData] = useState(null);

  useEffect(() => {
    const getData = async () => {
      const result = await getEditorialData(masterlistType, masterlist);
      if (result) {
        setFullImgData(result);
      }
    };
    getData();
    // eslint-disable-next-line
  }, []);

  //key value
  const [editorialData, setEditorialData] = useState([]);
  const citationFactor = (data) => {
    return data?.map((item) => {
      const editorial_data=data = item?.find((nestedItem) => nestedItem?.key === "image_alias")?.value[0];
      return {
        desc:`${editorial_data?.bibliographic_caption}`,
        image: editorial_data?.direct_url_of_cover_image,
        full_desc: `${editorial_data?.bibliographic_caption}` ,
        date_of_artwork:editorial_data?.year ,
      };
    });
  };
  useEffect(() => {
    const getData = async () => {
      const result = await getEditorialData(masterlistType, masterlist);
      if (result) {
        setEditorialData(citationFactor(result));
        setImgData(citationFactor(result));
      }
    };
    const getDesc = async () => {
      const result = await getFilmData.filmByAlias(masterlistType, masterlist);
      if (result) {
        setDesc(result?.find((it) => it?.key === "editorial_text")?.value);
      }
    };
    getData();
    getDesc();
  }, [masterlist, masterlistType]);

  useEffect(() => {
    if (editorialData && editorialData.length > 0) {
      setSelectedImg({
        image: editorialData[0].image,
        desc: editorialData[0].desc,
        index: 0,
        slideImage: editorialData[0].image,
      });
    }
  }, [editorialData]);

  useEffect(() => {
    if (!readmoreNavigation?.isReadMore) {
      setClickedImageIndex(readmoreNavigation?.clickedImageIndex);
    }
  }, [readmoreNavigation]);

  const showPosterImages = (image, flag) => {
    if (image) {
      setPosterFullScreenImage(flag);
      setposterImgData(image);
    } else {
      setPosterFullScreenImage(flag);
      setposterImgData(null);
    }
  };

  const handleImageClick = (item, index) => {
    setClickedImageIndex(index);
    setPosterFullScreenImage(true);
  };

  const swiperRef = useRef(null);
  const handleNextButtonClick = () => {
    // const activeIndex = swiperRef.current.swiper.activeIndex;
    const activeIndex = selectedImg.index + 1;
    const nextSlide = imgData[activeIndex];

    if (nextSlide) {
      setSelectedImg({
        image: nextSlide.image,
        desc: nextSlide.desc,
        index: activeIndex,
        slideImage: nextSlide.image,
      });
    }
  };

  const handlePreviousButtonClick = () => {
    // const activeIndex = swiperRef.current.swiper.activeIndex;
    const activeIndex = selectedImg.index - 1;
    const nextSlide = imgData[activeIndex];
    // swiperRef.current.swiper.slideTo(index);

    if (nextSlide) {
      setSelectedImg({
        image: nextSlide.image,
        desc: nextSlide.desc,
        index: activeIndex,
        slideImage: nextSlide.image,
      });
    }
  };

  useEffect(() => {
    if (!readmoreNavigation?.isReadMore) {
      if (readmoreNavigation?.title === "editorial") {
        setClickedImageIndex(readmoreNavigation?.clickedImageIndex);
        setPosterFullScreenImage(true);
      }
    }
  }, [readmoreNavigation]);
  return (
    <>
      <Stack
        width={{ base: "100%", md: "1135px" }}
        height={{lg:"100%", '2xl':"calc(100vh - 220px)"}}
      >
        <Stack  py={"25px"} width={{ base: "100%", md: "60%" }}>
          <LargeRegular onCopy={(e) => CopyPasteService(e, `${window.location.href}`)} textStyle={"primary"} lineHeight={"17.61px"}>{desc}</LargeRegular>
        </Stack>
        <Stack
          direction={"row"}
          borderWidth={"0.4px"}
          borderColor={"#e6e6e6"}
          bg={"white"}
          height={{ base: "100%", md: "478px" }}
          py={4}
        >
         { editorialData && editorialData?.length>0 ?
         <>
         
          {editorialData && editorialData?.length>0 && <Stack py={5} marginLeft={{ base: "10px", md: "25px" }}>
            <Box
              mx={{ base: 0, md: 12 }}
              display={"flex"}
              flexDirection={"column"}
              justifyContent={"center"}
              height={{ base: "auto", md: "419px" }}
              width={{ base: "100%", md: "100%" }}
              alignItems={"flex-start"}
              paddingLeft={"75px"}
            >
              <Image
                cursor={"pointer"}
                width={"auto"}
                height={"inherit"}
                objectFit={"contain"}
                objectPosition={"center"}
                src={selectedImg.image}
                onClick={() =>
                  handleImageClick(selectedImg.image, selectedImg.index)
                }
              />
              <LargeRegular textStyle={"primary"} lineHeight={"17.61px"} textAlign="center" mt={"5px"} color="color.blue"> {selectedImg.desc}</LargeRegular>
            </Box>
          </Stack>}
          {editorialData && editorialData?.length>0 && <Stack
            position={"relative"}
            id="allsliderskkEditorial"
            paddingLeft={{ base: "0px", md: "35px" }}
            py={4}
          >
            <Swiper
              ref={swiperRef}
              navigation={true}
              modules={[Navigation, Pagination]}
              direction="vertical"
              // loop={true}
              slidesPerView={4}
              slidesPerGroup={1}
              onSlideNextTransitionEnd={() => {
                handleNextButtonClick();
              }}
              onSlidePrevTransitionEnd={() => {
                handlePreviousButtonClick();
              }}
            >
              {editorialData &&
                editorialData.map((item, index) => (
                  <SwiperSlide key={index}>
                    <Stack
                      onClick={() => {
                        swiperRef.current.swiper.slideTo(
                          swiperRef.current.swiper.activeIndex
                        );
                        setSelectedImg({
                          image: item.image,
                          desc: item.desc,
                          index: index,
                          slideImage: item.image,
                        });
                      }}
                      style={{
                        border:
                          selectedImg.slideImage === item.image
                            ? "1px solid #A4A4A4"
                            : "none",
                        width: { base: "100px", md: "185px" },
                        height: { base: "auto", md: "98px" },
                      }}
                    >
                      <Box
                        display={"flex"}
                        justifyContent={"center"}
                        width={{ base: "100px", md: "170px" }}
                        mx={"auto"}
                        height={{ base: "100%", md: "95px" }}
                      >
                        <Image
                          width={"auto"}
                          height={"inherit"}
                          objectPosition={"center"}
                          cursor={"pointer"}
                          src={item?.image}
                        />
                      </Box>
                    </Stack>
                  </SwiperSlide>
                ))}
            </Swiper>
          </Stack>}
          </>
          :<>
          <HStack w={"100%"}>
            <Box
                    display={"flex"}
                    alignItems={"center"}
                    w={"inherit"}
                    justifyContent={"center"}
                    gap={4}
            >
              <Text py={"40"} fontSize={"45px"} fontWeight="700" textAlign="center">
                    {common.NO_DATA}
              </Text>
           </Box>
          </HStack>
          </>
          }
        </Stack>
      </Stack>
      <FullScreenImage
        isOpen={posterFullScreenImage}
        onClose={() => showPosterImages(null, false)}
        imageData={posterImgData}
        data={fullImgData}
        clickedImageIndex={clickedImageIndex}
        setClickedImageIndex={setClickedImageIndex}
        setReadMoreFlag={setReadMoreFlag}
      />
    </>
  );
}
export default Editorial;

export const FullScreenImage = ({
  isOpen,
  onClose,
  imageData,
  clickedImageIndex,
  data,
  setClickedImageIndex,
  setReadMoreFlag,
}) => {
  const [expanded, setExpanded] = useState(false);
  const MAX_LENGTH = 600; // Maximum length of description to show when collapsed

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };
  return (
    <Container maxW={"auto"} px="0px">
      <Modal
        size={"full"}
        isCentered
        isOpen={isOpen}
        onClick={onClose}
        Id="modalfullscreen"
      >
        <ModalOverlay />
        <ModalContent alignItems={"center"} zIndex={"999"}>
          <ModalBody margin={{ base: "0", md: "16" }}>
            <Box
              bg={"white"}
              justifyContent={"space-between"}
              position={"relative"}
            >
              <Stack maxW={{base:"100%",md:"1400px"}}>
                <Swiper
                  effect={{ sm: "fade", md: "creative" }}
                  slidesPerView={1}
                  navigation
                  initialSlide={clickedImageIndex}
                  // loop={true}
                  id="allsliderskk1"
                  modules={[EffectCreative, EffectFade, Navigation, Pagination]}
                >
                  {data.map((item, index) => {
                    const imageData=item?.find((nestedItem) => nestedItem?.key === "image_alias").value[0]
                    return (
                      <SwiperSlide key={index + 1}>
                        <Stack
                          maxW={"1180px"}
                          marginTop={{ base: "20px", md: "0" }}
                          flexDirection={{ base: "column", md: "row" }}
                          className="row"
                          alignItems={"flex-start"}
                          gap={"0"}
                        >
                          <HStack
                            className="col-sm-12 col-md-7"
                            justifyContent={"center"}
                            border={"0.01rem solid #d7d7d7"}
                            borderRadius={"5px"}
                          >
                            <Stack m={{base:"8",md:"4"}}>
                              <Box
                                display={"block"}
                                justifyContent={"center"}
                                width={"100%"}
                                height={{ base: "100%", md: "550px" }}
                              >
                                <Image
                                  src={
                                    imageData?.direct_url_of_cover_image
                                  }
                                  width={"100%"}
                                  height={"inherit"}
                                  objectFit={"contain"}
                                  alt=""
                                />
                              </Box>
                            </Stack>
                          </HStack>
                          <HStack
                            alignItems={"flex-start"}
                            className="col-sm-12 col-md-5"
                            flexDirection={"column"}
                          >
                            <Box
                              pb={"4"}
                              width={"100%"}
                            >
                              <Text
                                fontSize={"20px"}
                                fontWeight={"700"}
                                color="#0066B3"
                              >
                                {imageData?.bibliographic_caption}
                              </Text>
                      
                            </Box>
                            <Stack  
                              borderBottom={"1px"}
                              borderColor={"#e8e8e8"} width={"100%"} 
                              pb="4" 
                              marginInlineStart={"0rem!important"}>
                            <Stack
                                marginInlineStart={"0rem!important"}
                                py="1"
                                gap={"2"}
                                className="mt-0 px-0"
                              >
                                <HStack className="mt-0 px-0">
                                  <Text
                                    fontSize={"14px"}
                                    fontWeight={"700"}
                                    color="black"
                                  >
                                    {filmography.DATE_OF_ARTWORK}
                                  </Text>
                                </HStack>
                                <HStack className="mt-0">
                                  <Text
                                    fontSize={"14px"}
                                    fontWeight={"400"}
                                  >
                                    {imageData?.year}
                                  </Text>
                                </HStack>
                              </Stack>
                              <Stack
                                marginInlineStart={"0rem!important"}
                                py="1"
                                gap={"2"}
                                className="mt-0 px-0"
                              >
                                <HStack className="mt-0 px-0">
                                  <Text
                                    fontSize={"14px"}
                                    fontWeight={"700"}
                                    color="black"
                                  >
                                    {filmography.PHOTOGRAPHER}
                                  </Text>
                                </HStack>
                                <HStack className="mt-0">
                                  <Text
                                    fontSize={"14px"}
                                    fontWeight={"400"}
                                    // color="#035DA1"
                                  >
                                    {imageData?.creator_name}
                                  </Text>
                                </HStack>
                              </Stack>
                              <Stack
                                marginInlineStart={"0rem!important"}
                                py="1"
                                gap={"2"}
                                className="mt-0 px-0"
                              >
                                <HStack className="mt-0 px-0">
                                  <Text
                                    fontSize={"14px"}
                                    fontWeight={"700"}
                                    color="black"
                                  >
                                    {filmography.FEATURED_ACTORS}
                                  </Text>
                                </HStack>
                                <HStack className="mt-0">
                                  <Text
                                    fontSize={"14px"}
                                    fontWeight={"400"}
                                    // color="#035DA1"
                                  >
                                    {imageData?.featured_actors}
                                  </Text>
                                </HStack>
                              </Stack>
                            </Stack>
                            {item.imageData && (
                                <Box
                                py="2"
                                className="ml-0"
                                alignItems={"flex-start"}
                                flexDirection={"column"}
                              >
                                {isArray(item.imageData) ? (
                                  item.imageData.map((it) => (
                                    <Stack
                                      marginInlineStart={"0rem!important"}
                                      py="1"
                                      gap={"2"}
                                      className="mt-0 px-0"
                                    >
                                      <HStack className="mt-0 px-0">
                                        <Medium lineHeight={"16.44px"}>
                                          {it.display}
                                        </Medium>
                                      </HStack>
                                      {it.value && (
                                        <HStack className="mt-0">
                                         <Medium lineHeight={"16.44px"}>
                                            {it.value}
                                            </Medium>
                                        </HStack>
                                      )}
                                    </Stack>
                                  ))
                                ) : (
                                  <Box maxH={"380px"} overflowY={"scroll"}>
                                    <>
                                    <Medium lineHeight={"16.44px"}>
                                        {expanded ? item?.imageData : `${item?.imageData.substring(0, MAX_LENGTH)}...`}
                                      </Medium>

                                      <Medium
                                        pt="1"
                                        marginInlineStart={"0px!important"}
                                        fontWeight={"700"}
                                        color="color.blue"
                                        cursor={"pointer"}
                                        onClick={toggleExpanded}

                                      >
                                        {expanded ? common.READ_LESS : common.READ_MORE}
                                      </Medium>
                                    </>
                                  </Box>
                                )}
                              </Box>
                              )}
                          </HStack>
                          <HStack>
                            <Image
                              className="editorialclosebtn"
                              src={closeIcon}
                              onClick={() => {
                                onClose();
                              }}
                              cursor={"pointer"}
                            ></Image>
                          </HStack>
                        </Stack>
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
              </Stack>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Container>
  );
};
