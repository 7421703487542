import api from "../services/api";
import { APIS } from "../endpoints";

export const integratedTimelineDataByMasterlist = async (masterlistType, masterlist) => {
  const result = await api.get(`${APIS.BASE_URL}${masterlistType}/${masterlist}/${APIS.integratedTimeline}`);
  if (result) {
    return result?.data?.data;
  } else {
    return [];
  }
}

export const getYearRangeFilterOptions = async (masterlistType, masterlist) => {
  const result = await api.get(`${APIS.BASE_URL}${masterlistType}/${masterlist}/${APIS.integratedTimelineYearRange}`);
  if (result) {
    let time_periods = result?.data?.data;
    let temp = [];
    let temp2 = [];
    time_periods.forEach((ele, index) => {
      if (temp.length === 0) {
        temp.push(ele.time_period);
        temp2.push(ele);
      } else {
        if (!(temp.includes(ele.time_period))) {
          temp.push(ele.time_period);
          temp2.push(ele);
        }
      }
    });

    return temp2;
  } else {
    return [];
  }
}

export const getYearFilterOptions = async (masterlistType, masterlist) => {
  const result = await api.get(`${APIS.BASE_URL}${masterlistType}/${masterlist}/${APIS.integratedTimelineYearFilter}`);

  if (result) {
    let sortable_dates = result?.data?.data;
    const unique = [...new Set(sortable_dates.map(item => item.sortable_date))];
    let temp = [];
    unique.forEach(ele => {
      if (ele) {
        let obj = {};
        obj['sortable_date'] = ele;
        temp.push(obj);
      }
    });

    return temp;
  } else {
    return [];
  }
}
