import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  HStack,
  List,
  ListItem,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { common, indexTitle } from "../constants/constants";
import { useEffect, useRef, useState } from "react";
import { MdKeyboardArrowDown } from "react-icons/md";
import Small from "../components/layouts/Typography/Small";

export const MasterListType = ({ onClick, marginRight, masterlistOptions,top }) => {
  const [isOpenPopover, setIsOpenPopover] = useState(false);
  const popoverRef = useRef();
  const linkColor = useColorModeValue("gray.900", "gray.200");
  const popoverContentBgColor = useColorModeValue("white", "gray.800");
  const linkHoverColor = useColorModeValue("gray.800", "white");

  useEffect(() => {
    function handleClickOutside(event) {
      if (popoverRef.current && !popoverRef.current.contains(event.target)) {
        setIsOpenPopover(false);
      }
    }

    if (isOpenPopover) {
      document.addEventListener("click", handleClickOutside);
    } else {
      document.removeEventListener("click", handleClickOutside);
    }

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [isOpenPopover]);

  const handleToggle = () => {
    setIsOpenPopover(!isOpenPopover);
  };

  return (
    <>
      <Box display={"flex"} bg="white" alignItems={"center!important"} ref={popoverRef}>
        <Popover
          trigger={"click"}
          placement="top-start"
          isOpen={isOpenPopover}
          closeOnBlur={!isOpenPopover}
          onOpen={handleToggle}
          onClose={handleToggle}
        >
          <PopoverTrigger>
            <Box
              bg="white"
              //
              top={top? top:"92px"}
              left={"auto"}
              marginLeft={"195px"}
              position={"fixed"}
              cursor="pointer"
              fontSize={"14px"}
              fontWeight={"700"}
              fontFamily={"Raleway"}
              color={linkColor}
              _hover={{
                textDecoration: "none",
                color: linkHoverColor,
              }}
            >
              <HStack alignItems={"center"}>
                <Text
                  fontSize={"16px"}
                  color={"#333333"}
                  textStyle={"secondary"}
                  fontWeight={"600"}
                >
                  {indexTitle.MASTERLIST_TYPE}
                </Text>
                <MdKeyboardArrowDown
                  color={"gray.500"}
                  fontSize={"36px"}
                  style={{
                    transform: isOpenPopover ? "rotate(180deg)" : "rotate(0)",
                  }}
                />
              </HStack>
            </Box>
          </PopoverTrigger>
          <PopoverContent
            border={0}
            borderRadius={"1px"}
            bg={popoverContentBgColor}
            fontSize={"sm"}
            cursor="pointer"
            color="black"
            maxWidth={"200px"}
            marginTop={"-5px"}
          >
            <PopoverBody
              maxWidth={"200px"}
              style={{ overflowY: "auto" }}
              height={"270px"}
            >
              <List>
                {masterlistOptions.map((option, index) => (
                  <>
                    {option.dropdown ? (
                      <Accordion allowToggle>
                        <AccordionItem borderTop={"none"}>
                          <AccordionButton className="px-0" p={0}>
                            <Box flex="1" textAlign="left">
                              <Text fontSize={"14px"} lineHeight={"24px"}>
                                {option.type}
                              </Text>
                            </Box>
                            <AccordionIcon
                              fontSize={"30px"}
                              textAlign={"left"}
                            />
                          </AccordionButton>
                          <AccordionPanel pb={4} className="px-1">
                            <Small>{common.CONTENT_AWAITED}</Small>
                          </AccordionPanel>
                        </AccordionItem>
                      </Accordion>
                    ) : (
                      <ListItem
                        key={index}
                        borderBottom={"1px solid #DFDFDF"}
                        py={1}
                        fontSize={"12px"}
                        fontWeight={"500"}
                        lineHeight={"24px"}
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        {option.type}
                      </ListItem>
                    )}
                  </>
                ))}
              </List>
            </PopoverBody>
          </PopoverContent>
        </Popover>
      </Box>
    </>
  );
};
