import { exploreData } from "../MockData/ExploreImageData";
import api from "../services/api";
import { APIS } from "../endpoints";

export const getExploreData = async (page, pageSize) => {
  const dummyDataEnabled = process.env.REACT_APP_DUMMY_DATA || "";
  if (dummyDataEnabled.toLowerCase() === "yes") {
    return exploreData;
  } else {
    const response = await getExploreDataAPI(page, pageSize);
    try{
      if (response) {
        return response;
      } else {
        console.error("Invalid response data structure:", response);
        return [];
      }
    } catch (e){
      console.error("Something is wrong", e)
    }
  }
};

export const getExploreDataAPI = async (page, pageSize) => {
  const result = await api.get(`${APIS.BASE_URL}${APIS.explore}/${APIS.masterlists}?page_number=${page}&page_size=${pageSize}`)
  if (result) {
    return result?.data;
  } else {
    return [];
  }
};
