import { Text } from '@chakra-ui/react';

export default function FilterResultCount({filteredResultCount}) {

    return (
        <Text fontWeight={600} pb={2}>
            {`Showing filter result : ${filteredResultCount}`}
        </Text>
    );
}
