import { transformJSON } from "../util/transformer";
import { isTransformationNotAllowed } from "../util/isTranformationAllowed";
import { amitabh_bachchan_song_booklets, raj_kapoor_song_booklets, shammi_kapoor_song_booklets, silsila_song_booklets, songs_synopsis_booklets, stayajit_ray_song_booklets } from "../MockData/SongsSynopsisBooklets";

const schema = [];
export const getBookletSongsDataAPI = async (tableName, masterlist) => {
};

export const getBookletSongsData = async (tableName, masterlist) => {
  if (isTransformationNotAllowed() === true) {
    return getBookletSongsDataDummy(masterlist);
  }else if (isTransformationNotAllowed() === false) {
    const response = await getBookletSongsDataAPI(tableName, masterlist);
    try {
      if (response) {
        let transformedData = transformJSON(schema, true, response);
        return transformedData;
      } else {
        console.error("Invalid response data structure:", response);
        return [];
      }
    } catch (e) {
      console.error("Something is wrong", e);
    }
  }    
};

// export const getBookletSongsDataDummy = () => {
//     const obj = [
//       {
//         id: 1,
//         image: booklet1,
//         link: true,
//       },
//       {
//         id: 2,
//         image: placeholder,
//       },
//       {
//         id: 3,
//         image: placeholder,
//       },
//       {
//         id: 4,
//         image: placeholder,
//       },
//     ];
//     return obj;
//   };
  export const getBookletSongsDataDummy = (masterlist) => {
    const bookletSongsDataMap = {
      "satyajit-ray":stayajit_ray_song_booklets,
      "raj-kapoor":raj_kapoor_song_booklets,
      "amitabh-bachchan":amitabh_bachchan_song_booklets,
      "shammi-kapoor":shammi_kapoor_song_booklets
  
      // Add more cases as needed
    };
    // Check if the masterlist exists in the map, if not, use a default value
    const bookletSongsData = bookletSongsDataMap[masterlist] || stayajit_ray_song_booklets;
    return bookletSongsData;
  };
  
//songs synopsis booklets

export const getSongsSynopsisBookletsAPI = async (tableName, masterlist) => {
};
export const getSongsSynopsisBooklets = async (tableName, masterlist) => {
  if (isTransformationNotAllowed() === true) {
    return getSongsSynopsisBookletsDummy(masterlist);
  }else if (isTransformationNotAllowed() === false) {
    const response = await getSongsSynopsisBookletsAPI(tableName, masterlist);
    try {
      if (response) {
        let transformedData = transformJSON(schema, true, response);
        return transformedData;
      } else {
        console.error("Invalid response data structure:", response);
        return [];
      }
    } catch (e) {
      console.error("Something is wrong", e);
    }
  }    
};

export const getSongsSynopsisBookletsDummy = (masterlist) => {
  return songs_synopsis_booklets;
};

// songs Images Data
export const getSongsImagesAPI = async (tableName, songName) => {
};
export const getSongsImages = async (tableName, songName) => {
  if (isTransformationNotAllowed() === true) {
    return getSongsImagesDummy(songName);
  }else if (isTransformationNotAllowed() === false) {
    const response = await getSongsImagesAPI(tableName, songName);
    try {
      if (response) {
        let transformedData = transformJSON(schema, true, response);
        return transformedData;
      } else {
        console.error("Invalid response data structure:", response);
        return [];
      }
    } catch (e) {
      console.error("Something is wrong", e);
    }
  }    
};

export const getSongsImagesDummy = (songName) => {
  return silsila_song_booklets;
};