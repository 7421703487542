import { Navigation, Pagination, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import {
  Box,
  Container,
  HStack,
  Image,
  SimpleGrid,
  Stack,
  Text,
} from "@chakra-ui/react";

// Core files
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

// Service Files
import * as getInsightData from "../../services/Insight";

// Subsection Files
import { common } from "../../constants/constants";
import { WebpageComingSoon } from "../../CommonComponents/WebpageComingSoon";

function Insights({ masterlist }) {
  const [insightsData, setInsightsData] = useState(true);
  useEffect(() => {
    const getData = async () => {
      const result = await getInsightData.getInsightsByMasterlist(
        "masterlist_table",
        masterlist
      );
      if (result) {
        setInsightsData(result);
      }
    };
    getData();
  }, [masterlist]);

  return (
    <>
      {insightsData ? (
        <>
          <Container
            maxW="auto"
            pr="0px"
            pl="0px"
            className="songs exploretabs"
            id="allsliderskkcollection"
          >
            <Stack width={"100%"} position={"relative"}>
              <Swiper
                modules={[Navigation, Pagination, A11y]}
                slidesPerView={1}
                loop={false}
                navigation={true}
                height={"100%"}
              >
                {insightsData.length > 0 &&
                  insightsData.map((it, index) => (
                    <SwiperSlide>
                      <Box
                        width={"auto"}
                        height={{ base: "auto", md: "570px" }}
                        className="Bgimage"
                      >
                        <Link to={`/location/${masterlist}/insights/${it.insight}`}>
                          <Image
                            src={it.imgPath}
                            width={"auto"}
                            height={"100%"}
                            alt=""
                          />
                        </Link>
                      </Box>
                    </SwiperSlide>
                  ))}
              </Swiper>
            </Stack>
          </Container>
          <Container maxW="auto" mt={"40px"}>
            <Stack className="exploretabs">
              <SimpleGrid columns={[0, null, 3]} w={"auto"} spacing="30px">
                {[1, 2, 3, 4, 5, 6].map((_, index) => {
                  return (
                    <Box key={index} height="auto">
                      <Link to="">
                        <Box
                          height="272px"
                          width={"auto"}
                          bg={"#C4C4C4"}
                          display="flex"
                        ></Box>
                      </Link>
                      <Stack pb="8" mt="8px">
                        <HStack>
                          <Text
                            fontSize={"12px"}
                            fontWeight={"400"}
                            color="#035DA1"
                          >
                            {common.CONTENT_AWAITED}
                          </Text>
                        </HStack>
                        <HStack>
                          <Text
                            fontSize={"12px"}
                            fontWeight={"400"}
                            color="#333333"
                          >
                            {common.CONTENT_AWAITED}
                          </Text>
                        </HStack>
                      </Stack>
                    </Box>
                  );
                })}
              </SimpleGrid>
            </Stack>
          </Container>
        </>
      ) : (
       <WebpageComingSoon />
      )}
    </>
  );
}
export default Insights;
