import {
  Box,
  Divider,
  HStack,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Link,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import H4 from "../components/layouts/Typography/H4";
import Layout from "../components/sections/SidebarLayout";
import { AiOutlineSearch } from "@react-icons/all-files/ai/AiOutlineSearch";
import { RiCloseCircleFill } from "@react-icons/all-files/ri/RiCloseCircleFill";
import { common, indexTitle } from "../constants/constants";
import { getIndexData } from "../services/MasterlistIndexService";
import { CopyPasteService } from "../services/CopyPaste";

//S3 Bucket osw-dev-images
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;

const BackArrow = `${IMAGE_BASE_PATH}/ui/Chevron/backarrow.png`

function MasterlistsInbox() {
  const [tableData, setTableData] = React.useState([]);
  const [showLabel, setShowLabel] = React.useState(false);

  React.useEffect(() => {
    const getData = async () => {
      const result = await getIndexData();
      if (result?.length > 0) {
        setTableData(result);
        setShowLabel(false);
      }
      if (result?.length === 0) {
        setShowLabel(true);
      }
    };
    getData();
  }, []);
  const [dummyData, setDummyData] = useState(false);
  const dummyDataEnabled = process.env.REACT_APP_DUMMY_DATA || "";
  useEffect(() => {
    if (dummyDataEnabled.toLowerCase() === "yes") {
      setDummyData(true);
    } else {
      setDummyData(false);
    }
  }, [dummyDataEnabled]);
  // const [tableData, setTableData] = useState([]);
  // React.useEffect(() => {
  //   const getData = async () => {
  //     const result = await getIndexData();
  //     if (result) {
  //       setTableData(result);
  //     }
  //   };
  //   getData();
  // }, []);
  // console.log(setTableData);
  return (
    <Layout>
      <Stack mx="22px">
        <Box py="4">
          <Stack flexDirection={"row"} justifyContent={"space-between"}>
            <HStack marginTop={"0rem!important"} pl="4">
              <Link href="/explore/4">
                <Image src={BackArrow}></Image>
              </Link>

              {dummyData ? (
                <Text textStyle="secondary" fontSize="16px" fontWeight="700">
                  1 to 345 Masterlists
                </Text>
              ) : (
                <Text textStyle="secondary" fontSize="16px" fontWeight="700">
                  0 Masterlists
                </Text>
              )}
            </HStack>
            <HStack className="col-4" px="0">
              <InputGroup variant={"filled"}>
                <InputLeftElement
                  pointerEvents="none"
                  color="black"
                  fontSize="1.4em"
                  children={<AiOutlineSearch />}
                />
                <Input
                  placeholder="Search the Index"
                  opacity={1}
                  id="indexsearchbox"
                />
                <InputRightElement>
                  <RiCloseCircleFill size={"24px"} />
                </InputRightElement>
              </InputGroup>
            </HStack>
          </Stack>
        </Box>

        <Box
          boxShadow="2xl"
          py="2"
          px="0"
          ma
          rounded="md"
          bg="white"
          borderColor={"#f1f1f1"}
        >
          <HStack justifyContent={"center"}>
            <Stack>
              {showLabel ? (
                <Text
                  py={"40"}
                  fontSize={"45px"}
                  fontWeight="700"
                  textAlign="center!im"
                >
                 {common.WEBPAGE_COMING_SOON}
                </Text>
              ) : (
                <>
                  <TableContainer overflowX={"hidden"} cellSpacing="10">
                    <Table variant="unstyled" className="fixTableHead">
                      <Thead>
                        <Tr>
                          <Th textTransform={"capitalize"}>
                            <H4 pb="2" pt="4">
                              {indexTitle.PUBLISHED_NUMBER}
                            </H4>
                            <Divider className="bottom-border-radius" />
                          </Th>
                          <Th width={"50%"} textTransform={"capitalize"}>
                            <H4 pb="2">
                              {indexTitle.MASTERLIST_NAME} <br />
                              {indexTitle.TIME_AND_DATE}
                            </H4>
                            <Divider className="bottom-border-radius" />
                          </Th>
                          <Th textTransform={"capitalize"}>
                            <H4 pb="2" pt="4">
                              {indexTitle.MASTERLIST_TYPE}
                            </H4>
                            <Divider className="bottom-border-radius" />
                          </Th>
                          <Th textTransform={"capitalize"}>
                            <H4 pb="2" pt="4">
                              {indexTitle.DATE_OF_PUBLISHING}
                            </H4>
                            <Divider className="bottom-border-radius" />
                          </Th>
                          <Th textTransform={"capitalize"}>
                            <H4 pb="2" pt="4">
                              {indexTitle.LAST_UPDATED}
                            </H4>
                            <Divider
                              width={"50%"}
                              className="bottom-border-radius"
                            />
                          </Th>
                        </Tr>
                      </Thead>

                      <Tbody
                        textAlign={"left"}
                        onCopy={(e) =>
                          CopyPasteService(e, window.location.href)
                        }
                      >
                        {tableData.map((item, index) => {
                          return (
                            <Tr fontSize="16px" key={index} fontWeight={"600"}>
                              <Td textAlign={"center!important"}>{item?.id}</Td>
                              <Td width={"50%"}>{item?.name}</Td>
                              <Td>{item?.type}</Td>
                              <Td>{item?.date_of_publishing}</Td>
                              <Td>{item?.last_updated}</Td>
                            </Tr>
                          );
                        })}
                      </Tbody>
                    </Table>
                  </TableContainer>
                </>
              )}
            </Stack>
          </HStack>
        </Box>
        <Stack py="2"></Stack>
      </Stack>
    </Layout>
  );
}
export default MasterlistsInbox;
