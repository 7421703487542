import { Container, Stack, Text } from "@chakra-ui/react";
import React from "react";
import "react-vertical-timeline-component/style.min.css";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { common } from "../../constants/constants";

function Monthly() {
  return (
    <Stack>
      <Container className="px-0" maxW="auto">
 
          <Text
            py={"40"}
            fontSize={{ base: "24px", md: "45px" }}
            fontWeight="700"
            textAlign="center"
          >
            {common.WEBPAGE_COMING_SOON}
          </Text>
  
      </Container>
    </Stack>
  );
}

export default Monthly;
