import moment from "moment";

//S3 Bucket osw-dev-images
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;

const Dummy = `${IMAGE_BASE_PATH}/ui/DummyImage/dummy.png`
const Dummy1 = `${IMAGE_BASE_PATH}/ui/Calendar/image1.png`
const Dummy2 = `${IMAGE_BASE_PATH}/ui/Calendar/image2.png`
const Dummy3 = `${IMAGE_BASE_PATH}/ui/Calendar/image3.png`

const currentDate = moment();
const monthName = currentDate.format("MMM");
const daysInMonth = currentDate.daysInMonth();
const dayNames = [];
for (let day = 1; day <= daysInMonth; day++) {
    const date = moment({
      year: currentDate.year(),
      month: currentDate.month(),
      day,
    });
    const dayName = date.format("ddd");
    dayNames.push({
      date: day,
      day: dayName,
      month: monthName,
      images:[Dummy1, Dummy2, Dummy3],
      materlistName:"Name of | Masterlist",
      datetime:"3:16 PM, Monday, 17 January 2023"
    });
}

export const DailyCalendarData = dayNames;
export const WeeklyCalendarData = [];
export const MonthlyCalendarData =
{
    "months": [
        {
            "id":1,
            "name": "Jan",
            "weeks": [
                {
                    "weekNumber": 1,
                    "data": [Dummy,Dummy,Dummy,Dummy,Dummy,Dummy,Dummy,Dummy]
                },
                {
                    "weekNumber": 2,
                    "data": [Dummy,Dummy,Dummy,Dummy,Dummy,Dummy,Dummy,Dummy]
                },
                {
                    "weekNumber": 3,
                    "data": [Dummy,Dummy,Dummy,Dummy,Dummy,Dummy,Dummy,Dummy]
                },
                {
                    "weekNumber": 4,
                    "data": [Dummy,Dummy,Dummy,Dummy,Dummy,Dummy,Dummy,Dummy]
                }
            ]
        },
        {
            "id":2,
            "name": "Feb",
            "weeks": [
                {
                    "weekNumber": 1,
                    "data": [Dummy,Dummy,Dummy,Dummy,Dummy,Dummy,Dummy,Dummy]
                },
                {
                    "weekNumber": 2,
                    "data": [Dummy,Dummy,Dummy,Dummy,Dummy,Dummy,Dummy,Dummy]
                },
                {
                    "weekNumber": 3,
                    "data": [Dummy,Dummy,Dummy,Dummy,Dummy,Dummy,Dummy,Dummy]
                },
                {
                    "weekNumber": 4,
                    "data": [Dummy,Dummy,Dummy,Dummy,Dummy,Dummy,Dummy,Dummy]
                }
            ]
        },
        {
            "id":3,
            "name": "Mar",
            "weeks": [
                {
                    "weekNumber": 1,
                    "data": [Dummy,Dummy,Dummy,Dummy,Dummy,Dummy,Dummy,Dummy]
                },
                {
                    "weekNumber": 2,
                    "data": [Dummy,Dummy,Dummy,Dummy,Dummy,Dummy,Dummy,Dummy]
                },
                {
                    "weekNumber": 3,
                    "data": [Dummy,Dummy,Dummy,Dummy,Dummy,Dummy,Dummy,Dummy]
                },
                {
                    "weekNumber": 4,
                    "data": [Dummy,Dummy,Dummy,Dummy,Dummy,Dummy,Dummy,Dummy]
                }
            ]
        },
        {
            "id":4,
            "name": "Apr",
            "weeks": [
                {
                    "weekNumber": 1,
                    "data": [Dummy,Dummy,Dummy,Dummy,Dummy,Dummy,Dummy,Dummy]
                },
                {
                    "weekNumber": 2,
                    "data": [Dummy,Dummy,Dummy,Dummy,Dummy,Dummy,Dummy,Dummy]
                },
                {
                    "weekNumber": 3,
                    "data": [Dummy,Dummy,Dummy,Dummy,Dummy,Dummy,Dummy,Dummy]
                },
                {
                    "weekNumber": 4,
                    "data": [Dummy,Dummy,Dummy,Dummy,Dummy,Dummy,Dummy,Dummy]
                }
            ]
        },
        {
            "id":5,
            "name": "May",
            "weeks": [
                {
                    "weekNumber": 1,
                    "data": [Dummy,Dummy,Dummy,Dummy,Dummy,Dummy,Dummy,Dummy]
                },
                {
                    "weekNumber": 2,
                    "data": [Dummy,Dummy,Dummy,Dummy,Dummy,Dummy,Dummy,Dummy]
                },
                {
                    "weekNumber": 3,
                    "data": [Dummy,Dummy,Dummy,Dummy,Dummy,Dummy,Dummy,Dummy]
                },
                {
                    "weekNumber": 4,
                    "data": [Dummy,Dummy,Dummy,Dummy,Dummy,Dummy,Dummy,Dummy]
                }
            ]
        },
        {
            "id":6,
            "name": "Jun",
            "weeks": [
                {
                    "weekNumber": 1,
                    "data": [Dummy,Dummy,Dummy,Dummy,Dummy,Dummy,Dummy,Dummy]
                },
                {
                    "weekNumber": 2,
                    "data": [Dummy,Dummy,Dummy,Dummy,Dummy,Dummy,Dummy,Dummy]
                },
                {
                    "weekNumber": 3,
                    "data": [Dummy,Dummy,Dummy,Dummy,Dummy,Dummy,Dummy,Dummy]
                },
                {
                    "weekNumber": 4,
                    "data": [Dummy,Dummy,Dummy,Dummy,Dummy,Dummy,Dummy,Dummy]
                }
            ]
        },
        {
            "id":7,
            "name": "Jul",
            "weeks": [
                {
                    "weekNumber": 1,
                    "data": [Dummy,Dummy,Dummy,Dummy,Dummy,Dummy,Dummy,Dummy]
                },
                {
                    "weekNumber": 2,
                    "data": [Dummy,Dummy,Dummy,Dummy,Dummy,Dummy,Dummy,Dummy]
                },
                {
                    "weekNumber": 3,
                    "data": [Dummy,Dummy,Dummy,Dummy,Dummy,Dummy,Dummy,Dummy]
                },
                {
                    "weekNumber": 4,
                    "data": [Dummy,Dummy,Dummy,Dummy,Dummy,Dummy,Dummy,Dummy]
                }
            ]
        },
        {
            "id":8,
            "name": "Aug",
            "weeks": [
                {
                    "weekNumber": 1,
                    "data": [Dummy,Dummy,Dummy,Dummy,Dummy,Dummy,Dummy,Dummy]
                },
                {
                    "weekNumber": 2,
                    "data": [Dummy,Dummy,Dummy,Dummy,Dummy,Dummy,Dummy,Dummy]
                },
                {
                    "weekNumber": 3,
                    "data": [Dummy,Dummy,Dummy,Dummy,Dummy,Dummy,Dummy,Dummy]
                },
                {
                    "weekNumber": 4,
                    "data": [Dummy,Dummy,Dummy,Dummy,Dummy,Dummy,Dummy,Dummy]
                }
            ]
        },
        {
            "id":9,
            "name": "Sep",
            "weeks": [
                {
                    "weekNumber": 1,
                    "data": [Dummy,Dummy,Dummy,Dummy,Dummy,Dummy,Dummy,Dummy]
                },
                {
                    "weekNumber": 2,
                    "data": [Dummy,Dummy,Dummy,Dummy,Dummy,Dummy,Dummy,Dummy]
                },
                {
                    "weekNumber": 3,
                    "data": [Dummy,Dummy,Dummy,Dummy,Dummy,Dummy,Dummy,Dummy]
                },
                {
                    "weekNumber": 4,
                    "data": [Dummy,Dummy,Dummy,Dummy,Dummy,Dummy,Dummy,Dummy]
                }
            ]
        },
        {
            "id":10,
            "name": "Oct",
            "weeks": [
                {
                    "weekNumber": 1,
                    "data": [Dummy,Dummy,Dummy,Dummy,Dummy,Dummy,Dummy,Dummy]
                },
                {
                    "weekNumber": 2,
                    "data": [Dummy,Dummy,Dummy,Dummy,Dummy,Dummy,Dummy,Dummy]
                },
                {
                    "weekNumber": 3,
                    "data": [Dummy,Dummy,Dummy,Dummy,Dummy,Dummy,Dummy,Dummy]
                },
                {
                    "weekNumber": 4,
                    "data": [Dummy,Dummy,Dummy,Dummy,Dummy,Dummy,Dummy,Dummy]
                }
            ]
        },
        {
            "id":11,
            "name": "Nov",
            "weeks": [
                {
                    "weekNumber": 1,
                    "data": [Dummy,Dummy,Dummy,Dummy,Dummy,Dummy,Dummy,Dummy]
                },
                {
                    "weekNumber": 2,
                    "data": [Dummy,Dummy,Dummy,Dummy,Dummy,Dummy,Dummy,Dummy]
                },
                {
                    "weekNumber": 3,
                    "data": [Dummy,Dummy,Dummy,Dummy,Dummy,Dummy,Dummy,Dummy]
                },
                {
                    "weekNumber": 4,
                    "data": [Dummy,Dummy,Dummy,Dummy,Dummy,Dummy,Dummy,Dummy]
                }
            ]
        },
        {
            "id":12,
            "name": "Dec",
            "weeks": [
                {
                    "weekNumber": 1,
                    "data": [Dummy,Dummy,Dummy,Dummy,Dummy,Dummy,Dummy,Dummy]
                },
                {
                    "weekNumber": 2,
                    "data": [Dummy,Dummy,Dummy,Dummy,Dummy,Dummy,Dummy,Dummy]
                },
                {
                    "weekNumber": 3,
                    "data": [Dummy,Dummy,Dummy,Dummy,Dummy,Dummy,Dummy,Dummy]
                },
                {
                    "weekNumber": 4,
                    "data": [Dummy,Dummy,Dummy,Dummy,Dummy,Dummy,Dummy,Dummy]
                }
            ]
        },
    ]
}




