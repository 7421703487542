import { Image, Stack, Text, VStack } from "@chakra-ui/react";

//Core files
import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";

function ImageSlider({
  sliderData,
  handleImageClick,
  width,
  height,
  modules,
  grabCursor,
  setSwiper,
  classname,
  sheight,
}) {
  return (
    <Swiper
      grabCursor={!grabCursor && true}
      navigation={true}
      modules={modules}
      // loop={true}
      // id="allsliderskk"
      onSwiper={setSwiper && setSwiper}
    >
      {sliderData?.map((item, index) => {
        return (
          <>
            <SwiperSlide key={item?.id} className={classname && classname}>
              <VStack>
                <Stack
                  justifyContent={"center"}
                  width={{ base: "auto", md: "100%" }}
                  height={{ base: "275px", md: sheight ? sheight : "400px" }}
                >
                  <Image
                    onClick={() =>
                      handleImageClick && handleImageClick(item, index)
                    }
                    src={item.imgPath ? item?.imgPath : item}
                    cursor={"pointer"}
                    width={width}
                    height={height}
                  />
                </Stack>
                <Stack>
                  <Text
                    fontSize={"14px"}
                    fontWeight={"400"}
                    textAlign={"left"}
                    color={"#035DA1"}
                  >
                    {item?.desc ? item?.desc : item?.title}
                  </Text>
                </Stack>
              </VStack>
            </SwiperSlide>
          </>
        );
      })}
    </Swiper>
  );
}

export default ImageSlider;
