import { Box, Container, HStack, Stack} from "@chakra-ui/react";

//Core files
import React from "react";

//Component files

import ImageWithCitation from "../../../FunctionalComponents/SimpleGrid/ImageWithCitation";

//Language files
import { FilterBox } from "../../../FunctionalComponents/Filter/FilterBox";
import InfiniteScroll from "react-infinite-scroll-component";
import NoMoreToLoad from "../../../CommonComponents/NoMoreToLoad";
import Grid from "../../../FunctionalComponents/SimpleGrid/Grid";
import { CopyPasteService } from "../../../services/CopyPaste";
import { FilterButton } from "../../../CommonComponents/FilterButton";
import FilterPills from "../../../components/FilterPills/FilterPills";
import { DataNotFound } from "../../../CommonComponents/DataNotFound";
import { FullScreenImage } from "../../EventExhibition/Component/FullScreenImage";

//S3 Bucket osw-dev-images
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;
const loaderImg = `${IMAGE_BASE_PATH}/ui/Chevron/loader.gif`;

export default function GridView({
  alias,
  //new data
  showFilterBox,
  handleFilterClick,
  data,
  fetchMoreData,
  hasMore,
  setShowFilterBox,
  setApply,
  explore,
  apply,
  handleCheckboxChange,
  handleSearch,
  filterOptions,
  filteroptionData,
  setFilterOptions,
  setSearchTerm,
  filterCount,
  searchTerm,
  handleReset,
  handleImageClick,
  setFilterPills,
  filterPills,
  filteredResultCount,
  fullScreenImage,
  clickedImageIndex,
  setClickedImageIndex,
  showImages,
  ImgData,
  filerStyle
}) {

  return (
  <>
   <Stack flexDirection={"row-reverse"}>
        {!showFilterBox && <FilterButton marginRight={"-30px"} onClick={handleFilterClick} />}
        <Container
          maxW="auto"
          className="songs"
          px={{ base: "4", md: "0px" }}
          py={"30px"}
          position={"relative"}
        >
          <Stack flexDirection={"row"} justifyContent="center">
            {data && data.length > 0 ? (
              <Stack
                onCopy={(e) =>
                  CopyPasteService(e, `${window.location.href}/0/1`)
                }
                marginRight={{ base: "0px", md: "30px" }}
                pr={"30px"}
                alignItems={"flex-start"}
              >
              {((filterPills &&  filterPills!==undefined && filterCount>0) || filterOptions?.search) &&
                <FilterPills
                filters={filterPills}
                setFilterOptions={setFilterOptions}
                setApply={setApply}
                setSearchTerm={setSearchTerm}
                setFilterPills={setFilterPills}
                filteredResultCount={filteredResultCount}
                />
              }
                <InfiniteScroll
                 dataLength={data.length}
                 next={fetchMoreData}
                 hasMore={hasMore}
                  loader={
                    <Box textAlign="center" marginLeft={"50%"}>
                      <Stack marginTop={"20px"}>
                        <img
                          src={loaderImg}
                          width={"100px"}
                          height={"100px"}
                          alt="Loading"
                        />
                      </Stack>
                    </Box>
                  }
                  endMessage={<NoMoreToLoad />}
                >
                 <Grid
                  data={data}
                  columns={{ base: 1, md: 3, lg: 3, xl: 4 }}
                  spacing={{ base: "5px", md: "15px" }}
                  component={ImageWithCitation}
                  handleImageClick={handleImageClick}
                />
                </InfiniteScroll>
              </Stack>
            ) 
            : (
              <>
                <Stack
                  onCopy={(e) =>
                    CopyPasteService(e, `${window.location.href}/0/1`)
                  }
                  margin={"auto"}
                >
                  <Box position={"relative"} top="-70px" right={"auto"} left={"0px"}>
                  {filterPills &&  filterPills!==undefined && filterCount>0&&
                    <FilterPills
                    filters={filterPills}
                    setFilterOptions={setFilterOptions}
                    setApply={setApply}
                    setFilterPills={setFilterPills}
                    />
                  }
                </Box>
                  <DataNotFound />
                </Stack>
              </>
            )}
            <HStack
              marginLeft={"4"}
              marginRight={"-30px!important"}
              className="mt-0"
              alignItems={"flex-start"}
              pos={"relative"}
            >
              {showFilterBox && (
                <FilterBox
                setShowFilterBox={setShowFilterBox}
                setApply={setApply}
                explore={explore}
                apply={apply}
                handleCheckboxChange={handleCheckboxChange}
                handleSearch={handleSearch}
                filterOptions={filterOptions}
                filteroptionData={filteroptionData}
                setFilterOptions={setFilterOptions}
                setSearchTerm={setSearchTerm}
                filterCount={filterCount}
                searchTerm={searchTerm}
                handleReset={handleReset}
                className={filerStyle? filerStyle : "box-filter-film4"}
                />
              )}
            </HStack>
          </Stack>
        </Container>
        {fullScreenImage && (
        <FullScreenImage
          isOpen={fullScreenImage}
          onClose={() => showImages(null, false)}
          imageData={ImgData}
          data={data}
          clickedImageIndex={clickedImageIndex}
          setClickedImageIndex={setClickedImageIndex}
          bannerFlag={false}
          title="banner"
        />
      )}
      </Stack>
  </>
  );
}
