import {
  Container,
  HStack,
  Stack,
} from "@chakra-ui/react";

//Core files
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

//Language files
import { osiansCinefan } from "../../../constants/constants";
import TabMain from "../../../FunctionalComponents/TabComponents/TabMain";
import Catalogue from "./Overview/Catalogue";
import Programmes from "./Overview/Programes";
import { useLocation, useParams } from "react-router-dom";

function Overview({ winnerName, setReadMoreFlag, readmoreNavigation ,masterlistType}) {
  const [visible, setVisible] = React.useState(true);
  const [listGridIcon, setListGridIcon] = useState(false);
  const { t } = useTranslation("");
  const [tabPanelData, setTabPanelData] = useState([]);
  const {innerTab}=useParams();
  const [attr, setAttr] = useState(0)
  const pathToIndex = {
    'catalouge': 0,
    'programmes': 1,

  };
  const location = useLocation();
  // const width = { base: "100%", md: "1160px" };

  useEffect(() => {
    setAttr(pathToIndex[innerTab] ?? 0);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, attr]);
  function formatData() {
    return [
      {
        name: `${t(osiansCinefan.CATALOGUE)}`,
        component:<Catalogue winnerName={winnerName}/>,
        link:`/event-film-festival/${winnerName}/agraphy/body-of-work/overview/catalouge`,
        list:false
      },
      {
        name: `${t(osiansCinefan.PROGRAMMES)}`,
        component: <Programmes winnerName={winnerName}
        setReadMoreFlag={setReadMoreFlag}
        readmoreNavigation={readmoreNavigation} 
        masterlistType={masterlistType}
        />,
        link:`/event-film-festival/${winnerName}/agraphy/body-of-work/overview/programmes/screening`,
        list:false
      },

    ];
  }
  const tabHandle=(item)=>{
    setListGridIcon(item)
  }

  useEffect(() => {
    if (winnerName) {
      setTabPanelData(formatData());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [winnerName,visible]);
  return (
    <Container minW={"100%"} pb="4" paddingX={{ base: "4", md: "0px" }}>
      <Stack>
        <HStack>
          {tabPanelData && tabPanelData.length > 0 && (
            <TabMain paddingTop="2" width="1070px" pos={"fixed"} data={tabPanelData} listId="listgridbutton3" tabTop={{ base: "55px", sm: "75px", md: "55px" }} panelTop={{ base: "65px", sm: "85px", md: "40px" }} tabHandle={tabHandle} listGridIcon={listGridIcon} setVisible={setVisible} visible={visible} topTogleIcon={"140px"} attr={attr}/>
          )}
        </HStack>
      </Stack>
    </Container>
  );
}

export default Overview;
