import React, { useEffect, useState } from "react";
import { HStack, VStack } from "@chakra-ui/react";
import UserDetails from "./UserDetails";
import BookMarks from "./BookMarks";
import SuccessCard from "./SuccessCard";
import UserStats from "./UserStats";
import MasterlistChessBoard from "./MasterlistChessBoard";
import * as getProfile from "../../services/profile";

// import { getProfileData } from "../../services/profile";
import { useSelector } from "react-redux";

const ProfilePagenew = () => {
  const session = useSelector((state) => state.session);
  const { user } = session;
  const [profileDatas, setProfileData] = useState(null);
  const [successCard, setSuccessCard] = useState(false);
  const [showMasterlists, setShowMasterlists] = useState(false);
  const [showBookmark, setShowBookmark] = useState(false);
  const [UserStat, setUserStat] = useState(null)
  const [masterlistData, setMasterlistData] = useState([]);
  const [rcData, setRcData] = useState([]);
  const [bookmarkData, setBookmarkData] = useState([]);

  const [checkImages, setCheckImages] = useState([]);
  const [successCardData, setSuccessCardData] = useState(null);
  async function fetchData() {
    try {
      const result = await getProfile.getProfileData();
      if (result) {
        setProfileData(result);
        setRcData(result?.rcMasterlist);
        setUserStat(result?.stats)
        const images = result?.rcMasterlist?.flatMap((category) =>
          category.masterlists.map((item) => item)
        );
        setCheckImages(images);
        setBookmarkData(result?.bookmarks);
      }
      const rcResult = await getProfile.getRCProfileData();
      if (rcResult) {
        // setRcData(rcResult);
        const images = rcResult.flatMap((category) =>
          category.masterlist.map((item) => item)
        );
        setCheckImages(images);
      }
      const BookmarkResult = await getProfile.getBookmarkProfileData();
      if (BookmarkResult) {
        setBookmarkData(BookmarkResult);
        // setItems();
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }
  useEffect(() => {
    fetchData();
  }, [user.accessToken]);
  const handleSuccesCard = (item) => {
    setSuccessCard(true);
    setSuccessCardData(item);
    setShowMasterlists(false);
    setMasterlistData([]);
  };
  useEffect(() => {
    if (successCard) {
      setShowBookmark(false);
    }
  }, [successCard]);
  const handleToggle = () => {
    setSuccessCard(false);
    setShowMasterlists(false);
    setShowBookmark(!showBookmark);
    setMasterlistData([]);
  };
  return (
    <HStack
      flexDirection={{ base: "column", md: "row" }}
      spacing={8}
      align="start"
      py={{base:"15px",md:"32px"}}
      px={{base:"15px",md:"64px"}}
      gap={{base:"15px",md:"65px"}}
    >
      {/* User Details and Bookmarks */}
      <VStack align="start" gap={{base:"10px",md:"0px"}} spacing={4} width={{base:"100%",md:"50%"}}>
        {profileDatas !== undefined && profileDatas !== null && (
          <UserDetails
            data={profileDatas.profile}
            setSuccessCard={setSuccessCard}
            setShowMasterlists={setShowMasterlists}
            setShowBookmark={setShowBookmark}
            showMasterlists={showMasterlists}
            rcData={rcData}
            masterlistData={masterlistData}
            setMasterlistData={setMasterlistData}
          />
        )}
        {bookmarkData.length > 0 && (
          <BookMarks
            data={bookmarkData}
            setShowBookmark={setShowBookmark}
            showBookmark={showBookmark}
            handleToggle={handleToggle}
          />
        )}
        {successCard && successCardData !== null && (
          <SuccessCard
            setSuccessCard={setSuccessCard}
            data={profileDatas.profile}
            successCardData={successCardData}
          />
        )}
      </VStack>

      {/* Stats and Chessboard */}
      <VStack pb={{base:"75px",md:"0px"}} marginInlineStart={{base:"0!important",md:"8"}} align="start" spacing={4} width={{base:"100%",md:"50%"}}>
        {UserStat && <UserStats UserStat={UserStat}/>}
          {checkImages && checkImages.length>0 &&<MasterlistChessBoard
            data={checkImages}
            handleSuccesCard={handleSuccesCard}
          />}
      </VStack>
    </HStack>
  );
};

export default ProfilePagenew;
