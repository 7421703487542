import {
  Box,
  HStack,
  useColorModeValue,
  Drawer,
  DrawerContent,
  useDisclosure,
  Stack,
  Container,
  Text,
} from "@chakra-ui/react";

//Core files
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { RiLayoutGridFill } from "@react-icons/all-files/ri/RiLayoutGridFill";
import { IoMdCalendar } from "@react-icons/all-files/io/IoMdCalendar";
import { MdMap } from "@react-icons/all-files/md/MdMap";
import { Link } from "react-router-dom";
import { MdHomeFilled } from "react-icons/md";

// Service file 
import { CopyPasteService } from "../services/CopyPaste";

//Component files
import Carosuel from "../components/sections/Carosuel";
import ProjectsCarosuel from "..//components/sections/ProjectsCarosuel";
import Footer from "../components/sections/Footer";
import Slider from "../components/layouts/slider";
import H4 from "../components/layouts/Typography/H4";
import LeftVmenu from "../FunctionalComponents/LeftVmenu";

//Language file
import { button, homeTitle } from "../constants/constants";
import { useTranslation } from "react-i18next";
import H1 from "../Theme/Typography/H1";

const SidebarContent = ({ onClose, display, ...rest }) => {
  const [sumit, setSubmit] = useState(false);
  const toggleModal = () => {
    setSubmit(!sumit);
  };

  const LeftVmenuData = [
    {
      link: "/",
      id: "homeicon",
      icon: MdHomeFilled,
      active: true,
    },
    {
      link: "/explore",
      id: "exploreicon",
      icon: RiLayoutGridFill,
    },
    {
      link: "/calendar",
      id: "calendaricon",
      icon: IoMdCalendar,
    },
    {
      link: "/map",
      id: "mapicon",
      icon: MdMap,
    },
  ];
  const navigate = useNavigate();
  const handleNavigation = () => {
    navigate(-1);
  };
  return (
    <LeftVmenu
      handleNavigation={handleNavigation}
      backArrow={false}
      display={display}
      onClose={onClose}
      toggleModal={toggleModal}
      LeftVmenuData={LeftVmenuData}
    />
  );
};
export default function Home({ children }) {
  const { isOpen, onClose } = useDisclosure();
  const [sliderId, setSliderId] = useState(1);
  const [user, setUser] = useState(null);
  const navigate = useNavigate();
  const { sliderNumber } = useParams();
  const { t } = useTranslation("");

  useEffect(() => {
    if (sliderNumber) {
      setSliderId(sliderNumber);
      navigate("/", { replace: true });
    } else {
      setSliderId(0);
    }
  }, [sliderNumber, navigate]);

  const [dummyData, setDummyData] = useState(false);
  const dummyDataEnabled = process.env.REACT_APP_DUMMY_DATA || "";
  useEffect(() => {
    if (dummyDataEnabled.toLowerCase() === "yes") {
      setDummyData(true);
    } else {
      setDummyData(false);
    }
  }, [dummyDataEnabled]);

  useEffect(() => {
    const userData = localStorage.getItem('user');

    if (userData) {
      setUser(JSON.parse(userData));
    }
  }, []);
  console.log("user", user);

  return (
    <Container maxW="1440px" px="0px">
      <Box minH="100vh" bg={useColorModeValue("gray.100", "gray.900")}>
        <SidebarContent
          onClose={onClose}
          display={{ base: "none", md: "block" }}
        />
        <Drawer
          autoFocus={false}
          isOpen={isOpen}
          placement="left"
          onClose={onClose}
          returnFocusOnClose={false}
          onOverlayClick={onClose}
          size="full"
        >
          <DrawerContent>
            <SidebarContent onClose={onClose} />
          </DrawerContent>
        </Drawer>

        <Box ml={{ base: 0, md: 14 }} bg="white">
          {children}
          <Slider sliderNumber={sliderId} />
          <Stack className="homepagecontent" mx="16" my="50px">
            <HStack
              display={{ base: "none", md: "flex" }}
              justifyContent={"space-between"}
              px="4"
              mb="3"
            >
              <HStack onCopy={(e) => CopyPasteService(e, window.location.href)}>
                {dummyData ? (
                  <H1 fontSize={{base:"20px",md:"24px"}} lineHeight={{base:"26px",md:"32px"}}>{t(homeTitle.FESTIVALS)}</H1>
                ) : (
                  <H1 fontSize={{base:"20px",md:"24px"}} lineHeight={{base:"26px",md:"32px"}}>{t(homeTitle.FESTIVALS)}</H1>
                )}
              </HStack>
              <HStack width={"150px"} justifyContent={"flex-end"}>
                <Link to="/songs/all" style={{ textDecoration: "none" }}>
                  <H4 className="viewall">{t(button.VIEW_ALL)}</H4>
                </Link>
              </HStack>
            </HStack>
            <HStack
              display={{ base: "flex", md: "none" }}
              justifyContent={"space-between"}
              px="4"
              my="4"
            >
              <HStack onCopy={(e) => CopyPasteService(e, window.location.href)}>
                {dummyData ? (
                  <Text fontSize="20px" lineHeight={"28px"} fontWeight={"700"}>
                    {homeTitle.FESTIVALS}
                  </Text>
                ) : (
                  <H1 fontSize={{base:"20px",md:"24px"}} lineHeight={{base:"26px",md:"32px"}}>{t(homeTitle.FESTIVALS)} </H1>
                )}
              </HStack>
              <HStack width={"150px"} justifyContent={"flex-end"}>
                <Link to="/songs/all" style={{ textDecoration: "none" }}>
                  <Text
                    fontSize="14px"
                    lineHeight={"19px"}
                    fontWeight={"700"}
                    className="viewall"
                  >
                    {t(button.VIEW_ALL)}
                  </Text>
                </Link>
              </HStack>
            </HStack>
            <Stack>
              <Carosuel />
            </Stack>
            {/* TO DO: Restructure the Home.js,  */}
            <HStack
              display={{ base: "none", md: "flex" }}
              justifyContent={"space-between"}
              p="4"
              mb="4"
            >
              <HStack onCopy={(e) => CopyPasteService(e, window.location.href)}>
                {dummyData ? (
                  <H1 fontSize={{base:"20px",md:"24px"}} lineHeight={{base:"26px",md:"32px"}} textStyle={"primary"}>{t(homeTitle.PROJECTS)}</H1>
                ) : (
                  <H1 fontSize={{base:"20px",md:"24px"}} lineHeight={{base:"26px",md:"32px"}}>{t(homeTitle.PROJECTS)}</H1>
                )}
              </HStack>
              <HStack>
                <Link to="/projects/all" style={{ textDecoration: "none" }}>
                  <H4>{t(button.VIEW_ALL)}</H4>
                </Link>
              </HStack>
            </HStack>
            <HStack
              display={{ base: "flex", md: "none" }}
              justifyContent={"space-between"}
              px="4"
              my="4"
            >
              <HStack onCopy={(e) => CopyPasteService(e, window.location.href)}>
                {dummyData ? (
                  <Text fontSize="20px" lineHeight={"28px"} fontWeight={"700"}>
                    {t(homeTitle.PROJECTS)}
                  </Text>
                ) : (
                  <H1>{t(homeTitle.PROJECTS)}</H1>
                )}
              </HStack>
              <HStack width={"150px"} justifyContent={"flex-end"}>
                <Link to="/projects/all" style={{ textDecoration: "none" }}>
                  <Text
                    fontSize="14px"
                    lineHeight={"19px"}
                    fontWeight={"700"}
                    className="viewall"
                  >
                    {t(button.VIEW_ALL)}
                  </Text>
                </Link>
              </HStack>
            </HStack>
            <HStack>
              <ProjectsCarosuel />
            </HStack>
          </Stack>
          <Footer />
        </Box>
      </Box>
    </Container>
  );
}
