import axios from "axios";
import { transformJSON } from "../util/transformer";
import { archiveSchema } from "./schemas/Archive";
import { isTransformationNotAllowed } from "../util/isTranformationAllowed";
import { amitabh_bachchan_archive_data, raj_kapoor_archive_data, skk_archive_data, skk_filter_options } from "../MockData/ArchiveMockup";
const baseUrl = `${process.env.REACT_APP_GRAPHQL_URL}`;
const baseUrlMongo = `${process.env.REACT_APP_MONGODB_URL}`;

export const getArchiveImagesAPI = async (tableName, masterlist, filterOptions, searchTerm) => {
  const whereCondition = {
    masterlist_IN: masterlist,
  };

  // Conditionally add type_IN property if filterOptions?.docType is set and not empty
  if (filterOptions?.docType && filterOptions?.docType.length > 0) {
    whereCondition.medium_of_artwork_IN = filterOptions.docType;
  }
  const graphqlQuery = {
    operationName: "MyQuery",
    query: `query MyQuery ($where: ArtworkWhere){` +
      tableName +
      ` (where: $where){
          accession_number
          alias
          artist_name
          any_other_sales_in_auction
          artwork_sr_no_as_per_essay_publication
          any_gallery_price_list_available_for_artwork
          any_collaborators_for_exhibition
          auction_code
          auction_date_year
          additional_information
          artwork_in_a_graphy_section_sub_section
          content_id
          conversion_rate
          collection_of_image_links
          current_collection
          dates_for_exhibition
          date_of_artwork
          depth_in_cm
          depth_in_inches
          direct_url_of_preview_image
          featured_artist_or_facial_recognition_names
          height_in_cm
          height_in_inches
          height_cm
          id
          image
          image_code_from_the_auction_econ_database
          image_file_name_code
          location
          masterlist
          medium_of_artwork
          name_of_institution
          name_of_exhibition
          name_of_publication
          notes6
          notes5
          notes4
          notes3
          notes2
          notes1
          old_image_name
          object_of_focus
          publication_year
          revised_medium
          section
          sp_home_currency
          sortable_year_of_publication
          title
          title_of_artwork_listed_in_essay
          type_of_exhibition
          venues
          volume_number
          width_in_inches
          width_in_cm
          width_cm
          wnn
          sp_usd
          medium
          artworkSectionSubsection {
            id
            alias
            name
          }          
        }
      }`,
    variables: {
      where: whereCondition,
    },
  };

  const result = await axios.post(baseUrl, graphqlQuery);
  if (result?.data?.data?.artworks) {
    return result?.data?.data?.artworks;
  } else {
    return [];
  }
};

export const getArchiveImages = async (masterlistType, masterlist, filterOptions = {}, page = 1, searchTerm = "") => {
  // Create a new FormData object
  const formData = new FormData();
  formData.append('filters', JSON.stringify(filterOptions));
  formData.append('page_number', page);

  // Convert FormData to URLSearchParams
  const params = new URLSearchParams(formData);
  if (isTransformationNotAllowed() === true) {
    const result = await axios.get(`${baseUrlMongo}${masterlistType}/${masterlist}/research-centre/archive`, {
      params: params
    });
    console.log(result, "result")
    if (result?.data?.data.length > 0) {
      return result?.data;
    } else {
      const { mockData } = await getArchiveImagesDummy(masterlist);
      return mockData;
    }
  } else if (isTransformationNotAllowed() === false) {
    const result = await axios.get(`${baseUrlMongo}${masterlistType}/${masterlist}/research-centre/archive`, {
      params: params
    });
    if (result) {
      return result?.data;
    }
  }
};

export const getArchiveImagesDummy = (masterlist) => {
  const bibliographDataMap = {
    "satyajit-ray": skk_archive_data,
    "shatranj-ke-khilari-1977": skk_archive_data,
    "art-heritage-annual": skk_archive_data,
    "mughal-e-azam": skk_archive_data,
    "raj-kapoor": raj_kapoor_archive_data,
    "amitabh-bachchan": amitabh_bachchan_archive_data,
  }
  const mockData = bibliographDataMap[masterlist];
  // Need  for future use
  // const filterMockData = (filterCategories,searchTerm) => {

  //     if ((
  //         filterCategories.docType.length > 0 )
  //          ||
  //       searchTerm !== '' || searchTerm !==undefined
  //     ) {

  //       const filteredData = _.filter(mockData, entry => {
  //       const docTypeCheck = filterCategories.docType.length > 0
  //         ? filterCategories.docType.includes(entry.find(item => item.key === "medium_of_artwork").value)
  //         : true;

  //       const searchTermCheck = searchTerm !== ""
  //         ? 
  //           entry.find(item => item.key === "medium_of_artwork").value.toLowerCase().includes(searchTerm.toLowerCase())
  //         : true;
  //       return (
  //         docTypeCheck  && searchTermCheck
  //       );
  //     });
  //       return filteredData;
  //     } else {
  //       return mockData;
  //     }
  //   };
  return {
    mockData
    // filterMockData
  };
};

export const getArchiveFilterOptionsAPI = async (tableName, masterlist) => {
  const graphqlQuery = {
    operationName: "MyQuery",
    query: `query MyQuery($where: ArtworkWhere) {` +
      tableName + `(where: $where) {
            medium_of_artwork             
          }
        }`,
    variables: {
      where: {
        masterlist_IN: masterlist,
      },
    },
  };

  const result = await axios.post(baseUrl, graphqlQuery);
  if (result?.data?.data?.artworks) {
    return result?.data?.data?.artworks;
  } else {
    return [];
  }
};

export const getArchiveFilterOptions = async (tableName, masterlist) => {
  if (isTransformationNotAllowed() === true) {
    const response = await getArchiveFilterOptionsDummyData(masterlist);
    return response;
  } else if (isTransformationNotAllowed() === false) {
    const response = await getArchiveFilterOptionsAPI(tableName, masterlist);
    const FilterOptions = {
      documentTypeFilter: [...new Set(response.map((item) => item?.medium_of_artwork.trim()).filter((medium) => medium !== ""))],
    };

    return FilterOptions;
  }
};

const getArchiveFilterOptionsDummyData = (masterlist) => {
  const bibliographDataMap = {
    "satyajit-ray": skk_filter_options,
    "shatranj-ke-khilari-1977": skk_filter_options,
    "art-heritage-annual": skk_filter_options,
    "mughal-e-azam": skk_filter_options,
  }
  const data = bibliographDataMap[masterlist];
  return data;
};

export const getCreditsImagesByMasterlistAliasAPI = async (tableName, masterlist, section) => {
  const whereCondition = {
    masterlist_IN: masterlist,
  };

  if (section) {
    whereCondition.artwork_in_a_graphy_section_sub_section_CONTAINS = section;
  }

  const graphqlQuery = {
    operationName: "MyQuery",
    query: `query MyQuery($where: ArtworkWhere) {` +
      tableName + `(where: $where) {
            name_of_publication
            date_of_artwork
            medium_of_artwork
            revised_medium
            artwork_in_a_graphy_section_sub_section
            masterlist     
          }
        }`,
    variables: {
      where: whereCondition,
    },
  };

  const result = await axios.post(baseUrl, graphqlQuery);
  if (result?.data?.data?.artworks) {
    return result?.data?.data?.artworks;
  } else {
    return [];
  }
}

export const getCreditsImagesByMasterlistAlias = async (tableName, masterlist, section) => {
  if (isTransformationNotAllowed() === true) {
    const response = await getCreditsImagesByMasterlistAliasDummy(masterlist);
    return response;
  } else if (isTransformationNotAllowed() === false) {
    const response = await getCreditsImagesByMasterlistAliasAPI(tableName, masterlist, section);
    try {
      if (response) {
        let transformedData = transformJSON(archiveSchema, true, response);
        return transformedData;
      } else {
        console.error("Invalid response data structure:", response);
        return [];
      }
    } catch (e) {
      console.error("Something is wrong", e);
    }
  }
};

export const getCreditsImagesByMasterlistAliasDummy = (masterlist) => {
  return []
};

// To do - Move this dummy data function in mockup file
export const getArchiveData = async (alias) => {
  if (alias === "satyajit-ray") {
    const obj =
    {
      id: 1,
      imgPath: "",
      title: "Archive",
      description: "Satyajit Ray",
    }

    return obj;
  }
  if (alias === "sayed-haider-raza") {
    const obj =
    {
      id: 1,
      imgPath: "",
      title: "Archive",
      description: "S.H.Raza",
    }

    return obj;
  }
};

// To do - Move this dummy data function in mockup file
export const getArchiveImageData = async (alias) => {
  if (alias === "satyajit-ray") {
    const obj = [
      {
        id: 1,
        imgPath: "",
      },
    ];
    return obj;
  }
  if (alias === "sayed-haider-raza") {
    const obj = [
      {
        id: 1,
        imgPath: "",
      },
    ];
    return obj;
  }
};
