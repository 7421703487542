import { osianamaCircleData } from "../MockData/OsianamaCircleData";

export const getOsianamaImageData = async () => {
  const dummyDataEnabled = process.env.REACT_APP_DUMMY_DATA || "";
  if (dummyDataEnabled.toLowerCase() === "yes") {
    return osianamaCircleData;
  } else {
    return [];
  }
};
