import {
  Box,
  useColorModeValue,
  Drawer,
  DrawerContent,
  useDisclosure,
  Stack,
  Container,
} from "@chakra-ui/react";

//Core files
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

//Service files
import * as getIntroductionData from "../../services/Introduction";
import * as getLandingMasterlistData from "../../services/SectionService";

//Component files
import CommonLeftSidebar from "../../FunctionalComponents/LeftSidebar/CommonLeftSidebar";

//Language files
import { explore } from "../../constants/constants";
import Scholarship from "./BodyOfWork/Scholarship";
import ExhibitionsTabpanel from "./BodyOfWork/Tabs/ExhibitionsTabpanel";
import TabMain from "../../FunctionalComponents/TabComponents/TabMain";


export default function SHRazaBodyOfWork({ children }) {
  const [title, setTitle] = React.useState();
  const [date, setDate] = React.useState();
  const [datedod, setDateDod] = React.useState();
  const { isOpen, onClose } = useDisclosure();
  const [readMoreText, setReadMore] = useState(false);
  const { alias } = useParams();
  const aliasAPI = alias;
  const currentEndpoint = window.location.pathname;
  const parts = currentEndpoint.split("/");
  const masterlistType=parts[1]
  const [introData, setIntroData] = useState([]);
  const [horislinkData, setHorislinkData] = useState([]);
  const [artistDetails, setArtistDetails] = useState(null);
  React.useEffect(() => {
    const getIntroData = async () => {
      const result = await getIntroductionData.introductionByAlias(
        masterlistType,
        aliasAPI
      );
      if (result) {
        setHorislinkData([
          {
            link: `/research-categories/${
              result[0]?.find((item) => item?.key === "researchCategories")
                ?.value[0]?.link
            }`,
            title: result[0]?.find((item) => item?.key === "researchCategories")
              ?.value[0]?.value,
          },
          {
            link: `/categories/indian-cinema`,
            title: result[0].find((item) => item.key === "subject").value +"."+result[0].find((item) => item.key === "masterlist_type").value,
          }
        ]);
        setArtistDetails(
          result.reduce((acc, it) => {
            acc.name = it?.find((item) => item?.key === "full_name")?.value;
            acc.date_of_birth = it?.find(
              (item) => item?.key === "date_of_birth"
            )?.value;
            acc.date_of_death = it?.find(
              (item) => item?.key === "date_of_death"
            )?.value;
            return acc;
          }, {})
        );
      }
    };
    const getLandingData = async () => {
      const result = await getLandingMasterlistData.getLandingPageInfoByAlias(
        masterlistType,
        aliasAPI
      );
      if (result) {
        setIntroData(
          result.map((it) => ({
            text: it?.find((item) => item?.key === "landing_page_title")?.value,
            to: `/${masterlistType}/${
              it?.find((item) => item?.key === "masterlist_alias")?.value
            }/${
              it?.find((item) => item?.key === "landing_page_abbreviation")
                ?.value
            }/${it.find(
              (item) =>
                item.key === "landing_page_abbreviation"
            )?.value==="research-centre" ?`library`:``}
             `,
            title: it?.find((item) => item?.key === "landing_page_abbreviation")
              ?.value,
          }))
        );
      }
    };
    getIntroData();
    getLandingData();
  }, [aliasAPI, masterlistType]);

  return (
    <Box minH="100%" bg={useColorModeValue("white", "gray.900")}>
      <SidebarContent
        onClose={() => onClose}
        display={{ base: "none", md: "block" }}
        height="calc(100vh)"
        title={title}
        date={date}
        datedod={datedod}
        readMoreText={readMoreText}
        setReadMore={setReadMore}
        aliasAPI={aliasAPI}
        horislinkData={horislinkData}
        artistDetails={artistDetails}
        introData={introData}
        masterlistType={masterlistType}

      />
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
      >
        <DrawerContent>
          <SidebarContent
            onClose={onClose}
            title={title}
            date={date}
            datedod={datedod}
            readMoreText={readMoreText}
            setReadMore={setReadMore}
            aliasAPI={aliasAPI}
            masterlistType={masterlistType}
          />
        </DrawerContent>
      </Drawer>
      <Box ml={{ base: 0, md: 52 }} bg="white" >
        {children}
        <BodyOfWorkBody
          setTitle={setTitle}
          setDate={setDate}
          setDateDod={setDateDod}
          aliasAPI={aliasAPI}
          masterlistType={masterlistType}
        />
      </Box>
    </Box>
  );
}

const SidebarContent = ({
  title,
  date,
  datedod,
  onClose,
  readMoreText,
  setReadMore,
  aliasAPI,
  horislinkData,
  artistDetails,
  introData,
  masterlistType,
  ...rest
}) => {
  const data = {
    oSections: [
      {
        to: `/${masterlistType}/${aliasAPI}/posts`,
        title: "",
        text: explore.POSTS,
      },
      {
        to: `/${masterlistType}/${aliasAPI}/insights`,
        title: "",
        text: explore.INSIGHTS,
      },
    ],
    backNavigation: `/${masterlistType}/${aliasAPI}`,
  };
  const navigate = useNavigate();
  const handleNavigation = (alias) => {
    if (readMoreText) {
      setReadMore(false);
    } else {
      navigate(-1);
    }
  };

  return (
    <>
      <CommonLeftSidebar
        sections={introData}
        oSections={data.oSections}
        hMenu={horislinkData}
        artistDetails={artistDetails}
        backTo={data.backNavigation}
        handleNavigation={handleNavigation}
        leftFlag={true}
        readMoreText={readMoreText}
      />
    </>
  );
};

export const BodyOfWorkBody = ({ setTitle, setDate, setDateDod, aliasAPI,masterlistType }) => {
  let {aliasProp}=useParams();
  const [exhibitionsCount,setExhibitionsCount]=useState(0);

  function formatData(alias) {
    const createCommonComponents = (aliasAPI, masterlistType) => [
      {
        name: `${explore.EXHIBITIONS} ${exhibitionsCount >0 ? `(${exhibitionsCount})`:''}`,          
        component: <ExhibitionsTabpanel aliasAPI={aliasAPI} masterlistType={masterlistType} setExhibitionsCount={setExhibitionsCount} />,
        link:`/${masterlistType}/${aliasAPI}/agraphy/body-of-work/exhibitions`,
      },
      {
        name: `${explore.SCHOLARSHIP}`,
        component: <Scholarship masterlistType={masterlistType} masterlist={aliasAPI} />,
        link:`/${masterlistType}/${aliasAPI}/agraphy/body-of-work/scholarship`,
      }
    ];
  
    const commonComponents = createCommonComponents(aliasAPI, masterlistType);  
    return commonComponents;
  }
  
const [tabPanelData, setTabPanelData] = useState([])
  useEffect(()=>{
    if(aliasAPI!==undefined){
      setTabPanelData(formatData(aliasAPI))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[aliasAPI,exhibitionsCount,])

  return (
    <>
      <Stack height={"100%"}>
      <Container
        maxW="auto"
        px={{base:"4",md:"0"}}
        mx={"0"}
      >
      <Stack   px={{base:"0",md:"64px"}} >
      {tabPanelData && tabPanelData.length>0 && <TabMain paddingTop="20px" gap="50px" width={"1080px"} pos={"fixed"}
            panelTop={{ base: "55px", sm: "55px", md: "65px" }}  data={tabPanelData} aliasProp={aliasProp}/>}
      </Stack>
      </Container>	
      </Stack>
    </>
  );
};