
import { amitabh_bachchan_awardsData, mughal_awardsData, raj_kapoor_awardsData, satyajit_awardsData, skk_awardsData } from "../MockData/AwardMockup";
import { isTransformationNotAllowed } from "../util/isTranformationAllowed";
import api from "../services/api";
import { APIS } from "../endpoints";

export const awardshonoursByWinnerAPI = async (masterlistType, winner, page) => {
  const formData = new FormData();
  formData.append('page_number', page);
  const params = new URLSearchParams(formData);

  const result = await api.get(`${APIS.BASE_URL}${masterlistType}/${winner}/${APIS.awards}`, 
    {
      params: params
    })
  if (result) {
    return result?.data;
  } else {
    return [];
  }
};

export const awardshonoursByWinner = async (masterlistType, masterlist, page) => {
  if (isTransformationNotAllowed() === true) {
    const response = awardshonoursByWinnerdummydata(masterlist);
    return response;
  } else if (isTransformationNotAllowed() === false) {
    const response = await awardshonoursByWinnerAPI(masterlistType, masterlist, page);
    try {
      if (response) {
        return response;
      } else {
        console.error("Invalid response data structure:", response);
        return [];
      }
    } catch (e) {
      console.error("Something is wrong", e);
    }
  }
};

export const awardshonoursByWinnerdummydata = (winner) => {
  const awardsHonoursDataMap = {
    "satyajit-ray": satyajit_awardsData,
    "shatranj-ke-khilari-1977": skk_awardsData,
    "art-heritage-annual": satyajit_awardsData,
    "mughal-e-azam": mughal_awardsData,
    "sayed-haider-raza": satyajit_awardsData,
    "raj-kapoor": raj_kapoor_awardsData,
    "amitabh-bachchan": amitabh_bachchan_awardsData,
  }
  const mockData = awardsHonoursDataMap[winner];
  return mockData;
}

// Mughal-E-Azam Awards Data
export const getAwardsData = () => {
  const data = [
    {
      sortable_date: "1977",
      desc: "National Film Awards | Best Feature Film in Hindi | Directorate of Film Festivals",
      image: false
    },
    {
      sortable_date: "1978",
      desc: "Filmfare Awards: Critics Award for Best Movie. Best Director",
      image: false
    },
    {
      sortable_date: "1979",
      desc: "Filmfare Awards | Best Director | Filmfare",
      image: true
    },
    {
      sortable_date: "1979",
      desc: "Filmfare Awards | Best Supporting Actor | Filmfare",
      image: false
    },
  ];
  return data;
};
