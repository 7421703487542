import { Text } from "@chakra-ui/react";
import React from "react";

const SmallBold = ({ children, ...props }) => {
  return (
    <Text
      as="p"
      {...props}
      fontSize="12px"
      fontWeight="700"
      lineHeight={"14px"}
      textStyle={"primary"}
    >
      {children}
    </Text>
  );
};
export default React.memo(SmallBold);
