import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Container,
  Divider,
  HStack,
  Stack,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { common } from "../../../../constants/constants";
import { useParams } from "react-router-dom";
import * as getResearchCentreBook from "../../../../services/book";
import Small from "../../../../components/layouts/Typography/Small";
import BookSlider from "../../../../FunctionalComponents/BookSlider/BookSlider";

export const BookDetails = ({thumbnailWidth }) => {

  const { bookAlias } = useParams();
  const [bookData, setBookData] = useState([]);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isExpandedAuthor, setIsExpandedAuthor] = useState(false);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };
  const toggleExpandAuthor = () => {
    setIsExpandedAuthor(!isExpandedAuthor);
  };
  useEffect(() => {
    const fetchData = async () => {
      let result;
      result = await getResearchCentreBook.getResearchCentreBookbyBookAlias(
        "tableName",
        bookAlias
      );
      setBookData(result);
      if (bookData?.length > 0) {
        setSelectedImg({
          image: bookData[0].slider_data[0].image,
          desc: "",
          index: 0,
          slideImage: bookData[0]?.slider_data[0].image,
        });
      }
    };
    fetchData();
  }, [bookAlias, bookData]);


  const [selectedImg, setSelectedImg] = useState({
    image: "",
    desc: "",
    index: 0,
    slideImage:"",
  });

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Stack  flexDirection={{base:"column",md:"row"}}
        justifyContent={"space-between"}
        py={{base:"40px",md:"60px"}}
        gap={"20px"}
        width={{base:"100%",md:"1140px"}}>
        {bookData.length>0 && 
        <HStack
        alignItems={"center"}
        flexDirection={"column"}
        width={{ base: "100%", md: "567px" }}
        id="allBookSliders"
      >
       <BookSlider
          bookData={bookData[0]?.slider_data}
          setSelectedImg={setSelectedImg}
          selectedImg={selectedImg}
        />
        </HStack>
        }
        <HStack flexDirection={"column"} alignItems={"flex-start"} height={"100%"} width={"100%"} px={{base:"4",md:"8"}}>
          <Text
            fontSize={"20px"}
            fontWeight={"700"}
            lineHeight={"22px"}
            color="#333333"
          >
            {bookData[0]?.bookName}
          </Text>
          <Box width={{base:"100%",md:"392px"}} py={5}>
          <Accordion allowToggle>
              <AccordionItem>
                <AccordionButton className="px-0">
                  <Box flex="1" textAlign="left" px={5}>
                    <Text
                      fontSize={"16px"}
                      fontWeight={"700"}
                      lineHeight={"24px"}
                      color={"#000000"}
                    >
                      {common.CONTENTS}
                    </Text>
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
                <Box maxH="160px" overflowY="auto">
                  <AccordionPanel pb={4} className="px-1">
                    {bookData?.length > 0 &&
                      bookData?.contentsData?.length > 0 &&
                      bookData[0]?.contentsData.map((item) => {
                        return (
                          <>
                            <Text
                              fontSize={"14px"}
                              pt={"4px"}
                              pb={"4px"}
                              px={5}
                            >
                              {item.title}
                            </Text>
                            <Divider color="#909090" />
                          </>
                        );
                      })}
                  </AccordionPanel>
                </Box>
              </AccordionItem>
              <AccordionItem>
                <AccordionButton className="px-0">
                  <Box flex="1" textAlign="left" px={5}>
                    <Text
                      fontSize={"16px"}
                      fontWeight={"700"}
                      lineHeight={"24px"}
                      color={"#000000"}
                    >
                      {common.INDEX}
                    </Text>
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel pb={4} className="px-1">
                  <Small px={5}>{common.CONTENT_AWAITED}</Small>
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          </Box>
          <Container px="0px">
            <Text
              fontSize={"15px"}
              fontWeight={"700"}
              lineHeight={"22px"}
              paddingLeft={"20px"}
            >
              <li>{common.ABOUT_THE_BOOK}</li>
            </Text>
            <Text
              fontSize={"15px"}
              fontWeight={"500"}
              // lineHeight={"22px"}
              marginTop={"25px"}
              textAlign={"justify"}
            >
              {isExpanded
                ? bookData?.length > 0 && bookData[0]?.bookText
                : `${
                    bookData?.length > 0 && bookData[0]?.bookText.slice(0, 360)
                  }`}
              <span
                style={{
                  color: "#035DA1",
                  cursor: "pointer",
                  fontWeight: "700",
                }}
                onClick={toggleExpand}
              >
                {isExpanded ? " Read Less" : " Read More"}
              </span>
            </Text>
          </Container>
          <Container px="0px" py={5}>
            <Text
              fontSize={"15px"}
              fontWeight={"700"}
              lineHeight={"22px"}
              paddingLeft={"20px"}
            >
              <li>{common.ABOUT_THE_AUTHOR}</li>
            </Text>
            <Text
              fontSize={"15px"}
              fontWeight={"500"}
              // lineHeight={"22px"}
              marginTop={"25px"}
              textAlign={"justify"}
            >
              {isExpandedAuthor
                ? bookData?.length > 0 && bookData[0]?.authorText
                : `${
                    bookData?.length > 0 &&
                    bookData[0]?.authorText.slice(0, 263)
                  }`}
              <span
                style={{
                  color: "#035DA1",
                  cursor: "pointer",
                  fontWeight: "700",
                }}
                onClick={toggleExpandAuthor}
              >
                {isExpandedAuthor ? " Read Less" : " Read More"}
              </span>
            </Text>
          </Container>
        </HStack>
      </Stack>
    </>
  );
};
