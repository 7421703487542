import React from 'react';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import Small from '../../components/layouts/Typography/Small';

const TimelineWithText3 = ({ data, handleImageClick }) => {

    return (
        <>
            {data.length > 0 &&
                data?.map((item, index) => (
                    <VerticalTimeline layout={"1-column-left"} className="layout">
                  {item?.publicationYear !== "" && (
                    <VerticalTimelineElement
                      id="timeline2"
                      className="col-12"
                      contentStyle={{ fontSize: "16px", lineHeight:"18.78px", fontWeight:"400", marginLeft:"55px", paddingTop:"5px", paddingBottom:"5px" }}
                      iconStyle={{
                        background: "#fff",
                        color: "black",
                        fontSize: "16px",
                        fontWeight: "700",
                      }}
                      icon={item?.year}
                    >
                      {item?.link ? (
                        <Small cursor={"pointer"} color="#035DA1" onClick={()=>handleImageClick(item,index)}>
                          {item?.listDesc}
                        </Small>
                      ) : (
                        <Small cursor={"pointer"} color="#035DA1" onClick={()=>handleImageClick(item,index)}>
                          {item?.listDesc}
                        </Small>                      )}
                    </VerticalTimelineElement>
                  )}
                </VerticalTimeline>
                ))}
        </>
    );
};

export default TimelineWithText3;
