import {
  ComingSoonData,
  WhatsnewArchivedData,
  WhatsnewData,
} from "../MockData/Whatsnew";

export const getWhatsnewData = async () => {
  const dummyDataEnabled = process.env.REACT_APP_DUMMY_DATA || "";
  if (dummyDataEnabled.toLowerCase() === "yes") {
    return WhatsnewData;
  } else {
    return [];
  }
};

export const getComingSoonData = async () => {
  const dummyDataEnabled = process.env.REACT_APP_DUMMY_DATA || "";
  if (dummyDataEnabled.toLowerCase() === "yes") {
    return ComingSoonData;
  } else {
    return [];
  }
};

export const getWhatsnewArchivedData = async () => {
  const dummyDataEnabled = process.env.REACT_APP_DUMMY_DATA || "";
  if (dummyDataEnabled.toLowerCase() === "yes") {
    return WhatsnewArchivedData;
  } else {
    return [];
  }
};
