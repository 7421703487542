import { Text } from "@chakra-ui/react";
import { common } from "../constants/constants";

export const DataNotFound = () => {

  return (
    <>
        <Text py={{base:'20',md:'40'}} fontSize={{base:"22px",md:"25px"}} fontWeight="700" textAlign="center">
          {common.NO_DATA_FOUND}
        </Text>
    </>
  );
};