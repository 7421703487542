import React, { useEffect, useState } from 'react';
import { Autoplay, Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Box, Stack, Image } from '@chakra-ui/react';
import { getLoginSliderData } from '../services/LoginService';

const LoginSlider = () => {
  const [sliderData, setSliderData] = useState([]);

  useEffect(() => {
    const fetchSliderData = async () => {
      const data = await getLoginSliderData();
      setSliderData(data);
    };
    fetchSliderData();
  }, []);

  return (
    <Stack width={{ base: "370px", md: "665px" }} height={{ base: "auto", md: "465px" }}>
      <Box className="col-sm-12 col-md-12">
        {sliderData && sliderData.length>0 && <Swiper
          spaceBetween={0}
          centeredSlides={true}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          pagination={{
            clickable: false,
          }}
          loop={true}
          navigation={false}
          modules={[Autoplay, Navigation, Pagination]}
          className="mySwiper"
        >
          {sliderData.map((item) => (
            <SwiperSlide key={item.id}>
              <Box width={{ base: "100%", md: "665px" }}>
                <Image src={item?.image} width={"100%"} objectFit={"contain"} height={"auto"} alt={`Slide ${item?.id}`} />
              </Box>
            </SwiperSlide>
          ))}
        </Swiper>}
      </Box>
    </Stack>
  );
};

export default LoginSlider;
