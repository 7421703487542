import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Container,
  Heading,
  HStack,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Select,
  Stack,
  Text,
} from "@chakra-ui/react";
import React, { useState } from "react";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import Layout from "../components/sections/SidebarLayout";
import { RiCloseCircleFill } from "@react-icons/all-files/ri/RiCloseCircleFill";
import { BiSearch } from "@react-icons/all-files/bi/BiSearch";
import SmallBold from "../components/layouts/Typography/SmallBold";
import Small from "../components/layouts/Typography/Small";
import { Link } from "react-router-dom";
import { common } from "../constants/constants";

//S3 Bucket osw-dev-images
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;

const Img1 = `${IMAGE_BASE_PATH}/ui/PhilosophicalFramework/VisionSept2022/1.png`
const Img2 = `${IMAGE_BASE_PATH}/ui/PhilosophicalFramework/TimeLine/1.png`
const Img3 = `${IMAGE_BASE_PATH}/ui/PhilosophicalFramework/TimeLine/2.png`
const Img9 = `${IMAGE_BASE_PATH}/ui/PhilosophicalFramework/VisionOct2021/1.jpg`

function PhilosophicalFramework() {
  const [showFilterBox, setShowFilterBox] = useState(false);
  const handleFilterClick = () => {
    setShowFilterBox((showFilterBox) => !showFilterBox);
  };
  console.log(handleFilterClick);

  return (
    <Layout>
      <Stack width={"100%"} bg="#f1f1f1" alignItems={"center"}  paddingX={{ base: "0", md: "4" }}>
        <Container maxW={"94%"} marginX={{ base: "0px", md: "40px" }} >
          <Box>
            <Stack
            className="vision-tabs"
              flexDirection={"row"}
              justifyContent="center"
              alignItems={"flex-end"}
              gap="4"
              paddingTop={"6"}
              paddingBottom={"6"}
              position={"sticky"}
              top={"64px"}
              zIndex={999}
              bg="#f1f1f1"
            >
              <HStack>
                <Link to="/vision">
                  <Text
                    py="2"
                    paddingX={{base: "4", md:"8"}}
                    bg="rgba(255, 192, 21, 0.26)"
                    borderRadius={"5px"}
                    style={{
                      textAlign: "center",
                      fontSize: "15px",
                      fontWeight: "700",
                    }}
                  >
                    {common.VISION}
                  </Text>
                </Link>
              </HStack>
              <HStack>
                <Link to="/philosophical-framework">
                  <Text
                    py="2"
                    paddingX={{base: "4", md:"8"}}
                    bg="#FFC015"
                    borderRadius={"5px"}
                    style={{
                      textAlign: "center",
                      fontSize: "15px",
                      fontWeight: "700",
                    }}
                  >
                   {common.PHILOSOPHICAL_FRAME}
                  </Text>
                </Link>
              </HStack>
              <HStack>
                <Link to="/manifesto">
                  <Text
                    py="2"
                    paddingX={{base: "4", md:"8"}}
                    bg="rgba(255, 192, 21, 0.26)"
                    borderRadius={"5px"}
                    style={{
                      textAlign: "center",
                      fontSize: "15px",
                      fontWeight: "700",
                    }}
                  >
                    {common.MANIFESTO}
                  </Text>
                </Link>
              </HStack>
            </Stack>
            <Stack
              position={"sticky"}
              top="150px"
              zIndex={1}
              py="4"
              alignItems={"center"}
              spacing={3}
            >
              <HStack>
                <Select variant="unstyled" placeholder="Date">
                  <option value="option1">1997</option>
                  <option value="option2">1998</option>
                  <option value="option3">1999</option>
                  <option value="option3">2000</option>
                </Select>
              </HStack>
            </Stack>

            <VerticalTimeline>
              <VerticalTimelineElement
                // className="vertical-timeline-element--work"
                contentStyle={{
                  background: "rgb(255, 255, 255, 1)",
                  color: "gray.900",
                  zIndex: "1",
                }}
                contentArrowStyle={{
                  borderRight: "7px solid  rgb(255, 255, 255, 1)",
                }}
                iconStyle={{
                  background: "rgb(33, 150, 243)",
                  color: "#fff",
                  fontSize: "13px",
                  fontWeight: "900",
                  textAlign: "center",
                  paddingTop: "10px",
                  paddingLeft: "5px",
                  paddingRight: "5px",
                }}
                icon={"15 Nov 1997"}
              >
                <Link
                  style={{ color: "black", textDecoration: "none" }}
                  to="/philosophical-framework/the-intutive-logic-2"
                >
                  <Box>
                    <Heading pb="3" fontSize={"lg"} fontWeight={"700"}>
                      The Intutive-logic II
                    </Heading>

                    <Text fontSize={"11px"} fontWeight={"300"} color="#8f8f8f">
                      MODERN & CONTEMPORARY INDIAN PAINTING, DRAWINGS, GRAPHICS,
                      SCULPTURE & TAPESTRY.
                    </Text>
                  </Box>
                  <Box>
                    <HStack alignItems={"baseline"}>
                      <Text fontSize={"10px"} fontWeight="300" color="#8f8f8f">
                        Exhibition & Auction Curated with Text by{" "}
                      </Text>
                      <Text fontSize={"10px"} fontWeight="700">
                        Neville Tuli
                      </Text>
                    </HStack>

                    <Image
                      boxSize="auto"
                      w="100%"
                      objectPosition="center"
                      src={Img3}
                      alt=""
                    />
                    <Text
                      style={{ paddingBottom: "16px" }}
                      fontSize="12px"
                      fontWeight={"500"}
                    >
                      To my fellow members at HEART... the love and support of
                      Sanjeev Khandelwal has been instrumental in  allowing
                      one’s work to continue at the chosen pace. My  warm
                      gratitude and affection to Priya Paul, Vipula Kadri, 
                      Rashmi Poddar, Aditya Kanoria, Pheroza Godrej, Bipin 
                      Shah, Mallika Sarabhai, Smita Parekh, Vandana Kanoria, 
                    </Text>

                    <Link
                      style={{
                        color: "black",
                        fontSize: "16px",
                        fontWeight: "700",
                        textDecoration: "none",
                        border: "1px solid #e0e0e0",
                        padding: "10px",
                        marginTop: "10px",
                        borderRadius: "5px",
                      }}
                      to="/philosophical-framework/the-intutive-logic-2"
                    >
                      Know More
                    </Link>
                  </Box>
                </Link>
              </VerticalTimelineElement>
              <VerticalTimelineElement
                className="vertical-timeline-element--work oldvision"
                iconStyle={{
                  background: "rgb(233, 30, 99)",
                  color: "#fff",
                  fontSize: "13px",
                  fontWeight: "900",
                  textAlign: "center",
                  paddingTop: "10px",
                  paddingLeft: "5px",
                  paddingRight: "5px",
                }}
                icon={"1 Mar 1997"}
              >
                <Link
                  style={{ color: "black", textDecoration: "none" }}
                  to="/philosophical-framework/the-intutive-logic"
                >
                  <Heading
                    fontSize={"lg"}
                    borderBottom={"1"}
                    borderBottomColor="gray.800"
                    pb="4"
                  >
                    <Link to="" style={{ textDecoration: "none" }}>
                      The Intutive-logic
                    </Link>
                  </Heading>

                  <Link
                    to="/philosophical-framework/the-intutive-logic"
                    style={{ textDecoration: "none" }}
                  >
                    <Image src={Img2} width="475px" />
                  </Link>

                  <Text
                    fontSize={"12px"}
                    fontWeight="500"
                    style={{ paddingBottom: "16px" }}
                  >
                    As always, my mother and father are the unqualified pillars
                    of what I do and may do. Unqualified freedom has been their
                    gift to me, and constant renewal whenever I have failed to
                    be true to that freedom. My sister’s love has always
                    reinforced this
                  </Text>
                </Link>
                <Link
                  style={{
                    color: "black",
                    fontSize: "16px",
                    fontWeight: "700",
                    textDecoration: "none",
                    border: "1px solid #e0e0e0",
                    padding: "10px",
                    marginTop: "10px",
                    borderRadius: "5px",
                  }}
                  to="/philosophical-framework/the-intutive-logic"
                >
                  Know More
                </Link>
              </VerticalTimelineElement>
              <VerticalTimelineElement
                className="vertical-timeline-element--work"
                contentStyle={{
                  background: "rgb(255, 255, 255, 1)",
                  color: "gray.900",
                  zIndex: "1",
                }}
                contentArrowStyle={{
                  borderRight: "7px solid  rgb(255, 255, 255, 1)",
                }}
                iconStyle={{
                  background: "rgb(33, 150, 243)",
                  color: "#fff",
                  fontSize: "13px",
                  fontWeight: "900",
                  textAlign: "center",
                  paddingTop: "10px",
                }}
                icon={"Sept 2022"}
              >
                <Heading
                  fontSize={"lg"}
                  borderBottom={"1"}
                  borderBottomColor="gray.800"
                  pb="4"
                >
                  <Link
                    to="/philosophical-framework/vision-september-2022"
                    style={{ textDecoration: "none" }}
                  >
                    Tuli Research Centre for India Studies
                  </Link>
                </Heading>

                <Link
                  to="/philosophical-framework/vision-september-2022"
                  style={{ textDecoration: "none" }}
                >
                  <Image src={Img1} width="475px" />
                </Link>

                <p style={{ paddingBottom: "16px" }}>
                  The Tuli Research Centre for India Studies (T.R.I.S.), a
                  Charitable Society & Trust, is entrusted with the
                  responsibility of being the custodian of the vast archives and
                  libraries which one has obsessively built piece by piece since
                  1992.
                </p>
                <Link
                  style={{
                    color: "black",
                    fontSize: "16px",
                    fontWeight: "700",
                    textDecoration: "none",
                    border: "1px solid #e0e0e0",
                    padding: "10px",
                    marginTop: "10px",
                    borderRadius: "5px",
                  }}
                  to="/philosophical-framework/vision-september-2022"
                >
                  Know More
                </Link>
              </VerticalTimelineElement>
              <VerticalTimelineElement
                className="vertical-timeline-element--work oldvision"
                iconStyle={{
                  background: "rgb(233, 30, 99)",
                  color: "#fff",
                  fontSize: "13px",
                  fontWeight: "900",
                  textAlign: "center",
                  paddingTop: "10px",
                  paddingLeft: "5px",
                  paddingRight: "5px",
                }}
                icon={"Oct 2021"}
              >
                <Link
                  style={{ color: "black", textDecoration: "none" }}
                  to="/philosophical-framework/vision-october-2021"
                >
                  <Box>
                    <p>Introducing</p>
                    <Heading pb="3" fontSize={"lg"} fontWeight={"700"}>
                      OsianamaWorld.org
                    </Heading>

                    <Heading fontSize={"lg"} fontWeight={"500"}>
                      A Gift Long Overdue
                    </Heading>
                  </Box>
                  <Box>
                    <p>Dear Friends,</p>
                    <p style={{ paddingBottom: "16px" }}>
                      In the coming weeks my colleagues and I will be launching
                      a new web platform which represents a vision built over a
                      rollercoasting twenty-five years of research and
                      experience.
                    </p>
                    <Image
                      boxSize="auto"
                      w="100%"
                      objectPosition="center"
                      src={Img9}
                      alt=""
                    />
                    <p style={{ paddingBottom: "16px" }}>
                      OsianamaWorld.org will engage on an array of subjects on
                      India and her relationships with the world since the late
                      19th century.
                    </p>

                    <Link
                      style={{
                        color: "black",
                        fontSize: "16px",
                        fontWeight: "700",
                        textDecoration: "none",
                        border: "1px solid #e0e0e0",
                        padding: "10px",
                        marginTop: "10px",
                        borderRadius: "5px",
                      }}
                      to="/philosophical-framework/vision-october-2021"
                    >
                      Know More
                    </Link>
                  </Box>
                </Link>
              </VerticalTimelineElement>
            </VerticalTimeline>
          </Box>
          <Stack zIndex={9999999} right="0%" top="15%" position={"fixed"}>
            <Box bg="white">
              {showFilterBox && (
                <Box className="box-filter">
                  <Stack flexDirection={"row"} justifyContent={"space-between"}>
                    <HStack>
                      <SmallBold as="u" color={"black"}>
                        Clear All
                      </SmallBold>
                    </HStack>
                    <HStack className="mt-0">
                      <RiCloseCircleFill
                        size={"22px"}
                        cursor="pointer"
                        onClick={(e) => setShowFilterBox(false)}
                      />
                    </HStack>
                  </Stack>
                  <HStack alignItems={"left"} flexDirection={"column"}>
                    <SmallBold py="4">Search</SmallBold>
                    <InputGroup className="ml-0" pb="4">
                      <Input />
                      <InputRightElement>
                        <BiSearch color="green.500" />
                      </InputRightElement>
                    </InputGroup>
                  </HStack>
                  <Accordion allowToggle allowMultiple>
                    <AccordionItem>
                      <AccordionButton className="px-0">
                        <Box flex="1" textAlign="left">
                          <SmallBold>Month</SmallBold>
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>

                      <AccordionPanel pb={4} className="px-1">
                        <Small color="#035DA1">Content Awaited</Small>
                      </AccordionPanel>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionButton className="px-0">
                        <Box flex="1" textAlign="left">
                          <SmallBold>Year</SmallBold>
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>

                      <AccordionPanel pb={4} className="px-1">
                        <Small color="#035DA1">Content Awaited</Small>
                      </AccordionPanel>
                    </AccordionItem>
                  </Accordion>
                </Box>
              )}
            </Box>
          </Stack>
        </Container>
      </Stack>
    </Layout>
  );
}

export default PhilosophicalFramework;
