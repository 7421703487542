import {
  Box,
  Container,
  HStack,
  Stack,
  Text
} from "@chakra-ui/react";

//Core files
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

//Service files
import * as getArtworksCatalogues from "./../../../services/EventExhibition";
import { CopyPasteService } from "../../../services/CopyPaste";

//Component files
import { FullScreenImage } from "../Component/FullScreenImage";
import Grid from "../../../FunctionalComponents/SimpleGrid/Grid";
import ImageWithCitation2 from "../../../FunctionalComponents/SimpleGrid/ImageWithCitation2";
import { useParams } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import NoMoreToLoad from "../../../CommonComponents/NoMoreToLoad";

//Language files
import { common} from "../../../constants/constants";

const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;
const loaderImg = `${IMAGE_BASE_PATH}/ui/Chevron/loader.gif`

export default function ArtworksCatalogues() {
  const { alias } = useParams();
  const [showFilterBox, setShowFilterBox] = useState(false);
  const [FilterData, setFilterData] = useState([]);
  const [fullScreenImage, setFullScreenImage] = useState(false);
  const [clickedImageIndex, setClickedImageIndex] = useState(null);
  const [ImgData, setImgData] = useState(null);
  const [ page, setPage ] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const { t } = useTranslation("");
  const currentEndpoint = window.location.pathname;
  const parts = currentEndpoint.split("/");
  const masterlistType = parts[1];
  const initialLoadComplete = useRef(false);

  const showImages = (image, flag) => {
    if (image) {
      setFullScreenImage(flag);
      setImgData(image);
    } else {
      setFullScreenImage(flag);
      setImgData(null);
    }
  };

  const citationFactor = (data) => {
    const findItem = (items, key) => items.find(it => it.key === key) || {};
    const findItemValue = (item) => item.value || "";
    const findItemDisplay = (item) => item.display;
  
    return data.map((item, index) => {
      const fullImage = findItem(item, "direct_url_of_cover_image").value || "";
      const mediumArtworkItem = findItem(item, "medium_of_artwork");
      const medium_artwork = mediumArtworkItem.value ? mediumArtworkItem : "";    
      const sizeItem = findItem(item, "length_cm");
      const sizeDisplay = findItemDisplay(sizeItem);
      const lengthVal = findItemValue(sizeItem);
      const baseVal = findItemValue(findItem(item, "base_cm"));
      const sizeVal = `${lengthVal} x ${baseVal} cms`;
      const sizeData = { display: sizeDisplay, value: sizeVal };
      const titleItem = findItem(item, "title");
      const title_of_artwork = titleItem.value ? titleItem : "";
    
      return {
        image: fullImage,
        title: findItem(item, "creator_name").value || "",
        fullImg: fullImage,
        desc: findItem(item, "bibliographic_citation").value || "",
        content: findItem(item, "description").value || "",
        essayistData: [title_of_artwork, medium_artwork, sizeData],
        auctionData: [],
        auction_estimates: [],
      };
    });    
  };

  const getData = async () => {
    const result = await getArtworksCatalogues.artworksCataloguesData(
      masterlistType,
      alias,
      page
    );

    if (result && result?.data.length > 0) {
      const processedData = citationFactor(result?.data);
      setFilterData(processedData);
      if (!initialLoadComplete.current) {
        setPage((prevPage) => prevPage + 1);
        initialLoadComplete.current = true;
      }
    }
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, []);

  const fetchMoreData = async () => {
    try {
          const result = await getArtworksCatalogues.artworksCataloguesData(
            masterlistType,
            alias,
            page
          );

          if (result && result?.data) 
          {
            const processedData = citationFactor(result?.data);
            setFilterData((prevData) => [...prevData, ...processedData]);
            setPage(prevPage => prevPage + 1);

            if (FilterData.length >= result?.total) {
              setHasMore(false);
            }
          }
        }
        catch (error) 
        {
          console.error('Error fetching data:', error);
        }
  }

  const handleFilterClick = () => {
    setShowFilterBox(!showFilterBox);
  };

  const handleImageClick = (item, index) => {
    setClickedImageIndex(index);
    setFullScreenImage(true);
  };

  return (
    <Stack flexDirection={"row-reverse"} minH={"100vh"}>
      {!showFilterBox && <FilterButton marginRight={"-62px"} onClick={handleFilterClick} />}
      <Container
        maxW="auto"
        className="songs"
        px={{ base: "4", md: "0px" }}
        pb="8"
      >
        <Stack flexDirection={"row"} gap={4} justifyContent="center">
          {FilterData.length > 0 ? (
            <HStack
              onCopy={(e) => CopyPasteService(e, `${window.location.href}/0/1`)}
              marginRight={{ base: "0px", md: "30px" }}
            >
            <InfiniteScroll
              dataLength={FilterData.length}
              next={fetchMoreData}
              hasMore={hasMore}
              loader={<Box textAlign="center" marginLeft={"50%"}>
              <Stack marginTop={"20px"}>
                  <img src={loaderImg} width={"100px"} height={"100px"} alt="Loading" />
              </Stack>
            </Box>}
              endMessage={<NoMoreToLoad/>}
              >
              <Grid
                data={FilterData}
                columns={{ base: 1, md: 3, lg: 3, xl: 4 }}
                spacing={{ base: "5px", md: "24px" }}
                handleImageClick={handleImageClick}
                component={ImageWithCitation2}
              />
            </InfiniteScroll>
            </HStack>
          ) : (
            <>
              <HStack
                onCopy={(e) =>
                  CopyPasteService(e, `${window.location.href}/0/1`)
                }
                margin={"auto"}
              >
                <Text
                  py={"40"}
                  fontSize={"45px"}
                  fontWeight="700"
                  textAlign="center"
                >
                  {t(common.NO_DATA)}
                </Text>
              </HStack>
            </>
          )}
        </Stack>
      </Container>
      {fullScreenImage && (
        <FullScreenImage
          isOpen={fullScreenImage}
          onClose={() => showImages(null, false)}
          imageData={ImgData}
          data={FilterData}
          clickedImageIndex={clickedImageIndex}
          setClickedImageIndex={setClickedImageIndex}
          bannerFlag={false}
          title="ArtworksCatalogues"
        />
      )}
    </Stack>
  );
}

export const FilterButton = ({ onClick, marginRight, paddingTop }) => {
  // const { t } = useTranslation("");

  return (
    <>
      {/* <Stack
        position={"relative"}
        pos={"fixed"}
        marginTop={"100px"}
        marginRight={marginRight}
        paddingTop={paddingTop}
        zIndex={"99!important"}
        display={{ base: "none", md: "flex" }}
      >
        <Stack
          id="filterstickywbtn"
          marginRight={"0px!important"}
          onClick={onClick}
        >
          <Box
            display={"flex"}
            flexDirection={"column"}
            alignItems={"center"}
            gap="10px"
          >
            <Image transform={"rotate(360deg)"} src={Filter} />
            <Text
              as="button"
              transform={"rotate(90deg)"}
              fontSize={"14px"}
              fontWeight={"700"}
              marginTop={"4px"}
            >
              {t(explore.FILTER)}
            </Text>
          </Box>
        </Stack>
      </Stack> */}
    </>
  );
};
