import { Navigation, Pagination, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import {
  Box,
  Button,
  Container,
  HStack,
  Image,
  Stack,
  Text,
} from "@chakra-ui/react";

// Core files
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

// Service Files
import * as getInsightData from "../../services/Insight";

// Language Files
import { WebpageComingSoon } from "../../CommonComponents/WebpageComingSoon";
import Grid from "../../FunctionalComponents/SimpleGrid/Grid";
import PostWithCitation from "../../FunctionalComponents/SimpleGrid/PostWithCitation";
import { getImageURL } from "../../util/getImage";
// import { common } from "../../constants/constants";
// import { formatName } from "../../util/helper";
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;
const placeholderImg = `${IMAGE_BASE_PATH}/ui/DummyImage/blank.png`

function Insights({ masterlist, masterlistType, setPostsCount }) {
  const [insightsData, setInsightsData] = useState(true);
  const navigate = useNavigate();
  useEffect(() => {
    const getData = async () => {
      const result = await getInsightData.getInsightsByMasterlist(
        masterlistType,
        masterlist
      );
    
      if (result) {
        setInsightsData(citationFactor(result));
        setPostsCount(result?.length)
      }
    };
    getData();
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [masterlist, masterlistType]);

  const handleGrid = (item) => {
    navigate(`/${masterlistType}/${masterlist}/insights/${item?.alias}`)
  }

  const citationFactor = (data) => {
    return data.map((item) => {

      const firstImage = item?.insight_images_alias[0];
      const title = item?.insight_heading;
  
      return {
        image: firstImage,
        title: title,
        alias: item?.insight_alias,
        masterlist_type: item?.masterlist_type
      };
    });
  };

  return (
    <>
      {insightsData ? (
        <>
          <Container
            maxW="auto"
            pr="0px"
            pl="0px"
            marginTop={{ base: "100px", md: "100px" }}
          >
            <Stack width={"100%"} position={"relative"} id="postsSliderObject">
              <Swiper
                modules={[Navigation, Pagination, A11y]}
                slidesPerView={1}
                navigation
                height={"100%"}
                className="subslider"
              >
                {insightsData && insightsData.length > 0 &&
                  insightsData.map((insightItem, index) => (
                    <SwiperSlide key={index}>
                     <Box
                        width={{ base: "auto", md: "700px" }}
                        height={{ base: "auto", md: "570px" }}
                        display={"flex"}
                        justifyContent={"center"}
                        className="posthover"
                          id="DivForHoverItem"
                      >
                        <figure>
                        <Link to={`/${masterlistType}/${masterlist}/insights/${insightItem.alias}`}>
                          <Image
                            fallbackSrc={placeholderImg}
                            src={getImageURL(insightItem?.image)}
                            width={"100%"}
                            height={"100%"}
                            objectFit={"contain"}
                            alt=""
                          />
                             </Link>
                           <HStack alignContent={"center"}>
                            <Box
                              className="top-right"
                              zIndex={1}
                              pt="8"
                              width={"735px"}
                              height="max-content"
                              position={"absolute"}
                              right={"22%"}
                            >
                              <Stack
                                spacing={4}
                                gap={"0"}
                                className="btn-wrap-onmobile"
                                width={"380px"}
                                marginLeft={{base:"0px",md:"240"}}
                              >
                                <HStack>
                              <Text
                                className="title-on-slide"
                                fontSize={"20px"}
                                fontWeight={"700"}
                                teststyle="secondary"
                                textAlign={"left"}
                                id="HiddenText"
                                color="white"
                              >
                                {insightItem?.title}
                              </Text>
                              </HStack>
                              <HStack gap={"3"} flexWrap={"wrap"}>
                              {insightItem?.masterlist_type &&
                                  insightItem?.masterlist_type.map((it, index) => {
                                    // const aliasValue = postDetailsData?.alias || "";
                                    const masterlistAlias =
                                      it?.alias || "";
                                    return (
                                      <Button
                                        className={`keyword-btn secondary-color`}
                                        marginInlineStart={"0rem!important"}
                                        id="HiddenText"
                                        onClick={() =>
                                          navigate(`/${it.type}/${masterlistAlias}`)
                                        }
                                        width={"max-content"}
                                      >
                                        {it.name}
                                      </Button>
                                    );
                                  })}
                                  </HStack>
                              </Stack>
                            </Box>
                          </HStack>
                        </figure>
                      </Box>
                    </SwiperSlide>
                  ))}
              </Swiper>
            </Stack>
          </Container>
          <Container maxW="auto" marginTop={{ base: "0px", md: "40px" }}>
            <Stack className="exploretabs">
              {
                insightsData && insightsData?.length > 0 &&
                <Grid
                  data={insightsData}
                  columns={[1, null, 3]}
                  spacing="30px"
                  handleGrid={handleGrid}
                  component={PostWithCitation}
                />

              }
            </Stack>
          </Container>
        </>
      ) : (
        <WebpageComingSoon />
      )}
    </>
  );
}
export default Insights;
