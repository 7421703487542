import {
  Container,
  HStack,
  Stack,
} from "@chakra-ui/react";

//Core files
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

//Service file
import * as getMonumentsData from "../../../../services/Location";
import * as getLocationData from "../../../../services/Location";


//Component files
import { FilterButton } from "../../../../CommonComponents/FilterButton";

//Langugae file
import { explore } from "../../../../constants/constants";
import Grid from "../../../../FunctionalComponents/SimpleGrid/Grid";
import ImageWithCitation4 from "../../../../FunctionalComponents/SimpleGrid/ImageWithCitation4";
import { FilterBox } from "../../../../FunctionalComponents/Filter/FilterBox";

function GridView({ aliasAPI }) {
  const [showFilterBox, setShowFilterBox] = useState(false);
  const [gridViewData, setGridViewData] = useState([]);
  const currentEndpoint = window.location.pathname;
  const parts = currentEndpoint.split("/");
  const masterlistType = parts[1];
  const navigate = useNavigate();
  useEffect(() => {
    const getData = async () => {
      const result = await getMonumentsData.getMonuments("tableName", aliasAPI);
      if (result) {
        setGridViewData(result[0]);
      }
    };
    getData();
    window.scrollTo(0, 0);
  }, [aliasAPI]);
  const handleFilterClick = () => {
    setShowFilterBox(!showFilterBox);
  };
  const handleImage = (it) => {
    navigate(`/${masterlistType}/${aliasAPI}/body-of-work/${it}`)
  }

  const [filteroptionData, setFilteroptionsData] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [filterCount, setfilterCount] = useState(0);
  const [filterOptions, setFilterOptions] = useState({
    architect_style: [],
    architect_material: [],
    year: [],
    comissioned_by: [],
  });
  const [apply, setApply] = useState(false);
  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleCheckboxChange = (value, type) => {
    // Determine the array based on the type
    let selectedArray = [];
    switch (value) {
      case "architect_style":
        selectedArray = filterOptions.architect_style;
        break;
      case "architect_material":
        selectedArray = filterOptions.architect_material;
        break;
      case "year":
        selectedArray = filterOptions.year;
        break;
      case "comissioned_by":
        selectedArray = filterOptions.comissioned_by;
        break;
      default:
        break;
    }
    if (selectedArray.includes(type)) {
      selectedArray = selectedArray.filter((checkbox) => checkbox !== type);
    } else {
      selectedArray = [...selectedArray, type];
    }
    switch (value) {
      case "architect_style":
        setFilterOptions({
          ...filterOptions,
          architect_style: selectedArray,
        });
        break;
      case "architect_material":
        setFilterOptions({
          ...filterOptions,
          architect_material: selectedArray,
        });
        break;
      case "year":
        setFilterOptions({
          ...filterOptions,
          year: selectedArray,
        });
        break;
      case "comissioned_by":
        setFilterOptions({
          ...filterOptions,
          comissioned_by: selectedArray,
        });
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    const getFilterOptionsData = async () => {
      const result = await getLocationData.getLocationFilterOptions(
        "tableName",
        aliasAPI
      );
      if (result) {
        const dataIntro = result;
        setFilteroptionsData(dataIntro);
      }
    };

    getFilterOptionsData();
  }, [aliasAPI]);

  useEffect(() => {
    setfilterCount(0);
    for (const key in filterOptions) {
      if (filterOptions[key].length > 0) {
        setfilterCount((prev) => prev + 1);
      }
    }
  }, [filterOptions]);
  const handleReset=() => {
    setFilterOptions({
      architect_style: [],
      architect_material: [],
      year: [],
      comissioned_by: [],
    });
    setSearchTerm("");
  }
  return (
    <Stack flexDirection={"row-reverse"} paddingTop={"4"}>
      {!showFilterBox && <FilterButton marginRight={"-25px"} onClick={handleFilterClick} />}
      <Container maxW="auto" py="4" px="8">
        <Stack py="4" flexDirection={"row"} gap={4} justifyContent="center">
          <HStack>
            {gridViewData?.value?.length > 0 &&
              <Grid
                data={gridViewData?.value}
                handleImage={handleImage}
                columns={{ base: 2, md: 3, lg: 3, xl: 4 }}
                spacing="40px"
                component={ImageWithCitation4}
              />
            }
          </HStack>
          <HStack className="mt-0" alignItems={"flex-start"}>
            {showFilterBox && (
               <FilterBox
               setShowFilterBox={setShowFilterBox}
               setApply={setApply}
               explore={explore}
               handleCheckboxChange={handleCheckboxChange}
               handleSearch={handleSearch}
               filterOptions={filterOptions}
               filteroptionData={filteroptionData}
               setFilterOptions={setFilterOptions}
               setSearchTerm={setSearchTerm}
               filterCount={filterCount}
               searchTerm={searchTerm}
               handleReset={handleReset}
               className="common-box-filter"
               marginRight={"-59px!important"}
               apply={apply}
             />
            )}
          </HStack>
        </Stack>
      </Container>
    </Stack>
  );
}

export default GridView;