import {
  Box,
  Container,
  Drawer,
  DrawerContent,
  HStack,
  Image,
  Stack,
  Text,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { IoMdCalendar } from "@react-icons/all-files/io/IoMdCalendar";
import { RiLayoutGridFill } from "@react-icons/all-files/ri/RiLayoutGridFill";
import { MdMap } from "@react-icons/all-files/md/MdMap";
import { getOsianamaImageData } from "../services/OsianamaCircleService";
import { common } from "../constants/constants";
import LeftVmenu from "../FunctionalComponents/LeftVmenu";
import { MdHomeFilled } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import Grid from "../FunctionalComponents/SimpleGrid/Grid";
import ImageTextGrid from "../FunctionalComponents/SimpleGrid/ImageTextGrid";

//S3 Bucket osw-dev-images
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;

const member = `${IMAGE_BASE_PATH}/ui/Chevron/member.png`
const circle = `${IMAGE_BASE_PATH}/ui/Chevron/sidecircle.svg`
const osw = `${IMAGE_BASE_PATH}/ui/OsianamaWorld/osw.png`
const Next = `${IMAGE_BASE_PATH}/ui/OsianamaWorld/next.png`

export default function TuliResearchCentre() {
  const [osianamaImageData, setOsianamaImageData] = useState([]);
  const [showLabel, setShowLabel] = React.useState(false);

  React.useEffect(() => {
    const getData = async () => {
      const result = await getOsianamaImageData();
      if (result?.length > 0) {
        setOsianamaImageData(result);
        setShowLabel(false);
      }
      if (result?.length === 0) {
        setShowLabel(true);
      }
    };
    getData();
  }, []);

  return (
    <SidebarWithHeader>
      {showLabel ? (
        <Text py={"40"} fontSize={"45px"} fontWeight="700" textAlign="center">
          {common.WEBPAGE_COMING_SOON}
        </Text>
      ) : (
        <>
          <Stack width={"auto"} id="content" position={"relative"}>
            <Image src={osw} className="ribbon"></Image>
            <Image
              display={{ base: "none", md: "content" }}
              src={Next}
              className="ribbon1"
            ></Image>
            <Container maxW={"auto"} paddingX={"10"}>
              <Stack flexDirection={"column"} className="OsianamaWorld" py="5">
                <HStack>
                  <Text
                    as="h1"
                    fontSize={{ base: "42px", md: "50px" }}
                    zIndex={1}
                    className="OsianamaWorld-title"
                    fontWeight="700"
                    lineHeight={"57px"}
                  >
                    Tuli Research Centre for India Studies
                  </Text>
                </HStack>
                <HStack>
                  <Text
                    fontSize={"20px"}
                    zIndex={1}
                    fontWeight="700"
                    lineHeight={"23px"}
                  >
                    Welcome Neville! Pick at least 2 Research Categories to
                    begin…
                  </Text>
                </HStack>
                <HStack paddingTop={"8"} zIndex={1}>
                  <Grid
                    data={osianamaImageData}
                    columns={[2, null, 5]}
                    textStyle={"primary"}
                    fontWeight="500"
                    fontSize="16px"
                    height="auto"
                    spacing="20px"
                    component={ImageTextGrid} />
                </HStack>
              </Stack>
            </Container>
          </Stack>
        </>
      )}
    </SidebarWithHeader>
  );
}
const SidebarWithHeader = ({ children }) => {
  const { isOpen, onClose } = useDisclosure();
  return (
    <Box minH="100vh" bg={useColorModeValue("gray.100", "gray.900")}>
      <SidebarContent
        onClose={() => onClose}
        display={{ base: "none", md: "block" }}
      />
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
      >
        <DrawerContent>
          <SidebarContent onClose={onClose} />
        </DrawerContent>
      </Drawer>

      <Box ml={{ base: 0, md: 14 }} bg="white">
        {children}
      </Box>
    </Box>
  );
};
const SidebarContent = ({ onClose, display, ...rest }) => {
  const [error, setError] = useState(false);
  const toggleModal = () => {
    setError(!error);
  };
  const LeftVmenuData = [
    {
      link: "/",
      id: "homeicon",
      icon: MdHomeFilled,
    },
    {
      link: "/explore",
      id: "exploreicon",
      icon: RiLayoutGridFill,
    },
    {
      link: "/calendar",
      id: "calendaricon",
      icon: IoMdCalendar,
    },
    {
      link: "/map",
      id: "mapicon",
      icon: MdMap,
    },
  ];
  const navigate = useNavigate();
  const handleNavigation = () => {
    navigate(-1);
  };
  return (
    <LeftVmenu
      handleNavigation={handleNavigation}
      backArrow={true}
      display={display}
      onClose={onClose}
      toggleModal={toggleModal}
      member={member}
      circle={circle}
      LeftVmenuData={LeftVmenuData}
      subscribe={true}
    />
  );
};
