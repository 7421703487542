//Core file
import React from 'react'

//Component file
import { WebpageComingSoon } from '../../CommonComponents/WebpageComingSoon'

export default function Post() {
  return (
   <>
   <WebpageComingSoon/>
   </>
  )
}
