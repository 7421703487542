import { Stack, Button } from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";

export default function Tags({
    tags,
    onHover,
    alias,
}) {
    return (
        onHover ?
            <Stack
                spacing={4}
                direction="row"
                className="btn-wrap-onmobile"
                gap={"3"}
                alignItems="flex-start"
                marginTop={"5px"}
                paddingY={"4"}
                width={"400px"}
            >
                {tags && tags.length > 0 && (
                <>
                    {tags?.map((mTag, index) => {
                    const linkTo = mTag ? `${mTag?.type}/${mTag?.alias}` : "";
                    const buttonText = mTag?.name;

                    // Skip rendering if linkTo or buttonText is invalid
                    if (!buttonText) {
                        return null;
                    }

                    return (
                        <Link key={index} className="ml-0" to={linkTo}>
                        <Button
                            className="btn-on-slide"
                            height={"40px"}
                            id="HiddenText"
                            color={"black"}
                            size={"md"}
                            bg="#ffffff"
                            fontSize={"16px"}
                            fontWeight={"400"}
                        >
                            {buttonText}
                        </Button>
                        </Link>
                    );
                    })}
                </>
                )}
            </Stack>
            :
            <Stack spacing={2} direction="row" align="left">
                {tags && tags.length > 0 && (
                    <>
                      {tags?.map((mTag, index) => {
                        const linkTo = mTag ? `${mTag?.type}/${mTag?.alias}/posts/${alias}` : "";
                        const buttonText = mTag?.name;
                  
                        // Skip rendering if linkTo or buttonText is invalid
                        if (!buttonText) {
                          return null;
                        }
                  
                        return (
                          <Link key={index} to={linkTo}>
                            <Button
                              height={"34px"}
                              className="btn-on-slide"
                              color={"black"}
                              size={"md"}
                              bg="#FFFFFF"
                              fontSize={"14px"}
                              fontWeight={"700"}
                              px="4"
                              py="1"
                              _hover={{bg:"#ffffff"}}
                            >
                            {buttonText}
                            </Button>
                          </Link>
                        );
                      })}
                    </>
                  )}
                </Stack>
    );
}