import axios from "axios";
import { silsila_vertical_layout_data, skk_vertical_layout_data } from "../MockData/BoxOffice";

//S3 Bucket osw-dev-images
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;

const screening = `${IMAGE_BASE_PATH}/ui/Film/ShatranjKeKhilari/EconomicsOfCinema/Screening/1.png`
const screening1 = `${IMAGE_BASE_PATH}/ui/Film/ShatranjKeKhilari/EconomicsOfCinema/Screening/2.png`
const Cast1 = `${IMAGE_BASE_PATH}/ui/Film/ShatranjKeKhilari/BodyOfWork/CreditsCast/CastView/1.png`
const Cast2 = `${IMAGE_BASE_PATH}/ui/Film/ShatranjKeKhilari/BodyOfWork/CreditsCast/CastView/2.png`
const Cast3 = `${IMAGE_BASE_PATH}/ui/Film/ShatranjKeKhilari/BodyOfWork/CreditsCast/CastView/3.png`
const Cast4 = `${IMAGE_BASE_PATH}/ui/Film/ShatranjKeKhilari/BodyOfWork/CreditsCast/CastView/4.png`
const Cast5 = `${IMAGE_BASE_PATH}/ui/Film/ShatranjKeKhilari/BodyOfWork/CreditsCast/CastView/5.png`
const Cast6 = `${IMAGE_BASE_PATH}/ui/Film/ShatranjKeKhilari/BodyOfWork/CreditsCast/CastView/6.png`
const Cast7 = `${IMAGE_BASE_PATH}/ui/Film/ShatranjKeKhilari/BodyOfWork/CreditsCast/CastView/7.png`
const Cast8 = `${IMAGE_BASE_PATH}/ui/Film/ShatranjKeKhilari/BodyOfWork/CreditsCast/CastView/8.png`
const sign1 = `${IMAGE_BASE_PATH}/ui/PersonalityPolymath/SatyajitRay/EconomicsOfCinema/Screening/signatures/PatherPanchalisign.png`
const sign2 = `${IMAGE_BASE_PATH}/ui/PersonalityPolymath/SatyajitRay/EconomicsOfCinema/Screening/signatures/sign2.png`

const baseUrl = `${process.env.REACT_APP_GRAPHQL_URL}`;

export const getScreeningByMasterList = async (tableName, masterlist) => {
  const graphqlQuery = {
    operationName: "MyQuery",
    query:
      `query MyQuery($where: ScreeningWhere, $options: ScreeningOptions) {` +
      tableName +
      `(where: $where, options: $options) {
            id
            event_name
            type
            sortable_date
            opening_date
            closing_date
            masterlist
            alias
            organiser
            sl_no
            venue_location
            wnn
            screeningImages {
              wnn
              accession_number
              alias
              name_of_publication
              volume_number
              publication_year
              sortable_year_of_publication
              artist_name
              date_of_artwork
              medium_of_artwork
              revised_medium
              id
              image
              height_cm
              width_cm
              title
              direct_url_of_preview_image
              medium
            }
        }
      }`,
    variables: {
      where: {
        masterlist_IN: masterlist,
      },
      options: {
        sort: [
          {
            sortable_date: "ASC",
          },
        ],
      },
    },
  };

  const result = await axios.post(baseUrl, graphqlQuery);
  if (result?.data?.data?.screenings) {
    return result?.data?.data?.screenings;
  } else {
    return [];
  }
};

export const getTypeOfScreeningFilterOptions = async (
  tableName,
  masterlist
) => {
  const graphqlQuery = {
    operationName: "MyQuery",
    query:
      `query MyQuery($where: ScreeningWhere) {
        ` +
      tableName +
      `(where: $where) {
            type 
        }
        }
    `,
    variables: { masterlist: masterlist },
  };

  const result = await axios.post(baseUrl, graphqlQuery);
  if (result?.data?.data?.screenings) {
    return result?.data?.data?.screenings;
  } else {
    return [];
  }
};

export const getSortableDateFilterOptions = async (tableName, masterlist) => {
  const graphqlQuery = {
    operationName: "MyQuery",
    query:
      `query Screenings($where: ScreeningWhere) {
        ` +
      tableName +
      `(where: $where) {
            sortable_date 
        }
        }
    `,
    variables: {
      where: {
        masterlist: masterlist,
      },
    },
  };

  const result = await axios.post(baseUrl, graphqlQuery);
  if (result?.data?.data?.screenings) {
    return result?.data?.data?.screenings;
  } else {
    return [];
  }
};

const ScreeningBudgetInformationMockupData = [
  {
    budgetAmount: "12,34,567",
    currency: "rupee",
    currency_symbol: "₹",
    domestic: {
      distributor_name: "ABC Films",
      number_of_theatres_screened_at: 35,
      opening_date: "1997-10-03",
      overall_collection: "1,00,34,567",
    },
    international: {
      distributor_name: "ABC Films",
      number_of_theatres_screened_at: 35,
      opening_date: "1997-10-03",
      overall_collection: "20,00,000",
    },
    worldwide: {
      number_of_theatres_screened_at: 35,
      overall_collection: "1,20,34,567",
    },
    images: {
      screenImages: [screening, screening1],
    },
  },
];

export const getScreeningBudgetInformation = async (
  tableName,
  masterlist
) => {
  const graphqlQuery = {
    operationName: "MyQuery",
    query:
      `query MyQuery(masterlist: String!) {
            ` +
      tableName +
      `(where: {masterlist: {_eq: masterlist}}, distinct_on: sortable_date) {
                sortable_date 
            }
        }`,
    variables: { masterlist: masterlist },
  };

  const result = await axios.post(baseUrl, graphqlQuery);
  if (result?.data?.data?.screenings) {
    // return result?.data?.data?.screenings;
    return ScreeningBudgetInformationMockupData;
  } else {
    return [];
  }
};

export const getVerticalLayoutData = async (alias) => {
  // return verticalLayouData;
  const verticalLayouData = {
    "shatranj-ke-khilari-1977": skk_vertical_layout_data,
    "mughal-e-azam": skk_vertical_layout_data,
    "silsila":silsila_vertical_layout_data
  };
  const VerticalLayouData = verticalLayouData[alias] || skk_vertical_layout_data;
  return VerticalLayouData;
};

export const getFilmPersonalityData = async () => {
  const data = [
    {
      personalities: [
        {
          type: "film-producer",
          personality_alias: "Suresh Jindal",
        },
        {
          type: "film-director",
          personality_alias: "Satyajit Ray",
        },
        {
          type: "script",
          personality_alias: "Satyajit Ray",
        },
        {
          type: "screenplay",
          personality_alias: "Satyajit Ray",
        },
        {
          type: "story",
          personality_alias: "Premchand",
        },
        {
          type: "dialogue",
          personality_alias: "Satyajit Ray, Shama Zaidi, Javed Siddiqui",
        },
        {
          type: "music-director",
          personality_alias: "Satyajit Ray, Birju Maharaj",
        },
        {
          type: "editing",
          personality_alias: "Dulal Dutta",
        },
        {
          type: "cinematography",
          personality_alias: "Soumendu Roy",
        },
        {
          type: "art-director",
          personality_alias: "Bansi Chandragupta",
        },
        {
          type: "still-photographer",
          personality_alias:
            "Nemai Ghosh, Sandip Ray, Sumantra Ghoshal, Pablo Bartholomew",
        },
        {
          type: "poster-designer",
          personality_alias: "Satyajit Ray",
        },
        {
          type: "narrator",
          personality_alias: "Amitabh Bachchan",
        },
        {
          type: "costume-designer",
          personality_alias: "Shama Zaidi, Satyajit Ray",
        },
        {
          type: "choreography",
          personality_alias: "Saswati Sen, Birju Maharaj",
        },
        {
          type: "banner",
          personality_alias: "Devki Chitra",
        },
        {
          type: "lead-cast",
          personality_alias:
            "Sanjeev Kumar, Saeed Jaffrey, Shabana Azmi, Farida Jalal,Veena, David Abraham, Victor Bannerji",
        },
        {
          type: "camera",
          personality_alias: "Soumendu Roy",
        },
        {
          type: "assistants",
          personality_alias:
            "Purnendu Bose, Anil Ghosh, Dilip Bannerji, Arun Chakravarty, Dulal Das,Kashinath Bose",
        },
        {
          type: "art-direction",
          personality_alias: "Bansi Chandragupta",
        },
        {
          type: "associate",
          personality_alias: "Ashok Bose, Joseph St. Anne",
        },
        {
          type: "costumes",
          personality_alias: "Shama Zaidi",
        },
        {
          type: "associates",
          personality_alias: "Rabab & Sitara Jaffri, Saba Zaidi",
        },
        {
          type: "sound",
          personality_alias: "Narinder Singh",
        },
        {
          type: "sound",
          personality_alias: "Narinder Singh",
        },
        {
          type: "editing",
          personality_alias: "Dulal Dutta",
        },
        {
          type: "make-up",
          personality_alias: "Ananta Das",
        },
        {
          type: "assistant",
          personality_alias: "Pradip bag, Jagdish Shetty, Pradeep Powale",
        },
        {
          type: "wardrobe",
          personality_alias: "Haru Das",
        },
        {
          type: "backdrop",
          personality_alias: "Kabi Dasgupta",
        },
        {
          type: "assistant-directors",
          personality_alias: "Ramesh Sen, Santi Kumar Chatterji, Sandip Ray",
        },
        {
          type: "special-assistant",
          personality_alias: "Javed Siddiqi",
        },
        {
          type: "production-executive",
          personality_alias: "Anil Chowdhury",
        },
        {
          type: "dances",
          personality_alias:
            "Saswati Sen. Gitanjali Kathak Kalakendra ballet Troupe, New Delhi",
        },
        {
          type: "dances-composed-and-choreographed-by",
          personality_alias:
            "Saswati Sen. Gitanjali Kathak Kalakendra ballet Troupe, New Delhi",
        },
        { type: "re-recording", personality_alias: "Mangesh Desai" },
        { type: "music-recording", personality_alias: "Samir Majumdar" },
        {
          type: "dialogue",
          personality_alias: "Satyajit Ray, Shama Zaidi, Javed Siddiqi",
        },
        { type: "narration", personality_alias: "Amitabh Bachchan" },
        { type: "producer", personality_alias: "Suresh Jindal" },
        {
          type: "screenplay-music-and-direction",
          personality_alias: "Satyajit Ray",
        },
        { type: "production-manager", personality_alias: "Bhanu Ghosh" },
        { type: "location-management", personality_alias: "J. N. Srivastava" },
        {
          type: "production-assistants",
          personality_alias: "Bolai Addy. Trailokya Das",
        },
        {
          type: "studio",
          personality_alias:
            "Indrapuri Studios, Calcutta; Rajkamala Kalamandir, Bombay",
        },
        {
          type: "eastmancolor-by",
          personality_alias: "Gemini Colour Laboratory, Madras",
        },
        {
          type: "equipment",
          personality_alias:
            "Cinequip, N. C. Cine Service, Shree Krishna Films. Uma Deep Enterprises",
        },
        {
          type: "office-management",
          personality_alias: "N. B. Sukhwani, Ana Maria de Souza",
        },
        { type: "animation", personality_alias: "Ram Mohan" },
        { type: "graphic-design", personality_alias: "Zehra Tyabji" },
        { type: "publicity-and-Liasion", personality_alias: "Uma da Cunhe" },
        {
          type: "stills",
          personality_alias:
            "Nemai Ghosh, Sandip Ray, Sumatra Ghoshal, Pablo Bartholomew",
        },
        {
          type: "songs",
          personality_alias: "Reba Muhuri, Birju Maharaj. Calcutta Youth Choir",
        },
      ],
    },
  ];
  return data;
};

export const getCastImageData = () => {
  const data = [
    {
      id: 1,
      image: Cast1,
      title: "Sanjeev Kumar",
      subTitle: "as Mirza Sajjad Ali",
      link: true,
    },
    {
      id: 2,
      image: Cast2,
      title: "Shabana Azmi",
      subTitle: "as Khurshid, Mirza's wife",
    },
    {
      id: 3,
      image: Cast3,
      title: "Saeed Jaffrey",
      subTitle: "as Mir Roshan Ali",
    },
    {
      id: 4,
      image: Cast4,
      title: "Farida Jalal",
      subTitle: "as Nafeesa, Mir's wife",
    },
    {
      id: 5,
      image: Cast5,
      title: "Amjad Khan",
      subTitle: "as Wajid Ali Shah, Nawab(king)",
    },
    {
      id: 6,
      image: Cast6,
      title: "Victor Bannerjee",
      subTitle: "as Ali Naqi Khan, the prime minister",
    },
    {
      id: 7,
      image: Cast7,
      title: "Sir. Richard Attenborough",
      subTitle: "as General Outram",
    },
    {
      id: 8,
      image: Cast8,
      title: "Tom Alter",
      subTitle: "as Captain Waston",
    },
    // {
    //   id: 9,
    //   image: Cast9,
    //   title: "Farooque Shaikh",
    //   subTitle: "as Aqueel",
    // },
    // {
    //   id: 10,
    //   image: Cast10,
    //   title: "Barry John",
    //   subTitle: "as Doctor Joseph Fayrer",
    // },
    // {
    //   id: 11,
    //   image: Cast11,
    //   title: "Kamu Mukherjee",
    //   subTitle: "as Bookies",
    // },
    // {
    //   id: 11,
    //   image: Cast12,
    //   title: "Agha",
    //   subTitle: "as Son of dying lawyer",
    // },
  ];
  return data;
};

export const getScreeningData = () => {
  const data = [
    {
      id: 1,
      Title_of_event: "Pather Panchali; MoMA | Premieres | 3 May 1955",
      year: 1955,
      imgUrl: sign1,
    },
    {
      id: 2,
      Title_of_event:
        "Pather Panchali; Indian Premiere at Ordinance Club arranged by Advertising Club, Calcutta | Premieres",
      year: 1955,
    },
    {
      id: 3,
      Title_of_event: "Cannes Film Festival: Pather Panchali | Film Festivals",
      year: 1956,
    },
    {
      id: 4,
      Title_of_event:
        "9th Edinburgh International Film Festival: Pather Panchali | Film Festivals",
      year: 1956,
    },
    {
      id: 5,
      Title_of_event: "Rome Film Festival: Pather Panchali | Film Festivals",
      year: 1956,
    },
    {
      id: 6,
      Title_of_event: "Manila Fim Festival: Pather Panchali | Film Festivals",
      year: 1956,
    },
    {
      id: 7,
      Title_of_event:
        "Stratford Film Festival: Pather Panchali | Film Festivals",
      year: 1957,
    },
    {
      id: 8,
      Title_of_event:
        "Vancouver International Film Festival: Pather Panchali | Film Festivals",
      year: 1957,
    },
    {
      id: 9,
      Title_of_event:
        "7th Berlin International Film Festival: Pather Panchali | Film Festivals",
      year: 1957,
    },
    {
      id: 10,
      Title_of_event:
        "1st San Francisco International Film Festival: Pather Panchali | Film Festivals",
      year: 1957,
    },
    {
      id: 11,
      Title_of_event:
        "18th Venice International Film Festival: Aparajito | Film Festivals",
      year: 1958,
      imgUrl: sign2,
    },
    {
      id: 12,
      Title_of_event: "USA Film Festival: Aparajito | Film Festivals",
      year: 1958,
    },
    {
      id: 13,
      Title_of_event: "Cannes Film Festival: Parash Pathar | Film Festivals",
      year: 1958,
    },
    {
      id: 14,
      Title_of_event:
        "2nd San Francisco International Film Festival: Aparajito | Film Festivals",
      year: 1958,
    },
    {
      id: 15,
      Title_of_event:
        "Pather Panchali; New York Premiere | Premieres | April 1959",
      year: 1959,
    },
    {
      id: 16,
      Title_of_event:
        "1st Moscow International Film Festival: Jalsagar | Film Festivals",
      year: 1959,
    },
    {
      id: 17,
      Title_of_event:
        "New York Film Festival: Pather Panchali | Film Festivals",
      year: 1959,
    },
    {
      id: 18,
      Title_of_event:
        "Jalsaghar, Devi; United States premiere as a part of Homage to Satyajit Ray; University of California at Berkely by Albert Johnson | Premieres",
      year: 1960,
    },
    {
      id: 19,
      Title_of_event:
        "10th Berlin International Film Festival: Aparajito | Film Festivals",
      year: 1960,
    },
    {
      id: 20,
      Title_of_event:
        "7th BFI London Film Festival: Apur Sansar | Film Festivals",
      year: 1960,
    },
    {
      id: 21,
      Title_of_event:
        "Rabindranath Tagore documentary premiere, Delhi | Premieres | 5 May 1961",
      year: 1961,
    },
    {
      id: 22,
      Title_of_event:
        "Retrospective; Toronto International Film Festival | Retrospectives",
      year: 2019,
    },
    {
      id: 23,
      Title_of_event:
        "Satyajit Ray Retrospective; Rome Film Fest | Retrospectives | 15/10/2020 - 25/10/2020",
      year: 2020,
    },
    {
      id: 24,
      Title_of_event: "Retrospective of Satyajit Ray IFFI | Retrospectives",
      year: 2021,
    },
  ];
  return data;
};



export const getFilterOptions = () => {
  const FilterOptions = {
    screeningTypeFilter: [
      "Movie Screenings",
      "Retrospective",
      "Premieres",
      "Film Festival",
    ],
    yearFilter: [
      "1955",
      "1956",
      "1957",
      "1958",
      "1959",
      "1960",
      "1961",
      "2019",
      "2020",
      "2021",
    ],
  };
  return FilterOptions;
};
