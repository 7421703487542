import {
  Box,
  Stack,
  Container,
  HStack,
} from "@chakra-ui/react";

//Core file
import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

//Language file
import { monument } from "../../constants/constants";
import TabMain from "../../FunctionalComponents/TabComponents/TabMain";
import HistoricalBackground from "./BodyOfWork/HistoricalBackground";
import Architecture from "./BodyOfWork/Architecture";
import CulturalSignificance from "./BodyOfWork/CulturalSignificance";
import Large from "../../Theme/Typography/Large";

export const BodyOfWorkBody = ({
winnerName,
setReadmoreNavigation,
readmoreNavigation,
hidden,
setHidden,
tableName,
name,
}) => {
const navigate = useNavigate();
let { tabIndex } = useParams();
const { alias } = useParams();
const aliasAPI = alias;
const currentEndpoint = window.location.pathname;
const parts = currentEndpoint.split("/");
const masterlistType = parts[1];
const { t } = useTranslation("");
  useEffect(() => {
    if (tabIndex) {
      navigate(`/${masterlistType}/${aliasAPI}/body-of-work`, { replace: true });
    }
  }, [tabIndex, navigate,aliasAPI,masterlistType]);

  const [tabPanelData, setTabPanelData] = useState([]);

  function formatData() {
    return [
      {
        name: `${t(monument.HISTORICAL_BACKGROUND)}`,
        component:<HistoricalBackground/>,
      },
      {
        name: `${t(monument.ARCHITECTURE)}`,
        component:<Architecture/>,
      },
      {
        name: `${t(monument.CULTURAL_SIGNIFICANCE)}`,
        component:<CulturalSignificance/>,
      },
    ];
  }
  useEffect(() => {
    if (winnerName) {
      setTabPanelData(formatData());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [winnerName]);


  return (
    <Stack width={"100%"}>
        <Container
        maxW={"auto"}
        pb="4"
        paddingX={{ base: "4", md: "62px" }}
        >
          <Stack>
            <Box
              position={"fixed"}
              zIndex={999}
              bg="white"
              width={{ base: "100%", md: "1080px" }}
            >
              <HStack
                paddingTop={{ base: "20px", md: "25px" }}
                paddingBottom={{ base: "4", md: "25px" }}
              >
                {/* Show Masterlist title here */}
               <Large textStyle="tertiary" fontWeight="800"></Large>
              </HStack>
            </Box>
            <HStack width={{ base: "100%", md: "1080px" }}>
              {tabPanelData && tabPanelData.length > 0 && (
                <TabMain
                  data={tabPanelData}
                  pos={"fixed"}
                 gap="65px" width="1080px"
                  tabTop={{ base: "20px", md: "80px" }}
                  panelTop={{ base: "65px", sm: "85px", md: "130px" }}
                />
              )}
            </HStack>
          </Stack>
        </Container>
    </Stack>
  );
};

