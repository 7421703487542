import React, { useEffect, useState } from "react";
import { Map } from "google-maps-react";
import { GoogleApiWrapper } from "google-maps-react";
import { InfoWindow } from "google-maps-react";
import { Marker } from "google-maps-react";
import {
  Box,
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  HStack,
  Image,
  Stack,
} from "@chakra-ui/react";
import Small from "../../../components/layouts/Typography/Small";
import Large from "../../../components/layouts/Typography/Large";
import H2 from "../../../components/layouts/Typography/H2";
import { AiOutlineRight } from "@react-icons/all-files/ai/AiOutlineRight";
import { Link } from "react-router-dom";
import { WebpageComingSoon } from "../../../CommonComponents/WebpageComingSoon";

//S3 Bucket osw-dev-images
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;

const Img = `${IMAGE_BASE_PATH}/ui/Location/Lucknow/Introduction/DrawerImage/1.jpg`

const mapStyles = {
  width: "100%",
  height: "100vh",
  // webkitFilter: "grayscale(100%)",
  color: "white",
};

export function MapContainer({ google ,setShowAccordian}) {
  const [isOpen, setIsOpen] = React.useState(false);
  const [zoom, setZoom] = useState(4)
  const [zoomPositions, setZoomPositions] = useState({
    lat: 24.4053008,
    lng: 80.0333996,
  })
  const [showMonument, setShowMonument] = useState(false)  

  const onMarkerClick = (props, marker, e) => {
    setIsOpen(true);
  };
  const markerLabel =
    "Click on the map or drag the marker to select location where the incident occurred";

  const [dummyData, setDummyData] = useState(false);
  const dummyDataEnabled = process.env.REACT_APP_DUMMY_DATA || "";
  useEffect(() => {
    if (dummyDataEnabled.toLowerCase() === "yes") {
      setDummyData(true);
    } else {
      setDummyData(false);
    }
  }, [dummyDataEnabled]);

  return (
    <Stack>
      <Box position={""}>
        <Box
          display={{ base: "none", md: "flex" }}
          position={"fixed"}
          bottom={"5%"}
          zIndex={1}
        >
          {dummyData ? (
            <></>
          ) : (
           <WebpageComingSoon />
          )}
        </Box>
        <MapDrawer isOpen={isOpen} setIsOpen={setIsOpen} />
        
        {/*Need to update mock update data from service file */}
        
        <Map
          google={google}
          zoom={zoom}
          style={mapStyles}
          initialCenter={zoomPositions}
          center={zoomPositions}
        >
            {!showMonument && <Marker
              onClick={()=>{
                setZoom(14);
                setZoomPositions({ lat: 26.8685168, lng: 80.9101378 });
                setShowMonument(true);
                setShowAccordian(false)
              }}
              title={"Lucknow, India"}
              name={"Lucknow, India"}
              position={{ lat: 26.8466114, lng: 80.9458903 }}
              icon={{
                url: "/map-marker.png",
              }}
              label={{color: '#000000', fontWeight: '700', fontSize: '16px', marginLeft:"10px", text: 'Lucknow' }}
            >
              <InfoWindow>{{ content: markerLabel }}</InfoWindow>
            </Marker>}
           {/* Bara Imamabara */}
            {showMonument && <Marker
              title={"Bara Imamabara"}
              name={"Bara Imamabara"}
              position={{ lat: 26.8685168, lng: 80.9101378 }}
              icon={{
                url: "/marker.png",
              }}
              label={{color: '#000000', fontWeight: '700', fontSize: '16px', marginLeft:"10px", text: 'Bara Imamabara' }}
              labelAnchor = {{ x: "40px", y: -15 }}
            >
              <InfoWindow>{{ content: markerLabel }}</InfoWindow>
            </Marker>
            }
            {/* The Residency */}
            {showMonument && <Marker
              title={"The Residency 1800"}
              name={"The Residency 1800"}
              position={{ lat: 26.8606372, lng: 80.9242114 }}
              icon={{
                url: "/marker.png",
              }}
              label={{color: '#000000', fontWeight: '700', fontSize: '16px', marginLeft:"10px", text: 'The Residency' }}
            >
              <InfoWindow>{{ content: markerLabel }}</InfoWindow>
            </Marker>
            }
            {/* La Martiniere College */}
            {showMonument && <Marker
              title={"La Martiniere College"}
              name={"La Martiniere College"}
              position={{ lat: 26.8396463, lng: 80.9570484 }}
              icon={{
                url: "/marker.png",
              }}
              label={{color: '#000000', fontWeight: '700', fontSize: '16px', marginLeft:"10px", text: 'La Martiniere College' }}
            >
              <InfoWindow>{{ content: markerLabel }}</InfoWindow>
            </Marker>
            }
          {/* Chhatar Manzil */}
          {showMonument && <Marker
              title={"La Martiniere College"}
              name={"La Martiniere College"}
              position={{ lat: 26.8587412, lng: 80.9294133 }}
              icon={{
                url: "/marker.png",
              }}
              label={{color: '#000000', fontWeight: '700', fontSize: '16px', marginLeft:"10px", text: 'La Martiniere College' }}
            >
              <InfoWindow>{{ content: markerLabel }}</InfoWindow>
            </Marker>
            }

            <Marker
              onClick={onMarkerClick}
              title={"Calcutta, India"}
              name={"Calcutta, India"}
              position={{ lat: 22.5356839, lng: 88.207262 }}
              icon={{
                url: "/circle.png",
              }}
              label={{color: '#000000', fontWeight: '400', fontSize: '16px', text: 'Calcutta' }}
            >
              <InfoWindow>{{ content: markerLabel }}</InfoWindow>
            </Marker>
            <Marker
              title={"Bombay, India"}
              name={"Bombay, India"}
              position={{ lat: 19.0846595, lng: 72.6653447 }}
              icon={{
                url: "/circle.png",
              }}
              label={{color: '#000000', fontWeight: '400', fontSize: '16px', marginLeft:"10px", text: 'Bombay' }}
            >
              <InfoWindow>{{ content: markerLabel }}</InfoWindow>
            </Marker>
            <Marker
              title={"Madras, India"}
              name={"Madras, India"}
              position={{ lat: 13.0473168, lng: 79.8793326 }}
              icon={{
                url: "/circle.png", width:"16px"
              }}
              label={{color: '#000000', fontWeight: '400', fontSize: '16px', marginLeft:"10px", text: 'Madras' }}
            >
              <InfoWindow>{{ content: markerLabel }}</InfoWindow>
            </Marker>
        </Map>
      </Box>
    </Stack>
  );
}
export default GoogleApiWrapper({
  apiKey: "AIzaSyDfWmySOiwopIgPkjcQWu94KRMz805nSo0",
})(MapContainer);

const MapDrawer = ({ isOpen, setIsOpen }) => {
  const btnRef = React.useRef();
  return (

    // Need to update mock update data from service file

    <Stack position={"relative"}>
      <Drawer
        isOpen={isOpen}
        placement="right"
        finalFocusRef={btnRef}
        id="rightsidebar"
        onClose={() => setIsOpen(false)}
      >
        <DrawerOverlay />
        <DrawerContent px="2">
          <DrawerCloseButton />
          <DrawerHeader>
            <H2>Lucknow & Calcutta</H2>
          </DrawerHeader>
          <DrawerBody>
            <Stack pb="8">
              <Stack py="1" justifyContent="space-between" flexDirection="row">
                  <HStack>
                    <Large color="#035DA1">
                      Colonial architecture in India
                    </Large>
                  </HStack>
                  <HStack marginBottom={"0px"}>
                    <Large color="#035DA1">
                      <AiOutlineRight />
                    </Large>
                  </HStack>
                </Stack>
                <Stack py="1" justifyContent="space-between" flexDirection="row">
                  <HStack>
                    <Large color="#035DA1">
                      Cities of East India Company
                    </Large>
                  </HStack>
                  <HStack marginBottom={"0px"}>
                    <Large color="#035DA1">
                      <AiOutlineRight />
                    </Large>
                  </HStack>
                </Stack>
                <Stack py="1" justifyContent="space-between" flexDirection="row">
                  <HStack>
                    <Large color="#035DA1" >
                      Antiquariarn Photographs of monuments in India
                    </Large>
                  </HStack>
                  <HStack marginBottom={"0px"}>
                    <Large color="#035DA1">
                      <AiOutlineRight />
                    </Large>
                  </HStack>
                </Stack>
                </Stack>
              <HStack>
                <Image src={Img} />
              </HStack>
              <Stack pt="18px">
                <HStack pb="6">
                  <Link to="/location/calcutta">
                  <Small color="#035DA1">Visit the Calcutta Masterlist → →</Small>
                  </Link>
                </HStack>
              </Stack>
              <Divider />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Stack>
  );
};

