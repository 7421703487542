import { Box, Container, HStack, Stack, Text } from "@chakra-ui/react";
import React from "react";
import "react-vertical-timeline-component/style.min.css";

import Layout from "../components/sections/SidebarLayout";
import { Link } from "react-router-dom";
import { common } from "../constants/constants";

function Manifesto() {
  return (
    <Layout>
      <Stack width={"100%"} bg="white" className="mobileviewpadding">
        <Container maxW={"auto"}>
          <Box>
            <Stack
              flexDirection={"row"}
              justifyContent="center"
              alignItems={"flex-end"}
              gap="4"
              paddingTop={"6"}
              paddingBottom={"12"}
            >
              <HStack>
                <Link to="/vision">
                  <Text
                    py="2"
                    paddingX={{base: "4", md:"8"}}
                    bg="rgba(255, 192, 21, 0.26)"
                    borderRadius={"5px"}
                    style={{
                      textAlign: "center",
                      fontSize: "15px",
                      fontWeight: "700",
                    }}
                  >
                    {common.VISION}
                  </Text>
                </Link>
              </HStack>
              <HStack>
                <Link to="/philosophical-framework">
                  <Text
                    py="2"
                    paddingX={{base: "4", md:"8"}}
                    bg="#FFC015"
                    borderRadius={"5px"}
                    style={{
                      textAlign: "center",
                      fontSize: "15px",
                      fontWeight: "700",
                    }}
                  >
                    {common.PHILOSOPHICAL_FRAME}
                  </Text>
                </Link>
              </HStack>
              <HStack>
                <Link to="/manifesto">
                  <Text
                    py="2"
                    paddingX={{base: "4", md:"8"}}
                    bg="rgba(255, 192, 21, 0.26)"
                    borderRadius={"5px"}
                    style={{
                      textAlign: "center",
                      fontSize: "15px",
                      fontWeight: "700",
                    }}
                  >
                   {common.MANIFESTO}
                  </Text>
                </Link>
              </HStack>
            </Stack>
            <Text
              py={"40"}
              fontSize={{ base: "30px", md: "45px" }}
            
              fontWeight="700"
              textAlign="center"
            >
           {common.WEBPAGE_COMING_SOON}
            </Text>
          </Box>
        </Container>
      </Stack>
    </Layout>
  );
}

export default Manifesto;
