import {
  Box,
  Container,
  HStack,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  SimpleGrid,
  Stack,
  Text,
} from "@chakra-ui/react";

//Core files
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCreative, EffectFade, Navigation } from "swiper";

//Language files
import { common } from "../../../../constants/constants";

//S3 Bucket osw-dev-images
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;

const closeIcon = `${IMAGE_BASE_PATH}/ui/Chevron/closeicon.svg`

export const FullScreenView = ({
  isOpen,
  onClose,
  imageData,
  clickedImageIndex,
  setClickedImageIndex,
  setReadMoreFlag,
  bannerFlag,
  title,
}) => {
  return (
    <>
      <Container maxW={"auto"} px="0px">
        <Modal
          size={"full"}
          isCentered
          isOpen={isOpen}
          onClick={onClose}
          Id="modalfullscreen"
          motionPreset="none"
        >
          <ModalOverlay />
          <ModalContent zIndex={"999"}>
            <ModalBody margin={{ base: "0", md: "16" }}>
              <Box
                bg={"white"}
                justifyContent={"space-between"}
                position={"relative"}
              >
                <Stack>
                  <Swiper
                    effect={{ sm: "fade", md: "creative" }}
                    id="allsliderskk1"
                    grabCursor={true}
                    navigation={true}
                    initialSlide={clickedImageIndex}
                    modules={[EffectCreative, EffectFade, Navigation]}
                  >
                    {imageData.length > 0 &&
                      imageData?.slice(0, 3).map((it, index) => {
                        return (
                          <SwiperSlide key={index}>
                            <Stack
                              maxW={"1180px"}
                              marginTop={{ base: "50px", md: "0" }}
                              flexDirection={{ base: "column", md: "row" }}
                              className="row"
                              alignItems={"flex-start"}
                              gap={"0"}
                            >
                              <HStack
                                className="col-sm-12 col-md-6"
                                justifyContent={"center"}
                                border={"0.01rem solid #d7d7d7"}
                                borderRadius={"5px"}
                                background={"#eeeeee"}
                              >
                                <Stack m="8">
                                  <Box
                                    display={"block"}
                                    justifyContent={"center"}
                                    width={"100%"}
                                    height={{ base: "auto", md: "auto" }}
                                  >
                                    <Image
                                      src={it?.fullImg}
                                      key={index}
                                      width={"100%"}
                                      height={"inherit"}
                                      objectFit={"contain"}
                                      alt=""
                                    />
                                  </Box>
                                </Stack>
                              </HStack>
                              <HStack
                                alignItems={"flex-start"}
                                className="col-sm-12 col-md-6"
                                flexDirection={"column"}
                              >
                                <Box
                                  paddingBottom={"10px"}
                                  width={"100%"}
                                  borderBottom={"1px"}
                                  borderColor={"#e8e8e8"}
                                >
                                  <Text fontSize={"16px"} fontWeight={"700"} color={"#035DA1"}>
                                    {common.CONTENT_AWAITED}
                                  </Text>
                                </Box>
                                <SimpleGrid
                                  columns={[0, null, 1]}
                                  marginInlineStart={"0px!important"}
                                  spacing="2px"
                                  py="2"
                                >
                                  <Text
                                    fontSize={"14px"}
                                    fontWeight={"700"}
                                    color="#000000"
                                  >
                                    {"Title of the Photograph"}
                                  </Text>
                                  <Text
                                    fontSize={"14px"}
                                    fontWeight={"400"}
                                    color="black"
                                  >
                                    {it?.title_of_photograph}
                                  </Text>
                                  <Text
                                    fontSize={"14px"}
                                    fontWeight={"700"}
                                    color="#000000"
                                  >
                                    {"Photographer"}
                                  </Text>
                                  <Text
                                    fontSize={"14px"}
                                    fontWeight={"400"}
                                    color="black"
                                  >
                                    {it?.photographer}
                                  </Text>
                                  <Text
                                    fontSize={"14px"}
                                    fontWeight={"700"}
                                    color="#000000"
                                  >
                                    {"Medium"}
                                  </Text>
                                  <Text
                                    fontSize={"14px"}
                                    fontWeight={"400"}
                                    color="black"
                                  >
                                    {it?.medium}
                                  </Text>
                                  
                                  <Text
                                    fontSize={"14px"}
                                    fontWeight={"700"}
                                    color="#000000"
                                  >
                                    {"Date of Photograph"}
                                  </Text>
                                  <Text
                                    fontSize={"14px"}
                                    fontWeight={"400"}
                                    color="black"
                                  >
                                    {it?.date_of_photograph}
                                  </Text>
                                  <Text
                                    fontSize={"14px"}
                                    fontWeight={"600"}
                                    color="#000000"
                                  >
                                    {index === 0
                                      ? "Size  "
                                      : "Date of Artwork Size  "}
                                  </Text>

                                  <Text
                                    fontSize={"14px"}
                                    fontWeight={"400"}
                                    color="black"
                                  >
                                    {it?.size}
                                  </Text>
                                  <Text
                                    fontSize={"14px"}
                                    fontWeight={"600"}
                                    color="#000000"
                                  >
                                    {"Location"}
                                  </Text>
                                  <Text
                                    fontSize={"14px"}
                                    fontWeight={"400"}
                                    color="black"
                                  >
                                    {it?.location}
                                  </Text>
                                  <Text
                                    fontSize={"14px"}
                                    fontWeight={"600"}
                                    color="#000000"
                                  >
                                    {"Quotation"}
                                  </Text>
                                  <Text
                                    fontSize={"14px"}
                                    fontWeight={"400"}
                                    color="black"
                                  >
                                    {it?.quotation}
                                  </Text>
                                </SimpleGrid>
                                <Text
                                  pt="4"
                                  marginInlineStart={"0px!important"}
                                  fontSize={"14px"}
                                  fontWeight={"700"}
                                  color="#035DA1"
                                  cursor={"pointer"}
                                  onClick={() => {
                                    onClose();
                                    setReadMoreFlag(
                                      true,
                                      isOpen,
                                      index,
                                      bannerFlag,
                                      title
                                    );
                                  }}
                                >
                                  {common.READ_MORE}
                                </Text>
                              </HStack>
                              <HStack>
                                <Image
                                  className="art-he-closebtn"
                                  src={closeIcon}
                                  onClick={() => {
                                    onClose();
                                  }}
                                  cursor={"pointer"}
                                ></Image>
                              </HStack>
                            </Stack>
                          </SwiperSlide>
                        );
                      })}
                  </Swiper>
                </Stack>
              </Box>
            </ModalBody>
          </ModalContent>
        </Modal>
      </Container>
    </>
  );
};
