import { Stack } from "@chakra-ui/react";
import React, { useState } from "react";
import { getMasterlistData } from "../../services/MasterlistService";
import { WebpageComingSoon } from "../../CommonComponents/WebpageComingSoon";
import ImageTextGrid from "../../FunctionalComponents/SimpleGrid/ImageTextGrid";
import Grid from "../../FunctionalComponents/SimpleGrid/Grid";
import { useTranslation } from "react-i18next";
import { explore } from "../../constants/constants";
import H4 from "../../Theme/Typography/H4";
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;
const loaderImg = `${IMAGE_BASE_PATH}/ui/Chevron/loader.gif`

function Masterlists() {
  const [masterlistData, setMasterlistData] = useState([]);
  const [showLabel, setShowLabel] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(true);
  const { t } = useTranslation("");
  const [page, setPage] = useState(1);
  const [pageSize] = useState(20);
  const [showMore, setShowMore] = useState(true);
  const [totalCount]=useState(4)

  const fetchMasterlistData = async (page, pageSize) => {
    const result = await getMasterlistData(page, pageSize);
    setLoading(false);
    return result;
  };

  React.useEffect(() => {
    const loadInitialData = async () => {
      setLoading(true);
      const result = await fetchMasterlistData(page, pageSize);
      if (result?.length > 0) {
        setMasterlistData(result);
        setShowLabel(false);
        if (result.length === totalCount) {
          setShowMore(false);
        }
      } else {
        setShowLabel(true);
      }
      setLoading(false);
      setLoadingMore(false);
    };
    loadInitialData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSeeMore = async () => {
    const nextPage = page + 1;
    setPage(nextPage);
    setLoadingMore(true);
    const result = await fetchMasterlistData(nextPage, pageSize);
    if (result?.length > 0) {
      setMasterlistData((prevData) => {
        const newData = [...prevData, ...result];
        if (newData.length === totalCount) {
          setShowMore(false);
        }
        return newData;
      });
    }
    setLoadingMore(false);
  };

  return (
    <Stack p="0" pb={{base:"60px",md:"0px"}}>
      {loading ? (
        <Stack p="0" marginTop={"20px"} width={"100%"} alignItems={"center"}>
          <img src={loaderImg} width={"200px"} height={"200px"} alt="Loading" />
        </Stack>
      ) : showLabel ? (
        <WebpageComingSoon />
      ) : (
        <>
          {masterlistData.length > 0 && (
            <Grid
              data={masterlistData}
              columns={{ base: 2, md: 3, lg: 5, xl: 6 }}
              textStyle={"secondary"} fontSize={{base:"14px",md:"16px"}} fontWeight="400" height={"130px"}  spacing="16px"
              component={ImageTextGrid} />
          )}
          {loadingMore ? (
            <Stack p="0" marginTop={"20px"} width={"100%"} alignItems={"center"}>
              <img src={loaderImg} width={"100px"} height={"100px"} alt="Loading More" />
            </Stack>
          ) : (
          showMore && (
            <H4
            color="color.blue"
             textAlign="center"
             pt="27px"
             pb="17px"
             cursor="pointer"
             fontWeight={"700"}
             onClick={handleSeeMore}
           >
             {`${t(explore.SEE_MORE)}`}
           </H4>
           )
          )}
        </>
      )}
    </Stack>
  );
}
export default Masterlists;
