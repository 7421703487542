import { HStack, Heading, Stack, Text } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react'
import PieChart from './PieChart';

export default function ArtworkSalesVsMedium({data}) {
  const [selectedData, setSelectedData] = useState(null);

  const key = "artwork-sales-vs-medium-of-artwork";
  useEffect(() => {
    const handleSelectData = () => {
      const selected = data.find((item) => item.key === key);
      setSelectedData(selected);
    };
    handleSelectData();
  }, [data]);
 
  return (
    <>
    {data?.length > 0 && selectedData!==null && selectedData!==undefined && (
      <>
        <HStack flexDirection={"column"} alignItems={"self-start"}>
          <Heading as="h3" size="lg" mb={4} pb={4}>
            {selectedData?.title}
          </Heading>
          <Text fontSize={"16px"}>{selectedData?.subtitle}</Text>
          <Text fontSize={"16px"}>{selectedData?.heading}</Text>
        </HStack>
        <HStack alignItems={"flex-start"}>
          <Stack>
            <PieChart data={selectedData?.data} />
          </Stack>
          <Stack>
            <Text>{selectedData?.desc}</Text>
          </Stack>
        </HStack>
      </>
    )}
  </>
  )
}
