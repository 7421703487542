import { Container, HStack, Stack } from "@chakra-ui/react";

//Core files
import React, { useEffect, useState } from "react";

//Service files
import * as getComparableArtworksData from "./../../../../services/EventExhibition";
import { CopyPasteService } from "../../../../services/CopyPaste";

//Component files
import ImageWithCitation2 from "../../../../FunctionalComponents/SimpleGrid/ImageWithCitation2";
import Grid from "../../../../FunctionalComponents/SimpleGrid/Grid";
import { WebpageComingSoon } from "../../../../CommonComponents/WebpageComingSoon";

//Language files


export default function EventPhotographs({ winnerName }) {
  const [FilterData, setFilterData] = useState([]);

  const citationFactor = (data) => {
    return data.map((item, index) => {
      return {
        image: item?.image,
        desc: item?.desc,
      };
    });
  };

  useEffect(() => {
    const getData = async () => {
      const result = await getComparableArtworksData.comparableArtworksData(
        "event-exhibition",
        winnerName
      );
      setFilterData(citationFactor(result));
    };

    getData();
    // eslint-disable-next-line
  }, []);

  return (
    <Stack flexDirection={"row-reverse"} minH={"100vh"}>
      <Container
        maxW="auto"
        className="songs"
        px={{ base: "4", md: "0px" }}
        pb="8"
      >
        <Stack flexDirection={"row"} gap={4} justifyContent="center">
          {FilterData.length > 0 ? (
            <HStack
              onCopy={(e) => CopyPasteService(e, `${window.location.href}/0/1`)}
              marginRight={{ base: "0px", md: "30px" }}
            >
              <Grid
                data={FilterData}
                columns={{ base: 1, md: 3, lg: 3, xl: 4 }}
                spacing={{ base: "5px", md: "24px" }}
                component={ImageWithCitation2}
              />
            </HStack>
          ) : (
            <>
              <HStack
                onCopy={(e) =>
                  CopyPasteService(e, `${window.location.href}/0/1`)
                }
                margin={"auto"}
              >
                <WebpageComingSoon/>
              </HStack>
            </>
          )}
        </Stack>
      </Container>
    </Stack>
  );
}
