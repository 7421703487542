//S3 Bucket osw-dev-images
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;

const Banner1 = `${IMAGE_BASE_PATH}/ui/PersonalityPolymath/SatyajitRay/LandingPage/SliderImage/1.png`
const Banner2 = `${IMAGE_BASE_PATH}/ui/PersonalityPolymath/SatyajitRay/LandingPage/SliderImage/2.png`
const Banner5 = `${IMAGE_BASE_PATH}/ui/PersonalityPolymath/SatyajitRay/LandingPage/SliderImage/3.png`
const Banner3 = `${IMAGE_BASE_PATH}/ui/PersonalityPolymath/SatyajitRay/LandingPage/SliderImage/4.png`

const SHRaza1 = `${IMAGE_BASE_PATH}/ui/PersonalityArtist/SayedHaiderRaza/LandingPage/SliderImage/1.png`
const SHRaza2 = `${IMAGE_BASE_PATH}/ui/PersonalityArtist/SayedHaiderRaza/LandingPage/SliderImage/2.png`
const SHRaza3 = `${IMAGE_BASE_PATH}/ui/PersonalityArtist/SayedHaiderRaza/LandingPage/SliderImage/3.png`
const SHRaza5 = `${IMAGE_BASE_PATH}/ui/PersonalityArtist/SayedHaiderRaza/LandingPage/SliderImage/5.png`

const skk1 = `${IMAGE_BASE_PATH}/ui/Film/ShatranjKeKhilari/LandingPage/SliderImage/1.png`
const skk2 = `${IMAGE_BASE_PATH}/ui/Film/ShatranjKeKhilari/LandingPage/SliderImage/3.png`
const skk3 = `${IMAGE_BASE_PATH}/ui/Film/ShatranjKeKhilari/LandingPage/SliderImage/2.png`
const skk4 = `${IMAGE_BASE_PATH}/ui/Film/ShatranjKeKhilari/LandingPage/SliderImage/4.png`

const art1 = `${IMAGE_BASE_PATH}/ui/ObjectBookJou/ArtHeritageAnnual/LandingPage/SliderImage/1.png`
const art2 = `${IMAGE_BASE_PATH}/ui/ObjectBookJou/ArtHeritageAnnual/LandingPage/SliderImage/2.png`
const art3 = `${IMAGE_BASE_PATH}/ui/ObjectBookJou/ArtHeritageAnnual/LandingPage/SliderImage/3.png`
const art4 = `${IMAGE_BASE_PATH}/ui/ObjectBookJou/ArtHeritageAnnual/LandingPage/SliderImage/4.png`

const mughal1 = `${IMAGE_BASE_PATH}/ui/Film/MughalEAzam/LandingPage/SliderImage/1.png`
const mughal2 = `${IMAGE_BASE_PATH}/ui/Film/MughalEAzam/LandingPage/SliderImage/2.png`
const mughal3 = `${IMAGE_BASE_PATH}/ui/Film/MughalEAzam/LandingPage/SliderImage/3.png`
const mughal4 = `${IMAGE_BASE_PATH}/ui/Film/MughalEAzam/LandingPage/SliderImage/4.png`
const placeholderImg = `${IMAGE_BASE_PATH}/ui/DummyImage/blank.png`

const songbook1 = `${IMAGE_BASE_PATH}/ui/ObjectBookSsb/SongsSynopsisBooklet/LandingPage/SliderImage/1.png`
const songbook2 = `${IMAGE_BASE_PATH}/ui/ObjectBookSsb/SongsSynopsisBooklet/LandingPage/SliderImage/2.png`
const songbook3 = `${IMAGE_BASE_PATH}/ui/ObjectBookSsb/SongsSynopsisBooklet/LandingPage/SliderImage/3.png`

const lucknow1 = `${IMAGE_BASE_PATH}/ui/Location/Lucknow/LandingPage/1.png`
const lucknow2 = `${IMAGE_BASE_PATH}/ui/Location/Lucknow/LandingPage/2.png`
const lucknow3 = `${IMAGE_BASE_PATH}/ui/Location/Lucknow/LandingPage/3.png`
const lucknow4 = `${IMAGE_BASE_PATH}/ui/Location/Lucknow/LandingPage/4.png`

const PictorialSpaceIntro = `${IMAGE_BASE_PATH}/ui/EventExhibition/PictorialSpace/LandingPage/Introduction.png`
const PictorialSpaceBoW = `${IMAGE_BASE_PATH}/ui/EventExhibition/PictorialSpace/LandingPage/BodyofWork.png`
const PictorialSpaceEcon = `${IMAGE_BASE_PATH}/ui/EventExhibition/PictorialSpace/LandingPage/Econ.png`
const PictorialSpaceRC = `${IMAGE_BASE_PATH}/ui/EventExhibition/PictorialSpace/LandingPage/ResearchCentre.png`

const KGSubra1 = `${IMAGE_BASE_PATH}/ui/PersonalityArtist/KGSubramanyan/LandingPage/1.png`
const KGSubra2 = `${IMAGE_BASE_PATH}/ui/PersonalityArtist/KGSubramanyan/LandingPage/2.png`
const KGSubra3 = `${IMAGE_BASE_PATH}/ui/PersonalityArtist/KGSubramanyan/LandingPage/3.png`
const KGSubra4 = `${IMAGE_BASE_PATH}/ui/PersonalityArtist/KGSubramanyan/LandingPage/4.png`

const calcutta1 = `${IMAGE_BASE_PATH}/ui/Location/Calcutta/LandingPage/1.png`
const calcutta2 = `${IMAGE_BASE_PATH}/ui/Location/Calcutta/LandingPage/2.png`
const calcutta3 = `${IMAGE_BASE_PATH}/ui/Location/Calcutta/LandingPage/3.png`
const calcutta4 = `${IMAGE_BASE_PATH}/ui/Location/Calcutta/LandingPage/4.png`

const bannerImg = `${IMAGE_BASE_PATH}/ui/Sanjeev-kumar/sanjeev-kumar.png`
const ShammiKapoor1 = `${IMAGE_BASE_PATH}/ui/PersonalityActor/ShammiKapoor/LandingPage/1.jpg`
const ShammiKapoor2 = `${IMAGE_BASE_PATH}/ui/PersonalityActor/ShammiKapoor/LandingPage/2.jpg`
const ShammiKapoor3 = `${IMAGE_BASE_PATH}/ui/PersonalityActor/ShammiKapoor/LandingPage/3.jpg`
const ShammiKapoor4 = `${IMAGE_BASE_PATH}/ui/PersonalityActor/ShammiKapoor/LandingPage/4.jpg`

const RajKapoor1 = `${IMAGE_BASE_PATH}/ui/PersonalityFilmMaker/RajKapoor/LandingPage/1.jpg`
const RajKapoor2 = `${IMAGE_BASE_PATH}/ui/PersonalityFilmMaker/RajKapoor/LandingPage/2.jpg`
const RajKapoor3 = `${IMAGE_BASE_PATH}/ui/PersonalityFilmMaker/RajKapoor/LandingPage/3.jpg`
const RajKapoor4 = `${IMAGE_BASE_PATH}/ui/PersonalityFilmMaker/RajKapoor/LandingPage/4.jpg`

const AmitabhBachchan1 = `${IMAGE_BASE_PATH}/ui/PersonalityActor/AmitabhBachchan/LandingPage/1.jpg`
const AmitabhBachchan2 = `${IMAGE_BASE_PATH}/ui/PersonalityActor/AmitabhBachchan/LandingPage/2.jpg`
const AmitabhBachchan3 = `${IMAGE_BASE_PATH}/ui/PersonalityActor/AmitabhBachchan/LandingPage/3.jpg`
const AmitabhBachchan4 = `${IMAGE_BASE_PATH}/ui/PersonalityActor/AmitabhBachchan/LandingPage/4.jpg`

export const satyajit_landing_page_data=[
    [
        {
            "key": "alias",
            "value": "satyajit-ray-introduction"
        },
        {
            "key": "landing_page_title",
            "value": "Introduction"
        },
        {
            "key": "landing_page_abbreviation",
            "value": "introduction"
        },
        {
            "key": "description",
            "value": null
        },
        {
            "key": "artwork",
            "value": [{"key":"images","value":Banner1}]
        },
        {
            "key": "masterlist_alias",
            "value": "satyajit-ray"
        }
    ],
    [
        {
            "key": "alias",
            "value": "satyajit-ray-body-of-work"
        },
        {
            "key": "landing_page_title",
            "value": "Body of Work"
        },
        {
            "key": "landing_page_abbreviation",
            "value": "body-of-work"
        },
        {
            "key": "description",
            "value": null
        },
        {
            "key": "artwork",
            "value": [{"key":"images","value":Banner2}]
        },
        {
            "key": "masterlist_alias",
            "value": "satyajit-ray"
        }
    ],
    [
        {
            "key": "alias",
            "value": "satyajit-ray-economics-of-cinema"
        },
        {
            "key": "landing_page_title",
            "value": "Economics of Cinema"
        },
        {
            "key": "landing_page_abbreviation",
            "value": "economics-of-cinema"
        },
        {
            "key": "description",
            "value": null
        },
        {
            "key": "artwork",
            "value": [{"key":"images","value":Banner3}]
        },
        {
            "key": "masterlist_alias",
            "value": "satyajit-ray"
        }
    ],
    [
        {
            "key": "alias",
            "value": "satyajit-ray-research-centre"
        },
        {
            "key": "landing_page_title",
            "value": "Research Centre"
        },
        {
            "key": "landing_page_abbreviation",
            "value": "research-centre"
        },
        {
            "key": "description",
            "value": null
        },
        {
            "key": "artwork",
            "value": [{"key":"images","value":Banner5}]
        },
        {
            "key": "masterlist_alias",
            "value": "satyajit-ray"
        }
    ]
]
export const skk_landing_page_data=[
    [
        {
            "key": "alias",
            "value": "shatranj-ke-khilari-1977-introduction"
        },
        {
            "key": "landing_page_title",
            "value": "Introduction"
        },
        {
            "key": "landing_page_abbreviation",
            "value": "introduction"
        },
        {
            "key": "description",
            "value": null
        },
        {
            "key": "artwork",
            "value": [{"key":"images","value":skk1}]
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977"
        }
        
    ],
    [
        {
            "key": "alias",
            "value": "shatranj-ke-khilari-1977-body-of-work"
        },
        {
            "key": "landing_page_title",
            "value": "Body of Work"
        },
        {
            "key": "landing_page_abbreviation",
            "value": "body-of-work"
        },
        {
            "key": "description",
            "value": null
        },
        {
            "key": "artwork",
            "value": [{"key":"images","value":skk2}]
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977"
        }
    ],
    [
        {
            "key": "alias",
            "value": "shatranj-ke-khilari-1977-economics-of-cinema"
        },
        {
            "key": "landing_page_title",
            "value": "Economics of Cinema"
        },
        {
            "key": "landing_page_abbreviation",
            "value": "economics-of-cinema"
        },
        {
            "key": "description",
            "value": null
        },
        {
            "key": "artwork",
            "value": [{"key":"images","value":skk3}]
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977"
        }
    ],
    [
        {
            "key": "alias",
            "value": "shatranj-ke-khilari-1977-research-centre"
        },
        {
            "key": "landing_page_title",
            "value": "Research Centre"
        },
        {
            "key": "landing_page_abbreviation",
            "value": "research-centre"
        },
        {
            "key": "description",
            "value": null
        },
        {
            "key": "artwork",
            "value": [{"key":"images","value":skk4}]
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977"
        }
    ]
]
export const art_heritage_landing_page_data=[
    [
        {
            "key": "alias",
            "value": "art-heritage-annual-introduction"
        },
        {
            "key": "landing_page_title",
            "value": "Introduction"
        },
        {
            "key": "landing_page_abbreviation",
            "value": "introduction"
        },
        {
            "key": "description",
            "value": null
        },
        {
            "key": "artwork",
            "value": [{"key":"images","value":art1}]
        },
        {
            "key": "masterlist",
            "value": "art-heritage-annual"
        }
        
    ],
    [
        {
            "key": "alias",
            "value": "art-heritage-annual-body-of-work"
        },
        {
            "key": "landing_page_title",
            "value": "Body of Work"
        },
        {
            "key": "landing_page_abbreviation",
            "value": "body-of-work"
        },
        {
            "key": "description",
            "value": null
        },
        {
            "key": "artwork",
            "value": [{"key":"images","value":art2}]
        },
        {
            "key": "masterlist",
            "value": "art-heritage-annual"
        }
    ],
    [
        {
            "key": "alias",
            "value": "art-heritage-annual-economics-of-art"
        },
        {
            "key": "landing_page_title",
            "value": "Economics of Art"
        },
        {
            "key": "landing_page_abbreviation",
            "value": "economics-of-art"
        },
        {
            "key": "description",
            "value": null
        },
        {
            "key": "artwork",
            "value": [{"key":"images","value":art3}]
        },
        {
            "key": "masterlist",
            "value": "art-heritage-annual"
        }
    ],
    [
        {
            "key": "alias",
            "value": "art-heritage-annual-research-centre"
        },
        {
            "key": "landing_page_title",
            "value": "Research Centre"
        },
        {
            "key": "landing_page_abbreviation",
            "value": "research-centre"
        },
        {
            "key": "description",
            "value": null
        },
        {
            "key": "artwork",
            "value": [{"key":"images","value":art4}]
        },
        {
            "key": "masterlist",
            "value": "art-heritage-annual"
        }
    ]
]
export const mughal_e_azam_landing_page_data=[
    [
        {
            "key": "alias",
            "value": "mughal-e-azam-introduction"
        },
        {
            "key": "landing_page_title",
            "value": "Introduction"
        },
        {
            "key": "landing_page_abbreviation",
            "value": "introduction"
        },
        {
            "key": "description",
            "value": null
        },
        {
            "key": "artwork",
            "value": [{"key":"images","value":mughal1}]
        },
        {
            "key": "masterlist",
            "value": "mughal-e-azam"
        }
        
    ],
    [
        {
            "key": "alias",
            "value": "mughal-e-azam-body-of-work"
        },
        {
            "key": "landing_page_title",
            "value": "Body of Work"
        },
        {
            "key": "landing_page_abbreviation",
            "value": "body-of-work"
        },
        {
            "key": "description",
            "value": null
        },
        {
            "key": "artwork",
            "value": [{"key":"images","value":mughal2}]
        },
        {
            "key": "masterlist",
            "value": "mughal-e-azam"
        }
    ],
    [
        {
            "key": "alias",
            "value": "mughal-e-azam-economics-of-cinema"
        },
        {
            "key": "landing_page_title",
            "value": "Economics of Cinema"
        },
        {
            "key": "landing_page_abbreviation",
            "value": "economics-of-cinema"
        },
        {
            "key": "description",
            "value": null
        },
        {
            "key": "artwork",
            "value": [{"key":"images","value":mughal3}]
        },
        {
            "key": "masterlist",
            "value": "mughal-e-azam"
        }
    ],
    [
        {
            "key": "alias",
            "value": "mughal-e-azam-research-centre"
        },
        {
            "key": "landing_page_title",
            "value": "Research Centre"
        },
        {
            "key": "landing_page_abbreviation",
            "value": "research-centre"
        },
        {
            "key": "description",
            "value": null
        },
        {
            "key": "artwork",
            "value": [{"key":"images","value":mughal4}]
        },
        {
            "key": "masterlist",
            "value": "mughal-e-azam"
        }
    ]
]
export const sh_raza_landing_page_data=[
    [
        {
            "key": "alias",
            "value": "sayed-haider-raza-introduction"
        },
        {
            "key": "landing_page_title",
            "value": "Introduction"
        },
        {
            "key": "landing_page_abbreviation",
            "value": "introduction"
        },
        {
            "key": "description",
            "value": null
        },
        {
            "key": "artwork",
            "value": [{"key":"images","value":SHRaza1}]
        },
        {
            "key": "masterlist",
            "value": "sayed-haider-raza"
        }
    ],
    [
        {
            "key": "alias",
            "value": "sayed-haider-raza-body-of-work"
        },
        {
            "key": "landing_page_title",
            "value": "Body of Work"
        },
        {
            "key": "landing_page_abbreviation",
            "value": "body-of-work"
        },
        {
            "key": "description",
            "value": null
        },
        {
            "key": "artwork",
            "value": [{"key":"images","value":SHRaza2}]
        },
        {
            "key": "masterlist",
            "value": "sayed-haider-raza"
        }
    ],
    [
        {
            "key": "alias",
            "value": "sayed-haider-raza-economics-of-art"
        },
        {
            "key": "landing_page_title",
            "value": "Economics of Art"
        },
        {
            "key": "landing_page_abbreviation",
            "value": "economics-of-art"
        },
        {
            "key": "description",
            "value": null
        },
        {
            "key": "artwork",
            "value": [{"key":"images","value":SHRaza3}]
        },
        {
            "key": "masterlist",
            "value": "sayed-haider-raza"
        }
    ],
    [
        {
            "key": "alias",
            "value": "sayed-haider-raza-research-centre"
        },
        {
            "key": "landing_page_title",
            "value": "Research Centre"
        },
        {
            "key": "landing_page_abbreviation",
            "value": "Research Centre"
        },
        {
            "key": "description",
            "value": null
        },
        {
            "key": "artwork",
            "value": [{"key":"images","value":SHRaza5}]
        },
        {
            "key": "masterlist",
            "value": "sayed-haider-raza"
        }
    ]
]
export const suresh_jindal_landing_page_data=[
    [
        {
            "key": "alias",
            "value": "suresh-jindal-introduction"
        },
        {
            "key": "landing_page_title",
            "value": "Introduction"
        },
        {
            "key": "landing_page_abbreviation",
            "value": "introduction"
        },
        {
            "key": "description",
            "value": null
        },
        {
            "key": "artwork",
            "value": [{"key":"images","value":placeholderImg}]
        },
        {
            "key": "masterlist",
            "value": "suresh-jindal"
        }
    ],
    [
        {
            "key": "alias",
            "value": "suresh-jindal-body-of-work"
        },
        {
            "key": "landing_page_title",
            "value": "Body of Work"
        },
        {
            "key": "landing_page_abbreviation",
            "value": "body-of-work"
        },
        {
            "key": "description",
            "value": null
        },
        {
            "key": "artwork",
            "value": [{"key":"images","value":placeholderImg}]
        },
        {
            "key": "masterlist",
            "value": "suresh-jindal"
        }
    ],
    [
        {
            "key": "alias",
            "value": "suresh-jindal-economics-of-cinema"
        },
        {
            "key": "landing_page_title",
            "value": "Economics of Cinema"
        },
        {
            "key": "landing_page_abbreviation",
            "value": "economics-of-cinema"
        },
        {
            "key": "description",
            "value": null
        },
        {
            "key": "artwork",
            "value": [{"key":"images","value":placeholderImg}]
        },
        {
            "key": "masterlist",
            "value": "suresh-jindal"
        }
    ],
    [
        {
            "key": "alias",
            "value": "suresh-jindal-integrated-timeline"
        },
        {
            "key": "landing_page_title",
            "value": "Integrated Timeline"
        },
        {
            "key": "landing_page_abbreviation",
            "value": "integrated-timeline"
        },
        {
            "key": "description",
            "value": null
        },
        {
            "key": "artwork",
            "value": [{"key":"images","value":placeholderImg}]
        },
        {
            "key": "masterlist",
            "value": "suresh-jindal"
        }
    ],
    [
        {
            "key": "alias",
            "value": "suresh-jindal-research-centre"
        },
        {
            "key": "landing_page_title",
            "value": "Research Centre"
        },
        {
            "key": "landing_page_abbreviation",
            "value": "research-centre"
        },
        {
            "key": "description",
            "value": null
        },
        {
            "key": "artwork",
            "value": [{"key":"images","value":placeholderImg}]
        },
        {
            "key": "masterlist",
            "value": "suresh-jindal"
        }
    ]
]
export const javed_siddiqi_landing_page_data=[
    [
        {
            "key": "alias",
            "value": "javed-siddiqi-introduction"
        },
        {
            "key": "landing_page_title",
            "value": "Introduction"
        },
        {
            "key": "landing_page_abbreviation",
            "value": "introduction"
        },
        {
            "key": "description",
            "value": null
        },
        {
            "key": "artwork",
            "value": [{"key":"images","value":placeholderImg}]
        },
        {
            "key": "masterlist",
            "value": "javed-siddiqi"
        }
    ],
    [
        {
            "key": "alias",
            "value": "javed-siddiqi-body-of-work"
        },
        {
            "key": "landing_page_title",
            "value": "Body of Work"
        },
        {
            "key": "landing_page_abbreviation",
            "value": "body-of-work"
        },
        {
            "key": "description",
            "value": null
        },
        {
            "key": "artwork",
            "value": [{"key":"images","value":placeholderImg}]
        },
        {
            "key": "masterlist",
            "value": "javed-siddiqi"
        }
    ],
    [
        {
            "key": "alias",
            "value": "javed-siddiqi-economics-of-cinema"
        },
        {
            "key": "landing_page_title",
            "value": "Economics of Cinema"
        },
        {
            "key": "landing_page_abbreviation",
            "value": "economics-of-cinema"
        },
        {
            "key": "description",
            "value": null
        },
        {
            "key": "artwork",
            "value": [{"key":"images","value":placeholderImg}]
        },
        {
            "key": "masterlist",
            "value": "javed-siddiqi"
        }
    ],
    [
        {
            "key": "alias",
            "value": "javed-siddiqi-integrated-timeline"
        },
        {
            "key": "landing_page_title",
            "value": "Integrated Timeline"
        },
        {
            "key": "landing_page_abbreviation",
            "value": "integrated-timeline"
        },
        {
            "key": "description",
            "value": null
        },
        {
            "key": "artwork",
            "value": [{"key":"images","value":placeholderImg}]
        },
        {
            "key": "masterlist",
            "value": "javed-siddiqi"
        }
    ],
    [
        {
            "key": "alias",
            "value": "javed-siddiqi-research-centre"
        },
        {
            "key": "landing_page_title",
            "value": "Research Centre"
        },
        {
            "key": "landing_page_abbreviation",
            "value": "research-centre"
        },
        {
            "key": "description",
            "value": null
        },
        {
            "key": "artwork",
            "value": [{"key":"images","value":placeholderImg}]
        },
        {
            "key": "masterlist",
            "value": "javed-siddiqi"
        }
    ]
]
export const shama_zaidi_landing_page_data=[
    [
        {
            "key": "alias",
            "value": "shama-zaidi-introduction"
        },
        {
            "key": "landing_page_title",
            "value": "Introduction"
        },
        {
            "key": "landing_page_abbreviation",
            "value": "introduction"
        },
        {
            "key": "description",
            "value": null
        },
        {
            "key": "artwork",
            "value": [{"key":"images","value":placeholderImg}]
        },
        {
            "key": "masterlist",
            "value": "shama-zaidi"
        }
    ],
    [
        {
            "key": "alias",
            "value": "shama-zaidi-body-of-work"
        },
        {
            "key": "landing_page_title",
            "value": "Body of Work"
        },
        {
            "key": "landing_page_abbreviation",
            "value": "body-of-work"
        },
        {
            "key": "description",
            "value": null
        },
        {
            "key": "artwork",
            "value": [{"key":"images","value":placeholderImg}]
        },
        {
            "key": "masterlist",
            "value": "shama-zaidi"
        }
    ],
    [
        {
            "key": "alias",
            "value": "shama-zaidi-economics-of-cinema"
        },
        {
            "key": "landing_page_title",
            "value": "Economics of Cinema"
        },
        {
            "key": "landing_page_abbreviation",
            "value": "economics-of-cinema"
        },
        {
            "key": "description",
            "value": null
        },
        {
            "key": "artwork",
            "value": [{"key":"images","value":placeholderImg}]
        },
        {
            "key": "masterlist",
            "value": "shama-zaidi"
        }
    ],
    [
        {
            "key": "alias",
            "value": "shama-zaidi-integrated-timeline"
        },
        {
            "key": "landing_page_title",
            "value": "Integrated Timeline"
        },
        {
            "key": "landing_page_abbreviation",
            "value": "integrated-timeline"
        },
        {
            "key": "description",
            "value": null
        },
        {
            "key": "artwork",
            "value": [{"key":"images","value":placeholderImg}]
        },
        {
            "key": "masterlist",
            "value": "shama-zaidi"
        }
    ],
    [
        {
            "key": "alias",
            "value": "shama-zaidi-research-centre"
        },
        {
            "key": "landing_page_title",
            "value": "Research Centre"
        },
        {
            "key": "landing_page_abbreviation",
            "value": "research-centre"
        },
        {
            "key": "description",
            "value": null
        },
        {
            "key": "artwork",
            "value": [{"key":"images","value":placeholderImg}]
        },
        {
            "key": "masterlist",
            "value": "shama-zaidi"
        }
    ]
]
export const birju_maharaj_landing_page_data=[
    [
        {
            "key": "alias",
            "value": "birju-maharaj-introduction"
        },
        {
            "key": "landing_page_title",
            "value": "Introduction"
        },
        {
            "key": "landing_page_abbreviation",
            "value": "introduction"
        },
        {
            "key": "description",
            "value": null
        },
        {
            "key": "artwork",
            "value": [{"key":"images","value":placeholderImg}]
        },
        {
            "key": "masterlist",
            "value": "birju-maharaj"
        }
    ],
    [
        {
            "key": "alias",
            "value": "birju-maharaj-body-of-work"
        },
        {
            "key": "landing_page_title",
            "value": "Body of Work"
        },
        {
            "key": "landing_page_abbreviation",
            "value": "body-of-work"
        },
        {
            "key": "description",
            "value": null
        },
        {
            "key": "artwork",
            "value": [{"key":"images","value":placeholderImg}]
        },
        {
            "key": "masterlist",
            "value": "birju-maharaj"
        }
    ],
    [
        {
            "key": "alias",
            "value": "birju-maharaj-economics-of-cinema"
        },
        {
            "key": "landing_page_title",
            "value": "Economics of Cinema"
        },
        {
            "key": "landing_page_abbreviation",
            "value": "economics-of-cinema"
        },
        {
            "key": "description",
            "value": null
        },
        {
            "key": "artwork",
            "value": [{"key":"images","value":placeholderImg}]
        },
        {
            "key": "masterlist",
            "value": "birju-maharaj"
        }
    ],
    [
        {
            "key": "alias",
            "value": "birju-maharaj-integrated-timeline"
        },
        {
            "key": "landing_page_title",
            "value": "Integrated Timeline"
        },
        {
            "key": "landing_page_abbreviation",
            "value": "integrated-timeline"
        },
        {
            "key": "description",
            "value": null
        },
        {
            "key": "artwork",
            "value": [{"key":"images","value":placeholderImg}]
        },
        {
            "key": "masterlist",
            "value": "birju-maharaj"
        }
    ],
    [
        {
            "key": "alias",
            "value": "birju-maharaj-research-centre"
        },
        {
            "key": "landing_page_title",
            "value": "Research Centre"
        },
        {
            "key": "landing_page_abbreviation",
            "value": "research-centre"
        },
        {
            "key": "description",
            "value": null
        },
        {
            "key": "artwork",
            "value": [{"key":"images","value":placeholderImg}]
        },
        {
            "key": "masterlist",
            "value": "birju-maharaj"
        }
    ]
]
export const dulal_dutta_landing_page_data=[
    [
        {
            "key": "alias",
            "value": "dulal-dutta-introduction"
        },
        {
            "key": "landing_page_title",
            "value": "Introduction"
        },
        {
            "key": "landing_page_abbreviation",
            "value": "introduction"
        },
        {
            "key": "description",
            "value": null
        },
        {
            "key": "artwork",
            "value": [{"key":"images","value":placeholderImg}]
        },
        {
            "key": "masterlist",
            "value": "dulal-dutta"
        }
    ],
    [
        {
            "key": "alias",
            "value": "dulal-dutta-body-of-work"
        },
        {
            "key": "landing_page_title",
            "value": "Body of Work"
        },
        {
            "key": "landing_page_abbreviation",
            "value": "body-of-work"
        },
        {
            "key": "description",
            "value": null
        },
        {
            "key": "artwork",
            "value": [{"key":"images","value":placeholderImg}]
        },
        {
            "key": "masterlist",
            "value": "dulal-dutta"
        }
    ],
    [
        {
            "key": "alias",
            "value": "dulal-dutta-economics-of-cinema"
        },
        {
            "key": "landing_page_title",
            "value": "Economics of Cinema"
        },
        {
            "key": "landing_page_abbreviation",
            "value": "economics-of-cinema"
        },
        {
            "key": "description",
            "value": null
        },
        {
            "key": "artwork",
            "value": [{"key":"images","value":placeholderImg}]
        },
        {
            "key": "masterlist",
            "value": "dulal-dutta"
        }
    ],
    [
        {
            "key": "alias",
            "value": "dulal-dutta-integrated-timeline"
        },
        {
            "key": "landing_page_title",
            "value": "Integrated Timeline"
        },
        {
            "key": "landing_page_abbreviation",
            "value": "integrated-timeline"
        },
        {
            "key": "description",
            "value": null
        },
        {
            "key": "artwork",
            "value": [{"key":"images","value":placeholderImg}]
        },
        {
            "key": "masterlist",
            "value": "dulal-dutta"
        }
    ],
    [
        {
            "key": "alias",
            "value": "dulal-dutta-research-centre"
        },
        {
            "key": "landing_page_title",
            "value": "Research Centre"
        },
        {
            "key": "landing_page_abbreviation",
            "value": "research-centre"
        },
        {
            "key": "description",
            "value": null
        },
        {
            "key": "artwork",
            "value": [{"key":"images","value":placeholderImg}]
        },
        {
            "key": "masterlist",
            "value": "dulal-dutta"
        }
    ]
]
export const soumendu_roy_landing_page_data=[
    [
        {
            "key": "alias",
            "value": "soumendu-roy-introduction"
        },
        {
            "key": "landing_page_title",
            "value": "Introduction"
        },
        {
            "key": "landing_page_abbreviation",
            "value": "introduction"
        },
        {
            "key": "description",
            "value": null
        },
        {
            "key": "artwork",
            "value": [{"key":"images","value":placeholderImg}]
        },
        {
            "key": "masterlist",
            "value": "soumendu-roy"
        }
    ],
    [
        {
            "key": "alias",
            "value": "soumendu-roy-body-of-work"
        },
        {
            "key": "landing_page_title",
            "value": "Body of Work"
        },
        {
            "key": "landing_page_abbreviation",
            "value": "body-of-work"
        },
        {
            "key": "description",
            "value": null
        },
        {
            "key": "artwork",
            "value": [{"key":"images","value":placeholderImg}]
        },
        {
            "key": "masterlist",
            "value": "soumendu-roy"
        }
    ],
    [
        {
            "key": "alias",
            "value": "soumendu-roy-economics-of-cinema"
        },
        {
            "key": "landing_page_title",
            "value": "Economics of Cinema"
        },
        {
            "key": "landing_page_abbreviation",
            "value": "economics-of-cinema"
        },
        {
            "key": "description",
            "value": null
        },
        {
            "key": "artwork",
            "value": [{"key":"images","value":placeholderImg}]
        },
        {
            "key": "masterlist",
            "value": "soumendu-roy"
        }
    ],
    [
        {
            "key": "alias",
            "value": "soumendu-roy-integrated-timeline"
        },
        {
            "key": "landing_page_title",
            "value": "Integrated Timeline"
        },
        {
            "key": "landing_page_abbreviation",
            "value": "integrated-timeline"
        },
        {
            "key": "description",
            "value": null
        },
        {
            "key": "artwork",
            "value": [{"key":"images","value":placeholderImg}]
        },
        {
            "key": "masterlist",
            "value": "soumendu-roy"
        }
    ],
    [
        {
            "key": "alias",
            "value": "soumendu-roy-research-centre"
        },
        {
            "key": "landing_page_title",
            "value": "Research Centre"
        },
        {
            "key": "landing_page_abbreviation",
            "value": "research-centre"
        },
        {
            "key": "description",
            "value": null
        },
        {
            "key": "artwork",
            "value": [{"key":"images","value":placeholderImg}]
        },
        {
            "key": "masterlist",
            "value": "soumendu-roy"
        }
    ]
]
export const bansi_chandragupta_landing_page_data=[
    [
        {
            "key": "alias",
            "value": "bansi-chandragupta-introduction"
        },
        {
            "key": "landing_page_title",
            "value": "Introduction"
        },
        {
            "key": "landing_page_abbreviation",
            "value": "introduction"
        },
        {
            "key": "description",
            "value": null
        },
        {
            "key": "artwork",
            "value": [{"key":"images","value":placeholderImg}]
        },
        {
            "key": "masterlist",
            "value": "bansi-chandragupta"
        }
    ],
    [
        {
            "key": "alias",
            "value": "bansi-chandragupta-body-of-work"
        },
        {
            "key": "landing_page_title",
            "value": "Body of Work"
        },
        {
            "key": "landing_page_abbreviation",
            "value": "body-of-work"
        },
        {
            "key": "description",
            "value": null
        },
        {
            "key": "artwork",
            "value": [{"key":"images","value":placeholderImg}]
        },
        {
            "key": "masterlist",
            "value": "bansi-chandragupta"
        }
    ],
    [
        {
            "key": "alias",
            "value": "bansi-chandragupta-economics-of-cinema"
        },
        {
            "key": "landing_page_title",
            "value": "Economics of Cinema"
        },
        {
            "key": "landing_page_abbreviation",
            "value": "economics-of-cinema"
        },
        {
            "key": "description",
            "value": null
        },
        {
            "key": "artwork",
            "value": [{"key":"images","value":placeholderImg}]
        },
        {
            "key": "masterlist",
            "value": "bansi-chandragupta"
        }
    ],
    [
        {
            "key": "alias",
            "value": "bansi-chandragupta-integrated-timeline"
        },
        {
            "key": "landing_page_title",
            "value": "Integrated Timeline"
        },
        {
            "key": "landing_page_abbreviation",
            "value": "integrated-timeline"
        },
        {
            "key": "description",
            "value": null
        },
        {
            "key": "artwork",
            "value": [{"key":"images","value":placeholderImg}]
        },
        {
            "key": "masterlist",
            "value": "bansi-chandragupta"
        }
    ],
    [
        {
            "key": "alias",
            "value": "bansi-chandragupta-research-centre"
        },
        {
            "key": "landing_page_title",
            "value": "Research Centre"
        },
        {
            "key": "landing_page_abbreviation",
            "value": "research-centre"
        },
        {
            "key": "description",
            "value": null
        },
        {
            "key": "artwork",
            "value": [{"key":"images","value":placeholderImg}]
        },
        {
            "key": "masterlist",
            "value": "bansi-chandragupta"
        }
    ]
]
export const nemai_ghosh_landing_page_data=[
    [
        {
            "key": "alias",
            "value": "nemai-ghosh-introduction"
        },
        {
            "key": "landing_page_title",
            "value": "Introduction"
        },
        {
            "key": "landing_page_abbreviation",
            "value": "introduction"
        },
        {
            "key": "description",
            "value": null
        },
        {
            "key": "artwork",
            "value": [{"key":"images","value":placeholderImg}]
        },
        {
            "key": "masterlist",
            "value": "nemai-ghosh"
        }
    ],
    [
        {
            "key": "alias",
            "value": "nemai-ghosh-body-of-work"
        },
        {
            "key": "landing_page_title",
            "value": "Body of Work"
        },
        {
            "key": "landing_page_abbreviation",
            "value": "body-of-work"
        },
        {
            "key": "description",
            "value": null
        },
        {
            "key": "artwork",
            "value": [{"key":"images","value":placeholderImg}]
        },
        {
            "key": "masterlist",
            "value": "nemai-ghosh"
        }
    ],
    [
        {
            "key": "alias",
            "value": "nemai-ghosh-economics-of-cinema"
        },
        {
            "key": "landing_page_title",
            "value": "Economics of Cinema"
        },
        {
            "key": "landing_page_abbreviation",
            "value": "economics-of-cinema"
        },
        {
            "key": "description",
            "value": null
        },
        {
            "key": "artwork",
            "value": [{"key":"images","value":placeholderImg}]
        },
        {
            "key": "masterlist",
            "value": "nemai-ghosh"
        }
    ],
    [
        {
            "key": "alias",
            "value": "nemai-ghosh-research-centre"
        },
        {
            "key": "landing_page_title",
            "value": "Research Centre"
        },
        {
            "key": "landing_page_abbreviation",
            "value": "research-centre"
        },
        {
            "key": "description",
            "value": null
        },
        {
            "key": "artwork",
            "value": [{"key":"images","value":placeholderImg}]
        },
        {
            "key": "masterlist",
            "value": "nemai-ghosh"
        }
    ]
]
export const sandip_ray_landing_page_data=[
    [
        {
            "key": "alias",
            "value": "sandip-ray-introduction"
        },
        {
            "key": "landing_page_title",
            "value": "Introduction"
        },
        {
            "key": "landing_page_abbreviation",
            "value": "introduction"
        },
        {
            "key": "description",
            "value": null
        },
        {
            "key": "artwork",
            "value": [{"key":"images","value":placeholderImg}]
        },
        {
            "key": "masterlist",
            "value": "sandip-ray"
        }
    ],
    [
        {
            "key": "alias",
            "value": "sandip-ray-body-of-work"
        },
        {
            "key": "landing_page_title",
            "value": "Body of Work"
        },
        {
            "key": "landing_page_abbreviation",
            "value": "body-of-work"
        },
        {
            "key": "description",
            "value": null
        },
        {
            "key": "artwork",
            "value": [{"key":"images","value":placeholderImg}]
        },
        {
            "key": "masterlist",
            "value": "sandip-ray"
        }
    ],
    [
        {
            "key": "alias",
            "value": "sandip-ray-economics-of-cinema"
        },
        {
            "key": "landing_page_title",
            "value": "Economics of Cinema"
        },
        {
            "key": "landing_page_abbreviation",
            "value": "economics-of-cinema"
        },
        {
            "key": "description",
            "value": null
        },
        {
            "key": "artwork",
            "value": [{"key":"images","value":placeholderImg}]
        },
        {
            "key": "masterlist",
            "value": "sandip-ray"
        }
    ],
    [
        {
            "key": "alias",
            "value": "sandip-ray-integrated-timeline"
        },
        {
            "key": "landing_page_title",
            "value": "Integrated Timeline"
        },
        {
            "key": "landing_page_abbreviation",
            "value": "integrated-timeline"
        },
        {
            "key": "description",
            "value": null
        },
        {
            "key": "artwork",
            "value": [{"key":"images","value":placeholderImg}]
        },
        {
            "key": "masterlist",
            "value": "sandip-ray"
        }
    ],
    [
        {
            "key": "alias",
            "value": "sandip-ray-research-centre"
        },
        {
            "key": "landing_page_title",
            "value": "Research Centre"
        },
        {
            "key": "landing_page_abbreviation",
            "value": "research-centre"
        },
        {
            "key": "description",
            "value": null
        },
        {
            "key": "artwork",
            "value": [{"key":"images","value":placeholderImg}]
        },
        {
            "key": "masterlist",
            "value": "sandip-ray"
        }
    ]
]
export const sumatra_ghoshal_landing_page_data=[
    [
        {
            "key": "alias",
            "value": "sumatra-ghoshal-introduction"
        },
        {
            "key": "landing_page_title",
            "value": "Introduction"
        },
        {
            "key": "landing_page_abbreviation",
            "value": "introduction"
        },
        {
            "key": "description",
            "value": null
        },
        {
            "key": "artwork",
            "value": [{"key":"images","value":placeholderImg}]
        },
        {
            "key": "masterlist",
            "value": "sumatra-ghoshal"
        }
    ],
    [
        {
            "key": "alias",
            "value": "sumatra-ghoshal-body-of-work"
        },
        {
            "key": "landing_page_title",
            "value": "Body of Work"
        },
        {
            "key": "landing_page_abbreviation",
            "value": "body-of-work"
        },
        {
            "key": "description",
            "value": null
        },
        {
            "key": "artwork",
            "value": [{"key":"images","value":placeholderImg}]
        },
        {
            "key": "masterlist",
            "value": "sumatra-ghoshal"
        }
    ],
    [
        {
            "key": "alias",
            "value": "sumatra-ghoshal-economics-of-cinema"
        },
        {
            "key": "landing_page_title",
            "value": "Economics of Cinema"
        },
        {
            "key": "landing_page_abbreviation",
            "value": "economics-of-cinema"
        },
        {
            "key": "description",
            "value": null
        },
        {
            "key": "artwork",
            "value": [{"key":"images","value":placeholderImg}]
        },
        {
            "key": "masterlist",
            "value": "sumatra-ghoshal"
        }
    ],
    [
        {
            "key": "alias",
            "value": "sumatra-ghoshal-integrated-timeline"
        },
        {
            "key": "landing_page_title",
            "value": "Integrated Timeline"
        },
        {
            "key": "landing_page_abbreviation",
            "value": "integrated-timeline"
        },
        {
            "key": "description",
            "value": null
        },
        {
            "key": "artwork",
            "value": [{"key":"images","value":placeholderImg}]
        },
        {
            "key": "masterlist",
            "value": "sumatra-ghoshal"
        }
    ],
    [
        {
            "key": "alias",
            "value": "sumatra-ghoshal-research-centre"
        },
        {
            "key": "landing_page_title",
            "value": "Research Centre"
        },
        {
            "key": "landing_page_abbreviation",
            "value": "research-centre"
        },
        {
            "key": "description",
            "value": null
        },
        {
            "key": "artwork",
            "value": [{"key":"images","value":placeholderImg}]
        },
        {
            "key": "masterlist",
            "value": "sumatra-ghoshal"
        }
    ]
]
export const pablo_bartholomew_landing_page_data=[
    [
        {
            "key": "alias",
            "value": "pablo-bartholomew-introduction"
        },
        {
            "key": "landing_page_title",
            "value": "Introduction"
        },
        {
            "key": "landing_page_abbreviation",
            "value": "introduction"
        },
        {
            "key": "description",
            "value": null
        },
        {
            "key": "artwork",
            "value": [{"key":"images","value":placeholderImg}]
        },
        {
            "key": "masterlist",
            "value": "pablo-bartholomew"
        }
    ],
    [
        {
            "key": "alias",
            "value": "pablo-bartholomew-body-of-work"
        },
        {
            "key": "landing_page_title",
            "value": "Body of Work"
        },
        {
            "key": "landing_page_abbreviation",
            "value": "body-of-work"
        },
        {
            "key": "description",
            "value": null
        },
        {
            "key": "artwork",
            "value": [{"key":"images","value":placeholderImg}]
        },
        {
            "key": "masterlist",
            "value": "pablo-bartholomew"
        }
    ],
    [
        {
            "key": "alias",
            "value": "pablo-bartholomew-economics-of-cinema"
        },
        {
            "key": "landing_page_title",
            "value": "Economics of Cinema"
        },
        {
            "key": "landing_page_abbreviation",
            "value": "economics-of-cinema"
        },
        {
            "key": "description",
            "value": null
        },
        {
            "key": "artwork",
            "value": [{"key":"images","value":placeholderImg}]
        },
        {
            "key": "masterlist",
            "value": "pablo-bartholomew"
        }
    ],
    [
        {
            "key": "alias",
            "value": "pablo-bartholomew-integrated-timeline"
        },
        {
            "key": "landing_page_title",
            "value": "Integrated Timeline"
        },
        {
            "key": "landing_page_abbreviation",
            "value": "integrated-timeline"
        },
        {
            "key": "description",
            "value": null
        },
        {
            "key": "artwork",
            "value": [{"key":"images","value":placeholderImg}]
        },
        {
            "key": "masterlist",
            "value": "pablo-bartholomew"
        }
    ],
    [
        {
            "key": "alias",
            "value": "pablo-bartholomew-research-centre"
        },
        {
            "key": "landing_page_title",
            "value": "Research Centre"
        },
        {
            "key": "landing_page_abbreviation",
            "value": "research-centre"
        },
        {
            "key": "description",
            "value": null
        },
        {
            "key": "artwork",
            "value": [{"key":"images","value":placeholderImg}]
        },
        {
            "key": "masterlist",
            "value": "pablo-bartholomew"
        }
    ]
]
export const amitabh_bachchan_landing_page_data=[
    [
        {
            "key": "alias",
            "value": "amitabh-bachchan-introduction"
        },
        {
            "key": "landing_page_title",
            "value": "Introduction"
        },
        {
            "key": "landing_page_abbreviation",
            "value": "introduction"
        },
        {
            "key": "description",
            "value": null
        },
        {
            "key": "artwork",
            "value": [{"key":"images","value":AmitabhBachchan1}]
        },
        {
            "key": "masterlist_alias",
            "value": "amitabh-bachchan"
        }
    ],
    [
        {
            "key": "alias",
            "value": "amitabh-bachchan-body-of-work"
        },
        {
            "key": "landing_page_title",
            "value": "Body of Work"
        },
        {
            "key": "landing_page_abbreviation",
            "value": "body-of-work"
        },
        {
            "key": "description",
            "value": null
        },
        {
            "key": "artwork",
            "value": [{"key":"images","value":AmitabhBachchan2}]
        },
        {
            "key": "masterlist_alias",
            "value": "amitabh-bachchan"
        }
    ],
    [
        {
            "key": "alias",
            "value": "amitabh-bachchan-integrated-timeline"
        },
        {
            "key": "landing_page_title",
            "value": "Integrated Timeline"
        },
        {
            "key": "landing_page_abbreviation",
            "value": "integrated-timeline"
        },
        {
            "key": "description",
            "value": null
        },
        {
            "key": "artwork",
            "value": [{"key":"images","value":placeholderImg}]
        },
        {
            "key": "masterlist_alias",
            "value": "amitabh-bachchan"
        }
    ],
    [
        {
            "key": "alias",
            "value": "amitabh-bachchan-economics-of-cinema"
        },
        {
            "key": "landing_page_title",
            "value": "Economics of Cinema"
        },
        {
            "key": "landing_page_abbreviation",
            "value": "economics-of-cinema"
        },
        {
            "key": "description",
            "value": null
        },
        {
            "key": "artwork",
            "value": [{"key":"images","value":AmitabhBachchan3}]
        },
        {
            "key": "masterlist_alias",
            "value": "amitabh-bachchan"
        }
    ],
    [
        {
            "key": "alias",
            "value": "amitabh-bachchan-research-centre"
        },
        {
            "key": "landing_page_title",
            "value": "Research Centre"
        },
        {
            "key": "landing_page_abbreviation",
            "value": "research-centre"
        },
        {
            "key": "description",
            "value": null
        },
        {
            "key": "artwork",
            "value": [{"key":"images","value":AmitabhBachchan4}]
        },
        {
            "key": "masterlist_alias",
            "value": "amitabh-bachchan"
        }
    ]
]
export const saswati_sen_landing_page_data=[
    [
        {
            "key": "alias",
            "value": "saswati-sen-introduction"
        },
        {
            "key": "landing_page_title",
            "value": "Introduction"
        },
        {
            "key": "landing_page_abbreviation",
            "value": "introduction"
        },
        {
            "key": "description",
            "value": null
        },
        {
            "key": "artwork",
            "value": [{"key":"images","value":placeholderImg}]
        },
        {
            "key": "masterlist",
            "value": "saswati-sen"
        }
    ],
    [
        {
            "key": "alias",
            "value": "saswati-sen-body-of-work"
        },
        {
            "key": "landing_page_title",
            "value": "Body of Work"
        },
        {
            "key": "landing_page_abbreviation",
            "value": "body-of-work"
        },
        {
            "key": "description",
            "value": null
        },
        {
            "key": "artwork",
            "value": [{"key":"images","value":placeholderImg}]
        },
        {
            "key": "masterlist",
            "value": "saswati-sen"
        }
    ],
    [
        {
            "key": "alias",
            "value": "saswati-sen-economics-of-cinema"
        },
        {
            "key": "landing_page_title",
            "value": "Economics of Cinema"
        },
        {
            "key": "landing_page_abbreviation",
            "value": "economics-of-cinema"
        },
        {
            "key": "description",
            "value": null
        },
        {
            "key": "artwork",
            "value": [{"key":"images","value":placeholderImg}]
        },
        {
            "key": "masterlist",
            "value": "saswati-sen"
        }
    ],
    [
        {
            "key": "alias",
            "value": "saswati-sen-integrated-timeline"
        },
        {
            "key": "landing_page_title",
            "value": "Integrated Timeline"
        },
        {
            "key": "landing_page_abbreviation",
            "value": "integrated-timeline"
        },
        {
            "key": "description",
            "value": null
        },
        {
            "key": "artwork",
            "value": [{"key":"images","value":placeholderImg}]
        },
        {
            "key": "masterlist",
            "value": "saswati-sen"
        }
    ],
    [
        {
            "key": "alias",
            "value": "saswati-sen-research-centre"
        },
        {
            "key": "landing_page_title",
            "value": "Research Centre"
        },
        {
            "key": "landing_page_abbreviation",
            "value": "research-centre"
        },
        {
            "key": "description",
            "value": null
        },
        {
            "key": "artwork",
            "value": [{"key":"images","value":placeholderImg}]
        },
        {
            "key": "masterlist",
            "value": "saswati-sen"
        }
    ]
]

export const song_synopsis_booklet_landing_page_data=[
    [
        {
            "key": "alias",
            "value": "song-synopsis-booklet-introduction"
        },
        {
            "key": "landing_page_title",
            "value": "Introduction"
        },
        {
            "key": "landing_page_abbreviation",
            "value": "introduction"
        },
        {
            "key": "description",
            "value": null
        },
        {
            "key": "artwork",
            "value": [{"key":"images","value":songbook1}]
        },
        {
            "key": "masterlist",
            "value": "song-synopsis-booklet"
        }
    ],
    [
        {
            "key": "alias",
            "value": "song-synopsis-booklet-body-of-work"
        },
        {
            "key": "landing_page_title",
            "value": "Body of Work"
        },
        {
            "key": "landing_page_abbreviation",
            "value": "body-of-work"
        },
        {
            "key": "description",
            "value": null
        },
        {
            "key": "artwork",
            "value": [{"key":"images","value":songbook2}]
        },
        {
            "key": "masterlist",
            "value": "song-synopsis-booklet"
        }
    ],
    [
        {
            "key": "alias",
            "value": "song-synopsis-booklet-research-centre"
        },
        {
            "key": "landing_page_title",
            "value": "Research Centre"
        },
        {
            "key": "landing_page_abbreviation",
            "value": "research-centre"
        },
        {
            "key": "description",
            "value": null
        },
        {
            "key": "artwork",
            "value": [{"key":"images","value":songbook3}]
        },
        {
            "key": "masterlist",
            "value": "song-synopsis-booklet"
        }
    ]
]

export const pictorial_space_landing_page_data=[
    [
        {
            "key": "alias",
            "value": "pictorial-space-introduction"
        },
        {
            "key": "landing_page_title",
            "value": "Introduction"
        },
        {
            "key": "landing_page_abbreviation",
            "value": "introduction"
        },
        {
            "key": "description",
            "value": null
        },
        {
            "key": "artwork",
            "value": [{"key":"images","value":PictorialSpaceIntro}]
        },
        {
            "key": "masterlist",
            "value": "pictorial-space"
        }
    ],
    [
        {
            "key": "alias",
            "value": "pictorial-space-body-of-work"
        },
        {
            "key": "landing_page_title",
            "value": "Body of Work"
        },
        {
            "key": "landing_page_abbreviation",
            "value": "body-of-work"
        },
        {
            "key": "description",
            "value": null
        },
        {
            "key": "artwork",
            "value": [{"key":"images","value":PictorialSpaceBoW}]
        },
        {
            "key": "masterlist",
            "value": "pictorial-space"
        }
    ],
    [
        {
            "key": "alias",
            "value": "pictorial-space-economics-of-art"
        },
        {
            "key": "landing_page_title",
            "value": "Economics of Art"
        },
        {
            "key": "landing_page_abbreviation",
            "value": "economics-of-art"
        },
        {
            "key": "description",
            "value": null
        },
        {
            "key": "artwork",
            "value": [{"key":"images","value":PictorialSpaceEcon}]
        },
        {
            "key": "masterlist",
            "value": "pictorial-space"
        }
    ],
    [
        {
            "key": "alias",
            "value": "pictorial-space-research-centre"
        },
        {
            "key": "landing_page_title",
            "value": "Research Centre"
        },
        {
            "key": "landing_page_abbreviation",
            "value": "research-centre"
        },
        {
            "key": "description",
            "value": null
        },
        {
            "key": "artwork",
            "value": [{"key":"images","value":PictorialSpaceRC}]
        },
        {
            "key": "masterlist",
            "value": "pictorial-space"
        }
    ]
]

export const lucknow_landing_page_data=[
    [
        {
            "key": "alias",
            "value": "introduction"
        },
        {
            "key": "landing_page_title",
            "value": "Introduction"
        },
        {
            "key": "landing_page_abbreviation",
            "value": "introduction"
        },
        {
            "key": "description",
            "value": null
        },
        {
            "key": "artwork",
            "value": [{"key":"images","value":lucknow1}]
        },
        {
            "key": "masterlist",
            "value": "lucknow"
        }
    ],
    [
        {
            "key": "alias",
            "value": "body-of-work"
        },
        {
            "key": "landing_page_title",
            "value": "Body of Work"
        },
        {
            "key": "landing_page_abbreviation",
            "value": "body-of-work"
        },
        {
            "key": "description",
            "value": null
        },
        {
            "key": "artwork",
            "value": [{"key":"images","value":lucknow2}]
        },
        {
            "key": "masterlist",
            "value": "lucknow"
        }
    ],
    [
        {
            "key": "alias",
            "value": "economics-of-culture"
        },
        {
            "key": "landing_page_title",
            "value": "Economics of Culture"
        },
        {
            "key": "landing_page_abbreviation",
            "value": "economics-of-culture"
        },
        {
            "key": "description",
            "value": null
        },
        {
            "key": "artwork",
            "value": [{"key":"images","value":lucknow3}]
        },
        {
            "key": "masterlist",
            "value": "lucknow"
        }
    ],
    [
        {
            "key": "alias",
            "value": "research-centre"
        },
        {
            "key": "landing_page_title",
            "value": "Research Centre"
        },
        {
            "key": "landing_page_abbreviation",
            "value": "research-centre"
        },
        {
            "key": "description",
            "value": null
        },
        {
            "key": "artwork",
            "value": [{"key":"images","value":lucknow4}]
        },
        {
            "key": "masterlist",
            "value": "lucknow"
        }
    ]
]

export const raj_kapoor_landing_page_data=[
    [
        {
            "key": "alias",
            "value": "raj-kapoor-introduction"
        },
        {
            "key": "landing_page_title",
            "value": "Introduction"
        },
        {
            "key": "landing_page_abbreviation",
            "value": "introduction"
        },
        {
            "key": "description",
            "value": null
        },
        {
            "key": "artwork",
            "value": [{"key":"images","value":RajKapoor1}]
        },
        {
            "key": "masterlist",
            "value": "raj-kapoor"
        }
    ],
    [
        {
            "key": "alias",
            "value": "raj-kapoor-body-of-work"
        },
        {
            "key": "landing_page_title",
            "value": "Body of Work"
        },
        {
            "key": "landing_page_abbreviation",
            "value": "body-of-work"
        },
        {
            "key": "description",
            "value": null
        },
        {
            "key": "artwork",
            "value": [{"key":"images","value":RajKapoor2}]
        },
        {
            "key": "masterlist",
            "value": "raj-kapoor"
        }
    ],
    [
        {
            "key": "alias",
            "value": "raj-kapoor-economics-of-cinema"
        },
        {
            "key": "landing_page_title",
            "value": "Economics of Cinema"
        },
        {
            "key": "landing_page_abbreviation",
            "value": "economics-of-cinema"
        },
        {
            "key": "description",
            "value": null
        },
        {
            "key": "artwork",
            "value": [{"key":"images","value":RajKapoor3}]
        },
        {
            "key": "masterlist",
            "value": "raj-kapoor"
        }
    ],
    [
        {
            "key": "alias",
            "value": "raj-kapoor-research-centre"
        },
        {
            "key": "landing_page_title",
            "value": "Research Centre"
        },
        {
            "key": "landing_page_abbreviation",
            "value": "research-centre"
        },
        {
            "key": "description",
            "value": null
        },
        {
            "key": "artwork",
            "value": [{"key":"images","value":RajKapoor4}]
        },
        {
            "key": "masterlist",
            "value": "raj-kapoor"
        }
    ]
]
export const kg_subramanayam_landing_page_data=[
    [
        {
            "key": "alias",
            "value": "k.g-Subramanyan-introduction"
        },
        {
            "key": "landing_page_title",
            "value": "Introduction"
        },
        {
            "key": "landing_page_abbreviation",
            "value": "introduction"
        },
        {
            "key": "description",
            "value": null
        },
        {
            "key": "artwork",
            "value": [{"key":"images","value":KGSubra1}]
        },
        {
            "key": "masterlist",
            "value": "k.g-Subramanyan"
        }
    ],
    [
        {
            "key": "alias",
            "value": "k.g-Subramanyan-body-of-work"
        },
        {
            "key": "landing_page_title",
            "value": "Body of Work"
        },
        {
            "key": "landing_page_abbreviation",
            "value": "body-of-work"
        },
        {
            "key": "description",
            "value": null
        },
        {
            "key": "artwork",
            "value": [{"key":"images","value":KGSubra2}]
        },
        {
            "key": "masterlist",
            "value": "k.g-Subramanyan"
        }
    ],
    [
        {
            "key": "alias",
            "value": "k.g-Subramanyan-economics-of-art"
        },
        {
            "key": "landing_page_title",
            "value": "Economics of Art"
        },
        {
            "key": "landing_page_abbreviation",
            "value": "economics-of-art"
        },
        {
            "key": "description",
            "value": null
        },
        {
            "key": "artwork",
            "value": [{"key":"images","value":KGSubra3}]
        },
        {
            "key": "masterlist",
            "value": "k.g-Subramanyan"
        }
    ],
    [
        {
            "key": "alias",
            "value": "k.g-Subramanyan-research-centre"
        },
        {
            "key": "landing_page_title",
            "value": "Research Centre"
        },
        {
            "key": "landing_page_abbreviation",
            "value": "research-centre"
        },
        {
            "key": "description",
            "value": null
        },
        {
            "key": "artwork",
            "value": [{"key":"images","value":KGSubra4}]
        },
        {
            "key": "masterlist",
            "value": "k.g-Subramanyan"
        }
    ]
]

export const calcutta_landing_page_data=[
    [
        {
            "key": "alias",
            "value": ""
        },
        {
            "key": "landing_page_title",
            "value": "Introduction"
        },
        {
            "key": "landing_page_abbreviation",
            "value": "introduction"
        },
        {
            "key": "description",
            "value": null
        },
        {
            "key": "artwork",
            "value": [{"key":"images","value":calcutta1}]
        },
        {
            "key": "masterlist",
            "value": "calcutta"
        }
    ],
    [
        {
            "key": "alias",
            "value": ""
        },
        {
            "key": "landing_page_title",
            "value": "Body of Work"
        },
        {
            "key": "landing_page_abbreviation",
            "value": "body-of-work"
        },
        {
            "key": "description",
            "value": null
        },
        {
            "key": "artwork",
            "value": [{"key":"images","value":calcutta2}]
        },
        {
            "key": "masterlist",
            "value": "calcutta"
        }
    ],
    [
        {
            "key": "alias",
            "value": ""
        },
        {
            "key": "landing_page_title",
            "value": "Economics of Culture"
        },
        {
            "key": "landing_page_abbreviation",
            "value": "economics-of-culture"
        },
        {
            "key": "description",
            "value": null
        },
        {
            "key": "artwork",
            "value": [{"key":"images","value":calcutta3}]
        },
        {
            "key": "masterlist",
            "value": "calcutta"
        }
    ],
    [
        {
            "key": "alias",
            "value": "research-centre"
        },
        {
            "key": "landing_page_title",
            "value": "Research Centre"
        },
        {
            "key": "landing_page_abbreviation",
            "value": "research-centre"
        },
        {
            "key": "description",
            "value": null
        },
        {
            "key": "artwork",
            "value": [{"key":"images","value":calcutta4}]
        },
        {
            "key": "masterlist",
            "value": "calcutta"
        }
    ]
]

export const default_landing_page_data=[
    [
        {
            "key": "alias",
            "value": "introduction"
        },
        {
            "key": "landing_page_title",
            "value": "Introduction"
        },
        {
            "key": "landing_page_abbreviation",
            "value": "introduction"
        },
        {
            "key": "description",
            "value": null
        },
        {
            "key": "artwork",
            "value": [{"key":"images","value":placeholderImg}]
        },
        {
            "key": "masterlist",
            "value": ""
        }
    ],
    [
        {
            "key": "alias",
            "value": "body-of-work"
        },
        {
            "key": "landing_page_title",
            "value": "Body of Work"
        },
        {
            "key": "landing_page_abbreviation",
            "value": "body-of-work"
        },
        {
            "key": "description",
            "value": null
        },
        {
            "key": "artwork",
            "value": [{"key":"images","value":placeholderImg}]
        },
        {
            "key": "masterlist",
            "value": ""
        }
    ],
    [
        {
            "key": "alias",
            "value": "economics-of-cinema"
        },
        {
            "key": "landing_page_title",
            "value": "Economics of Cinema"
        },
        {
            "key": "landing_page_abbreviation",
            "value": "economics-of-cinema"
        },
        {
            "key": "description",
            "value": null
        },
        {
            "key": "artwork",
            "value": [{"key":"images","value":placeholderImg}]
        },
        {
            "key": "masterlist",
            "value": ""
        }
    ],
    [
        {
            "key": "alias",
            "value": "research-centre"
        },
        {
            "key": "landing_page_title",
            "value": "Research Centre"
        },
        {
            "key": "landing_page_abbreviation",
            "value": "research-centre"
        },
        {
            "key": "description",
            "value": null
        },
        {
            "key": "artwork",
            "value": [{"key":"images","value":placeholderImg}]
        },
        {
            "key": "masterlist",
            "value": ""
        }
    ]
]
export const sanjeev_kumar_landing_page_data = [
    [
      { key: "alias", value: "sanjeev-kumar-introduction" },
      { key: "landing_page_title", value: "Introduction" },
      { key: "landing_page_abbreviation", value: "introduction" },
      { key: "description", value: null },
      { key: "artwork", value: [{ key: "images", value: bannerImg }] },
      { key: "masterlist", value: "sanjeev-kumar" },
    ],
    [
      { key: "alias", value: "sanjeev-kumar-body-of-work" },
      { key: "landing_page_title", value: "Body of Work" },
      { key: "landing_page_abbreviation", value: "body-of-work" },
      { key: "description", value: null },
      { key: "artwork", value: [{ key: "images", value: bannerImg }] },
      { key: "masterlist", value: "sanjeev-kumar" },
    ],
    [
      { key: "alias", value: "sanjeev-kumar-economics-of-cinema" },
      { key: "landing_page_title", value: "Economics of Cinema" },
      { key: "landing_page_abbreviation", value: "economics-of-cinema" },
      { key: "description", value: null },
      { key: "artwork", value: [{ key: "images", value: Banner3 }] },
      { key: "masterlist", value: "sanjeev-kumar" },
    ],
    [
      { key: "alias", value: "sanjeev-kumar-research-centre" },
      { key: "landing_page_title", value: "Research Centre" },
      { key: "landing_page_abbreviation", value: "research-centre" },
      { key: "description", value: null },
      { key: "artwork", value: [{ key: "images", value: bannerImg }] },
      { key: "masterlist", value: "sanjeev-kumar" },
    ],
  ];


export const shammi_kapoor_landing_page_data=[
    [
        {
            "key": "alias",
            "value": "shammi-kapoor-introduction"
        },
        {
            "key": "landing_page_title",
            "value": "Introduction"
        },
        {
            "key": "landing_page_abbreviation",
            "value": "introduction"
        },
        {
            "key": "description",
            "value": null
        },
        {
            "key": "artwork",
            "value": [{"key":"images","value":ShammiKapoor1}]
        },
        {
            "key": "masterlist",
            "value": "shammi-kapoor"
        }
    ],
    [
        {
            "key": "alias",
            "value": "shammi-kapoor-body-of-work"
        },
        {
            "key": "landing_page_title",
            "value": "Body of Work"
        },
        {
            "key": "landing_page_abbreviation",
            "value": "body-of-work"
        },
        {
            "key": "description",
            "value": null
        },
        {
            "key": "artwork",
            "value": [{"key":"images","value":ShammiKapoor2}]
        },
        {
            "key": "masterlist",
            "value": "shammi-kapoor"
        }
    ],
    [
        {
            "key": "alias",
            "value": "shammi-kapoor-economics-of-cinema"
        },
        {
            "key": "landing_page_title",
            "value": "Economics of Cinema"
        },
        {
            "key": "landing_page_abbreviation",
            "value": "economics-of-cinema"
        },
        {
            "key": "description",
            "value": null
        },
        {
            "key": "artwork",
            "value": [{"key":"images","value":ShammiKapoor3}]
        },
        {
            "key": "masterlist",
            "value": "shammi-kapoor"
        }
    ],
    [
        {
            "key": "alias",
            "value": "shammi-kapoor-research-centre"
        },
        {
            "key": "landing_page_title",
            "value": "Research Centre"
        },
        {
            "key": "landing_page_abbreviation",
            "value": "research-centre"
        },
        {
            "key": "description",
            "value": null
        },
        {
            "key": "artwork",
            "value": [{"key":"images","value":ShammiKapoor4}]
        },
        {
            "key": "masterlist",
            "value": "shammi-kapoor"
        }
    ]
]