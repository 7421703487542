import { HStack, Image, Stack, Text } from '@chakra-ui/react'
import React from 'react'
import { Link } from 'react-router-dom'
import Large from '../../components/layouts/Typography/Large'
import { AiOutlineRight } from '@react-icons/all-files/ai/AiOutlineRight'
import Small from '../../components/layouts/Typography/Small'

export default function DrawerBody({data}) {
  return (
    <>{data.links &&
        data.links.map((it) => (
          <Link to={it.link}>
          <Stack
            pb="16px"
            justifyContent="space-between"
            flexDirection="row"
          >
            <HStack>
              <Large
                cursor="pointer"
                color="color.blue"
              >
                {it.link_name}
              </Large>
            </HStack>
            <HStack marginBottom={"0px"}>
              <Large color="color.blue">
                <AiOutlineRight />
              </Large>
            </HStack>
          </Stack>
          </Link>
        ))}
      <Stack>
        {data.Image && (
          <HStack py={4}>
            <Image
              cursor={"pointer"}
              src={data.Image}
            />
          </HStack>
        )}
        <HStack px={2}>
          <Text fontSize="12px" fontWeight="400" lineHeight={"15px"}>
            {data.desc}
          </Text>
        </HStack>
        <HStack justifyContent={"flex-start"} pt={"2px"}>
          <Link to={data.masterlist.link} onFocus={() => {}}>
            <Small color="color.blue">{data.masterlist.text}</Small>
          </Link>
        </HStack>
      </Stack>
      </>
  )
}

