import { HStack, Stack } from "@chakra-ui/react";
import React from "react";
import Large from "../layouts/Typography/Large";
import LastCommit from '../../util/gitUtils';
import { siteInfo } from "../../constants/constants";

function Footer() {
  return (
    <Stack alignItems="center" p="4" bg="black">
      <HStack flexDirection={"column"}>
        <Large color={"white"}>{siteInfo.SITE_COPY_RIGHTS}</Large>;
        <LastCommit
          color={"white"}
          repositoryhost={siteInfo.REPOSITORY_HOST}
          projectId={siteInfo.PROJECTID}
          accessToken={siteInfo.ACCESSTOKEN}
          branchName={siteInfo.BRANCHNAME}
        />
      </HStack>
    </Stack>
  );
}
export default Footer;
