import { Button, HStack, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Stack } from '@chakra-ui/react';
import React from 'react';
import H1 from '../../Theme/Typography/H1';
import H3 from '../../Theme/Typography/H3';
import { userRegistrationForm } from '../../constants/constants';

const CustomModal = ({ data, isOpen, onClose, nextStep }) => {
  const handleConfirm = () => {
    if (data.core !== "" && data.critical !== "" && data.material !== "" && data.meaningful !== "") {
      nextStep();
    }
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay opacity={"0.5!important"} />
      <ModalContent top={{base:"5%",md:"25%"}} maxH="auto" maxW={{base:"auto",md:"656px"}}>
        <ModalHeader pt="8"><H1  fontSize={{base:"22px",md:"24px"}}  fontWeight="400" color="black" lineHeight="28.18px">{userRegistrationForm.YOU_HAVE_CHOOSEN}</H1></ModalHeader>
       {data &&  <ModalBody>
        <H3 py="2">{data?.core}</H3>
        <H3 py="2">{data?.material}</H3>
          <H3 py="2">{data?.critical}</H3>
          <H3 py="2">{data?.meaningful}</H3>
        </ModalBody>}
        <ModalFooter justifyContent={"center"}>
          <Stack alignItems={"center"}>
            <H1  fontSize={{base:"22px",md:"24px"}}>{userRegistrationForm.DO_YOU_WISH_TO_CONFIRM}</H1>
            <HStack spacing={{base:"4",md:"16"}} py="4" justifyContent={"center"}>
              <Button variant="custom" px={"60px"} onClick={handleConfirm}>
                <H1>{userRegistrationForm.YES}</H1>
              </Button>
              <Button variant="" bg="color.lightbrown" px={"60px"} onClick={onClose}> <H1>{userRegistrationForm.NO}</H1></Button>
            </HStack>
          </Stack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CustomModal;
