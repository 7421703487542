import { Text } from "@chakra-ui/react";
import React from "react";

const MediumRegular = ({ children, lineHeight="16px", ...props }) => {
  return (
    <Text
      as="p"
      {...props}
      fontSize="14px"
      fontWeight="500"
      lineHeight={lineHeight}
      textStyle={"primary"}
    >
      {children}
    </Text>
  );
};
export default React.memo(MediumRegular);
