import { Box, Image, SimpleGrid, Stack } from "@chakra-ui/react";
import { getSocailMediaData } from "../services/SocialMediaService";
import React, { useState } from "react";

export const SocialMediaButton = () => {
  const [socialdata, setSocialData] = useState([]);

  React.useEffect(() => {
    const getData = async () => {
      const result = await getSocailMediaData();
      if (result) {
        setSocialData(result);
      }
    };
    getData();
  }, []);
  return (
    <Stack>
      <SimpleGrid
        columns={[6, null, 6]}
        gridTemplateColumns={"repeat(6, minmax(0, 1fr))"}
        spacing={"20px"}
      >
        {socialdata.length > 0 &&
          socialdata.map((item, index) => (
            <Box key={index} display={"flex"} width={"21px"} height={"22px"} flexDirection={"row"}>
              <Image src={item.image} width={"100%"} objectFit={"contain"} height={"inherit"}  />
            </Box>
          ))}
      </SimpleGrid>
    </Stack>
  );
};
