// Core Files
import { Container, HStack, Stack, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

// Service Files
import * as getFilmCastData from "../../../../../../services/Film.js";
import * as getFilmCrewData from "../../../../../../services/Crew.js";

// Subsection Files
import ImageGridList from "./ImageGridList.js";
import CommonDrawer from "../../Components/CommonDrawer.js";
import { isArray } from "lodash";
import { common } from "../../../../../../constants/constants.js";

//S3 Bucket osw-dev-images
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;

const Img = `${IMAGE_BASE_PATH}/ui/Film/ShatranjKeKhilari/BodyOfWork/Editorial/3.png`
const SKK1 = `${IMAGE_BASE_PATH}/ui/Film/ShatranjKeKhilari/TitleImage/small.png`

function HorizontalLayout({ winnerName, tableName, masterlistType }) {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [drawerOpenRay, setDrawerOpenRay] = useState(false);
  console.log(drawerOpenRay);
  const navigate = useNavigate();
  let { tabIndex, drawerId } = useParams();
  useEffect(() => {
    if (tabIndex) {
      navigate(`/${masterlistType}/${winnerName}/body-of-work`, { replace: true });

      if (drawerId === "satyajit") {
        setDrawerOpenRay(true);
      }
      if (drawerId === "sanjeev") {
        setDrawerOpen(true);
      }
    }
  }, [tabIndex, navigate, drawerId, winnerName, masterlistType]);
  //key value
  const [castData, setCastData] = useState([{}]);
  const [crewData, setCrewData] = useState([{}]);

  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const getData = async () => {
      const result = await getFilmCastData.filmCastData(masterlistType, winnerName);
      if (result?.length > 0) {
        setLoading(false);
        setCastData(
          result
            ?.map((item) => {
              if (item?.key !== "alias") {
                return {
                  display: item?.display,
                  anothervalue: item?.value,
                };
              }
              return null; // Skip the "alias" key
            })
            .filter(Boolean)
        );
      } else {
        setLoading(true);
      }
    };

    const getCrewData = async () => {
      const result = await getFilmCrewData.getFilmCrewsByMasterlist(
        winnerName
      );

      if (result?.length > 0) {
        setLoading(false);
        setCrewData(
          result
            .map((item) => {
              return {
                image: item?.find(
                  (nestedItem) => nestedItem?.key === "characterImage"
                )?.value[0].value,
                subTitle: `as ${
                  item?.find(
                    (nestedItem) => nestedItem?.key === "character_name"
                  )?.value
                }`,
                title: item?.find(
                  (nestedItem) => nestedItem?.key === "artist_name"
                )?.value,
                link: item?.find(
                  (nestedItem) => nestedItem?.key === "artist_name"
                )?.link,
                alias:item?.find(
                  (nestedItem) => nestedItem?.key === "artist_name"
                )?.alias
              };
            })
            .filter(Boolean)
        );
      } else {
        setLoading(true);
      }
    };
    getData();
    getCrewData();
  }, [tableName, winnerName, masterlistType]);
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  //Drawer Data basis on name:

  const [drawerData, setDrawerData] = useState(null);
  const [crewName, setCrewName] = useState(null);
  const drawerFormation = (data) => {
    return {
      name: data.full_name || data.title, 
      links: [
        { link_name: "Working Still", link: "" },
        { link_name: "Film Stills", link: "" },
        {
          link_name: "Books",
          link: `/film/${winnerName}/research-centre/1/1/${data?.first_name || data.title }`,
        },
      ],
      Image: Img,
      desc: "Nemai Ghosh, Shatranj Ke Khilari | 1977 | Working Still | CINE.wks",
      masterlist: {
        text: `Visit ${data?.full_name || data.title} Masterlist →`,
        link: `/person-poly/${data?.alias}`,
      },
    };
  };

  useEffect(() => {
    if (crewName) {
      setDrawerData(() => drawerFormation(crewName));
      setDrawerOpen(true);
    }else{
      setDrawerData(()=>null)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [crewName, winnerName]);


  const handleCastDrawer=(item)=>{
    setCrewName(item)
    setDrawerOpen(true)

  }

  return (
    <Stack position={"relative"} >
      <Stack
        width={{ base: "100%", md: "100%" }}
        py={4}  
        flexDirection={"row-reverse"}
      >
        {drawerData !== undefined && drawerData!==null && (
          <CommonDrawer
            setDrawerOpen={setDrawerOpen}
            setCrewName={setCrewName}
            drawerOpen={drawerOpen}
            data={drawerData}
            drawerImage={SKK1}
          />
        )}
        <Container maxW="auto" px="0">
          <HStack>
            <Text className="wordspacing col-11" px="0px">
              {!loading && castData.length > 0 ? (
                // eslint-disable-next-line
                castData.map((item, id) => {
                  if (
                    item?.anothervalue !== undefined &&
                    item?.anothervalue.length > 0
                  ) {
                    return (
                      <>
                        <strong>{item?.display}</strong>{" "}
                        {isArray(item?.anothervalue)
                          ? item?.anothervalue.map((it, index) => {
                              return (
                                <React.Fragment key={index}>
                                  {it.link ? (
                                    <>
                                      <span
                                        onClick={() => setCrewName(it)}
                                        style={{
                                          color: "#035DA1",
                                          fontWeight: "400",
                                          cursor: "pointer",
                                        }}
                                      >
                                        {it?.full_name}
                                        {index <
                                          item?.anothervalue.length - 1 &&
                                          " ,"}{" "}
                                      </span>
                                    </>
                                  ) : (
                                    <span
                                      style={{
                                        color: "#035DA1",
                                        fontWeight: "400",
                                      }}
                                    >
                                      {it?.full_name}
                                      {index < item?.anothervalue.length - 1 &&
                                        ", "}{" "}
                                    </span>
                                  )}
                                </React.Fragment>
                              );
                            })
                          : item?.anothervalue}
                      </>
                    );
                  }
                })
              ) : (
                <Text
                  py={"40"}
                  fontSize={"45px"}
                  fontWeight="700"
                  textAlign="center"
                >
                  {common.WEBPAGE_COMING_SOON}
                </Text>
              )}
            </Text>
          </HStack>

          <Stack bg="white" py="6">
            {crewData?.length > 0 ? (
              <ImageGridList
                data={crewData}
                columns={[1, 2, null, 4]}
                spacing={"20px"}
                setDrawerOpen={handleCastDrawer}
                text={true}
                width={"100%"}
                height={"198px"}
              />
            ) : (
              <Text
                py={"40"}
                fontSize={"45px"}
                fontWeight="700"
                textAlign="center"
              >
                {common.WEBPAGE_COMING_SOON}
              </Text>
            )}
          </Stack>
        </Container>
      </Stack>
    </Stack>
  );
}
export default HorizontalLayout;
