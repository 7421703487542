import {all, fork} from 'redux-saga/effects';

import {authSaga} from './app/login';
//import {mainAppSaga} from './app/header';
//import {transactSaga} from './app/transact';
//import {profileSaga} from './app/profile';

export default function* rootSaga() {
  yield all([
  fork(authSaga),

  ]);
}
