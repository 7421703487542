import { Stack } from '@chakra-ui/react'
import React from 'react'
import { WebpageComingSoon } from '../../../../../CommonComponents/WebpageComingSoon'

export default function TalentCampusAndWorkshops() {
    return (
        <Stack height={"100vh"}>
            <WebpageComingSoon />
        </Stack>
    )
}
