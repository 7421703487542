import { locationIntroductionData } from "../MockData/Introduction";
import { baraImambara_filter_options, lucknow_bara_imamabara_data, lucknow_filter_options, lucknow_monuments_data, lucknow_the_residency_data} from "../MockData/LocationMockup";
import { isTransformationNotAllowed } from "../util/isTranformationAllowed";
import { transformJSON } from "../util/transformer";


export const getMonumentsAPI = async (tableName, masterlistAlias) => {
  return "";
};

const LocationMonumentschema = {}

export const getMonuments = async (tableName, masterlist) => {
    if (isTransformationNotAllowed() === true) {
      const response = await getMonumentsDummyData(masterlist);
      return response;
    } else if (isTransformationNotAllowed() === false)  {
      const response = await getMonumentsAPI(tableName, masterlist);
      try {
        if (response) {
          let transformedData = transformJSON(LocationMonumentschema, true, response);
          return transformedData;
        } else {
          console.error("Invalid response data structure:", response);
          return [];
        }
      } catch (e) {
        console.error("Something is wrong", e);
      }
    }
  };
  
  export const getMonumentsDummyData = async (masterlist) => {
    const locationMonumentsDataMap = {
        "lucknow": lucknow_monuments_data,
      };
      // Check if the masterlist exists in the map, if not, use a default value
      const locationMonumentsData = locationMonumentsDataMap[masterlist] || lucknow_monuments_data;
      return locationMonumentsData;
  };

// Monuments Detail Page

  export const getMonumentDataByAliasAPI = async (tableName, aliasAPI, monumentAlias) => {
    return "";
  };

  export const getMonumentDataByAlias = async (tableName, aliasAPI, monumentAlias, ) => {
    if (isTransformationNotAllowed() === true) {
      const response = await getMonumentDataByAliasDummyData(monumentAlias);
      return response;
    } else if (isTransformationNotAllowed() === false)  {
      const response = await getMonumentDataByAliasAPI(tableName, aliasAPI, monumentAlias);
      try {
        if (response) {
          let transformedData = transformJSON(LocationMonumentschema, true, response);
          return transformedData;
        } else {
          console.error("Invalid response data structure:", response);
          return [];
        }
      } catch (e) {
        console.error("Something is wrong", e);
      }
    }
  };
  
  export const getMonumentDataByAliasDummyData = async (monumentAlias) => {
    const locationMonumentsDataMap = {
        "bara-imambara": lucknow_bara_imamabara_data,
        "the-residency": lucknow_the_residency_data,
      };
      // Check if the masterlist exists in the map, if not, use a default value
      const locationMonumentsData = locationMonumentsDataMap[monumentAlias] || lucknow_bara_imamabara_data;
      return locationMonumentsData;
  };

  // Geographical data on introdction page

  export const getGeographicalDataByAliasAPI = async (tableName, masterlist) => {
    return "";
  };

  export const getGeographicalDataByAlias = async (tableName, masterlist) => {
    if (isTransformationNotAllowed() === true) {
      const response = await getGeographicalDataByAliasDummyData(masterlist);
      return response;
    } else if (isTransformationNotAllowed() === false)  {
      const response = await getGeographicalDataByAliasAPI(tableName, masterlist);
      try {
        if (response) {
          let transformedData = transformJSON(LocationMonumentschema, true, response);
          return transformedData;
        } else {
          console.error("Invalid response data structure:", response);
          return [];
        }
      } catch (e) {
        console.error("Something is wrong", e);
      }
    }
  };
  
  export const getGeographicalDataByAliasDummyData = async (masterlist) => {
    const locationGeographicalDataMap = {
        "lucknow": locationIntroductionData,
      };
      // Check if the masterlist exists in the map, if not, use a default value
      const locationGeographicalData = locationGeographicalDataMap[masterlist] || locationIntroductionData;
      return locationGeographicalData;
  };

  export const getLocationFilterOptions = async (tableName, masterlist) => {
    if (isTransformationNotAllowed() === true) {
      const FilterOptions = await getLocationFilterOptionsDummyData(
        masterlist
      );
      return FilterOptions;
    } else if (isTransformationNotAllowed() === false) {
    }
  };
  const getLocationFilterOptionsDummyData = (tableName, masterlist) => {
    const locationFilterOptionsDataMap = {
      "lucknow": lucknow_filter_options,
    };
    // Check if the masterlist exists in the map, if not, use a default value
    const locationFilterOptionsData = locationFilterOptionsDataMap[masterlist] || lucknow_filter_options;
    return locationFilterOptionsData;
  };


  export const getMonumentFilterOptions = async (tableName, masterlist) => {
    if (isTransformationNotAllowed() === true) {
      const FilterOptions = await getMonumentFilterOptionsDummyData(
        masterlist
      );
      return FilterOptions;
    } else if (isTransformationNotAllowed() === false) {
    }
  };
  const getMonumentFilterOptionsDummyData = (tableName, masterlist) => {
    const monumentFilterOptionsDataMap = {
      "bara-imambara": baraImambara_filter_options,
    };
    // Check if the masterlist exists in the map, if not, use a default value
    const monumentFilterOptionsData = monumentFilterOptionsDataMap[masterlist] || baraImambara_filter_options;
    return monumentFilterOptionsData;
  };