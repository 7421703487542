import { amitabh_bachchan_screeningData, mughal_filter_options, mughal_screeningData, raj_kapoor_screeningData, satyajit_filter_options, satyajit_screeningData, skk_filter_options, skk_screeningData} from "../MockData/ScreeningMockup";
import _ from "lodash";
import { isTransformationNotAllowed } from "../util/isTranformationAllowed";
import api from "../services/api";
import { APIS } from "../endpoints";

export const getScreeningByMasterListAPI = async (masterlistType, masterlist, filterOptions, page) => {
  const formData = new FormData();
  formData.append('filters', JSON.stringify(filterOptions));
  formData.append('page_number', page);
  const params = new URLSearchParams(formData);
  const result = await api.get(`${APIS.BASE_URL}${masterlistType}/${masterlist}/${APIS.screening}`,{
    params: params
})
  if (result) {
    return result?.data;
  } else {
    return [];
  }
};

export const getScreeningByMasterList = async ( masterlistType, masterlist, filterOptions,page) => {
  if (isTransformationNotAllowed() === true) {
    const { mockData, filterMockData } = await getScreeningByMasterListDummyData(masterlist);
    return { mockData, filterMockData };
  } else if (isTransformationNotAllowed() === false) {
    const response = await getScreeningByMasterListAPI( masterlistType, masterlist, filterOptions,page );
    try {
      if (response) {
        return response;
      } else {
        console.error("Invalid response data structure:", response);
        return [];
      }
    } catch (e) {
      console.error("Something is wrong", e);
    }
  }
};

// Todo - Remove this after filter working on Screen page
export const getScreeningByMasterListDummyData = async (masterlist) => {
  const screeningDataMap = {
    "satyajit-ray": satyajit_screeningData,
    "shatranj-ke-khilari-1977": skk_screeningData,
    "art-heritage-annual": satyajit_screeningData,
    "mughal-e-azam": mughal_screeningData,
    "sayed-haider-raza":satyajit_screeningData,
    "raj-kapoor":raj_kapoor_screeningData,
    "amitabh-bachchan":amitabh_bachchan_screeningData,
  }
  const mockData= screeningDataMap[masterlist];
  const filterMockData = (filterCategories, searchTerm) => {
    if (
      filterCategories.Type_of_Screening.length > 0 ||
      filterCategories.Years.length > 0 ||
      searchTerm !== ""
    ) {
      const filteredData = _.filter(mockData, entry => {

        const isTypeMatch = filterCategories.Type_of_Screening.length > 0
          ? filterCategories.Type_of_Screening.includes(entry.find(item => item.key === 'type').value)
          : true;

      const isYearsCheck =
        filterCategories.Years.length > 0
          ? filterCategories.Years.includes(
              entry.find((item) => item.key === "sortable_date").value
            )
          : true;

      const searchTermCheck =
        searchTerm !== ""
          ? entry
              .find((item) => item.key === "type")
              .value.toLowerCase()
              .includes(searchTerm.toLowerCase()) ||
            entry
              .find((item) => item.key === "sortable_date")
              .value.includes(searchTerm)
          : true;

      return isTypeMatch && isYearsCheck && searchTermCheck;
    });
    return filteredData;
  } else {
    return mockData;
  }
};
return {
  mockData,
  filterMockData,
};
};

export const getScreeningTypeFilterOptionsAPI = async (masterlistType, masterlist) => {
  const result = await api.get(`${APIS.BASE_URL}${masterlistType}/${masterlist}/${APIS.screening}`)
  if (result) {
    return result?.data?.data;
  } else {
    return [];
  }
};

export const getScreeningTypeFilterOptions = async (masterlistType, masterlist) => {
  if (isTransformationNotAllowed() === true) {
    const response = await getScreeningTypeFilterOptionsDummyData(masterlist);
    return response;
  }else if (isTransformationNotAllowed() === false)  {
    const response = await getScreeningTypeFilterOptionsAPI(masterlistType, masterlist);
    const FilterOptions = {
      screeningTypeFilter: [...new Set(response.map((item) => item.type.trim()).filter((value)=> value !== ""))],
      yearFilter: [...new Set(response.map((item) => item.sortable_date.trim()).filter((value)=> value !== ""))],
    };

    return FilterOptions;
  }
};

const getScreeningTypeFilterOptionsDummyData = ( masterlist) => {
  const ScreeningTypeFilterOptionsMap = {
    "satyajit-ray": satyajit_filter_options,
    "shatranj-ke-khilari-1977": skk_filter_options,
    "mughal-e-azam": mughal_filter_options,
  };
  const ScreeningTypeFilterOptionsData = ScreeningTypeFilterOptionsMap[masterlist] || satyajit_filter_options;
  return ScreeningTypeFilterOptionsData;
};
