import { Box, HStack, Image, Modal, ModalBody, ModalContent, ModalOverlay, Stack, Text } from "@chakra-ui/react";
import { CgArrowsExpandRight } from "@react-icons/all-files/cg/CgArrowsExpandRight";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectFade, Navigation, Pagination } from "swiper";

export const ImagePopupModal = ({
    isOpen,
    onClose,
    imageData,
    data,
    clickedImageIndex,
  }) => {
    return (
      <Modal
        size={"full"}
        isCentered
        isOpen={isOpen}
        onClick={onClose}
        Id="modalfullscreen"
        zIndex={"9999"}
      >
        <ModalOverlay />
        <ModalContent overflowY="hidden" alignItems="center">
          <ModalBody>
            <Box bg="white" width={{ base: "350px", md: "1250px" }}>
              <Stack>
                <Swiper
                  slidesPerView={1}
                  navigation
                  initialSlide={clickedImageIndex}
                  id="sliderModal"
                  modules={[EffectFade, Navigation, Pagination]}
                >
                  {data.map((item, index) => {
                    return (
                      <SwiperSlide key={index + 1}>
                        <HStack
                          justifyContent={"center"}
                          flexDirection={"column"}
                          marginTop={"15px"}
                        >
                          <Box
                            width={"auto"}
                            height={{ base: "100%", md: "90vh" }}
                          >
                            <Image
                              src={item.image ? item.image : item}
                              width={"auto"}
                              height={"inherit"}
                              alt=""
                            />
                          </Box>
                          <HStack paddingTop="10px">
                            <Text
                              pt={"8px"}
                              fontSize={"15px"}
                              fontWeight={400}
                              lineHeight={"17px"}
                              color="#035DA1"
                            >
                              {item.desc}
                            </Text>
                          </HStack>
                        </HStack>
                        <HStack
                          justifyContent="flex-end"
                          position="absolute"
                          right={{ base: "95%", md: "15%" }}
                          top={{ base: "85%", md: "95%" }}
                          zIndex={"999"}
                          transform="translateY(-50%)"
                          paddingRight={"50px"}
                        >
                          <Text
                            fontSize={"xl"}
                            bg={"black"}
                            opacity={"0.6"}
                            color={"white"}
                            fontWeight={"400"}
                            padding="2"
                            cursor={"pointer"}
                            borderRadius={"35px"}
                            width="max-content"
                            transform={""}
                            onClick={onClose}
                          >
                            <CgArrowsExpandRight />
                          </Text>
                        </HStack>
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
              </Stack>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    );
  };