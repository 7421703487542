import React from "react";
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  useDisclosure,
  Text,
  HStack,
  Image,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import {
  common,
  explore,
  userRegistrationForm,
} from "../../constants/constants";
//S3 Bucket osw-dev-images
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;
const closeIcon = `${IMAGE_BASE_PATH}/ui/Chevron/closeicon.svg`;

const SubscriptionModal = ({ setShowSubscribeModal, className="subscribe-modal-content" }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();
  React.useEffect(() => {
    onOpen();
  }, [onOpen]);

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent className={className}>
          <ModalHeader>
            <HStack>
              <Image
                className="subscribeclosebtn"
                src={closeIcon}
                onClick={() => {
                  setShowSubscribeModal(false);
                  onClose();
                }}
                cursor={"pointer"}
              ></Image>
            </HStack>
          </ModalHeader>
          <ModalBody className="subscribe-modal-body">
            <Text
              fontSize="18px"
              fontWeight={500}
              lineHeight={"21px"}
              textAlign="center"
              mb={4}
            >
              {explore.SUBSCRIPTION_DESC}
            </Text>
          </ModalBody>
          <ModalFooter
            justifyContent={"space-between"}
            className="subscribe-modal-footer"
          >
            <Button
              colorScheme="yellow"
              className="subscribeButton"
              bg={"color.yellow"}
              onClick={() => navigate("/register")}
            >
              {common.SUBSCRIBE}
            </Button>
            <Button
              className="subscribeButton"
              bg={"color.yellowlight2"}
              onClick={() => {
                setShowSubscribeModal(false);
                onClose();
              }}
            >
              {userRegistrationForm.GO_BACK}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default SubscriptionModal;
