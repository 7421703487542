import {
  Box,
  Button,
  Container,
  Grid,
  GridItem,
  HStack,
  Image,
  Link,
  Stack,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import Caption from "../components/layouts/Typography/Caption";
import H2 from "../components/layouts/Typography/H2";
import Large from "../components/layouts/Typography/Large";
import Layout from "../components/sections/SidebarLayout";
import { useParams } from "react-router-dom";
import { HomeSliderData } from "../MockData/HomeSliderData";

//S3 Bucket osw-dev-images
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;

const BackArrow = `${IMAGE_BASE_PATH}/ui/Chevron/backarrow.png`

function WhatsNewPage() {
  const { sliderNumber } = useParams();
  const [data, setData] = useState(null);

  useEffect(() => {
    if (sliderNumber.length !== 0) {
      const newData = HomeSliderData?.filter(
        (item) => item.id === parseInt(sliderNumber)
      );
      if (newData.length > 0) {
        setData(newData[0]);
      }
      // const slideID = HomeData?.filter(
      //   (item) => item.id === parseInt (sliderNumber)
      // )
    }
  }, [sliderNumber]);

  return data !== null ? (
    <Layout>
      <Container maxW={"1400px"}>
        <Stack>
          <Box position={"sticky"} top={"90px"} marginLeft="8" zIndex={1}>
            <Link href={`/${data.id}`}>
              <Image src={BackArrow}></Image>
            </Link>
          </Box>

          <Stack
            width={"100%"}
            marginTop={"-1.5rem!important"}
            alignItems={"center"}
          >
            <HStack>
              <Image src={data.imgPath}></Image>
            </HStack>
            <Stack className="detail-page" p="10">
              <Grid
                className="on-mobile-gridview"
                templateColumns="repeat(5, 1fr)"
                gap={4}
              >
                <GridItem rowSpan={2} colSpan={3}>
                  <H2 pb="1">Carcassonne</H2>
                  <Caption pb="2">{data.title}</Caption>
                  <Stack
                    flexDirection={"row"}
                    className="des-col-on-mobile"
                    alignItems={"baseline"}
                    gap="7"
                  >
                    <HStack>
                      <Large>{data.description1}</Large>
                    </HStack>
                    <HStack>
                      <Large>{data.description2}</Large>
                    </HStack>
                  </Stack>
                </GridItem>

                <GridItem
                  className="grid-item-on-mobile"
                  colSpan={2}
                  width={"350px"}
                >
                  <Stack direction="row" spacing={4} align="center" pb="5">
                    <Button bg="#EEEEEE" size={"sm"} variant="solid">
                      {data.button1}
                    </Button>
                    <Button bg="#EEEEEE" size={"sm"} variant="solid">
                      {data.button2}
                    </Button>
                    <Button bg="#EEEEEE" size={"sm"} variant="solid">
                      {data.button3}
                    </Button>
                  </Stack>
                  <Large>{data.description3}</Large>
                </GridItem>
              </Grid>
            </Stack>
          </Stack>
        </Stack>
      </Container>
    </Layout>
  ) : (
    <></>
  );
}
export default WhatsNewPage;