import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  HStack,
} from "@chakra-ui/react";
import React, { Fragment, useEffect } from "react";
import H5 from "../../Theme/Typography/H5";
import { Profile } from "../../constants/constants";
import { Link } from "react-router-dom";
import H1 from "../../Theme/Typography/H1";
// import chevron from "./../../components/UI/Profile/chevron_right.png";

export default function BookMarks({
  showBookmark,
  setShowBookmark,
  data,
  handleToggle,
}) {
  useEffect(() => {
  }, [showBookmark]);
  return (
    <HStack className="mt-0">
      <Accordion index={showBookmark ? [0] : []} allowToggle borderColor={"white"}>
        <AccordionItem isOpen={showBookmark}>
          <h2>
            <AccordionButton pl="0px" onClick={handleToggle} _hover={"none"}>
              <Box><H1 fontSize={{base:"18px",md:"24px"}} lineHeight={{base:"22px",md:"28.18px"}}>{Profile.BOOKMARKS}</H1></Box>
              
              <AccordionIcon fontSize={{base:"30px",md:"49px"}} />
            </AccordionButton>
          </h2>
          <AccordionPanel px={{base:"0",md:"4"}} pb={{base:"2",md:"4"}}>
            {data && data.length > 0 &&
              data.map((item ,index) => (
                <Fragment key={index}>
                <Link to={`${item?.uri}`}>
                <H5 fontWeight={400} color={"color.blue"} paddingTop={{base:'10px',md:'16px'}}>
                  {item?.title}
                </H5>
                </Link>
                </Fragment>
              ))}{" "}
          </AccordionPanel>
        </AccordionItem>
      </Accordion>

      {/* <Image src={chevron} width={"16px"} height={"16px"} mb={"4px"} /> */}
    </HStack>
  );
}
