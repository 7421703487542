import React, { useState } from "react";
import { Stack } from "@chakra-ui/react";
import { explore } from "../../constants/constants";
import { getExploreData } from "../../services/ExploreService";
import H4 from "../../components/layouts/Typography/H4";
import { useTranslation } from "react-i18next";
import { WebpageComingSoon } from "../../CommonComponents/WebpageComingSoon";
import Grid from "../../FunctionalComponents/SimpleGrid/Grid";
import ImageOverlayGrid from "../../FunctionalComponents/SimpleGrid/ImageOverlayGrid";
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;
const loaderImg = `${IMAGE_BASE_PATH}/ui/Chevron/loader.gif`;

function ExplorePosts() {
  const [exploreData, setExploreData] = useState([]);
  const [showLabel, setShowLabel] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(true);
  const { t } = useTranslation("");
  const [page, setPage] = useState(1);
  const [pageSize] = useState(20);
  const [showMore, setShowMore] = useState(true);
  
  const fetchExploreData = async (page, pageSize) => {
    const result = await getExploreData(page, pageSize);
    setLoading(false);
    return result;
  };

  React.useEffect(() => {
    const loadInitialData = async () => {
      setLoading(true);
      const result = await fetchExploreData(page, pageSize);

      if (result?.data?.length > 0) {
        setExploreData(result?.data);
        setShowLabel(false);
        if (result?.data?.length === result?.total) {
          setShowMore(false);
        }
      } else {
        setShowLabel(true);
      }
      setLoading(false);
      setLoadingMore(false);
    };
    loadInitialData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSeeMore = async () => {
    const nextPage = page + 1;
    setPage(nextPage);
    setLoadingMore(true);
    const result = await fetchExploreData(nextPage, pageSize);
    if (result?.data?.length > 0) {
      setExploreData((prevData) => {
        const newData = [...prevData, ...result.data];
        if (newData?.length === result?.total) {
          setShowMore(false);
        }
        return newData;
      });
    }
    setLoadingMore(false);
  };

  return (
    <Stack p="0" pb={{base:"60px",md:"0px"}}>
      {loading ? (
        <Stack p="0" marginTop={"20px"} width={"100%"} alignItems={"center"}>
          <img src={loaderImg} width={"200px"} height={"200px"} alt="Loading" />
        </Stack>
      ) : showLabel ? (
        <WebpageComingSoon />
      ) : (
        <>
          {exploreData.length > 0 && (
            <Grid
              data={exploreData}
              className={"card"}
              columns={{ base: 2, md: 3, lg: 6, xl: 6 }}
              spacing="16px"
              component={ImageOverlayGrid}
            />
          )}
          {loadingMore ? (
            <Stack p="0" marginTop={"20px"} width={"100%"} alignItems={"center"}>
              <img src={loaderImg} width={"100px"} height={"100px"} alt="Loading More" />
            </Stack>
          ) : (
          showMore && (
            <H4
             color="color.blue"
              textAlign="center"
              pt="27px"
              pb="17px"
              cursor="pointer"
              onClick={handleSeeMore}
            >
              {`${t(explore.SEE_MORE)}`}
            </H4>
           )
          )}
        </>
      )}
    </Stack>
  );
}

export default ExplorePosts;
