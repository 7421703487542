import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  userAccess: []
};
const userAccessSlice = createSlice({
  name: "userAccess",
  initialState: initialState,
  reducers: {
    setUserAccess: (state, action) => {
        state.permissions = action.payload;
      },
  },
});

export const {
    setUserAccess
} = userAccessSlice.actions;
export default userAccessSlice.reducer;