import React from "react";
import ReactEcharts from "echarts-for-react";

function PieChart({data}) {
  const option = {
    title: {
      text: "Artwork sales vs Medium of Artwork",
      left: "center",
    },
    tooltip: {
      trigger: "item",
    },
    // legend: {
    //   orient: "vertical",
    //   left: "left",
    //   icon: 'circle',
    // },
    label: {
        formatter: '{c}',
      },
    series: [
      {
        name: "Value",
        type: "pie",
        data: data.map(item => ({ name: item.name, value: item.value })),
        // data: [
        //   { name: "Andy Warhol", value: 590.21 },
        //   { name: "Claude Monet", value: 494.57 },
        //   { name: "Pablo Picasso", value: 539.29 },
        //   { name: "Francis Bacon", value: 255.18 },
        //   { name: "René Magritte", value: 226.55 },
        //   { name: "Gerhard Richter", value: 225.62 },
        //   { name: "Jean-Michel Basquiat", value: 221.02 },
        //   { name: "Mark Rothko", value: 198.3 },
        //   { name: "Willem De Kooning", value: 195.05 },
        //   { name: "Yayoi Kusama", value: 194.6 },
        //   { name: "Paul Cézanne", value: 193.48 },
        // ],
      },
    ],
  };
  return (
    <>
      {" "}
      <ReactEcharts
        option={option}
        style={{ height: "470px", width: "560px" }}
      />
    </>
  );
}

export default PieChart;
