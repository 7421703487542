// theme.ts (tsx file with usage of StyleFunctions, see 4.)
import { extendTheme } from "@chakra-ui/react";
import { MultiSelectTheme } from "chakra-multiselect";
import "../../src/index.css";
import "../fonts/SegoeUI.ttf";
import "../fonts/SegoeUIBold.ttf";

const theme = extendTheme({
  fonts: {
    heading: `'Segoe UI'`,
    body: `'Raleway'`,
  },
  textStyles: {
    primary: {
      fontFamily: "Raleway",
    },
    secondary: {
      fontFamily: `'Segoe UI'`,
    },
    tertiary: {
      fontFamily: `'Segoe UI Bold'`,
    },
  },
  components: {
    MultiSelect: MultiSelectTheme,
    Button: {
      baseStyle: {
        _focus: { outline: "none" },
        _active: { outline: "none" },
        _hover: { outline: "none" },
      },
      variants:{
        custom:{
          bg:"color.yellow",
          color:"color.primary"
        }
      },
      sizes: {
        xl: {
          h: "48px",
          fontSize: "lg",
          px: "32px",
        },
      },
    },
    MenuItem: {
      baseStyle: {
        _focus: { outline: "none" },
        _active: { outline: "none" },
        _hover: { outline: "none" },
      },
    },
    Tabs: {
      variants: {
        custom: {
          tab: {
            fontSize:"16px",
            fontWeight:"400",
            _selected:{
              borderBottom: "4px",
              color: "color.primary",
              borderBottomColor: "color.primary",
              fontWeight: "700",
              borderRadius: "4px",
            },
          },
          tablist: {
            bg:"white",
            borderBottom:"1px",
            borderBottomColor:"color.lightgray5"
          },
        },
      },
    },
  },

  // Custom color paletts as per TRIS figma files

  colors: {
    color: {
      primary: "#000000",
      secondary: "#FFFFFF",
      blue: "#035DA1",
      lightblue1: "#0066B3",
      lightblue2: "#438BA5",
      red: "#860F0F",
      darkred : "#FF0000",
      lightred: "#FF4E4E",
      lightbrown: "#EFDFB2",
      yellow: "#FFC015",
      yellowlight: "#FAD51C",
      yellowlight2:"#FDE6A6",
      gray: "#333333",
      lightgray1: "#4C4C4C",
      lightgray2: "#989898",
      lightgray3: "#B2B2B2",
      lightgray4: "#DFDFDF",
      lightgray5: "#F1F1F1",
      lightgray6: "#F6F6F6",
      lightgray7: "#B5B5B5",
      success1:"#82CA3F",

    },
  },
});

export default theme;