export const ResearchCategory = [
  {
    List_I: 1,
    Research_Category: "Cinema as a critical Educational Resource",
    Abbreviation: "1_CinemaE",
    type: "Core",
    RC: "1_CinemaE | Core",
    alias:"cinema-as-a-critical-educational-resource_1",
  },
  {
    List_I: 2,
    Research_Category: "Modern and Contemporary Indian Fine Arts",
    Abbreviation: "2_FineArts",
    type: "Core",
    RC: "2_FineArts | Core",
    alias:"modern-and-contemporary-indian-fine-arts_2",
  },
  {
    List_I: 3,
    Research_Category: "Photography in India",
    Abbreviation: "3_Photography",
    type: "Critical",
    RC: "3_Photography | Critical",
    alias:"photography-in-india_3",
  },
  {
    List_I: 4,
    Research_Category: "The Printed Image, Popular Arts and Crafts",
    Abbreviation: "4_PopCult",
    type: "Critical",
    RC: "4_PopCult | Critical",
    alias:"the-printed-image-popular-arts-and-crafts_4",
  },
  {
    List_I: 5,
    Research_Category:
      "India's Architectural Heritage and its Inspiration for Urbanisation",
    Abbreviation: "5_ArchDev",
    type: "Critical",
    RC: "5_ArchDev | Critical",
    alias:"indias-architectural-heritage-and-its-inspirations_5",
  },
  {
    List_I: 6,
    Research_Category: "The Economics of Art, Cinema and Culture",
    Abbreviation: "6_EconArts",
    type: "Critical",
    RC: "6_AutoTravel | Critical",
    alias:"economics-of-art-cinema-and-culture_6",
  },
  {
    List_I: 7,
    Research_Category: "The Economics of Art, Cinema and Culture",
    Abbreviation: "7_EconArts",
    type: "Critical",
    RC: "7_EconArts | Critical",
    alias:"the-economics-of-art-cinema-and-culture_7",
  },
  {
    List_I: 8,
    Research_Category: "Uncertainty | Magic Mystery Horror Sci-Fi Gambling",
    Abbreviation: "8_Uncertainty",
    type: "Critical",
    RC: "8_Uncertainty | Critical",
    alias:"uncertainty-|-magic-horror-sci-fi-gambling_8",
  },
  {
    List_I: 9,
    Research_Category: "Social Responsibility of the Creative Mind",
    Abbreviation: "9_SocialR",
    type: "Critical",
    RC: "9_SocialR | Critical",
    alias:"social-responsibility-of-the-creative-mind_9",
  },
  {
    List_I: 10,
    Research_Category: "Childhood and its Second Coming",
    Abbreviation: "10_Childhood",
    type: "Critical",
    RC: "10_Childhood | Critical",
    alias:"childhood-and-its-second-coming_10",
  },
  {
    List_I: 11,
    Research_Category: "The Erotic Discipline within Creativity",
    Abbreviation: "11_EroticD",
    type: "Critical",
    RC: "11_EroticD | Critical",
    alias:"the-erotic-discipline-within-creativity_11",
  },
  {
    List_I: 12,
    Research_Category:
      "The History & Historiography of Scholarship in the Arts",
    Abbreviation: "12_Scholarship",
    type: "Meaningful",
    RC: "12_Scholarship | Meaningful",
    alias:"the-history-&-historiography-of-scholarship-in-the-arts_12",
  },
  {
    List_I: 13,
    Research_Category: "Seeking Justice and Political Integrity",
    Abbreviation: "13_JusticeP",
    type: "Meaningful",
    RC: "13_JusticeP | Meaningful",
    alias:"seeking-justice-and-political-integrity_13",
  },
  {
    List_I: 14,
    Research_Category: "India and her Relationship with the World",
    Abbreviation: "14_IndoWorld",
    type: "Meaningful",
    RC: "14_IndoWorld | Meaningful",
    alias:"india-and-her-relationship-with-the-world_14",
  },
  {
    List_I: 15,
    Research_Category: "The Religious-Spiritual-Poetic-Philosophical Continuum",
    Abbreviation: "15_RSPPC",
    type: "Meaningful",
    RC: "15_RSPPC | Meaningful",
    alias:"the-religious-spiritual-poetic-philosophical-continuum_15",
  },
  {
    List_I: 16,
    Research_Category: "The Animal-Human-Nature Continuum",
    Abbreviation: "16_Continuum",
    type: "Meaningful",
    RC: "16_Continuum | Meaningful",
    alias:"the-animal-human-nature-continuum_16",
  },
];
export const ResearchCategorydummyData=[
  [
    {
        key: "abbreviation",
        value: "1_CinemaEducation"
    },
    {
        key: "relevance_to_self_discovery_journey",
        value: "Core"
    },
    {
        key: "research_category_as_in_website",
        value: "1_CinemaEducation_Core | Cinema as a Critical Educational Resource"
    },
    {
        key: "alias",
        value: "cinema-as-a-critical-educational-resource_1"
    },
    {
        key: "id",
        value: "1"
    },
    {
        key: "name",
        value: "Cinema as a Critical Educational Resource"
    }
],
  [
      {
          key: "abbreviation",
          value: "2_FineArts"
      },
      {
          key: "relevance_to_self_discovery_journey",
          value: "Core"
      },
      {
          key: "research_category_as_in_website",
          value: "2_FineArts_Core | Modern and Contemporary Indian Fine Arts"
      },
      {
          key: "alias",
          value: "modern-and-contemporary-indian-fine-arts_2"
      },
      {
          key: "id",
          value: "2"
      },
      {
          key: "name",
          value: "Modern and Contemporary Indian Fine Arts"
      }
  ],
  [
      {
          key: "abbreviation",
          value: "3_Photography"
      },
      {
          key: "relevance_to_self_discovery_journey",
          value: "Critical"
      },
      {
          key: "research_category_as_in_website",
          value: "3_Photography_Critical | Photography in India"
      },
      {
          key: "alias",
          value: "photography-in-india_3"
      },
      {
          key: "id",
          value: "3"
      },
      {
          key: "name",
          value: "Photography in India"
      }
  ],
  [
      {
          key: "abbreviation",
          value: "4_PopCulture"
      },
      {
          key: "relevance_to_self_discovery_journey",
          value: "Critical"
      },
      {
          key: "research_category_as_in_website",
          value: "4_PopCulture_Critical | The Printed Image, Popular Arts and Crafts"
      },
      {
          key: "alias",
          value: "the-printed-image-popular-arts-and-crafts_4"
      },
      {
          key: "id",
          value: "4"
      },
      {
          key: "name",
          value: "The Printed Image, Popular Arts and Crafts"
      }
  ],
  [
      {
          key: "abbreviation",
          value: "5_ArcHeritage"
      },
      {
          key: "relevance_to_self_discovery_journey",
          value: "Critical"
      },
      {
          key: "research_category_as_in_website",
          value: "5_ArcHeritage_Critical | India's Architectural Heritage as Inspiration"
      },
      {
          key: "alias",
          value: "india's-architectural-heritage-as-inspiration_5"
      },
      {
          key: "id",
          value: "5"
      },
      {
          key: "name",
          value: "India's Architectural Heritage as Inspiration"
      }
  ],
  [
      {
          key: "abbreviation",
          value: "6_EconomicsArt"
      },
      {
          key: "relevance_to_self_discovery_journey",
          value: "Critical"
      },
      {
          key: "research_category_as_in_website",
          value: "6_EconomicsArt_Critical | Economics of Art, Cinema and Culture"
      },
      {
          key: "alias",
          value: "economics-of-art-cinema-and-culture_6"
      },
      {
          key: "id",
          value: "6"
      },
      {
          key: "name",
          value: "Economics of Art, Cinema and Culture"
      }
  ],
  [
      {
          key: "abbreviation",
          value: "7_Travel"
      },
      {
          key: "relevance_to_self_discovery_journey",
          value: "Essential"
      },
      {
          key: "research_category_as_in_website",
          value: "7_Travel_Essential | Automobile, Transport and Travel Heritage"
      },
      {
          key: "alias",
          value: "automobile-transport-and-travel-heritage_7"
      },
      {
          key: "id",
          value: "7"
      },
      {
          key: "name",
          value: "Automobile, Transport and Travel Heritage"
      }
  ],
  [
      {
          key: "abbreviation",
          value: "8_Uncertainty"
      },
      {
          key: "relevance_to_self_discovery_journey",
          value: "Essential"
      },
      {
          key: "research_category_as_in_website",
          value: "8_Uncertainty_Essential | Uncertainty : Mystery | Magic | Horror | Sci-Fi | Gambling"
      },
      {
          key: "alias",
          value: "uncertainty-mystery-magic-horror-sci-fi-gambling_8"
      },
      {
          key: "id",
          value: "8"
      },
      {
          key: "name",
          value: "Uncertainty : Mystery | Magic | Horror | Sci-Fi | Gambling"
      }
  ],
  [
      {
          key: "abbreviation",
          value: "9_SocialDuty"
      },
      {
          key: "relevance_to_self_discovery_journey",
          value: "Essential"
      },
      {
          key: "research_category_as_in_website",
          value: "9_SocialDuty_Essential | Social Responsibility of the Creative Mind"
      },
      {
          key: "alias",
          value: "social-responsibility-of-the-creative-mind_9"
      },
      {
          key: "id",
          value: "9"
      },
      {
          key: "name",
          value: "Social Responsibility of the Creative Mind"
      }
  ],
  [
      {
          key: "abbreviation",
          value: "10_Childhood"
      },
      {
          key: "relevance_to_self_discovery_journey",
          value: "Essential"
      },
      {
          key: "research_category_as_in_website",
          value: "10_Childhood_Essential | The Changing Smile of Childhood"
      },
      {
          key: "alias",
          value: "the-changing-smile-of-childhood_10"
      },
      {
          key: "id",
          value: "10"
      },
      {
          key: "name",
          value: "The Changing Smile of Childhood"
      }
  ],
  [
      {
          key: "abbreviation",
          value: "11_Eroticism"
      },
      {
          key: "relevance_to_self_discovery_journey",
          value: "Essential"
      },
      {
          key: "research_category_as_in_website",
          value: "11_Eroticism_Essential | The Erotic Discipline within Creativity"
      },
      {
          key: "alias",
          value: "the-erotic-discipline-within-creativity_11"
      },
      {
          key: "id",
          value: "11"
      },
      {
          key: "name",
          value: "The Erotic Discipline within Creativity"
      }
  ],
  [
      {
          key: "abbreviation",
          value: "12_Scholarship"
      },
      {
          key: "relevance_to_self_discovery_journey",
          value: "Meaningful"
      },
      {
          key: "research_category_as_in_website",
          value: "12_Scholarship_Meaningful | The History & Historiography of Scholarship in the Arts"
      },
      {
          key: "alias",
          value: "the-history-and-historiography-of-scholarship-in-the-arts_12"
      },
      {
          key: "id",
          value: "12"
      },
      {
          key: "name",
          value: "The History & Historiography of Scholarship in the Arts"
      }
  ],
  [
      {
          key: "abbreviation",
          value: "13_SeekJustice"
      },
      {
          key: "relevance_to_self_discovery_journey",
          value: "Meaningful"
      },
      {
          key: "research_category_as_in_website",
          value: "13_SeekJustice_Meaningful | Seeking Justice and Political Integrity"
      },
      {
          key: "alias",
          value: "seeking-justice-and-political-integrity_13"
      },
      {
          key: "id",
          value: "13"
      },
      {
          key: "name",
          value: "Seeking Justice and Political Integrity"
      }
  ],
  [
      {
          key: "abbreviation",
          value: "14_IndoWorld"
      },
      {
          key: "relevance_to_self_discovery_journey",
          value: "Meaningful"
      },
      {
          key: "research_category_as_in_website",
          value: "14_IndoWorld_Meaningful | India and her Relationship with the World"
      },
      {
          key: "alias",
          value: "india-and-her-relationship-with-the-world_14"
      },
      {
          key: "id",
          value: "14"
      },
      {
          key: "name",
          value: "India and her Relationship with the World"
      }
  ],
  [
      {
          key: "abbreviation",
          value: "15_SpiritualPoetics"
      },
      {
          key: "relevance_to_self_discovery_journey",
          value: "Meaningful"
      },
      {
          key: "research_category_as_in_website",
          value: "15_SpiritualPoetics_Meaningful | The Religious-Spiritual-Poetic-Philosophical Continuum"
      },
      {
          key: "alias",
          value: "the-religious-spiritual-poetic-philosophical-continuum_15"
      },
      {
          key: "id",
          value: "15"
      },
      {
          key: "name",
          value: "The Religious-Spiritual-Poetic-Philosophical Continuum"
      }
  ],
  [
      {
          key: "abbreviation",
          value: "16_Continuum"
      },
      {
          key: "relevance_to_self_discovery_journey",
          value: "Meaningful"
      },
      {
          key: "research_category_as_in_website",
          value: "16_Continuum_Meaningful | The Animal-Human-Nature Continuum"
      },
      {
          key: "alias",
          value: "the-animal-human-nature-continuum_16"
      },
      {
          key: "id",
          value: "16"
      },
      {
          key: "name",
          value: "The Animal-Human-Nature Continuum"
      }
  ]

]
