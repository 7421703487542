import React, { useEffect, useState } from "react";
import {
  Box,
  HStack,
  useColorModeValue,
  Drawer,
  DrawerContent,
  useDisclosure,
  Stack,
  Container,
  Image,
} from "@chakra-ui/react";

// Core files
import { useLocation, useNavigate, useParams } from "react-router-dom";

// Service Files
import * as getFilmData from "../../services/Film";
import * as getLandingMasterlistData from "../../services/SectionService";

// Subsection Files
import CommonLeftSidebar from "../../FunctionalComponents/LeftSidebar/CommonLeftSidebar";
import { BottomMainMenu } from "../../CommonComponents/BottomMainMenu";
import { explore, filmography } from "../../constants/constants";
import { isArray } from "lodash";

import Synopsis from "./BodyOfWork/Synopsis";
import { LyricsSoundtrack } from "./BodyOfWork/Soundtrack";
import Editorial from "./BodyOfWork/Editorial";
import HorizontalLayout from "./BodyOfWork/CrewAndCast/HorizontalLayout";
import VerticalLayout from "./BodyOfWork/CrewAndCast/VerticalLayout";
import ReadMore from "./ReadMore";
import WatchFullFilm from "./BodyOfWork/WatchFullFilm";
import TabMain from "../../FunctionalComponents/TabComponents/TabMain";
import { getImageURL } from "../../util/getImage";

//S3 Bucket osw-dev-images
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;

const SKK1 = `${IMAGE_BASE_PATH}/ui/Film/ShatranjKeKhilari/TitleImage/small.png`
const WatchTrailer = `${IMAGE_BASE_PATH}/ui/Chevron/watchTrailer.png`
const SatyajitRayImg = `${IMAGE_BASE_PATH}/ui/Film/ShatranjKeKhilari/BodyOfWork/Editorial/5.png`
const SureshJindal = `${IMAGE_BASE_PATH}/ui/Film/ShatranjKeKhilari/DummyImage/1.png`

// const artistLinksData = [
//   {
//     name: "Satyajit Ray",

//   },
//   {
//     name: "Suresh Jindal",
//   },
// ];
export default function MainBodyOfWok({ children }) {
  let { alias } = useParams();
  const { isOpen, onClose } = useDisclosure();
  const winnerName = alias;
  const [castTabFlag, setCastTabFlag] = useState(false);
  const [readmoreNavigation, setReadmoreNavigation] = useState({});
  const [introData, setIntroData] = useState([]);
  const [name, setName] = useState("");
  const [agraphyLinks, setAgraphyLink] = useState([{}]);
  const [filmTrailer, setFilmTrailer] = useState({});
  const [filmMainImage, setfilmMainImage] = useState("");
  const [filmDetails, setFilmDetails] = useState({});
  const [watchFilm, setWatchFilm] = useState("");
  const currentEndpoint = window.location.pathname;
  const parts = currentEndpoint.split("/");
  const masterlistType = parts[1];

  React.useEffect(() => {
    const getLandingData = async () => {
      const result = await getLandingMasterlistData.getLandingPageInfoByAlias(
        masterlistType,
        winnerName
      );
      if (result) {
        setIntroData(
          result.map((it) => ({
            text: it.find((item) => item.key === "landing_page_title").value,
            to: `/${masterlistType}/${it.find((item) => item.key === "masterlist_alias").value}/${it.find((item) => item.key === "landing_page_abbreviation").value
              }/${it.find(
                (item) =>
                  item.key === "landing_page_abbreviation"
              )?.value === "research-centre" ? `library` : ``}
             `,
            title: it.find((item) => item.key === "landing_page_abbreviation")
              .value,
          }))
        );
      }
    };
    // Get film intro data-need to refactor due to redundant code in every section.
    const getFilmIntroData = async () => {
      try {
        const result = await getFilmData.filmByAlias(masterlistType, winnerName);
        if (result) {
          setName(
            () =>
              result?.find((item) => item.key === "film_title_image_alias")?.value &&
              result?.find((item) => item.key === "film_title_image_alias")?.value
          );
          setAgraphyLink([
            {
              link: `/research-categories/${result?.find((item) => item?.key === "researchCategories")
                  ?.value[0]?.alias
                }`, // replace with the actual link
              title: result?.find((item) => item?.key === "researchCategories")
                ?.value[0]?.value,
            },
            // {
            //   link: `/categories/indian-cinema`,
            //   title: result?.find((item) => item?.key === "subject")?.value,
            // },
          ]);
          setfilmMainImage(
            {
               logoImage:result?.find((item) => item?.key === "ssb_image_alias")?.value,
                logoLink:`/${masterlistType}/${alias}/introduction/title-image`
               }
           );
          const loc = result?.find((item) => item?.key === "first_release_location")
            ?.value
          setFilmDetails({
            date: result?.find((item) => item?.key === "release_date")?.value,
            location: isArray(
              result?.find((item) => item?.key === "first_release_location")
                ?.value
            )
              ? result?.find((item) => item?.key === "first_release_location")
                ?.value[0]?.value
              : result?.find((item) => item?.key === "first_release_location")
                ?.value,
            link: `location/${loc.toLowerCase()}`
          });
          setFilmTrailer({
            link: `/${masterlistType}/${winnerName}/watch-film-trailer`,
            display: result?.find((item) => item?.key === "trailer_link")
              ?.display,
            image: WatchTrailer,
          });
          setWatchFilm(
            result?.find((item) => item?.key === "full_movie_link")?.value
          );
        }
      } catch (error) {
        console.error("Error fetching flimography data", error);
      }
    };
    getLandingData();
    getFilmIntroData();
  }, [masterlistType, winnerName, alias]);
  return (
    <Box minH="100%" bg={useColorModeValue("white", "gray.900")}>
      <SidebarContent
        onClose={() => onClose}
        display={{ base: "none", md: "block" }}
        height="calc(100vh - 64px)"
        winnerName={winnerName}
        castTabFlag={castTabFlag}
        readmoreNavigation={readmoreNavigation}
        setReadmoreNavigation={setReadmoreNavigation}
        introData={introData}
        name={name}
        filmDetails={filmDetails}
        filmTrailer={filmTrailer}
        agraphyLinks={agraphyLinks}
        filmMainImage={filmMainImage}
        masterlistType={masterlistType}
      />
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
      >
        <DrawerContent>
          <SidebarContent
            onClose={onClose}
            winnerName={winnerName}
            castTabFlag={castTabFlag}
            readmoreNavigation={readmoreNavigation}
            setReadmoreNavigation={setReadmoreNavigation}
          />
        </DrawerContent>
      </Drawer>

      <Box ml={{ base: 0, md: 52 }} bg="white">
        {children}
        <BodyOfWork
          winnerName={winnerName}
          setCastTabFlag={setCastTabFlag}
          setReadmoreNavigation={setReadmoreNavigation}
          readmoreNavigation={readmoreNavigation}
          name={name}
          watchFilm={watchFilm}
          masterlistType={masterlistType}
        />
      </Box>
    </Box>
  );
}

 const SidebarContent = ({
  filmographyData,
  winnerName,
  onClose,
  castTabFlag,
  readmoreNavigation,
  setReadmoreNavigation,
  name,
  introData,
  filmTrailer,
  agraphyLinks,
  filmMainImage,
  filmDetails,
  masterlistType,
  artistLinksData,

  ...rest
}) => {
  let { drawerId } = useParams();
  const navigate = useNavigate();
  const [nav, setNav] = useState();

  useEffect(() => {
    if (drawerId) {
      setNav(drawerId);
    }
  }, [drawerId, navigate]);
  const handleNavigation = () => {
    if (readmoreNavigation?.isReadMore) {
      setReadmoreNavigation({ ...readmoreNavigation, isReadMore: false });
      return;
    } else if (nav) {
      navigate(`/satyajit-ray/body-of-work/0/${nav}`);
    } else {
      navigate(-1);
    }
  };
  const [drawerName, setDrawerName] = useState("")

  const drawerData = [
    {
      name: "Satyajit Ray",
      title: "Satyajit Ray",
      links: [
        { link_name: "Working Still", link: "" },
        { link_name: "Film Stills", link: "" },
        {
          link_name: "Books",
          link: "/film/shatranj-ke-khilari-1977/research-centre/",
        },
      ],
      Image: SatyajitRayImg,
      desc: "",
      masterlist: {
        text: "Visit Satyajit Ray Masterlist →",
        link: "/person-poly/satyajit-ray",
      },
    }, {
      name: "Suresh Jindal",
      title: "Suresh Jindal",
      links: [
        {
          link_name: "Books",
          link: "/film/shatranj-ke-khilari-1977/research-centre/",
        },
        { link_name: "Interviews", link: "" },
        { link_name: "Newspaper Clippings", link: "" },
      ],
      Image: SureshJindal,
      desc: "",
      masterlist: {
        text: "Visit Suresh Jindal Masterlist →",
        link: "",
      },
    }];

    const oSections = [
      {
        to:`/${masterlistType}/${winnerName}/posts`,
        title: "",
        text: `${explore.POSTS} | ${explore.INSIGHTS}`,
      },
    ];

  const trailerLink = {
    link: `/${masterlistType}/${winnerName}/watch-film-trailer`,
    image: WatchTrailer,
  };
  return (
    <>
      <BottomMainMenu />
      <CommonLeftSidebar
        castTabFlag={castTabFlag}
        readmoreNavigation={readmoreNavigation}
        setReadmoreNavigation={setReadmoreNavigation}
        sections={introData}
        oSections={oSections}
        hMenu={agraphyLinks}
        LogoLink={filmMainImage}
        filmReleaseDetails={filmDetails}
        trailerLink={trailerLink}
        artistLinksData={artistLinksData}
        handleNavigation={handleNavigation}
        setDrawerName={setDrawerName}
        drawerName={drawerName}
        leftFlag={true}
        drawerData={drawerData}
        drawerImage={SKK1}
      />
    </>
  );
};

export const BodyOfWork = ({
  winnerName,
  setCastTabFlag,
  setReadmoreNavigation,
  readmoreNavigation,
  name,
  watchFilm,
  masterlistType
}) => {
  const [creditsView, setCreditsView] = useState(false);
  const [fullCreditsView, setFullCreditsView] = useState(false);

  const masterlist = winnerName;
  const tableName = "films";

  const handleCreditsView = (flag) => {
    setCreditsView(flag);
    setCastTabFlag(flag);
  };

  const handleReadMoreNavigation = (flag, isOpen, index, title) => {
    setReadmoreNavigation({
      isReadMore: flag,
      isOpen: isOpen,
      clickedImageIndex: index,
      title: title,
    });
  };
  const [attr, setAttr] = useState(1)

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const pathToIndex = {
    'editorial': 0,
    'synopsis': 1,
    'credit-cast': 2,
    'lyrics-soundtrack': 3,
    'watch-fullfilm': 4

  };
  const location = useLocation();
  let { tabValue } = useParams();

  useEffect(() => {
    setAttr(pathToIndex[tabValue] ?? 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, attr]);

  const [tabPanelData, setTabPanelData] = useState([]);
  function formatData() {
    return [
      {
        name: `${explore.EDITORIAL}`,
        component:<Editorial
        setReadMoreFlag={handleReadMoreNavigation}
        readmoreNavigation={readmoreNavigation}
        masterlist={masterlist}
        masterlistType={masterlistType}
      />,
        link:`/${masterlistType}/${masterlist}/agraphy/body-of-work/editorial`,
        list:false
      },
      {
        name: `${explore.SYNOPSIS}`,
        component: <Synopsis masterlist={masterlist} tableName={tableName} />,
        link:`/${masterlistType}/${masterlist}/agraphy/body-of-work/synopsis`,
        list:false
      },
      {
        name: `${explore.CREDITS_CAST}`,
        component: fullCreditsView ? (
          <VerticalLayout
            winnerName={winnerName}
            setCastTabFlag={setCastTabFlag}
            tableName={tableName}
            masterlistType={masterlistType}
            bg="white"
            name={name}
          />
        ) : (
          <HorizontalLayout
            winnerName={winnerName}
            tableName={tableName}
            masterlistType={masterlistType}
            name={name}
          />
        ),
        link:`/${masterlistType}/${masterlist}/agraphy/body-of-work/credit-cast`,
        list:true

      },
      {
        name: `${explore.LYRICS_AND_SOUNDTRACK}`,
        component: <LyricsSoundtrack winnerName={winnerName} />,
        link:`/${masterlistType}/${masterlist}/agraphy/body-of-work/lyrics-soundtrack`,
        list:false

      },
      {
        name: `${filmography.WATCH_FULL_FILM}`,
        component: watchFilm && (
          <WatchFullFilm watchFilm={watchFilm} />
        ),
        link:`/${masterlistType}/${masterlist}/agraphy/body-of-work/watch-fullfilm`,
        list:false
      }

    ];
  }
  useEffect(() => {
    if (masterlist) {
      setTabPanelData(formatData());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [masterlist,fullCreditsView,watchFilm]);

  return (
    <Stack>
      {readmoreNavigation?.isReadMore ? (
        <ReadMore />
      ) : (
        <Container
        maxW={"1195px"}
        pb="4"
        paddingX={{ base: "4", md: "0px" }}
        marginX={{ base: "0px", md: "30px" }}
        >
          <Stack>
            <Box
              position={"fixed"}
              zIndex={999}
              bg="white"
              width={{ base: "100%", md: "1150px" }}
            >
              <HStack
                paddingTop={{ base: "20px", md: "15px" }}
                paddingBottom={{ base: "4", md: "10px" }}
              >
                <Box width={{ base: "100%", md: "656px" }} height={"86px"}>
                <Image
                  src={getImageURL(name)}
                  width={"100%"}
                  objectFit={"contain"}
                  height={"100%"}
                />
                </Box>
              </HStack>
            </Box>
            <HStack width={{ base: "100%", md: "1136px" }}>
              {tabPanelData && tabPanelData.length > 0 && (
                <TabMain
                  data={tabPanelData}
                  pos={"fixed"}
                  width="1135px"
                  attr={attr}
                  setAttr={setAttr}
                  tabTop={{ base: "20px", md: "80px" }}
                  panelTop={{ base: "65px", sm: "85px", md: "130px" }}
                  creditsView={creditsView}
                  fullCreditsView={fullCreditsView}
                  setFullCreditsView={setFullCreditsView}
                  setCastTabFlag={setCastTabFlag}
                  tabHandle={handleCreditsView}
                />
              )}
            </HStack>
          </Stack>
        </Container>
      )}
    </Stack>
  );
};
