export const drawerFactor = (item,dynamicItem) => {
    console.log(dynamicItem,"dynamicItem data")
    // return data.map((item) => {
      const content_data = item?.find((nestedItem) => nestedItem?.key === "content_data")?.value?.split('\n\n')
      const index_data = item?.find((nestedItem) => nestedItem?.key === "index_data")?.value
      const about_book=item?.find((nestedItem) => nestedItem?.key === "about_book")?.value
      const about_author=item?.find((nestedItem) => nestedItem?.key === "about_author")?.value
      const bookName=item?.find((nestedItem) => nestedItem?.key === "title")?.value
      const content_page_image = item?.find((nestedItem) => nestedItem?.key === "direct_url_of_content_image")?.value

      const scanned_pages_image = item?.find(nestedItem => nestedItem?.key === "scanned_pages_image_alias").value?.map(nestedItem => nestedItem?.direct_url_of_cover_image)

      // dynamic data
      const imagePath =dynamicItem?.find((nestedItem) => nestedItem?.key === "direct_url_of_cover_image")?.value || dynamicItem?.find((nestedItem) => nestedItem?.key === "direct_url_of_preview_image")?.value || "";
    const firstName = dynamicItem?.find((nestedItem) => nestedItem?.key === "creator_first_name")?.value
    const lastName = dynamicItem?.find((nestedItem) => nestedItem?.key === "creator_surname")?.value
    const creatorName=dynamicItem?.find((nestedItem) => nestedItem?.key === "creator_name")?.value
    const author = lastName && firstName ? `${firstName} ${lastName}` : "";
    const bookKey =
      dynamicItem?.find((nestedItem) => nestedItem?.key === "title")?.value || null;
    const book = bookKey;
    const link =
      dynamicItem?.find((nestedItem) => nestedItem?.key === "drawer_link")?.value ||
      null;

    const docType =
      dynamicItem?.find((nestedItem) => nestedItem?.key === "document_type")
        ?.value || null;
    const typeCreator =
      dynamicItem?.find((nestedItem) => nestedItem?.key === "type_of_creator")
        ?.value || null;
    const fullname = creatorName!=="" ? author : creatorName;
    const sliderImage =
      dynamicItem?.find((nestedItem) => nestedItem?.key === "drawer_image_alias")
        ?.value ?
      dynamicItem?.find((nestedItem) => nestedItem?.key === "drawer_image_alias")
        ?.value : '';

        const alias = dynamicItem?.find(
          (nestedItem) => nestedItem?.key === "alias"
        )?.value;
        const title = dynamicItem?.find(
          (nestedItem) => nestedItem?.key === "title"
        )?.value;
        const header = `${title ? title :''}`;
        const publisher = dynamicItem?.find(
          (nestedItem) => nestedItem?.key === "place_of_publication"
        )?.value;
        const bookCaption =
          dynamicItem?.find((nestedItem) => nestedItem?.key === "book_caption")?.value ||
          null;
      const publicationYear =
      dynamicItem?.find((nestedItem) => nestedItem?.key === "sortable_year")
        ?.value || null;
      const firstEdition =
      dynamicItem?.find((nestedItem) => nestedItem?.key === "date_of_first_edition")
        ?.value || null;

      return {
      //static data
      content_data,
      index_data,
      about_book,
      about_author,
      scanned_pages_image,
      bookName,
      content_page_image,

      //dynamic data
      publicationYear,
      firstEdition,
      imagePath,
      book,
      link,
      docType,
      typeCreator,
      fullname,
      sliderImage,
      alias,
      header,
      publisher,
      bookCaption,
      body:[
        {
          text: "Index Page →",
        }
      ],
      footer: [
        {
          text: "Contents Page→",
        },
        {
          text: "Scanned Pages→",
        },
        
        ]
      };
    // });
  };