import { isArray } from "lodash";
import { useLocation } from "react-router-dom";

export const pagination = (arr = [], limit = 5, page = 1) => {
  const data = arr.slice((page - 1) * limit, page * limit);
  const totalPage = Math.ceil((arr?.length ? arr?.length : 0) / limit);
  return {
    data,
    totalPage,
    currentPage: page,
    limit,
    nextPage: totalPage > page ? page + 1 : null,
    previewPage: page > 1 ? page - 1 : null,
  };
};

export const filters = (data, filter) => {
  return data.filter((item) => {
    for (let key in filter) {
      if (
        item[key] === undefined ||
        !filter[key]?.includes(
          `${
            item?.[key] && typeof item?.[key] === "string"
              ? item[key].trim()
              : item[key]
          }`
        )
      ) {
        return false;
      }
    }
    return true;
  });
};

export const getWhereGraphqlQuery = (filter, param) => {
  const { prefixQuery, suffixQuery, withComa, withWhere, type } = param
    ? param
    : {};
  const filterKeys = Object.keys(filter ? filter : []);
  let where = ``;
  if (filterKeys.length > 0) {
    where = ``;
    if (withComa || typeof withComa === "undefined") {
      where = `(`;
    }
    if (prefixQuery) {
      where += `${prefixQuery}`;
    }

    if (withWhere || typeof withWhere === "undefined") {
      where += `where: {`;
    }

    filterKeys.forEach((item, key) => {
      let arrData = "";
      if (type === "_in") {
        filter[item].forEach((subItem, index) => {
          if (index) {
            arrData += ", _or:{";
          }
          arrData += `${item}: {_eq:"${subItem}"}`;
          if (index) {
            arrData += "}";
          }
        });
        where += arrData;
      } else {
        where += `${item}: {_eq:"${filter[item]}"}`;
      }
      if (filterKeys.length !== key + 1) {
        where += `, `;
      }
    });

    if (suffixQuery) {
      where += `${suffixQuery}`;
    }

    if (withWhere || typeof withWhere === "undefined") {
      where += `}`;
    }

    if (withComa || typeof withComa === "undefined") {
      where += `)`;
    }
  } else {
  }
  return where;
};

export const getVariableGraphqlQuery = (filter, types) => {
  const filterKeys = Object.keys(filter ? filter : []);
  let variableQuery = "";
  if (filterKeys.length > 0) {
    variableQuery += "(";
  }
  filterKeys.forEach((item, key) => {
    if (Array.isArray(filter[item])) {
      variableQuery += `$${item}: ${types?.[item] ? types[item] : `[String!]`}`;
    } else {
      variableQuery += `$${item}: ${types?.[item] ? types[item] : `String!`}`;
    }
  });
  if (filterKeys.length > 0) {
    variableQuery += ")";
  }
  return variableQuery;
};

export const chunk = (arr, chunkSize) => {
  const res = [];
  for (let i = 0; i < arr.length; i += chunkSize) {
    const chunk = arr.slice(i, i + chunkSize);
    res.push(chunk);
  }
  return res;
};
export const years = (startYear) => {
  var currentYear = new Date().getFullYear(),
    years = [];
  startYear = startYear || 1980;
  while (startYear <= currentYear) {
    years.push(startYear++);
  }
  return years;
};

export const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

export const formatName = (name) => {
  return name
  .split('-')
  .map(word => word.charAt(0).toUpperCase() + word.slice(1))
  .join(' ');
}

export function totalFilterCount(obj) {
  return Object.values(obj).reduce((total, value) => {
    if (Array.isArray(value)) {
      total += value.length;
    }
    return total;
  }, 0);
}
export const formatTime = (item) => {
  let [hours, minutes] = item.split(':').map(Number);
  return `${hours}:${minutes}`
}

export const updateFacetsData = (result) => {
  return result?.facetsHeaders?.map((header) => {
    const key = Object.keys(header)[0];
    const display = header[key];
    const value = isArray( result?.facets) ? result?.facets[0][key] : result?.facets[key]; // Assuming facetsData has only one object

    // Only include facets that have a value
    if (value && value.length > 0) {
      return {
        key,
        display,
        value,
      };
    } else {
      return null;
    }
  }).filter(facet => facet !== null);
}
export const removeFilter = (key, value, setFilterOptions, setFilterPills, setApply,setSearchTerm) => {
  if (key === 'search') {
    setFilterOptions((prevOptions) => ({
      ...prevOptions,
      search: "", // Reset search field
    }));
    setSearchTerm('');
    setFilterPills((prevOptions) => ({
      ...prevOptions,
      search: "", // Reset search field in pills
    }));
  } else {
    // Handle removing a filter from filters object
  setFilterOptions((prevOptions) => {
    const updatedFilters = { ...prevOptions.filters };
    updatedFilters[key] = updatedFilters[key].filter(item => item !== value);
    
    return {
      ...prevOptions,
      filters: updatedFilters,
    };
  });
  setFilterPills((prevOptions) => {
    const updatedFilters = { ...prevOptions.filters };
    updatedFilters[key] = updatedFilters[key].filter(item => item !== value);
    
    return {
      ...prevOptions,
      filters: updatedFilters,
    };
  });
}
  setApply(true);
};

export const createDataObject = (dataArray) => {
  const dataObject = {};
  if (dataArray.length > 0) {
    const dataItem = dataArray[0];
    for (const key in dataItem) {
      dataObject[key] = dataItem[key];
    }
  }
  return dataObject;
};