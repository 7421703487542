import {
  Box,
  Button,
  Container,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Stack,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import axios from "axios";
import Layout from "../components/sections/SidebarLayout";
import H2 from "../components/layouts/Typography/H2";

function ForgotPassword() {
  // const [userName, setUsername] = useState("");
  // const [password, setPassword] = useState("");

  // const [showPassword, setShowPassword] = useState(false);
  // const [showCpassword, setShowCpassword] = useState(false);
  // const [isSubmitted, setIsSubmitted] = useState(false);

  // const toggleShowPassword = () => {
  //   setShowPassword(!showPassword);
  // };
  // const toggleShowCPassword = () => {
  //   setShowCpassword(!showCpassword);
  // };
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_GRAPHQL_URL}`,
        {
          query: `
              mutation MyMutation($name: String!, $email: bpchar!, $password: String!,$username: bpchar!) {
                insert_trcis_users_one(object: {name: $name, email: $email, password: $password, username: $username}) {
                  id
                }
              }
            `,
          variables: {
            name: data.name,
            email: data.email,
            password: data.password,
            username: data.username,
          },
        }
      );

      console.log(response.data);
    } catch (error) {
      console.error(error);
    }
  };
  // const handleCaptchaChange = (value) => {
  //   console.log("Captcha value:", value);
  // };

  return (
    <Layout>
      <Stack className="mobileviewpadding">
        <Container mt="16">
          <Box borderWidth="1px" borderRadius="lg" bg="#f7f7f7" p="4" maxW="md">
            <H2 py="4" textAlign="center">
              Forgot Your Password?
            </H2>
            <form onSubmit={handleSubmit(onSubmit)}>
              <FormControl isInvalid={errors.email} pb="2">
                <FormLabel fontSize={"14px"} marginBottom="4px">
                  Email Address
                </FormLabel>
                <Input
                  bg="white"
                  it="email"
                  name="email"
                  {...register("email", {
                    required: "Email Address is required",
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: "Invalid email address",
                    },
                  })}
                  aria-invalid={errors.email ? "true" : "false"}
                />

                <FormErrorMessage>
                  {errors.email && errors.email?.message}
                </FormErrorMessage>
              </FormControl>

              <Button mt={4} colorScheme="blue" type="submit">
                Next
              </Button>
            </form>
          </Box>
        </Container>
      </Stack>
    </Layout>
  );
}

export default ForgotPassword;
