import { Box, Button, Container, HStack, Image, Stack, Text } from '@chakra-ui/react';

//Core file
import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { Navigation, Pagination, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

//Service file
import * as getPostData from '../../services/Post';

//Language file
import { getImageURL } from '../../util/getImage';
import { WebpageComingSoon } from '../../CommonComponents/WebpageComingSoon';
import PostWithCitation from '../../FunctionalComponents/SimpleGrid/PostWithCitation';
import Grid from '../../FunctionalComponents/SimpleGrid/Grid';
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;
const placeholderImg = `${IMAGE_BASE_PATH}/ui/DummyImage/blank.png`

export default function GlobalPost() {
    const navigate=useNavigate();

    const [postData, setPostData] = useState([]);
    useEffect(() => {
      const getData = async () => {
        const result = await getPostData.getPosts(1,20);

        if (result) {
          const processedData=result
          setPostData(processedData);
        }
      };
      getData();
    }, []);
    const handleGrid = (item) => {
      navigate(`/posts/${item?.alias}`)
    }
    return (
      <>
        {postData ? (
          <>
            <Container
              maxW="auto"
              pr="0px"
              pl="0px"
              marginTop={{ base: "0px", md: "0px" }}
            >
              <Stack width={"100%"} border={{base:"1px",md:"0px"}} borderColor={"color.lightgray4"} position={"relative"} id="postsSliderObject">
                <Swiper
                  modules={[Navigation, Pagination, A11y]}
                  slidesPerView={1}
                  navigation
                  Pagination={true}
                  height={"100%"}
                  className="subslider"
                >
                  {postData && postData.length > 0 &&
                    postData.map((postItem, index) => (
                      <SwiperSlide key={index}>
                        <Box
                          width={{ base: "auto", md: "700px" }}
                          height={{ base: "auto", md: "570px" }}
                          display={"flex"}
                          justifyContent={"center"}
                          className="posthover"
                            id="DivForHoverItem"
                        >
                          <figure>
                          <Link to={`/posts/${postItem.alias}`}>
                            <Image
                              fallbackSrc={placeholderImg}
                              src={getImageURL(postItem?.image)}
                              width={"100%"}
                              height={"100%"}
                              objectFit={"contain"}
                              
                              alt=""
                            />
                               </Link>
                            <HStack alignContent={"center"}>
                              <Box
                                className="top-right"
                                zIndex={1}
                                pt="8"
                                width={{base:"100%",md:"735px"}}
                                height="max-content"
                                position={"absolute"}
                                right={{base:"auto",md:"22%"}}
                              >
                                <Stack
                                  spacing={4}
                                  gap={"0"}
                                  className="btn-wrap-onmobile"
                                  width={{base:"100%",md:"380px"}}
                                  marginLeft={{base:"0px",md:"240"}}
                                >
                                  <HStack>
                                <Text
                                  className="title-on-slide"
                                  fontSize={"20px"}
                                  fontWeight={"700"}
                                  teststyle="secondary"
                                  textAlign={"left"}
                                  id="HiddenText"
                                  color="white"
                                >
                                  {postItem?.title}
                                </Text>
                                </HStack>
                                <HStack gap={"3"} flexWrap={"wrap"}>
                                  {postItem?.masterlist_type &&
                                    postItem?.masterlist_type.map((it, index) => {
                                      const masterlistAlias =
                                        it?.alias || "";
                                      return (
                                        <Button
                                          className={`keyword-btn secondary-color`}
                                          marginInlineStart={"0rem!important"}
                                          id="HiddenText"
                                          onClick={() =>
                                            navigate(`/${it.type}/${masterlistAlias}`)
                                          }
                                          width={"max-content"}
                                        >
                                          {it.name}
                                        </Button>
                                      );
                                    })}
                                    </HStack>
                                </Stack>
                              </Box>
                            </HStack>
                          </figure>
                        </Box>
                      </SwiperSlide>
                    ))}
                </Swiper>
              </Stack>
            </Container>
            <Container px="0" maxW="auto" marginTop={{ base: "25px", md: "40px" }}>
              <Stack>
                {
                  postData && postData?.length > 0 &&
                  <Grid
                    data={postData}
                    columns={[2, null, 3]}
                    spacing={{base:"15px",md:"30px"}}
                    handleGrid={handleGrid}
                    component={PostWithCitation}
                  />
  
                }
              </Stack>
            </Container>
          </>
        ) : (
          <WebpageComingSoon />
        )}
      </>
    )
}
