import React, { useState, useEffect } from 'react';
import { Box, Stack, HStack, Button, Checkbox, Image } from '@chakra-ui/react';
import { userRegistrationForm } from '../../constants/constants';
import Large from '../../Theme/Typography/Large';
import H1 from '../../Theme/Typography/H1';
import H2 from '../../Theme/Typography/H2';
import H3 from '../../Theme/Typography/H3';
import CustomModal from './Modal';
import { getResearchCategories } from '../../services/ResearchCategories';
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;
const loaderImg = `${IMAGE_BASE_PATH}/ui/Chevron/loader.gif`;

const Step2 = ({ selectedValues, setSelectedValues, formData,previousStep, setFormData, nextStep }) => {
  const [categories, setCategories] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [researchCategoriesType, setResearchCategoriesType] = useState([])
  const [isLoading, setIsLoading] = useState(false);
  const handleChange = (type, value, alias) => {
    setSelectedValues((prevValues) => {
      // Determine if the checkbox is being checked or unchecked
      const isChecked = !prevValues[type] || prevValues[type] !== value;
  
      // Create a copy of the current researchCategoriesMapping
      const newMapping = { ...prevValues.researchCategoriesMapping };
      if(value!==prevValues[type]){
        prevValues[type+'Masterlist']=[];
      }
      // Update the mapping based on the checkbox state
      if (isChecked) {
        newMapping[type] = {"alias":alias};
      } else {
        delete newMapping[type];
      }
  
      // Extract the values to form the updated researchCategories array
      // const updatedResearchCategories = Object.values(newMapping);
  
      return {
        ...prevValues,
        [type]: isChecked ? value : "",
        researchCategoriesMapping: newMapping,
      };
    });
  };
  
  

  const closeModal = () => {
    setIsOpen(false);

    setSelectedValues((prevValues) => ({
      ...prevValues,
      researchCategories: categories,
    }));

    setFormData({
      ...formData,
      // researchCategories: categories
    });
  };

  function extractUniqueRelevanceValues(data) {
    // Extract relevance values and filter out null or undefined
    const relevanceValues = data.map(item => {
        let relevanceObj = item.find(element => element.key === "relevance_to_self_discovery_journey");
        return relevanceObj ? relevanceObj.value : null;
    }).filter(value => value !== null);
    
    // Use a Set to get unique values and convert it back to an array
    const uniqueValues = [...new Set(relevanceValues)];
    
    return uniqueValues;
}
  useEffect(() => {
    const getData = async () => {
      try {
      setIsLoading(true);
      const result = await getResearchCategories();
      setIsLoading(false);
      if (result) {
        setCategories(result);
        setResearchCategoriesType(extractUniqueRelevanceValues(result))
      }
    }catch (error) {
      console.error("Error fetching data:", error);
      setIsLoading(false);
    }
    };
    getData();
  }, []);
  const renderCheckboxes = (categoryType) => {
    return categories
      .filter((category) => category.some((item) => item.value === categoryType))
      .map((category, index) => {
        const { name, id,alias, relevance_to_self_discovery_journey } = category.reduce(
          (acc, item) => ({ ...acc, [item.key]: item.value }),
          {}
        );
        return (
          <Box py="1" as="label" key={id}>
            <Checkbox
              colorScheme="blue"
              isChecked={selectedValues[relevance_to_self_discovery_journey.toLowerCase()] === `${id}_${name}`}
              onChange={() => handleChange(relevance_to_self_discovery_journey.toLowerCase(), `${id}_${name}`,alias)}
            >
              <H3 color="color.primary" >{`${id}_${name}`}</H3>
            </Checkbox>
          </Box>
        );
      });
  };

  return (
    <>
      <Stack height={"100%"} px={{ base: "10px", md: "62px" }} pt="25px" paddingBottom={{base:"170px",md:"200px"}}>
        <HStack justifyContent={"space-between"}>
          <HStack>
            <Large fontSize={{base:"24px",md:"34px"}} lineHeight={{base:"28.95px",md:"39.92px"}} fontWeight="600">{userRegistrationForm.LOGIN_FORM_TITLE}</Large>
          </HStack>
        </HStack>
        <H1 py="2" fontWeight="400" fontSize={{base:"18px",md:"24px"}} lineHeight={{base:"24px",md:"28.18px"}}>{userRegistrationForm.CHOOSE}{" "}
        <H1 as="span" borderBottom="2px" fontSize={{base:"18px",md:"24px"}} fontWeight="700" lineHeight={{base:"24px",md:"28.18px"}}>{userRegistrationForm.FOUR}</H1> <H1 as="span" fontSize={{base:"18px",md:"24px"}} fontWeight="700" lineHeight={{base:"24px",md:"28.18px"}}>{" "}{userRegistrationForm.RESEARCH_CATEGORYS}</H1> - {userRegistrationForm.ONE_FROM_EACH_TYPE}
        </H1>
        {!isLoading ? 
        <HStack pt={{base:"4",md:"10"}} flexDirection={{base:"column",md:"row"}} gap={{base:"20px",md:"0px"}} alignItems={"flex-start"}>
        {researchCategoriesType && researchCategoriesType?.length>0 &&
            researchCategoriesType?.map((item,index)=>(
          
              <Stack width={"100%"}>
              <H2 color="color.primary" pb={{base:"0",md:"4"}} className="mt-0" fontWeight="700">{item}</H2>
              <Stack spacing={{base:"2",md:"5"}} direction="column">
                {renderCheckboxes(item)}
              </Stack>
              </Stack>
           ))}
        </HStack>
          :(
        <HStack justifyContent={"center"} minH={"100vh"} alignItems={"flex-start"} mt={12}>
           <Image src={loaderImg} width={"100px"} height={"100px"} alt="Loading" />
        </HStack>
        )}
        <HStack px={{base:'15px',md:"0px"}} width={{base:"100%",md:"auto"}} position={"absolute"} top={"auto"} bottom={{base:"7.5%",md:"25.5"}} left={"auto"} right={{base:"0px",md:"auto"}} alignItems={"flex-end"}/* right="32%" */ justifyContent={"center"}>
        <Button
            size="xl"
            width={{ base: "100%", md: "520px" }}
            mt={{base:"0",md:"4"}}
            bg="color.lightbrown"
            zIndex={{base:"99",md:"999"}}
            onClick={previousStep}
          >
            <H1 fontSize={{base:"18px",md:"24px"}}>{userRegistrationForm.GO_BACK} </H1>
          </Button>
          <Button
            size="xl"
            width={{ base: "100%", md: "565px" }}
            mt={4}
            disabled={!(selectedValues.core !== "" && selectedValues.critical !== "" && selectedValues.material !== "" && selectedValues.meaningful !== "")}
            variant="custom"
            zIndex={{base:"99",md:"999"}}
            onClick={() => {
              if (selectedValues.core !== "" && selectedValues.critical !== "" && selectedValues.material !== "" && selectedValues.meaningful !== "") {
                setIsOpen(true);
              }
            }}
          >
            <H1 fontSize={{base:"18px",md:"24px"}}>{userRegistrationForm.NEXT} </H1>
          </Button>
        </HStack>
      </Stack>
      <CustomModal data={selectedValues} isOpen={isOpen} nextStep={nextStep} onClose={closeModal} width={{ base: "90%", md: "656px" }} />
    </>
  );
};

export default Step2;
