//S3 Bucket osw-dev-images
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;

const slideimg1 = `${IMAGE_BASE_PATH}/ui/Film/ShatranjKeKhilari/BodyOfWork/Editorial/EditorialSlider/1.png`
const slideimg2 = `${IMAGE_BASE_PATH}/ui/Film/ShatranjKeKhilari/BodyOfWork/Editorial/EditorialSlider/2.png`
const slideimg3 = `${IMAGE_BASE_PATH}/ui/Film/ShatranjKeKhilari/BodyOfWork/Editorial/EditorialSlider/3.png`
const slideimg4 = `${IMAGE_BASE_PATH}/ui/Film/ShatranjKeKhilari/BodyOfWork/Editorial/EditorialSlider/4.png`
const slideimg5 = `${IMAGE_BASE_PATH}/ui/Film/ShatranjKeKhilari/BodyOfWork/Editorial/EditorialSlider/5.png`
const slideimg6 = `${IMAGE_BASE_PATH}/ui/Film/ShatranjKeKhilari/BodyOfWork/Editorial/EditorialSlider/6.png`
const slideimg7 = `${IMAGE_BASE_PATH}/ui/Film/ShatranjKeKhilari/BodyOfWork/Editorial/EditorialSlider/7.png`
const slideimg8 = `${IMAGE_BASE_PATH}/ui/Film/ShatranjKeKhilari/BodyOfWork/Editorial/EditorialSlider/8.png`
const sideimg1 = `${IMAGE_BASE_PATH}/ui/Film/ShatranjKeKhilari/BodyOfWork/Editorial/SideImage/1.png`
const sideimg2 = `${IMAGE_BASE_PATH}/ui/Film/ShatranjKeKhilari/BodyOfWork/Editorial/SideImage/2.png`
const sideimg3 = `${IMAGE_BASE_PATH}/ui/Film/ShatranjKeKhilari/BodyOfWork/Editorial/SideImage/3.png`
const sideimg4 = `${IMAGE_BASE_PATH}/ui/Film/ShatranjKeKhilari/BodyOfWork/Editorial/SideImage/4.png`
const sideimg5 = `${IMAGE_BASE_PATH}/ui/Film/ShatranjKeKhilari/BodyOfWork/Editorial/SideImage/5.png`
const sideimg6 = `${IMAGE_BASE_PATH}/ui/Film/ShatranjKeKhilari/BodyOfWork/Editorial/SideImage/6.png`
const sideimg7 = `${IMAGE_BASE_PATH}/ui/Film/ShatranjKeKhilari/BodyOfWork/Editorial/SideImage/7.png`
const sideimg8 = `${IMAGE_BASE_PATH}/ui/Film/ShatranjKeKhilari/BodyOfWork/Editorial/SideImage/8.png`
// const fullImage1 = `${IMAGE_BASE_PATH}/ui/Film/ShatranjKeKhilari/BodyOfWork/Editorial/FullImageView/1.png`
// const fullImage2 = `${IMAGE_BASE_PATH}/ui/Film/ShatranjKeKhilari/BodyOfWork/Editorial/FullImageView/2.png`
// const fullImage3 = `${IMAGE_BASE_PATH}/ui/Film/ShatranjKeKhilari/BodyOfWork/Editorial/FullImageView/3.png`
// const fullImage4 = `${IMAGE_BASE_PATH}/ui/Film/ShatranjKeKhilari/BodyOfWork/Editorial/FullImageView/4.png`
// const fullImage5 = `${IMAGE_BASE_PATH}/ui/Film/ShatranjKeKhilari/BodyOfWork/Editorial/FullImageView/5.png`
// const fullImage6 = `${IMAGE_BASE_PATH}/ui/Film/ShatranjKeKhilari/BodyOfWork/Editorial/FullImageView/6.png`
// const fullImage7 = `${IMAGE_BASE_PATH}/ui/Film/ShatranjKeKhilari/BodyOfWork/Editorial/FullImageView/7.png`
// const fullImage8 = `${IMAGE_BASE_PATH}/ui/Film/ShatranjKeKhilari/BodyOfWork/Editorial/FullImageView/8.png`

export const skk_editorial_data = [
  [
    {
      key: "alias",
      value: "1-working-still-0000071",
      display: "Alias",
    },
    {
      key: "winner",
      value: "shatranj-ke-khilari-1977",
      display: "winner",
    },
    {
      key: "sl_no",
      value: "1",
      display: "sl_no",
    },
    {
      key: "artwork",
      value: [
        {
          key: "shatranj-ke-khilari-1977-working-still-0000071",
          value:
            "https://tuliresearchcentre.canto.global/direct/image/n53iqturnp3q1a324550hnj63q/cNJGeczHIAhyUUZvmvg9WIQNCH8/m800/800",
          date_of_artwork: "1977",
          wnn: "0000071",
          accession_number: "Working Still",
          name_of_publication: "Shatranj Ke Khilari",
          publication_year: "",
          sortable_year_of_publication: "1977",
          artist_name: "Nemai Ghosh",
          medium_of_artwork: "Working Still",
          revised_medium: "CINE.wks",
        },
      ],
      display: "editorial image",
    },
  ],
  [
    {
      key: "alias",
      value: "2-working-still-0000072",
      display: "Alias",
    },
    {
      key: "winner",
      value: "shatranj-ke-khilari-1977",
      display: "winner",
    },
    {
      key: "sl_no",
      value: "2",
      display: "sl_no",
    },
    {
      key: "artwork",
      value: [
        {
          key: "shatranj-ke-khilari-1977-working-still-0000072",
          value:
            "https://tuliresearchcentre.canto.global/direct/image/jnavck4mv51ej2ljtirg9hdi5g/OcdWctmRevkMH8Uixo_bP6GGuFg/m800/800",
          date_of_artwork: "1977",
          wnn: "0000072",
          accession_number: "Working Still",
          name_of_publication: "Shatranj Ke Khilari",
          publication_year: "",
          sortable_year_of_publication: "1977",
          artist_name: "Nemai Ghosh",
          medium_of_artwork: "Photographic Still",
          revised_medium: "CINE.pho",
        },
      ],
      display: "editorial image",
    },
  ],
  [
    {
      key: "alias",
      value: "3-working-still-0000073",
      display: "Alias",
    },
    {
      key: "winner",
      value: "shatranj-ke-khilari-1977",
      display: "winner",
    },
    {
      key: "sl_no",
      value: "3",
      display: "sl_no",
    },
    {
      key: "artwork",
      value: [
        {
          key: "shatranj-ke-khilari-1977-working-still-0000073",
          value:
            "https://tuliresearchcentre.canto.global/direct/image/j6k9fpllfp2ed138qav5givt0k/87_FEj1KeCTkoKEyOiP4ESeYT8Q/m800/800",
          date_of_artwork: "1977",
          wnn: "0000073",
          accession_number: "Working Still",
          name_of_publication: "Shatranj Ke Khilari",
          publication_year: "",
          sortable_year_of_publication: "1977",
          artist_name: "Nemai Ghosh",
          medium_of_artwork: "Working Still",
          revised_medium: "CINE.wks",
        },
      ],
      display: "editorial image",
    },
  ],
  [
    {
      key: "alias",
      value: "4-working-still-0000074",
      display: "Alias",
    },
    {
      key: "winner",
      value: "shatranj-ke-khilari-1977",
      display: "winner",
    },
    {
      key: "sl_no",
      value: "4",
      display: "sl_no",
    },
    {
      key: "artwork",
      value: [
        {
          key: "shatranj-ke-khilari-1977-working-still-0000074",
          value:
            "https://tuliresearchcentre.canto.global/direct/image/g2s1s44tah253djbmdd99edf5n/ZSME5ER5PwPbwOBC6B5reP1861A/m800/800",
          date_of_artwork: "1977",
          wnn: "0000074",
          accession_number: "Working Still",
          name_of_publication: "Shatranj Ke Khilari",
          publication_year: "",
          sortable_year_of_publication: "1977",
          artist_name: "Nemai Ghosh",
          medium_of_artwork: "Photographic Still",
          revised_medium: "CINE.pho",
        },
      ],
      display: "editorial image",
    },
  ],
  [
    {
      key: "alias",
      value: "5-working-still-0000075",
      display: "Alias",
    },
    {
      key: "winner",
      value: "shatranj-ke-khilari-1977",
      display: "winner",
    },
    {
      key: "sl_no",
      value: "5",
      display: "sl_no",
    },
    {
      key: "artwork",
      value: [
        {
          key: "shatranj-ke-khilari-1977-working-still-0000075",
          value:
            "https://tuliresearchcentre.canto.global/direct/image/fsi3cg788p5353n7ug043kkl74/zqcDhC1V_bnajCpc6g77bYt9CKg/m800/800",
          date_of_artwork: "1977",
          wnn: "0000075",
          accession_number: "Working Still",
          name_of_publication: "Shatranj Ke Khilari",
          publication_year: "",
          sortable_year_of_publication: "1977",
          artist_name: "Nemai Ghosh",
          medium_of_artwork: "Photographic Still",
          revised_medium: "CINE.pho",
        },
      ],
      display: "editorial image",
    },
  ],
  [
    {
      key: "alias",
      value: "6-working-still-0000076",
      display: "Alias",
    },
    {
      key: "winner",
      value: "shatranj-ke-khilari-1977",
      display: "winner",
    },
    {
      key: "sl_no",
      value: "6",
      display: "sl_no",
    },
    {
      key: "artwork",
      value: [
        {
          key: "shatranj-ke-khilari-1977-working-still-0000076",
          value:
            "https://tuliresearchcentre.canto.global/direct/image/d87skdd2gt7ide6etmpekecs0s/Da407O3dFJ2bwvweTCa6O-E_E-0/m800/800",
          date_of_artwork: "1977",
          wnn: "0000076",
          accession_number: "Working Still",
          name_of_publication: "Shatranj Ke Khilari",
          publication_year: "",
          sortable_year_of_publication: "1977",
          artist_name: "Nemai Ghosh",
          medium_of_artwork: "Photographic Still",
          revised_medium: "CINE.pho",
        },
      ],
      display: "editorial image",
    },
  ],
  [
    {
      key: "alias",
      value: "7-working-still-0000077",
      display: "Alias",
    },
    {
      key: "winner",
      value: "shatranj-ke-khilari-1977",
      display: "winner",
    },
    {
      key: "sl_no",
      value: "7",
      display: "sl_no",
    },
    {
      key: "artwork",
      value: [
        {
          key: "shatranj-ke-khilari-1977-working-still-0000077",
          value:
            "https://tuliresearchcentre.canto.global/direct/image/ra54q2cgn920td7khpq90rug44/FSSQHVF2TEO9mT6EZiiH0UGiblg/m800/800",
          date_of_artwork: "1977",
          wnn: "0000077",
          accession_number: "Working Still",
          name_of_publication: "Shatranj Ke Khilari",
          publication_year: "",
          sortable_year_of_publication: "1977",
          artist_name: "Nemai Ghosh",
          medium_of_artwork: "Photographic Still",
          revised_medium: "CINE.pho",
        },
      ],
      display: "editorial image",
    },
  ],
  [
    {
      key: "alias",
      value: "8-working-still-0000078",
      display: "Alias",
    },
    {
      key: "winner",
      value: "shatranj-ke-khilari-1977",
      display: "winner",
    },
    {
      key: "sl_no",
      value: "8",
      display: "sl_no",
    },
    {
      key: "artwork",
      value: [
        {
          key: "shatranj-ke-khilari-1977-working-still-0000078",
          value:
            "https://tuliresearchcentre.canto.global/direct/image/kkv3c7gho12sn815ib70nbdk2l/5NkPdeCszQ_sz4TbQP5vxtFWZK8/m800/800",
          date_of_artwork: "1977",
          wnn: "0000078",
          accession_number: "Working Still",
          name_of_publication: "Shatranj Ke Khilari",
          publication_year: "",
          sortable_year_of_publication: "1977",
          artist_name: "Nemai Ghosh",
          medium_of_artwork: "Photographic Still",
          revised_medium: "CINE.pho",
        },
      ],
      display: "editorial image",
    },
  ],
  // [
  //   {
  //     key: "alias",
  //     value: "9-working-still-0000079",
  //     display: "Alias",
  //   },
  //   {
  //     key: "winner",
  //     value: "shatranj-ke-khilari-1977",
  //     display: "winner",
  //   },
  //   {
  //     key: "sl_no",
  //     value: "9",
  //     display: "sl_no",
  //   },
  //   {
  //     key: "artwork",
  //     value: [
  //       {
  //         key: "shatranj-ke-khilari-1977-working-still-0000079",
  //         value:
  //           "https://tuliresearchcentre.canto.global/direct/image/57ck25udvd4p93nihodm7gnd3p/yJ5wVoGBwwKSUwQALb_fs2v6uws/m800/800",
  //         date_of_artwork: "1977",
  //         wnn: "0000079",
  //         accession_number: "Working Still",
  //         name_of_publication: "Shatranj Ke Khilari",
  //         publication_year: "",
  //         sortable_year_of_publication: "1977",
  //         artist_name: "Nemai Ghosh",
  //         medium_of_artwork: "Photographic Still",
  //         revised_medium: "CINE.pho",
  //       },
  //     ],
  //     display: "editorial image",
  //   },
  // ],
  // [
  //   {
  //     key: "alias",
  //     value: "10-working-still-0000080",
  //     display: "Alias",
  //   },
  //   {
  //     key: "winner",
  //     value: "shatranj-ke-khilari-1977",
  //     display: "winner",
  //   },
  //   {
  //     key: "sl_no",
  //     value: "10",
  //     display: "sl_no",
  //   },
  //   {
  //     key: "artwork",
  //     value: [
  //       {
  //         key: "shatranj-ke-khilari-1977-working-still-0000080",
  //         value:
  //           "https://tuliresearchcentre.canto.global/direct/image/b2tjrlpqpd0uv4ao167tl71q4a/8gPaoFaMlQw-Zg_ONEwJR6FW0xQ/m800/800",
  //         date_of_artwork: "1977",
  //         wnn: "0000080",
  //         accession_number: "Working Still",
  //         name_of_publication: "Shatranj Ke Khilari",
  //         publication_year: "",
  //         sortable_year_of_publication: "1977",
  //         artist_name: "Nemai Ghosh",
  //         medium_of_artwork: "Photographic Still",
  //         revised_medium: "CINE.pho",
  //       },
  //     ],
  //     display: "editorial image",
  //   },
  // ],
  // [
  //   {
  //     key: "alias",
  //     value: "11-working-still-0000081",
  //     display: "Alias",
  //   },
  //   {
  //     key: "winner",
  //     value: "shatranj-ke-khilari-1977",
  //     display: "winner",
  //   },
  //   {
  //     key: "sl_no",
  //     value: "11",
  //     display: "sl_no",
  //   },
  //   {
  //     key: "artwork",
  //     value: [
  //       {
  //         key: "shatranj-ke-khilari-1977-working-still-0000081",
  //         value:
  //           "https://tuliresearchcentre.canto.global/direct/image/476qf6augt1tt9or47iulht62e/vnYlUZBnqn_t4WK22yQL-iRtarU/m800/800",
  //         date_of_artwork: "1977",
  //         wnn: "0000081",
  //         accession_number: "Working Still",
  //         name_of_publication: "Shatranj Ke Khilari",
  //         publication_year: "",
  //         sortable_year_of_publication: "1977",
  //         artist_name: "Nemai Ghosh",
  //         medium_of_artwork: "Photographic Still",
  //         revised_medium: "CINE.pho",
  //       },
  //     ],
  //     display: "editorial image",
  //   },
  // ],
  // [
  //   {
  //     key: "alias",
  //     value: "12-working-still-0000082",
  //     display: "Alias",
  //   },
  //   {
  //     key: "winner",
  //     value: "shatranj-ke-khilari-1977",
  //     display: "winner",
  //   },
  //   {
  //     key: "sl_no",
  //     value: "12",
  //     display: "sl_no",
  //   },
  //   {
  //     key: "artwork",
  //     value: [
  //       {
  //         key: "shatranj-ke-khilari-1977-working-still-0000082",
  //         value:
  //           "https://tuliresearchcentre.canto.global/direct/image/1no8pm7kdh5bt6gtcb3ug8vl1o/qw0UC6NLpNWK4ZwDX_W--E1GLnI/m800/800",
  //         date_of_artwork: "1977",
  //         wnn: "0000082",
  //         accession_number: "Working Still",
  //         name_of_publication: "Shatranj Ke Khilari",
  //         publication_year: "",
  //         sortable_year_of_publication: "1977",
  //         artist_name: "Nemai Ghosh",
  //         medium_of_artwork: "Photographic Still",
  //         revised_medium: "CINE.pho",
  //       },
  //     ],
  //     display: "editorial image",
  //   },
  // ],
  // [
  //   {
  //     key: "alias",
  //     value: "13-working-still-0000083",
  //     display: "Alias",
  //   },
  //   {
  //     key: "winner",
  //     value: "shatranj-ke-khilari-1977",
  //     display: "winner",
  //   },
  //   {
  //     key: "sl_no",
  //     value: "13",
  //     display: "sl_no",
  //   },
  //   {
  //     key: "artwork",
  //     value: [
  //       {
  //         key: "shatranj-ke-khilari-1977-working-still-0000083",
  //         value:
  //           "https://tuliresearchcentre.canto.global/direct/image/ifu7ujd9b95ndd1aauh8v3t56t/fx5U73rz8hTFJDiczyLPBEL67A0/m800/800",
  //         date_of_artwork: "1977",
  //         wnn: "0000083",
  //         accession_number: "Working Still",
  //         name_of_publication: "Shatranj Ke Khilari",
  //         publication_year: "",
  //         sortable_year_of_publication: "1977",
  //         artist_name: "Nemai Ghosh",
  //         medium_of_artwork: "Photographic Still",
  //         revised_medium: "CINE.pho",
  //       },
  //     ],
  //     display: "editorial image",
  //   },
  // ],
  // [
  //   {
  //     key: "alias",
  //     value: "14-working-still-0000084",
  //     display: "Alias",
  //   },
  //   {
  //     key: "winner",
  //     value: "shatranj-ke-khilari-1977",
  //     display: "winner",
  //   },
  //   {
  //     key: "sl_no",
  //     value: "14",
  //     display: "sl_no",
  //   },
  //   {
  //     key: "artwork",
  //     value: [
  //       {
  //         key: "shatranj-ke-khilari-1977-working-still-0000084",
  //         value:
  //           "https://tuliresearchcentre.canto.global/direct/image/06jrtk2ghh3tjb78gsafikp452/TWy1S70bjVEKQS_CTAdadvE2zj8/m800/800",
  //         date_of_artwork: "1977",
  //         wnn: "0000084",
  //         accession_number: "Working Still",
  //         name_of_publication: "Shatranj Ke Khilari",
  //         publication_year: "",
  //         sortable_year_of_publication: "1977",
  //         artist_name: "Nemai Ghosh",
  //         medium_of_artwork: "Photographic Still",
  //         revised_medium: "CINE.pho",
  //       },
  //     ],
  //     display: "editorial image",
  //   },
  // ],
  // [
  //   {
  //     key: "alias",
  //     value: "15-working-still-0000085",
  //     display: "Alias",
  //   },
  //   {
  //     key: "winner",
  //     value: "shatranj-ke-khilari-1977",
  //     display: "winner",
  //   },
  //   {
  //     key: "sl_no",
  //     value: "15",
  //     display: "sl_no",
  //   },
  //   {
  //     key: "artwork",
  //     value: [
  //       {
  //         key: "shatranj-ke-khilari-1977-working-still-0000085",
  //         value:
  //           "https://tuliresearchcentre.canto.global/direct/image/kfd2m8t85t6b9e56vdv2lrfa5a/fX8Tp1hjvJ6E0BkDUROapaEotkg/m800/800",
  //         date_of_artwork: "1977",
  //         wnn: "0000085",
  //         accession_number: "Working Still",
  //         name_of_publication: "Shatranj Ke Khilari",
  //         publication_year: "",
  //         sortable_year_of_publication: "1977",
  //         artist_name: "Nemai Ghosh",
  //         medium_of_artwork: "Working Still",
  //         revised_medium: "CINE.wks",
  //       },
  //     ],
  //     display: "editorial image",
  //   },
  // ],
  // [
  //   {
  //     key: "alias",
  //     value: "16-working-still-0000086",
  //     display: "Alias",
  //   },
  //   {
  //     key: "winner",
  //     value: "shatranj-ke-khilari-1977",
  //     display: "winner",
  //   },
  //   {
  //     key: "sl_no",
  //     value: "16",
  //     display: "sl_no",
  //   },
  //   {
  //     key: "artwork",
  //     value: [
  //       {
  //         key: "shatranj-ke-khilari-1977-working-still-0000086",
  //         value:
  //           "https://tuliresearchcentre.canto.global/direct/image/fjbuiu90ah3blf1vi0rcpfmd0s/bx6Lkik-r82OfXkNZOJ50Ej8gMQ/m800/800",
  //         date_of_artwork: "1977",
  //         wnn: "0000086",
  //         accession_number: "Working Still",
  //         name_of_publication: "Shatranj Ke Khilari",
  //         publication_year: "",
  //         sortable_year_of_publication: "1977",
  //         artist_name: "Nemai Ghosh",
  //         medium_of_artwork: "Photographic Still",
  //         revised_medium: "CINE.pho",
  //       },
  //     ],
  //     display: "editorial image",
  //   },
  // ],
  // [
  //   {
  //     key: "alias",
  //     value: "17-working-still-0000087",
  //     display: "Alias",
  //   },
  //   {
  //     key: "winner",
  //     value: "shatranj-ke-khilari-1977",
  //     display: "winner",
  //   },
  //   {
  //     key: "sl_no",
  //     value: "17",
  //     display: "sl_no",
  //   },
  //   {
  //     key: "artwork",
  //     value: [
  //       {
  //         key: "shatranj-ke-khilari-1977-working-still-0000087",
  //         value:
  //           "https://tuliresearchcentre.canto.global/direct/image/e6055ibug96e94d98cqn457i19/R-JxnHsnuL5-9_MiHqFepJt1-aA/m800/800",
  //         date_of_artwork: "1977",
  //         wnn: "0000087",
  //         accession_number: "Working Still",
  //         name_of_publication: "Shatranj Ke Khilari",
  //         publication_year: "",
  //         sortable_year_of_publication: "1977",
  //         artist_name: "Nemai Ghosh",
  //         medium_of_artwork: "Photographic Still",
  //         revised_medium: "CINE.pho",
  //       },
  //     ],
  //     display: "editorial image",
  //   },
  // ],
  // [
  //   {
  //     key: "alias",
  //     value: "18-working-still-0000088",
  //     display: "Alias",
  //   },
  //   {
  //     key: "winner",
  //     value: "shatranj-ke-khilari-1977",
  //     display: "winner",
  //   },
  //   {
  //     key: "sl_no",
  //     value: "18",
  //     display: "sl_no",
  //   },
  //   {
  //     key: "artwork",
  //     value: [
  //       {
  //         key: "shatranj-ke-khilari-1977-working-still-0000088",
  //         value:
  //           "https://tuliresearchcentre.canto.global/direct/image/u7a1u0tgs16jb05b4qjvu7ps7g/pv8iSLb8QiNOomodkM69xG8mWvc/m800/800",
  //         date_of_artwork: "1977",
  //         wnn: "0000088",
  //         accession_number: "Working Still",
  //         name_of_publication: "Shatranj Ke Khilari",
  //         publication_year: "",
  //         sortable_year_of_publication: "1977",
  //         artist_name: "Nemai Ghosh",
  //         medium_of_artwork: "Photographic Still",
  //         revised_medium: "CINE.pho",
  //       },
  //     ],
  //     display: "editorial image",
  //   },
  // ],
  // [
  //   {
  //     key: "alias",
  //     value: "19-working-still-0000089",
  //     display: "Alias",
  //   },
  //   {
  //     key: "winner",
  //     value: "shatranj-ke-khilari-1977",
  //     display: "winner",
  //   },
  //   {
  //     key: "sl_no",
  //     value: "19",
  //     display: "sl_no",
  //   },
  //   {
  //     key: "artwork",
  //     value: [
  //       {
  //         key: "shatranj-ke-khilari-1977-working-still-0000089",
  //         value:
  //           "https://tuliresearchcentre.canto.global/direct/image/ptcuv8d53h0np032mbpg5k994j/JPRuy77lqjdLk-majUt_2DhQ3WQ/m800/800",
  //         date_of_artwork: "1977",
  //         wnn: "0000089",
  //         accession_number: "Working Still",
  //         name_of_publication: "Shatranj Ke Khilari",
  //         publication_year: "",
  //         sortable_year_of_publication: "1977",
  //         artist_name: "Nemai Ghosh",
  //         medium_of_artwork: "Working Still",
  //         revised_medium: "CINE.wks",
  //       },
  //     ],
  //     display: "editorial image",
  //   },
  // ],
  // [
  //   {
  //     key: "alias",
  //     value: "20-working-still-0000090",
  //     display: "Alias",
  //   },
  //   {
  //     key: "winner",
  //     value: "shatranj-ke-khilari-1977",
  //     display: "winner",
  //   },
  //   {
  //     key: "sl_no",
  //     value: "20",
  //     display: "sl_no",
  //   },
  //   {
  //     key: "artwork",
  //     value: [
  //       {
  //         key: "shatranj-ke-khilari-1977-working-still-0000090",
  //         value:
  //           "https://tuliresearchcentre.canto.global/direct/image/plqnf72ndh7kp0mapcv041js6m/o-aBVjimiBi6fp142qxOmQ3hZhQ/m800/800",
  //         date_of_artwork: "1977",
  //         wnn: "0000090",
  //         accession_number: "Working Still",
  //         name_of_publication: "Shatranj Ke Khilari",
  //         publication_year: "",
  //         sortable_year_of_publication: "1977",
  //         artist_name: "Nemai Ghosh",
  //         medium_of_artwork: "Photographic Still",
  //         revised_medium: "CINE.pho",
  //       },
  //     ],
  //     display: "editorial image",
  //   },
  // ],
  // [
  //   {
  //     key: "alias",
  //     value: "21-working-still-0000091",
  //     display: "Alias",
  //   },
  //   {
  //     key: "winner",
  //     value: "shatranj-ke-khilari-1977",
  //     display: "winner",
  //   },
  //   {
  //     key: "sl_no",
  //     value: "21",
  //     display: "sl_no",
  //   },
  //   {
  //     key: "artwork",
  //     value: [
  //       {
  //         key: "shatranj-ke-khilari-1977-working-still-0000091",
  //         value:
  //           "https://tuliresearchcentre.canto.global/direct/image/nkiu2fcn9d0gl316vqvaral84p/3LNmmeAVRGPTfhqdXxWWDlVRWSU/m800/800",
  //         date_of_artwork: "1977",
  //         wnn: "0000091",
  //         accession_number: "Working Still",
  //         name_of_publication: "Shatranj Ke Khilari",
  //         publication_year: "",
  //         sortable_year_of_publication: "1977",
  //         artist_name: "Nemai Ghosh",
  //         medium_of_artwork: "Photographic Still",
  //         revised_medium: "CINE.pho",
  //       },
  //     ],
  //     display: "editorial image",
  //   },
  // ],
  // [
  //   {
  //     key: "alias",
  //     value: "22-working-still-0000092",
  //     display: "Alias",
  //   },
  //   {
  //     key: "winner",
  //     value: "shatranj-ke-khilari-1977",
  //     display: "winner",
  //   },
  //   {
  //     key: "sl_no",
  //     value: "22",
  //     display: "sl_no",
  //   },
  //   {
  //     key: "artwork",
  //     value: [
  //       {
  //         key: "shatranj-ke-khilari-1977-working-still-0000092",
  //         value:
  //           "https://tuliresearchcentre.canto.global/direct/image/hsmmo6cvl13rd8pbca5ipl8u3m/898Oro-h7IxUczCpFhxzZjuPvw8/m800/800",
  //         date_of_artwork: "1977",
  //         wnn: "0000092",
  //         accession_number: "Working Still",
  //         name_of_publication: "Shatranj Ke Khilari",
  //         publication_year: "",
  //         sortable_year_of_publication: "1977",
  //         artist_name: "Nemai Ghosh",
  //         medium_of_artwork: "Working Still",
  //         revised_medium: "CINE.wks",
  //       },
  //     ],
  //     display: "editorial image",
  //   },
  // ],
  // [
  //   {
  //     key: "alias",
  //     value: "23-working-still-0000093",
  //     display: "Alias",
  //   },
  //   {
  //     key: "winner",
  //     value: "shatranj-ke-khilari-1977",
  //     display: "winner",
  //   },
  //   {
  //     key: "sl_no",
  //     value: "23",
  //     display: "sl_no",
  //   },
  //   {
  //     key: "artwork",
  //     value: [
  //       {
  //         key: "shatranj-ke-khilari-1977-working-still-0000093",
  //         value:
  //           "https://tuliresearchcentre.canto.global/direct/image/bcllibqlpt65j86lnvh39di029/bYRMSHBTQrr3xUpyAWIZty2z_Pk/m800/800",
  //         date_of_artwork: "1977",
  //         wnn: "0000093",
  //         accession_number: "Working Still",
  //         name_of_publication: "Shatranj Ke Khilari",
  //         publication_year: "",
  //         sortable_year_of_publication: "1977",
  //         artist_name: "Nemai Ghosh",
  //         medium_of_artwork: "Photographic Still",
  //         revised_medium: "CINE.pho",
  //       },
  //     ],
  //     display: "editorial image",
  //   },
  // ],
  // [
  //   {
  //     key: "alias",
  //     value: "24-working-still-0000094",
  //     display: "Alias",
  //   },
  //   {
  //     key: "winner",
  //     value: "shatranj-ke-khilari-1977",
  //     display: "winner",
  //   },
  //   {
  //     key: "sl_no",
  //     value: "24",
  //     display: "sl_no",
  //   },
  //   {
  //     key: "artwork",
  //     value: [
  //       {
  //         key: "shatranj-ke-khilari-1977-working-still-0000094",
  //         value:
  //           "https://tuliresearchcentre.canto.global/direct/image/6u34t8higp6299b2prlghqh90l/W1pkGBl_IRFeGvVfHY6pCs4Un18/m800/800",
  //         date_of_artwork: "1977",
  //         wnn: "0000094",
  //         accession_number: "Working Still",
  //         name_of_publication: "Shatranj Ke Khilari",
  //         publication_year: "",
  //         sortable_year_of_publication: "1977",
  //         artist_name: "Nemai Ghosh",
  //         medium_of_artwork: "Photographic Still",
  //         revised_medium: "CINE.pho",
  //       },
  //     ],
  //     display: "editorial image",
  //   },
  // ],
  // [
  //   {
  //     key: "alias",
  //     value: "25-working-still-0000095",
  //     display: "Alias",
  //   },
  //   {
  //     key: "winner",
  //     value: "shatranj-ke-khilari-1977",
  //     display: "winner",
  //   },
  //   {
  //     key: "sl_no",
  //     value: "25",
  //     display: "sl_no",
  //   },
  //   {
  //     key: "artwork",
  //     value: [
  //       {
  //         key: "shatranj-ke-khilari-1977-working-still-0000095",
  //         value:
  //           "https://tuliresearchcentre.canto.global/direct/image/2fjeramr6h26j4c5o90b88be3g/R1CaQ8EwX0T1wK8FWif-c8t1cC8/m800/800",
  //         date_of_artwork: "1977",
  //         wnn: "0000095",
  //         accession_number: "Working Still",
  //         name_of_publication: "Shatranj Ke Khilari",
  //         publication_year: "",
  //         sortable_year_of_publication: "1977",
  //         artist_name: "Nemai Ghosh",
  //         medium_of_artwork: "Photographic Still",
  //         revised_medium: "CINE.pho",
  //       },
  //     ],
  //     display: "editorial image",
  //   },
  // ],
  // [
  //   {
  //     key: "alias",
  //     value: "26-working-still-0000096",
  //     display: "Alias",
  //   },
  //   {
  //     key: "winner",
  //     value: "shatranj-ke-khilari-1977",
  //     display: "winner",
  //   },
  //   {
  //     key: "sl_no",
  //     value: "26",
  //     display: "sl_no",
  //   },
  //   {
  //     key: "artwork",
  //     value: [
  //       {
  //         key: "shatranj-ke-khilari-1977-working-still-0000096",
  //         value:
  //           "https://tuliresearchcentre.canto.global/direct/image/gl8tvdelcl4pn9mb01kcn8tn14/zG3QCUDEphX7nekVGKr-4YsbwY4/m800/800",
  //         date_of_artwork: "1977",
  //         wnn: "0000096",
  //         accession_number: "Working Still",
  //         name_of_publication: "Shatranj Ke Khilari",
  //         publication_year: "",
  //         sortable_year_of_publication: "1977",
  //         artist_name: "Nemai Ghosh",
  //         medium_of_artwork: "Working Still",
  //         revised_medium: "CINE.wks",
  //       },
  //     ],
  //     display: "editorial image",
  //   },
  // ],
  // [
  //   {
  //     key: "alias",
  //     value: "27-working-still-0000097",
  //     display: "Alias",
  //   },
  //   {
  //     key: "winner",
  //     value: "shatranj-ke-khilari-1977",
  //     display: "winner",
  //   },
  //   {
  //     key: "sl_no",
  //     value: "27",
  //     display: "sl_no",
  //   },
  //   {
  //     key: "artwork",
  //     value: [
  //       {
  //         key: "shatranj-ke-khilari-1977-working-still-0000097",
  //         value:
  //           "https://tuliresearchcentre.canto.global/direct/image/i3i6abcand2q3257skibmt5v38/SF1RcGvbPD1saojdBWpw0CeoGFQ/m800/800",
  //         date_of_artwork: "1977",
  //         wnn: "0000097",
  //         accession_number: "Working Still",
  //         name_of_publication: "Shatranj Ke Khilari",
  //         publication_year: "",
  //         sortable_year_of_publication: "1977",
  //         artist_name: "Nemai Ghosh",
  //         medium_of_artwork: "Working Still",
  //         revised_medium: "CINE.wks",
  //       },
  //     ],
  //     display: "editorial image",
  //   },
  // ],
  // [
  //   {
  //     key: "alias",
  //     value: "28-working-still-0000098",
  //     display: "Alias",
  //   },
  //   {
  //     key: "winner",
  //     value: "shatranj-ke-khilari-1977",
  //     display: "winner",
  //   },
  //   {
  //     key: "sl_no",
  //     value: "28",
  //     display: "sl_no",
  //   },
  //   {
  //     key: "artwork",
  //     value: [
  //       {
  //         key: "shatranj-ke-khilari-1977-working-still-0000098",
  //         value:
  //           "https://tuliresearchcentre.canto.global/direct/image/dhr6tfr2qd4bv7eog0ofip503j/BdCScCMfyY3uL4AIQWcBQHK8h1I/m800/800",
  //         date_of_artwork: "1977",
  //         wnn: "0000098",
  //         accession_number: "Working Still",
  //         name_of_publication: "Shatranj Ke Khilari",
  //         publication_year: "",
  //         sortable_year_of_publication: "1977",
  //         artist_name: "Nemai Ghosh",
  //         medium_of_artwork: "Photographic Still",
  //         revised_medium: "CINE.pho",
  //       },
  //     ],
  //     display: "editorial image",
  //   },
  // ],
  // [
  //   {
  //     key: "alias",
  //     value: "29-working-still-0000099",
  //     display: "Alias",
  //   },
  //   {
  //     key: "winner",
  //     value: "shatranj-ke-khilari-1977",
  //     display: "winner",
  //   },
  //   {
  //     key: "sl_no",
  //     value: "29",
  //     display: "sl_no",
  //   },
  //   {
  //     key: "artwork",
  //     value: [
  //       {
  //         key: "shatranj-ke-khilari-1977-working-still-0000099",
  //         value:
  //           "https://tuliresearchcentre.canto.global/direct/image/ni80bqb3sl4nbbn9bnpcuhge5n/vhGsVXCw05-Sgi4xR2hQ23Eqv28/m800/800",
  //         date_of_artwork: "1977",
  //         wnn: "0000099",
  //         accession_number: "Working Still",
  //         name_of_publication: "Shatranj Ke Khilari",
  //         publication_year: "",
  //         sortable_year_of_publication: "1977",
  //         artist_name: "Nemai Ghosh",
  //         medium_of_artwork: "Photographic Still",
  //         revised_medium: "CINE.pho",
  //       },
  //     ],
  //     display: "editorial image",
  //   },
  // ],
  // [
  //   {
  //     key: "alias",
  //     value: "30-working-still-0000100",
  //     display: "Alias",
  //   },
  //   {
  //     key: "winner",
  //     value: "shatranj-ke-khilari-1977",
  //     display: "winner",
  //   },
  //   {
  //     key: "sl_no",
  //     value: "30",
  //     display: "sl_no",
  //   },
  //   {
  //     key: "artwork",
  //     value: [
  //       {
  //         key: "shatranj-ke-khilari-1977-working-still-0000100",
  //         value:
  //           "https://tuliresearchcentre.canto.global/direct/image/pea9qnqk392ij29h7r0ckf0128/V0b5QyvzILcgpRLcZBwkQAGJjNY/m800/800",
  //         date_of_artwork: "1977",
  //         wnn: "0000100",
  //         accession_number: "Working Still",
  //         name_of_publication: "Shatranj Ke Khilari",
  //         publication_year: "",
  //         sortable_year_of_publication: "1977",
  //         artist_name: "Nemai Ghosh",
  //         medium_of_artwork: "Working Still",
  //         revised_medium: "CINE.wks",
  //       },
  //     ],
  //     display: "editorial image",
  //   },
  // ],
  // [
  //   {
  //     key: "alias",
  //     value: "31-working-still-0000101",
  //     display: "Alias",
  //   },
  //   {
  //     key: "winner",
  //     value: "shatranj-ke-khilari-1977",
  //     display: "winner",
  //   },
  //   {
  //     key: "sl_no",
  //     value: "31",
  //     display: "sl_no",
  //   },
  //   {
  //     key: "artwork",
  //     value: [
  //       {
  //         key: "shatranj-ke-khilari-1977-working-still-0000101",
  //         value:
  //           "https://tuliresearchcentre.canto.global/direct/image/7go1fuqclh0vtc6ijdt25ic73t/SH0PNi1CbyZkxFlu4Bi4Un0kd_U/m800/800",
  //         date_of_artwork: "1977",
  //         wnn: "0000101",
  //         accession_number: "Working Still",
  //         name_of_publication: "Shatranj Ke Khilari",
  //         publication_year: "",
  //         sortable_year_of_publication: "1977",
  //         artist_name: "Nemai Ghosh",
  //         medium_of_artwork: "Working Still",
  //         revised_medium: "CINE.wks",
  //       },
  //     ],
  //     display: "editorial image",
  //   },
  // ],
  // [
  //   {
  //     key: "alias",
  //     value: "32-working-still-0000102",
  //     display: "Alias",
  //   },
  //   {
  //     key: "winner",
  //     value: "shatranj-ke-khilari-1977",
  //     display: "winner",
  //   },
  //   {
  //     key: "sl_no",
  //     value: "32",
  //     display: "sl_no",
  //   },
  //   {
  //     key: "artwork",
  //     value: [
  //       {
  //         key: "shatranj-ke-khilari-1977-working-still-0000102",
  //         value:
  //           "https://tuliresearchcentre.canto.global/direct/image/vkha0vo83l7v55q7i55gp68447/tnZU2bf5zydqvLjyFZNoMDbaTos/m800/800",
  //         date_of_artwork: "1977",
  //         wnn: "0000102",
  //         accession_number: "Working Still",
  //         name_of_publication: "Shatranj Ke Khilari",
  //         publication_year: "",
  //         sortable_year_of_publication: "1977",
  //         artist_name: "Nemai Ghosh",
  //         medium_of_artwork: "Working Still",
  //         revised_medium: "CINE.wks",
  //       },
  //     ],
  //     display: "editorial image",
  //   },
  // ],
  // [
  //   {
  //     key: "alias",
  //     value: "33-working-still-0000103",
  //     display: "Alias",
  //   },
  //   {
  //     key: "winner",
  //     value: "shatranj-ke-khilari-1977",
  //     display: "winner",
  //   },
  //   {
  //     key: "sl_no",
  //     value: "33",
  //     display: "sl_no",
  //   },
  //   {
  //     key: "artwork",
  //     value: [
  //       {
  //         key: "shatranj-ke-khilari-1977-working-still-0000103",
  //         value:
  //           "https://tuliresearchcentre.canto.global/direct/image/p8ftcfp2rh4c5ddm3ivar7qf6p/0uWWBrgBcfwU3kSDjVQ4PHhKF3s/m800/800",
  //         date_of_artwork: "1977",
  //         wnn: "0000103",
  //         accession_number: "Working Still",
  //         name_of_publication: "Shatranj Ke Khilari",
  //         publication_year: "",
  //         sortable_year_of_publication: "1977",
  //         artist_name: "Nemai Ghosh",
  //         medium_of_artwork: "Working Still",
  //         revised_medium: "CINE.wks",
  //       },
  //     ],
  //     display: "editorial image",
  //   },
  // ],
  // [
  //   {
  //     key: "alias",
  //     value: "34-working-still-0000104",
  //     display: "Alias",
  //   },
  //   {
  //     key: "winner",
  //     value: "shatranj-ke-khilari-1977",
  //     display: "winner",
  //   },
  //   {
  //     key: "sl_no",
  //     value: "34",
  //     display: "sl_no",
  //   },
  //   {
  //     key: "artwork",
  //     value: [
  //       {
  //         key: "shatranj-ke-khilari-1977-working-still-0000104",
  //         value:
  //           "https://tuliresearchcentre.canto.global/direct/image/34pc7tb0kd33fb416u0dfobr2v/VefQg6goUA0MclBp-3OE-1QFOzc/m800/800",
  //         date_of_artwork: "1977",
  //         wnn: "0000104",
  //         accession_number: "Working Still",
  //         name_of_publication: "Shatranj Ke Khilari",
  //         publication_year: "",
  //         sortable_year_of_publication: "1977",
  //         artist_name: "Nemai Ghosh",
  //         medium_of_artwork: "Working Still",
  //         revised_medium: "CINE.wks",
  //       },
  //     ],
  //     display: "editorial image",
  //   },
  // ],
  // [
  //   {
  //     key: "alias",
  //     value: "35-working-still-0000105",
  //     display: "Alias",
  //   },
  //   {
  //     key: "winner",
  //     value: "shatranj-ke-khilari-1977",
  //     display: "winner",
  //   },
  //   {
  //     key: "sl_no",
  //     value: "35",
  //     display: "sl_no",
  //   },
  //   {
  //     key: "artwork",
  //     value: [
  //       {
  //         key: "shatranj-ke-khilari-1977-working-still-0000105",
  //         value:
  //           "https://tuliresearchcentre.canto.global/direct/image/452qlusrd16t9c2a16npjeke0e/SdrKqcrZgLNRHWVR7TiKtGnTh6k/m800/800",
  //         date_of_artwork: "1977",
  //         wnn: "0000105",
  //         accession_number: "Working Still",
  //         name_of_publication: "Shatranj Ke Khilari",
  //         publication_year: "",
  //         sortable_year_of_publication: "1977",
  //         artist_name: "Nemai Ghosh",
  //         medium_of_artwork: "Photographic Still",
  //         revised_medium: "CINE.pho",
  //       },
  //     ],
  //     display: "editorial image",
  //   },
  // ],
  // [
  //   {
  //     key: "alias",
  //     value: "36-working-still-0000106",
  //     display: "Alias",
  //   },
  //   {
  //     key: "winner",
  //     value: "shatranj-ke-khilari-1977",
  //     display: "winner",
  //   },
  //   {
  //     key: "sl_no",
  //     value: "36",
  //     display: "sl_no",
  //   },
  //   {
  //     key: "artwork",
  //     value: [
  //       {
  //         key: "shatranj-ke-khilari-1977-working-still-0000106",
  //         value:
  //           "https://tuliresearchcentre.canto.global/direct/image/l5gl8uf0d97qlb1egq556tk62l/3GeRAUszl3PFTJmEYSrrpVRwalM/m800/800",
  //         date_of_artwork: "1977",
  //         wnn: "0000106",
  //         accession_number: "Working Still",
  //         name_of_publication: "Shatranj Ke Khilari",
  //         publication_year: "",
  //         sortable_year_of_publication: "1977",
  //         artist_name: "Nemai Ghosh",
  //         medium_of_artwork: "Photographic Still",
  //         revised_medium: "CINE.pho",
  //       },
  //     ],
  //     display: "editorial image",
  //   },
  // ],
  // [
  //   {
  //     key: "alias",
  //     value: "37-working-still-0000107",
  //     display: "Alias",
  //   },
  //   {
  //     key: "winner",
  //     value: "shatranj-ke-khilari-1977",
  //     display: "winner",
  //   },
  //   {
  //     key: "sl_no",
  //     value: "37",
  //     display: "sl_no",
  //   },
  //   {
  //     key: "artwork",
  //     value: [
  //       {
  //         key: "shatranj-ke-khilari-1977-working-still-0000107",
  //         value:
  //           "https://tuliresearchcentre.canto.global/direct/image/c4vsh3r6h5247fbaggg74ggl3t/urD_6Ce7Cgzsz8F8dxZyrsruAjA/m800/800",
  //         date_of_artwork: "1977",
  //         wnn: "0000107",
  //         accession_number: "Working Still",
  //         name_of_publication: "Shatranj Ke Khilari",
  //         publication_year: "",
  //         sortable_year_of_publication: "1977",
  //         artist_name: "Nemai Ghosh",
  //         medium_of_artwork: "Photographic Still",
  //         revised_medium: "CINE.pho",
  //       },
  //     ],
  //     display: "editorial image",
  //   },
  // ],
  // [
  //   {
  //     key: "alias",
  //     value: "38-working-still-0000108",
  //     display: "Alias",
  //   },
  //   {
  //     key: "winner",
  //     value: "shatranj-ke-khilari-1977",
  //     display: "winner",
  //   },
  //   {
  //     key: "sl_no",
  //     value: "38",
  //     display: "sl_no",
  //   },
  //   {
  //     key: "artwork",
  //     value: [
  //       {
  //         key: "shatranj-ke-khilari-1977-working-still-0000108",
  //         value:
  //           "https://tuliresearchcentre.canto.global/direct/image/ruor2naspl6lt2g5k0o0b8bu2u/yEsNXid2TiRiqtIBtaSuCUW-r0s/m800/800",
  //         date_of_artwork: "1977",
  //         wnn: "0000108",
  //         accession_number: "Working Still",
  //         name_of_publication: "Shatranj Ke Khilari",
  //         publication_year: "",
  //         sortable_year_of_publication: "1977",
  //         artist_name: "Nemai Ghosh",
  //         medium_of_artwork: "Photographic Still",
  //         revised_medium: "CINE.pho",
  //       },
  //     ],
  //     display: "editorial image",
  //   },
  // ],
  // [
  //   {
  //     key: "alias",
  //     value: "39-working-still-0000109",
  //     display: "Alias",
  //   },
  //   {
  //     key: "winner",
  //     value: "shatranj-ke-khilari-1977",
  //     display: "winner",
  //   },
  //   {
  //     key: "sl_no",
  //     value: "39",
  //     display: "sl_no",
  //   },
  //   {
  //     key: "artwork",
  //     value: [
  //       {
  //         key: "shatranj-ke-khilari-1977-working-still-0000109",
  //         value:
  //           "https://tuliresearchcentre.canto.global/direct/image/jk568tbujt329e0c2gvvdlnd50/6osbhVoDgJ0TvnbAhn78JdFPv_M/m800/800",
  //         date_of_artwork: "1977",
  //         wnn: "0000109",
  //         accession_number: "Working Still",
  //         name_of_publication: "Shatranj Ke Khilari",
  //         publication_year: "",
  //         sortable_year_of_publication: "1977",
  //         artist_name: "Nemai Ghosh",
  //         medium_of_artwork: "Photographic Still",
  //         revised_medium: "CINE.pho",
  //       },
  //     ],
  //     display: "editorial image",
  //   },
  // ],
  // [
  //   {
  //     key: "alias",
  //     value: "40-working-still-0000110",
  //     display: "Alias",
  //   },
  //   {
  //     key: "winner",
  //     value: "shatranj-ke-khilari-1977",
  //     display: "winner",
  //   },
  //   {
  //     key: "sl_no",
  //     value: "40",
  //     display: "sl_no",
  //   },
  //   {
  //     key: "artwork",
  //     value: [
  //       {
  //         key: "shatranj-ke-khilari-1977-working-still-0000110",
  //         value:
  //           "https://tuliresearchcentre.canto.global/direct/image/s8egao1qel5o75076tikjfdl2u/HVQ-9Wsm5x8rpZ1IdgOaql47JG8/m800/800",
  //         date_of_artwork: "1977",
  //         wnn: "0000110",
  //         accession_number: "Working Still",
  //         name_of_publication: "Shatranj Ke Khilari",
  //         publication_year: "",
  //         sortable_year_of_publication: "1977",
  //         artist_name: "Nemai Ghosh",
  //         medium_of_artwork: "Photographic Still",
  //         revised_medium: "CINE.pho",
  //       },
  //     ],
  //     display: "editorial image",
  //   },
  // ],
  // [
  //   {
  //     key: "alias",
  //     value: "41-working-still-0000111",
  //     display: "Alias",
  //   },
  //   {
  //     key: "winner",
  //     value: "shatranj-ke-khilari-1977",
  //     display: "winner",
  //   },
  //   {
  //     key: "sl_no",
  //     value: "41",
  //     display: "sl_no",
  //   },
  //   {
  //     key: "artwork",
  //     value: [
  //       {
  //         key: "shatranj-ke-khilari-1977-working-still-0000111",
  //         value:
  //           "https://tuliresearchcentre.canto.global/direct/image/mdm4v7i4rt27ndgos9occu235a/gANSoUHWph0UCcMjXCD5K2Mff5s/m800/800",
  //         date_of_artwork: "1977",
  //         wnn: "0000111",
  //         accession_number: "Working Still",
  //         name_of_publication: "Shatranj Ke Khilari",
  //         publication_year: "",
  //         sortable_year_of_publication: "1977",
  //         artist_name: "Nemai Ghosh",
  //         medium_of_artwork: "Photographic Still",
  //         revised_medium: "CINE.pho",
  //       },
  //     ],
  //     display: "editorial image",
  //   },
  // ],
  // [
  //   {
  //     key: "alias",
  //     value: "42-working-still-0000112",
  //     display: "Alias",
  //   },
  //   {
  //     key: "winner",
  //     value: "shatranj-ke-khilari-1977",
  //     display: "winner",
  //   },
  //   {
  //     key: "sl_no",
  //     value: "42",
  //     display: "sl_no",
  //   },
  //   {
  //     key: "artwork",
  //     value: [
  //       {
  //         key: "shatranj-ke-khilari-1977-working-still-0000112",
  //         value:
  //           "https://tuliresearchcentre.canto.global/direct/image/i1uadu7l9t7r77h9lburob1o3o/XIZ5xYmSI0NHYlOV4-OYTRhGPtA/m800/800",
  //         date_of_artwork: "1977",
  //         wnn: "0000112",
  //         accession_number: "Working Still",
  //         name_of_publication: "Shatranj Ke Khilari",
  //         publication_year: "",
  //         sortable_year_of_publication: "1977",
  //         artist_name: "Nemai Ghosh",
  //         medium_of_artwork: "Photographic Still",
  //         revised_medium: "CINE.pho",
  //       },
  //     ],
  //     display: "editorial image",
  //   },
  // ],
];
export const mughal_e_azam_editorial_data = [
  [
    {
      key: "alias",
      value: "1-working-still-0000071",
      display: "Alias",
    },
    {
      key: "winner",
      value: "shatranj-ke-khilari-1977",
      display: "winner",
    },
    {
      key: "sl_no",
      value: "1",
      display: "sl_no",
    },
    {
      key: "artwork",
      value: [
        {
          key: "shatranj-ke-khilari-1977-working-still-0000071",
          value:
            "https://tuliresearchcentre.canto.global/direct/image/n53iqturnp3q1a324550hnj63q/cNJGeczHIAhyUUZvmvg9WIQNCH8/m800/800",
          date_of_artwork: "1977",
          wnn: "0000071",
          accession_number: "Working Still",
          name_of_publication: "Shatranj Ke Khilari",
          publication_year: "",
          sortable_year_of_publication: "1977",
          artist_name: "Nemai Ghosh",
          medium_of_artwork: "Working Still",
          revised_medium: "CINE.wks",
        },
      ],
      display: "editorial image",
    },
  ],
  [
    {
      key: "alias",
      value: "2-working-still-0000072",
      display: "Alias",
    },
    {
      key: "winner",
      value: "shatranj-ke-khilari-1977",
      display: "winner",
    },
    {
      key: "sl_no",
      value: "2",
      display: "sl_no",
    },
    {
      key: "artwork",
      value: [
        {
          key: "shatranj-ke-khilari-1977-working-still-0000072",
          value:
            "https://tuliresearchcentre.canto.global/direct/image/jnavck4mv51ej2ljtirg9hdi5g/OcdWctmRevkMH8Uixo_bP6GGuFg/m800/800",
          date_of_artwork: "1977",
          wnn: "0000072",
          accession_number: "Working Still",
          name_of_publication: "Shatranj Ke Khilari",
          publication_year: "",
          sortable_year_of_publication: "1977",
          artist_name: "Nemai Ghosh",
          medium_of_artwork: "Photographic Still",
          revised_medium: "CINE.pho",
        },
      ],
      display: "editorial image",
    },
  ],
  [
    {
      key: "alias",
      value: "3-working-still-0000073",
      display: "Alias",
    },
    {
      key: "winner",
      value: "shatranj-ke-khilari-1977",
      display: "winner",
    },
    {
      key: "sl_no",
      value: "3",
      display: "sl_no",
    },
    {
      key: "artwork",
      value: [
        {
          key: "shatranj-ke-khilari-1977-working-still-0000073",
          value:
            "https://tuliresearchcentre.canto.global/direct/image/j6k9fpllfp2ed138qav5givt0k/87_FEj1KeCTkoKEyOiP4ESeYT8Q/m800/800",
          date_of_artwork: "1977",
          wnn: "0000073",
          accession_number: "Working Still",
          name_of_publication: "Shatranj Ke Khilari",
          publication_year: "",
          sortable_year_of_publication: "1977",
          artist_name: "Nemai Ghosh",
          medium_of_artwork: "Working Still",
          revised_medium: "CINE.wks",
        },
      ],
      display: "editorial image",
    },
  ],
  [
    {
      key: "alias",
      value: "4-working-still-0000074",
      display: "Alias",
    },
    {
      key: "winner",
      value: "shatranj-ke-khilari-1977",
      display: "winner",
    },
    {
      key: "sl_no",
      value: "4",
      display: "sl_no",
    },
    {
      key: "artwork",
      value: [
        {
          key: "shatranj-ke-khilari-1977-working-still-0000074",
          value:
            "https://tuliresearchcentre.canto.global/direct/image/g2s1s44tah253djbmdd99edf5n/ZSME5ER5PwPbwOBC6B5reP1861A/m800/800",
          date_of_artwork: "1977",
          wnn: "0000074",
          accession_number: "Working Still",
          name_of_publication: "Shatranj Ke Khilari",
          publication_year: "",
          sortable_year_of_publication: "1977",
          artist_name: "Nemai Ghosh",
          medium_of_artwork: "Photographic Still",
          revised_medium: "CINE.pho",
        },
      ],
      display: "editorial image",
    },
  ],
  [
    {
      key: "alias",
      value: "5-working-still-0000075",
      display: "Alias",
    },
    {
      key: "winner",
      value: "shatranj-ke-khilari-1977",
      display: "winner",
    },
    {
      key: "sl_no",
      value: "5",
      display: "sl_no",
    },
    {
      key: "artwork",
      value: [
        {
          key: "shatranj-ke-khilari-1977-working-still-0000075",
          value:
            "https://tuliresearchcentre.canto.global/direct/image/fsi3cg788p5353n7ug043kkl74/zqcDhC1V_bnajCpc6g77bYt9CKg/m800/800",
          date_of_artwork: "1977",
          wnn: "0000075",
          accession_number: "Working Still",
          name_of_publication: "Shatranj Ke Khilari",
          publication_year: "",
          sortable_year_of_publication: "1977",
          artist_name: "Nemai Ghosh",
          medium_of_artwork: "Photographic Still",
          revised_medium: "CINE.pho",
        },
      ],
      display: "editorial image",
    },
  ],
  [
    {
      key: "alias",
      value: "6-working-still-0000076",
      display: "Alias",
    },
    {
      key: "winner",
      value: "shatranj-ke-khilari-1977",
      display: "winner",
    },
    {
      key: "sl_no",
      value: "6",
      display: "sl_no",
    },
    {
      key: "artwork",
      value: [
        {
          key: "shatranj-ke-khilari-1977-working-still-0000076",
          value:
            "https://tuliresearchcentre.canto.global/direct/image/d87skdd2gt7ide6etmpekecs0s/Da407O3dFJ2bwvweTCa6O-E_E-0/m800/800",
          date_of_artwork: "1977",
          wnn: "0000076",
          accession_number: "Working Still",
          name_of_publication: "Shatranj Ke Khilari",
          publication_year: "",
          sortable_year_of_publication: "1977",
          artist_name: "Nemai Ghosh",
          medium_of_artwork: "Photographic Still",
          revised_medium: "CINE.pho",
        },
      ],
      display: "editorial image",
    },
  ],
  [
    {
      key: "alias",
      value: "7-working-still-0000077",
      display: "Alias",
    },
    {
      key: "winner",
      value: "shatranj-ke-khilari-1977",
      display: "winner",
    },
    {
      key: "sl_no",
      value: "7",
      display: "sl_no",
    },
    {
      key: "artwork",
      value: [
        {
          key: "shatranj-ke-khilari-1977-working-still-0000077",
          value:
            "https://tuliresearchcentre.canto.global/direct/image/ra54q2cgn920td7khpq90rug44/FSSQHVF2TEO9mT6EZiiH0UGiblg/m800/800",
          date_of_artwork: "1977",
          wnn: "0000077",
          accession_number: "Working Still",
          name_of_publication: "Shatranj Ke Khilari",
          publication_year: "",
          sortable_year_of_publication: "1977",
          artist_name: "Nemai Ghosh",
          medium_of_artwork: "Photographic Still",
          revised_medium: "CINE.pho",
        },
      ],
      display: "editorial image",
    },
  ],
  [
    {
      key: "alias",
      value: "8-working-still-0000078",
      display: "Alias",
    },
    {
      key: "winner",
      value: "shatranj-ke-khilari-1977",
      display: "winner",
    },
    {
      key: "sl_no",
      value: "8",
      display: "sl_no",
    },
    {
      key: "artwork",
      value: [
        {
          key: "shatranj-ke-khilari-1977-working-still-0000078",
          value:
            "https://tuliresearchcentre.canto.global/direct/image/kkv3c7gho12sn815ib70nbdk2l/5NkPdeCszQ_sz4TbQP5vxtFWZK8/m800/800",
          date_of_artwork: "1977",
          wnn: "0000078",
          accession_number: "Working Still",
          name_of_publication: "Shatranj Ke Khilari",
          publication_year: "",
          sortable_year_of_publication: "1977",
          artist_name: "Nemai Ghosh",
          medium_of_artwork: "Photographic Still",
          revised_medium: "CINE.pho",
        },
      ],
      display: "editorial image",
    },
  ],
  [
    {
      key: "alias",
      value: "9-working-still-0000079",
      display: "Alias",
    },
    {
      key: "winner",
      value: "shatranj-ke-khilari-1977",
      display: "winner",
    },
    {
      key: "sl_no",
      value: "9",
      display: "sl_no",
    },
    {
      key: "artwork",
      value: [
        {
          key: "shatranj-ke-khilari-1977-working-still-0000079",
          value:
            "https://tuliresearchcentre.canto.global/direct/image/57ck25udvd4p93nihodm7gnd3p/yJ5wVoGBwwKSUwQALb_fs2v6uws/m800/800",
          date_of_artwork: "1977",
          wnn: "0000079",
          accession_number: "Working Still",
          name_of_publication: "Shatranj Ke Khilari",
          publication_year: "",
          sortable_year_of_publication: "1977",
          artist_name: "Nemai Ghosh",
          medium_of_artwork: "Photographic Still",
          revised_medium: "CINE.pho",
        },
      ],
      display: "editorial image",
    },
  ],
  [
    {
      key: "alias",
      value: "10-working-still-0000080",
      display: "Alias",
    },
    {
      key: "winner",
      value: "shatranj-ke-khilari-1977",
      display: "winner",
    },
    {
      key: "sl_no",
      value: "10",
      display: "sl_no",
    },
    {
      key: "artwork",
      value: [
        {
          key: "shatranj-ke-khilari-1977-working-still-0000080",
          value:
            "https://tuliresearchcentre.canto.global/direct/image/b2tjrlpqpd0uv4ao167tl71q4a/8gPaoFaMlQw-Zg_ONEwJR6FW0xQ/m800/800",
          date_of_artwork: "1977",
          wnn: "0000080",
          accession_number: "Working Still",
          name_of_publication: "Shatranj Ke Khilari",
          publication_year: "",
          sortable_year_of_publication: "1977",
          artist_name: "Nemai Ghosh",
          medium_of_artwork: "Photographic Still",
          revised_medium: "CINE.pho",
        },
      ],
      display: "editorial image",
    },
  ],
  [
    {
      key: "alias",
      value: "11-working-still-0000081",
      display: "Alias",
    },
    {
      key: "winner",
      value: "shatranj-ke-khilari-1977",
      display: "winner",
    },
    {
      key: "sl_no",
      value: "11",
      display: "sl_no",
    },
    {
      key: "artwork",
      value: [
        {
          key: "shatranj-ke-khilari-1977-working-still-0000081",
          value:
            "https://tuliresearchcentre.canto.global/direct/image/476qf6augt1tt9or47iulht62e/vnYlUZBnqn_t4WK22yQL-iRtarU/m800/800",
          date_of_artwork: "1977",
          wnn: "0000081",
          accession_number: "Working Still",
          name_of_publication: "Shatranj Ke Khilari",
          publication_year: "",
          sortable_year_of_publication: "1977",
          artist_name: "Nemai Ghosh",
          medium_of_artwork: "Photographic Still",
          revised_medium: "CINE.pho",
        },
      ],
      display: "editorial image",
    },
  ],
  [
    {
      key: "alias",
      value: "12-working-still-0000082",
      display: "Alias",
    },
    {
      key: "winner",
      value: "shatranj-ke-khilari-1977",
      display: "winner",
    },
    {
      key: "sl_no",
      value: "12",
      display: "sl_no",
    },
    {
      key: "artwork",
      value: [
        {
          key: "shatranj-ke-khilari-1977-working-still-0000082",
          value:
            "https://tuliresearchcentre.canto.global/direct/image/1no8pm7kdh5bt6gtcb3ug8vl1o/qw0UC6NLpNWK4ZwDX_W--E1GLnI/m800/800",
          date_of_artwork: "1977",
          wnn: "0000082",
          accession_number: "Working Still",
          name_of_publication: "Shatranj Ke Khilari",
          publication_year: "",
          sortable_year_of_publication: "1977",
          artist_name: "Nemai Ghosh",
          medium_of_artwork: "Photographic Still",
          revised_medium: "CINE.pho",
        },
      ],
      display: "editorial image",
    },
  ],
  [
    {
      key: "alias",
      value: "13-working-still-0000083",
      display: "Alias",
    },
    {
      key: "winner",
      value: "shatranj-ke-khilari-1977",
      display: "winner",
    },
    {
      key: "sl_no",
      value: "13",
      display: "sl_no",
    },
    {
      key: "artwork",
      value: [
        {
          key: "shatranj-ke-khilari-1977-working-still-0000083",
          value:
            "https://tuliresearchcentre.canto.global/direct/image/ifu7ujd9b95ndd1aauh8v3t56t/fx5U73rz8hTFJDiczyLPBEL67A0/m800/800",
          date_of_artwork: "1977",
          wnn: "0000083",
          accession_number: "Working Still",
          name_of_publication: "Shatranj Ke Khilari",
          publication_year: "",
          sortable_year_of_publication: "1977",
          artist_name: "Nemai Ghosh",
          medium_of_artwork: "Photographic Still",
          revised_medium: "CINE.pho",
        },
      ],
      display: "editorial image",
    },
  ],
  [
    {
      key: "alias",
      value: "14-working-still-0000084",
      display: "Alias",
    },
    {
      key: "winner",
      value: "shatranj-ke-khilari-1977",
      display: "winner",
    },
    {
      key: "sl_no",
      value: "14",
      display: "sl_no",
    },
    {
      key: "artwork",
      value: [
        {
          key: "shatranj-ke-khilari-1977-working-still-0000084",
          value:
            "https://tuliresearchcentre.canto.global/direct/image/06jrtk2ghh3tjb78gsafikp452/TWy1S70bjVEKQS_CTAdadvE2zj8/m800/800",
          date_of_artwork: "1977",
          wnn: "0000084",
          accession_number: "Working Still",
          name_of_publication: "Shatranj Ke Khilari",
          publication_year: "",
          sortable_year_of_publication: "1977",
          artist_name: "Nemai Ghosh",
          medium_of_artwork: "Photographic Still",
          revised_medium: "CINE.pho",
        },
      ],
      display: "editorial image",
    },
  ],
  [
    {
      key: "alias",
      value: "15-working-still-0000085",
      display: "Alias",
    },
    {
      key: "winner",
      value: "shatranj-ke-khilari-1977",
      display: "winner",
    },
    {
      key: "sl_no",
      value: "15",
      display: "sl_no",
    },
    {
      key: "artwork",
      value: [
        {
          key: "shatranj-ke-khilari-1977-working-still-0000085",
          value:
            "https://tuliresearchcentre.canto.global/direct/image/kfd2m8t85t6b9e56vdv2lrfa5a/fX8Tp1hjvJ6E0BkDUROapaEotkg/m800/800",
          date_of_artwork: "1977",
          wnn: "0000085",
          accession_number: "Working Still",
          name_of_publication: "Shatranj Ke Khilari",
          publication_year: "",
          sortable_year_of_publication: "1977",
          artist_name: "Nemai Ghosh",
          medium_of_artwork: "Working Still",
          revised_medium: "CINE.wks",
        },
      ],
      display: "editorial image",
    },
  ],
  [
    {
      key: "alias",
      value: "16-working-still-0000086",
      display: "Alias",
    },
    {
      key: "winner",
      value: "shatranj-ke-khilari-1977",
      display: "winner",
    },
    {
      key: "sl_no",
      value: "16",
      display: "sl_no",
    },
    {
      key: "artwork",
      value: [
        {
          key: "shatranj-ke-khilari-1977-working-still-0000086",
          value:
            "https://tuliresearchcentre.canto.global/direct/image/fjbuiu90ah3blf1vi0rcpfmd0s/bx6Lkik-r82OfXkNZOJ50Ej8gMQ/m800/800",
          date_of_artwork: "1977",
          wnn: "0000086",
          accession_number: "Working Still",
          name_of_publication: "Shatranj Ke Khilari",
          publication_year: "",
          sortable_year_of_publication: "1977",
          artist_name: "Nemai Ghosh",
          medium_of_artwork: "Photographic Still",
          revised_medium: "CINE.pho",
        },
      ],
      display: "editorial image",
    },
  ],
  [
    {
      key: "alias",
      value: "17-working-still-0000087",
      display: "Alias",
    },
    {
      key: "winner",
      value: "shatranj-ke-khilari-1977",
      display: "winner",
    },
    {
      key: "sl_no",
      value: "17",
      display: "sl_no",
    },
    {
      key: "artwork",
      value: [
        {
          key: "shatranj-ke-khilari-1977-working-still-0000087",
          value:
            "https://tuliresearchcentre.canto.global/direct/image/e6055ibug96e94d98cqn457i19/R-JxnHsnuL5-9_MiHqFepJt1-aA/m800/800",
          date_of_artwork: "1977",
          wnn: "0000087",
          accession_number: "Working Still",
          name_of_publication: "Shatranj Ke Khilari",
          publication_year: "",
          sortable_year_of_publication: "1977",
          artist_name: "Nemai Ghosh",
          medium_of_artwork: "Photographic Still",
          revised_medium: "CINE.pho",
        },
      ],
      display: "editorial image",
    },
  ],
  [
    {
      key: "alias",
      value: "18-working-still-0000088",
      display: "Alias",
    },
    {
      key: "winner",
      value: "shatranj-ke-khilari-1977",
      display: "winner",
    },
    {
      key: "sl_no",
      value: "18",
      display: "sl_no",
    },
    {
      key: "artwork",
      value: [
        {
          key: "shatranj-ke-khilari-1977-working-still-0000088",
          value:
            "https://tuliresearchcentre.canto.global/direct/image/u7a1u0tgs16jb05b4qjvu7ps7g/pv8iSLb8QiNOomodkM69xG8mWvc/m800/800",
          date_of_artwork: "1977",
          wnn: "0000088",
          accession_number: "Working Still",
          name_of_publication: "Shatranj Ke Khilari",
          publication_year: "",
          sortable_year_of_publication: "1977",
          artist_name: "Nemai Ghosh",
          medium_of_artwork: "Photographic Still",
          revised_medium: "CINE.pho",
        },
      ],
      display: "editorial image",
    },
  ],
  [
    {
      key: "alias",
      value: "19-working-still-0000089",
      display: "Alias",
    },
    {
      key: "winner",
      value: "shatranj-ke-khilari-1977",
      display: "winner",
    },
    {
      key: "sl_no",
      value: "19",
      display: "sl_no",
    },
    {
      key: "artwork",
      value: [
        {
          key: "shatranj-ke-khilari-1977-working-still-0000089",
          value:
            "https://tuliresearchcentre.canto.global/direct/image/ptcuv8d53h0np032mbpg5k994j/JPRuy77lqjdLk-majUt_2DhQ3WQ/m800/800",
          date_of_artwork: "1977",
          wnn: "0000089",
          accession_number: "Working Still",
          name_of_publication: "Shatranj Ke Khilari",
          publication_year: "",
          sortable_year_of_publication: "1977",
          artist_name: "Nemai Ghosh",
          medium_of_artwork: "Working Still",
          revised_medium: "CINE.wks",
        },
      ],
      display: "editorial image",
    },
  ],
  [
    {
      key: "alias",
      value: "20-working-still-0000090",
      display: "Alias",
    },
    {
      key: "winner",
      value: "shatranj-ke-khilari-1977",
      display: "winner",
    },
    {
      key: "sl_no",
      value: "20",
      display: "sl_no",
    },
    {
      key: "artwork",
      value: [
        {
          key: "shatranj-ke-khilari-1977-working-still-0000090",
          value:
            "https://tuliresearchcentre.canto.global/direct/image/plqnf72ndh7kp0mapcv041js6m/o-aBVjimiBi6fp142qxOmQ3hZhQ/m800/800",
          date_of_artwork: "1977",
          wnn: "0000090",
          accession_number: "Working Still",
          name_of_publication: "Shatranj Ke Khilari",
          publication_year: "",
          sortable_year_of_publication: "1977",
          artist_name: "Nemai Ghosh",
          medium_of_artwork: "Photographic Still",
          revised_medium: "CINE.pho",
        },
      ],
      display: "editorial image",
    },
  ],
  [
    {
      key: "alias",
      value: "21-working-still-0000091",
      display: "Alias",
    },
    {
      key: "winner",
      value: "shatranj-ke-khilari-1977",
      display: "winner",
    },
    {
      key: "sl_no",
      value: "21",
      display: "sl_no",
    },
    {
      key: "artwork",
      value: [
        {
          key: "shatranj-ke-khilari-1977-working-still-0000091",
          value:
            "https://tuliresearchcentre.canto.global/direct/image/nkiu2fcn9d0gl316vqvaral84p/3LNmmeAVRGPTfhqdXxWWDlVRWSU/m800/800",
          date_of_artwork: "1977",
          wnn: "0000091",
          accession_number: "Working Still",
          name_of_publication: "Shatranj Ke Khilari",
          publication_year: "",
          sortable_year_of_publication: "1977",
          artist_name: "Nemai Ghosh",
          medium_of_artwork: "Photographic Still",
          revised_medium: "CINE.pho",
        },
      ],
      display: "editorial image",
    },
  ],
  [
    {
      key: "alias",
      value: "22-working-still-0000092",
      display: "Alias",
    },
    {
      key: "winner",
      value: "shatranj-ke-khilari-1977",
      display: "winner",
    },
    {
      key: "sl_no",
      value: "22",
      display: "sl_no",
    },
    {
      key: "artwork",
      value: [
        {
          key: "shatranj-ke-khilari-1977-working-still-0000092",
          value:
            "https://tuliresearchcentre.canto.global/direct/image/hsmmo6cvl13rd8pbca5ipl8u3m/898Oro-h7IxUczCpFhxzZjuPvw8/m800/800",
          date_of_artwork: "1977",
          wnn: "0000092",
          accession_number: "Working Still",
          name_of_publication: "Shatranj Ke Khilari",
          publication_year: "",
          sortable_year_of_publication: "1977",
          artist_name: "Nemai Ghosh",
          medium_of_artwork: "Working Still",
          revised_medium: "CINE.wks",
        },
      ],
      display: "editorial image",
    },
  ],
  [
    {
      key: "alias",
      value: "23-working-still-0000093",
      display: "Alias",
    },
    {
      key: "winner",
      value: "shatranj-ke-khilari-1977",
      display: "winner",
    },
    {
      key: "sl_no",
      value: "23",
      display: "sl_no",
    },
    {
      key: "artwork",
      value: [
        {
          key: "shatranj-ke-khilari-1977-working-still-0000093",
          value:
            "https://tuliresearchcentre.canto.global/direct/image/bcllibqlpt65j86lnvh39di029/bYRMSHBTQrr3xUpyAWIZty2z_Pk/m800/800",
          date_of_artwork: "1977",
          wnn: "0000093",
          accession_number: "Working Still",
          name_of_publication: "Shatranj Ke Khilari",
          publication_year: "",
          sortable_year_of_publication: "1977",
          artist_name: "Nemai Ghosh",
          medium_of_artwork: "Photographic Still",
          revised_medium: "CINE.pho",
        },
      ],
      display: "editorial image",
    },
  ],
  [
    {
      key: "alias",
      value: "24-working-still-0000094",
      display: "Alias",
    },
    {
      key: "winner",
      value: "shatranj-ke-khilari-1977",
      display: "winner",
    },
    {
      key: "sl_no",
      value: "24",
      display: "sl_no",
    },
    {
      key: "artwork",
      value: [
        {
          key: "shatranj-ke-khilari-1977-working-still-0000094",
          value:
            "https://tuliresearchcentre.canto.global/direct/image/6u34t8higp6299b2prlghqh90l/W1pkGBl_IRFeGvVfHY6pCs4Un18/m800/800",
          date_of_artwork: "1977",
          wnn: "0000094",
          accession_number: "Working Still",
          name_of_publication: "Shatranj Ke Khilari",
          publication_year: "",
          sortable_year_of_publication: "1977",
          artist_name: "Nemai Ghosh",
          medium_of_artwork: "Photographic Still",
          revised_medium: "CINE.pho",
        },
      ],
      display: "editorial image",
    },
  ],
  [
    {
      key: "alias",
      value: "25-working-still-0000095",
      display: "Alias",
    },
    {
      key: "winner",
      value: "shatranj-ke-khilari-1977",
      display: "winner",
    },
    {
      key: "sl_no",
      value: "25",
      display: "sl_no",
    },
    {
      key: "artwork",
      value: [
        {
          key: "shatranj-ke-khilari-1977-working-still-0000095",
          value:
            "https://tuliresearchcentre.canto.global/direct/image/2fjeramr6h26j4c5o90b88be3g/R1CaQ8EwX0T1wK8FWif-c8t1cC8/m800/800",
          date_of_artwork: "1977",
          wnn: "0000095",
          accession_number: "Working Still",
          name_of_publication: "Shatranj Ke Khilari",
          publication_year: "",
          sortable_year_of_publication: "1977",
          artist_name: "Nemai Ghosh",
          medium_of_artwork: "Photographic Still",
          revised_medium: "CINE.pho",
        },
      ],
      display: "editorial image",
    },
  ],
  [
    {
      key: "alias",
      value: "26-working-still-0000096",
      display: "Alias",
    },
    {
      key: "winner",
      value: "shatranj-ke-khilari-1977",
      display: "winner",
    },
    {
      key: "sl_no",
      value: "26",
      display: "sl_no",
    },
    {
      key: "artwork",
      value: [
        {
          key: "shatranj-ke-khilari-1977-working-still-0000096",
          value:
            "https://tuliresearchcentre.canto.global/direct/image/gl8tvdelcl4pn9mb01kcn8tn14/zG3QCUDEphX7nekVGKr-4YsbwY4/m800/800",
          date_of_artwork: "1977",
          wnn: "0000096",
          accession_number: "Working Still",
          name_of_publication: "Shatranj Ke Khilari",
          publication_year: "",
          sortable_year_of_publication: "1977",
          artist_name: "Nemai Ghosh",
          medium_of_artwork: "Working Still",
          revised_medium: "CINE.wks",
        },
      ],
      display: "editorial image",
    },
  ],
  [
    {
      key: "alias",
      value: "27-working-still-0000097",
      display: "Alias",
    },
    {
      key: "winner",
      value: "shatranj-ke-khilari-1977",
      display: "winner",
    },
    {
      key: "sl_no",
      value: "27",
      display: "sl_no",
    },
    {
      key: "artwork",
      value: [
        {
          key: "shatranj-ke-khilari-1977-working-still-0000097",
          value:
            "https://tuliresearchcentre.canto.global/direct/image/i3i6abcand2q3257skibmt5v38/SF1RcGvbPD1saojdBWpw0CeoGFQ/m800/800",
          date_of_artwork: "1977",
          wnn: "0000097",
          accession_number: "Working Still",
          name_of_publication: "Shatranj Ke Khilari",
          publication_year: "",
          sortable_year_of_publication: "1977",
          artist_name: "Nemai Ghosh",
          medium_of_artwork: "Working Still",
          revised_medium: "CINE.wks",
        },
      ],
      display: "editorial image",
    },
  ],
  [
    {
      key: "alias",
      value: "28-working-still-0000098",
      display: "Alias",
    },
    {
      key: "winner",
      value: "shatranj-ke-khilari-1977",
      display: "winner",
    },
    {
      key: "sl_no",
      value: "28",
      display: "sl_no",
    },
    {
      key: "artwork",
      value: [
        {
          key: "shatranj-ke-khilari-1977-working-still-0000098",
          value:
            "https://tuliresearchcentre.canto.global/direct/image/dhr6tfr2qd4bv7eog0ofip503j/BdCScCMfyY3uL4AIQWcBQHK8h1I/m800/800",
          date_of_artwork: "1977",
          wnn: "0000098",
          accession_number: "Working Still",
          name_of_publication: "Shatranj Ke Khilari",
          publication_year: "",
          sortable_year_of_publication: "1977",
          artist_name: "Nemai Ghosh",
          medium_of_artwork: "Photographic Still",
          revised_medium: "CINE.pho",
        },
      ],
      display: "editorial image",
    },
  ],
  [
    {
      key: "alias",
      value: "29-working-still-0000099",
      display: "Alias",
    },
    {
      key: "winner",
      value: "shatranj-ke-khilari-1977",
      display: "winner",
    },
    {
      key: "sl_no",
      value: "29",
      display: "sl_no",
    },
    {
      key: "artwork",
      value: [
        {
          key: "shatranj-ke-khilari-1977-working-still-0000099",
          value:
            "https://tuliresearchcentre.canto.global/direct/image/ni80bqb3sl4nbbn9bnpcuhge5n/vhGsVXCw05-Sgi4xR2hQ23Eqv28/m800/800",
          date_of_artwork: "1977",
          wnn: "0000099",
          accession_number: "Working Still",
          name_of_publication: "Shatranj Ke Khilari",
          publication_year: "",
          sortable_year_of_publication: "1977",
          artist_name: "Nemai Ghosh",
          medium_of_artwork: "Photographic Still",
          revised_medium: "CINE.pho",
        },
      ],
      display: "editorial image",
    },
  ],
  [
    {
      key: "alias",
      value: "30-working-still-0000100",
      display: "Alias",
    },
    {
      key: "winner",
      value: "shatranj-ke-khilari-1977",
      display: "winner",
    },
    {
      key: "sl_no",
      value: "30",
      display: "sl_no",
    },
    {
      key: "artwork",
      value: [
        {
          key: "shatranj-ke-khilari-1977-working-still-0000100",
          value:
            "https://tuliresearchcentre.canto.global/direct/image/pea9qnqk392ij29h7r0ckf0128/V0b5QyvzILcgpRLcZBwkQAGJjNY/m800/800",
          date_of_artwork: "1977",
          wnn: "0000100",
          accession_number: "Working Still",
          name_of_publication: "Shatranj Ke Khilari",
          publication_year: "",
          sortable_year_of_publication: "1977",
          artist_name: "Nemai Ghosh",
          medium_of_artwork: "Working Still",
          revised_medium: "CINE.wks",
        },
      ],
      display: "editorial image",
    },
  ],
  [
    {
      key: "alias",
      value: "31-working-still-0000101",
      display: "Alias",
    },
    {
      key: "winner",
      value: "shatranj-ke-khilari-1977",
      display: "winner",
    },
    {
      key: "sl_no",
      value: "31",
      display: "sl_no",
    },
    {
      key: "artwork",
      value: [
        {
          key: "shatranj-ke-khilari-1977-working-still-0000101",
          value:
            "https://tuliresearchcentre.canto.global/direct/image/7go1fuqclh0vtc6ijdt25ic73t/SH0PNi1CbyZkxFlu4Bi4Un0kd_U/m800/800",
          date_of_artwork: "1977",
          wnn: "0000101",
          accession_number: "Working Still",
          name_of_publication: "Shatranj Ke Khilari",
          publication_year: "",
          sortable_year_of_publication: "1977",
          artist_name: "Nemai Ghosh",
          medium_of_artwork: "Working Still",
          revised_medium: "CINE.wks",
        },
      ],
      display: "editorial image",
    },
  ],
  [
    {
      key: "alias",
      value: "32-working-still-0000102",
      display: "Alias",
    },
    {
      key: "winner",
      value: "shatranj-ke-khilari-1977",
      display: "winner",
    },
    {
      key: "sl_no",
      value: "32",
      display: "sl_no",
    },
    {
      key: "artwork",
      value: [
        {
          key: "shatranj-ke-khilari-1977-working-still-0000102",
          value:
            "https://tuliresearchcentre.canto.global/direct/image/vkha0vo83l7v55q7i55gp68447/tnZU2bf5zydqvLjyFZNoMDbaTos/m800/800",
          date_of_artwork: "1977",
          wnn: "0000102",
          accession_number: "Working Still",
          name_of_publication: "Shatranj Ke Khilari",
          publication_year: "",
          sortable_year_of_publication: "1977",
          artist_name: "Nemai Ghosh",
          medium_of_artwork: "Working Still",
          revised_medium: "CINE.wks",
        },
      ],
      display: "editorial image",
    },
  ],
  [
    {
      key: "alias",
      value: "33-working-still-0000103",
      display: "Alias",
    },
    {
      key: "winner",
      value: "shatranj-ke-khilari-1977",
      display: "winner",
    },
    {
      key: "sl_no",
      value: "33",
      display: "sl_no",
    },
    {
      key: "artwork",
      value: [
        {
          key: "shatranj-ke-khilari-1977-working-still-0000103",
          value:
            "https://tuliresearchcentre.canto.global/direct/image/p8ftcfp2rh4c5ddm3ivar7qf6p/0uWWBrgBcfwU3kSDjVQ4PHhKF3s/m800/800",
          date_of_artwork: "1977",
          wnn: "0000103",
          accession_number: "Working Still",
          name_of_publication: "Shatranj Ke Khilari",
          publication_year: "",
          sortable_year_of_publication: "1977",
          artist_name: "Nemai Ghosh",
          medium_of_artwork: "Working Still",
          revised_medium: "CINE.wks",
        },
      ],
      display: "editorial image",
    },
  ],
  [
    {
      key: "alias",
      value: "34-working-still-0000104",
      display: "Alias",
    },
    {
      key: "winner",
      value: "shatranj-ke-khilari-1977",
      display: "winner",
    },
    {
      key: "sl_no",
      value: "34",
      display: "sl_no",
    },
    {
      key: "artwork",
      value: [
        {
          key: "shatranj-ke-khilari-1977-working-still-0000104",
          value:
            "https://tuliresearchcentre.canto.global/direct/image/34pc7tb0kd33fb416u0dfobr2v/VefQg6goUA0MclBp-3OE-1QFOzc/m800/800",
          date_of_artwork: "1977",
          wnn: "0000104",
          accession_number: "Working Still",
          name_of_publication: "Shatranj Ke Khilari",
          publication_year: "",
          sortable_year_of_publication: "1977",
          artist_name: "Nemai Ghosh",
          medium_of_artwork: "Working Still",
          revised_medium: "CINE.wks",
        },
      ],
      display: "editorial image",
    },
  ],
  [
    {
      key: "alias",
      value: "35-working-still-0000105",
      display: "Alias",
    },
    {
      key: "winner",
      value: "shatranj-ke-khilari-1977",
      display: "winner",
    },
    {
      key: "sl_no",
      value: "35",
      display: "sl_no",
    },
    {
      key: "artwork",
      value: [
        {
          key: "shatranj-ke-khilari-1977-working-still-0000105",
          value:
            "https://tuliresearchcentre.canto.global/direct/image/452qlusrd16t9c2a16npjeke0e/SdrKqcrZgLNRHWVR7TiKtGnTh6k/m800/800",
          date_of_artwork: "1977",
          wnn: "0000105",
          accession_number: "Working Still",
          name_of_publication: "Shatranj Ke Khilari",
          publication_year: "",
          sortable_year_of_publication: "1977",
          artist_name: "Nemai Ghosh",
          medium_of_artwork: "Photographic Still",
          revised_medium: "CINE.pho",
        },
      ],
      display: "editorial image",
    },
  ],
  [
    {
      key: "alias",
      value: "36-working-still-0000106",
      display: "Alias",
    },
    {
      key: "winner",
      value: "shatranj-ke-khilari-1977",
      display: "winner",
    },
    {
      key: "sl_no",
      value: "36",
      display: "sl_no",
    },
    {
      key: "artwork",
      value: [
        {
          key: "shatranj-ke-khilari-1977-working-still-0000106",
          value:
            "https://tuliresearchcentre.canto.global/direct/image/l5gl8uf0d97qlb1egq556tk62l/3GeRAUszl3PFTJmEYSrrpVRwalM/m800/800",
          date_of_artwork: "1977",
          wnn: "0000106",
          accession_number: "Working Still",
          name_of_publication: "Shatranj Ke Khilari",
          publication_year: "",
          sortable_year_of_publication: "1977",
          artist_name: "Nemai Ghosh",
          medium_of_artwork: "Photographic Still",
          revised_medium: "CINE.pho",
        },
      ],
      display: "editorial image",
    },
  ],
  [
    {
      key: "alias",
      value: "37-working-still-0000107",
      display: "Alias",
    },
    {
      key: "winner",
      value: "shatranj-ke-khilari-1977",
      display: "winner",
    },
    {
      key: "sl_no",
      value: "37",
      display: "sl_no",
    },
    {
      key: "artwork",
      value: [
        {
          key: "shatranj-ke-khilari-1977-working-still-0000107",
          value:
            "https://tuliresearchcentre.canto.global/direct/image/c4vsh3r6h5247fbaggg74ggl3t/urD_6Ce7Cgzsz8F8dxZyrsruAjA/m800/800",
          date_of_artwork: "1977",
          wnn: "0000107",
          accession_number: "Working Still",
          name_of_publication: "Shatranj Ke Khilari",
          publication_year: "",
          sortable_year_of_publication: "1977",
          artist_name: "Nemai Ghosh",
          medium_of_artwork: "Photographic Still",
          revised_medium: "CINE.pho",
        },
      ],
      display: "editorial image",
    },
  ],
  [
    {
      key: "alias",
      value: "38-working-still-0000108",
      display: "Alias",
    },
    {
      key: "winner",
      value: "shatranj-ke-khilari-1977",
      display: "winner",
    },
    {
      key: "sl_no",
      value: "38",
      display: "sl_no",
    },
    {
      key: "artwork",
      value: [
        {
          key: "shatranj-ke-khilari-1977-working-still-0000108",
          value:
            "https://tuliresearchcentre.canto.global/direct/image/ruor2naspl6lt2g5k0o0b8bu2u/yEsNXid2TiRiqtIBtaSuCUW-r0s/m800/800",
          date_of_artwork: "1977",
          wnn: "0000108",
          accession_number: "Working Still",
          name_of_publication: "Shatranj Ke Khilari",
          publication_year: "",
          sortable_year_of_publication: "1977",
          artist_name: "Nemai Ghosh",
          medium_of_artwork: "Photographic Still",
          revised_medium: "CINE.pho",
        },
      ],
      display: "editorial image",
    },
  ],
  [
    {
      key: "alias",
      value: "39-working-still-0000109",
      display: "Alias",
    },
    {
      key: "winner",
      value: "shatranj-ke-khilari-1977",
      display: "winner",
    },
    {
      key: "sl_no",
      value: "39",
      display: "sl_no",
    },
    {
      key: "artwork",
      value: [
        {
          key: "shatranj-ke-khilari-1977-working-still-0000109",
          value:
            "https://tuliresearchcentre.canto.global/direct/image/jk568tbujt329e0c2gvvdlnd50/6osbhVoDgJ0TvnbAhn78JdFPv_M/m800/800",
          date_of_artwork: "1977",
          wnn: "0000109",
          accession_number: "Working Still",
          name_of_publication: "Shatranj Ke Khilari",
          publication_year: "",
          sortable_year_of_publication: "1977",
          artist_name: "Nemai Ghosh",
          medium_of_artwork: "Photographic Still",
          revised_medium: "CINE.pho",
        },
      ],
      display: "editorial image",
    },
  ],
  [
    {
      key: "alias",
      value: "40-working-still-0000110",
      display: "Alias",
    },
    {
      key: "winner",
      value: "shatranj-ke-khilari-1977",
      display: "winner",
    },
    {
      key: "sl_no",
      value: "40",
      display: "sl_no",
    },
    {
      key: "artwork",
      value: [
        {
          key: "shatranj-ke-khilari-1977-working-still-0000110",
          value:
            "https://tuliresearchcentre.canto.global/direct/image/s8egao1qel5o75076tikjfdl2u/HVQ-9Wsm5x8rpZ1IdgOaql47JG8/m800/800",
          date_of_artwork: "1977",
          wnn: "0000110",
          accession_number: "Working Still",
          name_of_publication: "Shatranj Ke Khilari",
          publication_year: "",
          sortable_year_of_publication: "1977",
          artist_name: "Nemai Ghosh",
          medium_of_artwork: "Photographic Still",
          revised_medium: "CINE.pho",
        },
      ],
      display: "editorial image",
    },
  ],
  [
    {
      key: "alias",
      value: "41-working-still-0000111",
      display: "Alias",
    },
    {
      key: "winner",
      value: "shatranj-ke-khilari-1977",
      display: "winner",
    },
    {
      key: "sl_no",
      value: "41",
      display: "sl_no",
    },
    {
      key: "artwork",
      value: [
        {
          key: "shatranj-ke-khilari-1977-working-still-0000111",
          value:
            "https://tuliresearchcentre.canto.global/direct/image/mdm4v7i4rt27ndgos9occu235a/gANSoUHWph0UCcMjXCD5K2Mff5s/m800/800",
          date_of_artwork: "1977",
          wnn: "0000111",
          accession_number: "Working Still",
          name_of_publication: "Shatranj Ke Khilari",
          publication_year: "",
          sortable_year_of_publication: "1977",
          artist_name: "Nemai Ghosh",
          medium_of_artwork: "Photographic Still",
          revised_medium: "CINE.pho",
        },
      ],
      display: "editorial image",
    },
  ],
  [
    {
      key: "alias",
      value: "42-working-still-0000112",
      display: "Alias",
    },
    {
      key: "winner",
      value: "shatranj-ke-khilari-1977",
      display: "winner",
    },
    {
      key: "sl_no",
      value: "42",
      display: "sl_no",
    },
    {
      key: "artwork",
      value: [
        {
          key: "shatranj-ke-khilari-1977-working-still-0000112",
          value:
            "https://tuliresearchcentre.canto.global/direct/image/i1uadu7l9t7r77h9lburob1o3o/XIZ5xYmSI0NHYlOV4-OYTRhGPtA/m800/800",
          date_of_artwork: "1977",
          wnn: "0000112",
          accession_number: "Working Still",
          name_of_publication: "Shatranj Ke Khilari",
          publication_year: "",
          sortable_year_of_publication: "1977",
          artist_name: "Nemai Ghosh",
          medium_of_artwork: "Photographic Still",
          revised_medium: "CINE.pho",
        },
      ],
      display: "editorial image",
    },
  ],
];

export const skk_editorial_full_image_data = [
  {
    id: 1,
    image: slideimg1,
    fullImage: "https://tuliresearchcentre.canto.global/direct/image/n53iqturnp3q1a324550hnj63q/cNJGeczHIAhyUUZvmvg9WIQNCH8/m800/800",
    side_image: sideimg1,
    desc: "1_Nemai Ghosh, Shatranj Ke Khilari | 1977 | Working still | CINE.wks",
    author: "Nemai Ghosh",
    filmTitle: "Shatranj Ke Khilari (1977)",
    title: "Working Still, 1977_",
    document_type: "CINE.wks",
    imgNum: "0000071",
    photographer: "Nemai Ghosh",
    featured_actors: "Saeed Jaffrey, Sanjeev Kumar & Satyajit Ray",
    date_of_artwork: "1977",
    content: "Content Awaited",
    description1: "Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.Lorem ipsum is placeholder text commonly used in the ",
  },
  {
    id: 2,
    image: slideimg2,
    fullImage: "https://tuliresearchcentre.canto.global/direct/image/jnavck4mv51ej2ljtirg9hdi5g/OcdWctmRevkMH8Uixo_bP6GGuFg/m800/800",
    side_image: sideimg2,
    desc: "2_Nemai Ghosh, Shatranj Ke Khilari | 1977 | Photographic Still | CINE.pho",
    author: "Nemai Ghosh",
    filmTitle: "Shatranj Ke Khilari (1977)",
    title: "Photographic Still, 1977_",
    document_type: "CINE.pho",
    imgNum: "0000072",
    photographer: "Nemai Ghosh",
    featured_actors: "Sanjeev Kumar & Saeed Jaffrey",
    date_of_artwork: "1977",
    content: "Content Awaited",
    description1: "Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.Lorem ipsum is placeholder text commonly used in the ",
  },
  {
    id: 3,
    image: slideimg3,
    fullImage: "https://tuliresearchcentre.canto.global/direct/image/j6k9fpllfp2ed138qav5givt0k/87_FEj1KeCTkoKEyOiP4ESeYT8Q/m800/800",
    side_image: sideimg3,
    desc: "3_Nemai Ghosh, Shatranj Ke Khilari | 1977 | Working still | CINE.wks",
    author: "Nemai Ghosh",
    filmTitle: "Shatranj Ke Khilari (1977)",
    title: "Working Still, 1977_",
    document_type: "CINE.pho",
    imgNum: "0000072",
    photographer: "Nemai Ghosh",
    featured_actors: "Sanjeev Kumar, Saeed Jaffrey & Satyajit Ray",
    date_of_artwork: "1977",
    content: "Content Awaited",
    description1: "Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.Lorem ipsum is placeholder text commonly used in the ",
  },
  {
    id: 4,
    image: slideimg4,
    fullImage: "https://tuliresearchcentre.canto.global/direct/image/g2s1s44tah253djbmdd99edf5n/ZSME5ER5PwPbwOBC6B5reP1861A/m800/800",
    side_image: sideimg4,
    desc: "4_Nemai Ghosh, Shatranj Ke Khilari | 1977 | Photographic Still | CINE.pho",
    author: "Nemai Ghosh",
    filmTitle: "Shatranj Ke Khilari (1977)",
    title: "Photographic Still, 1977_",
    document_type: "CINE.pho",
    imgNum: "0000074",
    photographer: "Nemai Ghosh",
    featured_actors: "Sanjeev Kumar & Saeed Jaffrey",
    date_of_artwork: "1977",
    content: "Content Awaited",
    description1: "Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.Lorem ipsum is placeholder text commonly used in the ",
  },
  {
    id: 5,
    image: slideimg5,
    fullImage: "https://tuliresearchcentre.canto.global/direct/image/fsi3cg788p5353n7ug043kkl74/zqcDhC1V_bnajCpc6g77bYt9CKg/m800/800",
    side_image: sideimg5,
    desc: "5_Nemai Ghosh, Shatranj Ke Khilari | 1977 | Photographic Still | CINE.pho",
    author: "Nemai Ghosh",
    filmTitle: "Shatranj Ke Khilari (1977)",
    title: "Photographic Still, 1977_",
    document_type: "CINE.pho",
    imgNum: "0000108",
    photographer: "Nemai Ghosh",
    featured_actors: "Sanjeev Kumar & Saeed Jaffrey",
    date_of_artwork: "1977",
    content: "Content Awaited",
    description1: "Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.Lorem ipsum is placeholder text commonly used in the ",
  },
  {
    id: 6,
    image: slideimg6,
    fullImage: "https://tuliresearchcentre.canto.global/direct/image/d87skdd2gt7ide6etmpekecs0s/Da407O3dFJ2bwvweTCa6O-E_E-0/m800/800",
    side_image: sideimg6,
    desc: "6_Nemai Ghosh, Shatranj Ke Khilari | 1977 | Photographic Still | CINE.pho",
    author: "Nemai Ghosh",
    filmTitle: "Shatranj Ke Khilari (1977)",
    title: "Photographic Still, 1977_",
    document_type: "CINE.pho",
    imgNum: "0000107",
    photographer: "Nemai Ghosh",
    featured_actors: "Sanjeev Kumar & Shabana Azmi",
    date_of_artwork: "1977",
    content: "Content Awaited",
    description1: "Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.Lorem ipsum is placeholder text commonly used in the ",
  },
  {
    id: 7,
    image: slideimg7,
    fullImage: "https://tuliresearchcentre.canto.global/direct/image/ra54q2cgn920td7khpq90rug44/FSSQHVF2TEO9mT6EZiiH0UGiblg/m800/800",
    side_image: sideimg7,
    desc: "7_Nemai Ghosh, Shatranj Ke Khilari | 1977 | Photographic Still | CINE.pho",
    author: "Nemai Ghosh",
    filmTitle: "Shatranj Ke Khilari (1977)",
    title: "Photographic Still, 1977_",
    document_type: "CINE.pho",
    imgNum: "0000075",
    photographer: "Nemai Ghosh",
    featured_actors: "Shabana Azmi",
    date_of_artwork: "1977",
    content: "Content Awaited",
    description1: "Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.Lorem ipsum is placeholder text commonly used in the ",
  },
  {
    id: 8,
    image: slideimg8,
    fullImage: "https://tuliresearchcentre.canto.global/direct/image/kkv3c7gho12sn815ib70nbdk2l/5NkPdeCszQ_sz4TbQP5vxtFWZK8/m800/800",
    side_image: sideimg8,
    desc: "8_Nemai Ghosh, Shatranj Ke Khilari | 1977 | Photographic Still | CINE.pho",
    author: "Nemai Ghosh",
    filmTitle: "Shatranj Ke Khilari (1977)",
    title: "Photographic Still, 1977_",
    document_type: "CINE.pho",
    imgNum: "0000076",
    photographer: "Nemai Ghosh",
    featured_actors: "Sanjeev Kumar & Saeed Jaffrey",
    date_of_artwork: "1977",
    content: "Content Awaited",
    description1: "Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.Lorem ipsum is placeholder text commonly used in the ",
  },
];

export const mughal_e_azam_editorial_full_image_data = [];
