export const archiveSchema = [
    { key: "accession_number", display: "accession_number", seq: 1 },
    { key: "alias", display: "alias", seq: 2 },
    { key: "artist_name", display: "artist_name", seq: 3 },
    { key: "any_other_sales_in_auction", display: "any_other_sales_in_auction", seq: 4 },
    { key: "artwork_sr_no_as_per_essay_publication", display: "artwork_sr_no_as_per_essay_publication", seq: 5 },
    { key: "any_gallery_price_list_available_for_artwork", display: "any_gallery_price_list_available_for_artwork", seq: 6 },
    { key: "any_collaborators_for_exhibition", display: "any_collaborators_for_exhibition", seq: 7 },
    { key: "auction_code", display: "auction_code", seq: 8 },
    { key: "auction_date_year", display: "auction_date_year", seq: 9 },
    { key: "additional_information", display: "additional_information", seq: 10 },
    { key: "artwork_in_a_graphy_section_sub_section", display: "artwork_in_a_graphy_section_sub_section", seq: 11 },
    { key: "content_id", display: "content_id", seq: 12 },
    { key: "conversion_rate", display: "conversion_rate", seq: 13 },
    { key: "collection_of_image_links", display: "collection_of_image_links", seq: 14 },
    { key: "current_collection", display: "current_collection", seq: 15 },
    { key: "dates_for_exhibition", display: "dates_for_exhibition", seq: 16 },
    { key: "date_of_artwork", display: "date_of_artwork", seq: 17 },
    { key: "depth_in_cm", display: "depth_in_cm", seq: 18 },
    { key: "depth_in_inches", display: "depth_in_inches", seq: 19 },
    { key: "direct_url_of_preview_image", display: "direct_url_of_preview_image", seq: 20 },
    { key: "featured_artist_or_facial_recognition_names", display: "featured_artist_or_facial_recognition_names", seq: 21 },
    { key: "height_in_cm", display: "height_in_cm", seq: 22 },
    { key: "height_in_inches", display: "height_in_inches", seq: 23 },
    { key: "height_cm", display: "height_cm", seq: 24 },
    { key: "id", display: "id", seq: 25 },
    { key: "image", display: "image", seq: 26 },
    { key: "image_code_from_the_auction_econ_database", display: "image_code_from_the_auction_econ_database", seq: 27 },
    { key: "image_file_name_code", display: "image_file_name_code", seq: 28 },
    { key: "location", display: "location", seq: 29 },
    { key: "masterlist", display: "masterlist", seq: 30 },
    { key: "medium_of_artwork", display: "medium_of_artwork", seq: 31 },
    { key: "name_of_institution", display: "name_of_institution", seq: 32 },
    { key: "name_of_exhibition", display: "name_of_exhibition", seq: 33 },
    { key: "name_of_publication", display: "name_of_publication", seq: 34 },
    { key: "notes6", display: "notes6", seq: 35 },
    { key: "notes5", display: "notes5", seq: 36 },
    { key: "notes4", display: "notes4", seq: 37 },
    { key: "notes3", display: "notes3", seq: 38 },
    { key: "notes2", display: "notes2", seq: 39 },
    { key: "notes1", display: "notes1", seq: 40 },
    { key: "old_image_name", display: "old_image_name", seq: 41 },
    { key: "object_of_focus", display: "object_of_focus", seq: 42 },
    { key: "publication_year", display: "publication_year", seq: 43 },
    { key: "revised_medium", display: "revised_medium", seq: 44 },
    { key: "section", display: "section", seq: 45 },
    { key: "sp_home_currency", display: "sp_home_currency", seq: 46 },
    { key: "sortable_year_of_publication", display: "sortable_year_of_publication", seq: 47 },
    { key: "title", display: "title", seq: 48 },
    { key: "title_of_artwork_listed_in_essay", display: "title_of_artwork_listed_in_essay", seq: 49 },
    { key: "type_of_exhibition", display: "type_of_exhibition", seq: 50 },
    { key: "venues", display: "venues", seq: 51 },
    { key: "volume_number", display: "volume_number", seq: 52 },
    { key: "width_in_inches", display: "width_in_inches", seq: 53 },
    { key: "width_in_cm", display: "width_in_cm", seq: 54 },
    { key: "width_cm", display: "width_cm", seq: 55 },
    { key: "wnn", display: "wnn", seq: 56 },
    { key: "sp_usd", display: "sp_usd", seq: 57 },
    { key: "medium", display: "medium", seq: 58 },
];