import React from 'react';
import { HStack, Tag, TagCloseButton, TagLabel } from '@chakra-ui/react';
import { removeFilter } from '../../util/helper';
import FilterResultCount from './FilterResultCount';

const FilterPills = ({ filters, setFilterOptions,filteredResultCount, setFilterPills, setApply, mb="2",setSearchTerm }) => {
  // Extract filters and search text
  const { search, filters: filterData } = filters;
  return (
    <HStack flexDirection={"row"} alignItems={"baseline"} gap={0} paddingY={"2"}>
       {
         filteredResultCount>0 && 
        <FilterResultCount filteredResultCount={filteredResultCount}/>
      }
      <HStack alignItems={"flex-start"} gap={2} mb={mb} flexWrap={"wrap"}>
        {/* Render search text if present */}
        {search && search!==undefined && (
          <Tag py="3px" marginInlineStart={"0.0rem!important"} minH={"20px"} size="md" color="color.gray" variant={"outline"} borderColor={"color.yellow"}>
            <TagLabel textStyle={"secondary"} color={"black"} fontSize={"12px"}>{search}</TagLabel>
            <TagCloseButton fontSize={"14px"} color={"black"} onClick={() => removeFilter('search', search, setFilterOptions, setFilterPills, setApply,setSearchTerm)} />
          </Tag>
        )}

        {/* Render filter pills */}
        {filterData && Object.keys(filterData).map((key) => {
          const values = filterData[key];
          if (values.length > 0) {
            return (
              <React.Fragment key={key}>
                {values.map((value, index) => (
                  <Tag py="3px" marginInlineStart={"0.0rem!important"} minH={"20px"} key={index} size="md" color="color.gray" variant={"outline"} borderColor={"color.yellow"}>
                    <TagLabel textStyle={"secondary"} color={"black"} fontSize={"12px"}>{value}</TagLabel>
                    <TagCloseButton fontSize={"14px"} color={"black"} onClick={() => removeFilter(key, value, setFilterOptions, setFilterPills, setApply)} />
                  </Tag>
                ))}
              </React.Fragment>
            );
          }
          return null;
        })}
      </HStack>
    </HStack>
  );
};

export default FilterPills;

