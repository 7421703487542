import React, { useEffect, useState } from "react";
import * as getIntroductionData from "../../services/Introduction";
import {
  Box,
  Container,
  Drawer,
  DrawerContent,
  HStack,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import { IoMdCalendar } from "@react-icons/all-files/io/IoMdCalendar";
import { RiLayoutGridFill } from "@react-icons/all-files/ri/RiLayoutGridFill";
import { MdMap } from "@react-icons/all-files/md/MdMap";
import { MdHomeFilled } from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";
import LeftVmenu from "../../FunctionalComponents/LeftVmenu"
import { TextComponent } from "../../CommonComponents/TextComponent";
import Post from "./Post/Post";
import ResearchCentre from "./ResearchCentre/ResearchCentre";

//S3 Bucket osw-dev-images
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;

const sub0 = `${IMAGE_BASE_PATH}/ui/Chevron/subscribe0.png`
const sub1 = `${IMAGE_BASE_PATH}/ui/Chevron/subscribe1.png`

export default function NemaiGhosh() {
  const currentEndpoint = window.location.pathname;
  const parts = currentEndpoint.split("/");
  const masterlistTitle = parts[1];
  const [name, setName] = useState("");
  const { isOpen, onClose } = useDisclosure();
  let { tabIndex } = useParams();
  const [desc, setDesc] = useState("");

  useEffect(() => {
    const getIntroData = async () => {
      const result = await getIntroductionData.introductionByAlias(
        "personality",
        masterlistTitle
      );
      if (result) {
        setName(() => result[0].find((item) => item.key === "name").value);
        setDesc(
          () => result[0].find((item) => item.key === "description").value
        );
      }
    };
    getIntroData();
  }, [masterlistTitle]);
  return (
    <SidebarWithHeader>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        isCentered
        bgOpacity="100%"
        size={"lg"}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalBody>
            <Stack alignItems={"center"}>
              <HStack pt="16" pb="8">
                <Image src={sub0}></Image>
              </HStack>
              <HStack pb="40px">
                <Text fontSize={"35px"} fontWeight={"700"} textAlign="center">
                  Subscribe to Read More
                </Text>
              </HStack>
              <HStack pb="8">
                <Box bg="#FFC015" width={"421px"} p="3">
                  <Text fontSize={"25px"} fontWeight={"700"} textAlign="center">
                    Subscribe
                  </Text>
                </Box>
              </HStack>
              <HStack>
                <Image onClick={onClose} src={sub1}></Image>
              </HStack>
            </Stack>
          </ModalBody>
          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
      <Stack width={"100%"} height={"100%"}>
        <Container maxW={"auto"} px={"0px"}>
          <Tabs
            align={{ xs: "start", sm: "end" }}
            w="full"
            mt="9"
            variant="unstyled"
            defaultIndex={parseInt(tabIndex) ? parseInt(tabIndex) : 0}
          >
            <HStack
              position={"fixed"}
              background={"white"}
              zIndex={9}
              paddingTop={"16px"}
              paddingBottom={"16px"}
              // width={"82%"}
              top={"64px"}
            //   width={{ base: "100%", md: "container.xl" }}
              paddingX={{ base: "0px", md: "32px" }}
            >
              <TabList
                width={{ base: "100%", md: "container.xl" }}
                // w={"100%"}
                justify-content={"flex-end"}
                flexDirection={{ base: "column", md: "row" }}
                pb={{ base: "0", md: "4" }}
                alignItems={"center"}
                justifyContent={"center"}
                className="explorepage-tablist"
              >
                <Tab
                  p="0"
                  textStyle={"tertiary"}
                  fontSize={{ base: "35px", md: "45px" }}
                  fontWeight={"700"}
                  className="textWrap"
                  fontStyle="normal"
                  lineHeight={{ base: "55px", md: "60px" }}
                  _selected={{
                    borderBottom: "4px",
                    borderColor: "#333333",
                  }}
                >
                  {name}
                </Tab>
              </TabList>
            </HStack>

            <TabPanels paddingTop={{ base: "160px", md: "90px" }}>
              <TabPanel px={"0"} p="0px" className="introText">
                <TextComponent text={desc} fontSize={"16px"} fontWeight={500} />
              </TabPanel>
            </TabPanels>
          </Tabs>
          <HStack
            background={"white"}
            zIndex={9}
            paddingTop={"16px"}
            // paddingBottom={"16px"}
            // width={"82%"}
            // width={{ base: "100%", md: "container.xl" }}
            paddingX={{ base: "0px", md: "64px" }}
          >
            {" "}
            <Post masterlistTitle={masterlistTitle}/>
          </HStack>
          <HStack
            background={"white"}
            zIndex={9}
            paddingTop={"16px"}
            // paddingBottom={"16px"}
            // width={"82%"}
            // width={{ base: "100%", md: "container.xl" }}
            margin={{ base: "0px", md: "0px 0px 0px 64px" }}
          >
            {" "}
            <ResearchCentre/>
          </HStack>
        </Container>
      </Stack>
    </SidebarWithHeader>
  );
}
const SidebarWithHeader = ({ children }) => {
  const { isOpen, onClose } = useDisclosure();
  return (
    <Box minH="100vh" bg={useColorModeValue("gray.100", "gray.900")}>
      <SidebarContent
        onClose={() => onClose}
        display={{ base: "none", md: "block" }}
      />
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
      >
        <DrawerContent>
          <SidebarContent onClose={onClose} />
        </DrawerContent>
      </Drawer>
      <Box ml={{ base: 0, md: 14 }} bg="white">
        {children}
      </Box>
    </Box>
  );
};
const SidebarContent = ({ onClose, display, ...rest }) => {
  const [error, setError] = useState(false);
  const toggleModal = () => {
    setError(!error);
  };
  const LeftVmenuData = [
    {
      link: "/",
      id: "homeicon",
      icon: MdHomeFilled,
    },
    {
      link: "/explore",
      id: "exploreicon",
      icon: RiLayoutGridFill,
      active: true,
    },
    {
      link: "/calendar",
      id: "calendaricon",
      icon: IoMdCalendar,
    },
    {
      link: "/map",
      id: "mapicon",
      icon: MdMap,
    },
  ];
  const { drawerId } = useParams();
  const [nav, setNav] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    if (drawerId) {
      setNav(drawerId);
    }
  }, [drawerId, navigate]);
  const handleNavigation = () => {
    if (nav) {
      navigate(`/shatranj-ke-khilari-1977/body-of-work/2/${nav}`);
    } else {
      navigate(-1);
    }
  };
  return (
    <LeftVmenu
      backArrow={true}
      display={display}
      onClose={onClose}
      toggleModal={toggleModal}
      handleNavigation={handleNavigation}
      LeftVmenuData={LeftVmenuData}
    />
  );
};
