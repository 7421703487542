import { Text } from "@chakra-ui/react";
import React from "react";

const LargeRegular = ({ children,textStyle,lineHeight, ...props }) => {
  return (
    <Text
      as="p"
      {...props}
      fontSize="15px"
      fontWeight="400"
      lineHeight={lineHeight}
      textStyle={textStyle}
    >
      {children}
    </Text>
  );
};
export default React.memo(LargeRegular);
