import { Box, Container, HStack, Stack, Text, Image } from "@chakra-ui/react";

//Core files
import React from "react";
import { useTranslation } from "react-i18next";

//Service files
import { CopyPasteService } from "../../../../../services/CopyPaste";

//Component files
import { FullScreenImage } from "../../../Component/FullScreenImage";
import ImageWithCitation2 from "../../../../../FunctionalComponents/SimpleGrid/ImageWithCitation2";
import Grid from "../../../../../FunctionalComponents/SimpleGrid/Grid";

//Language files
import { explore } from "../../../../../constants/constants";
import { FilterBox } from "../../../../../FunctionalComponents/Filter/FilterBox";
import InfiniteScroll from "react-infinite-scroll-component";
import NoMoreToLoad from "../../../../../CommonComponents/NoMoreToLoad";
import FilterPills from "../../../../../components/FilterPills/FilterPills";
import { DataNotFound } from "../../../../../CommonComponents/DataNotFound";

//S3 Bucket osw-dev-images
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;

const Filter = `${IMAGE_BASE_PATH}/ui/Chevron/filter.png`;
const loaderImg = `${IMAGE_BASE_PATH}/ui/Chevron/loader.gif`;

export default function GridView({
  alias,
  //new data
  showFilterBox,
  handleFilterClick,
  data,
  fetchMoreData,
  hasMore,
  setShowFilterBox,
  setApply,
  explore,
  apply,
  handleCheckboxChange,
  handleSearch,
  filterOptions,
  filteroptionData,
  setFilterOptions,
  setSearchTerm,
  filterCount,
  searchTerm,
  handleReset,
  handleImageClick,
  fullScreenImage,
  ImgData,
  FilterData,
  clickedImageIndex,
  setClickedImageIndex,
  showImages,
  setFilterPills,
  filterPills,
  filteredResultCount
}) {

  return (
   <>
    <Stack flexDirection={"row-reverse"}>
        {!showFilterBox && <FilterButton marginRight={"-61px"} onClick={handleFilterClick} />}
        <Container
          maxW="auto"
          className="songs"
          px={{ base: "4", md: "0px" }}
          py={"8"}
          position={"relative"}
        >
          <Stack flexDirection={"row"} gap={4} justifyContent="center">
            {data && data.length > 0 ? (
              <Stack
                onCopy={(e) =>
                  CopyPasteService(e, `${window.location.href}/0/1`)
                }
                paddingRight={{ base: "0px", md: "45px" }}
                alignItems={"flex-start"}
              >
         {((filterPills &&  filterPills!==undefined && filterCount>0) || filterOptions?.search) &&
          <FilterPills
          filters={filterPills}
          setFilterOptions={setFilterOptions}
          setApply={setApply}
          setSearchTerm={setSearchTerm}
          setFilterPills={setFilterPills}
          filteredResultCount={filteredResultCount}
          />
          }
                <InfiniteScroll
                  dataLength={data.length}
                  next={fetchMoreData}
                  hasMore={hasMore}
                  loader={
                    <Box textAlign="center" marginLeft={"50%"}>
                      <Stack marginTop={"20px"}>
                        <img
                          src={loaderImg}
                          width={"100px"}
                          height={"100px"}
                          alt="Loading"
                        />
                      </Stack>
                    </Box>
                  }
                  endMessage={<NoMoreToLoad />}
                >
                <Grid
                  data={data}
                  columns={{ base: 1, md: 3, lg: 3, xl: 4 }}
                  spacing={{ base: "10px", md: "24px" }}
                  handleImageClick={handleImageClick}
                  component={ImageWithCitation2}
                />
                </InfiniteScroll>
              </Stack>
            ) 
            : (
              <>
                <Stack
                  onCopy={(e) =>
                    CopyPasteService(e, `${window.location.href}/0/1`)
                  }
                  margin={"auto"}
                >
                  <Box position={"relative"} top="-70px" right={"auto"} left={"0px"}>
                  {filterPills &&  filterPills!==undefined && filterCount>0&&
               <FilterPills
               filters={filterPills}
               setFilterOptions={setFilterOptions}
               setApply={setApply}
               setFilterPills={setFilterPills}
                  />
                }
          </Box>
                  <DataNotFound />
                </Stack>
              </>
            )}
            <HStack
              marginRight={"-61px!important"}
              className="mt-0"
              alignItems={"flex-start"}
            >
              {showFilterBox && (
                <FilterBox
                setShowFilterBox={setShowFilterBox}
                setApply={setApply}
                explore={explore}
                apply={apply}
                handleCheckboxChange={handleCheckboxChange}
                handleSearch={handleSearch}
                filterOptions={filterOptions}
                filteroptionData={filteroptionData}
                setFilterOptions={setFilterOptions}
                setSearchTerm={setSearchTerm}
                filterCount={filterCount}
                searchTerm={searchTerm}
                handleReset={handleReset}
                className="common-box-filter"
                />
              )}
            </HStack>
          </Stack>
        </Container>
        {fullScreenImage && (
        <FullScreenImage
          isOpen={fullScreenImage}
          onClose={() => showImages(null, false)}
          imageData={ImgData}
          data={data}
          clickedImageIndex={clickedImageIndex}
          setClickedImageIndex={setClickedImageIndex}
          bannerFlag={false}
          title="banner"
        />
      )}
      </Stack>
   </>
  );
}

export const FilterButton = ({ onClick, marginRight, paddingTop }) => {
  const { t } = useTranslation("");

  return (
    <>
      <Stack
        position={"relative"}
        pos={"fixed"}
        marginTop={"100px"}
        marginRight={marginRight}
        paddingTop={paddingTop}
        zIndex={"99!important"}
        display={{ base: "none", md: "flex" }}
      >
        <Stack
          id="filterstickywbtn"
          marginRight={"0px!important"}
          onClick={onClick}
        >
          <Box
            display={"flex"}
            flexDirection={"column"}
            alignItems={"center"}
            gap="10px"
          >
            <Image transform={"rotate(360deg)"} src={Filter} />
            <Text
              as="button"
              transform={"rotate(90deg)"}
              fontSize={"14px"}
              fontWeight={"700"}
              marginTop={"4px"}
            >
              {t(explore.FILTER)}
            </Text>
          </Box>
        </Stack>
      </Stack>
    </>
  );
};
