import React, { useState } from "react";
import {
  Box,
  Stack,
  Text,
  FormErrorMessage,
  FormControl,
  Input,
  Button,
  InputRightElement,
  HStack,
  InputGroup,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  CloseButton,
  VStack,
  useBreakpointValue,
} from "@chakra-ui/react";
import { FiEye, FiEyeOff } from "react-icons/fi";
import { Link, useNavigate } from "react-router-dom";
import Layout from "../components/sections/SidebarLayout";
import { userRegistrationForm } from "../constants/constants";
import { useDispatch, useSelector } from "react-redux";
import { sendLogin } from "../redux/reducers/app/login";
import Large from "../Theme/Typography/Large";
import H1 from "../Theme/Typography/H1";
import H4 from "../Theme/Typography/H4";
import LoginSlider from "../CommonComponents/LoginSlider";
import { useFormik } from 'formik';
import * as Yup from 'yup';

function Login() {
  const [showPassword, setShowPassword] = useState(false);
  const [successMsgFlag, setSuccessMsgFlag] = useState(false);
  const dispatch = useDispatch();
  const session = useSelector(state => state.session);
  let navigate = useNavigate();
  const isMobile = useBreakpointValue({ base: true, md: false });
  const formik = useFormik({
    initialValues: {
      email : '',
      password: ''
    },
    validationSchema: Yup.object().shape({
      email: Yup.string()
            // .email('Please enter a valid email address')
            .required(userRegistrationForm.LOGIN_FORM_USERNAME_VALIDATION_MESSAGE),
      password: Yup.string()
                .required(userRegistrationForm.LOGIN_FORM_PASSWORD_VALIDATION_MESSAGE)
    }),

  
    onSubmit: values => {

      const payload = {
        username: values.email,
        password: values.password,
      };
      dispatch(sendLogin(payload));
    }
  });


  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  if (session && session.isAuthenticated)
  {
    return(
      navigate(`/profile`)
    );
  }

  return (
    <Layout>
      <Stack px={{ base: "10px", md: "62px" }} py={{ base: "15px", md: "32px" }}>
        <VStack alignItems={"flex-start"}>
          <Large fontSize={{base:"24px",md:"34px"}} fontWeight="400" lineHeight={"40px"} >{userRegistrationForm.LOGIN_FORM_WELCOME_TO}
            <Large as="span" pl="2" fontSize={{base:"24px",md:"34px"}} lineHeight={"40px"} fontWeight="600">{userRegistrationForm.LOGIN_FORM_TITLE}</Large>
          </Large>
          <Large className="mt-0" pt={{base:"0px",md:"15px"}} fontSize={{base:"24px",md:"34px"}} fontWeight="700" lineHeight={"40px"}>{userRegistrationForm.REGISTRATION_FORM_BUTTON_LOGIN_LABEL}</Large>
          <H1 fontSize={{base:"18px",md:"24px"}} py="2" fontWeight="400" lineHeight={"28px"}>{userRegistrationForm.DO_NoT_HAVE_ACC_YET}<Link to="/register"> <H1 fontSize={{base:"18px",md:"24px"}} as="span" textDecor={"underline"} color={"color.blue"} fontWeight="400">{userRegistrationForm.SUBSCRIBE_HERE}.</H1></Link></H1>
        </VStack>
        <HStack alignItems={"flex-start"} flexDirection={{base:"column",md:"row"}}>
          <Stack width={{ base: "100%", md: "auto" }}>
            {session.isAuthenticated ?
              <Text>Welcome, {session.user}</Text>
              :
              <Box width={{ base: "100%", md: "565px" }}>
                {successMsgFlag && /* message  && */ (
                  <Alert status="success">
                    <AlertIcon />
                    <Box>
                      <AlertTitle>Congratulation!</AlertTitle>
                      <AlertDescription>{/* message */}</AlertDescription>
                    </Box>
                    <CloseButton
                      alignSelf="flex-start"
                      position="relative"
                      right={-1}
                      top={-1}
                      onClick={() => setSuccessMsgFlag(false)}
                    />
                  </Alert>
                )}
                <Box width={"100%"}>
                <form  onSubmit={formik.handleSubmit}>
                  <FormControl isInvalid={formik.touched.email && !!formik.errors.email} pb="4">
                    <Input
                      id="email"
                      bg="white"
                      size={'lg'}
                      className="inputfield"
                      borderColor={"color.lightgray2"}
                      name="email"
                      placeholder="Username/Email"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.email}
                      _placeholder={{ color: "color.gray" }}
                    />
                    <FormErrorMessage>
                     {formik.errors.email}
                    </FormErrorMessage>
                  </FormControl>

                  <FormControl isInvalid={formik.touched.password && !!formik.errors.password} pb="4">
                    <InputGroup>
                      <Input
                      id="password"
                        bg="white"
                        placeholder="Password"
                        size={'lg'}
                        name="password"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.password}
                        borderColor={"color.lightgray2"}
                        _placeholder={{ color: "color.gray" }}
                        type={showPassword ? "text" : "password"}
                      />
                      <InputRightElement>
                        <Text onClick={toggleShowPassword}>
                          {showPassword ? (
                            <FiEye size={"18px"} />
                          ) : (
                            <FiEyeOff size={"18px"} />
                          )}
                        </Text>
                      </InputRightElement>
                    </InputGroup>
                    <FormErrorMessage>
                      {formik.errors.password}
                    </FormErrorMessage>
                  </FormControl>
                    <Button
                      variant="custom" size={"xl"} width={"100%"} mt={4} type="submit"
                      isLoading={session.loading}
                      loadingText={<H1>{userRegistrationForm.REGISTRATION_FORM_BUTTON_LOGIN_LABEL} ...</H1>}
                      spinnerPlacement='end'
                    >
                    <H1>{userRegistrationForm.REGISTRATION_FORM_BUTTON_LOGIN_LABEL}</H1>
                    </Button>
                    <Text>{session?.error?.message}</Text>
               </form>
                </Box>
                <Box py="4">
                  <H4 color={"color.blue"} fontWeight="400" > 
                  <Link to="/forgot-password">
                    {userRegistrationForm.LOGIN_FORM_FORGOT}
                  </Link>
                  </H4>
                </Box>
              </Box>}
          </Stack>
          {!isMobile && (
          <Stack>
            <LoginSlider />
          </Stack>
        )}
        </HStack>
      </Stack>
    </Layout>
  );
}

export default Login;