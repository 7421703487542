import { Link } from "react-router-dom";
import Large from "../components/layouts/Typography/Large";
import { HStack } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

export const ObjectsMenu = ({ links }) => {
  const { t } = useTranslation("");

    return (
      <>
        {/* <Link to="/research-categories/cinemae"> */}
        {links &&
          links.length !== 0 &&
          links.map((it,index) => {
            return (
              <HStack justifyContent="left" bg="white">

              <Large py="1" px="4" color={"black"} key={index}>
              <Link
                to={it.to}
                _hover={{
                  textDecoration: "none",
                }}
              >
                {t(it.text)}
              </Link>
            </Large>
            </HStack>

            );
          })}
          </>
    );
  };
       