import { visionData } from "../MockData/vision";

export const getVisionData = async () => {
  const dummyDataEnabled = process.env.REACT_APP_DUMMY_DATA || "";
  if (dummyDataEnabled.toLowerCase() === "yes") {
    return visionData;
  } else {
    return [];
  }
};
