import {
  Box,
  Divider,
  Flex,
  HStack,
  Image,
  InputGroup,
  InputRightElement,
  Modal,
  ModalBody,
  ModalContent,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Stack,
  Text,
  useColorModeValue,
  useDisclosure
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import SideMenu from "./layouts/SidebarMenu/Menu";
import { DesktopNav } from "./sections/Navbar";
import DataContext from "../context/DataContext";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { OExplorer } from "../CommonComponents/OExplorer";
import { MdLanguage } from "react-icons/md";
import { IoClose, IoSearchSharp } from "react-icons/io5";
import { changeLanguage } from "i18next";
import { common, hamburgerMenu } from "../constants/constants";
import Medium from "../Theme/Typography/Medium";
import { SearchTags } from "./sections/SearchTags";
import { useDispatch, useSelector } from "react-redux";
import { logoutSuccess } from "../redux/reducers/app/login";
import H4 from "../Theme/Typography/H4";
import { useQuery } from "../util/helper";


//S3 Bucket osw-dev-images
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;
const Logo = `${IMAGE_BASE_PATH}/ui/Logo/TRCFIS_logo.svg`
const TextLogo = `${IMAGE_BASE_PATH}/ui/Logo/TRCFISname.svg`
const member = `${IMAGE_BASE_PATH}/ui/Chevron/usericon.svg`
const joker = `${IMAGE_BASE_PATH}/ui/Chevron/joker.png`

const MobileNav = ({ onOpen, isAuth, props,defaultLanguage, ...rest }) => {
  const languages = [
    { code: 'en', label: common.LANGUAGE_ENGLISH },
    { code: 'fr', label: common.LANGUAGE_FRENCH },
    { code: 'hi', label: common.LANGUAGE_HINDI }
  ];

  const { categories } = React.useContext(DataContext);
  const [selectedLang, setSelectedLang] = useState(() => {
    const storageKey = `i18nextLng_${window.location.host}`;
    return localStorage.getItem(storageKey) || defaultLanguage;
  });  const [searchTerms, setSearchTerm] = useState("");
  const session = useSelector(state => state.session);
  const [showSuggestion, setShowSuggestion] = useState(false)
  const [selectedItem, setSelectedItem] = useState(null);
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const ref = useRef(null);

  const query = useQuery();
  const searchTerm = query.get('query');
  const [inputValue, setInputValue] = useState(searchTerm);


  const changeLanguageHandler = (lng) => {
    changeLanguage(lng);
    // save selected language in local storage
    const storageKey = `i18nextLng_${window.location.host}`;
    localStorage.setItem(storageKey, lng);
  };

  useEffect(() => { }, [selectedLang]);
  const handleSearch = (value) => {
    setSearchTerm(value);
  };

  const naviagateSearch = (suggestsearch) => {
    if (suggestsearch !== "" && searchTerms !== "") {
      setShowSuggestion(false)
      navigate(`/search/research-centre/all?query=${encodeURIComponent(suggestsearch)}`);
    }
  };

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setShowSuggestion(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [showSuggestion]);
  const handleLogout = () => {
    dispatch(logoutSuccess())
    localStorage.clear();
    // Redirect to login page or any other page
    navigate("/login");
  };
  useEffect(() => {
    if (location.pathname === '/login') {
      setSelectedItem('Log In');
    } else if (location.pathname === '/register') {
      setSelectedItem('Subscribe');
    } else if (location.pathname === '/profile') {
      setSelectedItem('My Profile');
    } else {
      setSelectedItem(null);
    }
  }, [location.pathname]);
  const handleReset=()=>{
    setInputValue('');
    setShowSuggestion(false);
    // navigate('/')
  }

  const { isOpen: isSearchOpen, onOpen: onSearchOpen, onClose: onSearchClose } = useDisclosure();
  const { isOpen: isUserOpen, onOpen: onUserOpen, onClose: onUserClose } = useDisclosure();
  const { isOpen: isLangOpen, onOpen: onLangOpen, onClose: onLangClose } = useDisclosure();
  return (
    <Stack
      position="sticky"
      backgroundColor="white"
      w="100%"
      top="0px!important"
      zIndex={"9999"}
      boxShadow="sm"
      height={"64px"}
    >
      <Flex
        ml={{ base: 0, md: 0 }}
        pl={{ base: 4, md: 4 }}
        pr={{ base: 4, md: 4 }}
        height="20"
        alignItems="center"
        bg={useColorModeValue("white", "gray.900")}
        borderBottomWidth="1px"
        borderBottomColor={useColorModeValue("gray.200", "gray.700")}
        justifyContent={{ base: "space-between" }}
        {...rest}
        zIndex={"99999"}
      >
        <Box
          display={{ base: "flex" }}
          position={"relative"}
          alignItems={"center"}
        >
          <Flex>
            <SideMenu />
          </Flex>
          <Stack flexDirection={"row"}>
            <HStack>
              <Link to="/">
                <Image
                  pl={{ base: 1, md: 12 }}
                  src={Logo}
                  width="auto"
                  height={"48px"}
                />
              </Link>
            </HStack>
            <HStack className="mt-0" alignItems={"flex-end"}>
              <Link to="/explore">
                <Box marginRight={{base:"5px",md:"0px"}} borderRadius={"10px"} border={"1px"} borderColor={"#e8e8e8"}>
                  <Image
                    px="2"
                    py="1"
                    src={TextLogo}
                    width="auto"
                    height={"40px"}
                  />
                </Box>
              </Link>
            </HStack>
          </Stack>
          <HStack display={{ base: "none", md: "flex" }} >
          <H4
            ml="80px"
            py="0px"
            mt="1"
            className="rc-title">
            <DesktopNav
              navLinks={[
                {
                  attribute: "category",
                  label: "Research Categories For India Studies",
                  href: "/research-categories",
                  children: categories.flatMap((cat) => {
                    return cat.flatMap((it) => {
                      const { key, value } = it;
                      if (key === "value") {
                        return {
                          label: `${value}`,
                          href: `/research-categories/${cat.find((item) => item.key === "alias").value
                            }`,
                          hoverTitle: cat.find((item) => item?.key === "name")
                            ?.value,
                          type: cat.find(
                            (item) =>
                              item?.key ===
                              "relevance_to_self_discovery_journey"
                          )?.value,
                          alias: cat.find((item) => item?.key === "alias")
                            ?.value,
                        };
                      } else {
                        return [];
                      }
                    });
                  }),
                },
              ]}
            />
          </H4>
          </HStack>
          {/* Rsearch Category Dropdown for mobile view */}
          <HStack  display={{ base: "flex", md: "none" }}>
          <H4
            mr="5px"
            className="rc-title">
            <DesktopNav
              navLinks={[
                {
                  attribute: "category",
                  label: "RC",
                  href: "/research-categories",
                  children: categories.flatMap((cat) => {
                    return cat.flatMap((it) => {
                      const { key, value } = it;
                      if (key === "value") {
                        return {
                          label: `${value}`,
                          href: `/research-categories/${cat.find((item) => item.key === "alias").value
                            }`,
                          hoverTitle: cat.find((item) => item?.key === "name")
                            ?.value,
                          type: cat.find(
                            (item) =>
                              item?.key ===
                              "relevance_to_self_discovery_journey"
                          )?.value,
                          alias: cat.find((item) => item?.key === "alias")
                            ?.value,
                        };
                      } else {
                        return [];
                      }
                    });
                  }),
                },
              ]}
            />
          </H4>
            </HStack>
        </Box>
        <Stack flexDirection="inherit" spacing={{ base: "0", md: "2" }}>
          <HStack cursor={"pointer"} className="Explorer" mr={{base:"0",md:"2"}}>
            <OExplorer isAuth={isAuth} />
          </HStack>
          <HStack
            display={{ base: "none", md: "flex" }}
            marginTop={"0px!important"}
            px="2"
          >
            <InputGroup size="sm">
              <SearchTags handleSearch={handleSearch} showSuggestion={showSuggestion} setShowSuggestion={setShowSuggestion} ref={ref} naviagateSearch={naviagateSearch} searchTerm={searchTerms} inputValue={inputValue} setInputValue={setInputValue}/>
              <InputRightElement width="2.5rem">
              { inputValue &&
                inputValue.length>0 ?<IoClose cursor={"pointer"} fontSize={"20px"} onClick={handleReset} />:
                <IoSearchSharp cursor={"pointer"} fontSize={"20px"} onClick={() => naviagateSearch(searchTerms)} />
              }
              </InputRightElement>
            </InputGroup>
          </HStack>
          {/* For mobile view */}
          <HStack  display={{ base: "flex", md: "none" }}
            marginTop={"0px!important"}
            px="1" >
          <Box
           px={{ base: "0", md: "4" }}
           display={"flex"}
           alignItems={"center"}
           justifyContent={"center"}
           onClick={onSearchOpen} // Open the modal on click
           cursor={"pointer"}
           >
          <IoSearchSharp fontSize={"20px"} />
         </Box>
         <Modal isOpen={isSearchOpen} onClose={onSearchClose} size="lg"  >
          {/* <ModalOverlay /> */}
           <ModalContent top={"64px"} mt="0" borderRadius={"0px"}>
            <ModalBody p="0">
             <Stack spacing={2}>
              <InputGroup size="md">
                <SearchTags 
                  handleSearch={handleSearch} 
                  showSuggestion={showSuggestion} 
                  setShowSuggestion={setShowSuggestion} 
                  ref={ref} 
                  naviagateSearch={naviagateSearch} 
                  searchTerm={searchTerms} 
                  inputValue={inputValue} 
                  setInputValue={setInputValue}
                />
                <InputRightElement pr="2">
                { inputValue &&
                inputValue.length>0 ?<IoClose cursor={"pointer"} fontSize={"20px"} onClick={handleReset} />:
                  <IoSearchSharp 
                    cursor={"pointer"} 
                    fontSize={"20px"} 
                    onClick={() => naviagateSearch()} 
                  />
                }
                </InputRightElement>
              </InputGroup>
            </Stack>
          </ModalBody>
        </ModalContent>
      </Modal>
      </HStack>

          {/* UserIcon */}
          <HStack px={{base:"0",md:"4"}} position={"relative"} marginTop={"0px!important"}>
            <Popover trigger={"hover"} isOpen={isUserOpen} onOpen={onUserOpen} onClose={onUserClose}>
              <PopoverTrigger>
                <Box  width={{base:"26px",md:"26px"}}   height={{base:"26px",md:"26px"}}>
                <Image
                  src={session.isAuthenticated ? joker : member}
                  width={"100%"}
                  objectFit={"contain"}
                height={"100%"}
                  cursor={"pointer"}
                  border={session.isAuthenticated && "1px"}
                  borderRadius={session.isAuthenticated && "15px"}
                  borderColor={"color.lightgray3"}
                />
                </Box>
              </PopoverTrigger>
              <PopoverContent
                width={"12rem"}
                height={"auto"}
                position={"absolute"}
                left={"auto"}
                right={{base:"-30px",md:"-15px"}}
                top={{base:"7px",md:"0px"}}
                borderRadius={"0px"}
              >
                <PopoverBody>
                  <Stack>
                    {!session.isAuthenticated ? (
                      <>
                        <Link to="/register">
                          <Medium
                            cursor="pointer"
                            fontWeight={selectedItem === 'Subscribe' ? "700" : "400"}
                          >
                            {common.SUBSCRIBE}
                          </Medium>
                        </Link>
                        <Divider />
                        <Link to="/login">
                          <Medium
                            cursor="pointer"
                            fontWeight={selectedItem === 'Log In' ? "700" : "400"}
                          >
                            {common.LOGIN}
                          </Medium>
                        </Link>
                      </>
                    ) : (
                      <>
                        <Link to="/profile">
                          <Medium
                            cursor="pointer"
                            fontWeight={selectedItem === 'My Profile' ? "700" : "400"}
                          >
                            {hamburgerMenu.MY_PROFILE}
                          </Medium>
                        </Link>
                        <Divider />
                        <Link to="/login" onClick={handleLogout}>
                          <Medium
                            cursor="pointer"
                            fontWeight={selectedItem === 'Log Out' ? "700" : "400"}
                          >
                            {common.LOGOUT}
                          </Medium>
                        </Link>
                      </>
                    )}
                  </Stack>
                </PopoverBody>
              </PopoverContent>
            </Popover>
          </HStack>

          {/* Language  */}
          <HStack position={"relative"} marginTop={"0px!important"} display={{ base: "none", md: "flex" }}>
            <Popover trigger={"hover"} isOpen={isLangOpen} onOpen={onLangOpen} onClose={onLangClose}>
              <PopoverTrigger>
                <MdLanguage cursor={"pointer"} fontSize={"24px"} pl="3" pr="1" />
              </PopoverTrigger>
              <PopoverContent
                width={"11.5rem"}
                height={"2.5rem"}
                position={"absolute"}
                left={"auto"}
                right={"-15px"}
                top={"45px"}
                borderRadius={"0px"}
              >
                <PopoverArrow />
                <PopoverBody>
                  <HStack>
                    {languages.map((lang, index) => (

                      <React.Fragment key={lang.code}>
                        <Text
                          pl={index !== 0 ? "1" : "0"}
                          pr="1"
                          fontSize={"12px"}
                          fontWeight={selectedLang === lang.code ? "800" : "400"}
                          onClick={() => {
                            setSelectedLang(lang.code);
                            changeLanguageHandler(lang.code);
                          }}
                          cursor="pointer"
                        >
                          {lang.label}

                        </Text>
                        {index < languages.length - 1 && <Text as="span">|</Text>}
                      </React.Fragment>
                    ))}
                  </HStack>
                </PopoverBody>
              </PopoverContent>
            </Popover>
          </HStack>
        </Stack>
      </Flex>
    </Stack>
  );
};
export default MobileNav;
