import {
  Box,
  HStack,
  Image,
  SimpleGrid,
  Stack,
} from "@chakra-ui/react";

//Core file
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

//Service file
import * as getInsightsByResearchCategoryAlias from "../../../services/Insight";
import { common } from "../../../constants/constants";
import H3 from "../../../Theme/Typography/H3";
import { getImageURL } from "../../../util/getImage";
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;
const placeholderImg = `${IMAGE_BASE_PATH}/ui/DummyImage/blank.png`

function Insights({setInsightViewAll}) {
  // const parts = url.split("/");
  const [insight, setInsights] = useState([]);
  const {alias}=useParams();
  const currentEndpoint = window.location.pathname;
  const parts = currentEndpoint.split("/");
  const masterlistType = parts[1];
  const navigate=useNavigate()
  useEffect(() => {
    const getData = async () => {
      // const result = await getPostsByResearchCategoryAlias("", parts[2]);
      const result = await getInsightsByResearchCategoryAlias.getInsightsByMasterlist(
        masterlistType,
        alias
      );
      if (result && result?.length > 3)
      {
        // Handle post and insights separately if needed
        setInsightViewAll(true);
      }

      if (result) {
        setInsights(citationFactor(result));
      }
    };
    getData();
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const citationFactor = (data) => {
    return data.map((item) => {

      const firstImage = item?.insight_images_alias[0];
      const title = item?.insight_heading;
  
      return {
        image: firstImage,
        title: title,
        alias: item?.insight_alias,
        masterlist_type: item?.masterlist_type
      };
    });
  };
  return (
    <Stack my="8">
      <Box>
        {insight.length > 0 ? (
            <HStack >
              <SimpleGrid columns={[1, null, 3]} spacing={"82px"}>
                {insight.slice(0, 3).map((it, index) => (
                    <Box w={{base:'100%',md:'348px'}} h={{base:"205px",md:"333px"}} className="card1" onClick={()=>navigate(`insights/${it?.alias}`)}>
                      {it!==`` && 
                      <Image
                        w={"100%"}
                        height={"100%"}
                        src={getImageURL(it?.image)}
                        objectFit={"contain"}
                        transition="1s ease"
                        fallbackSrc={placeholderImg}
                      />}
                    </Box>
                ))}
              </SimpleGrid>
            </HStack>
          ) : (
            <HStack>
              <H3>{common.NO_POST}</H3>
            </HStack>
          )}
      </Box>
    </Stack>
  );
}
export default Insights;
