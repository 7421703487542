import React from 'react'
import ReactEcharts from "echarts-for-react";

function BarChart({data}) {

      const option = {
        title: {
          text: "",
        },
        tooltip: {
          trigger: "axis",
        },
        legend: {
          data: ["Number of Paintings"],
          left: 0,
        },
        xAxis: {
          type: "category",
          name: "Year",
          data: data.map((item) => item.year.toString()),
          nameTextStyle: {
            fontSize: 16,
          },
          nameLocation:"middle",
          nameRotate: 0,
          nameGap:40,
          axisLabel: {
            fontSize: 12,
            rotate: 40,
          },
        },
        yAxis: {
          type: "value",
          name: "Number of Paintings",
          nameTextStyle: {
            fontSize: 16,
          },
          nameLocation:"middle",
          nameRotate: 90,
          nameGap:40,
          label: {
            show: true,
            fontSize: 12,
          },
        },
        series: [
          {
            data: data.map((item) => item.numOfPaintings),
            type: "bar",
            itemStyle: {
              color: "#FF69B4", // Pink color
            },
            name: "Number of Paintings",
          },
        ],
      };
  return (
    <>
    <ReactEcharts
          option={option}
          style={{ height: "470px", width: "560px" }}
        />
    </>
  )
}

export default BarChart