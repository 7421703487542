import {
  Box,
  Container,
  Image,
  SimpleGrid,
  Stack,
  Text,
} from "@chakra-ui/react";

import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Layout from "../components/sections/SidebarLayout";
import { getProjectsData } from "../services/Projects";
import { WebpageComingSoon } from "../CommonComponents/WebpageComingSoon";
import { getImageURL } from "../util/getImage";
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;
const placeholderImg = `${IMAGE_BASE_PATH}/ui/DummyImage/small.png`;

function ProjectPage() {
  const [projects, setProjects] = React.useState([]);
  const navigate = useNavigate();
  React.useEffect(() => {
    const getData = async () => {
      const result = await getProjectsData();
      if (result) {
        setProjects(result);
      }
    };
    getData();
    window.scrollTo(0, 0);
  }, []);

  return (
    <Layout>
      <Stack py="32px" px="62px" className="mobileviewpadding">
        <Container maxW="auto">
          {projects.length === 0 ? (
            <WebpageComingSoon/>
          ) : (
            <SimpleGrid columns={[1, null, 4]} spacing="20px">
              {projects?.map((item, index) => (
                <Box
                  onClick={() => {
                    if (
                      item.name ===
                      "Tuli Research Centre | 1st Festival for India Studies"
                    ) {
                      navigate("/songs/all");
                    } else {
                      navigate("/projects/" + item.id);
                    }
                  }}
                  key={index}
                  maxW={"auto"}
                  w={"full"}
                  bg={"gray.50"}
                  border={"1px"}
                  borderColor={"gray.100"}
                  rounded={"md"}
                  p={6}
                  overflow={"hidden"}
                  cursor={"pointer"}
                  transition="0.3s ease-in-out"
                  _hover={{
                    transform: "scale(1.05)",
                  }}
                >
                  <Link to={item?.link} style={{ textDecoration: "none" }}>
                    <Box
                      h={"auto"}
                      bg={"white"}
                      mt={-6}
                      mx={-6}
                      mb={6}
                      pos={"relative"}
                    >
                      <Image
                        src={getImageURL(`${IMAGE_BASE_PATH}/${item?.image}`, '178', '210')}
                        fallbackSrc={placeholderImg}
                        alt=""
                        w={"100%"}
                      />
                    </Box>
                  </Link>
                  <Stack>
                    <Stack direction={"column"} spacing={0} fontSize={"sm"}>
                      <Text pb="2" color={"gray.500"}>
                        {item?.display_date}
                      </Text>
                      <Text pb="2" fontWeight={600}>
                        {item?.name}
                      </Text>
                      <Text pb="2" color={"gray.500"}>
                        {item?.venue}
                      </Text>
                    </Stack>
                  </Stack>
                </Box>
              ))}
            </SimpleGrid>
          )}
        </Container>
      </Stack>
    </Layout>
  );
}
export default ProjectPage;
