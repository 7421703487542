import {
    Box,
    useColorModeValue,
    Drawer,
    DrawerContent,
    useDisclosure,
  } from "@chakra-ui/react";
  
  //Core files
  import React, { useState } from "react";
  import "react-vertical-timeline-component/style.min.css";
  import "swiper/css";
  import "swiper/css/pagination";
  import "swiper/css/navigation";
  import { useNavigate, useParams } from "react-router-dom";
  
  //Service files
  import * as getIntroductionData from "../../services/Introduction";
  import * as getLandingMasterlistData from "../../services/SectionService";
  
  //Component file
  import CommonLeftSidebar from "../../FunctionalComponents/LeftSidebar/CommonLeftSidebar";
  import { BottomMainMenu } from "../../CommonComponents/BottomMainMenu";
  
  //Language files
  import { explore } from "../../constants/constants";
  import { IntegratedTimelineBody } from "../../FunctionalComponents/IntegratedTimeline/IntegratedTimeline";

  
  export default function IntegratedTimeline({ children }) {
    const { isOpen, onClose } = useDisclosure();
    const [modalcard, setModalcard] = useState(false);
    const {alias}=useParams();
    const aliasAPI = alias;
    const currentEndpoint = window.location.pathname;
    const parts = currentEndpoint.split("/");
    const masterlistType = parts[1];
    const [introData, setIntroData] = useState([]);
    const [horislinkData, setHorislinkData] = useState([]);
    const [artistDetails, setArtistDetails] = useState(null);

    React.useEffect(() => {
      const getIntroData = async () => {
        const result = await getIntroductionData.introductionByAlias(
            masterlistType,
            aliasAPI
        );
        if (result) {
          setHorislinkData([
            {
              link: `/research-categories/${
                result[0].find((item) => item?.key === "researchCategories")
                  ?.value[0]?.alias
              }`,
              title: result[0].find((item) => item?.key === "researchCategories")
                ?.value[0]?.value,
            },
            {
              link: `/categories/indian-cinema`,
              title: result[0].find((item) => item?.key === "subject")?.value,
            },
          ]);
          setArtistDetails(
            result.reduce((acc, it) => {
              acc.name = it?.find((item) => item?.key === "full_name")?.value;
              acc.date_of_birth = it?.find(
                (item) => item?.key === "date_of_birth"
              )?.value;
              acc.date_of_death = it?.find(
                (item) => item?.key === "date_of_death"
              )?.value;
              return acc;
            }, {})
          );
        }
      };
      const getLandingData = async () => {
        const result = await getLandingMasterlistData.getLandingPageInfoByAlias(
          masterlistType,
          aliasAPI
        );
        if (result) {
          setIntroData(
            result.map((it) => ({
              text: it?.find((item) => item?.key === "landing_page_title")?.value,
              to: `/${masterlistType}/${it?.find((item) => item?.key === "masterlist_alias")?.value}/${
                it?.find((item) => item?.key === "landing_page_abbreviation")?.value
              }`,
              title:it?.find((item) => item?.key === "landing_page_abbreviation")?.value
            }))
          );
        }
      };
      getIntroData();
      getLandingData();
    }, [masterlistType, aliasAPI]);


    return (
      <Box minH="100%" bg={useColorModeValue("white", "gray.900")}>
        <SidebarContent
          onClose={() => onClose}
          display={{ base: "none", md: "block" }}
          zIndex={"99999"}
          height="calc(100vh - 64px)"
          modalcard={modalcard}
          aliasAPI={aliasAPI}
          horislinkData={horislinkData}
          artistDetails={artistDetails}
          introData={introData}
          alias={alias}
          masterlistType={masterlistType}
        />
        <Drawer
          autoFocus={false}
          isOpen={isOpen}
          placement="left"
          onClose={onClose}
          returnFocusOnClose={false}
          onOverlayClick={onClose}
          size="full"
        >
          <DrawerContent>
            <SidebarContent
              onClose={onClose}
              modalcard={modalcard}
              aliasAPI={aliasAPI}
              alias={alias}
              masterlistType={masterlistType}
            />
          </DrawerContent>
        </Drawer>
  
        <Box ml={{ base: 0, md: 52 }} bg="white">
          {children}
          <IntegratedTimelineBody
            modalcard={modalcard}
            setModalcard={setModalcard}
            aliasAPI={aliasAPI}
            masterlistType={masterlistType}
          />
        </Box>
      </Box>
    );
  }
  
  const SidebarContent = ({
    title,
    date,
    datedod,
    onClose,
    modalcard,
    aliasAPI,
    horislinkData,
    artistDetails,
    introData,
    alias,
    masterlistType,
    ...rest
  }) => {
  
    const data = {
      oSections: [
        {
          to: `/${masterlistType}/${aliasAPI}/posts`,
          title: "",
          text: explore.POSTS,
        },
        {
          to: `/${masterlistType}/${aliasAPI}/insights`,
          title: "",
          text: explore.INSIGHTS,
        },
      ],
      backNavigation: `/${masterlistType}/${aliasAPI}`,
    };

    const navigate = useNavigate();
    const handleNavigation = () => {
      navigate(-1);
    };
    return (
      <>
      <BottomMainMenu />
      <CommonLeftSidebar
      sections={introData}
      oSections={data.oSections}
      hMenu={horislinkData}
      artistDetails={artistDetails}
      leftFlag={modalcard}
      handleNavigation={handleNavigation}
      aliasAPI={alias}
      />
      </>
    );
  };  
  