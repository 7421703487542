import { isTransformationNotAllowed } from "../util/isTranformationAllowed";
import api from "../services/api";
import { APIS } from "../endpoints";

/**
 * This function return songs data
 * @returns {Promise<Object>} - Function return Songs data
 */

export const getSongsData = async () => {
    if (isTransformationNotAllowed() === true) {
        const response = await getSongsDummyData();
        return response;
    } else if (isTransformationNotAllowed() === false) {
        const response = await getSongsAPIData();
        return response;
    }
};

export const getSongsDummyData = async () => {
    return [];
};

export const getSongsAPIData = async () => {
    const result = await api.get(`${APIS.BASE_URL}${APIS.songs}`)
    if (result) {
        return result?.data?.data;
    } else {
        return [];
    }
};
