import {
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  HStack,
  Image,
  Stack,
  Text,
} from "@chakra-ui/react";
// Core Files
import React from "react";
import { AiOutlineRight } from "@react-icons/all-files/ai/AiOutlineRight";
import { Link } from "react-router-dom";

// Subsection Files
import H2 from "../../../../components/layouts/Typography/H2";
import Large from "../../../../components/layouts/Typography/Large";
import Small from "../../../../components/layouts/Typography/Small";
import { getImageURL } from "../../../../util/getImage";
import { formatName } from "../../../../util/helper";
import H3 from "../../../../Theme/Typography/H3";

//S3 Bucket osw-dev-images
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;

const closeIcon = `${IMAGE_BASE_PATH}/ui/Chevron/closeicon.svg`
const placeholder = `${IMAGE_BASE_PATH}/ui/Film/ShatranjKeKhilari/DummyImage/1.png`

function CommonDrawer({ setDrawerOpen, drawerOpen, drawerImage, data,masterlist }) {
  return (
    <>
      <Drawer
        isOpen={drawerOpen}
        placement="right"
        onClose={() => setDrawerOpen()}
        id="rightsidebarsmall"
        autoFocus={false}
        returnFocusOnClose={false}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader px={8}>
            <HStack mt={"12px"} justifyContent={"space-between"}>
              <Stack>
                <HStack>
                  {drawerImage && (
                    <>
                      <Image
                        src={drawerImage}
                        width={"275px"}
                        height={"auto"}
                      />
                      <H2> & </H2>
                    </>
                  )}
                </HStack>
                {masterlist ? <H2 >{formatName(masterlist)}
               <Text as={"span"} color={"red"}>{" & "}</Text>
               <H3 fontSize="18px" as={"span"}>{data?.name}</H3>
               </H2>:<H3 fontSize="18px" as={"span"}>{data?.name}</H3>}
              </Stack>
              <Image
                src={closeIcon}
                paddingLeft="20px"
                paddingBottom={"30px"}
                cursor={"pointer"}
                onClick={() => setDrawerOpen()}
              />
            </HStack>
          </DrawerHeader>
          <DrawerBody px={8}>
            {data?.links &&
              data.links.map((it) => (
                <Link to={it.link}>
                <Stack
                  pb="16px"
                  justifyContent="space-between"
                  flexDirection="row"
                >
                  <HStack>
                    <Large
                      cursor="pointer"
                      color="#035DA1"
                    >
                      {it.link_name}
                    </Large>
                  </HStack>
                  <HStack marginBottom={"0px"}>
                    <Large color="#035DA1">
                      <AiOutlineRight />
                    </Large>
                  </HStack>
                </Stack>
                </Link>
              ))}
            <Stack>
              
                <HStack py={4}>
                  <Image
                    cursor={"pointer"}
                    fallbackSrc={placeholder}
                    src={getImageURL(data.Image)}
                  />
                </HStack>
              
              <HStack px={2}>
                <Text fontSize="12px" fontWeight="400" lineHeight={"15px"}>
                  {data?.desc}
                </Text>
              </HStack>
              <HStack justifyContent={"flex-start"} pt={"2px"}>
                <Link to={data?.masterlist?.link} onFocus={() => {}}>
                  <Small color="#035DA1">{data?.masterlist?.text}</Small>
                </Link>
              </HStack>
            </Stack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
}

export default CommonDrawer;
