import { Container, HStack, Stack } from "@chakra-ui/react";
import React, { useState } from "react";
import { CopyPasteService } from "../../../../services/CopyPaste";
import { FullScreenImage } from "../../Component/FullScreenImage";
import Grid from "../../../../FunctionalComponents/SimpleGrid/Grid";
import ImageWithCitation2 from "../../../../FunctionalComponents/SimpleGrid/ImageWithCitation2";

export default function Exhibits({ data }) {
  const [fullScreenImage, setFullScreenImage] = useState(false);
  const [clickedImageIndex, setClickedImageIndex] = useState(null);
  const [ImgData, setImgData] = useState(null);

  const showImages = (image, flag) => {
    if (image) {
      setFullScreenImage(flag);
      setImgData(image);
    } else {
      setFullScreenImage(flag);
      setImgData(null);
    }
  };
  const handleImageClick = (item, index) => {
    setClickedImageIndex(index);
    setFullScreenImage(true);
  };
  return (
    <Stack flexDirection={"row-reverse"} minH={"100vh"}>
      <Container
        maxW="auto"
        className="songs"
        px={{ base: "4", md: "0px" }}
        py="8"
      >
        <Stack flexDirection={"row"} gap={4} justifyContent="center">
          <HStack
            onCopy={(e) => CopyPasteService(e, `${window.location.href}`)}
            marginRight={{ base: "0px", md: "30px" }}
          >
            <Grid
              data={data}
              columns={{ base: 1, md: 3, lg: 3, xl: 4 }}
              spacing={{ base: "5px", md: "24px" }}
              handleImageClick={handleImageClick}
              component={ImageWithCitation2}
            />
          </HStack>
        </Stack>
        {fullScreenImage && (
          <FullScreenImage
            isOpen={fullScreenImage}
            onClose={() => showImages(null, false)}
            imageData={ImgData}
            data={data.slice(0, 1)}
            clickedImageIndex={clickedImageIndex}
            setClickedImageIndex={setClickedImageIndex}
            bannerFlag={false}
            title=""
          />
        )}
      </Container>
    </Stack>
  );
}
