// Core Files
import { Box, Image, Stack, Text } from "@chakra-ui/react";

//S3 Bucket osw-dev-images
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;

const ReadMoreImg = `${IMAGE_BASE_PATH}/ui/ReadMore/readmore.png`

function ReadMore() {
  return (
    <Stack
      direction="column"
      alignItems="center"
      justifyContent="center"
      py={4}
    >
      <Text
        fontSize={{ base: "24px", md: "43px" }}
        fontWeight={"600"}
        color="#000000"
        align={"center"}
      >
        For you to <span style={{ color: "#035DA1" }}>Read More</span> you will
        need to be at
        <br />
        Stage.Phase
      </Text>
      <Box
        height={{ base: "100%", md: "550px" }}
        width={{ base: "100%", md: "700px" }}
      >
        <Image src={ReadMoreImg} width={"100%"} height={"inherit"} />
      </Box>
    </Stack>
  );
}
export default ReadMore;
