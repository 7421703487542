import { HStack, Stack, Text } from "@chakra-ui/react";
import React from "react";

export default function DrawerFooter({
  data,
  handleContentClicked,
  contentClicked,
  scanClicked,
  indexValue,
  setShowSubscribeModal
}) {
  return (
    <>
      <Stack justifyContent={"flex-start"}>
        {data.footer &&
          data.footer.map((it, index) => (
            <HStack cursor={"pointer"} key={index}>
              <Text
                fontSize="12px"
                className="autherlink"
                color="color.blue"
                lineHeight={"15px"}
                onClick={(e) => {
                  handleContentClicked(index, it.text);
                  // setShowSubscribeModal(true)
                }}
                fontWeight={
                  (contentClicked || scanClicked) && indexValue === index
                    ? "700"
                    : "400"
                }
              >
                {it.text}
              </Text>
            </HStack>
          ))}
      </Stack>
    </>
  );
}
