import {
  Container,
  HStack,
  Stack,
} from "@chakra-ui/react";

//Core files
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

//Component files
import Essay from "./ExhibitionCatlouge/Essay";
import All from "./ExhibitionCatlouge/All";
import { Exhibits } from "./ExhibitionCatlouge/Exhibits";

//Language files
import { explore } from "../../../constants/constants";
import TabMain from "../../../FunctionalComponents/TabComponents/TabMain";

function ExhibitionCatlouge({ winnerName, setReadMoreFlag, readmoreNavigation }) {
  const [visible, setVisible] = React.useState(true);
  const [listGridIcon, setListGridIcon] = useState(false);
  const { t } = useTranslation("");
  const [tabPanelData, setTabPanelData] = useState([]);

  function formatData() {
    return [
      {
        name: `${t(explore.ALL)}`,
        component:<All winnerName={winnerName}/>,
        list:false
      },
      {
        name: `${t(explore.ESSAYS)}`,
        component: <Essay winnerName={winnerName}
        setReadMoreFlag={setReadMoreFlag}
        readmoreNavigation={readmoreNavigation} 
        />,
        list:false
      },
      {
        name: `${t(explore.EXHIBITS)}`,
        component: <Exhibits visible={visible} winnerName={winnerName} />,
        list:true
      },

    ];
  }
  const tabHandle=(item)=>{
    setListGridIcon(item)
  }

  useEffect(() => {
    if (winnerName) {
      setTabPanelData(formatData());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [winnerName,visible]);
  return (
    <Container minW={"100%"} pb="4" paddingX={{ base: "4", md: "0px" }}>
      <Stack>
        <HStack>
          {tabPanelData && tabPanelData.length > 0 && (
            <TabMain pos={"fixed"} width="1070px" data={tabPanelData} listId="listgridbutton3" tabTop={{ base: "55px", sm: "75px", md: "56px" }} panelTop={{ base: "65px", sm: "85px", md: "55px" }} tabHandle={tabHandle} listGridIcon={listGridIcon} setVisible={setVisible} visible={visible} topTogleIcon={"140px"}/>
          )}
        </HStack>
      </Stack>
    </Container>
  );
}

export default ExhibitionCatlouge;
