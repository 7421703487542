import { ChakraProvider, Container, Text } from "@chakra-ui/react";
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { Provider } from "react-redux";
import  store  from "./redux/store/index";
import {  HelmetProvider } from "react-helmet-async";
import i18next from "i18next";
import { I18nextProvider, initReactI18next } from "react-i18next";
import translation_En from "./MultilingualJson/en_language.json";
import translation_Fr from "./MultilingualJson/fr_language.json";
import translation_Hi from "./MultilingualJson/hi_language.json";
import { PersistGate } from "redux-persist/integration/react";
import { persistor } from "../src/redux/store/index";
import ScrollToTop from "./components/sections/ScrollToTop";
import * as Sentry from "@sentry/react";
const dsn = process.env.REACT_APP_SENTRY_DSN;
const apiUrl = process.env.REACT_APP_MONGODB_URL;

if (dsn)
{
  Sentry.init({
    dsn: dsn,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.browserProfilingIntegration(),
      Sentry.replayIntegration(),
      
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", apiUrl],
    // Set profilesSampleRate to 1.0 to profile every transaction.
    // Since profilesSampleRate is relative to tracesSampleRate,
    // the final profiling rate can be computed as tracesSampleRate * profilesSampleRate
    // For example, a tracesSampleRate of 0.5 and profilesSampleRate of 0.5 would
    // results in 25% of transactions being profiled (0.5*0.5=0.25)
    profilesSampleRate: 1.0,
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

const resources = {
  en: {
    translation: translation_En,
  },
  fr: {
    translation: translation_Fr,
  },
  hi: {
    translation: translation_Hi,
  },
};



i18next
  .use(initReactI18next)
  .init({
    resources:resources,
    lng: localStorage.getItem("i18nextLng_dev.osianamaworld.org") || "en",
    fallbackLng: "en",
    debug: false,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    saveMissingTo: (lng, ns) => {
      const storageKey = `i18nextLng_${window.location.host}`;
      localStorage.setItem(storageKey, lng);
    }
  });

  const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <Provider store={store}>
    <PersistGate loading={<Text>Loading...</Text>} persistor={persistor}>
      <HelmetProvider>
      <ChakraProvider>
      <I18nextProvider i18n={i18next}>
        <Container maxW="1400px" px="0px">
          <App />
          <ScrollToTop className="top-to-btm"/>
        </Container>
        </I18nextProvider>
      </ChakraProvider>
    </HelmetProvider>
    </PersistGate>
    </Provider>
  </React.StrictMode>
);
