import { SimpleGrid, Text, Stack } from "@chakra-ui/react";
import React from "react";
import { common } from "../../constants/constants";

export default function Grid({
  data,
  component: Component,
  columns,
  handleDrawer,
  border,
  spacing,
  height,
  textStyle,
  fontSize,
  fontWeight,
  setShowCarousal,
  setcarousalData,
  showFullImages,
  handleImage,
  handleImageClick,
  handleGrid,
  showArchiveImages,
  text
}) {
  return (
    <SimpleGrid
      columns={columns}
      spacing={spacing}
    >
      {data.length > 0 ? (
        <Component
          data={data}
          handleDrawer={handleDrawer}
          border={border}
          height={height}
          textStyle={textStyle}
          fontSize={fontSize}
          fontWeight={fontWeight}
          setShowCarousal={setShowCarousal}
          setcarousalData={setcarousalData}
          showFullImages={showFullImages}
          handleImage={handleImage}
          handleImageClick={handleImageClick} 
          handleGrid={handleGrid}
          showArchiveImages={showArchiveImages}
          text={text}
          />
      ) : (
        <Stack alignItems={"center"} height={"100vh"}>
          <Text py={"40"} fontSize={"45px"} fontWeight="700" textAlign="center">
            {common.NO_DATA}
          </Text>
        </Stack>
      )}
    </SimpleGrid>
  );
}