import React from "react";
import TabularView from "../TabularView";

export default function ExhibitionsTabpanel({ aliasAPI, masterlistType,setExhibitionsCount }) {
  return (
    <>
      <TabularView masterlist={aliasAPI} masterlistType={masterlistType}setExhibitionsCount={setExhibitionsCount} />
    </>
  );
}
