import React from "react";
// Import Swiper React components

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import {
  Box,
  Container,
  Image,
  SimpleGrid,
  Stack,
  Text,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { common } from "../../constants/constants";
import { getProjectsData } from "../../services/Projects";
import { getImageURL } from "../../util/getImage";
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;
const placeholderImg = `${IMAGE_BASE_PATH}/ui/DummyImage/small.png`;

function ProjectsCarosuel() {
  const [projects, setProjects] = React.useState([]);
  const navigate = useNavigate();
  const { t } = useTranslation("");

  React.useEffect(() => {
    const getData = async () => {
      const result = await getProjectsData();
      if (result) {
        setProjects(result);
      }
    };
    getData();
  }, []);

  return (
    <Stack width={"100%"} className="mobileviewpaddinghome">
      {projects.length === 0 ? (
        <Text py={"20"} fontSize={"20px"} fontWeight="600" textAlign="center">
          {t(common.WEBPAGE_COMING_SOON)}
        </Text>
      ) : (
        <Container maxW="auto">
          <SimpleGrid columns={{ base: 1, md: 2, lg: 4, xl: 4 }} spacing="20px">
            {projects?.map((item, index) => (
              <Box
                onClick={() => {
                  if (
                    item.name ===
                    "OsianamaWorld | 1st Festival for India Studies"
                  ) {
                    navigate("/songs/all");
                  } else {
                    navigate("/projects/" + item.id);
                  }
                }}
                key={index}
                maxW={"auto"}
                w={"full"}
                bg={"white"}
                border={"1px"}
                borderColor={"gray.100"}
                rounded={"md"}
                p={6}
                overflow={"hidden"}
                cursor={"pointer"}
                transition="0.3s ease-in-out"
                _hover={{
                  transform: "scale(1.0)",
                }}
              >
                <Box
                  h={"auto"}
                  bg={"white"}
                  mt={-6}
                  mx={-6}
                  mb={6}
                  pos={"relative"}
                >
                  <Image
                    src={getImageURL(`${IMAGE_BASE_PATH}/${item?.image}`, '178', '210')}
                    fallbackSrc={placeholderImg}
                    alt=""
                    w={"100%"}
                  />
                </Box>
                <Stack>
                  <Stack direction={"column"} spacing={0} fontSize={"sm"}>
                    <Text
                      pb="2"
                      fontSize={"12px"}
                      fontWeight={"400"}
                      color={"black"}
                    >
                      {item?.display_date}
                    </Text>
                    <Text pb="2" fontSize={"14px"} fontWeight={"600"}>
                      {item?.name}
                    </Text>
                    <Text
                      pb="2"
                      fontSize={"12px"}
                      fontWeight={"400"}
                      color={"black"}
                    >
                      {item?.venue}
                    </Text>
                  </Stack>
                </Stack>
              </Box>
            ))}
          </SimpleGrid>
        </Container>
      )}
    </Stack>
  );
}
export default ProjectsCarosuel;
