import { HStack, Image, Stack } from "@chakra-ui/react";

//Core files
import { Link } from "react-router-dom";

//Component files
import { HorisLink } from "./HorisLink";
import { SubscribeCircle } from "../CommonComponents/SubscribeCircle";

//S3 Bucket osw-dev-images
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;

const Hline = `${IMAGE_BASE_PATH}/ui/Chevron/Hline2.png`

export const AgraphyLinks = ({ links, mx, paddingLeft, marginTop="-60px", marginInlineStart="0.2rem"}) => {
    return (
      <>
      {links.length>0 && <Stack marginTop={marginTop} paddingLeft={paddingLeft} justifyContent="space-between" gap={{base:"115px",md:""}} alignItems={{base:"center",md:""}} flexDirection={{base:"column", md:"row"}}  width={{base:"100%",md:"100%"}}>
       <HStack alignItems={"flex-end"} pr="4" mx={mx} justifyContent="left" zIndex={'1'}>
       <HorisLink px="0" links={links} />
         <Image marginInlineStart={marginInlineStart} src={Hline}></Image>
      </HStack> 
       <HStack px="0px">
       <Link my="5px" py="3">
      <SubscribeCircle />
      </Link>
       </HStack>
       </Stack>}
     </>
    );
  };