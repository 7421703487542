import { Text } from "@chakra-ui/react";
import React from "react";

const Medium = ({ children, lineHeight, fontWeight="400",textStyle="primary", ...props }) => {
  return (
    <Text
      as="p"
      {...props}
      fontSize="14px"
      fontWeight={fontWeight}
      lineHeight={lineHeight}
      textStyle={textStyle}
    >
      {children}
    </Text>
  );
};
export default React.memo(Medium);
