import { Text } from "@chakra-ui/react";
import React from "react";

const H5 = ({ children, lineHeight, fontWeight="700",fontSize="15px", ...props }) => {
  return (
    <Text
      as="h5"
      {...props}
      fontSize={fontSize}
      fontWeight={fontWeight}
      lineHeight={lineHeight}
      textStyle={"primary"}
    >
      {children}
    </Text>
  );
};
export default React.memo(H5);
