import { HStack, Image, Stack } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { WebpageComingSoon } from '../../../../../CommonComponents/WebpageComingSoon'
import * as getScreeningMasterlistData from "../../../../../services/Screenings";
import { useParams } from 'react-router-dom';
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;
const loaderImg = `${IMAGE_BASE_PATH}/ui/Chevron/loader.gif`;

export default function ScreeningDetailPage({masterlistType, masterlist}) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const {childTab}=useParams();
  async function fetchData() {
    try {
      setLoading(true)
      const result = await getScreeningMasterlistData.getScreeningByMasterList(masterlistType, masterlist,)
      setLoading(false)
      if (result && result?.data?.length > 0) {
        // const processedData = citationFactor(result?.data);
        setData(result?.data);     
      }

    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false)
      setData([])
    }
  }
  useEffect(()=>{
    fetchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[childTab])
  return (
    <Stack height={"100vh"}>
      {!loading ? <>
      {
        data && data?.length>0 ?
       <Stack>
        <HStack>
          {/* Image */}
        </HStack>
        <HStack>
          <Stack>
          {/* Synopsis */}
          </Stack>
          <Stack>
            {/* Technical Details */}
          </Stack>
        </HStack>
       </Stack>
        :
        <>
        <WebpageComingSoon/>
        </>

      }
      </>:(
      <HStack justifyContent={"center"} minH={"100vh"} alignItems={"flex-start"} mt={4}>
          <Image src={loaderImg} width={"100px"} height={"100px"} alt="Loading" />
      </HStack>
    )
      
      }
     
    </Stack>
  )
}
