import { Box, Divider, Flex, HStack, Stack, Text, VStack } from '@chakra-ui/react';
import React from 'react'
import H4 from '../../components/layouts/Typography/H4';
import { isArray } from 'lodash';
import { AiOutlinePicture } from '@react-icons/all-files/ai/AiOutlinePicture';
import dateFormat from "dateformat";

export default function AccordianPersonalia(props) {
  return (
    <VStack align="start" spacing={2}>
    <Flex align="center" py={3}>
      <Text fontSize={"16px"} fontWeight={"500"} color={"#000000"}>
        {props.personalia &&
          props.personalia.map((item, index) => {
            return (
              <Stack key={index} marginLeft={"28px"}>
               {item?.value?.length>0 && <HStack
                  py={"2"}
                  flexDirection={"row"}
                  marginTop={"0px !important"}
                >
                  <Stack>
                    <H4>{item?.display}</H4>
                    {isArray(item?.value) ? (
                      item?.value?.map((member, memberIndex) => {
                        const imageUrl =
                          member?.image_alias !== "undefined" &&
                          member?.image_alias !== null
                            ? member?.image_alias
                            : null;

                        return (
                          <React.Fragment key={memberIndex}>
                            <HStack
                              marginLeft={
                                imageUrl
                                  ? "-25px!important"
                                  : "0px!important"
                              }
                              alignItems={"flex-start"}
                            >
                              {imageUrl && (
                                <Box pt="1">
                                  <AiOutlinePicture
                                    onClick={() =>
                                      props.showFamilyMemberImages(
                                        imageUrl,
                                        true
                                      )
                                    }
                                    cursor={"pointer"}
                                    color="#1E90FF"
                                    size={18}
                                  />
                                </Box>
                              )}
                              <Text fontSize="16px" fontWeight="400">
                                {member?.full_name && (
                                  <>
                                    {member.full_name.charAt(0).toUpperCase() + member.full_name.slice(1)}
                                    <br />
                                  </>
                                )}
                                
                                {member?.date_of_birth && (
                                  <>
                                    {"("}
                                    {dateFormat(member.date_of_birth, "d mmm yyyy")}
                                    {member?.date_of_death ? " - " : ""}
                                  </>
                                )}
                                {member?.date_of_death && dateFormat(member.date_of_death, "d mmm yyyy")}
                                {member?.date_of_birth && ")"} 
                              </Text>

                            </HStack>
                          </React.Fragment>
                        );
                      })
                    ) : (
                      <>
                        <HStack
                          pb={"2"}
                          pt="0"
                          flexDirection={"row"}
                          marginTop={"0px !important"}
                        >
                          <Stack>
                            <Text
                              fontSize={"16px"}
                              fontWeight={"400"}
                            >
                              {
                                item.key === "date_of_birth" || item.key === "date_of_death"
                                  ? item.value === ""
                                    ? ""
                                    : dateFormat(item?.value, "d mmm yyyy")
                                  : item.key === "marriageregistrationdate"
                                  ? /^[0-9]{4}$/.test(item.value) // Check if the value is a 4-digit year
                                    ? item.value // Show the year directly
                                    : dateFormat(item?.value, "d mmm yyyy") // Format the full date
                                  : item?.value
                              }
                            </Text>
                          </Stack>
                        </HStack>
                      </>
                    )}
                  </Stack>
                </HStack>}
              </Stack>
            );
          })}
      </Text>
    </Flex>
    <Divider color={"#B5B5B5"} borderBottomWidth={"0.1px"} />
  </VStack>
  )
}
