import React from "react";
import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router-dom";

const UpdateHelmet = ({ title, description, type, image }) => {
  const defaultTitle = "Tuli Research Centre for India Studies";
  const tit = title ? `${defaultTitle} - ${title}` : defaultTitle;
  let location = useLocation();
  const siteUrl = "https://preview.tuliresearchcentre.org"+location.pathname;
  const domainName = "preview.tuliresearchcentre.org";
  // const favicon = `/icons/${image}-icon.png`;
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>{tit}</title>
      <meta name="description" content={description || ""} />

      {/* Facebook and Gmail */}
      <meta property="og:title" content={tit} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content="https://jgivedemo.techjoomla.com/media/com_jgive/campaigns/images/L_1522142356-422.jpg" />
      <meta property="og:url" content={siteUrl} />
      <meta property="og:type" content="website" />

      {/* twitter */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content={domainName} />
      <meta property="twitter:url" content={siteUrl} />
      <meta name="twitter:title" content={tit} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content="https://jgivedemo.techjoomla.com/media/com_jgive/campaigns/images/L_1522142356-422.jpg" />
    </Helmet>
  );
};

export default UpdateHelmet;
