import { HStack, Heading, Stack, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import BarChart from "./BarChart";

export default function VolumeandValueArtworkSold({ data }) {
  const [selectedData, setSelectedData] = useState(null);

  const key = "volume-value-of-artwork-sold";
  useEffect(() => {
    const handleSelectData = () => {
      const selected = data.find((item) => item.key === key);
      setSelectedData(selected);
    };
    handleSelectData();
  }, [data]);
  return (
    <>
    {data?.length > 0 && selectedData!==null && selectedData!==undefined && (
        <>
          <HStack flexDirection={"column"} alignItems={"self-start"}>
            <Heading as="h3" size="lg" mb={4} pb={4}>
              {selectedData?.title}
            </Heading>
            <Text fontSize={"16px"}>{selectedData?.subtitle}</Text>

            <Text color={"#035DA1"} fontWeight={500} fontSize={"16px"}>
              {"Volume of Artworks Sold"}
            </Text>
            <Text fontSize={"16px"}>{selectedData?.heading}</Text>
          </HStack>
          <HStack alignItems={"flex-start"}>
            <Stack>
              <BarChart data={selectedData?.data} />
            </Stack>
            <Stack>
              <Text>{selectedData?.desc}</Text>
            </Stack>
          </HStack>
        </>
      )}
    </>
  );
}
