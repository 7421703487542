import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Container,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  HStack,
  IconButton,
  Image,
  Stack,
  Text,
} from "@chakra-ui/react";
import React from "react";
import Large from "../Typography/Large"
import H4 from "../Typography/H4";
import { Link } from "react-router-dom";
import { common, hamburgerMenu } from "../../../constants/constants";
import { SocialMediaButton } from "../../../CommonComponents/SocialMediaButton";
import MediumRegular from "../../../Theme/Typography/MediumRegular";

//S3 Bucket osw-dev-images
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;

const closeIcon = `${IMAGE_BASE_PATH}/ui/Chevron/closeIcon.png`
const menuIcon = `${IMAGE_BASE_PATH}/ui/Chevron/menu.png`
const paw = `${IMAGE_BASE_PATH}/ui/Chevron/paw.png`
const book = `${IMAGE_BASE_PATH}/ui/Chevron/book.png`
const library = `${IMAGE_BASE_PATH}/ui/Chevron/library.png`
const circle = `${IMAGE_BASE_PATH}/ui/Chevron/circle.png`

function SideMenu(props) {
  const [open, setOpen] = React.useState(false);
  const btnRef = React.useRef();

  return (
    <Container maxW={{base:"100%",md:"1400px"}} top={"0%"} position={"relative"} px="0px">
      <Stack width={{base:"25px",md:"100%"}}>
        <IconButton
          className="humbargermenu"
          variant={"unstyled"}
          ml="-5px"
          ref={btnRef}
          onClick={() => setOpen(!open)}
        >
          {!open ? <Image src={menuIcon} /> : <Image src={closeIcon} />}
        </IconButton>

        <Drawer
        position={"relative"}
          isOpen={open}
          placement="left"
          onClose={() => setOpen(false)}
          finalFocusRef={btnRef}
          id="humbergermenu"
          size={{base:"full",md:"sm"}}
        >
          <DrawerOverlay display={{base:"none",md:"contents"}} />
          <DrawerContent 
           position={{ base: "relative", md: "absolute" }}
           left={{ base: 0, md: "-100%" }} 
           maxW={{ base: "full", md: "318px" }}
          >
            {/* <DrawerCloseButton /> */}
            <DrawerHeader
              borderBottom={"1px"}
              borderBottomColor="#f6f6f6"
              py="2"
            >
              <Stack py="2">
                <Stack flexDirection={"row"} alignItems="center">
                  <HStack borderRadius={"5px"} alignItems={"flex-start"}>
                    <Image
                      src={circle}
                      bg="#F6F6F6"
                      padding={"6px"}
                      width="32px"
                      height={"32px"}
                      borderRadius={"5px"}
                    ></Image>
                  </HStack>
                  <HStack
                    flexDirection={"column"}
                    alignItems="baseline"
                    marginTop={"0px!important"}
                    pl="13px"
                  >
                     <MediumRegular>
                        {hamburgerMenu.TRC}
                      </MediumRegular>
                  </HStack>
                </Stack>
              </Stack>
            </DrawerHeader>
            <DrawerBody>
              <HStack py="3">
                <Link to="/whats-new">
                  <H4 color="#860F0F" mr="5">
                    {hamburgerMenu.WHATS_NEW}
                  </H4>
                </Link>
              </HStack>
              <Accordion allowMultiple>
                <AccordionItem borderTopWidth={"0px"}>
                  <AccordionButton p="0">
                    <Box as="span" flex="1" textAlign="left">
                      <Stack py="1" gap={0}>
                        <Stack flexDirection={"row"} gap={0}>
                          <HStack borderRadius={"5px"}>
                            <Image
                              src={book}
                              bg="#F6F6F6"
                              padding={"6px"}
                              width={{base:'32px',md:'42px'}}
                              height={"32px"}
                              borderRadius={"5px"}
                            ></Image>
                          </HStack>
                          <HStack alignItems="center" gap={0} px={"4"}>
                            <Stack>
                              <Text
                                fontSize={"14px"}
                                fontWeight={"500"}
                                lineHeight={"16px"}
                                color="#080808"
                                className="topmargin"
                              >
                                {hamburgerMenu.TRCI}
                              </Text>
                            </Stack>
                          </HStack>
                        </Stack>
                      </Stack>
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>

                  <AccordionPanel py={"3"}>
                    <Large color="#035DA1" pl="8">
                      {common.CONTENT_AWAITED}
                    </Large>
                  </AccordionPanel>
                </AccordionItem>
                <AccordionItem borderTopWidth={"0px"}>
                  <AccordionButton p="0">
                    <Box as="span" flex="1" textAlign="left">
                      <Stack py="1" gap={0}>
                        <Stack flexDirection={"row"} gap={0}>
                          <HStack borderRadius={"5px"} gap={0}>
                            <Image
                              src={library}
                              bg="#F6F6F6"
                              padding={"6px"}
                              width="32px"
                              height={"32px"}
                              borderRadius={"5px"}
                            ></Image>
                          </HStack>
                          <HStack alignItems="center" gap={0} px={"4"}>
                            <Stack>
                              <Text
                                fontSize={"14px"}
                                fontWeight={"500"}
                                lineHeight={"16px"}
                                color="#080808"
                              >
                                {hamburgerMenu.ARCHIVE_LIB}
                              </Text>
                            </Stack>
                          </HStack>
                        </Stack>
                      </Stack>
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>

                  <AccordionPanel py={"3"}>
                    <Large color="#035DA1" pl="8">
                      {common.CONTENT_AWAITED}
                    </Large>
                  </AccordionPanel>
                </AccordionItem>
                <AccordionItem pb="4" borderTopWidth={"0px"}>
                  <AccordionButton p="0">
                    <Box as="span" flex="1" textAlign="left">
                      <Stack py="1" gap={0}>
                        <Stack flexDirection={"row"} gap={0}>
                          <HStack borderRadius={"5px"}>
                            <Image
                              src={paw}
                              bg="#F6F6F6"
                              padding={"6px"}
                              width="32px"
                              height={"32px"}
                              borderRadius={"5px"}
                            ></Image>
                          </HStack>
                          <HStack alignItems="center" pl={"4"}>
                            <Stack>
                              <Text
                                fontSize={"14px"}
                                fontWeight={"500"}
                                lineHeight={"16px"}
                                color="#080808"
                                className="topmargin"
                              >
                                {hamburgerMenu.VANRAJA_CLINIC}
                              </Text>
                            </Stack>
                          </HStack>
                        </Stack>
                      </Stack>
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>

                  <AccordionPanel py={"3"}>
                    <Large color="#035DA1" pl="8">
                      {common.CONTENT_AWAITED}
                    </Large>
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>
              <Stack
                py="4"
                flexDirection={"row"}
                alignItems="baseline"
                gap="2"
                justifyContent={"space-between"}
              >
                <HStack alignItems="flex-start">
                  <Link to="/vision">
                  <Text
                    fontSize={"14px"}
                    fontWeight={"500"}
                    lineHeight={"16px"}
                    color="black"
                  >
                    {hamburgerMenu.VISION}
                  </Text>
                  </Link>
                </HStack>

                <HStack alignItems="flex-start" marginTop="0rem!important">
                <Link to="/about-us">
                  <Text
                    fontSize={"14px"}
                    fontWeight={"500"}
                    lineHeight={"16px"}
                    color="black"
                  >
                    {hamburgerMenu.ABOUT_US}
                  </Text>
                  </Link>
                </HStack>
                <HStack alignItems="flex-start" marginTop="0rem!important">
                <Link to="/careers">
                  <Text
                    fontSize={"14px"}
                    fontWeight={"500"}
                    lineHeight={"16px"}
                    color="black"
                  >
                    {hamburgerMenu.CAREERS}
                  </Text>
                  </Link>
                </HStack>
                <HStack alignItems="flex-start">
                <Link to="/contact-us">
                  <Text
                    fontSize={"14px"}
                    fontWeight={"500"}
                    lineHeight={"16px"}
                    color="black"
                  >
                    {hamburgerMenu.CONTACT_US}
                  </Text>
                  </Link>
                </HStack>
              </Stack>
            </DrawerBody>

            <DrawerFooter pb="8" alignItems={"flex-start"}  flexDirection={"column"} justifyContent={"flex-start"}>
              <Stack>
                <HStack>
                  <Large color="black">{hamburgerMenu.FOLLOW_US}</Large>
                </HStack>
                <SocialMediaButton />
              </Stack>
              <Stack
                py="4"
                flexDirection={"row"}
                alignItems="baseline"
                gap="2"
                justifyContent={"space-between"}
              >
                <HStack alignItems="flex-start">
                  <Link to="/terms-and-conditions">
                  <Text
                    fontSize={"14px"}
                    fontWeight={"500"}
                    lineHeight={"16px"}
                    color="black"
                  >
                    {hamburgerMenu.TERMS_AND_CONDITIONS}
                  </Text>
                  </Link>
                </HStack>

                <HStack alignItems="flex-start" marginTop="0rem!important">
                <Link to="/privacy-policy">
                  <Text
                    fontSize={"14px"}
                    fontWeight={"500"}
                    lineHeight={"16px"}
                    color="black"
                  >
                    {hamburgerMenu.PRIVACY_POLICY}
                  </Text>
                  </Link>
                </HStack>
              </Stack>
            </DrawerFooter>
          </DrawerContent>
        </Drawer>
      </Stack>
    </Container>
  );
}
export default SideMenu;