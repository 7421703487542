import React, { useState } from "react";
import {
  Stack,
} from "@chakra-ui/react";
import GridView from "./GridView";
import TabularView from "./TabularView";
import * as getBibliographyData from "../../../../services/Bibliography";

export const Bibliography = ({
  visible,
  handleBookClick,
  setleftFlag,
  openDrawer,
  setOpenDrawer,
  tableName,
  masterlist
}) => {
  // const [visible, setVisible] = React.useState(false);
  const [bibliography, setBibliography] = React.useState([]);
  const [contentClicked, setContentClicked] = useState(false);
  const [scanClicked, setScanClicked] = useState(false);


  React.useEffect(() => {
    const getData = async () => {
      const result = await getBibliographyData.getSKKBibliographyDummyData();
      if (result) {
        const dataBibliography = result;
        // ?.map((item) => {
        //   return JSON.parse(item);
        // });
        setBibliography(dataBibliography);
      }
    };
    getData();
  }, []);

  return (
    <Stack position={"relative"}>
      {visible ? (
        <>
          <GridView
            openDrawer={openDrawer}
            setOpenDrawer={setOpenDrawer}
            data={bibliography}
            handleBookClick={handleBookClick}
            contentClicked={contentClicked}
            scanClicked={scanClicked}
            setContentClicked={setContentClicked}
            setScanClicked={setScanClicked}
            setleftFlag={setleftFlag}
            tableName={tableName}
            masterlist={masterlist}
          />
        </>
      ) : (
        <>
          <TabularView
            openDrawer={openDrawer}
            setOpenDrawer={setOpenDrawer}
            data={bibliography}
            handleBookClick={handleBookClick}
            contentClicked={contentClicked}
            scanClicked={scanClicked}
            setContentClicked={setContentClicked}
            setScanClicked={setScanClicked}
            setleftFlag={setleftFlag}
            tableName={tableName}
            masterlist={masterlist}
          />
          {/* {
          contentClicked ? <DemoModal contentClicked={contentClicked} setContentClicked={setContentClicked}/>:
          scanClicked && <SliderModal scanClicked={scanClicked} setScanClicked={setScanClicked}/>
        } */}
        </>
      )}
    </Stack>
  );
};
