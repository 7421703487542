import { amitabh_bachchan_ambassdorship_data } from "../MockData/BrandAmbassdorShip";
import { isTransformationNotAllowed } from "../util/isTranformationAllowed";
import { APIS } from "../endpoints";
import api from "../services/api";

/**
        This function return BrandAmbassdorShip tab data of Body of work section for respective masterlist.
        @param {string} tableName - Table name.
        @param {string} alias - Masterlist alias.
        @returns {Promise} - Function return BrandAmbassdorShip tab data of Body of work section.
  */
export const BrandAmbassdorShipData = async (masterlistType, masterlist, page ) => {
  if (isTransformationNotAllowed() === true) {
    const response = await BrandAmbassdorShipDataDummy(masterlistType, masterlist );
    return response;
  } else if (isTransformationNotAllowed() === false) {

    const response = await BrandAmbassdorShipDataAPI(masterlistType, masterlist, page);
    try {
      if (response) {
        return response;
      } else {
        console.error("Invalid response data structure:", response);
        return [];
      }
    } catch (e) {
      console.error("Something is wrong", e);
    }

  }
};

export const BrandAmbassdorShipDataDummy = async (masterlistType, masterlist ) => {
  const BrandAmbassdorShipDataMap = {
    "amitabh-bachchan": amitabh_bachchan_ambassdorship_data,
    // Add more cases as needed
  };
  // Check if the masterlist exists in the map, if not, use a default value
  const BrandAmbassdorShipData =
    BrandAmbassdorShipDataMap[masterlist] || [];
  return BrandAmbassdorShipData;
};

export const BrandAmbassdorShipDataAPI = async (masterlistType, masterlist, page) => {
  const formData = new FormData();
  formData.append('page_number', page);
  const params = new URLSearchParams(formData);

  const result = await api.get(`${APIS.BASE_URL}${masterlistType}/${masterlist}/${APIS.brandAmbassdorShip}`, 
    {
      params: params
    })
  if (result) {
    return result?.data;
  } else {
    return [];
  }
}
