import {
  HStack,
  Image,
  Stack,
} from "@chakra-ui/react";
// Core Files
import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";

// Service Files
import { CopyPasteService } from "../../../services/CopyPaste";
import * as getFilmSynopsisData from "../../../services/Film";

// Subsection Files
import { EffectFlip, Navigation } from "swiper";
import { WebpageComingSoon } from "../../../CommonComponents/WebpageComingSoon";
import H3 from "../../../Theme/Typography/H3";
import H4 from "../../../Theme/Typography/H4";
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;

const placeholderImg = `${IMAGE_BASE_PATH}/ui/DummyImage/small.png`

function Architecture({ masterlist, tableName}) {
  const [showLabel, setShowLabel] = useState(true);
  const [description, setDescription] = useState("");
  const [synopsisData, setSynopsisData] = useState([{}]);

  const citationFactor = (data) => {
    return data.map((item) => {
      return {
        desc:
          `${item?.bibliographic_caption}` ,
        imagePath: item?.direct_url_of_cover_image,
        wnn: item?.wnn,
        newDesc:
          `${item?.bibliographic_citation}`,
      };
    });
  };
  useEffect(() => {
    const getData = async () => {
      const result = await getFilmSynopsisData.filmSynopsisData(
        tableName,
        masterlist
      );
      if (result?.length > 0) {
        setShowLabel(false);
        setDescription(
          result?.find((nestedItem) => nestedItem.key === "synopsis")?.value
        );
        result?.map(
          (item) =>
            item.key === "synopsis_image_alias" &&
            setSynopsisData(citationFactor(item?.value))
        );
      }
    };
    getData();
  }, [tableName, masterlist]);
  return showLabel ? (
    <Stack height={"100vh"}>
     <WebpageComingSoon />
    </Stack>
  ) : (
    <>
      <Stack
        flexDirection={{ base: "column", md: "row" }}
        alignItems={"flex-start"}
        gap={{ base: 1, md: 12 }}
        minHeight={{lg:"100%", '2xl':"calc(100vh - 180px)"}}
        py="20px"
      >
        <HStack
          marginTop="0.0rem!important"
          flexDirection={"column"}
          width={{ base: "100%", md: "664px" }}
          px="0px"
          pb="4"
          alignItems={"flex-start"}
        >
          <H3 textAlign="justify" color="color.gray" lineHeight={{ base: "28px", md: "32px" }} fontWeight={"600"} onCopy={(e) => CopyPasteService(e, `${window.location.href}/1`)}>
           {description}
          </H3>
        </HStack>
        <HStack paddingRight={"5px"}>
          <Stack
            width={{ base: "100%", md: "395px" }}
            id={"allsliderskk2"}
            position={"relative"}
          >
            <HStack pb="4">
              {synopsisData?.length > 0 && (
                <Swiper
                  grabCursor={true}
                  navigation={true}
                  modules={[EffectFlip, Navigation]}                  
                  // loop={true}
                >
                  {synopsisData?.map((item, index) => (
                    <>
                      <SwiperSlide className="myslide">
                        <Stack>
                          <Image
                            height={"100%"}
                            fallbackSrc={placeholderImg}
                            src={item?.imagePath}
                            cursor={"pointer"}
                          />
                          <H4  pt={"8px"} textAlign={"left"} lineHeight={"18.78px"} color="color.blue">{item?.desc}</H4>
                        </Stack>
                      </SwiperSlide>
                    </>
                  ))}
                </Swiper>
              )}
            </HStack>
          </Stack>
        </HStack>
      </Stack>
    </>
  );
}
export default Architecture;
