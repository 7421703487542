
import {
  Box,
  Container,
  Image,
  SimpleGrid,
  Stack,
  Text,
} from "@chakra-ui/react";

// Core Files
import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { useParams } from "react-router-dom";

// Service Files
import * as getInsightDetailsData from "../../../services/Insight";

function QuizSlider() {
  const {alias}=useParams()
  const masterlistTitle = alias;
  const [quizInsightData, setInsightQuizData] = useState([]);

  useEffect(() => {
    const getData = async () => {
      const result = await getInsightDetailsData.getInsightQuizData(
        "masterlist_table",
        masterlistTitle
      );
      if (result) {
        setInsightQuizData(result);
      }
    };
    getData();
  }, [masterlistTitle]);
  return (
    <>
      <Container px="0px" maxW="auto" className="songs" id="quizslider">
        <Stack px="0" width={"100%"} position={"relative"} mb="20">
          <Swiper
            slidesPerView={1}
            navigation={true}
            height={"100%"}
            id="subslidershraza"
            modules={[Navigation, Pagination]}
          >
            {quizInsightData.map((item, index) => (
              <SwiperSlide key={index}>
                <Box>
                  {item.question && (
                    <Text fontSize={"16px"} fontWeight={"600"} pb="4">
                      {item.question}
                    </Text>
                  )}
                  {item.type === "button" && (
                    <Stack>
                      {item.options.map((option, idx) => (
                        <Box key={idx} py="2">
                          <Text
                            as={"button"}
                            fontWeight={"500"}
                            variant={"unstyled"}
                            px="8"
                            py="4"
                            textAlign={"left"}
                            borderRadius={"5px"}
                            fontSize={"12px"}
                            bg="#FFBF17"
                            width={"100%"}
                          >
                            {option}
                          </Text>
                        </Box>
                      ))}
                    </Stack>
                  )}
                  {item.type === "image" && (
                    <SimpleGrid
                      justifyItems={"center"}
                      columns={[2, null, 2]}
                      spacing="20px"
                    >
                      {item.options.map((item, idx) => (
                        <Box className="quiz-img">
                          <Image
                            src={item.image}
                            width={"auto"}
                            height={"100%"}
                            alt={""}
                          />
                        </Box>
                      ))}
                    </SimpleGrid>
                  )}
                  {item.message && (
                    <Box py="40" className="Bgimage">
                      <Text
                        fontSize={"16px"}
                        fontWeight={"600"}
                        color={"black"}
                        alignItems={"center"}
                        pb="4"
                      >
                        {item.message}
                      </Text>
                    </Box>
                  )}
                </Box>
              </SwiperSlide>
            ))}
          </Swiper>
        </Stack>
      </Container>
    </>
  );
}
export default QuizSlider;
