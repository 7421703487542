import { Text } from "@chakra-ui/react";
import React from "react";

const H3 = ({ children, fontWeight="600", textStyle="primary", lineHeight,fontSize="16px",  ...props }) => {
  return (
    <Text
      as="h3"
      {...props}
      fontSize={fontSize}
      fontWeight={fontWeight}
      lineHeight={lineHeight}
      textStyle={textStyle}
    >
      {children}
    </Text>
  );
};
export default React.memo(H3);
