import { filterAttribute } from "../constants/constants";

//S3 Bucket osw-dev-images
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;

const SKK = `${IMAGE_BASE_PATH}/ui/Film/ShatranjKeKhilari/TitleImage/small.png`
const Img1 = `${IMAGE_BASE_PATH}/ui/Film/ShatranjKeKhilari/ResearchCentre/BookDetails/1.png`
const Img2 = `${IMAGE_BASE_PATH}/ui/Film/ShatranjKeKhilari/ResearchCentre/BookDetails/2.png`
const Img3 = `${IMAGE_BASE_PATH}/ui/Film/ShatranjKeKhilari/ResearchCentre/BookDetails/3.png`

const Imgsatyajit1 = `${IMAGE_BASE_PATH}/ui/PersonalityPolymath/SatyajitRay/ResearchCentre/Library/GridView/sl1.jpg`
const Imgsatyajit2 = `${IMAGE_BASE_PATH}/ui/PersonalityPolymath/SatyajitRay/ResearchCentre/Library/GridView/sl2.jpg`
const Imgsatyajit3 = `${IMAGE_BASE_PATH}/ui/PersonalityPolymath/SatyajitRay/ResearchCentre/Library/GridView/sl3.jpg`

const PhotoIndiaRC1 = `${IMAGE_BASE_PATH}/ui/ResearchCategories/PhotographyInIndia/ResearchCentre/1.png`
const PhotoIndiaRC2 = `${IMAGE_BASE_PATH}/ui/ResearchCategories/PhotographyInIndia/ResearchCentre/2.png`
const PhotoIndiaRC3 = `${IMAGE_BASE_PATH}/ui/ResearchCategories/PhotographyInIndia/ResearchCentre/3.png`
const PhotoIndiaRC4 = `${IMAGE_BASE_PATH}/ui/ResearchCategories/PhotographyInIndia/ResearchCentre/4.png`
const PhotoIndiaRC5 = `${IMAGE_BASE_PATH}/ui/ResearchCategories/PhotographyInIndia/ResearchCentre/5.png`
const PhotoIndiaRC6 = `${IMAGE_BASE_PATH}/ui/ResearchCategories/PhotographyInIndia/ResearchCentre/6.png`
const PhotoIndiaRC7 = `${IMAGE_BASE_PATH}/ui/ResearchCategories/PhotographyInIndia/ResearchCentre/7.png`
const PhotoIndiaRC8 = `${IMAGE_BASE_PATH}/ui/ResearchCategories/PhotographyInIndia/ResearchCentre/8.png`
const PhotoIndiaRC9 = `${IMAGE_BASE_PATH}/ui/ResearchCategories/PhotographyInIndia/ResearchCentre/9.png`
const PhotoIndiaRC10 = `${IMAGE_BASE_PATH}/ui/ResearchCategories/PhotographyInIndia/ResearchCentre/10.png`

const PhotoIndiaRCDrawImg1 = `${IMAGE_BASE_PATH}/ui/ResearchCategories/PhotographyInIndia/ResearchCentre/drawer1.jpg`
const PhotoIndiaRCDrawImg2 = `${IMAGE_BASE_PATH}/ui/ResearchCategories/PhotographyInIndia/ResearchCentre/drawer2.jpg`
const PhotoIndiaRCDrawImg3 = `${IMAGE_BASE_PATH}/ui/ResearchCategories/PhotographyInIndia/ResearchCentre/drawer3.jpg`
const placeholder = `${IMAGE_BASE_PATH}/ui/EventExhibition/PictorialSpace/ResearchCentre/placeholder.png`

const LucknowRC1 = `${IMAGE_BASE_PATH}/ui/Location/Lucknow/ResearchCentre/Library/1.png`
const LucknowRC2 = `${IMAGE_BASE_PATH}/ui/Location/Lucknow/ResearchCentre/Library/2.png`
const LucknowRC3 = `${IMAGE_BASE_PATH}/ui/Location/Lucknow/ResearchCentre/Library/3.png`
const LucknowRC4 = `${IMAGE_BASE_PATH}/ui/Location/Lucknow/ResearchCentre/Library/4.png`
const LucknowRC5 = `${IMAGE_BASE_PATH}/ui/Location/Lucknow/ResearchCentre/Library/5.png`
const LucknowRC6 = `${IMAGE_BASE_PATH}/ui/Location/Lucknow/ResearchCentre/Library/6.png`
const LucknowRC7 = `${IMAGE_BASE_PATH}/ui/Location/Lucknow/ResearchCentre/Library/7.png`
const LucknowRC8 = `${IMAGE_BASE_PATH}/ui/Location/Lucknow/ResearchCentre/Library/8.png`
const LucknowRC9 = `${IMAGE_BASE_PATH}/ui/Location/Lucknow/ResearchCentre/Library/9.png`
const LucknowRC10 = `${IMAGE_BASE_PATH}/ui/Location/Lucknow/ResearchCentre/Library/10.png`
const LucknowRC11 = `${IMAGE_BASE_PATH}/ui/Location/Lucknow/ResearchCentre/Library/11.png`

const LucknowRCDrawImg1 = `${IMAGE_BASE_PATH}/ui/Location/Lucknow/ResearchCentre/Library/drawer1.jpg`
const LucknowRCDrawImg2 = `${IMAGE_BASE_PATH}/ui/Location/Lucknow/ResearchCentre/Library/drawer2.jpg`
const LucknowRCDrawImg3 = `${IMAGE_BASE_PATH}/ui/Location/Lucknow/ResearchCentre/Library/drawer3.jpg`

//biblography satyajit
export const satyajit_research_centre=[
    [
        {
            "key": "id",
            "value": "e4cdd9b0-9779-4e1e-8456-32613c788037",
            "display": "Id"
        },
        {
            "key": "title",
            "value": null,
            "display": "Title"
        },
        {
            "key": "alias",
            "value": "bke-dutta-a-satyajit-ray-trilogy-of-film-masterpieces-1960",
            "display": "Alias"
        },
        {
            "key": "web_serial_number",
            "value": null,
            "display": "Web serial number"
        },
        {
            "key": "reference_code",
            "value": null,
            "display": "Reference code"
        },
        {
            "key": "web_display",
            "value": null,
            "display": "Web display"
        },
        {
            "key": "date_of_library_publication",
            "value": null,
            "display": "Date of library publication"
        },
        {
            "key": "place_of_publication",
            "value": "New Delhi",
            "display": "Place of publication"
        },
        {
            "key": "histographical_note",
            "value": null,
            "display": "Histographical note"
        },
        {
            "key": "main_historical_note",
            "value": null,
            "display": "Main historical note"
        },
        {
            "key": "binding_status",
            "value": "PB",
            "display": "Binding status"
        },
        {
            "key": "accession_number",
            "value": "0000002",
            "display": "Accession number"
        },
        {
            "key": "cdt",
            "value": null,
            "display": "CDT"
        },
        {
            "key": "country_of_publication",
            "value": "India",
            "display": "Country of publication"
        },
        {
            "key": "date_of_published_edition_in_library",
            "value": "",
            "display": "Date of published edition in library"
        },
        {
            "key": "dates_of_first_edition",
            "value": "",
            "display": "Dates of first edition"
        },
        {
            "key": "isbn_issn_number",
            "value": "",
            "display": "Isbn issn number"
        },
        {
            "key": "language_of_publication",
            "value": "",
            "display": "Language of publication"
        },
        {
            "key": "number_of_pages_in_publication_text",
            "value": null,
            "display": "Number of pages in publication text"
        },
        {
            "key": "personality_film_visually_featured_on_cover",
            "value": null,
            "display": "Personality film visually featured on cover"
        },
        {
            "key": "publisher",
            "value": "The Bharatiya Natya Sangh",
            "display": "Publisher"
        },
        {
            "key": "serial_number",
            "value": null,
            "display": "Serial number"
        },
        {
            "key": "sortable_year_date_of_publication",
            "value": "1960",
            "display": "Sortable year date of publication"
        },
        {
            "key": "sub_title_of_document_book",
            "value": null,
            "display": "Sub title of document book"
        },
        {
            "key": "title_of_document_book",
            "value": null,
            "display": "Title of document book"
        },
        {
            "key": "book_title_of_document",
            "value": "A Satyajit Ray Trilogy of Film Masterpieces",
            "display": "Book title of document"
        },
        {
            "key": "book_sub_title_of_document",
            "value": "Pather Panchali, Aparajita, Apur Sansar",
            "display": "Book sub title of document"
        },
        {
            "key": "volume_issue_number",
            "value": null,
            "display": "Volume issue number"
        },
        {
            "key": "masterlist",
            "value": "satyajit-ray",
            "display": "Masterlist"
        },
        {
            "key": "image",
            "value": ["https://tuliresearchcentre.canto.global/direct/image/jaq94k62bh4398j8oi30sd352q/izl3XoowqZx5CUjp3f55r31jACk/m800/800"],
            "display": "Image"
        },
        {
            "key": "document_type",
            "value": "bke",
            "display": "Document type"
        },
        {
            "key": "title_and_type_of_creator",
            "value": "Editor",
            "display": "Title and type of creator"
        },
        {
            "key": "number_of_pages_in_publication",
            "value": null,
            "display": "Number of pages in publication"
        },
        {
            "key": "researchCategories",
            "value": [
                {
                    "key": "Cinema as a Key Educational Resource",
                    "value": "CinemaE",
                    "link": "cinema-as-a-key-educational-resource"
                },
                {
                    "key": "Changing Smile of Childhood and its Second Coming",
                    "value": "Childhood",
                    "link": "changing-smile-of-childhood-and-its-second-coming"
                },
                {
                    "key": "India and her Relationship with the World",
                    "value": "IndoWorld",
                    "link": "india-and-her-relationship-with-the-world"
                },
                {
                    "key": "History & Historiography of Indian Scholarship in the Arts, Social Sciences & Humanities",
                    "value": "Scholarship",
                    "link": "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities"
                }
            ],
            "display": "Research categories"
        },
        {
            "key": "creator",
            "value": [
                {
                    "key": "dulal-dutta",
                    "value": "Dulal Dutta",
                    "first_name": "Dulal",
                    "last_name": "Dutta"
                }
            ],
            "display": "Creator"
        }
    ],
    [
        {
            "key": "id",
            "value": "32c095bb-78e3-4bfb-82f9-c4f26b60c685",
            "display": "Id"
        },
        {
            "key": "title",
            "value": null,
            "display": "Title"
        },
        {
            "key": "alias",
            "value": "bka-parrain-regards-sur-le-cinema-indien-1969",
            "display": "Alias"
        },
        {
            "key": "web_serial_number",
            "value": null,
            "display": "Web serial number"
        },
        {
            "key": "reference_code",
            "value": null,
            "display": "Reference code"
        },
        {
            "key": "web_display",
            "value": null,
            "display": "Web display"
        },
        {
            "key": "date_of_library_publication",
            "value": null,
            "display": "Date of library publication"
        },
        {
            "key": "place_of_publication",
            "value": "",
            "display": "Place of publication"
        },
        {
            "key": "histographical_note",
            "value": null,
            "display": "Histographical note"
        },
        {
            "key": "main_historical_note",
            "value": null,
            "display": "Main historical note"
        },
        {
            "key": "binding_status",
            "value": "PB",
            "display": "Binding status"
        },
        {
            "key": "accession_number",
            "value": "0000098",
            "display": "Accession number"
        },
        {
            "key": "cdt",
            "value": null,
            "display": "CDT"
        },
        {
            "key": "country_of_publication",
            "value": "France",
            "display": "Country of publication"
        },
        {
            "key": "date_of_published_edition_in_library",
            "value": "1969",
            "display": "Date of published edition in library"
        },
        {
            "key": "dates_of_first_edition",
            "value": "",
            "display": "Dates of first edition"
        },
        {
            "key": "isbn_issn_number",
            "value": "",
            "display": "Isbn issn number"
        },
        {
            "key": "language_of_publication",
            "value": "",
            "display": "Language of publication"
        },
        {
            "key": "number_of_pages_in_publication_text",
            "value": null,
            "display": "Number of pages in publication text"
        },
        {
            "key": "personality_film_visually_featured_on_cover",
            "value": null,
            "display": "Personality film visually featured on cover"
        },
        {
            "key": "publisher",
            "value": "Editions du Cerf",
            "display": "Publisher"
        },
        {
            "key": "serial_number",
            "value": null,
            "display": "Serial number"
        },
        {
            "key": "sortable_year_date_of_publication",
            "value": "1969",
            "display": "Sortable year date of publication"
        },
        {
            "key": "sub_title_of_document_book",
            "value": null,
            "display": "Sub title of document book"
        },
        {
            "key": "title_of_document_book",
            "value": null,
            "display": "Title of document book"
        },
        {
            "key": "book_title_of_document",
            "value": "Regards sur le Cinema Indien",
            "display": "Book title of document"
        },
        {
            "key": "book_sub_title_of_document",
            "value": "",
            "display": "Book sub title of document"
        },
        {
            "key": "volume_issue_number",
            "value": null,
            "display": "Volume issue number"
        },
        {
            "key": "masterlist",
            "value": "satyajit-ray",
            "display": "Masterlist"
        },
        {
            "key": "image",
            "value": ["https://tuliresearchcentre.canto.global/direct/image/hg9q4is0sl2p9fmio6k8sm3h6v/_Glt5lZlFHQB1AAxorKLPj0RIGI/m800/800"],
            "display": "Image"
        },
        {
            "key": "document_type",
            "value": "bka",
            "display": "Document type"
        },
        {
            "key": "title_and_type_of_creator",
            "value": "Author",
            "display": "Title and type of creator"
        },
        {
            "key": "number_of_pages_in_publication",
            "value": null,
            "display": "Number of pages in publication"
        },
        {
            "key": "researchCategories",
            "value": [
                {
                    "key": "Cinema as a Key Educational Resource",
                    "value": "CinemaE",
                    "link": "cinema-as-a-key-educational-resource"
                }
            ],
            "display": "Research categories"
        },
        {
            "key": "creator",
            "value": [
                {
                    "key": "philippe-parrain",
                    "value": "Philippe Parrain",
                    "first_name": "Philippe",
                    "last_name": "Parrain"
                }
            ],
            "display": "Creator"
        }
    ],
    [
        {
            "key": "id",
            "value": "e3d7c112-cebc-4533-93fb-719eecb5b615",
            "display": "Id"
        },
        {
            "key": "title",
            "value": null,
            "display": "Title"
        },
        {
            "key": "alias",
            "value": "bka-seton-portrait-of-a-director-satyajit-ray-1971",
            "display": "Alias"
        },
        {
            "key": "web_serial_number",
            "value": null,
            "display": "Web serial number"
        },
        {
            "key": "reference_code",
            "value": null,
            "display": "Reference code"
        },
        {
            "key": "web_display",
            "value": null,
            "display": "Web display"
        },
        {
            "key": "date_of_library_publication",
            "value": null,
            "display": "Date of library publication"
        },
        {
            "key": "place_of_publication",
            "value": "London",
            "display": "Place of publication"
        },
        {
            "key": "histographical_note",
            "value": null,
            "display": "Histographical note"
        },
        {
            "key": "main_historical_note",
            "value": null,
            "display": "Main historical note"
        },
        {
            "key": "binding_status",
            "value": "HB",
            "display": "Binding status"
        },
        {
            "key": "accession_number",
            "value": "0000064",
            "display": "Accession number"
        },
        {
            "key": "cdt",
            "value": null,
            "display": "CDT"
        },
        {
            "key": "country_of_publication",
            "value": "UK",
            "display": "Country of publication"
        },
        {
            "key": "date_of_published_edition_in_library",
            "value": "1971",
            "display": "Date of published edition in library"
        },
        {
            "key": "dates_of_first_edition",
            "value": "",
            "display": "Dates of first edition"
        },
        {
            "key": "isbn_issn_number",
            "value": "0-234772344",
            "display": "Isbn issn number"
        },
        {
            "key": "language_of_publication",
            "value": "",
            "display": "Language of publication"
        },
        {
            "key": "number_of_pages_in_publication_text",
            "value": null,
            "display": "Number of pages in publication text"
        },
        {
            "key": "personality_film_visually_featured_on_cover",
            "value": null,
            "display": "Personality film visually featured on cover"
        },
        {
            "key": "publisher",
            "value": "Dobson Books",
            "display": "Publisher"
        },
        {
            "key": "serial_number",
            "value": null,
            "display": "Serial number"
        },
        {
            "key": "sortable_year_date_of_publication",
            "value": "1971",
            "display": "Sortable year date of publication"
        },
        {
            "key": "sub_title_of_document_book",
            "value": null,
            "display": "Sub title of document book"
        },
        {
            "key": "title_of_document_book",
            "value": null,
            "display": "Title of document book"
        },
        {
            "key": "book_title_of_document",
            "value": "Portrait of a Director: Satyajit Ray",
            "display": "Book title of document"
        },
        {
            "key": "book_sub_title_of_document",
            "value": "",
            "display": "Book sub title of document"
        },
        {
            "key": "volume_issue_number",
            "value": null,
            "display": "Volume issue number"
        },
        {
            "key": "masterlist",
            "value": "satyajit-ray",
            "display": "Masterlist"
        },
        {
            "key": "image",
            "value": ["https://tuliresearchcentre.canto.global/direct/image/kg8hr0bnv55qffvgejqjauuk6h/mWPHOlkncErhiXVGDghja3jRFi8/m800/800"],
            "display": "Image"
        },
        {
            "key": "document_type",
            "value": "bka",
            "display": "Document type"
        },
        {
            "key": "title_and_type_of_creator",
            "value": "Author",
            "display": "Title and type of creator"
        },
        {
            "key": "number_of_pages_in_publication",
            "value": null,
            "display": "Number of pages in publication"
        },
        {
            "key": "researchCategories",
            "value": [
                {
                    "key": "History & Historiography of Indian Scholarship in the Arts, Social Sciences & Humanities",
                    "value": "Scholarship",
                    "link": "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities"
                },
                {
                    "key": "Cinema as a Key Educational Resource",
                    "value": "CinemaE",
                    "link": "cinema-as-a-key-educational-resource"
                }
            ],
            "display": "Research categories"
        },
        {
            "key": "creator",
            "value": [
                {
                    "key": "marie-seton",
                    "value": "Marie Seton",
                    "first_name": "Marie",
                    "last_name": "Seton"
                }
            ],
            "display": "Creator"
        }
    ],
    [
        {
            "key": "id",
            "value": "4ca219d3-e558-4b61-a5a5-c5034504d2f7",
            "display": "Id"
        },
        {
            "key": "title",
            "value": null,
            "display": "Title"
        },
        {
            "key": "alias",
            "value": "bka-wood-the-apu-trilogy-1971",
            "display": "Alias"
        },
        {
            "key": "web_serial_number",
            "value": null,
            "display": "Web serial number"
        },
        {
            "key": "reference_code",
            "value": null,
            "display": "Reference code"
        },
        {
            "key": "web_display",
            "value": null,
            "display": "Web display"
        },
        {
            "key": "date_of_library_publication",
            "value": null,
            "display": "Date of library publication"
        },
        {
            "key": "place_of_publication",
            "value": "New York",
            "display": "Place of publication"
        },
        {
            "key": "histographical_note",
            "value": null,
            "display": "Histographical note"
        },
        {
            "key": "main_historical_note",
            "value": null,
            "display": "Main historical note"
        },
        {
            "key": "binding_status",
            "value": "PB",
            "display": "Binding status"
        },
        {
            "key": "accession_number",
            "value": "0000082",
            "display": "Accession number"
        },
        {
            "key": "cdt",
            "value": null,
            "display": "CDT"
        },
        {
            "key": "country_of_publication",
            "value": "USA",
            "display": "Country of publication"
        },
        {
            "key": "date_of_published_edition_in_library",
            "value": "1971",
            "display": "Date of published edition in library"
        },
        {
            "key": "dates_of_first_edition",
            "value": "",
            "display": "Dates of first edition"
        },
        {
            "key": "isbn_issn_number",
            "value": "",
            "display": "Isbn issn number"
        },
        {
            "key": "language_of_publication",
            "value": "",
            "display": "Language of publication"
        },
        {
            "key": "number_of_pages_in_publication_text",
            "value": null,
            "display": "Number of pages in publication text"
        },
        {
            "key": "personality_film_visually_featured_on_cover",
            "value": null,
            "display": "Personality film visually featured on cover"
        },
        {
            "key": "publisher",
            "value": "Praeger",
            "display": "Publisher"
        },
        {
            "key": "serial_number",
            "value": null,
            "display": "Serial number"
        },
        {
            "key": "sortable_year_date_of_publication",
            "value": "1971",
            "display": "Sortable year date of publication"
        },
        {
            "key": "sub_title_of_document_book",
            "value": null,
            "display": "Sub title of document book"
        },
        {
            "key": "title_of_document_book",
            "value": null,
            "display": "Title of document book"
        },
        {
            "key": "book_title_of_document",
            "value": "The Apu Trilogy ",
            "display": "Book title of document"
        },
        {
            "key": "book_sub_title_of_document",
            "value": "",
            "display": "Book sub title of document"
        },
        {
            "key": "volume_issue_number",
            "value": null,
            "display": "Volume issue number"
        },
        {
            "key": "masterlist",
            "value": "satyajit-ray",
            "display": "Masterlist"
        },
        {
            "key": "image",
            "value": ["https://tuliresearchcentre.canto.global/direct/image/30bahp6d857p5a0m5gvnmaii04/iFiXQSfk_qXegsoubXN5ciPLdTU/m800/800"],
            "display": "Image"
        },
        {
            "key": "document_type",
            "value": "bka",
            "display": "Document type"
        },
        {
            "key": "title_and_type_of_creator",
            "value": "Author",
            "display": "Title and type of creator"
        },
        {
            "key": "number_of_pages_in_publication",
            "value": null,
            "display": "Number of pages in publication"
        },
        {
            "key": "researchCategories",
            "value": [
                {
                    "key": "Cinema as a Key Educational Resource",
                    "value": "CinemaE",
                    "link": "cinema-as-a-key-educational-resource"
                },
                {
                    "key": "Changing Smile of Childhood and its Second Coming",
                    "value": "Childhood",
                    "link": "changing-smile-of-childhood-and-its-second-coming"
                }
            ],
            "display": "Research categories"
        },
        {
            "key": "creator",
            "value": [
                {
                    "key": "robin-wood",
                    "value": "Robin Wood",
                    "first_name": "Robin",
                    "last_name": "Wood"
                }
            ],
            "display": "Creator"
        }
    ],
    [
        {
            "key": "id",
            "value": "404bdddf-cbf8-4147-bc87-b89bb605e63e",
            "display": "Id"
        },
        {
            "key": "title",
            "value": null,
            "display": "Title"
        },
        {
            "key": "alias",
            "value": "bka-seton-portrait-of-a-director-satyajit-ray-1972",
            "display": "Alias"
        },
        {
            "key": "web_serial_number",
            "value": null,
            "display": "Web serial number"
        },
        {
            "key": "reference_code",
            "value": null,
            "display": "Reference code"
        },
        {
            "key": "web_display",
            "value": null,
            "display": "Web display"
        },
        {
            "key": "date_of_library_publication",
            "value": null,
            "display": "Date of library publication"
        },
        {
            "key": "place_of_publication",
            "value": "Delhi",
            "display": "Place of publication"
        },
        {
            "key": "histographical_note",
            "value": null,
            "display": "Histographical note"
        },
        {
            "key": "main_historical_note",
            "value": null,
            "display": "Main historical note"
        },
        {
            "key": "binding_status",
            "value": "HB",
            "display": "Binding status"
        },
        {
            "key": "accession_number",
            "value": "0000065",
            "display": "Accession number"
        },
        {
            "key": "cdt",
            "value": null,
            "display": "CDT"
        },
        {
            "key": "country_of_publication",
            "value": "India",
            "display": "Country of publication"
        },
        {
            "key": "date_of_published_edition_in_library",
            "value": "1972",
            "display": "Date of published edition in library"
        },
        {
            "key": "dates_of_first_edition",
            "value": "",
            "display": "Dates of first edition"
        },
        {
            "key": "isbn_issn_number",
            "value": "",
            "display": "Isbn issn number"
        },
        {
            "key": "language_of_publication",
            "value": "",
            "display": "Language of publication"
        },
        {
            "key": "number_of_pages_in_publication_text",
            "value": null,
            "display": "Number of pages in publication text"
        },
        {
            "key": "personality_film_visually_featured_on_cover",
            "value": null,
            "display": "Personality film visually featured on cover"
        },
        {
            "key": "publisher",
            "value": "Vikas",
            "display": "Publisher"
        },
        {
            "key": "serial_number",
            "value": null,
            "display": "Serial number"
        },
        {
            "key": "sortable_year_date_of_publication",
            "value": "1972",
            "display": "Sortable year date of publication"
        },
        {
            "key": "sub_title_of_document_book",
            "value": null,
            "display": "Sub title of document book"
        },
        {
            "key": "title_of_document_book",
            "value": null,
            "display": "Title of document book"
        },
        {
            "key": "book_title_of_document",
            "value": "Portrait of a Director: Satyajit Ray",
            "display": "Book title of document"
        },
        {
            "key": "book_sub_title_of_document",
            "value": "",
            "display": "Book sub title of document"
        },
        {
            "key": "volume_issue_number",
            "value": null,
            "display": "Volume issue number"
        },
        {
            "key": "masterlist",
            "value": "satyajit-ray",
            "display": "Masterlist"
        },
        {
            "key": "image",
            "value": ["https://tuliresearchcentre.canto.global/direct/image/ief8vdinlp5v3f53imqaoc1d3v/ZfMPdfpQE7efjtWdkxAyQJO0RHQ/m800/800"],
            "display": "Image"
        },
        {
            "key": "document_type",
            "value": "bka",
            "display": "Document type"
        },
        {
            "key": "title_and_type_of_creator",
            "value": "Author",
            "display": "Title and type of creator"
        },
        {
            "key": "number_of_pages_in_publication",
            "value": null,
            "display": "Number of pages in publication"
        },
        {
            "key": "researchCategories",
            "value": [
                {
                    "key": "History & Historiography of Indian Scholarship in the Arts, Social Sciences & Humanities",
                    "value": "Scholarship",
                    "link": "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities"
                },
                {
                    "key": "Cinema as a Key Educational Resource",
                    "value": "CinemaE",
                    "link": "cinema-as-a-key-educational-resource"
                }
            ],
            "display": "Research categories"
        },
        {
            "key": "creator",
            "value": [
                {
                    "key": "marie-seton",
                    "value": "Marie Seton",
                    "first_name": "Marie",
                    "last_name": "Seton"
                }
            ],
            "display": "Creator"
        }
    ],
    [
        {
            "key": "id",
            "value": "4f3639ca-d956-4e03-b7c0-6000225ddff5",
            "display": "Id"
        },
        {
            "key": "title",
            "value": null,
            "display": "Title"
        },
        {
            "key": "alias",
            "value": "bke-satyajit-ray-1975",
            "display": "Alias"
        },
        {
            "key": "web_serial_number",
            "value": null,
            "display": "Web serial number"
        },
        {
            "key": "reference_code",
            "value": null,
            "display": "Reference code"
        },
        {
            "key": "web_display",
            "value": null,
            "display": "Web display"
        },
        {
            "key": "date_of_library_publication",
            "value": null,
            "display": "Date of library publication"
        },
        {
            "key": "place_of_publication",
            "value": "Bombay",
            "display": "Place of publication"
        },
        {
            "key": "histographical_note",
            "value": null,
            "display": "Histographical note"
        },
        {
            "key": "main_historical_note",
            "value": null,
            "display": "Main historical note"
        },
        {
            "key": "binding_status",
            "value": "PB",
            "display": "Binding status"
        },
        {
            "key": "accession_number",
            "value": "0000090",
            "display": "Accession number"
        },
        {
            "key": "cdt",
            "value": null,
            "display": "CDT"
        },
        {
            "key": "country_of_publication",
            "value": "India",
            "display": "Country of publication"
        },
        {
            "key": "date_of_published_edition_in_library",
            "value": "",
            "display": "Date of published edition in library"
        },
        {
            "key": "dates_of_first_edition",
            "value": "",
            "display": "Dates of first edition"
        },
        {
            "key": "isbn_issn_number",
            "value": "",
            "display": "Isbn issn number"
        },
        {
            "key": "language_of_publication",
            "value": "",
            "display": "Language of publication"
        },
        {
            "key": "number_of_pages_in_publication_text",
            "value": null,
            "display": "Number of pages in publication text"
        },
        {
            "key": "personality_film_visually_featured_on_cover",
            "value": null,
            "display": "Personality film visually featured on cover"
        },
        {
            "key": "publisher",
            "value": "National Film Development Corporation",
            "display": "Publisher"
        },
        {
            "key": "serial_number",
            "value": null,
            "display": "Serial number"
        },
        {
            "key": "sortable_year_date_of_publication",
            "value": "1975",
            "display": "Sortable year date of publication"
        },
        {
            "key": "sub_title_of_document_book",
            "value": null,
            "display": "Sub title of document book"
        },
        {
            "key": "title_of_document_book",
            "value": null,
            "display": "Title of document book"
        },
        {
            "key": "book_title_of_document",
            "value": "Satyajit Ray",
            "display": "Book title of document"
        },
        {
            "key": "book_sub_title_of_document",
            "value": "Twenty Five Films",
            "display": "Book sub title of document"
        },
        {
            "key": "volume_issue_number",
            "value": null,
            "display": "Volume issue number"
        },
        {
            "key": "masterlist",
            "value": "satyajit-ray",
            "display": "Masterlist"
        },
        {
            "key": "image",
            "value": ["https://tuliresearchcentre.canto.global/direct/image/ep6u6gjqgl0jbcushpe53rjb5s/rMjG7wddpabNgLA5cvRgzoYEzb4/m800/800"],
            "display": "Image"
        },
        {
            "key": "document_type",
            "value": "bke",
            "display": "Document type"
        },
        {
            "key": "title_and_type_of_creator",
            "value": "Organizer",
            "display": "Title and type of creator"
        },
        {
            "key": "number_of_pages_in_publication",
            "value": null,
            "display": "Number of pages in publication"
        },
        {
            "key": "researchCategories",
            "value": [
                {
                    "key": "History & Historiography of Indian Scholarship in the Arts, Social Sciences & Humanities",
                    "value": "Scholarship",
                    "link": "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities"
                },
                {
                    "key": "`",
                    "value": null,
                    "link": "`"
                }
            ],
            "display": "Research categories"
        },
        {
            "key": "creator",
            "value": [],
            "display": "Creator"
        }
    ],
    [
        {
            "key": "id",
            "value": "d9522a7c-71e0-4e68-9ff4-562a7d41b29b",
            "display": "Id"
        },
        {
            "key": "title",
            "value": null,
            "display": "Title"
        },
        {
            "key": "alias",
            "value": "bka-bahadur-the-world-of-pather-panchali-1975",
            "display": "Alias"
        },
        {
            "key": "web_serial_number",
            "value": null,
            "display": "Web serial number"
        },
        {
            "key": "reference_code",
            "value": null,
            "display": "Reference code"
        },
        {
            "key": "web_display",
            "value": null,
            "display": "Web display"
        },
        {
            "key": "date_of_library_publication",
            "value": null,
            "display": "Date of library publication"
        },
        {
            "key": "place_of_publication",
            "value": "Bombay",
            "display": "Place of publication"
        },
        {
            "key": "histographical_note",
            "value": null,
            "display": "Histographical note"
        },
        {
            "key": "main_historical_note",
            "value": null,
            "display": "Main historical note"
        },
        {
            "key": "binding_status",
            "value": "PB",
            "display": "Binding status"
        },
        {
            "key": "accession_number",
            "value": "0000005",
            "display": "Accession number"
        },
        {
            "key": "cdt",
            "value": null,
            "display": "CDT"
        },
        {
            "key": "country_of_publication",
            "value": "India",
            "display": "Country of publication"
        },
        {
            "key": "date_of_published_edition_in_library",
            "value": "",
            "display": "Date of published edition in library"
        },
        {
            "key": "dates_of_first_edition",
            "value": "",
            "display": "Dates of first edition"
        },
        {
            "key": "isbn_issn_number",
            "value": "",
            "display": "Isbn issn number"
        },
        {
            "key": "language_of_publication",
            "value": "",
            "display": "Language of publication"
        },
        {
            "key": "number_of_pages_in_publication_text",
            "value": null,
            "display": "Number of pages in publication text"
        },
        {
            "key": "personality_film_visually_featured_on_cover",
            "value": null,
            "display": "Personality film visually featured on cover"
        },
        {
            "key": "publisher",
            "value": "National Film Archive of India",
            "display": "Publisher"
        },
        {
            "key": "serial_number",
            "value": null,
            "display": "Serial number"
        },
        {
            "key": "sortable_year_date_of_publication",
            "value": "1975",
            "display": "Sortable year date of publication"
        },
        {
            "key": "sub_title_of_document_book",
            "value": null,
            "display": "Sub title of document book"
        },
        {
            "key": "title_of_document_book",
            "value": null,
            "display": "Title of document book"
        },
        {
            "key": "book_title_of_document",
            "value": "The World of Pather Panchali",
            "display": "Book title of document"
        },
        {
            "key": "book_sub_title_of_document",
            "value": "",
            "display": "Book sub title of document"
        },
        {
            "key": "volume_issue_number",
            "value": null,
            "display": "Volume issue number"
        },
        {
            "key": "masterlist",
            "value": "satyajit-ray",
            "display": "Masterlist"
        },
        {
            "key": "image",
            "value": ["https://tuliresearchcentre.canto.global/direct/image/hotv8vucvh2cd6uvubv10m7o3c/V6qKXVklU2x_oey0HKcIZtIIUGM/m800/800"],
            "display": "Image"
        },
        {
            "key": "document_type",
            "value": "bka",
            "display": "Document type"
        },
        {
            "key": "title_and_type_of_creator",
            "value": "Author",
            "display": "Title and type of creator"
        },
        {
            "key": "number_of_pages_in_publication",
            "value": null,
            "display": "Number of pages in publication"
        },
        {
            "key": "researchCategories",
            "value": [
                {
                    "key": "History & Historiography of Indian Scholarship in the Arts, Social Sciences & Humanities",
                    "value": "Scholarship",
                    "link": "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities"
                },
                {
                    "key": "India and her Relationship with the World",
                    "value": "IndoWorld",
                    "link": "india-and-her-relationship-with-the-world"
                },
                {
                    "key": "Changing Smile of Childhood and its Second Coming",
                    "value": "Childhood",
                    "link": "changing-smile-of-childhood-and-its-second-coming"
                },
                {
                    "key": "Cinema as a Key Educational Resource",
                    "value": "CinemaE",
                    "link": "cinema-as-a-key-educational-resource"
                }
            ],
            "display": "Research categories"
        },
        {
            "key": "creator",
            "value": [
                {
                    "key": "satish-bahadur",
                    "value": "Satish Bahadur",
                    "first_name": "Satish",
                    "last_name": "Bahadur"
                }
            ],
            "display": "Creator"
        }
    ],
    [
        {
            "key": "id",
            "value": "9880b981-3f24-47bb-bfa3-44681ffad804",
            "display": "Id"
        },
        {
            "key": "title",
            "value": null,
            "display": "Title"
        },
        {
            "key": "alias",
            "value": "bka-sarkar-indian-cinema-today-1975",
            "display": "Alias"
        },
        {
            "key": "web_serial_number",
            "value": null,
            "display": "Web serial number"
        },
        {
            "key": "reference_code",
            "value": null,
            "display": "Reference code"
        },
        {
            "key": "web_display",
            "value": null,
            "display": "Web display"
        },
        {
            "key": "date_of_library_publication",
            "value": null,
            "display": "Date of library publication"
        },
        {
            "key": "place_of_publication",
            "value": "New Delhi",
            "display": "Place of publication"
        },
        {
            "key": "histographical_note",
            "value": null,
            "display": "Histographical note"
        },
        {
            "key": "main_historical_note",
            "value": null,
            "display": "Main historical note"
        },
        {
            "key": "binding_status",
            "value": "HB",
            "display": "Binding status"
        },
        {
            "key": "accession_number",
            "value": "0000080",
            "display": "Accession number"
        },
        {
            "key": "cdt",
            "value": null,
            "display": "CDT"
        },
        {
            "key": "country_of_publication",
            "value": "India",
            "display": "Country of publication"
        },
        {
            "key": "date_of_published_edition_in_library",
            "value": "1975",
            "display": "Date of published edition in library"
        },
        {
            "key": "dates_of_first_edition",
            "value": "",
            "display": "Dates of first edition"
        },
        {
            "key": "isbn_issn_number",
            "value": "",
            "display": "Isbn issn number"
        },
        {
            "key": "language_of_publication",
            "value": "",
            "display": "Language of publication"
        },
        {
            "key": "number_of_pages_in_publication_text",
            "value": null,
            "display": "Number of pages in publication text"
        },
        {
            "key": "personality_film_visually_featured_on_cover",
            "value": null,
            "display": "Personality film visually featured on cover"
        },
        {
            "key": "publisher",
            "value": "Sterling",
            "display": "Publisher"
        },
        {
            "key": "serial_number",
            "value": null,
            "display": "Serial number"
        },
        {
            "key": "sortable_year_date_of_publication",
            "value": "1975",
            "display": "Sortable year date of publication"
        },
        {
            "key": "sub_title_of_document_book",
            "value": null,
            "display": "Sub title of document book"
        },
        {
            "key": "title_of_document_book",
            "value": null,
            "display": "Title of document book"
        },
        {
            "key": "book_title_of_document",
            "value": "Indian Cinema Today",
            "display": "Book title of document"
        },
        {
            "key": "book_sub_title_of_document",
            "value": "An Analysis",
            "display": "Book sub title of document"
        },
        {
            "key": "volume_issue_number",
            "value": null,
            "display": "Volume issue number"
        },
        {
            "key": "masterlist",
            "value": "satyajit-ray",
            "display": "Masterlist"
        },
        {
            "key": "image",
            "value": ["https://tuliresearchcentre.canto.global/direct/image/gmknoimv2l1mtcble6c69pjb0u/0PALmAT9D2HnJegLMCtvSt-S0Wk/m800/800"],
            "display": "Image"
        },
        {
            "key": "document_type",
            "value": "bka",
            "display": "Document type"
        },
        {
            "key": "title_and_type_of_creator",
            "value": "Author",
            "display": "Title and type of creator"
        },
        {
            "key": "number_of_pages_in_publication",
            "value": null,
            "display": "Number of pages in publication"
        },
        {
            "key": "researchCategories",
            "value": [
                {
                    "key": "History & Historiography of Indian Scholarship in the Arts, Social Sciences & Humanities",
                    "value": "Scholarship",
                    "link": "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities"
                },
                {
                    "key": "Cinema as a Key Educational Resource",
                    "value": "CinemaE",
                    "link": "cinema-as-a-key-educational-resource"
                }
            ],
            "display": "Research categories"
        },
        {
            "key": "creator",
            "value": [
                {
                    "key": "kobita-sarkar",
                    "value": "Kobita Sarkar",
                    "first_name": "Kobita",
                    "last_name": "Sarkar"
                }
            ],
            "display": "Creator"
        }
    ],
    [
        {
            "key": "id",
            "value": "47475192-3bdf-4ab3-8869-f00c94097d1e",
            "display": "Id"
        },
        {
            "key": "title",
            "value": null,
            "display": "Title"
        },
        {
            "key": "alias",
            "value": "bka-ray-our-films-their-films-1976",
            "display": "Alias"
        },
        {
            "key": "web_serial_number",
            "value": null,
            "display": "Web serial number"
        },
        {
            "key": "reference_code",
            "value": null,
            "display": "Reference code"
        },
        {
            "key": "web_display",
            "value": null,
            "display": "Web display"
        },
        {
            "key": "date_of_library_publication",
            "value": null,
            "display": "Date of library publication"
        },
        {
            "key": "place_of_publication",
            "value": "",
            "display": "Place of publication"
        },
        {
            "key": "histographical_note",
            "value": null,
            "display": "Histographical note"
        },
        {
            "key": "main_historical_note",
            "value": null,
            "display": "Main historical note"
        },
        {
            "key": "binding_status",
            "value": "",
            "display": "Binding status"
        },
        {
            "key": "accession_number",
            "value": "0000006",
            "display": "Accession number"
        },
        {
            "key": "cdt",
            "value": null,
            "display": "CDT"
        },
        {
            "key": "country_of_publication",
            "value": "",
            "display": "Country of publication"
        },
        {
            "key": "date_of_published_edition_in_library",
            "value": "1976",
            "display": "Date of published edition in library"
        },
        {
            "key": "dates_of_first_edition",
            "value": "",
            "display": "Dates of first edition"
        },
        {
            "key": "isbn_issn_number",
            "value": "81-25015655",
            "display": "Isbn issn number"
        },
        {
            "key": "language_of_publication",
            "value": "",
            "display": "Language of publication"
        },
        {
            "key": "number_of_pages_in_publication_text",
            "value": null,
            "display": "Number of pages in publication text"
        },
        {
            "key": "personality_film_visually_featured_on_cover",
            "value": null,
            "display": "Personality film visually featured on cover"
        },
        {
            "key": "publisher",
            "value": "",
            "display": "Publisher"
        },
        {
            "key": "serial_number",
            "value": null,
            "display": "Serial number"
        },
        {
            "key": "sortable_year_date_of_publication",
            "value": "1976",
            "display": "Sortable year date of publication"
        },
        {
            "key": "sub_title_of_document_book",
            "value": null,
            "display": "Sub title of document book"
        },
        {
            "key": "title_of_document_book",
            "value": null,
            "display": "Title of document book"
        },
        {
            "key": "book_title_of_document",
            "value": "Our Films Their Films",
            "display": "Book title of document"
        },
        {
            "key": "book_sub_title_of_document",
            "value": "",
            "display": "Book sub title of document"
        },
        {
            "key": "volume_issue_number",
            "value": null,
            "display": "Volume issue number"
        },
        {
            "key": "masterlist",
            "value": "satyajit-ray",
            "display": "Masterlist"
        },
        {
            "key": "image",
            "value": ["https://tuliresearchcentre.canto.global/direct/image/2ua2o4r7hd6f92i4mffjkf7u42/vnKZy3nGtj78jXbE50suRyp5p-8/m800/800"],
            "display": "Image"
        },
        {
            "key": "document_type",
            "value": "bka",
            "display": "Document type"
        },
        {
            "key": "title_and_type_of_creator",
            "value": "Author",
            "display": "Title and type of creator"
        },
        {
            "key": "number_of_pages_in_publication",
            "value": null,
            "display": "Number of pages in publication"
        },
        {
            "key": "researchCategories",
            "value": [
                {
                    "key": "Cinema as a Key Educational Resource",
                    "value": "CinemaE",
                    "link": "cinema-as-a-key-educational-resource"
                },
                {
                    "key": "History & Historiography of Indian Scholarship in the Arts, Social Sciences & Humanities",
                    "value": "Scholarship",
                    "link": "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities"
                }
            ],
            "display": "Research categories"
        },
        {
            "key": "creator",
            "value": [
                {
                    "key": "satyajit-ray",
                    "value": null,
                    "first_name": null,
                    "last_name": null
                }
            ],
            "display": "Creator"
        }
    ],
    [
        {
            "key": "id",
            "value": "ebec684d-6c65-446a-86ba-3543df77039e",
            "display": "Id"
        },
        {
            "key": "title",
            "value": null,
            "display": "Title"
        },
        {
            "key": "alias",
            "value": "bka-ray-joi-baba-felunath-1976",
            "display": "Alias"
        },
        {
            "key": "web_serial_number",
            "value": null,
            "display": "Web serial number"
        },
        {
            "key": "reference_code",
            "value": null,
            "display": "Reference code"
        },
        {
            "key": "web_display",
            "value": null,
            "display": "Web display"
        },
        {
            "key": "date_of_library_publication",
            "value": null,
            "display": "Date of library publication"
        },
        {
            "key": "place_of_publication",
            "value": "Calcutta",
            "display": "Place of publication"
        },
        {
            "key": "histographical_note",
            "value": null,
            "display": "Histographical note"
        },
        {
            "key": "main_historical_note",
            "value": null,
            "display": "Main historical note"
        },
        {
            "key": "binding_status",
            "value": "",
            "display": "Binding status"
        },
        {
            "key": "accession_number",
            "value": "0000007",
            "display": "Accession number"
        },
        {
            "key": "cdt",
            "value": null,
            "display": "CDT"
        },
        {
            "key": "country_of_publication",
            "value": "India",
            "display": "Country of publication"
        },
        {
            "key": "date_of_published_edition_in_library",
            "value": "",
            "display": "Date of published edition in library"
        },
        {
            "key": "dates_of_first_edition",
            "value": "",
            "display": "Dates of first edition"
        },
        {
            "key": "isbn_issn_number",
            "value": "",
            "display": "Isbn issn number"
        },
        {
            "key": "language_of_publication",
            "value": "",
            "display": "Language of publication"
        },
        {
            "key": "number_of_pages_in_publication_text",
            "value": null,
            "display": "Number of pages in publication text"
        },
        {
            "key": "personality_film_visually_featured_on_cover",
            "value": null,
            "display": "Personality film visually featured on cover"
        },
        {
            "key": "publisher",
            "value": "Ananda",
            "display": "Publisher"
        },
        {
            "key": "serial_number",
            "value": null,
            "display": "Serial number"
        },
        {
            "key": "sortable_year_date_of_publication",
            "value": "1976",
            "display": "Sortable year date of publication"
        },
        {
            "key": "sub_title_of_document_book",
            "value": null,
            "display": "Sub title of document book"
        },
        {
            "key": "title_of_document_book",
            "value": null,
            "display": "Title of document book"
        },
        {
            "key": "book_title_of_document",
            "value": "Joi Baba Felunath",
            "display": "Book title of document"
        },
        {
            "key": "book_sub_title_of_document",
            "value": "",
            "display": "Book sub title of document"
        },
        {
            "key": "volume_issue_number",
            "value": null,
            "display": "Volume issue number"
        },
        {
            "key": "masterlist",
            "value": "satyajit-ray",
            "display": "Masterlist"
        },
        {
            "key": "image",
            "value": ["https://tuliresearchcentre.canto.global/direct/image/p4vc9t10h55a563bs951r4im7c/d1sA7vb3JxUDz43qetmZw1wNq-0/m800/800"],
            "display": "Image"
        },
        {
            "key": "document_type",
            "value": "bka",
            "display": "Document type"
        },
        {
            "key": "title_and_type_of_creator",
            "value": "Author",
            "display": "Title and type of creator"
        },
        {
            "key": "number_of_pages_in_publication",
            "value": null,
            "display": "Number of pages in publication"
        },
        {
            "key": "researchCategories",
            "value": [
                {
                    "key": "Cinema as a Key Educational Resource",
                    "value": "CinemaE",
                    "link": "cinema-as-a-key-educational-resource"
                },
                {
                    "key": "Changing Smile of Childhood and its Second Coming",
                    "value": "Childhood",
                    "link": "changing-smile-of-childhood-and-its-second-coming"
                }
            ],
            "display": "Research categories"
        },
        {
            "key": "creator",
            "value": [
                {
                    "key": "satyajit-ray",
                    "value": null,
                    "first_name": null,
                    "last_name": null
                }
            ],
            "display": "Creator"
        }
    ],
    [
        {
            "key": "id",
            "value": "f3350379-22a1-401a-83f2-7e5dbd0fda52",
            "display": "Id"
        },
        {
            "key": "title",
            "value": null,
            "display": "Title"
        },
        {
            "key": "alias",
            "value": "bka-ray-nayak-1976",
            "display": "Alias"
        },
        {
            "key": "web_serial_number",
            "value": null,
            "display": "Web serial number"
        },
        {
            "key": "reference_code",
            "value": null,
            "display": "Reference code"
        },
        {
            "key": "web_display",
            "value": null,
            "display": "Web display"
        },
        {
            "key": "date_of_library_publication",
            "value": null,
            "display": "Date of library publication"
        },
        {
            "key": "place_of_publication",
            "value": "Delhi",
            "display": "Place of publication"
        },
        {
            "key": "histographical_note",
            "value": null,
            "display": "Histographical note"
        },
        {
            "key": "main_historical_note",
            "value": null,
            "display": "Main historical note"
        },
        {
            "key": "binding_status",
            "value": "HB",
            "display": "Binding status"
        },
        {
            "key": "accession_number",
            "value": "0000008",
            "display": "Accession number"
        },
        {
            "key": "cdt",
            "value": null,
            "display": "CDT"
        },
        {
            "key": "country_of_publication",
            "value": "India",
            "display": "Country of publication"
        },
        {
            "key": "date_of_published_edition_in_library",
            "value": "1976",
            "display": "Date of published edition in library"
        },
        {
            "key": "dates_of_first_edition",
            "value": "",
            "display": "Dates of first edition"
        },
        {
            "key": "isbn_issn_number",
            "value": "",
            "display": "Isbn issn number"
        },
        {
            "key": "language_of_publication",
            "value": "",
            "display": "Language of publication"
        },
        {
            "key": "number_of_pages_in_publication_text",
            "value": null,
            "display": "Number of pages in publication text"
        },
        {
            "key": "personality_film_visually_featured_on_cover",
            "value": null,
            "display": "Personality film visually featured on cover"
        },
        {
            "key": "publisher",
            "value": "Rajpal",
            "display": "Publisher"
        },
        {
            "key": "serial_number",
            "value": null,
            "display": "Serial number"
        },
        {
            "key": "sortable_year_date_of_publication",
            "value": "1976",
            "display": "Sortable year date of publication"
        },
        {
            "key": "sub_title_of_document_book",
            "value": null,
            "display": "Sub title of document book"
        },
        {
            "key": "title_of_document_book",
            "value": null,
            "display": "Title of document book"
        },
        {
            "key": "book_title_of_document",
            "value": "Nayak",
            "display": "Book title of document"
        },
        {
            "key": "book_sub_title_of_document",
            "value": "",
            "display": "Book sub title of document"
        },
        {
            "key": "volume_issue_number",
            "value": null,
            "display": "Volume issue number"
        },
        {
            "key": "masterlist",
            "value": "satyajit-ray",
            "display": "Masterlist"
        },
        {
            "key": "image",
            "value": ["https://tuliresearchcentre.canto.global/direct/image/qikv1c6uh509765199c00bde6k/fldVsObo8xP6Tn5_9V9HJ--vDfU/m800/800"],
            "display": "Image"
        },
        {
            "key": "document_type",
            "value": "bka",
            "display": "Document type"
        },
        {
            "key": "title_and_type_of_creator",
            "value": "Author",
            "display": "Title and type of creator"
        },
        {
            "key": "number_of_pages_in_publication",
            "value": null,
            "display": "Number of pages in publication"
        },
        {
            "key": "researchCategories",
            "value": [
                {
                    "key": "Cinema as a Key Educational Resource",
                    "value": "CinemaE",
                    "link": "cinema-as-a-key-educational-resource"
                }
            ],
            "display": "Research categories"
        },
        {
            "key": "creator",
            "value": [
                {
                    "key": "satyajit-ray",
                    "value": null,
                    "first_name": null,
                    "last_name": null
                }
            ],
            "display": "Creator"
        }
    ],
    [
        {
            "key": "id",
            "value": "2c3bcb06-0fc3-4dd7-a024-e6c409de9bd6",
            "display": "Id"
        },
        {
            "key": "title",
            "value": null,
            "display": "Title"
        },
        {
            "key": "alias",
            "value": "bka-india-satyajit-ray-1976",
            "display": "Alias"
        },
        {
            "key": "web_serial_number",
            "value": null,
            "display": "Web serial number"
        },
        {
            "key": "reference_code",
            "value": null,
            "display": "Reference code"
        },
        {
            "key": "web_display",
            "value": null,
            "display": "Web display"
        },
        {
            "key": "date_of_library_publication",
            "value": null,
            "display": "Date of library publication"
        },
        {
            "key": "place_of_publication",
            "value": "New Delhi",
            "display": "Place of publication"
        },
        {
            "key": "histographical_note",
            "value": null,
            "display": "Histographical note"
        },
        {
            "key": "main_historical_note",
            "value": null,
            "display": "Main historical note"
        },
        {
            "key": "binding_status",
            "value": "",
            "display": "Binding status"
        },
        {
            "key": "accession_number",
            "value": "0000091",
            "display": "Accession number"
        },
        {
            "key": "cdt",
            "value": null,
            "display": "CDT"
        },
        {
            "key": "country_of_publication",
            "value": "India",
            "display": "Country of publication"
        },
        {
            "key": "date_of_published_edition_in_library",
            "value": "1976",
            "display": "Date of published edition in library"
        },
        {
            "key": "dates_of_first_edition",
            "value": "",
            "display": "Dates of first edition"
        },
        {
            "key": "isbn_issn_number",
            "value": "",
            "display": "Isbn issn number"
        },
        {
            "key": "language_of_publication",
            "value": "",
            "display": "Language of publication"
        },
        {
            "key": "number_of_pages_in_publication_text",
            "value": null,
            "display": "Number of pages in publication text"
        },
        {
            "key": "personality_film_visually_featured_on_cover",
            "value": null,
            "display": "Personality film visually featured on cover"
        },
        {
            "key": "publisher",
            "value": "Directorate of Advertising and Visual Publicity",
            "display": "Publisher"
        },
        {
            "key": "serial_number",
            "value": null,
            "display": "Serial number"
        },
        {
            "key": "sortable_year_date_of_publication",
            "value": "1976",
            "display": "Sortable year date of publication"
        },
        {
            "key": "sub_title_of_document_book",
            "value": null,
            "display": "Sub title of document book"
        },
        {
            "key": "title_of_document_book",
            "value": null,
            "display": "Title of document book"
        },
        {
            "key": "book_title_of_document",
            "value": "Satyajit Ray",
            "display": "Book title of document"
        },
        {
            "key": "book_sub_title_of_document",
            "value": "",
            "display": "Book sub title of document"
        },
        {
            "key": "volume_issue_number",
            "value": null,
            "display": "Volume issue number"
        },
        {
            "key": "masterlist",
            "value": "satyajit-ray",
            "display": "Masterlist"
        },
        {
            "key": "image",
            "value": ["https://tuliresearchcentre.canto.global/direct/image/cqbefd62792jf46upb1je8g87q/dG9Q2EgurSAsJvMAud4TzWlogKc/m800/800"],
            "display": "Image"
        },
        {
            "key": "document_type",
            "value": "bka",
            "display": "Document type"
        },
        {
            "key": "title_and_type_of_creator",
            "value": "Author",
            "display": "Title and type of creator"
        },
        {
            "key": "number_of_pages_in_publication",
            "value": null,
            "display": "Number of pages in publication"
        },
        {
            "key": "researchCategories",
            "value": [
                {
                    "key": "Economics of Art, Cinema & Culture",
                    "value": "EconArts",
                    "link": "economics-of-art-cinema-culture"
                },
                {
                    "key": "Cinema as a Key Educational Resource",
                    "value": "CinemaE",
                    "link": "cinema-as-a-key-educational-resource"
                }
            ],
            "display": "Research categories"
        },
        {
            "key": "creator",
            "value": [
                {
                    "key": "ministry-of-i-b-government-of-india",
                    "value": "Ministry Of I B Government Of India",
                    "first_name": "Ministry-Of-I-B-Government-Of",
                    "last_name": "India"
                }
            ],
            "display": "Creator"
        }
    ],
    [
        {
            "key": "id",
            "value": "5dd890c3-19c3-49e1-9a7d-6fcbeab88fd8",
            "display": "Id"
        },
        {
            "key": "title",
            "value": null,
            "display": "Title"
        },
        {
            "key": "alias",
            "value": "bka-ray-our-films-their-films-1977",
            "display": "Alias"
        },
        {
            "key": "web_serial_number",
            "value": null,
            "display": "Web serial number"
        },
        {
            "key": "reference_code",
            "value": null,
            "display": "Reference code"
        },
        {
            "key": "web_display",
            "value": null,
            "display": "Web display"
        },
        {
            "key": "date_of_library_publication",
            "value": null,
            "display": "Date of library publication"
        },
        {
            "key": "place_of_publication",
            "value": "Calcutta",
            "display": "Place of publication"
        },
        {
            "key": "histographical_note",
            "value": null,
            "display": "Histographical note"
        },
        {
            "key": "main_historical_note",
            "value": null,
            "display": "Main historical note"
        },
        {
            "key": "binding_status",
            "value": "HB",
            "display": "Binding status"
        },
        {
            "key": "accession_number",
            "value": "0000006.DI",
            "display": "Accession number"
        },
        {
            "key": "cdt",
            "value": null,
            "display": "CDT"
        },
        {
            "key": "country_of_publication",
            "value": "India",
            "display": "Country of publication"
        },
        {
            "key": "date_of_published_edition_in_library",
            "value": "1977",
            "display": "Date of published edition in library"
        },
        {
            "key": "dates_of_first_edition",
            "value": "",
            "display": "Dates of first edition"
        },
        {
            "key": "isbn_issn_number",
            "value": "",
            "display": "Isbn issn number"
        },
        {
            "key": "language_of_publication",
            "value": "",
            "display": "Language of publication"
        },
        {
            "key": "number_of_pages_in_publication_text",
            "value": null,
            "display": "Number of pages in publication text"
        },
        {
            "key": "personality_film_visually_featured_on_cover",
            "value": null,
            "display": "Personality film visually featured on cover"
        },
        {
            "key": "publisher",
            "value": "Orient Longman",
            "display": "Publisher"
        },
        {
            "key": "serial_number",
            "value": null,
            "display": "Serial number"
        },
        {
            "key": "sortable_year_date_of_publication",
            "value": "1977",
            "display": "Sortable year date of publication"
        },
        {
            "key": "sub_title_of_document_book",
            "value": null,
            "display": "Sub title of document book"
        },
        {
            "key": "title_of_document_book",
            "value": null,
            "display": "Title of document book"
        },
        {
            "key": "book_title_of_document",
            "value": "Our Films Their Films",
            "display": "Book title of document"
        },
        {
            "key": "book_sub_title_of_document",
            "value": "",
            "display": "Book sub title of document"
        },
        {
            "key": "volume_issue_number",
            "value": null,
            "display": "Volume issue number"
        },
        {
            "key": "masterlist",
            "value": "satyajit-ray",
            "display": "Masterlist"
        },
        {
            "key": "image",
            "value": ["https://tuliresearchcentre.canto.global/direct/image/o0hsc5cp797qfdroet46epaa4c/HTjOhCDyxswgNuG-FNGsUVrM3No/m800/800"],
            "display": "Image"
        },
        {
            "key": "document_type",
            "value": "bka",
            "display": "Document type"
        },
        {
            "key": "title_and_type_of_creator",
            "value": "Author",
            "display": "Title and type of creator"
        },
        {
            "key": "number_of_pages_in_publication",
            "value": null,
            "display": "Number of pages in publication"
        },
        {
            "key": "researchCategories",
            "value": [
                {
                    "key": "Cinema as a Key Educational Resource",
                    "value": "CinemaE",
                    "link": "cinema-as-a-key-educational-resource"
                },
                {
                    "key": "History & Historiography of Indian Scholarship in the Arts, Social Sciences & Humanities",
                    "value": "Scholarship",
                    "link": "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities"
                }
            ],
            "display": "Research categories"
        },
        {
            "key": "creator",
            "value": [
                {
                    "key": "satyajit-ray",
                    "value": null,
                    "first_name": null,
                    "last_name": null
                }
            ],
            "display": "Creator"
        }
    ],
    [
        {
            "key": "id",
            "value": "1ed5b70e-de22-43c3-a983-ec87cd0b9ab5",
            "display": "Id"
        },
        {
            "key": "title",
            "value": null,
            "display": "Title"
        },
        {
            "key": "alias",
            "value": "bka-gupta-the-cinema-of-satyajit-ray-1980",
            "display": "Alias"
        },
        {
            "key": "web_serial_number",
            "value": null,
            "display": "Web serial number"
        },
        {
            "key": "reference_code",
            "value": null,
            "display": "Reference code"
        },
        {
            "key": "web_display",
            "value": null,
            "display": "Web display"
        },
        {
            "key": "date_of_library_publication",
            "value": null,
            "display": "Date of library publication"
        },
        {
            "key": "place_of_publication",
            "value": "New Delhi",
            "display": "Place of publication"
        },
        {
            "key": "histographical_note",
            "value": null,
            "display": "Histographical note"
        },
        {
            "key": "main_historical_note",
            "value": null,
            "display": "Main historical note"
        },
        {
            "key": "binding_status",
            "value": "HB",
            "display": "Binding status"
        },
        {
            "key": "accession_number",
            "value": "0000009",
            "display": "Accession number"
        },
        {
            "key": "cdt",
            "value": null,
            "display": "CDT"
        },
        {
            "key": "country_of_publication",
            "value": "India",
            "display": "Country of publication"
        },
        {
            "key": "date_of_published_edition_in_library",
            "value": "1980",
            "display": "Date of published edition in library"
        },
        {
            "key": "dates_of_first_edition",
            "value": "",
            "display": "Dates of first edition"
        },
        {
            "key": "isbn_issn_number",
            "value": "0-706910354",
            "display": "Isbn issn number"
        },
        {
            "key": "language_of_publication",
            "value": "",
            "display": "Language of publication"
        },
        {
            "key": "number_of_pages_in_publication_text",
            "value": null,
            "display": "Number of pages in publication text"
        },
        {
            "key": "personality_film_visually_featured_on_cover",
            "value": null,
            "display": "Personality film visually featured on cover"
        },
        {
            "key": "publisher",
            "value": "Vikas",
            "display": "Publisher"
        },
        {
            "key": "serial_number",
            "value": null,
            "display": "Serial number"
        },
        {
            "key": "sortable_year_date_of_publication",
            "value": "1980",
            "display": "Sortable year date of publication"
        },
        {
            "key": "sub_title_of_document_book",
            "value": null,
            "display": "Sub title of document book"
        },
        {
            "key": "title_of_document_book",
            "value": null,
            "display": "Title of document book"
        },
        {
            "key": "book_title_of_document",
            "value": "The Cinema of Satyajit Ray",
            "display": "Book title of document"
        },
        {
            "key": "book_sub_title_of_document",
            "value": "",
            "display": "Book sub title of document"
        },
        {
            "key": "volume_issue_number",
            "value": null,
            "display": "Volume issue number"
        },
        {
            "key": "masterlist",
            "value": "satyajit-ray",
            "display": "Masterlist"
        },
        {
            "key": "image",
            "value": ["https://tuliresearchcentre.canto.global/direct/image/a5etin56bd74r0cdb0tqpqdk6v/ZcvEdaY5AhDJT-VGv-hKEHgzfNY/m800/800"],
            "display": "Image"
        },
        {
            "key": "document_type",
            "value": "bka",
            "display": "Document type"
        },
        {
            "key": "title_and_type_of_creator",
            "value": "Author",
            "display": "Title and type of creator"
        },
        {
            "key": "number_of_pages_in_publication",
            "value": null,
            "display": "Number of pages in publication"
        },
        {
            "key": "researchCategories",
            "value": [
                {
                    "key": "Cinema as a Key Educational Resource",
                    "value": "CinemaE",
                    "link": "cinema-as-a-key-educational-resource"
                },
                {
                    "key": "History & Historiography of Indian Scholarship in the Arts, Social Sciences & Humanities",
                    "value": "Scholarship",
                    "link": "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities"
                }
            ],
            "display": "Research categories"
        },
        {
            "key": "creator",
            "value": [
                {
                    "key": "chidananda-das-gupta",
                    "value": "Chidananda Das Gupta",
                    "first_name": "Chidananda-Das",
                    "last_name": "Gupta"
                }
            ],
            "display": "Creator"
        }
    ],
    [
        {
            "key": "id",
            "value": "a1b07e54-7941-4b5d-8eac-0ce2bc00cd3a",
            "display": "Id"
        },
        {
            "key": "title",
            "value": null,
            "display": "Title"
        },
        {
            "key": "alias",
            "value": "bka-rangoonwalla-satyajit-rays-art-1980",
            "display": "Alias"
        },
        {
            "key": "web_serial_number",
            "value": null,
            "display": "Web serial number"
        },
        {
            "key": "reference_code",
            "value": null,
            "display": "Reference code"
        },
        {
            "key": "web_display",
            "value": null,
            "display": "Web display"
        },
        {
            "key": "date_of_library_publication",
            "value": null,
            "display": "Date of library publication"
        },
        {
            "key": "place_of_publication",
            "value": "Delhi",
            "display": "Place of publication"
        },
        {
            "key": "histographical_note",
            "value": null,
            "display": "Histographical note"
        },
        {
            "key": "main_historical_note",
            "value": null,
            "display": "Main historical note"
        },
        {
            "key": "binding_status",
            "value": "",
            "display": "Binding status"
        },
        {
            "key": "accession_number",
            "value": "0000055",
            "display": "Accession number"
        },
        {
            "key": "cdt",
            "value": null,
            "display": "CDT"
        },
        {
            "key": "country_of_publication",
            "value": "India",
            "display": "Country of publication"
        },
        {
            "key": "date_of_published_edition_in_library",
            "value": "1980",
            "display": "Date of published edition in library"
        },
        {
            "key": "dates_of_first_edition",
            "value": "",
            "display": "Dates of first edition"
        },
        {
            "key": "isbn_issn_number",
            "value": "086186235X",
            "display": "Isbn issn number"
        },
        {
            "key": "language_of_publication",
            "value": "",
            "display": "Language of publication"
        },
        {
            "key": "number_of_pages_in_publication_text",
            "value": null,
            "display": "Number of pages in publication text"
        },
        {
            "key": "personality_film_visually_featured_on_cover",
            "value": null,
            "display": "Personality film visually featured on cover"
        },
        {
            "key": "publisher",
            "value": "Clarion Books",
            "display": "Publisher"
        },
        {
            "key": "serial_number",
            "value": null,
            "display": "Serial number"
        },
        {
            "key": "sortable_year_date_of_publication",
            "value": "1980",
            "display": "Sortable year date of publication"
        },
        {
            "key": "sub_title_of_document_book",
            "value": null,
            "display": "Sub title of document book"
        },
        {
            "key": "title_of_document_book",
            "value": null,
            "display": "Title of document book"
        },
        {
            "key": "book_title_of_document",
            "value": "Satyajit Ray's Art",
            "display": "Book title of document"
        },
        {
            "key": "book_sub_title_of_document",
            "value": "",
            "display": "Book sub title of document"
        },
        {
            "key": "volume_issue_number",
            "value": null,
            "display": "Volume issue number"
        },
        {
            "key": "masterlist",
            "value": "satyajit-ray",
            "display": "Masterlist"
        },
        {
            "key": "image",
            "value": ["https://tuliresearchcentre.canto.global/direct/image/87tqucfojh6ld56aq6ur3gle1n/jUIWjMmBsZKQwlgwV0nXIZ606-M/m800/800"],
            "display": "Image"
        },
        {
            "key": "document_type",
            "value": "bka",
            "display": "Document type"
        },
        {
            "key": "title_and_type_of_creator",
            "value": "Author",
            "display": "Title and type of creator"
        },
        {
            "key": "number_of_pages_in_publication",
            "value": null,
            "display": "Number of pages in publication"
        },
        {
            "key": "researchCategories",
            "value": [
                {
                    "key": "Cinema as a Key Educational Resource",
                    "value": "CinemaE",
                    "link": "cinema-as-a-key-educational-resource"
                },
                {
                    "key": "History & Historiography of Indian Scholarship in the Arts, Social Sciences & Humanities",
                    "value": "Scholarship",
                    "link": "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities"
                }
            ],
            "display": "Research categories"
        },
        {
            "key": "creator",
            "value": [
                {
                    "key": "firoze-rangoonwalla",
                    "value": "Firoze Rangoonwalla",
                    "first_name": "Firoze",
                    "last_name": "Rangoonwalla"
                }
            ],
            "display": "Creator"
        }
    ],
    [
        {
            "key": "id",
            "value": "7bc7d555-ffcf-4af4-baf7-ba751bddb603",
            "display": "Id"
        },
        {
            "key": "title",
            "value": null,
            "display": "Title"
        },
        {
            "key": "alias",
            "value": "bka-barnouw-indian-film-1980",
            "display": "Alias"
        },
        {
            "key": "web_serial_number",
            "value": null,
            "display": "Web serial number"
        },
        {
            "key": "reference_code",
            "value": null,
            "display": "Reference code"
        },
        {
            "key": "web_display",
            "value": null,
            "display": "Web display"
        },
        {
            "key": "date_of_library_publication",
            "value": null,
            "display": "Date of library publication"
        },
        {
            "key": "place_of_publication",
            "value": "New York",
            "display": "Place of publication"
        },
        {
            "key": "histographical_note",
            "value": null,
            "display": "Histographical note"
        },
        {
            "key": "main_historical_note",
            "value": null,
            "display": "Main historical note"
        },
        {
            "key": "binding_status",
            "value": "PB",
            "display": "Binding status"
        },
        {
            "key": "accession_number",
            "value": "0000076",
            "display": "Accession number"
        },
        {
            "key": "cdt",
            "value": null,
            "display": "CDT"
        },
        {
            "key": "country_of_publication",
            "value": "USA",
            "display": "Country of publication"
        },
        {
            "key": "date_of_published_edition_in_library",
            "value": "1980",
            "display": "Date of published edition in library"
        },
        {
            "key": "dates_of_first_edition",
            "value": "",
            "display": "Dates of first edition"
        },
        {
            "key": "isbn_issn_number",
            "value": "0195026829",
            "display": "Isbn issn number"
        },
        {
            "key": "language_of_publication",
            "value": "",
            "display": "Language of publication"
        },
        {
            "key": "number_of_pages_in_publication_text",
            "value": null,
            "display": "Number of pages in publication text"
        },
        {
            "key": "personality_film_visually_featured_on_cover",
            "value": null,
            "display": "Personality film visually featured on cover"
        },
        {
            "key": "publisher",
            "value": "Oxford University",
            "display": "Publisher"
        },
        {
            "key": "serial_number",
            "value": null,
            "display": "Serial number"
        },
        {
            "key": "sortable_year_date_of_publication",
            "value": "1980",
            "display": "Sortable year date of publication"
        },
        {
            "key": "sub_title_of_document_book",
            "value": null,
            "display": "Sub title of document book"
        },
        {
            "key": "title_of_document_book",
            "value": null,
            "display": "Title of document book"
        },
        {
            "key": "book_title_of_document",
            "value": "Indian Film",
            "display": "Book title of document"
        },
        {
            "key": "book_sub_title_of_document",
            "value": "",
            "display": "Book sub title of document"
        },
        {
            "key": "volume_issue_number",
            "value": null,
            "display": "Volume issue number"
        },
        {
            "key": "masterlist",
            "value": "satyajit-ray",
            "display": "Masterlist"
        },
        {
            "key": "image",
            "value": ["https://tuliresearchcentre.canto.global/direct/image/safealga8p61fb6cnppogdn16f/0el1FcMhki39Lfm1kZumxt_IfEY/m800/800"],
            "display": "Image"
        },
        {
            "key": "document_type",
            "value": "bka",
            "display": "Document type"
        },
        {
            "key": "title_and_type_of_creator",
            "value": "Author",
            "display": "Title and type of creator"
        },
        {
            "key": "number_of_pages_in_publication",
            "value": null,
            "display": "Number of pages in publication"
        },
        {
            "key": "researchCategories",
            "value": [
                {
                    "key": "History & Historiography of Indian Scholarship in the Arts, Social Sciences & Humanities",
                    "value": "Scholarship",
                    "link": "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities"
                },
                {
                    "key": "Cinema as a Key Educational Resource",
                    "value": "CinemaE",
                    "link": "cinema-as-a-key-educational-resource"
                }
            ],
            "display": "Research categories"
        },
        {
            "key": "creator",
            "value": [
                {
                    "key": "erik-barnouw",
                    "value": "Erik Barnouw",
                    "first_name": "Erik",
                    "last_name": "Barnouw"
                }
            ],
            "display": "Creator"
        }
    ],
    [
        {
            "key": "id",
            "value": "b5380f99-89b0-4957-91e9-b27755b109b3",
            "display": "Id"
        },
        {
            "key": "title",
            "value": null,
            "display": "Title"
        },
        {
            "key": "alias",
            "value": "bka-gupta-talking-about-films-1981",
            "display": "Alias"
        },
        {
            "key": "web_serial_number",
            "value": null,
            "display": "Web serial number"
        },
        {
            "key": "reference_code",
            "value": null,
            "display": "Reference code"
        },
        {
            "key": "web_display",
            "value": null,
            "display": "Web display"
        },
        {
            "key": "date_of_library_publication",
            "value": null,
            "display": "Date of library publication"
        },
        {
            "key": "place_of_publication",
            "value": "Bombay",
            "display": "Place of publication"
        },
        {
            "key": "histographical_note",
            "value": null,
            "display": "Histographical note"
        },
        {
            "key": "main_historical_note",
            "value": null,
            "display": "Main historical note"
        },
        {
            "key": "binding_status",
            "value": "HB",
            "display": "Binding status"
        },
        {
            "key": "accession_number",
            "value": "0000010",
            "display": "Accession number"
        },
        {
            "key": "cdt",
            "value": null,
            "display": "CDT"
        },
        {
            "key": "country_of_publication",
            "value": "India",
            "display": "Country of publication"
        },
        {
            "key": "date_of_published_edition_in_library",
            "value": "1981",
            "display": "Date of published edition in library"
        },
        {
            "key": "dates_of_first_edition",
            "value": "",
            "display": "Dates of first edition"
        },
        {
            "key": "isbn_issn_number",
            "value": "",
            "display": "Isbn issn number"
        },
        {
            "key": "language_of_publication",
            "value": "",
            "display": "Language of publication"
        },
        {
            "key": "number_of_pages_in_publication_text",
            "value": null,
            "display": "Number of pages in publication text"
        },
        {
            "key": "personality_film_visually_featured_on_cover",
            "value": null,
            "display": "Personality film visually featured on cover"
        },
        {
            "key": "publisher",
            "value": "Orient Longman",
            "display": "Publisher"
        },
        {
            "key": "serial_number",
            "value": null,
            "display": "Serial number"
        },
        {
            "key": "sortable_year_date_of_publication",
            "value": "1981",
            "display": "Sortable year date of publication"
        },
        {
            "key": "sub_title_of_document_book",
            "value": null,
            "display": "Sub title of document book"
        },
        {
            "key": "title_of_document_book",
            "value": null,
            "display": "Title of document book"
        },
        {
            "key": "book_title_of_document",
            "value": "Talking About Films",
            "display": "Book title of document"
        },
        {
            "key": "book_sub_title_of_document",
            "value": "",
            "display": "Book sub title of document"
        },
        {
            "key": "volume_issue_number",
            "value": null,
            "display": "Volume issue number"
        },
        {
            "key": "masterlist",
            "value": "satyajit-ray",
            "display": "Masterlist"
        },
        {
            "key": "image",
            "value": ["https://tuliresearchcentre.canto.global/direct/image/t1d6pn27b11c530hl9r4vjj90r/HWFegcQQp6QdetpSgR38zrINh6Q/m800/800"],
            "display": "Image"
        },
        {
            "key": "document_type",
            "value": "bka",
            "display": "Document type"
        },
        {
            "key": "title_and_type_of_creator",
            "value": "Author",
            "display": "Title and type of creator"
        },
        {
            "key": "number_of_pages_in_publication",
            "value": null,
            "display": "Number of pages in publication"
        },
        {
            "key": "researchCategories",
            "value": [
                {
                    "key": "Cinema as a Key Educational Resource",
                    "value": "CinemaE",
                    "link": "cinema-as-a-key-educational-resource"
                },
                {
                    "key": "History & Historiography of Indian Scholarship in the Arts, Social Sciences & Humanities",
                    "value": "Scholarship",
                    "link": "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities"
                }
            ],
            "display": "Research categories"
        },
        {
            "key": "creator",
            "value": [
                {
                    "key": "chidananda-das-gupta",
                    "value": "Chidananda Das Gupta",
                    "first_name": "Chidananda-Das",
                    "last_name": "Gupta"
                }
            ],
            "display": "Creator"
        }
    ],
    [
        {
            "key": "id",
            "value": "6e8551a6-16b8-4885-b262-6c020274e45c",
            "display": "Id"
        },
        {
            "key": "title",
            "value": null,
            "display": "Title"
        },
        {
            "key": "alias",
            "value": "bka-ray-film-india-1981",
            "display": "Alias"
        },
        {
            "key": "web_serial_number",
            "value": null,
            "display": "Web serial number"
        },
        {
            "key": "reference_code",
            "value": null,
            "display": "Reference code"
        },
        {
            "key": "web_display",
            "value": null,
            "display": "Web display"
        },
        {
            "key": "date_of_library_publication",
            "value": null,
            "display": "Date of library publication"
        },
        {
            "key": "place_of_publication",
            "value": "New Delhi",
            "display": "Place of publication"
        },
        {
            "key": "histographical_note",
            "value": null,
            "display": "Histographical note"
        },
        {
            "key": "main_historical_note",
            "value": null,
            "display": "Main historical note"
        },
        {
            "key": "binding_status",
            "value": "PB",
            "display": "Binding status"
        },
        {
            "key": "accession_number",
            "value": "0000011",
            "display": "Accession number"
        },
        {
            "key": "cdt",
            "value": null,
            "display": "CDT"
        },
        {
            "key": "country_of_publication",
            "value": "India",
            "display": "Country of publication"
        },
        {
            "key": "date_of_published_edition_in_library",
            "value": "1981",
            "display": "Date of published edition in library"
        },
        {
            "key": "dates_of_first_edition",
            "value": "",
            "display": "Dates of first edition"
        },
        {
            "key": "isbn_issn_number",
            "value": "",
            "display": "Isbn issn number"
        },
        {
            "key": "language_of_publication",
            "value": "",
            "display": "Language of publication"
        },
        {
            "key": "number_of_pages_in_publication_text",
            "value": null,
            "display": "Number of pages in publication text"
        },
        {
            "key": "personality_film_visually_featured_on_cover",
            "value": null,
            "display": "Personality film visually featured on cover"
        },
        {
            "key": "publisher",
            "value": "Directorate of Film Festivals Ministry of Information and Broadcasting",
            "display": "Publisher"
        },
        {
            "key": "serial_number",
            "value": null,
            "display": "Serial number"
        },
        {
            "key": "sortable_year_date_of_publication",
            "value": "1981",
            "display": "Sortable year date of publication"
        },
        {
            "key": "sub_title_of_document_book",
            "value": null,
            "display": "Sub title of document book"
        },
        {
            "key": "title_of_document_book",
            "value": null,
            "display": "Title of document book"
        },
        {
            "key": "book_title_of_document",
            "value": "Film India",
            "display": "Book title of document"
        },
        {
            "key": "book_sub_title_of_document",
            "value": "An Anthology of Statements on Ray and by Ray",
            "display": "Book sub title of document"
        },
        {
            "key": "volume_issue_number",
            "value": null,
            "display": "Volume issue number"
        },
        {
            "key": "masterlist",
            "value": "satyajit-ray",
            "display": "Masterlist"
        },
        {
            "key": "image",
            "value": ["https://tuliresearchcentre.canto.global/direct/image/250kvopos911f3s6bnt2ane719/yeUiADMsnMRpq0AFrb-ktYzFXyk/m800/800"],
            "display": "Image"
        },
        {
            "key": "document_type",
            "value": "bka",
            "display": "Document type"
        },
        {
            "key": "title_and_type_of_creator",
            "value": "Author",
            "display": "Title and type of creator"
        },
        {
            "key": "number_of_pages_in_publication",
            "value": null,
            "display": "Number of pages in publication"
        },
        {
            "key": "researchCategories",
            "value": [
                {
                    "key": "Cinema as a Key Educational Resource",
                    "value": "CinemaE",
                    "link": "cinema-as-a-key-educational-resource"
                }
            ],
            "display": "Research categories"
        },
        {
            "key": "creator",
            "value": [
                {
                    "key": "satyajit-ray",
                    "value": null,
                    "first_name": null,
                    "last_name": null
                }
            ],
            "display": "Creator"
        }
    ],
    [
        {
            "key": "id",
            "value": "cdf9249e-cc39-4c9a-b63a-ba6f6c93e4f9",
            "display": "Id"
        },
        {
            "key": "title",
            "value": null,
            "display": "Title"
        },
        {
            "key": "alias",
            "value": "bka-ray-aro-baro-1981",
            "display": "Alias"
        },
        {
            "key": "web_serial_number",
            "value": null,
            "display": "Web serial number"
        },
        {
            "key": "reference_code",
            "value": null,
            "display": "Reference code"
        },
        {
            "key": "web_display",
            "value": null,
            "display": "Web display"
        },
        {
            "key": "date_of_library_publication",
            "value": null,
            "display": "Date of library publication"
        },
        {
            "key": "place_of_publication",
            "value": "Calcutta",
            "display": "Place of publication"
        },
        {
            "key": "histographical_note",
            "value": null,
            "display": "Histographical note"
        },
        {
            "key": "main_historical_note",
            "value": null,
            "display": "Main historical note"
        },
        {
            "key": "binding_status",
            "value": "",
            "display": "Binding status"
        },
        {
            "key": "accession_number",
            "value": "0000012",
            "display": "Accession number"
        },
        {
            "key": "cdt",
            "value": null,
            "display": "CDT"
        },
        {
            "key": "country_of_publication",
            "value": "India",
            "display": "Country of publication"
        },
        {
            "key": "date_of_published_edition_in_library",
            "value": "",
            "display": "Date of published edition in library"
        },
        {
            "key": "dates_of_first_edition",
            "value": "",
            "display": "Dates of first edition"
        },
        {
            "key": "isbn_issn_number",
            "value": "",
            "display": "Isbn issn number"
        },
        {
            "key": "language_of_publication",
            "value": "",
            "display": "Language of publication"
        },
        {
            "key": "number_of_pages_in_publication_text",
            "value": null,
            "display": "Number of pages in publication text"
        },
        {
            "key": "personality_film_visually_featured_on_cover",
            "value": null,
            "display": "Personality film visually featured on cover"
        },
        {
            "key": "publisher",
            "value": "Ananda",
            "display": "Publisher"
        },
        {
            "key": "serial_number",
            "value": null,
            "display": "Serial number"
        },
        {
            "key": "sortable_year_date_of_publication",
            "value": "1981",
            "display": "Sortable year date of publication"
        },
        {
            "key": "sub_title_of_document_book",
            "value": null,
            "display": "Sub title of document book"
        },
        {
            "key": "title_of_document_book",
            "value": null,
            "display": "Title of document book"
        },
        {
            "key": "book_title_of_document",
            "value": "Aro Baro",
            "display": "Book title of document"
        },
        {
            "key": "book_sub_title_of_document",
            "value": "",
            "display": "Book sub title of document"
        },
        {
            "key": "volume_issue_number",
            "value": null,
            "display": "Volume issue number"
        },
        {
            "key": "masterlist",
            "value": "satyajit-ray",
            "display": "Masterlist"
        },
        {
            "key": "image",
            "value": ["https://tuliresearchcentre.canto.global/direct/image/7dglfkb47t329dn0ndsf0uj73h/ISmECyG2TcOTVekaWowtr6BsgfQ/m800/800"],
            "display": "Image"
        },
        {
            "key": "document_type",
            "value": "bka",
            "display": "Document type"
        },
        {
            "key": "title_and_type_of_creator",
            "value": "Author",
            "display": "Title and type of creator"
        },
        {
            "key": "number_of_pages_in_publication",
            "value": null,
            "display": "Number of pages in publication"
        },
        {
            "key": "researchCategories",
            "value": [
                {
                    "key": "Cinema as a Key Educational Resource",
                    "value": "CinemaE",
                    "link": "cinema-as-a-key-educational-resource"
                }
            ],
            "display": "Research categories"
        },
        {
            "key": "creator",
            "value": [
                {
                    "key": "satyajit-ray",
                    "value": null,
                    "first_name": null,
                    "last_name": null
                }
            ],
            "display": "Creator"
        }
    ],
    [
        {
            "key": "id",
            "value": "37a0fe21-0fb9-456a-ad85-bb2aa3bf15d6",
            "display": "Id"
        },
        {
            "key": "title",
            "value": null,
            "display": "Title"
        },
        {
            "key": "alias",
            "value": "bka-bahadur-satyajit-rays-pather-panchali-1981",
            "display": "Alias"
        },
        {
            "key": "web_serial_number",
            "value": null,
            "display": "Web serial number"
        },
        {
            "key": "reference_code",
            "value": null,
            "display": "Reference code"
        },
        {
            "key": "web_display",
            "value": null,
            "display": "Web display"
        },
        {
            "key": "date_of_library_publication",
            "value": null,
            "display": "Date of library publication"
        },
        {
            "key": "place_of_publication",
            "value": "New Delhi",
            "display": "Place of publication"
        },
        {
            "key": "histographical_note",
            "value": null,
            "display": "Histographical note"
        },
        {
            "key": "main_historical_note",
            "value": null,
            "display": "Main historical note"
        },
        {
            "key": "binding_status",
            "value": "PB",
            "display": "Binding status"
        },
        {
            "key": "accession_number",
            "value": "0000050",
            "display": "Accession number"
        },
        {
            "key": "cdt",
            "value": null,
            "display": "CDT"
        },
        {
            "key": "country_of_publication",
            "value": "India",
            "display": "Country of publication"
        },
        {
            "key": "date_of_published_edition_in_library",
            "value": "1981",
            "display": "Date of published edition in library"
        },
        {
            "key": "dates_of_first_edition",
            "value": "",
            "display": "Dates of first edition"
        },
        {
            "key": "isbn_issn_number",
            "value": "",
            "display": "Isbn issn number"
        },
        {
            "key": "language_of_publication",
            "value": "",
            "display": "Language of publication"
        },
        {
            "key": "number_of_pages_in_publication_text",
            "value": null,
            "display": "Number of pages in publication text"
        },
        {
            "key": "personality_film_visually_featured_on_cover",
            "value": null,
            "display": "Personality film visually featured on cover"
        },
        {
            "key": "publisher",
            "value": "National Film Archive of India",
            "display": "Publisher"
        },
        {
            "key": "serial_number",
            "value": null,
            "display": "Serial number"
        },
        {
            "key": "sortable_year_date_of_publication",
            "value": "1981",
            "display": "Sortable year date of publication"
        },
        {
            "key": "sub_title_of_document_book",
            "value": null,
            "display": "Sub title of document book"
        },
        {
            "key": "title_of_document_book",
            "value": null,
            "display": "Title of document book"
        },
        {
            "key": "book_title_of_document",
            "value": "Satyajit Ray's Pather Panchali",
            "display": "Book title of document"
        },
        {
            "key": "book_sub_title_of_document",
            "value": "Screenplay with Analytical Notes",
            "display": "Book sub title of document"
        },
        {
            "key": "volume_issue_number",
            "value": null,
            "display": "Volume issue number"
        },
        {
            "key": "masterlist",
            "value": "satyajit-ray",
            "display": "Masterlist"
        },
        {
            "key": "image",
            "value": ["https://tuliresearchcentre.canto.global/direct/image/isf99hp46d3kreolqlvlaaca0p/aBrRcLDENyYUeqj-TvoyI8aHwlQ/m800/800"],
            "display": "Image"
        },
        {
            "key": "document_type",
            "value": "bka",
            "display": "Document type"
        },
        {
            "key": "title_and_type_of_creator",
            "value": "Author",
            "display": "Title and type of creator"
        },
        {
            "key": "number_of_pages_in_publication",
            "value": null,
            "display": "Number of pages in publication"
        },
        {
            "key": "researchCategories",
            "value": [
                {
                    "key": "Cinema as a Key Educational Resource",
                    "value": "CinemaE",
                    "link": "cinema-as-a-key-educational-resource"
                },
                {
                    "key": "History & Historiography of Indian Scholarship in the Arts, Social Sciences & Humanities",
                    "value": "Scholarship",
                    "link": "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities"
                }
            ],
            "display": "Research categories"
        },
        {
            "key": "creator",
            "value": [
                {
                    "key": "satish-bahadur",
                    "value": "Satish Bahadur",
                    "first_name": "Satish",
                    "last_name": "Bahadur"
                }
            ],
            "display": "Creator"
        }
    ],
    [
        {
            "key": "id",
            "value": "e1110d44-5ac1-447a-b6c6-dec22dceba9f",
            "display": "Id"
        },
        {
            "key": "title",
            "value": null,
            "display": "Title"
        },
        {
            "key": "alias",
            "value": "bka-micciollo-satyajit-ray-1981",
            "display": "Alias"
        },
        {
            "key": "web_serial_number",
            "value": null,
            "display": "Web serial number"
        },
        {
            "key": "reference_code",
            "value": null,
            "display": "Reference code"
        },
        {
            "key": "web_display",
            "value": null,
            "display": "Web display"
        },
        {
            "key": "date_of_library_publication",
            "value": null,
            "display": "Date of library publication"
        },
        {
            "key": "place_of_publication",
            "value": "Lausanne",
            "display": "Place of publication"
        },
        {
            "key": "histographical_note",
            "value": null,
            "display": "Histographical note"
        },
        {
            "key": "main_historical_note",
            "value": null,
            "display": "Main historical note"
        },
        {
            "key": "binding_status",
            "value": "",
            "display": "Binding status"
        },
        {
            "key": "accession_number",
            "value": "0000085",
            "display": "Accession number"
        },
        {
            "key": "cdt",
            "value": null,
            "display": "CDT"
        },
        {
            "key": "country_of_publication",
            "value": "France",
            "display": "Country of publication"
        },
        {
            "key": "date_of_published_edition_in_library",
            "value": "1981",
            "display": "Date of published edition in library"
        },
        {
            "key": "dates_of_first_edition",
            "value": "",
            "display": "Dates of first edition"
        },
        {
            "key": "isbn_issn_number",
            "value": "",
            "display": "Isbn issn number"
        },
        {
            "key": "language_of_publication",
            "value": "",
            "display": "Language of publication"
        },
        {
            "key": "number_of_pages_in_publication_text",
            "value": null,
            "display": "Number of pages in publication text"
        },
        {
            "key": "personality_film_visually_featured_on_cover",
            "value": null,
            "display": "Personality film visually featured on cover"
        },
        {
            "key": "publisher",
            "value": "L'Age D'Homme",
            "display": "Publisher"
        },
        {
            "key": "serial_number",
            "value": null,
            "display": "Serial number"
        },
        {
            "key": "sortable_year_date_of_publication",
            "value": "1981",
            "display": "Sortable year date of publication"
        },
        {
            "key": "sub_title_of_document_book",
            "value": null,
            "display": "Sub title of document book"
        },
        {
            "key": "title_of_document_book",
            "value": null,
            "display": "Title of document book"
        },
        {
            "key": "book_title_of_document",
            "value": "Satyajit Ray",
            "display": "Book title of document"
        },
        {
            "key": "book_sub_title_of_document",
            "value": "",
            "display": "Book sub title of document"
        },
        {
            "key": "volume_issue_number",
            "value": null,
            "display": "Volume issue number"
        },
        {
            "key": "masterlist",
            "value": "satyajit-ray",
            "display": "Masterlist"
        },
        {
            "key": "image",
            "value": ["https://tuliresearchcentre.canto.global/direct/image/2fdnva763t7qn7d62pqcaeh05e/mHhNIzMrunW0qXD9GT2kgewSRCM/m800/800"],
            "display": "Image"
        },
        {
            "key": "document_type",
            "value": "bka",
            "display": "Document type"
        },
        {
            "key": "title_and_type_of_creator",
            "value": "Author",
            "display": "Title and type of creator"
        },
        {
            "key": "number_of_pages_in_publication",
            "value": null,
            "display": "Number of pages in publication"
        },
        {
            "key": "researchCategories",
            "value": [
                {
                    "key": "Cinema as a Key Educational Resource",
                    "value": "CinemaE",
                    "link": "cinema-as-a-key-educational-resource"
                }
            ],
            "display": "Research categories"
        },
        {
            "key": "creator",
            "value": [
                {
                    "key": "henri-micciollo",
                    "value": "Henri Micciollo",
                    "first_name": "Henri",
                    "last_name": "Micciollo"
                }
            ],
            "display": "Creator"
        }
    ],
    [
        {
            "key": "id",
            "value": "496fe4f4-aeb1-49f1-ba70-b258b0117554",
            "display": "Id"
        },
        {
            "key": "title",
            "value": null,
            "display": "Title"
        },
        {
            "key": "alias",
            "value": "bke-banerjee-new-indian-cinema-1982",
            "display": "Alias"
        },
        {
            "key": "web_serial_number",
            "value": null,
            "display": "Web serial number"
        },
        {
            "key": "reference_code",
            "value": null,
            "display": "Reference code"
        },
        {
            "key": "web_display",
            "value": null,
            "display": "Web display"
        },
        {
            "key": "date_of_library_publication",
            "value": null,
            "display": "Date of library publication"
        },
        {
            "key": "place_of_publication",
            "value": "New Delhi",
            "display": "Place of publication"
        },
        {
            "key": "histographical_note",
            "value": null,
            "display": "Histographical note"
        },
        {
            "key": "main_historical_note",
            "value": null,
            "display": "Main historical note"
        },
        {
            "key": "binding_status",
            "value": "PB",
            "display": "Binding status"
        },
        {
            "key": "accession_number",
            "value": "0000089",
            "display": "Accession number"
        },
        {
            "key": "cdt",
            "value": null,
            "display": "CDT"
        },
        {
            "key": "country_of_publication",
            "value": "India",
            "display": "Country of publication"
        },
        {
            "key": "date_of_published_edition_in_library",
            "value": "1982",
            "display": "Date of published edition in library"
        },
        {
            "key": "dates_of_first_edition",
            "value": "",
            "display": "Dates of first edition"
        },
        {
            "key": "isbn_issn_number",
            "value": "",
            "display": "Isbn issn number"
        },
        {
            "key": "language_of_publication",
            "value": "",
            "display": "Language of publication"
        },
        {
            "key": "number_of_pages_in_publication_text",
            "value": null,
            "display": "Number of pages in publication text"
        },
        {
            "key": "personality_film_visually_featured_on_cover",
            "value": null,
            "display": "Personality film visually featured on cover"
        },
        {
            "key": "publisher",
            "value": "National Film Development Corporation",
            "display": "Publisher"
        },
        {
            "key": "serial_number",
            "value": null,
            "display": "Serial number"
        },
        {
            "key": "sortable_year_date_of_publication",
            "value": "1982",
            "display": "Sortable year date of publication"
        },
        {
            "key": "sub_title_of_document_book",
            "value": null,
            "display": "Sub title of document book"
        },
        {
            "key": "title_of_document_book",
            "value": null,
            "display": "Title of document book"
        },
        {
            "key": "book_title_of_document",
            "value": "New Indian Cinema",
            "display": "Book title of document"
        },
        {
            "key": "book_sub_title_of_document",
            "value": "",
            "display": "Book sub title of document"
        },
        {
            "key": "volume_issue_number",
            "value": null,
            "display": "Volume issue number"
        },
        {
            "key": "masterlist",
            "value": "satyajit-ray",
            "display": "Masterlist"
        },
        {
            "key": "image",
            "value": ["https://tuliresearchcentre.canto.global/direct/image/pbv4hejvdt5hd3vn13l0jjpe01/6E0rFz82Bte2vqz_u2c-Dn4rkhE/m800/800"],
            "display": "Image"
        },
        {
            "key": "document_type",
            "value": "bke",
            "display": "Document type"
        },
        {
            "key": "title_and_type_of_creator",
            "value": "Editor",
            "display": "Title and type of creator"
        },
        {
            "key": "number_of_pages_in_publication",
            "value": null,
            "display": "Number of pages in publication"
        },
        {
            "key": "researchCategories",
            "value": [
                {
                    "key": "Cinema as a Key Educational Resource",
                    "value": "CinemaE",
                    "link": "cinema-as-a-key-educational-resource"
                },
                {
                    "key": "History & Historiography of Indian Scholarship in the Arts, Social Sciences & Humanities",
                    "value": "Scholarship",
                    "link": "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities"
                }
            ],
            "display": "Research categories"
        },
        {
            "key": "creator",
            "value": [
                {
                    "key": "shampa-banerjee",
                    "value": "Shampa Banerjee",
                    "first_name": "Shampa",
                    "last_name": "Banerjee"
                }
            ],
            "display": "Creator"
        }
    ],
    [
        {
            "key": "id",
            "value": "75c74df0-e869-4953-b5f1-9b10695f3290",
            "display": "Id"
        },
        {
            "key": "title",
            "value": null,
            "display": "Title"
        },
        {
            "key": "alias",
            "value": "bka-ray-shatranj-ke-khilari-1982",
            "display": "Alias"
        },
        {
            "key": "web_serial_number",
            "value": null,
            "display": "Web serial number"
        },
        {
            "key": "reference_code",
            "value": null,
            "display": "Reference code"
        },
        {
            "key": "web_display",
            "value": null,
            "display": "Web display"
        },
        {
            "key": "date_of_library_publication",
            "value": null,
            "display": "Date of library publication"
        },
        {
            "key": "place_of_publication",
            "value": "Calcutta",
            "display": "Place of publication"
        },
        {
            "key": "histographical_note",
            "value": null,
            "display": "Histographical note"
        },
        {
            "key": "main_historical_note",
            "value": null,
            "display": "Main historical note"
        },
        {
            "key": "binding_status",
            "value": "",
            "display": "Binding status"
        },
        {
            "key": "accession_number",
            "value": "0000015",
            "display": "Accession number"
        },
        {
            "key": "cdt",
            "value": null,
            "display": "CDT"
        },
        {
            "key": "country_of_publication",
            "value": "India",
            "display": "Country of publication"
        },
        {
            "key": "date_of_published_edition_in_library",
            "value": "",
            "display": "Date of published edition in library"
        },
        {
            "key": "dates_of_first_edition",
            "value": "",
            "display": "Dates of first edition"
        },
        {
            "key": "isbn_issn_number",
            "value": "",
            "display": "Isbn issn number"
        },
        {
            "key": "language_of_publication",
            "value": "",
            "display": "Language of publication"
        },
        {
            "key": "number_of_pages_in_publication_text",
            "value": null,
            "display": "Number of pages in publication text"
        },
        {
            "key": "personality_film_visually_featured_on_cover",
            "value": null,
            "display": "Personality film visually featured on cover"
        },
        {
            "key": "publisher",
            "value": "Ekshan",
            "display": "Publisher"
        },
        {
            "key": "serial_number",
            "value": null,
            "display": "Serial number"
        },
        {
            "key": "sortable_year_date_of_publication",
            "value": "1982",
            "display": "Sortable year date of publication"
        },
        {
            "key": "sub_title_of_document_book",
            "value": null,
            "display": "Sub title of document book"
        },
        {
            "key": "title_of_document_book",
            "value": null,
            "display": "Title of document book"
        },
        {
            "key": "book_title_of_document",
            "value": "Shatranj Ke Khilari",
            "display": "Book title of document"
        },
        {
            "key": "book_sub_title_of_document",
            "value": "The Chess Players",
            "display": "Book sub title of document"
        },
        {
            "key": "volume_issue_number",
            "value": null,
            "display": "Volume issue number"
        },
        {
            "key": "masterlist",
            "value": "satyajit-ray",
            "display": "Masterlist"
        },
        {
            "key": "image",
            "value": ["https://tuliresearchcentre.canto.global/direct/image/9ntctdgqsp3hrd3gn7mpalbs0i/wuYtgcnOUjTpRKui-DI8E1x9Hu8/m800/800"],
            "display": "Image"
        },
        {
            "key": "document_type",
            "value": "bka",
            "display": "Document type"
        },
        {
            "key": "title_and_type_of_creator",
            "value": "Author",
            "display": "Title and type of creator"
        },
        {
            "key": "number_of_pages_in_publication",
            "value": null,
            "display": "Number of pages in publication"
        },
        {
            "key": "researchCategories",
            "value": [
                {
                    "key": "Cinema as a Key Educational Resource",
                    "value": "CinemaE",
                    "link": "cinema-as-a-key-educational-resource"
                }
            ],
            "display": "Research categories"
        },
        {
            "key": "creator",
            "value": [
                {
                    "key": "satyajit-ray",
                    "value": null,
                    "first_name": null,
                    "last_name": null
                }
            ],
            "display": "Creator"
        }
    ],
    [
        {
            "key": "id",
            "value": "f58e05c5-9a24-4774-958a-b83b6a7ddfc1",
            "display": "Id"
        },
        {
            "key": "title",
            "value": null,
            "display": "Title"
        },
        {
            "key": "alias",
            "value": "bke-bazaz-festival-of-india-1982",
            "display": "Alias"
        },
        {
            "key": "web_serial_number",
            "value": null,
            "display": "Web serial number"
        },
        {
            "key": "reference_code",
            "value": null,
            "display": "Reference code"
        },
        {
            "key": "web_display",
            "value": null,
            "display": "Web display"
        },
        {
            "key": "date_of_library_publication",
            "value": null,
            "display": "Date of library publication"
        },
        {
            "key": "place_of_publication",
            "value": "",
            "display": "Place of publication"
        },
        {
            "key": "histographical_note",
            "value": null,
            "display": "Histographical note"
        },
        {
            "key": "main_historical_note",
            "value": null,
            "display": "Main historical note"
        },
        {
            "key": "binding_status",
            "value": "PB",
            "display": "Binding status"
        },
        {
            "key": "accession_number",
            "value": "0000014",
            "display": "Accession number"
        },
        {
            "key": "cdt",
            "value": null,
            "display": "CDT"
        },
        {
            "key": "country_of_publication",
            "value": "UK",
            "display": "Country of publication"
        },
        {
            "key": "date_of_published_edition_in_library",
            "value": "1982",
            "display": "Date of published edition in library"
        },
        {
            "key": "dates_of_first_edition",
            "value": "",
            "display": "Dates of first edition"
        },
        {
            "key": "isbn_issn_number",
            "value": "",
            "display": "Isbn issn number"
        },
        {
            "key": "language_of_publication",
            "value": "",
            "display": "Language of publication"
        },
        {
            "key": "number_of_pages_in_publication_text",
            "value": null,
            "display": "Number of pages in publication text"
        },
        {
            "key": "personality_film_visually_featured_on_cover",
            "value": null,
            "display": "Personality film visually featured on cover"
        },
        {
            "key": "publisher",
            "value": "Delhi Diary",
            "display": "Publisher"
        },
        {
            "key": "serial_number",
            "value": null,
            "display": "Serial number"
        },
        {
            "key": "sortable_year_date_of_publication",
            "value": "1982",
            "display": "Sortable year date of publication"
        },
        {
            "key": "sub_title_of_document_book",
            "value": null,
            "display": "Sub title of document book"
        },
        {
            "key": "title_of_document_book",
            "value": null,
            "display": "Title of document book"
        },
        {
            "key": "book_title_of_document",
            "value": "Festival of India",
            "display": "Book title of document"
        },
        {
            "key": "book_sub_title_of_document",
            "value": "",
            "display": "Book sub title of document"
        },
        {
            "key": "volume_issue_number",
            "value": null,
            "display": "Volume issue number"
        },
        {
            "key": "masterlist",
            "value": "satyajit-ray",
            "display": "Masterlist"
        },
        {
            "key": "image",
            "value": ["https://tuliresearchcentre.canto.global/direct/image/g30lbekt8p007a3dk7fjc8dt3l/txdcf0vElyzK-S90XZgbc9lrICM/m800/800"],
            "display": "Image"
        },
        {
            "key": "document_type",
            "value": "bke",
            "display": "Document type"
        },
        {
            "key": "title_and_type_of_creator",
            "value": "Editor",
            "display": "Title and type of creator"
        },
        {
            "key": "number_of_pages_in_publication",
            "value": null,
            "display": "Number of pages in publication"
        },
        {
            "key": "researchCategories",
            "value": [
                {
                    "key": "History & Historiography of Indian Scholarship in the Arts, Social Sciences & Humanities",
                    "value": "Scholarship",
                    "link": "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities"
                },
                {
                    "key": "Cinema as a Key Educational Resource",
                    "value": "CinemaE",
                    "link": "cinema-as-a-key-educational-resource"
                }
            ],
            "display": "Research categories"
        },
        {
            "key": "creator",
            "value": [
                {
                    "key": "mohini-j-bazaz",
                    "value": "Mohini J Bazaz",
                    "first_name": "Mohini-J",
                    "last_name": "Bazaz"
                }
            ],
            "display": "Creator"
        }
    ],
    [
        {
            "key": "id",
            "value": "7823b501-12c1-4441-96d1-1eef3ca5ae49",
            "display": "Id"
        },
        {
            "key": "title",
            "value": null,
            "display": "Title"
        },
        {
            "key": "alias",
            "value": "bka-ray-phatik-chand-1983",
            "display": "Alias"
        },
        {
            "key": "web_serial_number",
            "value": null,
            "display": "Web serial number"
        },
        {
            "key": "reference_code",
            "value": null,
            "display": "Reference code"
        },
        {
            "key": "web_display",
            "value": null,
            "display": "Web display"
        },
        {
            "key": "date_of_library_publication",
            "value": null,
            "display": "Date of library publication"
        },
        {
            "key": "place_of_publication",
            "value": "Delhi",
            "display": "Place of publication"
        },
        {
            "key": "histographical_note",
            "value": null,
            "display": "Histographical note"
        },
        {
            "key": "main_historical_note",
            "value": null,
            "display": "Main historical note"
        },
        {
            "key": "binding_status",
            "value": "PB",
            "display": "Binding status"
        },
        {
            "key": "accession_number",
            "value": "0000016",
            "display": "Accession number"
        },
        {
            "key": "cdt",
            "value": null,
            "display": "CDT"
        },
        {
            "key": "country_of_publication",
            "value": "India",
            "display": "Country of publication"
        },
        {
            "key": "date_of_published_edition_in_library",
            "value": "1983",
            "display": "Date of published edition in library"
        },
        {
            "key": "dates_of_first_edition",
            "value": "",
            "display": "Dates of first edition"
        },
        {
            "key": "isbn_issn_number",
            "value": "",
            "display": "Isbn issn number"
        },
        {
            "key": "language_of_publication",
            "value": "",
            "display": "Language of publication"
        },
        {
            "key": "number_of_pages_in_publication_text",
            "value": null,
            "display": "Number of pages in publication text"
        },
        {
            "key": "personality_film_visually_featured_on_cover",
            "value": null,
            "display": "Personality film visually featured on cover"
        },
        {
            "key": "publisher",
            "value": "Orient Paperback",
            "display": "Publisher"
        },
        {
            "key": "serial_number",
            "value": null,
            "display": "Serial number"
        },
        {
            "key": "sortable_year_date_of_publication",
            "value": "1983",
            "display": "Sortable year date of publication"
        },
        {
            "key": "sub_title_of_document_book",
            "value": null,
            "display": "Sub title of document book"
        },
        {
            "key": "title_of_document_book",
            "value": null,
            "display": "Title of document book"
        },
        {
            "key": "book_title_of_document",
            "value": "Phatik Chand",
            "display": "Book title of document"
        },
        {
            "key": "book_sub_title_of_document",
            "value": "",
            "display": "Book sub title of document"
        },
        {
            "key": "volume_issue_number",
            "value": null,
            "display": "Volume issue number"
        },
        {
            "key": "masterlist",
            "value": "satyajit-ray",
            "display": "Masterlist"
        },
        {
            "key": "image",
            "value": ["https://tuliresearchcentre.canto.global/direct/image/dqu18l0e3t3e3e5kepdv5eff1s/6ZI1oavu_22gONBCmDwtjT92GDo/m800/800"],
            "display": "Image"
        },
        {
            "key": "document_type",
            "value": "bka",
            "display": "Document type"
        },
        {
            "key": "title_and_type_of_creator",
            "value": "Author",
            "display": "Title and type of creator"
        },
        {
            "key": "number_of_pages_in_publication",
            "value": null,
            "display": "Number of pages in publication"
        },
        {
            "key": "researchCategories",
            "value": [
                {
                    "key": "Cinema as a Key Educational Resource",
                    "value": "CinemaE",
                    "link": "cinema-as-a-key-educational-resource"
                }
            ],
            "display": "Research categories"
        },
        {
            "key": "creator",
            "value": [
                {
                    "key": "satyajit-ray",
                    "value": null,
                    "first_name": null,
                    "last_name": null
                }
            ],
            "display": "Creator"
        }
    ],
    [
        {
            "key": "id",
            "value": "16e9fa12-f1a6-4338-8000-defa9cf789b3",
            "display": "Id"
        },
        {
            "key": "title",
            "value": null,
            "display": "Title"
        },
        {
            "key": "alias",
            "value": "bke-mukhopadhyay-pather-panchali-1984",
            "display": "Alias"
        },
        {
            "key": "web_serial_number",
            "value": null,
            "display": "Web serial number"
        },
        {
            "key": "reference_code",
            "value": null,
            "display": "Reference code"
        },
        {
            "key": "web_display",
            "value": null,
            "display": "Web display"
        },
        {
            "key": "date_of_library_publication",
            "value": null,
            "display": "Date of library publication"
        },
        {
            "key": "place_of_publication",
            "value": "Calcutta",
            "display": "Place of publication"
        },
        {
            "key": "histographical_note",
            "value": null,
            "display": "Histographical note"
        },
        {
            "key": "main_historical_note",
            "value": null,
            "display": "Main historical note"
        },
        {
            "key": "binding_status",
            "value": "HB",
            "display": "Binding status"
        },
        {
            "key": "accession_number",
            "value": "0000051",
            "display": "Accession number"
        },
        {
            "key": "cdt",
            "value": null,
            "display": "CDT"
        },
        {
            "key": "country_of_publication",
            "value": "India",
            "display": "Country of publication"
        },
        {
            "key": "date_of_published_edition_in_library",
            "value": "1984",
            "display": "Date of published edition in library"
        },
        {
            "key": "dates_of_first_edition",
            "value": "",
            "display": "Dates of first edition"
        },
        {
            "key": "isbn_issn_number",
            "value": "",
            "display": "Isbn issn number"
        },
        {
            "key": "language_of_publication",
            "value": "",
            "display": "Language of publication"
        },
        {
            "key": "number_of_pages_in_publication_text",
            "value": null,
            "display": "Number of pages in publication text"
        },
        {
            "key": "personality_film_visually_featured_on_cover",
            "value": null,
            "display": "Personality film visually featured on cover"
        },
        {
            "key": "publisher",
            "value": "Cine Central",
            "display": "Publisher"
        },
        {
            "key": "serial_number",
            "value": null,
            "display": "Serial number"
        },
        {
            "key": "sortable_year_date_of_publication",
            "value": "1984",
            "display": "Sortable year date of publication"
        },
        {
            "key": "sub_title_of_document_book",
            "value": null,
            "display": "Sub title of document book"
        },
        {
            "key": "title_of_document_book",
            "value": null,
            "display": "Title of document book"
        },
        {
            "key": "book_title_of_document",
            "value": "Pather Panchali",
            "display": "Book title of document"
        },
        {
            "key": "book_sub_title_of_document",
            "value": "A Film by Satyajit Ray",
            "display": "Book sub title of document"
        },
        {
            "key": "volume_issue_number",
            "value": null,
            "display": "Volume issue number"
        },
        {
            "key": "masterlist",
            "value": "satyajit-ray",
            "display": "Masterlist"
        },
        {
            "key": "image",
            "value": ["https://tuliresearchcentre.canto.global/direct/image/li844dmijl77p3f6phbhic7o6d/mu5_CrFFHK1whDpKYF2Sz5847Zk/m800/800",Imgsatyajit1, Imgsatyajit2, Imgsatyajit3],
            "display": "Image"
        },
        {
            "key": "document_type",
            "value": "bke",
            "display": "Document type"
        },
        {
            "key": "title_and_type_of_creator",
            "value": "Editor",
            "display": "Title and type of creator"
        },
        {
            "key": "number_of_pages_in_publication",
            "value": null,
            "display": "Number of pages in publication"
        },
        {
            "key": "researchCategories",
            "value": [
                {
                    "key": "History & Historiography of Indian Scholarship in the Arts, Social Sciences & Humanities",
                    "value": "Scholarship",
                    "link": "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities"
                },
                {
                    "key": "Cinema as a Key Educational Resource",
                    "value": "CinemaE",
                    "link": "cinema-as-a-key-educational-resource"
                },
                {
                    "key": "Changing Smile of Childhood and its Second Coming",
                    "value": "Childhood",
                    "link": "changing-smile-of-childhood-and-its-second-coming"
                }
            ],
            "display": "Research categories"
        },
        {
            "key": "creator",
            "value": [
                {
                    "key": "parimal-mukhopadhyay",
                    "value": "Parimal Mukhopadhyay",
                    "first_name": "Parimal",
                    "last_name": "Mukhopadhyay"
                }
            ],
            "display": "Creator"
        },{
            key:"drawer_link",
            value:true
        }

    ],
    [
        {
            "key": "id",
            "value": "b19aefe5-8171-4e6e-abd2-a187672fdc1b",
            "display": "Id"
        },
        {
            "key": "title",
            "value": null,
            "display": "Title"
        },
        {
            "key": "alias",
            "value": "bka-ray-jab-main-chhota-tha-1984",
            "display": "Alias"
        },
        {
            "key": "web_serial_number",
            "value": null,
            "display": "Web serial number"
        },
        {
            "key": "reference_code",
            "value": null,
            "display": "Reference code"
        },
        {
            "key": "web_display",
            "value": null,
            "display": "Web display"
        },
        {
            "key": "date_of_library_publication",
            "value": null,
            "display": "Date of library publication"
        },
        {
            "key": "place_of_publication",
            "value": "Delhi",
            "display": "Place of publication"
        },
        {
            "key": "histographical_note",
            "value": null,
            "display": "Histographical note"
        },
        {
            "key": "main_historical_note",
            "value": null,
            "display": "Main historical note"
        },
        {
            "key": "binding_status",
            "value": "HB",
            "display": "Binding status"
        },
        {
            "key": "accession_number",
            "value": "0000017",
            "display": "Accession number"
        },
        {
            "key": "cdt",
            "value": null,
            "display": "CDT"
        },
        {
            "key": "country_of_publication",
            "value": "India",
            "display": "Country of publication"
        },
        {
            "key": "date_of_published_edition_in_library",
            "value": "1984",
            "display": "Date of published edition in library"
        },
        {
            "key": "dates_of_first_edition",
            "value": "",
            "display": "Dates of first edition"
        },
        {
            "key": "isbn_issn_number",
            "value": "",
            "display": "Isbn issn number"
        },
        {
            "key": "language_of_publication",
            "value": "",
            "display": "Language of publication"
        },
        {
            "key": "number_of_pages_in_publication_text",
            "value": null,
            "display": "Number of pages in publication text"
        },
        {
            "key": "personality_film_visually_featured_on_cover",
            "value": null,
            "display": "Personality film visually featured on cover"
        },
        {
            "key": "publisher",
            "value": "Rajkamal",
            "display": "Publisher"
        },
        {
            "key": "serial_number",
            "value": null,
            "display": "Serial number"
        },
        {
            "key": "sortable_year_date_of_publication",
            "value": "1984",
            "display": "Sortable year date of publication"
        },
        {
            "key": "sub_title_of_document_book",
            "value": null,
            "display": "Sub title of document book"
        },
        {
            "key": "title_of_document_book",
            "value": null,
            "display": "Title of document book"
        },
        {
            "key": "book_title_of_document",
            "value": "Jab Main Chhota Tha",
            "display": "Book title of document"
        },
        {
            "key": "book_sub_title_of_document",
            "value": "",
            "display": "Book sub title of document"
        },
        {
            "key": "volume_issue_number",
            "value": null,
            "display": "Volume issue number"
        },
        {
            "key": "masterlist",
            "value": "satyajit-ray",
            "display": "Masterlist"
        },
        {
            "key": "image",
            "value": ["https://tuliresearchcentre.canto.global/direct/image/0ru589u8u538n1ddgq7u0qk639/HRsXrxtud5x6bkp65OopMOmpryg/m800/800"],
            "display": "Image"
        },
        {
            "key": "document_type",
            "value": "bka",
            "display": "Document type"
        },
        {
            "key": "title_and_type_of_creator",
            "value": "Author",
            "display": "Title and type of creator"
        },
        {
            "key": "number_of_pages_in_publication",
            "value": null,
            "display": "Number of pages in publication"
        },
        {
            "key": "researchCategories",
            "value": [
                {
                    "key": "Changing Smile of Childhood and its Second Coming",
                    "value": "Childhood",
                    "link": "changing-smile-of-childhood-and-its-second-coming"
                },
                {
                    "key": "Cinema as a Key Educational Resource",
                    "value": "CinemaE",
                    "link": "cinema-as-a-key-educational-resource"
                }
            ],
            "display": "Research categories"
        },
        {
            "key": "creator",
            "value": [
                {
                    "key": "satyajit-ray",
                    "value": null,
                    "first_name": null,
                    "last_name": null
                }
            ],
            "display": "Creator"
        }
    ],
    [
        {
            "key": "id",
            "value": "aca49dce-a0f9-4fc5-8519-e281c8790f0e",
            "display": "Id"
        },
        {
            "key": "title",
            "value": null,
            "display": "Title"
        },
        {
            "key": "alias",
            "value": "bke-ramachandran-70-years-of-indian-cinema-\n1913-1983-1985",
            "display": "Alias"
        },
        {
            "key": "web_serial_number",
            "value": null,
            "display": "Web serial number"
        },
        {
            "key": "reference_code",
            "value": null,
            "display": "Reference code"
        },
        {
            "key": "web_display",
            "value": null,
            "display": "Web display"
        },
        {
            "key": "date_of_library_publication",
            "value": null,
            "display": "Date of library publication"
        },
        {
            "key": "place_of_publication",
            "value": "Bombay",
            "display": "Place of publication"
        },
        {
            "key": "histographical_note",
            "value": null,
            "display": "Histographical note"
        },
        {
            "key": "main_historical_note",
            "value": null,
            "display": "Main historical note"
        },
        {
            "key": "binding_status",
            "value": "HB",
            "display": "Binding status"
        },
        {
            "key": "accession_number",
            "value": "0000075",
            "display": "Accession number"
        },
        {
            "key": "cdt",
            "value": null,
            "display": "CDT"
        },
        {
            "key": "country_of_publication",
            "value": "India",
            "display": "Country of publication"
        },
        {
            "key": "date_of_published_edition_in_library",
            "value": "1985",
            "display": "Date of published edition in library"
        },
        {
            "key": "dates_of_first_edition",
            "value": "",
            "display": "Dates of first edition"
        },
        {
            "key": "isbn_issn_number",
            "value": "0861320905",
            "display": "Isbn issn number"
        },
        {
            "key": "language_of_publication",
            "value": "",
            "display": "Language of publication"
        },
        {
            "key": "number_of_pages_in_publication_text",
            "value": null,
            "display": "Number of pages in publication text"
        },
        {
            "key": "personality_film_visually_featured_on_cover",
            "value": null,
            "display": "Personality film visually featured on cover"
        },
        {
            "key": "publisher",
            "value": "Cinema India International",
            "display": "Publisher"
        },
        {
            "key": "serial_number",
            "value": null,
            "display": "Serial number"
        },
        {
            "key": "sortable_year_date_of_publication",
            "value": "1985",
            "display": "Sortable year date of publication"
        },
        {
            "key": "sub_title_of_document_book",
            "value": null,
            "display": "Sub title of document book"
        },
        {
            "key": "title_of_document_book",
            "value": null,
            "display": "Title of document book"
        },
        {
            "key": "book_title_of_document",
            "value": "70 Years of Indian Cinema -\n1913-1983",
            "display": "Book title of document"
        },
        {
            "key": "book_sub_title_of_document",
            "value": "",
            "display": "Book sub title of document"
        },
        {
            "key": "volume_issue_number",
            "value": null,
            "display": "Volume issue number"
        },
        {
            "key": "masterlist",
            "value": "satyajit-ray",
            "display": "Masterlist"
        },
        {
            "key": "image",
            "value": ["https://tuliresearchcentre.canto.global/direct/image/1krdki917d6e56at2vrkto7s00/cU_ZNe_iC8LGpq7PR7CJ5ZhdC9I/m800/800"],
            "display": "Image"
        },
        {
            "key": "document_type",
            "value": "bke",
            "display": "Document type"
        },
        {
            "key": "title_and_type_of_creator",
            "value": "Editor",
            "display": "Title and type of creator"
        },
        {
            "key": "number_of_pages_in_publication",
            "value": null,
            "display": "Number of pages in publication"
        },
        {
            "key": "researchCategories",
            "value": [
                {
                    "key": "History & Historiography of Indian Scholarship in the Arts, Social Sciences & Humanities",
                    "value": "Scholarship",
                    "link": "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities"
                },
                {
                    "key": "Cinema as a Key Educational Resource",
                    "value": "CinemaE",
                    "link": "cinema-as-a-key-educational-resource"
                }
            ],
            "display": "Research categories"
        },
        {
            "key": "creator",
            "value": [
                {
                    "key": "t-m-ramachandran",
                    "value": "T M Ramachandran",
                    "first_name": "T-M",
                    "last_name": "Ramachandran"
                }
            ],
            "display": "Creator"
        }
    ],
    [
        {
            "key": "id",
            "value": "0e4f09b2-6615-4cf3-8d62-3890b0331eef",
            "display": "Id"
        },
        {
            "key": "title",
            "value": null,
            "display": "Title"
        },
        {
            "key": "alias",
            "value": "bka-ray-the-apu-trilogy-1985",
            "display": "Alias"
        },
        {
            "key": "web_serial_number",
            "value": null,
            "display": "Web serial number"
        },
        {
            "key": "reference_code",
            "value": null,
            "display": "Reference code"
        },
        {
            "key": "web_display",
            "value": null,
            "display": "Web display"
        },
        {
            "key": "date_of_library_publication",
            "value": null,
            "display": "Date of library publication"
        },
        {
            "key": "place_of_publication",
            "value": "Calcutta",
            "display": "Place of publication"
        },
        {
            "key": "histographical_note",
            "value": null,
            "display": "Histographical note"
        },
        {
            "key": "main_historical_note",
            "value": null,
            "display": "Main historical note"
        },
        {
            "key": "binding_status",
            "value": "PB",
            "display": "Binding status"
        },
        {
            "key": "accession_number",
            "value": "0000018",
            "display": "Accession number"
        },
        {
            "key": "cdt",
            "value": null,
            "display": "CDT"
        },
        {
            "key": "country_of_publication",
            "value": "India",
            "display": "Country of publication"
        },
        {
            "key": "date_of_published_edition_in_library",
            "value": "1985",
            "display": "Date of published edition in library"
        },
        {
            "key": "dates_of_first_edition",
            "value": "",
            "display": "Dates of first edition"
        },
        {
            "key": "isbn_issn_number",
            "value": "",
            "display": "Isbn issn number"
        },
        {
            "key": "language_of_publication",
            "value": "",
            "display": "Language of publication"
        },
        {
            "key": "number_of_pages_in_publication_text",
            "value": null,
            "display": "Number of pages in publication text"
        },
        {
            "key": "personality_film_visually_featured_on_cover",
            "value": null,
            "display": "Personality film visually featured on cover"
        },
        {
            "key": "publisher",
            "value": "Seagull Books",
            "display": "Publisher"
        },
        {
            "key": "serial_number",
            "value": null,
            "display": "Serial number"
        },
        {
            "key": "sortable_year_date_of_publication",
            "value": "1985",
            "display": "Sortable year date of publication"
        },
        {
            "key": "sub_title_of_document_book",
            "value": null,
            "display": "Sub title of document book"
        },
        {
            "key": "title_of_document_book",
            "value": null,
            "display": "Title of document book"
        },
        {
            "key": "book_title_of_document",
            "value": "The Apu Trilogy",
            "display": "Book title of document"
        },
        {
            "key": "book_sub_title_of_document",
            "value": "Pather Panchali, Aparajito, Apur Sansar",
            "display": "Book sub title of document"
        },
        {
            "key": "volume_issue_number",
            "value": null,
            "display": "Volume issue number"
        },
        {
            "key": "masterlist",
            "value": "satyajit-ray",
            "display": "Masterlist"
        },
        {
            "key": "image",
            "value": ["https://tuliresearchcentre.canto.global/direct/image/3oug5tpeh179t9bl99kgl9sr7j/pI6vAq8TT-GQWEqMrb5GeJx_OlM/m800/800"],
            "display": "Image"
        },
        {
            "key": "document_type",
            "value": "bka",
            "display": "Document type"
        },
        {
            "key": "title_and_type_of_creator",
            "value": "Author",
            "display": "Title and type of creator"
        },
        {
            "key": "number_of_pages_in_publication",
            "value": null,
            "display": "Number of pages in publication"
        },
        {
            "key": "researchCategories",
            "value": [
                {
                    "key": "Changing Smile of Childhood and its Second Coming",
                    "value": "Childhood",
                    "link": "changing-smile-of-childhood-and-its-second-coming"
                },
                {
                    "key": "Cinema as a Key Educational Resource",
                    "value": "CinemaE",
                    "link": "cinema-as-a-key-educational-resource"
                }
            ],
            "display": "Research categories"
        },
        {
            "key": "creator",
            "value": [
                {
                    "key": "satyajit-ray",
                    "value": null,
                    "first_name": null,
                    "last_name": null
                }
            ],
            "display": "Creator"
        }
    ],
    [
        {
            "key": "id",
            "value": "14d9a58d-0c5f-4aeb-b85c-e482da69475e",
            "display": "Id"
        },
        {
            "key": "title",
            "value": null,
            "display": "Title"
        },
        {
            "key": "alias",
            "value": "bka-ray-pikoor-diary-o-onyanyo-1985",
            "display": "Alias"
        },
        {
            "key": "web_serial_number",
            "value": null,
            "display": "Web serial number"
        },
        {
            "key": "reference_code",
            "value": null,
            "display": "Reference code"
        },
        {
            "key": "web_display",
            "value": null,
            "display": "Web display"
        },
        {
            "key": "date_of_library_publication",
            "value": null,
            "display": "Date of library publication"
        },
        {
            "key": "place_of_publication",
            "value": "Calcutta",
            "display": "Place of publication"
        },
        {
            "key": "histographical_note",
            "value": null,
            "display": "Histographical note"
        },
        {
            "key": "main_historical_note",
            "value": null,
            "display": "Main historical note"
        },
        {
            "key": "binding_status",
            "value": "",
            "display": "Binding status"
        },
        {
            "key": "accession_number",
            "value": "0000019",
            "display": "Accession number"
        },
        {
            "key": "cdt",
            "value": null,
            "display": "CDT"
        },
        {
            "key": "country_of_publication",
            "value": "India",
            "display": "Country of publication"
        },
        {
            "key": "date_of_published_edition_in_library",
            "value": "",
            "display": "Date of published edition in library"
        },
        {
            "key": "dates_of_first_edition",
            "value": "",
            "display": "Dates of first edition"
        },
        {
            "key": "isbn_issn_number",
            "value": "",
            "display": "Isbn issn number"
        },
        {
            "key": "language_of_publication",
            "value": "",
            "display": "Language of publication"
        },
        {
            "key": "number_of_pages_in_publication_text",
            "value": null,
            "display": "Number of pages in publication text"
        },
        {
            "key": "personality_film_visually_featured_on_cover",
            "value": null,
            "display": "Personality film visually featured on cover"
        },
        {
            "key": "publisher",
            "value": "Ananda",
            "display": "Publisher"
        },
        {
            "key": "serial_number",
            "value": null,
            "display": "Serial number"
        },
        {
            "key": "sortable_year_date_of_publication",
            "value": "1985",
            "display": "Sortable year date of publication"
        },
        {
            "key": "sub_title_of_document_book",
            "value": null,
            "display": "Sub title of document book"
        },
        {
            "key": "title_of_document_book",
            "value": null,
            "display": "Title of document book"
        },
        {
            "key": "book_title_of_document",
            "value": "Pikoor Diary O Onyanyo",
            "display": "Book title of document"
        },
        {
            "key": "book_sub_title_of_document",
            "value": "",
            "display": "Book sub title of document"
        },
        {
            "key": "volume_issue_number",
            "value": null,
            "display": "Volume issue number"
        },
        {
            "key": "masterlist",
            "value": "satyajit-ray",
            "display": "Masterlist"
        },
        {
            "key": "image",
            "value": ["https://tuliresearchcentre.canto.global/direct/image/gb7p4hdvt910de33ii253os746/Cec8VtXnECuzoxnEAejOHIjgSvc/m800/800"],
            "display": "Image"
        },
        {
            "key": "document_type",
            "value": "bka",
            "display": "Document type"
        },
        {
            "key": "title_and_type_of_creator",
            "value": "Author",
            "display": "Title and type of creator"
        },
        {
            "key": "number_of_pages_in_publication",
            "value": null,
            "display": "Number of pages in publication"
        },
        {
            "key": "researchCategories",
            "value": [
                {
                    "key": "Cinema as a Key Educational Resource",
                    "value": "CinemaE",
                    "link": "cinema-as-a-key-educational-resource"
                }
            ],
            "display": "Research categories"
        },
        {
            "key": "creator",
            "value": [
                {
                    "key": "satyajit-ray",
                    "value": null,
                    "first_name": null,
                    "last_name": null
                }
            ],
            "display": "Creator"
        }
    ],
    [
        {
            "key": "id",
            "value": "a1395b56-5bc1-4bb6-8aa3-47618d87a5b2",
            "display": "Id"
        },
        {
            "key": "title",
            "value": null,
            "display": "Title"
        },
        {
            "key": "alias",
            "value": "bka-banerjee-jalsaghar-1986",
            "display": "Alias"
        },
        {
            "key": "web_serial_number",
            "value": null,
            "display": "Web serial number"
        },
        {
            "key": "reference_code",
            "value": null,
            "display": "Reference code"
        },
        {
            "key": "web_display",
            "value": null,
            "display": "Web display"
        },
        {
            "key": "date_of_library_publication",
            "value": null,
            "display": "Date of library publication"
        },
        {
            "key": "place_of_publication",
            "value": "Kolkata",
            "display": "Place of publication"
        },
        {
            "key": "histographical_note",
            "value": null,
            "display": "Histographical note"
        },
        {
            "key": "main_historical_note",
            "value": null,
            "display": "Main historical note"
        },
        {
            "key": "binding_status",
            "value": "HB",
            "display": "Binding status"
        },
        {
            "key": "accession_number",
            "value": "0000021",
            "display": "Accession number"
        },
        {
            "key": "cdt",
            "value": null,
            "display": "CDT"
        },
        {
            "key": "country_of_publication",
            "value": "India",
            "display": "Country of publication"
        },
        {
            "key": "date_of_published_edition_in_library",
            "value": "1986",
            "display": "Date of published edition in library"
        },
        {
            "key": "dates_of_first_edition",
            "value": "",
            "display": "Dates of first edition"
        },
        {
            "key": "isbn_issn_number",
            "value": "",
            "display": "Isbn issn number"
        },
        {
            "key": "language_of_publication",
            "value": "",
            "display": "Language of publication"
        },
        {
            "key": "number_of_pages_in_publication_text",
            "value": null,
            "display": "Number of pages in publication text"
        },
        {
            "key": "personality_film_visually_featured_on_cover",
            "value": null,
            "display": "Personality film visually featured on cover"
        },
        {
            "key": "publisher",
            "value": "Indranath Majumdar",
            "display": "Publisher"
        },
        {
            "key": "serial_number",
            "value": null,
            "display": "Serial number"
        },
        {
            "key": "sortable_year_date_of_publication",
            "value": "1986",
            "display": "Sortable year date of publication"
        },
        {
            "key": "sub_title_of_document_book",
            "value": null,
            "display": "Sub title of document book"
        },
        {
            "key": "title_of_document_book",
            "value": null,
            "display": "Title of document book"
        },
        {
            "key": "book_title_of_document",
            "value": "Jalsaghar",
            "display": "Book title of document"
        },
        {
            "key": "book_sub_title_of_document",
            "value": "",
            "display": "Book sub title of document"
        },
        {
            "key": "volume_issue_number",
            "value": null,
            "display": "Volume issue number"
        },
        {
            "key": "masterlist",
            "value": "satyajit-ray",
            "display": "Masterlist"
        },
        {
            "key": "image",
            "value": ["https://tuliresearchcentre.canto.global/direct/image/pajsen347d26b8feglipg80u7i/N-dtAybZtt1LAAIl4bw2gdv57t0/m800/800"],
            "display": "Image"
        },
        {
            "key": "document_type",
            "value": "bka",
            "display": "Document type"
        },
        {
            "key": "title_and_type_of_creator",
            "value": "Author",
            "display": "Title and type of creator"
        },
        {
            "key": "number_of_pages_in_publication",
            "value": null,
            "display": "Number of pages in publication"
        },
        {
            "key": "researchCategories",
            "value": [
                {
                    "key": "Cinema as a Key Educational Resource",
                    "value": "CinemaE",
                    "link": "cinema-as-a-key-educational-resource"
                }
            ],
            "display": "Research categories"
        },
        {
            "key": "creator",
            "value": [
                {
                    "key": "tarasankar-banerjee",
                    "value": "Tarasankar Banerjee",
                    "first_name": "Tarasankar",
                    "last_name": "Banerjee"
                }
            ],
            "display": "Creator"
        }
    ],
    [
        {
            "key": "id",
            "value": "84c9c361-98fd-445a-9aad-a9430170a03f",
            "display": "Id"
        },
        {
            "key": "title",
            "value": null,
            "display": "Title"
        },
        {
            "key": "alias",
            "value": "bka-ray-bravo-professor-shonku-1986",
            "display": "Alias"
        },
        {
            "key": "web_serial_number",
            "value": null,
            "display": "Web serial number"
        },
        {
            "key": "reference_code",
            "value": null,
            "display": "Reference code"
        },
        {
            "key": "web_display",
            "value": null,
            "display": "Web display"
        },
        {
            "key": "date_of_library_publication",
            "value": null,
            "display": "Date of library publication"
        },
        {
            "key": "place_of_publication",
            "value": "New Delhi",
            "display": "Place of publication"
        },
        {
            "key": "histographical_note",
            "value": null,
            "display": "Histographical note"
        },
        {
            "key": "main_historical_note",
            "value": null,
            "display": "Main historical note"
        },
        {
            "key": "binding_status",
            "value": "PB",
            "display": "Binding status"
        },
        {
            "key": "accession_number",
            "value": "0000022",
            "display": "Accession number"
        },
        {
            "key": "cdt",
            "value": null,
            "display": "CDT"
        },
        {
            "key": "country_of_publication",
            "value": "India",
            "display": "Country of publication"
        },
        {
            "key": "date_of_published_edition_in_library",
            "value": "1986",
            "display": "Date of published edition in library"
        },
        {
            "key": "dates_of_first_edition",
            "value": "",
            "display": "Dates of first edition"
        },
        {
            "key": "isbn_issn_number",
            "value": "",
            "display": "Isbn issn number"
        },
        {
            "key": "language_of_publication",
            "value": "",
            "display": "Language of publication"
        },
        {
            "key": "number_of_pages_in_publication_text",
            "value": null,
            "display": "Number of pages in publication text"
        },
        {
            "key": "personality_film_visually_featured_on_cover",
            "value": null,
            "display": "Personality film visually featured on cover"
        },
        {
            "key": "publisher",
            "value": "Rupa Paperback",
            "display": "Publisher"
        },
        {
            "key": "serial_number",
            "value": null,
            "display": "Serial number"
        },
        {
            "key": "sortable_year_date_of_publication",
            "value": "1986",
            "display": "Sortable year date of publication"
        },
        {
            "key": "sub_title_of_document_book",
            "value": null,
            "display": "Sub title of document book"
        },
        {
            "key": "title_of_document_book",
            "value": null,
            "display": "Title of document book"
        },
        {
            "key": "book_title_of_document",
            "value": "Bravo! Professor Shonku",
            "display": "Book title of document"
        },
        {
            "key": "book_sub_title_of_document",
            "value": "",
            "display": "Book sub title of document"
        },
        {
            "key": "volume_issue_number",
            "value": null,
            "display": "Volume issue number"
        },
        {
            "key": "masterlist",
            "value": "satyajit-ray",
            "display": "Masterlist"
        },
        {
            "key": "image",
            "value": ["https://tuliresearchcentre.canto.global/direct/image/5cv7o61d592vf5002qb98vqf2m/AbDOrARVuPLK1lOXnUI9MK-0g1I/m800/800"],
            "display": "Image"
        },
        {
            "key": "document_type",
            "value": "bka",
            "display": "Document type"
        },
        {
            "key": "title_and_type_of_creator",
            "value": "Author",
            "display": "Title and type of creator"
        },
        {
            "key": "number_of_pages_in_publication",
            "value": null,
            "display": "Number of pages in publication"
        },
        {
            "key": "researchCategories",
            "value": [
                {
                    "key": "Cinema as a Key Educational Resource",
                    "value": "CinemaE",
                    "link": "cinema-as-a-key-educational-resource"
                },
                {
                    "key": "Uncertainty Revealing Itself : Death | Magic | Mystery | Sci-Fi | Horror | Gambling  | A.I.",
                    "value": "Uncertainty",
                    "link": "uncertainy-revealing-itself-death-magic-mystery-sci-fi-horror-gambling-ai"
                }
            ],
            "display": "Research categories"
        },
        {
            "key": "creator",
            "value": [
                {
                    "key": "satyajit-ray",
                    "value": null,
                    "first_name": null,
                    "last_name": null
                }
            ],
            "display": "Creator"
        }
    ],
    [
        {
            "key": "id",
            "value": "9079801e-5f2d-46d3-9204-bbabfe168874",
            "display": "Id"
        },
        {
            "key": "title",
            "value": null,
            "display": "Title"
        },
        {
            "key": "alias",
            "value": "bka-datta-benegal-on-ray-1988",
            "display": "Alias"
        },
        {
            "key": "web_serial_number",
            "value": null,
            "display": "Web serial number"
        },
        {
            "key": "reference_code",
            "value": null,
            "display": "Reference code"
        },
        {
            "key": "web_display",
            "value": null,
            "display": "Web display"
        },
        {
            "key": "date_of_library_publication",
            "value": null,
            "display": "Date of library publication"
        },
        {
            "key": "place_of_publication",
            "value": "Calcutta",
            "display": "Place of publication"
        },
        {
            "key": "histographical_note",
            "value": null,
            "display": "Histographical note"
        },
        {
            "key": "main_historical_note",
            "value": null,
            "display": "Main historical note"
        },
        {
            "key": "binding_status",
            "value": "PB",
            "display": "Binding status"
        },
        {
            "key": "accession_number",
            "value": "0000003",
            "display": "Accession number"
        },
        {
            "key": "cdt",
            "value": null,
            "display": "CDT"
        },
        {
            "key": "country_of_publication",
            "value": "India",
            "display": "Country of publication"
        },
        {
            "key": "date_of_published_edition_in_library",
            "value": "1988",
            "display": "Date of published edition in library"
        },
        {
            "key": "dates_of_first_edition",
            "value": "",
            "display": "Dates of first edition"
        },
        {
            "key": "isbn_issn_number",
            "value": "8170460212",
            "display": "Isbn issn number"
        },
        {
            "key": "language_of_publication",
            "value": "",
            "display": "Language of publication"
        },
        {
            "key": "number_of_pages_in_publication_text",
            "value": null,
            "display": "Number of pages in publication text"
        },
        {
            "key": "personality_film_visually_featured_on_cover",
            "value": null,
            "display": "Personality film visually featured on cover"
        },
        {
            "key": "publisher",
            "value": "Seagull Books",
            "display": "Publisher"
        },
        {
            "key": "serial_number",
            "value": null,
            "display": "Serial number"
        },
        {
            "key": "sortable_year_date_of_publication",
            "value": "1988",
            "display": "Sortable year date of publication"
        },
        {
            "key": "sub_title_of_document_book",
            "value": null,
            "display": "Sub title of document book"
        },
        {
            "key": "title_of_document_book",
            "value": null,
            "display": "Title of document book"
        },
        {
            "key": "book_title_of_document",
            "value": "Benegal on Ray",
            "display": "Book title of document"
        },
        {
            "key": "book_sub_title_of_document",
            "value": "Satyajit Ray: A Film by Shyam Benegal",
            "display": "Book sub title of document"
        },
        {
            "key": "volume_issue_number",
            "value": null,
            "display": "Volume issue number"
        },
        {
            "key": "masterlist",
            "value": "satyajit-ray",
            "display": "Masterlist"
        },
        {
            "key": "image",
            "value": ["https://tuliresearchcentre.canto.global/direct/image/qhrjp5ieoh7oj6lrf5al03rm5a/ys6mfvXYquMNUugkIJkytA0ubpY/m800/800"],
            "display": "Image"
        },
        {
            "key": "document_type",
            "value": "bka",
            "display": "Document type"
        },
        {
            "key": "title_and_type_of_creator",
            "value": "Author",
            "display": "Title and type of creator"
        },
        {
            "key": "number_of_pages_in_publication",
            "value": null,
            "display": "Number of pages in publication"
        },
        {
            "key": "researchCategories",
            "value": [
                {
                    "key": "Cinema as a Key Educational Resource",
                    "value": "CinemaE",
                    "link": "cinema-as-a-key-educational-resource"
                }
            ],
            "display": "Research categories"
        },
        {
            "key": "creator",
            "value": [
                {
                    "key": "alakananda-datta",
                    "value": "Alakananda Datta",
                    "first_name": "Alaknanda",
                    "last_name": "Datta"
                }
            ],
            "display": "Creator"
        }
    ],
    [
        {
            "key": "id",
            "value": "d2358817-a6cd-4497-b315-2c228386744d",
            "display": "Id"
        },
        {
            "key": "title",
            "value": null,
            "display": "Title"
        },
        {
            "key": "alias",
            "value": "bka-nyce-satyajit-ray-1988",
            "display": "Alias"
        },
        {
            "key": "web_serial_number",
            "value": null,
            "display": "Web serial number"
        },
        {
            "key": "reference_code",
            "value": null,
            "display": "Reference code"
        },
        {
            "key": "web_display",
            "value": null,
            "display": "Web display"
        },
        {
            "key": "date_of_library_publication",
            "value": null,
            "display": "Date of library publication"
        },
        {
            "key": "place_of_publication",
            "value": "New York",
            "display": "Place of publication"
        },
        {
            "key": "histographical_note",
            "value": null,
            "display": "Histographical note"
        },
        {
            "key": "main_historical_note",
            "value": null,
            "display": "Main historical note"
        },
        {
            "key": "binding_status",
            "value": "HB",
            "display": "Binding status"
        },
        {
            "key": "accession_number",
            "value": "0000057",
            "display": "Accession number"
        },
        {
            "key": "cdt",
            "value": null,
            "display": "CDT"
        },
        {
            "key": "country_of_publication",
            "value": "USA",
            "display": "Country of publication"
        },
        {
            "key": "date_of_published_edition_in_library",
            "value": "1988",
            "display": "Date of published edition in library"
        },
        {
            "key": "dates_of_first_edition",
            "value": "",
            "display": "Dates of first edition"
        },
        {
            "key": "isbn_issn_number",
            "value": "0-275926664",
            "display": "Isbn issn number"
        },
        {
            "key": "language_of_publication",
            "value": "",
            "display": "Language of publication"
        },
        {
            "key": "number_of_pages_in_publication_text",
            "value": null,
            "display": "Number of pages in publication text"
        },
        {
            "key": "personality_film_visually_featured_on_cover",
            "value": null,
            "display": "Personality film visually featured on cover"
        },
        {
            "key": "publisher",
            "value": "Praeger",
            "display": "Publisher"
        },
        {
            "key": "serial_number",
            "value": null,
            "display": "Serial number"
        },
        {
            "key": "sortable_year_date_of_publication",
            "value": "1988",
            "display": "Sortable year date of publication"
        },
        {
            "key": "sub_title_of_document_book",
            "value": null,
            "display": "Sub title of document book"
        },
        {
            "key": "title_of_document_book",
            "value": null,
            "display": "Title of document book"
        },
        {
            "key": "book_title_of_document",
            "value": "Satyajit Ray",
            "display": "Book title of document"
        },
        {
            "key": "book_sub_title_of_document",
            "value": "A Study of His Films",
            "display": "Book sub title of document"
        },
        {
            "key": "volume_issue_number",
            "value": null,
            "display": "Volume issue number"
        },
        {
            "key": "masterlist",
            "value": "satyajit-ray",
            "display": "Masterlist"
        },
        {
            "key": "image",
            "value": ["https://tuliresearchcentre.canto.global/direct/image/nmn2np2kkp17b4iighdq5tl47a/NCclisCyEuuIhywFvMU97oOPXXs/m800/800"],
            "display": "Image"
        },
        {
            "key": "document_type",
            "value": "bka",
            "display": "Document type"
        },
        {
            "key": "title_and_type_of_creator",
            "value": "Author",
            "display": "Title and type of creator"
        },
        {
            "key": "number_of_pages_in_publication",
            "value": null,
            "display": "Number of pages in publication"
        },
        {
            "key": "researchCategories",
            "value": [
                {
                    "key": "Cinema as a Key Educational Resource",
                    "value": "CinemaE",
                    "link": "cinema-as-a-key-educational-resource"
                },
                {
                    "key": "History & Historiography of Indian Scholarship in the Arts, Social Sciences & Humanities",
                    "value": "Scholarship",
                    "link": "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities"
                }
            ],
            "display": "Research categories"
        },
        {
            "key": "creator",
            "value": [
                {
                    "key": "ben-nyce",
                    "value": "Ben Nyce",
                    "first_name": "Ben",
                    "last_name": "Nyce"
                }
            ],
            "display": "Creator"
        }
    ],
    [
        {
            "key": "id",
            "value": "6c52b0ea-1daf-471f-a646-2f2a4c979d62",
            "display": "Id"
        },
        {
            "key": "title",
            "value": null,
            "display": "Title"
        },
        {
            "key": "alias",
            "value": "bka-valicha-the-moving-image-1988",
            "display": "Alias"
        },
        {
            "key": "web_serial_number",
            "value": null,
            "display": "Web serial number"
        },
        {
            "key": "reference_code",
            "value": null,
            "display": "Reference code"
        },
        {
            "key": "web_display",
            "value": null,
            "display": "Web display"
        },
        {
            "key": "date_of_library_publication",
            "value": null,
            "display": "Date of library publication"
        },
        {
            "key": "place_of_publication",
            "value": "Bombay",
            "display": "Place of publication"
        },
        {
            "key": "histographical_note",
            "value": null,
            "display": "Histographical note"
        },
        {
            "key": "main_historical_note",
            "value": null,
            "display": "Main historical note"
        },
        {
            "key": "binding_status",
            "value": "HB",
            "display": "Binding status"
        },
        {
            "key": "accession_number",
            "value": "0000095",
            "display": "Accession number"
        },
        {
            "key": "cdt",
            "value": null,
            "display": "CDT"
        },
        {
            "key": "country_of_publication",
            "value": "India",
            "display": "Country of publication"
        },
        {
            "key": "date_of_published_edition_in_library",
            "value": "1988",
            "display": "Date of published edition in library"
        },
        {
            "key": "dates_of_first_edition",
            "value": "",
            "display": "Dates of first edition"
        },
        {
            "key": "isbn_issn_number",
            "value": "0861316819",
            "display": "Isbn issn number"
        },
        {
            "key": "language_of_publication",
            "value": "",
            "display": "Language of publication"
        },
        {
            "key": "number_of_pages_in_publication_text",
            "value": null,
            "display": "Number of pages in publication text"
        },
        {
            "key": "personality_film_visually_featured_on_cover",
            "value": null,
            "display": "Personality film visually featured on cover"
        },
        {
            "key": "publisher",
            "value": "Orient Longman",
            "display": "Publisher"
        },
        {
            "key": "serial_number",
            "value": null,
            "display": "Serial number"
        },
        {
            "key": "sortable_year_date_of_publication",
            "value": "1988",
            "display": "Sortable year date of publication"
        },
        {
            "key": "sub_title_of_document_book",
            "value": null,
            "display": "Sub title of document book"
        },
        {
            "key": "title_of_document_book",
            "value": null,
            "display": "Title of document book"
        },
        {
            "key": "book_title_of_document",
            "value": "The Moving Image",
            "display": "Book title of document"
        },
        {
            "key": "book_sub_title_of_document",
            "value": "A Study of Indian Cinema",
            "display": "Book sub title of document"
        },
        {
            "key": "volume_issue_number",
            "value": null,
            "display": "Volume issue number"
        },
        {
            "key": "masterlist",
            "value": "satyajit-ray",
            "display": "Masterlist"
        },
        {
            "key": "image",
            "value": ["https://tuliresearchcentre.canto.global/direct/image/ate182883576b4aola99k9740g/BYcjvNMmvk1WdV1mG0iqYyY8eNw/m800/800"],
            "display": "Image"
        },
        {
            "key": "document_type",
            "value": "bka",
            "display": "Document type"
        },
        {
            "key": "title_and_type_of_creator",
            "value": "Dr & Author",
            "display": "Title and type of creator"
        },
        {
            "key": "number_of_pages_in_publication",
            "value": null,
            "display": "Number of pages in publication"
        },
        {
            "key": "researchCategories",
            "value": [
                {
                    "key": "History & Historiography of Indian Scholarship in the Arts, Social Sciences & Humanities",
                    "value": "Scholarship",
                    "link": "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities"
                },
                {
                    "key": "Cinema as a Key Educational Resource",
                    "value": "CinemaE",
                    "link": "cinema-as-a-key-educational-resource"
                }
            ],
            "display": "Research categories"
        },
        {
            "key": "creator",
            "value": [
                {
                    "key": "kishore-valicha",
                    "value": "Kishore Valicha",
                    "first_name": "Kishore",
                    "last_name": "Valicha"
                }
            ],
            "display": "Creator"
        }
    ],
    [
        {
            "key": "id",
            "value": "c90bd305-5f85-4ef6-b4d1-2bd23aad15a0",
            "display": "Id"
        },
        {
            "key": "title",
            "value": null,
            "display": "Title"
        },
        {
            "key": "alias",
            "value": "bka-robinson-satyajit-ray-1989",
            "display": "Alias"
        },
        {
            "key": "web_serial_number",
            "value": null,
            "display": "Web serial number"
        },
        {
            "key": "reference_code",
            "value": null,
            "display": "Reference code"
        },
        {
            "key": "web_display",
            "value": null,
            "display": "Web display"
        },
        {
            "key": "date_of_library_publication",
            "value": null,
            "display": "Date of library publication"
        },
        {
            "key": "place_of_publication",
            "value": "London",
            "display": "Place of publication"
        },
        {
            "key": "histographical_note",
            "value": null,
            "display": "Histographical note"
        },
        {
            "key": "main_historical_note",
            "value": null,
            "display": "Main historical note"
        },
        {
            "key": "binding_status",
            "value": "HB",
            "display": "Binding status"
        },
        {
            "key": "accession_number",
            "value": "0000004",
            "display": "Accession number"
        },
        {
            "key": "cdt",
            "value": null,
            "display": "CDT"
        },
        {
            "key": "country_of_publication",
            "value": "UK",
            "display": "Country of publication"
        },
        {
            "key": "date_of_published_edition_in_library",
            "value": "1989",
            "display": "Date of published edition in library"
        },
        {
            "key": "dates_of_first_edition",
            "value": "",
            "display": "Dates of first edition"
        },
        {
            "key": "isbn_issn_number",
            "value": "0-233984739",
            "display": "Isbn issn number"
        },
        {
            "key": "language_of_publication",
            "value": "",
            "display": "Language of publication"
        },
        {
            "key": "number_of_pages_in_publication_text",
            "value": null,
            "display": "Number of pages in publication text"
        },
        {
            "key": "personality_film_visually_featured_on_cover",
            "value": null,
            "display": "Personality film visually featured on cover"
        },
        {
            "key": "publisher",
            "value": "Andrew Deutsch",
            "display": "Publisher"
        },
        {
            "key": "serial_number",
            "value": null,
            "display": "Serial number"
        },
        {
            "key": "sortable_year_date_of_publication",
            "value": "1989",
            "display": "Sortable year date of publication"
        },
        {
            "key": "sub_title_of_document_book",
            "value": null,
            "display": "Sub title of document book"
        },
        {
            "key": "title_of_document_book",
            "value": null,
            "display": "Title of document book"
        },
        {
            "key": "book_title_of_document",
            "value": "Satyajit Ray",
            "display": "Book title of document"
        },
        {
            "key": "book_sub_title_of_document",
            "value": "The Inner Eye",
            "display": "Book sub title of document"
        },
        {
            "key": "volume_issue_number",
            "value": null,
            "display": "Volume issue number"
        },
        {
            "key": "masterlist",
            "value": "satyajit-ray",
            "display": "Masterlist"
        },
        {
            "key": "image",
            "value": ["https://tuliresearchcentre.canto.global/direct/image/mmr1aihhat0r75dr67us6gbp4v/Rwe6Ni6vJ5R9gLxqGJmu6Pk7urI/m800/800"],
            "display": "Image"
        },
        {
            "key": "document_type",
            "value": "bka",
            "display": "Document type"
        },
        {
            "key": "title_and_type_of_creator",
            "value": "Author",
            "display": "Title and type of creator"
        },
        {
            "key": "number_of_pages_in_publication",
            "value": null,
            "display": "Number of pages in publication"
        },
        {
            "key": "researchCategories",
            "value": [
                {
                    "key": "India and her Relationship with the World",
                    "value": "IndoWorld",
                    "link": "india-and-her-relationship-with-the-world"
                },
                {
                    "key": "Cinema as a Key Educational Resource",
                    "value": "CinemaE",
                    "link": "cinema-as-a-key-educational-resource"
                }
            ],
            "display": "Research categories"
        },
        {
            "key": "creator",
            "value": [
                {
                    "key": "andrew-robinson",
                    "value": "Andrew Robinson",
                    "first_name": "Andrew",
                    "last_name": "Robinson"
                }
            ],
            "display": "Creator"
        }
    ],
    [
        {
            "key": "id",
            "value": "142b6968-7507-45b4-b889-506bd2036e3a",
            "display": "Id"
        },
        {
            "key": "title",
            "value": null,
            "display": "Title"
        },
        {
            "key": "alias",
            "value": "bke-robinson-the-chess-players-and-other-screenplays-1989",
            "display": "Alias"
        },
        {
            "key": "web_serial_number",
            "value": null,
            "display": "Web serial number"
        },
        {
            "key": "reference_code",
            "value": null,
            "display": "Reference code"
        },
        {
            "key": "web_display",
            "value": null,
            "display": "Web display"
        },
        {
            "key": "date_of_library_publication",
            "value": null,
            "display": "Date of library publication"
        },
        {
            "key": "place_of_publication",
            "value": "London",
            "display": "Place of publication"
        },
        {
            "key": "histographical_note",
            "value": null,
            "display": "Histographical note"
        },
        {
            "key": "main_historical_note",
            "value": null,
            "display": "Main historical note"
        },
        {
            "key": "binding_status",
            "value": "PB",
            "display": "Binding status"
        },
        {
            "key": "accession_number",
            "value": "0000024",
            "display": "Accession number"
        },
        {
            "key": "cdt",
            "value": null,
            "display": "CDT"
        },
        {
            "key": "country_of_publication",
            "value": "United Kingdom",
            "display": "Country of publication"
        },
        {
            "key": "date_of_published_edition_in_library",
            "value": "1989",
            "display": "Date of published edition in library"
        },
        {
            "key": "dates_of_first_edition",
            "value": "",
            "display": "Dates of first edition"
        },
        {
            "key": "isbn_issn_number",
            "value": "0-571140742",
            "display": "Isbn issn number"
        },
        {
            "key": "language_of_publication",
            "value": "",
            "display": "Language of publication"
        },
        {
            "key": "number_of_pages_in_publication_text",
            "value": null,
            "display": "Number of pages in publication text"
        },
        {
            "key": "personality_film_visually_featured_on_cover",
            "value": null,
            "display": "Personality film visually featured on cover"
        },
        {
            "key": "publisher",
            "value": "Faber and Faber",
            "display": "Publisher"
        },
        {
            "key": "serial_number",
            "value": null,
            "display": "Serial number"
        },
        {
            "key": "sortable_year_date_of_publication",
            "value": "1989",
            "display": "Sortable year date of publication"
        },
        {
            "key": "sub_title_of_document_book",
            "value": null,
            "display": "Sub title of document book"
        },
        {
            "key": "title_of_document_book",
            "value": null,
            "display": "Title of document book"
        },
        {
            "key": "book_title_of_document",
            "value": "The Chess Players and Other Screenplays",
            "display": "Book title of document"
        },
        {
            "key": "book_sub_title_of_document",
            "value": "",
            "display": "Book sub title of document"
        },
        {
            "key": "volume_issue_number",
            "value": null,
            "display": "Volume issue number"
        },
        {
            "key": "masterlist",
            "value": "satyajit-ray",
            "display": "Masterlist"
        },
        {
            "key": "image",
            "value": ["https://tuliresearchcentre.canto.global/direct/image/sc3ksj6h1178386ols1492457k/v6aSlzUWLa-ZAlDy81Yh4k1iBUk/m800/800"],
            "display": "Image"
        },
        {
            "key": "document_type",
            "value": "bke",
            "display": "Document type"
        },
        {
            "key": "title_and_type_of_creator",
            "value": "Editor",
            "display": "Title and type of creator"
        },
        {
            "key": "number_of_pages_in_publication",
            "value": null,
            "display": "Number of pages in publication"
        },
        {
            "key": "researchCategories",
            "value": [
                {
                    "key": "Cinema as a Key Educational Resource",
                    "value": "CinemaE",
                    "link": "cinema-as-a-key-educational-resource"
                },
                {
                    "key": "History & Historiography of Indian Scholarship in the Arts, Social Sciences & Humanities",
                    "value": "Scholarship",
                    "link": "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities"
                }
            ],
            "display": "Research categories"
        },
        {
            "key": "creator",
            "value": [
                {
                    "key": "andrew-robinson",
                    "value": "Andrew Robinson",
                    "first_name": "Andrew",
                    "last_name": "Robinson"
                }
            ],
            "display": "Creator"
        }
    ],
    [
        {
            "key": "id",
            "value": "b9816a6c-d324-403a-a9b9-11bdb4c1e452",
            "display": "Id"
        },
        {
            "key": "title",
            "value": null,
            "display": "Title"
        },
        {
            "key": "alias",
            "value": "bka-robinson-satyajit-ray-1990",
            "display": "Alias"
        },
        {
            "key": "web_serial_number",
            "value": null,
            "display": "Web serial number"
        },
        {
            "key": "reference_code",
            "value": null,
            "display": "Reference code"
        },
        {
            "key": "web_display",
            "value": null,
            "display": "Web display"
        },
        {
            "key": "date_of_library_publication",
            "value": null,
            "display": "Date of library publication"
        },
        {
            "key": "place_of_publication",
            "value": "Calcutta",
            "display": "Place of publication"
        },
        {
            "key": "histographical_note",
            "value": null,
            "display": "Histographical note"
        },
        {
            "key": "main_historical_note",
            "value": null,
            "display": "Main historical note"
        },
        {
            "key": "binding_status",
            "value": "PB",
            "display": "Binding status"
        },
        {
            "key": "accession_number",
            "value": "0000027",
            "display": "Accession number"
        },
        {
            "key": "cdt",
            "value": null,
            "display": "CDT"
        },
        {
            "key": "country_of_publication",
            "value": "India",
            "display": "Country of publication"
        },
        {
            "key": "date_of_published_edition_in_library",
            "value": "",
            "display": "Date of published edition in library"
        },
        {
            "key": "dates_of_first_edition",
            "value": "",
            "display": "Dates of first edition"
        },
        {
            "key": "isbn_issn_number",
            "value": "",
            "display": "Isbn issn number"
        },
        {
            "key": "language_of_publication",
            "value": "",
            "display": "Language of publication"
        },
        {
            "key": "number_of_pages_in_publication_text",
            "value": null,
            "display": "Number of pages in publication text"
        },
        {
            "key": "personality_film_visually_featured_on_cover",
            "value": null,
            "display": "Personality film visually featured on cover"
        },
        {
            "key": "publisher",
            "value": "Rupa Paperback",
            "display": "Publisher"
        },
        {
            "key": "serial_number",
            "value": null,
            "display": "Serial number"
        },
        {
            "key": "sortable_year_date_of_publication",
            "value": "1990",
            "display": "Sortable year date of publication"
        },
        {
            "key": "sub_title_of_document_book",
            "value": null,
            "display": "Sub title of document book"
        },
        {
            "key": "title_of_document_book",
            "value": null,
            "display": "Title of document book"
        },
        {
            "key": "book_title_of_document",
            "value": "Satyajit Ray",
            "display": "Book title of document"
        },
        {
            "key": "book_sub_title_of_document",
            "value": "The Inner Eye",
            "display": "Book sub title of document"
        },
        {
            "key": "volume_issue_number",
            "value": null,
            "display": "Volume issue number"
        },
        {
            "key": "masterlist",
            "value": "satyajit-ray",
            "display": "Masterlist"
        },
        {
            "key": "image",
            "value": ["https://tuliresearchcentre.canto.global/direct/image/2t22bqd4rp0kd7396d6hl5v113/hqcYOOT5KAiAQ9Lr6qV3lWgORz8/m800/800"],
            "display": "Image"
        },
        {
            "key": "document_type",
            "value": "bka",
            "display": "Document type"
        },
        {
            "key": "title_and_type_of_creator",
            "value": "Author",
            "display": "Title and type of creator"
        },
        {
            "key": "number_of_pages_in_publication",
            "value": null,
            "display": "Number of pages in publication"
        },
        {
            "key": "researchCategories",
            "value": [
                {
                    "key": "Cinema as a Key Educational Resource",
                    "value": "CinemaE",
                    "link": "cinema-as-a-key-educational-resource"
                },
                {
                    "key": "History & Historiography of Indian Scholarship in the Arts, Social Sciences & Humanities",
                    "value": "Scholarship",
                    "link": "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities"
                }
            ],
            "display": "Research categories"
        },
        {
            "key": "creator",
            "value": [
                {
                    "key": "andrew-robinson",
                    "value": "Andrew Robinson",
                    "first_name": "Andrew",
                    "last_name": "Robinson"
                }
            ],
            "display": "Creator"
        }
    ],
    [
        {
            "key": "id",
            "value": "718d356d-3f78-4b9f-ae1e-7c2ca454d4f4",
            "display": "Id"
        },
        {
            "key": "title",
            "value": null,
            "display": "Title"
        },
        {
            "key": "alias",
            "value": "bka-nandi-satyajit-ray-at-70-1991",
            "display": "Alias"
        },
        {
            "key": "web_serial_number",
            "value": null,
            "display": "Web serial number"
        },
        {
            "key": "reference_code",
            "value": null,
            "display": "Reference code"
        },
        {
            "key": "web_display",
            "value": null,
            "display": "Web display"
        },
        {
            "key": "date_of_library_publication",
            "value": null,
            "display": "Date of library publication"
        },
        {
            "key": "place_of_publication",
            "value": "Brussels",
            "display": "Place of publication"
        },
        {
            "key": "histographical_note",
            "value": null,
            "display": "Histographical note"
        },
        {
            "key": "main_historical_note",
            "value": null,
            "display": "Main historical note"
        },
        {
            "key": "binding_status",
            "value": "PB",
            "display": "Binding status"
        },
        {
            "key": "accession_number",
            "value": "0000058",
            "display": "Accession number"
        },
        {
            "key": "cdt",
            "value": null,
            "display": "CDT"
        },
        {
            "key": "country_of_publication",
            "value": "Belgium",
            "display": "Country of publication"
        },
        {
            "key": "date_of_published_edition_in_library",
            "value": "1991",
            "display": "Date of published edition in library"
        },
        {
            "key": "dates_of_first_edition",
            "value": "",
            "display": "Dates of first edition"
        },
        {
            "key": "isbn_issn_number",
            "value": "29300100061",
            "display": "Isbn issn number"
        },
        {
            "key": "language_of_publication",
            "value": "",
            "display": "Language of publication"
        },
        {
            "key": "number_of_pages_in_publication_text",
            "value": null,
            "display": "Number of pages in publication text"
        },
        {
            "key": "personality_film_visually_featured_on_cover",
            "value": null,
            "display": "Personality film visually featured on cover"
        },
        {
            "key": "publisher",
            "value": "Eiffel Editions",
            "display": "Publisher"
        },
        {
            "key": "serial_number",
            "value": null,
            "display": "Serial number"
        },
        {
            "key": "sortable_year_date_of_publication",
            "value": "1991",
            "display": "Sortable year date of publication"
        },
        {
            "key": "sub_title_of_document_book",
            "value": null,
            "display": "Sub title of document book"
        },
        {
            "key": "title_of_document_book",
            "value": null,
            "display": "Title of document book"
        },
        {
            "key": "book_title_of_document",
            "value": "Satyajit Ray at 70",
            "display": "Book title of document"
        },
        {
            "key": "book_sub_title_of_document",
            "value": "As Writer, Designer, Actor, Director, Cameraman, Editor, Composer",
            "display": "Book sub title of document"
        },
        {
            "key": "volume_issue_number",
            "value": null,
            "display": "Volume issue number"
        },
        {
            "key": "masterlist",
            "value": "satyajit-ray",
            "display": "Masterlist"
        },
        {
            "key": "image",
            "value": ["https://tuliresearchcentre.canto.global/direct/image/9dnslph5pp33t38fnsr4o91r2q/WU0_4_uX2F_TwWMEzNfq2c7sKeE/m800/800"],
            "display": "Image"
        },
        {
            "key": "document_type",
            "value": "bka",
            "display": "Document type"
        },
        {
            "key": "title_and_type_of_creator",
            "value": "Author",
            "display": "Title and type of creator"
        },
        {
            "key": "number_of_pages_in_publication",
            "value": null,
            "display": "Number of pages in publication"
        },
        {
            "key": "researchCategories",
            "value": [
                {
                    "key": "Popular Arts, Printed Image & Crafts of India",
                    "value": "PopCult",
                    "link": "popular-arts-printed-image-crafts-of-india"
                },
                {
                    "key": "Cinema as a Key Educational Resource",
                    "value": "CinemaE",
                    "link": "cinema-as-a-key-educational-resource"
                }
            ],
            "display": "Research categories"
        },
        {
            "key": "creator",
            "value": [
                {
                    "key": "alok-b-nandi",
                    "value": "Alok B Nandi",
                    "first_name": "Alok-B",
                    "last_name": "Nandi"
                }
            ],
            "display": "Creator"
        }
    ],
    [
        {
            "key": "id",
            "value": "9406cbe7-70b3-40e6-8f0c-4e326dc09c49",
            "display": "Id"
        },
        {
            "key": "title",
            "value": null,
            "display": "Title"
        },
        {
            "key": "alias",
            "value": "bka-gupta-the-painted-face-1991",
            "display": "Alias"
        },
        {
            "key": "web_serial_number",
            "value": null,
            "display": "Web serial number"
        },
        {
            "key": "reference_code",
            "value": null,
            "display": "Reference code"
        },
        {
            "key": "web_display",
            "value": null,
            "display": "Web display"
        },
        {
            "key": "date_of_library_publication",
            "value": null,
            "display": "Date of library publication"
        },
        {
            "key": "place_of_publication",
            "value": "New Delhi",
            "display": "Place of publication"
        },
        {
            "key": "histographical_note",
            "value": null,
            "display": "Histographical note"
        },
        {
            "key": "main_historical_note",
            "value": null,
            "display": "Main historical note"
        },
        {
            "key": "binding_status",
            "value": "HB",
            "display": "Binding status"
        },
        {
            "key": "accession_number",
            "value": "0000078",
            "display": "Accession number"
        },
        {
            "key": "cdt",
            "value": null,
            "display": "CDT"
        },
        {
            "key": "country_of_publication",
            "value": "India",
            "display": "Country of publication"
        },
        {
            "key": "date_of_published_edition_in_library",
            "value": "1991",
            "display": "Date of published edition in library"
        },
        {
            "key": "dates_of_first_edition",
            "value": "",
            "display": "Dates of first edition"
        },
        {
            "key": "isbn_issn_number",
            "value": "",
            "display": "Isbn issn number"
        },
        {
            "key": "language_of_publication",
            "value": "",
            "display": "Language of publication"
        },
        {
            "key": "number_of_pages_in_publication_text",
            "value": null,
            "display": "Number of pages in publication text"
        },
        {
            "key": "personality_film_visually_featured_on_cover",
            "value": null,
            "display": "Personality film visually featured on cover"
        },
        {
            "key": "publisher",
            "value": "Roli Books",
            "display": "Publisher"
        },
        {
            "key": "serial_number",
            "value": null,
            "display": "Serial number"
        },
        {
            "key": "sortable_year_date_of_publication",
            "value": "1991",
            "display": "Sortable year date of publication"
        },
        {
            "key": "sub_title_of_document_book",
            "value": null,
            "display": "Sub title of document book"
        },
        {
            "key": "title_of_document_book",
            "value": null,
            "display": "Title of document book"
        },
        {
            "key": "book_title_of_document",
            "value": "The Painted Face",
            "display": "Book title of document"
        },
        {
            "key": "book_sub_title_of_document",
            "value": "Studies in India's Popular Cinema",
            "display": "Book sub title of document"
        },
        {
            "key": "volume_issue_number",
            "value": null,
            "display": "Volume issue number"
        },
        {
            "key": "masterlist",
            "value": "satyajit-ray",
            "display": "Masterlist"
        },
        {
            "key": "image",
            "value": ["https://tuliresearchcentre.canto.global/direct/image/uiektppa3555pfe2h0l842un1m/eVyPuv00q2jg7yV6Xfnm83hxLyA/m800/800"],
            "display": "Image"
        },
        {
            "key": "document_type",
            "value": "bka",
            "display": "Document type"
        },
        {
            "key": "title_and_type_of_creator",
            "value": "Author",
            "display": "Title and type of creator"
        },
        {
            "key": "number_of_pages_in_publication",
            "value": null,
            "display": "Number of pages in publication"
        },
        {
            "key": "researchCategories",
            "value": [
                {
                    "key": "Cinema as a Key Educational Resource",
                    "value": "CinemaE",
                    "link": "cinema-as-a-key-educational-resource"
                },
                {
                    "key": "History & Historiography of Indian Scholarship in the Arts, Social Sciences & Humanities",
                    "value": "Scholarship",
                    "link": "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities"
                }
            ],
            "display": "Research categories"
        },
        {
            "key": "creator",
            "value": [
                {
                    "key": "chidananda-das-gupta",
                    "value": "Chidananda Das Gupta",
                    "first_name": "Chidananda-Das",
                    "last_name": "Gupta"
                }
            ],
            "display": "Creator"
        }
    ],
    [
        {
            "key": "id",
            "value": "39fd35f0-3689-447c-a42b-c3b72907001c",
            "display": "Id"
        },
        {
            "key": "title",
            "value": null,
            "display": "Title"
        },
        {
            "key": "alias",
            "value": "bka-sarkar-the-world-of-satyajit-ray-1992",
            "display": "Alias"
        },
        {
            "key": "web_serial_number",
            "value": null,
            "display": "Web serial number"
        },
        {
            "key": "reference_code",
            "value": null,
            "display": "Reference code"
        },
        {
            "key": "web_display",
            "value": null,
            "display": "Web display"
        },
        {
            "key": "date_of_library_publication",
            "value": null,
            "display": "Date of library publication"
        },
        {
            "key": "place_of_publication",
            "value": "New Delhi",
            "display": "Place of publication"
        },
        {
            "key": "histographical_note",
            "value": null,
            "display": "Histographical note"
        },
        {
            "key": "main_historical_note",
            "value": null,
            "display": "Main historical note"
        },
        {
            "key": "binding_status",
            "value": "HB",
            "display": "Binding status"
        },
        {
            "key": "accession_number",
            "value": "0000071",
            "display": "Accession number"
        },
        {
            "key": "cdt",
            "value": null,
            "display": "CDT"
        },
        {
            "key": "country_of_publication",
            "value": "India",
            "display": "Country of publication"
        },
        {
            "key": "date_of_published_edition_in_library",
            "value": "1992",
            "display": "Date of published edition in library"
        },
        {
            "key": "dates_of_first_edition",
            "value": "",
            "display": "Dates of first edition"
        },
        {
            "key": "isbn_issn_number",
            "value": "81-85674043",
            "display": "Isbn issn number"
        },
        {
            "key": "language_of_publication",
            "value": "",
            "display": "Language of publication"
        },
        {
            "key": "number_of_pages_in_publication_text",
            "value": null,
            "display": "Number of pages in publication text"
        },
        {
            "key": "personality_film_visually_featured_on_cover",
            "value": null,
            "display": "Personality film visually featured on cover"
        },
        {
            "key": "publisher",
            "value": "UBSPD",
            "display": "Publisher"
        },
        {
            "key": "serial_number",
            "value": null,
            "display": "Serial number"
        },
        {
            "key": "sortable_year_date_of_publication",
            "value": "1992",
            "display": "Sortable year date of publication"
        },
        {
            "key": "sub_title_of_document_book",
            "value": null,
            "display": "Sub title of document book"
        },
        {
            "key": "title_of_document_book",
            "value": null,
            "display": "Title of document book"
        },
        {
            "key": "book_title_of_document",
            "value": "The World of Satyajit Ray",
            "display": "Book title of document"
        },
        {
            "key": "book_sub_title_of_document",
            "value": "",
            "display": "Book sub title of document"
        },
        {
            "key": "volume_issue_number",
            "value": null,
            "display": "Volume issue number"
        },
        {
            "key": "masterlist",
            "value": "satyajit-ray",
            "display": "Masterlist"
        },
        {
            "key": "image",
            "value": ["https://tuliresearchcentre.canto.global/direct/image/agkvgtd3j95k936ef3tnfhjb1j/czNxALR360OzrpqCeSPpydr1zDo/m800/800"],
            "display": "Image"
        },
        {
            "key": "document_type",
            "value": "bka",
            "display": "Document type"
        },
        {
            "key": "title_and_type_of_creator",
            "value": "Author",
            "display": "Title and type of creator"
        },
        {
            "key": "number_of_pages_in_publication",
            "value": null,
            "display": "Number of pages in publication"
        },
        {
            "key": "researchCategories",
            "value": [
                {
                    "key": "Cinema as a Key Educational Resource",
                    "value": "CinemaE",
                    "link": "cinema-as-a-key-educational-resource"
                },
                {
                    "key": "History & Historiography of Indian Scholarship in the Arts, Social Sciences & Humanities",
                    "value": "Scholarship",
                    "link": "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities"
                }
            ],
            "display": "Research categories"
        },
        {
            "key": "creator",
            "value": [
                {
                    "key": "bidyut-sarkar",
                    "value": "Bidyut Sarkar",
                    "first_name": "Bidyut",
                    "last_name": "Sarkar"
                }
            ],
            "display": "Creator"
        }
    ],
    [
        {
            "key": "id",
            "value": "fbecebb4-6f82-47b4-bf0c-a363a78d1ba3",
            "display": "Id"
        },
        {
            "key": "title",
            "value": null,
            "display": "Title"
        },
        {
            "key": "alias",
            "value": "bka-tesson-satyajit-ray-1992",
            "display": "Alias"
        },
        {
            "key": "web_serial_number",
            "value": null,
            "display": "Web serial number"
        },
        {
            "key": "reference_code",
            "value": null,
            "display": "Reference code"
        },
        {
            "key": "web_display",
            "value": null,
            "display": "Web display"
        },
        {
            "key": "date_of_library_publication",
            "value": null,
            "display": "Date of library publication"
        },
        {
            "key": "place_of_publication",
            "value": "Paris",
            "display": "Place of publication"
        },
        {
            "key": "histographical_note",
            "value": null,
            "display": "Histographical note"
        },
        {
            "key": "main_historical_note",
            "value": null,
            "display": "Main historical note"
        },
        {
            "key": "binding_status",
            "value": "",
            "display": "Binding status"
        },
        {
            "key": "accession_number",
            "value": "0000086",
            "display": "Accession number"
        },
        {
            "key": "cdt",
            "value": null,
            "display": "CDT"
        },
        {
            "key": "country_of_publication",
            "value": "France",
            "display": "Country of publication"
        },
        {
            "key": "date_of_published_edition_in_library",
            "value": "1992",
            "display": "Date of published edition in library"
        },
        {
            "key": "dates_of_first_edition",
            "value": "",
            "display": "Dates of first edition"
        },
        {
            "key": "isbn_issn_number",
            "value": "2866421175",
            "display": "Isbn issn number"
        },
        {
            "key": "language_of_publication",
            "value": "",
            "display": "Language of publication"
        },
        {
            "key": "number_of_pages_in_publication_text",
            "value": null,
            "display": "Number of pages in publication text"
        },
        {
            "key": "personality_film_visually_featured_on_cover",
            "value": null,
            "display": "Personality film visually featured on cover"
        },
        {
            "key": "publisher",
            "value": "Cahiers Du Cine",
            "display": "Publisher"
        },
        {
            "key": "serial_number",
            "value": null,
            "display": "Serial number"
        },
        {
            "key": "sortable_year_date_of_publication",
            "value": "1992",
            "display": "Sortable year date of publication"
        },
        {
            "key": "sub_title_of_document_book",
            "value": null,
            "display": "Sub title of document book"
        },
        {
            "key": "title_of_document_book",
            "value": null,
            "display": "Title of document book"
        },
        {
            "key": "book_title_of_document",
            "value": "Satyajit Ray",
            "display": "Book title of document"
        },
        {
            "key": "book_sub_title_of_document",
            "value": "",
            "display": "Book sub title of document"
        },
        {
            "key": "volume_issue_number",
            "value": null,
            "display": "Volume issue number"
        },
        {
            "key": "masterlist",
            "value": "satyajit-ray",
            "display": "Masterlist"
        },
        {
            "key": "image",
            "value": ["https://tuliresearchcentre.canto.global/direct/image/jrdnpfuaj138v8rhidkg593449/rzd7GgACikU_nW7o1wFXCR5iklE/m800/800"],
            "display": "Image"
        },
        {
            "key": "document_type",
            "value": "bka",
            "display": "Document type"
        },
        {
            "key": "title_and_type_of_creator",
            "value": "Author",
            "display": "Title and type of creator"
        },
        {
            "key": "number_of_pages_in_publication",
            "value": null,
            "display": "Number of pages in publication"
        },
        {
            "key": "researchCategories",
            "value": [
                {
                    "key": "Cinema as a Key Educational Resource",
                    "value": "CinemaE",
                    "link": "cinema-as-a-key-educational-resource"
                }
            ],
            "display": "Research categories"
        },
        {
            "key": "creator",
            "value": [
                {
                    "key": "charles-tesson",
                    "value": "Charles Tesson",
                    "first_name": "Charles",
                    "last_name": "Tesson"
                }
            ],
            "display": "Creator"
        }
    ],
    [
        {
            "key": "id",
            "value": "922e8c49-8b80-4c3f-a5b5-3bcc69263238",
            "display": "Id"
        },
        {
            "key": "title",
            "value": null,
            "display": "Title"
        },
        {
            "key": "alias",
            "value": "bka-chakravarty-national-identity-in-indian-popular-cinema-1993",
            "display": "Alias"
        },
        {
            "key": "web_serial_number",
            "value": null,
            "display": "Web serial number"
        },
        {
            "key": "reference_code",
            "value": null,
            "display": "Reference code"
        },
        {
            "key": "web_display",
            "value": null,
            "display": "Web display"
        },
        {
            "key": "date_of_library_publication",
            "value": null,
            "display": "Date of library publication"
        },
        {
            "key": "place_of_publication",
            "value": "New Delhi",
            "display": "Place of publication"
        },
        {
            "key": "histographical_note",
            "value": null,
            "display": "Histographical note"
        },
        {
            "key": "main_historical_note",
            "value": null,
            "display": "Main historical note"
        },
        {
            "key": "binding_status",
            "value": "PB",
            "display": "Binding status"
        },
        {
            "key": "accession_number",
            "value": "0000068",
            "display": "Accession number"
        },
        {
            "key": "cdt",
            "value": null,
            "display": "CDT"
        },
        {
            "key": "country_of_publication",
            "value": "India",
            "display": "Country of publication"
        },
        {
            "key": "date_of_published_edition_in_library",
            "value": "1993",
            "display": "Date of published edition in library"
        },
        {
            "key": "dates_of_first_edition",
            "value": "",
            "display": "Dates of first edition"
        },
        {
            "key": "isbn_issn_number",
            "value": "0195647475",
            "display": "Isbn issn number"
        },
        {
            "key": "language_of_publication",
            "value": "",
            "display": "Language of publication"
        },
        {
            "key": "number_of_pages_in_publication_text",
            "value": null,
            "display": "Number of pages in publication text"
        },
        {
            "key": "personality_film_visually_featured_on_cover",
            "value": null,
            "display": "Personality film visually featured on cover"
        },
        {
            "key": "publisher",
            "value": "Oxford University",
            "display": "Publisher"
        },
        {
            "key": "serial_number",
            "value": null,
            "display": "Serial number"
        },
        {
            "key": "sortable_year_date_of_publication",
            "value": "1993",
            "display": "Sortable year date of publication"
        },
        {
            "key": "sub_title_of_document_book",
            "value": null,
            "display": "Sub title of document book"
        },
        {
            "key": "title_of_document_book",
            "value": null,
            "display": "Title of document book"
        },
        {
            "key": "book_title_of_document",
            "value": "National Identity in Indian Popular Cinema",
            "display": "Book title of document"
        },
        {
            "key": "book_sub_title_of_document",
            "value": "1947-1987",
            "display": "Book sub title of document"
        },
        {
            "key": "volume_issue_number",
            "value": null,
            "display": "Volume issue number"
        },
        {
            "key": "masterlist",
            "value": "satyajit-ray",
            "display": "Masterlist"
        },
        {
            "key": "image",
            "value": ["https://tuliresearchcentre.canto.global/direct/image/mg8bjc8mb16j184l2c7hmcuv41/aO3Pm2CAoyTCAwFwQtoCcmFlzn8/m800/800"],
            "display": "Image"
        },
        {
            "key": "document_type",
            "value": "bka",
            "display": "Document type"
        },
        {
            "key": "title_and_type_of_creator",
            "value": "Author",
            "display": "Title and type of creator"
        },
        {
            "key": "number_of_pages_in_publication",
            "value": null,
            "display": "Number of pages in publication"
        },
        {
            "key": "researchCategories",
            "value": [
                {
                    "key": "History & Historiography of Indian Scholarship in the Arts, Social Sciences & Humanities",
                    "value": "Scholarship",
                    "link": "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities"
                },
                {
                    "key": "Cinema as a Key Educational Resource",
                    "value": "CinemaE",
                    "link": "cinema-as-a-key-educational-resource"
                }
            ],
            "display": "Research categories"
        },
        {
            "key": "creator",
            "value": [
                {
                    "key": "sumita-s-chakravarty",
                    "value": "Sumita S Chakravarty",
                    "first_name": "Sumita-S",
                    "last_name": "Chakravarty"
                }
            ],
            "display": "Creator"
        }
    ],
    [
        {
            "key": "id",
            "value": "a657e0f8-9b43-4712-b09f-56522279927a",
            "display": "Id"
        },
        {
            "key": "title",
            "value": null,
            "display": "Title"
        },
        {
            "key": "alias",
            "value": "bka-ray-my-years-with-apu-1994",
            "display": "Alias"
        },
        {
            "key": "web_serial_number",
            "value": null,
            "display": "Web serial number"
        },
        {
            "key": "reference_code",
            "value": null,
            "display": "Reference code"
        },
        {
            "key": "web_display",
            "value": null,
            "display": "Web display"
        },
        {
            "key": "date_of_library_publication",
            "value": null,
            "display": "Date of library publication"
        },
        {
            "key": "place_of_publication",
            "value": "New Delhi",
            "display": "Place of publication"
        },
        {
            "key": "histographical_note",
            "value": null,
            "display": "Histographical note"
        },
        {
            "key": "main_historical_note",
            "value": null,
            "display": "Main historical note"
        },
        {
            "key": "binding_status",
            "value": "HB",
            "display": "Binding status"
        },
        {
            "key": "accession_number",
            "value": "0000028",
            "display": "Accession number"
        },
        {
            "key": "cdt",
            "value": null,
            "display": "CDT"
        },
        {
            "key": "country_of_publication",
            "value": "India",
            "display": "Country of publication"
        },
        {
            "key": "date_of_published_edition_in_library",
            "value": "1994",
            "display": "Date of published edition in library"
        },
        {
            "key": "dates_of_first_edition",
            "value": "",
            "display": "Dates of first edition"
        },
        {
            "key": "isbn_issn_number",
            "value": "0-670862150",
            "display": "Isbn issn number"
        },
        {
            "key": "language_of_publication",
            "value": "",
            "display": "Language of publication"
        },
        {
            "key": "number_of_pages_in_publication_text",
            "value": null,
            "display": "Number of pages in publication text"
        },
        {
            "key": "personality_film_visually_featured_on_cover",
            "value": null,
            "display": "Personality film visually featured on cover"
        },
        {
            "key": "publisher",
            "value": "Penguin Books India",
            "display": "Publisher"
        },
        {
            "key": "serial_number",
            "value": null,
            "display": "Serial number"
        },
        {
            "key": "sortable_year_date_of_publication",
            "value": "1994",
            "display": "Sortable year date of publication"
        },
        {
            "key": "sub_title_of_document_book",
            "value": null,
            "display": "Sub title of document book"
        },
        {
            "key": "title_of_document_book",
            "value": null,
            "display": "Title of document book"
        },
        {
            "key": "book_title_of_document",
            "value": "My Years With Apu",
            "display": "Book title of document"
        },
        {
            "key": "book_sub_title_of_document",
            "value": "A Memoir",
            "display": "Book sub title of document"
        },
        {
            "key": "volume_issue_number",
            "value": null,
            "display": "Volume issue number"
        },
        {
            "key": "masterlist",
            "value": "satyajit-ray",
            "display": "Masterlist"
        },
        {
            "key": "image",
            "value": ["https://tuliresearchcentre.canto.global/direct/image/ck1brc7e1h1e75d0emtcdp0875/uOgaVOAKmaPNzQccZcM7fSexyBc/m800/800"],
            "display": "Image"
        },
        {
            "key": "document_type",
            "value": "bka",
            "display": "Document type"
        },
        {
            "key": "title_and_type_of_creator",
            "value": "Author",
            "display": "Title and type of creator"
        },
        {
            "key": "number_of_pages_in_publication",
            "value": null,
            "display": "Number of pages in publication"
        },
        {
            "key": "researchCategories",
            "value": [
                {
                    "key": "Cinema as a Key Educational Resource",
                    "value": "CinemaE",
                    "link": "cinema-as-a-key-educational-resource"
                },
                {
                    "key": "Changing Smile of Childhood and its Second Coming",
                    "value": "Childhood",
                    "link": "changing-smile-of-childhood-and-its-second-coming"
                }
            ],
            "display": "Research categories"
        },
        {
            "key": "creator",
            "value": [
                {
                    "key": "satyajit-ray",
                    "value": null,
                    "first_name": null,
                    "last_name": null
                }
            ],
            "display": "Creator"
        }
    ],
    [
        {
            "key": "id",
            "value": "52bd7a7a-6871-481b-8c08-380629069069",
            "display": "Id"
        },
        {
            "key": "title",
            "value": null,
            "display": "Title"
        },
        {
            "key": "alias",
            "value": "bke-vasudev-frames-of-mind-1995",
            "display": "Alias"
        },
        {
            "key": "web_serial_number",
            "value": null,
            "display": "Web serial number"
        },
        {
            "key": "reference_code",
            "value": null,
            "display": "Reference code"
        },
        {
            "key": "web_display",
            "value": null,
            "display": "Web display"
        },
        {
            "key": "date_of_library_publication",
            "value": null,
            "display": "Date of library publication"
        },
        {
            "key": "place_of_publication",
            "value": "New Delhi",
            "display": "Place of publication"
        },
        {
            "key": "histographical_note",
            "value": null,
            "display": "Histographical note"
        },
        {
            "key": "main_historical_note",
            "value": null,
            "display": "Main historical note"
        },
        {
            "key": "binding_status",
            "value": "HB",
            "display": "Binding status"
        },
        {
            "key": "accession_number",
            "value": "0000093",
            "display": "Accession number"
        },
        {
            "key": "cdt",
            "value": null,
            "display": "CDT"
        },
        {
            "key": "country_of_publication",
            "value": "India",
            "display": "Country of publication"
        },
        {
            "key": "date_of_published_edition_in_library",
            "value": "1995",
            "display": "Date of published edition in library"
        },
        {
            "key": "dates_of_first_edition",
            "value": "",
            "display": "Dates of first edition"
        },
        {
            "key": "isbn_issn_number",
            "value": "8174760539",
            "display": "Isbn issn number"
        },
        {
            "key": "language_of_publication",
            "value": "",
            "display": "Language of publication"
        },
        {
            "key": "number_of_pages_in_publication_text",
            "value": null,
            "display": "Number of pages in publication text"
        },
        {
            "key": "personality_film_visually_featured_on_cover",
            "value": null,
            "display": "Personality film visually featured on cover"
        },
        {
            "key": "publisher",
            "value": "UBS",
            "display": "Publisher"
        },
        {
            "key": "serial_number",
            "value": null,
            "display": "Serial number"
        },
        {
            "key": "sortable_year_date_of_publication",
            "value": "1995",
            "display": "Sortable year date of publication"
        },
        {
            "key": "sub_title_of_document_book",
            "value": null,
            "display": "Sub title of document book"
        },
        {
            "key": "title_of_document_book",
            "value": null,
            "display": "Title of document book"
        },
        {
            "key": "book_title_of_document",
            "value": "Frames of Mind",
            "display": "Book title of document"
        },
        {
            "key": "book_sub_title_of_document",
            "value": "Reflections on Indian Cinema",
            "display": "Book sub title of document"
        },
        {
            "key": "volume_issue_number",
            "value": null,
            "display": "Volume issue number"
        },
        {
            "key": "masterlist",
            "value": "satyajit-ray",
            "display": "Masterlist"
        },
        {
            "key": "image",
            "value": ["https://tuliresearchcentre.canto.global/direct/image/5qhfeo9uop13j2mjhdf6t2q50o/0OrDPOB9sWBZIekXIFKbvJs4kZE/m800/800"],
            "display": "Image"
        },
        {
            "key": "document_type",
            "value": "bke",
            "display": "Document type"
        },
        {
            "key": "title_and_type_of_creator",
            "value": "Editor",
            "display": "Title and type of creator"
        },
        {
            "key": "number_of_pages_in_publication",
            "value": null,
            "display": "Number of pages in publication"
        },
        {
            "key": "researchCategories",
            "value": [
                {
                    "key": "History & Historiography of Indian Scholarship in the Arts, Social Sciences & Humanities",
                    "value": "Scholarship",
                    "link": "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities"
                },
                {
                    "key": "India and her Relationship with the World",
                    "value": "IndoWorld",
                    "link": "india-and-her-relationship-with-the-world"
                },
                {
                    "key": "Cinema as a Key Educational Resource",
                    "value": "CinemaE",
                    "link": "cinema-as-a-key-educational-resource"
                }
            ],
            "display": "Research categories"
        },
        {
            "key": "creator",
            "value": [
                {
                    "key": "aruna-vasudev",
                    "value": "Aruna Vasudev",
                    "first_name": "Aruna",
                    "last_name": "Vasudev"
                }
            ],
            "display": "Creator"
        }
    ],
    [
        {
            "key": "id",
            "value": "7537d010-2cff-479b-b4cb-d42f89fe9c5e",
            "display": "Id"
        },
        {
            "key": "title",
            "value": null,
            "display": "Title"
        },
        {
            "key": "alias",
            "value": "bka-chowdhury-goopy-gyne-bagha-byne-1996",
            "display": "Alias"
        },
        {
            "key": "web_serial_number",
            "value": null,
            "display": "Web serial number"
        },
        {
            "key": "reference_code",
            "value": null,
            "display": "Reference code"
        },
        {
            "key": "web_display",
            "value": null,
            "display": "Web display"
        },
        {
            "key": "date_of_library_publication",
            "value": null,
            "display": "Date of library publication"
        },
        {
            "key": "place_of_publication",
            "value": "Calcutta",
            "display": "Place of publication"
        },
        {
            "key": "histographical_note",
            "value": null,
            "display": "Histographical note"
        },
        {
            "key": "main_historical_note",
            "value": null,
            "display": "Main historical note"
        },
        {
            "key": "binding_status",
            "value": "",
            "display": "Binding status"
        },
        {
            "key": "accession_number",
            "value": "0000026",
            "display": "Accession number"
        },
        {
            "key": "cdt",
            "value": null,
            "display": "CDT"
        },
        {
            "key": "country_of_publication",
            "value": "India",
            "display": "Country of publication"
        },
        {
            "key": "date_of_published_edition_in_library",
            "value": "1996",
            "display": "Date of published edition in library"
        },
        {
            "key": "dates_of_first_edition",
            "value": "",
            "display": "Dates of first edition"
        },
        {
            "key": "isbn_issn_number",
            "value": "",
            "display": "Isbn issn number"
        },
        {
            "key": "language_of_publication",
            "value": "",
            "display": "Language of publication"
        },
        {
            "key": "number_of_pages_in_publication_text",
            "value": null,
            "display": "Number of pages in publication text"
        },
        {
            "key": "personality_film_visually_featured_on_cover",
            "value": null,
            "display": "Personality film visually featured on cover"
        },
        {
            "key": "publisher",
            "value": "Ananda",
            "display": "Publisher"
        },
        {
            "key": "serial_number",
            "value": null,
            "display": "Serial number"
        },
        {
            "key": "sortable_year_date_of_publication",
            "value": "1996",
            "display": "Sortable year date of publication"
        },
        {
            "key": "sub_title_of_document_book",
            "value": null,
            "display": "Sub title of document book"
        },
        {
            "key": "title_of_document_book",
            "value": null,
            "display": "Title of document book"
        },
        {
            "key": "book_title_of_document",
            "value": "Goopy Gyne Bagha Byne",
            "display": "Book title of document"
        },
        {
            "key": "book_sub_title_of_document",
            "value": "",
            "display": "Book sub title of document"
        },
        {
            "key": "volume_issue_number",
            "value": null,
            "display": "Volume issue number"
        },
        {
            "key": "masterlist",
            "value": "satyajit-ray",
            "display": "Masterlist"
        },
        {
            "key": "image",
            "value": ["https://tuliresearchcentre.canto.global/direct/image/meg4vb5u6p4c969gd7ir2ll679/ApS4WJyeez-CGyCHuKeWn5uZlCM/m800/800"],
            "display": "Image"
        },
        {
            "key": "document_type",
            "value": "bka",
            "display": "Document type"
        },
        {
            "key": "title_and_type_of_creator",
            "value": "Author",
            "display": "Title and type of creator"
        },
        {
            "key": "number_of_pages_in_publication",
            "value": null,
            "display": "Number of pages in publication"
        },
        {
            "key": "researchCategories",
            "value": [
                {
                    "key": "Cinema as a Key Educational Resource",
                    "value": "CinemaE",
                    "link": "cinema-as-a-key-educational-resource"
                },
                {
                    "key": "Changing Smile of Childhood and its Second Coming",
                    "value": "Childhood",
                    "link": "changing-smile-of-childhood-and-its-second-coming"
                }
            ],
            "display": "Research categories"
        },
        {
            "key": "creator",
            "value": [
                {
                    "key": "upendra-kishor-ray-chowdhury",
                    "value": "Upendra Kishor Ray Chowdhury",
                    "first_name": "Upendra-Kishor-Ray",
                    "last_name": "Chowdhury"
                }
            ],
            "display": "Creator"
        }
    ],
    [
        {
            "key": "id",
            "value": "13b43bea-9560-4d8f-b637-2f634e4bad45",
            "display": "Id"
        },
        {
            "key": "title",
            "value": null,
            "display": "Title"
        },
        {
            "key": "alias",
            "value": "bka-elena-satyajit-ray-1998",
            "display": "Alias"
        },
        {
            "key": "web_serial_number",
            "value": null,
            "display": "Web serial number"
        },
        {
            "key": "reference_code",
            "value": null,
            "display": "Reference code"
        },
        {
            "key": "web_display",
            "value": null,
            "display": "Web display"
        },
        {
            "key": "date_of_library_publication",
            "value": null,
            "display": "Date of library publication"
        },
        {
            "key": "place_of_publication",
            "value": "Madrid",
            "display": "Place of publication"
        },
        {
            "key": "histographical_note",
            "value": null,
            "display": "Histographical note"
        },
        {
            "key": "main_historical_note",
            "value": null,
            "display": "Main historical note"
        },
        {
            "key": "binding_status",
            "value": "",
            "display": "Binding status"
        },
        {
            "key": "accession_number",
            "value": "0000087",
            "display": "Accession number"
        },
        {
            "key": "cdt",
            "value": null,
            "display": "CDT"
        },
        {
            "key": "country_of_publication",
            "value": "Spain",
            "display": "Country of publication"
        },
        {
            "key": "date_of_published_edition_in_library",
            "value": "1998",
            "display": "Date of published edition in library"
        },
        {
            "key": "dates_of_first_edition",
            "value": "",
            "display": "Dates of first edition"
        },
        {
            "key": "isbn_issn_number",
            "value": "978-8437616476",
            "display": "Isbn issn number"
        },
        {
            "key": "language_of_publication",
            "value": "",
            "display": "Language of publication"
        },
        {
            "key": "number_of_pages_in_publication_text",
            "value": null,
            "display": "Number of pages in publication text"
        },
        {
            "key": "personality_film_visually_featured_on_cover",
            "value": null,
            "display": "Personality film visually featured on cover"
        },
        {
            "key": "publisher",
            "value": "Cátedra  ",
            "display": "Publisher"
        },
        {
            "key": "serial_number",
            "value": null,
            "display": "Serial number"
        },
        {
            "key": "sortable_year_date_of_publication",
            "value": "1998",
            "display": "Sortable year date of publication"
        },
        {
            "key": "sub_title_of_document_book",
            "value": null,
            "display": "Sub title of document book"
        },
        {
            "key": "title_of_document_book",
            "value": null,
            "display": "Title of document book"
        },
        {
            "key": "book_title_of_document",
            "value": "Satyajit Ray",
            "display": "Book title of document"
        },
        {
            "key": "book_sub_title_of_document",
            "value": "",
            "display": "Book sub title of document"
        },
        {
            "key": "volume_issue_number",
            "value": null,
            "display": "Volume issue number"
        },
        {
            "key": "masterlist",
            "value": "satyajit-ray",
            "display": "Masterlist"
        },
        {
            "key": "image",
            "value": ["https://tuliresearchcentre.canto.global/direct/image/84mqhqsmjp1jd337h6jnlsfa3u/z0KbsOwv8PsHt7d6-F9gQk7qzls/m800/800"],
            "display": "Image"
        },
        {
            "key": "document_type",
            "value": "bka",
            "display": "Document type"
        },
        {
            "key": "title_and_type_of_creator",
            "value": "Author",
            "display": "Title and type of creator"
        },
        {
            "key": "number_of_pages_in_publication",
            "value": null,
            "display": "Number of pages in publication"
        },
        {
            "key": "researchCategories",
            "value": [
                {
                    "key": "Cinema as a Key Educational Resource",
                    "value": "CinemaE",
                    "link": "cinema-as-a-key-educational-resource"
                }
            ],
            "display": "Research categories"
        },
        {
            "key": "creator",
            "value": [
                {
                    "key": "alberto-elena",
                    "value": "Alberto Elena",
                    "first_name": "Alberto",
                    "last_name": "Elena"
                }
            ],
            "display": "Creator"
        }
    ],
    [
        {
            "key": "id",
            "value": "27a35e76-5278-43a0-9db5-ebabded87f13",
            "display": "Id"
        },
        {
            "key": "title",
            "value": null,
            "display": "Title"
        },
        {
            "key": "alias",
            "value": "bka-prasad-ideology-of-the-hindi-film-1998",
            "display": "Alias"
        },
        {
            "key": "web_serial_number",
            "value": null,
            "display": "Web serial number"
        },
        {
            "key": "reference_code",
            "value": null,
            "display": "Reference code"
        },
        {
            "key": "web_display",
            "value": null,
            "display": "Web display"
        },
        {
            "key": "date_of_library_publication",
            "value": null,
            "display": "Date of library publication"
        },
        {
            "key": "place_of_publication",
            "value": "New Delhi",
            "display": "Place of publication"
        },
        {
            "key": "histographical_note",
            "value": null,
            "display": "Histographical note"
        },
        {
            "key": "main_historical_note",
            "value": null,
            "display": "Main historical note"
        },
        {
            "key": "binding_status",
            "value": "PB",
            "display": "Binding status"
        },
        {
            "key": "accession_number",
            "value": "0000096",
            "display": "Accession number"
        },
        {
            "key": "cdt",
            "value": null,
            "display": "CDT"
        },
        {
            "key": "country_of_publication",
            "value": "India",
            "display": "Country of publication"
        },
        {
            "key": "date_of_published_edition_in_library",
            "value": "2000",
            "display": "Date of published edition in library"
        },
        {
            "key": "dates_of_first_edition",
            "value": "",
            "display": "Dates of first edition"
        },
        {
            "key": "isbn_issn_number",
            "value": "0195652959",
            "display": "Isbn issn number"
        },
        {
            "key": "language_of_publication",
            "value": "",
            "display": "Language of publication"
        },
        {
            "key": "number_of_pages_in_publication_text",
            "value": null,
            "display": "Number of pages in publication text"
        },
        {
            "key": "personality_film_visually_featured_on_cover",
            "value": null,
            "display": "Personality film visually featured on cover"
        },
        {
            "key": "publisher",
            "value": "Oxford University",
            "display": "Publisher"
        },
        {
            "key": "serial_number",
            "value": null,
            "display": "Serial number"
        },
        {
            "key": "sortable_year_date_of_publication",
            "value": "1998",
            "display": "Sortable year date of publication"
        },
        {
            "key": "sub_title_of_document_book",
            "value": null,
            "display": "Sub title of document book"
        },
        {
            "key": "title_of_document_book",
            "value": null,
            "display": "Title of document book"
        },
        {
            "key": "book_title_of_document",
            "value": "Ideology of the Hindi Film",
            "display": "Book title of document"
        },
        {
            "key": "book_sub_title_of_document",
            "value": "A Historical Construction",
            "display": "Book sub title of document"
        },
        {
            "key": "volume_issue_number",
            "value": null,
            "display": "Volume issue number"
        },
        {
            "key": "masterlist",
            "value": "satyajit-ray",
            "display": "Masterlist"
        },
        {
            "key": "image",
            "value": ["https://tuliresearchcentre.canto.global/direct/image/b6mdgmpg552170tl13ohk5ml1v/qZjguVcI8Ox1Vy2c5rNeSn4ocq4/m800/800"],
            "display": "Image"
        },
        {
            "key": "document_type",
            "value": "bka",
            "display": "Document type"
        },
        {
            "key": "title_and_type_of_creator",
            "value": "Author",
            "display": "Title and type of creator"
        },
        {
            "key": "number_of_pages_in_publication",
            "value": null,
            "display": "Number of pages in publication"
        },
        {
            "key": "researchCategories",
            "value": [
                {
                    "key": "History & Historiography of Indian Scholarship in the Arts, Social Sciences & Humanities",
                    "value": "Scholarship",
                    "link": "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities"
                },
                {
                    "key": "Cinema as a Key Educational Resource",
                    "value": "CinemaE",
                    "link": "cinema-as-a-key-educational-resource"
                }
            ],
            "display": "Research categories"
        },
        {
            "key": "creator",
            "value": [
                {
                    "key": "m-madhava-prasad",
                    "value": "M Madhava Prasad",
                    "first_name": "M-Madhava",
                    "last_name": "Prasad"
                }
            ],
            "display": "Creator"
        }
    ],
    [
        {
            "key": "id",
            "value": "69843412-3d51-4e17-8184-4182e71b593c",
            "display": "Id"
        },
        {
            "key": "title",
            "value": null,
            "display": "Title"
        },
        {
            "key": "alias",
            "value": "bke-das-satyajit-ray-1998",
            "display": "Alias"
        },
        {
            "key": "web_serial_number",
            "value": null,
            "display": "Web serial number"
        },
        {
            "key": "reference_code",
            "value": null,
            "display": "Reference code"
        },
        {
            "key": "web_display",
            "value": null,
            "display": "Web display"
        },
        {
            "key": "date_of_library_publication",
            "value": null,
            "display": "Date of library publication"
        },
        {
            "key": "place_of_publication",
            "value": "New Delhi",
            "display": "Place of publication"
        },
        {
            "key": "histographical_note",
            "value": null,
            "display": "Histographical note"
        },
        {
            "key": "main_historical_note",
            "value": null,
            "display": "Main historical note"
        },
        {
            "key": "binding_status",
            "value": "HB",
            "display": "Binding status"
        },
        {
            "key": "accession_number",
            "value": "0000030",
            "display": "Accession number"
        },
        {
            "key": "cdt",
            "value": null,
            "display": "CDT"
        },
        {
            "key": "country_of_publication",
            "value": "India",
            "display": "Country of publication"
        },
        {
            "key": "date_of_published_edition_in_library",
            "value": "1998",
            "display": "Date of published edition in library"
        },
        {
            "key": "dates_of_first_edition",
            "value": "",
            "display": "Dates of first edition"
        },
        {
            "key": "isbn_issn_number",
            "value": "8170237483",
            "display": "Isbn issn number"
        },
        {
            "key": "language_of_publication",
            "value": "",
            "display": "Language of publication"
        },
        {
            "key": "number_of_pages_in_publication_text",
            "value": null,
            "display": "Number of pages in publication text"
        },
        {
            "key": "personality_film_visually_featured_on_cover",
            "value": null,
            "display": "Personality film visually featured on cover"
        },
        {
            "key": "publisher",
            "value": "Allied",
            "display": "Publisher"
        },
        {
            "key": "serial_number",
            "value": null,
            "display": "Serial number"
        },
        {
            "key": "sortable_year_date_of_publication",
            "value": "1998",
            "display": "Sortable year date of publication"
        },
        {
            "key": "sub_title_of_document_book",
            "value": null,
            "display": "Sub title of document book"
        },
        {
            "key": "title_of_document_book",
            "value": null,
            "display": "Title of document book"
        },
        {
            "key": "book_title_of_document",
            "value": "Satyajit Ray",
            "display": "Book title of document"
        },
        {
            "key": "book_sub_title_of_document",
            "value": "An Intimate Master",
            "display": "Book sub title of document"
        },
        {
            "key": "volume_issue_number",
            "value": null,
            "display": "Volume issue number"
        },
        {
            "key": "masterlist",
            "value": "satyajit-ray",
            "display": "Masterlist"
        },
        {
            "key": "image",
            "value": ["https://tuliresearchcentre.canto.global/direct/image/hvno05ago95hrc89t2scm8ra7q/gbrmVVXXY6tbphQw5R8XVY02jjs/m800/800"],
            "display": "Image"
        },
        {
            "key": "document_type",
            "value": "bke",
            "display": "Document type"
        },
        {
            "key": "title_and_type_of_creator",
            "value": "Editor",
            "display": "Title and type of creator"
        },
        {
            "key": "number_of_pages_in_publication",
            "value": null,
            "display": "Number of pages in publication"
        },
        {
            "key": "researchCategories",
            "value": [
                {
                    "key": "History & Historiography of Indian Scholarship in the Arts, Social Sciences & Humanities",
                    "value": "Scholarship",
                    "link": "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities"
                },
                {
                    "key": "Cinema as a Key Educational Resource",
                    "value": "CinemaE",
                    "link": "cinema-as-a-key-educational-resource"
                },
                {
                    "key": "Popular Arts, Printed Image & Crafts of India",
                    "value": "PopCult",
                    "link": "popular-arts-printed-image-crafts-of-india"
                }
            ],
            "display": "Research categories"
        },
        {
            "key": "creator",
            "value": [
                {
                    "key": "santi-das",
                    "value": "Santi Das",
                    "first_name": "Santi",
                    "last_name": "Das"
                }
            ],
            "display": "Creator"
        }
    ],
    [
        {
            "key": "id",
            "value": "e9bfa5c5-7587-48b1-a401-d855bbdc4e2f",
            "display": "Id"
        },
        {
            "key": "title",
            "value": null,
            "display": "Title"
        },
        {
            "key": "alias",
            "value": "bka-elena-los-cines-perifericos-1999",
            "display": "Alias"
        },
        {
            "key": "web_serial_number",
            "value": null,
            "display": "Web serial number"
        },
        {
            "key": "reference_code",
            "value": null,
            "display": "Reference code"
        },
        {
            "key": "web_display",
            "value": null,
            "display": "Web display"
        },
        {
            "key": "date_of_library_publication",
            "value": null,
            "display": "Date of library publication"
        },
        {
            "key": "place_of_publication",
            "value": "Barcelona",
            "display": "Place of publication"
        },
        {
            "key": "histographical_note",
            "value": null,
            "display": "Histographical note"
        },
        {
            "key": "main_historical_note",
            "value": null,
            "display": "Main historical note"
        },
        {
            "key": "binding_status",
            "value": "PB",
            "display": "Binding status"
        },
        {
            "key": "accession_number",
            "value": "0000097",
            "display": "Accession number"
        },
        {
            "key": "cdt",
            "value": null,
            "display": "CDT"
        },
        {
            "key": "country_of_publication",
            "value": "Spain",
            "display": "Country of publication"
        },
        {
            "key": "date_of_published_edition_in_library",
            "value": "1999",
            "display": "Date of published edition in library"
        },
        {
            "key": "dates_of_first_edition",
            "value": "",
            "display": "Dates of first edition"
        },
        {
            "key": "isbn_issn_number",
            "value": "84-49307104",
            "display": "Isbn issn number"
        },
        {
            "key": "language_of_publication",
            "value": "",
            "display": "Language of publication"
        },
        {
            "key": "number_of_pages_in_publication_text",
            "value": null,
            "display": "Number of pages in publication text"
        },
        {
            "key": "personality_film_visually_featured_on_cover",
            "value": null,
            "display": "Personality film visually featured on cover"
        },
        {
            "key": "publisher",
            "value": "Paidos",
            "display": "Publisher"
        },
        {
            "key": "serial_number",
            "value": null,
            "display": "Serial number"
        },
        {
            "key": "sortable_year_date_of_publication",
            "value": "1999",
            "display": "Sortable year date of publication"
        },
        {
            "key": "sub_title_of_document_book",
            "value": null,
            "display": "Sub title of document book"
        },
        {
            "key": "title_of_document_book",
            "value": null,
            "display": "Title of document book"
        },
        {
            "key": "book_title_of_document",
            "value": "Los Cines Perifericos",
            "display": "Book title of document"
        },
        {
            "key": "book_sub_title_of_document",
            "value": "Africa, Oriente Medio, India",
            "display": "Book sub title of document"
        },
        {
            "key": "volume_issue_number",
            "value": null,
            "display": "Volume issue number"
        },
        {
            "key": "masterlist",
            "value": "satyajit-ray",
            "display": "Masterlist"
        },
        {
            "key": "image",
            "value": ["https://tuliresearchcentre.canto.global/direct/image/ecup88f81951hfb6sbbqns1040/moPs-UTn_maGD7TzBF8kN-sbFUw/m800/800"],
            "display": "Image"
        },
        {
            "key": "document_type",
            "value": "bka",
            "display": "Document type"
        },
        {
            "key": "title_and_type_of_creator",
            "value": "Author",
            "display": "Title and type of creator"
        },
        {
            "key": "number_of_pages_in_publication",
            "value": null,
            "display": "Number of pages in publication"
        },
        {
            "key": "researchCategories",
            "value": [
                {
                    "key": "Cinema as a Key Educational Resource",
                    "value": "CinemaE",
                    "link": "cinema-as-a-key-educational-resource"
                }
            ],
            "display": "Research categories"
        },
        {
            "key": "creator",
            "value": [
                {
                    "key": "alberto-elena",
                    "value": "Alberto Elena",
                    "first_name": "Alberto",
                    "last_name": "Elena"
                }
            ],
            "display": "Creator"
        }
    ],
    [
        {
            "key": "id",
            "value": "570d2fc8-c594-4d0b-944b-600fa882324d",
            "display": "Id"
        },
        {
            "key": "title",
            "value": null,
            "display": "Title"
        },
        {
            "key": "alias",
            "value": "bka-ghosh-manikda-2000",
            "display": "Alias"
        },
        {
            "key": "web_serial_number",
            "value": null,
            "display": "Web serial number"
        },
        {
            "key": "reference_code",
            "value": null,
            "display": "Reference code"
        },
        {
            "key": "web_display",
            "value": null,
            "display": "Web display"
        },
        {
            "key": "date_of_library_publication",
            "value": null,
            "display": "Date of library publication"
        },
        {
            "key": "place_of_publication",
            "value": "Kolkata",
            "display": "Place of publication"
        },
        {
            "key": "histographical_note",
            "value": null,
            "display": "Histographical note"
        },
        {
            "key": "main_historical_note",
            "value": null,
            "display": "Main historical note"
        },
        {
            "key": "binding_status",
            "value": "PB",
            "display": "Binding status"
        },
        {
            "key": "accession_number",
            "value": "0000034",
            "display": "Accession number"
        },
        {
            "key": "cdt",
            "value": null,
            "display": "CDT"
        },
        {
            "key": "country_of_publication",
            "value": "India",
            "display": "Country of publication"
        },
        {
            "key": "date_of_published_edition_in_library",
            "value": "2000",
            "display": "Date of published edition in library"
        },
        {
            "key": "dates_of_first_edition",
            "value": "",
            "display": "Dates of first edition"
        },
        {
            "key": "isbn_issn_number",
            "value": "81877570010",
            "display": "Isbn issn number"
        },
        {
            "key": "language_of_publication",
            "value": "",
            "display": "Language of publication"
        },
        {
            "key": "number_of_pages_in_publication_text",
            "value": null,
            "display": "Number of pages in publication text"
        },
        {
            "key": "personality_film_visually_featured_on_cover",
            "value": null,
            "display": "Personality film visually featured on cover"
        },
        {
            "key": "publisher",
            "value": "Bingsha Shatabdi",
            "display": "Publisher"
        },
        {
            "key": "serial_number",
            "value": null,
            "display": "Serial number"
        },
        {
            "key": "sortable_year_date_of_publication",
            "value": "2000",
            "display": "Sortable year date of publication"
        },
        {
            "key": "sub_title_of_document_book",
            "value": null,
            "display": "Sub title of document book"
        },
        {
            "key": "title_of_document_book",
            "value": null,
            "display": "Title of document book"
        },
        {
            "key": "book_title_of_document",
            "value": "Manikda",
            "display": "Book title of document"
        },
        {
            "key": "book_sub_title_of_document",
            "value": "Memoire sur Satyajit Ray",
            "display": "Book sub title of document"
        },
        {
            "key": "volume_issue_number",
            "value": null,
            "display": "Volume issue number"
        },
        {
            "key": "masterlist",
            "value": "satyajit-ray",
            "display": "Masterlist"
        },
        {
            "key": "image",
            "value": ["https://tuliresearchcentre.canto.global/direct/image/gk79bl2n154ff5ngmvk62uqp7n/9ibPJZu-OXKZ7p3xOov345ToQaM/m800/800"],
            "display": "Image"
        },
        {
            "key": "document_type",
            "value": "bka",
            "display": "Document type"
        },
        {
            "key": "title_and_type_of_creator",
            "value": "Author",
            "display": "Title and type of creator"
        },
        {
            "key": "number_of_pages_in_publication",
            "value": null,
            "display": "Number of pages in publication"
        },
        {
            "key": "researchCategories",
            "value": [
                {
                    "key": "Cinema as a Key Educational Resource",
                    "value": "CinemaE",
                    "link": "cinema-as-a-key-educational-resource"
                }
            ],
            "display": "Research categories"
        },
        {
            "key": "creator",
            "value": [
                {
                    "key": "nemai-ghosh",
                    "value": "Nemai Ghosh",
                    "first_name": "Nemai",
                    "last_name": "Ghosh"
                }
            ],
            "display": "Creator"
        }
    ],
    [
        {
            "key": "id",
            "value": "af86b3c1-e930-4ba2-8fe0-dbf175ffaa8e",
            "display": "Id"
        },
        {
            "key": "title",
            "value": null,
            "display": "Title"
        },
        {
            "key": "alias",
            "value": "bka-cooper-the-cinema-of-satyajit-ray-2000",
            "display": "Alias"
        },
        {
            "key": "web_serial_number",
            "value": null,
            "display": "Web serial number"
        },
        {
            "key": "reference_code",
            "value": null,
            "display": "Reference code"
        },
        {
            "key": "web_display",
            "value": null,
            "display": "Web display"
        },
        {
            "key": "date_of_library_publication",
            "value": null,
            "display": "Date of library publication"
        },
        {
            "key": "place_of_publication",
            "value": "Cambridge",
            "display": "Place of publication"
        },
        {
            "key": "histographical_note",
            "value": null,
            "display": "Histographical note"
        },
        {
            "key": "main_historical_note",
            "value": null,
            "display": "Main historical note"
        },
        {
            "key": "binding_status",
            "value": "PB",
            "display": "Binding status"
        },
        {
            "key": "accession_number",
            "value": "0000062",
            "display": "Accession number"
        },
        {
            "key": "cdt",
            "value": null,
            "display": "CDT"
        },
        {
            "key": "country_of_publication",
            "value": "UK",
            "display": "Country of publication"
        },
        {
            "key": "date_of_published_edition_in_library",
            "value": "2000",
            "display": "Date of published edition in library"
        },
        {
            "key": "dates_of_first_edition",
            "value": "",
            "display": "Dates of first edition"
        },
        {
            "key": "isbn_issn_number",
            "value": "0-521629802",
            "display": "Isbn issn number"
        },
        {
            "key": "language_of_publication",
            "value": "",
            "display": "Language of publication"
        },
        {
            "key": "number_of_pages_in_publication_text",
            "value": null,
            "display": "Number of pages in publication text"
        },
        {
            "key": "personality_film_visually_featured_on_cover",
            "value": null,
            "display": "Personality film visually featured on cover"
        },
        {
            "key": "publisher",
            "value": "Cambridge University",
            "display": "Publisher"
        },
        {
            "key": "serial_number",
            "value": null,
            "display": "Serial number"
        },
        {
            "key": "sortable_year_date_of_publication",
            "value": "2000",
            "display": "Sortable year date of publication"
        },
        {
            "key": "sub_title_of_document_book",
            "value": null,
            "display": "Sub title of document book"
        },
        {
            "key": "title_of_document_book",
            "value": null,
            "display": "Title of document book"
        },
        {
            "key": "book_title_of_document",
            "value": "The Cinema of Satyajit Ray",
            "display": "Book title of document"
        },
        {
            "key": "book_sub_title_of_document",
            "value": "Between Tradition and Modernity",
            "display": "Book sub title of document"
        },
        {
            "key": "volume_issue_number",
            "value": null,
            "display": "Volume issue number"
        },
        {
            "key": "masterlist",
            "value": "satyajit-ray",
            "display": "Masterlist"
        },
        {
            "key": "image",
            "value": ["https://tuliresearchcentre.canto.global/direct/image/6g871mhtid68vfb6tdjri9vc6o/omjs26yZrqF7oHOBQVxe_idNspc/m800/800"],
            "display": "Image"
        },
        {
            "key": "document_type",
            "value": "bka",
            "display": "Document type"
        },
        {
            "key": "title_and_type_of_creator",
            "value": "Author",
            "display": "Title and type of creator"
        },
        {
            "key": "number_of_pages_in_publication",
            "value": null,
            "display": "Number of pages in publication"
        },
        {
            "key": "researchCategories",
            "value": [
                {
                    "key": "Cinema as a Key Educational Resource",
                    "value": "CinemaE",
                    "link": "cinema-as-a-key-educational-resource"
                },
                {
                    "key": "History & Historiography of Indian Scholarship in the Arts, Social Sciences & Humanities",
                    "value": "Scholarship",
                    "link": "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities"
                }
            ],
            "display": "Research categories"
        },
        {
            "key": "creator",
            "value": [
                {
                    "key": "darius-cooper",
                    "value": "Darius Cooper",
                    "first_name": "Darius",
                    "last_name": "Cooper"
                }
            ],
            "display": "Creator"
        }
    ],
    [
        {
            "key": "id",
            "value": "f4892551-4738-4b46-a9cd-afb0e562de40",
            "display": "Id"
        },
        {
            "key": "title",
            "value": null,
            "display": "Title"
        },
        {
            "key": "alias",
            "value": "bka-thoraval-the-cinemas-of-india\n1896-2000-2000",
            "display": "Alias"
        },
        {
            "key": "web_serial_number",
            "value": null,
            "display": "Web serial number"
        },
        {
            "key": "reference_code",
            "value": null,
            "display": "Reference code"
        },
        {
            "key": "web_display",
            "value": null,
            "display": "Web display"
        },
        {
            "key": "date_of_library_publication",
            "value": null,
            "display": "Date of library publication"
        },
        {
            "key": "place_of_publication",
            "value": "New Delhi",
            "display": "Place of publication"
        },
        {
            "key": "histographical_note",
            "value": null,
            "display": "Histographical note"
        },
        {
            "key": "main_historical_note",
            "value": null,
            "display": "Main historical note"
        },
        {
            "key": "binding_status",
            "value": "HB",
            "display": "Binding status"
        },
        {
            "key": "accession_number",
            "value": "0000094",
            "display": "Accession number"
        },
        {
            "key": "cdt",
            "value": null,
            "display": "CDT"
        },
        {
            "key": "country_of_publication",
            "value": "India",
            "display": "Country of publication"
        },
        {
            "key": "date_of_published_edition_in_library",
            "value": "2000",
            "display": "Date of published edition in library"
        },
        {
            "key": "dates_of_first_edition",
            "value": "",
            "display": "Dates of first edition"
        },
        {
            "key": "isbn_issn_number",
            "value": "0333934105",
            "display": "Isbn issn number"
        },
        {
            "key": "language_of_publication",
            "value": "",
            "display": "Language of publication"
        },
        {
            "key": "number_of_pages_in_publication_text",
            "value": null,
            "display": "Number of pages in publication text"
        },
        {
            "key": "personality_film_visually_featured_on_cover",
            "value": null,
            "display": "Personality film visually featured on cover"
        },
        {
            "key": "publisher",
            "value": "Macmillan India",
            "display": "Publisher"
        },
        {
            "key": "serial_number",
            "value": null,
            "display": "Serial number"
        },
        {
            "key": "sortable_year_date_of_publication",
            "value": "2000",
            "display": "Sortable year date of publication"
        },
        {
            "key": "sub_title_of_document_book",
            "value": null,
            "display": "Sub title of document book"
        },
        {
            "key": "title_of_document_book",
            "value": null,
            "display": "Title of document book"
        },
        {
            "key": "book_title_of_document",
            "value": "The Cinemas of India:\n1896-2000",
            "display": "Book title of document"
        },
        {
            "key": "book_sub_title_of_document",
            "value": "",
            "display": "Book sub title of document"
        },
        {
            "key": "volume_issue_number",
            "value": null,
            "display": "Volume issue number"
        },
        {
            "key": "masterlist",
            "value": "satyajit-ray",
            "display": "Masterlist"
        },
        {
            "key": "image",
            "value": ["https://tuliresearchcentre.canto.global/direct/image/h3t7tpae1d16329tpg0opuke1l/h8y8OlZTDl1UjPhqvVoVVAKip_Q/m800/800"],
            "display": "Image"
        },
        {
            "key": "document_type",
            "value": "bka",
            "display": "Document type"
        },
        {
            "key": "title_and_type_of_creator",
            "value": "Author",
            "display": "Title and type of creator"
        },
        {
            "key": "number_of_pages_in_publication",
            "value": null,
            "display": "Number of pages in publication"
        },
        {
            "key": "researchCategories",
            "value": [
                {
                    "key": "Cinema as a Key Educational Resource",
                    "value": "CinemaE",
                    "link": "cinema-as-a-key-educational-resource"
                },
                {
                    "key": "India and her Relationship with the World",
                    "value": "IndoWorld",
                    "link": "india-and-her-relationship-with-the-world"
                }
            ],
            "display": "Research categories"
        },
        {
            "key": "creator",
            "value": [
                {
                    "key": "yves-thoraval",
                    "value": "Yves Thoraval",
                    "first_name": "Yves",
                    "last_name": "Thoraval"
                }
            ],
            "display": "Creator"
        }
    ],
    [
        {
            "key": "id",
            "value": "9bfd5961-a8fd-4610-99bd-c5ae67cfb359",
            "display": "Id"
        },
        {
            "key": "title",
            "value": null,
            "display": "Title"
        },
        {
            "key": "alias",
            "value": "bka-gupta-the-cinema-of-satyajit-ray-2001",
            "display": "Alias"
        },
        {
            "key": "web_serial_number",
            "value": null,
            "display": "Web serial number"
        },
        {
            "key": "reference_code",
            "value": null,
            "display": "Reference code"
        },
        {
            "key": "web_display",
            "value": null,
            "display": "Web display"
        },
        {
            "key": "date_of_library_publication",
            "value": null,
            "display": "Date of library publication"
        },
        {
            "key": "place_of_publication",
            "value": "New Delhi",
            "display": "Place of publication"
        },
        {
            "key": "histographical_note",
            "value": null,
            "display": "Histographical note"
        },
        {
            "key": "main_historical_note",
            "value": null,
            "display": "Main historical note"
        },
        {
            "key": "binding_status",
            "value": "HB",
            "display": "Binding status"
        },
        {
            "key": "accession_number",
            "value": "0000009.DI",
            "display": "Accession number"
        },
        {
            "key": "cdt",
            "value": null,
            "display": "CDT"
        },
        {
            "key": "country_of_publication",
            "value": "India",
            "display": "Country of publication"
        },
        {
            "key": "date_of_published_edition_in_library",
            "value": "1994",
            "display": "Date of published edition in library"
        },
        {
            "key": "dates_of_first_edition",
            "value": "",
            "display": "Dates of first edition"
        },
        {
            "key": "isbn_issn_number",
            "value": "81-23707533",
            "display": "Isbn issn number"
        },
        {
            "key": "language_of_publication",
            "value": "",
            "display": "Language of publication"
        },
        {
            "key": "number_of_pages_in_publication_text",
            "value": null,
            "display": "Number of pages in publication text"
        },
        {
            "key": "personality_film_visually_featured_on_cover",
            "value": null,
            "display": "Personality film visually featured on cover"
        },
        {
            "key": "publisher",
            "value": "National Book Trust",
            "display": "Publisher"
        },
        {
            "key": "serial_number",
            "value": null,
            "display": "Serial number"
        },
        {
            "key": "sortable_year_date_of_publication",
            "value": "2001",
            "display": "Sortable year date of publication"
        },
        {
            "key": "sub_title_of_document_book",
            "value": null,
            "display": "Sub title of document book"
        },
        {
            "key": "title_of_document_book",
            "value": null,
            "display": "Title of document book"
        },
        {
            "key": "book_title_of_document",
            "value": "The Cinema of Satyajit Ray",
            "display": "Book title of document"
        },
        {
            "key": "book_sub_title_of_document",
            "value": "",
            "display": "Book sub title of document"
        },
        {
            "key": "volume_issue_number",
            "value": null,
            "display": "Volume issue number"
        },
        {
            "key": "masterlist",
            "value": "satyajit-ray",
            "display": "Masterlist"
        },
        {
            "key": "image",
            "value": ["https://tuliresearchcentre.canto.global/direct/image/ufdh66f5b152tfq349m6as6o6s/5dFTlSnkHovZD2ZrYTX3Gaiey_U/m800/800"],
            "display": "Image"
        },
        {
            "key": "document_type",
            "value": "bka",
            "display": "Document type"
        },
        {
            "key": "title_and_type_of_creator",
            "value": "Author",
            "display": "Title and type of creator"
        },
        {
            "key": "number_of_pages_in_publication",
            "value": null,
            "display": "Number of pages in publication"
        },
        {
            "key": "researchCategories",
            "value": [
                {
                    "key": "History & Historiography of Indian Scholarship in the Arts, Social Sciences & Humanities",
                    "value": "Scholarship",
                    "link": "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities"
                },
                {
                    "key": "Cinema as a Key Educational Resource",
                    "value": "CinemaE",
                    "link": "cinema-as-a-key-educational-resource"
                }
            ],
            "display": "Research categories"
        },
        {
            "key": "creator",
            "value": [
                {
                    "key": "chidananda-das-gupta",
                    "value": "Chidananda Das Gupta",
                    "first_name": "Chidananda-Das",
                    "last_name": "Gupta"
                }
            ],
            "display": "Creator"
        }
    ],
    [
        {
            "key": "id",
            "value": "572ac88f-d6ff-4909-b7d3-f0c1ee4e46ce",
            "display": "Id"
        },
        {
            "key": "title",
            "value": null,
            "display": "Title"
        },
        {
            "key": "alias",
            "value": "bka-vallachira-asian-cinema-2001",
            "display": "Alias"
        },
        {
            "key": "web_serial_number",
            "value": null,
            "display": "Web serial number"
        },
        {
            "key": "reference_code",
            "value": null,
            "display": "Reference code"
        },
        {
            "key": "web_display",
            "value": null,
            "display": "Web display"
        },
        {
            "key": "date_of_library_publication",
            "value": null,
            "display": "Date of library publication"
        },
        {
            "key": "place_of_publication",
            "value": "Thrissur",
            "display": "Place of publication"
        },
        {
            "key": "histographical_note",
            "value": null,
            "display": "Histographical note"
        },
        {
            "key": "main_historical_note",
            "value": null,
            "display": "Main historical note"
        },
        {
            "key": "binding_status",
            "value": "PB",
            "display": "Binding status"
        },
        {
            "key": "accession_number",
            "value": "0000031",
            "display": "Accession number"
        },
        {
            "key": "cdt",
            "value": null,
            "display": "CDT"
        },
        {
            "key": "country_of_publication",
            "value": "India",
            "display": "Country of publication"
        },
        {
            "key": "date_of_published_edition_in_library",
            "value": "2001",
            "display": "Date of published edition in library"
        },
        {
            "key": "dates_of_first_edition",
            "value": "",
            "display": "Dates of first edition"
        },
        {
            "key": "isbn_issn_number",
            "value": "",
            "display": "Isbn issn number"
        },
        {
            "key": "language_of_publication",
            "value": "",
            "display": "Language of publication"
        },
        {
            "key": "number_of_pages_in_publication_text",
            "value": null,
            "display": "Number of pages in publication text"
        },
        {
            "key": "personality_film_visually_featured_on_cover",
            "value": null,
            "display": "Personality film visually featured on cover"
        },
        {
            "key": "publisher",
            "value": "Dhrisyasahithi",
            "display": "Publisher"
        },
        {
            "key": "serial_number",
            "value": null,
            "display": "Serial number"
        },
        {
            "key": "sortable_year_date_of_publication",
            "value": "2001",
            "display": "Sortable year date of publication"
        },
        {
            "key": "sub_title_of_document_book",
            "value": null,
            "display": "Sub title of document book"
        },
        {
            "key": "title_of_document_book",
            "value": null,
            "display": "Title of document book"
        },
        {
            "key": "book_title_of_document",
            "value": "Asian Cinema",
            "display": "Book title of document"
        },
        {
            "key": "book_sub_title_of_document",
            "value": "A New Perspective",
            "display": "Book sub title of document"
        },
        {
            "key": "volume_issue_number",
            "value": null,
            "display": "Volume issue number"
        },
        {
            "key": "masterlist",
            "value": "satyajit-ray",
            "display": "Masterlist"
        },
        {
            "key": "image",
            "value": ["https://tuliresearchcentre.canto.global/direct/image/lp40tc6c3951h6hh7rbopbft06/VfZ75t0bvomPx0reeaJ0XlyZQRE/m800/800"],
            "display": "Image"
        },
        {
            "key": "document_type",
            "value": "bka",
            "display": "Document type"
        },
        {
            "key": "title_and_type_of_creator",
            "value": "Author",
            "display": "Title and type of creator"
        },
        {
            "key": "number_of_pages_in_publication",
            "value": null,
            "display": "Number of pages in publication"
        },
        {
            "key": "researchCategories",
            "value": [
                {
                    "key": "Cinema as a Key Educational Resource",
                    "value": "CinemaE",
                    "link": "cinema-as-a-key-educational-resource"
                }
            ],
            "display": "Research categories"
        },
        {
            "key": "creator",
            "value": [
                {
                    "key": "aravindan-vallachira",
                    "value": "Aravindan Vallachira",
                    "first_name": "Aravindan",
                    "last_name": "Vallachira"
                }
            ],
            "display": "Creator"
        }
    ],
    [
        {
            "key": "id",
            "value": "75887e87-9f10-4e6c-ac6b-43ed289a9050",
            "display": "Id"
        },
        {
            "key": "title",
            "value": null,
            "display": "Title"
        },
        {
            "key": "alias",
            "value": "bka-ganguly-satyajit-ray-2001",
            "display": "Alias"
        },
        {
            "key": "web_serial_number",
            "value": null,
            "display": "Web serial number"
        },
        {
            "key": "reference_code",
            "value": null,
            "display": "Reference code"
        },
        {
            "key": "web_display",
            "value": null,
            "display": "Web display"
        },
        {
            "key": "date_of_library_publication",
            "value": null,
            "display": "Date of library publication"
        },
        {
            "key": "place_of_publication",
            "value": "New Delhi",
            "display": "Place of publication"
        },
        {
            "key": "histographical_note",
            "value": null,
            "display": "Histographical note"
        },
        {
            "key": "main_historical_note",
            "value": null,
            "display": "Main historical note"
        },
        {
            "key": "binding_status",
            "value": "PB",
            "display": "Binding status"
        },
        {
            "key": "accession_number",
            "value": "0000042",
            "display": "Accession number"
        },
        {
            "key": "cdt",
            "value": null,
            "display": "CDT"
        },
        {
            "key": "country_of_publication",
            "value": "India",
            "display": "Country of publication"
        },
        {
            "key": "date_of_published_edition_in_library",
            "value": "2001",
            "display": "Date of published edition in library"
        },
        {
            "key": "dates_of_first_edition",
            "value": "",
            "display": "Dates of first edition"
        },
        {
            "key": "isbn_issn_number",
            "value": "81-87981040",
            "display": "Isbn issn number"
        },
        {
            "key": "language_of_publication",
            "value": "",
            "display": "Language of publication"
        },
        {
            "key": "number_of_pages_in_publication_text",
            "value": null,
            "display": "Number of pages in publication text"
        },
        {
            "key": "personality_film_visually_featured_on_cover",
            "value": null,
            "display": "Personality film visually featured on cover"
        },
        {
            "key": "publisher",
            "value": "Indialog",
            "display": "Publisher"
        },
        {
            "key": "serial_number",
            "value": null,
            "display": "Serial number"
        },
        {
            "key": "sortable_year_date_of_publication",
            "value": "2001",
            "display": "Sortable year date of publication"
        },
        {
            "key": "sub_title_of_document_book",
            "value": null,
            "display": "Sub title of document book"
        },
        {
            "key": "title_of_document_book",
            "value": null,
            "display": "Title of document book"
        },
        {
            "key": "book_title_of_document",
            "value": "Satyajit Ray",
            "display": "Book title of document"
        },
        {
            "key": "book_sub_title_of_document",
            "value": "In Search of the Modern",
            "display": "Book sub title of document"
        },
        {
            "key": "volume_issue_number",
            "value": null,
            "display": "Volume issue number"
        },
        {
            "key": "masterlist",
            "value": "satyajit-ray",
            "display": "Masterlist"
        },
        {
            "key": "image",
            "value": ["https://tuliresearchcentre.canto.global/direct/image/4aupoppujt13rf8tu3jl8k8f04/AwUN7ByPGSam2uBjpi675mGDcyg/m800/800"],
            "display": "Image"
        },
        {
            "key": "document_type",
            "value": "bka",
            "display": "Document type"
        },
        {
            "key": "title_and_type_of_creator",
            "value": "Author",
            "display": "Title and type of creator"
        },
        {
            "key": "number_of_pages_in_publication",
            "value": null,
            "display": "Number of pages in publication"
        },
        {
            "key": "researchCategories",
            "value": [
                {
                    "key": "History & Historiography of Indian Scholarship in the Arts, Social Sciences & Humanities",
                    "value": "Scholarship",
                    "link": "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities"
                },
                {
                    "key": "Cinema as a Key Educational Resource",
                    "value": "CinemaE",
                    "link": "cinema-as-a-key-educational-resource"
                }
            ],
            "display": "Research categories"
        },
        {
            "key": "creator",
            "value": [
                {
                    "key": "suranjan-ganguly",
                    "value": "Suranjan Ganguly",
                    "first_name": "Suranjan",
                    "last_name": "Ganguly"
                }
            ],
            "display": "Creator"
        }
    ],
    [
        {
            "key": "id",
            "value": "a1d09b07-c8a2-4be1-aa59-ecdad045b1da",
            "display": "Id"
        },
        {
            "key": "title",
            "value": null,
            "display": "Title"
        },
        {
            "key": "alias",
            "value": "bke-dasgupta-ray-2001",
            "display": "Alias"
        },
        {
            "key": "web_serial_number",
            "value": null,
            "display": "Web serial number"
        },
        {
            "key": "reference_code",
            "value": null,
            "display": "Reference code"
        },
        {
            "key": "web_display",
            "value": null,
            "display": "Web display"
        },
        {
            "key": "date_of_library_publication",
            "value": null,
            "display": "Date of library publication"
        },
        {
            "key": "place_of_publication",
            "value": "Kolkata",
            "display": "Place of publication"
        },
        {
            "key": "histographical_note",
            "value": null,
            "display": "Histographical note"
        },
        {
            "key": "main_historical_note",
            "value": null,
            "display": "Main historical note"
        },
        {
            "key": "binding_status",
            "value": "HB",
            "display": "Binding status"
        },
        {
            "key": "accession_number",
            "value": "0000029",
            "display": "Accession number"
        },
        {
            "key": "cdt",
            "value": null,
            "display": "CDT"
        },
        {
            "key": "country_of_publication",
            "value": "India",
            "display": "Country of publication"
        },
        {
            "key": "date_of_published_edition_in_library",
            "value": "2001",
            "display": "Date of published edition in library"
        },
        {
            "key": "dates_of_first_edition",
            "value": "",
            "display": "Dates of first edition"
        },
        {
            "key": "isbn_issn_number",
            "value": "",
            "display": "Isbn issn number"
        },
        {
            "key": "language_of_publication",
            "value": "",
            "display": "Language of publication"
        },
        {
            "key": "number_of_pages_in_publication_text",
            "value": null,
            "display": "Number of pages in publication text"
        },
        {
            "key": "personality_film_visually_featured_on_cover",
            "value": null,
            "display": "Personality film visually featured on cover"
        },
        {
            "key": "publisher",
            "value": "Kurchi Dasgupta",
            "display": "Publisher"
        },
        {
            "key": "serial_number",
            "value": null,
            "display": "Serial number"
        },
        {
            "key": "sortable_year_date_of_publication",
            "value": "2001",
            "display": "Sortable year date of publication"
        },
        {
            "key": "sub_title_of_document_book",
            "value": null,
            "display": "Sub title of document book"
        },
        {
            "key": "title_of_document_book",
            "value": null,
            "display": "Title of document book"
        },
        {
            "key": "book_title_of_document",
            "value": "Ray",
            "display": "Book title of document"
        },
        {
            "key": "book_sub_title_of_document",
            "value": "A Glimpse",
            "display": "Book sub title of document"
        },
        {
            "key": "volume_issue_number",
            "value": null,
            "display": "Volume issue number"
        },
        {
            "key": "masterlist",
            "value": "satyajit-ray",
            "display": "Masterlist"
        },
        {
            "key": "image",
            "value": ["https://tuliresearchcentre.canto.global/direct/image/52164hlcjp4cv1qulg8up9502v/F_tR1W0oo1fMjqrhNN2F5qb18g8/m800/800"],
            "display": "Image"
        },
        {
            "key": "document_type",
            "value": "bke",
            "display": "Document type"
        },
        {
            "key": "title_and_type_of_creator",
            "value": "Publisher",
            "display": "Title and type of creator"
        },
        {
            "key": "number_of_pages_in_publication",
            "value": null,
            "display": "Number of pages in publication"
        },
        {
            "key": "researchCategories",
            "value": [
                {
                    "key": "History & Historiography of Indian Scholarship in the Arts, Social Sciences & Humanities",
                    "value": "Scholarship",
                    "link": "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities"
                },
                {
                    "key": "Cinema as a Key Educational Resource",
                    "value": "CinemaE",
                    "link": "cinema-as-a-key-educational-resource"
                }
            ],
            "display": "Research categories"
        },
        {
            "key": "creator",
            "value": [
                {
                    "key": "kurchi-dasgupta",
                    "value": "Kurchi Dasgupta",
                    "first_name": "Kurchi",
                    "last_name": "Dasgupta"
                }
            ],
            "display": "Creator"
        }
    ],
    [
        {
            "key": "id",
            "value": "25901051-a959-4760-9385-a92220bf7c0e",
            "display": "Id"
        },
        {
            "key": "title",
            "value": null,
            "display": "Title"
        },
        {
            "key": "alias",
            "value": "bka-vasudev-being-becoming-2002",
            "display": "Alias"
        },
        {
            "key": "web_serial_number",
            "value": null,
            "display": "Web serial number"
        },
        {
            "key": "reference_code",
            "value": null,
            "display": "Reference code"
        },
        {
            "key": "web_display",
            "value": null,
            "display": "Web display"
        },
        {
            "key": "date_of_library_publication",
            "value": null,
            "display": "Date of library publication"
        },
        {
            "key": "place_of_publication",
            "value": "New Delhi",
            "display": "Place of publication"
        },
        {
            "key": "histographical_note",
            "value": null,
            "display": "Histographical note"
        },
        {
            "key": "main_historical_note",
            "value": null,
            "display": "Main historical note"
        },
        {
            "key": "binding_status",
            "value": "HB",
            "display": "Binding status"
        },
        {
            "key": "accession_number",
            "value": "0000081",
            "display": "Accession number"
        },
        {
            "key": "cdt",
            "value": null,
            "display": "CDT"
        },
        {
            "key": "country_of_publication",
            "value": "India",
            "display": "Country of publication"
        },
        {
            "key": "date_of_published_edition_in_library",
            "value": "2002",
            "display": "Date of published edition in library"
        },
        {
            "key": "dates_of_first_edition",
            "value": "",
            "display": "Dates of first edition"
        },
        {
            "key": "isbn_issn_number",
            "value": "0333938208",
            "display": "Isbn issn number"
        },
        {
            "key": "language_of_publication",
            "value": "",
            "display": "Language of publication"
        },
        {
            "key": "number_of_pages_in_publication_text",
            "value": null,
            "display": "Number of pages in publication text"
        },
        {
            "key": "personality_film_visually_featured_on_cover",
            "value": null,
            "display": "Personality film visually featured on cover"
        },
        {
            "key": "publisher",
            "value": "Macmillan India",
            "display": "Publisher"
        },
        {
            "key": "serial_number",
            "value": null,
            "display": "Serial number"
        },
        {
            "key": "sortable_year_date_of_publication",
            "value": "2002",
            "display": "Sortable year date of publication"
        },
        {
            "key": "sub_title_of_document_book",
            "value": null,
            "display": "Sub title of document book"
        },
        {
            "key": "title_of_document_book",
            "value": null,
            "display": "Title of document book"
        },
        {
            "key": "book_title_of_document",
            "value": "Being & Becoming",
            "display": "Book title of document"
        },
        {
            "key": "book_sub_title_of_document",
            "value": "The Cinemas of Asia",
            "display": "Book sub title of document"
        },
        {
            "key": "volume_issue_number",
            "value": null,
            "display": "Volume issue number"
        },
        {
            "key": "masterlist",
            "value": "satyajit-ray",
            "display": "Masterlist"
        },
        {
            "key": "image",
            "value": ["https://tuliresearchcentre.canto.global/direct/image/iejinh906537dcn50l6albv66b/AFDo4TaF3mF73NIcdoAiqq4x4PA/m800/800"],
            "display": "Image"
        },
        {
            "key": "document_type",
            "value": "bka",
            "display": "Document type"
        },
        {
            "key": "title_and_type_of_creator",
            "value": "Author",
            "display": "Title and type of creator"
        },
        {
            "key": "number_of_pages_in_publication",
            "value": null,
            "display": "Number of pages in publication"
        },
        {
            "key": "researchCategories",
            "value": [
                {
                    "key": "India and her Relationship with the World",
                    "value": "IndoWorld",
                    "link": "india-and-her-relationship-with-the-world"
                },
                {
                    "key": "Cinema as a Key Educational Resource",
                    "value": "CinemaE",
                    "link": "cinema-as-a-key-educational-resource"
                }
            ],
            "display": "Research categories"
        },
        {
            "key": "creator",
            "value": [
                {
                    "key": "aruna-vasudev",
                    "value": "Aruna Vasudev",
                    "first_name": "Aruna",
                    "last_name": "Vasudev"
                }
            ],
            "display": "Creator"
        }
    ],
    [
        {
            "key": "id",
            "value": "ad78cb36-78e2-41c4-838d-7cce9fd8b10c",
            "display": "Id"
        },
        {
            "key": "title",
            "value": null,
            "display": "Title"
        },
        {
            "key": "alias",
            "value": "bke-majumdar-raytrospective-2001-2002",
            "display": "Alias"
        },
        {
            "key": "web_serial_number",
            "value": null,
            "display": "Web serial number"
        },
        {
            "key": "reference_code",
            "value": null,
            "display": "Reference code"
        },
        {
            "key": "web_display",
            "value": null,
            "display": "Web display"
        },
        {
            "key": "date_of_library_publication",
            "value": null,
            "display": "Date of library publication"
        },
        {
            "key": "place_of_publication",
            "value": "New Delhi",
            "display": "Place of publication"
        },
        {
            "key": "histographical_note",
            "value": null,
            "display": "Histographical note"
        },
        {
            "key": "main_historical_note",
            "value": null,
            "display": "Main historical note"
        },
        {
            "key": "binding_status",
            "value": "PB",
            "display": "Binding status"
        },
        {
            "key": "accession_number",
            "value": "0000033",
            "display": "Accession number"
        },
        {
            "key": "cdt",
            "value": null,
            "display": "CDT"
        },
        {
            "key": "country_of_publication",
            "value": "India",
            "display": "Country of publication"
        },
        {
            "key": "date_of_published_edition_in_library",
            "value": "2002-07-01",
            "display": "Date of published edition in library"
        },
        {
            "key": "dates_of_first_edition",
            "value": "",
            "display": "Dates of first edition"
        },
        {
            "key": "isbn_issn_number",
            "value": "",
            "display": "Isbn issn number"
        },
        {
            "key": "language_of_publication",
            "value": "",
            "display": "Language of publication"
        },
        {
            "key": "number_of_pages_in_publication_text",
            "value": null,
            "display": "Number of pages in publication text"
        },
        {
            "key": "personality_film_visually_featured_on_cover",
            "value": null,
            "display": "Personality film visually featured on cover"
        },
        {
            "key": "publisher",
            "value": "Indrani Majumdar",
            "display": "Publisher"
        },
        {
            "key": "serial_number",
            "value": null,
            "display": "Serial number"
        },
        {
            "key": "sortable_year_date_of_publication",
            "value": "2002",
            "display": "Sortable year date of publication"
        },
        {
            "key": "sub_title_of_document_book",
            "value": null,
            "display": "Sub title of document book"
        },
        {
            "key": "title_of_document_book",
            "value": null,
            "display": "Title of document book"
        },
        {
            "key": "book_title_of_document",
            "value": "RayTrospective 2001",
            "display": "Book title of document"
        },
        {
            "key": "book_sub_title_of_document",
            "value": "A Celebration",
            "display": "Book sub title of document"
        },
        {
            "key": "volume_issue_number",
            "value": null,
            "display": "Volume issue number"
        },
        {
            "key": "masterlist",
            "value": "satyajit-ray",
            "display": "Masterlist"
        },
        {
            "key": "image",
            "value": ["https://tuliresearchcentre.canto.global/direct/image/dms2lu81v14fbccc6sovsp3l38/Is2eVy5oB_P1YpDBYkoYlEZiGw8/m800/800"],
            "display": "Image"
        },
        {
            "key": "document_type",
            "value": "bke",
            "display": "Document type"
        },
        {
            "key": "title_and_type_of_creator",
            "value": "Compiler",
            "display": "Title and type of creator"
        },
        {
            "key": "number_of_pages_in_publication",
            "value": null,
            "display": "Number of pages in publication"
        },
        {
            "key": "researchCategories",
            "value": [
                {
                    "key": "Cinema as a Key Educational Resource",
                    "value": "CinemaE",
                    "link": "cinema-as-a-key-educational-resource"
                },
                {
                    "key": "History & Historiography of Indian Scholarship in the Arts, Social Sciences & Humanities",
                    "value": "Scholarship",
                    "link": "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities"
                }
            ],
            "display": "Research categories"
        },
        {
            "key": "creator",
            "value": [
                {
                    "key": "indrani-majumdar",
                    "value": "Indrani Majumdar",
                    "first_name": "Indrani",
                    "last_name": "Majumdar"
                }
            ],
            "display": "Creator"
        }
    ],
    [
        {
            "key": "id",
            "value": "d3b412f0-01cf-4589-ac8d-ec40b9a48612",
            "display": "Id"
        },
        {
            "key": "title",
            "value": null,
            "display": "Title"
        },
        {
            "key": "alias",
            "value": "bka-ghosh-satyajit-ray-2003",
            "display": "Alias"
        },
        {
            "key": "web_serial_number",
            "value": null,
            "display": "Web serial number"
        },
        {
            "key": "reference_code",
            "value": null,
            "display": "Reference code"
        },
        {
            "key": "web_display",
            "value": null,
            "display": "Web display"
        },
        {
            "key": "date_of_library_publication",
            "value": null,
            "display": "Date of library publication"
        },
        {
            "key": "place_of_publication",
            "value": "New Delhi",
            "display": "Place of publication"
        },
        {
            "key": "histographical_note",
            "value": null,
            "display": "Histographical note"
        },
        {
            "key": "main_historical_note",
            "value": null,
            "display": "Main historical note"
        },
        {
            "key": "binding_status",
            "value": "",
            "display": "Binding status"
        },
        {
            "key": "accession_number",
            "value": "0000084",
            "display": "Accession number"
        },
        {
            "key": "cdt",
            "value": null,
            "display": "CDT"
        },
        {
            "key": "country_of_publication",
            "value": "India",
            "display": "Country of publication"
        },
        {
            "key": "date_of_published_edition_in_library",
            "value": "2003",
            "display": "Date of published edition in library"
        },
        {
            "key": "dates_of_first_edition",
            "value": "",
            "display": "Dates of first edition"
        },
        {
            "key": "isbn_issn_number",
            "value": "",
            "display": "Isbn issn number"
        },
        {
            "key": "language_of_publication",
            "value": "",
            "display": "Language of publication"
        },
        {
            "key": "number_of_pages_in_publication_text",
            "value": null,
            "display": "Number of pages in publication text"
        },
        {
            "key": "personality_film_visually_featured_on_cover",
            "value": null,
            "display": "Personality film visually featured on cover"
        },
        {
            "key": "publisher",
            "value": "National Gallery of Modern Art",
            "display": "Publisher"
        },
        {
            "key": "serial_number",
            "value": null,
            "display": "Serial number"
        },
        {
            "key": "sortable_year_date_of_publication",
            "value": "2003",
            "display": "Sortable year date of publication"
        },
        {
            "key": "sub_title_of_document_book",
            "value": null,
            "display": "Sub title of document book"
        },
        {
            "key": "title_of_document_book",
            "value": null,
            "display": "Title of document book"
        },
        {
            "key": "book_title_of_document",
            "value": "Satyajit Ray",
            "display": "Book title of document"
        },
        {
            "key": "book_sub_title_of_document",
            "value": "From Script to Screen ",
            "display": "Book sub title of document"
        },
        {
            "key": "volume_issue_number",
            "value": null,
            "display": "Volume issue number"
        },
        {
            "key": "masterlist",
            "value": "satyajit-ray",
            "display": "Masterlist"
        },
        {
            "key": "image",
            "value": ["https://tuliresearchcentre.canto.global/direct/image/37m5q6ruip1bfb3it7jv6sgl30/F3hJedI2_BC93q5GLMzFT3GO_UU/m800/800"],
            "display": "Image"
        },
        {
            "key": "document_type",
            "value": "bka",
            "display": "Document type"
        },
        {
            "key": "title_and_type_of_creator",
            "value": "Photographer",
            "display": "Title and type of creator"
        },
        {
            "key": "number_of_pages_in_publication",
            "value": null,
            "display": "Number of pages in publication"
        },
        {
            "key": "researchCategories",
            "value": [
                {
                    "key": "Photography in India",
                    "value": "Photo",
                    "link": "photography-in-india"
                },
                {
                    "key": "Cinema as a Key Educational Resource",
                    "value": "CinemaE",
                    "link": "cinema-as-a-key-educational-resource"
                }
            ],
            "display": "Research categories"
        },
        {
            "key": "creator",
            "value": [
                {
                    "key": "nemai-ghosh",
                    "value": "Nemai Ghosh",
                    "first_name": "Nemai",
                    "last_name": "Ghosh"
                }
            ],
            "display": "Creator"
        }
    ],
    [
        {
            "key": "id",
            "value": "f49a443c-18ed-477c-b876-84ee0168f91d",
            "display": "Id"
        },
        {
            "key": "title",
            "value": null,
            "display": "Title"
        },
        {
            "key": "alias",
            "value": "bke-robinson-satyajit-ray-2005",
            "display": "Alias"
        },
        {
            "key": "web_serial_number",
            "value": null,
            "display": "Web serial number"
        },
        {
            "key": "reference_code",
            "value": null,
            "display": "Reference code"
        },
        {
            "key": "web_display",
            "value": null,
            "display": "Web display"
        },
        {
            "key": "date_of_library_publication",
            "value": null,
            "display": "Date of library publication"
        },
        {
            "key": "place_of_publication",
            "value": "London",
            "display": "Place of publication"
        },
        {
            "key": "histographical_note",
            "value": null,
            "display": "Histographical note"
        },
        {
            "key": "main_historical_note",
            "value": null,
            "display": "Main historical note"
        },
        {
            "key": "binding_status",
            "value": "HB",
            "display": "Binding status"
        },
        {
            "key": "accession_number",
            "value": "0000036",
            "display": "Accession number"
        },
        {
            "key": "cdt",
            "value": null,
            "display": "CDT"
        },
        {
            "key": "country_of_publication",
            "value": "UK",
            "display": "Country of publication"
        },
        {
            "key": "date_of_published_edition_in_library",
            "value": "2005",
            "display": "Date of published edition in library"
        },
        {
            "key": "dates_of_first_edition",
            "value": "",
            "display": "Dates of first edition"
        },
        {
            "key": "isbn_issn_number",
            "value": "1845110749",
            "display": "Isbn issn number"
        },
        {
            "key": "language_of_publication",
            "value": "",
            "display": "Language of publication"
        },
        {
            "key": "number_of_pages_in_publication_text",
            "value": null,
            "display": "Number of pages in publication text"
        },
        {
            "key": "personality_film_visually_featured_on_cover",
            "value": null,
            "display": "Personality film visually featured on cover"
        },
        {
            "key": "publisher",
            "value": "I B Tauris",
            "display": "Publisher"
        },
        {
            "key": "serial_number",
            "value": null,
            "display": "Serial number"
        },
        {
            "key": "sortable_year_date_of_publication",
            "value": "2005",
            "display": "Sortable year date of publication"
        },
        {
            "key": "sub_title_of_document_book",
            "value": null,
            "display": "Sub title of document book"
        },
        {
            "key": "title_of_document_book",
            "value": null,
            "display": "Title of document book"
        },
        {
            "key": "book_title_of_document",
            "value": "Satyajit Ray",
            "display": "Book title of document"
        },
        {
            "key": "book_sub_title_of_document",
            "value": "A Vision of Cinema",
            "display": "Book sub title of document"
        },
        {
            "key": "volume_issue_number",
            "value": null,
            "display": "Volume issue number"
        },
        {
            "key": "masterlist",
            "value": "satyajit-ray",
            "display": "Masterlist"
        },
        {
            "key": "image",
            "value": ["https://tuliresearchcentre.canto.global/direct/image/egpjgdchdp20p4h6skf9aq982g/BJKO1SueFEcBR0oef6J0QLtVcyg/m800/800"],
            "display": "Image"
        },
        {
            "key": "document_type",
            "value": "bke",
            "display": "Document type"
        },
        {
            "key": "title_and_type_of_creator",
            "value": "Editor",
            "display": "Title and type of creator"
        },
        {
            "key": "number_of_pages_in_publication",
            "value": null,
            "display": "Number of pages in publication"
        },
        {
            "key": "researchCategories",
            "value": [
                {
                    "key": "Cinema as a Key Educational Resource",
                    "value": "CinemaE",
                    "link": "cinema-as-a-key-educational-resource"
                },
                {
                    "key": "History & Historiography of Indian Scholarship in the Arts, Social Sciences & Humanities",
                    "value": "Scholarship",
                    "link": "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities"
                },
                {
                    "key": "Popular Arts, Printed Image & Crafts of India",
                    "value": "PopCult",
                    "link": "popular-arts-printed-image-crafts-of-india"
                }
            ],
            "display": "Research categories"
        },
        {
            "key": "creator",
            "value": [
                {
                    "key": "andrew-robinson",
                    "value": "Andrew Robinson",
                    "first_name": "Andrew",
                    "last_name": "Robinson"
                }
            ],
            "display": "Creator"
        }
    ],
    [
        {
            "key": "id",
            "value": "6e90fe6a-5d36-4fde-9910-d3b002f06e2c",
            "display": "Id"
        },
        {
            "key": "title",
            "value": null,
            "display": "Title"
        },
        {
            "key": "alias",
            "value": "bka-roberge-satyajit-ray-2007",
            "display": "Alias"
        },
        {
            "key": "web_serial_number",
            "value": null,
            "display": "Web serial number"
        },
        {
            "key": "reference_code",
            "value": null,
            "display": "Reference code"
        },
        {
            "key": "web_display",
            "value": null,
            "display": "Web display"
        },
        {
            "key": "date_of_library_publication",
            "value": null,
            "display": "Date of library publication"
        },
        {
            "key": "place_of_publication",
            "value": "New Delhi",
            "display": "Place of publication"
        },
        {
            "key": "histographical_note",
            "value": null,
            "display": "Histographical note"
        },
        {
            "key": "main_historical_note",
            "value": null,
            "display": "Main historical note"
        },
        {
            "key": "binding_status",
            "value": "",
            "display": "Binding status"
        },
        {
            "key": "accession_number",
            "value": "0000038",
            "display": "Accession number"
        },
        {
            "key": "cdt",
            "value": null,
            "display": "CDT"
        },
        {
            "key": "country_of_publication",
            "value": "India",
            "display": "Country of publication"
        },
        {
            "key": "date_of_published_edition_in_library",
            "value": "2007",
            "display": "Date of published edition in library"
        },
        {
            "key": "dates_of_first_edition",
            "value": "",
            "display": "Dates of first edition"
        },
        {
            "key": "isbn_issn_number",
            "value": "81-73047359",
            "display": "Isbn issn number"
        },
        {
            "key": "language_of_publication",
            "value": "",
            "display": "Language of publication"
        },
        {
            "key": "number_of_pages_in_publication_text",
            "value": null,
            "display": "Number of pages in publication text"
        },
        {
            "key": "personality_film_visually_featured_on_cover",
            "value": null,
            "display": "Personality film visually featured on cover"
        },
        {
            "key": "publisher",
            "value": "Manohar",
            "display": "Publisher"
        },
        {
            "key": "serial_number",
            "value": null,
            "display": "Serial number"
        },
        {
            "key": "sortable_year_date_of_publication",
            "value": "2007",
            "display": "Sortable year date of publication"
        },
        {
            "key": "sub_title_of_document_book",
            "value": null,
            "display": "Sub title of document book"
        },
        {
            "key": "title_of_document_book",
            "value": null,
            "display": "Title of document book"
        },
        {
            "key": "book_title_of_document",
            "value": "Satyajit Ray",
            "display": "Book title of document"
        },
        {
            "key": "book_sub_title_of_document",
            "value": "",
            "display": "Book sub title of document"
        },
        {
            "key": "volume_issue_number",
            "value": null,
            "display": "Volume issue number"
        },
        {
            "key": "masterlist",
            "value": "satyajit-ray",
            "display": "Masterlist"
        },
        {
            "key": "image",
            "value": ["https://tuliresearchcentre.canto.global/direct/image/invae7aiip6elb68p8tccces3r/06bEoKwVczWLYDTPIOIGPzpu5RM/m800/800"],
            "display": "Image"
        },
        {
            "key": "document_type",
            "value": "bka",
            "display": "Document type"
        },
        {
            "key": "title_and_type_of_creator",
            "value": "Author",
            "display": "Title and type of creator"
        },
        {
            "key": "number_of_pages_in_publication",
            "value": null,
            "display": "Number of pages in publication"
        },
        {
            "key": "researchCategories",
            "value": [
                {
                    "key": "Cinema as a Key Educational Resource",
                    "value": "CinemaE",
                    "link": "cinema-as-a-key-educational-resource"
                }
            ],
            "display": "Research categories"
        },
        {
            "key": "creator",
            "value": [
                {
                    "key": "gaston-roberge",
                    "value": "Gaston Roberge",
                    "first_name": "Gaston",
                    "last_name": "Roberge"
                }
            ],
            "display": "Creator"
        }
    ],
    [
        {
            "key": "id",
            "value": "715bcae5-68e4-4a9d-bea1-e08f7c95acbf",
            "display": "Id"
        },
        {
            "key": "title",
            "value": null,
            "display": "Title"
        },
        {
            "key": "alias",
            "value": "bka-bahadur-a-textual-study-of-the-apu-trilogy-2011",
            "display": "Alias"
        },
        {
            "key": "web_serial_number",
            "value": null,
            "display": "Web serial number"
        },
        {
            "key": "reference_code",
            "value": null,
            "display": "Reference code"
        },
        {
            "key": "web_display",
            "value": null,
            "display": "Web display"
        },
        {
            "key": "date_of_library_publication",
            "value": null,
            "display": "Date of library publication"
        },
        {
            "key": "place_of_publication",
            "value": "New Delhi",
            "display": "Place of publication"
        },
        {
            "key": "histographical_note",
            "value": null,
            "display": "Histographical note"
        },
        {
            "key": "main_historical_note",
            "value": null,
            "display": "Main historical note"
        },
        {
            "key": "binding_status",
            "value": "HB",
            "display": "Binding status"
        },
        {
            "key": "accession_number",
            "value": "0000070",
            "display": "Accession number"
        },
        {
            "key": "cdt",
            "value": null,
            "display": "CDT"
        },
        {
            "key": "country_of_publication",
            "value": "India",
            "display": "Country of publication"
        },
        {
            "key": "date_of_published_edition_in_library",
            "value": "2011",
            "display": "Date of published edition in library"
        },
        {
            "key": "dates_of_first_edition",
            "value": "",
            "display": "Dates of first edition"
        },
        {
            "key": "isbn_issn_number",
            "value": "978-9350004289",
            "display": "Isbn issn number"
        },
        {
            "key": "language_of_publication",
            "value": "",
            "display": "Language of publication"
        },
        {
            "key": "number_of_pages_in_publication_text",
            "value": null,
            "display": "Number of pages in publication text"
        },
        {
            "key": "personality_film_visually_featured_on_cover",
            "value": null,
            "display": "Personality film visually featured on cover"
        },
        {
            "key": "publisher",
            "value": "Vani",
            "display": "Publisher"
        },
        {
            "key": "serial_number",
            "value": null,
            "display": "Serial number"
        },
        {
            "key": "sortable_year_date_of_publication",
            "value": "2011",
            "display": "Sortable year date of publication"
        },
        {
            "key": "sub_title_of_document_book",
            "value": null,
            "display": "Sub title of document book"
        },
        {
            "key": "title_of_document_book",
            "value": null,
            "display": "Title of document book"
        },
        {
            "key": "book_title_of_document",
            "value": "A Textual Study of the Apu Trilogy",
            "display": "Book title of document"
        },
        {
            "key": "book_sub_title_of_document",
            "value": "",
            "display": "Book sub title of document"
        },
        {
            "key": "volume_issue_number",
            "value": null,
            "display": "Volume issue number"
        },
        {
            "key": "masterlist",
            "value": "satyajit-ray",
            "display": "Masterlist"
        },
        {
            "key": "image",
            "value": ["https://tuliresearchcentre.canto.global/direct/image/bboako27dl5qt38cbj9phr3c62/scn5ShxbspIWo1kf-eUQ5a9yugk/m800/800"],
            "display": "Image"
        },
        {
            "key": "document_type",
            "value": "bka",
            "display": "Document type"
        },
        {
            "key": "title_and_type_of_creator",
            "value": "Author",
            "display": "Title and type of creator"
        },
        {
            "key": "number_of_pages_in_publication",
            "value": null,
            "display": "Number of pages in publication"
        },
        {
            "key": "researchCategories",
            "value": [
                {
                    "key": "Cinema as a Key Educational Resource",
                    "value": "CinemaE",
                    "link": "cinema-as-a-key-educational-resource"
                },
                {
                    "key": "History & Historiography of Indian Scholarship in the Arts, Social Sciences & Humanities",
                    "value": "Scholarship",
                    "link": "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities"
                }
            ],
            "display": "Research categories"
        },
        {
            "key": "creator",
            "value": [
                {
                    "key": "satish-bahadur",
                    "value": "Satish Bahadur",
                    "first_name": "Satish",
                    "last_name": "Bahadur"
                }
            ],
            "display": "Creator"
        }
    ],
    [
        {
            "key": "id",
            "value": "4cc382c5-6ffd-4e3c-9cdd-a3478fa88865",
            "display": "Id"
        },
        {
            "key": "title",
            "value": null,
            "display": "Title"
        },
        {
            "key": "alias",
            "value": "bka-sen-looking-beyond-2012",
            "display": "Alias"
        },
        {
            "key": "web_serial_number",
            "value": null,
            "display": "Web serial number"
        },
        {
            "key": "reference_code",
            "value": null,
            "display": "Reference code"
        },
        {
            "key": "web_display",
            "value": null,
            "display": "Web display"
        },
        {
            "key": "date_of_library_publication",
            "value": null,
            "display": "Date of library publication"
        },
        {
            "key": "place_of_publication",
            "value": "New Delhi",
            "display": "Place of publication"
        },
        {
            "key": "histographical_note",
            "value": null,
            "display": "Histographical note"
        },
        {
            "key": "main_historical_note",
            "value": null,
            "display": "Main historical note"
        },
        {
            "key": "binding_status",
            "value": "PB",
            "display": "Binding status"
        },
        {
            "key": "accession_number",
            "value": "0000040",
            "display": "Accession number"
        },
        {
            "key": "cdt",
            "value": null,
            "display": "CDT"
        },
        {
            "key": "country_of_publication",
            "value": "India",
            "display": "Country of publication"
        },
        {
            "key": "date_of_published_edition_in_library",
            "value": "2012",
            "display": "Date of published edition in library"
        },
        {
            "key": "dates_of_first_edition",
            "value": "",
            "display": "Dates of first edition"
        },
        {
            "key": "isbn_issn_number",
            "value": "978-8174365651",
            "display": "Isbn issn number"
        },
        {
            "key": "language_of_publication",
            "value": "",
            "display": "Language of publication"
        },
        {
            "key": "number_of_pages_in_publication_text",
            "value": null,
            "display": "Number of pages in publication text"
        },
        {
            "key": "personality_film_visually_featured_on_cover",
            "value": null,
            "display": "Personality film visually featured on cover"
        },
        {
            "key": "publisher",
            "value": "Roli Books",
            "display": "Publisher"
        },
        {
            "key": "serial_number",
            "value": null,
            "display": "Serial number"
        },
        {
            "key": "sortable_year_date_of_publication",
            "value": "2012",
            "display": "Sortable year date of publication"
        },
        {
            "key": "sub_title_of_document_book",
            "value": null,
            "display": "Sub title of document book"
        },
        {
            "key": "title_of_document_book",
            "value": null,
            "display": "Title of document book"
        },
        {
            "key": "book_title_of_document",
            "value": "Looking Beyond",
            "display": "Book title of document"
        },
        {
            "key": "book_sub_title_of_document",
            "value": "Graphics of Satyajit Ray",
            "display": "Book sub title of document"
        },
        {
            "key": "volume_issue_number",
            "value": null,
            "display": "Volume issue number"
        },
        {
            "key": "masterlist",
            "value": "satyajit-ray",
            "display": "Masterlist"
        },
        {
            "key": "image",
            "value": ["https://tuliresearchcentre.canto.global/direct/image/ght4l47dvd1jt7fi2pigju2s6e/mCNVf8TsVW4WPTjOyYXwTKWREw0/m800/800"],
            "display": "Image"
        },
        {
            "key": "document_type",
            "value": "bka",
            "display": "Document type"
        },
        {
            "key": "title_and_type_of_creator",
            "value": "Author",
            "display": "Title and type of creator"
        },
        {
            "key": "number_of_pages_in_publication",
            "value": null,
            "display": "Number of pages in publication"
        },
        {
            "key": "researchCategories",
            "value": [
                {
                    "key": "Cinema as a Key Educational Resource",
                    "value": "CinemaE",
                    "link": "cinema-as-a-key-educational-resource"
                },
                {
                    "key": "Popular Arts, Printed Image & Crafts of India",
                    "value": "PopCult",
                    "link": "popular-arts-printed-image-crafts-of-india"
                }
            ],
            "display": "Research categories"
        },
        {
            "key": "creator",
            "value": [
                {
                    "key": "jayanti-sen",
                    "value": "Jayanti Sen",
                    "first_name": "Jayanti",
                    "last_name": "Sen"
                }
            ],
            "display": "Creator"
        }
    ],
    [
        {
            "key": "id",
            "value": "6c3ec717-596e-4945-961c-2c38343a6314",
            "display": "Id"
        },
        {
            "key": "title",
            "value": null,
            "display": "Title"
        },
        {
            "key": "alias",
            "value": "bka-ghosh-satyajit-ray-and-beyond-2013",
            "display": "Alias"
        },
        {
            "key": "web_serial_number",
            "value": null,
            "display": "Web serial number"
        },
        {
            "key": "reference_code",
            "value": null,
            "display": "Reference code"
        },
        {
            "key": "web_display",
            "value": null,
            "display": "Web display"
        },
        {
            "key": "date_of_library_publication",
            "value": null,
            "display": "Date of library publication"
        },
        {
            "key": "place_of_publication",
            "value": "New Delhi",
            "display": "Place of publication"
        },
        {
            "key": "histographical_note",
            "value": null,
            "display": "Histographical note"
        },
        {
            "key": "main_historical_note",
            "value": null,
            "display": "Main historical note"
        },
        {
            "key": "binding_status",
            "value": "HB",
            "display": "Binding status"
        },
        {
            "key": "accession_number",
            "value": "0000032",
            "display": "Accession number"
        },
        {
            "key": "cdt",
            "value": null,
            "display": "CDT"
        },
        {
            "key": "country_of_publication",
            "value": "India",
            "display": "Country of publication"
        },
        {
            "key": "date_of_published_edition_in_library",
            "value": "2013",
            "display": "Date of published edition in library"
        },
        {
            "key": "dates_of_first_edition",
            "value": "",
            "display": "Dates of first edition"
        },
        {
            "key": "isbn_issn_number",
            "value": "978-9381217313",
            "display": "Isbn issn number"
        },
        {
            "key": "language_of_publication",
            "value": "",
            "display": "Language of publication"
        },
        {
            "key": "number_of_pages_in_publication_text",
            "value": null,
            "display": "Number of pages in publication text"
        },
        {
            "key": "personality_film_visually_featured_on_cover",
            "value": null,
            "display": "Personality film visually featured on cover"
        },
        {
            "key": "publisher",
            "value": "Delhi Art Gallery",
            "display": "Publisher"
        },
        {
            "key": "serial_number",
            "value": null,
            "display": "Serial number"
        },
        {
            "key": "sortable_year_date_of_publication",
            "value": "2013",
            "display": "Sortable year date of publication"
        },
        {
            "key": "sub_title_of_document_book",
            "value": null,
            "display": "Sub title of document book"
        },
        {
            "key": "title_of_document_book",
            "value": null,
            "display": "Title of document book"
        },
        {
            "key": "book_title_of_document",
            "value": "Satyajit Ray and Beyond",
            "display": "Book title of document"
        },
        {
            "key": "book_sub_title_of_document",
            "value": "",
            "display": "Book sub title of document"
        },
        {
            "key": "volume_issue_number",
            "value": null,
            "display": "Volume issue number"
        },
        {
            "key": "masterlist",
            "value": "satyajit-ray",
            "display": "Masterlist"
        },
        {
            "key": "image",
            "value": ["https://tuliresearchcentre.canto.global/direct/image/nasua0toht6e1ci7ivg3rkpj3g/WEoFWKCL41BnCculov5xFxDfGYQ/m800/800"],
            "display": "Image"
        },
        {
            "key": "document_type",
            "value": "bka",
            "display": "Document type"
        },
        {
            "key": "title_and_type_of_creator",
            "value": "Author",
            "display": "Title and type of creator"
        },
        {
            "key": "number_of_pages_in_publication",
            "value": null,
            "display": "Number of pages in publication"
        },
        {
            "key": "researchCategories",
            "value": [
                {
                    "key": "Cinema as a Key Educational Resource",
                    "value": "CinemaE",
                    "link": "cinema-as-a-key-educational-resource"
                },
                {
                    "key": "Photography in India",
                    "value": "Photo",
                    "link": "photography-in-india"
                }
            ],
            "display": "Research categories"
        },
        {
            "key": "creator",
            "value": [
                {
                    "key": "nemai-ghosh",
                    "value": "Nemai Ghosh",
                    "first_name": "Nemai",
                    "last_name": "Ghosh"
                }
            ],
            "display": "Creator"
        }
    ],
    [
        {
            "key": "id",
            "value": "bf93f99d-4d2e-4136-81f4-385b1505f85a",
            "display": "Id"
        },
        {
            "key": "title",
            "value": null,
            "display": "Title"
        },
        {
            "key": "alias",
            "value": "bke-ray-satyajit-rays-ravi-shankar-2014",
            "display": "Alias"
        },
        {
            "key": "web_serial_number",
            "value": null,
            "display": "Web serial number"
        },
        {
            "key": "reference_code",
            "value": null,
            "display": "Reference code"
        },
        {
            "key": "web_display",
            "value": null,
            "display": "Web display"
        },
        {
            "key": "date_of_library_publication",
            "value": null,
            "display": "Date of library publication"
        },
        {
            "key": "place_of_publication",
            "value": "Noida",
            "display": "Place of publication"
        },
        {
            "key": "histographical_note",
            "value": null,
            "display": "Histographical note"
        },
        {
            "key": "main_historical_note",
            "value": null,
            "display": "Main historical note"
        },
        {
            "key": "binding_status",
            "value": "HB",
            "display": "Binding status"
        },
        {
            "key": "accession_number",
            "value": "0000044",
            "display": "Accession number"
        },
        {
            "key": "cdt",
            "value": null,
            "display": "CDT"
        },
        {
            "key": "country_of_publication",
            "value": "India",
            "display": "Country of publication"
        },
        {
            "key": "date_of_published_edition_in_library",
            "value": "2014",
            "display": "Date of published edition in library"
        },
        {
            "key": "dates_of_first_edition",
            "value": "",
            "display": "Dates of first edition"
        },
        {
            "key": "isbn_issn_number",
            "value": "978-9351361749",
            "display": "Isbn issn number"
        },
        {
            "key": "language_of_publication",
            "value": "",
            "display": "Language of publication"
        },
        {
            "key": "number_of_pages_in_publication_text",
            "value": null,
            "display": "Number of pages in publication text"
        },
        {
            "key": "personality_film_visually_featured_on_cover",
            "value": null,
            "display": "Personality film visually featured on cover"
        },
        {
            "key": "publisher",
            "value": "Collins in association with the Society for the Preservation of Satyajit Ray Archives",
            "display": "Publisher"
        },
        {
            "key": "serial_number",
            "value": null,
            "display": "Serial number"
        },
        {
            "key": "sortable_year_date_of_publication",
            "value": "2014",
            "display": "Sortable year date of publication"
        },
        {
            "key": "sub_title_of_document_book",
            "value": null,
            "display": "Sub title of document book"
        },
        {
            "key": "title_of_document_book",
            "value": null,
            "display": "Title of document book"
        },
        {
            "key": "book_title_of_document",
            "value": "Satyajit Ray's Ravi Shankar",
            "display": "Book title of document"
        },
        {
            "key": "book_sub_title_of_document",
            "value": "An Unfilimed Visual Script",
            "display": "Book sub title of document"
        },
        {
            "key": "volume_issue_number",
            "value": null,
            "display": "Volume issue number"
        },
        {
            "key": "masterlist",
            "value": "satyajit-ray",
            "display": "Masterlist"
        },
        {
            "key": "image",
            "value": ["https://tuliresearchcentre.canto.global/direct/image/f78n060ej57gn41lb3s9bck44l/aS82ufFCMmh_rI3gd1418JVbkno/m800/800"],
            "display": "Image"
        },
        {
            "key": "document_type",
            "value": "bke",
            "display": "Document type"
        },
        {
            "key": "title_and_type_of_creator",
            "value": "Editor",
            "display": "Title and type of creator"
        },
        {
            "key": "number_of_pages_in_publication",
            "value": null,
            "display": "Number of pages in publication"
        },
        {
            "key": "researchCategories",
            "value": [
                {
                    "key": "History & Historiography of Indian Scholarship in the Arts, Social Sciences & Humanities",
                    "value": "Scholarship",
                    "link": "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities"
                },
                {
                    "key": "Cinema as a Key Educational Resource",
                    "value": "CinemaE",
                    "link": "cinema-as-a-key-educational-resource"
                },
                {
                    "key": "Modern & Contemporary Fine Arts in India",
                    "value": "FineArts",
                    "link": "modern-contemporary-fine-arts-in-india"
                }
            ],
            "display": "Research categories"
        },
        {
            "key": "creator",
            "value": [
                {
                    "key": "sandip-ray",
                    "value": null,
                    "first_name": null,
                    "last_name": null
                }
            ],
            "display": "Creator"
        }
    ],
    [
        {
            "key": "id",
            "value": "cbad1667-ff7e-480a-8a64-d32fd5c3c26d",
            "display": "Id"
        },
        {
            "key": "title",
            "value": null,
            "display": "Title"
        },
        {
            "key": "alias",
            "value": "bka-cherian-indias-film-society-movement-2017",
            "display": "Alias"
        },
        {
            "key": "web_serial_number",
            "value": null,
            "display": "Web serial number"
        },
        {
            "key": "reference_code",
            "value": null,
            "display": "Reference code"
        },
        {
            "key": "web_display",
            "value": null,
            "display": "Web display"
        },
        {
            "key": "date_of_library_publication",
            "value": null,
            "display": "Date of library publication"
        },
        {
            "key": "place_of_publication",
            "value": "New Delhi",
            "display": "Place of publication"
        },
        {
            "key": "histographical_note",
            "value": null,
            "display": "Histographical note"
        },
        {
            "key": "main_historical_note",
            "value": null,
            "display": "Main historical note"
        },
        {
            "key": "binding_status",
            "value": "HB",
            "display": "Binding status"
        },
        {
            "key": "accession_number",
            "value": "0000037",
            "display": "Accession number"
        },
        {
            "key": "cdt",
            "value": null,
            "display": "CDT"
        },
        {
            "key": "country_of_publication",
            "value": "India",
            "display": "Country of publication"
        },
        {
            "key": "date_of_published_edition_in_library",
            "value": "2017",
            "display": "Date of published edition in library"
        },
        {
            "key": "dates_of_first_edition",
            "value": "",
            "display": "Dates of first edition"
        },
        {
            "key": "isbn_issn_number",
            "value": "978-9385985638",
            "display": "Isbn issn number"
        },
        {
            "key": "language_of_publication",
            "value": "",
            "display": "Language of publication"
        },
        {
            "key": "number_of_pages_in_publication_text",
            "value": null,
            "display": "Number of pages in publication text"
        },
        {
            "key": "personality_film_visually_featured_on_cover",
            "value": null,
            "display": "Personality film visually featured on cover"
        },
        {
            "key": "publisher",
            "value": "Sage",
            "display": "Publisher"
        },
        {
            "key": "serial_number",
            "value": null,
            "display": "Serial number"
        },
        {
            "key": "sortable_year_date_of_publication",
            "value": "2017",
            "display": "Sortable year date of publication"
        },
        {
            "key": "sub_title_of_document_book",
            "value": null,
            "display": "Sub title of document book"
        },
        {
            "key": "title_of_document_book",
            "value": null,
            "display": "Title of document book"
        },
        {
            "key": "book_title_of_document",
            "value": "India's Film Society Movement",
            "display": "Book title of document"
        },
        {
            "key": "book_sub_title_of_document",
            "value": "The Journey and its Impact",
            "display": "Book sub title of document"
        },
        {
            "key": "volume_issue_number",
            "value": null,
            "display": "Volume issue number"
        },
        {
            "key": "masterlist",
            "value": "satyajit-ray",
            "display": "Masterlist"
        },
        {
            "key": "image",
            "value": ["https://tuliresearchcentre.canto.global/direct/image/d4ib3ctkh9619e00hd6pu9sd10/aR0Ou22ieFX_ltrBnXgRzjP0UjE/m800/800"],
            "display": "Image"
        },
        {
            "key": "document_type",
            "value": "bka",
            "display": "Document type"
        },
        {
            "key": "title_and_type_of_creator",
            "value": "Author",
            "display": "Title and type of creator"
        },
        {
            "key": "number_of_pages_in_publication",
            "value": null,
            "display": "Number of pages in publication"
        },
        {
            "key": "researchCategories",
            "value": [
                {
                    "key": "Social Responsibility of the Creative Mind ",
                    "value": "SocialR",
                    "link": "social-responsibility-of-the-creative-mind"
                },
                {
                    "key": "Cinema as a Key Educational Resource",
                    "value": "CinemaE",
                    "link": "cinema-as-a-key-educational-resource"
                }
            ],
            "display": "Research categories"
        },
        {
            "key": "creator",
            "value": [
                {
                    "key": "v-k-cherian",
                    "value": "V K Cherian",
                    "first_name": "V-K",
                    "last_name": "Cherian"
                }
            ],
            "display": "Creator"
        }
    ],
    [
        {
            "key": "id",
            "value": "5e50d364-e0f6-4832-8e4f-af01a3f789d7",
            "display": "Id"
        },
        {
            "key": "title",
            "value": null,
            "display": "Title"
        },
        {
            "key": "alias",
            "value": "bka-jindal-my-adventures-with-satyajit-ray-2017",
            "display": "Alias"
        },
        {
            "key": "web_serial_number",
            "value": null,
            "display": "Web serial number"
        },
        {
            "key": "reference_code",
            "value": null,
            "display": "Reference code"
        },
        {
            "key": "web_display",
            "value": null,
            "display": "Web display"
        },
        {
            "key": "date_of_library_publication",
            "value": null,
            "display": "Date of library publication"
        },
        {
            "key": "place_of_publication",
            "value": "Gurugram",
            "display": "Place of publication"
        },
        {
            "key": "histographical_note",
            "value": null,
            "display": "Histographical note"
        },
        {
            "key": "main_historical_note",
            "value": null,
            "display": "Main historical note"
        },
        {
            "key": "binding_status",
            "value": "PB",
            "display": "Binding status"
        },
        {
            "key": "accession_number",
            "value": "0000039",
            "display": "Accession number"
        },
        {
            "key": "cdt",
            "value": null,
            "display": "CDT"
        },
        {
            "key": "country_of_publication",
            "value": "India",
            "display": "Country of publication"
        },
        {
            "key": "date_of_published_edition_in_library",
            "value": "2017",
            "display": "Date of published edition in library"
        },
        {
            "key": "dates_of_first_edition",
            "value": "",
            "display": "Dates of first edition"
        },
        {
            "key": "isbn_issn_number",
            "value": "978-9352771025",
            "display": "Isbn issn number"
        },
        {
            "key": "language_of_publication",
            "value": "",
            "display": "Language of publication"
        },
        {
            "key": "number_of_pages_in_publication_text",
            "value": null,
            "display": "Number of pages in publication text"
        },
        {
            "key": "personality_film_visually_featured_on_cover",
            "value": null,
            "display": "Personality film visually featured on cover"
        },
        {
            "key": "publisher",
            "value": "HarperCollins",
            "display": "Publisher"
        },
        {
            "key": "serial_number",
            "value": null,
            "display": "Serial number"
        },
        {
            "key": "sortable_year_date_of_publication",
            "value": "2017",
            "display": "Sortable year date of publication"
        },
        {
            "key": "sub_title_of_document_book",
            "value": null,
            "display": "Sub title of document book"
        },
        {
            "key": "title_of_document_book",
            "value": null,
            "display": "Title of document book"
        },
        {
            "key": "book_title_of_document",
            "value": "My Adventures with Satyajit Ray",
            "display": "Book title of document"
        },
        {
            "key": "book_sub_title_of_document",
            "value": "The Making of Shatranj Ke Khilari",
            "display": "Book sub title of document"
        },
        {
            "key": "volume_issue_number",
            "value": null,
            "display": "Volume issue number"
        },
        {
            "key": "masterlist",
            "value": "satyajit-ray",
            "display": "Masterlist"
        },
        {
            "key": "image",
            "value": ["https://tuliresearchcentre.canto.global/direct/image/q67095kdnp5fp2r8d2c02rtu53/4EmN-s3VNr8sxXJJ9tvEH8xwGnQ/m800/800"],
            "display": "Image"
        },
        {
            "key": "document_type",
            "value": "bka",
            "display": "Document type"
        },
        {
            "key": "title_and_type_of_creator",
            "value": "Author",
            "display": "Title and type of creator"
        },
        {
            "key": "number_of_pages_in_publication",
            "value": null,
            "display": "Number of pages in publication"
        },
        {
            "key": "researchCategories",
            "value": [
                {
                    "key": "Economics of Art, Cinema & Culture",
                    "value": "EconArts",
                    "link": "economics-of-art-cinema-culture"
                },
                {
                    "key": "Cinema as a Key Educational Resource",
                    "value": "CinemaE",
                    "link": "cinema-as-a-key-educational-resource"
                }
            ],
            "display": "Research categories"
        },
        {
            "key": "creator",
            "value": [
                {
                    "key": "suresh-jindal",
                    "value": "Suresh Jindal",
                    "first_name": "Suresh",
                    "last_name": "Jindal"
                }
            ],
            "display": "Creator"
        }
    ],
    [
        {
            "key": "id",
            "value": "ab5c5147-32e3-4f6f-8822-3721c29d2c9a",
            "display": "Id"
        },
        {
            "key": "title",
            "value": null,
            "display": "Title"
        },
        {
            "key": "alias",
            "value": "bke-ray-travails-with-the-alien-2018",
            "display": "Alias"
        },
        {
            "key": "web_serial_number",
            "value": null,
            "display": "Web serial number"
        },
        {
            "key": "reference_code",
            "value": null,
            "display": "Reference code"
        },
        {
            "key": "web_display",
            "value": null,
            "display": "Web display"
        },
        {
            "key": "date_of_library_publication",
            "value": null,
            "display": "Date of library publication"
        },
        {
            "key": "place_of_publication",
            "value": "Noida",
            "display": "Place of publication"
        },
        {
            "key": "histographical_note",
            "value": null,
            "display": "Histographical note"
        },
        {
            "key": "main_historical_note",
            "value": null,
            "display": "Main historical note"
        },
        {
            "key": "binding_status",
            "value": "PB",
            "display": "Binding status"
        },
        {
            "key": "accession_number",
            "value": "0000048",
            "display": "Accession number"
        },
        {
            "key": "cdt",
            "value": null,
            "display": "CDT"
        },
        {
            "key": "country_of_publication",
            "value": "India",
            "display": "Country of publication"
        },
        {
            "key": "date_of_published_edition_in_library",
            "value": "2018",
            "display": "Date of published edition in library"
        },
        {
            "key": "dates_of_first_edition",
            "value": "",
            "display": "Dates of first edition"
        },
        {
            "key": "isbn_issn_number",
            "value": "978-9352779154",
            "display": "Isbn issn number"
        },
        {
            "key": "language_of_publication",
            "value": "",
            "display": "Language of publication"
        },
        {
            "key": "number_of_pages_in_publication_text",
            "value": null,
            "display": "Number of pages in publication text"
        },
        {
            "key": "personality_film_visually_featured_on_cover",
            "value": null,
            "display": "Personality film visually featured on cover"
        },
        {
            "key": "publisher",
            "value": "Harper Collins",
            "display": "Publisher"
        },
        {
            "key": "serial_number",
            "value": null,
            "display": "Serial number"
        },
        {
            "key": "sortable_year_date_of_publication",
            "value": "2018",
            "display": "Sortable year date of publication"
        },
        {
            "key": "sub_title_of_document_book",
            "value": null,
            "display": "Sub title of document book"
        },
        {
            "key": "title_of_document_book",
            "value": null,
            "display": "Title of document book"
        },
        {
            "key": "book_title_of_document",
            "value": "Travails With the Alien",
            "display": "Book title of document"
        },
        {
            "key": "book_sub_title_of_document",
            "value": "The Film That was Never Made and Other Adventures with Science Fiction : Satyajit Ray",
            "display": "Book sub title of document"
        },
        {
            "key": "volume_issue_number",
            "value": null,
            "display": "Volume issue number"
        },
        {
            "key": "masterlist",
            "value": "satyajit-ray",
            "display": "Masterlist"
        },
        {
            "key": "image",
            "value": ["https://tuliresearchcentre.canto.global/direct/image/d8s5l1is5h2lr8t5d3gvh8o766/kD27cH5am2JMgN8suQ_zMU74In8/m800/800"],
            "display": "Image"
        },
        {
            "key": "document_type",
            "value": "bke",
            "display": "Document type"
        },
        {
            "key": "title_and_type_of_creator",
            "value": "Editor",
            "display": "Title and type of creator"
        },
        {
            "key": "number_of_pages_in_publication",
            "value": null,
            "display": "Number of pages in publication"
        },
        {
            "key": "researchCategories",
            "value": [
                {
                    "key": "Uncertainty Revealing Itself : Death | Magic | Mystery | Sci-Fi | Horror | Gambling  | A.I.",
                    "value": "Uncertainty",
                    "link": "uncertainy-revealing-itself-death-magic-mystery-sci-fi-horror-gambling-ai"
                },
                {
                    "key": "History & Historiography of Indian Scholarship in the Arts, Social Sciences & Humanities",
                    "value": "Scholarship",
                    "link": "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities"
                },
                {
                    "key": "Cinema as a Key Educational Resource",
                    "value": "CinemaE",
                    "link": "cinema-as-a-key-educational-resource"
                }
            ],
            "display": "Research categories"
        },
        {
            "key": "creator",
            "value": [
                {
                    "key": "sandip-ray",
                    "value": null,
                    "first_name": null,
                    "last_name": null
                }
            ],
            "display": "Creator"
        }
    ],
    [
        {
            "key": "id",
            "value": "de79c8bc-86f5-4dac-8586-3363d0b1c21f",
            "display": "Id"
        },
        {
            "key": "title",
            "value": null,
            "display": "Title"
        },
        {
            "key": "alias",
            "value": "bke-devasundaram-indian-cinema-beyond-bollywood-2018",
            "display": "Alias"
        },
        {
            "key": "web_serial_number",
            "value": null,
            "display": "Web serial number"
        },
        {
            "key": "reference_code",
            "value": null,
            "display": "Reference code"
        },
        {
            "key": "web_display",
            "value": null,
            "display": "Web display"
        },
        {
            "key": "date_of_library_publication",
            "value": null,
            "display": "Date of library publication"
        },
        {
            "key": "place_of_publication",
            "value": "New York",
            "display": "Place of publication"
        },
        {
            "key": "histographical_note",
            "value": null,
            "display": "Histographical note"
        },
        {
            "key": "main_historical_note",
            "value": null,
            "display": "Main historical note"
        },
        {
            "key": "binding_status",
            "value": "HB",
            "display": "Binding status"
        },
        {
            "key": "accession_number",
            "value": "0000079",
            "display": "Accession number"
        },
        {
            "key": "cdt",
            "value": null,
            "display": "CDT"
        },
        {
            "key": "country_of_publication",
            "value": "USA",
            "display": "Country of publication"
        },
        {
            "key": "date_of_published_edition_in_library",
            "value": "2018",
            "display": "Date of published edition in library"
        },
        {
            "key": "dates_of_first_edition",
            "value": "",
            "display": "Dates of first edition"
        },
        {
            "key": "isbn_issn_number",
            "value": "978-0815368601",
            "display": "Isbn issn number"
        },
        {
            "key": "language_of_publication",
            "value": "",
            "display": "Language of publication"
        },
        {
            "key": "number_of_pages_in_publication_text",
            "value": null,
            "display": "Number of pages in publication text"
        },
        {
            "key": "personality_film_visually_featured_on_cover",
            "value": null,
            "display": "Personality film visually featured on cover"
        },
        {
            "key": "publisher",
            "value": "Routledge",
            "display": "Publisher"
        },
        {
            "key": "serial_number",
            "value": null,
            "display": "Serial number"
        },
        {
            "key": "sortable_year_date_of_publication",
            "value": "2018",
            "display": "Sortable year date of publication"
        },
        {
            "key": "sub_title_of_document_book",
            "value": null,
            "display": "Sub title of document book"
        },
        {
            "key": "title_of_document_book",
            "value": null,
            "display": "Title of document book"
        },
        {
            "key": "book_title_of_document",
            "value": "Indian Cinema Beyond Bollywood",
            "display": "Book title of document"
        },
        {
            "key": "book_sub_title_of_document",
            "value": "The New Indepenedent Cinema Revolution",
            "display": "Book sub title of document"
        },
        {
            "key": "volume_issue_number",
            "value": null,
            "display": "Volume issue number"
        },
        {
            "key": "masterlist",
            "value": "satyajit-ray",
            "display": "Masterlist"
        },
        {
            "key": "image",
            "value": ["https://tuliresearchcentre.canto.global/direct/image/n2bf4e2u5t1t1f0nh1uc8hjh14/RCTgPLTg1pjtSdSk7HZrfVH4DEs/m800/800"],
            "display": "Image"
        },
        {
            "key": "document_type",
            "value": "bke",
            "display": "Document type"
        },
        {
            "key": "title_and_type_of_creator",
            "value": "Editor",
            "display": "Title and type of creator"
        },
        {
            "key": "number_of_pages_in_publication",
            "value": null,
            "display": "Number of pages in publication"
        },
        {
            "key": "researchCategories",
            "value": [
                {
                    "key": "Cinema as a Key Educational Resource",
                    "value": "CinemaE",
                    "link": "cinema-as-a-key-educational-resource"
                },
                {
                    "key": "History & Historiography of Indian Scholarship in the Arts, Social Sciences & Humanities",
                    "value": "Scholarship",
                    "link": "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities"
                }
            ],
            "display": "Research categories"
        },
        {
            "key": "creator",
            "value": [
                {
                    "key": "ashvin-immanuel-devasundaram",
                    "value": "Ashvin Immanuel Devasundaram",
                    "first_name": "Ashvin-Immanuel",
                    "last_name": "Devasundaram"
                }
            ],
            "display": "Creator"
        }
    ],
    [
        {
            "key": "id",
            "value": "f75ea9b8-00fb-48bf-9eef-adb0cc408714",
            "display": "Id"
        },
        {
            "key": "title",
            "value": null,
            "display": "Title"
        },
        {
            "key": "alias",
            "value": "bka-sengupta-soumitra-chatterjee-2020",
            "display": "Alias"
        },
        {
            "key": "web_serial_number",
            "value": null,
            "display": "Web serial number"
        },
        {
            "key": "reference_code",
            "value": null,
            "display": "Reference code"
        },
        {
            "key": "web_display",
            "value": null,
            "display": "Web display"
        },
        {
            "key": "date_of_library_publication",
            "value": null,
            "display": "Date of library publication"
        },
        {
            "key": "place_of_publication",
            "value": "New Delhi",
            "display": "Place of publication"
        },
        {
            "key": "histographical_note",
            "value": null,
            "display": "Histographical note"
        },
        {
            "key": "main_historical_note",
            "value": null,
            "display": "Main historical note"
        },
        {
            "key": "binding_status",
            "value": "HB",
            "display": "Binding status"
        },
        {
            "key": "accession_number",
            "value": "0000077",
            "display": "Accession number"
        },
        {
            "key": "cdt",
            "value": null,
            "display": "CDT"
        },
        {
            "key": "country_of_publication",
            "value": "India",
            "display": "Country of publication"
        },
        {
            "key": "date_of_published_edition_in_library",
            "value": "2020",
            "display": "Date of published edition in library"
        },
        {
            "key": "dates_of_first_edition",
            "value": "",
            "display": "Dates of first edition"
        },
        {
            "key": "isbn_issn_number",
            "value": "978-9389136760",
            "display": "Isbn issn number"
        },
        {
            "key": "language_of_publication",
            "value": "",
            "display": "Language of publication"
        },
        {
            "key": "number_of_pages_in_publication_text",
            "value": null,
            "display": "Number of pages in publication text"
        },
        {
            "key": "personality_film_visually_featured_on_cover",
            "value": null,
            "display": "Personality film visually featured on cover"
        },
        {
            "key": "publisher",
            "value": "Niyogi Books",
            "display": "Publisher"
        },
        {
            "key": "serial_number",
            "value": null,
            "display": "Serial number"
        },
        {
            "key": "sortable_year_date_of_publication",
            "value": "2020",
            "display": "Sortable year date of publication"
        },
        {
            "key": "sub_title_of_document_book",
            "value": null,
            "display": "Sub title of document book"
        },
        {
            "key": "title_of_document_book",
            "value": null,
            "display": "Title of document book"
        },
        {
            "key": "book_title_of_document",
            "value": "Soumitra Chatterjee",
            "display": "Book title of document"
        },
        {
            "key": "book_sub_title_of_document",
            "value": "A Life in Cinema, Theatre, Poetry & Painting",
            "display": "Book sub title of document"
        },
        {
            "key": "volume_issue_number",
            "value": null,
            "display": "Volume issue number"
        },
        {
            "key": "masterlist",
            "value": "satyajit-ray",
            "display": "Masterlist"
        },
        {
            "key": "image",
            "value": ["https://tuliresearchcentre.canto.global/direct/image/57sfokvt252u347ce80cr3le7r/bhQV6urIurKCOfD281oI8mL8oOs/m800/800"],
            "display": "Image"
        },
        {
            "key": "document_type",
            "value": "bka",
            "display": "Document type"
        },
        {
            "key": "title_and_type_of_creator",
            "value": "Author",
            "display": "Title and type of creator"
        },
        {
            "key": "number_of_pages_in_publication",
            "value": null,
            "display": "Number of pages in publication"
        },
        {
            "key": "researchCategories",
            "value": [
                {
                    "key": "Cinema as a Key Educational Resource",
                    "value": "CinemaE",
                    "link": "cinema-as-a-key-educational-resource"
                }
            ],
            "display": "Research categories"
        },
        {
            "key": "creator",
            "value": [
                {
                    "key": "arjun-sengupta",
                    "value": "Arjun Sengupta",
                    "first_name": "Arjun",
                    "last_name": "Sengupta"
                }
            ],
            "display": "Creator"
        }
    ],
    [
        {
            "key": "id",
            "value": "74d87aa5-4344-46a6-8ab8-5d41e7d2e535",
            "display": "Id"
        },
        {
            "key": "title",
            "value": null,
            "display": "Title"
        },
        {
            "key": "alias",
            "value": "bke-ray-satyajit-ray-2022",
            "display": "Alias"
        },
        {
            "key": "web_serial_number",
            "value": null,
            "display": "Web serial number"
        },
        {
            "key": "reference_code",
            "value": null,
            "display": "Reference code"
        },
        {
            "key": "web_display",
            "value": null,
            "display": "Web display"
        },
        {
            "key": "date_of_library_publication",
            "value": null,
            "display": "Date of library publication"
        },
        {
            "key": "place_of_publication",
            "value": "Gurugram",
            "display": "Place of publication"
        },
        {
            "key": "histographical_note",
            "value": null,
            "display": "Histographical note"
        },
        {
            "key": "main_historical_note",
            "value": null,
            "display": "Main historical note"
        },
        {
            "key": "binding_status",
            "value": "PB",
            "display": "Binding status"
        },
        {
            "key": "accession_number",
            "value": "0000046",
            "display": "Accession number"
        },
        {
            "key": "cdt",
            "value": null,
            "display": "CDT"
        },
        {
            "key": "country_of_publication",
            "value": "India",
            "display": "Country of publication"
        },
        {
            "key": "date_of_published_edition_in_library",
            "value": "2022",
            "display": "Date of published edition in library"
        },
        {
            "key": "dates_of_first_edition",
            "value": "",
            "display": "Dates of first edition"
        },
        {
            "key": "isbn_issn_number",
            "value": "9780143448990",
            "display": "Isbn issn number"
        },
        {
            "key": "language_of_publication",
            "value": "",
            "display": "Language of publication"
        },
        {
            "key": "number_of_pages_in_publication_text",
            "value": null,
            "display": "Number of pages in publication text"
        },
        {
            "key": "personality_film_visually_featured_on_cover",
            "value": null,
            "display": "Personality film visually featured on cover"
        },
        {
            "key": "publisher",
            "value": "Penguin Random House India",
            "display": "Publisher"
        },
        {
            "key": "serial_number",
            "value": null,
            "display": "Serial number"
        },
        {
            "key": "sortable_year_date_of_publication",
            "value": "2022",
            "display": "Sortable year date of publication"
        },
        {
            "key": "sub_title_of_document_book",
            "value": null,
            "display": "Sub title of document book"
        },
        {
            "key": "title_of_document_book",
            "value": null,
            "display": "Title of document book"
        },
        {
            "key": "book_title_of_document",
            "value": "Satyajit Ray",
            "display": "Book title of document"
        },
        {
            "key": "book_sub_title_of_document",
            "value": "Miscellany on Life, Cinema, People & Much More",
            "display": "Book sub title of document"
        },
        {
            "key": "volume_issue_number",
            "value": null,
            "display": "Volume issue number"
        },
        {
            "key": "masterlist",
            "value": "satyajit-ray",
            "display": "Masterlist"
        },
        {
            "key": "image",
            "value": ["https://tuliresearchcentre.canto.global/direct/image/3ldj1re1ll2khdaqno856dph2l/Jw5CQvtfuIZtyBni-37fNh-WBDM/m800/800"],
            "display": "Image"
        },
        {
            "key": "document_type",
            "value": "bke",
            "display": "Document type"
        },
        {
            "key": "title_and_type_of_creator",
            "value": "Editor",
            "display": "Title and type of creator"
        },
        {
            "key": "number_of_pages_in_publication",
            "value": null,
            "display": "Number of pages in publication"
        },
        {
            "key": "researchCategories",
            "value": [
                {
                    "key": "History & Historiography of Indian Scholarship in the Arts, Social Sciences & Humanities",
                    "value": "Scholarship",
                    "link": "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities"
                },
                {
                    "key": "Cinema as a Key Educational Resource",
                    "value": "CinemaE",
                    "link": "cinema-as-a-key-educational-resource"
                }
            ],
            "display": "Research categories"
        },
        {
            "key": "creator",
            "value": [
                {
                    "key": "sandip-ray",
                    "value": null,
                    "first_name": null,
                    "last_name": null
                }
            ],
            "display": "Creator"
        }
    ],
    [
        {
            "key": "id",
            "value": "9c414441-30b7-40e5-8d58-69da0bcb7481",
            "display": "Id"
        },
        {
            "key": "title",
            "value": null,
            "display": "Title"
        },
        {
            "key": "alias",
            "value": "bke-majumdar-a-companion-to-indian-cinema-2022",
            "display": "Alias"
        },
        {
            "key": "web_serial_number",
            "value": null,
            "display": "Web serial number"
        },
        {
            "key": "reference_code",
            "value": null,
            "display": "Reference code"
        },
        {
            "key": "web_display",
            "value": null,
            "display": "Web display"
        },
        {
            "key": "date_of_library_publication",
            "value": null,
            "display": "Date of library publication"
        },
        {
            "key": "place_of_publication",
            "value": "New Jursey",
            "display": "Place of publication"
        },
        {
            "key": "histographical_note",
            "value": null,
            "display": "Histographical note"
        },
        {
            "key": "main_historical_note",
            "value": null,
            "display": "Main historical note"
        },
        {
            "key": "binding_status",
            "value": "HB",
            "display": "Binding status"
        },
        {
            "key": "accession_number",
            "value": "0000069",
            "display": "Accession number"
        },
        {
            "key": "cdt",
            "value": null,
            "display": "CDT"
        },
        {
            "key": "country_of_publication",
            "value": "USA",
            "display": "Country of publication"
        },
        {
            "key": "date_of_published_edition_in_library",
            "value": "2022",
            "display": "Date of published edition in library"
        },
        {
            "key": "dates_of_first_edition",
            "value": "",
            "display": "Dates of first edition"
        },
        {
            "key": "isbn_issn_number",
            "value": "978-1119048268",
            "display": "Isbn issn number"
        },
        {
            "key": "language_of_publication",
            "value": "",
            "display": "Language of publication"
        },
        {
            "key": "number_of_pages_in_publication_text",
            "value": null,
            "display": "Number of pages in publication text"
        },
        {
            "key": "personality_film_visually_featured_on_cover",
            "value": null,
            "display": "Personality film visually featured on cover"
        },
        {
            "key": "publisher",
            "value": "John Wiley",
            "display": "Publisher"
        },
        {
            "key": "serial_number",
            "value": null,
            "display": "Serial number"
        },
        {
            "key": "sortable_year_date_of_publication",
            "value": "2022",
            "display": "Sortable year date of publication"
        },
        {
            "key": "sub_title_of_document_book",
            "value": null,
            "display": "Sub title of document book"
        },
        {
            "key": "title_of_document_book",
            "value": null,
            "display": "Title of document book"
        },
        {
            "key": "book_title_of_document",
            "value": "A Companion to Indian Cinema",
            "display": "Book title of document"
        },
        {
            "key": "book_sub_title_of_document",
            "value": "",
            "display": "Book sub title of document"
        },
        {
            "key": "volume_issue_number",
            "value": null,
            "display": "Volume issue number"
        },
        {
            "key": "masterlist",
            "value": "satyajit-ray",
            "display": "Masterlist"
        },
        {
            "key": "image",
            "value": ["https://tuliresearchcentre.canto.global/direct/image/tc3osd8tbd2p9967efchufk71i/9dBwRpP4g_UUBMZu1m6solHpkxM/m800/800"],
            "display": "Image"
        },
        {
            "key": "document_type",
            "value": "bke",
            "display": "Document type"
        },
        {
            "key": "title_and_type_of_creator",
            "value": "Editor",
            "display": "Title and type of creator"
        },
        {
            "key": "number_of_pages_in_publication",
            "value": null,
            "display": "Number of pages in publication"
        },
        {
            "key": "researchCategories",
            "value": [
                {
                    "key": "Cinema as a Key Educational Resource",
                    "value": "CinemaE",
                    "link": "cinema-as-a-key-educational-resource"
                },
                {
                    "key": "History & Historiography of Indian Scholarship in the Arts, Social Sciences & Humanities",
                    "value": "Scholarship",
                    "link": "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities"
                }
            ],
            "display": "Research categories"
        },
        {
            "key": "creator",
            "value": [
                {
                    "key": "neepa-majumdar",
                    "value": "Neepa Majumdar",
                    "first_name": "Neepa",
                    "last_name": "Majumdar"
                }
            ],
            "display": "Creator"
        }
    ],
    [
        {
            "key": "id",
            "value": "677ebe47-9235-4268-b608-e7e0404a0aaf",
            "display": "Id"
        },
        {
            "key": "title",
            "value": null,
            "display": "Title"
        },
        {
            "key": "alias",
            "value": "bke-saklani-iti-satyajit-da-2022",
            "display": "Alias"
        },
        {
            "key": "web_serial_number",
            "value": null,
            "display": "Web serial number"
        },
        {
            "key": "reference_code",
            "value": null,
            "display": "Reference code"
        },
        {
            "key": "web_display",
            "value": null,
            "display": "Web display"
        },
        {
            "key": "date_of_library_publication",
            "value": null,
            "display": "Date of library publication"
        },
        {
            "key": "place_of_publication",
            "value": "New Delhi",
            "display": "Place of publication"
        },
        {
            "key": "histographical_note",
            "value": null,
            "display": "Histographical note"
        },
        {
            "key": "main_historical_note",
            "value": null,
            "display": "Main historical note"
        },
        {
            "key": "binding_status",
            "value": "HB",
            "display": "Binding status"
        },
        {
            "key": "accession_number",
            "value": "0000041",
            "display": "Accession number"
        },
        {
            "key": "cdt",
            "value": null,
            "display": "CDT"
        },
        {
            "key": "country_of_publication",
            "value": "India",
            "display": "Country of publication"
        },
        {
            "key": "date_of_published_edition_in_library",
            "value": "2022",
            "display": "Date of published edition in library"
        },
        {
            "key": "dates_of_first_edition",
            "value": "",
            "display": "Dates of first edition"
        },
        {
            "key": "isbn_issn_number",
            "value": "978-8185983325",
            "display": "Isbn issn number"
        },
        {
            "key": "language_of_publication",
            "value": "",
            "display": "Language of publication"
        },
        {
            "key": "number_of_pages_in_publication_text",
            "value": null,
            "display": "Number of pages in publication text"
        },
        {
            "key": "personality_film_visually_featured_on_cover",
            "value": null,
            "display": "Personality film visually featured on cover"
        },
        {
            "key": "publisher",
            "value": "Musui Art Foundation",
            "display": "Publisher"
        },
        {
            "key": "serial_number",
            "value": null,
            "display": "Serial number"
        },
        {
            "key": "sortable_year_date_of_publication",
            "value": "2022",
            "display": "Sortable year date of publication"
        },
        {
            "key": "sub_title_of_document_book",
            "value": null,
            "display": "Sub title of document book"
        },
        {
            "key": "title_of_document_book",
            "value": null,
            "display": "Title of document book"
        },
        {
            "key": "book_title_of_document",
            "value": "Iti, Satyajit Da",
            "display": "Book title of document"
        },
        {
            "key": "book_sub_title_of_document",
            "value": "Letters to a Friend from Satyajit Ray",
            "display": "Book sub title of document"
        },
        {
            "key": "volume_issue_number",
            "value": null,
            "display": "Volume issue number"
        },
        {
            "key": "masterlist",
            "value": "satyajit-ray",
            "display": "Masterlist"
        },
        {
            "key": "image",
            "value": ["https://tuliresearchcentre.canto.global/direct/image/q7cs9t0b6t22lasel75o4bje4h/Q38Rdxvzm3uNtyf6xkP0ejbu8HI/m800/800"],
            "display": "Image"
        },
        {
            "key": "document_type",
            "value": "bke",
            "display": "Document type"
        },
        {
            "key": "title_and_type_of_creator",
            "value": "Editor",
            "display": "Title and type of creator"
        },
        {
            "key": "number_of_pages_in_publication",
            "value": null,
            "display": "Number of pages in publication"
        },
        {
            "key": "researchCategories",
            "value": [
                {
                    "key": "Cinema as a Key Educational Resource",
                    "value": "CinemaE",
                    "link": "cinema-as-a-key-educational-resource"
                },
                {
                    "key": "History & Historiography of Indian Scholarship in the Arts, Social Sciences & Humanities",
                    "value": "Scholarship",
                    "link": "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities"
                }
            ],
            "display": "Research categories"
        },
        {
            "key": "creator",
            "value": [
                {
                    "key": "juhi-saklani",
                    "value": "Juhi Saklani",
                    "first_name": "Juhi",
                    "last_name": "Saklani"
                }
            ],
            "display": "Creator"
        }
    ],
    [
        {
            "key": "id",
            "value": "b8f5a08d-532b-4e37-a347-f9f21f4ede1e",
            "display": "Id"
        },
        {
            "key": "title",
            "value": null,
            "display": "Title"
        },
        {
            "key": "alias",
            "value": "bke-ray-the-best-of-satyajit-ray-2023",
            "display": "Alias"
        },
        {
            "key": "web_serial_number",
            "value": null,
            "display": "Web serial number"
        },
        {
            "key": "reference_code",
            "value": null,
            "display": "Reference code"
        },
        {
            "key": "web_display",
            "value": null,
            "display": "Web display"
        },
        {
            "key": "date_of_library_publication",
            "value": null,
            "display": "Date of library publication"
        },
        {
            "key": "place_of_publication",
            "value": "Gurgaon",
            "display": "Place of publication"
        },
        {
            "key": "histographical_note",
            "value": null,
            "display": "Histographical note"
        },
        {
            "key": "main_historical_note",
            "value": null,
            "display": "Main historical note"
        },
        {
            "key": "binding_status",
            "value": "PB",
            "display": "Binding status"
        },
        {
            "key": "accession_number",
            "value": "0000047",
            "display": "Accession number"
        },
        {
            "key": "cdt",
            "value": null,
            "display": "CDT"
        },
        {
            "key": "country_of_publication",
            "value": "India",
            "display": "Country of publication"
        },
        {
            "key": "date_of_published_edition_in_library",
            "value": "2023",
            "display": "Date of published edition in library"
        },
        {
            "key": "dates_of_first_edition",
            "value": "",
            "display": "Dates of first edition"
        },
        {
            "key": "isbn_issn_number",
            "value": "978-0143459453",
            "display": "Isbn issn number"
        },
        {
            "key": "language_of_publication",
            "value": "",
            "display": "Language of publication"
        },
        {
            "key": "number_of_pages_in_publication_text",
            "value": null,
            "display": "Number of pages in publication text"
        },
        {
            "key": "personality_film_visually_featured_on_cover",
            "value": null,
            "display": "Personality film visually featured on cover"
        },
        {
            "key": "publisher",
            "value": "Penguin Random House India",
            "display": "Publisher"
        },
        {
            "key": "serial_number",
            "value": null,
            "display": "Serial number"
        },
        {
            "key": "sortable_year_date_of_publication",
            "value": "2023",
            "display": "Sortable year date of publication"
        },
        {
            "key": "sub_title_of_document_book",
            "value": null,
            "display": "Sub title of document book"
        },
        {
            "key": "title_of_document_book",
            "value": null,
            "display": "Title of document book"
        },
        {
            "key": "book_title_of_document",
            "value": "The Best of Satyajit Ray",
            "display": "Book title of document"
        },
        {
            "key": "book_sub_title_of_document",
            "value": "",
            "display": "Book sub title of document"
        },
        {
            "key": "volume_issue_number",
            "value": null,
            "display": "Volume issue number"
        },
        {
            "key": "masterlist",
            "value": "satyajit-ray",
            "display": "Masterlist"
        },
        {
            "key": "image",
            "value": ["https://tuliresearchcentre.canto.global/direct/image/ertljmokad6llf6he465qdau4h/7elzYyy6jUJ8IVGffKimhbKNIJY/m800/800"],
            "display": "Image"
        },
        {
            "key": "document_type",
            "value": "bke",
            "display": "Document type"
        },
        {
            "key": "title_and_type_of_creator",
            "value": "Compiler",
            "display": "Title and type of creator"
        },
        {
            "key": "number_of_pages_in_publication",
            "value": null,
            "display": "Number of pages in publication"
        },
        {
            "key": "researchCategories",
            "value": [
                {
                    "key": "Cinema as a Key Educational Resource",
                    "value": "CinemaE",
                    "link": "cinema-as-a-key-educational-resource"
                },
                {
                    "key": "History & Historiography of Indian Scholarship in the Arts, Social Sciences & Humanities",
                    "value": "Scholarship",
                    "link": "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities"
                }
            ],
            "display": "Research categories"
        },
        {
            "key": "creator",
            "value": [
                {
                    "key": "sandip-ray",
                    "value": null,
                    "first_name": null,
                    "last_name": null
                }
            ],
            "display": "Creator"
        }
    ],
    [
        {
            "key": "id",
            "value": "6ba72374-8e4f-40ea-b180-0b47879e3d20",
            "display": "Id"
        },
        {
            "key": "title",
            "value": null,
            "display": "Title"
        },
        {
            "key": "alias",
            "value": "bka-tiwari-shatranj-ke-khilari-nd",
            "display": "Alias"
        },
        {
            "key": "web_serial_number",
            "value": null,
            "display": "Web serial number"
        },
        {
            "key": "reference_code",
            "value": null,
            "display": "Reference code"
        },
        {
            "key": "web_display",
            "value": null,
            "display": "Web display"
        },
        {
            "key": "date_of_library_publication",
            "value": null,
            "display": "Date of library publication"
        },
        {
            "key": "place_of_publication",
            "value": "Bhopal",
            "display": "Place of publication"
        },
        {
            "key": "histographical_note",
            "value": null,
            "display": "Histographical note"
        },
        {
            "key": "main_historical_note",
            "value": null,
            "display": "Main historical note"
        },
        {
            "key": "binding_status",
            "value": "",
            "display": "Binding status"
        },
        {
            "key": "accession_number",
            "value": "0000066",
            "display": "Accession number"
        },
        {
            "key": "cdt",
            "value": null,
            "display": "CDT"
        },
        {
            "key": "country_of_publication",
            "value": "India",
            "display": "Country of publication"
        },
        {
            "key": "date_of_published_edition_in_library",
            "value": "",
            "display": "Date of published edition in library"
        },
        {
            "key": "dates_of_first_edition",
            "value": "",
            "display": "Dates of first edition"
        },
        {
            "key": "isbn_issn_number",
            "value": "",
            "display": "Isbn issn number"
        },
        {
            "key": "language_of_publication",
            "value": "",
            "display": "Language of publication"
        },
        {
            "key": "number_of_pages_in_publication_text",
            "value": null,
            "display": "Number of pages in publication text"
        },
        {
            "key": "personality_film_visually_featured_on_cover",
            "value": null,
            "display": "Personality film visually featured on cover"
        },
        {
            "key": "publisher",
            "value": "Madhya Pradesh Film Vikash Nigam",
            "display": "Publisher"
        },
        {
            "key": "serial_number",
            "value": null,
            "display": "Serial number"
        },
        {
            "key": "sortable_year_date_of_publication",
            "value": "n.d.",
            "display": "Sortable year date of publication"
        },
        {
            "key": "sub_title_of_document_book",
            "value": null,
            "display": "Sub title of document book"
        },
        {
            "key": "title_of_document_book",
            "value": null,
            "display": "Title of document book"
        },
        {
            "key": "book_title_of_document",
            "value": "Shatranj Ke Khilari",
            "display": "Book title of document"
        },
        {
            "key": "book_sub_title_of_document",
            "value": "",
            "display": "Book sub title of document"
        },
        {
            "key": "volume_issue_number",
            "value": null,
            "display": "Volume issue number"
        },
        {
            "key": "masterlist",
            "value": "satyajit-ray",
            "display": "Masterlist"
        },
        {
            "key": "image",
            "value": ["https://tuliresearchcentre.canto.global/direct/image/69qcpkr3rh5q19vkeu9k48ci0c/MdqBl9t6ZdIncuscROu02mIfBUc/m800/800"],
            "display": "Image"
        },
        {
            "key": "document_type",
            "value": "bka",
            "display": "Document type"
        },
        {
            "key": "title_and_type_of_creator",
            "value": "Author",
            "display": "Title and type of creator"
        },
        {
            "key": "number_of_pages_in_publication",
            "value": null,
            "display": "Number of pages in publication"
        },
        {
            "key": "researchCategories",
            "value": [
                {
                    "key": "Cinema as a Key Educational Resource",
                    "value": "CinemaE",
                    "link": "cinema-as-a-key-educational-resource"
                }
            ],
            "display": "Research categories"
        },
        {
            "key": "creator",
            "value": [
                {
                    "key": "surendra-tiwari",
                    "value": "Surendra Tiwari",
                    "first_name": "Surendra",
                    "last_name": "Tiwari"
                }
            ],
            "display": "Creator"
        }
    ]
]
//biblography
export const satyajit_biblography_filter_options = [
    {
        key:"creatorType",
        display:filterAttribute.AUTHOR_EDITOR_CONTRIBUTOR,
        value: [
        "Editor",
        "Compiler",
        "Organizer",
        "Author",
        "Photographer",
        "Dr & Author",
        "Publisher",
      ]},
  {
    key:"year",
    display:filterAttribute.DATE,
    value: [
    "2022",
    "2023",
    "2018",
    "1984",
    "1985",
    "1982",
    "1975",
    "1995",
    "1988",
    "1989",
    "1976",
    "1977",
    "1980",
    "2001",
    "1981",
    "1983",
    "1986",
    "1996",
    "1990",
    "1994",
    "2013",
    "2000",
    "2017",
    "2007",
    "2012",
    "1991",
    "1971",
    "1972",
    "1993",
    "2011",
    "1992",
    "2020",
    "2002",
    "2003",
    "1998",
    "1999",
    "1969",
    "1960",
    "2005",
    "2014",
  ]},
  {
    key:"docType",
    display:filterAttribute.DOCUMENT_TYPE,    
    value: [
    "auc",
    "bka",
    "bke",
    "bkq",
    "ess",
    "exc",
    "ffc",
    "inv",
    "jou",
    "mag",
    "new",
    "q&a",
  ]},
  
];

//skk biblography
export const skk_research_centre = [
  [
    {
      key: "id",
      value: "3d84381f-58d8-4bfc-b5d6-ba89a9d933fa",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bka-gupta-the-cinema-of-satyajit-ray-1980",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "New Delhi",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "HB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000009",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "India",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "1980",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "1980",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "0-706910354",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "english",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "Vikas",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "1980",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "The Cinema of Satyajit Ray",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        ["https://tuliresearchcentre.canto.global/direct/image/a5etin56bd74r0cdb0tqpqdk6v/ZcvEdaY5AhDJT-VGv-hKEHgzfNY/m800/800"],
      display: "Image",
    },
    {
      key: "document_type",
      value: "bka",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Author",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
        {
          key: "History & Historiography of Indian Scholarship in the Arts, Social Sciences & Humanities",
          value: "Scholarship",
          link: "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "chidananda-das-gupta",
          value: "chidananda das gupta",
          first_name: "Chidananda",
          last_name: "DasGupta",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "5f6135ba-67c0-421f-951e-0be2744a45a5",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bka-rangoonwalla-satyajit-rays-art-1980",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "Delhi",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000055",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "India",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "1980",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "1980",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "086186235X",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "english",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "Clarion Books",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "1980",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "Satyajit Ray's Art",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        ["https://tuliresearchcentre.canto.global/direct/image/87tqucfojh6ld56aq6ur3gle1n/jUIWjMmBsZKQwlgwV0nXIZ606-M/m800/800"],
      display: "Image",
    },
    {
      key: "document_type",
      value: "bka",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Author",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
        {
          key: "History & Historiography of Indian Scholarship in the Arts, Social Sciences & Humanities",
          value: "Scholarship",
          link: "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "firoze-rangoonwalla",
          value: "Firoze-Rangoonwalla",
          first_name: "Firoze",
          last_name: "Rangoonwalla",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "fff27d9a-745e-40bf-8226-7cd1c8bd5c97",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bka-barnouw-indian-film-1980",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "New York",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "PB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000076",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "USA",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "1980",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "1980",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "0195026829",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "english",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "Oxford University",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "1980",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "Indian Film",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        ["https://tuliresearchcentre.canto.global/direct/image/safealga8p61fb6cnppogdn16f/0el1FcMhki39Lfm1kZumxt_IfEY/m800/800"],
      display: "Image",
    },
    {
      key: "document_type",
      value: "bka",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Author",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "History & Historiography of Indian Scholarship in the Arts, Social Sciences & Humanities",
          value: "Scholarship",
          link: "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities",
        },
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "erik-barnouw",
          value: "Erik-Barnouw",
          first_name: "Erik",
          last_name: "Barnouw",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "66025eeb-b495-4fb6-b2e1-643e3b2dbe48",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bka-gupta-talking-about-films-1981",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "Bombay",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "HB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000010",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "India",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "1981",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "1981",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "english",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "Orient Longman",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "1981",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "Talking About Films",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        ["https://tuliresearchcentre.canto.global/direct/image/t1d6pn27b11c530hl9r4vjj90r/HWFegcQQp6QdetpSgR38zrINh6Q/m800/800"],
      display: "Image",
    },
    {
      key: "document_type",
      value: "bka",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Author",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "History & Historiography of Indian Scholarship in the Arts, Social Sciences & Humanities",
          value: "Scholarship",
          link: "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities",
        },
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "chidananda-das-gupta",
          value: "chidananda das gupta",
          first_name: "Chidananda",
          last_name: "DasGupta",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "8274cbd9-df47-46bd-b3f2-2bae27da4895",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bka-ray-film-india-1981",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "New Delhi",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "PB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000011",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "India",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "1981",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "1981",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "english",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value:
        "Directorate of Film Festivals Ministry of Information and Broadcasting",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "1981",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "Film India",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "An Anthology of Statements on Ray and by Ray",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        ["https://tuliresearchcentre.canto.global/direct/image/250kvopos911f3s6bnt2ane719/yeUiADMsnMRpq0AFrb-ktYzFXyk/m800/800"],
      display: "Image",
    },
    {
      key: "document_type",
      value: "bka",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Author",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "satyajit-ray",
          value: "Satyajit-Ray",
          first_name: "Satyajit",
          last_name: "Ray",
        }
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "8b1223fa-3372-4a09-b67d-faa808c44c8c",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bka-ray-aro-baro-1981",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "Calcutta",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000012",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "India",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "1981",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "bengali",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "Ananda",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "1981",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "Aro Baro",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        ["https://tuliresearchcentre.canto.global/direct/image/7dglfkb47t329dn0ndsf0uj73h/ISmECyG2TcOTVekaWowtr6BsgfQ/m800/800"],
      display: "Image",
    },
    {
      key: "document_type",
      value: "bka",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Author",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "satyajit-ray",
          value: "Satyajit-Ray",
          first_name: "Satyajit",
          last_name: "Ray",
        }
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "5d333dd1-ba62-457b-ada9-b26391065032",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bka-bahadur-satyajit-rays-pather-panchali-1981",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "New Delhi",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "PB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000050",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "India",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "1981",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "1981",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "english",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "National Film Archive of India",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "1981",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "Satyajit Ray's Pather Panchali",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "Screenplay with Analytical Notes",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        ["https://tuliresearchcentre.canto.global/direct/image/isf99hp46d3kreolqlvlaaca0p/aBrRcLDENyYUeqj-TvoyI8aHwlQ/m800/800"],
      display: "Image",
    },
    {
      key: "document_type",
      value: "bka",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Author",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
        {
          key: "History & Historiography of Indian Scholarship in the Arts, Social Sciences & Humanities",
          value: "Scholarship",
          link: "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "satish-bahadur",
          value: "Satish-Bahadur",
          first_name: "Satish",
          last_name: "Bahadur",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "20d223a9-4c84-4da5-8ba2-fd84ab286b41",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bka-micciollo-satyajit-ray-1981",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "Lausanne",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000085",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "France",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "1981",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "1981",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "french",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "L'Age D'Homme",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "1981",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "Satyajit Ray",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        ["https://tuliresearchcentre.canto.global/direct/image/2fdnva763t7qn7d62pqcaeh05e/mHhNIzMrunW0qXD9GT2kgewSRCM/m800/800"],
      display: "Image",
    },
    {
      key: "document_type",
      value: "bka",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Author",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "henri-micciollo",
          value: "Henri-Micciollo",
          first_name: "Henri",
          last_name: "Micciollo",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "dbd82396-fcd9-4b51-9553-38c59918f271",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bka-ray-shatranj-ke-khilari-1982",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "Calcutta",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000015",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "India",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "english",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "Ekshan",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "1982",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "Shatranj Ke Khilari",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "The Chess Players",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        ["https://tuliresearchcentre.canto.global/direct/image/9ntctdgqsp3hrd3gn7mpalbs0i/wuYtgcnOUjTpRKui-DI8E1x9Hu8/m800/800"],
      display: "Image",
    },
    {
      key: "document_type",
      value: "bka",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Author",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "satyajit-ray",
          value: "Satyajit-Ray",
          first_name: "Satyajit",
          last_name: "Ray",
        }
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "0672b88a-4306-4163-81d5-625722218622",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bke-bazaz-festival-of-india-1982",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "PB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000014",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "UK",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "1982",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "1982",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "english",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "Delhi Diary",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "1982",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "Festival of India",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        ["https://tuliresearchcentre.canto.global/direct/image/g30lbekt8p007a3dk7fjc8dt3l/txdcf0vElyzK-S90XZgbc9lrICM/m800/800"],
      display: "Image",
    },
    {
      key: "document_type",
      value: "bke",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Editor",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
        {
          key: "History & Historiography of Indian Scholarship in the Arts, Social Sciences & Humanities",
          value: "Scholarship",
          link: "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "mohini-j-bazaz",
          value: "Mohini-J-Bazaz",
          first_name: "Mohini-J",
          last_name: "Bazaz",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "e4e749c5-fc97-424e-a187-315fc7edb6b5",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bke-banerjee-new-indian-cinema-1982",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "New Delhi",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "PB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000089",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "India",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "1982",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "1982",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "english",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "National Film Development Corporation",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "1982",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "New Indian Cinema",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        ["https://tuliresearchcentre.canto.global/direct/image/pbv4hejvdt5hd3vn13l0jjpe01/6E0rFz82Bte2vqz_u2c-Dn4rkhE/m800/800"],
      display: "Image",
    },
    {
      key: "document_type",
      value: "bke",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Editor",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
        {
          key: "History & Historiography of Indian Scholarship in the Arts, Social Sciences & Humanities",
          value: "Scholarship",
          link: "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "shampa-banerjee",
          value: "Shampa-Banerjee",
          first_name: "Shampa",
          last_name: "Banerjee",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "fdbad41f-ee9a-47e8-8698-6d4c5ee36847",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bka-ray-phatik-chand-1983",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "Delhi",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "PB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000016",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "India",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "1983",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "1983",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "english",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "Orient Paperback",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "1983",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "Phatik Chand",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        ["https://tuliresearchcentre.canto.global/direct/image/dqu18l0e3t3e3e5kepdv5eff1s/6ZI1oavu_22gONBCmDwtjT92GDo/m800/800"],
      display: "Image",
    },
    {
      key: "document_type",
      value: "bka",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Author",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "satyajit-ray",
          value: "Satyajit-Ray",
          first_name: "Satyajit",
          last_name: "Ray",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "3e610ec6-7583-4a70-b87a-ffe41e87fca3",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bka-ray-jab-main-chhota-tha-1984",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "Delhi",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "HB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000017",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "India",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "1984",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "1984",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "hindi ",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "Rajkamal",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "1984",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "Jab Main Chhota Tha",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        ["https://tuliresearchcentre.canto.global/direct/image/0ru589u8u538n1ddgq7u0qk639/HRsXrxtud5x6bkp65OopMOmpryg/m800/800"],
      display: "Image",
    },
    {
      key: "document_type",
      value: "bka",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Author",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
        {
          key: "Changing Smile of Childhood and its Second Coming",
          value: "Childhood",
          link: "changing-smile-of-childhood-and-its-second-coming",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "satyajit-ray",
          value: "Satyajit Ray",
          first_name: "Satyajit",
          last_name: "Ray",
        }
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "54bbaae0-d31c-4433-b9c9-f5fcc2a64c67",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bke-mukhopadhyay-pather-panchali-1984",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "Calcutta",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "HB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000051",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "India",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "1984",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "1984",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "english",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "Cine Central",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "1984",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "Pather Panchali",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "A Film by Satyajit Ray",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        ["https://tuliresearchcentre.canto.global/direct/image/li844dmijl77p3f6phbhic7o6d/mu5_CrFFHK1whDpKYF2Sz5847Zk/m800/800"],
      display: "Image",
    },
    {
      key: "document_type",
      value: "bke",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Editor",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "History & Historiography of Indian Scholarship in the Arts, Social Sciences & Humanities",
          value: "Scholarship",
          link: "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities",
        },
        {
          key: "Changing Smile of Childhood and its Second Coming",
          value: "Childhood",
          link: "changing-smile-of-childhood-and-its-second-coming",
        },
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "parimal-mukhopadhyay",
          value: "Parimal-Mukhopadhyay",
          first_name: "Parimal",
          last_name: "Mukhopadhyay",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "e721c9d5-b998-42f5-98f5-72ecb6c8ff37",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bka-ray-the-apu-trilogy-1985",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "Calcutta",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "PB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000018",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "India",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "1985",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "1985",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "english",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "Seagull Books",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "1985",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "The Apu Trilogy",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "Pather Panchali, Aparajito, Apur Sansar",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        ["https://tuliresearchcentre.canto.global/direct/image/3oug5tpeh179t9bl99kgl9sr7j/pI6vAq8TT-GQWEqMrb5GeJx_OlM/m800/800"],
      display: "Image",
    },
    {
      key: "document_type",
      value: "bka",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Author",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
        {
          key: "Changing Smile of Childhood and its Second Coming",
          value: "Childhood",
          link: "changing-smile-of-childhood-and-its-second-coming",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "satyajit-ray",
          value: "Satyajit-Ray",
          first_name: "Satyajit",
          last_name: "Ray",
        },
        
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "c9c5caab-71bc-46c3-90e2-90f2423c095b",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bka-ray-pikoor-diary-o-onyanyo-1985",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "Calcutta",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000019",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "India",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "1985",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "bengali",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "Ananda",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "1985",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "Pikoor Diary O Onyanyo",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        ["https://tuliresearchcentre.canto.global/direct/image/gb7p4hdvt910de33ii253os746/Cec8VtXnECuzoxnEAejOHIjgSvc/m800/800"],
      display: "Image",
    },
    {
      key: "document_type",
      value: "bka",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Author",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "satyajit-ray",
          value: "Satyajit Ray",
          first_name: "Satyajit",
          last_name: "Ray",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "99730d0f-329b-4f83-9152-0ae35dc3aca9",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bke-ramachandran-70-years-of-indian-cinema-\n1913-1983-1985",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "Bombay",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "HB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000075",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "India",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "1985",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "1985",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "0861320905",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "english",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "Cinema India International",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "1985",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "70 Years of Indian Cinema -\n1913-1983",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        ["https://tuliresearchcentre.canto.global/direct/image/1krdki917d6e56at2vrkto7s00/cU_ZNe_iC8LGpq7PR7CJ5ZhdC9I/m800/800"],
      display: "Image",
    },
    {
      key: "document_type",
      value: "bke",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Editor",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
        {
          key: "History & Historiography of Indian Scholarship in the Arts, Social Sciences & Humanities",
          value: "Scholarship",
          link: "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "t-m-ramachandran",
          value: "T-M-Ramachandran",
          first_name: "T-M",
          last_name: "Ramachandran",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "2bd8e6fe-fd8e-4183-bea8-5edb79f82b88",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bka-banerjee-jalsaghar-1986",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "Kolkata",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "HB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000021",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "India",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "1986",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "1937",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "bengali",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "Indranath Majumdar",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "1986",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "Jalsaghar",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        ["https://tuliresearchcentre.canto.global/direct/image/pajsen347d26b8feglipg80u7i/N-dtAybZtt1LAAIl4bw2gdv57t0/m800/800"],
      display: "Image",
    },
    {
      key: "document_type",
      value: "bka",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Author",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "tarasankar-banerjee",
          value: "Tarasankar-Banerjee",
          first_name: "Tarasankar",
          last_name: "Banerjee",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "13b29e42-ef12-4b52-ab19-dcf25a7fa97a",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bka-ray-bravo-professor-shonku-1986",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "New Delhi",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "PB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000022",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "India",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "1986",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "1974",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "english",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "Rupa Paperback",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "1986",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "Bravo! Professor Shonku",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        ["https://tuliresearchcentre.canto.global/direct/image/5cv7o61d592vf5002qb98vqf2m/AbDOrARVuPLK1lOXnUI9MK-0g1I/m800/800"],
      display: "Image",
    },
    {
      key: "document_type",
      value: "bka",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Author",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
        {
          key: "Uncertainty Revealing Itself : Death | Magic | Mystery | Sci-Fi | Horror | Gambling  | A.I.",
          value: "Uncertainty",
          link: "uncertainy-revealing-itself-death-magic-mystery-sci-fi-horror-gambling-ai",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "satyajit-ray",
          value: "Satyajit-Ray",
          first_name: "Satyajit",
          last_name: "Ray",
        }
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "53f6a656-7034-4329-bb72-7f36f6547948",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bka-datta-benegal-on-ray-1988",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "Calcutta",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "PB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000003",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "India",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "1988",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "1988",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "8170460212",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "english",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "Seagull Books",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "1988",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "Benegal on Ray",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "Satyajit Ray: A Film by Shyam Benegal",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        ["https://tuliresearchcentre.canto.global/direct/image/qhrjp5ieoh7oj6lrf5al03rm5a/ys6mfvXYquMNUugkIJkytA0ubpY/m800/800"],
      display: "Image",
    },
    {
      key: "document_type",
      value: "bka",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Author",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "alakananda-datta",
          value: "Alakananda-Datta",
          first_name: "Alakananda",
          last_name: "Datta",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "abe62ab1-bea9-419b-9f8e-aa2e13fc8248",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bka-nyce-satyajit-ray-1988",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "New York",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "HB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000057",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "USA",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "1988",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "1988",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "0-275926664",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "english",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "Praeger",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "1988",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "Satyajit Ray",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "A Study of His Films",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        ["https://tuliresearchcentre.canto.global/direct/image/nmn2np2kkp17b4iighdq5tl47a/NCclisCyEuuIhywFvMU97oOPXXs/m800/800"],
      display: "Image",
    },
    {
      key: "document_type",
      value: "bka",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Author",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "History & Historiography of Indian Scholarship in the Arts, Social Sciences & Humanities",
          value: "Scholarship",
          link: "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities",
        },
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "ben-nyce",
          value: "Ben-Nyce",
          first_name: "Ben",
          last_name: "Nyce",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "32eb20eb-f597-4ea7-b27c-eeadb91cbf77",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bka-valicha-the-moving-image-1988",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "Bombay",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "HB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000095",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "India",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "1988",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "1988",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "0861316819",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "english",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "Orient Longman",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "1988",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "The Moving Image",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "A Study of Indian Cinema",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        ["https://tuliresearchcentre.canto.global/direct/image/ate182883576b4aola99k9740g/BYcjvNMmvk1WdV1mG0iqYyY8eNw/m800/800"],
      display: "Image",
    },
    {
      key: "document_type",
      value: "bka",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Dr & Author",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
        {
          key: "History & Historiography of Indian Scholarship in the Arts, Social Sciences & Humanities",
          value: "Scholarship",
          link: "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "kishore-valicha",
          value: "Kishore-Valicha",
          first_name: "Kishore",
          last_name: "Valicha",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "b9aefde9-36fc-471d-b9aa-bfa2f0334aa2",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bka-robinson-satyajit-ray-1989",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "London",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "HB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000004",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "UK",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "1989",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "1989",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "0-233984739",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "english",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "Andrew Deutsch",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "1989",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "Satyajit Ray",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "The Inner Eye",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        ["https://tuliresearchcentre.canto.global/direct/image/mmr1aihhat0r75dr67us6gbp4v/Rwe6Ni6vJ5R9gLxqGJmu6Pk7urI/m800/800"],
      display: "Image",
    },
    {
      key: "document_type",
      value: "bka",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Author",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
        {
          key: "India and her Relationship with the World",
          value: "IndoWorld",
          link: "india-and-her-relationship-with-the-world",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "andrew-robinson",
          value: "Andrew-Robinson",
          first_name: "Andrew",
          last_name: "Robinson",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "40cf522d-0236-41cd-a5b0-ef21662d3b0d",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bke-robinson-the-chess-players-and-other-screenplays-1989",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "London",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "PB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000024",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "United Kingdom",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "1989",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "1989",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "0-571140742",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "english ",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "Faber and Faber",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "1989",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "The Chess Players and Other Screenplays",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        ["https://tuliresearchcentre.canto.global/direct/image/sc3ksj6h1178386ols1492457k/v6aSlzUWLa-ZAlDy81Yh4k1iBUk/m800/800"],
      display: "Image",
    },
    {
      key: "document_type",
      value: "bke",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Editor",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "History & Historiography of Indian Scholarship in the Arts, Social Sciences & Humanities",
          value: "Scholarship",
          link: "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities",
        },
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "andrew-robinson",
          value: "Andrew-Robinson",
          first_name: "Andrew",
          last_name: "Robinson",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "4804406a-df00-4f9d-a54f-d30ba6ea6c42",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bka-robinson-satyajit-ray-1990",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "Calcutta",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "PB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000027",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "India",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "english",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "Rupa Paperback",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "1990",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "Satyajit Ray",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "The Inner Eye",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        ["https://tuliresearchcentre.canto.global/direct/image/2t22bqd4rp0kd7396d6hl5v113/hqcYOOT5KAiAQ9Lr6qV3lWgORz8/m800/800"],
      display: "Image",
    },
    {
      key: "document_type",
      value: "bka",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Author",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "History & Historiography of Indian Scholarship in the Arts, Social Sciences & Humanities",
          value: "Scholarship",
          link: "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities",
        },
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "andrew-robinson",
          value: "Andrew-Robinson",
          first_name: "Andrew",
          last_name: "Robinson",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "bc2099d0-252f-4f79-9e21-d28b9a1a1fe4",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bka-nandi-satyajit-ray-at-70-1991",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "Brussels",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "PB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000058",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "Belgium",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "1991",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "1991",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "29300100061",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "english ",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "Eiffel Editions",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "1991",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "Satyajit Ray at 70",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value:
        "As Writer, Designer, Actor, Director, Cameraman, Editor, Composer",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        ["https://tuliresearchcentre.canto.global/direct/image/9dnslph5pp33t38fnsr4o91r2q/WU0_4_uX2F_TwWMEzNfq2c7sKeE/m800/800"],
      display: "Image",
    },
    {
      key: "document_type",
      value: "bka",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Author",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "Popular Arts, Printed Image & Crafts of India",
          value: "PopCult",
          link: "popular-arts-printed-image-crafts-of-india",
        },
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "alok-b-nandi",
          value: "Alok-B-Nandi",
          first_name: "Alok-B",
          last_name: "Nandi",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "1f44ad12-ad30-47cb-9bda-8b9c2753cbee",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bka-gupta-the-painted-face-1991",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "New Delhi",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "HB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000078",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "India",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "1991",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "1991",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "english",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "Roli Books",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "1991",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "The Painted Face",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "Studies in India's Popular Cinema",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        ["https://tuliresearchcentre.canto.global/direct/image/uiektppa3555pfe2h0l842un1m/eVyPuv00q2jg7yV6Xfnm83hxLyA/m800/800"],
      display: "Image",
    },
    {
      key: "document_type",
      value: "bka",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Author",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
        {
          key: "History & Historiography of Indian Scholarship in the Arts, Social Sciences & Humanities",
          value: "Scholarship",
          link: "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "chidananda-das-gupta",
          value: "chidananda das gupta",
          first_name: "Chidananda",
          last_name: "DasGupta",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "2bd31e86-b848-46d6-a4c5-9527acb5531a",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bka-sarkar-the-world-of-satyajit-ray-1992",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "New Delhi",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "HB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000071",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "India",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "1992",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "1992",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "81-85674043",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "english ",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "UBSPD",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "1992",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "The World of Satyajit Ray",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        ["https://tuliresearchcentre.canto.global/direct/image/agkvgtd3j95k936ef3tnfhjb1j/czNxALR360OzrpqCeSPpydr1zDo/m800/800"],
      display: "Image",
    },
    {
      key: "document_type",
      value: "bka",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Author",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
        {
          key: "History & Historiography of Indian Scholarship in the Arts, Social Sciences & Humanities",
          value: "Scholarship",
          link: "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "bidyut-sarkar",
          value: "Bidyut-Sarkar",
          first_name: "Bidyut",
          last_name: "Sarkar",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "83315c59-2d01-45d7-8e68-71e7af9e0bcb",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bka-tesson-satyajit-ray-1992",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "Paris",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000086",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "France",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "1992",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "1992",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "2866421175",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "french",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "Cahiers Du Cine",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "1992",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "Satyajit Ray",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        ["https://tuliresearchcentre.canto.global/direct/image/jrdnpfuaj138v8rhidkg593449/rzd7GgACikU_nW7o1wFXCR5iklE/m800/800"],
      display: "Image",
    },
    {
      key: "document_type",
      value: "bka",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Author",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "charles-tesson",
          value: "Charles-Tesson",
          first_name: "Charles",
          last_name: "Tesson",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "89f15be7-f07d-4526-97e8-c592a40b49a6",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bka-chakravarty-national-identity-in-indian-popular-cinema-1993",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "New Delhi",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "PB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000068",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "India",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "1993",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "1993",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "0195647475",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "english",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "Oxford University",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "1993",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "National Identity in Indian Popular Cinema",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "1947-1987",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        ["https://tuliresearchcentre.canto.global/direct/image/mg8bjc8mb16j184l2c7hmcuv41/aO3Pm2CAoyTCAwFwQtoCcmFlzn8/m800/800"],
      display: "Image",
    },
    {
      key: "document_type",
      value: "bka",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Author",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "History & Historiography of Indian Scholarship in the Arts, Social Sciences & Humanities",
          value: "Scholarship",
          link: "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities",
        },
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "sumita-s-chakravarty",
          value: "Sumita-S-Chakravarty",
          first_name: "Sumita-S",
          last_name: "Chakravarty",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "d0e19d8d-36ed-4290-a545-7fc7721f7c46",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bka-ray-my-years-with-apu-1994",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "New Delhi",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "HB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000028",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "India",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "1994",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "1994",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "0-670862150",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "english",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "Penguin Books India",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "1994",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "My Years With Apu",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "A Memoir",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        ["https://tuliresearchcentre.canto.global/direct/image/ck1brc7e1h1e75d0emtcdp0875/uOgaVOAKmaPNzQccZcM7fSexyBc/m800/800"],
      display: "Image",
    },
    {
      key: "document_type",
      value: "bka",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Author",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "Changing Smile of Childhood and its Second Coming",
          value: "Childhood",
          link: "changing-smile-of-childhood-and-its-second-coming",
        },
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "satyajit-ray",
          value: "Satyajit-Ray",
          first_name: "Satyajit",
          last_name: "Ray",
        }
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "5c3ebef6-6c1f-4af1-ba58-ecd4be76d67d",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bke-vasudev-frames-of-mind-1995",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "New Delhi",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "HB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000093",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "India",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "1995",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "1995",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "8174760539",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "english",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "UBS",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "1995",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "Frames of Mind",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "Reflections on Indian Cinema",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        ["https://tuliresearchcentre.canto.global/direct/image/5qhfeo9uop13j2mjhdf6t2q50o/0OrDPOB9sWBZIekXIFKbvJs4kZE/m800/800"],
      display: "Image",
    },
    {
      key: "document_type",
      value: "bke",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Editor",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "History & Historiography of Indian Scholarship in the Arts, Social Sciences & Humanities",
          value: "Scholarship",
          link: "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities",
        },
        {
          key: "India and her Relationship with the World",
          value: "IndoWorld",
          link: "india-and-her-relationship-with-the-world",
        },
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "aruna-vasudev",
          value: "Aruna-Vasudev",
          first_name: "Aruna",
          last_name: "Vasudev",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "75e601c6-b3a3-4b3b-b48a-7a027b066ed9",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bka-chowdhury-goopy-gyne-bagha-byne-1996",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "Calcutta",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000026",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "India",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "1996",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "1996",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "bengali",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "Ananda",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "1996",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "Goopy Gyne Bagha Byne",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        ["https://tuliresearchcentre.canto.global/direct/image/meg4vb5u6p4c969gd7ir2ll679/ApS4WJyeez-CGyCHuKeWn5uZlCM/m800/800"],
      display: "Image",
    },
    {
      key: "document_type",
      value: "bka",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Author",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "Changing Smile of Childhood and its Second Coming",
          value: "Childhood",
          link: "changing-smile-of-childhood-and-its-second-coming",
        },
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "upendra-kishor-ray-chowdhury",
          value: "Upendra-Kishor-Ray-Chowdhury",
          first_name: "Upendra-Kishor",
          last_name: "Ray-Chowdhury",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "5fff6235-3e51-4590-af3f-2af61948cf2a",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bka-elena-satyajit-ray-1998",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "Madrid",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000087",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "Spain",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "1998",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "1998  ",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "978-8437616476",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "spanish ",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "Cátedra  ",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "1998",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "Satyajit Ray",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        ["https://tuliresearchcentre.canto.global/direct/image/84mqhqsmjp1jd337h6jnlsfa3u/z0KbsOwv8PsHt7d6-F9gQk7qzls/m800/800"],
      display: "Image",
    },
    {
      key: "document_type",
      value: "bka",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Author",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "alberto-elena",
          value: "Alberto-Elena",
          first_name: "Alberto",
          last_name: "Elena",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "d2c6fc1e-5c82-49c5-8a13-5823b5f66460",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bka-prasad-ideology-of-the-hindi-film-1998",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "New Delhi",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "PB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000096",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "India",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "2000",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "1998",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "0195652959",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "english",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "Oxford University",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "1998",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "Ideology of the Hindi Film",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "A Historical Construction",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        ["https://tuliresearchcentre.canto.global/direct/image/b6mdgmpg552170tl13ohk5ml1v/qZjguVcI8Ox1Vy2c5rNeSn4ocq4/m800/800"],
      display: "Image",
    },
    {
      key: "document_type",
      value: "bka",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Author",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "History & Historiography of Indian Scholarship in the Arts, Social Sciences & Humanities",
          value: "Scholarship",
          link: "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities",
        },
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "m-madhava-prasad",
          value: "M-Madhava-Prasad",
          first_name: "M-Madhava",
          last_name: "Prasad",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "8a247196-4003-45a5-a467-8075cd8c3d3e",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bke-das-satyajit-ray-1998",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "New Delhi",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "HB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000030",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "India",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "1998",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "1998",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "8170237483",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "english ",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "Allied",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "1998",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "Satyajit Ray",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "An Intimate Master",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        ["https://tuliresearchcentre.canto.global/direct/image/hvno05ago95hrc89t2scm8ra7q/gbrmVVXXY6tbphQw5R8XVY02jjs/m800/800"],
      display: "Image",
    },
    {
      key: "document_type",
      value: "bke",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Editor",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "History & Historiography of Indian Scholarship in the Arts, Social Sciences & Humanities",
          value: "Scholarship",
          link: "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities",
        },
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
        {
          key: "Popular Arts, Printed Image & Crafts of India",
          value: "PopCult",
          link: "popular-arts-printed-image-crafts-of-india",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "santi-das",
          value: "Santi-Das",
          first_name: "Santi",
          last_name: "Das",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "2603c609-8050-454a-8fe9-7ae910a52035",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bka-elena-los-cines-perifericos-1999",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "Barcelona",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "PB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000097",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "Spain",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "1999",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "1999",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "84-49307104",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "spanish ",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "Paidos",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "1999",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "Los Cines Perifericos",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "Africa, Oriente Medio, India",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        ["https://tuliresearchcentre.canto.global/direct/image/ecup88f81951hfb6sbbqns1040/moPs-UTn_maGD7TzBF8kN-sbFUw/m800/800"],
      display: "Image",
    },
    {
      key: "document_type",
      value: "bka",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Author",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "alberto-elena",
          value: "Alberto-Elena",
          first_name: "Alberto",
          last_name: "Elena",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "aa2df08c-2e80-466c-aba5-383465a6e035",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bka-ghosh-manikda-2000",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "Kolkata",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "PB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000034",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "India",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "2000",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "2000",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "81877570010",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "french",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "Bingsha Shatabdi",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "2000",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "Manikda",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "Memoire sur Satyajit Ray",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        ["https://tuliresearchcentre.canto.global/direct/image/gk79bl2n154ff5ngmvk62uqp7n/9ibPJZu-OXKZ7p3xOov345ToQaM/m800/800"],
      display: "Image",
    },
    {
      key: "document_type",
      value: "bka",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Author",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "nemai-ghosh",
          value: "Nemai Ghosh",
          first_name: "Nemai",
          last_name: "Ghosh",
        },
        {
          key: "nemai-ghosh",
          value: "Nemai-Ghosh",
          first_name: "Nemai",
          last_name: "Ghosh",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "363ae2aa-4328-46c3-997e-1967273b07ed",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bka-cooper-the-cinema-of-satyajit-ray-2000",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "Cambridge",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "PB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000062",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "UK",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "2000",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "2000",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "0-521629802",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "english",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "Cambridge University",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "2000",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "The Cinema of Satyajit Ray",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "Between Tradition and Modernity",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        ["https://tuliresearchcentre.canto.global/direct/image/6g871mhtid68vfb6tdjri9vc6o/omjs26yZrqF7oHOBQVxe_idNspc/m800/800"],
      display: "Image",
    },
    {
      key: "document_type",
      value: "bka",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Author",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
        {
          key: "History & Historiography of Indian Scholarship in the Arts, Social Sciences & Humanities",
          value: "Scholarship",
          link: "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "darius-cooper",
          value: "Darius-Cooper",
          first_name: "Darius",
          last_name: "Cooper",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "b88efc75-cc13-4a7d-b97c-fb7cab8e2116",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bka-thoraval-the-cinemas-of-india\n1896-2000-2000",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "New Delhi",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "HB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000094",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "India",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "2000",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "2000",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "0333934105",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "english",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "Macmillan India",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "2000",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "The Cinemas of India:\n1896-2000",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        ["https://tuliresearchcentre.canto.global/direct/image/h3t7tpae1d16329tpg0opuke1l/h8y8OlZTDl1UjPhqvVoVVAKip_Q/m800/800"],
      display: "Image",
    },
    {
      key: "document_type",
      value: "bka",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Author",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "India and her Relationship with the World",
          value: "IndoWorld",
          link: "india-and-her-relationship-with-the-world",
        },
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "yves-thoraval",
          value: "Yves-Thoraval",
          first_name: "Yves",
          last_name: "Thoraval",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "6d3a9c9c-a4dc-4be4-92e9-9ea6e2ece4c6",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bka-gupta-the-cinema-of-satyajit-ray-2001",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "New Delhi",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "HB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000009.DI",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "India",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "1994",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "1994",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "81-23707533",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "english",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "National Book Trust",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "2001",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "The Cinema of Satyajit Ray",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        ["https://tuliresearchcentre.canto.global/direct/image/ufdh66f5b152tfq349m6as6o6s/5dFTlSnkHovZD2ZrYTX3Gaiey_U/m800/800"],
      display: "Image",
    },
    {
      key: "document_type",
      value: "bka",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Author",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
        {
          key: "History & Historiography of Indian Scholarship in the Arts, Social Sciences & Humanities",
          value: "Scholarship",
          link: "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "chidananda-das-gupta",
          value: "chidananda das gupta",
          first_name: "Chidananda",
          last_name: "DasGupta",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "8d3dd4a1-61d7-42d2-835e-cc6d921e0788",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bka-vallachira-asian-cinema-2001",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "Thrissur",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "PB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000031",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "India",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "2001",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "2001",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "malayalam",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "Dhrisyasahithi",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "2001",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "Asian Cinema",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "A New Perspective",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        ["https://tuliresearchcentre.canto.global/direct/image/lp40tc6c3951h6hh7rbopbft06/VfZ75t0bvomPx0reeaJ0XlyZQRE/m800/800"],
      display: "Image",
    },
    {
      key: "document_type",
      value: "bka",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Author",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "aravindan-vallachira",
          value: "Aravindan-Vallachira",
          first_name: "Aravindan",
          last_name: "Vallachira",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "bb92388c-5a76-4d61-baf5-0c1ad348ce10",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bka-ganguly-satyajit-ray-2001",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "New Delhi",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "PB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000042",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "India",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "2001",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "2001",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "81-87981040",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "english",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "Indialog",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "2001",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "Satyajit Ray",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "In Search of the Modern",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        ["https://tuliresearchcentre.canto.global/direct/image/4aupoppujt13rf8tu3jl8k8f04/AwUN7ByPGSam2uBjpi675mGDcyg/m800/800"],
      display: "Image",
    },
    {
      key: "document_type",
      value: "bka",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Author",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "History & Historiography of Indian Scholarship in the Arts, Social Sciences & Humanities",
          value: "Scholarship",
          link: "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities",
        },
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "suranjan-ganguly",
          value: "Suranjan-Ganguly",
          first_name: "Suranjan",
          last_name: "Ganguly",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "87a27741-6e31-4dce-8b59-927084401205",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bke-dasgupta-ray-2001",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "Kolkata",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "HB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000029",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "India",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "2001",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "2001",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "english-french",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "Kurchi Dasgupta",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "2001",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "Ray",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "A Glimpse",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        ["https://tuliresearchcentre.canto.global/direct/image/52164hlcjp4cv1qulg8up9502v/F_tR1W0oo1fMjqrhNN2F5qb18g8/m800/800"],
      display: "Image",
    },
    {
      key: "document_type",
      value: "bke",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Publisher",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
        {
          key: "History & Historiography of Indian Scholarship in the Arts, Social Sciences & Humanities",
          value: "Scholarship",
          link: "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "kurchi-dasgupta",
          value: "Kurchi-Dasgupta",
          first_name: "Kurchi",
          last_name: "Dasgupta",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "1b8ae6f7-9ac6-41ed-be60-1be9fa79bc76",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bka-vasudev-being-becoming-2002",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "New Delhi",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "HB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000081",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "India",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "2002",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "2002",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "0333938208",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "english",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "Macmillan India",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "2002",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "Being & Becoming",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "The Cinemas of Asia",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        ["https://tuliresearchcentre.canto.global/direct/image/iejinh906537dcn50l6albv66b/AFDo4TaF3mF73NIcdoAiqq4x4PA/m800/800"],
      display: "Image",
    },
    {
      key: "document_type",
      value: "bka",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Author",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "India and her Relationship with the World",
          value: "IndoWorld",
          link: "india-and-her-relationship-with-the-world",
        },
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "aruna-vasudev",
          value: "Aruna-Vasudev",
          first_name: "Aruna",
          last_name: "Vasudev",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "5ff23798-7d51-46e5-b096-99137ccdebef",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bke-majumdar-raytrospective-2001-2002",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "New Delhi",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "PB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000033",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "India",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "2002-07-01",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "2002",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "english-bengali-hindi",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "Indrani Majumdar",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "2002",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "RayTrospective 2001",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "A Celebration",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        ["https://tuliresearchcentre.canto.global/direct/image/dms2lu81v14fbccc6sovsp3l38/Is2eVy5oB_P1YpDBYkoYlEZiGw8/m800/800"],
      display: "Image",
    },
    {
      key: "document_type",
      value: "bke",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Compiler",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "History & Historiography of Indian Scholarship in the Arts, Social Sciences & Humanities",
          value: "Scholarship",
          link: "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities",
        },
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "indrani-majumdar",
          value: "Indrani-Majumdar",
          first_name: "Indrani",
          last_name: "Majumdar",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "30532fb2-bc08-4ab5-81f5-cf3d5aafc96e",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bka-ghosh-satyajit-ray-2003",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "New Delhi",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000084",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "India",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "2003",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "2003",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "english ",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "National Gallery of Modern Art",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "2003",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "Satyajit Ray",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "From Script to Screen ",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        ["https://tuliresearchcentre.canto.global/direct/image/37m5q6ruip1bfb3it7jv6sgl30/F3hJedI2_BC93q5GLMzFT3GO_UU/m800/800"],
      display: "Image",
    },
    {
      key: "document_type",
      value: "bka",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Photographer",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
        {
          key: "Photography in India",
          value: "Photo",
          link: "photography-in-india",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "nemai-ghosh",
          value: "Nemai-Ghosh",
          first_name: "Nemai",
          last_name: "Ghosh",
        },
        {
          key: "nemai-ghosh",
          value: "Nemai Ghosh",
          first_name: "Nemai",
          last_name: "Ghosh",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "4f76c726-22a4-440a-bb9e-1bc09ac3f603",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bke-robinson-satyajit-ray-2005",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "London",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "HB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000036",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "UK",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "2005",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "2005",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "1845110749",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "english",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "I B Tauris",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "2005",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "Satyajit Ray",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "A Vision of Cinema",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        ["https://tuliresearchcentre.canto.global/direct/image/egpjgdchdp20p4h6skf9aq982g/BJKO1SueFEcBR0oef6J0QLtVcyg/m800/800"],
      display: "Image",
    },
    {
      key: "document_type",
      value: "bke",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Editor",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "History & Historiography of Indian Scholarship in the Arts, Social Sciences & Humanities",
          value: "Scholarship",
          link: "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities",
        },
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
        {
          key: "Popular Arts, Printed Image & Crafts of India",
          value: "PopCult",
          link: "popular-arts-printed-image-crafts-of-india",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "andrew-robinson",
          value: "Andrew-Robinson",
          first_name: "Andrew",
          last_name: "Robinson",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "c6288985-0a29-42cd-9236-e6d9764fd0c1",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bka-roberge-satyajit-ray-2007",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "New Delhi",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000038",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "India",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "2007",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "2007",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "81-73047359",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "english",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "Manohar",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "2007",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "Satyajit Ray",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        ["https://tuliresearchcentre.canto.global/direct/image/invae7aiip6elb68p8tccces3r/06bEoKwVczWLYDTPIOIGPzpu5RM/m800/800"],
      display: "Image",
    },
    {
      key: "document_type",
      value: "bka",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Author",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "gaston-roberge",
          value: "Gaston-Roberge",
          first_name: "Gaston",
          last_name: "Roberge",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "3dd456f9-a099-4d54-bfe6-8573744c70f2",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bka-bahadur-a-textual-study-of-the-apu-trilogy-2011",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "New Delhi",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "HB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000070",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "India",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "2011",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "2011",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "978-9350004289",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "english",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "Vani",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "2011",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "A Textual Study of the Apu Trilogy",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        ["https://tuliresearchcentre.canto.global/direct/image/bboako27dl5qt38cbj9phr3c62/scn5ShxbspIWo1kf-eUQ5a9yugk/m800/800"],
      display: "Image",
    },
    {
      key: "document_type",
      value: "bka",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Author",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
        {
          key: "History & Historiography of Indian Scholarship in the Arts, Social Sciences & Humanities",
          value: "Scholarship",
          link: "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "satish-bahadur",
          value: "Satish-Bahadur",
          first_name: "Satish",
          last_name: "Bahadur",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "aa1bcd2a-3103-4a46-a5a4-dc25e8ef3a0c",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bka-sen-looking-beyond-2012",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "New Delhi",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "PB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000040",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "India",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "2012",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "2012",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "978-8174365651",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "english",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "Roli Books",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "2012",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "Looking Beyond",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "Graphics of Satyajit Ray",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        ["https://tuliresearchcentre.canto.global/direct/image/ght4l47dvd1jt7fi2pigju2s6e/mCNVf8TsVW4WPTjOyYXwTKWREw0/m800/800"],
      display: "Image",
    },
    {
      key: "document_type",
      value: "bka",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Author",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "Popular Arts, Printed Image & Crafts of India",
          value: "PopCult",
          link: "popular-arts-printed-image-crafts-of-india",
        },
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "jayanti-sen",
          value: "Jayanti-Sen",
          first_name: "Jayanti",
          last_name: "Sen",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "e81a9579-f2ef-4dbd-a43c-2f62785e5d28",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bka-ghosh-satyajit-ray-and-beyond-2013",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "New Delhi",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "HB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000032",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "India",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "2013",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "2013",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "978-9381217313",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "english",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "Delhi Art Gallery",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "2013",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "Satyajit Ray and Beyond",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        ["https://tuliresearchcentre.canto.global/direct/image/nasua0toht6e1ci7ivg3rkpj3g/WEoFWKCL41BnCculov5xFxDfGYQ/m800/800"],
      display: "Image",
    },
    {
      key: "document_type",
      value: "bka",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Author",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
        {
          key: "Photography in India",
          value: "Photo",
          link: "photography-in-india",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "nemai-ghosh",
          value: "Nemai Ghosh",
          first_name: "Nemai",
          last_name: "Ghosh",
        },
        {
          key: "nemai-ghosh",
          value: "Nemai-Ghosh",
          first_name: "Nemai",
          last_name: "Ghosh",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "ad07a953-fa00-4b5d-91c2-66794da6dcd1",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bke-ray-satyajit-rays-ravi-shankar-2014",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "Noida",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "HB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000044",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "India",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "2014",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "2014",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "978-9351361749",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "english",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value:
        "Collins in association with the Society for the Preservation of Satyajit Ray Archives",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "2014",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "Satyajit Ray's Ravi Shankar",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "An Unfilimed Visual Script",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        ["https://tuliresearchcentre.canto.global/direct/image/f78n060ej57gn41lb3s9bck44l/aS82ufFCMmh_rI3gd1418JVbkno/m800/800"],
      display: "Image",
    },
    {
      key: "document_type",
      value: "bke",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Editor",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
        {
          key: "History & Historiography of Indian Scholarship in the Arts, Social Sciences & Humanities",
          value: "Scholarship",
          link: "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities",
        },
        {
          key: "Modern & Contemporary Fine Arts in India",
          value: "FineArts",
          link: "modern-contemporary-fine-arts-in-india",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "sandip-ray",
          value: "Sandip Ray",
          first_name: "Sandip",
          last_name: "Ray",
        }
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "3cc5cd89-eea8-4a3c-b742-c7afceaf819b",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bka-cherian-indias-film-society-movement-2017",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "New Delhi",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "HB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000037",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "India",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "2017",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "2017",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "978-9385985638",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "english",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "Sage",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "2017",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "India's Film Society Movement",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "The Journey and its Impact",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        ["https://tuliresearchcentre.canto.global/direct/image/d4ib3ctkh9619e00hd6pu9sd10/aR0Ou22ieFX_ltrBnXgRzjP0UjE/m800/800"],
      display: "Image",
    },
    {
      key: "document_type",
      value: "bka",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Author",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
        {
          key: "Social Responsibility of the Creative Mind ",
          value: "SocialR",
          link: "social-responsibility-of-the-creative-mind",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "v-k-cherian",
          value: "V-K-Cherian",
          first_name: "V-K",
          last_name: "Cherian",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "052349d1-7462-493a-8fd9-b14698c69fbe",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bka-jindal-my-adventures-with-satyajit-ray-2017",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "Gurugram",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "PB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000039",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "India",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "2017",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "2017",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "978-9352771025",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "english",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "HarperCollins",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "2017",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "My Adventures with Satyajit Ray",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "The Making of Shatranj Ke Khilari",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        ["https://tuliresearchcentre.canto.global/direct/image/q67095kdnp5fp2r8d2c02rtu53/4EmN-s3VNr8sxXJJ9tvEH8xwGnQ/m800/800",Img1,Img2,Img3],
      display: "Image",
    },
    {
      key: "document_type",
      value: "bka",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Author",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
        {
          key: "Economics of Art, Cinema & Culture",
          value: "EconArts",
          link: "economics-of-art-cinema-culture",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "suresh-jindal",
          value: "Suresh Jindal",
          first_name: "Suresh",
          last_name: "Jindal",
        },
      ],
      display: "Creator",
    },
    {
      key: "drawer_link",
      value: true,
    },
  ],
  [
    {
      key: "id",
      value: "c2720f1c-a0af-4a71-a10c-0a45cf5b563d",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bke-ray-travails-with-the-alien-2018",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "Noida",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "PB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000048",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "India",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "2018",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "2018",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "978-9352779154",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "english",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "Harper Collins",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "2018",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "Travails With the Alien",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value:
        "The Film That was Never Made and Other Adventures with Science Fiction : Satyajit Ray",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        ["https://tuliresearchcentre.canto.global/direct/image/d8s5l1is5h2lr8t5d3gvh8o766/kD27cH5am2JMgN8suQ_zMU74In8/m800/800"],
      display: "Image",
    },
    {
      key: "document_type",
      value: "bke",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Editor",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "History & Historiography of Indian Scholarship in the Arts, Social Sciences & Humanities",
          value: "Scholarship",
          link: "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities",
        },
        {
          key: "Uncertainty Revealing Itself : Death | Magic | Mystery | Sci-Fi | Horror | Gambling  | A.I.",
          value: "Uncertainty",
          link: "uncertainy-revealing-itself-death-magic-mystery-sci-fi-horror-gambling-ai",
        },
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "sandip-ray",
          value: "Sandip Ray",
          first_name: "Sandip",
          last_name: "Ray",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "f1634c3f-6c2e-4c9f-b79e-987efc98ac92",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bke-devasundaram-indian-cinema-beyond-bollywood-2018",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "New York",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "HB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000079",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "USA",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "2018",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "2018",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "978-0815368601",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "english",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "Routledge",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "2018",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "Indian Cinema Beyond Bollywood",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "The New Indepenedent Cinema Revolution",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        ["https://tuliresearchcentre.canto.global/direct/image/n2bf4e2u5t1t1f0nh1uc8hjh14/RCTgPLTg1pjtSdSk7HZrfVH4DEs/m800/800"],
      display: "Image",
    },
    {
      key: "document_type",
      value: "bke",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Editor",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "History & Historiography of Indian Scholarship in the Arts, Social Sciences & Humanities",
          value: "Scholarship",
          link: "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities",
        },
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "ashvin-immanuel-devasundaram",
          value: "Ashvin-Immanuel-Devasundaram",
          first_name: "Ashvin-Immanuel",
          last_name: "Devasundaram",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "b8e87b89-94d6-4d17-a265-5ea4afd147fc",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bka-sengupta-soumitra-chatterjee-2020",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "New Delhi",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "HB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000077",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "India",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "2020",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "2020",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "978-9389136760",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "english",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "Niyogi Books",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "2020",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "Soumitra Chatterjee",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "A Life in Cinema, Theatre, Poetry & Painting",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        ["https://tuliresearchcentre.canto.global/direct/image/57sfokvt252u347ce80cr3le7r/bhQV6urIurKCOfD281oI8mL8oOs/m800/800"],
      display: "Image",
    },
    {
      key: "document_type",
      value: "bka",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Author",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "arjun-sengupta",
          value: "Arjun-Sengupta",
          first_name: "Arjun",
          last_name: "Sengupta",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "fce0bb59-603f-4c01-9565-c040e5bdbaea",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bke-saklani-iti-satyajit-da-2022",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "New Delhi",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "HB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000041",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "India",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "2022",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "2022",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "978-8185983325",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "english",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "Musui Art Foundation",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "2022",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "Iti, Satyajit Da",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "Letters to a Friend from Satyajit Ray",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        ["https://tuliresearchcentre.canto.global/direct/image/q7cs9t0b6t22lasel75o4bje4h/Q38Rdxvzm3uNtyf6xkP0ejbu8HI/m800/800"],
      display: "Image",
    },
    {
      key: "document_type",
      value: "bke",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Editor",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
        {
          key: "History & Historiography of Indian Scholarship in the Arts, Social Sciences & Humanities",
          value: "Scholarship",
          link: "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "juhi-saklani",
          value: "Juhi-Saklani",
          first_name: "Juhi",
          last_name: "Saklani",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "0c671511-e9e6-41cc-9d08-9f2093bbfeaf",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bke-ray-satyajit-ray-2022",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "Gurugram",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "PB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000046",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "India",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "2022",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "2022",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "9780143448990",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "english",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "Penguin Random House India",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "2022",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "Satyajit Ray",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "Miscellany on Life, Cinema, People & Much More",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        ["https://tuliresearchcentre.canto.global/direct/image/3ldj1re1ll2khdaqno856dph2l/Jw5CQvtfuIZtyBni-37fNh-WBDM/m800/800"],
      display: "Image",
    },
    {
      key: "document_type",
      value: "bke",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Editor",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
        {
          key: "History & Historiography of Indian Scholarship in the Arts, Social Sciences & Humanities",
          value: "Scholarship",
          link: "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "sandip-ray",
          value: "Sandip-Ray",
          first_name: "Sandip",
          last_name: "Ray",
        }
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "a52ec4cc-7761-4617-9cf8-e07a1eee597e",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bke-majumdar-a-companion-to-indian-cinema-2022",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "New Jursey",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "HB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000069",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "USA",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "2022",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "2022",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "978-1119048268",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "english",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "John Wiley",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "2022",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "A Companion to Indian Cinema",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        ["https://tuliresearchcentre.canto.global/direct/image/tc3osd8tbd2p9967efchufk71i/9dBwRpP4g_UUBMZu1m6solHpkxM/m800/800"],
      display: "Image",
    },
    {
      key: "document_type",
      value: "bke",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Editor",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "History & Historiography of Indian Scholarship in the Arts, Social Sciences & Humanities",
          value: "Scholarship",
          link: "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities",
        },
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "neepa-majumdar",
          value: "Neepa-Majumdar",
          first_name: "Neepa",
          last_name: "Majumdar",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "c8ceaf6f-d31d-4dbd-8e6b-3920608aec8d",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bke-ray-the-best-of-satyajit-ray-2023",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "Gurgaon",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "PB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000047",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "India",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "2023",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "2023",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "978-0143459453",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "english",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "Penguin Random House India",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "2023",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "The Best of Satyajit Ray",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        ["https://tuliresearchcentre.canto.global/direct/image/ertljmokad6llf6he465qdau4h/7elzYyy6jUJ8IVGffKimhbKNIJY/m800/800"],
      display: "Image",
    },
    {
      key: "document_type",
      value: "bke",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Compiler",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "History & Historiography of Indian Scholarship in the Arts, Social Sciences & Humanities",
          value: "Scholarship",
          link: "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities",
        },
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "sandip-ray",
          value: "Sandip-Ray",
          first_name: "Sandip",
          last_name: "Ray",
        }
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "634b7972-e27b-42ae-ba4a-3807fa860124",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bka-tiwari-shatranj-ke-khilari-nd",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "Bhopal",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000066",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "India",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "hindi ",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "Madhya Pradesh Film Vikash Nigam",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "n.d.",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "Shatranj Ke Khilari",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        ["https://tuliresearchcentre.canto.global/direct/image/69qcpkr3rh5q19vkeu9k48ci0c/MdqBl9t6ZdIncuscROu02mIfBUc/m800/800"],
      display: "Image",
    },
    {
      key: "document_type",
      value: "bka",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Author",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "surendra-tiwari",
          value: "Surendra-Tiwari",
          first_name: "Surendra",
          last_name: "Tiwari",
        },
      ],
      display: "Creator",
    },
  ],
];
export const skk_biblography_filter_options = [
    {
        key:"creatorType",
        display:filterAttribute.AUTHOR_EDITOR_CONTRIBUTOR,
        value: [
          "Author",
          "Dr & Author",
          "Photographer",
          "Editor",
          "Publisher",
          "Compiler",
        ]},
        {
            key:"year",
            display:filterAttribute.DATE,
            value: [
          "1980",
          "1981",
          "1982",
          "1983",
          "1984",
          "1985",
          "1986",
          "1988",
          "1989",
          "1990",
          "1991",
          "1992",
          "1993",
          "1994",
          "1996",
          "1998",
          "1999",
          "2000",
          "2001",
          "2002",
          "2003",
          "2007",
          "2011",
          "2012",
          "2013",
          "2017",
          "2020",
          "n.d.",
          "1995",
          "2005",
          "2014",
          "2018",
          "2022",
          "2023",
        ]},
    {
    key:"docType",
    display:filterAttribute.DOCUMENT_TYPE,
    value: ["bka", "bke"],
    },
    {
        key:"publisher",
    display:filterAttribute.PUBLISHER,
    value: [
      "New Delhi",
      "Delhi",
      "New York",
      "Bombay",
      "Calcutta",
      "Lausanne",
      "Kolkata",
      "London",
      "Brussels",
      "Paris",
      "Madrid",
      "Barcelona",
      "Cambridge",
      "Thrissur",
      "Gurugram",
      "Bhopal",
      "Noida",
      "New Jursey",
      "Gurgaon",
    ],
  }
];
export const skk_biblography_drawerData = {
  header: {
    Image: SKK,
    text: "& ",
    desc: "My Adventures With Satyajit Ray",
  },
  body: {
    sliderImage: [Img1, Img2, Img3],
    details: [
      {
        key: "Author: ",
        link: "",
        text: "Suresh Jindal",
      },
      {
        key: "Book: ",
        link: "",
        text: "My Advantures with Satyajit Ray - Making of Shatranj Ke Khilari",
      },
      {
        key: "Publisher: ",
        link: "",
        text: "HarperCollins",
      },
      {
        key: "Year of Publication:",
        link: "",
        text: "2017",
      },
    ],
  },
  footer: [
    {
      text: "Contents Page →",
    },
    {
      text: "Index Page →",
    },
    {
      text: "Scanned Pages →",
    },
  ],
};
//mughal biblography
export const mughal_research_centre = [
  [
    {
      key: "id",
      value: "3d84381f-58d8-4bfc-b5d6-ba89a9d933fa",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bka-gupta-the-cinema-of-satyajit-ray-1980",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "New Delhi",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "HB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000009",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "India",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "1980",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "1980",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "0-706910354",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "english",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "Vikas",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "1980",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "The Cinema of Satyajit Ray",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        "https://tuliresearchcentre.canto.global/direct/image/a5etin56bd74r0cdb0tqpqdk6v/ZcvEdaY5AhDJT-VGv-hKEHgzfNY/m800/800",
      display: "Image",
    },
    {
      key: "document_type",
      value: "bka",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Author",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
        {
          key: "History & Historiography of Indian Scholarship in the Arts, Social Sciences & Humanities",
          value: "Scholarship",
          link: "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "chidananda-das-gupta",
          value: "chidananda das gupta",
          first_name: "Chidananda",
          last_name: "DasGupta",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "5f6135ba-67c0-421f-951e-0be2744a45a5",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bka-rangoonwalla-satyajit-rays-art-1980",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "Delhi",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000055",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "India",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "1980",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "1980",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "086186235X",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "english",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "Clarion Books",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "1980",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "Satyajit Ray's Art",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        "https://tuliresearchcentre.canto.global/direct/image/87tqucfojh6ld56aq6ur3gle1n/jUIWjMmBsZKQwlgwV0nXIZ606-M/m800/800",
      display: "Image",
    },
    {
      key: "document_type",
      value: "bka",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Author",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
        {
          key: "History & Historiography of Indian Scholarship in the Arts, Social Sciences & Humanities",
          value: "Scholarship",
          link: "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "firoze-rangoonwalla",
          value: "Firoze-Rangoonwalla",
          first_name: "Firoze",
          last_name: "Rangoonwalla",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "fff27d9a-745e-40bf-8226-7cd1c8bd5c97",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bka-barnouw-indian-film-1980",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "New York",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "PB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000076",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "USA",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "1980",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "1980",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "0195026829",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "english",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "Oxford University",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "1980",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "Indian Film",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        "https://tuliresearchcentre.canto.global/direct/image/safealga8p61fb6cnppogdn16f/0el1FcMhki39Lfm1kZumxt_IfEY/m800/800",
      display: "Image",
    },
    {
      key: "document_type",
      value: "bka",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Author",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "History & Historiography of Indian Scholarship in the Arts, Social Sciences & Humanities",
          value: "Scholarship",
          link: "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities",
        },
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "erik-barnouw",
          value: "Erik-Barnouw",
          first_name: "Erik",
          last_name: "Barnouw",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "66025eeb-b495-4fb6-b2e1-643e3b2dbe48",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bka-gupta-talking-about-films-1981",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "Bombay",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "HB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000010",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "India",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "1981",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "1981",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "english",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "Orient Longman",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "1981",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "Talking About Films",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        "https://tuliresearchcentre.canto.global/direct/image/t1d6pn27b11c530hl9r4vjj90r/HWFegcQQp6QdetpSgR38zrINh6Q/m800/800",
      display: "Image",
    },
    {
      key: "document_type",
      value: "bka",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Author",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "History & Historiography of Indian Scholarship in the Arts, Social Sciences & Humanities",
          value: "Scholarship",
          link: "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities",
        },
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "chidananda-das-gupta",
          value: "chidananda das gupta",
          first_name: "Chidananda",
          last_name: "DasGupta",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "8274cbd9-df47-46bd-b3f2-2bae27da4895",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bka-ray-film-india-1981",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "New Delhi",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "PB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000011",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "India",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "1981",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "1981",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "english",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value:
        "Directorate of Film Festivals Ministry of Information and Broadcasting",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "1981",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "Film India",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "An Anthology of Statements on Ray and by Ray",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        "https://tuliresearchcentre.canto.global/direct/image/250kvopos911f3s6bnt2ane719/yeUiADMsnMRpq0AFrb-ktYzFXyk/m800/800",
      display: "Image",
    },
    {
      key: "document_type",
      value: "bka",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Author",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "satyajit-ray",
          value: "Satyajit-Ray",
          first_name: "Satyajit",
          last_name: "Ray",
        },
        {
          key: "satyajit-ray",
          value: null,
          first_name: null,
          last_name: null,
        },
        {
          key: "satyajit-ray",
          value: "Satyajit Ray",
          first_name: "Satyajit",
          last_name: "Ray",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "8b1223fa-3372-4a09-b67d-faa808c44c8c",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bka-ray-aro-baro-1981",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "Calcutta",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000012",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "India",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "1981",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "bengali",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "Ananda",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "1981",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "Aro Baro",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        "https://tuliresearchcentre.canto.global/direct/image/7dglfkb47t329dn0ndsf0uj73h/ISmECyG2TcOTVekaWowtr6BsgfQ/m800/800",
      display: "Image",
    },
    {
      key: "document_type",
      value: "bka",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Author",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "satyajit-ray",
          value: "Satyajit-Ray",
          first_name: "Satyajit",
          last_name: "Ray",
        },
        {
          key: "satyajit-ray",
          value: "Satyajit Ray",
          first_name: "Satyajit",
          last_name: "Ray",
        },
        {
          key: "satyajit-ray",
          value: null,
          first_name: null,
          last_name: null,
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "5d333dd1-ba62-457b-ada9-b26391065032",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bka-bahadur-satyajit-rays-pather-panchali-1981",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "New Delhi",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "PB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000050",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "India",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "1981",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "1981",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "english",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "National Film Archive of India",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "1981",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "Satyajit Ray's Pather Panchali",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "Screenplay with Analytical Notes",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        "https://tuliresearchcentre.canto.global/direct/image/isf99hp46d3kreolqlvlaaca0p/aBrRcLDENyYUeqj-TvoyI8aHwlQ/m800/800",
      display: "Image",
    },
    {
      key: "document_type",
      value: "bka",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Author",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
        {
          key: "History & Historiography of Indian Scholarship in the Arts, Social Sciences & Humanities",
          value: "Scholarship",
          link: "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "satish-bahadur",
          value: "Satish-Bahadur",
          first_name: "Satish",
          last_name: "Bahadur",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "20d223a9-4c84-4da5-8ba2-fd84ab286b41",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bka-micciollo-satyajit-ray-1981",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "Lausanne",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000085",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "France",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "1981",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "1981",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "french",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "L'Age D'Homme",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "1981",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "Satyajit Ray",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        "https://tuliresearchcentre.canto.global/direct/image/2fdnva763t7qn7d62pqcaeh05e/mHhNIzMrunW0qXD9GT2kgewSRCM/m800/800",
      display: "Image",
    },
    {
      key: "document_type",
      value: "bka",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Author",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "henri-micciollo",
          value: "Henri-Micciollo",
          first_name: "Henri",
          last_name: "Micciollo",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "dbd82396-fcd9-4b51-9553-38c59918f271",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bka-ray-shatranj-ke-khilari-1982",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "Calcutta",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000015",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "India",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "english",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "Ekshan",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "1982",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "Shatranj Ke Khilari",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "The Chess Players",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        "https://tuliresearchcentre.canto.global/direct/image/9ntctdgqsp3hrd3gn7mpalbs0i/wuYtgcnOUjTpRKui-DI8E1x9Hu8/m800/800",
      display: "Image",
    },
    {
      key: "document_type",
      value: "bka",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Author",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "satyajit-ray",
          value: "Satyajit-Ray",
          first_name: "Satyajit",
          last_name: "Ray",
        },
        {
          key: "satyajit-ray",
          value: null,
          first_name: null,
          last_name: null,
        },
        {
          key: "satyajit-ray",
          value: "Satyajit Ray",
          first_name: "Satyajit",
          last_name: "Ray",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "0672b88a-4306-4163-81d5-625722218622",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bke-bazaz-festival-of-india-1982",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "PB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000014",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "UK",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "1982",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "1982",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "english",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "Delhi Diary",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "1982",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "Festival of India",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        "https://tuliresearchcentre.canto.global/direct/image/g30lbekt8p007a3dk7fjc8dt3l/txdcf0vElyzK-S90XZgbc9lrICM/m800/800",
      display: "Image",
    },
    {
      key: "document_type",
      value: "bke",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Editor",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
        {
          key: "History & Historiography of Indian Scholarship in the Arts, Social Sciences & Humanities",
          value: "Scholarship",
          link: "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "mohini-j-bazaz",
          value: "Mohini-J-Bazaz",
          first_name: "Mohini-J",
          last_name: "Bazaz",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "e4e749c5-fc97-424e-a187-315fc7edb6b5",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bke-banerjee-new-indian-cinema-1982",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "New Delhi",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "PB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000089",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "India",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "1982",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "1982",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "english",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "National Film Development Corporation",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "1982",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "New Indian Cinema",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        "https://tuliresearchcentre.canto.global/direct/image/pbv4hejvdt5hd3vn13l0jjpe01/6E0rFz82Bte2vqz_u2c-Dn4rkhE/m800/800",
      display: "Image",
    },
    {
      key: "document_type",
      value: "bke",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Editor",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
        {
          key: "History & Historiography of Indian Scholarship in the Arts, Social Sciences & Humanities",
          value: "Scholarship",
          link: "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "shampa-banerjee",
          value: "Shampa-Banerjee",
          first_name: "Shampa",
          last_name: "Banerjee",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "fdbad41f-ee9a-47e8-8698-6d4c5ee36847",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bka-ray-phatik-chand-1983",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "Delhi",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "PB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000016",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "India",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "1983",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "1983",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "english",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "Orient Paperback",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "1983",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "Phatik Chand",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        "https://tuliresearchcentre.canto.global/direct/image/dqu18l0e3t3e3e5kepdv5eff1s/6ZI1oavu_22gONBCmDwtjT92GDo/m800/800",
      display: "Image",
    },
    {
      key: "document_type",
      value: "bka",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Author",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "satyajit-ray",
          value: null,
          first_name: null,
          last_name: null,
        },
        {
          key: "satyajit-ray",
          value: "Satyajit Ray",
          first_name: "Satyajit",
          last_name: "Ray",
        },
        {
          key: "satyajit-ray",
          value: "Satyajit-Ray",
          first_name: "Satyajit",
          last_name: "Ray",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "3e610ec6-7583-4a70-b87a-ffe41e87fca3",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bka-ray-jab-main-chhota-tha-1984",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "Delhi",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "HB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000017",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "India",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "1984",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "1984",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "hindi ",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "Rajkamal",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "1984",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "Jab Main Chhota Tha",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        "https://tuliresearchcentre.canto.global/direct/image/0ru589u8u538n1ddgq7u0qk639/HRsXrxtud5x6bkp65OopMOmpryg/m800/800",
      display: "Image",
    },
    {
      key: "document_type",
      value: "bka",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Author",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
        {
          key: "Changing Smile of Childhood and its Second Coming",
          value: "Childhood",
          link: "changing-smile-of-childhood-and-its-second-coming",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "satyajit-ray",
          value: "Satyajit Ray",
          first_name: "Satyajit",
          last_name: "Ray",
        },
        {
          key: "satyajit-ray",
          value: null,
          first_name: null,
          last_name: null,
        },
        {
          key: "satyajit-ray",
          value: "Satyajit-Ray",
          first_name: "Satyajit",
          last_name: "Ray",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "54bbaae0-d31c-4433-b9c9-f5fcc2a64c67",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bke-mukhopadhyay-pather-panchali-1984",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "Calcutta",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "HB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000051",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "India",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "1984",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "1984",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "english",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "Cine Central",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "1984",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "Pather Panchali",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "A Film by Satyajit Ray",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        "https://tuliresearchcentre.canto.global/direct/image/li844dmijl77p3f6phbhic7o6d/mu5_CrFFHK1whDpKYF2Sz5847Zk/m800/800",
      display: "Image",
    },
    {
      key: "document_type",
      value: "bke",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Editor",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "History & Historiography of Indian Scholarship in the Arts, Social Sciences & Humanities",
          value: "Scholarship",
          link: "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities",
        },
        {
          key: "Changing Smile of Childhood and its Second Coming",
          value: "Childhood",
          link: "changing-smile-of-childhood-and-its-second-coming",
        },
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "parimal-mukhopadhyay",
          value: "Parimal-Mukhopadhyay",
          first_name: "Parimal",
          last_name: "Mukhopadhyay",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "e721c9d5-b998-42f5-98f5-72ecb6c8ff37",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bka-ray-the-apu-trilogy-1985",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "Calcutta",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "PB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000018",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "India",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "1985",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "1985",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "english",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "Seagull Books",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "1985",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "The Apu Trilogy",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "Pather Panchali, Aparajito, Apur Sansar",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        "https://tuliresearchcentre.canto.global/direct/image/3oug5tpeh179t9bl99kgl9sr7j/pI6vAq8TT-GQWEqMrb5GeJx_OlM/m800/800",
      display: "Image",
    },
    {
      key: "document_type",
      value: "bka",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Author",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
        {
          key: "Changing Smile of Childhood and its Second Coming",
          value: "Childhood",
          link: "changing-smile-of-childhood-and-its-second-coming",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "satyajit-ray",
          value: "Satyajit-Ray",
          first_name: "Satyajit",
          last_name: "Ray",
        },
        {
          key: "satyajit-ray",
          value: null,
          first_name: null,
          last_name: null,
        },
        {
          key: "satyajit-ray",
          value: "Satyajit Ray",
          first_name: "Satyajit",
          last_name: "Ray",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "c9c5caab-71bc-46c3-90e2-90f2423c095b",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bka-ray-pikoor-diary-o-onyanyo-1985",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "Calcutta",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000019",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "India",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "1985",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "bengali",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "Ananda",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "1985",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "Pikoor Diary O Onyanyo",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        "https://tuliresearchcentre.canto.global/direct/image/gb7p4hdvt910de33ii253os746/Cec8VtXnECuzoxnEAejOHIjgSvc/m800/800",
      display: "Image",
    },
    {
      key: "document_type",
      value: "bka",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Author",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "satyajit-ray",
          value: null,
          first_name: null,
          last_name: null,
        },
        {
          key: "satyajit-ray",
          value: "Satyajit-Ray",
          first_name: "Satyajit",
          last_name: "Ray",
        },
        {
          key: "satyajit-ray",
          value: "Satyajit Ray",
          first_name: "Satyajit",
          last_name: "Ray",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "99730d0f-329b-4f83-9152-0ae35dc3aca9",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bke-ramachandran-70-years-of-indian-cinema-\n1913-1983-1985",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "Bombay",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "HB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000075",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "India",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "1985",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "1985",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "0861320905",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "english",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "Cinema India International",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "1985",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "70 Years of Indian Cinema -\n1913-1983",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        "https://tuliresearchcentre.canto.global/direct/image/1krdki917d6e56at2vrkto7s00/cU_ZNe_iC8LGpq7PR7CJ5ZhdC9I/m800/800",
      display: "Image",
    },
    {
      key: "document_type",
      value: "bke",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Editor",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
        {
          key: "History & Historiography of Indian Scholarship in the Arts, Social Sciences & Humanities",
          value: "Scholarship",
          link: "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "t-m-ramachandran",
          value: "T-M-Ramachandran",
          first_name: "T-M",
          last_name: "Ramachandran",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "2bd8e6fe-fd8e-4183-bea8-5edb79f82b88",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bka-banerjee-jalsaghar-1986",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "Kolkata",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "HB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000021",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "India",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "1986",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "1937",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "bengali",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "Indranath Majumdar",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "1986",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "Jalsaghar",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        "https://tuliresearchcentre.canto.global/direct/image/pajsen347d26b8feglipg80u7i/N-dtAybZtt1LAAIl4bw2gdv57t0/m800/800",
      display: "Image",
    },
    {
      key: "document_type",
      value: "bka",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Author",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "tarasankar-banerjee",
          value: "Tarasankar-Banerjee",
          first_name: "Tarasankar",
          last_name: "Banerjee",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "13b29e42-ef12-4b52-ab19-dcf25a7fa97a",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bka-ray-bravo-professor-shonku-1986",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "New Delhi",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "PB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000022",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "India",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "1986",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "1974",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "english",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "Rupa Paperback",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "1986",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "Bravo! Professor Shonku",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        "https://tuliresearchcentre.canto.global/direct/image/5cv7o61d592vf5002qb98vqf2m/AbDOrARVuPLK1lOXnUI9MK-0g1I/m800/800",
      display: "Image",
    },
    {
      key: "document_type",
      value: "bka",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Author",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
        {
          key: "Uncertainty Revealing Itself : Death | Magic | Mystery | Sci-Fi | Horror | Gambling  | A.I.",
          value: "Uncertainty",
          link: "uncertainy-revealing-itself-death-magic-mystery-sci-fi-horror-gambling-ai",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "satyajit-ray",
          value: "Satyajit-Ray",
          first_name: "Satyajit",
          last_name: "Ray",
        },
        {
          key: "satyajit-ray",
          value: null,
          first_name: null,
          last_name: null,
        },
        {
          key: "satyajit-ray",
          value: "Satyajit Ray",
          first_name: "Satyajit",
          last_name: "Ray",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "53f6a656-7034-4329-bb72-7f36f6547948",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bka-datta-benegal-on-ray-1988",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "Calcutta",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "PB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000003",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "India",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "1988",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "1988",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "8170460212",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "english",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "Seagull Books",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "1988",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "Benegal on Ray",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "Satyajit Ray: A Film by Shyam Benegal",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        "https://tuliresearchcentre.canto.global/direct/image/qhrjp5ieoh7oj6lrf5al03rm5a/ys6mfvXYquMNUugkIJkytA0ubpY/m800/800",
      display: "Image",
    },
    {
      key: "document_type",
      value: "bka",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Author",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "alakananda-datta",
          value: "Alakananda-Datta",
          first_name: "Alakananda",
          last_name: "Datta",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "abe62ab1-bea9-419b-9f8e-aa2e13fc8248",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bka-nyce-satyajit-ray-1988",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "New York",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "HB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000057",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "USA",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "1988",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "1988",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "0-275926664",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "english",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "Praeger",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "1988",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "Satyajit Ray",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "A Study of His Films",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        "https://tuliresearchcentre.canto.global/direct/image/nmn2np2kkp17b4iighdq5tl47a/NCclisCyEuuIhywFvMU97oOPXXs/m800/800",
      display: "Image",
    },
    {
      key: "document_type",
      value: "bka",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Author",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "History & Historiography of Indian Scholarship in the Arts, Social Sciences & Humanities",
          value: "Scholarship",
          link: "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities",
        },
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "ben-nyce",
          value: "Ben-Nyce",
          first_name: "Ben",
          last_name: "Nyce",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "32eb20eb-f597-4ea7-b27c-eeadb91cbf77",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bka-valicha-the-moving-image-1988",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "Bombay",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "HB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000095",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "India",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "1988",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "1988",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "0861316819",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "english",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "Orient Longman",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "1988",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "The Moving Image",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "A Study of Indian Cinema",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        "https://tuliresearchcentre.canto.global/direct/image/ate182883576b4aola99k9740g/BYcjvNMmvk1WdV1mG0iqYyY8eNw/m800/800",
      display: "Image",
    },
    {
      key: "document_type",
      value: "bka",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Dr & Author",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
        {
          key: "History & Historiography of Indian Scholarship in the Arts, Social Sciences & Humanities",
          value: "Scholarship",
          link: "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "kishore-valicha",
          value: "Kishore-Valicha",
          first_name: "Kishore",
          last_name: "Valicha",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "b9aefde9-36fc-471d-b9aa-bfa2f0334aa2",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bka-robinson-satyajit-ray-1989",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "London",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "HB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000004",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "UK",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "1989",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "1989",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "0-233984739",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "english",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "Andrew Deutsch",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "1989",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "Satyajit Ray",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "The Inner Eye",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        "https://tuliresearchcentre.canto.global/direct/image/mmr1aihhat0r75dr67us6gbp4v/Rwe6Ni6vJ5R9gLxqGJmu6Pk7urI/m800/800",
      display: "Image",
    },
    {
      key: "document_type",
      value: "bka",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Author",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
        {
          key: "India and her Relationship with the World",
          value: "IndoWorld",
          link: "india-and-her-relationship-with-the-world",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "andrew-robinson",
          value: "Andrew-Robinson",
          first_name: "Andrew",
          last_name: "Robinson",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "40cf522d-0236-41cd-a5b0-ef21662d3b0d",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bke-robinson-the-chess-players-and-other-screenplays-1989",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "London",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "PB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000024",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "United Kingdom",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "1989",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "1989",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "0-571140742",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "english ",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "Faber and Faber",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "1989",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "The Chess Players and Other Screenplays",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        "https://tuliresearchcentre.canto.global/direct/image/sc3ksj6h1178386ols1492457k/v6aSlzUWLa-ZAlDy81Yh4k1iBUk/m800/800",
      display: "Image",
    },
    {
      key: "document_type",
      value: "bke",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Editor",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "History & Historiography of Indian Scholarship in the Arts, Social Sciences & Humanities",
          value: "Scholarship",
          link: "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities",
        },
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "andrew-robinson",
          value: "Andrew-Robinson",
          first_name: "Andrew",
          last_name: "Robinson",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "4804406a-df00-4f9d-a54f-d30ba6ea6c42",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bka-robinson-satyajit-ray-1990",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "Calcutta",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "PB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000027",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "India",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "english",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "Rupa Paperback",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "1990",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "Satyajit Ray",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "The Inner Eye",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        "https://tuliresearchcentre.canto.global/direct/image/2t22bqd4rp0kd7396d6hl5v113/hqcYOOT5KAiAQ9Lr6qV3lWgORz8/m800/800",
      display: "Image",
    },
    {
      key: "document_type",
      value: "bka",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Author",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "History & Historiography of Indian Scholarship in the Arts, Social Sciences & Humanities",
          value: "Scholarship",
          link: "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities",
        },
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "andrew-robinson",
          value: "Andrew-Robinson",
          first_name: "Andrew",
          last_name: "Robinson",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "bc2099d0-252f-4f79-9e21-d28b9a1a1fe4",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bka-nandi-satyajit-ray-at-70-1991",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "Brussels",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "PB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000058",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "Belgium",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "1991",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "1991",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "29300100061",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "english ",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "Eiffel Editions",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "1991",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "Satyajit Ray at 70",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value:
        "As Writer, Designer, Actor, Director, Cameraman, Editor, Composer",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        "https://tuliresearchcentre.canto.global/direct/image/9dnslph5pp33t38fnsr4o91r2q/WU0_4_uX2F_TwWMEzNfq2c7sKeE/m800/800",
      display: "Image",
    },
    {
      key: "document_type",
      value: "bka",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Author",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "Popular Arts, Printed Image & Crafts of India",
          value: "PopCult",
          link: "popular-arts-printed-image-crafts-of-india",
        },
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "alok-b-nandi",
          value: "Alok-B-Nandi",
          first_name: "Alok-B",
          last_name: "Nandi",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "1f44ad12-ad30-47cb-9bda-8b9c2753cbee",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bka-gupta-the-painted-face-1991",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "New Delhi",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "HB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000078",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "India",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "1991",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "1991",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "english",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "Roli Books",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "1991",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "The Painted Face",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "Studies in India's Popular Cinema",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        "https://tuliresearchcentre.canto.global/direct/image/uiektppa3555pfe2h0l842un1m/eVyPuv00q2jg7yV6Xfnm83hxLyA/m800/800",
      display: "Image",
    },
    {
      key: "document_type",
      value: "bka",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Author",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
        {
          key: "History & Historiography of Indian Scholarship in the Arts, Social Sciences & Humanities",
          value: "Scholarship",
          link: "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "chidananda-das-gupta",
          value: "chidananda das gupta",
          first_name: "Chidananda",
          last_name: "DasGupta",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "2bd31e86-b848-46d6-a4c5-9527acb5531a",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bka-sarkar-the-world-of-satyajit-ray-1992",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "New Delhi",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "HB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000071",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "India",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "1992",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "1992",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "81-85674043",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "english ",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "UBSPD",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "1992",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "The World of Satyajit Ray",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        "https://tuliresearchcentre.canto.global/direct/image/agkvgtd3j95k936ef3tnfhjb1j/czNxALR360OzrpqCeSPpydr1zDo/m800/800",
      display: "Image",
    },
    {
      key: "document_type",
      value: "bka",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Author",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
        {
          key: "History & Historiography of Indian Scholarship in the Arts, Social Sciences & Humanities",
          value: "Scholarship",
          link: "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "bidyut-sarkar",
          value: "Bidyut-Sarkar",
          first_name: "Bidyut",
          last_name: "Sarkar",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "83315c59-2d01-45d7-8e68-71e7af9e0bcb",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bka-tesson-satyajit-ray-1992",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "Paris",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000086",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "France",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "1992",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "1992",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "2866421175",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "french",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "Cahiers Du Cine",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "1992",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "Satyajit Ray",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        "https://tuliresearchcentre.canto.global/direct/image/jrdnpfuaj138v8rhidkg593449/rzd7GgACikU_nW7o1wFXCR5iklE/m800/800",
      display: "Image",
    },
    {
      key: "document_type",
      value: "bka",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Author",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "charles-tesson",
          value: "Charles-Tesson",
          first_name: "Charles",
          last_name: "Tesson",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "89f15be7-f07d-4526-97e8-c592a40b49a6",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bka-chakravarty-national-identity-in-indian-popular-cinema-1993",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "New Delhi",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "PB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000068",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "India",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "1993",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "1993",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "0195647475",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "english",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "Oxford University",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "1993",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "National Identity in Indian Popular Cinema",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "1947-1987",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        "https://tuliresearchcentre.canto.global/direct/image/mg8bjc8mb16j184l2c7hmcuv41/aO3Pm2CAoyTCAwFwQtoCcmFlzn8/m800/800",
      display: "Image",
    },
    {
      key: "document_type",
      value: "bka",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Author",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "History & Historiography of Indian Scholarship in the Arts, Social Sciences & Humanities",
          value: "Scholarship",
          link: "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities",
        },
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "sumita-s-chakravarty",
          value: "Sumita-S-Chakravarty",
          first_name: "Sumita-S",
          last_name: "Chakravarty",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "d0e19d8d-36ed-4290-a545-7fc7721f7c46",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bka-ray-my-years-with-apu-1994",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "New Delhi",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "HB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000028",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "India",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "1994",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "1994",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "0-670862150",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "english",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "Penguin Books India",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "1994",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "My Years With Apu",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "A Memoir",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        "https://tuliresearchcentre.canto.global/direct/image/ck1brc7e1h1e75d0emtcdp0875/uOgaVOAKmaPNzQccZcM7fSexyBc/m800/800",
      display: "Image",
    },
    {
      key: "document_type",
      value: "bka",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Author",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "Changing Smile of Childhood and its Second Coming",
          value: "Childhood",
          link: "changing-smile-of-childhood-and-its-second-coming",
        },
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "satyajit-ray",
          value: "Satyajit-Ray",
          first_name: "Satyajit",
          last_name: "Ray",
        },
        {
          key: "satyajit-ray",
          value: "Satyajit Ray",
          first_name: "Satyajit",
          last_name: "Ray",
        },
        {
          key: "satyajit-ray",
          value: null,
          first_name: null,
          last_name: null,
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "5c3ebef6-6c1f-4af1-ba58-ecd4be76d67d",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bke-vasudev-frames-of-mind-1995",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "New Delhi",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "HB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000093",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "India",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "1995",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "1995",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "8174760539",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "english",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "UBS",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "1995",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "Frames of Mind",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "Reflections on Indian Cinema",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        "https://tuliresearchcentre.canto.global/direct/image/5qhfeo9uop13j2mjhdf6t2q50o/0OrDPOB9sWBZIekXIFKbvJs4kZE/m800/800",
      display: "Image",
    },
    {
      key: "document_type",
      value: "bke",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Editor",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "History & Historiography of Indian Scholarship in the Arts, Social Sciences & Humanities",
          value: "Scholarship",
          link: "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities",
        },
        {
          key: "India and her Relationship with the World",
          value: "IndoWorld",
          link: "india-and-her-relationship-with-the-world",
        },
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "aruna-vasudev",
          value: "Aruna-Vasudev",
          first_name: "Aruna",
          last_name: "Vasudev",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "75e601c6-b3a3-4b3b-b48a-7a027b066ed9",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bka-chowdhury-goopy-gyne-bagha-byne-1996",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "Calcutta",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000026",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "India",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "1996",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "1996",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "bengali",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "Ananda",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "1996",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "Goopy Gyne Bagha Byne",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        "https://tuliresearchcentre.canto.global/direct/image/meg4vb5u6p4c969gd7ir2ll679/ApS4WJyeez-CGyCHuKeWn5uZlCM/m800/800",
      display: "Image",
    },
    {
      key: "document_type",
      value: "bka",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Author",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "Changing Smile of Childhood and its Second Coming",
          value: "Childhood",
          link: "changing-smile-of-childhood-and-its-second-coming",
        },
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "upendra-kishor-ray-chowdhury",
          value: "Upendra-Kishor-Ray-Chowdhury",
          first_name: "Upendra-Kishor",
          last_name: "Ray-Chowdhury",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "5fff6235-3e51-4590-af3f-2af61948cf2a",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bka-elena-satyajit-ray-1998",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "Madrid",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000087",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "Spain",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "1998",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "1998  ",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "978-8437616476",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "spanish ",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "Cátedra  ",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "1998",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "Satyajit Ray",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        "https://tuliresearchcentre.canto.global/direct/image/84mqhqsmjp1jd337h6jnlsfa3u/z0KbsOwv8PsHt7d6-F9gQk7qzls/m800/800",
      display: "Image",
    },
    {
      key: "document_type",
      value: "bka",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Author",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "alberto-elena",
          value: "Alberto-Elena",
          first_name: "Alberto",
          last_name: "Elena",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "d2c6fc1e-5c82-49c5-8a13-5823b5f66460",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bka-prasad-ideology-of-the-hindi-film-1998",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "New Delhi",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "PB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000096",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "India",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "2000",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "1998",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "0195652959",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "english",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "Oxford University",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "1998",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "Ideology of the Hindi Film",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "A Historical Construction",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        "https://tuliresearchcentre.canto.global/direct/image/b6mdgmpg552170tl13ohk5ml1v/qZjguVcI8Ox1Vy2c5rNeSn4ocq4/m800/800",
      display: "Image",
    },
    {
      key: "document_type",
      value: "bka",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Author",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "History & Historiography of Indian Scholarship in the Arts, Social Sciences & Humanities",
          value: "Scholarship",
          link: "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities",
        },
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "m-madhava-prasad",
          value: "M-Madhava-Prasad",
          first_name: "M-Madhava",
          last_name: "Prasad",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "8a247196-4003-45a5-a467-8075cd8c3d3e",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bke-das-satyajit-ray-1998",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "New Delhi",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "HB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000030",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "India",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "1998",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "1998",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "8170237483",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "english ",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "Allied",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "1998",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "Satyajit Ray",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "An Intimate Master",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        "https://tuliresearchcentre.canto.global/direct/image/hvno05ago95hrc89t2scm8ra7q/gbrmVVXXY6tbphQw5R8XVY02jjs/m800/800",
      display: "Image",
    },
    {
      key: "document_type",
      value: "bke",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Editor",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "History & Historiography of Indian Scholarship in the Arts, Social Sciences & Humanities",
          value: "Scholarship",
          link: "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities",
        },
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
        {
          key: "Popular Arts, Printed Image & Crafts of India",
          value: "PopCult",
          link: "popular-arts-printed-image-crafts-of-india",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "santi-das",
          value: "Santi-Das",
          first_name: "Santi",
          last_name: "Das",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "2603c609-8050-454a-8fe9-7ae910a52035",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bka-elena-los-cines-perifericos-1999",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "Barcelona",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "PB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000097",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "Spain",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "1999",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "1999",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "84-49307104",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "spanish ",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "Paidos",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "1999",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "Los Cines Perifericos",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "Africa, Oriente Medio, India",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        "https://tuliresearchcentre.canto.global/direct/image/ecup88f81951hfb6sbbqns1040/moPs-UTn_maGD7TzBF8kN-sbFUw/m800/800",
      display: "Image",
    },
    {
      key: "document_type",
      value: "bka",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Author",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "alberto-elena",
          value: "Alberto-Elena",
          first_name: "Alberto",
          last_name: "Elena",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "aa2df08c-2e80-466c-aba5-383465a6e035",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bka-ghosh-manikda-2000",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "Kolkata",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "PB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000034",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "India",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "2000",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "2000",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "81877570010",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "french",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "Bingsha Shatabdi",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "2000",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "Manikda",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "Memoire sur Satyajit Ray",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        "https://tuliresearchcentre.canto.global/direct/image/gk79bl2n154ff5ngmvk62uqp7n/9ibPJZu-OXKZ7p3xOov345ToQaM/m800/800",
      display: "Image",
    },
    {
      key: "document_type",
      value: "bka",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Author",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "nemai-ghosh",
          value: "Nemai Ghosh",
          first_name: "Nemai",
          last_name: "Ghosh",
        },
        {
          key: "nemai-ghosh",
          value: "Nemai-Ghosh",
          first_name: "Nemai",
          last_name: "Ghosh",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "363ae2aa-4328-46c3-997e-1967273b07ed",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bka-cooper-the-cinema-of-satyajit-ray-2000",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "Cambridge",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "PB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000062",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "UK",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "2000",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "2000",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "0-521629802",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "english",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "Cambridge University",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "2000",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "The Cinema of Satyajit Ray",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "Between Tradition and Modernity",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        "https://tuliresearchcentre.canto.global/direct/image/6g871mhtid68vfb6tdjri9vc6o/omjs26yZrqF7oHOBQVxe_idNspc/m800/800",
      display: "Image",
    },
    {
      key: "document_type",
      value: "bka",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Author",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
        {
          key: "History & Historiography of Indian Scholarship in the Arts, Social Sciences & Humanities",
          value: "Scholarship",
          link: "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "darius-cooper",
          value: "Darius-Cooper",
          first_name: "Darius",
          last_name: "Cooper",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "b88efc75-cc13-4a7d-b97c-fb7cab8e2116",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bka-thoraval-the-cinemas-of-india\n1896-2000-2000",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "New Delhi",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "HB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000094",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "India",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "2000",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "2000",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "0333934105",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "english",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "Macmillan India",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "2000",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "The Cinemas of India:\n1896-2000",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        "https://tuliresearchcentre.canto.global/direct/image/h3t7tpae1d16329tpg0opuke1l/h8y8OlZTDl1UjPhqvVoVVAKip_Q/m800/800",
      display: "Image",
    },
    {
      key: "document_type",
      value: "bka",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Author",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "India and her Relationship with the World",
          value: "IndoWorld",
          link: "india-and-her-relationship-with-the-world",
        },
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "yves-thoraval",
          value: "Yves-Thoraval",
          first_name: "Yves",
          last_name: "Thoraval",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "6d3a9c9c-a4dc-4be4-92e9-9ea6e2ece4c6",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bka-gupta-the-cinema-of-satyajit-ray-2001",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "New Delhi",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "HB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000009.DI",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "India",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "1994",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "1994",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "81-23707533",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "english",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "National Book Trust",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "2001",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "The Cinema of Satyajit Ray",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        "https://tuliresearchcentre.canto.global/direct/image/ufdh66f5b152tfq349m6as6o6s/5dFTlSnkHovZD2ZrYTX3Gaiey_U/m800/800",
      display: "Image",
    },
    {
      key: "document_type",
      value: "bka",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Author",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
        {
          key: "History & Historiography of Indian Scholarship in the Arts, Social Sciences & Humanities",
          value: "Scholarship",
          link: "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "chidananda-das-gupta",
          value: "chidananda das gupta",
          first_name: "Chidananda",
          last_name: "DasGupta",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "8d3dd4a1-61d7-42d2-835e-cc6d921e0788",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bka-vallachira-asian-cinema-2001",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "Thrissur",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "PB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000031",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "India",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "2001",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "2001",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "malayalam",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "Dhrisyasahithi",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "2001",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "Asian Cinema",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "A New Perspective",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        "https://tuliresearchcentre.canto.global/direct/image/lp40tc6c3951h6hh7rbopbft06/VfZ75t0bvomPx0reeaJ0XlyZQRE/m800/800",
      display: "Image",
    },
    {
      key: "document_type",
      value: "bka",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Author",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "aravindan-vallachira",
          value: "Aravindan-Vallachira",
          first_name: "Aravindan",
          last_name: "Vallachira",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "bb92388c-5a76-4d61-baf5-0c1ad348ce10",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bka-ganguly-satyajit-ray-2001",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "New Delhi",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "PB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000042",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "India",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "2001",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "2001",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "81-87981040",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "english",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "Indialog",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "2001",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "Satyajit Ray",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "In Search of the Modern",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        "https://tuliresearchcentre.canto.global/direct/image/4aupoppujt13rf8tu3jl8k8f04/AwUN7ByPGSam2uBjpi675mGDcyg/m800/800",
      display: "Image",
    },
    {
      key: "document_type",
      value: "bka",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Author",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "History & Historiography of Indian Scholarship in the Arts, Social Sciences & Humanities",
          value: "Scholarship",
          link: "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities",
        },
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "suranjan-ganguly",
          value: "Suranjan-Ganguly",
          first_name: "Suranjan",
          last_name: "Ganguly",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "87a27741-6e31-4dce-8b59-927084401205",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bke-dasgupta-ray-2001",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "Kolkata",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "HB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000029",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "India",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "2001",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "2001",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "english-french",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "Kurchi Dasgupta",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "2001",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "Ray",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "A Glimpse",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        "https://tuliresearchcentre.canto.global/direct/image/52164hlcjp4cv1qulg8up9502v/F_tR1W0oo1fMjqrhNN2F5qb18g8/m800/800",
      display: "Image",
    },
    {
      key: "document_type",
      value: "bke",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Publisher",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
        {
          key: "History & Historiography of Indian Scholarship in the Arts, Social Sciences & Humanities",
          value: "Scholarship",
          link: "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "kurchi-dasgupta",
          value: "Kurchi-Dasgupta",
          first_name: "Kurchi",
          last_name: "Dasgupta",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "1b8ae6f7-9ac6-41ed-be60-1be9fa79bc76",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bka-vasudev-being-becoming-2002",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "New Delhi",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "HB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000081",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "India",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "2002",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "2002",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "0333938208",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "english",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "Macmillan India",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "2002",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "Being & Becoming",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "The Cinemas of Asia",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        "https://tuliresearchcentre.canto.global/direct/image/iejinh906537dcn50l6albv66b/AFDo4TaF3mF73NIcdoAiqq4x4PA/m800/800",
      display: "Image",
    },
    {
      key: "document_type",
      value: "bka",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Author",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "India and her Relationship with the World",
          value: "IndoWorld",
          link: "india-and-her-relationship-with-the-world",
        },
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "aruna-vasudev",
          value: "Aruna-Vasudev",
          first_name: "Aruna",
          last_name: "Vasudev",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "5ff23798-7d51-46e5-b096-99137ccdebef",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bke-majumdar-raytrospective-2001-2002",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "New Delhi",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "PB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000033",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "India",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "2002-07-01",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "2002",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "english-bengali-hindi",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "Indrani Majumdar",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "2002",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "RayTrospective 2001",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "A Celebration",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        "https://tuliresearchcentre.canto.global/direct/image/dms2lu81v14fbccc6sovsp3l38/Is2eVy5oB_P1YpDBYkoYlEZiGw8/m800/800",
      display: "Image",
    },
    {
      key: "document_type",
      value: "bke",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Compiler",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "History & Historiography of Indian Scholarship in the Arts, Social Sciences & Humanities",
          value: "Scholarship",
          link: "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities",
        },
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "indrani-majumdar",
          value: "Indrani-Majumdar",
          first_name: "Indrani",
          last_name: "Majumdar",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "30532fb2-bc08-4ab5-81f5-cf3d5aafc96e",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bka-ghosh-satyajit-ray-2003",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "New Delhi",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000084",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "India",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "2003",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "2003",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "english ",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "National Gallery of Modern Art",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "2003",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "Satyajit Ray",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "From Script to Screen ",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        "https://tuliresearchcentre.canto.global/direct/image/37m5q6ruip1bfb3it7jv6sgl30/F3hJedI2_BC93q5GLMzFT3GO_UU/m800/800",
      display: "Image",
    },
    {
      key: "document_type",
      value: "bka",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Photographer",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
        {
          key: "Photography in India",
          value: "Photo",
          link: "photography-in-india",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "nemai-ghosh",
          value: "Nemai-Ghosh",
          first_name: "Nemai",
          last_name: "Ghosh",
        },
        {
          key: "nemai-ghosh",
          value: "Nemai Ghosh",
          first_name: "Nemai",
          last_name: "Ghosh",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "4f76c726-22a4-440a-bb9e-1bc09ac3f603",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bke-robinson-satyajit-ray-2005",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "London",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "HB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000036",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "UK",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "2005",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "2005",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "1845110749",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "english",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "I B Tauris",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "2005",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "Satyajit Ray",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "A Vision of Cinema",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        "https://tuliresearchcentre.canto.global/direct/image/egpjgdchdp20p4h6skf9aq982g/BJKO1SueFEcBR0oef6J0QLtVcyg/m800/800",
      display: "Image",
    },
    {
      key: "document_type",
      value: "bke",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Editor",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "History & Historiography of Indian Scholarship in the Arts, Social Sciences & Humanities",
          value: "Scholarship",
          link: "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities",
        },
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
        {
          key: "Popular Arts, Printed Image & Crafts of India",
          value: "PopCult",
          link: "popular-arts-printed-image-crafts-of-india",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "andrew-robinson",
          value: "Andrew-Robinson",
          first_name: "Andrew",
          last_name: "Robinson",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "c6288985-0a29-42cd-9236-e6d9764fd0c1",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bka-roberge-satyajit-ray-2007",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "New Delhi",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000038",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "India",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "2007",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "2007",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "81-73047359",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "english",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "Manohar",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "2007",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "Satyajit Ray",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        "https://tuliresearchcentre.canto.global/direct/image/invae7aiip6elb68p8tccces3r/06bEoKwVczWLYDTPIOIGPzpu5RM/m800/800",
      display: "Image",
    },
    {
      key: "document_type",
      value: "bka",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Author",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "gaston-roberge",
          value: "Gaston-Roberge",
          first_name: "Gaston",
          last_name: "Roberge",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "3dd456f9-a099-4d54-bfe6-8573744c70f2",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bka-bahadur-a-textual-study-of-the-apu-trilogy-2011",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "New Delhi",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "HB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000070",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "India",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "2011",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "2011",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "978-9350004289",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "english",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "Vani",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "2011",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "A Textual Study of the Apu Trilogy",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        "https://tuliresearchcentre.canto.global/direct/image/bboako27dl5qt38cbj9phr3c62/scn5ShxbspIWo1kf-eUQ5a9yugk/m800/800",
      display: "Image",
    },
    {
      key: "document_type",
      value: "bka",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Author",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
        {
          key: "History & Historiography of Indian Scholarship in the Arts, Social Sciences & Humanities",
          value: "Scholarship",
          link: "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "satish-bahadur",
          value: "Satish-Bahadur",
          first_name: "Satish",
          last_name: "Bahadur",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "aa1bcd2a-3103-4a46-a5a4-dc25e8ef3a0c",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bka-sen-looking-beyond-2012",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "New Delhi",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "PB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000040",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "India",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "2012",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "2012",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "978-8174365651",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "english",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "Roli Books",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "2012",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "Looking Beyond",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "Graphics of Satyajit Ray",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        "https://tuliresearchcentre.canto.global/direct/image/ght4l47dvd1jt7fi2pigju2s6e/mCNVf8TsVW4WPTjOyYXwTKWREw0/m800/800",
      display: "Image",
    },
    {
      key: "document_type",
      value: "bka",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Author",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "Popular Arts, Printed Image & Crafts of India",
          value: "PopCult",
          link: "popular-arts-printed-image-crafts-of-india",
        },
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "jayanti-sen",
          value: "Jayanti-Sen",
          first_name: "Jayanti",
          last_name: "Sen",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "e81a9579-f2ef-4dbd-a43c-2f62785e5d28",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bka-ghosh-satyajit-ray-and-beyond-2013",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "New Delhi",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "HB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000032",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "India",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "2013",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "2013",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "978-9381217313",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "english",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "Delhi Art Gallery",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "2013",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "Satyajit Ray and Beyond",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        "https://tuliresearchcentre.canto.global/direct/image/nasua0toht6e1ci7ivg3rkpj3g/WEoFWKCL41BnCculov5xFxDfGYQ/m800/800",
      display: "Image",
    },
    {
      key: "document_type",
      value: "bka",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Author",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
        {
          key: "Photography in India",
          value: "Photo",
          link: "photography-in-india",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "nemai-ghosh",
          value: "Nemai Ghosh",
          first_name: "Nemai",
          last_name: "Ghosh",
        },
        {
          key: "nemai-ghosh",
          value: "Nemai-Ghosh",
          first_name: "Nemai",
          last_name: "Ghosh",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "ad07a953-fa00-4b5d-91c2-66794da6dcd1",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bke-ray-satyajit-rays-ravi-shankar-2014",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "Noida",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "HB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000044",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "India",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "2014",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "2014",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "978-9351361749",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "english",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value:
        "Collins in association with the Society for the Preservation of Satyajit Ray Archives",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "2014",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "Satyajit Ray's Ravi Shankar",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "An Unfilimed Visual Script",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        "https://tuliresearchcentre.canto.global/direct/image/f78n060ej57gn41lb3s9bck44l/aS82ufFCMmh_rI3gd1418JVbkno/m800/800",
      display: "Image",
    },
    {
      key: "document_type",
      value: "bke",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Editor",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
        {
          key: "History & Historiography of Indian Scholarship in the Arts, Social Sciences & Humanities",
          value: "Scholarship",
          link: "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities",
        },
        {
          key: "Modern & Contemporary Fine Arts in India",
          value: "FineArts",
          link: "modern-contemporary-fine-arts-in-india",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "sandip-ray",
          value: "Sandip Ray",
          first_name: "Sandip",
          last_name: "Ray",
        },
        {
          key: "sandip-ray",
          value: "Sandip-Ray",
          first_name: "Sandip",
          last_name: "Ray",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "3cc5cd89-eea8-4a3c-b742-c7afceaf819b",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bka-cherian-indias-film-society-movement-2017",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "New Delhi",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "HB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000037",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "India",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "2017",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "2017",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "978-9385985638",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "english",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "Sage",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "2017",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "India's Film Society Movement",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "The Journey and its Impact",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        "https://tuliresearchcentre.canto.global/direct/image/d4ib3ctkh9619e00hd6pu9sd10/aR0Ou22ieFX_ltrBnXgRzjP0UjE/m800/800",
      display: "Image",
    },
    {
      key: "document_type",
      value: "bka",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Author",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
        {
          key: "Social Responsibility of the Creative Mind ",
          value: "SocialR",
          link: "social-responsibility-of-the-creative-mind",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "v-k-cherian",
          value: "V-K-Cherian",
          first_name: "V-K",
          last_name: "Cherian",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "052349d1-7462-493a-8fd9-b14698c69fbe",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bka-jindal-my-adventures-with-satyajit-ray-2017",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "Gurugram",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "PB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000039",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "India",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "2017",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "2017",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "978-9352771025",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "english",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "HarperCollins",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "2017",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "My Adventures with Satyajit Ray",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "The Making of Shatranj Ke Khilari",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        "https://tuliresearchcentre.canto.global/direct/image/q67095kdnp5fp2r8d2c02rtu53/4EmN-s3VNr8sxXJJ9tvEH8xwGnQ/m800/800",
      display: "Image",
    },
    {
      key: "document_type",
      value: "bka",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Author",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
        {
          key: "Economics of Art, Cinema & Culture",
          value: "EconArts",
          link: "economics-of-art-cinema-culture",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "suresh-jindal",
          value: "Suresh Jindal",
          first_name: "Suresh",
          last_name: "Jindal",
        },
      ],
      display: "Creator",
    },
    {
      key: "drawer_link",
      value: true,
    },
  ],
  [
    {
      key: "id",
      value: "c2720f1c-a0af-4a71-a10c-0a45cf5b563d",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bke-ray-travails-with-the-alien-2018",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "Noida",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "PB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000048",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "India",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "2018",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "2018",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "978-9352779154",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "english",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "Harper Collins",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "2018",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "Travails With the Alien",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value:
        "The Film That was Never Made and Other Adventures with Science Fiction : Satyajit Ray",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        "https://tuliresearchcentre.canto.global/direct/image/d8s5l1is5h2lr8t5d3gvh8o766/kD27cH5am2JMgN8suQ_zMU74In8/m800/800",
      display: "Image",
    },
    {
      key: "document_type",
      value: "bke",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Editor",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "History & Historiography of Indian Scholarship in the Arts, Social Sciences & Humanities",
          value: "Scholarship",
          link: "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities",
        },
        {
          key: "Uncertainty Revealing Itself : Death | Magic | Mystery | Sci-Fi | Horror | Gambling  | A.I.",
          value: "Uncertainty",
          link: "uncertainy-revealing-itself-death-magic-mystery-sci-fi-horror-gambling-ai",
        },
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "sandip-ray",
          value: "Sandip Ray",
          first_name: "Sandip",
          last_name: "Ray",
        },
        {
          key: "sandip-ray",
          value: "Sandip-Ray",
          first_name: "Sandip",
          last_name: "Ray",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "f1634c3f-6c2e-4c9f-b79e-987efc98ac92",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bke-devasundaram-indian-cinema-beyond-bollywood-2018",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "New York",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "HB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000079",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "USA",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "2018",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "2018",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "978-0815368601",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "english",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "Routledge",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "2018",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "Indian Cinema Beyond Bollywood",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "The New Indepenedent Cinema Revolution",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        "https://tuliresearchcentre.canto.global/direct/image/n2bf4e2u5t1t1f0nh1uc8hjh14/RCTgPLTg1pjtSdSk7HZrfVH4DEs/m800/800",
      display: "Image",
    },
    {
      key: "document_type",
      value: "bke",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Editor",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "History & Historiography of Indian Scholarship in the Arts, Social Sciences & Humanities",
          value: "Scholarship",
          link: "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities",
        },
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "ashvin-immanuel-devasundaram",
          value: "Ashvin-Immanuel-Devasundaram",
          first_name: "Ashvin-Immanuel",
          last_name: "Devasundaram",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "b8e87b89-94d6-4d17-a265-5ea4afd147fc",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bka-sengupta-soumitra-chatterjee-2020",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "New Delhi",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "HB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000077",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "India",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "2020",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "2020",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "978-9389136760",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "english",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "Niyogi Books",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "2020",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "Soumitra Chatterjee",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "A Life in Cinema, Theatre, Poetry & Painting",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        "https://tuliresearchcentre.canto.global/direct/image/57sfokvt252u347ce80cr3le7r/bhQV6urIurKCOfD281oI8mL8oOs/m800/800",
      display: "Image",
    },
    {
      key: "document_type",
      value: "bka",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Author",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "arjun-sengupta",
          value: "Arjun-Sengupta",
          first_name: "Arjun",
          last_name: "Sengupta",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "fce0bb59-603f-4c01-9565-c040e5bdbaea",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bke-saklani-iti-satyajit-da-2022",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "New Delhi",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "HB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000041",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "India",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "2022",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "2022",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "978-8185983325",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "english",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "Musui Art Foundation",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "2022",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "Iti, Satyajit Da",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "Letters to a Friend from Satyajit Ray",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        "https://tuliresearchcentre.canto.global/direct/image/q7cs9t0b6t22lasel75o4bje4h/Q38Rdxvzm3uNtyf6xkP0ejbu8HI/m800/800",
      display: "Image",
    },
    {
      key: "document_type",
      value: "bke",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Editor",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
        {
          key: "History & Historiography of Indian Scholarship in the Arts, Social Sciences & Humanities",
          value: "Scholarship",
          link: "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "juhi-saklani",
          value: "Juhi-Saklani",
          first_name: "Juhi",
          last_name: "Saklani",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "0c671511-e9e6-41cc-9d08-9f2093bbfeaf",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bke-ray-satyajit-ray-2022",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "Gurugram",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "PB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000046",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "India",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "2022",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "2022",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "9780143448990",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "english",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "Penguin Random House India",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "2022",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "Satyajit Ray",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "Miscellany on Life, Cinema, People & Much More",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        "https://tuliresearchcentre.canto.global/direct/image/3ldj1re1ll2khdaqno856dph2l/Jw5CQvtfuIZtyBni-37fNh-WBDM/m800/800",
      display: "Image",
    },
    {
      key: "document_type",
      value: "bke",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Editor",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
        {
          key: "History & Historiography of Indian Scholarship in the Arts, Social Sciences & Humanities",
          value: "Scholarship",
          link: "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "sandip-ray",
          value: "Sandip-Ray",
          first_name: "Sandip",
          last_name: "Ray",
        },
        {
          key: "sandip-ray",
          value: "Sandip Ray",
          first_name: "Sandip",
          last_name: "Ray",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "a52ec4cc-7761-4617-9cf8-e07a1eee597e",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bke-majumdar-a-companion-to-indian-cinema-2022",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "New Jursey",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "HB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000069",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "USA",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "2022",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "2022",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "978-1119048268",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "english",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "John Wiley",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "2022",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "A Companion to Indian Cinema",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        "https://tuliresearchcentre.canto.global/direct/image/tc3osd8tbd2p9967efchufk71i/9dBwRpP4g_UUBMZu1m6solHpkxM/m800/800",
      display: "Image",
    },
    {
      key: "document_type",
      value: "bke",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Editor",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "History & Historiography of Indian Scholarship in the Arts, Social Sciences & Humanities",
          value: "Scholarship",
          link: "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities",
        },
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "neepa-majumdar",
          value: "Neepa-Majumdar",
          first_name: "Neepa",
          last_name: "Majumdar",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "c8ceaf6f-d31d-4dbd-8e6b-3920608aec8d",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bke-ray-the-best-of-satyajit-ray-2023",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "Gurgaon",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "PB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000047",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "India",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "2023",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "2023",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "978-0143459453",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "english",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "Penguin Random House India",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "2023",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "The Best of Satyajit Ray",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        "https://tuliresearchcentre.canto.global/direct/image/ertljmokad6llf6he465qdau4h/7elzYyy6jUJ8IVGffKimhbKNIJY/m800/800",
      display: "Image",
    },
    {
      key: "document_type",
      value: "bke",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Compiler",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "History & Historiography of Indian Scholarship in the Arts, Social Sciences & Humanities",
          value: "Scholarship",
          link: "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities",
        },
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "sandip-ray",
          value: "Sandip-Ray",
          first_name: "Sandip",
          last_name: "Ray",
        },
        {
          key: "sandip-ray",
          value: "Sandip Ray",
          first_name: "Sandip",
          last_name: "Ray",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "634b7972-e27b-42ae-ba4a-3807fa860124",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bka-tiwari-shatranj-ke-khilari-nd",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "Bhopal",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000066",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "India",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "hindi ",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "Madhya Pradesh Film Vikash Nigam",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "n.d.",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "Shatranj Ke Khilari",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        "https://tuliresearchcentre.canto.global/direct/image/69qcpkr3rh5q19vkeu9k48ci0c/MdqBl9t6ZdIncuscROu02mIfBUc/m800/800",
      display: "Image",
    },
    {
      key: "document_type",
      value: "bka",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Author",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "surendra-tiwari",
          value: "Surendra-Tiwari",
          first_name: "Surendra",
          last_name: "Tiwari",
        },
      ],
      display: "Creator",
    },
  ],
];
export const mughal_biblography_filter_options = {
  documentTypeFilter: ["bka", "bke"],
  yearFilter: [
    "1980",
    "1981",
    "1982",
    "1983",
    "1984",
    "1985",
    "1986",
    "1988",
    "1989",
    "1990",
    "1991",
    "1992",
    "1993",
    "1994",
    "1996",
    "1998",
    "1999",
    "2000",
    "2001",
    "2002",
    "2003",
    "2007",
    "2011",
    "2012",
    "2013",
    "2017",
    "2020",
    "n.d.",
    "1995",
    "2005",
    "2014",
    "2018",
    "2022",
    "2023",
  ],
  creatorTypeFilter: [
    "Author",
    "Dr & Author",
    "Photographer",
    "Editor",
    "Publisher",
    "Compiler",
  ],
  publisher: [
    "New Delhi",
    "Delhi",
    "New York",
    "Bombay",
    "Calcutta",
    "Lausanne",
    "Kolkata",
    "London",
    "Brussels",
    "Paris",
    "Madrid",
    "Barcelona",
    "Cambridge",
    "Thrissur",
    "Gurugram",
    "Bhopal",
    "Noida",
    "New Jursey",
    "Gurgaon",
  ],
};
//nemai-ghosh biblography
export const nemai_ghosh_research_centre = [
  [
    {
      key: "id",
      value: "3d84381f-58d8-4bfc-b5d6-ba89a9d933fa",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bka-gupta-the-cinema-of-satyajit-ray-1980",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "New Delhi",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "HB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000009",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "India",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "1980",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "1980",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "0-706910354",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "english",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "Vikas",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "1980",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "The Cinema of Satyajit Ray",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        "https://tuliresearchcentre.canto.global/direct/image/a5etin56bd74r0cdb0tqpqdk6v/ZcvEdaY5AhDJT-VGv-hKEHgzfNY/m800/800",
      display: "Image",
    },
    {
      key: "document_type",
      value: "bka",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Author",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
        {
          key: "History & Historiography of Indian Scholarship in the Arts, Social Sciences & Humanities",
          value: "Scholarship",
          link: "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "chidananda-das-gupta",
          value: "chidananda das gupta",
          first_name: "Chidananda",
          last_name: "DasGupta",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "5f6135ba-67c0-421f-951e-0be2744a45a5",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bka-rangoonwalla-satyajit-rays-art-1980",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "Delhi",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000055",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "India",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "1980",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "1980",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "086186235X",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "english",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "Clarion Books",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "1980",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "Satyajit Ray's Art",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        "https://tuliresearchcentre.canto.global/direct/image/87tqucfojh6ld56aq6ur3gle1n/jUIWjMmBsZKQwlgwV0nXIZ606-M/m800/800",
      display: "Image",
    },
    {
      key: "document_type",
      value: "bka",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Author",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
        {
          key: "History & Historiography of Indian Scholarship in the Arts, Social Sciences & Humanities",
          value: "Scholarship",
          link: "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "firoze-rangoonwalla",
          value: "Firoze-Rangoonwalla",
          first_name: "Firoze",
          last_name: "Rangoonwalla",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "fff27d9a-745e-40bf-8226-7cd1c8bd5c97",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bka-barnouw-indian-film-1980",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "New York",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "PB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000076",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "USA",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "1980",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "1980",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "0195026829",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "english",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "Oxford University",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "1980",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "Indian Film",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        "https://tuliresearchcentre.canto.global/direct/image/safealga8p61fb6cnppogdn16f/0el1FcMhki39Lfm1kZumxt_IfEY/m800/800",
      display: "Image",
    },
    {
      key: "document_type",
      value: "bka",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Author",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "History & Historiography of Indian Scholarship in the Arts, Social Sciences & Humanities",
          value: "Scholarship",
          link: "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities",
        },
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "erik-barnouw",
          value: "Erik-Barnouw",
          first_name: "Erik",
          last_name: "Barnouw",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "66025eeb-b495-4fb6-b2e1-643e3b2dbe48",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bka-gupta-talking-about-films-1981",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "Bombay",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "HB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000010",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "India",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "1981",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "1981",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "english",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "Orient Longman",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "1981",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "Talking About Films",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        "https://tuliresearchcentre.canto.global/direct/image/t1d6pn27b11c530hl9r4vjj90r/HWFegcQQp6QdetpSgR38zrINh6Q/m800/800",
      display: "Image",
    },
    {
      key: "document_type",
      value: "bka",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Author",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "History & Historiography of Indian Scholarship in the Arts, Social Sciences & Humanities",
          value: "Scholarship",
          link: "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities",
        },
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "chidananda-das-gupta",
          value: "chidananda das gupta",
          first_name: "Chidananda",
          last_name: "DasGupta",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "8274cbd9-df47-46bd-b3f2-2bae27da4895",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bka-ray-film-india-1981",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "New Delhi",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "PB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000011",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "India",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "1981",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "1981",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "english",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value:
        "Directorate of Film Festivals Ministry of Information and Broadcasting",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "1981",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "Film India",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "An Anthology of Statements on Ray and by Ray",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        "https://tuliresearchcentre.canto.global/direct/image/250kvopos911f3s6bnt2ane719/yeUiADMsnMRpq0AFrb-ktYzFXyk/m800/800",
      display: "Image",
    },
    {
      key: "document_type",
      value: "bka",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Author",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "satyajit-ray",
          value: "Satyajit-Ray",
          first_name: "Satyajit",
          last_name: "Ray",
        },
        {
          key: "satyajit-ray",
          value: null,
          first_name: null,
          last_name: null,
        },
        {
          key: "satyajit-ray",
          value: "Satyajit Ray",
          first_name: "Satyajit",
          last_name: "Ray",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "8b1223fa-3372-4a09-b67d-faa808c44c8c",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bka-ray-aro-baro-1981",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "Calcutta",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000012",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "India",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "1981",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "bengali",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "Ananda",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "1981",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "Aro Baro",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        "https://tuliresearchcentre.canto.global/direct/image/7dglfkb47t329dn0ndsf0uj73h/ISmECyG2TcOTVekaWowtr6BsgfQ/m800/800",
      display: "Image",
    },
    {
      key: "document_type",
      value: "bka",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Author",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "satyajit-ray",
          value: "Satyajit-Ray",
          first_name: "Satyajit",
          last_name: "Ray",
        },
        {
          key: "satyajit-ray",
          value: "Satyajit Ray",
          first_name: "Satyajit",
          last_name: "Ray",
        },
        {
          key: "satyajit-ray",
          value: null,
          first_name: null,
          last_name: null,
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "5d333dd1-ba62-457b-ada9-b26391065032",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bka-bahadur-satyajit-rays-pather-panchali-1981",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "New Delhi",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "PB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000050",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "India",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "1981",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "1981",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "english",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "National Film Archive of India",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "1981",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "Satyajit Ray's Pather Panchali",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "Screenplay with Analytical Notes",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        "https://tuliresearchcentre.canto.global/direct/image/isf99hp46d3kreolqlvlaaca0p/aBrRcLDENyYUeqj-TvoyI8aHwlQ/m800/800",
      display: "Image",
    },
    {
      key: "document_type",
      value: "bka",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Author",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
        {
          key: "History & Historiography of Indian Scholarship in the Arts, Social Sciences & Humanities",
          value: "Scholarship",
          link: "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "satish-bahadur",
          value: "Satish-Bahadur",
          first_name: "Satish",
          last_name: "Bahadur",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "20d223a9-4c84-4da5-8ba2-fd84ab286b41",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bka-micciollo-satyajit-ray-1981",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "Lausanne",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000085",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "France",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "1981",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "1981",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "french",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "L'Age D'Homme",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "1981",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "Satyajit Ray",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        "https://tuliresearchcentre.canto.global/direct/image/2fdnva763t7qn7d62pqcaeh05e/mHhNIzMrunW0qXD9GT2kgewSRCM/m800/800",
      display: "Image",
    },
    {
      key: "document_type",
      value: "bka",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Author",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "henri-micciollo",
          value: "Henri-Micciollo",
          first_name: "Henri",
          last_name: "Micciollo",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "dbd82396-fcd9-4b51-9553-38c59918f271",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bka-ray-shatranj-ke-khilari-1982",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "Calcutta",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000015",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "India",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "english",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "Ekshan",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "1982",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "Shatranj Ke Khilari",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "The Chess Players",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        "https://tuliresearchcentre.canto.global/direct/image/9ntctdgqsp3hrd3gn7mpalbs0i/wuYtgcnOUjTpRKui-DI8E1x9Hu8/m800/800",
      display: "Image",
    },
    {
      key: "document_type",
      value: "bka",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Author",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "satyajit-ray",
          value: "Satyajit-Ray",
          first_name: "Satyajit",
          last_name: "Ray",
        },
        {
          key: "satyajit-ray",
          value: null,
          first_name: null,
          last_name: null,
        },
        {
          key: "satyajit-ray",
          value: "Satyajit Ray",
          first_name: "Satyajit",
          last_name: "Ray",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "0672b88a-4306-4163-81d5-625722218622",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bke-bazaz-festival-of-india-1982",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "PB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000014",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "UK",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "1982",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "1982",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "english",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "Delhi Diary",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "1982",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "Festival of India",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        "https://tuliresearchcentre.canto.global/direct/image/g30lbekt8p007a3dk7fjc8dt3l/txdcf0vElyzK-S90XZgbc9lrICM/m800/800",
      display: "Image",
    },
    {
      key: "document_type",
      value: "bke",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Editor",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
        {
          key: "History & Historiography of Indian Scholarship in the Arts, Social Sciences & Humanities",
          value: "Scholarship",
          link: "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "mohini-j-bazaz",
          value: "Mohini-J-Bazaz",
          first_name: "Mohini-J",
          last_name: "Bazaz",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "e4e749c5-fc97-424e-a187-315fc7edb6b5",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bke-banerjee-new-indian-cinema-1982",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "New Delhi",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "PB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000089",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "India",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "1982",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "1982",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "english",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "National Film Development Corporation",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "1982",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "New Indian Cinema",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        "https://tuliresearchcentre.canto.global/direct/image/pbv4hejvdt5hd3vn13l0jjpe01/6E0rFz82Bte2vqz_u2c-Dn4rkhE/m800/800",
      display: "Image",
    },
    {
      key: "document_type",
      value: "bke",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Editor",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
        {
          key: "History & Historiography of Indian Scholarship in the Arts, Social Sciences & Humanities",
          value: "Scholarship",
          link: "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "shampa-banerjee",
          value: "Shampa-Banerjee",
          first_name: "Shampa",
          last_name: "Banerjee",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "fdbad41f-ee9a-47e8-8698-6d4c5ee36847",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bka-ray-phatik-chand-1983",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "Delhi",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "PB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000016",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "India",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "1983",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "1983",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "english",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "Orient Paperback",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "1983",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "Phatik Chand",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        "https://tuliresearchcentre.canto.global/direct/image/dqu18l0e3t3e3e5kepdv5eff1s/6ZI1oavu_22gONBCmDwtjT92GDo/m800/800",
      display: "Image",
    },
    {
      key: "document_type",
      value: "bka",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Author",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "satyajit-ray",
          value: null,
          first_name: null,
          last_name: null,
        },
        {
          key: "satyajit-ray",
          value: "Satyajit Ray",
          first_name: "Satyajit",
          last_name: "Ray",
        },
        {
          key: "satyajit-ray",
          value: "Satyajit-Ray",
          first_name: "Satyajit",
          last_name: "Ray",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "3e610ec6-7583-4a70-b87a-ffe41e87fca3",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bka-ray-jab-main-chhota-tha-1984",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "Delhi",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "HB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000017",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "India",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "1984",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "1984",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "hindi ",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "Rajkamal",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "1984",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "Jab Main Chhota Tha",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        "https://tuliresearchcentre.canto.global/direct/image/0ru589u8u538n1ddgq7u0qk639/HRsXrxtud5x6bkp65OopMOmpryg/m800/800",
      display: "Image",
    },
    {
      key: "document_type",
      value: "bka",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Author",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
        {
          key: "Changing Smile of Childhood and its Second Coming",
          value: "Childhood",
          link: "changing-smile-of-childhood-and-its-second-coming",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "satyajit-ray",
          value: "Satyajit Ray",
          first_name: "Satyajit",
          last_name: "Ray",
        },
        {
          key: "satyajit-ray",
          value: null,
          first_name: null,
          last_name: null,
        },
        {
          key: "satyajit-ray",
          value: "Satyajit-Ray",
          first_name: "Satyajit",
          last_name: "Ray",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "54bbaae0-d31c-4433-b9c9-f5fcc2a64c67",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bke-mukhopadhyay-pather-panchali-1984",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "Calcutta",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "HB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000051",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "India",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "1984",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "1984",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "english",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "Cine Central",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "1984",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "Pather Panchali",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "A Film by Satyajit Ray",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        "https://tuliresearchcentre.canto.global/direct/image/li844dmijl77p3f6phbhic7o6d/mu5_CrFFHK1whDpKYF2Sz5847Zk/m800/800",
      display: "Image",
    },
    {
      key: "document_type",
      value: "bke",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Editor",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "History & Historiography of Indian Scholarship in the Arts, Social Sciences & Humanities",
          value: "Scholarship",
          link: "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities",
        },
        {
          key: "Changing Smile of Childhood and its Second Coming",
          value: "Childhood",
          link: "changing-smile-of-childhood-and-its-second-coming",
        },
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "parimal-mukhopadhyay",
          value: "Parimal-Mukhopadhyay",
          first_name: "Parimal",
          last_name: "Mukhopadhyay",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "e721c9d5-b998-42f5-98f5-72ecb6c8ff37",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bka-ray-the-apu-trilogy-1985",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "Calcutta",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "PB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000018",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "India",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "1985",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "1985",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "english",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "Seagull Books",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "1985",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "The Apu Trilogy",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "Pather Panchali, Aparajito, Apur Sansar",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        "https://tuliresearchcentre.canto.global/direct/image/3oug5tpeh179t9bl99kgl9sr7j/pI6vAq8TT-GQWEqMrb5GeJx_OlM/m800/800",
      display: "Image",
    },
    {
      key: "document_type",
      value: "bka",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Author",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
        {
          key: "Changing Smile of Childhood and its Second Coming",
          value: "Childhood",
          link: "changing-smile-of-childhood-and-its-second-coming",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "satyajit-ray",
          value: "Satyajit-Ray",
          first_name: "Satyajit",
          last_name: "Ray",
        },
        {
          key: "satyajit-ray",
          value: null,
          first_name: null,
          last_name: null,
        },
        {
          key: "satyajit-ray",
          value: "Satyajit Ray",
          first_name: "Satyajit",
          last_name: "Ray",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "c9c5caab-71bc-46c3-90e2-90f2423c095b",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bka-ray-pikoor-diary-o-onyanyo-1985",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "Calcutta",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000019",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "India",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "1985",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "bengali",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "Ananda",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "1985",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "Pikoor Diary O Onyanyo",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        "https://tuliresearchcentre.canto.global/direct/image/gb7p4hdvt910de33ii253os746/Cec8VtXnECuzoxnEAejOHIjgSvc/m800/800",
      display: "Image",
    },
    {
      key: "document_type",
      value: "bka",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Author",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "satyajit-ray",
          value: null,
          first_name: null,
          last_name: null,
        },
        {
          key: "satyajit-ray",
          value: "Satyajit-Ray",
          first_name: "Satyajit",
          last_name: "Ray",
        },
        {
          key: "satyajit-ray",
          value: "Satyajit Ray",
          first_name: "Satyajit",
          last_name: "Ray",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "99730d0f-329b-4f83-9152-0ae35dc3aca9",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bke-ramachandran-70-years-of-indian-cinema-\n1913-1983-1985",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "Bombay",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "HB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000075",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "India",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "1985",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "1985",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "0861320905",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "english",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "Cinema India International",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "1985",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "70 Years of Indian Cinema -\n1913-1983",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        "https://tuliresearchcentre.canto.global/direct/image/1krdki917d6e56at2vrkto7s00/cU_ZNe_iC8LGpq7PR7CJ5ZhdC9I/m800/800",
      display: "Image",
    },
    {
      key: "document_type",
      value: "bke",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Editor",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
        {
          key: "History & Historiography of Indian Scholarship in the Arts, Social Sciences & Humanities",
          value: "Scholarship",
          link: "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "t-m-ramachandran",
          value: "T-M-Ramachandran",
          first_name: "T-M",
          last_name: "Ramachandran",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "2bd8e6fe-fd8e-4183-bea8-5edb79f82b88",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bka-banerjee-jalsaghar-1986",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "Kolkata",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "HB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000021",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "India",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "1986",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "1937",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "bengali",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "Indranath Majumdar",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "1986",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "Jalsaghar",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        "https://tuliresearchcentre.canto.global/direct/image/pajsen347d26b8feglipg80u7i/N-dtAybZtt1LAAIl4bw2gdv57t0/m800/800",
      display: "Image",
    },
    {
      key: "document_type",
      value: "bka",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Author",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "tarasankar-banerjee",
          value: "Tarasankar-Banerjee",
          first_name: "Tarasankar",
          last_name: "Banerjee",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "13b29e42-ef12-4b52-ab19-dcf25a7fa97a",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bka-ray-bravo-professor-shonku-1986",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "New Delhi",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "PB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000022",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "India",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "1986",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "1974",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "english",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "Rupa Paperback",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "1986",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "Bravo! Professor Shonku",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        "https://tuliresearchcentre.canto.global/direct/image/5cv7o61d592vf5002qb98vqf2m/AbDOrARVuPLK1lOXnUI9MK-0g1I/m800/800",
      display: "Image",
    },
    {
      key: "document_type",
      value: "bka",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Author",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
        {
          key: "Uncertainty Revealing Itself : Death | Magic | Mystery | Sci-Fi | Horror | Gambling  | A.I.",
          value: "Uncertainty",
          link: "uncertainy-revealing-itself-death-magic-mystery-sci-fi-horror-gambling-ai",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "satyajit-ray",
          value: "Satyajit-Ray",
          first_name: "Satyajit",
          last_name: "Ray",
        },
        {
          key: "satyajit-ray",
          value: null,
          first_name: null,
          last_name: null,
        },
        {
          key: "satyajit-ray",
          value: "Satyajit Ray",
          first_name: "Satyajit",
          last_name: "Ray",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "53f6a656-7034-4329-bb72-7f36f6547948",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bka-datta-benegal-on-ray-1988",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "Calcutta",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "PB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000003",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "India",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "1988",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "1988",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "8170460212",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "english",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "Seagull Books",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "1988",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "Benegal on Ray",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "Satyajit Ray: A Film by Shyam Benegal",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        "https://tuliresearchcentre.canto.global/direct/image/qhrjp5ieoh7oj6lrf5al03rm5a/ys6mfvXYquMNUugkIJkytA0ubpY/m800/800",
      display: "Image",
    },
    {
      key: "document_type",
      value: "bka",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Author",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "alakananda-datta",
          value: "Alakananda-Datta",
          first_name: "Alakananda",
          last_name: "Datta",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "abe62ab1-bea9-419b-9f8e-aa2e13fc8248",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bka-nyce-satyajit-ray-1988",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "New York",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "HB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000057",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "USA",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "1988",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "1988",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "0-275926664",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "english",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "Praeger",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "1988",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "Satyajit Ray",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "A Study of His Films",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        "https://tuliresearchcentre.canto.global/direct/image/nmn2np2kkp17b4iighdq5tl47a/NCclisCyEuuIhywFvMU97oOPXXs/m800/800",
      display: "Image",
    },
    {
      key: "document_type",
      value: "bka",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Author",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "History & Historiography of Indian Scholarship in the Arts, Social Sciences & Humanities",
          value: "Scholarship",
          link: "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities",
        },
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "ben-nyce",
          value: "Ben-Nyce",
          first_name: "Ben",
          last_name: "Nyce",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "32eb20eb-f597-4ea7-b27c-eeadb91cbf77",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bka-valicha-the-moving-image-1988",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "Bombay",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "HB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000095",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "India",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "1988",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "1988",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "0861316819",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "english",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "Orient Longman",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "1988",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "The Moving Image",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "A Study of Indian Cinema",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        "https://tuliresearchcentre.canto.global/direct/image/ate182883576b4aola99k9740g/BYcjvNMmvk1WdV1mG0iqYyY8eNw/m800/800",
      display: "Image",
    },
    {
      key: "document_type",
      value: "bka",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Dr & Author",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
        {
          key: "History & Historiography of Indian Scholarship in the Arts, Social Sciences & Humanities",
          value: "Scholarship",
          link: "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "kishore-valicha",
          value: "Kishore-Valicha",
          first_name: "Kishore",
          last_name: "Valicha",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "b9aefde9-36fc-471d-b9aa-bfa2f0334aa2",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bka-robinson-satyajit-ray-1989",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "London",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "HB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000004",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "UK",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "1989",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "1989",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "0-233984739",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "english",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "Andrew Deutsch",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "1989",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "Satyajit Ray",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "The Inner Eye",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        "https://tuliresearchcentre.canto.global/direct/image/mmr1aihhat0r75dr67us6gbp4v/Rwe6Ni6vJ5R9gLxqGJmu6Pk7urI/m800/800",
      display: "Image",
    },
    {
      key: "document_type",
      value: "bka",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Author",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
        {
          key: "India and her Relationship with the World",
          value: "IndoWorld",
          link: "india-and-her-relationship-with-the-world",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "andrew-robinson",
          value: "Andrew-Robinson",
          first_name: "Andrew",
          last_name: "Robinson",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "40cf522d-0236-41cd-a5b0-ef21662d3b0d",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bke-robinson-the-chess-players-and-other-screenplays-1989",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "London",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "PB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000024",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "United Kingdom",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "1989",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "1989",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "0-571140742",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "english ",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "Faber and Faber",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "1989",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "The Chess Players and Other Screenplays",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        "https://tuliresearchcentre.canto.global/direct/image/sc3ksj6h1178386ols1492457k/v6aSlzUWLa-ZAlDy81Yh4k1iBUk/m800/800",
      display: "Image",
    },
    {
      key: "document_type",
      value: "bke",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Editor",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "History & Historiography of Indian Scholarship in the Arts, Social Sciences & Humanities",
          value: "Scholarship",
          link: "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities",
        },
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "andrew-robinson",
          value: "Andrew-Robinson",
          first_name: "Andrew",
          last_name: "Robinson",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "4804406a-df00-4f9d-a54f-d30ba6ea6c42",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bka-robinson-satyajit-ray-1990",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "Calcutta",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "PB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000027",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "India",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "english",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "Rupa Paperback",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "1990",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "Satyajit Ray",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "The Inner Eye",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        "https://tuliresearchcentre.canto.global/direct/image/2t22bqd4rp0kd7396d6hl5v113/hqcYOOT5KAiAQ9Lr6qV3lWgORz8/m800/800",
      display: "Image",
    },
    {
      key: "document_type",
      value: "bka",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Author",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "History & Historiography of Indian Scholarship in the Arts, Social Sciences & Humanities",
          value: "Scholarship",
          link: "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities",
        },
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "andrew-robinson",
          value: "Andrew-Robinson",
          first_name: "Andrew",
          last_name: "Robinson",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "bc2099d0-252f-4f79-9e21-d28b9a1a1fe4",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bka-nandi-satyajit-ray-at-70-1991",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "Brussels",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "PB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000058",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "Belgium",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "1991",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "1991",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "29300100061",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "english ",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "Eiffel Editions",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "1991",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "Satyajit Ray at 70",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value:
        "As Writer, Designer, Actor, Director, Cameraman, Editor, Composer",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        "https://tuliresearchcentre.canto.global/direct/image/9dnslph5pp33t38fnsr4o91r2q/WU0_4_uX2F_TwWMEzNfq2c7sKeE/m800/800",
      display: "Image",
    },
    {
      key: "document_type",
      value: "bka",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Author",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "Popular Arts, Printed Image & Crafts of India",
          value: "PopCult",
          link: "popular-arts-printed-image-crafts-of-india",
        },
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "alok-b-nandi",
          value: "Alok-B-Nandi",
          first_name: "Alok-B",
          last_name: "Nandi",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "1f44ad12-ad30-47cb-9bda-8b9c2753cbee",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bka-gupta-the-painted-face-1991",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "New Delhi",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "HB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000078",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "India",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "1991",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "1991",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "english",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "Roli Books",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "1991",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "The Painted Face",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "Studies in India's Popular Cinema",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        "https://tuliresearchcentre.canto.global/direct/image/uiektppa3555pfe2h0l842un1m/eVyPuv00q2jg7yV6Xfnm83hxLyA/m800/800",
      display: "Image",
    },
    {
      key: "document_type",
      value: "bka",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Author",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
        {
          key: "History & Historiography of Indian Scholarship in the Arts, Social Sciences & Humanities",
          value: "Scholarship",
          link: "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "chidananda-das-gupta",
          value: "chidananda das gupta",
          first_name: "Chidananda",
          last_name: "DasGupta",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "2bd31e86-b848-46d6-a4c5-9527acb5531a",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bka-sarkar-the-world-of-satyajit-ray-1992",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "New Delhi",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "HB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000071",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "India",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "1992",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "1992",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "81-85674043",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "english ",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "UBSPD",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "1992",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "The World of Satyajit Ray",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        "https://tuliresearchcentre.canto.global/direct/image/agkvgtd3j95k936ef3tnfhjb1j/czNxALR360OzrpqCeSPpydr1zDo/m800/800",
      display: "Image",
    },
    {
      key: "document_type",
      value: "bka",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Author",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
        {
          key: "History & Historiography of Indian Scholarship in the Arts, Social Sciences & Humanities",
          value: "Scholarship",
          link: "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "bidyut-sarkar",
          value: "Bidyut-Sarkar",
          first_name: "Bidyut",
          last_name: "Sarkar",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "83315c59-2d01-45d7-8e68-71e7af9e0bcb",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bka-tesson-satyajit-ray-1992",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "Paris",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000086",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "France",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "1992",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "1992",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "2866421175",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "french",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "Cahiers Du Cine",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "1992",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "Satyajit Ray",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        "https://tuliresearchcentre.canto.global/direct/image/jrdnpfuaj138v8rhidkg593449/rzd7GgACikU_nW7o1wFXCR5iklE/m800/800",
      display: "Image",
    },
    {
      key: "document_type",
      value: "bka",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Author",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "charles-tesson",
          value: "Charles-Tesson",
          first_name: "Charles",
          last_name: "Tesson",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "89f15be7-f07d-4526-97e8-c592a40b49a6",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bka-chakravarty-national-identity-in-indian-popular-cinema-1993",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "New Delhi",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "PB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000068",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "India",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "1993",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "1993",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "0195647475",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "english",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "Oxford University",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "1993",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "National Identity in Indian Popular Cinema",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "1947-1987",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        "https://tuliresearchcentre.canto.global/direct/image/mg8bjc8mb16j184l2c7hmcuv41/aO3Pm2CAoyTCAwFwQtoCcmFlzn8/m800/800",
      display: "Image",
    },
    {
      key: "document_type",
      value: "bka",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Author",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "History & Historiography of Indian Scholarship in the Arts, Social Sciences & Humanities",
          value: "Scholarship",
          link: "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities",
        },
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "sumita-s-chakravarty",
          value: "Sumita-S-Chakravarty",
          first_name: "Sumita-S",
          last_name: "Chakravarty",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "d0e19d8d-36ed-4290-a545-7fc7721f7c46",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bka-ray-my-years-with-apu-1994",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "New Delhi",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "HB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000028",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "India",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "1994",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "1994",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "0-670862150",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "english",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "Penguin Books India",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "1994",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "My Years With Apu",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "A Memoir",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        "https://tuliresearchcentre.canto.global/direct/image/ck1brc7e1h1e75d0emtcdp0875/uOgaVOAKmaPNzQccZcM7fSexyBc/m800/800",
      display: "Image",
    },
    {
      key: "document_type",
      value: "bka",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Author",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "Changing Smile of Childhood and its Second Coming",
          value: "Childhood",
          link: "changing-smile-of-childhood-and-its-second-coming",
        },
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "satyajit-ray",
          value: "Satyajit-Ray",
          first_name: "Satyajit",
          last_name: "Ray",
        },
        {
          key: "satyajit-ray",
          value: "Satyajit Ray",
          first_name: "Satyajit",
          last_name: "Ray",
        },
        {
          key: "satyajit-ray",
          value: null,
          first_name: null,
          last_name: null,
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "5c3ebef6-6c1f-4af1-ba58-ecd4be76d67d",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bke-vasudev-frames-of-mind-1995",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "New Delhi",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "HB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000093",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "India",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "1995",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "1995",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "8174760539",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "english",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "UBS",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "1995",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "Frames of Mind",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "Reflections on Indian Cinema",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        "https://tuliresearchcentre.canto.global/direct/image/5qhfeo9uop13j2mjhdf6t2q50o/0OrDPOB9sWBZIekXIFKbvJs4kZE/m800/800",
      display: "Image",
    },
    {
      key: "document_type",
      value: "bke",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Editor",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "History & Historiography of Indian Scholarship in the Arts, Social Sciences & Humanities",
          value: "Scholarship",
          link: "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities",
        },
        {
          key: "India and her Relationship with the World",
          value: "IndoWorld",
          link: "india-and-her-relationship-with-the-world",
        },
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "aruna-vasudev",
          value: "Aruna-Vasudev",
          first_name: "Aruna",
          last_name: "Vasudev",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "75e601c6-b3a3-4b3b-b48a-7a027b066ed9",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bka-chowdhury-goopy-gyne-bagha-byne-1996",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "Calcutta",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000026",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "India",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "1996",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "1996",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "bengali",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "Ananda",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "1996",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "Goopy Gyne Bagha Byne",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        "https://tuliresearchcentre.canto.global/direct/image/meg4vb5u6p4c969gd7ir2ll679/ApS4WJyeez-CGyCHuKeWn5uZlCM/m800/800",
      display: "Image",
    },
    {
      key: "document_type",
      value: "bka",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Author",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "Changing Smile of Childhood and its Second Coming",
          value: "Childhood",
          link: "changing-smile-of-childhood-and-its-second-coming",
        },
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "upendra-kishor-ray-chowdhury",
          value: "Upendra-Kishor-Ray-Chowdhury",
          first_name: "Upendra-Kishor",
          last_name: "Ray-Chowdhury",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "5fff6235-3e51-4590-af3f-2af61948cf2a",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bka-elena-satyajit-ray-1998",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "Madrid",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000087",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "Spain",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "1998",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "1998  ",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "978-8437616476",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "spanish ",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "Cátedra  ",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "1998",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "Satyajit Ray",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        "https://tuliresearchcentre.canto.global/direct/image/84mqhqsmjp1jd337h6jnlsfa3u/z0KbsOwv8PsHt7d6-F9gQk7qzls/m800/800",
      display: "Image",
    },
    {
      key: "document_type",
      value: "bka",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Author",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "alberto-elena",
          value: "Alberto-Elena",
          first_name: "Alberto",
          last_name: "Elena",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "d2c6fc1e-5c82-49c5-8a13-5823b5f66460",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bka-prasad-ideology-of-the-hindi-film-1998",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "New Delhi",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "PB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000096",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "India",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "2000",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "1998",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "0195652959",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "english",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "Oxford University",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "1998",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "Ideology of the Hindi Film",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "A Historical Construction",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        "https://tuliresearchcentre.canto.global/direct/image/b6mdgmpg552170tl13ohk5ml1v/qZjguVcI8Ox1Vy2c5rNeSn4ocq4/m800/800",
      display: "Image",
    },
    {
      key: "document_type",
      value: "bka",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Author",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "History & Historiography of Indian Scholarship in the Arts, Social Sciences & Humanities",
          value: "Scholarship",
          link: "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities",
        },
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "m-madhava-prasad",
          value: "M-Madhava-Prasad",
          first_name: "M-Madhava",
          last_name: "Prasad",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "8a247196-4003-45a5-a467-8075cd8c3d3e",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bke-das-satyajit-ray-1998",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "New Delhi",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "HB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000030",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "India",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "1998",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "1998",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "8170237483",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "english ",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "Allied",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "1998",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "Satyajit Ray",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "An Intimate Master",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        "https://tuliresearchcentre.canto.global/direct/image/hvno05ago95hrc89t2scm8ra7q/gbrmVVXXY6tbphQw5R8XVY02jjs/m800/800",
      display: "Image",
    },
    {
      key: "document_type",
      value: "bke",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Editor",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "History & Historiography of Indian Scholarship in the Arts, Social Sciences & Humanities",
          value: "Scholarship",
          link: "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities",
        },
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
        {
          key: "Popular Arts, Printed Image & Crafts of India",
          value: "PopCult",
          link: "popular-arts-printed-image-crafts-of-india",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "santi-das",
          value: "Santi-Das",
          first_name: "Santi",
          last_name: "Das",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "2603c609-8050-454a-8fe9-7ae910a52035",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bka-elena-los-cines-perifericos-1999",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "Barcelona",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "PB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000097",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "Spain",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "1999",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "1999",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "84-49307104",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "spanish ",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "Paidos",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "1999",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "Los Cines Perifericos",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "Africa, Oriente Medio, India",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        "https://tuliresearchcentre.canto.global/direct/image/ecup88f81951hfb6sbbqns1040/moPs-UTn_maGD7TzBF8kN-sbFUw/m800/800",
      display: "Image",
    },
    {
      key: "document_type",
      value: "bka",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Author",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "alberto-elena",
          value: "Alberto-Elena",
          first_name: "Alberto",
          last_name: "Elena",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "aa2df08c-2e80-466c-aba5-383465a6e035",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bka-ghosh-manikda-2000",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "Kolkata",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "PB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000034",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "India",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "2000",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "2000",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "81877570010",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "french",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "Bingsha Shatabdi",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "2000",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "Manikda",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "Memoire sur Satyajit Ray",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        "https://tuliresearchcentre.canto.global/direct/image/gk79bl2n154ff5ngmvk62uqp7n/9ibPJZu-OXKZ7p3xOov345ToQaM/m800/800",
      display: "Image",
    },
    {
      key: "document_type",
      value: "bka",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Author",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "nemai-ghosh",
          value: "Nemai Ghosh",
          first_name: "Nemai",
          last_name: "Ghosh",
        },
        {
          key: "nemai-ghosh",
          value: "Nemai-Ghosh",
          first_name: "Nemai",
          last_name: "Ghosh",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "363ae2aa-4328-46c3-997e-1967273b07ed",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bka-cooper-the-cinema-of-satyajit-ray-2000",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "Cambridge",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "PB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000062",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "UK",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "2000",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "2000",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "0-521629802",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "english",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "Cambridge University",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "2000",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "The Cinema of Satyajit Ray",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "Between Tradition and Modernity",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        "https://tuliresearchcentre.canto.global/direct/image/6g871mhtid68vfb6tdjri9vc6o/omjs26yZrqF7oHOBQVxe_idNspc/m800/800",
      display: "Image",
    },
    {
      key: "document_type",
      value: "bka",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Author",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
        {
          key: "History & Historiography of Indian Scholarship in the Arts, Social Sciences & Humanities",
          value: "Scholarship",
          link: "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "darius-cooper",
          value: "Darius-Cooper",
          first_name: "Darius",
          last_name: "Cooper",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "b88efc75-cc13-4a7d-b97c-fb7cab8e2116",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bka-thoraval-the-cinemas-of-india\n1896-2000-2000",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "New Delhi",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "HB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000094",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "India",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "2000",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "2000",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "0333934105",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "english",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "Macmillan India",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "2000",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "The Cinemas of India:\n1896-2000",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        "https://tuliresearchcentre.canto.global/direct/image/h3t7tpae1d16329tpg0opuke1l/h8y8OlZTDl1UjPhqvVoVVAKip_Q/m800/800",
      display: "Image",
    },
    {
      key: "document_type",
      value: "bka",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Author",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "India and her Relationship with the World",
          value: "IndoWorld",
          link: "india-and-her-relationship-with-the-world",
        },
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "yves-thoraval",
          value: "Yves-Thoraval",
          first_name: "Yves",
          last_name: "Thoraval",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "6d3a9c9c-a4dc-4be4-92e9-9ea6e2ece4c6",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bka-gupta-the-cinema-of-satyajit-ray-2001",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "New Delhi",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "HB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000009.DI",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "India",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "1994",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "1994",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "81-23707533",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "english",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "National Book Trust",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "2001",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "The Cinema of Satyajit Ray",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        "https://tuliresearchcentre.canto.global/direct/image/ufdh66f5b152tfq349m6as6o6s/5dFTlSnkHovZD2ZrYTX3Gaiey_U/m800/800",
      display: "Image",
    },
    {
      key: "document_type",
      value: "bka",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Author",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
        {
          key: "History & Historiography of Indian Scholarship in the Arts, Social Sciences & Humanities",
          value: "Scholarship",
          link: "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "chidananda-das-gupta",
          value: "chidananda das gupta",
          first_name: "Chidananda",
          last_name: "DasGupta",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "8d3dd4a1-61d7-42d2-835e-cc6d921e0788",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bka-vallachira-asian-cinema-2001",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "Thrissur",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "PB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000031",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "India",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "2001",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "2001",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "malayalam",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "Dhrisyasahithi",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "2001",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "Asian Cinema",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "A New Perspective",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        "https://tuliresearchcentre.canto.global/direct/image/lp40tc6c3951h6hh7rbopbft06/VfZ75t0bvomPx0reeaJ0XlyZQRE/m800/800",
      display: "Image",
    },
    {
      key: "document_type",
      value: "bka",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Author",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "aravindan-vallachira",
          value: "Aravindan-Vallachira",
          first_name: "Aravindan",
          last_name: "Vallachira",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "bb92388c-5a76-4d61-baf5-0c1ad348ce10",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bka-ganguly-satyajit-ray-2001",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "New Delhi",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "PB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000042",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "India",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "2001",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "2001",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "81-87981040",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "english",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "Indialog",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "2001",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "Satyajit Ray",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "In Search of the Modern",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        "https://tuliresearchcentre.canto.global/direct/image/4aupoppujt13rf8tu3jl8k8f04/AwUN7ByPGSam2uBjpi675mGDcyg/m800/800",
      display: "Image",
    },
    {
      key: "document_type",
      value: "bka",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Author",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "History & Historiography of Indian Scholarship in the Arts, Social Sciences & Humanities",
          value: "Scholarship",
          link: "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities",
        },
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "suranjan-ganguly",
          value: "Suranjan-Ganguly",
          first_name: "Suranjan",
          last_name: "Ganguly",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "87a27741-6e31-4dce-8b59-927084401205",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bke-dasgupta-ray-2001",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "Kolkata",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "HB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000029",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "India",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "2001",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "2001",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "english-french",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "Kurchi Dasgupta",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "2001",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "Ray",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "A Glimpse",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        "https://tuliresearchcentre.canto.global/direct/image/52164hlcjp4cv1qulg8up9502v/F_tR1W0oo1fMjqrhNN2F5qb18g8/m800/800",
      display: "Image",
    },
    {
      key: "document_type",
      value: "bke",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Publisher",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
        {
          key: "History & Historiography of Indian Scholarship in the Arts, Social Sciences & Humanities",
          value: "Scholarship",
          link: "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "kurchi-dasgupta",
          value: "Kurchi-Dasgupta",
          first_name: "Kurchi",
          last_name: "Dasgupta",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "1b8ae6f7-9ac6-41ed-be60-1be9fa79bc76",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bka-vasudev-being-becoming-2002",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "New Delhi",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "HB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000081",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "India",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "2002",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "2002",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "0333938208",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "english",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "Macmillan India",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "2002",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "Being & Becoming",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "The Cinemas of Asia",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        "https://tuliresearchcentre.canto.global/direct/image/iejinh906537dcn50l6albv66b/AFDo4TaF3mF73NIcdoAiqq4x4PA/m800/800",
      display: "Image",
    },
    {
      key: "document_type",
      value: "bka",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Author",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "India and her Relationship with the World",
          value: "IndoWorld",
          link: "india-and-her-relationship-with-the-world",
        },
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "aruna-vasudev",
          value: "Aruna-Vasudev",
          first_name: "Aruna",
          last_name: "Vasudev",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "5ff23798-7d51-46e5-b096-99137ccdebef",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bke-majumdar-raytrospective-2001-2002",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "New Delhi",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "PB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000033",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "India",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "2002-07-01",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "2002",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "english-bengali-hindi",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "Indrani Majumdar",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "2002",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "RayTrospective 2001",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "A Celebration",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        "https://tuliresearchcentre.canto.global/direct/image/dms2lu81v14fbccc6sovsp3l38/Is2eVy5oB_P1YpDBYkoYlEZiGw8/m800/800",
      display: "Image",
    },
    {
      key: "document_type",
      value: "bke",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Compiler",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "History & Historiography of Indian Scholarship in the Arts, Social Sciences & Humanities",
          value: "Scholarship",
          link: "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities",
        },
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "indrani-majumdar",
          value: "Indrani-Majumdar",
          first_name: "Indrani",
          last_name: "Majumdar",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "30532fb2-bc08-4ab5-81f5-cf3d5aafc96e",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bka-ghosh-satyajit-ray-2003",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "New Delhi",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000084",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "India",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "2003",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "2003",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "english ",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "National Gallery of Modern Art",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "2003",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "Satyajit Ray",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "From Script to Screen ",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        "https://tuliresearchcentre.canto.global/direct/image/37m5q6ruip1bfb3it7jv6sgl30/F3hJedI2_BC93q5GLMzFT3GO_UU/m800/800",
      display: "Image",
    },
    {
      key: "document_type",
      value: "bka",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Photographer",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
        {
          key: "Photography in India",
          value: "Photo",
          link: "photography-in-india",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "nemai-ghosh",
          value: "Nemai-Ghosh",
          first_name: "Nemai",
          last_name: "Ghosh",
        },
        {
          key: "nemai-ghosh",
          value: "Nemai Ghosh",
          first_name: "Nemai",
          last_name: "Ghosh",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "4f76c726-22a4-440a-bb9e-1bc09ac3f603",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bke-robinson-satyajit-ray-2005",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "London",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "HB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000036",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "UK",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "2005",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "2005",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "1845110749",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "english",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "I B Tauris",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "2005",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "Satyajit Ray",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "A Vision of Cinema",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        "https://tuliresearchcentre.canto.global/direct/image/egpjgdchdp20p4h6skf9aq982g/BJKO1SueFEcBR0oef6J0QLtVcyg/m800/800",
      display: "Image",
    },
    {
      key: "document_type",
      value: "bke",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Editor",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "History & Historiography of Indian Scholarship in the Arts, Social Sciences & Humanities",
          value: "Scholarship",
          link: "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities",
        },
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
        {
          key: "Popular Arts, Printed Image & Crafts of India",
          value: "PopCult",
          link: "popular-arts-printed-image-crafts-of-india",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "andrew-robinson",
          value: "Andrew-Robinson",
          first_name: "Andrew",
          last_name: "Robinson",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "c6288985-0a29-42cd-9236-e6d9764fd0c1",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bka-roberge-satyajit-ray-2007",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "New Delhi",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000038",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "India",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "2007",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "2007",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "81-73047359",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "english",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "Manohar",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "2007",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "Satyajit Ray",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        "https://tuliresearchcentre.canto.global/direct/image/invae7aiip6elb68p8tccces3r/06bEoKwVczWLYDTPIOIGPzpu5RM/m800/800",
      display: "Image",
    },
    {
      key: "document_type",
      value: "bka",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Author",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "gaston-roberge",
          value: "Gaston-Roberge",
          first_name: "Gaston",
          last_name: "Roberge",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "3dd456f9-a099-4d54-bfe6-8573744c70f2",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bka-bahadur-a-textual-study-of-the-apu-trilogy-2011",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "New Delhi",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "HB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000070",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "India",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "2011",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "2011",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "978-9350004289",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "english",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "Vani",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "2011",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "A Textual Study of the Apu Trilogy",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        "https://tuliresearchcentre.canto.global/direct/image/bboako27dl5qt38cbj9phr3c62/scn5ShxbspIWo1kf-eUQ5a9yugk/m800/800",
      display: "Image",
    },
    {
      key: "document_type",
      value: "bka",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Author",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
        {
          key: "History & Historiography of Indian Scholarship in the Arts, Social Sciences & Humanities",
          value: "Scholarship",
          link: "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "satish-bahadur",
          value: "Satish-Bahadur",
          first_name: "Satish",
          last_name: "Bahadur",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "aa1bcd2a-3103-4a46-a5a4-dc25e8ef3a0c",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bka-sen-looking-beyond-2012",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "New Delhi",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "PB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000040",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "India",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "2012",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "2012",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "978-8174365651",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "english",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "Roli Books",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "2012",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "Looking Beyond",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "Graphics of Satyajit Ray",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        "https://tuliresearchcentre.canto.global/direct/image/ght4l47dvd1jt7fi2pigju2s6e/mCNVf8TsVW4WPTjOyYXwTKWREw0/m800/800",
      display: "Image",
    },
    {
      key: "document_type",
      value: "bka",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Author",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "Popular Arts, Printed Image & Crafts of India",
          value: "PopCult",
          link: "popular-arts-printed-image-crafts-of-india",
        },
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "jayanti-sen",
          value: "Jayanti-Sen",
          first_name: "Jayanti",
          last_name: "Sen",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "e81a9579-f2ef-4dbd-a43c-2f62785e5d28",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bka-ghosh-satyajit-ray-and-beyond-2013",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "New Delhi",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "HB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000032",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "India",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "2013",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "2013",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "978-9381217313",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "english",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "Delhi Art Gallery",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "2013",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "Satyajit Ray and Beyond",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        "https://tuliresearchcentre.canto.global/direct/image/nasua0toht6e1ci7ivg3rkpj3g/WEoFWKCL41BnCculov5xFxDfGYQ/m800/800",
      display: "Image",
    },
    {
      key: "document_type",
      value: "bka",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Author",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
        {
          key: "Photography in India",
          value: "Photo",
          link: "photography-in-india",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "nemai-ghosh",
          value: "Nemai Ghosh",
          first_name: "Nemai",
          last_name: "Ghosh",
        },
        {
          key: "nemai-ghosh",
          value: "Nemai-Ghosh",
          first_name: "Nemai",
          last_name: "Ghosh",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "ad07a953-fa00-4b5d-91c2-66794da6dcd1",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bke-ray-satyajit-rays-ravi-shankar-2014",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "Noida",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "HB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000044",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "India",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "2014",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "2014",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "978-9351361749",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "english",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value:
        "Collins in association with the Society for the Preservation of Satyajit Ray Archives",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "2014",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "Satyajit Ray's Ravi Shankar",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "An Unfilimed Visual Script",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        "https://tuliresearchcentre.canto.global/direct/image/f78n060ej57gn41lb3s9bck44l/aS82ufFCMmh_rI3gd1418JVbkno/m800/800",
      display: "Image",
    },
    {
      key: "document_type",
      value: "bke",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Editor",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
        {
          key: "History & Historiography of Indian Scholarship in the Arts, Social Sciences & Humanities",
          value: "Scholarship",
          link: "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities",
        },
        {
          key: "Modern & Contemporary Fine Arts in India",
          value: "FineArts",
          link: "modern-contemporary-fine-arts-in-india",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "sandip-ray",
          value: "Sandip Ray",
          first_name: "Sandip",
          last_name: "Ray",
        },
        {
          key: "sandip-ray",
          value: "Sandip-Ray",
          first_name: "Sandip",
          last_name: "Ray",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "3cc5cd89-eea8-4a3c-b742-c7afceaf819b",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bka-cherian-indias-film-society-movement-2017",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "New Delhi",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "HB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000037",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "India",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "2017",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "2017",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "978-9385985638",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "english",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "Sage",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "2017",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "India's Film Society Movement",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "The Journey and its Impact",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        "https://tuliresearchcentre.canto.global/direct/image/d4ib3ctkh9619e00hd6pu9sd10/aR0Ou22ieFX_ltrBnXgRzjP0UjE/m800/800",
      display: "Image",
    },
    {
      key: "document_type",
      value: "bka",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Author",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
        {
          key: "Social Responsibility of the Creative Mind ",
          value: "SocialR",
          link: "social-responsibility-of-the-creative-mind",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "v-k-cherian",
          value: "V-K-Cherian",
          first_name: "V-K",
          last_name: "Cherian",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "052349d1-7462-493a-8fd9-b14698c69fbe",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bka-jindal-my-adventures-with-satyajit-ray-2017",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "Gurugram",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "PB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000039",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "India",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "2017",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "2017",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "978-9352771025",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "english",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "HarperCollins",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "2017",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "My Adventures with Satyajit Ray",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "The Making of Shatranj Ke Khilari",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        "https://tuliresearchcentre.canto.global/direct/image/q67095kdnp5fp2r8d2c02rtu53/4EmN-s3VNr8sxXJJ9tvEH8xwGnQ/m800/800",
      display: "Image",
    },
    {
      key: "document_type",
      value: "bka",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Author",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
        {
          key: "Economics of Art, Cinema & Culture",
          value: "EconArts",
          link: "economics-of-art-cinema-culture",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "suresh-jindal",
          value: "Suresh Jindal",
          first_name: "Suresh",
          last_name: "Jindal",
        },
      ],
      display: "Creator",
    },
    {
      key: "drawer_link",
      value: true,
    },
  ],
  [
    {
      key: "id",
      value: "c2720f1c-a0af-4a71-a10c-0a45cf5b563d",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bke-ray-travails-with-the-alien-2018",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "Noida",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "PB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000048",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "India",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "2018",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "2018",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "978-9352779154",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "english",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "Harper Collins",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "2018",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "Travails With the Alien",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value:
        "The Film That was Never Made and Other Adventures with Science Fiction : Satyajit Ray",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        "https://tuliresearchcentre.canto.global/direct/image/d8s5l1is5h2lr8t5d3gvh8o766/kD27cH5am2JMgN8suQ_zMU74In8/m800/800",
      display: "Image",
    },
    {
      key: "document_type",
      value: "bke",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Editor",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "History & Historiography of Indian Scholarship in the Arts, Social Sciences & Humanities",
          value: "Scholarship",
          link: "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities",
        },
        {
          key: "Uncertainty Revealing Itself : Death | Magic | Mystery | Sci-Fi | Horror | Gambling  | A.I.",
          value: "Uncertainty",
          link: "uncertainy-revealing-itself-death-magic-mystery-sci-fi-horror-gambling-ai",
        },
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "sandip-ray",
          value: "Sandip Ray",
          first_name: "Sandip",
          last_name: "Ray",
        },
        {
          key: "sandip-ray",
          value: "Sandip-Ray",
          first_name: "Sandip",
          last_name: "Ray",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "f1634c3f-6c2e-4c9f-b79e-987efc98ac92",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bke-devasundaram-indian-cinema-beyond-bollywood-2018",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "New York",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "HB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000079",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "USA",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "2018",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "2018",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "978-0815368601",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "english",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "Routledge",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "2018",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "Indian Cinema Beyond Bollywood",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "The New Indepenedent Cinema Revolution",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        "https://tuliresearchcentre.canto.global/direct/image/n2bf4e2u5t1t1f0nh1uc8hjh14/RCTgPLTg1pjtSdSk7HZrfVH4DEs/m800/800",
      display: "Image",
    },
    {
      key: "document_type",
      value: "bke",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Editor",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "History & Historiography of Indian Scholarship in the Arts, Social Sciences & Humanities",
          value: "Scholarship",
          link: "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities",
        },
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "ashvin-immanuel-devasundaram",
          value: "Ashvin-Immanuel-Devasundaram",
          first_name: "Ashvin-Immanuel",
          last_name: "Devasundaram",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "b8e87b89-94d6-4d17-a265-5ea4afd147fc",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bka-sengupta-soumitra-chatterjee-2020",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "New Delhi",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "HB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000077",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "India",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "2020",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "2020",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "978-9389136760",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "english",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "Niyogi Books",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "2020",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "Soumitra Chatterjee",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "A Life in Cinema, Theatre, Poetry & Painting",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        "https://tuliresearchcentre.canto.global/direct/image/57sfokvt252u347ce80cr3le7r/bhQV6urIurKCOfD281oI8mL8oOs/m800/800",
      display: "Image",
    },
    {
      key: "document_type",
      value: "bka",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Author",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "arjun-sengupta",
          value: "Arjun-Sengupta",
          first_name: "Arjun",
          last_name: "Sengupta",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "fce0bb59-603f-4c01-9565-c040e5bdbaea",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bke-saklani-iti-satyajit-da-2022",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "New Delhi",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "HB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000041",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "India",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "2022",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "2022",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "978-8185983325",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "english",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "Musui Art Foundation",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "2022",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "Iti, Satyajit Da",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "Letters to a Friend from Satyajit Ray",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        "https://tuliresearchcentre.canto.global/direct/image/q7cs9t0b6t22lasel75o4bje4h/Q38Rdxvzm3uNtyf6xkP0ejbu8HI/m800/800",
      display: "Image",
    },
    {
      key: "document_type",
      value: "bke",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Editor",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
        {
          key: "History & Historiography of Indian Scholarship in the Arts, Social Sciences & Humanities",
          value: "Scholarship",
          link: "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "juhi-saklani",
          value: "Juhi-Saklani",
          first_name: "Juhi",
          last_name: "Saklani",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "0c671511-e9e6-41cc-9d08-9f2093bbfeaf",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bke-ray-satyajit-ray-2022",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "Gurugram",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "PB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000046",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "India",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "2022",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "2022",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "9780143448990",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "english",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "Penguin Random House India",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "2022",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "Satyajit Ray",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "Miscellany on Life, Cinema, People & Much More",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        "https://tuliresearchcentre.canto.global/direct/image/3ldj1re1ll2khdaqno856dph2l/Jw5CQvtfuIZtyBni-37fNh-WBDM/m800/800",
      display: "Image",
    },
    {
      key: "document_type",
      value: "bke",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Editor",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
        {
          key: "History & Historiography of Indian Scholarship in the Arts, Social Sciences & Humanities",
          value: "Scholarship",
          link: "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "sandip-ray",
          value: "Sandip-Ray",
          first_name: "Sandip",
          last_name: "Ray",
        },
        {
          key: "sandip-ray",
          value: "Sandip Ray",
          first_name: "Sandip",
          last_name: "Ray",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "a52ec4cc-7761-4617-9cf8-e07a1eee597e",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bke-majumdar-a-companion-to-indian-cinema-2022",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "New Jursey",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "HB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000069",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "USA",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "2022",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "2022",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "978-1119048268",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "english",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "John Wiley",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "2022",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "A Companion to Indian Cinema",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        "https://tuliresearchcentre.canto.global/direct/image/tc3osd8tbd2p9967efchufk71i/9dBwRpP4g_UUBMZu1m6solHpkxM/m800/800",
      display: "Image",
    },
    {
      key: "document_type",
      value: "bke",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Editor",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "History & Historiography of Indian Scholarship in the Arts, Social Sciences & Humanities",
          value: "Scholarship",
          link: "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities",
        },
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "neepa-majumdar",
          value: "Neepa-Majumdar",
          first_name: "Neepa",
          last_name: "Majumdar",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "c8ceaf6f-d31d-4dbd-8e6b-3920608aec8d",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bke-ray-the-best-of-satyajit-ray-2023",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "Gurgaon",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "PB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000047",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "India",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "2023",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "2023",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "978-0143459453",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "english",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "Penguin Random House India",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "2023",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "The Best of Satyajit Ray",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        "https://tuliresearchcentre.canto.global/direct/image/ertljmokad6llf6he465qdau4h/7elzYyy6jUJ8IVGffKimhbKNIJY/m800/800",
      display: "Image",
    },
    {
      key: "document_type",
      value: "bke",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Compiler",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "History & Historiography of Indian Scholarship in the Arts, Social Sciences & Humanities",
          value: "Scholarship",
          link: "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities",
        },
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "sandip-ray",
          value: "Sandip-Ray",
          first_name: "Sandip",
          last_name: "Ray",
        },
        {
          key: "sandip-ray",
          value: "Sandip Ray",
          first_name: "Sandip",
          last_name: "Ray",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "634b7972-e27b-42ae-ba4a-3807fa860124",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bka-tiwari-shatranj-ke-khilari-nd",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "Bhopal",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000066",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "India",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: "",
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "hindi ",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "Madhya Pradesh Film Vikash Nigam",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: "n.d.",
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "Shatranj Ke Khilari",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "shatranj-ke-khilari-1977",
      display: "Masterlist",
    },
    {
      key: "image",
      value:
        "https://tuliresearchcentre.canto.global/direct/image/69qcpkr3rh5q19vkeu9k48ci0c/MdqBl9t6ZdIncuscROu02mIfBUc/m800/800",
      display: "Image",
    },
    {
      key: "document_type",
      value: "bka",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "Author",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "Cinema as a Key Educational Resource",
          value: "CinemaE",
          link: "cinema-as-a-key-educational-resource",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "surendra-tiwari",
          value: "Surendra-Tiwari",
          first_name: "Surendra",
          last_name: "Tiwari",
        },
      ],
      display: "Creator",
    },
  ],
];

//photography in India Biblography
export const photography_in_india_research_centre = [
    [
        {
          key: "id",
          value: "5d333dd1-bas62-457b-ada9-b26391065032",
          display: "Id",
        },
        {
          key: "title",
          value: null,
          display: "Title",
        },
        {
          key: "alias",
          value: "bka-bahadur-satyajit-rays-pather-panchali-1981",
          display: "Alias",
        },
        {
          key: "web_serial_number",
          value: null,
          display: "Web serial number",
        },
        {
          key: "reference_code",
          value: null,
          display: "Reference code",
        },
        {
          key: "web_display",
          value: null,
          display: "Web display",
        },
        {
          key: "date_of_library_publication",
          value: null,
          display: "Date of library publication",
        },
        {
          key: "place_of_publication",
          value: "London",
          display: "Place of publication",
        },
        {
          key: "histographical_note",
          value: null,
          display: "Histographical note",
        },
        {
          key: "main_historical_note",
          value: null,
          display: "Main historical note",
        },
        {
          key: "binding_status",
          value: "PB",
          display: "Binding status",
        },
        {
          key: "accession_number",
          value: null,
          display: "Accession number",
        },
        {
          key: "cdt",
          value: null,
          display: "CDT",
        },
        {
          key: "country_of_publication",
          value: "UK",
          display: "Country of publication",
        },
        {
          key: "date_of_published_edition_in_library",
          value: "1956",
          display: "Date of published edition in library",
        },
        {
          key: "dates_of_first_edition",
          value: "1956",
          display: "Dates of first edition",
        },
        {
          key: "isbn_issn_number",
          value: "",
          display: "Isbn issn number",
        },
        {
          key: "language_of_publication",
          value: "english",
          display: "Language of publication",
        },
        {
          key: "number_of_pages_in_publication_text",
          value: null,
          display: "Number of pages in publication text",
        },
        {
          key: "personality_film_visually_featured_on_cover",
          value: null,
          display: "Personality film visually featured on cover",
        },
        {
          key: "publisher",
          value: "Thames & Hudson",
          display: "Publisher",
        },
        {
          key: "serial_number",
          value: null,
          display: "Serial number",
        },
        {
          key: "sortable_year_date_of_publication",
          value: "1956",
          display: "Sortable year date of publication",
        },
        {
          key: "sub_title_of_document_book",
          value: null,
          display: "Sub title of document book",
        },
        {
          key: "title_of_document_book",
          value: null,
          display: "Title of document book",
        },
        {
          key: "book_title_of_document",
          value: "China in Transition",
          display: "Book title of document",
        },
        {
          key: "book_sub_title_of_document",
          value: "A Moment in History.",
          display: "Book sub title of document",
        },
        {
          key: "volume_issue_number",
          value: null,
          display: "Volume issue number",
        },
        {
          key: "masterlist",
          value: "photography-in-india",
          display: "Masterlist",
        },
        {
          key: "image",
          value:[PhotoIndiaRC1],
          display: "Image",
        },
        {
          key: "document_type",
          value: "bke",
          display: "Document type",
        },
        {
          key: "title_and_type_of_creator",
          value: "Photographer",
          display: "Title and type of creator",
        },
        {
          key: "number_of_pages_in_publication",
          value: null,
          display: "Number of pages in publication",
        },
        {
          key: "researchCategories",
          value: [
            {
              key: "Cinema as a Key Educational Resource",
              value: "Photography",
              link: "cinema-as-a-key-educational-resource",
            },
          ],
          display: "Research categories",
        },
        {
          key: "creator",
          value: [
            {
              key: "cartier-bresson",
              value: "Cartier-Bresson",
              first_name: "Cartier-Bresson",
              last_name: "Henri",
            },
          ],
          display: "Creator",
        },
        {
            key: "drawer_link",
            value: true,
          },
      ],
      [
        {
          key: "id",
          value: "5d333dd1-bas62-457b-ada9-b26391065032",
          display: "Id",
        },
        {
          key: "title",
          value: null,
          display: "Title",
        },
        {
          key: "alias",
          value: "bka-bahadur-satyajit-rays-pather-panchali-1981",
          display: "Alias",
        },
        {
          key: "web_serial_number",
          value: null,
          display: "Web serial number",
        },
        {
          key: "reference_code",
          value: null,
          display: "Reference code",
        },
        {
          key: "web_display",
          value: null,
          display: "Web display",
        },
        {
          key: "date_of_library_publication",
          value: null,
          display: "Date of library publication",
        },
        {
          key: "place_of_publication",
          value: "San Fraancisco",
          display: "Place of publication",
        },
        {
          key: "histographical_note",
          value: null,
          display: "Histographical note",
        },
        {
          key: "main_historical_note",
          value: null,
          display: "Main historical note",
        },
        {
          key: "binding_status",
          value: "PB",
          display: "Binding status",
        },
        {
          key: "accession_number",
          value: null,
          display: "Accession number",
        },
        {
          key: "cdt",
          value: null,
          display: "CDT",
        },
        {
          key: "country_of_publication",
          value: "UK",
          display: "Country of publication",
        },
        {
          key: "date_of_published_edition_in_library",
          value: "1975",
          display: "Date of published edition in library",
        },
        {
          key: "dates_of_first_edition",
          value: "1975",
          display: "Dates of first edition",
        },
        {
          key: "isbn_issn_number",
          value: "",
          display: "Isbn issn number",
        },
        {
          key: "language_of_publication",
          value: "english",
          display: "Language of publication",
        },
        {
          key: "number_of_pages_in_publication_text",
          value: null,
          display: "Number of pages in publication text",
        },
        {
          key: "personality_film_visually_featured_on_cover",
          value: null,
          display: "Personality film visually featured on cover",
        },
        {
          key: "publisher",
          value: "James Wills Gallery",
          display: "Publisher",
        },
        {
          key: "serial_number",
          value: null,
          display: "Serial number",
        },
        {
          key: "sortable_year_date_of_publication",
          value: "1975",
          display: "Sortable year date of publication",
        },
        {
          key: "sub_title_of_document_book",
          value: null,
          display: "Sub title of document book",
        },
        {
          key: "title_of_document_book",
          value: null,
          display: "Title of document book",
        },
        {
          key: "book_title_of_document",
          value: "An Exhibition of African Tribal Masks",
          display: "Book title of document",
        },
        {
          key: "book_sub_title_of_document",
          value: "",
          display: "Book sub title of document",
        },
        {
          key: "volume_issue_number",
          value: null,
          display: "Volume issue number",
        },
        {
          key: "masterlist",
          value: "photography-in-india",
          display: "Masterlist",
        },
        {
          key: "image",
          value:[PhotoIndiaRC2],
          display: "Image",
        },
        {
          key: "document_type",
          value: "bke",
          display: "Document type",
        },
        {
          key: "title_and_type_of_creator",
          value: "Photographer",
          display: "Title and type of creator",
        },
        {
          key: "number_of_pages_in_publication",
          value: null,
          display: "Number of pages in publication",
        },
        {
          key: "researchCategories",
          value: [
            {
              key: "Cinema as a Key Educational Resource",
              value: "Photography",
              link: "cinema-as-a-key-educational-resource",
            },
          ],
          display: "Research categories",
        },
        {
          key: "creator",
          value: [
            {
              key: "Bernhard-Ruth",
              value: "Bernhard-Ruth",
              first_name: "Ruth",
              last_name: "Bernhard",
            },
          ],
          display: "Creator",
        },
      ],
      [
        {
          key: "id",
          value: "5d333dd1-bas62-457b-ada9-b26391065032",
          display: "Id",
        },
        {
          key: "title",
          value: null,
          display: "Title",
        },
        {
          key: "alias",
          value: "bka-bahadur-satyajit-rays-pather-panchali-1981",
          display: "Alias",
        },
        {
          key: "web_serial_number",
          value: null,
          display: "Web serial number",
        },
        {
          key: "reference_code",
          value: null,
          display: "Reference code",
        },
        {
          key: "web_display",
          value: null,
          display: "Web display",
        },
        {
          key: "date_of_library_publication",
          value: null,
          display: "Date of library publication",
        },
        {
          key: "place_of_publication",
          value: "Boston",
          display: "Place of publication",
        },
        {
          key: "histographical_note",
          value: null,
          display: "Histographical note",
        },
        {
          key: "main_historical_note",
          value: null,
          display: "Main historical note",
        },
        {
          key: "binding_status",
          value: "PB",
          display: "Binding status",
        },
        {
          key: "accession_number",
          value: null,
          display: "Accession number",
        },
        {
          key: "cdt",
          value: null,
          display: "CDT",
        },
        {
          key: "country_of_publication",
          value: "UK",
          display: "Country of publication",
        },
        {
          key: "date_of_published_edition_in_library",
          value: "1976",
          display: "Date of published edition in library",
        },
        {
          key: "dates_of_first_edition",
          value: "1976",
          display: "Dates of first edition",
        },
        {
          key: "isbn_issn_number",
          value: "",
          display: "Isbn issn number",
        },
        {
          key: "language_of_publication",
          value: "english",
          display: "Language of publication",
        },
        {
          key: "number_of_pages_in_publication_text",
          value: null,
          display: "Number of pages in publication text",
        },
        {
          key: "personality_film_visually_featured_on_cover",
          value: null,
          display: "Personality film visually featured on cover",
        },
        {
          key: "publisher",
          value: "Pucker / Safrai Gallery",
          display: "Publisher",
        },
        {
          key: "serial_number",
          value: null,
          display: "Serial number",
        },
        {
          key: "sortable_year_date_of_publication",
          value: "1976",
          display: "Sortable year date of publication",
        },
        {
          key: "sub_title_of_document_book",
          value: null,
          display: "Sub title of document book",
        },
        {
          key: "title_of_document_book",
          value: null,
          display: "Title of document book",
        },
        {
          key: "book_title_of_document",
          value: "Masks of West Africa",
          display: "Book title of document",
        },
        {
          key: "book_sub_title_of_document",
          value: "",
          display: "Book sub title of document",
        },
        {
          key: "volume_issue_number",
          value: null,
          display: "Volume issue number",
        },
        {
          key: "masterlist",
          value: "photography-in-india",
          display: "Masterlist",
        },
        {
          key: "image",
          value:[PhotoIndiaRC3],
          display: "Image",
        },
        {
          key: "document_type",
          value: "bke",
          display: "Document type",
        },
        {
          key: "title_and_type_of_creator",
          value: "Photographer",
          display: "Title and type of creator",
        },
        {
          key: "number_of_pages_in_publication",
          value: null,
          display: "Number of pages in publication",
        },
        {
          key: "researchCategories",
          value: [
            {
              key: "Cinema as a Key Educational Resource",
              value: "Photography",
              link: "cinema-as-a-key-educational-resource",
            },
          ],
          display: "Research categories",
        },
        {
          key: "creator",
          value: [
            {
              key: "Burstein-Barney",
              value: "Burstein-Barney",
              first_name: "Barney",
              last_name: "Burstein",
            },
          ],
          display: "Creator",
        },
      ],
      [
        {
          key: "id",
          value: "5d333dd1-bas62-457b-ada9-b26391065032",
          display: "Id",
        },
        {
          key: "title",
          value: null,
          display: "Title",
        },
        {
          key: "alias",
          value: "bka-bahadur-satyajit-rays-pather-panchali-1981",
          display: "Alias",
        },
        {
          key: "web_serial_number",
          value: null,
          display: "Web serial number",
        },
        {
          key: "reference_code",
          value: null,
          display: "Reference code",
        },
        {
          key: "web_display",
          value: null,
          display: "Web display",
        },
        {
          key: "date_of_library_publication",
          value: null,
          display: "Date of library publication",
        },
        {
          key: "place_of_publication",
          value: "New York",
          display: "Place of publication",
        },
        {
          key: "histographical_note",
          value: null,
          display: "Histographical note",
        },
        {
          key: "main_historical_note",
          value: null,
          display: "Main historical note",
        },
        {
          key: "binding_status",
          value: "PB",
          display: "Binding status",
        },
        {
          key: "accession_number",
          value: null,
          display: "Accession number",
        },
        {
          key: "cdt",
          value: null,
          display: "CDT",
        },
        {
          key: "country_of_publication",
          value: "UK",
          display: "Country of publication",
        },
        {
          key: "date_of_published_edition_in_library",
          value: "1985",
          display: "Date of published edition in library",
        },
        {
          key: "dates_of_first_edition",
          value: "1985",
          display: "Dates of first edition",
        },
        {
          key: "isbn_issn_number",
          value: "",
          display: "Isbn issn number",
        },
        {
          key: "language_of_publication",
          value: "english",
          display: "Language of publication",
        },
        {
          key: "number_of_pages_in_publication_text",
          value: null,
          display: "Number of pages in publication text",
        },
        {
          key: "personality_film_visually_featured_on_cover",
          value: null,
          display: "Personality film visually featured on cover",
        },
        {
          key: "publisher",
          value: "Museum of Modern Art",
          display: "Publisher",
        },
        {
          key: "serial_number",
          value: null,
          display: "Serial number",
        },
        {
          key: "sortable_year_date_of_publication",
          value: "1985",
          display: "Sortable year date of publication",
        },
        {
          key: "sub_title_of_document_book",
          value: null,
          display: "Sub title of document book",
        },
        {
          key: "title_of_document_book",
          value: null,
          display: "Title of document book",
        },
        {
          key: "book_title_of_document",
          value: "A Personal View",
          display: "Book title of document",
        },
        {
          key: "book_sub_title_of_document",
          value: "",
          display: "Book sub title of document",
        },
        {
          key: "volume_issue_number",
          value: null,
          display: "Volume issue number",
        },
        {
          key: "masterlist",
          value: "photography-in-india",
          display: "Masterlist",
        },
        {
          key: "image",
          value:[PhotoIndiaRC4],
          display: "Image",
        },
        {
          key: "document_type",
          value: "bke",
          display: "Document type",
        },
        {
          key: "title_and_type_of_creator",
          value: "Photographer",
          display: "Title and type of creator",
        },
        {
          key: "number_of_pages_in_publication",
          value: null,
          display: "Number of pages in publication",
        },
        {
          key: "researchCategories",
          value: [
            {
              key: "Cinema as a Key Educational Resource",
              value: "Photography",
              link: "cinema-as-a-key-educational-resource",
            },
          ],
          display: "Research categories",
        },
        {
          key: "creator",
          value: [
            {
              key: "Walter-Paul-F",
              value: "Walter-Paul-F",
              first_name: "Paul-F",
              last_name: "Walter",
            },
          ],
          display: "Creator",
        },
      ],
      [
        {
          key: "id",
          value: "5d333dd1-bas62-457b-ada9-b26391065032",
          display: "Id",
        },
        {
          key: "title",
          value: null,
          display: "Title",
        },
        {
          key: "alias",
          value: "bka-bahadur-satyajit-rays-pather-panchali-1981",
          display: "Alias",
        },
        {
          key: "web_serial_number",
          value: null,
          display: "Web serial number",
        },
        {
          key: "reference_code",
          value: null,
          display: "Reference code",
        },
        {
          key: "web_display",
          value: null,
          display: "Web display",
        },
        {
          key: "date_of_library_publication",
          value: null,
          display: "Date of library publication",
        },
        {
          key: "place_of_publication",
          value: "Netherlands",
          display: "Place of publication",
        },
        {
          key: "histographical_note",
          value: null,
          display: "Histographical note",
        },
        {
          key: "main_historical_note",
          value: null,
          display: "Main historical note",
        },
        {
          key: "binding_status",
          value: "PB",
          display: "Binding status",
        },
        {
          key: "accession_number",
          value: null,
          display: "Accession number",
        },
        {
          key: "cdt",
          value: null,
          display: "CDT",
        },
        {
          key: "country_of_publication",
          value: "UK",
          display: "Country of publication",
        },
        {
          key: "date_of_published_edition_in_library",
          value: "2002",
          display: "Date of published edition in library",
        },
        {
          key: "dates_of_first_edition",
          value: "2002",
          display: "Dates of first edition",
        },
        {
          key: "isbn_issn_number",
          value: "",
          display: "Isbn issn number",
        },
        {
          key: "language_of_publication",
          value: "english",
          display: "Language of publication",
        },
        {
          key: "number_of_pages_in_publication_text",
          value: null,
          display: "Number of pages in publication text",
        },
        {
          key: "personality_film_visually_featured_on_cover",
          value: null,
          display: "Personality film visually featured on cover",
        },
        {
          key: "publisher",
          value: "Greenpeace",
          display: "Publisher",
        },
        {
          key: "serial_number",
          value: null,
          display: "Serial number",
        },
        {
          key: "sortable_year_date_of_publication",
          value: "2002",
          display: "Sortable year date of publication",
        },
        {
          key: "sub_title_of_document_book",
          value: null,
          display: "Sub title of document book",
        },
        {
          key: "title_of_document_book",
          value: null,
          display: "Title of document book",
        },
        {
          key: "book_title_of_document",
          value: "Exposure",
          display: "Book title of document",
        },
        {
          key: "book_sub_title_of_document",
          value: "Portrait of a Corporate Crime",
          display: "Book sub title of document",
        },
        {
          key: "volume_issue_number",
          value: null,
          display: "Volume issue number",
        },
        {
          key: "masterlist",
          value: "photography-in-india",
          display: "Masterlist",
        },
        {
          key: "image",
          value:[PhotoIndiaRC5],
          display: "Image",
        },
        {
          key: "document_type",
          value: "bke",
          display: "Document type",
        },
        {
          key: "title_and_type_of_creator",
          value: "Photographer",
          display: "Title and type of creator",
        },
        {
          key: "number_of_pages_in_publication",
          value: null,
          display: "Number of pages in publication",
        },
        {
          key: "researchCategories",
          value: [
            {
              key: "Cinema as a Key Educational Resource",
              value: "Photography",
              link: "cinema-as-a-key-educational-resource",
            },
          ],
          display: "Research categories",
        },
        {
          key: "creator",
          value: [
            {
              key: "Raghu-Rai",
              value: "Raghu-Rai",
              first_name: "Raghu",
              last_name: "Rai",
            },
          ],
          display: "Creator",
        },
      ],
      [
        {
          key: "id",
          value: "5d333dd1-bas62-457b-ada9-b26391065032",
          display: "Id",
        },
        {
          key: "title",
          value: null,
          display: "Title",
        },
        {
          key: "alias",
          value: "bka-bahadur-satyajit-rays-pather-panchali-1981",
          display: "Alias",
        },
        {
          key: "web_serial_number",
          value: null,
          display: "Web serial number",
        },
        {
          key: "reference_code",
          value: null,
          display: "Reference code",
        },
        {
          key: "web_display",
          value: null,
          display: "Web display",
        },
        {
          key: "date_of_library_publication",
          value: null,
          display: "Date of library publication",
        },
        {
          key: "place_of_publication",
          value: "New Delhi",
          display: "Place of publication",
        },
        {
          key: "histographical_note",
          value: null,
          display: "Histographical note",
        },
        {
          key: "main_historical_note",
          value: null,
          display: "Main historical note",
        },
        {
          key: "binding_status",
          value: "PB",
          display: "Binding status",
        },
        {
          key: "accession_number",
          value: null,
          display: "Accession number",
        },
        {
          key: "cdt",
          value: null,
          display: "CDT",
        },
        {
          key: "country_of_publication",
          value: "UK",
          display: "Country of publication",
        },
        {
          key: "date_of_published_edition_in_library",
          value: "2004",
          display: "Date of published edition in library",
        },
        {
          key: "dates_of_first_edition",
          value: "2004",
          display: "Dates of first edition",
        },
        {
          key: "isbn_issn_number",
          value: "",
          display: "Isbn issn number",
        },
        {
          key: "language_of_publication",
          value: "english",
          display: "Language of publication",
        },
        {
          key: "number_of_pages_in_publication_text",
          value: null,
          display: "Number of pages in publication text",
        },
        {
          key: "personality_film_visually_featured_on_cover",
          value: null,
          display: "Personality film visually featured on cover",
        },
        {
          key: "publisher",
          value: "Timeless Books",
          display: "Publisher",
        },
        {
          key: "serial_number",
          value: null,
          display: "Serial number",
        },
        {
          key: "sortable_year_date_of_publication",
          value: "2004",
          display: "Sortable year date of publication",
        },
        {
          key: "sub_title_of_document_book",
          value: null,
          display: "Sub title of document book",
        },
        {
          key: "title_of_document_book",
          value: null,
          display: "Title of document book",
        },
        {
          key: "book_title_of_document",
          value: "Indira Gandhi",
          display: "Book title of document",
        },
        {
          key: "book_sub_title_of_document",
          value: "A Living Legacy",
          display: "Book sub title of document",
        },
        {
          key: "volume_issue_number",
          value: null,
          display: "Volume issue number",
        },
        {
          key: "masterlist",
          value: "photography-in-india",
          display: "Masterlist",
        },
        {
          key: "image",
          value:[PhotoIndiaRC6],
          display: "Image",
        },
        {
          key: "document_type",
          value: "bke",
          display: "Document type",
        },
        {
          key: "title_and_type_of_creator",
          value: "Photographer",
          display: "Title and type of creator",
        },
        {
          key: "number_of_pages_in_publication",
          value: null,
          display: "Number of pages in publication",
        },
        {
          key: "researchCategories",
          value: [
            {
              key: "Cinema as a Key Educational Resource",
              value: "Photography",
              link: "cinema-as-a-key-educational-resource",
            },
          ],
          display: "Research categories",
        },
        {
          key: "creator",
          value: [
            {
              key: "Raghu-Rai",
              value: "Raghu-Rai",
              first_name: "Raghu",
              last_name: "Rai",
            },
          ],
          display: "Creator",
        },
      ],
      [
        {
          key: "id",
          value: "5d333dd1-bas62-457b-ada9-b26391065032",
          display: "Id",
        },
        {
          key: "title",
          value: null,
          display: "Title",
        },
        {
          key: "alias",
          value: "bka-bahadur-satyajit-rays-pather-panchali-1981",
          display: "Alias",
        },
        {
          key: "web_serial_number",
          value: null,
          display: "Web serial number",
        },
        {
          key: "reference_code",
          value: null,
          display: "Reference code",
        },
        {
          key: "web_display",
          value: null,
          display: "Web display",
        },
        {
          key: "date_of_library_publication",
          value: null,
          display: "Date of library publication",
        },
        {
          key: "place_of_publication",
          value: "New Delhi",
          display: "Place of publication",
        },
        {
          key: "histographical_note",
          value: null,
          display: "Histographical note",
        },
        {
          key: "main_historical_note",
          value: null,
          display: "Main historical note",
        },
        {
          key: "binding_status",
          value: "PB",
          display: "Binding status",
        },
        {
          key: "accession_number",
          value: null,
          display: "Accession number",
        },
        {
          key: "cdt",
          value: null,
          display: "CDT",
        },
        {
          key: "country_of_publication",
          value: "UK",
          display: "Country of publication",
        },
        {
          key: "date_of_published_edition_in_library",
          value: "2007",
          display: "Date of published edition in library",
        },
        {
          key: "dates_of_first_edition",
          value: "2007",
          display: "Dates of first edition",
        },
        {
          key: "isbn_issn_number",
          value: "",
          display: "Isbn issn number",
        },
        {
          key: "language_of_publication",
          value: "english",
          display: "Language of publication",
        },
        {
          key: "number_of_pages_in_publication_text",
          value: null,
          display: "Number of pages in publication text",
        },
        {
          key: "personality_film_visually_featured_on_cover",
          value: null,
          display: "Personality film visually featured on cover",
        },
        {
          key: "publisher",
          value: "Tasveer",
          display: "Publisher",
        },
        {
          key: "serial_number",
          value: null,
          display: "Serial number",
        },
        {
          key: "sortable_year_date_of_publication",
          value: "2007",
          display: "Sortable year date of publication",
        },
        {
          key: "sub_title_of_document_book",
          value: null,
          display: "Sub title of document book",
        },
        {
          key: "title_of_document_book",
          value: null,
          display: "Title of document book",
        },
        {
          key: "book_title_of_document",
          value: "Just by The Way",
          display: "Book title of document",
        },
        {
          key: "book_sub_title_of_document",
          value: "Rock Clouds and Nudes",
          display: "Book sub title of document",
        },
        {
          key: "volume_issue_number",
          value: null,
          display: "Volume issue number",
        },
        {
          key: "masterlist",
          value: "photography-in-india",
          display: "Masterlist",
        },
        {
          key: "image",
          value:[PhotoIndiaRC7],
          display: "Image",
        },
        {
          key: "document_type",
          value: "bke",
          display: "Document type",
        },
        {
          key: "title_and_type_of_creator",
          value: "Photographer",
          display: "Title and type of creator",
        },
        {
          key: "number_of_pages_in_publication",
          value: null,
          display: "Number of pages in publication",
        },
        {
          key: "researchCategories",
          value: [
            {
              key: "Cinema as a Key Educational Resource",
              value: "Photography",
              link: "cinema-as-a-key-educational-resource",
            },
          ],
          display: "Research categories",
        },
        {
          key: "creator",
          value: [
            {
              key: "Raghu-Rai",
              value: "Raghu-Rai",
              first_name: "Raghu",
              last_name: "Rai",
            },
          ],
          display: "Creator",
        },
      ],
      [
        {
          key: "id",
          value: "5d333dd1-bas62-457b-ada9-b26391065032",
          display: "Id",
        },
        {
          key: "title",
          value: null,
          display: "Title",
        },
        {
          key: "alias",
          value: "bka-bahadur-satyajit-rays-pather-panchali-1981",
          display: "Alias",
        },
        {
          key: "web_serial_number",
          value: null,
          display: "Web serial number",
        },
        {
          key: "reference_code",
          value: null,
          display: "Reference code",
        },
        {
          key: "web_display",
          value: null,
          display: "Web display",
        },
        {
          key: "date_of_library_publication",
          value: null,
          display: "Date of library publication",
        },
        {
          key: "place_of_publication",
          value: "Noida",
          display: "Place of publication",
        },
        {
          key: "histographical_note",
          value: null,
          display: "Histographical note",
        },
        {
          key: "main_historical_note",
          value: null,
          display: "Main historical note",
        },
        {
          key: "binding_status",
          value: "PB",
          display: "Binding status",
        },
        {
          key: "accession_number",
          value: null,
          display: "Accession number",
        },
        {
          key: "cdt",
          value: null,
          display: "CDT",
        },
        {
          key: "country_of_publication",
          value: "UK",
          display: "Country of publication",
        },
        {
          key: "date_of_published_edition_in_library",
          value: "2010",
          display: "Date of published edition in library",
        },
        {
          key: "dates_of_first_edition",
          value: "2010",
          display: "Dates of first edition",
        },
        {
          key: "isbn_issn_number",
          value: "",
          display: "Isbn issn number",
        },
        {
          key: "language_of_publication",
          value: "english",
          display: "Language of publication",
        },
        {
          key: "number_of_pages_in_publication_text",
          value: null,
          display: "Number of pages in publication text",
        },
        {
          key: "personality_film_visually_featured_on_cover",
          value: null,
          display: "Personality film visually featured on cover",
        },
        {
          key: "publisher",
          value: "Om Books International",
          display: "Publisher",
        },
        {
          key: "serial_number",
          value: null,
          display: "Serial number",
        },
        {
          key: "sortable_year_date_of_publication",
          value: "2010",
          display: "Sortable year date of publication",
        },
        {
          key: "sub_title_of_document_book",
          value: null,
          display: "Sub title of document book",
        },
        {
          key: "title_of_document_book",
          value: null,
          display: "Title of document book",
        },
        {
          key: "book_title_of_document",
          value: "Bombay Mumbai",
          display: "Book title of document",
        },
        {
          key: "book_sub_title_of_document",
          value: "Where Dreams don't Die",
          display: "Book sub title of document",
        },
        {
          key: "volume_issue_number",
          value: null,
          display: "Volume issue number",
        },
        {
          key: "masterlist",
          value: "photography-in-india",
          display: "Masterlist",
        },
        {
          key: "image",
          value:[PhotoIndiaRC8],
          display: "Image",
        },
        {
          key: "document_type",
          value: "bke",
          display: "Document type",
        },
        {
          key: "title_and_type_of_creator",
          value: "Photographer",
          display: "Title and type of creator",
        },
        {
          key: "number_of_pages_in_publication",
          value: null,
          display: "Number of pages in publication",
        },
        {
          key: "researchCategories",
          value: [
            {
              key: "Cinema as a Key Educational Resource",
              value: "Photography",
              link: "cinema-as-a-key-educational-resource",
            },
          ],
          display: "Research categories",
        },
        {
          key: "creator",
          value: [
            {
              key: "Raghu-Rai",
              value: "Raghu-Rai",
              first_name: "Raghu",
              last_name: "Rai",
            },
          ],
          display: "Creator",
        },
      ],
      [
        {
          key: "id",
          value: "5d333dd1-bas62-457b-ada9-b26391065032",
          display: "Id",
        },
        {
          key: "title",
          value: null,
          display: "Title",
        },
        {
          key: "alias",
          value: "bka-bahadur-satyajit-rays-pather-panchali-1981",
          display: "Alias",
        },
        {
          key: "web_serial_number",
          value: null,
          display: "Web serial number",
        },
        {
          key: "reference_code",
          value: null,
          display: "Reference code",
        },
        {
          key: "web_display",
          value: null,
          display: "Web display",
        },
        {
          key: "date_of_library_publication",
          value: null,
          display: "Date of library publication",
        },
        {
          key: "place_of_publication",
          value: "New Delhi",
          display: "Place of publication",
        },
        {
          key: "histographical_note",
          value: null,
          display: "Histographical note",
        },
        {
          key: "main_historical_note",
          value: null,
          display: "Main historical note",
        },
        {
          key: "binding_status",
          value: "PB",
          display: "Binding status",
        },
        {
          key: "accession_number",
          value: null,
          display: "Accession number",
        },
        {
          key: "cdt",
          value: null,
          display: "CDT",
        },
        {
          key: "country_of_publication",
          value: "UK",
          display: "Country of publication",
        },
        {
          key: "date_of_published_edition_in_library",
          value: "2013",
          display: "Date of published edition in library",
        },
        {
          key: "dates_of_first_edition",
          value: "2013",
          display: "Dates of first edition",
        },
        {
          key: "isbn_issn_number",
          value: "",
          display: "Isbn issn number",
        },
        {
          key: "language_of_publication",
          value: "english",
          display: "Language of publication",
        },
        {
          key: "number_of_pages_in_publication_text",
          value: null,
          display: "Number of pages in publication text",
        },
        {
          key: "personality_film_visually_featured_on_cover",
          value: null,
          display: "Personality film visually featured on cover",
        },
        {
          key: "publisher",
          value: "Photoink",
          display: "Publisher",
        },
        {
          key: "serial_number",
          value: null,
          display: "Serial number",
        },
        {
          key: "sortable_year_date_of_publication",
          value: "2013",
          display: "Sortable year date of publication",
        },
        {
          key: "sub_title_of_document_book",
          value: null,
          display: "Sub title of document book",
        },
        {
          key: "title_of_document_book",
          value: null,
          display: "Title of document book",
        },
        {
          key: "book_title_of_document",
          value: "Trees",
          display: "Book title of document",
        },
        {
          key: "book_sub_title_of_document",
          value: "",
          display: "Book sub title of document",
        },
        {
          key: "volume_issue_number",
          value: null,
          display: "Volume issue number",
        },
        {
          key: "masterlist",
          value: "photography-in-india",
          display: "Masterlist",
        },
        {
          key: "image",
          value:[PhotoIndiaRC9],
          display: "Image",
        },
        {
          key: "document_type",
          value: "bke",
          display: "Document type",
        },
        {
          key: "title_and_type_of_creator",
          value: "Photographer",
          display: "Title and type of creator",
        },
        {
          key: "number_of_pages_in_publication",
          value: null,
          display: "Number of pages in publication",
        },
        {
          key: "researchCategories",
          value: [
            {
              key: "Cinema as a Key Educational Resource",
              value: "Photography",
              link: "cinema-as-a-key-educational-resource",
            },
          ],
          display: "Research categories",
        },
        {
          key: "creator",
          value: [
            {
              key: "Raghu-Rai",
              value: "Raghu-Rai",
              first_name: "Raghu",
              last_name: "Rai",
            },
          ],
          display: "Creator",
        },
      ],
      [
        {
          key: "id",
          value: "5d333dd1-bas62-457b-ada9-b26391065032",
          display: "Id",
        },
        {
          key: "title",
          value: null,
          display: "Title",
        },
        {
          key: "alias",
          value: "bka-bahadur-satyajit-rays-pather-panchali-1981",
          display: "Alias",
        },
        {
          key: "web_serial_number",
          value: null,
          display: "Web serial number",
        },
        {
          key: "reference_code",
          value: null,
          display: "Reference code",
        },
        {
          key: "web_display",
          value: null,
          display: "Web display",
        },
        {
          key: "date_of_library_publication",
          value: null,
          display: "Date of library publication",
        },
        {
          key: "place_of_publication",
          value: "New Delhi",
          display: "Place of publication",
        },
        {
          key: "histographical_note",
          value: null,
          display: "Histographical note",
        },
        {
          key: "main_historical_note",
          value: null,
          display: "Main historical note",
        },
        {
          key: "binding_status",
          value: "PB",
          display: "Binding status",
        },
        {
          key: "accession_number",
          value: null,
          display: "Accession number",
        },
        {
          key: "cdt",
          value: null,
          display: "CDT",
        },
        {
          key: "country_of_publication",
          value: "UK",
          display: "Country of publication",
        },
        {
          key: "date_of_published_edition_in_library",
          value: "2013",
          display: "Date of published edition in library",
        },
        {
          key: "dates_of_first_edition",
          value: "2013",
          display: "Dates of first edition",
        },
        {
          key: "isbn_issn_number",
          value: "",
          display: "Isbn issn number",
        },
        {
          key: "language_of_publication",
          value: "english",
          display: "Language of publication",
        },
        {
          key: "number_of_pages_in_publication_text",
          value: null,
          display: "Number of pages in publication text",
        },
        {
          key: "personality_film_visually_featured_on_cover",
          value: null,
          display: "Personality film visually featured on cover",
        },
        {
          key: "publisher",
          value: "Oxford University",
          display: "Publisher",
        },
        {
          key: "serial_number",
          value: null,
          display: "Serial number",
        },
        {
          key: "sortable_year_date_of_publication",
          value: "2013",
          display: "Sortable year date of publication",
        },
        {
          key: "sub_title_of_document_book",
          value: null,
          display: "Sub title of document book",
        },
        {
          key: "title_of_document_book",
          value: null,
          display: "Title of document book",
        },
        {
          key: "book_title_of_document",
          value: "Photographing India",
          display: "Book title of document",
        },
        {
          key: "book_sub_title_of_document",
          value: "",
          display: "Book sub title of document",
        },
        {
          key: "volume_issue_number",
          value: null,
          display: "Volume issue number",
        },
        {
          key: "masterlist",
          value: "photography-in-india",
          display: "Masterlist",
        },
        {
          key: "image",
          value:[PhotoIndiaRC10],
          display: "Image",
        },
        {
          key: "document_type",
          value: "bke",
          display: "Document type",
        },
        {
          key: "title_and_type_of_creator",
          value: "Photographer",
          display: "Title and type of creator",
        },
        {
          key: "number_of_pages_in_publication",
          value: null,
          display: "Number of pages in publication",
        },
        {
          key: "researchCategories",
          value: [
            {
              key: "Cinema as a Key Educational Resource",
              value: "Photography",
              link: "cinema-as-a-key-educational-resource",
            },
          ],
          display: "Research categories",
        },
        {
          key: "creator",
          value: [
            {
              key: "Sunil-Janah",
              value: "Sunil-Janah",
              first_name: "Sunil",
              last_name: "Janah",
            },
          ],
          display: "Creator",
        },
      ],
  ];

  export const photography_in_india_biblography_filter_options = {
    documentTypeFilter: ["auc","bka","bke","bkq","ess","exc", "ffc","inv","jou","mag","new","q&a","rrm"],
    yearFilter: [],
    creatorTypeFilter: [
      "Author",
      "Dr & Author",
      "Photographer",
      "Editor",
      "Publisher",
      "Compiler",
    ],
    publisher: [],
  };
  export const photography_in_india_biblography_drawerData = {
    header: {
      Image: "",
      text: "",
      desc: "Henri Cartier Bresson. China in Transition, 1956",
    },
    body: {
      sliderImage: [PhotoIndiaRCDrawImg1, PhotoIndiaRCDrawImg2, PhotoIndiaRCDrawImg3],
      details: [
        {
          key: "Author: ",
          link: "",
          text: "Abdul Halim-Sharar",
        },
        {
          key: "Book: ",
          link: "",
          text: "The Lucknow Omnibus",
        },
        {
          key: "Publisher: ",
          link: "",
          text: "Oxford University",
        },
        {
          key: "Year of Publication:",
          link: "",
          text: "2001",
        },
      ],
    },
    footer: [
      {
        text: "Contents Page →",
      },
      {
        text: "Index Page →",
      },
      {
        text: "Scanned Pages →",
      },
    ],
  };

  //biblography lucknow
  export const lucknow_research_centre=[
    [
        {
            "key": "id",
            "value": "e4cdd9b0-9779-4e1e-8456-32613c788037",
            "display": "Id"
        },
        {
            "key": "title",
            "value": null,
            "display": "Title"
        },
        {
            "key": "alias",
            "value": "bke-dutta-a-satyajit-ray-trilogy-of-film-masterpieces-1960",
            "display": "Alias"
        },
        {
            "key": "web_serial_number",
            "value": null,
            "display": "Web serial number"
        },
        {
            "key": "reference_code",
            "value": null,
            "display": "Reference code"
        },
        {
            "key": "web_display",
            "value": null,
            "display": "Web display"
        },
        {
            "key": "date_of_library_publication",
            "value": null,
            "display": "Date of library publication"
        },
        {
            "key": "place_of_publication",
            "value": "xxx",
            "display": "Place of publication"
        },
        {
            "key": "histographical_note",
            "value": null,
            "display": "Histographical note"
        },
        {
            "key": "main_historical_note",
            "value": null,
            "display": "Main historical note"
        },
        {
            "key": "binding_status",
            "value": "PB",
            "display": "Binding status"
        },
        {
            "key": "accession_number",
            "value": "002573",
            "display": "Accession number"
        },
        {
            "key": "cdt",
            "value": null,
            "display": "CDT"
        },
        {
            "key": "country_of_publication",
            "value": "India",
            "display": "Country of publication"
        },
        {
            "key": "date_of_published_edition_in_library",
            "value": "",
            "display": "Date of published edition in library"
        },
        {
            "key": "dates_of_first_edition",
            "value": "",
            "display": "Dates of first edition"
        },
        {
            "key": "isbn_issn_number",
            "value": "",
            "display": "Isbn issn number"
        },
        {
            "key": "language_of_publication",
            "value": "",
            "display": "Language of publication"
        },
        {
            "key": "number_of_pages_in_publication_text",
            "value": null,
            "display": "Number of pages in publication text"
        },
        {
            "key": "personality_film_visually_featured_on_cover",
            "value": null,
            "display": "Personality film visually featured on cover"
        },
        {
            "key": "publisher",
            "value": "Richard Bentely",
            "display": "Publisher"
        },
        {
            "key": "serial_number",
            "value": null,
            "display": "Serial number"
        },
        {
            "key": "sortable_year_date_of_publication",
            "value": "1858",
            "display": "Sortable year date of publication"
        },
        {
            "key": "sub_title_of_document_book",
            "value": null,
            "display": "Sub title of document book"
        },
        {
            "key": "title_of_document_book",
            "value": null,
            "display": "Title of document book"
        },
        {
            "key": "book_title_of_document",
            "value": "Account of the Mutinies in Oudh and of the Siege of the Lucknow Residency",
            "display": "Book title of document"
        },
        {
            "key": "book_sub_title_of_document",
            "value": "with Some Observations on the Condition of the Province of Oudh, and on the Causes of the Mutiny of the Bengal Army",
            "display": "Book sub title of document"
        },
        {
            "key": "volume_issue_number",
            "value": null,
            "display": "Volume issue number"
        },
        {
            "key": "masterlist",
            "value": "satyajit-ray",
            "display": "Masterlist"
        },
        {
            "key": "image",
            "value": [LucknowRC1],
            "display": "Image"
        },
        {
            "key": "document_type",
            "value": "bka",
            "display": "Document type"
        },
        {
            "key": "title_and_type_of_creator",
            "value": "Author",
            "display": "Title and type of creator"
        },
        {
            "key": "number_of_pages_in_publication",
            "value": null,
            "display": "Number of pages in publication"
        },
        {
            "key": "researchCategories",
            "value": [
                {
                    "key": "ArchDev",
                    "value": "ArchDev",
                    "link": "ArchDev"
                },
                {
                    "key": "Changing Smile of Childhood and its Second Coming",
                    "value": "Childhood",
                    "link": "changing-smile-of-childhood-and-its-second-coming"
                },
                {
                    "key": "India and her Relationship with the World",
                    "value": "IndoWorld",
                    "link": "india-and-her-relationship-with-the-world"
                },
                {
                    "key": "History & Historiography of Indian Scholarship in the Arts, Social Sciences & Humanities",
                    "value": "Scholarship",
                    "link": "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities"
                }
            ],
            "display": "Research categories"
        },
        {
            "key": "creator",
            "value": [
                {
                    "key": "gubbins",
                    "value": "Gubbins",
                    "first_name": " Martin-Richard",
                    "last_name": "Gubbins"
                }
            ],
            "display": "Creator"
        }
    ],
    [
        {
            "key": "id",
            "value": "",
            "display": "Id"
        },
        {
            "key": "title",
            "value": null,
            "display": "Title"
        },
        {
            "key": "alias",
            "value": "",
            "display": "Alias"
        },
        {
            "key": "web_serial_number",
            "value": null,
            "display": "Web serial number"
        },
        {
            "key": "reference_code",
            "value": null,
            "display": "Reference code"
        },
        {
            "key": "web_display",
            "value": null,
            "display": "Web display"
        },
        {
            "key": "date_of_library_publication",
            "value": null,
            "display": "Date of library publication"
        },
        {
            "key": "place_of_publication",
            "value": "New Delhi",
            "display": "Place of publication"
        },
        {
            "key": "histographical_note",
            "value": null,
            "display": "Histographical note"
        },
        {
            "key": "main_historical_note",
            "value": null,
            "display": "Main historical note"
        },
        {
            "key": "binding_status",
            "value": "PB",
            "display": "Binding status"
        },
        {
            "key": "accession_number",
            "value": "002983",
            "display": "Accession number"
        },
        {
            "key": "cdt",
            "value": null,
            "display": "CDT"
        },
        {
            "key": "country_of_publication",
            "value": "India",
            "display": "Country of publication"
        },
        {
            "key": "date_of_published_edition_in_library",
            "value": "",
            "display": "Date of published edition in library"
        },
        {
            "key": "dates_of_first_edition",
            "value": "",
            "display": "Dates of first edition"
        },
        {
            "key": "isbn_issn_number",
            "value": "",
            "display": "Isbn issn number"
        },
        {
            "key": "language_of_publication",
            "value": "",
            "display": "Language of publication"
        },
        {
            "key": "number_of_pages_in_publication_text",
            "value": null,
            "display": "Number of pages in publication text"
        },
        {
            "key": "personality_film_visually_featured_on_cover",
            "value": null,
            "display": "Personality film visually featured on cover"
        },
        {
            "key": "publisher",
            "value": "Gyan",
            "display": "Publisher"
        },
        {
            "key": "serial_number",
            "value": null,
            "display": "Serial number"
        },
        {
            "key": "sortable_year_date_of_publication",
            "value": "1883",
            "display": "Sortable year date of publication"
        },
        {
            "key": "sub_title_of_document_book",
            "value": null,
            "display": "Sub title of document book"
        },
        {
            "key": "title_of_document_book",
            "value": null,
            "display": "Title of document book"
        },
        {
            "key": "book_title_of_document",
            "value": " The Pictorial Lucknow",
            "display": "Book title of document"
        },
        {
            "key": "book_sub_title_of_document",
            "value": "",
            "display": "Book sub title of document"
        },
        {
            "key": "volume_issue_number",
            "value": null,
            "display": "Volume issue number"
        },
        {
            "key": "masterlist",
            "value": "lucknow",
            "display": "Masterlist"
        },
        {
            "key": "image",
            "value": [LucknowRC2],
            "display": "Image"
        },
        {
            "key": "document_type",
            "value": "bka",
            "display": "Document type"
        },
        {
            "key": "title_and_type_of_creator",
            "value": "Author",
            "display": "Title and type of creator"
        },
        {
            "key": "number_of_pages_in_publication",
            "value": null,
            "display": "Number of pages in publication"
        },
        {
            "key": "researchCategories",
            "value": [
                {
                    "key": "ArchDev",
                    "value": "ArchDev",
                    "link": "ArchDev"
                },
                {
                    "key": "Changing Smile of Childhood and its Second Coming",
                    "value": "Childhood",
                    "link": "changing-smile-of-childhood-and-its-second-coming"
                },
                {
                    "key": "India and her Relationship with the World",
                    "value": "IndoWorld",
                    "link": "india-and-her-relationship-with-the-world"
                },
                {
                    "key": "History & Historiography of Indian Scholarship in the Arts, Social Sciences & Humanities",
                    "value": "Scholarship",
                    "link": "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities"
                }
            ],
            "display": "Research categories"
        },
        {
            "key": "creator",
            "value": [
                {
                    "key": "mookherji",
                    "value": "Mookherji",
                    "first_name": " P-C",
                    "last_name": "Mookherji"
                }
            ],
            "display": "Creator"
        }
    ],
    [
        {
            "key": "id",
            "value": "",
            "display": "Id"
        },
        {
            "key": "title",
            "value": null,
            "display": "Title"
        },
        {
            "key": "alias",
            "value": "",
            "display": "Alias"
        },
        {
            "key": "web_serial_number",
            "value": null,
            "display": "Web serial number"
        },
        {
            "key": "reference_code",
            "value": null,
            "display": "Reference code"
        },
        {
            "key": "web_display",
            "value": null,
            "display": "Web display"
        },
        {
            "key": "date_of_library_publication",
            "value": null,
            "display": "Date of library publication"
        },
        {
            "key": "place_of_publication",
            "value": "New Delhi",
            "display": "Place of publication"
        },
        {
            "key": "histographical_note",
            "value": null,
            "display": "Histographical note"
        },
        {
            "key": "main_historical_note",
            "value": null,
            "display": "Main historical note"
        },
        {
            "key": "binding_status",
            "value": "PB",
            "display": "Binding status"
        },
        {
            "key": "accession_number",
            "value": "002637",
            "display": "Accession number"
        },
        {
            "key": "cdt",
            "value": null,
            "display": "CDT"
        },
        {
            "key": "country_of_publication",
            "value": "India",
            "display": "Country of publication"
        },
        {
            "key": "date_of_published_edition_in_library",
            "value": "",
            "display": "Date of published edition in library"
        },
        {
            "key": "dates_of_first_edition",
            "value": "",
            "display": "Dates of first edition"
        },
        {
            "key": "isbn_issn_number",
            "value": "",
            "display": "Isbn issn number"
        },
        {
            "key": "language_of_publication",
            "value": "",
            "display": "Language of publication"
        },
        {
            "key": "number_of_pages_in_publication_text",
            "value": null,
            "display": "Number of pages in publication text"
        },
        {
            "key": "personality_film_visually_featured_on_cover",
            "value": null,
            "display": "Personality film visually featured on cover"
        },
        {
            "key": "publisher",
            "value": "Ministry of Information and Broadcasting",
            "display": "Publisher"
        },
        {
            "key": "serial_number",
            "value": null,
            "display": "Serial number"
        },
        {
            "key": "sortable_year_date_of_publication",
            "value": "1954",
            "display": "Sortable year date of publication"
        },
        {
            "key": "sub_title_of_document_book",
            "value": null,
            "display": "Sub title of document book"
        },
        {
            "key": "title_of_document_book",
            "value": null,
            "display": "Title of document book"
        },
        {
            "key": "book_title_of_document",
            "value": "",
            "display": "Book title of document"
        },
        {
            "key": "book_sub_title_of_document",
            "value": "",
            "display": "Book sub title of document"
        },
        {
            "key": "volume_issue_number",
            "value": null,
            "display": "Volume issue number"
        },
        {
            "key": "masterlist",
            "value": "lucknow",
            "display": "Masterlist"
        },
        {
            "key": "image",
            "value": [LucknowRC3],
            "display": "Image"
        },
        {
            "key": "document_type",
            "value": "bka",
            "display": "Document type"
        },
        {
            "key": "title_and_type_of_creator",
            "value": "",
            "display": "Title and type of creator"
        },
        {
            "key": "number_of_pages_in_publication",
            "value": null,
            "display": "Number of pages in publication"
        },
        {
            "key": "researchCategories",
            "value": [
                {
                    "key": "ArchDev",
                    "value": "ArchDev",
                    "link": "ArchDev"
                },
                {
                    "key": "Changing Smile of Childhood and its Second Coming",
                    "value": "Childhood",
                    "link": "changing-smile-of-childhood-and-its-second-coming"
                },
                {
                    "key": "India and her Relationship with the World",
                    "value": "IndoWorld",
                    "link": "india-and-her-relationship-with-the-world"
                },
                {
                    "key": "History & Historiography of Indian Scholarship in the Arts, Social Sciences & Humanities",
                    "value": "Scholarship",
                    "link": "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities"
                }
            ],
            "display": "Research categories"
        },
        {
            "key": "creator",
            "value": [
                {
                    "key": "lucknow",
                    "value": "Lucknow",
                    "first_name": "- Hussainabad Imambara",
                    "last_name": "Lucknow"
                }
            ],
            "display": "Creator"
        }
    ],
    [
        {
            "key": "id",
            "value": "",
            "display": "Id"
        },
        {
            "key": "title",
            "value": null,
            "display": "Title"
        },
        {
            "key": "alias",
            "value": "",
            "display": "Alias"
        },
        {
            "key": "web_serial_number",
            "value": null,
            "display": "Web serial number"
        },
        {
            "key": "reference_code",
            "value": null,
            "display": "Reference code"
        },
        {
            "key": "web_display",
            "value": null,
            "display": "Web display"
        },
        {
            "key": "date_of_library_publication",
            "value": null,
            "display": "Date of library publication"
        },
        {
            "key": "place_of_publication",
            "value": "London",
            "display": "Place of publication"
        },
        {
            "key": "histographical_note",
            "value": null,
            "display": "Histographical note"
        },
        {
            "key": "main_historical_note",
            "value": null,
            "display": "Main historical note"
        },
        {
            "key": "binding_status",
            "value": "PB",
            "display": "Binding status"
        },
        {
            "key": "accession_number",
            "value": "000370",
            "display": "Accession number"
        },
        {
            "key": "cdt",
            "value": null,
            "display": "CDT"
        },
        {
            "key": "country_of_publication",
            "value": "India",
            "display": "Country of publication"
        },
        {
            "key": "date_of_published_edition_in_library",
            "value": "",
            "display": "Date of published edition in library"
        },
        {
            "key": "dates_of_first_edition",
            "value": "",
            "display": "Dates of first edition"
        },
        {
            "key": "isbn_issn_number",
            "value": "",
            "display": "Isbn issn number"
        },
        {
            "key": "language_of_publication",
            "value": "",
            "display": "Language of publication"
        },
        {
            "key": "number_of_pages_in_publication_text",
            "value": null,
            "display": "Number of pages in publication text"
        },
        {
            "key": "personality_film_visually_featured_on_cover",
            "value": null,
            "display": "Personality film visually featured on cover"
        },
        {
            "key": "publisher",
            "value": "Penguin Books",
            "display": "Publisher"
        },
        {
            "key": "serial_number",
            "value": null,
            "display": "Serial number"
        },
        {
            "key": "sortable_year_date_of_publication",
            "value": "1989",
            "display": "Sortable year date of publication"
        },
        {
            "key": "sub_title_of_document_book",
            "value": null,
            "display": "Sub title of document book"
        },
        {
            "key": "title_of_document_book",
            "value": null,
            "display": "Title of document book"
        },
        {
            "key": "book_title_of_document",
            "value": "Monuments of India",
            "display": "Book title of document"
        },
        {
            "key": "book_sub_title_of_document",
            "value": "Islamic, Rajput, European : Volume Two",
            "display": "Book sub title of document"
        },
        {
            "key": "volume_issue_number",
            "value": null,
            "display": "Volume issue number"
        },
        {
            "key": "masterlist",
            "value": "lucknow",
            "display": "Masterlist"
        },
        {
            "key": "image",
            "value": [LucknowRC4],
            "display": "Image"
        },
        {
            "key": "document_type",
            "value": "bka",
            "display": "Document type"
        },
        {
            "key": "title_and_type_of_creator",
            "value": "Author",
            "display": "Title and type of creator"
        },
        {
            "key": "number_of_pages_in_publication",
            "value": null,
            "display": "Number of pages in publication"
        },
        {
            "key": "researchCategories",
            "value": [
                {
                    "key": "ArchDev",
                    "value": "ArchDev",
                    "link": "ArchDev"
                },
                {
                    "key": "Changing Smile of Childhood and its Second Coming",
                    "value": "Childhood",
                    "link": "changing-smile-of-childhood-and-its-second-coming"
                },
                {
                    "key": "India and her Relationship with the World",
                    "value": "IndoWorld",
                    "link": "india-and-her-relationship-with-the-world"
                },
                {
                    "key": "History & Historiography of Indian Scholarship in the Arts, Social Sciences & Humanities",
                    "value": "Scholarship",
                    "link": "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities"
                }
            ],
            "display": "Research categories"
        },
        {
            "key": "creator",
            "value": [
                {
                    "key": "lucknow",
                    "value": "Lucknow",
                    "first_name": "Philip",
                    "last_name": "Davies"
                }
            ],
            "display": "Creator"
        }
    ],
    [
        {
            "key": "id",
            "value": "",
            "display": "Id"
        },
        {
            "key": "title",
            "value": null,
            "display": "Title"
        },
        {
            "key": "alias",
            "value": "",
            "display": "Alias"
        },
        {
            "key": "web_serial_number",
            "value": null,
            "display": "Web serial number"
        },
        {
            "key": "reference_code",
            "value": null,
            "display": "Reference code"
        },
        {
            "key": "web_display",
            "value": null,
            "display": "Web display"
        },
        {
            "key": "date_of_library_publication",
            "value": null,
            "display": "Date of library publication"
        },
        {
            "key": "place_of_publication",
            "value": "New Delhi",
            "display": "Place of publication"
        },
        {
            "key": "histographical_note",
            "value": null,
            "display": "Histographical note"
        },
        {
            "key": "main_historical_note",
            "value": null,
            "display": "Main historical note"
        },
        {
            "key": "binding_status",
            "value": "PB",
            "display": "Binding status"
        },
        {
            "key": "accession_number",
            "value": "000070",
            "display": "Accession number"
        },
        {
            "key": "cdt",
            "value": null,
            "display": "CDT"
        },
        {
            "key": "country_of_publication",
            "value": "India",
            "display": "Country of publication"
        },
        {
            "key": "date_of_published_edition_in_library",
            "value": "",
            "display": "Date of published edition in library"
        },
        {
            "key": "dates_of_first_edition",
            "value": "",
            "display": "Dates of first edition"
        },
        {
            "key": "isbn_issn_number",
            "value": "",
            "display": "Isbn issn number"
        },
        {
            "key": "language_of_publication",
            "value": "",
            "display": "Language of publication"
        },
        {
            "key": "number_of_pages_in_publication_text",
            "value": null,
            "display": "Number of pages in publication text"
        },
        {
            "key": "personality_film_visually_featured_on_cover",
            "value": null,
            "display": "Personality film visually featured on cover"
        },
        {
            "key": "publisher",
            "value": "Foundation Books",
            "display": "Publisher"
        },
        {
            "key": "serial_number",
            "value": null,
            "display": "Serial number"
        },
        {
            "key": "sortable_year_date_of_publication",
            "value": "1995",
            "display": "Sortable year date of publication"
        },
        {
            "key": "sub_title_of_document_book",
            "value": null,
            "display": "Sub title of document book"
        },
        {
            "key": "title_of_document_book",
            "value": null,
            "display": "Title of document book"
        },
        {
            "key": "book_title_of_document",
            "value": "Architecture of Mughal India",
            "display": "Book title of document"
        },
        {
            "key": "book_sub_title_of_document",
            "value": "",
            "display": "Book sub title of document"
        },
        {
            "key": "volume_issue_number",
            "value": null,
            "display": "Volume issue number"
        },
        {
            "key": "masterlist",
            "value": "lucknow",
            "display": "Masterlist"
        },
        {
            "key": "image",
            "value": [LucknowRC5],
            "display": "Image"
        },
        {
            "key": "document_type",
            "value": "bka",
            "display": "Document type"
        },
        {
            "key": "title_and_type_of_creator",
            "value": "Author",
            "display": "Title and type of creator"
        },
        {
            "key": "number_of_pages_in_publication",
            "value": null,
            "display": "Number of pages in publication"
        },
        {
            "key": "researchCategories",
            "value": [
                {
                    "key": "ArchDev",
                    "value": "ArchDev",
                    "link": "ArchDev"
                },
                {
                    "key": "Changing Smile of Childhood and its Second Coming",
                    "value": "Childhood",
                    "link": "changing-smile-of-childhood-and-its-second-coming"
                },
                {
                    "key": "India and her Relationship with the World",
                    "value": "IndoWorld",
                    "link": "india-and-her-relationship-with-the-world"
                },
                {
                    "key": "History & Historiography of Indian Scholarship in the Arts, Social Sciences & Humanities",
                    "value": "Scholarship",
                    "link": "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities"
                }
            ],
            "display": "Research categories"
        },
        {
            "key": "creator",
            "value": [
                {
                    "key": "asher",
                    "value": "Asher",
                    "first_name": "Catherine-B",
                    "last_name": "Asher"
                }
            ],
            "display": "Creator"
        }
    ],
    [
        {
            "key": "id",
            "value": "",
            "display": "Id"
        },
        {
            "key": "title",
            "value": null,
            "display": "Title"
        },
        {
            "key": "alias",
            "value": "bka-abdul-halim-the-lucknow-Omnibus-2001",
            "display": "Alias"
        },
        {
            "key": "web_serial_number",
            "value": null,
            "display": "Web serial number"
        },
        {
            "key": "reference_code",
            "value": null,
            "display": "Reference code"
        },
        {
            "key": "web_display",
            "value": null,
            "display": "Web display"
        },
        {
            "key": "date_of_library_publication",
            "value": null,
            "display": "Date of library publication"
        },
        {
            "key": "place_of_publication",
            "value": "New Delhi",
            "display": "Place of publication"
        },
        {
            "key": "histographical_note",
            "value": null,
            "display": "Histographical note"
        },
        {
            "key": "main_historical_note",
            "value": null,
            "display": "Main historical note"
        },
        {
            "key": "binding_status",
            "value": "PB",
            "display": "Binding status"
        },
        {
            "key": "accession_number",
            "value": "001456",
            "display": "Accession number"
        },
        {
            "key": "cdt",
            "value": null,
            "display": "CDT"
        },
        {
            "key": "country_of_publication",
            "value": "India",
            "display": "Country of publication"
        },
        {
            "key": "date_of_published_edition_in_library",
            "value": "",
            "display": "Date of published edition in library"
        },
        {
            "key": "dates_of_first_edition",
            "value": "",
            "display": "Dates of first edition"
        },
        {
            "key": "isbn_issn_number",
            "value": "",
            "display": "Isbn issn number"
        },
        {
            "key": "language_of_publication",
            "value": "",
            "display": "Language of publication"
        },
        {
            "key": "number_of_pages_in_publication_text",
            "value": null,
            "display": "Number of pages in publication text"
        },
        {
            "key": "personality_film_visually_featured_on_cover",
            "value": null,
            "display": "Personality film visually featured on cover"
        },
        {
            "key": "publisher",
            "value": "Oxford University",
            "display": "Publisher"
        },
        {
            "key": "serial_number",
            "value": null,
            "display": "Serial number"
        },
        {
            "key": "sortable_year_date_of_publication",
            "value": "2001",
            "display": "Sortable year date of publication"
        },
        {
            "key": "sub_title_of_document_book",
            "value": null,
            "display": "Sub title of document book"
        },
        {
            "key": "title_of_document_book",
            "value": null,
            "display": "Title of document book"
        },
        {
            "key": "book_title_of_document",
            "value": "The Lucknow Omnibus",
            "display": "Book title of document"
        },
        {
            "key": "book_sub_title_of_document",
            "value": "",
            "display": "Book sub title of document"
        },
        {
            "key": "volume_issue_number",
            "value": null,
            "display": "Volume issue number"
        },
        {
            "key": "masterlist",
            "value": "lucknow",
            "display": "Masterlist"
        },
        {
            "key": "image",
            // "value": [LucknowRC6],
            "value": [LucknowRC6,LucknowRCDrawImg1, LucknowRCDrawImg2, LucknowRCDrawImg3],

            "display": "Image"
        },
        {
            "key": "document_type",
            "value": "bka",
            "display": "Document type"
        },
        {
            "key": "title_and_type_of_creator",
            "value": "Author",
            "display": "Title and type of creator"
        },
        {
            "key": "number_of_pages_in_publication",
            "value": null,
            "display": "Number of pages in publication"
        },
        {
            "key": "researchCategories",
            "value": [
                {
                    "key": "ArchDev",
                    "value": "ArchDev",
                    "link": "ArchDev"
                },
                {
                    "key": "Changing Smile of Childhood and its Second Coming",
                    "value": "Childhood",
                    "link": "changing-smile-of-childhood-and-its-second-coming"
                },
                {
                    "key": "India and her Relationship with the World",
                    "value": "IndoWorld",
                    "link": "india-and-her-relationship-with-the-world"
                },
                {
                    "key": "History & Historiography of Indian Scholarship in the Arts, Social Sciences & Humanities",
                    "value": "Scholarship",
                    "link": "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities"
                }
            ],
            "display": "Research categories"
        },
        {
            "key": "creator",
            "value": [
                {
                    "key": "sharar",
                    "value": "Sharar",
                    "first_name": "Abdul-Halim",
                    "last_name": "Sharar"
                }
            ],
            "display": "Creator"
        },
        {
            key: "drawer_link",
            value: true,
          }
    ],
    [
        {
            "key": "id",
            "value": "",
            "display": "Id"
        },
        {
            "key": "title",
            "value": null,
            "display": "Title"
        },
        {
            "key": "alias",
            "value": "",
            "display": "Alias"
        },
        {
            "key": "web_serial_number",
            "value": null,
            "display": "Web serial number"
        },
        {
            "key": "reference_code",
            "value": null,
            "display": "Reference code"
        },
        {
            "key": "web_display",
            "value": null,
            "display": "Web display"
        },
        {
            "key": "date_of_library_publication",
            "value": null,
            "display": "Date of library publication"
        },
        {
            "key": "place_of_publication",
            "value": "Munich",
            "display": "Place of publication"
        },
        {
            "key": "histographical_note",
            "value": null,
            "display": "Histographical note"
        },
        {
            "key": "main_historical_note",
            "value": null,
            "display": "Main historical note"
        },
        {
            "key": "binding_status",
            "value": "PB",
            "display": "Binding status"
        },
        {
            "key": "accession_number",
            "value": "000744",
            "display": "Accession number"
        },
        {
            "key": "cdt",
            "value": null,
            "display": "CDT"
        },
        {
            "key": "country_of_publication",
            "value": "India",
            "display": "Country of publication"
        },
        {
            "key": "date_of_published_edition_in_library",
            "value": "",
            "display": "Date of published edition in library"
        },
        {
            "key": "dates_of_first_edition",
            "value": "",
            "display": "Dates of first edition"
        },
        {
            "key": "isbn_issn_number",
            "value": "",
            "display": "Isbn issn number"
        },
        {
            "key": "language_of_publication",
            "value": "",
            "display": "Language of publication"
        },
        {
            "key": "number_of_pages_in_publication_text",
            "value": null,
            "display": "Number of pages in publication text"
        },
        {
            "key": "personality_film_visually_featured_on_cover",
            "value": null,
            "display": "Personality film visually featured on cover"
        },
        {
            "key": "publisher",
            "value": "Prestel Verlag",
            "display": "Publisher"
        },
        {
            "key": "serial_number",
            "value": null,
            "display": "Serial number"
        },
        {
            "key": "sortable_year_date_of_publication",
            "value": "2006",
            "display": "Sortable year date of publication"
        },
        {
            "key": "sub_title_of_document_book",
            "value": null,
            "display": "Sub title of document book"
        },
        {
            "key": "title_of_document_book",
            "value": null,
            "display": "Title of document book"
        },
        {
            "key": "book_title_of_document",
            "value": "Lucknow - City Of Illusion",
            "display": "Book title of document"
        },
        {
            "key": "book_sub_title_of_document",
            "value": "",
            "display": "Book sub title of document"
        },
        {
            "key": "volume_issue_number",
            "value": null,
            "display": "Volume issue number"
        },
        {
            "key": "masterlist",
            "value": "lucknow",
            "display": "Masterlist"
        },
        {
            "key": "image",
            "value": [LucknowRC7],
            "display": "Image"
        },
        {
            "key": "document_type",
            "value": "bka",
            "display": "Document type"
        },
        {
            "key": "title_and_type_of_creator",
            "value": "Editor",
            "display": "Title and type of creator"
        },
        {
            "key": "number_of_pages_in_publication",
            "value": null,
            "display": "Number of pages in publication"
        },
        {
            "key": "researchCategories",
            "value": [
                {
                    "key": "ArchDev",
                    "value": "ArchDev",
                    "link": "ArchDev"
                },
                {
                    "key": "Changing Smile of Childhood and its Second Coming",
                    "value": "Childhood",
                    "link": "changing-smile-of-childhood-and-its-second-coming"
                },
                {
                    "key": "India and her Relationship with the World",
                    "value": "IndoWorld",
                    "link": "india-and-her-relationship-with-the-world"
                },
                {
                    "key": "History & Historiography of Indian Scholarship in the Arts, Social Sciences & Humanities",
                    "value": "Scholarship",
                    "link": "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities"
                }
            ],
            "display": "Research categories"
        },
        {
            "key": "creator",
            "value": [
                {
                    "key": "jones",
                    "value": "Jones",
                    "first_name": "Roise-Llewellyn",
                    "last_name": "Jones"
                }
            ],
            "display": "Creator"
        }
    ],
    [
        {
            "key": "id",
            "value": "",
            "display": "Id"
        },
        {
            "key": "title",
            "value": null,
            "display": "Title"
        },
        {
            "key": "alias",
            "value": "",
            "display": "Alias"
        },
        {
            "key": "web_serial_number",
            "value": null,
            "display": "Web serial number"
        },
        {
            "key": "reference_code",
            "value": null,
            "display": "Reference code"
        },
        {
            "key": "web_display",
            "value": null,
            "display": "Web display"
        },
        {
            "key": "date_of_library_publication",
            "value": null,
            "display": "Date of library publication"
        },
        {
            "key": "place_of_publication",
            "value": "Los Angeles",
            "display": "Place of publication"
        },
        {
            "key": "histographical_note",
            "value": null,
            "display": "Histographical note"
        },
        {
            "key": "main_historical_note",
            "value": null,
            "display": "Main historical note"
        },
        {
            "key": "binding_status",
            "value": "PB",
            "display": "Binding status"
        },
        {
            "key": "accession_number",
            "value": "000928",
            "display": "Accession number"
        },
        {
            "key": "cdt",
            "value": null,
            "display": "CDT"
        },
        {
            "key": "country_of_publication",
            "value": "India",
            "display": "Country of publication"
        },
        {
            "key": "date_of_published_edition_in_library",
            "value": "",
            "display": "Date of published edition in library"
        },
        {
            "key": "dates_of_first_edition",
            "value": "",
            "display": "Dates of first edition"
        },
        {
            "key": "isbn_issn_number",
            "value": "",
            "display": "Isbn issn number"
        },
        {
            "key": "language_of_publication",
            "value": "",
            "display": "Language of publication"
        },
        {
            "key": "number_of_pages_in_publication_text",
            "value": null,
            "display": "Number of pages in publication text"
        },
        {
            "key": "personality_film_visually_featured_on_cover",
            "value": null,
            "display": "Personality film visually featured on cover"
        },
        {
            "key": "publisher",
            "value": "Los Angeles County Museum of Art",
            "display": "Publisher"
        },
        {
            "key": "serial_number",
            "value": null,
            "display": "Serial number"
        },
        {
            "key": "sortable_year_date_of_publication",
            "value": "2010",
            "display": "Sortable year date of publication"
        },
        {
            "key": "sub_title_of_document_book",
            "value": null,
            "display": "Sub title of document book"
        },
        {
            "key": "title_of_document_book",
            "value": null,
            "display": "Title of document book"
        },
        {
            "key": "book_title_of_document",
            "value": "The Art of Courtly Lucknow",
            "display": "Book title of document"
        },
        {
            "key": "book_sub_title_of_document",
            "value": "India's Fabled City",
            "display": "Book sub title of document"
        },
        {
            "key": "volume_issue_number",
            "value": null,
            "display": "Volume issue number"
        },
        {
            "key": "masterlist",
            "value": "lucknow",
            "display": "Masterlist"
        },
        {
            "key": "image",
            "value": [LucknowRC8],
            "display": "Image"
        },
        {
            "key": "document_type",
            "value": "bka",
            "display": "Document type"
        },
        {
            "key": "title_and_type_of_creator",
            "value": "Author",
            "display": "Title and type of creator"
        },
        {
            "key": "number_of_pages_in_publication",
            "value": null,
            "display": "Number of pages in publication"
        },
        {
            "key": "researchCategories",
            "value": [
                {
                    "key": "ArchDev",
                    "value": "ArchDev",
                    "link": "ArchDev"
                },
                {
                    "key": "Changing Smile of Childhood and its Second Coming",
                    "value": "Childhood",
                    "link": "changing-smile-of-childhood-and-its-second-coming"
                },
                {
                    "key": "India and her Relationship with the World",
                    "value": "IndoWorld",
                    "link": "india-and-her-relationship-with-the-world"
                },
                {
                    "key": "History & Historiography of Indian Scholarship in the Arts, Social Sciences & Humanities",
                    "value": "Scholarship",
                    "link": "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities"
                }
            ],
            "display": "Research categories"
        },
        {
            "key": "creator",
            "value": [
                {
                    "key": "markel",
                    "value": "Markel",
                    "first_name": "Stephen",
                    "last_name": "Markel"
                }
            ],
            "display": "Creator"
        }
    ],
    [
        {
            "key": "id",
            "value": "",
            "display": "Id"
        },
        {
            "key": "title",
            "value": null,
            "display": "Title"
        },
        {
            "key": "alias",
            "value": "",
            "display": "Alias"
        },
        {
            "key": "web_serial_number",
            "value": null,
            "display": "Web serial number"
        },
        {
            "key": "reference_code",
            "value": null,
            "display": "Reference code"
        },
        {
            "key": "web_display",
            "value": null,
            "display": "Web display"
        },
        {
            "key": "date_of_library_publication",
            "value": null,
            "display": "Date of library publication"
        },
        {
            "key": "place_of_publication",
            "value": "New Delhi",
            "display": "Place of publication"
        },
        {
            "key": "histographical_note",
            "value": null,
            "display": "Histographical note"
        },
        {
            "key": "main_historical_note",
            "value": null,
            "display": "Main historical note"
        },
        {
            "key": "binding_status",
            "value": "PB",
            "display": "Binding status"
        },
        {
            "key": "accession_number",
            "value": "000622",
            "display": "Accession number"
        },
        {
            "key": "cdt",
            "value": null,
            "display": "CDT"
        },
        {
            "key": "country_of_publication",
            "value": "India",
            "display": "Country of publication"
        },
        {
            "key": "date_of_published_edition_in_library",
            "value": "",
            "display": "Date of published edition in library"
        },
        {
            "key": "dates_of_first_edition",
            "value": "",
            "display": "Dates of first edition"
        },
        {
            "key": "isbn_issn_number",
            "value": "",
            "display": "Isbn issn number"
        },
        {
            "key": "language_of_publication",
            "value": "",
            "display": "Language of publication"
        },
        {
            "key": "number_of_pages_in_publication_text",
            "value": null,
            "display": "Number of pages in publication text"
        },
        {
            "key": "personality_film_visually_featured_on_cover",
            "value": null,
            "display": "Personality film visually featured on cover"
        },
        {
            "key": "publisher",
            "value": " The People Place Project",
            "display": "Publisher"
        },
        {
            "key": "serial_number",
            "value": null,
            "display": "Serial number"
        },
        {
            "key": "sortable_year_date_of_publication",
            "value": "2019",
            "display": "Sortable year date of publication"
        },
        {
            "key": "sub_title_of_document_book",
            "value": null,
            "display": "Sub title of document book"
        },
        {
            "key": "title_of_document_book",
            "value": null,
            "display": "Title of document book"
        },
        {
            "key": "book_title_of_document",
            "value": "People Called Lucknow",
            "display": "Book title of document"
        },
        {
            "key": "book_sub_title_of_document",
            "value": "45 Narratives Unlayering Time in Awadhi Andaz",
            "display": "Book sub title of document"
        },
        {
            "key": "volume_issue_number",
            "value": null,
            "display": "Volume issue number"
        },
        {
            "key": "masterlist",
            "value": "lucknow",
            "display": "Masterlist"
        },
        {
            "key": "image",
            "value": [LucknowRC9],
            "display": "Image"
        },
        {
            "key": "document_type",
            "value": "bka",
            "display": "Document type"
        },
        {
            "key": "title_and_type_of_creator",
            "value": "Author",
            "display": "Title and type of creator"
        },
        {
            "key": "number_of_pages_in_publication",
            "value": null,
            "display": "Number of pages in publication"
        },
        {
            "key": "researchCategories",
            "value": [
                {
                    "key": "ArchDev",
                    "value": "ArchDev",
                    "link": "ArchDev"
                },
                {
                    "key": "Changing Smile of Childhood and its Second Coming",
                    "value": "Childhood",
                    "link": "changing-smile-of-childhood-and-its-second-coming"
                },
                {
                    "key": "India and her Relationship with the World",
                    "value": "IndoWorld",
                    "link": "india-and-her-relationship-with-the-world"
                },
                {
                    "key": "History & Historiography of Indian Scholarship in the Arts, Social Sciences & Humanities",
                    "value": "Scholarship",
                    "link": "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities"
                }
            ],
            "display": "Research categories"
        },
        {
            "key": "creator",
            "value": [
                {
                    "key": "habibullah",
                    "value": "Habibullah",
                    "first_name": "Jyotsna Kaur",
                    "last_name": "Habibullah"
                }
            ],
            "display": "Creator"
        }
    ],
    [
        {
            "key": "id",
            "value": "",
            "display": "Id"
        },
        {
            "key": "title",
            "value": null,
            "display": "Title"
        },
        {
            "key": "alias",
            "value": "",
            "display": "Alias"
        },
        {
            "key": "web_serial_number",
            "value": null,
            "display": "Web serial number"
        },
        {
            "key": "reference_code",
            "value": null,
            "display": "Reference code"
        },
        {
            "key": "web_display",
            "value": null,
            "display": "Web display"
        },
        {
            "key": "date_of_library_publication",
            "value": null,
            "display": "Date of library publication"
        },
        {
            "key": "place_of_publication",
            "value": "Noida ",
            "display": "Place of publication"
        },
        {
            "key": "histographical_note",
            "value": null,
            "display": "Histographical note"
        },
        {
            "key": "main_historical_note",
            "value": null,
            "display": "Main historical note"
        },
        {
            "key": "binding_status",
            "value": "PB",
            "display": "Binding status"
        },
        {
            "key": "accession_number",
            "value": "001201",
            "display": "Accession number"
        },
        {
            "key": "cdt",
            "value": null,
            "display": "CDT"
        },
        {
            "key": "country_of_publication",
            "value": "India",
            "display": "Country of publication"
        },
        {
            "key": "date_of_published_edition_in_library",
            "value": "",
            "display": "Date of published edition in library"
        },
        {
            "key": "dates_of_first_edition",
            "value": "",
            "display": "Dates of first edition"
        },
        {
            "key": "isbn_issn_number",
            "value": "",
            "display": "Isbn issn number"
        },
        {
            "key": "language_of_publication",
            "value": "",
            "display": "Language of publication"
        },
        {
            "key": "number_of_pages_in_publication_text",
            "value": null,
            "display": "Number of pages in publication text"
        },
        {
            "key": "personality_film_visually_featured_on_cover",
            "value": null,
            "display": "Personality film visually featured on cover"
        },
        {
            "key": "publisher",
            "value": "Taj Mohal Hotel",
            "display": "Publisher"
        },
        {
            "key": "serial_number",
            "value": null,
            "display": "Serial number"
        },
        {
            "key": "sortable_year_date_of_publication",
            "value": "XXX",
            "display": "Sortable year date of publication"
        },
        {
            "key": "sub_title_of_document_book",
            "value": null,
            "display": "Sub title of document book"
        },
        {
            "key": "title_of_document_book",
            "value": null,
            "display": "Title of document book"
        },
        {
            "key": "book_title_of_document",
            "value": "Lucknow",
            "display": "Book title of document"
        },
        {
            "key": "book_sub_title_of_document",
            "value": "",
            "display": "Book sub title of document"
        },
        {
            "key": "volume_issue_number",
            "value": null,
            "display": "Volume issue number"
        },
        {
            "key": "masterlist",
            "value": "lucknow",
            "display": "Masterlist"
        },
        {
            "key": "image",
            "value": [LucknowRC10],
            "display": "Image"
        },
        {
            "key": "document_type",
            "value": "bka",
            "display": "Document type"
        },
        {
            "key": "title_and_type_of_creator",
            "value": "Author",
            "display": "Title and type of creator"
        },
        {
            "key": "number_of_pages_in_publication",
            "value": null,
            "display": "Number of pages in publication"
        },
        {
            "key": "researchCategories",
            "value": [
                {
                    "key": "ArchDev",
                    "value": "ArchDev",
                    "link": "ArchDev"
                },
                {
                    "key": "Changing Smile of Childhood and its Second Coming",
                    "value": "Childhood",
                    "link": "changing-smile-of-childhood-and-its-second-coming"
                },
                {
                    "key": "India and her Relationship with the World",
                    "value": "IndoWorld",
                    "link": "india-and-her-relationship-with-the-world"
                },
                {
                    "key": "History & Historiography of Indian Scholarship in the Arts, Social Sciences & Humanities",
                    "value": "Scholarship",
                    "link": "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities"
                }
            ],
            "display": "Research categories"
        },
        {
            "key": "creator",
            "value": [
                {
                    "key": "Praveen",
                    "value": "Praveen",
                    "first_name": "Yogesh",
                    "last_name": "Praveen"
                }
            ],
            "display": "Creator"
        }
    ],
    [
        {
            "key": "id",
            "value": "",
            "display": "Id"
        },
        {
            "key": "title",
            "value": null,
            "display": "Title"
        },
        {
            "key": "alias",
            "value": "",
            "display": "Alias"
        },
        {
            "key": "web_serial_number",
            "value": null,
            "display": "Web serial number"
        },
        {
            "key": "reference_code",
            "value": null,
            "display": "Reference code"
        },
        {
            "key": "web_display",
            "value": null,
            "display": "Web display"
        },
        {
            "key": "date_of_library_publication",
            "value": null,
            "display": "Date of library publication"
        },
        {
            "key": "place_of_publication",
            "value": "",
            "display": "Place of publication"
        },
        {
            "key": "histographical_note",
            "value": null,
            "display": "Histographical note"
        },
        {
            "key": "main_historical_note",
            "value": null,
            "display": "Main historical note"
        },
        {
            "key": "binding_status",
            "value": "PB",
            "display": "Binding status"
        },
        {
            "key": "accession_number",
            "value": "",
            "display": "Accession number"
        },
        {
            "key": "cdt",
            "value": null,
            "display": "CDT"
        },
        {
            "key": "country_of_publication",
            "value": "India",
            "display": "Country of publication"
        },
        {
            "key": "date_of_published_edition_in_library",
            "value": "",
            "display": "Date of published edition in library"
        },
        {
            "key": "dates_of_first_edition",
            "value": "",
            "display": "Dates of first edition"
        },
        {
            "key": "isbn_issn_number",
            "value": "",
            "display": "Isbn issn number"
        },
        {
            "key": "language_of_publication",
            "value": "",
            "display": "Language of publication"
        },
        {
            "key": "number_of_pages_in_publication_text",
            "value": null,
            "display": "Number of pages in publication text"
        },
        {
            "key": "personality_film_visually_featured_on_cover",
            "value": null,
            "display": "Personality film visually featured on cover"
        },
        {
            "key": "publisher",
            "value": "",
            "display": "Publisher"
        },
        {
            "key": "serial_number",
            "value": null,
            "display": "Serial number"
        },
        {
            "key": "sortable_year_date_of_publication",
            "value": "",
            "display": "Sortable year date of publication"
        },
        {
            "key": "sub_title_of_document_book",
            "value": null,
            "display": "Sub title of document book"
        },
        {
            "key": "title_of_document_book",
            "value": null,
            "display": "Title of document book"
        },
        {
            "key": "book_title_of_document",
            "value": "",
            "display": "Book title of document"
        },
        {
            "key": "book_sub_title_of_document",
            "value": "",
            "display": "Book sub title of document"
        },
        {
            "key": "volume_issue_number",
            "value": null,
            "display": "Volume issue number"
        },
        {
            "key": "masterlist",
            "value": "",
            "display": "Masterlist"
        },
        {
            "key": "image",
            "value": [LucknowRC11],
            "display": "Image"
        },
        {
            "key": "document_type",
            "value": "",
            "display": "Document type"
        },
        {
            "key": "title_and_type_of_creator",
            "value": "",
            "display": "Title and type of creator"
        },
        {
            "key": "number_of_pages_in_publication",
            "value": null,
            "display": "Number of pages in publication"
        },
        {
            "key": "researchCategories",
            "value": [
                {
                    "key": "ArchDev",
                    "value": "",
                    "link": "ArchDev"
                },
                {
                    "key": "Changing Smile of Childhood and its Second Coming",
                    "value": "Childhood",
                    "link": "changing-smile-of-childhood-and-its-second-coming"
                },
                {
                    "key": "India and her Relationship with the World",
                    "value": "IndoWorld",
                    "link": "india-and-her-relationship-with-the-world"
                },
                {
                    "key": "History & Historiography of Indian Scholarship in the Arts, Social Sciences & Humanities",
                    "value": "Scholarship",
                    "link": "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities"
                }
            ],
            "display": "Research categories"
        },
        {
            "key": "creator",
            "value": [
                {
                    "key": "",
                    "value": "",
                    "first_name": "",
                    "last_name": "Content Awaited"
                }
            ],
            "display": "Creator"
        }
    ],
    [
        {
            "key": "id",
            "value": "",
            "display": "Id"
        },
        {
            "key": "title",
            "value": null,
            "display": "Title"
        },
        {
            "key": "alias",
            "value": "",
            "display": "Alias"
        },
        {
            "key": "web_serial_number",
            "value": null,
            "display": "Web serial number"
        },
        {
            "key": "reference_code",
            "value": null,
            "display": "Reference code"
        },
        {
            "key": "web_display",
            "value": null,
            "display": "Web display"
        },
        {
            "key": "date_of_library_publication",
            "value": null,
            "display": "Date of library publication"
        },
        {
            "key": "place_of_publication",
            "value": "",
            "display": "Place of publication"
        },
        {
            "key": "histographical_note",
            "value": null,
            "display": "Histographical note"
        },
        {
            "key": "main_historical_note",
            "value": null,
            "display": "Main historical note"
        },
        {
            "key": "binding_status",
            "value": "PB",
            "display": "Binding status"
        },
        {
            "key": "accession_number",
            "value": "",
            "display": "Accession number"
        },
        {
            "key": "cdt",
            "value": null,
            "display": "CDT"
        },
        {
            "key": "country_of_publication",
            "value": "India",
            "display": "Country of publication"
        },
        {
            "key": "date_of_published_edition_in_library",
            "value": "",
            "display": "Date of published edition in library"
        },
        {
            "key": "dates_of_first_edition",
            "value": "",
            "display": "Dates of first edition"
        },
        {
            "key": "isbn_issn_number",
            "value": "",
            "display": "Isbn issn number"
        },
        {
            "key": "language_of_publication",
            "value": "",
            "display": "Language of publication"
        },
        {
            "key": "number_of_pages_in_publication_text",
            "value": null,
            "display": "Number of pages in publication text"
        },
        {
            "key": "personality_film_visually_featured_on_cover",
            "value": null,
            "display": "Personality film visually featured on cover"
        },
        {
            "key": "publisher",
            "value": "",
            "display": "Publisher"
        },
        {
            "key": "serial_number",
            "value": null,
            "display": "Serial number"
        },
        {
            "key": "sortable_year_date_of_publication",
            "value": "",
            "display": "Sortable year date of publication"
        },
        {
            "key": "sub_title_of_document_book",
            "value": null,
            "display": "Sub title of document book"
        },
        {
            "key": "title_of_document_book",
            "value": null,
            "display": "Title of document book"
        },
        {
            "key": "book_title_of_document",
            "value": "",
            "display": "Book title of document"
        },
        {
            "key": "book_sub_title_of_document",
            "value": "",
            "display": "Book sub title of document"
        },
        {
            "key": "volume_issue_number",
            "value": null,
            "display": "Volume issue number"
        },
        {
            "key": "masterlist",
            "value": "",
            "display": "Masterlist"
        },
        {
            "key": "image",
            "value": [LucknowRC11],
            "display": "Image"
        },
        {
            "key": "document_type",
            "value": "",
            "display": "Document type"
        },
        {
            "key": "title_and_type_of_creator",
            "value": "",
            "display": "Title and type of creator"
        },
        {
            "key": "number_of_pages_in_publication",
            "value": null,
            "display": "Number of pages in publication"
        },
        {
            "key": "researchCategories",
            "value": [
                {
                    "key": "ArchDev",
                    "value": "",
                    "link": "ArchDev"
                },
                {
                    "key": "Changing Smile of Childhood and its Second Coming",
                    "value": "Childhood",
                    "link": "changing-smile-of-childhood-and-its-second-coming"
                },
                {
                    "key": "India and her Relationship with the World",
                    "value": "IndoWorld",
                    "link": "india-and-her-relationship-with-the-world"
                },
                {
                    "key": "History & Historiography of Indian Scholarship in the Arts, Social Sciences & Humanities",
                    "value": "Scholarship",
                    "link": "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities"
                }
            ],
            "display": "Research categories"
        },
        {
            "key": "creator",
            "value": [
                {
                    "key": "",
                    "value": "",
                    "first_name": "",
                    "last_name": "Content Awaited"
                }
            ],
            "display": "Creator"
        }
    ],
]
//biblography
export const lucknow_biblography_filter_options = {
  documentTypeFilter: [
    "Book.bka",
  ],
  yearFilter: [
    "1858",
    "1883",
    "1954",
    "1989",
    "1995",
    "2001",
    "2006",
    "2010",
    "2019",
    "xxx",
  ],
  creatorTypeFilter: [
    "Editor",
    "Author",
    "Publisher",
  ],
};

//pictorial_space biblography

export const pictorial_space_research_centre = [
  [
    {
      key: "id",
      value: "3d84381f-58d8-4bfc-b5d6-ba89a9d933fa",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bka-gupta-the-cinema-of-satyajit-ray-1980",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "New Delhi",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "HB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000009",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "India",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: ``,
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "1980",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "0-706910354",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "english",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "Vikas",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: `1987`,
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "The Cinema of Satyajit Ray",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "pictorial-space",
      display: "Masterlist",
    },
    {
      key: "image",
      value: [placeholder],
      display: "Image",
    },
    {
      key: "document_type",
      value: "bka",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "Cinema as a Key Educational Resource",
          value: "",
          link: "cinema-as-a-key-educational-resource",
        },
        {
          key: "History & Historiography of Indian Scholarship in the Arts, Social Sciences & Humanities",
          value: "",
          link: "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "",
          value: "",
          first_name: "",
          last_name: "",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "3d84381f-58d8-4bfc-b5d6-ba89a9d933fa",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bka-gupta-the-cinema-of-satyajit-ray-1980",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "New Delhi",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "HB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000009",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "India",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: `1987
          1997
          1999
          2007
          1999
          1995
          2006
          2007
          1994
          2010`,
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "1980",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "0-706910354",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "english",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "Vikas",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: `1997`,
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "The Cinema of Satyajit Ray",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "pictorial-space",
      display: "Masterlist",
    },
    {
      key: "image",
      value: [placeholder],
      display: "Image",
    },
    {
      key: "document_type",
      value: "bka",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "Cinema as a Key Educational Resource",
          value: "",
          link: "cinema-as-a-key-educational-resource",
        },
        {
          key: "History & Historiography of Indian Scholarship in the Arts, Social Sciences & Humanities",
          value: "",
          link: "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "",
          value: "",
          first_name: "",
          last_name: "",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "3d84381f-58d8-4bfc-b5d6-ba89a9d933fa",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bka-gupta-the-cinema-of-satyajit-ray-1980",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "New Delhi",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "HB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000009",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "India",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: `1987
          1997
          1999
          2007
          1999
          1995
          2006
          2007
          1994
          2010`,
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "1980",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "0-706910354",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "english",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "Vikas",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: `1999`,
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "The Cinema of Satyajit Ray",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "pictorial-space",
      display: "Masterlist",
    },
    {
      key: "image",
      value: [placeholder],
      display: "Image",
    },
    {
      key: "document_type",
      value: "bka",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "Cinema as a Key Educational Resource",
          value: "",
          link: "cinema-as-a-key-educational-resource",
        },
        {
          key: "History & Historiography of Indian Scholarship in the Arts, Social Sciences & Humanities",
          value: "",
          link: "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "",
          value: "",
          first_name: "",
          last_name: "",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "3d84381f-58d8-4bfc-b5d6-ba89a9d933fa",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bka-gupta-the-cinema-of-satyajit-ray-1980",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "New Delhi",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "HB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000009",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "India",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: `1987
          1997
          1999
          2007
          1999
          1995
          2006
          2007
          1994
          2010`,
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "1980",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "0-706910354",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "english",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "Vikas",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: `
          2007
          `,
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "The Cinema of Satyajit Ray",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "pictorial-space",
      display: "Masterlist",
    },
    {
      key: "image",
      value: [placeholder],
      display: "Image",
    },
    {
      key: "document_type",
      value: "bka",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "Cinema as a Key Educational Resource",
          value: "",
          link: "cinema-as-a-key-educational-resource",
        },
        {
          key: "History & Historiography of Indian Scholarship in the Arts, Social Sciences & Humanities",
          value: "",
          link: "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "",
          value: "",
          first_name: "",
          last_name: "",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "3d84381f-58d8-4bfc-b5d6-ba89a9d933fa",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bka-gupta-the-cinema-of-satyajit-ray-1980",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "New Delhi",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "HB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000009",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "India",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: `1987
          1997
          1999
          2007
          1999
          1995
          2006
          2007
          1994
          2010`,
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "1980",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "0-706910354",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "english",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "Vikas",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: `1999`,
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "The Cinema of Satyajit Ray",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "pictorial-space",
      display: "Masterlist",
    },
    {
      key: "image",
      value: [placeholder],
      display: "Image",
    },
    {
      key: "document_type",
      value: "bka",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "Cinema as a Key Educational Resource",
          value: "",
          link: "cinema-as-a-key-educational-resource",
        },
        {
          key: "History & Historiography of Indian Scholarship in the Arts, Social Sciences & Humanities",
          value: "",
          link: "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "",
          value: "",
          first_name: "",
          last_name: "",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "3d84381f-58d8-4bfc-b5d6-ba89a9d933fa",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bka-gupta-the-cinema-of-satyajit-ray-1980",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "New Delhi",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "HB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000009",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "India",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: `1987
          1997
          1999
          2007
          1999
          1995
          2006
          2007
          1994
          2010`,
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "1980",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "0-706910354",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "english",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "Vikas",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: `
          1995
          `,
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "The Cinema of Satyajit Ray",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "pictorial-space",
      display: "Masterlist",
    },
    {
      key: "image",
      value: [placeholder],
      display: "Image",
    },
    {
      key: "document_type",
      value: "bka",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "Cinema as a Key Educational Resource",
          value: "",
          link: "cinema-as-a-key-educational-resource",
        },
        {
          key: "History & Historiography of Indian Scholarship in the Arts, Social Sciences & Humanities",
          value: "",
          link: "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "",
          value: "",
          first_name: "",
          last_name: "",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "3d84381f-58d8-4bfc-b5d6-ba89a9d933fa",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bka-gupta-the-cinema-of-satyajit-ray-1980",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "New Delhi",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "HB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000009",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "India",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: `1987
          1997
          1999
          2007
          1999
          1995
          2006
          2007
          1994
          2010`,
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "1980",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "0-706910354",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "english",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "Vikas",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: `2006`,
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "The Cinema of Satyajit Ray",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "pictorial-space",
      display: "Masterlist",
    },
    {
      key: "image",
      value: [placeholder],
      display: "Image",
    },
    {
      key: "document_type",
      value: "bka",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "Cinema as a Key Educational Resource",
          value: "",
          link: "cinema-as-a-key-educational-resource",
        },
        {
          key: "History & Historiography of Indian Scholarship in the Arts, Social Sciences & Humanities",
          value: "",
          link: "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "",
          value: "",
          first_name: "",
          last_name: "",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "3d84381f-58d8-4bfc-b5d6-ba89a9d933fa",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bka-gupta-the-cinema-of-satyajit-ray-1980",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "New Delhi",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "HB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000009",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "India",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: `1987
          1997
          1999
          2007
          1999
          1995
          2006
          2007
          1994
          2010`,
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "1980",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "0-706910354",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "english",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "Vikas",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: `2007`,
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "The Cinema of Satyajit Ray",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "pictorial-space",
      display: "Masterlist",
    },
    {
      key: "image",
      value: [placeholder],
      display: "Image",
    },
    {
      key: "document_type",
      value: "bka",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "Cinema as a Key Educational Resource",
          value: "",
          link: "cinema-as-a-key-educational-resource",
        },
        {
          key: "History & Historiography of Indian Scholarship in the Arts, Social Sciences & Humanities",
          value: "",
          link: "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "",
          value: "",
          first_name: "",
          last_name: "",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "3d84381f-58d8-4bfc-b5d6-ba89a9d933fa",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bka-gupta-the-cinema-of-satyajit-ray-1980",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "New Delhi",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "HB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000009",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "India",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: `1987
          1997
          1999
          2007
          1999
          1995
          2006
          2007
          1994
          2010`,
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "1980",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "0-706910354",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "english",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "Vikas",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: `1994`,
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "The Cinema of Satyajit Ray",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "pictorial-space",
      display: "Masterlist",
    },
    {
      key: "image",
      value: [placeholder],
      display: "Image",
    },
    {
      key: "document_type",
      value: "bka",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "Cinema as a Key Educational Resource",
          value: "",
          link: "cinema-as-a-key-educational-resource",
        },
        {
          key: "History & Historiography of Indian Scholarship in the Arts, Social Sciences & Humanities",
          value: "",
          link: "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "",
          value: "",
          first_name: "",
          last_name: "",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "3d84381f-58d8-4bfc-b5d6-ba89a9d933fa",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bka-gupta-the-cinema-of-satyajit-ray-1980",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "New Delhi",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "HB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000009",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "India",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: `1987
          1997
          1999
          2007
          1999
          1995
          2006
          2007
          1994
          2010`,
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "1980",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "0-706910354",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "english",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "Vikas",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: `2010`,
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "The Cinema of Satyajit Ray",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "pictorial-space",
      display: "Masterlist",
    },
    {
      key: "image",
      value: [placeholder],
      display: "Image",
    },
    {
      key: "document_type",
      value: "bka",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "Cinema as a Key Educational Resource",
          value: "",
          link: "cinema-as-a-key-educational-resource",
        },
        {
          key: "History & Historiography of Indian Scholarship in the Arts, Social Sciences & Humanities",
          value: "",
          link: "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "",
          value: "",
          first_name: "",
          last_name: "",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "3d84381f-58d8-4bfc-b5d6-ba89a9d933fa",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bka-gupta-the-cinema-of-satyajit-ray-1980",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "New Delhi",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "HB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000009",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "India",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: ``,
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "1980",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "0-706910354",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "english",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "Vikas",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: `1987`,
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "The Cinema of Satyajit Ray",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "pictorial-space",
      display: "Masterlist",
    },
    {
      key: "image",
      value: [placeholder],
      display: "Image",
    },
    {
      key: "document_type",
      value: "bka",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "Cinema as a Key Educational Resource",
          value: "",
          link: "cinema-as-a-key-educational-resource",
        },
        {
          key: "History & Historiography of Indian Scholarship in the Arts, Social Sciences & Humanities",
          value: "",
          link: "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "",
          value: "",
          first_name: "",
          last_name: "",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "3d84381f-58d8-4bfc-b5d6-ba89a9d933fa",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bka-gupta-the-cinema-of-satyajit-ray-1980",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "New Delhi",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "HB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000009",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "India",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: `1987
            1997
            1999
            2007
            1999
            1995
            2006
            2007
            1994
            2010`,
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "1980",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "0-706910354",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "english",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "Vikas",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: `1997`,
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "The Cinema of Satyajit Ray",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "pictorial-space",
      display: "Masterlist",
    },
    {
      key: "image",
      value: [placeholder],
      display: "Image",
    },
    {
      key: "document_type",
      value: "bka",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "Cinema as a Key Educational Resource",
          value: "",
          link: "cinema-as-a-key-educational-resource",
        },
        {
          key: "History & Historiography of Indian Scholarship in the Arts, Social Sciences & Humanities",
          value: "",
          link: "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "",
          value: "",
          first_name: "",
          last_name: "",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "3d84381f-58d8-4bfc-b5d6-ba89a9d933fa",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bka-gupta-the-cinema-of-satyajit-ray-1980",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "New Delhi",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "HB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000009",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "India",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: `1987
            1997
            1999
            2007
            1999
            1995
            2006
            2007
            1994
            2010`,
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "1980",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "0-706910354",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "english",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "Vikas",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: `1999`,
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "The Cinema of Satyajit Ray",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "pictorial-space",
      display: "Masterlist",
    },
    {
      key: "image",
      value: [placeholder],
      display: "Image",
    },
    {
      key: "document_type",
      value: "bka",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "Cinema as a Key Educational Resource",
          value: "",
          link: "cinema-as-a-key-educational-resource",
        },
        {
          key: "History & Historiography of Indian Scholarship in the Arts, Social Sciences & Humanities",
          value: "",
          link: "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "",
          value: "",
          first_name: "",
          last_name: "",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "3d84381f-58d8-4bfc-b5d6-ba89a9d933fa",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bka-gupta-the-cinema-of-satyajit-ray-1980",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "New Delhi",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "HB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000009",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "India",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: `1987
            1997
            1999
            2007
            1999
            1995
            2006
            2007
            1994
            2010`,
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "1980",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "0-706910354",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "english",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "Vikas",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: `
            2007
            `,
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "The Cinema of Satyajit Ray",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "pictorial-space",
      display: "Masterlist",
    },
    {
      key: "image",
      value: [placeholder],
      display: "Image",
    },
    {
      key: "document_type",
      value: "bka",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "Cinema as a Key Educational Resource",
          value: "",
          link: "cinema-as-a-key-educational-resource",
        },
        {
          key: "History & Historiography of Indian Scholarship in the Arts, Social Sciences & Humanities",
          value: "",
          link: "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "",
          value: "",
          first_name: "",
          last_name: "",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "3d84381f-58d8-4bfc-b5d6-ba89a9d933fa",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bka-gupta-the-cinema-of-satyajit-ray-1980",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "New Delhi",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "HB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000009",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "India",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: `1987
            1997
            1999
            2007
            1999
            1995
            2006
            2007
            1994
            2010`,
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "1980",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "0-706910354",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "english",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "Vikas",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: `1999`,
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "The Cinema of Satyajit Ray",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "pictorial-space",
      display: "Masterlist",
    },
    {
      key: "image",
      value: [placeholder],
      display: "Image",
    },
    {
      key: "document_type",
      value: "bka",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "Cinema as a Key Educational Resource",
          value: "",
          link: "cinema-as-a-key-educational-resource",
        },
        {
          key: "History & Historiography of Indian Scholarship in the Arts, Social Sciences & Humanities",
          value: "",
          link: "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "",
          value: "",
          first_name: "",
          last_name: "",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "3d84381f-58d8-4bfc-b5d6-ba89a9d933fa",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bka-gupta-the-cinema-of-satyajit-ray-1980",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "New Delhi",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "HB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000009",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "India",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: `1987
            1997
            1999
            2007
            1999
            1995
            2006
            2007
            1994
            2010`,
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "1980",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "0-706910354",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "english",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "Vikas",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: `
            1995
            `,
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "The Cinema of Satyajit Ray",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "pictorial-space",
      display: "Masterlist",
    },
    {
      key: "image",
      value: [placeholder],
      display: "Image",
    },
    {
      key: "document_type",
      value: "bka",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "Cinema as a Key Educational Resource",
          value: "",
          link: "cinema-as-a-key-educational-resource",
        },
        {
          key: "History & Historiography of Indian Scholarship in the Arts, Social Sciences & Humanities",
          value: "",
          link: "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "",
          value: "",
          first_name: "",
          last_name: "",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "3d84381f-58d8-4bfc-b5d6-ba89a9d933fa",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bka-gupta-the-cinema-of-satyajit-ray-1980",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "New Delhi",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "HB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000009",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "India",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: `1987
            1997
            1999
            2007
            1999
            1995
            2006
            2007
            1994
            2010`,
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "1980",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "0-706910354",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "english",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "Vikas",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: `2006`,
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "The Cinema of Satyajit Ray",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "pictorial-space",
      display: "Masterlist",
    },
    {
      key: "image",
      value: [placeholder],
      display: "Image",
    },
    {
      key: "document_type",
      value: "bka",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "Cinema as a Key Educational Resource",
          value: "",
          link: "cinema-as-a-key-educational-resource",
        },
        {
          key: "History & Historiography of Indian Scholarship in the Arts, Social Sciences & Humanities",
          value: "",
          link: "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "",
          value: "",
          first_name: "",
          last_name: "",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "3d84381f-58d8-4bfc-b5d6-ba89a9d933fa",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bka-gupta-the-cinema-of-satyajit-ray-1980",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "New Delhi",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "HB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000009",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "India",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: `1987
            1997
            1999
            2007
            1999
            1995
            2006
            2007
            1994
            2010`,
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "1980",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "0-706910354",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "english",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "Vikas",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: `2007`,
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "The Cinema of Satyajit Ray",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "pictorial-space",
      display: "Masterlist",
    },
    {
      key: "image",
      value: [placeholder],
      display: "Image",
    },
    {
      key: "document_type",
      value: "bka",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "Cinema as a Key Educational Resource",
          value: "",
          link: "cinema-as-a-key-educational-resource",
        },
        {
          key: "History & Historiography of Indian Scholarship in the Arts, Social Sciences & Humanities",
          value: "",
          link: "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "",
          value: "",
          first_name: "",
          last_name: "",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "3d84381f-58d8-4bfc-b5d6-ba89a9d933fa",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bka-gupta-the-cinema-of-satyajit-ray-1980",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "New Delhi",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "HB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000009",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "India",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: `1987
            1997
            1999
            2007
            1999
            1995
            2006
            2007
            1994
            2010`,
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "1980",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "0-706910354",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "english",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "Vikas",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: `1994`,
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "The Cinema of Satyajit Ray",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "pictorial-space",
      display: "Masterlist",
    },
    {
      key: "image",
      value: [placeholder],
      display: "Image",
    },
    {
      key: "document_type",
      value: "bka",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "Cinema as a Key Educational Resource",
          value: "",
          link: "cinema-as-a-key-educational-resource",
        },
        {
          key: "History & Historiography of Indian Scholarship in the Arts, Social Sciences & Humanities",
          value: "",
          link: "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "",
          value: "",
          first_name: "",
          last_name: "",
        },
      ],
      display: "Creator",
    },
  ],
  [
    {
      key: "id",
      value: "3d84381f-58d8-4bfc-b5d6-ba89a9d933fa",
      display: "Id",
    },
    {
      key: "title",
      value: null,
      display: "Title",
    },
    {
      key: "alias",
      value: "bka-gupta-the-cinema-of-satyajit-ray-1980",
      display: "Alias",
    },
    {
      key: "web_serial_number",
      value: null,
      display: "Web serial number",
    },
    {
      key: "reference_code",
      value: null,
      display: "Reference code",
    },
    {
      key: "web_display",
      value: null,
      display: "Web display",
    },
    {
      key: "date_of_library_publication",
      value: null,
      display: "Date of library publication",
    },
    {
      key: "place_of_publication",
      value: "New Delhi",
      display: "Place of publication",
    },
    {
      key: "histographical_note",
      value: null,
      display: "Histographical note",
    },
    {
      key: "main_historical_note",
      value: null,
      display: "Main historical note",
    },
    {
      key: "binding_status",
      value: "HB",
      display: "Binding status",
    },
    {
      key: "accession_number",
      value: "0000009",
      display: "Accession number",
    },
    {
      key: "cdt",
      value: null,
      display: "CDT",
    },
    {
      key: "country_of_publication",
      value: "India",
      display: "Country of publication",
    },
    {
      key: "date_of_published_edition_in_library",
      value: `1987
            1997
            1999
            2007
            1999
            1995
            2006
            2007
            1994
            2010`,
      display: "Date of published edition in library",
    },
    {
      key: "dates_of_first_edition",
      value: "1980",
      display: "Dates of first edition",
    },
    {
      key: "isbn_issn_number",
      value: "0-706910354",
      display: "Isbn issn number",
    },
    {
      key: "language_of_publication",
      value: "english",
      display: "Language of publication",
    },
    {
      key: "number_of_pages_in_publication_text",
      value: null,
      display: "Number of pages in publication text",
    },
    {
      key: "personality_film_visually_featured_on_cover",
      value: null,
      display: "Personality film visually featured on cover",
    },
    {
      key: "publisher",
      value: "Vikas",
      display: "Publisher",
    },
    {
      key: "serial_number",
      value: null,
      display: "Serial number",
    },
    {
      key: "sortable_year_date_of_publication",
      value: `2010`,
      display: "Sortable year date of publication",
    },
    {
      key: "sub_title_of_document_book",
      value: null,
      display: "Sub title of document book",
    },
    {
      key: "title_of_document_book",
      value: null,
      display: "Title of document book",
    },
    {
      key: "book_title_of_document",
      value: "The Cinema of Satyajit Ray",
      display: "Book title of document",
    },
    {
      key: "book_sub_title_of_document",
      value: "",
      display: "Book sub title of document",
    },
    {
      key: "volume_issue_number",
      value: null,
      display: "Volume issue number",
    },
    {
      key: "masterlist",
      value: "pictorial-space",
      display: "Masterlist",
    },
    {
      key: "image",
      value: [placeholder],
      display: "Image",
    },
    {
      key: "document_type",
      value: "bka",
      display: "Document type",
    },
    {
      key: "title_and_type_of_creator",
      value: "",
      display: "Title and type of creator",
    },
    {
      key: "number_of_pages_in_publication",
      value: null,
      display: "Number of pages in publication",
    },
    {
      key: "researchCategories",
      value: [
        {
          key: "Cinema as a Key Educational Resource",
          value: "",
          link: "cinema-as-a-key-educational-resource",
        },
        {
          key: "History & Historiography of Indian Scholarship in the Arts, Social Sciences & Humanities",
          value: "",
          link: "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities",
        },
      ],
      display: "Research categories",
    },
    {
      key: "creator",
      value: [
        {
          key: "",
          value: "",
          first_name: "",
          last_name: "",
        },
      ],
      display: "Creator",
    },
  ],
];

  export const pictorial_space_biblography_filter_options = [
    {
        key:"creatorType",
        display:filterAttribute.AUTHOR_EDITOR_CONTRIBUTOR,
        value: [
        ]}
    ,{
        key:"year",
        display:filterAttribute.DATE,
        value: [
    ]},
    {
        key:"docType",
        display:filterAttribute.DOCUMENT_TYPE,
        value: [],
    }
  ];

// Raj Kapoor bibliography

export const raj_kapoor_research_centre=[
      [
        {
          "key": "id",
          "value": "12",
          "display": "Id"
        },
        {
          "key": "title",
          "value": "75 Years of Indian Cinema",
          "display": "Title"
        },
        {
          "key": "alias",
          "value": "book.bka-rangoonwalla--1975",
          "display": "Alias"
        },
        {
          "key": "web_serial_number",
          "value": null,
          "display": "Web serial number"
        },
        {
          "key": "reference_code",
          "value": null,
          "display": "Reference code"
        },
        {
          "key": "web_display",
          "value": "P#251_180a.jpg",
          "display": "Web display"
        },
        {
          "key": "date_of_library_publication",
          "value": "1975",
          "display": "Date of library publication"
        },
        {
          "key": "place_of_publication",
          "value": "New Delhi",
          "display": "Place of publication"
        },
        {
          "key": "histographical_note",
          "value": null,
          "display": "Histographical note"
        },
        {
          "key": "main_historical_note",
          "value": null,
          "display": "Main historical note"
        },
        {
          "key": "binding_status",
          "value": null,
          "display": "Binding status"
        },
        {
          "key": "accession_number",
          "value": "BOOK.bka",
          "display": "Accession number"
        },
        {
          "key": "cdt",
          "value": null,
          "display": "CDT"
        },
        {
          "key": "country_of_publication",
          "value": "India",
          "display": "Country of publication"
        },
        {
          "key": "date_of_published_edition_in_library",
          "value": "1975",
          "display": "Date of published edition in library"
        },
        {
          "key": "dates_of_first_edition",
          "value": "1975",
          "display": "Dates of first edition"
        },
        {
          "key": "isbn_issn_number",
          "value": null,
          "display": "Isbn issn number"
        },
        {
          "key": "language_of_publication",
          "value": "English",
          "display": "Language of publication"
        },
        {
          "key": "number_of_pages_in_publication_text",
          "value": null,
          "display": "Number of pages in publication text"
        },
        {
          "key": "personality_film_visually_featured_on_cover",
          "value": null,
          "display": "Personality film visually featured on cover"
        },
        {
          "key": "publisher",
          "value": "Indian Book Co.",
          "display": "Publisher"
        },
        {
          "key": "serial_number",
          "value": null,
          "display": "Serial number"
        },
        {
          "key": "sortable_year_date_of_publication",
          "value": "1975",
          "display": "Sortable year date of publication"
        },
        {
          "key": "sub_title_of_document_book",
          "value": null,
          "display": "Sub title of document book"
        },
        {
          "key": "title_of_document_book",
          "value": "75 Years of Indian Cinema",
          "display": "Title of document book"
        },
        {
          "key": "book_title_of_document",
          "value": "75 Years of Indian Cinema",
          "display": "Book title of document"
        },
        {
          "key": "book_sub_title_of_document",
          "value": null,
          "display": "Book sub title of document"
        },
        {
          "key": "volume_issue_number",
          "value": null,
          "display": "Volume issue number"
        },
        {
          "key": "masterlist",
          "value": "raj-kapoor",
          "display": "Masterlist"
        },
        {
          "key": "image",
          "value": ["https://tuliresearchcentre.canto.global/direct/image/68r2mc6k1h5k9e2kltiijmce6c/QgPMP25OpLoZtnPENqIOaXE6uzg/m800/800"],
          "display": "Image"
        },
        {
          "key": "document_type",
          "value": "bka",
          "display": "Document type"
        },
        {
          "key": "title_and_type_of_creator",
          "value": "Author",
          "display": "Title and type of creator"
        },
        {
          "key": "number_of_pages_in_publication",
          "value": null,
          "display": "Number of pages in publication"
        },
        {
          "key": "researchCategories",
          "value": [
            {
              "key": "Cinema as a Key Educational Resource",
              "value": "CinemaE",
              "link": "cinema-as-a-key-educational-resource"
            }
          ],
          "display": "Research categories"
        },
        {
          "key": "creator",
          "value": [
            {
              "key": "firoze-rangoonwalla",
              "value": "Firoze Rangoonwalla",
              "first_name": "Firoze",
              "last_name": "Rangoonwalla"
            }
          ],
          "display": "Creator"
        }
      ],
      [
        {
          "key": "id",
          "value": "11",
          "display": "Id"
        },
        {
          "key": "title",
          "value": "I am Not an Island",
          "display": "Title"
        },
        {
          "key": "alias",
          "value": "book.bka-abbas-an-experiment-in-autobiography-1977",
          "display": "Alias"
        },
        {
          "key": "web_serial_number",
          "value": null,
          "display": "Web serial number"
        },
        {
          "key": "reference_code",
          "value": null,
          "display": "Reference code"
        },
        {
          "key": "web_display",
          "value": "P#251_201a.jpg",
          "display": "Web display"
        },
        {
          "key": "date_of_library_publication",
          "value": "1977",
          "display": "Date of library publication"
        },
        {
          "key": "place_of_publication",
          "value": "New Delhi",
          "display": "Place of publication"
        },
        {
          "key": "histographical_note",
          "value": null,
          "display": "Histographical note"
        },
        {
          "key": "main_historical_note",
          "value": null,
          "display": "Main historical note"
        },
        {
          "key": "binding_status",
          "value": null,
          "display": "Binding status"
        },
        {
          "key": "accession_number",
          "value": "BOOK.bka",
          "display": "Accession number"
        },
        {
          "key": "cdt",
          "value": null,
          "display": "CDT"
        },
        {
          "key": "country_of_publication",
          "value": "India",
          "display": "Country of publication"
        },
        {
          "key": "date_of_published_edition_in_library",
          "value": "1977",
          "display": "Date of published edition in library"
        },
        {
          "key": "dates_of_first_edition",
          "value": "1977",
          "display": "Dates of first edition"
        },
        {
          "key": "isbn_issn_number",
          "value": null,
          "display": "Isbn issn number"
        },
        {
          "key": "language_of_publication",
          "value": "English",
          "display": "Language of publication"
        },
        {
          "key": "number_of_pages_in_publication_text",
          "value": null,
          "display": "Number of pages in publication text"
        },
        {
          "key": "personality_film_visually_featured_on_cover",
          "value": null,
          "display": "Personality film visually featured on cover"
        },
        {
          "key": "publisher",
          "value": "Vikas",
          "display": "Publisher"
        },
        {
          "key": "serial_number",
          "value": null,
          "display": "Serial number"
        },
        {
          "key": "sortable_year_date_of_publication",
          "value": "1977",
          "display": "Sortable year date of publication"
        },
        {
          "key": "sub_title_of_document_book",
          "value": "An Experiment in Autobiography",
          "display": "Sub title of document book"
        },
        {
          "key": "title_of_document_book",
          "value": "I am Not an Island",
          "display": "Title of document book"
        },
        {
          "key": "book_title_of_document",
          "value": "I am Not an Island",
          "display": "Book title of document"
        },
        {
          "key": "book_sub_title_of_document",
          "value": "An Experiment in Autobiography",
          "display": "Book sub title of document"
        },
        {
          "key": "volume_issue_number",
          "value": null,
          "display": "Volume issue number"
        },
        {
          "key": "masterlist",
          "value": "raj-kapoor",
          "display": "Masterlist"
        },
        {
          "key": "image",
          "value": ["https://tuliresearchcentre.canto.global/direct/image/4tmqgbjvet4it7g8vvtdpu3462/Lt1yZ-8mIl8El7HYSOFS7YDpaBI/m800/800"],
          "display": "Image"
        },
        {
          "key": "document_type",
          "value": "bka",
          "display": "Document type"
        },
        {
          "key": "title_and_type_of_creator",
          "value": "Author",
          "display": "Title and type of creator"
        },
        {
          "key": "number_of_pages_in_publication",
          "value": null,
          "display": "Number of pages in publication"
        },
        {
          "key": "researchCategories",
          "value": [
            {
              "key": "Cinema as a Key Educational Resource",
              "value": "CinemaE",
              "link": "cinema-as-a-key-educational-resource"
            }
          ],
          "display": "Research categories"
        },
        {
          "key": "creator",
          "value": [
            {
              "key": "khwaja-ahmad-abbas",
              "value": "Khwaja Ahmed Abbas",
              "first_name": "Khwaja Ahmed",
              "last_name": "Abbas"
            }
          ],
          "display": "Creator"
        }
      ],   
    [
        {
          "key": "id",
          "value": "1",
          "display": "Id"
        },
        {
          "key": "title",
          "value": "Raj Kapoor",
          "display": "Title"
        },
        {
          "key": "alias",
          "value": "book.bka-reuben-the-fabulous-showman-1988",
          "display": "Alias"
        },
        {
          "key": "web_serial_number",
          "value": null,
          "display": "Web serial number"
        },
        {
          "key": "reference_code",
          "value": null,
          "display": "Reference code"
        },
        {
          "key": "web_display",
          "value": null,
          "display": "Web display"
        },
        {
          "key": "date_of_library_publication",
          "value": null,
          "display": "Date of library publication"
        },
        {
          "key": "place_of_publication",
          "value": "Bombay",
          "display": "Place of publication"
        },
        {
          "key": "histographical_note",
          "value": null,
          "display": "Histographical note"
        },
        {
          "key": "main_historical_note",
          "value": null,
          "display": "Main historical note"
        },
        {
          "key": "binding_status",
          "value": "PB",
          "display": "Binding status"
        },
        {
          "key": "accession_number",
          "value": "",
          "display": "Accession number"
        },
        {
          "key": "cdt",
          "value": null,
          "display": "CDT"
        },
        {
          "key": "country_of_publication",
          "value": "India",
          "display": "Country of publication"
        },
        {
          "key": "date_of_published_edition_in_library",
          "value": "1988",
          "display": "Date of published edition in library"
        },
        {
          "key": "dates_of_first_edition",
          "value": "",
          "display": "Dates of first edition"
        },
        {
          "key": "isbn_issn_number",
          "value": "",
          "display": "Isbn issn number"
        },
        {
          "key": "language_of_publication",
          "value": "",
          "display": "Language of publication"
        },
        {
          "key": "number_of_pages_in_publication_text",
          "value": null,
          "display": "Number of pages in publication text"
        },
        {
          "key": "personality_film_visually_featured_on_cover",
          "value": null,
          "display": "Personality film visually featured on cover"
        },
        {
          "key": "publisher",
          "value": "Virgo Books",
          "display": "Publisher"
        },
        {
          "key": "serial_number",
          "value": null,
          "display": "Serial number"
        },
        {
          "key": "sortable_year_date_of_publication",
          "value": "1988",
          "display": "Sortable year date of publication"
        },
        {
          "key": "sub_title_of_document_book",
          "value": null,
          "display": "Sub title of document book"
        },
        {
          "key": "title_of_document_book",
          "value": null,
          "display": "Title of document book"
        },
        {
          "key": "book_title_of_document",
          "value": "Raj Kapoor",
          "display": "Book title of document"
        },
        {
          "key": "book_sub_title_of_document",
          "value": "The Fabulous Showman",
          "display": "Book sub title of document"
        },
        {
          "key": "volume_issue_number",
          "value": null,
          "display": "Volume issue number"
        },
        {
          "key": "masterlist",
          "value": "raj-kapoor",
          "display": "Masterlist"
        },
        {
          "key": "image",
          "value": ["https://tuliresearchcentre.canto.global/direct/image/3t3gc8relt63rcv3cifkgco75b/CjY2g-tRtXP7BZw9fkz78zxKjQU/m800/800"],
          "display": "Image"
        },
        {
          "key": "document_type",
          "value": "bka",
          "display": "Document type"
        },
        {
          "key": "title_and_type_of_creator",
          "value": "Author",
          "display": "Title and type of creator"
        },
        {
          "key": "number_of_pages_in_publication",
          "value": null,
          "display": "Number of pages in publication"
        },
        {
          "key": "researchCategories",
          "value": [
            {
              "key": "Cinema as a Key Educational Resource",
              "value": "CinemaE",
              "link": "cinema-as-a-key-educational-resource"
            }
          ],
          "display": "Research categories"
        },
        {
          "key": "creator",
          "value": [
            {
              "key": "bunny-reuben",
              "value": "Bunny Reuben",
              "first_name": "Bunny",
              "last_name": "Reuben"
            }
          ],
          "display": "Creator"
        }
      ],
      [
        {
          "key": "id",
          "value": "7",
          "display": "Id"
        },
        {
          "key": "title",
          "value": "Follywood Flashback",
          "display": "Title"
        },
        {
          "key": "alias",
          "value": "book.bka-reuben-a-collection-of-movie-memories-1993",
          "display": "Alias"
        },
        {
          "key": "web_serial_number",
          "value": null,
          "display": "Web serial number"
        },
        {
          "key": "reference_code",
          "value": null,
          "display": "Reference code"
        },
        {
          "key": "web_display",
          "value": null,
          "display": "Web display"
        },
        {
          "key": "date_of_library_publication",
          "value": null,
          "display": "Date of library publication"
        },
        {
          "key": "place_of_publication",
          "value": "New Delhi",
          "display": "Place of publication"
        },
        {
          "key": "histographical_note",
          "value": null,
          "display": "Histographical note"
        },
        {
          "key": "main_historical_note",
          "value": null,
          "display": "Main historical note"
        },
        {
          "key": "binding_status",
          "value": "PB",
          "display": "Binding status"
        },
        {
          "key": "accession_number",
          "value": "",
          "display": "Accession number"
        },
        {
          "key": "cdt",
          "value": null,
          "display": "CDT"
        },
        {
          "key": "country_of_publication",
          "value": "India",
          "display": "Country of publication"
        },
        {
          "key": "date_of_published_edition_in_library",
          "value": "1993",
          "display": "Date of published edition in library"
        },
        {
          "key": "dates_of_first_edition",
          "value": "1993",
          "display": "Dates of first edition"
        },
        {
          "key": "isbn_issn_number",
          "value": "",
          "display": "Isbn issn number"
        },
        {
          "key": "language_of_publication",
          "value": "",
          "display": "Language of publication"
        },
        {
          "key": "number_of_pages_in_publication_text",
          "value": null,
          "display": "Number of pages in publication text"
        },
        {
          "key": "personality_film_visually_featured_on_cover",
          "value": null,
          "display": "Personality film visually featured on cover"
        },
        {
          "key": "publisher",
          "value": "Harper Collins",
          "display": "Publisher"
        },
        {
          "key": "serial_number",
          "value": null,
          "display": "Serial number"
        },
        {
          "key": "sortable_year_date_of_publication",
          "value": "1993",
          "display": "Sortable year date of publication"
        },
        {
          "key": "sub_title_of_document_book",
          "value": "A Collection of Movie Memories",
          "display": "Sub title of document book"
        },
        {
          "key": "title_of_document_book",
          "value": "Follywood Flashback",
          "display": "Title of document book"
        },
        {
          "key": "book_title_of_document",
          "value": "Follywood Flashback",
          "display": "Book title of document"
        },
        {
          "key": "book_sub_title_of_document",
          "value": "A Collection of Movie Memories",
          "display": "Book sub title of document"
        },
        {
          "key": "volume_issue_number",
          "value": null,
          "display": "Volume issue number"
        },
        {
          "key": "masterlist",
          "value": "raj-kapoor",
          "display": "Masterlist"
        },
        {
          "key": "image",
          "value": ["https://tuliresearchcentre.canto.global/direct/image/i7tfmuiv155153u7uqufqenc6s/kE8DnEn-VXdlH1Lify8ErAKoO5Q/m800/800"],
          "display": "Image"
        },
        {
          "key": "document_type",
          "value": "bka",
          "display": "Document type"
        },
        {
          "key": "title_and_type_of_creator",
          "value": "Author",
          "display": "Title and type of creator"
        },
        {
          "key": "number_of_pages_in_publication",
          "value": null,
          "display": "Number of pages in publication"
        },
        {
          "key": "researchCategories",
          "value": [
            {
              "key": "Cinema as a Key Educational Resource",
              "value": "CinemaE",
              "link": "cinema-as-a-key-educational-resource"
            }
          ],
          "display": "Research categories"
        },
        {
          "key": "creator",
          "value": [
            {
              "key": "bunny-reuben",
              "value": "Bunny Reuben",
              "first_name": "Bunny",
              "last_name": "Reuben"
            }
          ],
          "display": "Creator"
        }
      ],
      [
        {
          "key": "id",
          "value": "3",
          "display": "Id"
        },
        {
          "key": "title",
          "value": "Raj Kapoor and Hindi Films",
          "display": "Title"
        },
        {
          "key": "alias",
          "value": "book.bka-jall-catalysts-of-political-socialization-in-india-1995",
          "display": "Alias"
        },
        {
          "key": "web_serial_number",
          "value": null,
          "display": "Web serial number"
        },
        {
          "key": "reference_code",
          "value": null,
          "display": "Reference code"
        },
        {
          "key": "web_display",
          "value": null,
          "display": "Web display"
        },
        {
          "key": "date_of_library_publication",
          "value": null,
          "display": "Date of library publication"
        },
        {
          "key": "place_of_publication",
          "value": "Calcutta",
          "display": "Place of publication"
        },
        {
          "key": "histographical_note",
          "value": null,
          "display": "Histographical note"
        },
        {
          "key": "main_historical_note",
          "value": null,
          "display": "Main historical note"
        },
        {
          "key": "binding_status",
          "value": "PB",
          "display": "Binding status"
        },
        {
          "key": "accession_number",
          "value": "",
          "display": "Accession number"
        },
        {
          "key": "cdt",
          "value": null,
          "display": "CDT"
        },
        {
          "key": "country_of_publication",
          "value": "India",
          "display": "Country of publication"
        },
        {
          "key": "date_of_published_edition_in_library",
          "value": "1995",
          "display": "Date of published edition in library"
        },
        {
          "key": "dates_of_first_edition",
          "value": "",
          "display": "Dates of first edition"
        },
        {
          "key": "isbn_issn_number",
          "value": "",
          "display": "Isbn issn number"
        },
        {
          "key": "language_of_publication",
          "value": "",
          "display": "Language of publication"
        },
        {
          "key": "number_of_pages_in_publication_text",
          "value": null,
          "display": "Number of pages in publication text"
        },
        {
          "key": "personality_film_visually_featured_on_cover",
          "value": null,
          "display": "Personality film visually featured on cover"
        },
        {
          "key": "publisher",
          "value": "Statesman",
          "display": "Publisher"
        },
        {
          "key": "serial_number",
          "value": null,
          "display": "Serial number"
        },
        {
          "key": "sortable_year_date_of_publication",
          "value": "1995",
          "display": "Sortable year date of publication"
        },
        {
          "key": "sub_title_of_document_book",
          "value": null,
          "display": "Sub title of document book"
        },
        {
          "key": "title_of_document_book",
          "value": null,
          "display": "Title of document book"
        },
        {
          "key": "book_title_of_document",
          "value": "Raj Kapoor and Hindi Films",
          "display": "Book title of document"
        },
        {
          "key": "book_sub_title_of_document",
          "value": "Catalysts of Political Socialization in India",
          "display": "Book sub title of document"
        },
        {
          "key": "volume_issue_number",
          "value": null,
          "display": "Volume issue number"
        },
        {
          "key": "masterlist",
          "value": "raj-kapoor",
          "display": "Masterlist"
        },
        {
          "key": "image",
          "value": ["https://tuliresearchcentre.canto.global/direct/image/hbfmtvsilh6vt71mh5ba878h3c/SGPF7osO6_XRb8ysqXVZYpAqJP4/m800/800"],
          "display": "Image"
        },
        {
          "key": "document_type",
          "value": "bka",
          "display": "Document type"
        },
        {
          "key": "title_and_type_of_creator",
          "value": "Author",
          "display": "Title and type of creator"
        },
        {
          "key": "number_of_pages_in_publication",
          "value": null,
          "display": "Number of pages in publication"
        },
        {
          "key": "researchCategories",
          "value": [
            {
              "key": "Cinema as a Key Educational Resource",
              "value": "CinemaE",
              "link": "cinema-as-a-key-educational-resource"
            }
          ],
          "display": "Research categories"
        },
        {
          "key": "creator",
          "value": [
            {
              "key": "hutokshi-jall",
              "value": "Hutokshi Jall",
              "first_name": "Hutokshi",
              "last_name": "Jall"
            }
          ],
          "display": "Creator"
        }
      ],
      [
        {
          "key": "id",
          "value": "4",
          "display": "Id"
        },
        {
          "key": "title",
          "value": "Raj Kapoor",
          "display": "Title"
        },
        {
          "key": "alias",
          "value": "book.bka-nanda-the-one-and-only-showman-2002",
          "display": "Alias"
        },
        {
          "key": "web_serial_number",
          "value": null,
          "display": "Web serial number"
        },
        {
          "key": "reference_code",
          "value": null,
          "display": "Reference code"
        },
        {
          "key": "web_display",
          "value": null,
          "display": "Web display"
        },
        {
          "key": "date_of_library_publication",
          "value": null,
          "display": "Date of library publication"
        },
        {
          "key": "place_of_publication",
          "value": "Noida",
          "display": "Place of publication"
        },
        {
          "key": "histographical_note",
          "value": null,
          "display": "Histographical note"
        },
        {
          "key": "main_historical_note",
          "value": null,
          "display": "Main historical note"
        },
        {
          "key": "binding_status",
          "value": "PB",
          "display": "Binding status"
        },
        {
          "key": "accession_number",
          "value": "",
          "display": "Accession number"
        },
        {
          "key": "cdt",
          "value": null,
          "display": "CDT"
        },
        {
          "key": "country_of_publication",
          "value": "India",
          "display": "Country of publication"
        },
        {
          "key": "date_of_published_edition_in_library",
          "value": "2017",
          "display": "Date of published edition in library"
        },
        {
          "key": "dates_of_first_edition",
          "value": "2002",
          "display": "Dates of first edition"
        },
        {
          "key": "isbn_issn_number",
          "value": "",
          "display": "Isbn issn number"
        },
        {
          "key": "language_of_publication",
          "value": "",
          "display": "Language of publication"
        },
        {
          "key": "number_of_pages_in_publication_text",
          "value": null,
          "display": "Number of pages in publication text"
        },
        {
          "key": "personality_film_visually_featured_on_cover",
          "value": null,
          "display": "Personality film visually featured on cover"
        },
        {
          "key": "publisher",
          "value": "Harper Collins",
          "display": "Publisher"
        },
        {
          "key": "serial_number",
          "value": null,
          "display": "Serial number"
        },
        {
          "key": "sortable_year_date_of_publication",
          "value": "2002",
          "display": "Sortable year date of publication"
        },
        {
          "key": "sub_title_of_document_book",
          "value": "The One and Only Showman",
          "display": "Sub title of document book"
        },
        {
          "key": "title_of_document_book",
          "value": "Raj Kapoor",
          "display": "Title of document book"
        },
        {
          "key": "book_title_of_document",
          "value": "Raj Kapoor",
          "display": "Book title of document"
        },
        {
          "key": "book_sub_title_of_document",
          "value": "The One and Only Showman",
          "display": "Book sub title of document"
        },
        {
          "key": "volume_issue_number",
          "value": null,
          "display": "Volume issue number"
        },
        {
          "key": "masterlist",
          "value": "raj-kapoor",
          "display": "Masterlist"
        },
        {
          "key": "image",
          "value": ["https://tuliresearchcentre.canto.global/direct/image/li27u0jvrl0q74f457pcgr8u61/UbKW0Q9rYLPZefVP-4TQ74CaKOE/m800/800"],
          "display": "Image"
        },
        {
          "key": "document_type",
          "value": "bka",
          "display": "Document type"
        },
        {
          "key": "title_and_type_of_creator",
          "value": "Author",
          "display": "Title and type of creator"
        },
        {
          "key": "number_of_pages_in_publication",
          "value": null,
          "display": "Number of pages in publication"
        },
        {
          "key": "researchCategories",
          "value": [
            {
              "key": "Cinema as a Key Educational Resource",
              "value": "CinemaE",
              "link": "cinema-as-a-key-educational-resource"
            }
          ],
          "display": "Research categories"
        },
        {
          "key": "creator",
          "value": [
            {
              "key": "ritu-nanda",
              "value": "Ritu Nanda",
              "first_name": "Ritu",
              "last_name": "Nanda"
            }
          ],
          "display": "Creator"
        }
      ],
      [
        {
          "key": "id",
          "value": "6",
          "display": "Id"
        },
        {
          "key": "title",
          "value": "Take 25",
          "display": "Title"
        },
        {
          "key": "alias",
          "value": "book.bka-somaaya-star-insights-&-attitudes-2002",
          "display": "Alias"
        },
        {
          "key": "web_serial_number",
          "value": null,
          "display": "Web serial number"
        },
        {
          "key": "reference_code",
          "value": null,
          "display": "Reference code"
        },
        {
          "key": "web_display",
          "value": null,
          "display": "Web display"
        },
        {
          "key": "date_of_library_publication",
          "value": null,
          "display": "Date of library publication"
        },
        {
          "key": "place_of_publication",
          "value": "New Delhi",
          "display": "Place of publication"
        },
        {
          "key": "histographical_note",
          "value": null,
          "display": "Histographical note"
        },
        {
          "key": "main_historical_note",
          "value": null,
          "display": "Main historical note"
        },
        {
          "key": "binding_status",
          "value": "PB",
          "display": "Binding status"
        },
        {
          "key": "accession_number",
          "value": "",
          "display": "Accession number"
        },
        {
          "key": "cdt",
          "value": null,
          "display": "CDT"
        },
        {
          "key": "country_of_publication",
          "value": "India",
          "display": "Country of publication"
        },
        {
          "key": "date_of_published_edition_in_library",
          "value": "2002",
          "display": "Date of published edition in library"
        },
        {
          "key": "dates_of_first_edition",
          "value": "2002",
          "display": "Dates of first edition"
        },
        {
          "key": "isbn_issn_number",
          "value": "",
          "display": "Isbn issn number"
        },
        {
          "key": "language_of_publication",
          "value": "",
          "display": "Language of publication"
        },
        {
          "key": "number_of_pages_in_publication_text",
          "value": null,
          "display": "Number of pages in publication text"
        },
        {
          "key": "personality_film_visually_featured_on_cover",
          "value": null,
          "display": "Personality film visually featured on cover"
        },
        {
          "key": "publisher",
          "value": "Puffin",
          "display": "Publisher"
        },
        {
          "key": "serial_number",
          "value": null,
          "display": "Serial number"
        },
        {
          "key": "sortable_year_date_of_publication",
          "value": "2002",
          "display": "Sortable year date of publication"
        },
        {
          "key": "sub_title_of_document_book",
          "value": "Star Insights & Attitudes",
          "display": "Sub title of document book"
        },
        {
          "key": "title_of_document_book",
          "value": "Take 25",
          "display": "Title of document book"
        },
        {
          "key": "book_title_of_document",
          "value": "Take 25",
          "display": "Book title of document"
        },
        {
          "key": "book_sub_title_of_document",
          "value": "Star Insights & Attitudes",
          "display": "Book sub title of document"
        },
        {
          "key": "volume_issue_number",
          "value": null,
          "display": "Volume issue number"
        },
        {
          "key": "masterlist",
          "value": "raj-kapoor",
          "display": "Masterlist"
        },
        {
          "key": "image",
            "value": ["https://tuliresearchcentre.canto.global/direct/image/a42psmuog95op5m1llrei10t2l/qwjlwnJ5Eeb-vhxx1m72W7KRG98/m800/800"],
          "display": "Image"
        },
        {
          "key": "document_type",
          "value": "bka",
          "display": "Document type"
        },
        {
          "key": "title_and_type_of_creator",
          "value": "Author",
          "display": "Title and type of creator"
        },
        {
          "key": "number_of_pages_in_publication",
          "value": null,
          "display": "Number of pages in publication"
        },
        {
          "key": "researchCategories",
          "value": [
            {
              "key": "Cinema as a Key Educational Resource",
              "value": "CinemaE",
              "link": "cinema-as-a-key-educational-resource"
            }
          ],
          "display": "Research categories"
        },
        {
          "key": "creator",
          "value": [
            {
              "key": "bhawana-somaaya",
              "value": "Bhawana Somaaya",
              "first_name": "Bhawana",
              "last_name": "Somaaya"
            }
          ],
          "display": "Creator"
        }
      ],
      [
        {
          "key": "id",
          "value": "5",
          "display": "Id"
        },
        {
          "key": "title",
          "value": "Shooting Stars",
          "display": "Title"
        },
        {
          "key": "alias",
          "value": "book.bka-pal--2004",
          "display": "Alias"
        },
        {
          "key": "web_serial_number",
          "value": null,
          "display": "Web serial number"
        },
        {
          "key": "reference_code",
          "value": null,
          "display": "Reference code"
        },
        {
          "key": "web_display",
          "value": null,
          "display": "Web display"
        },
        {
          "key": "date_of_library_publication",
          "value": null,
          "display": "Date of library publication"
        },
        {
          "key": "place_of_publication",
          "value": "Mumbai",
          "display": "Place of publication"
        },
        {
          "key": "histographical_note",
          "value": null,
          "display": "Histographical note"
        },
        {
          "key": "main_historical_note",
          "value": null,
          "display": "Main historical note"
        },
        {
          "key": "binding_status",
          "value": "PB",
          "display": "Binding status"
        },
        {
          "key": "accession_number",
          "value": "",
          "display": "Accession number"
        },
        {
          "key": "cdt",
          "value": null,
          "display": "CDT"
        },
        {
          "key": "country_of_publication",
          "value": "India",
          "display": "Country of publication"
        },
        {
          "key": "date_of_published_edition_in_library",
          "value": "2004",
          "display": "Date of published edition in library"
        },
        {
          "key": "dates_of_first_edition",
          "value": "2004",
          "display": "Dates of first edition"
        },
        {
          "key": "isbn_issn_number",
          "value": "",
          "display": "Isbn issn number"
        },
        {
          "key": "language_of_publication",
          "value": "",
          "display": "Language of publication"
        },
        {
          "key": "number_of_pages_in_publication_text",
          "value": null,
          "display": "Number of pages in publication text"
        },
        {
          "key": "personality_film_visually_featured_on_cover",
          "value": null,
          "display": "Personality film visually featured on cover"
        },
        {
          "key": "publisher",
          "value": "Rajendra Ojha | Screen World",
          "display": "Publisher"
        },
        {
          "key": "serial_number",
          "value": null,
          "display": "Serial number"
        },
        {
          "key": "sortable_year_date_of_publication",
          "value": "2004",
          "display": "Sortable year date of publication"
        },
        {
          "key": "sub_title_of_document_book",
          "value": "",
          "display": "Sub title of document book"
        },
        {
          "key": "title_of_document_book",
          "value": "Shooting Stars",
          "display": "Title of document book"
        },
        {
          "key": "book_title_of_document",
          "value": "Shooting Stars",
          "display": "Book title of document"
        },
        {
          "key": "book_sub_title_of_document",
          "value": "",
          "display": "Book sub title of document"
        },
        {
          "key": "volume_issue_number",
          "value": null,
          "display": "Volume issue number"
        },
        {
          "key": "masterlist",
          "value": "raj-kapoor",
          "display": "Masterlist"
        },
        {
          "key": "image",
          "value": ["https://tuliresearchcentre.canto.global/direct/image/ltcaij7g2p64h89hc7l9l9651b/VQRNQ7NhxpH2RuzOOdK6ZwxmNak/m800/800"],
          "display": "Image"
        },
        {
          "key": "document_type",
          "value": "bka",
          "display": "Document type"
        },
        {
          "key": "title_and_type_of_creator",
          "value": "Author",
          "display": "Title and type of creator"
        },
        {
          "key": "number_of_pages_in_publication",
          "value": null,
          "display": "Number of pages in publication"
        },
        {
          "key": "researchCategories",
          "value": [
            {
              "key": "Cinema as a Key Educational Resource",
              "value": "CinemaE",
              "link": "cinema-as-a-key-educational-resource"
            }
          ],
          "display": "Research categories"
        },
        {
          "key": "creator",
          "value": [
            {
              "key": "colin-pal",
              "value": "Colin Pal",
              "first_name": "Colin",
              "last_name": "Pal"
            }
          ],
          "display": "Creator"
        }
      ],
      [
        {
          "key": "id",
          "value": "2",
          "display": "Id"
        },
        {
          "key": "title",
          "value": "The Kapoors",
          "display": "Title"
        },
        {
          "key": "alias",
          "value": "book.bka-jain-the-first-family-of-indian-cinema-2005",
          "display": "Alias"
        },
        {
          "key": "web_serial_number",
          "value": null,
          "display": "Web serial number"
        },
        {
          "key": "reference_code",
          "value": null,
          "display": "Reference code"
        },
        {
          "key": "web_display",
          "value": null,
          "display": "Web display"
        },
        {
          "key": "date_of_library_publication",
          "value": null,
          "display": "Date of library publication"
        },
        {
          "key": "place_of_publication",
          "value": "New Delhi",
          "display": "Place of publication"
        },
        {
          "key": "histographical_note",
          "value": null,
          "display": "Histographical note"
        },
        {
          "key": "main_historical_note",
          "value": null,
          "display": "Main historical note"
        },
        {
          "key": "binding_status",
          "value": "PB",
          "display": "Binding status"
        },
        {
          "key": "accession_number",
          "value": "",
          "display": "Accession number"
        },
        {
          "key": "cdt",
          "value": null,
          "display": "CDT"
        },
        {
          "key": "country_of_publication",
          "value": "India",
          "display": "Country of publication"
        },
        {
          "key": "date_of_published_edition_in_library",
          "value": "2005",
          "display": "Date of published edition in library"
        },
        {
          "key": "dates_of_first_edition",
          "value": "",
          "display": "Dates of first edition"
        },
        {
          "key": "isbn_issn_number",
          "value": "",
          "display": "Isbn issn number"
        },
        {
          "key": "language_of_publication",
          "value": "",
          "display": "Language of publication"
        },
        {
          "key": "number_of_pages_in_publication_text",
          "value": null,
          "display": "Number of pages in publication text"
        },
        {
          "key": "personality_film_visually_featured_on_cover",
          "value": null,
          "display": "Personality film visually featured on cover"
        },
        {
          "key": "publisher",
          "value": "Penguin Books India",
          "display": "Publisher"
        },
        {
          "key": "serial_number",
          "value": null,
          "display": "Serial number"
        },
        {
          "key": "sortable_year_date_of_publication",
          "value": "2005",
          "display": "Sortable year date of publication"
        },
        {
          "key": "sub_title_of_document_book",
          "value": null,
          "display": "Sub title of document book"
        },
        {
          "key": "title_of_document_book",
          "value": null,
          "display": "Title of document book"
        },
        {
          "key": "book_title_of_document",
          "value": "The Kapoors",
          "display": "Book title of document"
        },
        {
          "key": "book_sub_title_of_document",
          "value": "The First Family of Indian Cinema",
          "display": "Book sub title of document"
        },
        {
          "key": "volume_issue_number",
          "value": null,
          "display": "Volume issue number"
        },
        {
          "key": "masterlist",
          "value": "raj-kapoor",
          "display": "Masterlist"
        },
        {
          "key": "image",
          "value": ["https://tuliresearchcentre.canto.global/direct/image/g0mfvh166p26raooorapieb10e/rzcToyXql3X9QCvaAfBN9qZMGGI/m800/800"],
          "display": "Image"
        },
        {
          "key": "document_type",
          "value": "bka",
          "display": "Document type"
        },
        {
          "key": "title_and_type_of_creator",
          "value": "Author",
          "display": "Title and type of creator"
        },
        {
          "key": "number_of_pages_in_publication",
          "value": null,
          "display": "Number of pages in publication"
        },
        {
          "key": "researchCategories",
          "value": [
            {
              "key": "Cinema as a Key Educational Resource",
              "value": "CinemaE",
              "link": "cinema-as-a-key-educational-resource"
            }
          ],
          "display": "Research categories"
        },
        {
          "key": "creator",
          "value": [
            {
              "key": "madhu-jain",
              "value": "Madhu Jain",
              "first_name": "Madhu",
              "last_name": "Jain"
            }
          ],
          "display": "Creator"
        }
      ], 
      [
        {
          "key": "id",
          "value": "9",
          "display": "Id"
        },
        {
          "key": "title",
          "value": "Bollywood Melodies",
          "display": "Title"
        },
        {
          "key": "alias",
          "value": "book.bka-anantharaman-a-history-of-the-hindi-film-song-2008",
          "display": "Alias"
        },
        {
          "key": "web_serial_number",
          "value": null,
          "display": "Web serial number"
        },
        {
          "key": "reference_code",
          "value": null,
          "display": "Reference code"
        },
        {
          "key": "web_display",
          "value": "P#251_117a.jpg",
          "display": "Web display"
        },
        {
          "key": "date_of_library_publication",
          "value": "2008",
          "display": "Date of library publication"
        },
        {
          "key": "place_of_publication",
          "value": "Gurgaon",
          "display": "Place of publication"
        },
        {
          "key": "histographical_note",
          "value": null,
          "display": "Histographical note"
        },
        {
          "key": "main_historical_note",
          "value": null,
          "display": "Main historical note"
        },
        {
          "key": "binding_status",
          "value": null,
          "display": "Binding status"
        },
        {
          "key": "accession_number",
          "value": "BOOK.bka",
          "display": "Accession number"
        },
        {
          "key": "cdt",
          "value": null,
          "display": "CDT"
        },
        {
          "key": "country_of_publication",
          "value": "India",
          "display": "Country of publication"
        },
        {
          "key": "date_of_published_edition_in_library",
          "value": "2008",
          "display": "Date of published edition in library"
        },
        {
          "key": "dates_of_first_edition",
          "value": "2008",
          "display": "Dates of first edition"
        },
        {
          "key": "isbn_issn_number",
          "value": null,
          "display": "Isbn issn number"
        },
        {
          "key": "language_of_publication",
          "value": "English",
          "display": "Language of publication"
        },
        {
          "key": "number_of_pages_in_publication_text",
          "value": null,
          "display": "Number of pages in publication text"
        },
        {
          "key": "personality_film_visually_featured_on_cover",
          "value": null,
          "display": "Personality film visually featured on cover"
        },
        {
          "key": "publisher",
          "value": "Penguin Random House India",
          "display": "Publisher"
        },
        {
          "key": "serial_number",
          "value": null,
          "display": "Serial number"
        },
        {
          "key": "sortable_year_date_of_publication",
          "value": "2008",
          "display": "Sortable year date of publication"
        },
        {
          "key": "sub_title_of_document_book",
          "value": "A History of the Hindi Film Song",
          "display": "Sub title of document book"
        },
        {
          "key": "title_of_document_book",
          "value": "Bollywood Melodies",
          "display": "Title of document book"
        },
        {
          "key": "book_title_of_document",
          "value": "Bollywood Melodies",
          "display": "Book title of document"
        },
        {
          "key": "book_sub_title_of_document",
          "value": "A History of the Hindi Film Song",
          "display": "Book sub title of document"
        },
        {
          "key": "volume_issue_number",
          "value": null,
          "display": "Volume issue number"
        },
        {
          "key": "masterlist",
          "value": "raj-kapoor",
          "display": "Masterlist"
        },
        {
          "key": "image",
          "value": ["https://tuliresearchcentre.canto.global/direct/image/cd8oj5pgml18l3893ndcna0v6t/azUZOk4sLRKhy1L07anSo058hn8/m800/800"],
          "display": "Image"
        },
        {
          "key": "document_type",
          "value": "bka",
          "display": "Document type"
        },
        {
          "key": "title_and_type_of_creator",
          "value": "Author",
          "display": "Title and type of creator"
        },
        {
          "key": "number_of_pages_in_publication",
          "value": null,
          "display": "Number of pages in publication"
        },
        {
          "key": "researchCategories",
          "value": [
            {
              "key": "Cinema as a Key Educational Resource",
              "value": "CinemaE",
              "link": "cinema-as-a-key-educational-resource"
            }
          ],
          "display": "Research categories"
        },
        {
          "key": "creator",
          "value": [
            {
              "key": "ganesh-anantharaman",
              "value": "Ganesh Anantharaman",
              "first_name": "Ganesh",
              "last_name": "Anantharaman"
            },
            {
              "key": "mahesh-bhatt",
              "value": "Mahesh Bhatt",
              "first_name": "Mahesh",
              "last_name": "Bhatt"
            }
          ],
          "display": "Creator"
        }
      ],
      [
        {
          "key": "id",
          "value": "10",
          "display": "Id"
        },
        {
          "key": "title",
          "value": "Rajopadhye",
          "display": "Title"
        },
        {
          "key": "alias",
          "value": "book.bka-atthaiya-the-art-of-costume-design-2010",
          "display": "Alias"
        },
        {
          "key": "web_serial_number",
          "value": null,
          "display": "Web serial number"
        },
        {
          "key": "reference_code",
          "value": null,
          "display": "Reference code"
        },
        {
          "key": "web_display",
          "value": "P#251_152a.jpg",
          "display": "Web display"
        },
        {
          "key": "date_of_library_publication",
          "value": "2010",
          "display": "Date of library publication"
        },
        {
          "key": "place_of_publication",
          "value": "Noida",
          "display": "Place of publication"
        },
        {
          "key": "histographical_note",
          "value": null,
          "display": "Histographical note"
        },
        {
          "key": "main_historical_note",
          "value": null,
          "display": "Main historical note"
        },
        {
          "key": "binding_status",
          "value": null,
          "display": "Binding status"
        },
        {
          "key": "accession_number",
          "value": "BOOK.bka",
          "display": "Accession number"
        },
        {
          "key": "cdt",
          "value": null,
          "display": "CDT"
        },
        {
          "key": "country_of_publication",
          "value": "India",
          "display": "Country of publication"
        },
        {
          "key": "date_of_published_edition_in_library",
          "value": "2010",
          "display": "Date of published edition in library"
        },
        {
          "key": "dates_of_first_edition",
          "value": "2010",
          "display": "Dates of first edition"
        },
        {
          "key": "isbn_issn_number",
          "value": null,
          "display": "Isbn issn number"
        },
        {
          "key": "language_of_publication",
          "value": "English",
          "display": "Language of publication"
        },
        {
          "key": "number_of_pages_in_publication_text",
          "value": null,
          "display": "Number of pages in publication text"
        },
        {
          "key": "personality_film_visually_featured_on_cover",
          "value": null,
          "display": "Personality film visually featured on cover"
        },
        {
          "key": "publisher",
          "value": "Harper Collins",
          "display": "Publisher"
        },
        {
          "key": "serial_number",
          "value": null,
          "display": "Serial number"
        },
        {
          "key": "sortable_year_date_of_publication",
          "value": "2010",
          "display": "Sortable year date of publication"
        },
        {
          "key": "sub_title_of_document_book",
          "value": "The Art of Costume Design",
          "display": "Sub title of document book"
        },
        {
          "key": "title_of_document_book",
          "value": "Rajopadhye",
          "display": "Title of document book"
        },
        {
          "key": "book_title_of_document",
          "value": "Rajopadhye",
          "display": "Book title of document"
        },
        {
          "key": "book_sub_title_of_document",
          "value": "The Art of Costume Design",
          "display": "Book sub title of document"
        },
        {
          "key": "volume_issue_number",
          "value": null,
          "display": "Volume issue number"
        },
        {
          "key": "masterlist",
          "value": "raj-kapoor",
          "display": "Masterlist"
        },
        {
          "key": "image",
          "value": ["https://tuliresearchcentre.canto.global/direct/image/vq99lrder5663dbrb105pubo4l/_MCjvb8Y5bJvsNX_vf4zn9b0rZw/m800/800"],
          "display": "Image"
        },
        {
          "key": "document_type",
          "value": "bka",
          "display": "Document type"
        },
        {
          "key": "title_and_type_of_creator",
          "value": "Author",
          "display": "Title and type of creator"
        },
        {
          "key": "number_of_pages_in_publication",
          "value": null,
          "display": "Number of pages in publication"
        },
        {
          "key": "researchCategories",
          "value": [
            {
              "key": "Cinema as a Key Educational Resource",
              "value": "CinemaE",
              "link": "cinema-as-a-key-educational-resource"
            }
          ],
          "display": "Research categories"
        },
        {
          "key": "creator",
          "value": [
            {
              "key": "bhanu-athaiya",
              "value": "Bhanu Athaiya",
              "first_name": "Bhanu",
              "last_name": "Athaiya"
            },
            {
              "key": "richard-attenborough",
              "value": "Richard Attenborough",
              "first_name": "Richard",
              "last_name": "Attenborough"
            }
          ],
          "display": "Creator"
        }
      ],
      [
        {
          "key": "id",
          "value": "8",
          "display": "Id"
        },
        {
          "key": "title",
          "value": "First Day First Show",
          "display": "Title"
        },
        {
          "key": "alias",
          "value": "book.bka-chopra-writing-from-the-bollywood-trenches-2011",
          "display": "Alias"
        },
        {
          "key": "web_serial_number",
          "value": null,
          "display": "Web serial number"
        },
        {
          "key": "reference_code",
          "value": null,
          "display": "Reference code"
        },
        {
          "key": "web_display",
          "value": null,
          "display": "Web display"
        },
        {
          "key": "date_of_library_publication",
          "value": null,
          "display": "Date of library publication"
        },
        {
          "key": "place_of_publication",
          "value": "Gurgaon",
          "display": "Place of publication"
        },
        {
          "key": "histographical_note",
          "value": null,
          "display": "Histographical note"
        },
        {
          "key": "main_historical_note",
          "value": null,
          "display": "Main historical note"
        },
        {
          "key": "binding_status",
          "value": "PB",
          "display": "Binding status"
        },
        {
          "key": "accession_number",
          "value": "",
          "display": "Accession number"
        },
        {
          "key": "cdt",
          "value": null,
          "display": "CDT"
        },
        {
          "key": "country_of_publication",
          "value": "India",
          "display": "Country of publication"
        },
        {
          "key": "date_of_published_edition_in_library",
          "value": "2011",
          "display": "Date of published edition in library"
        },
        {
          "key": "dates_of_first_edition",
          "value": "2011",
          "display": "Dates of first edition"
        },
        {
          "key": "isbn_issn_number",
          "value": "",
          "display": "Isbn issn number"
        },
        {
          "key": "language_of_publication",
          "value": "",
          "display": "Language of publication"
        },
        {
          "key": "number_of_pages_in_publication_text",
          "value": null,
          "display": "Number of pages in publication text"
        },
        {
          "key": "personality_film_visually_featured_on_cover",
          "value": null,
          "display": "Personality film visually featured on cover"
        },
        {
          "key": "publisher",
          "value": "Penguin Random House India",
          "display": "Publisher"
        },
        {
          "key": "serial_number",
          "value": null,
          "display": "Serial number"
        },
        {
          "key": "sortable_year_date_of_publication",
          "value": "2011",
          "display": "Sortable year date of publication"
        },
        {
          "key": "sub_title_of_document_book",
          "value": "Writing from the Bollywood Trenches",
          "display": "Sub title of document book"
        },
        {
          "key": "title_of_document_book",
          "value": "First Day First Show",
          "display": "Title of document book"
        },
        {
          "key": "book_title_of_document",
          "value": "First Day First Show",
          "display": "Book title of document"
        },
        {
          "key": "book_sub_title_of_document",
          "value": "Writing from the Bollywood Trenches",
          "display": "Book sub title of document"
        },
        {
          "key": "volume_issue_number",
          "value": null,
          "display": "Volume issue number"
        },
        {
          "key": "masterlist",
          "value": "raj-kapoor",
          "display": "Masterlist"
        },
        {
          "key": "image",
          "value": ["https://tuliresearchcentre.canto.global/direct/image/4cv3lbtfll191511d2scvfg016/ji_z0tm8mQjyuNAK-0uwqMU1hN4/m800/800"],
          "display": "Image"
        },
        {
          "key": "document_type",
          "value": "bka",
          "display": "Document type"
        },
        {
          "key": "title_and_type_of_creator",
          "value": "Author",
          "display": "Title and type of creator"
        },
        {
          "key": "number_of_pages_in_publication",
          "value": null,
          "display": "Number of pages in publication"
        },
        {
          "key": "researchCategories",
          "value": [
            {
              "key": "Cinema as a Key Educational Resource",
              "value": "CinemaE",
              "link": "cinema-as-a-key-educational-resource"
            }
          ],
          "display": "Research categories"
        },
        {
          "key": "creator",
          "value": [
            {
              "key": "anupama-chopra",
              "value": "Anupama Chopra",
              "first_name": "Anupama",
              "last_name": "Chopra"
            }
          ],
          "display": "Creator"
        }
      ],                                                
]

export const raj_kapoor_biblography_filter_options = [
    {   
        key: "creatorType",
        display: filterAttribute.AUTHOR_EDITOR_CONTRIBUTOR,
        value: [
      "Author",
    ]},
    {
    key: "year",
    display: filterAttribute.DATE,
    value: [
        "1975",
        "1977",
        "1988",
        "1993",
        "1995",
        "2002",
        "2004",
        "2005",
        "2008",
        "2010",
        "2011"
    ]},
    {
        key: "docType",
        display: filterAttribute.DOCUMENT_TYPE,
        value: [
          "Book.bka",
        ]},
  ];

// Amitabh Bachchan bibliography

export const amitabh_bachchan_research_centre=[
    [
        {
          "key": "id",
          "value": "4",
          "display": "Id"
        },
        {
          "key": "title",
          "value": "Talking Films",
          "display": "Title"
        },
        {
          "key": "alias",
          "value": "book-bka-baghdadi--1995",
          "display": "Alias"
        },
        {
          "key": "web_serial_number",
          "value": null,
          "display": "Web serial number"
        },
        {
          "key": "reference_code",
          "value": null,
          "display": "Reference code"
        },
        {
          "key": "web_display",
          "value": null,
          "display": "Web display"
        },
        {
          "key": "date_of_library_publication",
          "value": null,
          "display": "Date of library publication"
        },
        {
          "key": "place_of_publication",
          "value": "New Delhi",
          "display": "Place of publication"
        },
        {
          "key": "histographical_note",
          "value": null,
          "display": "Histographical note"
        },
        {
          "key": "main_historical_note",
          "value": null,
          "display": "Main historical note"
        },
        {
          "key": "binding_status",
          "value": "HB",
          "display": "Binding status"
        },
        {
          "key": "accession_number",
          "value": "",
          "display": "Accession number"
        },
        {
          "key": "cdt",
          "value": null,
          "display": "CDT"
        },
        {
          "key": "country_of_publication",
          "value": "India",
          "display": "Country of publication"
        },
        {
          "key": "date_of_published_edition_in_library",
          "value": "1995",
          "display": "Date of published edition in library"
        },
        {
          "key": "dates_of_first_edition",
          "value": "1995",
          "display": "Dates of first edition"
        },
        {
          "key": "isbn_issn_number",
          "value": "8172231970",
          "display": "Isbn issn number"
        },
        {
          "key": "language_of_publication",
          "value": "English",
          "display": "Language of publication"
        },
        {
          "key": "number_of_pages_in_publication_text",
          "value": null,
          "display": "Number of pages in publication text"
        },
        {
          "key": "personality_film_visually_featured_on_cover",
          "value": null,
          "display": "Personality film visually featured on cover"
        },
        {
          "key": "publisher",
          "value": "Harper Collins",
          "display": "Publisher"
        },
        {
          "key": "serial_number",
          "value": null,
          "display": "Serial number"
        },
        {
          "key": "sortable_year_date_of_publication",
          "value": "1995",
          "display": "Sortable year date of publication"
        },
        {
          "key": "sub_title_of_document_book",
          "value": "",
          "display": "Sub title of document book"
        },
        {
          "key": "title_of_document_book",
          "value": "Talking Films",
          "display": "Title of document book"
        },
        {
          "key": "book_title_of_document",
          "value": "Talking Films",
          "display": "Book title of document"
        },
        {
          "key": "book_sub_title_of_document",
          "value": "",
          "display": "Book sub title of document"
        },
        {
          "key": "volume_issue_number",
          "value": null,
          "display": "Volume issue number"
        },
        {
          "key": "masterlist",
          "value": "amitabh-bachchan",
          "display": "Masterlist"
        },
        {
          "key": "image",
          "value": ["https://tuliresearchcentre.canto.global/direct/image/0js2qtkg953ub7poehsoa03214/c284qLoEoGKWxF-Ff3j5LaXtMKM/m800/800"],
          "display": "Image"
        },
        {
          "key": "document_type",
          "value": "bka",
          "display": "Document type"
        },
        {
          "key": "title_and_type_of_creator",
          "value": "Author",
          "display": "Title and type of creator"
        },
        {
          "key": "number_of_pages_in_publication",
          "value": null,
          "display": "Number of pages in publication"
        },
        {
          "key": "researchCategories",
          "value": [
            {
              "key": "Cinema as a Key Educational Resource",
              "value": "CinemaE",
              "link": "cinema-as-a-key-educational-resource"
            }
          ],
          "display": "Research categories"
        },
        {
          "key": "creator",
          "value": [
            {
              "key": "rafique-baghdadi",
              "value": "Rafique Baghdadi",
              "first_name": "Rafique",
              "last_name": "Baghdadi"
            }
          ],
          "display": "Creator"
        }
      ],
      [
        {
          "key": "id",
          "value": "2",
          "display": "Id"
        },
        {
          "key": "title",
          "value": "Amitabh Bachchan",
          "display": "Title"
        },
        {
          "key": "alias",
          "value": "book-bka-somaaya-the-legend-1999",
          "display": "Alias"
        },
        {
          "key": "web_serial_number",
          "value": null,
          "display": "Web serial number"
        },
        {
          "key": "reference_code",
          "value": null,
          "display": "Reference code"
        },
        {
          "key": "web_display",
          "value": null,
          "display": "Web display"
        },
        {
          "key": "date_of_library_publication",
          "value": null,
          "display": "Date of library publication"
        },
        {
          "key": "place_of_publication",
          "value": "New Delhi",
          "display": "Place of publication"
        },
        {
          "key": "histographical_note",
          "value": null,
          "display": "Histographical note"
        },
        {
          "key": "main_historical_note",
          "value": null,
          "display": "Main historical note"
        },
        {
          "key": "binding_status",
          "value": "HB",
          "display": "Binding status"
        },
        {
          "key": "accession_number",
          "value": "",
          "display": "Accession number"
        },
        {
          "key": "cdt",
          "value": null,
          "display": "CDT"
        },
        {
          "key": "country_of_publication",
          "value": "India",
          "display": "Country of publication"
        },
        {
          "key": "date_of_published_edition_in_library",
          "value": "1999",
          "display": "Date of published edition in library"
        },
        {
          "key": "dates_of_first_edition",
          "value": "1999",
          "display": "Dates of first edition"
        },
        {
          "key": "isbn_issn_number",
          "value": "0333933559",
          "display": "Isbn issn number"
        },
        {
          "key": "language_of_publication",
          "value": "English",
          "display": "Language of publication"
        },
        {
          "key": "number_of_pages_in_publication_text",
          "value": null,
          "display": "Number of pages in publication text"
        },
        {
          "key": "personality_film_visually_featured_on_cover",
          "value": null,
          "display": "Personality film visually featured on cover"
        },
        {
          "key": "publisher",
          "value": "Macmillan India",
          "display": "Publisher"
        },
        {
          "key": "serial_number",
          "value": null,
          "display": "Serial number"
        },
        {
          "key": "sortable_year_date_of_publication",
          "value": "1999",
          "display": "Sortable year date of publication"
        },
        {
          "key": "sub_title_of_document_book",
          "value": "The Legend",
          "display": "Sub title of document book"
        },
        {
          "key": "title_of_document_book",
          "value": "Amitabh Bachchan",
          "display": "Title of document book"
        },
        {
          "key": "book_title_of_document",
          "value": "Amitabh Bachchan",
          "display": "Book title of document"
        },
        {
          "key": "book_sub_title_of_document",
          "value": "The Legend",
          "display": "Book sub title of document"
        },
        {
          "key": "volume_issue_number",
          "value": null,
          "display": "Volume issue number"
        },
        {
          "key": "masterlist",
          "value": "amitabh-bachchan",
          "display": "Masterlist"
        },
        {
          "key": "image",
          "value": ["https://tuliresearchcentre.canto.global/direct/image/3tlla3q6dh0n3dbjomnqqli976/iQokOfXOpIT0z-T2ryaEXGOIGg8/m800/800"],
          "display": "Image"
        },
        {
          "key": "document_type",
          "value": "bka",
          "display": "Document type"
        },
        {
          "key": "title_and_type_of_creator",
          "value": "Author",
          "display": "Title and type of creator"
        },
        {
          "key": "number_of_pages_in_publication",
          "value": null,
          "display": "Number of pages in publication"
        },
        {
          "key": "researchCategories",
          "value": [
            {
              "key": "Cinema as a Key Educational Resource",
              "value": "CinemaE",
              "link": "cinema-as-a-key-educational-resource"
            }
          ],
          "display": "Research categories"
        },
        {
          "key": "creator",
          "value": [
            {
              "key": "bhawana-somaaya",
              "value": "Bhawana Somaaya",
              "first_name": "Bhawana",
              "last_name": "Somaaya"
            }
          ],
          "display": "Creator"
        }
      ],
      [
        {
          "key": "id",
          "value": "11",
          "display": "Id"
        },
        {
          "key": "title",
          "value": "Behind the Scenes of Hindi Cinema",
          "display": "Title"
        },
        {
          "key": "alias",
          "value": "book-bka-manschot-a-visual-journey-through-the-heart-of-bollywood-2005",
          "display": "Alias"
        },
        {
          "key": "web_serial_number",
          "value": null,
          "display": "Web serial number"
        },
        {
          "key": "reference_code",
          "value": null,
          "display": "Reference code"
        },
        {
          "key": "web_display",
          "value": null,
          "display": "Web display"
        },
        {
          "key": "date_of_library_publication",
          "value": null,
          "display": "Date of library publication"
        },
        {
          "key": "place_of_publication",
          "value": "Amsterdam",
          "display": "Place of publication"
        },
        {
          "key": "histographical_note",
          "value": null,
          "display": "Histographical note"
        },
        {
          "key": "main_historical_note",
          "value": null,
          "display": "Main historical note"
        },
        {
          "key": "binding_status",
          "value": "HB",
          "display": "Binding status"
        },
        {
          "key": "accession_number",
          "value": "",
          "display": "Accession number"
        },
        {
          "key": "cdt",
          "value": null,
          "display": "CDT"
        },
        {
          "key": "country_of_publication",
          "value": "Netherlands",
          "display": "Country of publication"
        },
        {
          "key": "date_of_published_edition_in_library",
          "value": "2005",
          "display": "Date of published edition in library"
        },
        {
          "key": "dates_of_first_edition",
          "value": "2005",
          "display": "Dates of first edition"
        },
        {
          "key": "isbn_issn_number",
          "value": "9068321862",
          "display": "Isbn issn number"
        },
        {
          "key": "language_of_publication",
          "value": "English",
          "display": "Language of publication"
        },
        {
          "key": "number_of_pages_in_publication_text",
          "value": null,
          "display": "Number of pages in publication text"
        },
        {
          "key": "personality_film_visually_featured_on_cover",
          "value": null,
          "display": "Personality film visually featured on cover"
        },
        {
          "key": "publisher",
          "value": "KIT",
          "display": "Publisher"
        },
        {
          "key": "serial_number",
          "value": null,
          "display": "Serial number"
        },
        {
          "key": "sortable_year_date_of_publication",
          "value": "2005",
          "display": "Sortable year date of publication"
        },
        {
          "key": "sub_title_of_document_book",
          "value": "A Visual Journey through the Heart of Bollywood",
          "display": "Sub title of document book"
        },
        {
          "key": "title_of_document_book",
          "value": "Behind the Scenes of Hindi Cinema",
          "display": "Title of document book"
        },
        {
          "key": "book_title_of_document",
          "value": "Behind the Scenes of Hindi Cinema",
          "display": "Book title of document"
        },
        {
          "key": "book_sub_title_of_document",
          "value": "A Visual Journey through the Heart of Bollywood",
          "display": "Book sub title of document"
        },
        {
          "key": "volume_issue_number",
          "value": null,
          "display": "Volume issue number"
        },
        {
          "key": "masterlist",
          "value": "amitabh-bachchan",
          "display": "Masterlist"
        },
        {
          "key": "image",
          "value": ["https://tuliresearchcentre.canto.global/direct/image/amgh7tqlhl2nb0rgik64csia29/j2fIJZrhIEFcJE3BzyzUx_qUqrQ/m800/800"],
          "display": "Image"
        },
        {
          "key": "document_type",
          "value": "bka",
          "display": "Document type"
        },
        {
          "key": "title_and_type_of_creator",
          "value": "Author",
          "display": "Title and type of creator"
        },
        {
          "key": "number_of_pages_in_publication",
          "value": null,
          "display": "Number of pages in publication"
        },
        {
          "key": "researchCategories",
          "value": [
            {
              "key": "Cinema as a Key Educational Resource",
              "value": "CinemaE",
              "link": "cinema-as-a-key-educational-resource"
            }
          ],
          "display": "Research categories"
        },
        {
          "key": "creator",
          "value": [
            {
              "key": "johan-manschot",
              "value": "Johan Manschot",
              "first_name": "Johan",
              "last_name": "Manschot"
            },
            {
              "key": "marijke-de-vos",
              "value": "Marijke de Vos",
              "first_name": "Marijke",
              "last_name": "de Vos"
            }
          ],
          "display": "Creator"
        }
      ],    
      [
        {
          "key": "id",
          "value": "3",
          "display": "Id"
        },
        {
          "key": "title",
          "value": "AB the Legend",
          "display": "Title"
        },
        {
          "key": "alias",
          "value": "book-bka-chandra-a-photographer's-tribute-2006",
          "display": "Alias"
        },
        {
          "key": "web_serial_number",
          "value": null,
          "display": "Web serial number"
        },
        {
          "key": "reference_code",
          "value": null,
          "display": "Reference code"
        },
        {
          "key": "web_display",
          "value": null,
          "display": "Web display"
        },
        {
          "key": "date_of_library_publication",
          "value": null,
          "display": "Date of library publication"
        },
        {
          "key": "place_of_publication",
          "value": "New Delhi",
          "display": "Place of publication"
        },
        {
          "key": "histographical_note",
          "value": null,
          "display": "Histographical note"
        },
        {
          "key": "main_historical_note",
          "value": null,
          "display": "Main historical note"
        },
        {
          "key": "binding_status",
          "value": "HB",
          "display": "Binding status"
        },
        {
          "key": "accession_number",
          "value": "",
          "display": "Accession number"
        },
        {
          "key": "cdt",
          "value": null,
          "display": "CDT"
        },
        {
          "key": "country_of_publication",
          "value": "India",
          "display": "Country of publication"
        },
        {
          "key": "date_of_published_edition_in_library",
          "value": "2006",
          "display": "Date of published edition in library"
        },
        {
          "key": "dates_of_first_edition",
          "value": "2006",
          "display": "Dates of first edition"
        },
        {
          "key": "isbn_issn_number",
          "value": "812910881x",
          "display": "Isbn issn number"
        },
        {
          "key": "language_of_publication",
          "value": "English",
          "display": "Language of publication"
        },
        {
          "key": "number_of_pages_in_publication_text",
          "value": null,
          "display": "Number of pages in publication text"
        },
        {
          "key": "personality_film_visually_featured_on_cover",
          "value": null,
          "display": "Personality film visually featured on cover"
        },
        {
          "key": "publisher",
          "value": "Rupa",
          "display": "Publisher"
        },
        {
          "key": "serial_number",
          "value": null,
          "display": "Serial number"
        },
        {
          "key": "sortable_year_date_of_publication",
          "value": "2006",
          "display": "Sortable year date of publication"
        },
        {
          "key": "sub_title_of_document_book",
          "value": "A Photographer's Tribute",
          "display": "Sub title of document book"
        },
        {
          "key": "title_of_document_book",
          "value": "AB the Legend",
          "display": "Title of document book"
        },
        {
          "key": "book_title_of_document",
          "value": "AB the Legend",
          "display": "Book title of document"
        },
        {
          "key": "book_sub_title_of_document",
          "value": "A Photographer's Tribute",
          "display": "Book sub title of document"
        },
        {
          "key": "volume_issue_number",
          "value": null,
          "display": "Volume issue number"
        },
        {
          "key": "masterlist",
          "value": "amitabh-bachchan",
          "display": "Masterlist"
        },
        {
          "key": "image",
          "value": ["https://tuliresearchcentre.canto.global/direct/image/as7pvjftpd1f5btec7n73lu079/ispOkWDu1tpfuLM6d9kUH4pA5yg/m320/800"],
          "display": "Image"
        },
        {
          "key": "document_type",
          "value": "bka",
          "display": "Document type"
        },
        {
          "key": "title_and_type_of_creator",
          "value": "Author",
          "display": "Title and type of creator"
        },
        {
          "key": "number_of_pages_in_publication",
          "value": null,
          "display": "Number of pages in publication"
        },
        {
          "key": "researchCategories",
          "value": [
            {
              "key": "Cinema as a Key Educational Resource",
              "value": "CinemaE",
              "link": "cinema-as-a-key-educational-resource"
            }
          ],
          "display": "Research categories"
        },
        {
          "key": "creator",
          "value": [
            {
              "key": "pradeep-chandra",
              "value": "Pradeep Chandra",
              "first_name": "Pradeep",
              "last_name": "Chandra"
            }
          ],
          "display": "Creator"
        }
      ],
      [
        {
          "key": "id",
          "value": "8",
          "display": "Id"
        },
        {
          "key": "title",
          "value": "Enchantment of the Mind",
          "display": "Title"
        },
        {
          "key": "alias",
          "value": "book-bka-haham-manmohan-desai's-films-2006",
          "display": "Alias"
        },
        {
          "key": "web_serial_number",
          "value": null,
          "display": "Web serial number"
        },
        {
          "key": "reference_code",
          "value": null,
          "display": "Reference code"
        },
        {
          "key": "web_display",
          "value": null,
          "display": "Web display"
        },
        {
          "key": "date_of_library_publication",
          "value": null,
          "display": "Date of library publication"
        },
        {
          "key": "place_of_publication",
          "value": "New Delhi",
          "display": "Place of publication"
        },
        {
          "key": "histographical_note",
          "value": null,
          "display": "Histographical note"
        },
        {
          "key": "main_historical_note",
          "value": null,
          "display": "Main historical note"
        },
        {
          "key": "binding_status",
          "value": "HB",
          "display": "Binding status"
        },
        {
          "key": "accession_number",
          "value": "",
          "display": "Accession number"
        },
        {
          "key": "cdt",
          "value": null,
          "display": "CDT"
        },
        {
          "key": "country_of_publication",
          "value": "India",
          "display": "Country of publication"
        },
        {
          "key": "date_of_published_edition_in_library",
          "value": "2006",
          "display": "Date of published edition in library"
        },
        {
          "key": "dates_of_first_edition",
          "value": "2006",
          "display": "Dates of first edition"
        },
        {
          "key": "isbn_issn_number",
          "value": "978-8174364315",
          "display": "Isbn issn number"
        },
        {
          "key": "language_of_publication",
          "value": "English",
          "display": "Language of publication"
        },
        {
          "key": "number_of_pages_in_publication_text",
          "value": null,
          "display": "Number of pages in publication text"
        },
        {
          "key": "personality_film_visually_featured_on_cover",
          "value": null,
          "display": "Personality film visually featured on cover"
        },
        {
          "key": "publisher",
          "value": "Roli Books",
          "display": "Publisher"
        },
        {
          "key": "serial_number",
          "value": null,
          "display": "Serial number"
        },
        {
          "key": "sortable_year_date_of_publication",
          "value": "2006",
          "display": "Sortable year date of publication"
        },
        {
          "key": "sub_title_of_document_book",
          "value": "Manmohan Desai's Films",
          "display": "Sub title of document book"
        },
        {
          "key": "title_of_document_book",
          "value": "Enchantment of the Mind",
          "display": "Title of document book"
        },
        {
          "key": "book_title_of_document",
          "value": "Enchantment of the Mind",
          "display": "Book title of document"
        },
        {
          "key": "book_sub_title_of_document",
          "value": "Manmohan Desai's Films",
          "display": "Book sub title of document"
        },
        {
          "key": "volume_issue_number",
          "value": null,
          "display": "Volume issue number"
        },
        {
          "key": "masterlist",
          "value": "amitabh-bachchan",
          "display": "Masterlist"
        },
        {
          "key": "image",
          "value": ["https://tuliresearchcentre.canto.global/direct/image/bkqmddhepl17lako8grklk174s/UPtHLChYUkh7Ufi3M4bWYZnjIkM/m800/800"],
          "display": "Image"
        },
        {
          "key": "document_type",
          "value": "bka",
          "display": "Document type"
        },
        {
          "key": "title_and_type_of_creator",
          "value": "Author",
          "display": "Title and type of creator"
        },
        {
          "key": "number_of_pages_in_publication",
          "value": null,
          "display": "Number of pages in publication"
        },
        {
          "key": "researchCategories",
          "value": [
            {
              "key": "Cinema as a Key Educational Resource",
              "value": "CinemaE",
              "link": "cinema-as-a-key-educational-resource"
            }
          ],
          "display": "Research categories"
        },
        {
          "key": "creator",
          "value": [
            {
              "key": "connie-haham",
              "value": "Connie Haham",
              "first_name": "Connie",
              "last_name": "Haham"
            }
          ],
          "display": "Creator"
        }
      ],
      [
        {
          "key": "id",
          "value": "7",
          "display": "Id"
        },
        {
          "key": "title",
          "value": "Madhushala",
          "display": "Title"
        },
        {
          "key": "alias",
          "value": "book-bka-bachchan-the-house-of-wine-2009",
          "display": "Alias"
        },
        {
          "key": "web_serial_number",
          "value": null,
          "display": "Web serial number"
        },
        {
          "key": "reference_code",
          "value": null,
          "display": "Reference code"
        },
        {
          "key": "web_display",
          "value": null,
          "display": "Web display"
        },
        {
          "key": "date_of_library_publication",
          "value": null,
          "display": "Date of library publication"
        },
        {
          "key": "place_of_publication",
          "value": "Mumbai",
          "display": "Place of publication"
        },
        {
          "key": "histographical_note",
          "value": null,
          "display": "Histographical note"
        },
        {
          "key": "main_historical_note",
          "value": null,
          "display": "Main historical note"
        },
        {
          "key": "binding_status",
          "value": "HB",
          "display": "Binding status"
        },
        {
          "key": "accession_number",
          "value": "",
          "display": "Accession number"
        },
        {
          "key": "cdt",
          "value": null,
          "display": "CDT"
        },
        {
          "key": "country_of_publication",
          "value": "India",
          "display": "Country of publication"
        },
        {
          "key": "date_of_published_edition_in_library",
          "value": "2009",
          "display": "Date of published edition in library"
        },
        {
          "key": "dates_of_first_edition",
          "value": "2009",
          "display": "Dates of first edition"
        },
        {
          "key": "isbn_issn_number",
          "value": "819015172x",
          "display": "Isbn issn number"
        },
        {
          "key": "language_of_publication",
          "value": "Hindi | English",
          "display": "Language of publication"
        },
        {
          "key": "number_of_pages_in_publication_text",
          "value": null,
          "display": "Number of pages in publication text"
        },
        {
          "key": "personality_film_visually_featured_on_cover",
          "value": null,
          "display": "Personality film visually featured on cover"
        },
        {
          "key": "publisher",
          "value": "Saraswati Creation",
          "display": "Publisher"
        },
        {
          "key": "serial_number",
          "value": null,
          "display": "Serial number"
        },
        {
          "key": "sortable_year_date_of_publication",
          "value": "2009",
          "display": "Sortable year date of publication"
        },
        {
          "key": "sub_title_of_document_book",
          "value": "The House of Wine",
          "display": "Sub title of document book"
        },
        {
          "key": "title_of_document_book",
          "value": "Madhushala",
          "display": "Title of document book"
        },
        {
          "key": "book_title_of_document",
          "value": "Madhushala",
          "display": "Book title of document"
        },
        {
          "key": "book_sub_title_of_document",
          "value": "The House of Wine",
          "display": "Book sub title of document"
        },
        {
          "key": "volume_issue_number",
          "value": null,
          "display": "Volume issue number"
        },
        {
          "key": "masterlist",
          "value": "amitabh-bachchan",
          "display": "Masterlist"
        },
        {
          "key": "image",
          "value": ["https://tuliresearchcentre.canto.global/direct/image/p74hbqfmp13sb6sul9v9vc351t/jZ3CIejF0rZlKsSU76ALj8JxqX0/m800/800"],
          "display": "Image"
        },
        {
          "key": "document_type",
          "value": "bka",
          "display": "Document type"
        },
        {
          "key": "title_and_type_of_creator",
          "value": "Author",
          "display": "Title and type of creator"
        },
        {
          "key": "number_of_pages_in_publication",
          "value": null,
          "display": "Number of pages in publication"
        },
        {
          "key": "researchCategories",
          "value": [
            {
              "key": "Cinema as a Key Educational Resource",
              "value": "CinemaE",
              "link": "cinema-as-a-key-educational-resource"
            }
          ],
          "display": "Research categories"
        },
        {
          "key": "creator",
          "value": [
            {
              "key": "harivansh-rai-bachchan",
              "value": "Harivansh Rai Bachchan",
              "first_name": "Harivansh Rai",
              "last_name": "Bachchan"
            },
            {
              "key": "marjourie-boulton",
              "value": "Marjourie Boulton",
              "first_name": "Marjourie",
              "last_name": "Boulton"
            },
            {
              "key": "ram-swaroop-vyas",
              "value": "Ram Swaroop Vyas",
              "first_name": "Ram Swaroop",
              "last_name": "Vyas"
            },
            {
              "key": "namrita-a-bachchan",
              "value": "Namrita A. Bachchan",
              "first_name": "Namrita A.",
              "last_name": "Bachchan"
            }
          ],
          "display": "Creator"
        }
      ],
      [
        {
          "key": "id",
          "value": "10",
          "display": "Id"
        },
        {
          "key": "title",
          "value": "First Day First Show",
          "display": "Title"
        },
        {
          "key": "alias",
          "value": "book-bka-chopra-writing-from-the-bollywood-trenches-2011",
          "display": "Alias"
        },
        {
          "key": "web_serial_number",
          "value": null,
          "display": "Web serial number"
        },
        {
          "key": "reference_code",
          "value": null,
          "display": "Reference code"
        },
        {
          "key": "web_display",
          "value": null,
          "display": "Web display"
        },
        {
          "key": "date_of_library_publication",
          "value": null,
          "display": "Date of library publication"
        },
        {
          "key": "place_of_publication",
          "value": "Gurgaon",
          "display": "Place of publication"
        },
        {
          "key": "histographical_note",
          "value": null,
          "display": "Histographical note"
        },
        {
          "key": "main_historical_note",
          "value": null,
          "display": "Main historical note"
        },
        {
          "key": "binding_status",
          "value": "PB",
          "display": "Binding status"
        },
        {
          "key": "accession_number",
          "value": "",
          "display": "Accession number"
        },
        {
          "key": "cdt",
          "value": null,
          "display": "CDT"
        },
        {
          "key": "country_of_publication",
          "value": "India",
          "display": "Country of publication"
        },
        {
          "key": "date_of_published_edition_in_library",
          "value": "2011",
          "display": "Date of published edition in library"
        },
        {
          "key": "dates_of_first_edition",
          "value": "2011",
          "display": "Dates of first edition"
        },
        {
          "key": "isbn_issn_number",
          "value": "978-0143065944",
          "display": "Isbn issn number"
        },
        {
          "key": "language_of_publication",
          "value": "English",
          "display": "Language of publication"
        },
        {
          "key": "number_of_pages_in_publication_text",
          "value": null,
          "display": "Number of pages in publication text"
        },
        {
          "key": "personality_film_visually_featured_on_cover",
          "value": null,
          "display": "Personality film visually featured on cover"
        },
        {
          "key": "publisher",
          "value": "Penguin Random House India",
          "display": "Publisher"
        },
        {
          "key": "serial_number",
          "value": null,
          "display": "Serial number"
        },
        {
          "key": "sortable_year_date_of_publication",
          "value": "2011",
          "display": "Sortable year date of publication"
        },
        {
          "key": "sub_title_of_document_book",
          "value": "Writing from the Bollywood Trenches",
          "display": "Sub title of document book"
        },
        {
          "key": "title_of_document_book",
          "value": "First Day First Show",
          "display": "Title of document book"
        },
        {
          "key": "book_title_of_document",
          "value": "First Day First Show",
          "display": "Book title of document"
        },
        {
          "key": "book_sub_title_of_document",
          "value": "Writing from the Bollywood Trenches",
          "display": "Book sub title of document"
        },
        {
          "key": "volume_issue_number",
          "value": null,
          "display": "Volume issue number"
        },
        {
          "key": "masterlist",
          "value": "amitabh-bachchan",
          "display": "Masterlist"
        },
        {
          "key": "image",
          "value": ["https://tuliresearchcentre.canto.global/direct/image/4cv3lbtfll191511d2scvfg016/ji_z0tm8mQjyuNAK-0uwqMU1hN4/m800/800"],
          "display": "Image"
        },
        {
          "key": "document_type",
          "value": "bka",
          "display": "Document type"
        },
        {
          "key": "title_and_type_of_creator",
          "value": "Author",
          "display": "Title and type of creator"
        },
        {
          "key": "number_of_pages_in_publication",
          "value": null,
          "display": "Number of pages in publication"
        },
        {
          "key": "researchCategories",
          "value": [
            {
              "key": "Cinema as a Key Educational Resource",
              "value": "CinemaE",
              "link": "cinema-as-a-key-educational-resource"
            }
          ],
          "display": "Research categories"
        },
        {
          "key": "creator",
          "value": [
            {
              "key": "anupama-chopra",
              "value": "Anupama Chopra",
              "first_name": "Anupama",
              "last_name": "Chopra"
            },
            {
              "key": "shah-rukh-khan",
              "value": "Shah Rukh Khan",
              "first_name": "Shah Rukh",
              "last_name": "Khan"
            }
          ],
          "display": "Creator"
        }
      ],   
      [
        {
          "key": "id",
          "value": "5",
          "display": "Id"
        },
        {
          "key": "title",
          "value": "Bimal Roy's Madhumati",
          "display": "Title"
        },
        {
          "key": "alias",
          "value": "book-bka-bhattacharya-untold-stories-from-behind-the-scenes-2014",
          "display": "Alias"
        },
        {
          "key": "web_serial_number",
          "value": null,
          "display": "Web serial number"
        },
        {
          "key": "reference_code",
          "value": null,
          "display": "Reference code"
        },
        {
          "key": "web_display",
          "value": null,
          "display": "Web display"
        },
        {
          "key": "date_of_library_publication",
          "value": null,
          "display": "Date of library publication"
        },
        {
          "key": "place_of_publication",
          "value": "New Delhi",
          "display": "Place of publication"
        },
        {
          "key": "histographical_note",
          "value": null,
          "display": "Histographical note"
        },
        {
          "key": "main_historical_note",
          "value": null,
          "display": "Main historical note"
        },
        {
          "key": "binding_status",
          "value": "HB",
          "display": "Binding status"
        },
        {
          "key": "accession_number",
          "value": "",
          "display": "Accession number"
        },
        {
          "key": "cdt",
          "value": null,
          "display": "CDT"
        },
        {
          "key": "country_of_publication",
          "value": "India",
          "display": "Country of publication"
        },
        {
          "key": "date_of_published_edition_in_library",
          "value": "2014",
          "display": "Date of published edition in library"
        },
        {
          "key": "dates_of_first_edition",
          "value": "2014",
          "display": "Dates of first edition"
        },
        {
          "key": "isbn_issn_number",
          "value": "978-8129129161",
          "display": "Isbn issn number"
        },
        {
          "key": "language_of_publication",
          "value": "English",
          "display": "Language of publication"
        },
        {
          "key": "number_of_pages_in_publication_text",
          "value": null,
          "display": "Number of pages in publication text"
        },
        {
          "key": "personality_film_visually_featured_on_cover",
          "value": null,
          "display": "Personality film visually featured on cover"
        },
        {
          "key": "publisher",
          "value": "Rupa",
          "display": "Publisher"
        },
        {
          "key": "serial_number",
          "value": null,
          "display": "Serial number"
        },
        {
          "key": "sortable_year_date_of_publication",
          "value": "2014",
          "display": "Sortable year date of publication"
        },
        {
          "key": "sub_title_of_document_book",
          "value": "Untold Stories from behind the Scenes",
          "display": "Sub title of document book"
        },
        {
          "key": "title_of_document_book",
          "value": "Bimal Roy's Madhumati",
          "display": "Title of document book"
        },
        {
          "key": "book_title_of_document",
          "value": "Bimal Roy's Madhumati",
          "display": "Book title of document"
        },
        {
          "key": "book_sub_title_of_document",
          "value": "Untold Stories from behind the Scenes",
          "display": "Book sub title of document"
        },
        {
          "key": "volume_issue_number",
          "value": null,
          "display": "Volume issue number"
        },
        {
          "key": "masterlist",
          "value": "amitabh-bachchan",
          "display": "Masterlist"
        },
        {
          "key": "image",
          "value": ["https://tuliresearchcentre.canto.global/direct/image/jh080k764t6ld2htmg91f6rs2q/V8tLKS1QTjq-BbocmgoSZ8hTFQI/m800/800"],
          "display": "Image"
        },
        {
          "key": "document_type",
          "value": "bka",
          "display": "Document type"
        },
        {
          "key": "title_and_type_of_creator",
          "value": "Author",
          "display": "Title and type of creator"
        },
        {
          "key": "number_of_pages_in_publication",
          "value": null,
          "display": "Number of pages in publication"
        },
        {
          "key": "researchCategories",
          "value": [
            {
              "key": "Cinema as a Key Educational Resource",
              "value": "CinemaE",
              "link": "cinema-as-a-key-educational-resource"
            }
          ],
          "display": "Research categories"
        },
        {
          "key": "creator",
          "value": [
            {
              "key": "rinki-roy-bhattacharya",
              "value": "Rinki Roy Bhattacharya",
              "first_name": "Rinki Roy",
              "last_name": "Bhattacharya"
            }
          ],
          "display": "Creator"
        }
      ],
      [
        {
          "key": "id",
          "value": "1",
          "display": "Id"
        },
        {
          "key": "title",
          "value": "Amitabh Bachchan",
          "display": "Title"
        },
        {
          "key": "alias",
          "value": "book-bka-chandra-a-kaleidoscope-2019",
          "display": "Alias"
        },
        {
          "key": "web_serial_number",
          "value": null,
          "display": "Web serial number"
        },
        {
          "key": "reference_code",
          "value": null,
          "display": "Reference code"
        },
        {
          "key": "web_display",
          "value": null,
          "display": "Web display"
        },
        {
          "key": "date_of_library_publication",
          "value": null,
          "display": "Date of library publication"
        },
        {
          "key": "place_of_publication",
          "value": "New Delhi",
          "display": "Place of publication"
        },
        {
          "key": "histographical_note",
          "value": null,
          "display": "Histographical note"
        },
        {
          "key": "main_historical_note",
          "value": null,
          "display": "Main historical note"
        },
        {
          "key": "binding_status",
          "value": "HB",
          "display": "Binding status"
        },
        {
          "key": "accession_number",
          "value": "",
          "display": "Accession number"
        },
        {
          "key": "cdt",
          "value": null,
          "display": "CDT"
        },
        {
          "key": "country_of_publication",
          "value": "India",
          "display": "Country of publication"
        },
        {
          "key": "date_of_published_edition_in_library",
          "value": "2019",
          "display": "Date of published edition in library"
        },
        {
          "key": "dates_of_first_edition",
          "value": "2019",
          "display": "Dates of first edition"
        },
        {
          "key": "isbn_issn_number",
          "value": "978-9386906946",
          "display": "Isbn issn number"
        },
        {
          "key": "language_of_publication",
          "value": "English",
          "display": "Language of publication"
        },
        {
          "key": "number_of_pages_in_publication_text",
          "value": null,
          "display": "Number of pages in publication text"
        },
        {
          "key": "personality_film_visually_featured_on_cover",
          "value": null,
          "display": "Personality film visually featured on cover"
        },
        {
          "key": "publisher",
          "value": "Niyogi Books",
          "display": "Publisher"
        },
        {
          "key": "serial_number",
          "value": null,
          "display": "Serial number"
        },
        {
          "key": "sortable_year_date_of_publication",
          "value": "2019",
          "display": "Sortable year date of publication"
        },
        {
          "key": "sub_title_of_document_book",
          "value": "A Kaleidoscope",
          "display": "Sub title of document book"
        },
        {
          "key": "title_of_document_book",
          "value": "Amitabh Bachchan",
          "display": "Title of document book"
        },
        {
          "key": "book_title_of_document",
          "value": "Amitabh Bachchan",
          "display": "Book title of document"
        },
        {
          "key": "book_sub_title_of_document",
          "value": "A Kaleidoscope",
          "display": "Book sub title of document"
        },
        {
          "key": "volume_issue_number",
          "value": null,
          "display": "Volume issue number"
        },
        {
          "key": "masterlist",
          "value": "amitabh-bachchan",
          "display": "Masterlist"
        },
        {
          "key": "image",
          "value": ["https://tuliresearchcentre.canto.global/direct/image/k26ak2jr1l41703pubem5u8l4a/VYB4ODBjVs_e0NRmFRyF5E5wQ20/m800/800"],
          "display": "Image"
        },
        {
          "key": "document_type",
          "value": "bka",
          "display": "Document type"
        },
        {
          "key": "title_and_type_of_creator",
          "value": "Author",
          "display": "Title and type of creator"
        },
        {
          "key": "number_of_pages_in_publication",
          "value": null,
          "display": "Number of pages in publication"
        },
        {
          "key": "researchCategories",
          "value": [
            {
              "key": "Cinema as a Key Educational Resource",
              "value": "CinemaE",
              "link": "cinema-as-a-key-educational-resource"
            }
          ],
          "display": "Research categories"
        },
        {
          "key": "creator",
          "value": [
            {
              "key": "pradeep-chandra",
              "value": "Pradeep Chandra",
              "first_name": "Pradeep",
              "last_name": "Chandra"
            }
          ],
          "display": "Creator"
        }
      ],
      [
        {
          "key": "id",
          "value": "6",
          "display": "Id"
        },
        {
          "key": "title",
          "value": "Sone Chandi ke Buth",
          "display": "Title"
        },
        {
          "key": "alias",
          "value": "book-bka-abbas-writing-on-cinema-2022",
          "display": "Alias"
        },
        {
          "key": "web_serial_number",
          "value": null,
          "display": "Web serial number"
        },
        {
          "key": "reference_code",
          "value": null,
          "display": "Reference code"
        },
        {
          "key": "web_display",
          "value": null,
          "display": "Web display"
        },
        {
          "key": "date_of_library_publication",
          "value": null,
          "display": "Date of library publication"
        },
        {
          "key": "place_of_publication",
          "value": "Gurugram",
          "display": "Place of publication"
        },
        {
          "key": "histographical_note",
          "value": null,
          "display": "Histographical note"
        },
        {
          "key": "main_historical_note",
          "value": null,
          "display": "Main historical note"
        },
        {
          "key": "binding_status",
          "value": "HB",
          "display": "Binding status"
        },
        {
          "key": "accession_number",
          "value": "",
          "display": "Accession number"
        },
        {
          "key": "cdt",
          "value": null,
          "display": "CDT"
        },
        {
          "key": "country_of_publication",
          "value": "India",
          "display": "Country of publication"
        },
        {
          "key": "date_of_published_edition_in_library",
          "value": "2022",
          "display": "Date of published edition in library"
        },
        {
          "key": "dates_of_first_edition",
          "value": "2022",
          "display": "Dates of first edition"
        },
        {
          "key": "isbn_issn_number",
          "value": "978-0670095933",
          "display": "Isbn issn number"
        },
        {
          "key": "language_of_publication",
          "value": "English",
          "display": "Language of publication"
        },
        {
          "key": "number_of_pages_in_publication_text",
          "value": "232",
          "display": "Number of pages in publication text"
        },
        {
          "key": "personality_film_visually_featured_on_cover",
          "value": null,
          "display": "Personality film visually featured on cover"
        },
        {
          "key": "publisher",
          "value": "Penguin Random House India",
          "display": "Publisher"
        },
        {
          "key": "serial_number",
          "value": null,
          "display": "Serial number"
        },
        {
          "key": "sortable_year_date_of_publication",
          "value": "2022",
          "display": "Sortable year date of publication"
        },
        {
          "key": "sub_title_of_document_book",
          "value": "Writing on Cinema",
          "display": "Sub title of document book"
        },
        {
          "key": "title_of_document_book",
          "value": "Sone Chandi ke Buth",
          "display": "Title of document book"
        },
        {
          "key": "book_title_of_document",
          "value": "Sone Chandi ke Buth",
          "display": "Book title of document"
        },
        {
          "key": "book_sub_title_of_document",
          "value": "Writing on Cinema",
          "display": "Book sub title of document"
        },
        {
          "key": "volume_issue_number",
          "value": null,
          "display": "Volume issue number"
        },
        {
          "key": "masterlist",
          "value": "amitabh-bachchan",
          "display": "Masterlist"
        },
        {
          "key": "image",
          "value": ["https://tuliresearchcentre.canto.global/direct/image/uluudsb8c97k569b2bl59igr7s/h0TTCXIOVKG9BvUG6fpwE598SJo/m800/800"],
          "display": "Image"
        },
        {
          "key": "document_type",
          "value": "bka",
          "display": "Document type"
        },
        {
          "key": "title_and_type_of_creator",
          "value": "Author",
          "display": "Title and type of creator"
        },
        {
          "key": "number_of_pages_in_publication",
          "value": "232",
          "display": "Number of pages in publication"
        },
        {
          "key": "researchCategories",
          "value": [
            {
              "key": "Cinema as a Key Educational Resource",
              "value": "CinemaE",
              "link": "cinema-as-a-key-educational-resource"
            }
          ],
          "display": "Research categories"
        },
        {
          "key": "creator",
          "value": [
            {
              "key": "khwaja-ahmad-abbas",
              "value": "Khwaja Ahmad Abbas",
              "first_name": "Khwaja Ahmad",
              "last_name": "Abbas"
            },
            {
              "key": "syeda-hameed",
              "value": "Syeda Hameed",
              "first_name": "Syeda",
              "last_name": "Hameed"
            },
            {
              "key": "sukhpreet-kahlon",
              "value": "Sukhpreet Kahlon",
              "first_name": "Sukhpreet",
              "last_name": "Kahlon"
            }
          ],
          "display": "Creator"
        }
      ],  
      [
        {
          "key": "id",
          "value": "12",
          "display": "Id"
        },
        {
          "key": "title",
          "value": "The Bachchans",
          "display": "Title"
        },
        {
          "key": "alias",
          "value": "book-bka-ausaja-a-saga-of-excellence-2024",
          "display": "Alias"
        },
        {
          "key": "web_serial_number",
          "value": null,
          "display": "Web serial number"
        },
        {
          "key": "reference_code",
          "value": null,
          "display": "Reference code"
        },
        {
          "key": "web_display",
          "value": null,
          "display": "Web display"
        },
        {
          "key": "date_of_library_publication",
          "value": null,
          "display": "Date of library publication"
        },
        {
          "key": "place_of_publication",
          "value": "Noida",
          "display": "Place of publication"
        },
        {
          "key": "histographical_note",
          "value": null,
          "display": "Histographical note"
        },
        {
          "key": "main_historical_note",
          "value": null,
          "display": "Main historical note"
        },
        {
          "key": "binding_status",
          "value": "HB",
          "display": "Binding status"
        },
        {
          "key": "accession_number",
          "value": "",
          "display": "Accession number"
        },
        {
          "key": "cdt",
          "value": null,
          "display": "CDT"
        },
        {
          "key": "country_of_publication",
          "value": "India",
          "display": "Country of publication"
        },
        {
          "key": "date_of_published_edition_in_library",
          "value": "2024",
          "display": "Date of published edition in library"
        },
        {
          "key": "dates_of_first_edition",
          "value": "2024",
          "display": "Dates of first edition"
        },
        {
          "key": "isbn_issn_number",
          "value": "978-8187108542",
          "display": "Isbn issn number"
        },
        {
          "key": "language_of_publication",
          "value": "English",
          "display": "Language of publication"
        },
        {
          "key": "number_of_pages_in_publication_text",
          "value": "503",
          "display": "Number of pages in publication text"
        },
        {
          "key": "personality_film_visually_featured_on_cover",
          "value": null,
          "display": "Personality film visually featured on cover"
        },
        {
          "key": "publisher",
          "value": "Om Books Internationals",
          "display": "Publisher"
        },
        {
          "key": "serial_number",
          "value": null,
          "display": "Serial number"
        },
        {
          "key": "sortable_year_date_of_publication",
          "value": "2024",
          "display": "Sortable year date of publication"
        },
        {
          "key": "sub_title_of_document_book",
          "value": "A Saga of Excellence",
          "display": "Sub title of document book"
        },
        {
          "key": "title_of_document_book",
          "value": "The Bachchans",
          "display": "Title of document book"
        },
        {
          "key": "book_title_of_document",
          "value": "The Bachchans",
          "display": "Book title of document"
        },
        {
          "key": "book_sub_title_of_document",
          "value": "A Saga of Excellence",
          "display": "Book sub title of document"
        },
        {
          "key": "volume_issue_number",
          "value": null,
          "display": "Volume issue number"
        },
        {
          "key": "masterlist",
          "value": "amitabh-bachchan",
          "display": "Masterlist"
        },
        {
          "key": "image",
          "value": ["https://tuliresearchcentre.canto.global/direct/image/qcnp6tve6d4mr5aj557b8t8a57/H5bqMJA5ywlmTdzcJT-ac3rMQmk/m800/800"],
          "display": "Image"
        },
        {
          "key": "document_type",
          "value": "bka",
          "display": "Document type"
        },
        {
          "key": "title_and_type_of_creator",
          "value": "Author",
          "display": "Title and type of creator"
        },
        {
          "key": "number_of_pages_in_publication",
          "value": "503",
          "display": "Number of pages in publication"
        },
        {
          "key": "researchCategories",
          "value": [
            {
              "key": "Cinema as a Key Educational Resource",
              "value": "CinemaE",
              "link": "cinema-as-a-key-educational-resource"
            }
          ],
          "display": "Research categories"
        },
        {
          "key": "creator",
          "value": [
            {
              "key": "s-m-m-ausaja",
              "value": "S M M Ausaja",
              "first_name": "S M M",
              "last_name": "Ausaja"
            }
          ],
          "display": "Creator"
        }
      ]
                                                    
]

export const amitabh_bachchan_biblography_filter_options = [
    {
      key: "creatorType",
      display: filterAttribute.AUTHOR_EDITOR_CONTRIBUTOR,
      value: ["Author"],
    },
    {
      key: "year",
      display: filterAttribute.DATE,
      value: [
        "1995",
        "1999",
        "2005",
        "2006",
        "2009",
        "2011",
        "2014",
        "2019",
        "2022",
        "2024",
      ],
    },
    {
      key: "docType",
      display: filterAttribute.DOCUMENT_TYPE,
      value: ["Book.bka"],
    },
  ];