import React from 'react';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import Small from '../../components/layouts/Typography/Small';

const TimelineWithText = ({ data, handleDrawer,handleImageClick }) => {
    const btnRef = React.useRef();
    return (
        <VerticalTimeline layout={"1-column-left"} className="layout mt-0">
            {data.map((item, index) => (
                item?.publicationYear && (
                    <VerticalTimelineElement
                        id="timeline2"
                        className="col-12 pr-0"
                        key={index}
                        contentStyle={{ fontSize: "sm" }}
                        iconStyle={{
                            background: "#fff",
                            color: "black",
                            fontSize: "16px",
                            fontWeight: "700",
                        }}
                        icon={item?.publicationYear}
                    >
                        {item?.link ? <Small
                            ref={btnRef}
                            cursor={"pointer"}
                            color="#035DA1"
                            onClick={(e) => {
                                handleDrawer && handleDrawer(item);
                                handleImageClick && handleImageClick(item?.image, item, true, index);

                            }}
                        >
                            {item?.listDesc}
                        </Small> : (<Small
                            ref={btnRef}
                            cursor={"pointer"}
                            color="#035DA1"
                            onClick={(e) => {
                                handleDrawer && handleDrawer(item);
                                handleImageClick && handleImageClick(item?.image, item, true, index);
                            }}
                            minH="50px"
                        >
                            {item?.listDesc}
                        </Small>)}
                    </VerticalTimelineElement>
                )
            ))}
        </VerticalTimeline>
    );
};

export default TimelineWithText;
