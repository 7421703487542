//S3 Bucket osw-dev-images
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;

const Banner1 = `${IMAGE_BASE_PATH}/ui/WhatsNew/1.jpg`
const Banner2 = `${IMAGE_BASE_PATH}/ui/WhatsNew/2.jpg`
const Banner3 = `${IMAGE_BASE_PATH}/ui/WhatsNew/3.jpg`
const Banner4 = `${IMAGE_BASE_PATH}/ui/WhatsNew/4.jpg`
const Banner5 = `${IMAGE_BASE_PATH}/ui/WhatsNew/5.jpg`

export const HomeSliderData = [
  {
    id: 3,
    imgPath: Banner1,
    title:
      "Carcassonne, painted by S.H. Raza in 1956, is a rendering of the French Citadel",
    button1: "Shraza",
    button2: "1956",
    button3: "Indian art in France",
    description1:
      "Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.",
    description2:
      "Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.",
    description3:
      "Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.",
  },
  {
    id: 4,
    imgPath: Banner2,
    title:
      "Carcassonne, painted by S.H. Raza in 1956, is a rendering of the French Citadel",
    button1: "Shraza",
    button2: "1963",
    button3: "Indian Cinema",
    description1:
      "Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.",
    description2:
      "Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.",
    description3:
      "Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.",
  },
  {
    id: 5,
    imgPath: Banner3,
    title:
      "Carcassonne, painted by S.H. Raza in 1956, is a rendering of the French Citadel",
    button1: "M. K. Gandhi",
    button2: "1960",
    button3: "Hostorical Epic",
    description1:
      "Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.",
    description2:
      "Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.",
    description3:
      "Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.",
  },
  {
    id: 6,
    imgPath: Banner4,
    title:
      "Carcassonne, painted by S.H. Raza in 1956, is a rendering of the French Citadel",
    button1: "Lucknow",
    button2: "1784",
    button3: "Indian Architecure",
    description1:
      "Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.",
    description2:
      "Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.",
    description3:
      "Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.",
  },
  {
    id: 7,
    imgPath: Banner5,
    title:
      "Carcassonne, painted by S.H. Raza in 1956, is a rendering of the French Citadel",
    button1: "Calcutta",
    button2: "1910",
    button3: "Automobilia",
    description1:
      "Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.",
    description2:
      "Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.",
    description3:
      "Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.",
  },
];
