import { Text } from "@chakra-ui/react";
import React from "react";

const Large = ({ children,fontWeight="700", textStyle="primary", fontSize="45px",lineHeight="64px", ...props }) => {
  return (
    <Text
      as="h1"
      {...props}
      fontSize={fontSize}
      fontWeight={fontWeight}
      lineHeight={lineHeight}
      textStyle={textStyle}
    >
      {children}
    </Text>
  );
};
export default React.memo(Large);