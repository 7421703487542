import {createSlice} from "@reduxjs/toolkit";

export const researchCategoriesSlice = createSlice({
    initialState:[],
    name: "researchCategories",
    reducers:{
        getCategoriesData:(state,data)=>{
            return{...state, data:data.payload} ;
        }
    }
});

export const { getCategoriesData} = researchCategoriesSlice.actions;
export default researchCategoriesSlice.reducer;