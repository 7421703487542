import {
  Container,
  HStack,
  Stack,

} from "@chakra-ui/react";

//Core file
import React from "react";

//Component file
import SongSynopsisBooklet from "./SongSynopsisBooklet";


export default function Booklets({ winnerName }) {
  let tableName="book"

  return (
    <Container
      minW={"100%"}
      pb="4"
      paddingX={{ base: "4", md: "0px" }}
    >
      <Stack>
        <HStack flexDirection={{base:"column",md:"row"}}>
        <SongSynopsisBooklet masterlist={winnerName} tableName={tableName}/>
        </HStack>
      </Stack>
    </Container>
  );
}
