import {
  Container,
  HStack,
  Stack,
  Text,
} from "@chakra-ui/react";

//Core files
import React, { useEffect, useState } from "react";
import "react-vertical-timeline-component/style.min.css";
import { TbFoldersOff } from "react-icons/tb";
import { useNavigate, useParams } from "react-router-dom";

//Service files
import { CopyPasteService } from "../../../../services/CopyPaste";
import { isTransformationNotAllowed } from "../../../../util/isTranformationAllowed";
import * as getBibliographyData from "../../../../services/Bibliography";

//Component files
import { FilterButton } from "../../../../CommonComponents/FilterButton";
//Language files
import { common, explore } from "../../../../constants/constants";
import ArchiveGrid from "../../../../FunctionalComponents/SimpleGrid/ArchiveGrid";
import Grid from "../../../../FunctionalComponents/SimpleGrid/Grid";
import DrawerFooter from "../../../../FunctionalComponents/CommonDrawer/ArchiveDrawerSection/DrawerFooter";
import DrawerBody from "../../../../FunctionalComponents/CommonDrawer/ArchiveDrawerSection/DrawerBody";
import DrawerHeader from "../../../../FunctionalComponents/CommonDrawer/ArchiveDrawerSection/DrawerHeader";
import CommonDrawer from "../../../../FunctionalComponents/CommonDrawer/CommonDrawer";
import { FilterBox } from "../../../../FunctionalComponents/Filter/FilterBox";

function GridView({
  handleBookClick,
  contentClicked,
  scanClicked,
  setContentClicked,
  setScanClicked,
  openDrawer,
  setOpenDrawer,
  setleftFlag,
  tableName,
  masterlist,
}) {
  const [showFilterBox, setShowFilterBox] = useState(false);
  const [bibliographyObject, setBibliographyObject] = React.useState();
  const [apply, setApply] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [FilterData, setFilterData] = useState([{}]);
  const [loading, setLoading] = useState(true);
  const [indexValue, setIndexValue] = useState();
  const [swiper, setSwiper] = useState(null);
  const [drawerBook, setDrawerBook] = useState("");
  const navigate = useNavigate();
  const { bookAlias } = useParams();
  const [filterCount, setfilterCount] = useState(0);
  const [filteroptionData, setFilteroptionsData] = useState({});
  const [filterOptions, setFilterOptions] = useState({
    docType: [],
    year: [],
    creatorType: [],
    publisher: [],
    searches_v: [],
  });

  const handleFilterClick = () => {
    setShowFilterBox(!showFilterBox);
  };

  const handleDrawer = (item) => {
    setDrawerBook(item);
    setBibliographyObject(item);
    setOpenDrawer(true);
    setleftFlag(true);
    navigate(`${item?.alias}`)

  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };
  useEffect(() => {
    setfilterCount(0);
    for (const key in filterOptions) {
      if (filterOptions[key].length > 0) {
        setfilterCount((prev) => prev + 1);
      }
    }
  }, [filterOptions]);

  // Fetch data function
  const fetchData = async () => {
    let result;

    if (
      // Object.values(filterOptions).every((options) => options.length === 0) &&
      searchTerm === ""
    ) {
      // No filters applied, fetch all data
      result = await getBibliographyData.bibliographyByMasterlist(
        tableName,
        masterlist
      );
    } else {
      // Filters are applied, fetch filtered data
      result = await getBibliographyData.bibliographyByMasterlist(
        tableName,
        masterlist,
        // filterOptions,
        searchTerm
      );
    }

    setLoading(false);
    const data = result || []; // Ensure data is not null or undefined
    setFilterData(citationFactor(data));
  };

  useEffect(() => {
    if (isTransformationNotAllowed() === false) {
      fetchData();
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isTransformationNotAllowed() === false) {
      if (apply) {
        fetchData();
        setApply(false);
      }
    }
    // eslint-disable-next-line
  }, [apply]);

  function capitalizeWords(str) {
    return str.replace(/\b\w/g, function (char) {
      return char.toUpperCase();
    });
  }
  const citationFactor = (data) => {
    return data?.map((item) => {
      const imagePath =
        item?.find((nestedItem) => nestedItem?.key === "image")?.value[0] || null;
      const sliderImage =
        item?.find((nestedItem) => nestedItem?.key === "image")?.value.length >
        1 &&
        item?.find((nestedItem) => nestedItem?.key === "image")?.value.slice(1);
      const creator =
        item?.find((nestedItem) => nestedItem?.key === "creator")?.value || [];
      const lastName = creator?.map(nestedItem => nestedItem?.last_name)?.join(", ") || null;
      const firstName = creator?.map(nestedItem => nestedItem?.first_name)?.join(", ") || null;
      const author = lastName && firstName ? `${lastName} ${firstName}` : "";

      const fullname = lastName && firstName ? `${firstName} ${lastName}` : "";

      const bookKey =
        item?.find((nestedItem) => nestedItem?.key === "book_title_of_document")
          ?.value || null;
      const subbookKey =
        item?.find(
          (nestedItem) => nestedItem?.key === "book_sub_title_of_document"
        )?.value || null;
      const book = bookKey + " - " + subbookKey;
      const link =
        item?.find((nestedItem) => nestedItem?.key === "drawer_link")?.value ||
        null;
      const publicationYear =
        item?.find(
          (nestedItem) => nestedItem?.key === "sortable_year_date_of_publication"
        )?.value || null;
      const publisher =
        item?.find((nestedItem) => nestedItem?.key === "publisher")?.value ||
        null;
      const documentType =
        item?.find((nestedItem) => nestedItem?.key === "document_type")?.value ||
        "";
      const nameText =
        capitalizeWords(
          item
            .find((nestedItem) => nestedItem?.key === "masterlist")
            ?.value.replace(/-/g, " ")
        ) || null;
      const typeCreator =
        item?.find((nestedItem) => nestedItem?.key === "title_and_type_of_creator")?.value ||
        null;
      const header = nameText + " & " + book;

      const desc = `${lastName && `${lastName},`} ${author && `${bookKey} ,`} ${publicationYear} ${documentType}`;
      const alias = item?.find((nestedItem) => nestedItem?.key === "alias")?.value

      return {
        imagePath,
        desc,
        header,
        bookKey,
        typeCreator,
        link,
        author,
        book,
        publicationYear,
        publisher,
        nameText,
        sliderImage,
        fullname,
        alias,
        footer: [
          {
            text: "Contents Page →",
          },
          {
            text: "Index Page →",
          },
          {
            text: "Scanned Pages →",
          },
        ],
      };
    });
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const { mockData } =
          (await getBibliographyData.bibliographyByMasterlist(
            tableName,
            masterlist
          )) || {};

        if (mockData && isTransformationNotAllowed() === true) {
          const processedData = citationFactor(mockData);
          setLoading(false);
          setFilterData(processedData);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    async function fetchData() {
      if (apply && isTransformationNotAllowed() === true) {
        try {
          const { filterMockData } =
            (await getBibliographyData.bibliographyByMasterlist(
              tableName,
              masterlist
            )) || {};
          const filteredMockDataResult = await filterMockData(
            null,
            searchTerm
          );
          setFilterData(citationFactor(filteredMockDataResult));
        } catch (error) {
          console.error("Error filtering data:", error);
        }
        setApply(false);
      }
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apply, masterlist, searchTerm, tableName]);

  //drawer dynamic
  const handleCloseClick = () => {
    setContentClicked(false);
    setScanClicked(false);
  };
  const showSliderImage = (index) => {
    swiper.slideTo(index);
  };
  const handleClose = () => {
    setBibliographyObject();
    handleCloseClick();
    setDrawerBook("");
    navigate(-1);
  };
  const handleContentClicked = (index, text) => {
    setIndexValue(index);
    showSliderImage(2);
    if (text === "Contents Page →") {
      setContentClicked(true);
      setScanClicked(false);
    }
    if (text === "Scanned Pages →") {
      setScanClicked(false);
      setContentClicked(false);
    }
  };

  useEffect(() => {
    if (FilterData) {
      const item = FilterData.find((it) => it.alias === bookAlias)
      setDrawerBook(item);
      setOpenDrawer(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps, no-use-before-define
  }, [FilterData, bookAlias])

  React.useEffect(() => {
    const getFilterOptionsData = async () => {
      const result = await getBibliographyData.getBibliographyFilterOptions(
        tableName,
        masterlist
      );
      if (result) {
        const dataIntro = result;
        setFilteroptionsData(dataIntro);
      }
    };

    getFilterOptionsData();
  }, [masterlist, tableName]);
  
  const handleReset=() => {
    setFilterOptions({
      docType: [],
      year: [],
      creatorType: [],
      publisher: [],
    });
    setSearchTerm("");
  }
  return (
    <>
      {!loading ? (
        <>
          <Stack
            flexDirection={"row-reverse"}
            minH={"100vh"}
            marginTop={"20px"}
          >
            {bookAlias && drawerBook!==undefined && (
              <CommonDrawer
                drawerOpen={openDrawer}
                setDrawerOpen={handleClose}
                drawerHeader={
                  <DrawerHeader
                  data={drawerBook}
                  handleClose={handleClose}
                />
            }
            drawerBody={
              <DrawerBody
                setSwiper={setSwiper}
                data={drawerBook}
                handleBookClick={handleBookClick}
                bibliographyObject={bibliographyObject}
              />
            }
            drawerFooter={
              <DrawerFooter
                data={drawerBook}
                handleContentClicked={handleContentClicked}
                scanClicked={scanClicked}
                indexValue={indexValue}
              />
            }
            />
            )}
            {!showFilterBox && (
              <FilterButton onClick={handleFilterClick} marginRight={"0px"} />
            )}
            <Container maxW="auto" className="songs" px="0" py="2">
              <Stack
                flexDirection={{ base: "column", md: "row" }}
                gap={4}
                justifyContent={
                  FilterData.length > 0 ? "center" : "space-between"
                }
              >
                <HStack
                  onCopy={(e) =>
                    CopyPasteService(e, `${window.location.href}/0/1`)
                  }
                  marginRight={!showFilterBox && { base: "0px", md: "44px" }}
                >
                  {FilterData.length > 0 ? (
                    <Grid
                      data={FilterData && FilterData}
                      handleDrawer={handleDrawer}
                      spacing={"24px"}
                      columns={{ base: 2, md: 3, lg: 4, xl: 4 }}
                      component={ArchiveGrid}
                      border={true}
                    />
                  ) : (
                    <Text
                      py={"40"}
                      fontSize={"45px"}
                      fontWeight="700"
                      textAlign="center"
                      display={"flex"}
                      alignItems={"center"}
                      marginLeft={"auto"}
                    >
                      <TbFoldersOff style={{ marginRight: "20px" }} />
                      {common.NO_DATA}
                    </Text>
                  )}
                </HStack>
                <HStack
                  marginRight={"0px!important"}
                  className="mt-0"
                  alignItems={"flex-start"}
                >
                  {showFilterBox && (
                    <FilterBox
                    setShowFilterBox={setShowFilterBox}
                    setApply={setApply}
                    explore={explore}
                    handleSearch={handleSearch}
                    filterOptions={filterOptions}
                    filteroptionData={filteroptionData}
                    setFilterOptions={setFilterOptions}
                    setSearchTerm={setSearchTerm}
                    filterCount={filterCount}
                    searchTerm={searchTerm}
                    handleReset={handleReset}
                  />
                  )}
                </HStack>
              </Stack>
              {/* Need for future use */}
              {/* {contentClicked && (
                <ContentModal
                  contentClicked={contentClicked}
                  setContentClicked={setContentClicked}
                />
              )}
              {scanClicked && (
                <SliderModal
                  scanClicked={scanClicked}
                  setScanClicked={setScanClicked}
                  sliderModalImg={sliderImages}
                />
              )} */}
            </Container>
          </Stack>
        </>
      ) : (
        <Text py={"40"} fontSize={"45px"} fontWeight="700" textAlign="center">
          {common.LOADING}
        </Text>
      )}
    </>
  );
}
export default GridView;