import {
  Box,
  Image,
  Stack,
  Text,
  useBreakpointValue,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  useDisclosure,
} from "@chakra-ui/react";
import React from "react";
import H1 from "../../Theme/Typography/H1";
import H2 from "../../Theme/Typography/H2";
import H3 from "../../Theme/Typography/H3";

const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;
const closeIcon = `${IMAGE_BASE_PATH}/ui/Chevron/closeicon.svg`;

export default function SuccessCard({ setSuccessCard, data, successCardData }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  
  // Determine if the user is on a mobile view
  const isMobile = useBreakpointValue({ base: true, md: false });

  // Open modal if mobile view
  React.useEffect(() => {
    if (isMobile) {
      onOpen();
    }
  }, [isMobile, onOpen]);

  return (
    <>
      {isMobile ? (
        <Modal isOpen={isOpen} onClose={onClose} motionPreset="slideInBottom">
          <ModalOverlay />
          <ModalContent
            mt="10px"
            mx="5px"
            maxW="90%"
            borderWidth="1px"
            borderColor="color.primary"
            boxShadow="lg"
            borderRadius="md"
            top={"15%"}
          >
            <ModalHeader>
              <Image
                src={closeIcon}
                position="absolute"
                top="10px"
                right="10px"
                cursor={"pointer"}
                onClick={onClose}
                boxSize="24px"
              />
            </ModalHeader>
            <ModalBody>
              <Stack alignItems={"center"} spacing="4">
                <H1
                  textAlign="center"
                  fontSize="24px"
                  lineHeight="28px"
                  fontWeight="700"
                >
                  {successCardData?.name}
                </H1>
                <H2 lineHeight="20px" fontWeight="500">
                  {successCardData?.research_categories[0]?.value}
                </H2>
                <H1 fontWeight="700" lineHeight="22px">
                  {`${data?.currentUserStage?.stage?.stage} Level`}
                </H1>
                <H3 fontWeight="600" lineHeight="16px" py="10px">
                  You have completed{" "}
                  <Text as={"span"} color={"color.darkred"} fontWeight="600">
                    2/2
                  </Text>{" "}
                  readings.
                </H3>
                <H3 mt="2">
                  You answered{" "}
                  <Text as="span" fontWeight="bold" color={"color.red"}>
                    9/10
                  </Text>{" "}
                  questions <br />correctly!
                </H3>
                <Text mt="2" fontWeight="bold" color="color.success1">
                  <span style={{ color: "#000" }}>Status:</span> SUCCESSFULLY <br />COMPLETED
                </Text>
              </Stack>
            </ModalBody>
          </ModalContent>
        </Modal>
      ) : (
        <Box
          borderWidth="1px"
          overflow="hidden"
          px="30px"
          pt="20px"
          pb="30px"
          borderColor="color.primary"
          boxShadow="lg"
          position="relative"
          maxW="340px"
          margin="auto"
        >
          <Image
            src={closeIcon}
            position="absolute"
            top="10px"
            right="10px"
            cursor={"pointer"}
            onClick={() => setSuccessCard(false)}
          />
          <Stack alignItems={"center"}>
            <H1 textAlign="center" fontSize="35px" lineHeight="39.92px" fontWeight="700">
              {successCardData?.name}
            </H1>
            <H2 lineHeight={"23.48px"} fontWeight="500">
              {successCardData?.research_categories[0]?.value}
            </H2>
            <H1 fontWeight="700" lineHeight="28.18px">
              {`${data?.currentUserStage?.stage?.stage} Level`}
            </H1>
          </Stack>
          <H3 fontWeight="600" lineHeight={"18.78px"} py="20px">
            You have completed{" "}
            <Text as={"span"} color={"color.darkred"} fontWeight="600">
              2/2
            </Text>{" "}
            readings.
          </H3>
          <H3 mt="2">
            You answered{" "}
            <Text as="span" fontWeight="bold" color={"color.red"}>
              9/10
            </Text>{" "}
            questions <br />correctly!
          </H3>
          <Text mt="4" fontWeight="bold" color="color.success1">
            <span style={{ color: "#000" }}>Status:</span> SUCCESSFULLY <br />COMPLETED
          </Text>
        </Box>
      )}
    </>
  );
}
