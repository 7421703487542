import { Box, Stack, Text } from "@chakra-ui/react";
import { common } from "../constants/constants";
import { useTranslation } from "react-i18next";

export const WebpageComingSoon = () => {
  const { t } = useTranslation("");

    return (
      <>
       <Stack alignItems={"center"}>
       <Box>
         <Text
           py={"40"}
           fontSize={{base:"24px", md:"25px"}}
           fontWeight="700"
           textAlign="center"
         >
           {t(common.WEBPAGE_COMING_SOON)}
         </Text>
       </Box>
     </Stack>
     </>
    );
  };