import { Box, Divider, HStack, Stack, Text } from '@chakra-ui/react';
import { AiOutlinePicture } from '@react-icons/all-files/ai/AiOutlinePicture';
import React from 'react'

export default function AccordianEduction(props) {
  return (
    <>
              <Stack>
                {props?.educationData?.length > 0 && (
                  <>
                    <HStack
                      pb={"2"}
                      pt="0"
                      flexDirection={"row"}
                      marginLeft={"30px"}
                    >
                      <Stack>
                        {props?.educationData?.map((education, educationIndex) => {
                          const imageObject = education?.image
                            ? education?.image
                            : null;
                          return (
                            <>
                              <Text
                                fontSize={"16px"}
                                fontWeight={"700"}
                                key={educationIndex}
                                marginTop={"0px !important"}
                              >
                                {education?.year ? <> {education?.year}</>: "#"}
                              </Text>
                              <HStack
                                marginLeft={
                                  imageObject
                                    ? "-25px!important"
                                    : "0px!important"
                                }
                                alignItems={"flex-start"}
                                paddingBottom={"15px"}
                              >
                                {imageObject && (
                                  <Box pt="1">
                                    <AiOutlinePicture
                                      onClick={() =>
                                        props?.showEducationImages(imageObject, true)
                                      }
                                      cursor={"pointer"}
                                      color="#1E90FF"
                                      size={18}
                                    />
                                  </Box>
                                )}
                                <Text
                                  fontSize={"16px"}
                                  fontWeight={"400"}
                                  width={"280px"}
                                  key={educationIndex}
                                >
                                  {education?.education}
                                </Text>
                              </HStack>
                            </>
                          );
                        })}
                      </Stack>
                    </HStack>
                    <Divider color={"#B5B5B5"} borderBottomWidth={"0.1px"} />
                  </>
                )}
              </Stack>
             
            </>
  )
}
