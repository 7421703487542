
import {
  Box,
  HStack,
  useColorModeValue,
  Drawer,
  DrawerContent,
  Text,
  useDisclosure,
  Stack,
  Container,
  Image,
  ModalOverlay,
  Modal,
  ModalContent,
  ModalBody,
} from "@chakra-ui/react";

//Core files
import React, { useEffect, useState } from "react";
import "react-vertical-timeline-component/style.min.css";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import { useNavigate, useParams } from "react-router-dom";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

//Service files
import * as getIntegratedTimelineData from "../../../MockData/IntegratedTimeline_data";
import * as getIntroductionData from "../../../services/Introduction";
import * as getLandingMasterlistData from "../../../services/SectionService";
import { CopyPasteService } from "../../../services/CopyPaste";

//Component file
import Small from "../../../components/layouts/Typography/Small";
import { filters } from "../../../util/helper";
import H4 from "../../../components/layouts/Typography/H4";
import Large from "../../../components/layouts/Typography/Large";
import YearPicker from "../../../FunctionalComponents/YearPicker";
import CommonLeftSidebar from "../../../FunctionalComponents/LeftSidebar/CommonLeftSidebar";
import { ImagePopupModal } from "../../Film/BodyOfWork/Synopsis";
import { BottomMainMenu } from "../../../CommonComponents/BottomMainMenu";

//Language files
import { explore } from "../../../constants/constants";
import { WebpageComingSoon } from "../../../CommonComponents/WebpageComingSoon";

//S3 Bucket osw-dev-images
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;

const closeIcon = `${IMAGE_BASE_PATH}/ui/Chevron/closeicon.svg`

export default function IntegratedTimeline({ children }) {
  const { isOpen, onClose } = useDisclosure();
  const [modalcard, setModalcard] = useState(false);
  const {alias}=useParams();
  const aliasAPI = alias;
  const currentEndpoint = window.location.pathname;
  const parts = currentEndpoint.split("/");
  const masterlistType = parts[1];
  const [introData, setIntroData] = useState([]);
  const [horislinkData, setHorislinkData] = useState([]);
  const [artistDetails, setArtistDetails] = useState(null);

  React.useEffect(() => {
    const getIntroData = async () => {
      const result = await getIntroductionData.introductionByAlias(
        "",
        aliasAPI
      );
      if (result) {
        setHorislinkData([
          {
            link: `/research-categories/${
              result[0].find((item) => item?.key === "researchCategories")
                ?.value[0].alias
            }`,
            title: result[0].find((item) => item?.key === "researchCategories")
              ?.value[0]?.value,
          },
          {
            link: `/categories/indian-cinema`,
            title: result[0].find((item) => item?.key === "subject")?.value,
          },
        ]);
        setArtistDetails(
          result.reduce((acc, it) => {
            acc.name = it?.find((item) => item?.key === "full_name")?.value;
            acc.date_of_birth = it?.find(
              (item) => item?.key === "date_of_birth"
            )?.value;
            acc.date_of_death = it?.find(
              (item) => item?.key === "date_of_death"
            )?.value;
            return acc;
          }, {})
        );
      }
    };
    const getLandingData = async () => {
      const result = await getLandingMasterlistData.getLandingPageInfoByAlias(
        aliasAPI
      );
      if (result) {
        setIntroData(
          result.map((it) => ({
            text: it?.find((item) => item?.key === "landing_page_title")?.value,
            to: `/${masterlistType}/${it?.find((item) => item?.key === "masterlist")?.value}/${
              it?.find((item) => item?.key === "landing_page_abbreviation")?.value
            }`,
            title:it?.find((item) => item?.key === "landing_page_abbreviation")?.value
          }))
        );
      }
    };
    getIntroData();
    getLandingData();
  }, [masterlistType, aliasAPI]);
  
  return (
    <Box minH="100%" bg={useColorModeValue("white", "gray.900")}>
      <SidebarContent
        onClose={() => onClose}
        display={{ base: "none", md: "block" }}
        zIndex={"99999"}
        height="calc(100vh - 64px)"
        modalcard={modalcard}
        aliasAPI={aliasAPI}
        horislinkData={horislinkData}
        artistDetails={artistDetails}
        introData={introData}
        alias={alias}
        masterlistType={masterlistType}
      />
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
      >
        <DrawerContent>
          <SidebarContent
            onClose={onClose}
            modalcard={modalcard}
            aliasAPI={aliasAPI}
            alias={alias}
            masterlistType={masterlistType}
          />
        </DrawerContent>
      </Drawer>

      <Box ml={{ base: 0, md: 52 }} bg="white">
        {children}
        <IntegratedTimelineBody
          modalcard={modalcard}
          setModalcard={setModalcard}
          aliasAPI={aliasAPI}
        />
      </Box>
    </Box>
  );
}

const SidebarContent = ({
  title,
  date,
  datedod,
  onClose,
  modalcard,
  aliasAPI,
  horislinkData,
  artistDetails,
  introData,
  alias,
  masterlistType,
  ...rest
}) => {

  const oSections = [
    {
      to: `/${masterlistType}/${alias}/1`,
      title: "",
      text: explore.POSTS,
    },
    {
      to: `/${masterlistType}/${alias}/2`,
      title: "",
      text: explore.INSIGHTS,
    },
  ];
  const navigate = useNavigate();
  const handleNavigation = () => {
    navigate(-1);
  };
  return (
    <>
    <BottomMainMenu />
    <CommonLeftSidebar
    sections={introData}
    oSections={oSections}
    hMenu={horislinkData}
    artistDetails={artistDetails}
    leftFlag={modalcard}
    handleNavigation={handleNavigation}
    />
    </>
  );
};

export const IntegratedTimelineBody = ({ modalcard, setModalcard, aliasAPI }) => {
  const [yearRangeFilterOptions, setYearRangeFilterOptions] = React.useState(
    []
  );
  const [yearFilterOptions, setYearFilterOptions] = React.useState([]);
  const [filter, setFilter] = React.useState({});
  const [timeline, setTimeline] = React.useState([]);
  const [activeCard, setActiveCard] = React.useState();
  const sliderRef = React.useRef(null);
  const sliderRef1 = React.useRef(null);
  const sliderRef3 = React.useRef(null);
  const [list, setlist] = React.useState([]);
  const [titleOfTimePeriod, setTitleOfTimePeriod] = React.useState([]);
  const [selectedYearIndex, setSelectedYearIndex] = useState({});
  const [timeLineCopy, setTimeLineCopy] = useState([]);
  const [selectedItemIndex, setSelectedItemIndex] = useState(null);
  const [contentAwaited, setContentAwaited] = useState(false);
  const [clickedImageIndex, setClickedImageIndex] = useState(null);
  const [synopsisFullScreenImage, setSynopsisFullScreenImage] = useState(false);
  const [synopsisImgData, setsynopsisImgData] = useState(null);
  const [mergeData, setMergeData] = useState([]);

  // YearRangeFilterOptions
  React.useEffect(() => {
    const fetchYearRangeFilterOptions = async () => {
      const yearRangeFilterOptions =
        await getIntegratedTimelineData.integratedTimelineDataByMasterlist(
          aliasAPI
        );
      function uniqueElementsByTimePeriod(inputArray) {
        const uniqueTimePeriodsSet = new Set();
        const uniqueElementsArray = [];

        for (const element of inputArray) {
          const timePeriod = element.time_period;

          // Check if the time period is not in the set, then add it to the set and push the element to the result array
          if (!uniqueTimePeriodsSet.has(timePeriod)) {
            uniqueTimePeriodsSet.add(timePeriod);
            uniqueElementsArray.push(element);
          }
        }

        return uniqueElementsArray;
      }

      const inputArray = yearRangeFilterOptions; 
      const uniqueElements = uniqueElementsByTimePeriod(inputArray);
      setYearRangeFilterOptions(uniqueElements);
    };
    fetchYearRangeFilterOptions();
  }, [aliasAPI]);

  // Get Data
  React.useEffect(() => {
    const getData = async () => {
      sliderRef?.current?.swiper?.slideTo(0);
      const result =
        await getIntegratedTimelineData.integratedTimelineDataByMasterlist(
          aliasAPI
        );

      if (result?.length === 0) {
        setContentAwaited(true);
      }
      if (result?.length > 0) {
        setContentAwaited(false);
        let groupedBySortableDate = result.reduce((finalRes, ite) => {
          if (!finalRes[ite.sortable_date]) {
            finalRes[ite.sortable_date] = [];
          }
          finalRes[ite.sortable_date].push(ite);
          return finalRes;
        }, {});
        setTimeLineCopy(groupedBySortableDate);
        setYearFilterOptions(Object.keys(groupedBySortableDate));
        setTimeline(groupedBySortableDate);
        const filterKeys = Object.keys(filter);

        if (filterKeys?.length > 0) {
          let data = filters(result, filter);

          let filteredData = data.reduce((finalRes, ite) => {
            if (!finalRes[ite.sortable_date]) {
              finalRes[ite.sortable_date] = [];
            }
            finalRes[ite.sortable_date].push(ite);
            return finalRes;
          }, {});

          setTimeline(filteredData);
          setTitleOfTimePeriod(
            filteredData[Object.keys(filteredData)[0]][0].title_of_time_period
          );
        } else {
          setTimeLineCopy(groupedBySortableDate);
          setTimeline(groupedBySortableDate);
          setTitleOfTimePeriod(
            groupedBySortableDate[Object.keys(groupedBySortableDate)[0]][0]
              .title_of_time_period
          );
        }

        // To Slide the top year filter
        if (filter) {
          const index = yearRangeFilterOptions?.findIndex(
            (item) =>
              item?.time_period === filter.time_period ||
              (filter.time_period !==undefined && filter.time_period.length > 1 &&
                item?.time_period === filter.time_period[1])
          );
          if (index >= 8) {
            sliderRef3?.current?.swiper?.slideTo(2);
          }
        }
      }
    };
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, aliasAPI]);

  const handleYearFilter = (year) => {
    if (timeLineCopy[year]) {
      const tempList = timeLineCopy[year];
      setlist(tempList);
    }
  };

  const handleTo = (number) => {
    if (!sliderRef.current) return;
    setActiveCard(number);
    sliderRef1?.current?.swiper?.slideTo(number);
    setSelectedItemIndex(number);
  };

  const toggleModal = () => {
    setModalcard(!modalcard);
  };
  
  const showSynopsisImages = (image, flag, index) => {
    if (image) {
      setClickedImageIndex(index);
      setSynopsisFullScreenImage(flag);
      setsynopsisImgData(image);
    } else {
      setSynopsisFullScreenImage(flag);
      setsynopsisImgData(null);
    }
  };

  useEffect(() => {
    setMergeData([]);
    Object.keys(timeline).forEach((key) => {
      let index = selectedYearIndex[key] || 0;
      let item = timeline[key][index];
      if (item?.fullImage) {
        setMergeData((prevMergeData) => [
          ...prevMergeData,
          { imagePath: item?.fullImage, newDesc: item?.caption_1 },
        ]);
      }
    });
  }, [synopsisFullScreenImage, timeline, selectedYearIndex]);
  return (
    <>
      {!contentAwaited ? (
        <>
          <ImagePopupModal
            isOpen={synopsisFullScreenImage}
            onClose={() => showSynopsisImages(null, false)}
            imageData={synopsisImgData}
            data={mergeData}
            clickedImageIndex={clickedImageIndex}
          />
          <Stack alignItems={"center"}>
            {/* Modal Pop for bottom selected year */}
            <YearModalPopup 
            modalcard={modalcard}
            setModalcard={setModalcard}
            setSelectedItemIndex={setModalcard}
            data={list}
            sliderRef={sliderRef}
            />
            {/* Top Year range filter */}
            <YearRangeFilter
              sliderRef3={sliderRef3}
              yearRangeFilterOptions={yearRangeFilterOptions}
              filter={filter}
              setFilter={setFilter}
              yearFilterOptions={yearFilterOptions}
              handleTo={handleTo}
              setSelectedItemIndex={setSelectedItemIndex}
              synopsisFullScreenImage={synopsisFullScreenImage}
            />
            <Container
              maxW={"auto"}
              px="0px"
              className="middle-section-height"
            >
              <Box py="4" marginTop={{base:"15%",md:"5%"}}>
                <H4>{titleOfTimePeriod}</H4>
              </Box>
              <Stack position={"relative"} id="integratedtimelinearrow">
                {contentAwaited ? (
                  <WebpageComingSoon/>
                ) : (
                  <Swiper
                    ref={sliderRef}
                    initialSlide={activeCard}
                    slidesPerView={3}
                    centeredSlides={false}
                    spaceBetween={20}
                    grabCursor={false}
                    navigation={true}
                    modules={[Navigation]}
                    className="mySwiper"
                    breakpoints={{
                      320: {
                        slidesPerView: 1,
                        spaceBetween: 20
                      },
                      // when window width is >= 480px
                      480: {
                        slidesPerView: 1,
                        spaceBetween: 30
                      },
                      // when window width is >= 640px
                      640: {
                        slidesPerView: 1,
                        spaceBetween: 40
                      },
                      768: {
                        slidesPerView: 1,
                        spaceBetween: 40,
                      },
                      1024: {
                        slidesPerView: 2,
                        spaceBetween: 30,
                      },
                      1400: {
                        slidesPerView: 3,
                        spaceBetween: 40,
                      },
                    }}
                  >
                    {Object.keys(timeline).map((key, ids) => {
                      let index = selectedYearIndex[key] || 0;
                      let item = timeline[key][index];

                      let currentVal = { ...selectedYearIndex };
                      return (
                        <SwiperSlide key={key}>
                          <HStack
                            onCopy={(e) =>
                              CopyPasteService(e, window.location.href)
                            }
                          >
                            {timeline[key].length > 1 && (
                              <FaChevronLeft
                                onFocus={"true"}
                                onClick={() => {
                                  let updatedPreviousIndexVal = index - 1;

                                  if (updatedPreviousIndexVal < 0) {
                                    currentVal[key] = timeline[key].length - 1;
                                  } else {
                                    currentVal[key] = index - 1;
                                  }
                                  setSelectedYearIndex(currentVal);
                                }}
                                className="card-arrow-left"
                              />
                            )}
                            <Box cursor={"pointer"}>
                              <Box
                                justifyItems="center"
                                display="flex"
                                height={"315px"}
                                bg="white"
                                justifyContent="space-around"
                              >
                                <Image
                                  width={
                                    timeline[key].length > 1 ? "340px" : "auto"
                                  }
                                  objectFit={"contain"}
                                  objectPosition={"center"}
                                  src={
                                    item?.image1
                                    
                                  }
                                  onClick={(e) => {
                                    item?.fullImage &&
                                      showSynopsisImages(
                                        item?.fullImage,
                                        true,
                                        item?.fullId
                                      );
                                  }}
                                  alt=""
                                />
                              </Box>
                              <Stack py={4}>
                                <H4>{item?.sortable_date}</H4>
                                <Large
                                  color={"#004F79"}
                                  style={{
                                    width: "100%",
                                    wordWrap: "break-word",
                                    marginTop: "10px",
                                  }}
                                >
                                  {item?.caption_1}
                                </Large>
                                <Box className="scrollText">
                                  <Small
                                    textAlign="justify"
                                    color={"black"}
                                    pt={4}
                                  >
                                    {item?.text_1}
                                  </Small>
                                  <Small
                                    textAlign="justify"
                                    color={"black"}
                                    pt={4}
                                  >
                                    {item?.text_2}
                                  </Small>
                                  <Small
                                    textAlign="justify"
                                    color={"black"}
                                    pt={4}
                                  >
                                    {item?.text_3}
                                  </Small>
                                </Box>
                              </Stack>
                            </Box>
                            {timeline[key].length > 1 && (
                              <FaChevronRight
                                onClick={() => {
                                  let updatedNextIndexVal = index + 1;

                                  if (
                                    updatedNextIndexVal >
                                    timeline[key].length - 1
                                  ) {
                                    currentVal[key] = 0;
                                  } else {
                                    currentVal[key] = index + 1;
                                  }
                                  setSelectedYearIndex(currentVal);
                                }}
                                className="card-arrow-right"
                              />
                            )}
                          </HStack>
                        </SwiperSlide>
                      );
                    })}
                  </Swiper>
                )}
              </Stack>
              {/* Bottom Year Filter */}
              <Yearfilter
                sliderRef1={sliderRef1}
                yearFilterOptions={yearFilterOptions}
                handleYearFilter={(e) => handleYearFilter(e)}
                toggleModal={toggleModal}
                handleTo={handleTo}
                selectedItemIndex={selectedItemIndex}
                setSelectedItemIndex={setSelectedItemIndex}
                yearRangeFilterOptions={yearRangeFilterOptions}
                filter={filter}
                setFilter={setFilter}
                synopsisFullScreenImage={synopsisFullScreenImage}
              />
            </Container>
          </Stack>
        </>
      ) : (
        <Stack alignItems={"center"} height={"100vh"}>
        <WebpageComingSoon/>
        </Stack>
      )}
    </>
  );
};

const YearRangeFilter = ({
  sliderRef3,
  yearRangeFilterOptions,
  filter,
  setFilter,
  yearFilterOptions,
  handleTo,
  setSelectedItemIndex,
  synopsisFullScreenImage,
}) => {
  const handleTopFilter = (year, sortable_date) => {
    if (year === null) {
      setFilter({});
      handleTo(0);
      sliderRef3?.current?.swiper?.slideTo(0);
    } else {
      setFilter({ ...filter, time_period: year });
      const yearRangeArray = year.split(" – ");
      const findIndex = yearFilterOptions.findIndex(
        (item) => item === yearRangeArray[0]
      );
      if (findIndex === -1) {
        const index = yearFilterOptions.findIndex(
          (item) => item === sortable_date
        );
        handleTo(parseInt(index));
      } else {
        handleTo(parseInt(findIndex));
      }
    }
    setSelectedItemIndex(null);
  };
  return (
    <Box
      width={"-webkit-fill-available"}
      justifyContent={"space-between"}
      backgroundColor="#505050"
      position={"fixed"}
      zIndex={!synopsisFullScreenImage && 9999}
      maxW={{base:"100%",md:"1195px"}}
    >
      <Container maxW={{base:"100%",md:"1280px"}} px="3.5vw" display={"flex"}>
        <Box
          cursor="pointer"
          fontSize="16px"
          fontStyle={"normal"}
          onClick={(e) => handleTopFilter(null, "")}
          color={
            filter?.time_period && Object.keys(filter).length > 0
              ? "gray.200"
              : "white"
          }
          fontWeight={
            filter?.time_period && Object.keys(filter).length > 0
              ? "400"
              : "600"
          }
        >
          <Text className="all-years" mr={"8"}>
            Time Periods
          </Text>
        </Box>
        <Container
          maxW={{base:"65%",md:"980px"}}
          display="flex"
          position={"relative"}
          id={"yearrangeslider"}
        >
          <Swiper
            ref={sliderRef3}
            slidesPerView={7.5}
            spaceBetween={10}
            navigation={true}
            modules={[Navigation]}
            style={{
              padding: "16px",
              backgroundColor: "#505050",
              width: "max-contant",
            }}
            breakpoints={{
              320: {
                slidesPerView: 2,
                spaceBetween: 20
              },
              // when window width is >= 480px
              480: {
                slidesPerView: 2,
                spaceBetween: 30
              },
              // when window width is >= 640px
              640: {
                slidesPerView: 2,
                spaceBetween: 40
              },
              768: {
                slidesPerView: 4,
                spaceBetween: 30,
              },
              1024: {
                slidesPerView: 6,
                spaceBetween: 30,
              },
              1400: {
                slidesPerView: 7.5,
                spaceBetween: 30,
              },
            }}
          >
            {yearRangeFilterOptions.map((item, index) => {
              return (
                <SwiperSlide
                  key={index}
                  fontSize="16px"
                  fontStyle={"normal"}
                  style={{ backgroundColor: "#505050" }}
                  color={
                    filter?.time_period &&
                    filter?.time_period.includes(item?.time_period)
                      ? "white"
                      : "white"
                  }
                >
                  <Box
                    cursor="pointer"
                    fontSize="16px"
                    fontStyle={"normal"}
                    textStyle={"secondary"}
                    onClick={(e) => {
                      handleTopFilter(item?.time_period, item?.sortable_date);
                      setFilter({ ...filter, time_period: item?.time_period });
                    }}
                    color={
                      filter?.time_period &&
                      filter?.time_period.includes(item?.time_period)
                        ? "#FFC015"
                        : "gray.200"
                    }
                    fontWeight={
                      filter?.time_period &&
                      filter?.time_period.includes(item?.time_period)
                        ? "700"
                        : "400"
                    }
                  >
                    {item?.time_period}
                  </Box>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </Container>
      </Container>
    </Box>
  );
};

const Yearfilter = ({
  sliderRef1,
  yearFilterOptions,
  handleYearFilter,
  toggleModal,
  handleTo,
  selectedItemIndex,
  setSelectedItemIndex,
  yearRangeFilterOptions,
  filter,
  setFilter,
  synopsisFullScreenImage,
}) => {
  // Event handler for navigation arrow clicking
  const handleArrowClick = (swiper, isNextClick) => {
    // Number of elements to slide at once
    const elementsToSlide = 0;
    // Slide the specified number of elements in the appropriate direction
    for (let i = 0; i < elementsToSlide; i++) {
      if (isNextClick) {
        swiper.slideNext();
      } else {
        swiper.slidePrev();
      }
    }
  };

  const handleFilter = (selectedYear, key) => {
    handleYearFilter(selectedYear);
    setSelectedItemIndex(key);
    // let matchingTimePeriod = null;
    let matchingTimePeriod = [];

    for (const item of yearRangeFilterOptions) {
      const sortableDateParts = item?.time_period.split(" – ");
      const startYear = parseInt(sortableDateParts[0]);
      const endYear = parseInt(sortableDateParts[1]);

      if (
        parseInt(selectedYear) >= startYear &&
        parseInt(selectedYear) <= endYear
      ) {
        matchingTimePeriod.push(item?.time_period);
      }
    }

    setFilter({ ...filter, time_period: matchingTimePeriod });
  };
  return (
    <Box
      justifyContent={"space-between"}
      backgroundColor="white"
      position={"fixed"}
      zIndex={!synopsisFullScreenImage && 99999}
    >
      <Stack
        py="1"
        ml="-56px"
        width={{base:"100%",md:"1195px"}}
        position={"fixed"}
        bottom={{base:"8%",md:"0%"}}
        bg="white"
      >
        <Stack gap={10} flexDirection={"row"} ml="14px" alignItems={"baseline"}>
          <HStack>
            {yearFilterOptions.length > 0 && (
              <YearPicker
                yearFilterOptions={yearFilterOptions}
                handleYearFilter={handleYearFilter}
                toggleModal={toggleModal}
                handleTo={handleTo}
                yearRangeFilterOptions={yearRangeFilterOptions}
                filter={filter}
                setFilter={setFilter}
                setSelectedItemIndex={setSelectedItemIndex}
              />
            )}
          </HStack>
          <HStack>
            <Container
              maxW={{base:"200px",md:"980px"}}
              display="flex"
              id="allslider"
              position={"relative"}
              marginLeft={"34px"}
            >
              <Swiper
                ref={sliderRef1}
                slidesPerView={10}
                centeredSlides={false}
                spaceBetween={8}
                navigation={true}
                modules={[Navigation]}
                breakpoints={{
                  320: {
                    slidesPerView: 2,
                    spaceBetween: 20
                  },
                  // when window width is >= 480px
                  480: {
                    slidesPerView: 1,
                    spaceBetween: 30
                  },
                  // when window width is >= 640px
                  640: {
                    slidesPerView: 2,
                    spaceBetween: 40
                  },
                  768: {
                    slidesPerView: 4,
                    spaceBetween: 30,
                  },
                  1024: {
                    slidesPerView: 8,
                    spaceBetween: 40,
                  },
                  1400: {
                    slidesPerView: 8,
                    spaceBetween: 40,
                  },
                  1600: {
                    slidesPerView: 10,
                    spaceBetween: 40,
                  },
                }}
                onSwiper={(swiper) => {
                  // Add event listeners to navigation arrows
                  swiper.navigation.$nextEl.on("click", () =>
                    handleArrowClick(swiper, true)
                  );
                  swiper.navigation.$prevEl.on("click", () =>
                    handleArrowClick(swiper, false)
                  );
                }}
              >
                {yearFilterOptions?.map((item, key) => (
                  <SwiperSlide key={key}>
                    <Box
                      onClick={(e) => {
                        handleFilter(item, key);
                        handleYearFilter(item);
                        setSelectedItemIndex(key); // Update the selected item index on click
                      }}
                      cursor="pointer"
                    >
                      <Text
                        fontSize={{base:"18px",md:"24px"}}
                        fontWeight={selectedItemIndex === key ? "700" : "500"}
                        color={
                          selectedItemIndex === key ? "#000000" : "#949494"
                        }
                        onClick={toggleModal}
                      >
                        {item}
                      </Text>
                    </Box>
                  </SwiperSlide>
                ))}
              </Swiper>
            </Container>
          </HStack>
        </Stack>
      </Stack>
    </Box>
  );
};

const YearModalPopup=({modalcard,setModalcard,setSelectedItemIndex,data,sliderRef})=>{
  return(
    <Modal
    size={"lg"}
    isOpen={modalcard}
    onClose={() => {
      setModalcard();
      setSelectedItemIndex(null);
    }}
    isCentered
    motionPreset="none"
   
  >
    <ModalOverlay   />
    <ModalContent
      ml={{base:"0px",md:"200px"}}
      top={{base:"40px",md:"130px"}}
      position={"absolute"}
      borderRadius={"none"}
      height={{base:"100%",md:"564px"}}
      id="intgrated-moadl-popup"
    >
      <Box >
        <Image
          mr={2}
          mt={2}
          zIndex={99}
          className="intgTimelineclosebtn"
          src={closeIcon}
          onClick={() => {
            setModalcard(false);
            setSelectedItemIndex(null);
          }}
          cursor={"pointer"}
        ></Image>
      </Box>
      <ModalBody px={{base:"8",md:"14"}} py="4" id="modalslider">
        <Stack position={"relative"}>
          <Swiper
            ref={sliderRef}
            slidesPerView={1}
            spaceBetween={30}
            navigation={true}
            modules={[Navigation]}
          >
            {data.length > 0 &&
              data.map((item, key) => (
                <SwiperSlide key={key}>
                  <HStack>
                    <Box cursor={"pointer"} maxW={"auto"}>
                      <Box bg="white" justifyContent="space-around">
                        <Image
                          w="auto"
                          height={"285px"}
                          objectPosition={"center"}
                          margin={"auto"}
                          src={
                            item?.image1
                          }
                          alt=""
                        />
                      </Box>
                      <Stack pt={2}>
                        <H4 pb={4}>{item?.title_of_time_period}</H4>
                        <H4>{item?.sortable_date}</H4>
                        <Large color={"#004F79"}>
                          {item?.caption_1}
                        </Large>
                        <Box className="scrollText">
                          {item?.text_1 && (
                            <Small color={"black"}>
                              {item?.text_1}
                            </Small>
                          )}
                          {item?.text_2 && (
                            <Small color={"black"}>
                              {item?.text_2}
                            </Small>
                          )}
                          {item?.text_3 && (
                            <Small color={"black"}>
                              {item?.text_3}
                            </Small>
                          )}
                        </Box>
                      </Stack>
                    </Box>
                  </HStack>
                </SwiperSlide>
              ))}
          </Swiper>
        </Stack>
      </ModalBody>
    </ModalContent>
  </Modal>
  )
}
