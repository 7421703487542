import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  HStack,
  Stack,
  Container,
  Image,
  Divider,
  Text,
  IconButton,
  ButtonGroup,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";

//Core file
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectFlip, Navigation } from "swiper";
import { GrPlayFill } from "@react-icons/all-files/gr/GrPlayFill";
import { BiVolumeMute } from "@react-icons/all-files/bi/BiVolumeMute";
import { GrPauseFill } from "@react-icons/all-files/gr/GrPauseFill";
import { MdSkipNext } from "@react-icons/all-files/md/MdSkipNext";
import { MdSkipPrevious } from "@react-icons/all-files/md/MdSkipPrevious";
import { CgArrowsExpandRight } from "@react-icons/all-files/cg/CgArrowsExpandRight";
import { AiOutlineInfoCircle } from "@react-icons/all-files/ai/AiOutlineInfoCircle";

// Service file 
import * as getLyricsSoundtrackData from "../../../../../services/LyricsSoundtrack";
import { CopyPasteService } from "../../../../../services/CopyPaste";

//Component file
import Small from "../../../../../components/layouts/Typography/Small";
import H4 from "../../../../../components/layouts/Typography/H4";

//S3 Bucket osw-dev-images
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;

const sound1 = `${IMAGE_BASE_PATH}/ui/Film/ShatranjKeKhilari/BodyOfWork/LyricsSoundtrack/Bajaye Bansuriya Shyam.mp3`
const sound2 = `${IMAGE_BASE_PATH}/ui/Film/ShatranjKeKhilari/BodyOfWork/LyricsSoundtrack/Tadap Tadap Sagri Rain Guzri.mp3`
const sound3 = `${IMAGE_BASE_PATH}/ui/Film/ShatranjKeKhilari/BodyOfWork/LyricsSoundtrack/Bajaye Bansuriya Shyam.mp3`
const polygon = `${IMAGE_BASE_PATH}/ui/Chevron/polygon.png`
const play = `${IMAGE_BASE_PATH}/ui/Chevron/play.png`
const soundIcon = `${IMAGE_BASE_PATH}/ui/Chevron/soundicon.svg`

export const LyricsSoundtrack = ({ winnerName, mergeData, data }) => {
  const [fullscreen, setFullscreen] = useState(false);
  const [lyricsSoundtrack, setLyricsSoundtrack] = React.useState([{}]);
  const [currentSongData, setCurrentSongData] = React.useState({});
  const [currentSongIndex, setCurrentSongIndex] = useState(0);
  const [nextButtonColor, setNextButtonColor] = useState("white");
  const [previousButtonColor, setpreviousButtonColor] = useState("white");
  const [isPlaying, setIsPlaying] = useState(false);
  const [volume, setVolume] = useState(1);
  const [isMuted, setIsMuted] = useState(false);
  const audioRef = useRef(null);
  const [soundImage, setSoundImage] = useState([]);
  const [selectedImageItem, setSeletectedImage] = useState({});

  const fullscreenImage = (image, value) => {
    setSeletectedImage(image);
    setFullscreen(true);
  };
  const citationFactor = (data) => {
    return data.map((item) => {
      return {
        songName: item.find((nestedItem) => nestedItem.key === "name")?.value,
        artistName:
          item.find((nestedItem) => nestedItem.key === "personality")?.value
            .length > 0
            ? item.find((nestedItem) => nestedItem.key === "personality")
                ?.value[0].full_name
            : "",
        duration: item.find(
          (nestedItem) => nestedItem.key === "duration_of_song"
        )?.value,
        songLink: item.find((nestedItem) => nestedItem.key === "song_link")
          ?.value,
        songImage:
          item.find((nestedItem) => nestedItem.key === "lyricsImage")?.value
            .length > 0 &&
          item.find((nestedItem) => nestedItem.key === "lyricsImage")?.value,
      };
    });
  };
  React.useEffect(() => {
    const getData = async () => {
      const result = await getLyricsSoundtrackData.lyricsSoundtrackByFlim(
        winnerName
      );
      if (result) {
        setLyricsSoundtrack(citationFactor(result));
      }
    };
    getData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (lyricsSoundtrack) {
      setSoundImage(lyricsSoundtrack?.filter((item) => item?.songImage));
    }
  }, [lyricsSoundtrack]);

  React.useEffect(() => {
    if (
      lyricsSoundtrack?.length > 0 &&
      Object.keys(currentSongData).length === 0
    ) {
      setCurrentSongData(lyricsSoundtrack[currentSongIndex]);

      if (currentSongIndex === 0) {
        setpreviousButtonColor("#747474");
      }
    }
  }, [currentSongData, currentSongIndex, lyricsSoundtrack]);

  const handleSongClick = (song, index) => {
    setCurrentSongData(song);
    setIsPlaying(false);
    if (index === 0) {
      setpreviousButtonColor("#747474");
      setNextButtonColor("white");
    } else if (index === lyricsSoundtrack.length - 1) {
      setpreviousButtonColor("white");
      setNextButtonColor("#747474");
    } else {
      setpreviousButtonColor("white");
      setNextButtonColor("white");
    }
  };

  const handlePreviousButtonClick = () => {
    if (currentSongIndex > 0) {
      let previousIndex = currentSongIndex - 1;
      setCurrentSongIndex(previousIndex);
      handleSongClick(lyricsSoundtrack[previousIndex], previousIndex);
      setIsPlaying(false);

      if (previousIndex === 0) {
        setpreviousButtonColor("#747474");
        setNextButtonColor("white");
      } else {
        setpreviousButtonColor("white");
        setNextButtonColor("white");
      }
    }
  };

  const handleNextButtonClick = () => {
    if (currentSongIndex < lyricsSoundtrack.length - 1) {
      let nextIndex = currentSongIndex + 1;
      setCurrentSongIndex(nextIndex);
      handleSongClick(lyricsSoundtrack[nextIndex], nextIndex);
      setIsPlaying(false);

      if (nextIndex === lyricsSoundtrack.length - 1) {
        setNextButtonColor("#747474");
        setpreviousButtonColor("white");
      } else {
        setpreviousButtonColor("white");
        setNextButtonColor("white");
      }
    }
  };

  const handlePauseClick = () => {
    const audioElement = audioRef.current;

    if (isPlaying) {
      audioElement.pause();
    } else {
      audioElement.play();
    }

    setIsPlaying(!isPlaying);
  };

  const handleVolumeChange = (event) => {
    const audioElement = audioRef.current;
    const newVolume = event.target.value;
    setVolume(newVolume);
    audioElement.volume = newVolume;
  };

  const toggleMute = () => {
    setIsMuted(!isMuted);
    const audioElement = audioRef.current;

    if (isMuted) {
      audioElement.volume = volume;
    } else {
      audioElement.volume = 0;
    }
  };

  return (
    <Stack position={"relative"} minHeight="calc(100vh - 80px)">
      <audio
        ref={audioRef}
        src={
          currentSongIndex === 0
            ? sound1
            : currentSongIndex === 1
            ? sound2
            : sound3
        }
      />
      <Stack
        justifyContent={"space-between"}
        width={"100%"}
        pb={"14"}
        flexDirection={{base:"column",md:"row"}}
        onCopy={(e) => CopyPasteService(e, `${window.location.href}/3`)}
      >
        <HStack alignItems={"flex-start"} paddingTop={"20px"}>
          <Box>
            {lyricsSoundtrack?.map((item, index) => (
              <>
                <Divider color={"#DFDFDF"}></Divider>
                <Stack
                  key={item?.songName}
                  flexDirection={"row"}
                  py="2"
                  justifyContent={"space-between"}
                >
                  <HStack alignItems={"flex-start"} gap={2}>
                    <Image
                      pt="2"
                      src={
                        currentSongData?.songName === item?.songName &&
                        isPlaying
                          ? play
                          : polygon
                      }
                      cursor={"pointer"}
                      onClick={() => {
                        setCurrentSongIndex(index);
                        handleSongClick(item, index);
                        handlePauseClick();
                      }}
                    ></Image>
                    <Box
                      paddingLeft={"15px"}
                      cursor={"pointer"}
                      onClick={() => {
                        setCurrentSongIndex(index);
                        handleSongClick(item, index);
                      }}
                    >
                      <H4>{item?.songName}</H4>
                      <Small py="1">{item?.artistName}</Small>
                    </Box>
                  </HStack>
                  <HStack className="mt-0">
                    <IconButton
                      variant={"unstyled"}
                      fontSize={"25px"}
                      paddingLeft="30px"
                    >
                      <AiOutlineInfoCircle />
                    </IconButton>
                    <Box paddingLeft="10px">{item?.duration}</Box>
                  </HStack>
                </Stack>
              </>
            ))}
            <Divider color={"#DFDFDF"}></Divider>
          </Box>
        </HStack>
        <HStack
          className={data ? {base:"col-sm-12",md:"col-md-4"} : {base:"col-sm-12",md:"col-md-5"}}
          px="0px"
          alignItems={"flex-end"}
        >
          <Stack>
              <Stack id={"satyajitslidersound"} position={"relative"}>
                <HStack py="2">
                  <Swiper
                    grabCursor={true}
                    navigation={true}
                    modules={[EffectFlip, Navigation]}
                  >
                    {soundImage?.length>0 && soundImage[0]?.songImage?.length>0 &&
                      soundImage[0]?.songImage?.map((item, index) => {
                        return (
                          <>
                            <SwiperSlide className="myslide">
                              <Stack>
                                <Image
                                  onClick={(e) => fullscreenImage(item, true)}
                                  width={"388px"}
                                  height={"354px"}
                                  src={
                                    item?.value
                                  }
                                  cursor={"pointer"}
                                />
                                <Text
                                  pt={"16px"}
                                  fontSize={"16px"}
                                  fontWeight={"400"}
                                  lineHeight={"17px"}
                                  color="#035DA1"
                                  textAlign={"left"}
                                >
                                  {`${item?.name_of_publication} | ${item?.date_of_artwork} | ${item.medium_of_artwork} | ${item.revised_medium}`}
                                </Text>
                              </Stack>
                            </SwiperSlide>
                          </>
                        );
                      })}
                  </Swiper>
                </HStack>
              </Stack>
            <HStack>
              <Modal
                size={"full"}
                isCentered
                isOpen={fullscreen}
                onClose={(e) => setFullscreen(false)}
                Id="modalfullscreen"
              >
                <ModalOverlay />
                <ModalContent zIndex={"999"}>
                  <ModalBody>
                    <Box bg="white" py={4}>
                      <Stack >
                        <HStack
                          justifyContent={"center"}
                          flexDirection={"column"}
                        >
                          <Image
                            src={selectedImageItem.value}
                            height={{base:"auto",md:"100vh"}}
                            objectFit={"contain"}
                            width={"100%"}
                          ></Image>
                          <HStack paddingTop="10px">
                            <Text
                              pt={"8px"}
                              fontSize={"15px"}
                              fontWeight={400}
                              lineHeight={"17px"}
                              color="#035DA1"
                            >
                              {`${selectedImageItem?.name_of_publication} | ${selectedImageItem?.date_of_artwork} | ${selectedImageItem?.medium_of_artwork} | ${selectedImageItem?.revised_medium}`}
                            </Text>
                          </HStack>
                        </HStack>
                        <HStack justifyContent={"flex-end"}>
                          <Text
                            fontSize={"xl"}
                            bg={"black"}
                            opacity={"0.6"}
                            color={"white"}
                            fontWeight={"400"}
                            padding="2"
                            cursor={"pointer"}
                            borderRadius={"35px"}
                            width="max-content"
                            marginTop={{base:"-140px",md:"-200px"}}
                            marginRight={{base:"10px",md:"200px"}}
                            onClick={(e) => setFullscreen(false)}
                          >
                            <CgArrowsExpandRight />
                          </Text>
                        </HStack>
                      </Stack>
                    </Box>
                  </ModalBody>
                </ModalContent>
              </Modal>
            </HStack>
          </Stack>
        </HStack>
      </Stack>
      <Box
       position={"fixed"}
       bottom={{ base: "8%", md: "0%" }}
       bg="black"
       p="6"
       marginLeft={{ base: "-20px !important", md: "-60px !important" }}
       width={{ base: "100%", md: "1192px" }}
        onCopy={(e) => CopyPasteService(e, `${window.location.href}/3`)}
      >
        <Container maxW={"944px"}>
          <Stack
            justifyItems={"center"}
            flexDirection={{base:"column",md:"row"}}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <HStack>
              <Wrap spacing={4}>
                <WrapItem>
                  <IconButton
                    variant={"unstyled"}
                    fontSize={"36px"}
                    color={previousButtonColor}
                    px={"14px"}
                    onClick={handlePreviousButtonClick}
                  >
                    <MdSkipPrevious />
                  </IconButton>
                </WrapItem>
                <WrapItem
                  width={"45px"}
                  height={"45px"}
                  justifyContent={"center"}
                >
                  <IconButton
                    fontSize={"16px"}
                    color={"black"}
                    borderRadius={"100%"}
                    bg="white"
                    _hover={{
                      outline: "0px",
                      background: "white",
                    }}
                    onClick={() => handlePauseClick()}
                  >
                    {isPlaying ? <GrPauseFill /> : <GrPlayFill />}
                  </IconButton>
                </WrapItem>
                <IconButton
                  variant={"unstyled"}
                  fontSize={"32px"}
                  color={nextButtonColor}
                  onClick={handleNextButtonClick}
                >
                  <MdSkipNext />
                </IconButton>
              </Wrap>
            </HStack>
            <HStack>
              <Box>
                <H4 color="white">{currentSongData?.songName}</H4>
                <Small color="white">{currentSongData?.artistName}</Small>
              </Box>
            </HStack>
            <HStack>
              <ButtonGroup variant="unstyled">
                {isMuted ? (
                  <BiVolumeMute
                    onClick={() => {
                      toggleMute();
                    }}
                    fontSize={"24px"}
                    color="white"
                  />
                ) : (
                  <Image
                    src={soundIcon}
                    onClick={() => {
                      toggleMute();
                    }}
                  ></Image>
                )}
                <input
                  type="range"
                  min="0"
                  max="1"
                  step="0.01"
                  value={volume}
                  onChange={handleVolumeChange}
                  style={{
                    height: "7px",
                    WebkitAppearance: "none",
                    background: "white",
                    border: "none",
                    borderRadius: "5px",
                    outline: "none",
                    opacity: "0.7",
                    transition: "opacity .2s",
                    cursor: "pointer",
                    marginTop: "8px",
                  }}
                />
              </ButtonGroup>
            </HStack>
          </Stack>
        </Container>
      </Box>
    </Stack>
  );
};
