import React from 'react';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import Small from '../../components/layouts/Typography/Small';

const TimelineWithCitation1 = ({ data, handleImageClick }) => {
    const btnRef = React.useRef();

    return (
        <VerticalTimeline layout={"1-column-left"} className="layout">
            {data &&
                data.map((it, index) => {
                    return (
                        <VerticalTimelineElement
                            key={1}
                            id="editionTimeline"
                            contentStyle={{ fontSize: "sm" }}
                            iconStyle={{
                                background: "#fff",
                                color: "black",
                                fontSize: "16px",
                                fontWeight: "700",
                            }}
                            icon={it.year}
                        >
                        <Small
                            ref={btnRef}
                            cursor={"pointer"}
                            color="#035DA1"
                            pb={{ base: "2", md: "0" }}
                            onClick={()=>handleImageClick(it)}
                        >
                            {it.listDesc}
                        </Small>
                        </VerticalTimelineElement>
                    );
                })}
        </VerticalTimeline>
    );
};

export default TimelineWithCitation1;
