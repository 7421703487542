//Core files
import React from "react";

//Component files
import { WebpageComingSoon } from "../../../CommonComponents/WebpageComingSoon";

function BoxOffice({masterlist}) {
  return (
    <>
    <WebpageComingSoon/>
    </>
  );
}

export default BoxOffice;
