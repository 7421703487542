import api from "../services/api";
import { APIS } from "../endpoints";

export const userAccess = async () => {
    const result = await api.get(`${APIS.BASE_URL}${APIS.userAccess}`);
  if (result) {
    return result?.data?.data;
  } else {
    console.error("Error fetching access data:");
  }
};
function getLocalItem(key) {
  const lresult = window.localStorage.getItem(key);
  if (lresult && lresult.length) {
    try {
      return JSON.parse(lresult);
    } catch (e) {
      console.log("error", e);
    }
  }
  return null;
}
function setLocalItem(key, obj) {
  window.localStorage.setItem(key, JSON.stringify(obj));
  return;
}

export const getAccess = async (pageName, masterlist) => {
  
  let url = `${APIS.BASE_URL}hasAccess?`;

  let keyForLocal = `${pageName}`;
  if (masterlist && masterlist.length) {
  }
  if (pageName && pageName.length) {
    url += "page=" + pageName;
  }
  if (masterlist && masterlist.length) {
    url += "&masterlist=" + masterlist;
    keyForLocal += `-${masterlist}`;
  }
  let result = getLocalItem(keyForLocal);
  if (result) {
    //check expired
    const timelapsed = Math.floor((Date.now() - result.created) / 1000 / 60);
    if (timelapsed < 2) {
      return result;
    }
  }
  result = await api.get(url);
  if (result && result.length) {
    return null;
  }
  let data = {
    data: [],
    subscription: [],
  };
  if (result) {
    data = {
      data: result.data.data.data || [],
      subscription: (result && result.data && result.data.subscription) || [],
      created: Date.now(),
    };
    setLocalItem(keyForLocal, data);
    return data;
  } else {
    console.error("Error fetching access data:");
  }
};