import _ from "lodash";

export const getImageURL = (URI, width=0, height=0) => {

  if (
    !URI ||
    (!_.isString(URI) && !_.isArray(URI)) ||
    (_.isObject(URI) && _.isEmpty(URI))
  ) {
    return false
  }

  const url = _.isArray(URI) ? URI[0] : URI;
  const resizeImageCdn = process.env.REACT_APP_AMAZON_IMAGE_RESIZE_CDN;

  if (url.indexOf("https://") !== -1 || url.indexOf("http://") !== -1) {
    return resizeImageCdn ? `${resizeImageCdn}${width}x${height}?&url=${url}` : url;
  }

  const imageURI = `${process.env.REACT_APP_MONGODB_URL}images/${url}`;

  return resizeImageCdn ? `${resizeImageCdn}${width}x${height}?&url=${imageURI}` : imageURI;
};