import { isTransformationNotAllowed } from "../util/isTranformationAllowed";
import api from "../services/api";
import { APIS } from "../endpoints";

/**
 * This function return projects data
 * @returns {Promise<Object>} - Function return Projects data
 */

export const getProjectsData = async () => {
    if (isTransformationNotAllowed() === true) {
        const response = await getProjectsDummyData();
        return response;
    } else if (isTransformationNotAllowed() === false) {
        const response = await getProjectsAPIData();
        return response;
    }
};

export const getProjectsDummyData = async () => {
    return [];
};

export const getProjectsAPIData = async () => {
    const result = await api.get(`${APIS.BASE_URL}${APIS.projects}`)
    if (result) {
        return result?.data?.data;
    } else {
        return [];
    }
};
