import React, { useEffect, useState } from 'react';
import { HStack } from "@chakra-ui/react";
import Large from '../components/layouts/Typography/Large';
import { siteInfo } from "../../src/constants/constants";

const LastCommit = ({ repositoryhost, projectId, accessToken, branchName }) => {
  const [gitInfo, setGitInfo] = useState(null);

  const getGitInfo = async () => {
    const response = await fetch('/git.json');
    if (!response.ok) {
      throw new Error('Failed to fetch git.json');
    }
    const data = await response.json();
    return data;
  };

  useEffect(() => {
    const fetchGitInfo = async () => {
      try {
        const data = await getGitInfo();
        setGitInfo(data);
      } catch (error) {
        console.error('Error fetching git info:', error);
      }
    };
    
    fetchGitInfo();
  }, []);

  return (
    <div>
      {gitInfo ? (
        <HStack>
            <Large color={"white"}>
            {`${siteInfo.LAST_DEPLOYMENT_DATE}: ${gitInfo.date} | ${siteInfo.COMMIT_ID}: ${gitInfo.commitid} | ${siteInfo.DB_NAME}: ${process.env.REACT_APP_GRAPHQL_URL}`}
            </Large>
        </HStack>
      ) : (
        <HStack>
            <Large color={"white"}>Loading last commit information...</Large>;
        </HStack>
      )}
    </div>
  );
};

export default LastCommit;
