import { HStack, Stack } from "@chakra-ui/react";

//Core files
import React, { useEffect, useState } from "react";

//Service files
import * as getAllData from "./../../../../services/EventExhibition";
import { useParams } from "react-router-dom";
import { getImageURL } from "../../../../util/getImage";
import CatalogueImageSlider from "../../Component/CatalogueImageSlider";
import { DataNotFound } from "../../../../CommonComponents/DataNotFound";

export default function All({ winnerName }) {
  const { alias } = useParams();
  const [allData, setAllData] = useState([]);
  const currentEndpoint = window.location.pathname;
  const parts = currentEndpoint.split("/");
  const masterlistType = parts[1];

  useEffect(() => {
    const getData = async () => {
      const result = await getAllData.allData(
        masterlistType,
        alias);

        if (result && result.length > 0)
        {
          setAllData(citationFactor(result));
        }
      };

    getData();
  }, [masterlistType, alias]);

  const citationFactor = (data) => {

    return data.map((item) => {
      const fullImage = getImageURL(item.find((it) => it.key === "image_alias")?.value);
  
      return {
        desc: item.find((it) => it.key === "bibliographic_caption")?.value || "",
        imgPath: fullImage,
        title: item.find((it) => it.key === "bibliographic_caption")?.value || "",
        fullImg: fullImage,
      };
    });
  };

  return (
    <>
    {allData && allData.length > 0  ? (
      <Stack px={"68px"} height={"100vh"}>
      <HStack justifyContent={"end"} alignItems={"self-start"} mt="30px">
        <CatalogueImageSlider
        sliderData={allData}
        width={"auto"}
        height={"auto"}
        sheight={"100%"}
      />
      </HStack>
    </Stack>
    ) : (
      <Stack alignItems={"center"} height={"100vh"}>
     <DataNotFound />
    </Stack>
    )}
  </>
  );
}
