import { all, call, put, takeEvery } from "redux-saga/effects";
// import {
//   setOtpSent,
//   setOtpVerified,
//   setLoginResp,
//   setUser,
//   setFailure,
// } from '../../../reducers/app/login';
import api from "../../../../services/api";
import { APIS } from "../../../../endpoints";
import { handleCommonErrors } from "../../../../util/handlecommonerrors";

function* sendLoginSaga(payload) {
  console.log("sendOtpSaga called", APIS.request_otp, api, payload.payload);
  try {
    const res = yield call(() => {
      //return api.get(`${APIS.BASE_URL}${APIS.request_otp}`, {
      return api.post(`${APIS.login}`, {
        username: payload.payload.username,
        password: payload.payload.password,
      });
    });
    yield put({ type: "session/setLoginResp", payload: res });
    yield put({ type: "session/setUser", payload: res.data });
  } catch (err) {
    const result = yield call(handleCommonErrors, err, "session/setFailure");
    result.payload.logout = err.response.logout || false;
    yield put(result);
    if (result.payload.logout) {
      yield put({ type: "session/logout", payload: {} });
    }
    console.log("request_otp Got error", result);
  }
}

function* verifyOtpSaga(payload) {
  console.log("verifyOtpOtpSaga called", payload);
  try {
    const res = yield call(() => {
      //return api.get(`${APIS.BASE_URL}${APIS.request_otp}`, {
      return api.post(`${APIS.login}`, {
        mobile: payload.payload.mobile,
        otp: payload.payload.otp,
      });
    });
    console.log(" verifyOtp ** Got this response from server", res.data.data);

    yield put({ type: "session/setUser", payload: res.data.data });
    yield put({ type: "session/setOtpVerified", payload: res.data.data });
  } catch (err) {
    const result = yield call(handleCommonErrors, err, "session/setFailure");
    result.payload.logout = err.response.logout || false;
    yield put(result);
    if (result.payload.logout) {
      yield put({ type: "session/logout", payload: {} });
    }
    console.log("verifyOtpSaga  Got error", result);
  }
}
function* updateProfileSaga(payload) {
  console.log("updateProfileSaga called", payload);
  try {
    const res = yield call(() => {
      return api.put(`${APIS.update_profile}`, {
        first_name: payload.payload.first_name,
        last_name: payload.payload.last_name,
        email: payload.payload.email,
        about: payload.payload.about,
      });
    });
    console.log("Got this response from server", res.data.data);

    yield put({ type: "session/updateProfileSuccess", payload: res.data.data });
  } catch (err) {
    const result = yield call(handleCommonErrors, err, "session/setFailure");
    result.payload.logout = err.response.logout || false;
    yield put(result);
    if (result.payload.logout) {
      yield put({ type: "session/logout", payload: {} });
    }
    console.log("updateProfileSaga Got error", result);
  }
}
function* resetPinSaga(payload) {
  console.log("resetPinSaga called", payload);
  try {
    const res = yield call(() => {
      return api.put(`${APIS.reset_pin}`, {
        old_pin: payload.payload.txn_pin,
        new_pin: payload.payload.new_pin,
      });
    });
    console.log("Got this response from server", res.data.data);

    yield put({ type: "session/resetPinSuccess", payload: res.data.data });
  } catch (err) {
    const result = yield call(handleCommonErrors, err, "session/setFailure");
    result.payload.logout = err.response.logout || false;
    yield put(result);
    if (result.payload.logout) {
      yield put({ type: "session/logout", payload: {} });
    }
    console.log("resetPinSaga Got error", result);
  }
}
function* accountDetailsSaga(payload) {
  console.log("accountDetailsSaga called", payload);
  try {
    const res = yield call(() => {
      return api.get(`${APIS.account_details}`, {});
    });
    console.log("Got this response from server", res.data.data);

    yield put({
      type: "session/getAccountDetailsSuccess",
      payload: res.data.data,
    });
  } catch (err) {
    const result = yield call(handleCommonErrors, err, "session/setFailure");
    result.payload.logout = err.response.logout || false;
    yield put(result);
    if (result.payload.logout) {
      yield put({ type: "session/logout", payload: {} });
    }
    console.log("getAccountDetailsSaga Got error", result);
  }
}

function* logoutSaga({ payload }) {
  try {
    console.log("auth logoutSaga", payload);
    yield put({ type: "transaction/resetError", payload: {} });
    yield put({ type: "profile/resetError", payload: {} });
    yield put({ type: "session/resetError", payload: {} });
    yield put({ type: "session/logoutSuccess", payload: {} });
  } catch (error) {
    yield put({ type: "transaction/resetError", payload: {} });
    yield put({ type: "profile/resetError", payload: {} });
    yield put({ type: "session/resetError", payload: {} });
    yield put({ type: "session/logoutFailure", payload: {} });
  }
}

export function* authSaga() {
  console.log("auth saga called *******");
  yield all([
    takeEvery("session/sendLogin", sendLoginSaga),
    takeEvery("session/verifyOtp", verifyOtpSaga),
    takeEvery("session/logout", logoutSaga),
    takeEvery("session/updateProfile", updateProfileSaga),
    takeEvery("session/resetPin", resetPinSaga),
    takeEvery("session/getAccountDetails", accountDetailsSaga),
  ]);
}

