import { Text } from "@chakra-ui/react";

export const TextComponent = ({
  text,
  fontSize,
  fontWeight,
  textAlign,
  lineHeight,
  color,
  onCopy,
  ...rest
}) => {
  return (
    <>
      {text && (
        <Text
          fontSize={fontSize}
          fontWeight={fontWeight}
          lineHeight={lineHeight}
          color={color}
          textAlign={textAlign}
        >
          {text}
        </Text>
      )}
    </>
  );
};
