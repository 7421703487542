import { Container} from "@chakra-ui/react";
import React from "react";
import "react-vertical-timeline-component/style.min.css";

import Layout from "../components/sections/SidebarLayout";
import { WebpageComingSoon } from "../CommonComponents/WebpageComingSoon";

function Careers() {
  return (
    <Layout>
        <>
            <Container maxW={"auto"}>
            <WebpageComingSoon />
            </Container>
        </>
    </Layout>
  );
}

export default Careers;
