import React, { useState } from 'react';
import { Button, HStack, Stack } from '@chakra-ui/react';
import Large from '../../Theme/Typography/Large';
import H1 from '../../Theme/Typography/H1';
import { userRegistrationForm } from '../../constants/constants';
import CustomDrawer from './Drawer';
import { useDisclosure } from '@chakra-ui/react';
import { getResearchCategoriesMasterlistsAPI } from '../../services/ResearchCategories';


const Step3 = ({ formData, setFormData, nextStep, selectedValues, setSelectedValues, previousStep,researchCategories,setResearchCategories }) => {
  const [masterlists, setMasterlists] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [finalMasterlists, setFinalMasterlists] = useState([])
  let [matserlistType, setMasterlistType] = useState('');
  const [rcType,setRcType]=useState('')

  const onOpenHandler = async (value, type) => {
    setMasterlistType(type);
    const researchCategoryObj = value?.split("_")?.[1];
    selectedValues.researchCategories.map(async (researchCategory) => {
      const nameObj = researchCategory.find(obj => obj.key === 'name');
      const aliasObj = researchCategory.find(obj => obj.key === 'alias');
      const rctype = researchCategory.find(obj => obj.key === 'relevance_to_self_discovery_journey').value;

      if (nameObj?.value === researchCategoryObj) {
        setResearchCategories([...researchCategories,aliasObj?.value])

        const result = await getResearchCategoriesMasterlistsAPI(aliasObj?.value);
        if (result) {
          setMasterlists(result);
          setRcType(rctype)
          onOpen();
        } else {
          return [];
        }
      }
    });
  }

  const handleSelectedMasterlists = (newSelectedMasterlists) => {
    if (matserlistType === 'core') {
      setSelectedValues((prevSelectedValues) => ({
        ...prevSelectedValues,
        masterlist: newSelectedMasterlists,
        coreMasterlist: newSelectedMasterlists,
        researchCategoriesMapping: {
          ...prevSelectedValues.researchCategoriesMapping,
          core: {
            ...prevSelectedValues.researchCategoriesMapping.core,
            masterlists: extractKeys(newSelectedMasterlists)
          }
        }
      }));
    } else if (matserlistType === 'material') {
      setSelectedValues((prevSelectedValues) => ({
        ...prevSelectedValues,
        masterlist: newSelectedMasterlists,
        materialMasterlist: newSelectedMasterlists,
        researchCategoriesMapping: {
          ...prevSelectedValues.researchCategoriesMapping,
          material: {
            ...prevSelectedValues.researchCategoriesMapping.material,
            masterlists: extractKeys(newSelectedMasterlists)
          }
        }
      }));
    } else if (matserlistType === 'critical') {
      setSelectedValues((prevSelectedValues) => ({
        ...prevSelectedValues,
        masterlist: newSelectedMasterlists,
        criticalMasterlist: newSelectedMasterlists,
        researchCategoriesMapping: {
          ...prevSelectedValues.researchCategoriesMapping,
          critical: {
            ...prevSelectedValues.researchCategoriesMapping.critical,
            masterlists:extractKeys(newSelectedMasterlists)
          }
        }
      }));
    } else if (matserlistType === 'meaningful') {
      setSelectedValues((prevSelectedValues) => ({
        ...prevSelectedValues,
        masterlist: newSelectedMasterlists,
        meaningfulMasterlist: newSelectedMasterlists,
        researchCategoriesMapping: {
          ...prevSelectedValues.researchCategoriesMapping,
          meaningful: {
            ...prevSelectedValues.researchCategoriesMapping.meaningful,
            masterlists: extractKeys(newSelectedMasterlists)
          }
        }
      }));
    }
    setFormData({
      ...formData,
      masterlist: newSelectedMasterlists,
      // researchCategories: researchCategories
    });
  };

  const totalLength = selectedValues?.coreMasterlist.length + selectedValues?.criticalMasterlist.length + selectedValues?.materialMasterlist.length + selectedValues?.meaningfulMasterlist.length;

  const previewConfirmation=()=>{
    if(totalLength===32){
      const masterlistsAlias = [
        ...extractKeys(selectedValues?.coreMasterlist),
        ...extractKeys(selectedValues?.criticalMasterlist),
        ...extractKeys(selectedValues?.materialMasterlist),
        ...extractKeys(selectedValues?.meaningfulMasterlist)
    ]
    const researchCategoriesAlias = selectedValues?.researchCategoriesMapping
    setFormData({
      ...formData,
      masterlist: masterlistsAlias,
      core:researchCategoriesAlias.core,
      critical:researchCategoriesAlias.critical,
      material:researchCategoriesAlias.material,
      meaningful:researchCategoriesAlias.meaningful,
      researchCategories:[researchCategoriesAlias.core.alias,researchCategoriesAlias.material.alias,researchCategoriesAlias.critical.alias,researchCategoriesAlias.meaningful.alias]
      
    })
      nextStep();
    }
  }
  function extractKeys(array) {
    return array.map(item => item.split(':')[0]);
}

  return (
    <Stack height={"100%"} px={{ base: "10px", md: "62px" }} pt={{ base: "15px", md: "32px" }} pb={{base:"320px",md:"335px"}}>
      <HStack justifyContent={"space-between"}>
        <HStack>
          <Large fontSize={{base:"24px",md:"34px"}} lineHeight={"35.92px"} fontWeight="600">{userRegistrationForm.LOGIN_FORM_TITLE}</Large>
        </HStack>
      </HStack>
      <H1 fontSize={{base:"18px",md:"24px"}} lineHeight={{base:"24px",md:"28.18px"}} py="2" fontWeight="400">
        {userRegistrationForm.CHOOSE}
        <H1 fontSize={{base:"18px",md:"24px"}} lineHeight={{base:"24px",md:"28.18px"}} as="span" fontWeight="700">
          {" "}{userRegistrationForm.THE_MASTERLISTS}</H1> {userRegistrationForm.FOR_EACH_RESEARCH_CATEGORY}
      </H1>
      <Stack className="mt-0" py={{base:"2",md:"4"}} alignItems={"flex-start"}>
        <H1 fontSize={{base:"18px",md:"24px"}} lineHeight={{base:"24px",md:"28.18px"}} onClick={() => onOpenHandler(selectedValues?.core, 'core')}
          cursor="pointer" className="mt-0" fontWeight="500" color="color.blue">{selectedValues.core} {selectedValues.coreMasterlist?.length>0 && `(${selectedValues?.coreMasterlist?.length})`}</H1>
      </Stack>
      <Stack className="mt-0" py={{base:"2",md:"4"}} alignItems={"flex-start"}>
        <H1 fontSize={{base:"18px",md:"24px"}} lineHeight={{base:"24px",md:"28.18px"}} onClick={() => onOpenHandler(selectedValues?.material, 'material')}
          cursor="pointer" className="mt-0" fontWeight="500" color="color.blue">{selectedValues.material} {selectedValues.materialMasterlist?.length>0 && `(${selectedValues?.materialMasterlist?.length})`}</H1>
      </Stack>
      <Stack className="mt-0" py={{base:"2",md:"4"}} alignItems={"flex-start"}>
        <H1 fontSize={{base:"18px",md:"24px"}} lineHeight={{base:"24px",md:"28.18px"}} onClick={() => onOpenHandler(selectedValues?.critical, 'critical')}
          cursor="pointer" className="mt-0" fontWeight="500" color="color.blue">{selectedValues.critical} {selectedValues.criticalMasterlist?.length>0 && `(${selectedValues?.criticalMasterlist?.length})`}</H1>
      </Stack>
      <Stack className="mt-0" py={{base:"2",md:"4"}} alignItems={"flex-start"}>
        <H1 fontSize={{base:"18px",md:"24px"}} lineHeight={{base:"24px",md:"28.18px"}}a onClick={() => onOpenHandler(selectedValues?.meaningful, 'meaningful')}
          cursor="pointer" className="mt-0" fontWeight="500" color="color.blue">{selectedValues.meaningful} {selectedValues.meaningfulMasterlist?.length>0 && `(${selectedValues?.meaningfulMasterlist?.length})`}</H1>
      </Stack>
      {masterlists && (masterlists?.length > 0) ?
        <CustomDrawer
          isOpen={isOpen}
          onClose={onClose}
          selectedValues={selectedValues}
          masterlistData={masterlists}
          rcType={rcType}
          setFinalMasterlists={setFinalMasterlists}
          finalMasterlists={finalMasterlists}
          // setSelectedCount={setSelectedCount}
          setSelectedMasterlists={handleSelectedMasterlists}
        /> : ''
      }
      <HStack px={{base:'15px',md:"0px"}} width={{base:"100%",md:"auto"}} position={"absolute"} bottom={{base:"12.5%",md:"4.5%"}} left={"auto"} alignItems={"flex-end"}  right={{base:"0px",md:"auto"}}  justifyContent={"center"}>
      <Button zIndex={{base:"99",md:"999"}}size={"xl"} width={{ base: "100%", md: "520px" }} mt={4} bg="color.lightbrown" onClick={previousStep}>
          <H1 fontSize={{base:"18px",md:"24px"}}>{userRegistrationForm.GO_BACK}</H1>
        </Button>
        <Button zIndex={{base:"99",md:"999"}} size={"xl"} width={{ base: "100%", md: "565px" }} disabled={!(totalLength===32)} mt={4} variant="custom" onClick={previewConfirmation}>
          <H1 fontSize={{base:"18px",md:"24px"}}>{userRegistrationForm.PREVIEW}</H1>
        </Button>
      </HStack>
    </Stack>
  );
};

export default Step3;
