import { amitabh_bachchan_soundtrack, raj_kapoor_soundtrack, silsila_soundtrack, skk_soundtrack } from "../MockData/LyricsSound";
import { isTransformationNotAllowed } from "../util/isTranformationAllowed";
import api from "../services/api";
import { APIS } from "../endpoints";

export const lyricsSoundtrackByFlimAPI = async (masterlist) => {
  const result = await api.get(`${APIS.BASE_URL}${APIS.film}/${masterlist}/${APIS.lyricsSoundtrack}`)
  if (result) {
    return result?.data;
  } else {
    return [];
  }
};

export const lyricsSoundtrackByFlim = async (masterlist) => {
  if (isTransformationNotAllowed() === true) {
    const response = await getLyricsSoundtrackDummyData(masterlist);
    return response;
  }else if (isTransformationNotAllowed() === false)  {
  const response = await lyricsSoundtrackByFlimAPI(masterlist);
    try {
      if (response) {
        return response;
      } else {
        console.error("Invalid response data structure:", response);
        return [];
      }
    } catch (e) {
      console.error("Something is wrong", e);
    }
  }
};

export const getLyricsSoundtrackDummyData = async (masterlist) => {
  
  const lyricsSoundtrackDataMap = {
    "shatranj-ke-khilari-1977": skk_soundtrack,
    "mughal-e-azam": skk_soundtrack,
    "silsila":silsila_soundtrack,
    "raj-kapoor":raj_kapoor_soundtrack,
    "amitabh-bachchan":amitabh_bachchan_soundtrack,
  };
  const lyricsSoundtrackData = lyricsSoundtrackDataMap[masterlist] || [];
  return lyricsSoundtrackData;
};
