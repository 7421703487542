import React, { useEffect, useState } from 'react'
import { Box, HStack, Image, SimpleGrid, Stack, Text } from '@chakra-ui/react'
import * as getPostData from "../../../services/Post";
import { explore } from '../../../constants/constants';
export default function Post({masterlistTitle}) {
    const [postData, setPostData] = useState([]);

    useEffect(() => {
        const getData = async () => {
          const result = await getPostData.getPostsByMasterlist(
            "masterlist_table",
            masterlistTitle
          );
          if (result) {
            setPostData(result);
          }
        };
        getData();
      }, [masterlistTitle]);
  return (
    <Stack>
        <HStack pb={"10px"}>
        <Text fontSize={"30px"} fontWeight={700}>{explore.POSTS}</Text>
        </HStack>
        <HStack>
        <SimpleGrid
          columns={[1, null, 4]}
        //   gridTemplateColumns={"repeat(6, minmax(0, 1fr))"}
          spacing={"24px"}
        >
          {postData.length>0 && postData.map((it)=>
          <Box w={"286px"} h={"292px"} className="card1">
            <Image w={"100%"} height={"100%"} src={it.imgPath} transition="1s ease"/>
          </Box>
          )}
          
        </SimpleGrid>

        </HStack>
    </Stack>
  )
}
