export const handleCommonErrors = (err, type) =>
  new Promise(res => {
    console.log('handleCommonErrors called', err);
    let yieldObj = {};
    if (err && typeof err == 'string') {
      console.log('Error at err object', err);
      yieldObj = {
        type: type,
        payload: {
          message: 'Could not connect to API Server',
          status_code: 404,
        },
      };
    } else if (err && typeof err.response.data == 'string') {
      console.log('Error at err response object');
      yieldObj = {
        type: type,
        payload: {
          message: 'Could not connect to API Server',
          status_code: err.response.status,
        },
      };
    } else if (err.response.status === 500) {
      console.log('Got 500', err.response.logout);
      yieldObj = {
        type: type,
        payload: {
          message: err.response.data,
          status_code: err.response.status,
        },
      };
    } else {
      // let payload = err.response.data;
      if (typeof err.response.data == 'string') {
        // payload = JSON.parse(err.response.data);
      }

      const msg = err?.response?.data?.errors?.join(". ") || err?.response?.data?.error;

      yieldObj = {
        type: type,
        payload: {
          message: msg || 'Error processing request on server!',
          status_code: err.response.status,
        },
      };
    }

    return res(yieldObj);
  });

