import React, { useEffect, useState } from 'react'
import { explore } from '../../../../constants/constants'
import IllustratedBooks from './Publications/IllustratedBooks'
import AuthoredEditedBooks from './Publications/AuthoredEditedBooks'
import TabMain from '../../../../FunctionalComponents/TabComponents/TabMain'

export default function Publications({masterlist}) {
  function formatData() {
    return [
      {
        name: `${explore.ILLUSTRATED_BOOKS}`,
        component:<IllustratedBooks masterlist={masterlist}/>,
      },
      {
        name: `${explore.AUTHORED_EDITED_BOOKS}`,
        component:<AuthoredEditedBooks masterlist={masterlist}/>,

      },
    ];
}

const [tabPanelData, setTabPanelData] = useState([])
  useEffect(()=>{
    if(masterlist){
      setTabPanelData(formatData())
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[masterlist])
  return (
    <>
      {tabPanelData && tabPanelData.length>0 && <TabMain data={tabPanelData} />}

    </>
  )
}
