export const OExplorerData = [
  [
    {
      key: "id",
      value: "1",
    },
    {
      key: "name",
      value: "Navigator",
    },
  ],
  [
    {
      key: "id",
      value: "2",
    },
    {
      key: "name",
      value: "Discoverer",
    },
  ],
  [
    {
      key: "id",
      value: "3",
    },
    {
      key: "name",
      value: "Researcher",
    },
  ],
  [
    {
      key: "id",
      value: "4",
    },
    {
      key: "name",
      value: "Rediscover",
    },
  ],
  [
    {
      key: "id",
      value: "5",
    },
    {
      key: "name",
      value: "Expert",
    },
  ],
  [
    {
      key: "id",
      value: "6",
    },
    {
      key: "name",
      value: "Selflessness",
    },
  ],
  [
    {
      key: "id",
      value: "7",
    },
    {
      key: "name",
      value: "Wisdom",
    },
  ],
  [
    {
      key: "id",
      value: "8",
    },
    {
      key: "name",
      value: "Equanimity",
    },
  ],
  [
    {
      key: "id",
      value: "9",
    },
    {
      key: "name",
      value: "Self",
    },
  ],
];
