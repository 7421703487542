//S3 Bucket osw-dev-images
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;

const Clock = `${IMAGE_BASE_PATH}/ui/Chevron/clock.png`
const Img0 = `${IMAGE_BASE_PATH}/ui/WhatsNew/dummy.png`
const Img1 = `${IMAGE_BASE_PATH}/ui/WhatsNew/s-ray.png`
const Img2 = `${IMAGE_BASE_PATH}/ui/WhatsNew/sh-raza.png`
const Img3 = `${IMAGE_BASE_PATH}/ui/WhatsNew/skk.png`
const Img4 = `${IMAGE_BASE_PATH}/ui/WhatsNew/abachan.png`
const Img5 = `${IMAGE_BASE_PATH}/ui/WhatsNew/mani.png`
const Img6 = `${IMAGE_BASE_PATH}/ui/WhatsNew/mughal.png`

export const WhatsnewData = [
  {
    id: 1,
    name: "Satyajit Ray | Masterlist",
    image: Img1,
    clockImage: Clock,
    date_and_time: "3:16 PM, Monday, 17 January 2023",
  },
  {
    id: 2,
    name: "SH Raza | Masterlist",
    image: Img2,
    clockImage: Clock,
    date_and_time: "3:16 PM, Monday, 17 January 2023",
  },
  {
    id: 3,
    name: "Shatranj Ke Khilari | Masterlist",
    image: Img3,
    clockImage: Clock,
    date_and_time: "3:16 PM, Monday, 17 January 2023",
  },
  {
    id: 4,
    name: "Satyajit Ray | Masterlist",
    image: Img1,
    clockImage: Clock,
    date_and_time: "3:16 PM, Monday, 17 January 2023",
  },
];

export const ComingSoonData = [
  {
    id: 1,
    name: "Mani Kaul | Masterlist",
    image: Img5,
    clockImage: Clock,
    date_and_time: "4:36 PM, Monday, 16 February 2023",
  },
  {
    id: 2,
    name: "Mughal-e-Azam",
    image: Img6,
    clockImage: Clock,
    date_and_time: "4:36 PM, Monday, 16 February 2023",
  },
  {
    id: 3,
    name: "Amitabh Bachchan",
    image: Img4,
    clockImage: Clock,
    date_and_time: "4:36 PM, Monday, 16 February 2023",
  },
  {
    id: 4,
    name: "Mani Kaul | Masterlist",
    image: Img5,
    clockImage: Clock,
    date_and_time: "4:36 PM, Monday, 16 February 2023",
  },
];
export const WhatsnewArchivedData = [
  {
    id: 1,
    name: "Name of | Masterlist",
    image: Img0,
    clockImage: Clock,
    date_and_time: "3:16 PM, Monday, 17 January 2023",
  },
  {
    id: 2,
    name: "Name of | Masterlist",
    image: Img0,
    clockImage: Clock,
    date_and_time: "3:16 PM, Monday, 17 January 2023",
  },
];
