import React from "react";
import { Text } from "@chakra-ui/react";
import { common } from "../../../constants/constants";

function All() {
  return (
    <Text py={"40"} fontSize={{base:"25px",md:"45px"}} fontWeight="700" textAlign="center">
      {common.WEBPAGE_COMING_SOON}
    </Text>
  );
}

export default All;
