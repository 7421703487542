import { createSlice } from "@reduxjs/toolkit";
//import * as Navigation from "../../../../utils/navigation";
const initialState = {
  isAuthenticated: false,
  showBottomBar: false,
  mobile: "",
  email: "",
  user: {},
  otp: "",
  loading: false,
  activeMenuItem: "home",
  otpSent: false,
  otpVerified: false,
  access_token: "",
  refresh_token: "",
  balance: 0,
  error: {},
  success: "",
};
console.log("initial state",initialState)

const authSlice = createSlice({
  name: "session",
  initialState: initialState,
  reducers: {
    sendLogin: (state, action) => {
      console.log("sendLogin called in reducer",state,action);

      state.username = action.payload.username;
      state.password = action.payload.password;
      state.loading = true;
      console.log("sendLogin called in reducer");
    },

    sendOtp: (state, action) => {
      state.mobile = action.payload.mobile;
      state.otp = action.payload.otp;
      state.loading = true;
      console.log("sendotp called in reducer");
    },
    setLoginResp: (state, action) => {
      console.log("setLoginResp called", action);
      const res = action.payload;
      state.loading = false;
      state.otpVerified = true;
      state.access_token = res.accessToken;
      state.refresh_token = res.refreshToken || "";
      state.isAuthenticated = true;
      state.showBottomBar = true;
      state.user=res.user
      state.error = {};

      state.error = {};
      console.log("setLoginResp done navigting done");
    },

    setOtpSent: (state, action) => {
      console.log("setOtpsent called");
      state.loading = false;
      state.otpSent = true;
      state.error = {};
      console.log("setOtpsent done navigting");
      //delaying navigation for rex store to stabilize
      console.log("setOtpsent done navigting done");
    },
    verifyOtp: (state, action) => {
      state.otp = action.payload.otp;
      console.log(state, action);
      state.loading = true;
    },
    setOtpVerified: (state, action) => {
      console.log("setOtpVerified", action.payload);
      const res = action.payload;
      state.loading = false;
      state.otpVerified = true;
      state.access_token = res.access_token;
      state.refresh_token = res.refresh_token;
      state.isAuthenticated = true;
      state.showBottomBar = true;
      state.error = {};
      console.log("Otp verified", state);
      //Navigation.navigate('app.user.home', {});
      //Navigation.reset({index: 0, routes: [{name: 'app.user.home'}]});
      //console.log('This is done', Navigation);
      //Navigation.navigate('app.user.home', {});
    },
    setBalance: (state, action) => {
      state.balance = action.payload.balance;
    },
    setUser: (state, action) => {
      console.log("setUser called", action.payload);
      state.loading = false;
      state.user = action.payload;
    },
    setUserInfo: (state, action) => {
      console.log("setUserInfo called", action.payload);
      state.loading = false;
      state.user.user = action.payload;
    },

    logout: (state, action) => {
      console.log("reducer auth logout called");
    },
    logoutSuccess: (state, action) => {
      console.log("logout successful", state);
      //state = {...initialState};
      state.loading = false;
      state.otpVerified = false;
      state.isAuthenticated = false;
      state.access_token = "";
      state.refresh_token = "";
      state.isAuthenticated = false;
      state.showBottomBar = false;
      state.activeMenuItem = "";
      state.error = {};
      state.user = { user: {} };

      console.log(state);
      //Navigation.navigate('app.login', {})
    },
    updateProfile: (state, action) => {
      console.log("updateProfile", action);
      state.loading = true;
      state.error = {};
    },
    updateProfileSuccess: (state, action) => {
      console.log("updateProfileSuccess", action);
      state.loading = false;
      const usr = state.user;
      usr.user = action.payload;
      state.user = usr;
      state.error = {};
      state.success = {
        message: "Profile updated successfully!",
      };
    },
    updateProfileFailure: (state, action) => {
      console.log("updateProfileFailure", action);
      state.loading = false;
    },
    getAccountDetails: (state, action) => {
      console.log("getAccountDetails", action);
      state.loading = true;
      state.error = {};
    },
    getAccountDetailsSuccess: (state, action) => {
      console.log("getAccountDetailsSuccess", action);
      state.loading = false;
      state.error = {};
      state.success = {};
      state.balance = action.payload.balance;
    },

    resetPin: (state, action) => {
      console.log("resetPin", action);
      state.loading = true;
      state.error = {};
    },
    resetPinSuccess: (state, action) => {
      console.log("resetPin", action);
      state.loading = false;
      state.error = {};
      state.success = {
        message: "Transaction pin  updated successfully!",
      };
    },
    resetPinFailure: (state, action) => {
      console.log("resetPinFailure", action);
      state.loading = false;
    },

    logoutFailure: (state, action) => {
      console.log("logout failed", action);
    },
    setActiveMenuItem: (state, action) => {
      state.activeMenuItem = action.payload;
    },

    resetError: (state, action) => {
      state.error = {};
      state.success = {};
      console.log("reseterror login");
    },
    setFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  setActiveMenuItem,
  sendOtp,
  sendLogin,
  setLoginResp,
  setOtpSent,
  verifyOtp,
  setOtpVerified,
  setUser,
  setUserInfo,
  setFailure,
  logoutSuccess,
  logout,
  logoutFailure,
  setBalance,
  updateProfile,
  updateProfileFailure,
  updateProfileSuccess,
  resetPin,
  resetPinSuccess,
  resetPinFailure,
  getAccountDetails,
  getAccountDetailsSuccess,
  resetError,
} = authSlice.actions;
export default authSlice.reducer;
