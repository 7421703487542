import {
  Box,
  Container,
  Grid,
  GridItem,
  HStack,
  Image,
  Stack,
  Text,
} from "@chakra-ui/react";

import Layout from "../components/sections/SidebarLayout";
import { Link } from "react-router-dom";

//S3 Bucket osw-dev-images
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;

const BackArrow = `${IMAGE_BASE_PATH}/ui/Chevron/backarrow.png`

function TheIntutivelogicII() {
  return (
    <Layout>
      <Stack m="5" pr={"1"} pl={"1"} >
        <Container paddingX={{base:"0px", md:"4"}} maxW="auto">
          <Box display={{base:"content", md:"none"}} position={"sticky"} top={"90px"} zIndex={1}>
            <Link to="/philosophical-framework">
              <Image src={BackArrow}></Image>
            </Link>
          </Box>
          <Box pt="4">
            <Text fontSize={"50px"} fontWeight="700">
              THE IIntuitive-LOGIC II
            </Text>
            <Text
              pb="2"
              fontSize={"11px"}
              lineHeight="13px"
              fontWeight={"300"}
              color="#242424"
            >
              MODERN & CONTEMPORARY INDIAN PAINTING, DRAWINGS, GRAPHICS,
              SCULPTURE & TAPESTRY.
            </Text>
            <Text pb="2" fontSize={"11px"} lineHeight="13px" fontWeight={"300"}>
              Exhibition & Auction Curated with Text by{" "}
              <strong>Neville Tuli</strong>
            </Text>
            <Text pb="2" fontSize={"25px"} lineHeight="29px" fontWeight={"700"}>
              Acknowledgements
            </Text>
            <Text py="4" fontSize={"18px"} lineHeight="28px" fontWeight={"600"}>
              To all the artists and collectors who gave their works to make
              this event significant my gratitude and warmest thank you.
            </Text>
          </Box>
          <Stack>
            <HStack>
              <Grid templateColumns="repeat(6, 1fr)" gap={4}>
                <GridItem rowSpan={1} colSpan={2}></GridItem>
                <GridItem colSpan={4}>
                  <Text
                    py="2"
                    fontSize={"18px"}
                    lineHeight="28px"
                    fontWeight={"400"}
                    textAlign="justify"
                  >
                    To my fellow members at HEART... the love and support of
                    Sanjeev Khandelwal has been instrumental in  allowing one’s
                    work to continue at the chosen pace. My  warm gratitude and
                    affection to Priya Paul, Vipula Kadri,  Rashmi Poddar,
                    Aditya Kanoria, Pheroza Godrej, Bipin  Shah, Mallika
                    Sarabhai, Smita Parekh, Vandana Kanoria,  Rajyalakshmi Rao,
                    Sheela Bhogilal, Rajit Kapur and  Rupika Chawla, for the
                    time and energy you have  lovingly devoted to helping HEART
                    build its vision in  these early days.
                  </Text>
                  <Text
                    py="2"
                    fontSize={"18px"}
                    lineHeight="28px"
                    fontWeight={"400"}
                    textAlign="justify"
                  >
                    To Pradnya Desai and Angira Arya, without whose help  and
                    dedication this event would have lacked on many  fronts.
                  </Text>
                  <Text
                    py="2"
                    fontSize={"18px"}
                    lineHeight="28px"
                    fontWeight={"400"}
                    textAlign="justify"
                  >
                    To Mallika Sarabhai & Bipin Shah at Mapin Publishing  Pvt.
                    Ltd., for taking forward our relationship with love  and
                    patience, professionalism and care. To Prof. Kirti  Trivedi,
                    my deep gratitude for your time and expertise in  such
                    difficult circumstances.
                  </Text>
                  <Text
                    py="2"
                    fontSize={"18px"}
                    lineHeight="28px"
                    fontWeight={"400"}
                    textAlign="justify"
                  >
                    To my dear friend Prakash Rao (Mumbai) once again, as  to
                    Asit Poddar (Calcutta) & Prabjit Singh (New Delhi) for 
                    their efficient and professional photography.
                  </Text>
                  <Text
                    py="2"
                    fontSize={"18px"}
                    lineHeight="28px"
                    fontWeight={"400"}
                    textAlign="justify"
                  >
                    To Ketan & Pankaj Mehta, and the team at Reproscan 
                    (Mumbai), for the quality care, patience and time, despite 
                    the tight deadlines.
                  </Text>
                  <Text
                    py="2"
                    fontSize={"18px"}
                    lineHeight="28px"
                    fontWeight={"400"}
                    textAlign="justify"
                  >
                    To Pragati Art Printers (Hyderabad). Hello, for we have not
                    met yet.
                  </Text>
                  <Text
                    py="2"
                    fontSize={"18px"}
                    lineHeight="28px"
                    fontWeight={"400"}
                    textAlign="justify"
                  >
                    To Kunika & Chemould Framers, for your able work.
                  </Text>
                  <Text
                    py="2"
                    fontSize={"18px"}
                    lineHeight="28px"
                    fontWeight={"400"}
                    textAlign="justify"
                  >
                    In the collection of the art works, my gratitude and warm
                    thank you to the following artists for entrusting and
                    sharing, at times, some of the last significant works in 
                    their private collection: K.M.Adimoolam, Manjit Bawa,  Veena
                    Bhargava, Jyoti Bhatt, Rameshwar Broota &  Vasundhara Tewari
                    Broota, Jogen Chowdhury, Amitava  Das, Jatin Das, Sunil Das,
                    Dharamnarayan Dasgupta,  Biren De, Debrata De, Vibha Desai,
                    Atul Dodiya, Shyamal Dutta Ray, K.Laxma Goud, Ganesh Haloi,
                    M.F.Husain, Wasim Kapoor, Chintamoni Kar, Sanat Kar,
                    Prabhakar Kolte, Achutan Kudallur, Paresh Maity, Anjolie Ela
                    Menon, Theodore Mesquita, Altaf Mohammedi, Rabin Mondal,
                    Surendran Nair, S.Nandagopal, Navjot, Akbar Padamsee, Madhvi
                    & Manu Parekh, Gieve Patel, Jeram Patel, Sudhir Patwardhan,
                    A. Ramachandran, Rekha Rodwittiya, Jehangir Sabavala,
                    B.C.Sanyal, Paritosh Sen, Himmat Shah, Laxman Shreshtha,
                    Shuvaprassana,  Arpita & Paramjit Singh, K.G.Subramanyan, &
                    Anupam  Sud.
                  </Text>
                  <Text
                    py="2"
                    fontSize={"18px"}
                    lineHeight="28px"
                    fontWeight={"400"}
                    textAlign="justify"
                  >
                    Hand in hand with the artists, it has been the help of
                    certain collectors who have parted with their old works, 
                    further enabling rare pieces of art to buyers be made 
                    available, so encouraging the chance of new collectors to 
                    access key works and build a serious collection. To this 
                    end my affection and warmest gratitude to Prakash & 
                    Soumitra Kejriwal and family; Mukund, Nirja, Reena,  Abhijit
                    & Abhinand Lath; to Kekoo, Korshed & Shireen  Gandhy; Sara
                    Abraham, Sharan Apparao, Shrimathi  Mahesh, Supriya
                    Bannerjee, Ashish Anand, Shoba  Bhatia, Mr.Suri and family,
                    Soli Shroff, Jagdish Mittal,  Bipin Shah, Aditya Kanoria and
                    family, Zareen Narielwala  Walsh & Stan Walsh, Ritu Dalmia,
                    Sunita Khandelwal and  family, Mariam Chandy, Jeroo Mango
                    and family, Tania  Reeves, Ella Chacko, Yash Poddar and
                    family, Haresh  Khaitan, Seth Vijay Kumar, and Rohit Poddar
                    and family.
                  </Text>
                  <Text
                    py="2"
                    fontSize={"18px"}
                    lineHeight="28px"
                    fontWeight={"400"}
                    textAlign="justify"
                  >
                    My gratitude and thank you to the galleries who helped  to
                    facilitate the process of this auction, such as:  Chitrakoot
                    Art Gallery (Calcutta), Akar Prakar (Jaipur),  The Gallery
                    (Chennai), Gallery Chemould & Cymroza Art  Gallery (Mumbai),
                    Sakshi Gallery in their association with  Rekha Rodwittiya
                    (Mumbai), Values Art Foundation  (Chennai), Kala Yatra
                    (Bangalore), Gallerie 88 (Calcutta),  Delhi Art Gallery,
                    Gallerie Ganesha & Habiart Gallery  (New Delhi).
                  </Text>
                  <Text
                    py="2"
                    fontSize={"18px"}
                    lineHeight="28px"
                    fontWeight={"400"}
                    textAlign="justify"
                  >
                    To Lalu at Chitrakoot Art Gallery; Sonal, Ashok, Ashley  and
                    Chintamoni at Gallery Chemould; Kumar at Cymroza  Art
                    Gallery my warm thank you.
                  </Text>
                  <Text
                    py="2"
                    fontSize={"18px"}
                    lineHeight="28px"
                    fontWeight={"400"}
                    textAlign="justify"
                  >
                    Further, a few especially encouraged with their loving  time
                    and knowledge, for which I am grateful: Prakash  Kejriwal,
                    Mukund Lath, Sara Abraham, Rupika Chawla,  Kekoo & Korshed
                    Gandhy, Sharan Apparao, Manjit  Bawa, Rameshwar Broota,
                    Jogen Chowdhury,  Dharamnarayan Dasgupta, Jatin Das, Biren
                    Do, Sanat  Kar, M.F.Husain, Anjolie Ela Menon,
                    S.Nandagopal,  Akbar Padamsee, Gieve Patel, Sudhir
                    Patwardhan,  Paritosh Sen, A.Ramachandran, J.Sabavala,
                    Laxman  Shreshtha, Shuvaprassana, K.G.Subramanyan, Anupam 
                    Sud, Amrita Jhaveri, Haresh Chaganlal, Sundaram  Tagore and
                    my dear friends Chester & Davida Herwitz,  thank you for the
                    humour and reprimand.
                  </Text>
                  <Text
                    py="2"
                    fontSize={"18px"}
                    lineHeight="28px"
                    fontWeight={"400"}
                    textAlign="justify"
                  >
                    In progressing along this path since returning home in 1993
                    I have come to believe that a few key principles for action
                    need to be obeyed if one is to successfully help build an
                    integrated educational infrastructure for India.
                  </Text>
                  <Text
                    py="2"
                    fontSize={"18px"}
                    lineHeight="28px"
                    fontWeight={"400"}
                    textAlign="justify"
                  ></Text>
                  <Text
                    py="2"
                    fontSize={"18px"}
                    lineHeight="28px"
                    fontWeight={"400"}
                    textAlign="justify"
                  ></Text>
                  <Text
                    py="2"
                    fontSize={"18px"}
                    lineHeight="28px"
                    fontWeight={"400"}
                    textAlign="justify"
                  ></Text>
                  <Text
                    py="2"
                    fontSize={"18px"}
                    lineHeight="28px"
                    fontWeight={"400"}
                    textAlign="justify"
                  ></Text>
                </GridItem>
              </Grid>
            </HStack>
          </Stack>
        </Container>
      </Stack>
      {/* <Footer /> */}
    </Layout>
  );
}
export default TheIntutivelogicII;
