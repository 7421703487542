import { Box, Image, Stack, Text } from "@chakra-ui/react";
import { explore } from "../constants/constants";
import { useTranslation } from "react-i18next";
import { FiFilter } from "react-icons/fi";

//S3 Bucket osw-dev-images
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;

const Filter = `${IMAGE_BASE_PATH}/ui/Chevron/filter.png`

export const FilterButton = ({
  onClick,
  marginRight,
  paddingTop,
  masterlistType,
  marginTop="100px",
  filterbuttonStyle
}) => {
  const { t } = useTranslation("");

  return (
    <>
      <Stack
          pos={masterlistType==="generic"?"relative":"fixed"}
          marginTop={marginTop}
          right={masterlistType==="generic" && "0px"}
          marginRight={marginRight}
          paddingTop={paddingTop}
          zIndex={masterlistType ==="search" ? "99!important":"9!important"}
          display={{base:"none",md:"flex"}}
        >
          <Stack
            id={filterbuttonStyle? filterbuttonStyle : "filterstickywbtn"}
            marginRight={"0px!important"}
            onClick={onClick}
       >
            <Box
              display={"flex"}
              flexDirection={"column"}
              alignItems={"center"}
              gap="10px"
            >
              <Image transform={"rotate(360deg)"} src={Filter} />
              <Text
                as="button"
                transform={"rotate(90deg)"}
                fontSize={"14px"}
                fontWeight={"700"}
                marginTop={"4px"}
              >
                {t(explore.FILTER)}
              </Text>
            </Box>
          </Stack>
        </Stack>
        <Stack
          // position={"relative"}
          pos={masterlistType==="generic"?"relative":"fixed"}
          marginTop={"100px"}
          right={masterlistType==="generic" && "0px"}
          marginRight={marginRight}
          paddingTop={paddingTop}
          zIndex={"99!important"}
          alignItems={"flex-end"}
          display={{base:"flex",md:"none"}}
        >
          <Stack
            id=""
            onClick={onClick}
            position={"absolute"}
            width={"max-content"}
            alignItems={"flex-end"}
          >
            <Box
              flexDirection={"column"}
              alignItems={"center"}
              className="filter-btn"
              position={"relative"}
              top={masterlistType==="research-categories"?"0px":"-80px"}
              bg="#000000b5"
              right={"10%"}
              left={"auto"}
              padding={"4px"}
              borderRadius={"30px"}
            >
              <FiFilter fontSize="18px" color="white" />
              {/* Need this img code for future use */}
              {/* <Image transform={"rotate(270deg)"} width={"100%"} height={"16px"} src={Filter} /> */}
            </Box>
          </Stack>
        </Stack>
    </>
  );
};