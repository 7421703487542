import { Box, HStack, Heading, Stack, Text } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react'

export default function PercentageDifferenceMidEstimate({data}) {
    const [selectedData, setSelectedData] = useState({});

  const key = "percentage-difference-mid-estimate";
  useEffect(() => {
    const handleSelectData = () => {
      const selected = data.find((item) => item.key === key);
      setSelectedData(selected);
    };
    handleSelectData();
  }, [data]);
  return (
<Box minH={"80vh"}>
{data?.length > 0 && selectedData!==null && selectedData!==undefined && (
      <>
        <HStack flexDirection={"column"} alignItems={"self-start"}>
          <Heading as="h3" size="lg" mb={4} pb={4}>
            {selectedData?.title}
          </Heading>
          <Text fontSize={"16px"}>{selectedData?.subtitle}</Text>
          <Text fontSize={"16px"}>{selectedData?.heading}</Text>
        </HStack>
        <HStack>
          <Stack>
            <Text fontSize={"146px"} fontWeight={800} pr={"200px"}>
              {selectedData?.percentage_diff}
            </Text>
          </Stack>
          <Stack>
            <Text>{selectedData?.desc}</Text>
          </Stack>
        </HStack>
      </>
    )}
  </Box>
    )
}
