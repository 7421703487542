import { Text } from "@chakra-ui/react";
import React from "react";

const Heading = ({ children, ...props }) => {
  return (
    <Text {...props} fontSize="48px" fontWeight="900" lineHeight={"65px"}>
      {children}
    </Text>
  );
};
export default React.memo(Heading);
