import { Box, Image } from '@chakra-ui/react'
import React from 'react'
import H4 from '../../Theme/Typography/H4';

//S3 Bucket osw-dev-images
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;

const placeholderImg = `${IMAGE_BASE_PATH}/ui/DummyImage/small.png`

export default function ArchiveGrid({
  data,
  handleDrawer,
  border,
  alignLeft }) {
  return (
    <>{data?.map((item, index) => (
      <Box key={index}>
        <Box height="auto" >
          {item.link ? (
            <Box
              justifyItems="center"
              display="flex"
              height={"270px"}
              bg="white"
              justifyContent="space-around"
            >
              <Image
               fallbackSrc={placeholderImg}
                src={item?.imagePath ? item?.imagePath : item?.image}
                alt=""
                cursor={"pointer"}
                transition="1s ease"
                width={"auto"}
                objectFit={"contain"}
                onClick={() =>
                  handleDrawer(item)
                }
                _hover={{ transform: "scale(1.1)" }}
              />
            </Box>
          ) : (
            <Box
              justifyItems="center"
              display="flex"
              height={{base:"185px",md:"270px"}}
              bg="white"
              justifyContent="space-around"
            >
              <Image
                src={item?.imagePath ? item?.imagePath : item?.image}
                alt=""
                fallbackSrc={placeholderImg}
                cursor={"pointer"}
                transition="1s ease"
                width={"auto"}
                objectFit={"contain"}
                onClick={() =>
                  handleDrawer(item)
                }
              />
            </Box>
          )}
         <H4
            fontSize={{base:"14px",md:"16px"}}
            lineHeight={"18.78px"}
            textAlign={{ base: "left", md: "left" }}
            color="color.blue"
            py="2"
            style={{
              display: '-webkit-box',
              overflow: 'hidden',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: 3,
            }}
          >
            {/* {!item.desc && <>
              {item?.creator && item?.creator.creator && (
                <>{` ${item?.creator.creator.lastname}, `}</>
              )}

              {item?.creator && item?.creator.creator && (
                <>
                  {` ${item?.creator.creator.firstname}`}
                  {item?.creator.creator.lastname !== "" && ` | `}
                </>
              )}

              {item?.sortable_year_date_of_publication &&
                item?.creator.creator &&
                item?.creator.creator.lastname === "" ? (
                <>{` (Ed). (${item?.sortable_year_date_of_publication}), `}</>
              ) : (
                <>{`${item?.sortable_year_date_of_publication}. `}</>
              )}
              {item?.creator &&
                item?.creator.creator &&
                item?.creator.creator.lastname === "" && (
                  <>{`Art Heritage Annual. ${item?.location}:${item?.gallery}. `}</>
                )}
              {item?.document_type &&
                item?.document_type &&
                item?.creator.creator &&
                item?.creator.creator.lastname !== "" && (
                <>{`BOOK.${item?.document_type} `}</>
              )}
            </>} */}
            {
              item.desc ? item.desc :''
            }
          </H4>
        </Box>
      </Box>))}
    </>
  )
}

