//S3 Bucket osw-dev-images
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;

const sign1 = `${IMAGE_BASE_PATH}/ui/PersonalityPolymath/SatyajitRay/EconomicsOfCinema/Screening/signatures/PatherPanchalisign.png`
const sign2 = `${IMAGE_BASE_PATH}/ui/PersonalityPolymath/SatyajitRay/EconomicsOfCinema/Screening/signatures/sign2.png`

export const satyajit_without_key_screeningData=[
    {
        id: "4cfb1cbe-fc7f-477b-a470-adabe6df63d9",
        event_name: "Pather Panchali",
        type: "Premieres",
        sortable_date: "1955",
        opening_date: "1955-05-03",
        closing_date: "",
        masterlist: "satyajit-ray",
        alias: "pather-panchali-1955",
        organiser: "Museum of Modern Art",
        sl_no: "1",
        venue_location: "New York",
        wnn: null,
        screeningImages: [sign1]
    },
    {
        id: "ff81cc41-b5b3-48a4-a537-201626777754",
        event_name: "Pather Panchali: Indian Premiere",
        type: "Premieres",
        sortable_date: "1955",
        opening_date: "",
        closing_date: "",
        masterlist: "satyajit-ray",
        alias: "pather-panchali-indian-premiere-1955",
        organiser: "Advertising Club",
        sl_no: "2",
        venue_location: "Ordinance Club, Calcutta",
        wnn: null,
        screeningImages: []
    },
    {
        id: "81de00d5-b658-478b-aa5d-d9c430ed9cd3",
        event_name: "Pather Panchali",
        type: "Film Festivals",
        sortable_date: "1956",
        opening_date: "",
        closing_date: "",
        masterlist: "satyajit-ray",
        alias: "pather-panchali-1956",
        organiser: "Cannes Film Festival",
        sl_no: "3",
        venue_location: "Cannes",
        wnn: null,
        screeningImages: []
    },
    {
        id: "ddc5d5ec-566d-4e33-b5fe-8557d89522a9",
        event_name: "9th Edinburgh International Film Festival: Pather Panchali",
        type: "Film Festivals",
        sortable_date: "1956",
        opening_date: "",
        closing_date: "",
        masterlist: "satyajit-ray",
        alias: "9th-edinburgh-international-film-festival-pather-panchali-1956",
        organiser: "Edinburgh International Film Festival",
        sl_no: "4",
        venue_location: "Edinburgh",
        wnn: null,
        screeningImages: []
    },
    {
        id: "33bd23cc-33aa-47e3-94f9-20e2e724586b",
        event_name: "Rome Film Festival: Pather Panchali",
        type: "Film Festivals",
        sortable_date: "1956",
        opening_date: "",
        closing_date: "",
        masterlist: "satyajit-ray",
        alias: "rome-film-festival-pather-panchali-1956",
        organiser: "Rome Film Festival",
        sl_no: "5",
        venue_location: "Rome",
        wnn: null,
        screeningImages: []
    },
    {
        id: "dbd12290-a461-43cd-907c-6c6dc91bf4b4",
        event_name: "Manila Film Festival: Pather Panchali",
        type: "Film Festivals",
        sortable_date: "1956",
        opening_date: "",
        closing_date: "",
        masterlist: "satyajit-ray",
        alias: "manila-film-festival-pather-panchali-1956",
        organiser: "Manila Fim Festival",
        sl_no: "6",
        venue_location: "Manila",
        wnn: null,
        screeningImages: []
    },
    {
        id: "f1912117-90a0-42f2-9f74-51d3f2a9e66a",
        event_name: "Stratford Film Festival: Pather Panchali",
        type: "Film Festivals",
        sortable_date: "1957",
        opening_date: "",
        closing_date: "",
        masterlist: "satyajit-ray",
        alias: "stratford-film-festival-pather-panchali-1957",
        organiser: "Stratford Film Festival",
        sl_no: "7",
        venue_location: "Stratford",
        wnn: null,
        screeningImages: []
    },
    {
        id: "b1974c4d-ac37-4836-b4c2-15eb0546a80f",
        event_name: "Vancouver  International Film Festival: Pather Panchali",
        type: "Film Festivals",
        sortable_date: "1957",
        opening_date: "",
        closing_date: "",
        masterlist: "satyajit-ray",
        alias: "vancouver-international-film-festival-pather-panchali-1957",
        organiser: "Vancouver International Film Festival",
        sl_no: "8",
        venue_location: "Vancouver  International",
        wnn: null,
        screeningImages: []
    },
    {
        id: "91c1e319-6ced-4058-bc8a-434eb3079e28",
        event_name: "7th Berlin International Film Festival: Pather Panchali",
        type: "Film Festivals",
        sortable_date: "1957",
        opening_date: "1957-06-21",
        closing_date: "1957-07-02",
        masterlist: "satyajit-ray",
        alias: "7th-berlin-international-film-festival-pather-panchali-1957",
        organiser: "Berlin International Film Festival",
        sl_no: "9",
        venue_location: "Berlin",
        wnn: null,
        screeningImages: []
    },
    {
        id: "d064cba5-9536-4bba-82cd-a64a01003f87",
        event_name: "1st San Francisco International Film Festival: Pather Panchali",
        type: "Film Festivals",
        sortable_date: "1957",
        opening_date: "",
        closing_date: "",
        masterlist: "satyajit-ray",
        alias: "1st-san-francisco-international-film-festival-pather-panchali-1957",
        organiser: "San Francisco International Film Festival",
        sl_no: "10",
        venue_location: "San Francisco",
        wnn: null,
        screeningImages: []
    },
    {
        id: "2f3be1ea-cbb8-44ff-9498-aafe46b0c957",
        event_name: "18th Venice International Film Festival: Aparajitho",
        type: "Film Festivals",
        sortable_date: "1957",
        opening_date: "1957-08-25",
        closing_date: "1957-09-08",
        masterlist: "satyajit-ray",
        alias: "18th-venice-international-film-festival-aparajitho-1957",
        organiser: "Venice International Film Festival",
        sl_no: "11",
        venue_location: "Venice",
        wnn: null,
        screeningImages: [sign2]
    },
    {
        id: "0dfe8346-cd28-40be-8554-4d6a50b5e6e4",
        event_name: "USA Film Festival: Aparajitho",
        type: "Film Festivals",
        sortable_date: "1958",
        opening_date: "",
        closing_date: "",
        masterlist: "satyajit-ray",
        alias: "usa-film-festival-aparajitho-1958",
        organiser: "USA Film Festival",
        sl_no: "12",
        venue_location: "USA",
        wnn: null,
        screeningImages: []
    },
    {
        id: "b9d4055c-e34e-4877-becc-d5e3d7cace4d",
        event_name: "Cannes Film Festival: Parash Pathar",
        type: "Film Festivals",
        sortable_date: "1958",
        opening_date: "1958-05-02",
        closing_date: "1958-05-18",
        masterlist: "satyajit-ray",
        alias: "cannes-film-festival-parash-pathar-1958",
        organiser: "Cannes Film Festival",
        sl_no: "13",
        venue_location: "Cannes, Uruguay",
        wnn: null,
        screeningImages: []
    },
    {
        id: "88c5b874-00e7-4af2-86cf-b8e76ba693f2",
        event_name: "2nd San Francisco International Film Festival: Aparajitho",
        type: "Film Festivals",
        sortable_date: "1958",
        opening_date: "",
        closing_date: "",
        masterlist: "satyajit-ray",
        alias: "2nd-san-francisco-international-film-festival-aparajitho-1958",
        organiser: "San Francisco International Film Festival",
        sl_no: "14",
        venue_location: "San Francisco",
        wnn: null,
        screeningImages: []
    },
    {
        id: "8d0cc0e6-0f08-4c44-9a24-fbc657f441c8",
        event_name: "Pather Panchali: New York Premiere",
        type: "Premieres",
        sortable_date: "1959",
        opening_date: "1959-05-01",
        closing_date: "",
        masterlist: "satyajit-ray",
        alias: "pather-panchali-new-york-premiere-1959",
        organiser: "",
        sl_no: "15",
        venue_location: "New York",
        wnn: null,
        screeningImages: []
    },
    {
        id: "31357581-aefe-40eb-852f-b75a9f141fbe",
        event_name: "1st Moscow International Film Festival: Jalsagar",
        type: "Film Festivals",
        sortable_date: "1959",
        opening_date: "1959-08-03",
        closing_date: "1959-08-17",
        masterlist: "satyajit-ray",
        alias: "1st-moscow-international-film-festival-jalsagar-1959",
        organiser: "Moscow International Film Festival",
        sl_no: "16",
        venue_location: "Moscow",
        wnn: null,
        screeningImages: []
    },
    {
        id: "5e3d55f2-0e1d-41ff-aa4f-13b860637a1c",
        event_name: "New York Film Festival: Pather Panchali",
        type: "Film Festivals",
        sortable_date: "1959",
        opening_date: "",
        closing_date: "",
        masterlist: "satyajit-ray",
        alias: "new-york-film-festival-pather-panchali-1959",
        organiser: "New York Film Festival",
        sl_no: "17",
        venue_location: "New York",
        wnn: null,
        screeningImages: []
    },
    {
        id: "5fc7665e-edd6-497c-b3a8-1fe81cc50ff1",
        event_name: "Jalsaghar, Devi: United States Premiere, Homage to Satyajit Ray",
        type: "Premieres",
        sortable_date: "1960",
        opening_date: "",
        closing_date: "",
        masterlist: "satyajit-ray",
        alias: "jalsaghar-devi-united-states-premiere-homage-to-satyajit-ray-1960",
        organiser: "Albert Johnson",
        sl_no: "18",
        venue_location: "University fo California, Berkely",
        wnn: null,
        screeningImages: []
    },
    {
        id: "ee0c80d6-c0ae-4329-9636-61dd41790227",
        event_name: "10th Berlin International Film Festival: Aparajitho",
        type: "Film Festivals",
        sortable_date: "1960",
        opening_date: "1960-06-24",
        closing_date: "1960-07-05",
        masterlist: "satyajit-ray",
        alias: "10th-berlin-international-film-festival-aparajitho-1960",
        organiser: "Berlin International Film Festival",
        sl_no: "19",
        venue_location: "Berlin",
        wnn: null,
        screeningImages: []
    },
    {
        id: "b068eec0-6be8-4193-914b-2d70b7bd4798",
        event_name: "7th BFI London Film Festival: Apur Sansar",
        type: "Film Festivals",
        sortable_date: "1960",
        opening_date: "",
        closing_date: "",
        masterlist: "satyajit-ray",
        alias: "7th-bfi-london-film-festival-apur-sansar-1960",
        organiser: "BFI London Film Festival",
        sl_no: "20",
        venue_location: "British Film Institute",
        wnn: null,
        screeningImages: []
    },
    {
        id: "1e824aad-b475-4934-8e52-397d496364a1",
        event_name: "Rabindranath Tagore: Premiere",
        type: "Premieres",
        sortable_date: "1961",
        opening_date: "1961-05-05",
        closing_date: "",
        masterlist: "satyajit-ray",
        alias: "rabindranath-tagore-premiere-1961",
        organiser: "",
        sl_no: "21",
        venue_location: "Delhi",
        wnn: null,
        screeningImages: []
    },
    {
        id: "26082fd6-f9ca-489c-89d9-6422716adb9d",
        event_name: "14th Edinburgh International Film Festival: Apur Sansar",
        type: "Film Festivals",
        sortable_date: "1961",
        opening_date: "",
        closing_date: "",
        masterlist: "satyajit-ray",
        alias: "14th-edinburgh-international-film-festival-apur-sansar-1961",
        organiser: "Edinburgh International Film Festival",
        sl_no: "22",
        venue_location: "Edinburgh",
        wnn: null,
        screeningImages: []
    },
    {
        id: "173b73ba-3fa7-42ea-b596-5be94ac267a8",
        event_name: "14th Locarno International Film Festival: Rabindranath Tagore",
        type: "Film Festivals",
        sortable_date: "1961",
        opening_date: "",
        closing_date: "",
        masterlist: "satyajit-ray",
        alias: "14th-locarno-international-film-festival-rabindranath-tagore-1961",
        organiser: "Locarno International Film Festival",
        sl_no: "23",
        venue_location: "Locarno",
        wnn: null,
        screeningImages: []
    },
    {
        id: "ec97999e-5ab2-451b-9828-f12ca4fc712a",
        event_name: "Cannes Film Festival: Devi",
        type: "Film Festivals",
        sortable_date: "1962",
        opening_date: "1962-05-07",
        closing_date: "1962-05-23",
        masterlist: "satyajit-ray",
        alias: "cannes-film-festival-devi-1962",
        organiser: "Cannes Film Festival",
        sl_no: "24",
        venue_location: "Cannes, France",
        wnn: null,
        screeningImages: []
    },
    {
        id: "894b7b4a-87d8-4fe2-98e9-9b4d0a92ecb6",
        event_name: "10th Melbourne International Film Festival: Teen Kanya",
        type: "Film Festivals",
        sortable_date: "1962",
        opening_date: "",
        closing_date: "",
        masterlist: "satyajit-ray",
        alias: "10th-melbourne-international-film-festival-teen-kanya-1962",
        organiser: "Melbourne International Film Festival",
        sl_no: "25",
        venue_location: "Melbourne",
        wnn: null,
        screeningImages: []
    },
    {
        id: "6853eda9-3035-47de-9688-b7c1c6d6e856",
        event_name: "Montevideo Film Festival: Rabindranath Tagore",
        type: "Film Festivals",
        sortable_date: "1962",
        opening_date: "",
        closing_date: "",
        masterlist: "satyajit-ray",
        alias: "montevideo-film-festival-rabindranath-tagore-1962",
        organiser: "Montevideo Film Festival",
        sl_no: "26",
        venue_location: "Montevideo, Uruguay",
        wnn: null,
        screeningImages: []
    },
    {
        id: "a1aae69b-5090-4516-8cbf-94cb2b0e1355",
        event_name: "13th Berlin International Film Festival: Teen Kanya",
        type: "Film Festivals",
        sortable_date: "1963",
        opening_date: "1963-06-21",
        closing_date: "1963-07-02",
        masterlist: "satyajit-ray",
        alias: "13th-berlin-international-film-festival-teen-kanya-1963",
        organiser: "Berlin International Film Festival",
        sl_no: "27",
        venue_location: "Berlin",
        wnn: null,
        screeningImages: []
    },
    {
        id: "346290a4-6f25-4ea4-9e96-70218debb9b6",
        event_name: "Kapurush: European Premiere",
        type: "Premieres",
        sortable_date: "1965",
        opening_date: "1965-08-24",
        closing_date: "1965-09-06",
        masterlist: "satyajit-ray",
        alias: "kapurush-european-premiere-1965",
        organiser: "Venice Film Festival",
        sl_no: "28",
        venue_location: "Venice",
        wnn: null,
        screeningImages: []
    },
    {
        id: "62a9f644-b3a2-4f68-ad38-05d1c0ff9e3b",
        event_name: "Acapulco Film Festival: Charulatha",
        type: "Film Festivals",
        sortable_date: "1965",
        opening_date: "",
        closing_date: "",
        masterlist: "satyajit-ray",
        alias: "acapulco-film-festival-charulatha-1965",
        organiser: "Acapulco Film Festival",
        sl_no: "29",
        venue_location: "Acapulco, Mexico",
        wnn: null,
        screeningImages: []
    },
    {
        id: "a5f6b867-e583-4bf7-9689-eee95a14c9db",
        event_name: "26th Venice International Film Festival: Kapurush",
        type: "Film Festivals",
        sortable_date: "1965",
        opening_date: "1965-08-24",
        closing_date: "1965-09-06",
        masterlist: "satyajit-ray",
        alias: "26th-venice-international-film-festival-kapurush-1965",
        organiser: "Venice International Film Festival",
        sl_no: "30",
        venue_location: "Venice",
        wnn: null,
        screeningImages: []
    },
    {
        id: "38e069df-636d-4ca0-b752-95fe6e15140d",
        event_name: "15th Berlin International Film Festival: Charulatha",
        type: "Film Festivals",
        sortable_date: "1965",
        opening_date: "1965-06-25",
        closing_date: "1965-07-06",
        masterlist: "satyajit-ray",
        alias: "15th-berlin-international-film-festival-charulatha-1965",
        organiser: "Berlin International Film Festival",
        sl_no: "31",
        venue_location: "Berlin",
        wnn: null,
        screeningImages: []
    },
    {
        id: "3777d8d7-b529-489a-9151-861ade8ad96b",
        event_name: "Berlin International Film Festival: Nayak",
        type: "Film Festivals",
        sortable_date: "1966",
        opening_date: "1966-06-24",
        closing_date: "1966-07-05",
        masterlist: "satyajit-ray",
        alias: "berlin-international-film-festival-nayak-1966",
        organiser: "Berlin International Film Festival",
        sl_no: "32",
        venue_location: "Berlin",
        wnn: null,
        screeningImages: []
    },
    {
        id: "02f1d898-bd0c-4e67-bf20-8d7073a3d041",
        event_name: "Auckland International Film Festival: Goopy Gyne Bagha Byne",
        type: "Film Festivals",
        sortable_date: "1969",
        opening_date: "",
        closing_date: "",
        masterlist: "satyajit-ray",
        alias: "auckland-international-film-festival-goopy-gyne-bagha-byne-1969",
        organiser: "Auckland International Film Festival",
        sl_no: "33",
        venue_location: "Auckland",
        wnn: null,
        screeningImages: []
    },
    {
        id: "04be9e5c-4306-4623-be4b-ca38e6cdd823",
        event_name: "18th Melbourne International Film Festival: Goopy Gyne Bagha Byne",
        type: "Film Festivals",
        sortable_date: "1969",
        opening_date: "1969-06-06",
        closing_date: "1969-06-21",
        masterlist: "satyajit-ray",
        alias: "18th-melbourne-international-film-festival-goopy-gyne-bagha-byne-1969",
        organiser: "Melbourne International Film Festival",
        sl_no: "34",
        venue_location: "Melbourne",
        wnn: null,
        screeningImages: []
    },
    {
        id: "9daa1413-5f4c-4901-b32e-824a2a0fe1f6",
        event_name: "Seemabaddha: European Premiere",
        type: "Premieres",
        sortable_date: "1972",
        opening_date: "1972-08-21",
        closing_date: "1972-09-03",
        masterlist: "satyajit-ray",
        alias: "seemabaddha-european-premiere-1972",
        organiser: "Venice Film Festival",
        sl_no: "35",
        venue_location: "Venice",
        wnn: null,
        screeningImages: []
    },
    {
        id: "674d6b43-7062-487f-b195-d25bf7801d0b",
        event_name: "33rd Venice International Film Festival: Seemabaddha",
        type: "Film Festivals",
        sortable_date: "1972",
        opening_date: "1972-08-21",
        closing_date: "1972-09-03",
        masterlist: "satyajit-ray",
        alias: "33rd-venice-international-film-festival-seemabaddha-1972",
        organiser: "Venice International Film Festival",
        sl_no: "36",
        venue_location: "Venice",
        wnn: null,
        screeningImages: []
    },
    {
        id: "d29d1e5d-f53b-4c33-926c-3a07b1eb34b0",
        event_name: "Asani Sanket: European Premiere",
        type: "Premieres",
        sortable_date: "1973",
        opening_date: "1973-06-22",
        closing_date: "1973-07-03",
        masterlist: "satyajit-ray",
        alias: "asani-sanket-european-premiere-1973",
        organiser: "Berlin Film Festival",
        sl_no: "37",
        venue_location: "Berlin",
        wnn: null,
        screeningImages: []
    },
    {
        id: "df2a6eee-6e45-499c-805f-1e72ea278887",
        event_name: "9th Chicago International Film Festival: Ashani Sanket",
        type: "Film Festivals",
        sortable_date: "1973",
        opening_date: "",
        closing_date: "",
        masterlist: "satyajit-ray",
        alias: "9th-chicago-international-film-festival-ashani-sanket-1973",
        organiser: "Chicago International Film Festival",
        sl_no: "38",
        venue_location: "Chicago",
        wnn: null,
        screeningImages: []
    },
    {
        id: "df285934-20a4-4db9-b55f-6eb35258521d",
        event_name: "23rd Berlin International Film Festival: Ashani Sanket",
        type: "Film Festivals",
        sortable_date: "1973",
        opening_date: "1973-06-22",
        closing_date: "1973-07-03",
        masterlist: "satyajit-ray",
        alias: "23rd-berlin-international-film-festival-ashani-sanket-1973",
        organiser: "Berlin International Film Festival",
        sl_no: "39",
        venue_location: "Berlin",
        wnn: null,
        screeningImages: []
    },
    {
        id: "a4ddc085-138a-4a58-a720-f830fd942ee6",
        event_name: "10th Teheran International Festival of Films for Children and Young Adults: Sonar Kella",
        type: "Film Festivals",
        sortable_date: "1975",
        opening_date: "",
        closing_date: "",
        masterlist: "satyajit-ray",
        alias: "10th-teheran-international-festival-of-films-for-children-and-young-adults-sonar-kella-1975",
        organiser: "Teheran International Festival of Films for Children and Young Adults",
        sl_no: "40",
        venue_location: "Teheran",
        wnn: null,
        screeningImages: []
    },
    {
        id: "1872bd74-6019-41ba-bca6-4f5beaccb594",
        event_name: "20th Karlovy Vary International Film Festival: Jana Aranya",
        type: "Film Festivals",
        sortable_date: "1976",
        opening_date: "1976-07-07",
        closing_date: "1976-07-20",
        masterlist: "satyajit-ray",
        alias: "20th-karlovy-vary-international-film-festival-jana-aranya-1976",
        organiser: "Karlovy Vary International Film Festival",
        sl_no: "41",
        venue_location: "Czech Republic",
        wnn: null,
        screeningImages: []
    },
    {
        id: "8ba2ff72-74ea-4c56-a6c6-d5e65d7b7a60",
        event_name: "Shatranj Ke Khilari: World Premiere, Competition Section ",
        type: "Premieres",
        sortable_date: "1978",
        opening_date: "22 Feburary 1978",
        closing_date: "1978-03-05",
        masterlist: "satyajit-ray",
        alias: "shatranj-ke-khilari-world-premiere-competition-section-1978",
        organiser: "Berlin Film Festival",
        sl_no: "42",
        venue_location: "Berlin",
        wnn: null,
        screeningImages: []
    },
    {
        id: "bd9ea8f4-c8a7-44f8-b1ee-5a33232ad665",
        event_name: "3rd Hong Kong International Film Festival: Joi Baba Felunath",
        type: "Film Festivals",
        sortable_date: "1979",
        opening_date: "1979-06-25",
        closing_date: "1979-07-08",
        masterlist: "satyajit-ray",
        alias: "3rd-hong-kong-international-film-festival-joi-baba-felunath-1979",
        organiser: "Hong Kong International Film Festival",
        sl_no: "43",
        venue_location: "Hong Kong",
        wnn: null,
        screeningImages: []
    },
    {
        id: "66de4a8c-e2ff-4522-b346-a4984da202bc",
        event_name: "27th BFI London Film Festival: Pather Panchali",
        type: "Film Festivals",
        sortable_date: "1980",
        opening_date: "1980-11-13",
        closing_date: "1980-11-30",
        masterlist: "satyajit-ray",
        alias: "27th-bfi-london-film-festival-pather-panchali-1980",
        organiser: "British Film Institute",
        sl_no: "44",
        venue_location: "British Film Institute",
        wnn: null,
        screeningImages: []
    },
    {
        id: "3225ebcc-9282-4091-ae3a-20d70e0793b8",
        event_name: "27th BFI London Film Festival: Aparajitho",
        type: "Film Festivals",
        sortable_date: "1980",
        opening_date: "1980-11-13",
        closing_date: "1980-11-30",
        masterlist: "satyajit-ray",
        alias: "27th-bfi-london-film-festival-aparajitho-1980",
        organiser: "British Film Institute",
        sl_no: "45",
        venue_location: "British Film Institute",
        wnn: null,
        screeningImages: []
    },
    {
        id: "201110eb-315c-4f8d-af49-951c4b55a529",
        event_name: "27th BFI London Film Festival: Apur Sansar",
        type: "Film Festivals",
        sortable_date: "1980",
        opening_date: "1980-11-13",
        closing_date: "1980-11-30",
        masterlist: "satyajit-ray",
        alias: "27th-bfi-london-film-festival-apur-sansar-1980",
        organiser: "British Film Institute",
        sl_no: "46",
        venue_location: "British Film Institute",
        wnn: null,
        screeningImages: []
    },
    {
        id: "eaf1cf03-d0e2-4540-bb50-160678469cba",
        event_name: "Retrospective: Film India, Complete Satyajit Ray Retrospective",
        type: "Retrospectives",
        sortable_date: "1981",
        opening_date: "1981-06-25",
        closing_date: "1981-07-24",
        masterlist: "satyajit-ray",
        alias: "retrospective-film-india-complete-satyajit-ray-retrospective-1981",
        organiser: "Mumeum of Modern Art",
        sl_no: "47",
        venue_location: "New York",
        wnn: null,
        screeningImages: []
    },
    {
        id: "cc113023-d1a3-4ff8-b45d-8a2dee7d17e1",
        event_name: "Two: USA Premiere",
        type: "Premieres",
        sortable_date: "1981",
        opening_date: "1981-06-29",
        closing_date: "",
        masterlist: "satyajit-ray",
        alias: "two-usa-premiere-1981",
        organiser: "Museum of Modern Art",
        sl_no: "48",
        venue_location: "New York",
        wnn: null,
        screeningImages: []
    },
    {
        id: "9b5ceb43-ab48-4e53-8ade-993f689e3bcc",
        event_name: "Bala: USA Premiere",
        type: "Premieres",
        sortable_date: "1981",
        opening_date: "1981-06-29",
        closing_date: "",
        masterlist: "satyajit-ray",
        alias: "bala-usa-premiere-1981",
        organiser: "Museum of Modern Art",
        sl_no: "49",
        venue_location: "New York",
        wnn: null,
        screeningImages: []
    },
    {
        id: "e526e19d-c7ee-47dc-8842-84ea1f759b91",
        event_name: "The Inner Eye: USA Premiere",
        type: "Premieres",
        sortable_date: "1981",
        opening_date: "1981-06-29",
        closing_date: "",
        masterlist: "satyajit-ray",
        alias: "the-inner-eye-usa-premiere-1981",
        organiser: "Museum of Modern Art",
        sl_no: "50",
        venue_location: "New York",
        wnn: null,
        screeningImages: []
    },
    {
        id: "91732043-1985-4e3e-b4bc-c5562da3bd57",
        event_name: "Pikoor Diary: USA Premiere",
        type: "Premieres",
        sortable_date: "1981",
        opening_date: "1981-07-14",
        closing_date: "",
        masterlist: "satyajit-ray",
        alias: "pikoor-diary-usa-premiere-1981",
        organiser: "Museum of Modern Art",
        sl_no: "51",
        venue_location: "New York",
        wnn: null,
        screeningImages: []
    },
    {
        id: "cf90963c-2118-431f-a769-cce59ed8a821",
        event_name: "Sikkim: USA Premiere",
        type: "Premieres",
        sortable_date: "1981",
        opening_date: "1981-07-12",
        closing_date: "",
        masterlist: "satyajit-ray",
        alias: "sikkim-usa-premiere-1981",
        organiser: "Museum of Modern Art",
        sl_no: "52",
        venue_location: "New York",
        wnn: null,
        screeningImages: []
    },
    {
        id: "77267312-f5f0-4304-a603-162edb0a64f9",
        event_name: "Sadgati: Special Screening at Bijoli",
        type: "Movie Screenings",
        sortable_date: "1982",
        opening_date: "1982-03-28",
        closing_date: "",
        masterlist: "satyajit-ray",
        alias: "sadgati-special-screening-at-bijoli-1982",
        organiser: "",
        sl_no: "53",
        venue_location: "Bijoli",
        wnn: null,
        screeningImages: []
    },
    {
        id: "4fa600e1-2799-4f1b-aeed-9d0b1ce21af2",
        event_name: "Pikoor Diary: Special Screening at Bijoli",
        type: "Movie Screenings",
        sortable_date: "1982",
        opening_date: "1982-03-28",
        closing_date: "",
        masterlist: "satyajit-ray",
        alias: "pikoor-diary-special-screening-at-bijoli-1982",
        organiser: "",
        sl_no: "54",
        venue_location: "Bijoli",
        wnn: null,
        screeningImages: []
    },
    {
        id: "05242096-5cc9-44a2-98d8-86434b878b7f",
        event_name: "Complete Retrospective",
        type: "Retrospectives",
        sortable_date: "1983",
        opening_date: "",
        closing_date: "",
        masterlist: "satyajit-ray",
        alias: "complete-retrospective-1983",
        organiser: "",
        sl_no: "55",
        venue_location: "Paris",
        wnn: null,
        screeningImages: []
    },
    {
        id: "d56e708e-37bd-40c8-acf5-e412fa13bcd7",
        event_name: "Ghare-Baire: World Premiere",
        type: "Premieres",
        sortable_date: "1984",
        opening_date: "",
        closing_date: "",
        masterlist: "satyajit-ray",
        alias: "gharebaire-world-premiere-1984",
        organiser: "Cannes Film Festival",
        sl_no: "56",
        venue_location: "Cannes, France",
        wnn: null,
        screeningImages: []
    },
    {
        id: "0b08846a-067a-4782-9401-6c1108ae620e",
        event_name: "Cannes Film Festival: Ghare Baire",
        type: "Film Festivals",
        sortable_date: "1984",
        opening_date: "1984-05-11",
        closing_date: "1984-05-23",
        masterlist: "satyajit-ray",
        alias: "cannes-film-festival-ghare-baire-1984",
        organiser: "Cannes Film Festival",
        sl_no: "57",
        venue_location: "Cannes, France",
        wnn: null,
        screeningImages: []
    },
    {
        id: "b37854e7-9a1a-487b-9900-34e427cd3fd9",
        event_name: "Kanchenjungha: British Television Premiere",
        type: "Premieres",
        sortable_date: "1988",
        opening_date: "",
        closing_date: "",
        masterlist: "satyajit-ray",
        alias: "kanchenjungha-british-television-premiere-1988",
        organiser: "",
        sl_no: "58",
        venue_location: "England",
        wnn: null,
        screeningImages: []
    },
    {
        id: "ea8a53d9-dd39-43a8-8e4f-9d641e35cff6",
        event_name: "Ganashatru: World Premiere, Special Screening",
        type: "Premieres",
        sortable_date: "1989",
        opening_date: "",
        closing_date: "",
        masterlist: "satyajit-ray",
        alias: "ganashatru-world-premiere-special-screening-1989",
        organiser: "Cannes Film Festival",
        sl_no: "59",
        venue_location: "Cannes, France",
        wnn: null,
        screeningImages: []
    },
    {
        id: "7cea5626-c0b6-4e74-889b-f52c962889d6",
        event_name: "48th Venice International Film Festival: Agantuk",
        type: "Film Festivals",
        sortable_date: "1991",
        opening_date: "1991-09-03",
        closing_date: "1991-09-14",
        masterlist: "satyajit-ray",
        alias: "48th-venice-international-film-festival-agantuk-1991",
        organiser: "48th Venice International Film Festival",
        sl_no: "60",
        venue_location: "Venice",
        wnn: null,
        screeningImages: []
    },
    {
        id: "82e52429-6701-48aa-bb32-f02e40630503",
        event_name: "Satyajit Ray Retrospective",
        type: "Retrospectives",
        sortable_date: "1996",
        opening_date: "1996-10-05",
        closing_date: "1996-11-02",
        masterlist: "satyajit-ray",
        alias: "satyajit-ray-retrospective-1996",
        organiser: "Grey Art Gallery",
        sl_no: "61",
        venue_location: "New York University, New York",
        wnn: null,
        screeningImages: []
    },
    {
        id: "72c40676-f407-4c55-9ec8-425544486c22",
        event_name: "Satyajit Ray: Lekha Theke Chhabi",
        type: "Retrospectives",
        sortable_date: "2006",
        opening_date: "",
        closing_date: "",
        masterlist: "satyajit-ray",
        alias: "satyajit-ray-lekha-theke-chhabi-2006",
        organiser: "Bengal Gallery of Fine Arts of India and Bangladesh",
        sl_no: "62",
        venue_location: "National Museum, Bangladesh",
        wnn: null,
        screeningImages: []
    },
    {
        id: "a20057af-15a0-40f9-a616-6fdb4adee7d5",
        event_name: "The World of Satyajit Ray",
        type: "Retrospectives",
        sortable_date: "2009",
        opening_date: "2009-08-15",
        closing_date: "2009-08-30",
        masterlist: "satyajit-ray",
        alias: "the-world-of-satyajit-ray-2009",
        organiser: "Film Society of Lincoln Center's Walter Reade Theatre",
        sl_no: "63",
        venue_location: "New York",
        wnn: null,
        screeningImages: []
    },
    {
        id: "9410f4ba-3e1e-4a0b-a03e-d4b8841802a6",
        event_name: "Satyajit Ray Retrospective",
        type: "Retrospectives",
        sortable_date: "2010",
        opening_date: "2010-01-10",
        closing_date: "2010-10-24",
        masterlist: "satyajit-ray",
        alias: "satyajit-ray-retrospective-2010",
        organiser: "National Museum of Singapore",
        sl_no: "64",
        venue_location: "Singapore",
        wnn: null,
        screeningImages: []
    },
    {
        id: "f4b56192-95cb-4bb8-9fa4-6fbcf57310db",
        event_name: "Satyajit Ray Retrospective",
        type: "Retrospectives",
        sortable_date: "2011",
        opening_date: "2011-02-21",
        closing_date: "2011-02-25",
        masterlist: "satyajit-ray",
        alias: "satyajit-ray-retrospective-2011",
        organiser: "Film South Asia",
        sl_no: "65",
        venue_location: "Yale Maya Kendra, Tamil Nadu",
        wnn: null,
        screeningImages: []
    },
    {
        id: "5ffa4a1a-7d39-4556-96ce-0a46b68f71f3",
        event_name: "Satyajit Ray Retrospective",
        type: "Retrospectives",
        sortable_date: "2013",
        opening_date: "2013-08-01",
        closing_date: "2013-09-01",
        masterlist: "satyajit-ray",
        alias: "satyajit-ray-retrospective-2013",
        organiser: "British Film Institute",
        sl_no: "66",
        venue_location: "London",
        wnn: null,
        screeningImages: []
    },
    {
        id: "49dbe9f7-67d7-45ef-aabd-182353d44da2",
        event_name: "The Brilliance of Satyajit Ray",
        type: "Retrospectives",
        sortable_date: "2014",
        opening_date: "2014-01-17",
        closing_date: "2014-08-31",
        masterlist: "satyajit-ray",
        alias: "the-brilliance-of-satyajit-ray-2014",
        organiser: "Berkeley Art Museum and Pacific Film Archive",
        sl_no: "67",
        venue_location: "Berkeley Art Museum ",
        wnn: null,
        screeningImages: []
    },
    {
        id: "665f9278-49bb-4bd5-b05e-67f89ffdf178",
        event_name: "The Sun and the Moon: The Films of Satyajit Ray",
        type: "Retrospectives",
        sortable_date: "2014",
        opening_date: "2014-07-03",
        closing_date: "2014-08-17",
        masterlist: "satyajit-ray",
        alias: "the-sun-and-the-moon-the-films-of-satyajit-ray-2014",
        organiser: "Toronto International Film Festival",
        sl_no: "68",
        venue_location: "TIFF Bell Lightbox, Toronto",
        wnn: null,
        screeningImages: []
    },
    {
        id: "b55b9b4b-0249-47bc-a08c-7894f709fef0",
        event_name: "Satyajit Ray Film Retrospective",
        type: "Retrospectives",
        sortable_date: "2016",
        opening_date: "2016-07-24",
        closing_date: "2016-08-08",
        masterlist: "satyajit-ray",
        alias: "satyajit-ray-film-retrospective-2016",
        organiser: "Embassy of India, Tel Aviv",
        sl_no: "69",
        venue_location: "Jerusalem, Tel Aviv, Haifa Cinematheques, Israel",
        wnn: null,
        screeningImages: []
    },
    {
        id: "c603128f-8079-4778-b1fd-f7db30144fc5",
        event_name: "Satyajit Ray Retrospective",
        type: "Retrospectives",
        sortable_date: "2018",
        opening_date: "2018-09-29",
        closing_date: "2018-09-30",
        masterlist: "satyajit-ray",
        alias: "satyajit-ray-retrospective-2018",
        organiser: "Aniruddho Sanyal, Rasa Festival",
        sl_no: "70",
        venue_location: "Ann Arbor District Library, Downtown Branch, USA",
        wnn: null,
        screeningImages: []
    },
    {
        id: "8b06e015-bc7c-4fe5-b09e-7f9b7836c64c",
        event_name: "Satyajit Ray: A Retrospective",
        type: "Retrospectives",
        sortable_date: "2019",
        opening_date: "2009-04-03",
        closing_date: "2009-05-06",
        masterlist: "satyajit-ray",
        alias: "satyajit-ray-a-retrospective-2019",
        organiser: "Southern Asia at Chicago",
        sl_no: "71",
        venue_location: "Chicago",
        wnn: null,
        screeningImages: []
    },
    {
        id: "a470f4a8-0afe-4e43-a73c-731120b53218",
        event_name: "Retrospective",
        type: "Retrospectives",
        sortable_date: "2019",
        opening_date: "2019-09-05",
        closing_date: "2019-09-15",
        masterlist: "satyajit-ray",
        alias: "retrospective-2019",
        organiser: "Toronto International Film Festival",
        sl_no: "72",
        venue_location: "Toronto",
        wnn: null,
        screeningImages: []
    },
    {
        id: "ff711667-6bfd-4577-8cf3-389e63ec5620",
        event_name: "Satyajit Ray Retrospective",
        type: "Retrospectives",
        sortable_date: "2020",
        opening_date: "2020-10-15",
        closing_date: "2020-10-25",
        masterlist: "satyajit-ray",
        alias: "satyajit-ray-retrospective-2020",
        organiser: "Rome Film Festival",
        sl_no: "73",
        venue_location: "Rome",
        wnn: null,
        screeningImages: []
    },
    {
        id: "4e8d0096-0283-420e-a133-ce978de7f170",
        event_name: "Retrospective of Satyajit Ray",
        type: "Retrospectives",
        sortable_date: "2021",
        opening_date: "2021-11-20",
        closing_date: "2021-11-28",
        masterlist: "satyajit-ray",
        alias: "retrospective-of-satyajit-ray-2021",
        organiser: "International Film Festival of India",
        sl_no: "74",
        venue_location: "Goa",
        wnn: null,
        screeningImages: []
    },
    {
        id: "e02c5581-ed15-456e-ae44-5b7818f14a4d",
        event_name: "Satyajit Ray Tribute",
        type: "Retrospectives",
        sortable_date: "2021",
        opening_date: "2021-01-16",
        closing_date: "2021-01-24",
        masterlist: "satyajit-ray",
        alias: "satyajit-ray-tribute-2021",
        organiser: "Dhaka International Film Festival",
        sl_no: "75",
        venue_location: "Dhaka, Bangladesh",
        wnn: null,
        screeningImages: []
    }
]
export const satyajit_filter_options = [
    {
    key:"Type_of_Screening",
    display:"Type of Screening",
    value: [
      "Movie Screenings",
      "Retrospectives",
      "Premieres",
      "Film Festivals",
    ]},
   { 
    key:"Years",
    display:"Year",
    value: [
      "1955",
      "1956",
      "1957",
      "1958",
      "1959",
      "1960",
      "1961",
      "2019",
      "2020",
      "2021",
    ]},
  ];

export const satyajit_screeningData=[
    [
        {
            key: "id",
            value: "71eb7f7c-c3c8-4250-baa9-a2b7821ed7fe"
        },
        {
            key: "alias",
            value: "pather-panchali-1955"
        },
        {
            key: "event_name",
            value: "Pather Panchali"
        },
        {
            key: "type",
            value: "Premieres"
        },
        {
            key: "sortable_date",
            value: "1955"
        },
        {
            key: "opening_date",
            value: "1955-05-03"
        },
        {
            key: "closing_date",
            value: ""
        },
        {
            key: "masterlist",
            value: "satyajit-ray"
        },
        {
            key: "organiser",
            value: "Museum of Modern Art"
        },
        {
            key: "venue_location",
            value: "New York"
        },
        {
            key: "wnn",
            value: 1
        },
        {
            key: "screeningImages",
            value: [sign1]
        }
    ],
    [
        {
            key: "id",
            value: "053c4083-1baf-4c86-90ec-3688e99cdbdb"
        },
        {
            key: "alias",
            value: "pather-panchali-indian-premiere-1955"
        },
        {
            key: "event_name",
            value: "Pather Panchali: Indian Premiere"
        },
        {
            key: "type",
            value: "Premieres"
        },
        {
            key: "sortable_date",
            value: "1955"
        },
        {
            key: "opening_date",
            value: ""
        },
        {
            key: "closing_date",
            value: ""
        },
        {
            key: "masterlist",
            value: "satyajit-ray"
        },
        {
            key: "organiser",
            value: "Advertising Club"
        },
        {
            key: "venue_location",
            value: "Ordinance Club, Calcutta"
        },
        {
            key: "wnn",
            value: 2
        },
        {
            key: "screeningImages",
            value: [sign2]
        }
    ],
    [
        {
            key: "id",
            value: "a9c7caf1-b723-4dc2-9379-1902b3b6900a"
        },
        {
            key: "alias",
            value: "pather-panchali-1956"
        },
        {
            key: "event_name",
            value: "Pather Panchali"
        },
        {
            key: "type",
            value: "Film Festivals"
        },
        {
            key: "sortable_date",
            value: "1956"
        },
        {
            key: "opening_date",
            value: ""
        },
        {
            key: "closing_date",
            value: ""
        },
        {
            key: "masterlist",
            value: "satyajit-ray"
        },
        {
            key: "organiser",
            value: "Cannes Film Festival"
        },
        {
            key: "venue_location",
            value: "Cannes"
        },
        {
            key: "3",
            value: null
        },
        {
            key: "screeningImages",
            value: []
        }
    ],
    [
        {
            key: "id",
            value: "f46ba012-bd34-4c60-a3ea-2c988dd6fab7"
        },
        {
            key: "alias",
            value: "9th-edinburgh-international-film-festival-pather-panchali-1956"
        },
        {
            key: "event_name",
            value: "9th Edinburgh International Film Festival: Pather Panchali"
        },
        {
            key: "type",
            value: "Film Festivals"
        },
        {
            key: "sortable_date",
            value: "1956"
        },
        {
            key: "opening_date",
            value: ""
        },
        {
            key: "closing_date",
            value: ""
        },
        {
            key: "masterlist",
            value: "satyajit-ray"
        },
        {
            key: "organiser",
            value: "Edinburgh International Film Festival"
        },
        {
            key: "venue_location",
            value: "Edinburgh"
        },
        {
            key: "wnn",
            value: null
        },
        {
            key: "screeningImages",
            value: []
        }
    ],
    [
        {
            key: "id",
            value: "c7f34397-b204-45d8-b780-87a9202b19d3"
        },
        {
            key: "alias",
            value: "rome-film-festival-pather-panchali-1956"
        },
        {
            key: "event_name",
            value: "Rome Film Festival: Pather Panchali"
        },
        {
            key: "type",
            value: "Film Festivals"
        },
        {
            key: "sortable_date",
            value: "1956"
        },
        {
            key: "opening_date",
            value: ""
        },
        {
            key: "closing_date",
            value: ""
        },
        {
            key: "masterlist",
            value: "satyajit-ray"
        },
        {
            key: "organiser",
            value: "Rome Film Festival"
        },
        {
            key: "venue_location",
            value: "Rome"
        },
        {
            key: "wnn",
            value: null
        },
        {
            key: "screeningImages",
            value: []
        }
    ],
    [
        {
            key: "id",
            value: "64bad344-b630-4cfe-8c37-8d127cabf48e"
        },
        {
            key: "alias",
            value: "manila-film-festival-pather-panchali-1956"
        },
        {
            key: "event_name",
            value: "Manila Film Festival: Pather Panchali"
        },
        {
            key: "type",
            value: "Film Festivals"
        },
        {
            key: "sortable_date",
            value: "1956"
        },
        {
            key: "opening_date",
            value: ""
        },
        {
            key: "closing_date",
            value: ""
        },
        {
            key: "masterlist",
            value: "satyajit-ray"
        },
        {
            key: "organiser",
            value: "Manila Fim Festival"
        },
        {
            key: "venue_location",
            value: "Manila"
        },
        {
            key: "wnn",
            value: null
        },
        {
            key: "screeningImages",
            value: []
        }
    ],
    [
        {
            key: "id",
            value: "60f0cafe-f306-4bcb-8407-cff03524c90e"
        },
        {
            key: "alias",
            value: "stratford-film-festival-pather-panchali-1957"
        },
        {
            key: "event_name",
            value: "Stratford Film Festival: Pather Panchali"
        },
        {
            key: "type",
            value: "Film Festivals"
        },
        {
            key: "sortable_date",
            value: "1957"
        },
        {
            key: "opening_date",
            value: ""
        },
        {
            key: "closing_date",
            value: ""
        },
        {
            key: "masterlist",
            value: "satyajit-ray"
        },
        {
            key: "organiser",
            value: "Stratford Film Festival"
        },
        {
            key: "venue_location",
            value: "Stratford"
        },
        {
            key: "wnn",
            value: null
        },
        {
            key: "screeningImages",
            value: []
        }
    ]
]

//skk

export const skk_screeningData=[
    [
        {
            "key": "id",
            "value": "5a8972c6-7c74-46e1-9023-2187036310fe",
            "display": "Id"
        },
        {
            "key": "alias",
            "value": "shatranj-ke-khilari-world-premiere-competition-section-1978",
            "display": "Alias"
        },
        {
            "key": "event_name",
            "value": "Shatranj Ke Khilari - World Premiere, Competition Section",
            "display": "Event name"
        },
        {
            "key": "type",
            "value": "Premieres",
            "display": "Type"
        },
        {
            "key": "sortable_date",
            "value": "1978",
            "display": "Sortable date"
        },
        {
            "key": "opening_date",
            "value": "1978-02-22",
            "display": "Opening date"
        },
        {
            "key": "closing_date",
            "value": "1978-05-03",
            "display": "Closing date"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "Masterlist"
        },
        {
            "key": "organiser",
            "value": "Berlin Film Festival",
            "display": "Organiser"
        },
        {
            "key": "venue_location",
            "value": "Berlin",
            "display": "Venue location"
        },
        {
            "key": "wnn",
            "value": null,
            "display": "Wnn"
        },
        {
            "key": "screeningImages",
            "value": [],
            "display": "screeningImages"
        }
    ],
    [
        {
            "key": "id",
            "value": "5b428fe0-be23-4400-b013-992b49ec7de4",
            "display": "Id"
        },
        {
            "key": "alias",
            "value": "retrospective-film-india-complete-satyajit-ray-retrospective-1981",
            "display": "Alias"
        },
        {
            "key": "event_name",
            "value": "Retrospective: Film India - Complete Satyajit Ray Retrospective",
            "display": "Event name"
        },
        {
            "key": "type",
            "value": "Retrospectives",
            "display": "Type"
        },
        {
            "key": "sortable_date",
            "value": "1981",
            "display": "Sortable date"
        },
        {
            "key": "opening_date",
            "value": "1981-06-25",
            "display": "Opening date"
        },
        {
            "key": "closing_date",
            "value": "1981-07-24",
            "display": "Closing date"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "Masterlist"
        },
        {
            "key": "organiser",
            "value": "Museum of Modern Art ",
            "display": "Organiser"
        },
        {
            "key": "venue_location",
            "value": "New York",
            "display": "Venue location"
        },
        {
            "key": "wnn",
            "value": null,
            "display": "Wnn"
        },
        {
            "key": "screeningImages",
            "value": [{
                key:"image",
                value:"true"
            }],
            "display": "screeningImages"
        }
    ],
    [
        {
            "key": "id",
            "value": "c8286c42-604c-4f80-96c7-3da1e42c4c5a",
            "display": "Id"
        },
        {
            "key": "alias",
            "value": "satyajit-ray-retrospective-1996",
            "display": "Alias"
        },
        {
            "key": "event_name",
            "value": "Satyajit Ray Retrospective",
            "display": "Event name"
        },
        {
            "key": "type",
            "value": "Retrospectives",
            "display": "Type"
        },
        {
            "key": "sortable_date",
            "value": "1996",
            "display": "Sortable date"
        },
        {
            "key": "opening_date",
            "value": "1996-05-10",
            "display": "Opening date"
        },
        {
            "key": "closing_date",
            "value": "1996-11-02",
            "display": "Closing date"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "Masterlist"
        },
        {
            "key": "organiser",
            "value": "Grey Art Gallery",
            "display": "Organiser"
        },
        {
            "key": "venue_location",
            "value": "New York University",
            "display": "Venue location"
        },
        {
            "key": "wnn",
            "value": null,
            "display": "Wnn"
        },
        {
            "key": "screeningImages",
            "value": [],
            "display": "screeningImages"
        }
    ],
    [
        {
            "key": "id",
            "value": "4677be8c-1e43-4a59-b95f-065a4e78bbf9",
            "display": "Id"
        },
        {
            "key": "alias",
            "value": "satyajit-ray-lekha-theke-chhab-2006",
            "display": "Alias"
        },
        {
            "key": "event_name",
            "value": "Satyajit Ray: Lekha Theke Chhab",
            "display": "Event name"
        },
        {
            "key": "type",
            "value": "Retrospectives",
            "display": "Type"
        },
        {
            "key": "sortable_date",
            "value": "2006",
            "display": "Sortable date"
        },
        {
            "key": "opening_date",
            "value": "2006-04-01",
            "display": "Opening date"
        },
        {
            "key": "closing_date",
            "value": "2006-04-01",
            "display": "Closing date"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "Masterlist"
        },
        {
            "key": "organiser",
            "value": "Bengal Gallery of Fine Arts of India | Bangladesh National Museum",
            "display": "Organiser"
        },
        {
            "key": "venue_location",
            "value": "Bangladesh",
            "display": "Venue location"
        },
        {
            "key": "wnn",
            "value": null,
            "display": "Wnn"
        },
        {
            "key": "screeningImages",
            "value": [],
            "display": "screeningImages"
        }
    ],
    [
        {
            "key": "id",
            "value": "b4b59a5c-6372-45fb-acb0-a9dfffeeb19b",
            "display": "Id"
        },
        {
            "key": "alias",
            "value": "the-world-of-satyajit-ray-2009",
            "display": "Alias"
        },
        {
            "key": "event_name",
            "value": "The World of Satyajit Ray",
            "display": "Event name"
        },
        {
            "key": "type",
            "value": "Retrospectives",
            "display": "Type"
        },
        {
            "key": "sortable_date",
            "value": "2009",
            "display": "Sortable date"
        },
        {
            "key": "opening_date",
            "value": "2009-08-15",
            "display": "Opening date"
        },
        {
            "key": "closing_date",
            "value": "2009-08-30",
            "display": "Closing date"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "Masterlist"
        },
        {
            "key": "organiser",
            "value": "Film Society of Lincoln Center's Walter Reade Theater",
            "display": "Organiser"
        },
        {
            "key": "venue_location",
            "value": "New York",
            "display": "Venue location"
        },
        {
            "key": "wnn",
            "value": null,
            "display": "Wnn"
        },
        {
            "key": "screeningImages",
            "value": [],
            "display": "screeningImages"
        }
    ],
    [
        {
            "key": "id",
            "value": "4bfc9ee4-c693-4f20-a0e1-bbd2d34759f8",
            "display": "Id"
        },
        {
            "key": "alias",
            "value": "satyajit-ray-retrospective-2010",
            "display": "Alias"
        },
        {
            "key": "event_name",
            "value": "Satyajit Ray Retrospective",
            "display": "Event name"
        },
        {
            "key": "type",
            "value": "Retrospectives",
            "display": "Type"
        },
        {
            "key": "sortable_date",
            "value": "2010",
            "display": "Sortable date"
        },
        {
            "key": "opening_date",
            "value": "2010-01-10",
            "display": "Opening date"
        },
        {
            "key": "closing_date",
            "value": "2010-10-24",
            "display": "Closing date"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "Masterlist"
        },
        {
            "key": "organiser",
            "value": "National Museum of Singapore",
            "display": "Organiser"
        },
        {
            "key": "venue_location",
            "value": "Singapore",
            "display": "Venue location"
        },
        {
            "key": "wnn",
            "value": null,
            "display": "Wnn"
        },
        {
            "key": "screeningImages",
            "value": [],
            "display": "screeningImages"
        }
    ],
    [
        {
            "key": "id",
            "value": "1447097b-4dc0-43a3-9c5d-79e64e874200",
            "display": "Id"
        },
        {
            "key": "alias",
            "value": "satyajit-ray-retrospective-2011",
            "display": "Alias"
        },
        {
            "key": "event_name",
            "value": "Satyajit Ray Retrospective",
            "display": "Event name"
        },
        {
            "key": "type",
            "value": "Retrospectives",
            "display": "Type"
        },
        {
            "key": "sortable_date",
            "value": "2011",
            "display": "Sortable date"
        },
        {
            "key": "opening_date",
            "value": "2011-02-21",
            "display": "Opening date"
        },
        {
            "key": "closing_date",
            "value": "2011-02-25",
            "display": "Closing date"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "Masterlist"
        },
        {
            "key": "organiser",
            "value": "Film South Asia (FSA)",
            "display": "Organiser"
        },
        {
            "key": "venue_location",
            "value": "Yale Maya Kendra, Kathmandu",
            "display": "Venue location"
        },
        {
            "key": "wnn",
            "value": null,
            "display": "Wnn"
        },
        {
            "key": "screeningImages",
            "value": [],
            "display": "screeningImages"
        }
    ],
    [
        {
            "key": "id",
            "value": "0991657d-fa13-4742-b8a2-0ed8240e3d2b",
            "display": "Id"
        },
        {
            "key": "alias",
            "value": "satyajit-ray-retrospective-2013",
            "display": "Alias"
        },
        {
            "key": "event_name",
            "value": "Satyajit Ray Retrospective",
            "display": "Event name"
        },
        {
            "key": "type",
            "value": "Retrospectives",
            "display": "Type"
        },
        {
            "key": "sortable_date",
            "value": "2013",
            "display": "Sortable date"
        },
        {
            "key": "opening_date",
            "value": "2013-08-01",
            "display": "Opening date"
        },
        {
            "key": "closing_date",
            "value": "2013-09-01",
            "display": "Closing date"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "Masterlist"
        },
        {
            "key": "organiser",
            "value": "British Film Institute",
            "display": "Organiser"
        },
        {
            "key": "venue_location",
            "value": "London",
            "display": "Venue location"
        },
        {
            "key": "wnn",
            "value": null,
            "display": "Wnn"
        },
        {
            "key": "screeningImages",
            "value": [],
            "display": "screeningImages"
        }
    ],
    [
        {
            "key": "id",
            "value": "4463987f-b9ea-409b-ba5f-a215720c387a",
            "display": "Id"
        },
        {
            "key": "alias",
            "value": "the-brilliance-of-satyajit-ray-2014",
            "display": "Alias"
        },
        {
            "key": "event_name",
            "value": "The Brilliance of Satyajit Ray",
            "display": "Event name"
        },
        {
            "key": "type",
            "value": "Retrospectives",
            "display": "Type"
        },
        {
            "key": "sortable_date",
            "value": "2014",
            "display": "Sortable date"
        },
        {
            "key": "opening_date",
            "value": "2014-01-17",
            "display": "Opening date"
        },
        {
            "key": "closing_date",
            "value": "2014-08-31",
            "display": "Closing date"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "Masterlist"
        },
        {
            "key": "organiser",
            "value": "Berkeley Art Museum | Pacific Film Archive",
            "display": "Organiser"
        },
        {
            "key": "venue_location",
            "value": "",
            "display": "Venue location"
        },
        {
            "key": "wnn",
            "value": null,
            "display": "Wnn"
        },
        {
            "key": "screeningImages",
            "value": [],
            "display": "screeningImages"
        }
    ],
    [
        {
            "key": "id",
            "value": "b31656c4-aa39-4e74-9268-f82cee5c3ccf",
            "display": "Id"
        },
        {
            "key": "alias",
            "value": "the-sun-and-the-moon-the-films-of-satyajit-ray-2014",
            "display": "Alias"
        },
        {
            "key": "event_name",
            "value": "The Sun and the Moon: The Films of Satyajit Ray",
            "display": "Event name"
        },
        {
            "key": "type",
            "value": "Retrospectives",
            "display": "Type"
        },
        {
            "key": "sortable_date",
            "value": "2014",
            "display": "Sortable date"
        },
        {
            "key": "opening_date",
            "value": "2014-03-07",
            "display": "Opening date"
        },
        {
            "key": "closing_date",
            "value": "2014-08-17",
            "display": "Closing date"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "Masterlist"
        },
        {
            "key": "organiser",
            "value": "Toronto International Film Festival (TIFF)",
            "display": "Organiser"
        },
        {
            "key": "venue_location",
            "value": "TIFF Bell Lightbox, Toronto",
            "display": "Venue location"
        },
        {
            "key": "wnn",
            "value": null,
            "display": "Wnn"
        },
        {
            "key": "screeningImages",
            "value": [],
            "display": "screeningImages"
        }
    ],
    [
        {
            "key": "id",
            "value": "3d2b5fdd-70e8-4c88-86ef-f483ee91218c",
            "display": "Id"
        },
        {
            "key": "alias",
            "value": "satyajit-ray-film-retrospective-2016",
            "display": "Alias"
        },
        {
            "key": "event_name",
            "value": "Satyajit Ray Film Retrospective",
            "display": "Event name"
        },
        {
            "key": "type",
            "value": "Retrospectives",
            "display": "Type"
        },
        {
            "key": "sortable_date",
            "value": "2016",
            "display": "Sortable date"
        },
        {
            "key": "opening_date",
            "value": "2016-07-24",
            "display": "Opening date"
        },
        {
            "key": "closing_date",
            "value": "2016-08-08",
            "display": "Closing date"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "Masterlist"
        },
        {
            "key": "organiser",
            "value": "Jerusalem, Tel Aviv, Haifa Cinematheques",
            "display": "Organiser"
        },
        {
            "key": "venue_location",
            "value": "Israel",
            "display": "Venue location"
        },
        {
            "key": "wnn",
            "value": null,
            "display": "Wnn"
        },
        {
            "key": "screeningImages",
            "value": [],
            "display": "screeningImages"
        }
    ],
    [
        {
            "key": "id",
            "value": "2282b202-df3f-4905-982b-a7682122edd1",
            "display": "Id"
        },
        {
            "key": "alias",
            "value": "satyajit-ray-retrospective-2018",
            "display": "Alias"
        },
        {
            "key": "event_name",
            "value": "Satyajit Ray Retrospective",
            "display": "Event name"
        },
        {
            "key": "type",
            "value": "Retrospectives",
            "display": "Type"
        },
        {
            "key": "sortable_date",
            "value": "2018",
            "display": "Sortable date"
        },
        {
            "key": "opening_date",
            "value": "2018-09-30",
            "display": "Opening date"
        },
        {
            "key": "closing_date",
            "value": "2018-09-30",
            "display": "Closing date"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "Masterlist"
        },
        {
            "key": "organiser",
            "value": "Rasa Festival",
            "display": "Organiser"
        },
        {
            "key": "venue_location",
            "value": "Ann Arbor District Library, Downtown Branch, USA",
            "display": "Venue location"
        },
        {
            "key": "wnn",
            "value": null,
            "display": "Wnn"
        },
        {
            "key": "screeningImages",
            "value": [],
            "display": "screeningImages"
        }
    ],
    [
        {
            "key": "id",
            "value": "5774f94e-7cfb-4615-b4d1-193c2e1df1d2",
            "display": "Id"
        },
        {
            "key": "alias",
            "value": "satyajit-ray-a-retrospective-2019",
            "display": "Alias"
        },
        {
            "key": "event_name",
            "value": "Satyajit Ray: A Retrospective",
            "display": "Event name"
        },
        {
            "key": "type",
            "value": "Retrospectives",
            "display": "Type"
        },
        {
            "key": "sortable_date",
            "value": "2019",
            "display": "Sortable date"
        },
        {
            "key": "opening_date",
            "value": "2019-03-04",
            "display": "Opening date"
        },
        {
            "key": "closing_date",
            "value": "2019-06-05",
            "display": "Closing date"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "Masterlist"
        },
        {
            "key": "organiser",
            "value": "Southern Asia at Chicago",
            "display": "Organiser"
        },
        {
            "key": "venue_location",
            "value": "Chicago",
            "display": "Venue location"
        },
        {
            "key": "wnn",
            "value": null,
            "display": "Wnn"
        },
        {
            "key": "screeningImages",
            "value": [],
            "display": "screeningImages"
        }
    ],
    [
        {
            "key": "id",
            "value": "82f9b115-2d33-43da-97c0-2f7195da09aa",
            "display": "Id"
        },
        {
            "key": "alias",
            "value": "satyajit-ray-retrospective-2020",
            "display": "Alias"
        },
        {
            "key": "event_name",
            "value": "Satyajit Ray Retrospective",
            "display": "Event name"
        },
        {
            "key": "type",
            "value": "Retrospectives",
            "display": "Type"
        },
        {
            "key": "sortable_date",
            "value": "2020",
            "display": "Sortable date"
        },
        {
            "key": "opening_date",
            "value": "2020-10-15",
            "display": "Opening date"
        },
        {
            "key": "closing_date",
            "value": "2020-10-25",
            "display": "Closing date"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "Masterlist"
        },
        {
            "key": "organiser",
            "value": "Rome Film Fest",
            "display": "Organiser"
        },
        {
            "key": "venue_location",
            "value": "Rome",
            "display": "Venue location"
        },
        {
            "key": "wnn",
            "value": null,
            "display": "Wnn"
        },
        {
            "key": "screeningImages",
            "value": [],
            "display": "screeningImages"
        }
    ],
    [
        {
            "key": "id",
            "value": "5d714e4c-17be-4b65-9827-ba982a365083",
            "display": "Id"
        },
        {
            "key": "alias",
            "value": "retrospective-of-satyajit-ray-2021",
            "display": "Alias"
        },
        {
            "key": "event_name",
            "value": "Retrospective of Satyajit Ray",
            "display": "Event name"
        },
        {
            "key": "type",
            "value": "Retrospectives",
            "display": "Type"
        },
        {
            "key": "sortable_date",
            "value": "2021",
            "display": "Sortable date"
        },
        {
            "key": "opening_date",
            "value": "2021-11-20",
            "display": "Opening date"
        },
        {
            "key": "closing_date",
            "value": "2021-11-28",
            "display": "Closing date"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "Masterlist"
        },
        {
            "key": "organiser",
            "value": "International Film Festival of India",
            "display": "Organiser"
        },
        {
            "key": "venue_location",
            "value": "Goa",
            "display": "Venue location"
        },
        {
            "key": "wnn",
            "value": null,
            "display": "Wnn"
        },
        {
            "key": "screeningImages",
            "value": [{
                key:"image",
                value:"true"
            }],
            "display": "screeningImages"
        }
    ],
    [
        {
            "key": "id",
            "value": "ad4abc82-545e-4eff-99ed-5340110747ca",
            "display": "Id"
        },
        {
            "key": "alias",
            "value": "satyajit-ray-tribute-2021",
            "display": "Alias"
        },
        {
            "key": "event_name",
            "value": "Satyajit Ray Tribute",
            "display": "Event name"
        },
        {
            "key": "type",
            "value": "Retrospectives",
            "display": "Type"
        },
        {
            "key": "sortable_date",
            "value": "2021",
            "display": "Sortable date"
        },
        {
            "key": "opening_date",
            "value": "2021-01-16",
            "display": "Opening date"
        },
        {
            "key": "closing_date",
            "value": "2021-01-24",
            "display": "Closing date"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "Masterlist"
        },
        {
            "key": "organiser",
            "value": "Dhaka International Film Festival (DIFF)",
            "display": "Organiser"
        },
        {
            "key": "venue_location",
            "value": "Dhaka",
            "display": "Venue location"
        },
        {
            "key": "wnn",
            "value": null,
            "display": "Wnn"
        },
        {
            "key": "screeningImages",
            "value": [{
                key:"image",
                value:"true"
            }],
            "display": "screeningImages"
        }
    ],
    [
        {
            "key": "id",
            "value": "8b038d3f-c8c2-4aaa-88bb-760ad849e021",
            "display": "Id"
        },
        {
            "key": "alias",
            "value": "satyajit-ray-his-contemporaries-and-legacy-2022",
            "display": "Alias"
        },
        {
            "key": "event_name",
            "value": "Satyajit Ray: His Contemporaries and Legacy",
            "display": "Event name"
        },
        {
            "key": "type",
            "value": "Retrospectives",
            "display": "Type"
        },
        {
            "key": "sortable_date",
            "value": "2022",
            "display": "Sortable date"
        },
        {
            "key": "opening_date",
            "value": "2022-04-08",
            "display": "Opening date"
        },
        {
            "key": "closing_date",
            "value": "2022-08-27",
            "display": "Closing date"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "Masterlist"
        },
        {
            "key": "organiser",
            "value": "Toronto International Film Festival (TIFF)",
            "display": "Organiser"
        },
        {
            "key": "venue_location",
            "value": "Toronto",
            "display": "Venue location"
        },
        {
            "key": "wnn",
            "value": null,
            "display": "Wnn"
        },
        {
            "key": "screeningImages",
            "value": [],
            "display": "screeningImages"
        }
    ]
]

export const skk_filter_options = [{
    key:"Type_of_Screening",
    display:"Type of Screening",
    value: [
      "Movie Screenings",
      "Retrospectives",
      "Premieres",
      "Film Festivals",
    ]
},{
    key:"Years",
    display:"Year",
    value: [
        "1978",
        "1981",
        "1996",
        "2006",
        "2009",
        "2010",
        "2011",
        "2013",
        "2014",
        "2016",
        "2018",
        "2019",
        "2020",
        "2021",
        "2022",
      ],
}
    ];

//mughal-e-azam

export const mughal_filter_options = {
    screeningTypeFilter: [
      "Movie Screenings",
      "Retrospectives",
      "Premieres",
      "Film Festivals",
    ],
    yearFilter: [
      "1978",
      "1981",
      "1996",
      "2006",
      "2009",
      "2010",
      "2011",
      "2013",
      "2014",
      "2016",
      "2018",
      "2019",
      "2020",
      "2021",
      "2022",
    ],
  };
export const mughal_screeningData=[
    [
        {
            "key": "id",
            "value": "5a8972c6-7c74-46e1-9023-2187036310fe",
            "display": "Id"
        },
        {
            "key": "alias",
            "value": "shatranj-ke-khilari-world-premiere-competition-section-1978",
            "display": "Alias"
        },
        {
            "key": "event_name",
            "value": "Shatranj Ke Khilari - World Premiere, Competition Section",
            "display": "Event name"
        },
        {
            "key": "type",
            "value": "Premieres",
            "display": "Type"
        },
        {
            "key": "sortable_date",
            "value": "1978",
            "display": "Sortable date"
        },
        {
            "key": "opening_date",
            "value": "1978-02-22",
            "display": "Opening date"
        },
        {
            "key": "closing_date",
            "value": "1978-05-03",
            "display": "Closing date"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "Masterlist"
        },
        {
            "key": "organiser",
            "value": "Berlin Film Festival",
            "display": "Organiser"
        },
        {
            "key": "venue_location",
            "value": "Berlin",
            "display": "Venue location"
        },
        {
            "key": "wnn",
            "value": null,
            "display": "Wnn"
        },
        {
            "key": "screeningImages",
            "value": [],
            "display": "screeningImages"
        }
    ],
    [
        {
            "key": "id",
            "value": "5b428fe0-be23-4400-b013-992b49ec7de4",
            "display": "Id"
        },
        {
            "key": "alias",
            "value": "retrospective-film-india-complete-satyajit-ray-retrospective-1981",
            "display": "Alias"
        },
        {
            "key": "event_name",
            "value": "Retrospective: Film India - Complete Satyajit Ray Retrospective",
            "display": "Event name"
        },
        {
            "key": "type",
            "value": "Retrospectives",
            "display": "Type"
        },
        {
            "key": "sortable_date",
            "value": "1981",
            "display": "Sortable date"
        },
        {
            "key": "opening_date",
            "value": "1981-06-25",
            "display": "Opening date"
        },
        {
            "key": "closing_date",
            "value": "1981-07-24",
            "display": "Closing date"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "Masterlist"
        },
        {
            "key": "organiser",
            "value": "Museum of Modern Art ",
            "display": "Organiser"
        },
        {
            "key": "venue_location",
            "value": "New York",
            "display": "Venue location"
        },
        {
            "key": "wnn",
            "value": null,
            "display": "Wnn"
        },
        {
            "key": "screeningImages",
            "value": [{
                key:"image",
                value:"true"
            }],
            "display": "screeningImages"
        }
    ],
    [
        {
            "key": "id",
            "value": "c8286c42-604c-4f80-96c7-3da1e42c4c5a",
            "display": "Id"
        },
        {
            "key": "alias",
            "value": "satyajit-ray-retrospective-1996",
            "display": "Alias"
        },
        {
            "key": "event_name",
            "value": "Satyajit Ray Retrospective",
            "display": "Event name"
        },
        {
            "key": "type",
            "value": "Retrospectives",
            "display": "Type"
        },
        {
            "key": "sortable_date",
            "value": "1996",
            "display": "Sortable date"
        },
        {
            "key": "opening_date",
            "value": "1996-05-10",
            "display": "Opening date"
        },
        {
            "key": "closing_date",
            "value": "1996-11-02",
            "display": "Closing date"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "Masterlist"
        },
        {
            "key": "organiser",
            "value": "Grey Art Gallery",
            "display": "Organiser"
        },
        {
            "key": "venue_location",
            "value": "New York University",
            "display": "Venue location"
        },
        {
            "key": "wnn",
            "value": null,
            "display": "Wnn"
        },
        {
            "key": "screeningImages",
            "value": [],
            "display": "screeningImages"
        }
    ],
    [
        {
            "key": "id",
            "value": "4677be8c-1e43-4a59-b95f-065a4e78bbf9",
            "display": "Id"
        },
        {
            "key": "alias",
            "value": "satyajit-ray-lekha-theke-chhab-2006",
            "display": "Alias"
        },
        {
            "key": "event_name",
            "value": "Satyajit Ray: Lekha Theke Chhab",
            "display": "Event name"
        },
        {
            "key": "type",
            "value": "Retrospectives",
            "display": "Type"
        },
        {
            "key": "sortable_date",
            "value": "2006",
            "display": "Sortable date"
        },
        {
            "key": "opening_date",
            "value": "2006-04-01",
            "display": "Opening date"
        },
        {
            "key": "closing_date",
            "value": "2006-04-01",
            "display": "Closing date"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "Masterlist"
        },
        {
            "key": "organiser",
            "value": "Bengal Gallery of Fine Arts of India | Bangladesh National Museum",
            "display": "Organiser"
        },
        {
            "key": "venue_location",
            "value": "Bangladesh",
            "display": "Venue location"
        },
        {
            "key": "wnn",
            "value": null,
            "display": "Wnn"
        },
        {
            "key": "screeningImages",
            "value": [],
            "display": "screeningImages"
        }
    ],
    [
        {
            "key": "id",
            "value": "b4b59a5c-6372-45fb-acb0-a9dfffeeb19b",
            "display": "Id"
        },
        {
            "key": "alias",
            "value": "the-world-of-satyajit-ray-2009",
            "display": "Alias"
        },
        {
            "key": "event_name",
            "value": "The World of Satyajit Ray",
            "display": "Event name"
        },
        {
            "key": "type",
            "value": "Retrospectives",
            "display": "Type"
        },
        {
            "key": "sortable_date",
            "value": "2009",
            "display": "Sortable date"
        },
        {
            "key": "opening_date",
            "value": "2009-08-15",
            "display": "Opening date"
        },
        {
            "key": "closing_date",
            "value": "2009-08-30",
            "display": "Closing date"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "Masterlist"
        },
        {
            "key": "organiser",
            "value": "Film Society of Lincoln Center's Walter Reade Theater",
            "display": "Organiser"
        },
        {
            "key": "venue_location",
            "value": "New York",
            "display": "Venue location"
        },
        {
            "key": "wnn",
            "value": null,
            "display": "Wnn"
        },
        {
            "key": "screeningImages",
            "value": [],
            "display": "screeningImages"
        }
    ],
    [
        {
            "key": "id",
            "value": "4bfc9ee4-c693-4f20-a0e1-bbd2d34759f8",
            "display": "Id"
        },
        {
            "key": "alias",
            "value": "satyajit-ray-retrospective-2010",
            "display": "Alias"
        },
        {
            "key": "event_name",
            "value": "Satyajit Ray Retrospective",
            "display": "Event name"
        },
        {
            "key": "type",
            "value": "Retrospectives",
            "display": "Type"
        },
        {
            "key": "sortable_date",
            "value": "2010",
            "display": "Sortable date"
        },
        {
            "key": "opening_date",
            "value": "2010-01-10",
            "display": "Opening date"
        },
        {
            "key": "closing_date",
            "value": "2010-10-24",
            "display": "Closing date"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "Masterlist"
        },
        {
            "key": "organiser",
            "value": "National Museum of Singapore",
            "display": "Organiser"
        },
        {
            "key": "venue_location",
            "value": "Singapore",
            "display": "Venue location"
        },
        {
            "key": "wnn",
            "value": null,
            "display": "Wnn"
        },
        {
            "key": "screeningImages",
            "value": [],
            "display": "screeningImages"
        }
    ],
    [
        {
            "key": "id",
            "value": "1447097b-4dc0-43a3-9c5d-79e64e874200",
            "display": "Id"
        },
        {
            "key": "alias",
            "value": "satyajit-ray-retrospective-2011",
            "display": "Alias"
        },
        {
            "key": "event_name",
            "value": "Satyajit Ray Retrospective",
            "display": "Event name"
        },
        {
            "key": "type",
            "value": "Retrospectives",
            "display": "Type"
        },
        {
            "key": "sortable_date",
            "value": "2011",
            "display": "Sortable date"
        },
        {
            "key": "opening_date",
            "value": "2011-02-21",
            "display": "Opening date"
        },
        {
            "key": "closing_date",
            "value": "2011-02-25",
            "display": "Closing date"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "Masterlist"
        },
        {
            "key": "organiser",
            "value": "Film South Asia (FSA)",
            "display": "Organiser"
        },
        {
            "key": "venue_location",
            "value": "Yale Maya Kendra, Kathmandu",
            "display": "Venue location"
        },
        {
            "key": "wnn",
            "value": null,
            "display": "Wnn"
        },
        {
            "key": "screeningImages",
            "value": [],
            "display": "screeningImages"
        }
    ],
    [
        {
            "key": "id",
            "value": "0991657d-fa13-4742-b8a2-0ed8240e3d2b",
            "display": "Id"
        },
        {
            "key": "alias",
            "value": "satyajit-ray-retrospective-2013",
            "display": "Alias"
        },
        {
            "key": "event_name",
            "value": "Satyajit Ray Retrospective",
            "display": "Event name"
        },
        {
            "key": "type",
            "value": "Retrospectives",
            "display": "Type"
        },
        {
            "key": "sortable_date",
            "value": "2013",
            "display": "Sortable date"
        },
        {
            "key": "opening_date",
            "value": "2013-08-01",
            "display": "Opening date"
        },
        {
            "key": "closing_date",
            "value": "2013-09-01",
            "display": "Closing date"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "Masterlist"
        },
        {
            "key": "organiser",
            "value": "British Film Institute",
            "display": "Organiser"
        },
        {
            "key": "venue_location",
            "value": "London",
            "display": "Venue location"
        },
        {
            "key": "wnn",
            "value": null,
            "display": "Wnn"
        },
        {
            "key": "screeningImages",
            "value": [],
            "display": "screeningImages"
        }
    ],
    [
        {
            "key": "id",
            "value": "4463987f-b9ea-409b-ba5f-a215720c387a",
            "display": "Id"
        },
        {
            "key": "alias",
            "value": "the-brilliance-of-satyajit-ray-2014",
            "display": "Alias"
        },
        {
            "key": "event_name",
            "value": "The Brilliance of Satyajit Ray",
            "display": "Event name"
        },
        {
            "key": "type",
            "value": "Retrospectives",
            "display": "Type"
        },
        {
            "key": "sortable_date",
            "value": "2014",
            "display": "Sortable date"
        },
        {
            "key": "opening_date",
            "value": "2014-01-17",
            "display": "Opening date"
        },
        {
            "key": "closing_date",
            "value": "2014-08-31",
            "display": "Closing date"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "Masterlist"
        },
        {
            "key": "organiser",
            "value": "Berkeley Art Museum | Pacific Film Archive",
            "display": "Organiser"
        },
        {
            "key": "venue_location",
            "value": "",
            "display": "Venue location"
        },
        {
            "key": "wnn",
            "value": null,
            "display": "Wnn"
        },
        {
            "key": "screeningImages",
            "value": [],
            "display": "screeningImages"
        }
    ],
    [
        {
            "key": "id",
            "value": "b31656c4-aa39-4e74-9268-f82cee5c3ccf",
            "display": "Id"
        },
        {
            "key": "alias",
            "value": "the-sun-and-the-moon-the-films-of-satyajit-ray-2014",
            "display": "Alias"
        },
        {
            "key": "event_name",
            "value": "The Sun and the Moon: The Films of Satyajit Ray",
            "display": "Event name"
        },
        {
            "key": "type",
            "value": "Retrospectives",
            "display": "Type"
        },
        {
            "key": "sortable_date",
            "value": "2014",
            "display": "Sortable date"
        },
        {
            "key": "opening_date",
            "value": "2014-03-07",
            "display": "Opening date"
        },
        {
            "key": "closing_date",
            "value": "2014-08-17",
            "display": "Closing date"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "Masterlist"
        },
        {
            "key": "organiser",
            "value": "Toronto International Film Festival (TIFF)",
            "display": "Organiser"
        },
        {
            "key": "venue_location",
            "value": "TIFF Bell Lightbox, Toronto",
            "display": "Venue location"
        },
        {
            "key": "wnn",
            "value": null,
            "display": "Wnn"
        },
        {
            "key": "screeningImages",
            "value": [],
            "display": "screeningImages"
        }
    ],
    [
        {
            "key": "id",
            "value": "3d2b5fdd-70e8-4c88-86ef-f483ee91218c",
            "display": "Id"
        },
        {
            "key": "alias",
            "value": "satyajit-ray-film-retrospective-2016",
            "display": "Alias"
        },
        {
            "key": "event_name",
            "value": "Satyajit Ray Film Retrospective",
            "display": "Event name"
        },
        {
            "key": "type",
            "value": "Retrospectives",
            "display": "Type"
        },
        {
            "key": "sortable_date",
            "value": "2016",
            "display": "Sortable date"
        },
        {
            "key": "opening_date",
            "value": "2016-07-24",
            "display": "Opening date"
        },
        {
            "key": "closing_date",
            "value": "2016-08-08",
            "display": "Closing date"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "Masterlist"
        },
        {
            "key": "organiser",
            "value": "Jerusalem, Tel Aviv, Haifa Cinematheques",
            "display": "Organiser"
        },
        {
            "key": "venue_location",
            "value": "Israel",
            "display": "Venue location"
        },
        {
            "key": "wnn",
            "value": null,
            "display": "Wnn"
        },
        {
            "key": "screeningImages",
            "value": [],
            "display": "screeningImages"
        }
    ],
    [
        {
            "key": "id",
            "value": "2282b202-df3f-4905-982b-a7682122edd1",
            "display": "Id"
        },
        {
            "key": "alias",
            "value": "satyajit-ray-retrospective-2018",
            "display": "Alias"
        },
        {
            "key": "event_name",
            "value": "Satyajit Ray Retrospective",
            "display": "Event name"
        },
        {
            "key": "type",
            "value": "Retrospectives",
            "display": "Type"
        },
        {
            "key": "sortable_date",
            "value": "2018",
            "display": "Sortable date"
        },
        {
            "key": "opening_date",
            "value": "2018-09-30",
            "display": "Opening date"
        },
        {
            "key": "closing_date",
            "value": "2018-09-30",
            "display": "Closing date"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "Masterlist"
        },
        {
            "key": "organiser",
            "value": "Rasa Festival",
            "display": "Organiser"
        },
        {
            "key": "venue_location",
            "value": "Ann Arbor District Library, Downtown Branch, USA",
            "display": "Venue location"
        },
        {
            "key": "wnn",
            "value": null,
            "display": "Wnn"
        },
        {
            "key": "screeningImages",
            "value": [],
            "display": "screeningImages"
        }
    ],
    [
        {
            "key": "id",
            "value": "5774f94e-7cfb-4615-b4d1-193c2e1df1d2",
            "display": "Id"
        },
        {
            "key": "alias",
            "value": "satyajit-ray-a-retrospective-2019",
            "display": "Alias"
        },
        {
            "key": "event_name",
            "value": "Satyajit Ray: A Retrospective",
            "display": "Event name"
        },
        {
            "key": "type",
            "value": "Retrospectives",
            "display": "Type"
        },
        {
            "key": "sortable_date",
            "value": "2019",
            "display": "Sortable date"
        },
        {
            "key": "opening_date",
            "value": "2019-03-04",
            "display": "Opening date"
        },
        {
            "key": "closing_date",
            "value": "2019-06-05",
            "display": "Closing date"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "Masterlist"
        },
        {
            "key": "organiser",
            "value": "Southern Asia at Chicago",
            "display": "Organiser"
        },
        {
            "key": "venue_location",
            "value": "Chicago",
            "display": "Venue location"
        },
        {
            "key": "wnn",
            "value": null,
            "display": "Wnn"
        },
        {
            "key": "screeningImages",
            "value": [],
            "display": "screeningImages"
        }
    ],
    [
        {
            "key": "id",
            "value": "82f9b115-2d33-43da-97c0-2f7195da09aa",
            "display": "Id"
        },
        {
            "key": "alias",
            "value": "satyajit-ray-retrospective-2020",
            "display": "Alias"
        },
        {
            "key": "event_name",
            "value": "Satyajit Ray Retrospective",
            "display": "Event name"
        },
        {
            "key": "type",
            "value": "Retrospectives",
            "display": "Type"
        },
        {
            "key": "sortable_date",
            "value": "2020",
            "display": "Sortable date"
        },
        {
            "key": "opening_date",
            "value": "2020-10-15",
            "display": "Opening date"
        },
        {
            "key": "closing_date",
            "value": "2020-10-25",
            "display": "Closing date"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "Masterlist"
        },
        {
            "key": "organiser",
            "value": "Rome Film Fest",
            "display": "Organiser"
        },
        {
            "key": "venue_location",
            "value": "Rome",
            "display": "Venue location"
        },
        {
            "key": "wnn",
            "value": null,
            "display": "Wnn"
        },
        {
            "key": "screeningImages",
            "value": [],
            "display": "screeningImages"
        }
    ],
    [
        {
            "key": "id",
            "value": "5d714e4c-17be-4b65-9827-ba982a365083",
            "display": "Id"
        },
        {
            "key": "alias",
            "value": "retrospective-of-satyajit-ray-2021",
            "display": "Alias"
        },
        {
            "key": "event_name",
            "value": "Retrospective of Satyajit Ray",
            "display": "Event name"
        },
        {
            "key": "type",
            "value": "Retrospectives",
            "display": "Type"
        },
        {
            "key": "sortable_date",
            "value": "2021",
            "display": "Sortable date"
        },
        {
            "key": "opening_date",
            "value": "2021-11-20",
            "display": "Opening date"
        },
        {
            "key": "closing_date",
            "value": "2021-11-28",
            "display": "Closing date"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "Masterlist"
        },
        {
            "key": "organiser",
            "value": "International Film Festival of India",
            "display": "Organiser"
        },
        {
            "key": "venue_location",
            "value": "Goa",
            "display": "Venue location"
        },
        {
            "key": "wnn",
            "value": null,
            "display": "Wnn"
        },
        {
            "key": "screeningImages",
            "value": [{
                key:"image",
                value:"true"
            }],
            "display": "screeningImages"
        }
    ],
    [
        {
            "key": "id",
            "value": "ad4abc82-545e-4eff-99ed-5340110747ca",
            "display": "Id"
        },
        {
            "key": "alias",
            "value": "satyajit-ray-tribute-2021",
            "display": "Alias"
        },
        {
            "key": "event_name",
            "value": "Satyajit Ray Tribute",
            "display": "Event name"
        },
        {
            "key": "type",
            "value": "Retrospectives",
            "display": "Type"
        },
        {
            "key": "sortable_date",
            "value": "2021",
            "display": "Sortable date"
        },
        {
            "key": "opening_date",
            "value": "2021-01-16",
            "display": "Opening date"
        },
        {
            "key": "closing_date",
            "value": "2021-01-24",
            "display": "Closing date"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "Masterlist"
        },
        {
            "key": "organiser",
            "value": "Dhaka International Film Festival (DIFF)",
            "display": "Organiser"
        },
        {
            "key": "venue_location",
            "value": "Dhaka",
            "display": "Venue location"
        },
        {
            "key": "wnn",
            "value": null,
            "display": "Wnn"
        },
        {
            "key": "screeningImages",
            "value": [{
                key:"image",
                value:"true"
            }],
            "display": "screeningImages"
        }
    ],
    [
        {
            "key": "id",
            "value": "8b038d3f-c8c2-4aaa-88bb-760ad849e021",
            "display": "Id"
        },
        {
            "key": "alias",
            "value": "satyajit-ray-his-contemporaries-and-legacy-2022",
            "display": "Alias"
        },
        {
            "key": "event_name",
            "value": "Satyajit Ray: His Contemporaries and Legacy",
            "display": "Event name"
        },
        {
            "key": "type",
            "value": "Retrospectives",
            "display": "Type"
        },
        {
            "key": "sortable_date",
            "value": "2022",
            "display": "Sortable date"
        },
        {
            "key": "opening_date",
            "value": "2022-04-08",
            "display": "Opening date"
        },
        {
            "key": "closing_date",
            "value": "2022-08-27",
            "display": "Closing date"
        },
        {
            "key": "masterlist",
            "value": "shatranj-ke-khilari-1977",
            "display": "Masterlist"
        },
        {
            "key": "organiser",
            "value": "Toronto International Film Festival (TIFF)",
            "display": "Organiser"
        },
        {
            "key": "venue_location",
            "value": "Toronto",
            "display": "Venue location"
        },
        {
            "key": "wnn",
            "value": null,
            "display": "Wnn"
        },
        {
            "key": "screeningImages",
            "value": [],
            "display": "screeningImages"
        }
    ]
]

// Raj Kapoor screening data
export const raj_kapoor_screeningData=[]

// Amitabh Bachchan screening data
export const amitabh_bachchan_screeningData=[]

