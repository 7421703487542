import React from 'react';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { AiOutlinePicture } from '@react-icons/all-files/ai/AiOutlinePicture';
import { Box, Text } from '@chakra-ui/react';

const TimelineWithImage = ({ data, showImage }) => {
    const btnRef = React.useRef();

    return (
        <VerticalTimeline layout={"1-column-left"} className="layout1 mt-0">
            {data?.map((item) => (
                <>
                    {item.images ? (
                        <Box pos={"relative"} right={"20px"} top={"20px"}>
                            <AiOutlinePicture
                                onClick={() => showImage(0, true)}
                                cursor={"pointer"}
                                color="#1E90FF"
                                size={16}
                            />
                        </Box>
                    ) : (
                        <Box width={"16px"} height={"16px"}></Box>
                    )}
                    <VerticalTimelineElement
                        id="skkScreening_timeline"
                        contentStyle={{ fontSize: "sm" }}
                        iconStyle={{
                            background: "#fff",
                            color: "black",
                            fontSize: "16px",
                            fontWeight: "700",
                        }}
                        icon={item?.year}
                    >
                        <Text
                            fontSize={"15px"}
                            fontWeight={"400"}
                            lineHeight={"17px"}
                            color={"color.gray"} 
                            ref={btnRef}
                            width={"90%"}
                        >
                            {item.value}
                        </Text>
                    </VerticalTimelineElement>
                </>
            ))}
        </VerticalTimeline>

    );
};

export default TimelineWithImage;
