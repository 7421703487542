
import {
  Box,
  Button,
  Container,
  HStack,
  Image,
  Stack,
  Text,
} from "@chakra-ui/react";

//Core file
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Navigation, Pagination, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

//Service file
import * as getInsightData from "../../services/Insight";

//Language file
import { WebpageComingSoon } from "../../CommonComponents/WebpageComingSoon";
import PostWithCitation from "../../FunctionalComponents/SimpleGrid/PostWithCitation";
import Grid from "../../FunctionalComponents/SimpleGrid/Grid";
import { getImageURL } from "../../util/getImage";
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;
const placeholderImg = `${IMAGE_BASE_PATH}/ui/DummyImage/blank.png`

export default function GlobalInsights() {
  const navigate=useNavigate();

  const [insightData, setInsightData] = useState([]);

  useEffect(() => {
    const getData = async () => {
      const result = await getInsightData.getInsights(
        1,20
      );
      if (result) {
        setInsightData(citationFactor(result));
      }
    };
    getData();
  }, []);
  
  const handleGrid = (item) => {
    navigate(`/insights/${item?.alias}`)
  }
  const citationFactor = (data) => {
    return data.map((item) => {

      const firstImage = item?.insight_images_alias[0];
      const title = item?.insight_heading;
  
      return {
        image: firstImage,
        title: title,
        alias: item?.insight_alias,
        masterlist_type: item?.masterlist_type
      };
    });
  };
  return (
    <>
      {insightData ? (
        <>
          <Container
            maxW="auto"
            pr="0px"
            pl="0px"
            marginTop={{ base: "0px", md: "0px" }}
            >
            <Stack width={"100%"} border={{base:"1px",md:"0px"}} borderColor={"color.lightgray4"} position={"relative"} id="postsSliderObject">
              <Swiper
                modules={[Navigation, Pagination, A11y]}
                slidesPerView={1}
                navigation
                height={"100%"}
                className="subslider"
              >
                {insightData && insightData.length > 0 &&
                  insightData.map((insightItem, index) => (
                    <SwiperSlide key={index}>
                      <Box
                        width={{ base: "auto", md: "700px" }}
                        height={{ base: "auto", md: "570px" }}
                        display={"flex"}
                        justifyContent={"center"}
                        className="posthover"
                          id="DivForHoverItem"
                      >
                        <figure>
                        <Link to={`/insights/${insightItem.alias}`}>
                          <Image
                            fallbackSrc={placeholderImg}
                            src={getImageURL(insightItem?.image)}
                            width={"100%"}
                            height={"100%"}
                            objectFit={"contain"}
                            
                            alt=""
                          />
                             </Link>
                          <HStack alignContent={"center"}>
                            <Box
                              className="top-right"
                              zIndex={1}
                              pt="8"
                              width={{base:"100%",md:"735px"}}
                              height="max-content"
                              position={"absolute"}
                              right={{base:"auto",md:"22%"}}
                            >
                              <Stack
                                spacing={4}
                                gap={"0"}
                                className="btn-wrap-onmobile"
                                width={{base:"100%",md:"380px"}}
                                marginLeft={{base:"0px",md:"240"}}
                              >
                                <HStack>
                              <Text
                                className="title-on-slide"
                                fontSize={"20px"}
                                fontWeight={"700"}
                                teststyle="secondary"
                                textAlign={"left"}
                                id="HiddenText"
                                color="white"
                              >
                                {insightItem?.title}
                              </Text>
                              </HStack>
                              <HStack gap={"3"} flexWrap={"wrap"}>
                                {insightItem?.masterlist_type &&
                                  insightItem?.masterlist_type.map((it, index) => {
                                    const masterlistAlias =
                                      it?.alias || "";
                                    return (
                                      <Button
                                        className={`keyword-btn secondary-color`}
                                        marginInlineStart={"0rem!important"}
                                        id="HiddenText"
                                        onClick={() =>
                                          navigate(`/${it.type}/${masterlistAlias}`)
                                        }
                                        width={"max-content"}
                                      >
                                        {it.name}
                                      </Button>
                                    );
                                  })}
                                  </HStack>
                              </Stack>
                            </Box>
                          </HStack>
                        </figure>
                      </Box>
                    </SwiperSlide>
                  ))}
              </Swiper>
            </Stack>
          </Container>
          <Container px="0" maxW="auto" marginTop={{ base: "25px", md: "40px" }}>
            <Stack>
              {
                insightData && insightData?.length > 0 &&
                <Grid
                  data={insightData}
                  columns={[2, null, 3]}
                  spacing={{base:"15px",md:"30px"}}
                  handleGrid={handleGrid}
                  component={PostWithCitation}
                />

              }
            </Stack>
          </Container>
        </>
      ) : (
        <WebpageComingSoon />
      )}
    </>
  )
}
