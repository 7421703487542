
import { mughal_e_azam_editorial_data, skk_editorial_data, skk_editorial_full_image_data, mughal_e_azam_editorial_full_image_data } from "../MockData/EditorialMockup";
import { isTransformationNotAllowed } from "../util/isTranformationAllowed";
import api from "../services/api";
import { APIS } from "../endpoints";

export const filmEditorialByMasterlistAPI = async (masterlistType, masterlist) => {
  const result = await api.get(`${APIS.BASE_URL}${masterlistType}/${masterlist}/${APIS.filmEditorial}`)
  if (result) {
    return result?.data?.data;
  } else {
    return [];
  }
};

export const filmEditorialByMasterlist = async (masterlistType, masterlist) => {
  if (isTransformationNotAllowed() === true) {
   const response = await filmEditorialByMasterlistDummyData(masterlist);
   return response;
  }else if (isTransformationNotAllowed() === false) {
    const response = await filmEditorialByMasterlistAPI(masterlistType, masterlist);
    try{
      if (response) {
        return response;
      } else {
        console.error("Invalid response data structure:", response);
        return [];
      }
    } catch (e){
      console.error("Something is wrong", e)
    }
  }
};

export const filmEditorialByMasterlistDummyData = async (masterlist) => {
  const filmEditorialDataMap = {
    "shatranj-ke-khilari-1977": skk_editorial_data,
    "mughal-e-azam": mughal_e_azam_editorial_data,
  };
  // Check if the masterlist exists in the map, if not, use a default value
  const FilmEditorialData = filmEditorialDataMap[masterlist] || skk_editorial_data;
  return FilmEditorialData;
};

export const getEditorialDataAPI = async (masterlistType, masterlist) => {
  const result = await api.get(`${APIS.BASE_URL}${masterlistType}/${masterlist}/${APIS.editorial}`)
  if (result) {
    return result?.data?.data;
  } else {
    return [];
  }
};

// This function return Editorial Full image data
export const getEditorialData = async (masterlistType, masterlist) => {
  if (isTransformationNotAllowed() === true) {
    const response = await getEditorialDataDummyData(masterlist);
    return response;
  }else if (isTransformationNotAllowed() === false) {
    const response = await getEditorialDataAPI(masterlistType, masterlist);
    // const finalresponse = getFormattedDta(response);
    return response;
  }
};

export const getEditorialDataDummyData = async (masterlist) => {
  const filmEditorialDataMap = {
    "shatranj-ke-khilari-1977": skk_editorial_full_image_data,
    "mughal-e-azam": mughal_e_azam_editorial_full_image_data,
  };

  const FilmEditorialFullImageData = filmEditorialDataMap[masterlist] || skk_editorial_full_image_data;
  return FilmEditorialFullImageData;
};

// const getFormattedDta = (response) => {
//   if (response.length === 0){
//     return [];
//   }

//   const convertedData = response.map(item => {
//     const artwork = item.artwork[0];
//     const desc = `${item.sl_no}_${artwork.artist_name}, ${artwork.name_of_publication} | ${artwork.sortable_year_of_publication} | ${artwork.medium_of_artwork} | ${artwork.revised_medium}`;
//     return {
//       id: item.sl_no,
//       image: artwork.direct_url_of_preview_image,
//       fullImage: artwork.direct_url_of_preview_image, // Full image URL needs to be filled
//       side_image: artwork.direct_url_of_preview_image, // Side image URL needs to be filled
//       desc: desc,
//       author: artwork.artist_name,
//       title: `${artwork.medium_of_artwork}, ${artwork.sortable_year_of_publication}_`,
//       document_type: artwork.revised_medium,
//       imgNum: artwork.wnn,
//       photographer: artwork.artist_name,
//       featured_actors: artwork.featuredArtist.map(artist => artist.full_name).join(", "),
//       date_of_artwork: artwork.sortable_year_of_publication,
//       content: "Content Awaited",
//       description1: "",
//     };
//   })

//   return convertedData;  
// }

