import React, { useEffect, useState } from "react";
import { Map, Polyline } from "google-maps-react";
import { GoogleApiWrapper } from "google-maps-react";
import { InfoWindow } from "google-maps-react";
import { Marker } from "google-maps-react";
import {
  Box,
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  HStack,
  Image,
  Stack,
  Text,
} from "@chakra-ui/react";
import MapTitle from "./MapTitle";
import YearMapFilter from "./YearMapFilter";
import Small from "../components/layouts/Typography/Small";
import Large from "../components/layouts/Typography/Large";
import H2 from "../components/layouts/Typography/H2";
import { common } from "../constants/constants";

//S3 Bucket osw-dev-images
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;
const Img = `${IMAGE_BASE_PATH}/ui/DummyImage/dummy2.png`

// Googel Map API Key
const gMapApiKey = process.env.REACT_APP_DEV_GMAP_API_KEY;

const mapStyles = {
  width: "100%",
  height: "100vh",
  // webkitFilter: "grayscale(99%)",
  color: "white",
};

export function MapContainer({ google }) {

  const [isOpen, setIsOpen] = React.useState(false);
  const onMarkerClick = (props, marker, e) => {
    setIsOpen(true);
  };
  const markerLabel =
    "Click on the map or drag the marker to select location where the incident occurred";
  const shantiniketanToKyoto = [
    { lat: 23.6783675, lng: 87.6728296 },
    { lat: 35.0978739, lng: 135.4386711 },
  ];
  const TokyoToBostanToDublin = [
    { lat: 35.504052, lng: 138.6484602 },
    { lat: 42.3145186, lng: -71.1103719 },
    { lat: 53.3244431, lng: -6.3857898 },
  ];
  const [dummyData, setDummyData] = useState(false);
  const dummyDataEnabled = process.env.REACT_APP_DUMMY_DATA || "";
  useEffect(() => {
    if (dummyDataEnabled.toLowerCase() === "yes") {
      setDummyData(true);
    } else {
      setDummyData(false);
    }
  }, [dummyDataEnabled]);

  return (
    <Stack>
      <Box position={""}>
        <Box
          display={{ base: "none", md: "flex" }}
          position={"fixed"}
          bottom={"5%"}
          zIndex={1}
        >
          {dummyData ? (
            <MapTitle />
          ) : (
            <Text
              p={"8"}
              fontSize={"45px"}
              color={"gray.500"}
              fontWeight="700"
              textAlign="center"
            >
              {common.WEBPAGE_COMING_SOON}
            </Text>
          )}
        </Box>
        <MapDrawer isOpen={isOpen} setIsOpen={setIsOpen} />

        <Map
          google={google}
          zoom={3}
          style={mapStyles}
          initialCenter={{
            lat: 22.7410055,
            lng: 36.4090906,
          }}
        >
          <Polyline
            path={shantiniketanToKyoto}
            strokeColor="#FF4E4E"
            strokeOpacity={0.8}
            strokeWeight={2}
            draggable={true}
            geodesic={true}
            lineDashPattern={[70, 70]}
          />
          <Polyline
            path={TokyoToBostanToDublin}
            strokeColor="#035DA1"
            strokeOpacity={0.8}
            strokeWeight={2}
            draggable={true}
            geodesic={true}
            lineDashPattern={[70, 70]}
          />
          <Box
            display={{ base: "none", md: "flex" }}
            position={"relative"}
            top="40px"
            zIndex={1}
          >
            {dummyData ? (
              <YearMapFilter />
            ) : (
              <Text
                p={"48"}
                fontSize={"45px"}
                color={"gray.400"}
                fontWeight="700"
                textAlign="center"
              >
               {common.WEBPAGE_COMING_SOON}
              </Text>
            )}
          </Box>
          {dummyData ? (
            <Marker
              title={"Shantiniketan, India"}
              name={"Shantiniketan, India"}
              position={{ lat: 23.6783675, lng: 87.6728296 }}
              icon={{
                url: "/map-marker.png",
              }}
            >
              <InfoWindow>{{ content: markerLabel }}</InfoWindow>
            </Marker>
          ) : (
            <Text fontSize={"16px"} color={"gray.400"} fontWeight="700">
            {common.WEBPAGE_COMING_SOON}
            </Text>
          )}
          {dummyData ? (
            <Marker
              title={"Calcutta, India"}
              name={"Calcutta, India"}
              position={{ lat: 22.5356839, lng: 88.207262 }}
              icon={{
                url: "/map-marker.png",
              }}
            >
              <InfoWindow>{{ content: markerLabel }}</InfoWindow>
            </Marker>
          ) : (
            <Text
              fontSize={"16px"}
              color={"gray.400"}
              fontWeight="700"
              textAlign="center"
            >
             {common.WEBPAGE_COMING_SOON}
            </Text>
          )}
          {dummyData ? (
            <Marker
              onClick={onMarkerClick}
              title={"India"}
              name={"India"}
              position={{ lat: 21.8087754, lng: 88.6863064 }}
              icon={{
                url: "/vector1.png",
              }}
            ></Marker>
          ) : (
            <Text
              fontSize={"16px"}
              color={"gray.400"}
              fontWeight="700"
              textAlign="center"
            >
            {common.WEBPAGE_COMING_SOON}
            </Text>
          )}
          {dummyData ? (
            <Marker
              name={"Boston USA"}
              position={{ lat: 42.3145186, lng: -71.1103719 }}
              icon={{
                url: "/map-marker.png",
              }}
            ></Marker>
          ) : (
            <Text
              fontSize={"16px"}
              color={"gray.400"}
              fontWeight="700"
              textAlign="center"
            >
            {common.WEBPAGE_COMING_SOON}
            </Text>
          )}
          {dummyData ? (
            <Marker
              name={"Dublin, Ireland"}
              position={{ lat: 53.3244431, lng: -6.3857898 }}
              icon={{
                url: "/map-marker.png",
              }}
            ></Marker>
          ) : (
            <Text
              fontSize={"16px"}
              color={"gray.400"}
              fontWeight="700"
              textAlign="center"
            >
            {common.WEBPAGE_COMING_SOON}
            </Text>
          )}
          {dummyData ? (
            <Marker
              name={"Boston USA"}
              position={{ lat: 42.3145186, lng: -71.1103719 }}
              icon={{
                url: "/vector2.png",
              }}
            ></Marker>
          ) : (
            <Text
              fontSize={"16px"}
              color={"gray.400"}
              fontWeight="700"
              textAlign="center"
            >
              {common.WEBPAGE_COMING_SOON}
            </Text>
          )}
          {dummyData ? (
            <Marker
              name={"Boston USA"}
              position={{ lat: 35.0978739, lng: 135.4386711 }}
              icon={{
                url: "/vector3.png",
              }}
            ></Marker>
          ) : (
            <Text
              fontSize={"16px"}
              color={"gray.400"}
              fontWeight="700"
              textAlign="center"
            >
               {common.WEBPAGE_COMING_SOON}
            </Text>
          )}
          {dummyData ? (
            <Marker
              name={"Tokyo, Japan"}
              position={{ lat: 35.504052, lng: 138.6484602 }}
              icon={{
                url: "/map-marker.png",
              }}
            ></Marker>
          ) : (
            <Text
              fontSize={"16px"}
              color={"gray.400"}
              fontWeight="700"
              textAlign="center"
            >
             {common.WEBPAGE_COMING_SOON}
            </Text>
          )}
          {dummyData ? (
            <Marker
              name={"Kyoto, Japan"}
              position={{ lat: 35.0978739, lng: 135.4386711 }}
              icon={{
                url: "/map-marker.png",
              }}
            ></Marker>
          ) : (
            <Text
              fontSize={"16px"}
              color={"gray.400"}
              fontWeight="700"
              textAlign="center"
            >
              {common.WEBPAGE_COMING_SOON}
            </Text>
          )}
        </Map>
      </Box>
    </Stack>
  );
}
const MapDrawer = ({ isOpen, setIsOpen }) => {
  const btnRef = React.useRef();

  return (
    <Stack position={"relative"}>
      <Drawer
        isOpen={isOpen}
        placement="right"
        finalFocusRef={btnRef}
        id="rightsidebarsmall"
        onClose={() => setIsOpen(false)}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader px="14px">
            <H2>Rabindranath Tagore</H2>
          </DrawerHeader>

          <DrawerBody px="14px">
            <Stack>
              <HStack>
                <Image src={Img} />
              </HStack>
              <Stack pt="18px">
                <HStack>
                  <Small color="black">
                    Rabindranath Tagore was a Bengali polymath – poet, writer,
                    playwright, composer, philosopher, social reformer and
                    painter
                  </Small>
                </HStack>
                <HStack pb="6">
                  <Small color="#035DA1">Visit Rabindranath Tagore →</Small>
                </HStack>
              </Stack>
              <Divider />
            </Stack>
            <Stack py="4">
              <H2>Indo Japanese Dialogue</H2>
              <Stack pt="3" justifyContent="space-between" flexDirection="row">
                <HStack>
                  <Large color="#035DA1">24 Books/ Poems/ Articles</Large>
                </HStack>
                <HStack>
                  <Large color="#035DA1"></Large>
                </HStack>
              </Stack>
              <Stack pt="3" justifyContent="space-between" flexDirection="row">
                <HStack>
                  <Large color="#035DA1">2 Artworks</Large>
                </HStack>
                <HStack>
                  <Large color="#035DA1"></Large>
                </HStack>
              </Stack>
              <Stack pt="3" justifyContent="space-between" flexDirection="row">
                <HStack>
                  <Large color="#035DA1">17 Posts</Large>
                </HStack>
                <HStack>
                  <Large color="#035DA1"></Large>
                </HStack>
              </Stack>
              <Stack pt="3" justifyContent="space-between" flexDirection="row">
                <HStack>
                  <Large color="#035DA1">Relation with James Cousins</Large>
                </HStack>
                <HStack>
                  <Large color="#035DA1"></Large>
                </HStack>
              </Stack>
              <Stack pt="3" justifyContent="space-between" flexDirection="row">
                <HStack>
                  <Large color="#035DA1">Relation with Okakura Kakuzō</Large>
                </HStack>
                <HStack>
                  <Large color="#035DA1"></Large>
                </HStack>
              </Stack>
              <Stack pt="3" justifyContent="space-between" flexDirection="row">
                <HStack>
                  <Large color="#035DA1">Relation with Shantiniketan</Large>
                </HStack>
                <HStack>
                  <Large color="#035DA1"></Large>
                </HStack>
              </Stack>
              <Stack pt="3" justifyContent="space-between" flexDirection="row">
                <HStack>
                  <Large color="#035DA1">Relation with Calcutta</Large>
                </HStack>
                <HStack>
                  <Large color="#035DA1"></Large>
                </HStack>
              </Stack>
            </Stack>
          </DrawerBody>

          <DrawerFooter></DrawerFooter>
        </DrawerContent>
      </Drawer>
    </Stack>
  );
};
export default GoogleApiWrapper({
  apiKey:gMapApiKey
})(MapContainer);
