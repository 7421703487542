import { Container, HStack, Image, Stack, Text } from "@chakra-ui/react";
import React, { useState } from "react";
import "react-vertical-timeline-component/style.min.css";

import Layout from "../components/sections/SidebarLayout";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper";
import { getVisionData } from "../services/VisionService";
import { common } from "../constants/constants";
import { Link } from "react-router-dom";

function Vision() {
  const [visionData, setVisionData] = useState([]);

  const [showLabel, setShowLabel] = React.useState(false);

  React.useEffect(() => {
    const getData = async () => {
      const result = await getVisionData();
      if (result?.length > 0) {
        setVisionData(result);
        setShowLabel(false);
      }
      if (result?.length === 0) {
        setShowLabel(true);
      }
    };
    getData();
  }, []);
  return (
    <Layout>
      {showLabel ? (
        <Text py={"40"} fontSize={"45px"} fontWeight="700" textAlign="center">
         {common.WEBPAGE_COMING_SOON}
        </Text>
      ) : (
        <>
          <Stack
            width={"100%"}
            bg="white"
            className="mobileviewpadding middle-section-height"
          >
            <Container maxW={"auto"}>
              <Stack
                flexDirection={"row"}
                justifyContent="center"
                alignItems={"flex-end"}
                gap="4"
                paddingTop={"6"}
                paddingBottom={"12"}
              >
                <HStack>
                  <Link to="/vision">
                    <Text
                      py="2"
                      paddingX={{base: "4", md:"8"}}
                      bg="#FFC015"
                      borderRadius={"5px"}
                      style={{
                        textAlign: "center",
                        fontSize: "15px",
                        fontWeight: "700",
                      }}
                    >
                      {common.VISION}
                    </Text>
                  </Link>
                </HStack>
                <HStack>
                  <Link to="/philosophical-framework">
                    <Text
                      py="2"
                      paddingX={{base: "4", md:"8"}}
                      bg="rgba(255, 192, 21, 0.26)"
                      borderRadius={"5px"}
                      style={{
                        textAlign: "center",
                        fontSize: "15px",
                        fontWeight: "700",
                      }}
                    >
                     {common.PHILOSOPHICAL_FRAME}
                    </Text>
                  </Link>
                </HStack>
                <HStack>
                  <Link to="/manifesto">
                    <Text
                      py="2"
                      paddingX={{base: "4", md:"8"}}
                      bg="rgba(255, 192, 21, 0.26)"
                      borderRadius={"5px"}
                      style={{
                        textAlign: "center",
                        fontSize: "15px",
                        fontWeight: "700",
                      }}
                    >
                      {common.MANIFESTO}
                    </Text>
                  </Link>
                </HStack>
              </Stack>

              <Stack width={"100%"} position={"relative"}>
                <Swiper
                  slidesPerView={1}
                  // loop={true}
                  pagination={{
                    type: "fraction",
                  }}
                  navigation={true}
                  height={"100%"}
                  modules={[Navigation, Pagination]}
                  id="visionslideshowinside"
                >
                  {visionData.map((item, index) => {
                    return (
                      <SwiperSlide width="100%" key={index}>
                        <HStack justifyContent="space-evenly">
                          <Image src={item?.image} alt="" />
                        </HStack>
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
              </Stack>
            </Container>
          </Stack>
        </>
      )}
    </Layout>
  );
}

export default Vision;
