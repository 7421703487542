import axios from "axios";
import { isTransformationNotAllowed } from "../util/isTranformationAllowed";
const baseUrlMongo = `${process.env.REACT_APP_MONGODB_URL}`;
export const researchCentreAllByMasterlistAPI = async (
    masterlistType,
    masterlist,
    filterOptions,
    page,
  ) => {
    const formData = new FormData();
    formData.append('filters', JSON.stringify(filterOptions));
    formData.append('page_number', page);
    const params = new URLSearchParams(formData);
    const result = await axios.get(`${baseUrlMongo}${masterlistType}/${masterlist}/research-centre/all`, {
      params: params
  });
    if (result?.data) {
      return result?.data;
    } else {
      return [];
    }
  };
  
  // This function return Personality researchCentreALl data
  export const researchCentreAllByMasterlist = async (
    masterlistType="generic",
    masterlist,
    filterOptions,
    page,
  ) => {
    if (isTransformationNotAllowed() === true) {
      try{

          const result = await researchCentreAllByMasterlistDummyData(masterlist);
          return result;
        
      }catch(e){
        const result = await researchCentreAllByMasterlistDummyData(masterlist);
          return result;
      }
      
    } else if (isTransformationNotAllowed() === false) {
      const response = await researchCentreAllByMasterlistAPI(
        masterlistType,
        masterlist,
        filterOptions,
        page,
      );
  
      try {
        if (response) {
  
          return response;
        } else {
          console.error("Invalid response data structure:", response);
          return [];
        }
      } catch (e) {
        console.error("Something is wrong", e);
      }
    }
  };
  
  export const researchCentreAllByMasterlistDummyData = ( masterlist) => {
    return [];
  };