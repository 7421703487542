import { silsila_horizontal_images_data, skk_horizontal_images_data } from "../MockData/FilmMockup";
import { isTransformationNotAllowed } from "../util/isTranformationAllowed";

import api from "../services/api";
import { APIS } from "../endpoints";

export const getFilmCrewsByMasterlistAPI = async (masterlist) => {
  const result = await api.get(`${APIS.BASE_URL}${APIS.film}/${masterlist}/${APIS.castCrew}`);
  if (result) {
    return result?.data?.data;
  } else {
    return [];
  }
};

export const getFilmCrewsByMasterlist = async (masterlist) => {
  if (isTransformationNotAllowed() === true) {
    const response = await getFilmCrewsByMasterlistDummyData(masterlist);
    return response;
  } else if (isTransformationNotAllowed() === false)  {
    const response = await getFilmCrewsByMasterlistAPI(masterlist);
    try {
      if (response) {
        return response;
      } else {
        console.error("Invalid response data structure:", response);
        return [];
      }
    } catch (e) {
      console.error("Something is wrong", e);
    }
  }
};

export const getFilmCrewsByMasterlistDummyData = async (masterlist) => {
  const filmCrewsDataMap = {
    "shatranj-ke-khilari-1977": skk_horizontal_images_data,
    "mughal-e-azam": skk_horizontal_images_data,
    "silsila":silsila_horizontal_images_data
  };
  // Check if the masterlist exists in the map, if not, use a default value
  const FilmCrewsData = filmCrewsDataMap[masterlist] || skk_horizontal_images_data;
  return FilmCrewsData;
};
