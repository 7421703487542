import { Navigation, Pagination, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { Box, Container, Image, Stack } from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { HasAccessLink } from "../HasAccessLink";
import SubscriptionModal from "../SubscriptionModal/SubscriptionModal";

//S3 Bucket osw-dev-images
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;

const placeholderImg = `${IMAGE_BASE_PATH}/ui/DummyImage/large.png`

const LandingPageSlider=({ initialSlide,className,data,pageName,masterlist, id="allslider"}) =>{
  const navigate =useNavigate();
  const [showSubscribeModal, setShowSubscribeModal] = useState(false);

  const pagination = {
    clickable: true,
    renderBullet: function (index, className) {
      const isLastItem = index === data.length - 1;
      const pipeCharacter = isLastItem ? "" : " | ";
      return `
      <span 
        class="${className} swiper-pagination-bullet" 
        data-to="${data[index]?.to}" 
        onclick="navigateToPage(event)"
      >
        ${data[index]?.label}
      </span>${pipeCharacter}
    `;
    },
  };

  const navigateToPage = (event) => {
    const url = event.target.getAttribute("data-to");
    navigate(url); // Navigate using react-router-dom's navigate function
  };
 
  window.navigateToPage = navigateToPage;  
  
  const swiperRef = useRef(null);
  useEffect(() => {
    if (initialSlide !== null) {
      swiperRef?.current?.swiper?.slideTo(0);
    }
  }, [initialSlide]);

  const clickHandler = (e, targetUrl) => {
    e.preventDefault();
    if(!targetUrl){
      setShowSubscribeModal(true)
    }
    navigate(targetUrl);
  };
  return (
    <>
      <Container maxW="auto" className="songs" id={id}>
        <Stack width={"100%"} position={"relative"} mb="20">
          <Swiper
            ref={swiperRef}
            modules={[Navigation, Pagination, A11y]}
            slidesPerView={1}
            navigation
            height={"100%"}
            id={className ? className :"subsliderssk"}
            pagination={data!==undefined && pagination}
          >
            {data.map((item, index) => {
              return (
                <SwiperSlide key={index}>
                    <HasAccessLink
                    key={index}
                    to={item.to}
                    pageName={pageName || ""}
                    masterlist={masterlist || ""}
                    componentName={item.componentName}
                    onClick={clickHandler}
                  >
                       <Box display={"contents"} width={{base:"100%",md:"992px"}}   height={{base:"auto",md:"520px"}}>
                       <Image src={item.image} fallbackSrc={placeholderImg} width={"auto"} objectFit={"contain"} height={"inherit"} alt="" />   
                        </Box>
                        </HasAccessLink>  
                </SwiperSlide>
              );
            })}
          </Swiper>
        </Stack>
        {
        showSubscribeModal &&
        <SubscriptionModal className="subscribe-modal-content1" setShowSubscribeModal={setShowSubscribeModal} />
      }
        
      </Container>
    </>
  );
}
export default LandingPageSlider;
