import {
  Box,
  Container,
  Divider,
  HStack,
  Image,
  Stack,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { common } from "../../constants/constants";
import { useParams } from "react-router-dom";
import * as getResearchCentreBook from "../../services/book";
import Small from "../../components/layouts/Typography/Small";
import BookSlider from "../../FunctionalComponents/BookSlider/BookSlider";
import Accordian from "../../FunctionalComponents/Accordion/Accordian";
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;
const loaderImg = `${IMAGE_BASE_PATH}/ui/Chevron/loader.gif`;

export const BookDetails = ({thumbnailWidth,name }) => {

  const { bookAlias } = useParams();
  const [bookData, setBookData] = useState(null);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isExpandedAuthor, setIsExpandedAuthor] = useState(false);
  const [accordianData, setAccordianData] = useState([]);
  const [selectedImg, setSelectedImg] = useState({
    image: "",
    desc: "",
    index: 0,
    slideImage:"",
  });
  const [isLoading, setIsLoading] = useState(false);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };
  const toggleExpandAuthor = () => {
    setIsExpandedAuthor(!isExpandedAuthor);
  };

  const citationFactor = (item) => {
    // return data.map((item) => {
      const content_data = item?.find((nestedItem) => nestedItem?.key === "content_data")?.value?.split('\n\n')
      const index_data = item?.find((nestedItem) => nestedItem?.key === "index_data")?.value
      const about_book=item?.find((nestedItem) => nestedItem?.key === "about_book")?.value
      const about_author=item?.find((nestedItem) => nestedItem?.key === "about_author")?.value
      const title=item?.find((nestedItem) => nestedItem?.key === "title")?.value
      const sub_title=item?.find((nestedItem) => nestedItem?.key === "sub_title")?.value
      const bookName= `${title} - ${sub_title}`
      
      const scanned_pages_image = item?.find(nestedItem => nestedItem?.key === "scanned_pages_image_alias").value?.map(nestedItem => nestedItem?.direct_url_of_cover_image)

      
      // publicationYear
      return {
      content_data,
      index_data,
      about_book,
      about_author,
      scanned_pages_image,
      bookName
      };
    // });
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
      let result = await getResearchCentreBook.getResearchCentreBookbyBookAlias(
        bookAlias
      );
      setIsLoading(false);

      const bookProcessedData=citationFactor(result?.data[0]?.staticBookData)
      setBookData(bookProcessedData);
      if (bookProcessedData) {
        setSelectedImg({
          image: bookProcessedData?.scanned_pages_image[0],
          desc: "",
          index: 0,
          slideImage: bookProcessedData?.scanned_pages_image[0],
        });
        const formattedData = formatData(bookProcessedData);
        setAccordianData(formattedData);
      }
    }catch (error) {
      console.error("Error fetching data:", error);
      setIsLoading(false);
    }
    };
    fetchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
}, []);

  function formatData(data) {
    return [
      {
        name: `${common.CONTENTS}`,
        data: data?.content_data,
        component:
        data &&
        data?.content_data?.length > 0 &&
        data?.content_data?.map((item,index) => {
            return (
              <>
                <Text fontSize={"14px"} pt={"4px"} pb={"4px"} px={5} key={index}>
                  {item}
                </Text>
                <Divider color="color.lightgray7" />
              </>
            );
          }),
      },
      {
        name: `${common.INDEX}`,
        component: <Small px={5}>{common.CONTENT_AWAITED}</Small>,
      },
    ];
  }

  return (
    <>
    {!isLoading ?
      <Stack  flexDirection={{base:"column",md:"row"}}
        justifyContent={"space-between"}
        py={{base:"40px",md:name?"90px":"60px"}}
        gap={"20px"}
        width={{base:"100%",md:"1140px"}}>

        {bookData && 
        <HStack
        alignItems={"center"}
        flexDirection={"column"}
        width={{base:"100%",md:"100%"}}
        className="col-6"
      >
        <BookSlider bookData={bookData?.scanned_pages_image} left={"0"} width={"100%"} thumbnailWidth={thumbnailWidth} setSelectedImg={setSelectedImg} selectedImg={selectedImg}/>
        </HStack>
        }
        <HStack flexDirection={"column"} alignItems={"flex-start"} height={"100%"} width={"100%"} px={{base:"4",md:"8"}}>
          <Text
            fontSize={"20px"}
            fontWeight={"700"}
            lineHeight={"22px"}
            color="color.gray"
          >
            {bookData?.bookName}
          </Text>
          <Box width={{base:"100%",md:"392px"}} py={5}>
            {accordianData && accordianData?.length > 0 && (
              <Accordian data={accordianData} maxH="160px" />
            )}
          </Box>
          <Container px="0px">
            <Text
              fontSize={"15px"}
              fontWeight={"700"}
              lineHeight={"22px"}
              paddingLeft={"20px"}
            >
              <li>{common.ABOUT_THE_BOOK}</li>
            </Text>
            <Text
              fontSize={"15px"}
              fontWeight={"500"}
              // lineHeight={"22px"}
              marginTop={"25px"}
              textAlign={"justify"}
            >
              {isExpanded && bookData?.about_book
                ? bookData && bookData?.about_book
                : `${
                    bookData && bookData?.about_book.slice(0, 360)
                  }`}
              <Text
               as={"span"}
               color={"color.blue"}
               cursor={"pointer"}
               fontWeight={"700"}
                
                onClick={toggleExpand}
              >
                {isExpanded ? " Read Less" : " Read More"}
              </Text>
            </Text>
          </Container>
          <Container px="0px" py={5}>
            <Text
              fontSize={"15px"}
              fontWeight={"700"}
              lineHeight={"22px"}
              paddingLeft={"20px"}
            >
              <li>{common.ABOUT_THE_AUTHOR}</li>
            </Text>
            <Text
              fontSize={"15px"}
              fontWeight={"500"}
              // lineHeight={"22px"}
              marginTop={"25px"}
              textAlign={"justify"}
            >
              {isExpandedAuthor
                ? bookData && bookData?.about_author
                : `${
                    bookData &&
                    bookData?.about_author?.slice(0, 263)
                  }`}
              <Text
               as={"span"}
               color={"color.blue"}
               cursor={"pointer"}
               fontWeight={"700"}
               onClick={toggleExpandAuthor}
              >
                {isExpandedAuthor ? " Read Less" : " Read More"}
              </Text>
            </Text>
          </Container>
        </HStack>
      </Stack>:
    (
      <HStack justifyContent={"center"} minH={"100vh"} alignItems={"flex-start"} mt={4}>
          <Image src={loaderImg} width={"100px"} height={"100px"} alt="Loading" />
      </HStack>
    )
  }
    </>
  );
};
