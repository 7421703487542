import { Image, HStack, Box } from "@chakra-ui/react";

//Core files
import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import H4 from "../../../Theme/Typography/H4";

function CatalogueImageSlider ({
  sliderData,
  handleImageClick,
  width,
  height,
  modules,
  grabCursor,
  setSwiper,
  classname,
  sheight,
}) {
  return (
    <Swiper
      grabCursor={!grabCursor && true}
      navigation={false}
      modules={modules}
      effect="creative"
      onSwiper={setSwiper && setSwiper}
    >
      {sliderData?.map((item, index) => {
        return (
          <>
            <SwiperSlide key={item?.id} className={classname && classname}>
              <HStack ml={{base:"0px",md:"210px"}} justifyContent={"end"} alignItems={"self-start"}>
                <Box width={{base:"100%", md:"438px"}} height={{base:"100%", md:"534px"}}>
                  <Image
                    onClick={() =>
                      handleImageClick && handleImageClick(item, index)
                    }
                    src={item.imgPath ? item?.imgPath : item}
                    width={"100%"}
                    height={"inherit"}
                    objectFit={"contain"}
                  />
                </Box>
                <H4 className="ml-0" width={"286px"} color={"color.blue"} >
                    {item?.desc ? item?.desc : item?.title}
                  </H4>
              </HStack>
            </SwiperSlide>
          </>
        );
      })}
    </Swiper>
  );
}

export default CatalogueImageSlider;
