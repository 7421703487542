import axios from "axios";
import { getWhereGraphqlQuery } from "../util/helper";
import {
  art_heritage_grid_view,
  art_heritage_list_view,
} from "../MockData/Bibliography";
import { SKKBibliography } from "../MockData/SKKBibliography";
import { transformJSON } from "../util/transformer";
import { personalityBibliographySchema } from "./schemas/Bibliography";
import {
  mughal_biblography_filter_options,
  mughal_research_centre,
  photography_in_india_biblography_drawerData,
  photography_in_india_biblography_filter_options,
  photography_in_india_research_centre,
  pictorial_space_biblography_filter_options,
  pictorial_space_research_centre,
  satyajit_biblography_filter_options,
  satyajit_research_centre,
  skk_biblography_drawerData,
  skk_biblography_filter_options,
  skk_research_centre,
  lucknow_research_centre,
  raj_kapoor_research_centre,
  raj_kapoor_biblography_filter_options,
  amitabh_bachchan_research_centre,
  amitabh_bachchan_biblography_filter_options,
} from "../MockData/BibliographyMockup";
import _ from "lodash";
import { isTransformationNotAllowed } from "../util/isTranformationAllowed";
import { kg_subramanym_research_centre, shraza_research_centre } from "../MockData/PersonalityArtist";
const baseUrl = `${process.env.REACT_APP_GRAPHQL_URL}`;

const baseUrlMongo= `${process.env.REACT_APP_MONGODB_URL}`
// This is used for location page only
export const getBibliography = async ({ author, publisher, ...filter }) => {
  let otherFilter = {
    ...(author ? { author } : {}),
    ...(publisher ? { publisher } : {}),
  };
  let filterQuery = `(`;
  filterQuery += "order_by: {date_of_library_publication: asc}";
  if (Object.keys(filter).length > 0 || Object.keys(otherFilter).length > 0) {
    filterQuery += ", where:{";
  }
  filterQuery += getWhereGraphqlQuery(filter, {
    withComa: false,
    withWhere: false,
  });
  if (author) {
    if (Object.keys(filter).length > 0) {
      filterQuery += `, `;
    }
    filterQuery += getQuery(author, "author");
  }

  if (publisher) {
    if (Object.keys(filter).length > 0) {
      filterQuery += `, `;
    }
    filterQuery += ` _or:{`;
    filterQuery += getQuery(publisher, "publisher");
    filterQuery += `}`;
  }
  if (Object.keys(filter).length > 0 || Object.keys(otherFilter).length > 0) {
    filterQuery += "}";
  }
  filterQuery += `)`;

  const graphqlQuery = {
    operationName: "MyQuery",
    query: `query MyQuery {
      trcis_bibliography${filterQuery} {
        id
        place_of_publication
        reference_code
        title
        web_serial_number
        web_display
        histographical_note
        date_of_library_publication
        main_historical_note
        alias
        bibliography_creators {
          is_author
          is_publisher
          creator {
            creator
          }
        }
      }
    }
      `,
    variables: {},
  };
  const result = await axios.post(baseUrl, graphqlQuery);
  if (result?.data?.data?.trcis_bibliography) {
    return result?.data?.data?.trcis_bibliography;
  } else {
    return [];
  }
};

const getQuery = (creator, type = "author") => {
  let filterQuery = "";
  if (creator) {
    filterQuery += `bibliography_creators: {creator: {creator: {_eq:"${creator}"}}, is_${type}: {_eq:true}}`;
  }

  return filterQuery;
};

export const bibliographyByMasterlistAPI = async (
  masterlist,
  masterlistType,
  filterOptions,
  page,
) => {
  const formData = new FormData();
  formData.append('filters', JSON.stringify(filterOptions));
  formData.append('page_number', page);
  const params = new URLSearchParams(formData);

  const result = await axios.get(`${baseUrlMongo}${masterlistType}/${masterlist}/research-centre/library`, {
    params: params
});
  if (result?.data) {
    return result?.data;
  } else {
    return [];
  }
};

// This function return Personality Bibliography data
export const bibliographyByMasterlist = async (
  masterlist,
  masterlistType="person-actor",
  filterOptions,
  page,
) => {
  if (isTransformationNotAllowed() === true) {
    try{
      const result = await axios.get(`${baseUrlMongo}/${masterlistType}/${masterlist}/research-centre/library`);
      if (result?.data?.data?.length>0) {
        return result?.data;
      } else {
        const { mockData, filterMockData } = await bibliographyByMasterlistDummyData(masterlist);
        return { mockData, filterMockData };
      }
    }catch(e){
      console.log(e,"Error")
      const { mockData, filterMockData } = await bibliographyByMasterlistDummyData(masterlist);
        return { mockData, filterMockData };
    }
    
  } else if (isTransformationNotAllowed() === false) {
    const response = await bibliographyByMasterlistAPI(
      masterlist,
      masterlistType,
      filterOptions,
      page,
    );

    try {
      if (response) {

        return response;
      } else {
        console.error("Invalid response data structure:", response);
        return [];
      }
    } catch (e) {
      console.error("Something is wrong", e);
    }
  }
};

export const bibliographyByMasterlistDummyData = ( masterlist) => {
  const bibliographDataMap = {
    "satyajit-ray": satyajit_research_centre,
    "shatranj-ke-khilari-1977": skk_research_centre,
    "art-heritage-annual": satyajit_research_centre,
    "mughal-e-azam": mughal_research_centre,
    "sayed-haider-raza": shraza_research_centre,
    "photography-in-india_3":photography_in_india_research_centre,
    "lucknow":lucknow_research_centre,
    "pictorial-space":pictorial_space_research_centre,
    "raj-kapoor": raj_kapoor_research_centre,
    "k.g-Subramanyan":kg_subramanym_research_centre,
    "amitabh-bachchan":amitabh_bachchan_research_centre
  };
  const mockData = bibliographDataMap[masterlist] || [];
  // const mockData= satyajit_research_centre;
  const filterMockData = (filterCategories, searchTerm) => {
    if (
      filterCategories !== null &&
      (filterCategories.docType.length > 0 ||
        searchTerm !== "" ||
        searchTerm !== undefined)
    ) {
      const filteredData = _.filter(mockData, (entry) => {
        const docTypeCheck =
          filterCategories.docType.length > 0
            ? filterCategories.docType.includes(
                entry.find((item) => item.key === "document_type").value
              )
            : true;
        const yearTypeCheck =
          filterCategories.year.length > 0
            ? filterCategories.year.includes(
                entry.find(
                  (item) => item.key === "sortable_year_date_of_publication"
                ).value
              )
            : true;
        const creatorTypeCheck =
          filterCategories.creatorType.length > 0
            ? filterCategories.creatorType.includes(
                entry.find((item) => item.key === "title_and_type_of_creator")
                  .value
              )
            : true;
        const searchTermCheck =
          searchTerm !== ""
            ? entry
                .find((item) => item.key === "document_type")
                .value.toLowerCase()
                .includes(searchTerm.toLowerCase()) ||
              entry
                .find(
                  (item) => item.key === "sortable_year_date_of_publication"
                )
                .value.toLowerCase()
                .includes(searchTerm.toLowerCase()) ||
              entry
                .find((item) => item.key === "title_and_type_of_creator")
                .value.toLowerCase()
                .includes(searchTerm.toLowerCase())
            : true;
        return (
          docTypeCheck && yearTypeCheck && creatorTypeCheck && searchTermCheck
        );
      });
      return filteredData;
    } else {
      return mockData;
    }
  };
  return {
    mockData,
    filterMockData,
  };
};

// This function return Personality Bibliography Filter options data
export const getBibliographyFilterOptionsAPI = async (
  tableName,
  masterlist
) => {
  const graphqlQuery = {
    query:
      `query Bibliographies($where: BibliographiesWhere, $options: BibliographiesOptions) {
        ` +
      tableName +
      `(where: $where, options: $options) {
          sortable_year_date_of_publication
          document_type
          title_and_type_of_creator
          publisher       
        }
      }
    `,
    variables: {
      where: {
        masterlist_IN: masterlist,
      },
    },
  };

  const result = await axios.post(baseUrl, graphqlQuery);
  if (result?.data?.data?.bibliographies) {
    return result?.data?.data?.bibliographies;
  } else {
    return [];
  }
};

export const getBibliographyFilterOptions = async (tableName, masterlist) => {
  if (isTransformationNotAllowed() === true) {
    const response = await getBibliographyFilterOptionsDummyData(masterlist);
    return response;
  } else if (isTransformationNotAllowed() === false) {
    const response = await getBibliographyFilterOptionsAPI(
      tableName,
      masterlist
    );
    const FilterOptions = {
      documentTypeFilter: [
        ...new Set(
          response
            .map((item) => item.document_type.trim())
            .filter((value) => value !== "")
        ),
      ],
      yearFilter: [
        ...new Set(
          response
            .map((item) => item.sortable_year_date_of_publication.trim())
            .filter((value) => value !== "")
        ),
      ],
      creatorTypeFilter: [
        ...new Set(
          response
            .map((item) => item.title_and_type_of_creator.trim())
            .filter((value) => value !== "")
        ),
      ],
      publisher: [
        ...new Set(
          response
            .map((item) => item.publisher.trim())
            .filter((value) => value !== "")
        ),
      ],
    };

    return FilterOptions;
  }
};
const getBibliographyFilterOptionsDummyData = (masterlist) => {
  const bibliographDataMap = {
    "satyajit-ray": satyajit_biblography_filter_options,
    "shatranj-ke-khilari-1977": skk_biblography_filter_options,
    "art-heritage-annual": satyajit_biblography_filter_options,
    "mughal-e-azam": mughal_biblography_filter_options,
    "sayed-haider-raza": satyajit_biblography_filter_options,
    "photography-in-india_3":photography_in_india_biblography_filter_options,
    "pictorial-space":pictorial_space_biblography_filter_options,
    "raj-kapoor": raj_kapoor_biblography_filter_options,
    "amitabh-bachchan": amitabh_bachchan_biblography_filter_options,
  };
  return bibliographDataMap[masterlist] || satyajit_biblography_filter_options;
};

//Art-heritage
export const getBibliographyDummyData = async (winnerName) => {
  // return bibliographyDummyData;
  switch (winnerName) {
    case "grid":
      return art_heritage_grid_view;
    case "list":
      return art_heritage_list_view;
    default:
      // Handle the default case if needed
      return art_heritage_grid_view;
  }
};

// SKK, Mugale-E-Azam Bibliography dummy data
export const getSKKBibliographyDummyData = async () => {
  return SKKBibliography;
};
//Drawer Data
export const getBibliographyBookData = async (bookName, masterlist) => {
  if (isTransformationNotAllowed() === true) {
    const response = await getBibliographyBookDataDummyData(masterlist);
    return response;
  } else if (isTransformationNotAllowed() === false) {
  }
};
const getBibliographyBookDataDummyData = (masterlist) => {
  const bibliographBookDataDataMap = {
    "satyajit-ray": skk_biblography_drawerData,
    "shatranj-ke-khilari-1977": skk_biblography_drawerData,
    "art-heritage-annual": skk_biblography_drawerData,
    "mughal-e-azam": skk_biblography_drawerData,
    "sayed-haider-raza": skk_biblography_drawerData,
    "photography-in-india_3":photography_in_india_biblography_drawerData,
    // "lucknow": lucknow_biblography_drawerData,
  };
  return bibliographBookDataDataMap[masterlist];
};


// RC bibiliography data
export const bibliographyByResearchCategoryAPI = async (
  tableName,
  masterlist,
  filterOptions,
  searchTerm
) => {
  const whereCondition = {
    masterlist_IN: masterlist,
  };

  // Conditionally add sortable_year_date_of_publication_IN property if date is set and not empty
  if (filterOptions?.year && filterOptions?.year.length > 0) {
    whereCondition.sortable_year_date_of_publication_IN = filterOptions.year;
  }

  // Conditionally add document_type_IN property if document type is set and not empty
  if (filterOptions?.docType && filterOptions?.docType.length > 0) {
    whereCondition.document_type_IN = filterOptions.docType;
  }

  // Conditionally add title_and_type_of_creator property if creator type is set and not empty
  if (filterOptions?.creatorType && filterOptions?.creatorType.length > 0) {
    whereCondition.title_and_type_of_creator_IN = filterOptions.creatorType;
  }

  // Conditionally add place_of_publication property if document type is set and not empty
  if (filterOptions?.publisher && filterOptions?.publisher.length > 0) {
    whereCondition.publisher_IN = filterOptions.publisher;
  }
  const graphqlQuery = {
    query:
      `query Bibliographies($where: BibliographiesWhere, $options: BibliographiesOptions) {
        ` +
      tableName +
      `(where: $where, options: $options) {
          id
          title
          alias
          web_serial_number
          reference_code
          web_display
          date_of_library_publication
          place_of_publication
          histographical_note
          main_historical_note
          binding_status
          accession_number
          cdt
          country_of_publication
          date_of_published_edition_in_library
          dates_of_first_edition
          isbn_issn_number
          language_of_publication
          number_of_pages_in_publication_text
          personality_film_visually_featured_on_cover
          publisher
          serial_number
          sortable_year_date_of_publication
          sub_title_of_document_book
          title_of_document_book
          book_title_of_document
          book_sub_title_of_document
          volume_issue_number
          masterlist
          image
          document_type
          title_and_type_of_creator
          number_of_pages_in_publication
          serial_no
          researchCategories {
            alias
            id
            name
            abbreviation
            relevance_to_self_discovery_journey
            research_category_as_in_website
          }
          creator {
            alias
            name
            first_name
            last_name
            full_name
          }        
        }
      }
    `,
    variables: {
      where: whereCondition,
      options: {
        sort: [
          {
            sortable_year_date_of_publication: "ASC",
          },
        ],
      },
    },
  };

  const result = await axios.post(baseUrl, graphqlQuery);
  if (result?.data?.data?.bibliographies) {
    return result?.data?.data?.bibliographies;
  } else {
    return [];
  }
};
export const bibliographyByResearchCategory = async (
  tableName,
  masterlist,
  filterOptions = {},
  searchTerm = ""
) => {
  if (isTransformationNotAllowed() === true) {
    const { mockData, filterMockData } =
      await bibliographyByResearchCategoryDummyData(tableName, masterlist);
    return { mockData, filterMockData };
  } else if (isTransformationNotAllowed() === false) {
    const filteredData = Object.fromEntries(
      Object.entries(filterOptions).filter(([_, v]) => v.length > 0)
    );
    const response = await bibliographyByResearchCategoryAPI(
      tableName,
      masterlist,
      filteredData,
      searchTerm
    );
    try {
      if (response) {
        let transformedData = transformJSON(
          personalityBibliographySchema,
          true,
          response
        );

        return transformedData;
      } else {
        console.error("Invalid response data structure:", response);
        return [];
      }
    } catch (e) {
      console.error("Something is wrong", e);
    }
  }
};

export const bibliographyByResearchCategoryDummyData = (tableName, masterlist) => {
  const bibliographDataMap = {
    "photography-in-india_3":photography_in_india_research_centre
  };
  const mockData = bibliographDataMap[masterlist];
  // const mockData= satyajit_research_centre;
  const filterMockData = (filterCategories, searchTerm) => {
    if (
      filterCategories !== null &&
      (filterCategories.docType.length > 0 ||
        searchTerm !== "" ||
        searchTerm !== undefined)
    ) {
      const filteredData = _.filter(mockData, (entry) => {
        const docTypeCheck =
          filterCategories.docType.length > 0
            ? filterCategories.docType.includes(
                entry.find((item) => item.key === "document_type").value
              )
            : true;
        const yearTypeCheck =
          filterCategories.year.length > 0
            ? filterCategories.year.includes(
                entry.find(
                  (item) => item.key === "sortable_year_date_of_publication"
                ).value
              )
            : true;
        const creatorTypeCheck =
          filterCategories.creatorType.length > 0
            ? filterCategories.creatorType.includes(
                entry.find((item) => item.key === "title_and_type_of_creator")
                  .value
              )
            : true;
        const searchTermCheck =
          searchTerm !== ""
            ? entry
                .find((item) => item.key === "document_type")
                .value.toLowerCase()
                .includes(searchTerm.toLowerCase()) ||
              entry
                .find(
                  (item) => item.key === "sortable_year_date_of_publication"
                )
                .value.toLowerCase()
                .includes(searchTerm.toLowerCase()) ||
              entry
                .find((item) => item.key === "title_and_type_of_creator")
                .value.toLowerCase()
                .includes(searchTerm.toLowerCase())
            : true;
        return (
          docTypeCheck && yearTypeCheck && creatorTypeCheck && searchTermCheck
        );
      });
      return filteredData;
    } else {
      return mockData;
    }
  };
  return {
    mockData,
    filterMockData,
  };
};