import { IntroductionData, amitabh_bachchan_education_data, amitabh_bachchan_introduction_data, amitabh_bachchan_personalia_data, amitabh_bachchan_teaching_data, art_heritage_annual_data, bansi_chandragupta_introduction_data, birju_maharaj_introduction_data, calcutta_data, dulal_dutta_introduction_data, javed_siddiqi_introduction_data, kg_subramanym_education_data, kg_subramanym_introduction_data, kg_subramanym_personalia_data, kg_subramanym_teaching_data, lucknow_data, nemai_ghosh_introduction_data, pablo_bartholomew_introduction_data, pictorial_space_annual_data, raj_kapoor_education_data, raj_kapoor_introduction_data, raj_kapoor_personalia_data, raj_kapoor_teaching_data, sandip_ray_introduction_data, sanjeev_kumar_introduction_data, saswati_sen_introduction_data, satyajit_education_data, satyajit_introduction_data, satyajit_personalia_data, satyajit_teaching_data, sh_raza_education_data, sh_raza_introduction_data, sh_raza_personalia_data, sh_raza_teaching_data, shama_zaidi_introduction_data, shammi_kapoor_introduction_data, shammi_kapoor_personalia_data, shammi_kapoor_teaching_data, song_synopsis_booklet_data, songsSynopsisBookletData, soumendu_roy_introduction_data, sumatra_ghoshal_introduction_data, suresh_jindal_introduction_data } from "../MockData/Introduction";
import { explore } from "../constants/constants";
import { isTransformationNotAllowed } from "../util/isTranformationAllowed";

import api from "../services/api";
import { APIS } from "../endpoints";

//S3 Bucket osw-dev-images
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;

const Banner2 = `${IMAGE_BASE_PATH}/ui/Home/SliderImage/banner2.jpg`
const Banner3 = `${IMAGE_BASE_PATH}/ui/PersonalityPolymath/SatyajitRay/Posts/postimage.png`
const SKK = `${IMAGE_BASE_PATH}/ui/Film/ShatranjKeKhilari/Posts/PostBanner/banner1.png`
const SKK2 = `${IMAGE_BASE_PATH}/ui/Film/ShatranjKeKhilari/Posts/PostBanner/banner2.png`
const SKK3 = `${IMAGE_BASE_PATH}/ui/Film/ShatranjKeKhilari/Posts/PostBanner/banner3.png`
const Img = `${IMAGE_BASE_PATH}/ui/DummyImage/dummy2.png`
const Banner4 = `${IMAGE_BASE_PATH}/ui/Home/SliderImage/banner4.jpg`
const Banner5 = `${IMAGE_BASE_PATH}/ui/Home/SliderImage/banner5.jpg`
const Banner6 = `${IMAGE_BASE_PATH}/ui/Home/SliderImage/banner6.jpg`
const Pre4 = `${IMAGE_BASE_PATH}/ui/Film/ShatranjKeKhilari/Introduction/Specification/Small1.png`
const Pre5 = `${IMAGE_BASE_PATH}/ui/Film/ShatranjKeKhilari/Introduction/Specification/Small2.png`
const Pre6 = `${IMAGE_BASE_PATH}/ui/Film/ShatranjKeKhilari/Introduction/Specification/Small3.png`
const Pre7 = `${IMAGE_BASE_PATH}/ui/Film/ShatranjKeKhilari/Introduction/Specification/Small4.png`
const Synopsis1 = `${IMAGE_BASE_PATH}/ui/Film/ShatranjKeKhilari/BodyOfWork/Synopsis/synopsis5.png`
const Pre1 = `${IMAGE_BASE_PATH}/ui/Film/ShatranjKeKhilari/Introduction/Preface/Small1.png`
const Pre11 = `${IMAGE_BASE_PATH}/ui/Film/ShatranjKeKhilari/Introduction/Preface/Full1.png`
const Pre12 = `${IMAGE_BASE_PATH}/ui/Film/ShatranjKeKhilari/Introduction/Preface/Full2.png`
const Pre13 = `${IMAGE_BASE_PATH}/ui/Film/ShatranjKeKhilari/Introduction/Preface/Full3.png`
const Pre2 = `${IMAGE_BASE_PATH}/ui/Film/ShatranjKeKhilari/Introduction/Preface/Small2.png`
const Pre3 = `${IMAGE_BASE_PATH}/ui/Film/ShatranjKeKhilari/Introduction/Preface/Small3.png`
const Dummy = `${IMAGE_BASE_PATH}/ui/Film/ShatranjKeKhilari/DummyImage/1.png`
const PreFull1 = `${IMAGE_BASE_PATH}/ui/Film/ShatranjKeKhilari/Introduction/Specification/Full1.png`
const PreFull2 = `${IMAGE_BASE_PATH}/ui/Film/ShatranjKeKhilari/Introduction/Specification/Full2.png`
const PreFull3 = `${IMAGE_BASE_PATH}/ui/Film/ShatranjKeKhilari/Introduction/Specification/Full3.png`
const PreFull4 = `${IMAGE_BASE_PATH}/ui/Film/ShatranjKeKhilari/Introduction/Specification/Full4.png`
const nagpurImg = `${IMAGE_BASE_PATH}/ui/PersonalityArtist/SayedHaiderRaza/Introduction/Education/nagpur_school.png`
const diplomaImg = `${IMAGE_BASE_PATH}/ui/PersonalityArtist/SayedHaiderRaza/Introduction/Education/diploma.png`
const frenchGovImg = `${IMAGE_BASE_PATH}/ui/PersonalityArtist/SayedHaiderRaza/Introduction/Education/french_gov.png`

// Get Personality Introduction data via API
export const introductionByAliasAPI = async (masterlistType, masterlist) => {
  const result = await api.get(`${APIS.BASE_URL}${masterlistType}/${masterlist}/${APIS.personality}`)
  if (result) {
    return result?.data?.data;
  } else {
    return [];
  }
};

// This function return complete Personality Introduction data
export const introductionByAlias = async (masterlistType, masterlist) => {
  if (isTransformationNotAllowed() === true) {
    const response = await getIntroductiondummydata(masterlist);
    return response;
  }else if (isTransformationNotAllowed() === false)  {
    const response = await introductionByAliasAPI(masterlistType, masterlist);
    try {
      if (response) {
        return response;
      } else {
        console.error("Invalid response data structure:", response);
        return [];
      }
    } catch (e) {
      console.error("Something is wrong", e);
    }
  }
};

export const getIntroductiondummydata = (winner) => {
  // switch (winner) {
  //   case "satyajit-ray":
  //     return satyajit_introduction_data;
  //   case "sayed-haider-raza":
  //     return sh_raza_introduction_data;
  //   case "suresh-jindal":
  //     return suresh_jindal_introduction_data;
  //   default:
  //     // Handle the default case if needed
  //     return satyajit_introduction_data;
  // }
  const introductionDataMap = {
    "satyajit-ray": satyajit_introduction_data,
    "sayed-haider-raza": sh_raza_introduction_data,
    "suresh-jindal": suresh_jindal_introduction_data,
    "javed-siddiqi": javed_siddiqi_introduction_data,
    "shama-zaidi": shama_zaidi_introduction_data,
    "birju-maharaj": birju_maharaj_introduction_data,
    "dulal-dutta": dulal_dutta_introduction_data,
    "soumendu-roy": soumendu_roy_introduction_data,
    "bansi-chandragupta": bansi_chandragupta_introduction_data,
    "nemai-ghosh": nemai_ghosh_introduction_data,
    "sandip-ray": sandip_ray_introduction_data,
    "sumatra-ghoshal": sumatra_ghoshal_introduction_data,
    "pablo-bartholomew": pablo_bartholomew_introduction_data,
    "saswati-sen": saswati_sen_introduction_data,
    "song-synopsis-booklet": song_synopsis_booklet_data,
    "art-heritage-annual": art_heritage_annual_data,
    "lucknow": lucknow_data,
    "pictorial-space": pictorial_space_annual_data,
    "raj-kapoor": raj_kapoor_introduction_data,
    "k.g-Subramanyan": kg_subramanym_introduction_data,
    "calcutta": calcutta_data,
    "amitabh-bachchan": amitabh_bachchan_introduction_data,
    "sanjeev-kumar": sanjeev_kumar_introduction_data,
    "shammi-kapoor": shammi_kapoor_introduction_data,
    // Add more cases as needed
  };
  // Check if the masterlist exists in the map, if not, use a default value
  const introductionData = introductionDataMap[winner] || [];
  return introductionData;
};

export const getEducationByAliasAPI = async (masterlistType, masterlist) => {
  const result = await api.get(`${APIS.BASE_URL}${masterlistType}/${masterlist}/${APIS.personalityEducation}`)
  if (result) {
    return result?.data?.data;
  } else {
    return [];
  }
};

// This function return education details of Personality for drawer
export const getEducationByAlias = async (masterlistType, masterlist) => {
  if (isTransformationNotAllowed() === true) {
  const response=getEducationdummydata(masterlist)
  return response;
  }else if (isTransformationNotAllowed() === false) {
    const response = await getEducationByAliasAPI(masterlistType, masterlist);
    try {
      if (response) {
        const educationData = response[0].find(
          (item) => item.key === "education"
        );

        if (educationData) {
          const transformedData = educationData.value;
          return transformedData;
        } else {
          console.error("Education data not found in the response:", response);
          return [];
        }
      } else {
        console.error("Invalid response data structure:", response);
        return [];
      }
    } catch (e) {
      console.error("Something is wrong", e);
    }
  }
};

export const getEducationdummydata = (winner) => {
  switch (winner) {
    case "satyajit-ray":
      return satyajit_education_data;
    case "sayed-haider-raza":
      return sh_raza_education_data;
    case "raj-kapoor":
      return raj_kapoor_education_data;
    case "k.g-Subramanyan":
      return kg_subramanym_education_data
    case "amitabh-bachchan":
      return amitabh_bachchan_education_data;
    default:
      // Handle the default case if needed
      return satyajit_education_data;
  }
};

export const getTeachingInfraByAliasAPI = async (masterlistType, masterlist) => {
  const result = await api.get(`${APIS.BASE_URL}${masterlistType}/${masterlist}/${APIS.personalityTeachingInfrastructure}`)
  if (result) {
    return result?.data?.data;
  } else {
    return [];
  }
};

// This function return teaching infra details of Personality for drawer
export const getTeachingInfraByAlias = async (masterlistType, masterlist) => {
  if (isTransformationNotAllowed() === true) {
    const response = getTeachingdummydata(masterlist);
    return response;
  }else if (isTransformationNotAllowed() === false) {
    const response = await getTeachingInfraByAliasAPI(masterlistType, masterlist);
    try {
      if (response) {
        const teachingInfraData = response[0].find(
          (item) => item.key === "teaching_infrastructure"
        );

        if (teachingInfraData) {
          const transformedData = teachingInfraData.value;

          const sortedData = [...transformedData].sort((a, b) => {
            // If one item is "Official Position" and the other is not, the "Official Position" should come first
            if (a.post === "Official Position" && b.post !== "Official Position") {
              return -1;
            } else if (a.post !== "Official Position" && b.post === "Official Position") {
              return 1;
            }

            // If one item is "Associated Institution" and the other is not, the "Associated Institution" should come after "Official Position"
            if (a.post === "Associated Institution" && b.post !== "Associated Institution") {
              return -1;
            } else if (a.post !== "Associated Institution" && b.post === "Associated Institution") {
              return 1;
            }

            // If one item is "Associated Movements" and the other is not, the "Associated Movements" should come after "Associated Institution"
            if (a.post === "Associated Movements" && b.post !== "Associated Movements") {
              return -1;
            } else if (a.post !== "Associated Movements" && b.post === "Associated Movements") {
              return 1;
            }

            if (a.display_date === 'NA') return 1; // Push N/A values to the end
            if (b.display_date === 'NA') return -1; // Push N/A values to the end
            return a.display_date.localeCompare(b.display_date);
          });

          return sortedData;
        } else {
          console.error(
            "Teaching Infrastructure data not found in the response:",
            response
          );
          return [];
        }
      } else {
        console.error("Invalid response data structure:", response);
        return [];
      }
    } catch (e) {
      console.error("Something is wrong", e);
    }
  }
};

export const getTeachingdummydata = (winner) => {
        const teachingDataMap = {
          "satyajit-ray": satyajit_teaching_data,
          "sayed-haider-raza": sh_raza_teaching_data,
          "raj-kapoor": raj_kapoor_teaching_data,
          "k.g-Subramanyan": kg_subramanym_teaching_data,
          "amitabh-bachchan":amitabh_bachchan_teaching_data,
          "shammi-kapoor":shammi_kapoor_teaching_data
        }
        const teachingData= teachingDataMap[winner] || satyajit_teaching_data;
        return teachingData
};

// This function return Personality Personalia drawer data
export const getPersonaliaByAlias = async (masterlistType, masterlist) => {
  if (isTransformationNotAllowed() === true) {
    const response = getPersonaliadummydata(masterlist);
    return response;
  }else if (isTransformationNotAllowed() === false) {
    const response = await introductionByAliasAPI(masterlistType, masterlist);
    try {
      if (response) {
        const keysToKeep = [
          "date_of_birth",
          "time_of_birth",
          "zodiac_sign",
          "date_of_death",
          "age",
          "nationality",
          "place_of_birth",
          "spouse",
          "marriageregistrationdate",
          "children",
          "parents",
          "grandparents",
          "ancestors",
          "siblings"
        ];

        const firstElement = response[0];
        const filteredData = keysToKeep.map((key) => {
          const nestedObject = firstElement.find((item) => item.key === key);
          return {
            key,
            value: nestedObject ? nestedObject.value : null,
            display: nestedObject ? nestedObject.display : null,
          };
        });

        return filteredData;
      } else {
        console.error("Invalid response data structure:", response);
        return [];
      }
    } catch (e) {
      console.error("Something is wrong", e);
    }
  }
};

export const getPersonaliadummydata = (winner) => {
      const personaliaDataMap = {
        "satyajit-ray": satyajit_personalia_data,
        "sayed-haider-raza": sh_raza_personalia_data,
        "raj-kapoor": raj_kapoor_personalia_data,
        "k.g-Subramanyan": kg_subramanym_personalia_data,
        "amitabh-bachchan":amitabh_bachchan_personalia_data,
        "shammi-kapoor":shammi_kapoor_personalia_data
      }
      const personaliaData= personaliaDataMap[winner] || satyajit_personalia_data;
      return personaliaData
};

export const getObjectOfFocusData = async (alias) => {
  if (alias === "satyajit-ray") {
    const obj = [
      {
        id: 1,
        imgPath: Banner3,
        title: "The Big City Poster",
        description:
          "Lorem ipsum dolor sit amet consetetur sadipscing elitr, sed diam...Read More",
        button1: "Satyajit Ray",
        button2: "1963",
        button3: "Indian Cinema",
        to: "/whats-new-page1",
        cdt: 0,
        event: 0,
        film: 0,
        idea: 1,
        institution: 1,
        location: 0,
        personality: 1,
        prices: 0,
        research_category: 0,
        song: 0,
        year: 1,
      },
    ];
    return obj;
  }
  if (alias === "sayed-haider-raza") {
    const obj = [
      {
        id: 1,
        imgPath: Banner2,
        title: "Carcassonne",
        description:
          "Carcassonne, painted by S.H. Raza in 1956, is a rendering of the French Citadel",
        button1: "S.H. Raza",
        button2: "1956",
        button3: "Indian Artist in France",
        to: "/whats-new-page",
        cdt: 0,
        event: 0,
        film: 0,
        idea: 1,
        institution: 0,
        location: 1,
        personality: 1,
        prices: 0,
        research_category: 0,
        song: 0,
        year: 2,
      },
    ];
    return obj;
  }
  if (alias === "shatranj-ke-khilari-1977") {
    const obj = [
      {
        id: 1,
        imgPath: SKK,
        title: "Carcassonne",
        description:
          "Carcassonne, painted by S.H. Raza in 1956, is a rendering of the French Citadel",
        button1: "Satyajit Ray",
        button2: "Shatranj Ke Khilari",
        button3: "1977",
        link: "/whats-new-page",
        cdt: 0,
        event: 0,
        film: 0,
        idea: 1,
        institution: 0,
        location: 1,
        personality: 1,
        prices: 0,
        research_category: 0,
        song: 0,
        year: 2,
      },
      {
        id: 2,
        imgPath: SKK2,
        title: "Carcassonne",
        description:
          "Carcassonne, painted by S.H. Raza in 1956, is a rendering of the French Citadel",
        button1: "Satyajit Ray",
        button2: "Shatranj Ke Khilari",
        button3: "1977",
        link: "/whats-new-page",
        cdt: 0,
        event: 0,
        film: 0,
        idea: 1,
        institution: 0,
        location: 1,
        personality: 1,
        prices: 0,
        research_category: 0,
        song: 0,
        year: 2,
      },
      {
        id: 3,
        imgPath: SKK3,
        title: "Carcassonne",
        description:
          "Carcassonne, painted by S.H. Raza in 1956, is a rendering of the French Citadel",
        button1: "Satyajit Ray",
        button2: "Shatranj Ke Khilari",
        button3: "1977",
        link: "/whats-new-page",
        cdt: 0,
        event: 0,
        film: 0,
        idea: 1,
        institution: 0,
        location: 1,
        personality: 1,
        prices: 0,
        research_category: 0,
        song: 0,
        year: 2,
      },
    ];
    return obj;
  }
};

export const getObjectOfFocusImageData = (alias) => {
  if (alias === "satyajit-ray") {
    const obj = [
      {
        id: 1,
        imgPath: Img,
        title: "004_SatyajitRay_Mahanagar_1963",
        date: "15 Augest 2021",
      },
      {
        id: 2,
        imgPath: Img,
        title: "004_SatyajitRay_Mahanagar_1963",
        date: "15 Augest 2021",
      },
      {
        id: 3,
        imgPath: Img,
        title: "004_SatyajitRay_Mahanagar_1963",
        date: "15 Augest 2021",
      },
      {
        id: 4,
        imgPath: Img,
        title: "004_SatyajitRay_Mahanagar_1963",
        date: "15 Augest 2021",
      },
      {
        id: 5,
        imgPath: Img,
        title: "004_SatyajitRay_Mahanagar_1963",
        date: "15 Augest 2021",
      },
      {
        id: 6,
        imgPath: Img,
        title: "004_SatyajitRay_Mahanagar_1963",
        date: "15 Augest 2021",
      },
    ];
    return obj;
  }
  if (alias === "sayed-haider-raza") {
    const obj = [
      {
        id: 1,
        imgPath: Img,
        title: "147_SHRaza_Bindu_1984",
        date: "15 Augest 2021",
      },
      {
        id: 2,
        imgPath: Img,
        title: "147_SHRaza_Bindu_1984",
        date: "15 Augest 2021",
      },
      {
        id: 3,
        imgPath: Img,
        title: "147_SHRaza_Bindu_1984",
        date: "15 Augest 2021",
      },
      {
        id: 4,
        imgPath: Img,
        title: "147_SHRaza_Bindu_1984",
        date: "15 Augest 2021",
      },
      {
        id: 5,
        imgPath: Img,
        title: "147_SHRaza_Bindu_1984",
        date: "15 Augest 2021",
      },
      {
        id: 6,
        imgPath: Img,
        title: "147_SHRaza_Bindu_1984",
        date: "15 Augest 2021",
      },
    ];
    return obj;
  }
  if (alias === "shatranj-ke-khilari-1977") {
    const obj = [
      {
        id: 1,
        imgPath: SKK,
        title: "Content Awaited",
        date: "Content Awaited",
      },
      {
        id: 2,
        imgPath: SKK2,
        title: "Content Awaited",
        date: "Content Awaited",
      },
      {
        id: 3,
        imgPath: SKK3,
        title: "Content Awaited",
        date: "Content Awaited",
      },
    ];
    return obj;
  }
};

export const getObjectOfFocusExploreData = () => {
  const dummyDataEnabled = process.env.REACT_APP_DUMMY_DATA || "";
  if (dummyDataEnabled.toLowerCase() === "yes") {
    const obj = [
      {
        id: 1,
        imgPath: Banner2,
        link1: "/whats-new-page/3",
        link2: "/sayed-haider-raza/introduction",
        link3: "#",
        link4: "#",
        title: "Carcassonne",
        button1: "S.H. Raza",
        button2: "1956",
        button3: "Indian Artist in France",
        description:
          "Carcassonne, painted by S.H. Raza in 1956, is a rendering of the French Citadel",
      },
      {
        id: 2,
        imgPath: Banner3,
        link1: "/whats-new-page/4",
        link2: "/satyajit-ray/introduction",
        link3: "/content-awaited",
        link4: "/content-awaited",
        title: "The Big City Poster",
        button1: "Satyajit Ray",
        button2: "1963",
        button3: "Indian Cinema",
        description:
          "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam… Read More",
      },
      {
        id: 3,
        imgPath: Banner4,
        link1: "/whats-new-page/5",
        link2: "/content-awaited",
        link3: "/content-awaited",
        link4: "/content-awaited",
        title: "Gandhiji Visiting PoliticalPrisoners At Dum Dum JailIn 1947",
        button1: "M.K. Gandhi",
        button2: "1960",
        button3: "Historical Epic",
        description:
          "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam… Read More",
      },
      {
        id: 4,
        imgPath: Banner5,
        link1: "/whats-new-page/6",
        link2: "/location/introduction",
        link3: "/content-awaited",
        link4: "/content-awaited",
        title: "Rumi Darwaaza",
        button1: "Lucknow",
        button2: "1784",
        button3: "Indian Architecure",
        description:
          "Rumi Darwaaza is an imposing gateway which was built by Nawab Asaf-Ud-Daula in 1784.",
      },
      {
        id: 7,
        imgPath: Banner6,
        link1: "/whats-new-page/7",
        link2: "/location/introduction",
        link3: "/content-awaited",
        link4: "/content-awaited",
        title: "The Swan Car",
        button1: "Calcutta",
        button2: "1910",
        button3: "Automobilia",
        description:
          "In 1910, a wealthy Scotsman, Robert Nicholl Matthewson, took a swan-shaped…",
      },
    ];
    return obj;
  } else {
    return [];
  }
};

export const getCollectionsExploreData = () => {
  const dummyDataEnabled = process.env.REACT_APP_DUMMY_DATA || "";
  if (dummyDataEnabled.toLowerCase() === "yes") {
    const obj = [
      {
        id: 1,
        imgPath: Banner5,
        link1: "/whats-new-page/6",
        link2: "/location/introduction",
        link3: "/content-awaited",
        link4: "/content-awaited",
        title: "Rumi Darwaaza",
        button1: "Lucknow",
        button2: "1784",
        button3: "Indian Architecure",
        description:
          "Rumi Darwaaza is an imposing gateway which was built by Nawab Asaf-Ud-Daula in 1784.",
      },
      {
        id: 2,
        imgPath: Banner6,
        link1: "/whats-new-page/7",
        link2: "/location/introduction",
        link3: "/content-awaited",
        link4: "/content-awaited",
        title: "The Swan Car",
        button1: "Calcutta",
        button2: "1910",
        button3: "Automobilia",
        description:
          "In 1910, a wealthy Scotsman, Robert Nicholl Matthewson, took a swan-shaped…",
      },
    ];
    return obj;
  } else {
    return [];
  }
};

export const getArtHeritagePublicationIntroData = async () => {
  return IntroductionData;
};
export const getSongsSynopsisBookletDataAPI = async () => {
  return [];
};
export const getSongsSynopsisBookletData = async (masterlist) => {
  if (isTransformationNotAllowed() === true) {
    const response = await getSongsSynopsisBookletDataDummy(masterlist);
    return response;
  } else if (isTransformationNotAllowed() === false) {
    // To do after receving real data plz remove it
    const response1 = await await getSongsSynopsisBookletDataAPI(masterlist);
    return response1;

  }
};
export const getSongsSynopsisBookletDataDummy = (masterlist) => {
  return songsSynopsisBookletData;
};

// To do after skk integration done remove this function
export const getPrefaceBannerData = async () => {
  const secondSliderData = [
    {
      id: 1,
      imgPath: Pre4,
      fullImage: PreFull1,
      desc: "Shatranj Ke Khilari | 1977 | Photographic stills mounted on lobby card | CINE.phl",
      author: "Satyajit Ray",
      desc0: "Content Awaited",
      filmTitle: " Shatranj Ke Khilari (1977)",
      title: "Lobby Card, 1977_",
      document_type: "CINE.lby",
      imgNum: "0000054",
      publicity_designer: "Satyajit Ray",
      printer: "Delight Offset Works",
      art_director: "Bansi Chandragupta",
      still_photographer: "Technica",
      date_of_artwork: "1977",
      content: "Content Awaited",
      description1: "",
    },
    {
      id: 2,
      imgPath: Pre5,
      fullImage: PreFull2,
      desc: "Shatranj Ke Khilari | 1977 | Costumes, Maquettes & Props | CINE.maq",
      author: "Shama Zaidi",
      desc0: "Content Awaited",
      filmTitle: " Shatranj Ke Khilari (1977)",
      title: "Silk from Benaras (COS), 1977_",
      document_type: "CINE.maq",
      imgNum: "0000054",
      costume_designer: "Shama Zaidi",
      printer: "Delight Offset Works",
      art_director: "Content Awaited",
      still_photographer: "Content Awaited",
      date_of_artwork: "1977",
      content: "Content Awaited",
      description1: "",
    },
    {
      id: 3,
      imgPath: Pre6,
      fullImage: PreFull3,
      desc: "Peter Strausfeld | 1977 | Showcard | CINE.shw",
      author: "Shama Zaidi",
      desc0: "Content Awaited",
      filmTitle: " Shatranj Ke Khilari (1977)",
      title: "Silk from Benaras (COS), 1977_",
      document_type: "CINE.maq",
      imgNum: "0000054",
      publicity_designer: "Peter Strausfeld",
      printer: "Content Awaited",
      art_director: "Content Awaited",
      still_photographer: "Content Awaited",
      date_of_artwork: "1977",
      content: "Content Awaited",
      description1: "",
    },
    {
      id: 4,
      imgPath: Pre7,
      fullImage: PreFull4,
      desc: "Shatranj Ke Khilari | 1977 | Photographic Still Mounted on Lobby Card | CINE.phl",
      author: "Shama Zaidi",
      desc0: "Content Awaited",
      filmTitle: " Shatranj Ke Khilari (1977)",
      title: "Silk from Benaras (COS), 1977_",
      document_type: "CINE.maq",
      imgNum: "0000054",
      publicity_designer: "Satyajit Ray",
      printer: "Delight Offset Works",
      art_director: "Content Awaited",
      still_photographer: "Content Awaited",
      date_of_artwork: "1977",
      content: "Content Awaited",
      description1: "",
    },
  ];
  return secondSliderData;
};

// To do after skk integration done remove this function
export const getSliderData = async () => {
  const sliderData = [
    {
      id: 1,
      imgPath: Pre1,
      fullImg: Pre11,
      desc: "Shatranj Ke Khilari | 1977 | Original release poster | CINE.pos",
      desc0: "Content Awaited",
      filmTitle: " Shatranj Ke Khilari (1977)",
      author: "Satyajit Ray",
      title: "Original Release Poster, 1977_",
      document_type: "CINE.pos",
      imgNum: "0000051",
      poster_designer: "Satyajit Ray",
      art_director: "Satyajit Ray",
      still_photographer: "Technica",
      printer: "Delight Offset Works",
      date_of_artwork: "1977",
      content: "Content Awaited",
      description1: "",
    },
    {
      id: 2,
      imgPath: Pre2,
      fullImg: Pre12,
      desc: "Shatranj Ke Khilari | 1977 | Original release poster | CINE.pos",
      desc0: "Content Awaited",
      filmTitle: " Shatranj Ke Khilari (1977)",
      author: "Satyajit Ray",
      title: "Original Release Poster, 1977_",
      document_type: "CINE.pos",
      imgNum: "0000050",
      poster_designer: "Satyajit Ray",
      art_director: "Satyajit Ray",
      still_photographer: "Technica",
      printer: "Delight Offset Works",
      date_of_artwork: "1977",
      content: "Content Awaited ",
      description1: "",
    },
    {
      id: 3,
      imgPath: Pre3,
      fullImg: Pre13,
      desc: "Shatranj Ke Khilari | 1977 | Original release poster | CINE.pos",
      desc0: "Content Awaited",
      filmTitle: " Shatranj Ke Khilari (1977)",
      author: "Satyajit Ray",
      title: "Original Release Poster, 1977_",
      document_type: "CINE.pos",
      imgNum: "0000052",
      poster_designer: "Satyajit Ray",
      art_director: "Satyajit Ray",
      still_photographer: "Technica",
      printer: "Delight Offset Works",
      date_of_artwork: "1977",
      // content: "Content -3",
      content:
        "Zehra Tyabji designed letterheads, handlooms for me, all the stationery and so on. Zehra did these designs, but of course the final say in a Ray film was always Mr. Ray. Because he himself was a phenomenal designer. Normally in his film he did everything. So he conveyed the ideas to Zehra, she came with the sketches and designs and Ray approved the final poster",
      description1:
        "Zehra Tyabji designed letterheads, handlooms for me, all the stationery and so on. Zehra did these designs, but of course the final say in a Ray film was always Mr. Ray. Because he himself was a phenomenal designer. Normally in his film he did everything. So he conveyed the ideas to Zehra, she came with the sketches and designs and Ray approved the final poster.",
    },
  ];
  return sliderData;
};

// To do after skk integration done remove this function
export const getSpecificationData = async () => {
  const data = [
    {
      certification: "U",
      languages: "Hindi, Urdu, English",
      duration_mins: "115",
      color_type: "Eastman Color",
      lab: "Gemini Color Lab, Madras",
      gauge: "35 mm",
      length_of_reels: "3434.18 m",
      number_of_reels: 14,
      shooting_period: "December 1976 - June 1977",
      studios: "Indrapuri, Culcutta; Rajkamal Kalamandir, Bombay",
      on_location: "Lucknow and Jaipur",
      certificate_number: 84526,
      date_of_issue: "03/10/1977",
      date_of_expiry: "02/10/1987",
      image: [
        {
          image: Synopsis1,
          title:
            "Shatranj Ke Khilari | 1977 | Original release poster | CINE.pos",
        },
        { image: Dummy, title: "Content Awaited" },
      ],
    },
  ];
  return data;
};

export const getSidebarContentData = async (aliasAPI) => {
  const sh_raza_data = {
    sections: [
      {
        to: `/${aliasAPI}/introduction`,
        title: "introduction",
        text: explore.INTRODUCTION,
      },
      {
        to: `/${aliasAPI}/body-of-work`,
        title: "body-of-work",
        text: explore.BODY_OF_WORK,
      },
      {
        to: `/${aliasAPI}/economicsart`,
        title: "economicsart",
        text: explore.ECONOMICS_OF_ARTS,
      },
      {
        to: `/${aliasAPI}/integrated-timeline`,
        title: "integrated-timeline",
        text: explore.INTEGRATED_TIMELINE,
      },
      {
        to: `/${aliasAPI}/research-centre`,
        title: "research-centre",
        text: explore.RESEARCH_CENTRE,
      },
    ],
    hMenu: [
      {
        link: "/categories/fine-arts",
        title: "FineArts",
      },
      {
        link: "/categories/arts-personality",
        title: "ARTS.Personality",
      },
    ],
    oSections: [
      {
        to: `/${aliasAPI}/1`,
        title: "",
        text: explore.POSTS,
      },
      {
        to: `/${aliasAPI}/2`,
        title: "",
        text: explore.INSIGHTS,
      },
    ],
    artistDetails: {
      name: "Sayed Haider Raza",
      date_of_birth: "22 Feb 1922",
      date_of_death: "23 Jul 2016",
    },
    backNavigation: `/${aliasAPI}`,
  };

  switch (aliasAPI) {
    case "sh-raza":
      return sh_raza_data;
    default:
      // Handle the default case if needed
      return sh_raza_data;
  }
};
export const getIntroData = async (aliasAPI) => {
  const Intro_SH_Raja = [
    {
      alias: "sh-raza",
      place_of_birth: "Kakkaiya, Madhya Pradesh",
      official_website: null,
      zodiac_sign: "Pisces",
      time_of_birth: null,
      date_of_birth: "22 February 1922",
      date_of_death: "23 July 2016",
      description: `Sayed Haider Raza is one of the most prominent and groundbreaking Indian painters of his generation. During his training in France, Raza experimented with a variety of Modernist styles `,
      id: null,
      image: null,
      name: "Sayed Haider Raza",
      nationality: "Indian",
      age: 94,
      marriageregistrationdate: null,
      education: [
        {
          year: "1939",
          education: "Nagpur School of Art, Nagpur",
          image: nagpurImg,
        },
        {
          year: "1943 - 1947",
          education:
            "Diploma of Paintings and Drawings from Sir J.J. School of Art, Mumbai, India",
          image: diplomaImg,
        },
        {
          year: "1950 - 1953",
          education:
            "Scholarship from French Government, Ecole Nationale des Beaux-Arts, Paris, France",
          image: frenchGovImg,
        },
      ],
      spouse: [
        {
          name: "Janine Mongillat",
          date_of_birth: null,
          date_of_death: null,
          image: null,
        },
      ],
      children: [],
      parents: [
        {
          name: "Sayed Mohammed Razi",
          image: null,
        },
        {
          name: "Tahira Begum",
        },
      ],
      grandParents: [],
      grandfather: [],
      grandmother: [],
      ancestors: [],
      awards: [],
      teaching_infrastructure: [],
    },
  ];
  return aliasAPI === "sayed-haider-raza" ? Intro_SH_Raja : [];
};
export const getTeachingInfraData = async (aliasAPI) => {
  const Teaching_Infra_SH_Raza = [
    {
      position: "Associated Institution",
      position_history: [
        {
          year: 1962,
          desc: "Visiting Lecturer at the Art Department University of California, Berkeley, USA",
        },
        {
          year: 1961,
          desc: "Fellowship, Lalit Kala Akademi, New Delhi, India",
        },
      ],
    },
  ];
  return aliasAPI === "sayed-haider-raza" ? Teaching_Infra_SH_Raza : [];
};
