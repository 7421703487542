import React, { useState, useEffect } from "react";
import { ArrowUpIcon } from "@chakra-ui/icons";
import "../../styles.css";
import { Stack } from "@chakra-ui/react";

const ScrollToTop = ({className="top-to-btm"}) => {
  const [showTopBtn, setShowTopBtn] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setShowTopBtn(window.scrollY > 400);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <Stack className={className} zIndex={"999"} >
      {showTopBtn && (
        <ArrowUpIcon className="icon-position icon-style" onClick={goToTop} />
      )}
    </Stack>
  );
};

export default ScrollToTop;
