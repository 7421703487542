import {
  Box,
  Stack,
  Container,
  useDisclosure,
  Drawer,
  DrawerContent,
  useColorModeValue,
} from "@chakra-ui/react";

//Core file
import React, { useState } from "react";
import { IoMdCalendar } from "@react-icons/all-files/io/IoMdCalendar";
import { RiLayoutGridFill } from "@react-icons/all-files/ri/RiLayoutGridFill";
import { MdMap } from "@react-icons/all-files/md/MdMap";
import { MdHomeFilled } from "react-icons/md";
import { useNavigate } from "react-router-dom";

//Service file
import { CopyPasteService } from "../../../../services/CopyPaste";

//Component file
import LeftVmenu from "../../../../FunctionalComponents/LeftVmenu";
import Chronology from "../Chronology";

export default function RCChronolgyTimeline() {
  return (
    <>
      <SidebarWithHeader>
        <Stack width={"100%"} flexDirection={"row"}>
          <Stack
            flexDirection={"column"}
            width={"100%"}
            px="0px"
            onCopy={(e) => CopyPasteService(e, `${window.location.href}/1`)}
          >
            <Container maxW={"100%"} px="0px">
              <Chronology />
            </Container>
          </Stack>
        </Stack>
      </SidebarWithHeader>
    </>
  );
}

const SidebarWithHeader = ({ children }) => {
  const { isOpen, onClose } = useDisclosure();
  return (
    <Container maxW="auto" px="0px">
      <Box minH="100vh" bg={useColorModeValue("gray.100", "gray.900")}>
        <SidebarContent
          onClose={() => onClose}
          display={{ base: "none", md: "block" }}
        />
        <Drawer
          autoFocus={false}
          isOpen={isOpen}
          placement="left"
          onClose={onClose}
          returnFocusOnClose={false}
          onOverlayClick={onClose}
          size="full"
        >
          <DrawerContent>
            <SidebarContent onClose={onClose} />
          </DrawerContent>
        </Drawer>
        <Box ml={{ base: 0, md: 14 }} bg="white">
          {children}
        </Box>
      </Box>
    </Container>
  );
};

const SidebarContent = ({ onClose, display, ...rest }) => {
  const [error, setError] = useState(false);
  const toggleModal = () => {
    setError(!error);
  };
  const LeftVmenuData = [
    {
      link: "/",
      id: "homeicon",
      icon: MdHomeFilled,
    },
    {
      link: "/explore",
      id: "exploreicon",
      icon: RiLayoutGridFill,
    },
    {
      link: "/calendar",
      id: "calendaricon",
      icon: IoMdCalendar,
    },
    {
      link: "/map",
      id: "mapicon",
      icon: MdMap,
    },
  ];
  const navigate = useNavigate();
  const handleNavigation = () => {
    navigate(-1);
  };
  return (
    <LeftVmenu
      handleNavigation={handleNavigation}
      backArrow={true}
      display={display}
      onClose={onClose}
      toggleModal={toggleModal}
      LeftVmenuData={LeftVmenuData}
    />
  );
};
