import {
  Stack,
  Container,
} from "@chakra-ui/react";

//Core files
import React, { useEffect, useState } from "react";

// Service files
import { CopyPasteService } from "../../services/CopyPaste";

//Component files
import AwardsHonours from "./EconomicsOfCinema/AwardsHonours";
import BoxOffice from "./EconomicsOfCinema/BoxOffice";

//Language files
import { explore } from "../../constants/constants";
import TabMain from "../../FunctionalComponents/TabComponents/TabMain";
import Screenings from "../../components/Screenings/Screenings";
import { useLocation, useParams } from "react-router-dom";


export const EconomicsOfCinemaBody = ({masterlist, masterlistType}) => {
  const [tabPanelData, setTabPanelData] = useState([]);
  const [screeningCount,setScreeningCount]=useState(0);
  const [awardCount,setAwardCount]=useState(0);
  const {tabValue}=useParams();
  const [attr, setAttr] = useState(0)
  const pathToIndex = {
    'screening': 0,
    'awards-honours': 1,
    'box-office': 2,

  };
  const location = useLocation();  
  useEffect(() => {
    setAttr(pathToIndex[tabValue] ?? 0);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, attr]);
  function formatData() {
    return [
      {
        name: `${explore.SCREENINGS} ${screeningCount >0 ? `(${screeningCount})`:''}`,          
        component: (
          <Screenings
                CopyPasteService={CopyPasteService}
                masterlistType={masterlistType}
                masterlist={masterlist}
                setScreeningCount={setScreeningCount}
              />
        ),
        link:`/${masterlistType}/${masterlist}/agraphy/economics-of-cinema/screening`

      },
      {
        name: `${explore.AWARDS_AND_HONOURS} ${awardCount >0 ? `(${awardCount})`:''}`,          
        component: (
          <AwardsHonours
                CopyPasteService={CopyPasteService}
                masterlistType={masterlistType}
                masterlist={masterlist}
                setAwardCount={setAwardCount}

              />
        ),
        link:`/${masterlistType}/${masterlist}/agraphy/economics-of-cinema/awards-honours`

      },
      {
        name: `${explore.BOX_OFFICE}`,
        component: <BoxOffice masterlist={masterlist} />,
        link:`/${masterlistType}/${masterlist}/agraphy/economics-of-cinema/box-office`

      },
    ];
  }
  useEffect(() => {
    if (masterlist) {
      setTabPanelData(formatData());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [masterlist,screeningCount,awardCount]);
  return (
    <>
    <Stack height={"100%"}>
      <Container maxW="auto" px={{ base: "4", md: "0" }} mx={"0"}>
        <Stack px={{ base: "0", md: "55px" }}>
          {tabPanelData && tabPanelData.length > 0 && (
            <TabMain paddingTop="10px" gap="50px" width={"1080px"} cla pos={"fixed"} attr={attr} setAttr={setAttr}
            panelTop={{ base: "65px", sm: "75px", md: "65px" }} topTogleIcon="25px" listId={"listgridbutton6"}  data={tabPanelData} />
          )}
        </Stack>
      </Container>
    </Stack>
    </>
  );
};
