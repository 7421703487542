import { Box, HStack, Input, VStack} from '@chakra-ui/react';
import React, { forwardRef, useRef, useState } from 'react'
import * as getSearchResultData from "../../services/Search";
import SmallBold from '../../Theme/Typography/SmallBold';
import Small from '../../Theme/Typography/Small';
import { RiArrowRightFill } from "react-icons/ri";
import { common } from '../../constants/constants';

export const SearchTags = forwardRef(({ handleSearch, naviagateSearch, showSuggestion, setShowSuggestion,inputValue, setInputValue }, ref) => {
  const [tags, setTags] = useState([]);
    const [suggestions, setSuggestions] = useState([]);
    const [keywordPresent,setKeywordPresent]=useState(false)
    const inputRef = useRef(null);
    const handleInputChange = (event) => {
      const value = event.target.value;
    setInputValue(value);
    handleSearch(value);
      if (value.trim() !== "") {
        fetchSuggestions(value);

      } else {
        setSuggestions([]);
        setShowSuggestion(false)

      }
    };

    const fetchSuggestions = async (query) => {
      try {
        const result = await getSearchResultData.getSuggestionData(query);
        if (result && result?.data) {
          // For future use
          // const cleanedData = result.data.map((item) =>
          //   item.replace(/<\/?em>/g, "")
          // );
          setSuggestions(result?.data);
          setShowSuggestion(true);
          const hasExactSearchFalse = result?.data.some(item => item.exactSearch === false);    
          if (hasExactSearchFalse) {
              setKeywordPresent(true);
          }else{
            setKeywordPresent(false)
          }
        }
      } catch (error) {
        console.error("Error fetching suggestions:", error);
      }
    };
  const handleKeyDown = (event) => {
    if (event.key === "Enter" && inputValue.trim() !== '') {
      setTags([...tags, inputValue.trim()]);
      naviagateSearch(inputValue)
      setShowSuggestion(false)
      setSuggestions([]);
    }
  };
  const handleAutosuggest = (suggestion) => {
    setInputValue(suggestion);
    handleSearch(suggestion);
    setSuggestions([]);
    naviagateSearch(suggestion);
  };
  const handleClick = () => {
    if (inputRef.current) {
      inputRef.current.select();
    }
  };
  return (
    <Box>
      <Input
      width={{ base: "350px", md: "298px" }} 
      outlineColor={{base:"transparent",md:""}}
      border={{base:"none",md:"1px"}}
      borderColor={{base:"none",md:"#E2E8F0"}}
      height={{base:"40px",md:"34px"}}
        placeholder="Search..."
        _placeholder={{ color: "#6d6d6d", fontSize:"12px", lineHeight:"20px" }} 
        value={inputValue}
        ref={inputRef}
        onClick={handleClick}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
      />
       {showSuggestion && suggestions.length > 0 && (
        <VStack
          width={{base:"100%",md:"398px"}}
          ref={ref}
          pos={"absolute"}
          top={{base:"35px",md:"47px"}}
          border={"1px"}
          borderColor={"color.lightgray4"}
          borderRadius={"5px"}
          right={0}
          className="searchscrollbar"
          height={"320px"}
              overflowY={"scroll"}
              overflowX={"hidden"}
          bg="white"
          align="stretch"
          spacing={1}
        >
          <Box>
          {suggestions.map((suggestion, index) => (
            <>
            {suggestion?.exactSearch &&<HStack
              pl={{base:"4",md:"2"}}
              pr={"2"}
              py={1}
              key={index}
              spacing={2}
              justifyContent={"space-between"}
              _hover={{ bg: "gray.100" }}
              cursor="pointer"
              onClick={()=>handleAutosuggest(suggestion?.title)}
            >
              <Small width={{base:"100%",md:"255px"}}>{suggestion?.displayTitle}</Small>
              <RiArrowRightFill fontSize={"20px"} color="#035DA1" />
            </HStack>}
            </>
          ))}
          </Box>
         <Box>
            <>
            {keywordPresent  &&
              <Box bg="white" borderBottom={{base:"0px",md:"1px"}} borderColor={{base:'#efefef',md:"#efefef"}} width={{base:"100%",md:"390px"}}>
                <SmallBold px={{base:"4",md:"2"}} py="2">{common.DID_YOU_MEAN}</SmallBold>
                </Box>
            }
            </>
          {suggestions.map((suggestion, index) => (
            <>
            {!suggestion?.exactSearch &&
            <>
            <HStack
              pl={{base:"4",md:"2"}}
              pr={"2"}
              py={1}
              key={index}
              spacing={2}
              justifyContent={"space-between"}
              _hover={{ bg: "gray.100" }}
              cursor="pointer"
              onClick={()=>handleAutosuggest(suggestion?.title)}
            >
              <Small width={{base:"100%",md:"255px"}}>{suggestion?.displayTitle}</Small>
              <RiArrowRightFill fontSize={"20px"} color="#035DA1" />
            </HStack>
            </>
            }
            </>
          ))}
          </Box>
        </VStack>
      )}
      {/* <Wrap top="10px" left={"12px"} pos={"absolute"}>
        {tags.map((tag, index) => (
          <WrapItem key={index}>
            <Tag borderRadius="full" variant="solid" colorScheme="blue">
              <TagLabel>{tag}</TagLabel>
              <TagCloseButton onClick={() => handleTagClose(tag)} />
            </Tag>
          </WrapItem>
        ))}
      </Wrap> */}
    </Box>
  );
})