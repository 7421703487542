import {
  Box,
  Container,
  Stack,
  Tab,
  TabList,
  Tabs,
  Text,
} from "@chakra-ui/react";

//Core file
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

//Component file
import Layout from "../../../../components/sections/SidebarLayout";

//Language file
import {  research_category } from "../../../../constants/constants";
import { getIntroductionByAlias } from "../../../../services/ResearchCategoryDetails";
import Posts from "../../../../FunctionalComponents/Posts/Posts";

function RCPosts() {
  const [rcIntroduction, setRCIntroduction] = useState([]);
  const [postsCount, setPostsCount] = useState(0);
  const { alias } = useParams();
  const currentEndpoint = window.location.pathname;
  const parts = currentEndpoint.split("/");
  const masterlistType = parts[1];
  const navigate = useNavigate();
  const url = currentEndpoint.lastIndexOf("/");
  const mainUrl = window.location.pathname.slice(0, url);
  useEffect(() => {
    const getData = async () => {
      try {
        const result = await getIntroductionByAlias("", alias);
        if (result) {
          setRCIntroduction(result?.data[0]);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    getData();
  }, [alias]);
  return (
    <Layout>
      <Box
          position={"fixed"}
          px={{ base: "4", md: "16" }}
          py={{ base: "4", md: "8" }}
          zIndex={999}
          bg="white"
          width={"1344px"}
        >
          <Tabs variant={"unstyled"} display={"flex"} align="end" pos={"none"} alignItems={"center"} justifyContent={"space-between"} defaultIndex={-1}>
            <Box>
            <Text
                  pos={"absolute"}
                  top={"23px"}
                  borderBottom={"4px"}
                  borderBottomColor={"black"}
                  textAlign={"left"}
                  width={{ base: "100%", md: "max-content" }}
                  fontSize={{ base: "22px", md: "22px" }}
                  fontWeight={700}
                  cursor={"pointer"}
                  onClick={() => navigate(`${mainUrl}/scrollToIntroduction`)}
                >
                  {rcIntroduction && rcIntroduction?.find((nestedItem) => nestedItem?.key === "name")?.value}
                </Text>
            </Box>
            <TabList gap={"25px"}>
              <Tab
                onClick={() => navigate(`${mainUrl}/Introduction`)}
                p="0px"
                fontSize={"16"}
                fontWeight={600}
                _selected={{ borderBottom: "2px" }}
              >
                {research_category.INTRODUCTION_TAB_TITLE}
              </Tab>
              <Tab
                onClick={() => navigate(`${mainUrl}/scrollToChronology`)}
                p="0px"
                fontSize={"16"}
                fontWeight={600}
                _selected={{ borderBottom: "2px" }}
              >
                {research_category.CHRONOLOGY_TAB_TITLE}
              </Tab>
              <Tab
                onClick={() => navigate(`${mainUrl}/scrollToMasterlists`)}
                p="0px"
                fontSize={"16"}
                fontWeight={600}
                _selected={{ borderBottom: "2px" }}
              >
                {research_category.MASTERLISTS_TAB_TITLE}
              </Tab>
              <Tab
                onClick={() => navigate(`${mainUrl}/scrollToPosts`)}
                p="0px"
                fontSize={"16"}
                borderBottom="2px"
                fontWeight={600}
                _selected={{ borderBottom: "2px" }}
              >
               {postsCount>0 ? `${research_category.POSTS_TAB_TITLE} (${postsCount})`:`${research_category.POSTS_TAB_TITLE} `}
               </Tab>
               <Tab
                onClick={() => navigate(`${mainUrl}/scrollToInsights`)}
                p="0px"
                fontSize={"16"}
                fontWeight={600}
                _selected={{ borderBottom: "2px" }}
              >
                {research_category.INSIGHTS_TAB_TITLE}
              </Tab>
              <Tab
                onClick={() => navigate(`${mainUrl}/scrollToResearchCentre`)}
                p="0px"
                fontSize={"16"}
                fontWeight={600}
                _selected={{ borderBottom: "2px" }}
              >
                {research_category.RESEARCH_CENTRE_TAB_TITLE}
              </Tab>
            </TabList>
          </Tabs>
        </Box>
        <>
        <Stack  height={"100%"} background={"white"}>
          <Container
            maxW="auto"
            pr="0px"
            pl="0px"
            height={"100%"}
            marginTop={{ base: "10", md: "0" }}
          >
        <Posts masterlistType={masterlistType} masterlist={alias} setPostsCount={setPostsCount}/>
        </Container>
        </Stack>
        </>
    </Layout>
  );
}
export default RCPosts;
