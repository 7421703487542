import React from 'react'
import { WebpageComingSoon } from '../../../../CommonComponents/WebpageComingSoon'

export default function ArtCriticismMediaReportage() {
  return (
    <>
    <WebpageComingSoon/>
    </>
      )
}
