import { Box, HStack, Image, Stack, Text } from '@chakra-ui/react'
import React from 'react'
import { FilterButton } from '../../../../../../CommonComponents/FilterButton'
import InfiniteScroll from 'react-infinite-scroll-component'
import NoMoreToLoad from '../../../../../../CommonComponents/NoMoreToLoad'
import TimeLine from '../../../../../../FunctionalComponents/TimeLine/TimeLine'
import TimelineWithSignatureImage from '../../../../../../FunctionalComponents/TimeLine/TimelineWithSignatureImage'
import { common } from '../../../../../../constants/constants'
import { FilterBox } from '../../../../../../FunctionalComponents/Filter/FilterBox'
import { TbFoldersOff } from 'react-icons/tb'
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;
const loaderImg = `${IMAGE_BASE_PATH}/ui/Chevron/loader.gif`;

export default function TabularView({
    loading,
    showFilterBox,
    masterlistType,
    handleFilterClick,
    data,
    fetchMoreData,
    hasMore,
    setShowFilterBox,
    setApply,
    handleCheckboxChange,
    handleSearch,
    filterOptions,
    filteroptionData,
    setFilterOptions,
    setSearchTerm,
    filterCount,
    searchTerm,
    handleReset,
    facets,
    explore,
    handleScreeningData

}) {
  return (
    <>
      {!loading ? (
        <>
          <Stack flexDirection={"row-reverse"} justifyContent="space-between">
            {!showFilterBox && (
              <FilterButton onClick={handleFilterClick} marginRight={"-62px"} />
            )}
            <Stack
              flexDirection={{ base: "column", md: "row" }}
              justifyContent={"space-between"}
              marginTop={"0px !important"}
              py={{ base: "5px", md: "10px" }}
              width={"100%"}
              minHeight={"calc(100vh - 150px)"}
            >
              <HStack flexDirection={"row"} alignItems={"flex-start"} px="0px" width={{ base: "100%", md: "1035px" }}>
                <>
                  {data && data?.length > 0 ?
                    <InfiniteScroll
                      dataLength={data?.length}
                      next={fetchMoreData}
                      hasMore={hasMore}
                      loader={<Box textAlign="center" marginLeft={"50%"}>
                        <Stack marginTop={"20px"}>
                          <img src={loaderImg} width={"100px"} height={"100px"} alt="Loading" />
                        </Stack>
                      </Box>}
                      endMessage={<NoMoreToLoad/>}
                    >
                      <TimeLine
                        data={data}
                        textColor={"blue"}
                        cursor={"pointer"}
                        handleImageClick={handleScreeningData}
                        component={TimelineWithSignatureImage}
                      />
                    </InfiniteScroll>
                    : (
                      <HStack w={"100%"}>
                        <Box
                          display={"flex"}
                          alignItems={"center"}
                          w={"inherit"}
                          justifyContent={"right"}
                          gap={4}
                        >
                          <TbFoldersOff size={40} />
                          <Text
                            py={"40"}
                            fontSize={"30px"}
                            fontWeight="700"
                            textAlign="center"
                            display={"flex"}
                            alignItems={"center"}
                          >
                            {common.NO_DATA}
                          </Text>
                        </Box>
                      </HStack>
                    )}
                </>
              </HStack>
             
              <HStack
                marginLeft={"4"}
                marginRight={"-62px!important"}
                alignItems={"flex-start"}
              >
                {showFilterBox && (
                  <FilterBox
                  setShowFilterBox={setShowFilterBox}
                  setApply={setApply}
                  handleCheckboxChange={handleCheckboxChange}
                  handleSearch={handleSearch}
                  filterOptions={filterOptions}
                  filteroptionData={filteroptionData}
                  setFilterOptions={setFilterOptions}
                  setSearchTerm={setSearchTerm}
                  filterCount={filterCount}
                  searchTerm={searchTerm}
                  handleReset={handleReset}
                  className={"box-filter-film"}
                />
                )}
              </HStack>
            </Stack>
          </Stack>
        </>
      ) : (
        <Stack
          flexDirection={"row-reverse"}
          justifyContent="space-between"
        >
          {!showFilterBox && (
            <FilterButton onClick={handleFilterClick} marginRight={"-55px"} />
          )}
          <Stack
            flexDirection={"row"}
            justifyContent={"space-between"}
            marginLeft={"24px !important"}
            marginTop={"0px !important"}
            width={"100%"}
          >
            <HStack flexDirection={"column"} className="col-8">
            <Image src={loaderImg} width={"100px"} height={"100px"} alt="Loading" />
            </HStack>
            <HStack
              marginLeft={"4"}
              marginRight={"-55px!important"}
              alignItems={"flex-start"}
            >
              {showFilterBox && (
                <FilterBox
                setShowFilterBox={setShowFilterBox}
                setApply={setApply}
                handleCheckboxChange={handleCheckboxChange}
                handleSearch={handleSearch}
                filterOptions={filterOptions}
                filteroptionData={filteroptionData}
                setFilterOptions={setFilterOptions}
                setSearchTerm={setSearchTerm}
                filterCount={filterCount}
                searchTerm={searchTerm}
                handleReset={handleReset}
                className={"box-filter-film"}
              />
              )}
            </HStack>
          </Stack>
        </Stack>
      )}
    </>
  )
}
