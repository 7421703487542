import {
  Container,
  HStack,
  Stack,
  Text,
} from "@chakra-ui/react";

//Core files
import React, { useEffect, useState } from "react";
import { TbFoldersOff } from "react-icons/tb";
import { useNavigate, useParams } from "react-router-dom";

//Service files
import { CopyPasteService } from "../../../../services/CopyPaste";
import * as getBibliographyData from "../../../../services/Bibliography";
import { isTransformationNotAllowed } from "../../../../util/isTranformationAllowed";

//Component files
import { FilterButton } from "../../../../CommonComponents/FilterButton";
import TimeLine from "../../../../FunctionalComponents/TimeLine/TimeLine";
import TimelineWithText from "../../../../FunctionalComponents/TimeLine/TimelineWithText";

//Language files
import { common, explore } from "../../../../constants/constants";
import DrawerFooter from "../../../../FunctionalComponents/CommonDrawer/ArchiveDrawerSection/DrawerFooter";
import DrawerBody from "../../../../FunctionalComponents/CommonDrawer/ArchiveDrawerSection/DrawerBody";
import DrawerHeader from "../../../../FunctionalComponents/CommonDrawer/ArchiveDrawerSection/DrawerHeader";
import CommonDrawer from "../../../../FunctionalComponents/CommonDrawer/CommonDrawer";
import { FilterBox } from "../../../../FunctionalComponents/Filter/FilterBox";

function TabularView({
  handleBookClick,
  contentClicked,
  scanClicked,
  setContentClicked,
  setScanClicked,
  openDrawer,
  setOpenDrawer,
  tableName,
  masterlist,
}) {
  const btnRef = React.useRef();
  const [bibliographyObject, setBibliographyObject] = React.useState();
  const [showFilterBox, setShowFilterBox] = useState(false);
  const [apply, setApply] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [FilterData, setFilterData] = useState([{}]);
  const [loading, setLoading] = useState(true);
  const [indexValue, setIndexValue] = useState();
  const [swiper, setSwiper] = useState(null);
  const navigate=useNavigate();
  const {bookAlias}=useParams();
  const [filterCount, setfilterCount] = useState(0);
  const [filteroptionData, setFilteroptionsData] = useState({});
  const [filterOptions, setFilterOptions] = useState({
    docType: [],
    year: [],
    creatorType: [],
    publisher: [],
    searches_v: [],
  });

  const handleFilterClick = () => {
    setShowFilterBox(!showFilterBox);
  };

  function capitalizeWords(str) {
    return str.replace(/\b\w/g, function (char) {
      return char.toUpperCase();
    });
  }
  useEffect(() => {
    setfilterCount(0);
    for (const key in filterOptions) {
      if (filterOptions[key].length > 0) {
        setfilterCount((prev) => prev + 1);
      }
    }
  }, [filterOptions]);

  const citationFactor = (data) => {
    return data?.map((item) => {
      const imagePath =
        item?.find((nestedItem) => nestedItem?.key === "image")?.value[0] || null;
        const sliderImage=item?.find((nestedItem) => nestedItem?.key === "image")?.value.length>1&&item?.find((nestedItem) => nestedItem?.key === "image")?.value.slice(1)

      const creator = item?.find((nestedItem) => nestedItem?.key === "creator")?.value || [];
      const lastName = creator.map((nestedItem) => nestedItem?.last_name && nestedItem?.last_name);
      const firstName = creator.map((nestedItem) => nestedItem?.first_name);
      const fullname = lastName && firstName ? `${firstName} ${lastName}` : "";
      const author = lastName[0] && firstName[0] ? `${lastName},${firstName}` : "";
      const bookKey =
        item?.find((nestedItem) => nestedItem?.key === "book_title_of_document")
          ?.value || "";
      const subbookKey =
        item?.find(
          (nestedItem) => nestedItem?.key === "book_sub_title_of_document"
        )?.value || "";
        const book= bookKey && subbookKey && bookKey+' - '+subbookKey;
      const publisher =
        item?.find((nestedItem) => nestedItem?.key === "publisher")?.value ||
        "";
      const publicationPlace =
        item?.find((nestedItem) => nestedItem?.key === "place_of_publication")
          ?.value || "";

      const artistType =
        item?.find(
          (nestedItem) => nestedItem?.key === "title_and_type_of_creator"
        )?.value || "";
      const link =
        item?.find((nestedItem) => nestedItem?.key === "drawer_link")?.value ||
        "";
      const publicationYear =
        item?.find(
          (nestedItem) => nestedItem?.key === "sortable_year_date_of_publication"
        )?.value || "";

      const documentType =
        item?.find((nestedItem) => nestedItem?.key === "document_type")?.value ||
        "";

      const accession_number =
        item?.find((nestedItem) => nestedItem?.key === "accession_number")
          ?.value || "";
      const researchCategory = item?.find(
        (item) => item?.key === "researchCategories"
      ).value[0].value;
      const desc = `${author} ${artistType && `(${artistType}).`} ${
        bookKey && bookKey
      }-${subbookKey && subbookKey}.${publicationPlace && publicationPlace}:${
        publisher && publisher
      },${publicationYear} | ${accession_number}.${documentType} | ${researchCategory} `;
      const nameText =
        capitalizeWords(
          item
            .find((nestedItem) => nestedItem?.key === "masterlist")
            ?.value.replace(/-/g, " ")
        ) || "";
      const header = nameText + " & " + book;
      const typeCreator =
        item?.find((nestedItem) => nestedItem?.key === "title_and_type_of_creator")?.value ||
        "";
      const alias=item?.find((nestedItem) => nestedItem?.key === "alias")?.value

      return  {
        publicationYear,
        imagePath,
        desc,
        typeCreator,
        bookKey,
        book,
        link,sliderImage,
        nameText,
        publisher,
        fullname,
        header,
        alias,
        footer: [
          {
            text: "Contents Page →",
          },
          {
            text: "Index Page →",
          },
          {
            text: "Scanned Pages →",
          },
        ],
      };
    });
  };
  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };
  useEffect(() => {
    async function fetchData() {
      try {
        const { mockData } =
          (await getBibliographyData.bibliographyByMasterlist(
            tableName,
            masterlist
          )) || {};

        if (mockData && isTransformationNotAllowed() === true) {
          const processedData = citationFactor(mockData);
          setLoading(false);
          setFilterData(processedData);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    async function fetchData() {
      if (apply && isTransformationNotAllowed() === true) {
        try {
          const { filterMockData } =
            (await getBibliographyData.bibliographyByMasterlist(
              tableName,
              masterlist
            )) || {};
          const filteredMockDataResult = await filterMockData(
            null,
            searchTerm
          );
          setFilterData(citationFactor(filteredMockDataResult));
        } catch (error) {
          console.error("Error filtering data:", error);
        }
        setApply(false);
      }
    }
    fetchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apply, masterlist, searchTerm, tableName]);

  const [drawerBook,setDrawerBook]=useState("");
  const handleDrawer = (item) => {
    setDrawerBook(item);
    setBibliographyObject(item);
    setOpenDrawer(true);
    navigate(`${item?.alias}`)

    // setleftFlag(true);
  };
  const handleCloseClick = () => {
    setContentClicked(false);
    setScanClicked(false);
  };
  const handleClose = () => {
    setBibliographyObject();
    handleCloseClick();
    setDrawerBook('')
    navigate(-1);
  };
  const showSliderImage = (index) => {
    swiper.slideTo(index);
  };
  const handleContentClicked = (index, text) => {
    setIndexValue(index);
    showSliderImage(2);
    if (text === "Contents Page →") {
      setContentClicked(true);
      setScanClicked(false);
    }
    if (text === "Scanned Pages →") {
      setScanClicked(true);
      setContentClicked(false);
    }
  };

  useEffect(()=>{
    if(FilterData){
      const item=FilterData.find((it)=>it.alias===bookAlias)
      setDrawerBook(item)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps, no-use-before-define
  },[FilterData,bookAlias])

  React.useEffect(() => {
    const getFilterOptionsData = async () => {
      const result = await getBibliographyData.getBibliographyFilterOptions(
        tableName,
        masterlist
      );
      if (result) {
        const dataIntro = result;
        setFilteroptionsData(dataIntro);
      }
    };

    getFilterOptionsData();
  }, [masterlist, tableName]);

  const handleReset=() => {
    setFilterOptions({
      docType: [],
      year: [],
      creatorType: [],
      publisher: [],
    });
    setSearchTerm("");
  }
  return (
    <>
      {!loading ? (
        <>
          <Stack flexDirection={"row-reverse"}  width={{ base: "100%", md:"1160px"}} minH={"100vh"}>
             
          {bookAlias && (
              <CommonDrawer
                drawerOpen={openDrawer}
                setDrawerOpen={handleClose}
                drawerHeader={
                  <DrawerHeader
                  data={drawerBook}
                  handleClose={handleClose}
                />
            }
            drawerBody={
              <DrawerBody
                setSwiper={setSwiper}
                data={drawerBook}
                handleBookClick={handleBookClick}
                bibliographyObject={bibliographyObject}
              />
            }
            drawerFooter={
              <DrawerFooter
                data={drawerBook}
                handleContentClicked={handleContentClicked}
                scanClicked={scanClicked}
                indexValue={indexValue}
              />
            }
            />
            )}
            {!showFilterBox && (
              <FilterButton onClick={handleFilterClick} marginRight={"0px"} />
            )}
            <Container
              maxW="auto"
              className="songs"
              py="2"
              mt={"0px !important"}
            >
              <Stack
                flexDirection={"row"}
                gap={0}
                justifyContent="space-between"
              >
                <HStack
                  flexDirection={"column"}
                  gap={0}
                  height="100%"
                  onCopy={(e) =>
                    CopyPasteService(e, `${window.location.href}/0/0`)
                  }
                  width={{ base: "100%", md:"935px"}}
                  marginTop={"16px"}
                >
                  {FilterData.length > 0 ?
                    <TimeLine
                      data={FilterData}
                      handleDrawer={handleDrawer}
                      btnRef={btnRef}
                      component={TimelineWithText}
                    /> : (
                    <Text
                      py={"40"}
                      fontSize={"45px"}
                      fontWeight="700"
                      textAlign="center"
                      display={"flex"}
                      alignItems={"center"}
                      marginLeft={"auto"}
                    >
                      <TbFoldersOff style={{ marginRight: "20px" }} />
                      {common.NO_DATA}
                    </Text>
                  )}
                </HStack>
                <HStack
                  className="mt-0"
                  marginRight={"-15px!important"}
                  alignItems={"flex-start"}
                >
                  {showFilterBox && (
                    <FilterBox
                    setShowFilterBox={setShowFilterBox}
                    setApply={setApply}
                    explore={explore}
                    handleSearch={handleSearch}
                    filterOptions={filterOptions}
                    filteroptionData={filteroptionData}
                    setFilterOptions={setFilterOptions}
                    setSearchTerm={setSearchTerm}
                    filterCount={filterCount}
                    searchTerm={searchTerm}
                    handleReset={handleReset}
                  />
                  )}
                </HStack>
              </Stack>
            </Container>
          </Stack>
          {/* Need for future use */}
          {/* {contentClicked && (
            <ContentModal
              contentClicked={contentClicked}
              setContentClicked={setContentClicked}
              // width={"746px"}
            />
          )}
          {scanClicked && (
            <SliderModal
              scanClicked={scanClicked}
              setScanClicked={setScanClicked}
              sliderModalImg={sliderImages}
            />
          )} */}
        </>
      ) : (
        <Text py={"40"} fontSize={{base:"25px",md:"45px"}} fontWeight="700" textAlign="center">
          {common.LOADING}
        </Text>
      )}
    </>
  );
}
export default TabularView;
