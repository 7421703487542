//S3 Bucket osw-dev-images
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;

const Img1 = `${IMAGE_BASE_PATH}/ui/Vision/SliderImage/1.jpg`
const Img2 = `${IMAGE_BASE_PATH}/ui/Vision/SliderImage/2.jpg`
const Img3 = `${IMAGE_BASE_PATH}/ui/Vision/SliderImage/3.jpg`
const Img4 = `${IMAGE_BASE_PATH}/ui/Vision/SliderImage/4.jpg`
const Img5 = `${IMAGE_BASE_PATH}/ui/Vision/SliderImage/5.jpg`
const Img6 = `${IMAGE_BASE_PATH}/ui/Vision/SliderImage/6.jpg`
const Img7 = `${IMAGE_BASE_PATH}/ui/Vision/SliderImage/7.jpg`
const Img8 = `${IMAGE_BASE_PATH}/ui/Vision/SliderImage/8.jpg`

export const visionData = [
  {
    id: 1,
    image: Img1,
  },
  {
    id: 2,
    image: Img2,
  },
  {
    id: 3,
    image: Img3,
  },
  {
    id: 4,
    image: Img4,
  },
  {
    id: 5,
    image: Img5,
  },
  {
    id: 6,
    image: Img6,
  },
  {
    id: 7,
    image: Img7,
  },
  {
    id: 8,
    image: Img8,
  },
];
