import {
  Box,
  Container,
  Grid,
  GridItem,
  HStack,
  Image,
  Stack,
  Text,
} from "@chakra-ui/react";
import Layout from "../components/sections/SidebarLayout";
import { Link } from "react-router-dom";

//S3 Bucket osw-dev-images
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;

const BackArrow = `${IMAGE_BASE_PATH}/ui/Chevron/backarrow.png`

function TheIntutivelogic() {
  return (
    <Layout>
      <Stack m="5" pr={"1"} pl={"1"} className="mobileviewpadding">
        <Container paddingX={{base:"0px", md:"4"}} maxW="auto">
          <Box display={{base:"content", md:"none"}} position={"sticky"} top={"90px"} zIndex={1}>
            <Link to="/philosophical-framework">
              <Image src={BackArrow}></Image>
            </Link>
          </Box>
          <Box pt="4">
            <Text fontSize={"50px"} fontWeight="700">
              The Intuitive-logic
            </Text>
            <Text
              pb="2"
              fontSize={"11px"}
              lineHeight="13px"
              fontWeight={"300"}
              color="#242424"
            >
              A Festival of Indian Contemporary Painting
            </Text>
            <Text pb="2" fontSize={"11px"} lineHeight="13px" fontWeight={"300"}>
              1 March – 19 April 1997, Mumbai
            </Text>
            <Text pb="2" fontSize={"25px"} lineHeight="29px" fontWeight={"700"}>
              Curator’s Note: Acknowledgements
            </Text>
            <Text py="4" fontSize={"18px"} lineHeight="28px" fontWeight={"600"}>
              To all the collaborators and partners of HEART my gratitude.
            </Text>
          </Box>
          <Stack>
            <HStack>
              <Grid templateColumns="repeat(6, 1fr)" gap={4}>
                <GridItem rowSpan={1} colSpan={2}></GridItem>
                <GridItem colSpan={4}>
                  <Text
                    py="2"
                    fontSize={"18px"}
                    lineHeight="28px"
                    fontWeight={"400"}
                    textAlign="justify"
                  >
                    Without the support of B.P. Singh, Ashok Vajpayi, Dr. Anjali
                    Sen and Saryu Doshi, this project would not have been
                    possible. Each in their own way, facilitated the project’s
                    idealism and in doing so, revealed their own hopes for a
                    more rigorous Indian contemporary arts infrastructure. At
                    the NGMA (New Delhi) Dr. Anjali Sen’s enthusiasm and precise
                    care proved invaluable, as was the help of M. Shankar in
                    assembling all the selected works. The efforts of Dr.
                    Sudhakar Sharma and Satinder Gupta are also appreciated.{" "}
                  </Text>
                  <Text
                    py="2"
                    fontSize={"18px"}
                    lineHeight="28px"
                    fontWeight={"400"}
                    textAlign="justify"
                  >
                    In a way the support from the private individuals and
                    galleries was more pleasing, simply because it is not their
                    duty to see or partake in that larger vision. Of course many
                    differences existed, but each we resolved through dialogue.
                    Eventually the private collaboration came to focus upon
                    those experienced stalwarts of the Indian contemporary art
                    scene whose genuine love for the arts clearly overwhelms
                    their commercial needs.{" "}
                  </Text>
                  <Text
                    py="2"
                    fontSize={"18px"}
                    lineHeight="28px"
                    fontWeight={"400"}
                    textAlign="justify"
                  >
                    Pheroza Godrej and Prakash Kejriwal’s support was always
                    uncompromising, as were the efforts of John Edmundson and
                    Rupa Patel at the British Council Division. The Practical
                    counsel of Shireen Gandhy and Dadiba Pundole was much
                    appreciated. Sanjna Kapoor’s bubbling enthusiasm and support
                    balanced the sombre days when things went astray.{" "}
                  </Text>
                  <Text
                    py="2"
                    fontSize={"18px"}
                    lineHeight="28px"
                    fontWeight={"400"}
                    textAlign="justify"
                  >
                    The kind support of Ashok Vajpayi and Manjit Bawa at the
                    Bharat Bhavan was appreciated, as were the professional
                    efforts of Akhilesh (though we have never met). The support
                    of Dr. Anna Winterberg and the Max Mueller Bhavan extended
                    to Gallery Chemould is also appreciated. Urmila Jain’s warm
                    support to HEART through Aaren is also gratefully
                    appreciated. To Sam Buhariwala in Mumbai and Vadehra Gallery
                    and Capt. Suri in New Delhi my gratitude for a smooth
                    packaging and transportation effort. To Prakash Rao and Asit
                    Poddar for their dedicated camerawork.{" "}
                  </Text>
                  <Text
                    py="2"
                    fontSize={"18px"}
                    lineHeight="28px"
                    fontWeight={"400"}
                    textAlign="justify"
                  >
                    As always I owe the trustees of HEART a special gratitude,
                    especially Pheroza Godrej, Rashmi Poddar, Rupika Chawla,
                    Sanjeev Khandelwal & Priya Paul. To Angira Arya my warmest
                    thank you, for without his tireless efforts the Festival
                    would have lacked on many fronts. To my dear friends Bipin
                    Shah and Kirti Trivedi, the backbone of HEART’s publishing
                    efforts, my warmest gratitude.{" "}
                  </Text>
                  <Text
                    py="2"
                    fontSize={"18px"}
                    lineHeight="28px"
                    fontWeight={"400"}
                    textAlign="justify"
                  >
                    To all the collectors and artists who have lent their work
                    for the Festival my gratitude: the National Gallery of
                    Modern Art, whose collection constitutes a significant
                    section of the Festival; Roopankar Museum of Fine Arts,
                    Bharat Bhavan; Prakash Kejriwal and his exquisite collection
                    of Ganesh Pyne’s works; Kekoo, Khorshed & Shireen Gandhy and
                    Gallery Chemould; Pheroza Godrej and Cymroza Art Gallery;
                    Dadiba Pundole, Jehangir Nicholson and the Jehangir
                    Nicholson Museum, NCPA. To Harsh Goenka, Devinder &
                    Kanwaldeep Sahney, Shirin Paul, Dinesh & Amrita Jhaveri,
                    Sangeeta Jindal, Renu Modi, Zai Daftary, Sanjiv Bambri, and
                    various artists, my warm thank you.{" "}
                  </Text>
                  <Text
                    py="2"
                    fontSize={"18px"}
                    lineHeight="28px"
                    fontWeight={"400"}
                    textAlign="justify"
                  >
                    The scale of the Festival has been reduced due to my ill
                    health, and to Dr.Udwadia and the nurses at Breach Candy my
                    gratitude for keeping one ticking away. And as always to my
                    parents for sustaining one’s frenetic energy without
                    qualification, and to my wife for her patience and love.{" "}
                  </Text>
                  <Text
                    py="2"
                    fontSize={"25px"}
                    lineHeight="29px"
                    fontWeight={"700"}
                  >
                    HEART
                  </Text>
                  <Text
                    py="2"
                    fontSize={"18px"}
                    lineHeight="28px"
                    fontWeight={"400"}
                    textAlign="justify"
                  >
                    This Festival of Exhibitions is a part of HEART’s ongoing
                    project concerned with helping to build an integrated and
                    rigorous educational infrastructure for the Indian
                    Contemporary Arts. Indian Contemporary Painting is the focus
                    at present.
                  </Text>
                  <Text
                    py="2"
                    fontSize={"18px"}
                    lineHeight="28px"
                    fontWeight={"400"}
                    textAlign="justify"
                  >
                    To simultaneously tackle the conceptual and the
                    institution-building issues is at the heart of our work.
                    This requires an integrating process which unites that which
                    seems contradictory, on terms which seem unrealistic, and at
                    a pace of implementation which feels unsustainable. If such
                    can be accomplished with credibility, the chances of
                    attitudes changing are real. Our inaugural publication – The
                    Flamed-mosaic: Indian Contemporary Painting initiated the
                    process; this Festival provides its immediate complement.{" "}
                  </Text>
                  <Text
                    py="2"
                    fontSize={"18px"}
                    lineHeight="28px"
                    fontWeight={"400"}
                    textAlign="justify"
                  >
                    This Festival has been structured mostly through the
                    resources of HEART and the goodwill of art lovers, hence
                    avoiding the need for sponsorship. This important issue
                    needs to be harnessed, especially if a mutually respectful
                    dialogue between the cultural and corporate sectors is to be
                    deepened.
                  </Text>
                  <Text
                    py="2"
                    fontSize={"18px"}
                    lineHeight="28px"
                    fontWeight={"400"}
                    textAlign="justify"
                  >
                    One other intent of the Festival is to increase the
                    awareness and quality regarding the curating and
                    documentation of international standard exhibitions. If the
                    subject is not clarified on the scale its efforts demand,
                    the logic inherent in the creative process will always be
                    facing an uphill struggle to influence daily values. Today
                    India is not even sufficiently exposed to Indian
                    Contemporary Art, let alone in a position to justly
                    appreciate its aesthetic. Believing that the developmental
                    process and its value systems across the nation and beyond
                    can be moulded by creativity and its aesthetic is an even
                    more remote dream. To help transform this dream into daily
                    reality is HEART’s intent.
                  </Text>
                  <Text
                    py="2"
                    fontSize={"25px"}
                    lineHeight="29px"
                    fontWeight={"700"}
                  >
                    Stubbornness Absorbs All Else
                  </Text>
                  <Text
                    py="2"
                    fontSize={"18px"}
                    lineHeight="28px"
                    fontWeight={"400"}
                    textAlign="justify"
                  >
                    The inner metamorphosis between stubbornness, will,
                    idealism, imagination and surrender, is eternally fluid
                    across time and localities, continually interacting with
                    its  flexible environment– All else, Pain, Another Logic,
                    Tradition and Playful Wisdom. Grasping this struggle is one
                    essential principle of understanding the human journey,
                    which seeks to know itself. Artistic creativity reflects
                    this journey of self–knowledge, of balancing the inner and
                    outer, the seemingly subjective and its objectivity, the
                    fluid and flexible, with a genuine depth and sense of
                    freedom.
                  </Text>
                  <Text
                    py="2"
                    fontSize={"18px"}
                    lineHeight="28px"
                    fontWeight={"400"}
                    textAlign="justify"
                  >
                    The changes within the inner resolve, are to a large extent
                    determined by the perceptions regarding the outer
                    environment. Thus the inability or unwillingness to absorb
                    all else through an inner stubbornness, can slowly transform
                    stubbornness into will, and absorption into a balancing act.
                    Suddenly all else may no longer seem inevitably absorbed,
                    and pain takes on an acute awareness that maybe  to continue
                    a balance is all one can achieve. Similarly, from will to
                    idealism, implies balance transformed into discipline, as
                    pain seeks a more cerebral grasp, as if a logical warfare
                    can systematically be waged within, and that the rhythm
                    within can coincide with the material logic which seemingly
                    propels human co–existence.
                  </Text>
                  <Text
                    py="2"
                    fontSize={"18px"}
                    lineHeight="28px"
                    fontWeight={"400"}
                    textAlign="justify"
                  >
                    The Stubbornness can emanate from infinite sources, because
                    it seems a pre-existing aspect of  consciousness. The
                    ability of such a pre-existing force to play with
                    uncertainty becomes the motivation for most artists. They
                    are unable to break away from the desperate need to know
                    oneself, and more than that, to be true to the search of
                    knowing oneself. Mystical and in constant awe, as if a
                    child, in continuous  wonder with the fascination of each
                    small deed and idea, and yet grasping the very depths of
                    pain, mortality and its aftermath, such are the clear
                    preoccupations of Rabindranath Tagore and Ganesh Pyne, one a
                    creative genius inspite of a technical naivete, the other an
                    artistic genius inspite of his technical brilliance, whose
                    trickery so easily overwhelms many artists with lesser
                    emotional honesty.
                  </Text>
                  <Text
                    py="2"
                    fontSize={"18px"}
                    lineHeight="28px"
                    fontWeight={"400"}
                    textAlign="justify"
                  >
                    Stubbornness also reveals itself through the dark
                    introspective integrity of Jeram Patel, obsessed by the need
                    to find the  roots of existence, while Rameshwar Broota’s
                    introspection reveals the monumental power of evolution,
                    uncontaminated by the lesser forces  of the  daily
                    environment. The vast energies revealed in the work of Biren
                    De, has its stubbornness rooted in the eternal dilemma of
                    fusing contradictory forces, whether it be male and female,
                    the erotic and the spiritual, the silent and the absolutely
                    anxious which cannot help but burst forth. The tension of
                    this fusion can only be sustained by the stubbornness which
                    realises that neither polarity is real, and that one is
                    indeed pursuing that by which neither are seen as they
                    appear, so living this frighteningly alone journey without
                    compromise.
                  </Text>
                  <Text
                    py="2"
                    fontSize={"18px"}
                    lineHeight="28px"
                    fontWeight={"400"}
                    textAlign="justify"
                  >
                    Close to this spiritually proud stubbornness (pride having
                    absorbed the humility which detachment provides) follows the
                    intellectual purity that demands the simplicity of essence
                    always to dominate, suppressing emotion and clutter so as to
                    stay true to an aesthetic delight. This stark delight fills
                    each moment, and motivates each subsequent encounter with
                    one’s environment. Akbar Padamsee’s art reflects this
                    intellectual will, which also recognises the healing force
                    of nature so as to temper the coldness such an aloof
                    temperament demands. Many artists recognise this balm like
                    feel for the land, seeking an erotic companionship in its
                    mystery, clearest in Ramkinkar Baij’s art, as with S.H. Raza
                    and Laxman Shreshtha,  and more gently as with Ganesh Haloi,
                    Jehangir Sabavala and Ramkumar.
                  </Text>
                  <Text
                    py="2"
                    fontSize={"18px"}
                    lineHeight="28px"
                    fontWeight={"400"}
                    textAlign="justify"
                  >
                    The gentleness in the latter artists evolves due to the pain
                    which their creativity tries to discipline and  absorb, and
                    so transforming a raw energy into a reflective mood of
                    assimilating in silence and  dissolving the self amid
                    infinite lands and their natural forces of regeneration.
                  </Text>
                  <Text
                    py="2"
                    fontSize={"18px"}
                    lineHeight="28px"
                    fontWeight={"400"}
                    textAlign="justify"
                  >
                    A different stubbornness comes with a madness slightly
                    beyond control, as when inflicted by a necessity, but
                    tempered by an imagination which knows no bounds, and yet is
                    confined to a little corner, because that is the burden of
                    the madness. 
                    <br />
                    K. Ramanujam ideally reflects this creative force  whose
                    mission is simply to keep sustained a blind absorption of
                    the moment, through magical deceits and saddened dreams
                    unwilling to collapse. This pain can lead to a refined
                    detachment, as easily as to an obsessed excess, and the
                    tense line manifests this energy with polarity-like
                    differences, as with say, Nasreen Mohammedi and Laxma K.
                    Goud.
                  </Text>
                  <Text
                    py="2"
                    fontSize={"18px"}
                    lineHeight="28px"
                    fontWeight={"400"}
                    textAlign="justify"
                  >
                    In Nasreen’s work the line numbs energy so as to simplify
                    the clutter, to cut through the pain, while in Laxma Goud’s
                    work, so forceful is the erotic energy, convinced of its all
                    encompassing vision, so that the very complexity of the mind
                    can be replicated by the line. Fusing the male, female,
                    beastly, and the natural seems an inevitable and simple
                    mission. The line simply oozes with a  confidence that it
                    reflects this continuum, and grasps the rhythm by which
                    energy dances upon each sensual moment, whatever the
                    circumstance.
                  </Text>
                  <Text
                    py="2"
                    fontSize={"18px"}
                    lineHeight="28px"
                    fontWeight={"400"}
                    textAlign="justify"
                  >
                    The oozing of confidence, the clear recognition of the
                    superiority of the creative mind, is  reflected in the
                    frenetic masterly line and colour of F.N. Souza. It is a
                    genius which once held the evolutionary force inherent in
                    the creative moment, and then became convinced that it would
                    last forever, and that it was an inevitability that the
                    creative energy would always renew itself, more possessed.
                    That delusion can become as real in  inspiring a genuine
                    stubbornness simply reveals the difficulty of grasping
                    creative evolution, and to know where stagnancy ends and 
                    self-belief continues towards self-criticism.
                  </Text>
                  <Text
                    py="2"
                    fontSize={"25px"}
                    lineHeight="29px"
                    fontWeight={"700"}
                  >
                    Inner Will Balancing the Pain
                  </Text>
                  <Text
                    py="2"
                    fontSize={"18px"}
                    lineHeight="28px"
                    fontWeight={"400"}
                    textAlign="justify"
                  >
                    When stubbornness is hammered by forces so intimidating and
                    seductive, then a transformation from stubbornness to the
                    will inherent in itself, motivates the nature of the
                    creative journey. The tussle now seems more evenly balanced,
                    for once the seductive logic and pain of one’s environment
                    infiltrates the mind, escape seems limited, though each
                    moment cannot help but believe in hope through the
                    imaginative force of idealism.
                  </Text>
                  <Text
                    py="2"
                    fontSize={"18px"}
                    lineHeight="28px"
                    fontWeight={"400"}
                    textAlign="justify"
                  >
                    The surrealistic brilliance of the Bengal atmosphere and
                    struggles, seemingly match the rhythms of a creative mind,
                    and so one allows a seduction to be instigated believing
                    that stubbornness is indeed dominating the day.
                    Gaganendranath Tagore’s aesthetic integrity had during
                    certain moments harnessed the awareness of pain with such
                    delicacy that the tensions of a violent and corrupt world
                    seem totally absorbed.
                  </Text>
                  <Text
                    py="2"
                    fontSize={"18px"}
                    lineHeight="28px"
                    fontWeight={"400"}
                    textAlign="justify"
                  >
                    However, sooner or later, the sheer tension of the tussle
                    between an awareness of pain and its balance becomes the
                    only motivating force, where moments of freedom and
                    helplessness are rarely separable. Tyeb Mehta, Somnath Hore,
                    Bikash Bhattacharjee and Jogen Chowdhury clearly reveal the
                    artistic genius whose inner will is convinced of absorbing
                    all which surrounds, and due to a heightened awareness
                    delves deeper into that material logic of violence and
                    injustice. With this indepth understanding, they are forced
                    to realise that to simply leave this initiated battlefield
                    is not possible. The emotional excess of yesteryear has
                    extracted its price, and the mind cannot extricate itself
                    until that early pain has been so completely harnessed that
                    detachment and its playful wisdom seem feasible.
                  </Text>
                  <Text
                    py="2"
                    fontSize={"18px"}
                    lineHeight="28px"
                    fontWeight={"400"}
                    textAlign="justify"
                  >
                    The discipline necessary to sustain the artistic idiom and
                    maintain the necessary self–criticism, while keeping fresh
                    the emotional purity, is a most tortuous attitude, which few
                    have sustained.  Sooner or later will must be transformed
                    into idealism if that logic beyond the inner voice is also
                    to be grasped with material clarity.
                  </Text>
                  <Text
                    py="2"
                    fontSize={"25px"}
                    lineHeight="29px"
                    fontWeight={"700"}
                  >
                    Idealism Disiplines and is Disciplined by Another Logic.
                  </Text>
                  <Text
                    py="2"
                    fontSize={"18px"}
                    lineHeight="28px"
                    fontWeight={"400"}
                    textAlign="justify"
                  >
                    This is the stage where creativity is motivated by a  now
                    seemingly more distant stubbornness, yet clearly real, and
                    faces a battle it is convinced is to be fought  on terms
                    which the logic of a material civilisation dictates. The
                    hope of allowing the spiritual and emotional aspect of
                    idealism to dominate the day is overwhelmed by a clear
                    awareness that creativity is at war with its surroundings.
                    Sentimentality is immediately curbed, and then the task of
                    harnessing the material logic, into the poetic rhythms of
                    idealism begins in earnest.  The degree with which the
                    compassion of idealism comes to discipline the sense of
                    alienation becomes the tussle. To not be armed with the
                    logic of materiality is not even considered.
                  </Text>
                  <Text
                    py="2"
                    fontSize={"18px"}
                    lineHeight="28px"
                    fontWeight={"400"}
                    textAlign="justify"
                  >
                    Gieve Patel, Sudhir Patwardhan, Vivan Sundaram, Nalini
                    Malani, G.m. Sheikh and Krishen Khanna ideally manifest this
                    deep dilemma. Their images speak most clearly of the
                    conflict every Arjuna faces and must resolve peacefully, yet
                    becoming a warrior of creativity and its aesthetic.
                    Non-violence is thus deeply appreciated, and its potential
                    in becoming part of a value-system clearer. However, at
                    times the reaction against violence seems to cloud
                    judgements regarding the viability of a non-violent daily
                    life, especially in a country such as India.
                  </Text>
                  <Text
                    py="2"
                    fontSize={"18px"}
                    lineHeight="28px"
                    fontWeight={"400"}
                    textAlign="justify"
                  >
                    To maintain this passion for non-violence and still become a
                    warrior - it is an eternal dilemma. Yet it must be
                    continually tackled. Creativity provides the fullest freedom
                    in seeking this aspiration towards fearlessness. That
                    idealism’s imagination tempts, and a playful wisdom beckons
                    just beyond, is the bait goodness dangles to a mind ready to
                    abstain from battle, content to let history recognise the
                    quiet wisdom of detached work. That tradition has absorbed
                    these struggles is understood by the artist, and with such
                    comes the humility to surrender, convinced of the honesty
                    sustained so far, and the futility of a fight beyond the war
                    one wages within, in any corner, at any time, under any
                    name. And so sooner or later every idea and trigger allows
                    the creative act to germinate, and aesthetics takes on the
                    glow that beauty is indeed possible and existent in every
                    form. From K.G. Subramanyan to M.F. Husain, Prabhakar Barwe
                    to Bhupen Khakhar, each with different aspects of the
                    intuitive-logic dominating their energy, bridge all forms of
                    life, absorbing, balancing and disciplining the moment, so
                    as to harness it towards that rhythm which reflects the pace
                    of daily breath or the walk through a park.
                  </Text>
                </GridItem>
              </Grid>
            </HStack>
          </Stack>
        </Container>
      </Stack>
      {/* <Footer /> */}
    </Layout>
  );
}
export default TheIntutivelogic;
