import {
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  HStack,
  Image,
  Stack,
  Text,
} from "@chakra-ui/react";
import React from "react";
import H2 from "../../../../../components/layouts/Typography/H2";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectFade, Navigation } from "swiper";
import Small from "../../../../../components/layouts/Typography/Small";
import { Link } from "react-router-dom";

//S3 Bucket osw-dev-images
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;

const closeIcon = `${IMAGE_BASE_PATH}/ui/Chevron/closeicon.svg`

function ResearchCentreDrawer({
  data,
  openDrawer,
  setOpenDrawer,
  handleClose,
  handleBookClick,
  handleContentClicked,
  scanClicked,
  contentClicked,
  setSwiper,
  bibliographyObject,
  indexValue,
}) {
  return (
    <>
      <Drawer
        isOpen={openDrawer}
        placement="right"
        onClose={(e) => setOpenDrawer(false)}
        // onClose={(e) => {
        //   e.preventDefault();
        // }}
        //   finalFocusRef={btnRef}
        id="rightsidebarsmall"
        // maxW={"308px"}
        maxW={"400px!important"}
      >
        <DrawerOverlay />
        <DrawerContent>
          {/* <DrawerCloseButton /> */}
          <DrawerHeader>
            <HStack
              mt={"12px"}
              justifyContent={"space-between"}
              alignItems={"flex-start"}
            >
              <Stack>
                <HStack>
                  {data.header.Image &&<Image
                    width={"265px"}
                    height={"auto"}
                    src={data.header.Image}
                  />}
                  <H2>{data.header.text}</H2>
                </HStack>
                <H2 className="mt-0">{data.header.desc}</H2>
              </Stack>
              <HStack>
                <Image
                  src={closeIcon}
                  paddingLeft="5px"
                  cursor={"pointer"}
                  onClick={() => {
                    handleClose();
                  }}
                />
              </HStack>
            </HStack>
          </DrawerHeader>

          <DrawerBody px={"5"} overflowY={"hidden"}>
            <Stack position={"relative"} id="allsliderskkArchive">
              <Swiper
                effect={"fade"}
                grabCursor={true}
                navigation={true}
                modules={[EffectFade, Navigation]}
                // className="rightslideshow"
                style={{ marginTop: "30px" }}
                onSwiper={setSwiper}
              >
                {data.body.sliderImage &&
                  data.body.sliderImage.map((it) => (
                    <SwiperSlide>
                      {" "}
                      <Image
                        // src={bibliographyObject?.image}
                        src={it}
                        onClick={() =>
                          handleBookClick(true, bibliographyObject)
                        }
                      />
                    </SwiperSlide>
                  ))}
              </Swiper>
            </Stack>
            <HStack pt={"44px"}>
              <Small className="autherlink">
                {/* {bibliographyObject?.creator?.creator && (
                    <>
                      Author:{" "}
                      <Link href="" color="#035DA1">
                        {` ${bibliographyObject?.creator?.creator.lastname}, ${bibliographyObject?.creator?.creator?.firstname} `}
                      </Link>
                    </>
                  )}
                  {bibliographyObject?.publisher &&
                    bibliographyObject?.publisher && (
                      <>
                        Publisher:{" "}
                        <Link href="" color="#035DA1">
                          {`${bibliographyObject?.publisher} `}
                        </Link>
                      </>
                    )}
                  {bibliographyObject?.date_of_published_edition_in_library && (
                    <>
                      Publishing:{" "}
                      <Link
                        href=""
                        color="#035DA1"
                      >{`${bibliographyObject?.date_of_published_edition_in_library}, ${bibliographyObject.place_of_publication} `}</Link>
                    </>
                  )} */}
                {data.body.details &&
                  data.body.details.map((it) => (
                    <>
                      {it.key}
                      <Link to={it.link} color="#035DA1">
                        {it.text}{" "}
                      </Link>
                    </>
                  ))}
              </Small>
            </HStack>
          </DrawerBody>
          <DrawerFooter justifyContent={"flex-start"}>
            <Stack justifyContent={"flex-start"}>
              {data.footer &&
                data.footer.map((it, index) => (
                  <HStack cursor={"pointer"}>
                    <Text
                      fontSize="12px"
                      className="autherlink"
                      color="#035DA1"
                      lineHeight={"15px"}
                      onClick={(e) => {
                        handleContentClicked(index, it.text);
                      }}
                      fontWeight={
                        (contentClicked || scanClicked) && indexValue === index
                          ? "700"
                          : "400"
                      }
                    >
                      {it.text}
                    </Text>
                  </HStack>
                ))}
            </Stack>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
}

export default ResearchCentreDrawer;
