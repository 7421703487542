//S3 Bucket osw-dev-images
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;

const Pre1 = `${IMAGE_BASE_PATH}/ui/ObjectBookJou/ArtHeritageAnnual/ResearchCentre/Library/GridView/1.png`
const Pre2 = `${IMAGE_BASE_PATH}/ui/ObjectBookJou/ArtHeritageAnnual/ResearchCentre/Library/GridView/2.png`
const Pre3 = `${IMAGE_BASE_PATH}/ui/ObjectBookJou/ArtHeritageAnnual/ResearchCentre/Library/GridView/3.png`
const Pre4 = `${IMAGE_BASE_PATH}/ui/ObjectBookJou/ArtHeritageAnnual/ResearchCentre/Library/GridView/4.png`
const Pre5 = `${IMAGE_BASE_PATH}/ui/ObjectBookJou/ArtHeritageAnnual/ResearchCentre/Library/GridView/5.png`
const Pre6 = `${IMAGE_BASE_PATH}/ui/ObjectBookJou/ArtHeritageAnnual/ResearchCentre/Library/GridView/6.png`
const Pre9 = `${IMAGE_BASE_PATH}/ui/ObjectBookJou/ArtHeritageAnnual/ResearchCentre/Library/GridView/9.png`
const Pre11 = `${IMAGE_BASE_PATH}/ui/ObjectBookJou/ArtHeritageAnnual/ResearchCentre/Library/GridView/11.png`
const Pre12 = `${IMAGE_BASE_PATH}/ui/ObjectBookJou/ArtHeritageAnnual/ResearchCentre/Library/GridView/12.png`
const Pre17 = `${IMAGE_BASE_PATH}/ui/ObjectBookJou/ArtHeritageAnnual/ResearchCentre/Library/GridView/17.png`
const Pre18 = `${IMAGE_BASE_PATH}/ui/ObjectBookJou/ArtHeritageAnnual/ResearchCentre/Library/GridView/18.png`
const Pre19 = `${IMAGE_BASE_PATH}/ui/ObjectBookJou/ArtHeritageAnnual/ResearchCentre/Library/GridView/19.png`
const Pre20 = `${IMAGE_BASE_PATH}/ui/ObjectBookJou/ArtHeritageAnnual/ResearchCentre/Library/GridView/20.png`
const placeholder = `${IMAGE_BASE_PATH}/ui/ObjectBookJou/ArtHeritageAnnual/ResearchCentre/Library/GridView/placeholder.png`


export const art_heritage_list_view =[
    {
      creator: "Sheikh, Gulam-Mohammed",
      year: 1979,
      gallery: "Art Heritage Gallery",
      location: "New Delhi",
      description: "K G Subramanyan",
      imgPath: Pre1,
    },
    {
      creator: "Goud, K-Laxma",
      year: 1979,
      gallery: "Art Heritage Gallery",
      location: "New Delhi",
      description: "Laxma Goud",
      imgPath: Pre1,
    },
    {
      creator: "Davierwalla, A-M",
      year: 1979,
      gallery: "Art Heritage Gallery",
      location: "New Delhi",
      description: "The Sculptor's Vocation",
      imgPath: Pre1,
      link: true,
    },
    {
      creator: "Chaitanya, Krishna",
      year: 1979,
      gallery: "Art Heritage Gallery",
      location: "New Delhi",
      description: "Dhanraj Bhagat - Sculptor Sculptor",
      imgPath: Pre1,
    },
    {
      creator: "Sanyal, B-C",
      year: 1979,
      gallery: "Art Heritage Gallery",
      location: "New Delhi",
      description: "Dhanraj Bhagat - Appraisals",
      imgPath: Pre1,
    },
    {
      creator: "Marwah, Mala",
      year: 1979,
      gallery: "Art Heritage Gallery",
      location: "New Delhi",
      description:
        "Drawing & Linear Expression - Six Contemporary Artists Six Contemporary Artists",
      imgPath: Pre1,
    },
    {
      creator: "Alkazi, E",
      year: 1979,
      gallery: "Art Heritage Gallery",
      location: "New Delhi",
      description: "M F Hussain The Modern Artist and Tradition",
    },
    {
      creator: "Davierwalla, A-M",
      year: 1979,
      gallery: "Art Heritage Gallery",
      location: "New Delhi",
      description: "The Sculptor's Vocation",
    },
    {
      creator: "Sabavala, Jehangir",
      year: 1980,
      gallery: "Art Heritage Gallery",
      location: "New Delhi",
      description: "Jehangir Sabavala",
    },
    {
      creator: "Patel, Gieve",
      year: 1980,
      gallery: "Art Heritage Gallery",
      location: "New Delhi",
      description: "Gieve Patel",
    },
    {
      creator: "Ezekiel, Nissim",
      year: 1980,
      gallery: "Art Heritage Gallery",
      location: "New Delhi",
      description: "Gieve Patel Artist and Environment",
    },
    {
      creator: "",
      year: 1980,
      gallery: "Art Heritage Gallery",
      location: "New Delhi",
      description: "Lalitha Lajmi",
    },
    {
      creator: "",
      year: 1980,
      gallery: "Art Heritage Gallery",
      location: "New Delhi",
      description: "Piraji Sagara",
    },
    {
      creator: "Krishnan, S-A",
      year: 1980,
      gallery: "Art Heritage Gallery",
      location: "New Delhi",
      description: "Serbjeet Singh",
    },
    {
      creator: "",
      year: 1980,
      gallery: "Art Heritage Gallery",
      location: "New Delhi",
      description: "Somnath Hore",
    },
    {
      creator: "Dutta, Santo",
      year: 1980,
      gallery: "Art Heritage Gallery",
      location: "New Delhi",
      description:
        "Visitations Obsessive Imagery in the Works of Some Contemporary Indian Painters",
    },
    {
      creator: "",
      year: 1982,
      gallery: "Art Heritage Gallery",
      location: "New Delhi",
      description: "K Laxma Goud",
    },
    {
      creator: "Marwah, Mala",
      year: 1982,
      gallery: "Art Heritage Gallery",
      location: "New Delhi",
      description: "Dhruva Mistry",
    },
    {
      creator: "Lavlin",
      year: 1982,
      gallery: "Art Heritage Gallery",
      location: "New Delhi",
      description: "Raghu Rai",
    },
    {
      creator: "Dube, Anita",
      year: 1984,
      gallery: "Art Heritage Gallery",
      location: "New Delhi",
      description: "Graphic Art and Somnath Hore",
    },
    {
      creator: "",
      year: 1984,
      gallery: "Art Heritage Gallery",
      location: "New Delhi",
      description: "Somnath Hore",
    },
    {
      creator: "Dube, Anita",
      year: 1984,
      gallery: "Art Heritage Gallery",
      location: "New Delhi",
      description: "The Season's Artist and Their Work",
    },
    {
      creator: "",
      year: 1985,
      gallery: "Art Heritage Gallery",
      location: "New Delhi",
      description: "Akbar Padamsee",
    },
    {
      creator: "",
      year: 1985,
      gallery: "Art Heritage Gallery",
      location: "New Delhi",
      description:
        "Sivakumar R Apotheosis of the Ordinary An Interview with K G Subramanyan conducted by R Sivakumar",
    },
    {
      creator: "Hebbar, Archana",
      year: 1985,
      gallery: "Art Heritage Gallery",
      location: "New Delhi",
      description:
        "From Image to Icon - A Study of the Development of K G Subramanyan A Study of the Development of K G Subramanyan",
    },
    {
      creator: "Bartholomew, Richard",
      year: 1985,
      gallery: "Art Heritage Gallery",
      location: "New Delhi",
      description: "Impressions of Varanasi Wax and Ink Painting by Ram Kumar",
    },
    {
      creator: "Bartholomew, Richard",
      year: 1985,
      gallery: "Art Heritage Gallery",
      location: "New Delhi",
      description: "Landscape as Vision",
    },
    {
      creator: "Bartholomew, Richard",
      year: 1985,
      gallery: "Art Heritage Gallery",
      location: "New Delhi",
      description: "Ram Kumar The Early Years",
    },
    {
      creator: "Francia, Peter-De",
      year: 1985,
      gallery: "Art Heritage Gallery",
      location: "New Delhi",
      description: "Rekha Rodwittiya",
    },
    {
      creator: "Subramanyan, K-G",
      year: 1985,
      gallery: "Art Heritage Gallery",
      location: "New Delhi",
      description: "State of the Arts in Contemporary India",
    },
    {
      creator: "Bartholomew, Richard",
      year: 1985,
      gallery: "Art Heritage Gallery",
      location: "New Delhi",
      description: "Unreal City Ram Kumar's Banaras",
    },
    {
      creator: "",
      year: 1986,
      gallery: "Art Heritage Gallery",
      location: "New Delhi",
      description: "Akbar Padamsee",
    },
    {
      creator: "Mukhopadhyay, Amit",
      year: 1986,
      gallery: "Art Heritage Gallery",
      location: "New Delhi",
      description: "Chittaprasad Humanist and Patriot Humanist and Patriot",
    },
    {
      creator: "Bhatt, Jyoti",
      year: 1986,
      gallery: "Art Heritage Gallery",
      location: "New Delhi",
      description: "Jyoti Bhatt",
    },
    {
      creator: "",
      year: 1987,
      gallery: "Art Heritage Gallery",
      location: "New Delhi",
      description:
        "Bhasker C -I David Olivant - Extracts from an Interview Excerpts from an Interview",
    },
    {
      creator: "Campbell, Andrew",
      year: 1987,
      gallery: "Art Heritage Gallery",
      location: "New Delhi",
      description: 'David Olivant\'s "Chaotic Sea of Marks"',
    },
    {
      creator: "",
      year: 1987,
      gallery: "Art Heritage Gallery",
      location: "New Delhi",
      description: "K G Subramanyan",
    },
    {
      creator: "Alkazi, E",
      year: 1987,
      gallery: "Art Heritage Gallery",
      location: "New Delhi",
      description: "Souza's Seasons in Hell",
    },
    {
      creator: "Bartholomew, Richard",
      year: 1987,
      gallery: "Art Heritage Gallery",
      location: "New Delhi",
      description: "The Three worlds of Satish Gujral",
    },
    {
      creator: "",
      year: 1987,
      gallery: "Art Heritage Gallery",
      location: "New Delhi",
      description: "Gujral Speaks An Interview",
    },
    {
      creator: "Alkazi, E",
      year: 1987,
      gallery: "Art Heritage Gallery",
      location: "New Delhi",
      description: "Nasreen Mohammedi",
    },
    {
      creator: "Ramachandran, A",
      year: 1987,
      gallery: "Art Heritage Gallery",
      location: "New Delhi",
      description: "Notes from the Underground",
    },
    {
      creator: "",
      year: 1987,
      gallery: "Art Heritage Gallery",
      location: "New Delhi",
      description: "Rekha Rodwittiya The Artist's Statement",
    },
    {
      creator: "Singh, Serbjeet",
      year: 1987,
      gallery: "Art Heritage Gallery",
      location: "New Delhi",
      description: "Serbjeet Singh Himalayan Odyssey",
    },
    {
      creator: "Hore, Somnath",
      year: 1987,
      gallery: "Art Heritage Gallery",
      location: "New Delhi",
      description: "Somnath Hore",
    },
    {
      creator: "Kowshik, Dinkar",
      year: 1987,
      gallery: "Art Heritage Gallery",
      location: "New Delhi",
      description: "Somnath Hore Wounds in Bronze",
    },
    {
      creator: "Kapoor, Kamala",
      year: 1988,
      gallery: "Art Heritage Gallery",
      location: "New Delhi",
      description:
        "Jehangir Sabavala - Excerpts from an Interview Excerpts from an Interview",
    },
    {
      creator: "Datta, Ella",
      year: 1989,
      gallery: "Art Heritage Gallery",
      location: "New Delhi",
      description:
        "The Spirit of Order - Pictorial Chronology 1951-88 The Spirit of Order",
    },
    {
      creator: "Elliott, David",
      year: 1989,
      gallery: "Art Heritage Gallery",
      location: "New Delhi",
      description:
        "K G Subramanyan Inayat Khan's Peregrinations Inyat Khan's Peregrinations",
    },
  ]

export const art_heritage_grid_view =[
    {
      creator: {
        creator: {
          firstname: "Gulam-Mohammed",
          lastname: "Sheikh",
        },
      },
      sortable_year_date_of_publication: 1979,
      gallery: "Art Heritage Gallery",
      location: "New Delhi",
      description: "K G Subramanyan",
      image: Pre1,
      document_type: "ess",
    },
    {
      creator: {
        creator: {
          firstname: "K-Laxma",
          lastname: "Goud",
        },
      },
      sortable_year_date_of_publication: 1979,
      gallery: "Art Heritage Gallery",
      location: "New Delhi",
      description: "Laxma Goud",
      image: Pre2,
      document_type: "ess",
    },
    {
      creator: {
        creator: {
          firstname: "A-M",
          lastname: "Davierwalla",
        },
      },
      sortable_year_date_of_publication: 1979,
      gallery: "Art Heritage Gallery",
      location: "New Delhi",
      description: "The Sculptor's Vocation",
      image: Pre3,
      document_type: "exc",
      link: true,
    },
    {
      creator: {
        creator: {
          firstname: "Krishna",
          lastname: "Chaitanya",
        },
      },
      sortable_year_date_of_publication: 1979,
      gallery: "Art Heritage Gallery",
      location: "New Delhi",
      description: "Dhanraj Bhagat - Sculptor Sculptor",
      image: Pre4,
      document_type: "ess",
    },
    {
      creator: {
        creator: {
          firstname: "B-C",
          lastname: "Sanyal",
        },
      },
      sortable_year_date_of_publication: 1979,
      gallery: "Art Heritage Gallery",
      location: "New Delhi",
      description: "Dhanraj Bhagat - Appraisals",
      image: Pre5,
      document_type: "exc",
    },
    {
      creator: {
        creator: {
          firstname: "Mala",
          lastname: "Marwah",
        },
      },
      sortable_year_date_of_publication: 1979,
      gallery: "Art Heritage Gallery",
      location: "New Delhi",
      description:
        "Drawing & Linear Expression - Six Contemporary Artists Six Contemporary Artists",
      image: Pre6,
      document_type: "ess",
    },
    {
      creator: {
        creator: null,
      },
      sortable_year_date_of_publication: 1979,
      gallery: "Art Heritage Gallery",
      location: "New Delhi",
      description: "Lalitha Lajmi",
      image: placeholder,
      document_type: "exc",
    },
    {
      creator: {
        creator: {
          firstname: "A-M",
          lastname: "Davierwalla",
        },
      },
      sortable_year_date_of_publication: 1979,
      gallery: "Art Heritage Gallery",
      location: "New Delhi",
      description: "The Sculptor's Vocation",
      image: placeholder,
      document_type: "ess",
    },
    {
      creator: {
        creator: {
          firstname: "E.",
          lastname: "Alkazi",
        },
      },
      sortable_year_date_of_publication: 1979,
      gallery: "Art Heritage Gallery",
      location: "New Delhi",
      description: "M F Hussain The Modern Artist and Tradition",
      image: Pre9,
      document_type: "jou",
    },
    {
      creator: {
        creator: {
          firstname: "Jehangir",
          lastname: "Sabavala",
        },
      },
      sortable_year_date_of_publication: 1980,
      gallery: "Art Heritage Gallery",
      location: "New Delhi",
      description: "Jehangir Sabavala",
      image: placeholder,
      document_type: "ess",
    },
    {
      creator: {
        creator: {
          firstname: "Gieve",
          lastname: "Patel",
        },
      },
      sortable_year_date_of_publication: 1980,
      gallery: "Art Heritage Gallery",
      location: "New Delhi",
      description: "Gieve Patel",
      image: Pre11,
      document_type: "exc",
    },
    {
      creator: {
        creator: {
          firstname: "Nissim",
          lastname: "Ezekiel",
        },
      },
      sortable_year_date_of_publication: 1980,
      gallery: "Art Heritage Gallery",
      location: "New Delhi",
      description: "Gieve Patel Artist and Environment",
      image: Pre12,
      document_type: "ess",
    },
    {
      creator: {
        creator: null,
      },
      sortable_year_date_of_publication: 1980,
      gallery: "Art Heritage Gallery",
      location: "New Delhi",
      description: "Lalitha Lajmi",
      image: placeholder,
      document_type: "exc",
    },
    {
      creator: {
        creator: null,
      },
      sortable_year_date_of_publication: 1980,
      gallery: "Art Heritage Gallery",
      location: "New Delhi",
      description: "Piraji Sagara",
      image: placeholder,
      document_type: "exc",
    },
    {
      creator: {
        creator: {
          firstname: "S-A",
          lastname: "Krishnan",
        },
      },
      sortable_year_date_of_publication: 1980,
      gallery: "Art Heritage Gallery",
      location: "New Delhi",
      description: "Serbjeet Singh",
      image: placeholder,
      document_type: "exc",
    },
    {
      creator: {
        creator: null,
      },
      sortable_year_date_of_publication: 1980,
      gallery: "Art Heritage Gallery",
      location: "New Delhi",
      description: "Somnath Hore",
      image: placeholder,
      document_type: "exc",
    },
    {
      creator: {
        creator: {
          firstname: "Santo",
          lastname: "Dutta",
        },
      },
      sortable_year_date_of_publication: 1980,
      gallery: "Art Heritage Gallery",
      location: "New Delhi",
      description:
        "Visitations Obsessive Imagery in the Works of Some Contemporary Indian Painters",
      image: Pre17,
      document_type: "ess",
    },
    {
      creator: {
        creator: {
          firstname: "E.",
          lastname: "Alkazi",
        },
      },
      sortable_year_date_of_publication: 1980,
      gallery: "Art Heritage Gallery",
      location: "New Delhi",
      description: "M F Hussain The Modern Artist and Tradition",
      image: Pre18,
      document_type: "jou",
    },
    {
      creator: {
        creator: {
          firstname: "E.",
          lastname: "Alkazi",
        },
      },
      sortable_year_date_of_publication: 1980,
      gallery: "Art Heritage Gallery",
      location: "New Delhi",
      description: "M F Hussain The Modern Artist and Tradition",
      image: Pre19,
      document_type: "jou",
    },
    {
      creator: {
        creator: {
          firstname: "E.",
          lastname: "Alkazi",
        },
      },
      sortable_year_date_of_publication: 1982,
      gallery: "Art Heritage Gallery",
      location: "New Delhi",
      description: "M F Hussain The Modern Artist and Tradition",
      image: Pre20,
      document_type: "jou",
    },
  ];

