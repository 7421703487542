import {
  Box,
  HStack,
  Image,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  useDisclosure,
} from "@chakra-ui/react";
import { oExplorer } from "../constants/constants";
import { getOExplorerData } from "../services/OExplorerService";
import * as getProfile from "../services/profile";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import H5 from "../Theme/Typography/H5";
import H3 from "../Theme/Typography/H3";
import { TriangleDownIcon } from "@chakra-ui/icons";

//S3 Bucket osw-dev-images
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;

const Vector = `${IMAGE_BASE_PATH}/ui/Chevron/Vector.png`
const joker = `${IMAGE_BASE_PATH}/ui/Chevron/joker.png`

export const OExplorer = ({isAuth}) => {
  const [oExplorerData, setOExplorerData] = useState([]);
  const [showLabel, setShowLabel] = useState(false);
  const [currentStage, setCurrentStage] = useState('');
  const session = useSelector(state => state.session);
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    const getData = async () => {
      const result = await getOExplorerData();
      if (result?.length > 0) {
        setOExplorerData(result);
        setShowLabel(false);
      } else {
        setShowLabel(true);
      }
    };
    const getProfileData = async () => {
      const result = await getProfile.getProfileData();
      if (result) {
        setCurrentStage(result?.profile?.currentUserStage?.stage ? `${result?.profile?.currentUserStage?.stage?.order}_${result?.profile?.currentUserStage?.stage?.level}` : '');
      }
    };
    setCurrentStage(oExplorer.O_NON_SUBSCRIBER);
    if (session.isAuthenticated) {
      getProfileData();
    }
    getData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuth]);

  return (
    <>
      <Box
        border={"1px"}
        borderColor={"color.lightgray4"}
        borderRadius={"5px"}
        p="1"
        mx="4"
        height={"34px"}
        display={{base:"none",md:"contents"}}
      >
        {showLabel ? (
          <H5 className="rc-title" py="1" px="2" fontWeight={"700"}>
            {oExplorer.O_NON_SUBSCRIBER}
          </H5>
        ) : (
          <Popover trigger={"hover"} isOpen={isOpen} onClose={onClose}>
            <PopoverTrigger>
              {/* <Box onMouseEnter={onOpen}> */}
              <Box onClick={onOpen}>
                <H5 className="rc-title" pt="7px" px="1" fontWeight={"700"}>
                  {currentStage !== "" ? currentStage : oExplorer.O_NON_SUBSCRIBER}
                </H5>
              </Box>
            </PopoverTrigger>
            <PopoverContent width={"204px"}  _focus={{ boxShadow: 'none', border: 'none' }} borderRadius={"0px"} position={"relative"} right={session.isAuthenticated? "25%!important":"15%!important"}>
              <PopoverBody pl="4">
                {oExplorerData.map((item) => (
                  <HStack key={item.order} justifyContent={"space-between"}>
                    <H3 py="2" fontWeight={"700"} lineHeight="18px" textDecoration={"none"}>
                      {item.order}_{item.level}
                    </H3>
                    {`${item.order}_${item.level}` === currentStage && (
                      <HStack gap={"0px"} alignItems={"flex-end"}>
                        <Image marginInlineStart={"-0.5rem"} src={joker} width={{base:'auto',md:'100%'}} objectFit={"contain"} height={"28px"} />
                        <Image marginInlineStart={"0.1rem"} transform="rotate(180deg)" src={Vector} />
                      </HStack>
                      // <></>
                    )}
                  </HStack>
                ))}
              </PopoverBody>
            </PopoverContent>
          </Popover>
        )}
      </Box>
    {/* This for mobile device */}
      <Box
        border={"1px"}
        borderColor={"color.lightgray4"}
        borderRadius={"5px"}
        p="1"
        mx="4"
        height={"34px"}
        display={{base:"contents",md:"none"}}
      >
        {showLabel ? (
          <H5 py="1" px="1" fontWeight={"700"}>
            {oExplorer.SD_RI}
          </H5>
        ) : (
          <Popover trigger={"hover"} isOpen={isOpen} onClose={onClose}>
            <PopoverTrigger>
              <Box onMouseEnter={onOpen}>
              <HStack width={{base:"60px",md:"100%"}} alignItems={"center"}  spacing="1">
                <H5 fontSize="12px" p={{base:"5px",md:"2"}} className="rc-title" fontWeight={"600"}>
                {oExplorer.SD_RI}
               
                <TriangleDownIcon
                          color={"gray.500"}
                          fontSize={"10px"}
                          ml={{base:"1",md:"3"}}
                          mr={{base:"0",md:"0"}}
                        />
                         </H5>
                           </HStack>
              </Box>
            </PopoverTrigger>
            <PopoverContent width={"204px"}  _focus={{ boxShadow: 'none', border: 'none' }} borderRadius={"0px"} position={"relative"} right={session.isAuthenticated? "0%!important":"0%!important"} top="10px">
              <PopoverBody pl="4">
                {oExplorerData.map((item) => (
                  <HStack key={item.order} justifyContent={"space-between"}>
                    <H3 fontSize="14px" py="2" fontWeight={"600"} lineHeight={"14px"} textDecoration={"none"}>
                      {item.order}_{item.level}
                    </H3>
                    {`${item.order}_${item.level}` === currentStage && (
                      <HStack gap={"0px"} alignItems={"flex-end"}>
                        <Image marginInlineStart={"-0.5rem"} src={joker} width={"100%"} height={"28px"} />
                        <Image marginInlineStart={"0.1rem"} transform="rotate(180deg)" src={Vector} />
                      </HStack>
                      // <></>
                    )}
                  </HStack>
                ))}
              </PopoverBody>
            </PopoverContent>
          </Popover>
        )}
      </Box>
    </>
  );
};
