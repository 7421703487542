import {
  Container,
  HStack,
  Stack,
} from "@chakra-ui/react";

//Core files
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

//Language files
import TabMain from "../../../../FunctionalComponents/TabComponents/TabMain";

import { osiansCinefan } from "../../../../constants/constants";
import Print from "./Print";
import Radio from "./Radio";
import Television from "./Television";
import SocialMedia from "./SocialMedia";
import EventPhotographs from "./EventPhotographs";

function MediaReportage({ winnerName, setReadMoreFlag, readmoreNavigation }) {
  const [visible, setVisible] = React.useState(true);
  const [listGridIcon, setListGridIcon] = useState(false);
  const { t } = useTranslation("");
  const [tabPanelData, setTabPanelData] = useState([]);

  function formatData() {
    return [
      {
        name: `${t(osiansCinefan.PRINT)}`,
        component:<Print winnerName={winnerName}/>,
        link:`/event-film-festival/${winnerName}/agraphy/body-of-work/media-reportage/print`

        // list:false
      },
      {
        name: `${t(osiansCinefan.RADIO)}`,
        component: <Radio winnerName={winnerName}/>,
        link:`/event-film-festival/${winnerName}/agraphy/body-of-work/media-reportage/radio`

        // list:false
      },
      {
        name: `${t(osiansCinefan.TELEVISION)}`,
        component: <Television winnerName={winnerName}/>,
        link:`/event-film-festival/${winnerName}/agraphy/body-of-work/media-reportage/television`

        // list:false
      },
      {
        name: `${t(osiansCinefan.SOCIAL_MEDIA)}`,
        component: <SocialMedia winnerName={winnerName}/>,
        link:`/event-film-festival/${winnerName}/agraphy/body-of-work/media-reportage/social-media`
        // list:false
      },
      {
        name: `${t(osiansCinefan.EVENT_PHOTOGRAPHS)}`,
        component: <EventPhotographs  winnerName={winnerName}/>,
        link:`/event-film-festival/${winnerName}/agraphy/body-of-work/media-reportage/event-photographs`,
        list:false
      },

    ];
  }

  const tabHandle=(item)=>{
    setListGridIcon(item)
  }

  useEffect(() => {
    if (winnerName) {
      setTabPanelData(formatData());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [winnerName,visible]);
  return (
    <Container minW={"100%"} pb="4" paddingX={{ base: "4", md: "0px" }}>
      <Stack>
        <HStack>
          {tabPanelData && tabPanelData.length > 0 && (
            <TabMain ClassName="Timeline" paddingTop="2" width="1070px" pos={"fixed"} data={tabPanelData} listId="listgridbutton3" tabTop={{ base: "55px", sm: "75px", md: "56px" }} panelTop={{ base: "65px", sm: "85px", md: "40px" }} tabHandle={tabHandle} listGridIcon={listGridIcon} setVisible={setVisible} visible={visible} topTogleIcon={"140px"}/>
          )}
        </HStack>
      </Stack>
    </Container>
  );
}

export default MediaReportage;
