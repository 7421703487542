import { Navigation, Pagination, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import {
  Box,
  Button,
  Container,
  HStack,
  Image,
  SimpleGrid,
  Stack,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import {
  getObjectOfFocusData,
  getObjectOfFocusImageData,
} from "../services/Introduction";
import { Link } from "react-router-dom";

export const ObjectofFocus = ({ alias }) => {
  const [objData, setObjData] = useState([]);
  const [imageData, setImageData] = useState([]);
  useEffect(() => {
    const getData = async () => {
      const result = await getObjectOfFocusData(alias);
      const imgData = getObjectOfFocusImageData(alias);
      if (result) {
        setObjData(result);
      }
      if (imgData) {
        setImageData(imgData);
      }
    };
    getData();
  }, [alias]);

  return (
    <>
      <Container
        maxW="auto"
        pr="0px"
        pl="0px"
      >
        <Stack width={"auto"} position={"relative"} id="allsliderskkObject">
          <Swiper
            modules={[Navigation, Pagination, A11y]}
            slidesPerView={1}
            loop={true}
            navigation
            height={"100%"}
            className="subslider"
          >
            <SwiperSlide>
              <Box width={"auto"}  height={{base:"auto",md:"570px"}} className="Bgimage">
                
                  <Image width={"auto"} height={"inherit"} src={objData[0]?.imgPath} alt="" />
               
                  {/* <Link to={objData[0]?.link}> */}
                {/* <Box
                  className="top-right"
                  zIndex={1}
                  pt="14"
                  pr="4"
                  pb="16"
                  pl="16"
                  id="DivForHoverItem"
                  width={"550px"}
                  height="600px"
                >
                  <Text
                    className="title-on-slide"
                    fontSize={"40px"}
                    fontWeight={"900"}
                    lineHeight={"54px"}
                    teststyle="secondary"
                    textAlign={"left"}
                    id="HiddenText"
                    color={"white"}
                  >
                    {objData[0]?.title}
                  </Text>
                  <Text
                    fontSize={"16px"}
                    fontWeight={"600"}
                    lineHeight={"22px"}
                    teststyle="secondary"
                    textAlign={"left"}
                    py="2"
                    id="HiddenText"
                    color={"white"}
                  >
                    {objData[0]?.description}
                  </Text>
                  <Stack
                    spacing={4}
                    direction="row"
                    className="btn-wrap-onmobile"
                    gap={"3"}
                    align="center"
                  >
                    <Link className="ml-0" to="satyajit-ray/introduction">
                      <Button
                        className="btn-on-slide"
                        id="HiddenText"
                        color={"black"}
                        size={"md"}
                        bg="#ffffffb5"
                        fontSize={"16px"}
                        fontWeight={"400"}
                      >
                        {objData[0]?.button1}
                      </Button>
                    </Link>
                    <Link className="ml-0" to="/content-awaited">
                      <Button
                        id="HiddenText"
                        className="btn-on-slide"
                        color={"black"}
                        size={"md"}
                        bg="#ffffffb5"
                        fontSize={"16px"}
                        fontWeight={"400"}
                      >
                        {objData[0]?.button2}
                      </Button>
                    </Link>
                    <Link className="ml-0" to="/content-awaited">
                      <Button
                        id="HiddenText"
                        className="btn-on-slide"
                        color={"black"}
                        size={"md"}
                        bg="#ffffffb5"
                        fontSize={"16px"}
                        fontWeight={"400"}
                      >
                        {objData[0]?.button3}
                      </Button>
                    </Link>
                  </Stack>
                </Box> */}
                <Box
                    className="top-right"
                    zIndex={1}
                    pt="8"
                    pl="40"
                    id="DivForHoverItem"
                    width={"778px"}
                    height="600px"
                    _hover={{
                      backgroundColor: "black",
                      opacity: "0.7",
                    }}
                  >
                    <Text
                      className="title-on-slide"
                      fontSize={"40px"}
                      fontWeight={"900"}
                      // lineHeight={"54px"}
                      teststyle="secondary"
                      textAlign={"left"}
                      id="HiddenText"
                      color="white"
                    >
                       {objData[0]?.title}
                    </Text>
                    <Text
                      fontSize={"16px"}
                      fontWeight={"400"}
                      lineHeight={"22px"}
                      teststyle="secondary"
                      textAlign={"left"}
                      py="1"
                      id="HiddenText"
                      color="white"
                      width={"75%"}
                    >
                      {objData[0]?.description}
                    </Text>
                    <Text
                      fontSize={"16px"}
                      fontWeight={"400"}
                      lineHeight={"12px"}
                      teststyle="secondary"
                      textAlign={"left"}
                      py="1"
                      id="HiddenText"
                      color="white"
                    >
                      -------------------------------------
                    </Text>
                    <Stack
                      spacing={4}
                      direction="row"
                      gap={"3"}
                      className="btn-wrap-onmobile"
                      align="center"
                    >
                      <Link className="ml-0" to="/content-awaited">
                        <Button
                          id="HiddenText"
                          className="btn-on-slide"
                          color={"black"}
                          // size={"md"}
                          bg="#ffffffb5"
                          fontSize={"14px"}
                          fontWeight={"600"}
                          height={"30px"}
                        >
                          {objData[0]?.button1}
                        </Button>
                      </Link>
                      <Link className="ml-0" to="/content-awaited">
                        <Button
                          id="HiddenText"
                          className="btn-on-slide"
                          color={"black"}
                          // size={"md"}
                          bg="#ffffffb5"
                          fontSize={"14px"}
                          fontWeight={"600"}
                          height={"30px"}
                        >
                           {objData[0]?.button2}
                        </Button>
                      </Link>
                      <Link className="ml-0" to="/content-awaited">
                        <Button
                          id="HiddenText"
                          className="btn-on-slide"
                          color={"black"}
                          // size={"md"}
                          bg="#ffffffb5"
                          fontSize={"14px"}
                          fontWeight={"600"}
                          height={"30px"}
                        >
                         {objData[0]?.button3}
                        </Button>
                      </Link>
                    </Stack>
                  </Box>
                {/* </Link> */}
              </Box>
            </SwiperSlide>
          </Swiper>
        </Stack>
      </Container>
      <Container maxW="auto" mt={"40px"}>
        <Stack className="exploretabs">
          <SimpleGrid columns={[0, null, 3]} w={"auto"} spacing="30px">
          {imageData.map((item) => {
              return (
                <Box height="auto">
                  <Link href="">
                    <Box
                      // height="100%"
                      height="272px"
                      width={"auto"}
                      bg={"#C4C4C4"}
                      // justifyItems="center"
                      display="flex"
                      // justifyContent="space-around"
                    ></Box>
                  </Link>
                  <Stack pb="8" mt="8px">
                    <HStack>
                      <Text
                        fontSize={"12px"}
                        fontWeight={"400"}
                        color="#035DA1"
                      >
                        {item.title}
                      </Text>
                    </HStack>
                    <HStack>
                      <Text
                        fontSize={"12px"}
                        fontWeight={"400"}
                        color="#333333"
                      >
                       {item.date}
                      </Text>
                    </HStack>
                  </Stack>
                </Box>
              );
            })}
          </SimpleGrid>
        </Stack>
      </Container>
    </>
  );
};
