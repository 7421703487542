export const homeTitle = {
  PROJECTS:"Projects",
  FESTIVALS: "Tuli Research Centre 1st Festival of India Studies",
  FESTIVALS_2: "Tuli Research Centre | 1st Festival of India Studies"
};
export const explore = {
  EXPLORE_TAB_TITLE: "Explore",
  OBJECTOFFOCUS_TAB_TITLE: "Objects of Focus",
  COLLECTIONS_TAB_TITLE: "Collections",
  CURATEDTHEMES_TAB_TITLE: "Curated Themes",
  MASTERLISTS_TAB_TITLE: "Masterlists",
  INDEX_TAB_TITLE: "Index",
  SEE_MORE: "See more",
  SEE_ALL:"See all",
  CDT: "C.D.T (0)",
  EVENT: "Event (0)",
  FILM: "Film (0)",
  IDEA: "Idea (0)",
  INSTITUTION: "Institution (0)",
  LOCATION: "Location (0)",
  PERSONALITY: "Personality (0)",
  PRICES: "Prices (0)",
  RESEARCH_CATEGORY: "Research Category (0)",
  SONG: "Song (0)",
  YEAR: "Year (0)",
  INTRODUCTION: "Introduction",
  EXHIBITIONS: "Exhibitions",
  BIBLIOGRAPHY: "Bibliography",
  ECONOMICS_OF_ARTS: "Economics of Arts",
  ECONOMICS_OF_ART: "Economics of Art",
  INTEGRATED_TIMELINE: "Integrated Timeline",
  ARCHIVE: "Archive",
  FILMOGRAPHY: "Filmography",
  BOX_OFFICE: "Box Office",
  PERSONALIA_EL_F_E: "Personalia | Early Life, Family & Education",
  PERSONALIA: "Personalia",
  EDUCATION: "Education",
  TEACHING_INFRA_B_A: "Teaching & Infrastructure-Building Activities",
  TEACHING_INFRA_B: "Teaching & Infrastructure-Building",
  AWARDS_HONOURS: "Awards-Honours",
  AGRAPHY: "A-Graphy",
  CINEMAE: "CinemaE",
  SCREENINGS: "Screenings",
  TOP_COMM_RELEASES: "Top Commercial Releases",
  BOX_OFFICE_ANALYSIS: "Box-Office Analysis",
  FILTER: "Filter",
  SEARCH: "Search",
  CLEAR_ALL: "Clear all",
  RESET: "Reset",
  APPLY: "Apply",
  TYPE_OF_SCREENING: "Type of Screening",
  FILTER_YEAR: "Year",
  TIMELINE: "Timeline",
  BOOKLET_SONG: "Booklet | Song",
  SYNOPSIS_BOOKLET: "Synopsis Booklets",
  BOOKLETS: "Booklets",
  BOOKLET: "Booklet",
  HANDBILLS: "Handbills",
  SONG_SYNOPSIS_BOOKLET: "Song Synopsis Booklet",
  PROMOTIONAL_BOOKLET: "Promotional Booklets",
  FILM_FESTIVAL_BOOKLETS: "Film Festival Booklets",
  SYNOPSIS_SPECIFICATIONS: "Synopsis & Specifications",
  CREW_CAST: "Crew & Cast",
  AWARDS_AND_HONOURS: "Awards & Honours",
  TOP_AUCTION_SALES:"Top Auction Sales",
  HISTORY_OF_HIGHS:"History of Highs",
  SCREENING_AND_BOX_OFFICE: "Screenings & Box-Office",
  LYRICS_AND_SOUNDTRACK: "Lyrics & Soundtrack",
  EDITIONS: "Editions",
  BODY_OF_WORK: "Body of Work",
  ECONOMICS_OF_CINEMA: "Economics of Cinema",
  LIBRARY_ARCHIVE: "Library & Archive",
  RESEARCH_CENTRE: "Research Centre",
  LIBRARY: "Library",
  PREFACE: "Preface",
  SPECIFICATIONS: "Specifications",
  EDITORIAL: "Editorial",
  SYNOPSIS: "Synopsis",
  CREDITS_CAST: "Credits & Cast",
  BUDGET_BOX_OFFICE: "Budget & Box-Office",
  ALL: "All",
  BROWSE_ALL:"Browse All",
  JOURNAL: "Journal",
  INDIAN_ART: "Indian Art",
  POSTS: "Posts",
  INSIGHTS: "INSIGHTS",
  GARDEN_OF_DELIGHTS: "Garden of Delights",
  ESSAYS: "Essays",
  EXHIBITS: "Exhibits",
  SCHOLARSHIP:"Scholarship",
  LETTERS_SCRIBBLES_POETRY:"Letters, Scribbles & Poetry",
  PUBLICATIONS:"Publications",
  ILLUSTRATED_BOOKS:"Illustrated Books",
  AUTHORED_EDITED_BOOKS:"Authored/Edited Books",
  CATALOGUE: "Catalogue",
  EXHIBITION_PHOTOGRAPHS :"Exhibition Photographs",
  ART_CRITICISM_MEDIA_REPORTAGE:"Art Criticism & Media Reportage",
  MEDIA_REPORTAGE:"Media Reportage",
  EXHIBITION_CATALOGUE:"Exhibition Catalogue",
  BRAND_AMBASSDORSHIP:"Brand Ambassdorship",
  NO_FILTERS:"No Filters Available",
  SUBSCRIPTION_DESC:"Please Subscribe for further access.",
  TEAM:"Team",
  VENUE: "Venue",
  HIGHLIGHTS:"Highlights",
};

export const calendar = {
  CALENDAR_TAB_TITLE: "Calendar",
  DAILY_TAB_TITLE: "Daily",
  WEEKLY_TAB_TITLE: "Weekly",
  MONTHLY_TAB_TITLE: "Monthly",
};

export const common = {
  SUBSCRIBE: "Subscribe",
  NO_DATA: "Data not found",
  ALL_YEARS: "All Years",
  SUBSCRIBE_TO_READ_MORE: "Subscribe to Read More",
  CONTENT_AWAITED: "Content Awaited",
  IMAGE_AWAITED: "Image Awaited",
  PHILOSOPHICAL_FRAME: "Philosophical Framework",
  VISION: "Vision",
  MANIFESTO: "Manifesto",
  LOADING: "Loading...",
  SHARE: "Share",
  E_READING: "Essential Reading",
  S_READING: "Suggested Reading",
  READ_MORE: "Read More",
  READ_LESS: "Read Less",
  INSIGHT: "INSIGHT",
  QA_BUTTON: "Q&A",
  SEARCHER: "Richa_A.1 Searcher",
  Q_REMAINING: "Questions Remaining",
  SCORE: "Score",
  TIMER: "Timer",
  QUIZ_MESSAGE: "Next Questions will be added",
  CONTENT_AWAITED_FOR: "Content Awaited For",
  INDEX: "Index",
  CONTENTS: "Contents",
  ABOUT_THE_BOOK: "About the book",
  ABOUT_THE_AUTHOR: "About the Author",
  WEBPAGE_COMING_SOON:"Webpage coming soon",
  WEBPAGE_COMING_SOON_FOR:"Webpage coming soon for",
  LOGIN:"Log In",
  LANGUAGE_ENGLISH:"English",
  LANGUAGE_HINDI:"Hindi",
  LANGUAGE_FRENCH:"French",
  LOGOUT:"Logout",
  NO_POST:"No post",
  NO_DATA_FOUND: "Data not found",
  THANK_YOU_REGISTRATION_MESSAGE: "Thank you for subscribing to Tuli Research Centre for India Studies.",
  NO_MORE_ITEM_TO_LOAD:"No more items to load",
  WORKING_STILLS:"Working Still",
  FILM_STILLS:"Film Stills",
  BOOKS:"Books",
  DID_YOU_MEAN:"Did You Mean?",
  MAGAZINE_ARTICLES:"Magazine Articles",
  MEMORABILIA:"Memorabilia"

};
export const hamburgerMenu = {
  TRC: "Tuli Research Centre",
  LOGIN: "Login",
  CREATE_ACCOUNT: "Create an Account",
  FOLLOW_US: "Follow us on",
  ABOUT_US: "About Us",
  CONTACT_US: "Contact Us",
  CAREERS: "Careers",
  WHATS_NEW: "Whats New?",
  ARCHIVE_LIB: "Archive & Library",
  OSIANAMA_MAPIN: "Osianama Mapin",
  PUB_HOUSE: "Publishing House",
  VANRAJA_CLINIC: "Vanraja Sanctuary & Hospice",
  CANINE_WELFARE: "Canine & Feline Welfare",
  GRANTS_SCHOLARSHIPS: "Grants, Scholarships &",
  FELLOWSHIPS: "Fellowships",
  RESEARCH_CATEGORY: "Research Categories For India Studies",
  TRCI: "Tuli Research Centre for India Studies",
  MY_PROFILE:"My Profile",
  LOGOUT:"Logout",
  VISION:"Vision",
  PRIVACY_POLICY:"Privacy Policy",
  TERMS_AND_CONDITIONS:"Terms & Conditions"
};
export const oExplorer = {
  O_EXPLORER: "0_Explorer",
  COUNT: "contd.",
  O_NON_SUBSCRIBER:"0_Non Subscriber",
  SD_RI:"SD-RI"
};

export const personalityType = {
  CINE: "CINE.Personality",
  ARTS: "ARTS.Personality",
  FILM: "Film",
  ART_JOURNALS: "Arts Journal (BOOK.Jou)",
  INDIAN_CINEMA: "Indian Cinema",
  FINEARTS: "FineArts",
  CINEMA_E: "CinemaE | Indian Cinema",
  MODERN_ART: "Modern Indian Art",
};
export const masterpersonalityName = {
  SHRAZA: "Sayed Haider Raza",
  SatyajitRay: "Satyajit Ray",
  AHJ: "Art Heritage Annual",
  SKK: "Shatranj Ke Khilari",
  SANJEEVKUMAR: "Sanjeev Kumar",
  EBRAHIMALKAZI: "Ebrahim Alkazi",
};

export const personalityAttribute = {
  PARENTS: "Parent(s)",
  SCHOOL: "School",
  DATE_OF_BIRTH: "Date of Birth",
  TIME_OF_BIRTH: "Time of Birth",
  DATE_OF_DEATH: "Date of Death",
  ZODIAC_SIGN: "Zodiac Sign",
  AGE: "Age",
  NATIONALITY: "Nationality",
  PLACE_OF_BIRTH: "Place of Birth",
  SPOUSES: "Spouse(s)",
  YEAR_MARRIAGE_REGIST: "Year Marriage Registered",
  CHILDREN: "Children",
  EDUCATION: "Education",
  EDUCATION_YEAR: "Year",
  LATITUDE_OF_PLACE_OF_BIRTH: "Latitude of Place of Birth",
  LONGITUDE_OF_PLACE_OF_BIRTH: "Longitude of Place of Birth",
  FATHER_NAME: "Father Name",
  MOTHER_NAME: "Mother Name",
  GRAND_PARENT: "Grand Parent(s)",
  ANCESTORS: "Ancestor(s)",
};

export const bibliography = {
  AUTHOR: "Author",
  BOOK: "Book",
  PUBLISHER: "Publisher",
  YEAR_OF_PUBLISHING: "Year of Publishing",
  YEAR_OF_PUBLICATION: "Year of  Publication",
  PERTINENT_PERSONALITIES: "Pertinent Personalities",
};

export const filmography = {
  RATED: "Rated",
  LANGUAGES: "Languages",
  DURATION: "Duration",
  COLOR: "Color",
  LAB: "LAB",
  GAUGE: "Gauge",
  LENGTH_OF_REELS: "Length of Reels",
  NUMBER_OF_REELS: "Number of Reels",
  SHOOTING_PERIOD: "Shooting Period",
  STUDIO: "Studio",
  ON_LOCATION: "On Location",
  CERTIFICATE_NUMBER: "Certificate Number",
  DATE_OF_ISSUE: "Date of Issue",
  DATE_OF_EXPIRY: "Date of Expiry",
  SYNOPSIS_BOOKLET_LANGUAGES: "Song Synopsis Booklet Languages:",
  FILM_PRODUCER: "Film Producer",
  FILM_DIRECTOR: "Film Director",
  SCRIPT: "Script",
  SCREENPLAY: " Screenplay",
  DIALOGUE: "Dialogue",
  MUSIC_DIRECTOR: "Music Director",
  EDITING: "Editing",
  CINEMATOGRAPHY: "Cinematography",
  ART_DIRECTOR: "Art Director",
  STILL_PHOTOGRAPHER: "Still Photographer",
  POSTER_DESIGNER: "Poster Designer",
  PUBLICITY_DESIGNER: "Publicity Designer",
  DESIGNER: "Designer",
  NARRATOR: "Narrator",
  COSTUME_DESIGNER: "Costume Designer",
  CHOREOGRAPHY: "Choreography",
  STORY: "Story",
  WATCH_FILM_TRAILER: "Watch Film Trailer",
  WATCH_FULL_MOVIE: "Watch Full Movie",
  WATCH_FULL_FILM: "Watch Full Film",
  CAST_VIEW: "Cast View",
  FULL_CREDITS_VIEW: "Full Credits View",
  PRINTER: "Printer",
  DATE_OF_ARTWORK: "Date of Artwork",
  PHOTOGRAPHER: "Photographer",
  FEATURED_ACTORS: "Featured Actors",
  BANNER: "Banner",
  LEAD_CAST: "Lead Cast",
  CAMERA: "Camera",
  ASSISTANTS: "Assistants",
  ART_DIRECTION: "Art Direction",
  ASSOCIATE: "Associate",
  COSTUMES: "Costumes",
  ASSOCIATES: "Associates",
  SOUND: "Sound",
  MAKE_UP: "Make up",
  ASSISTANT: "Assistant",
  WARDROBE: "Wardrobe",
  BACKDROP: "Backdrop",
  ASSISTANT_DIRECTORS: "Assistant Directors",
  SPECIAL_ASSISTANT: "Special Assistant",
  PRODUCTION_EXECUTIVE: "Production Executive",
  PRODUCTION_MANAGER: "Production Manager",
  LOCATION_MANAGEMENT: "Location Management",
  PRODUCTION_ASSISTANTS: "Production Assistants",
  EASTMANCOLOR_BY: "Eastmancolor by",
  EQUIPMENT: "Equipment",
  OFFICE_MANAGEMENT: "Office Management",
  ANIMATION: "Animation",
  GRAPHIC_DESIGN: "Graphic Design",
  PUBLICITY_AND_LIASION: "Publicity & Liasion",
  STILLS: "Stills",
  SONGS: "Songs",
  DANCES: "Dances",
  DANCES_COMPOSED_AND_CHOREOGRAPHED_BY: "Dances composed and choreographed by",
  RE_RECORDING: "Re-recording",
  MUSIC_RECORDING: "Music Recording",
  NARRATION: "Narration",
  PRODUCER: "Producer",
  SCREENPLAY_MUSIC_AND_DIRECTION: "Screenplay, Music & Direction",
  PUBLISHED: "Published",
  BOOKLET:"Booklet"
};

export const siteInfo = {
  SITE_COPY_RIGHTS: "© Tuli Research Centre for India Studies, 2024",
  LAST_DEPLOYMENT_DATE: "Last Deployment",
  COMMIT_ID: "Commit Id",
  REPOSITORY_HOST: "github.com",
  PROJECTID: "2384",
  ACCESSTOKEN: "glpat-rb8fREfFbywYXpHudxWk",
  BRANCHNAME: "development",
  INDIANTIMEZONE: "Asia/Kolkata",
  DB_NAME: "Database Name",
};

export const indexTitle = {
  PUBLISHED_NUMBER: "Published Number",
  MASTERLIST_NAME: "INDEX | Masterlists | As on",
  TIME_AND_DATE: "On6 Pm WED, 26 Jan 2023",
  MASTERLIST_TYPE: "Masterlist Type",
  DATE_OF_PUBLISHING: "Date Of First Publishing",
  LAST_UPDATED: "Last Updated On",
};
export const button = {
  VIEW_ALL: "View All",
  INDEX: "Index",
  CLEAR_ALL: "Clear All",
  INSIGHT_GYAN:"INSIGHT | ज्ञान",
  INSIGHT_VIDEO:"INSIGHT Video",
};
export const screenBudget = {
  BUDGET: "Budget",
  COLLECTION_DETAILS: "Collection Details",
  DOMESTIC: "DOMESTIC",
  INTERNATIONAL: "INTERNATIONAL",
  WORLDWIDE: "WORLDWIDE",
  NAME_OF_DISTRIBUTOR: "Name of Distributor",
  NUMBER_OF_THEATRES_SCREENED_AT: "Number of theatres screened at",
  DOMESTIC_OPENING: "Domestic Opening",
  OVERALL_COLLECTION: "Overall Collection",
};

export const catalogues = {
  TIMELINE: "Timeline",
  CATALOGUES: "Catalogues",
  SHORT_DES:
    "The Sacred Search : A Select Collection of works from 1951 to 2002",
  DATE: "22nd to 28th February 2002, Apparao Art Gallery",
  PLACE: "Jehangir Art Gallery, Mumbai",
  TAB1: "Exhibition Catalogue",
  TAB2: "Artworks Exhibited",
  TAB3: "Exhibition Related Photographs",
};

export const whatsnew = {
  HEADING: "What’s New",
  SUB_HEADING: "on Osianamaworld.org",
  PUBLISHED: "Published",
  DATE: "17 January 2023",
  ARCHIVED: "Archived",
  COMING_SOON_LATER: "Coming Later Today",
  COMING_SOON: "Coming Soon",
};
export const AHP = {
  FOUNDERS: "Founder",
  PUBLISHERS: "Publishers",
  EDITORS: "Editors",
  CURRENT_DIRECTORS: "Current Directors",
  INDEX: "Index",
};
export const economicsarttabs = {
  AUCTIONS_SALE: "Top 50 Auctions Sales",
  COLLECTIONS: "Collections",
  ART_MARKET_ANALYSIS: "Art Market Analysis",
};

export const filterAttribute = {
  DATE: "Date",
  GATE:"Combine Terms",
  DOCUMENT_TYPE: "Document Type",
  PUBLISHING_HOUSE: "Publishing House",
  DATE_OF_PUBLICATION: "Date of Publication",
  AUTHOR_EDITOR_CONTRIBUTOR: "Author | Editor | Contributor",
  TYPE_OF_EXHIBITION: "Type of Exhibition",
  LAYOUT: "Layout",
  TITLE_OF_EXHIBITIONS: "Title of Exhibitions",
  ARTISTS: "Artists",
  VENUE_LOCATION: "Venue | Location",
  PUBLISHER: "Publisher",
  TYPE_OF_SCREENING: "Type of Screening",
  YEAR: "Year",
  CATEGORIES: "Categories",
  LANGUAGES: "Languages",
  FILM_TYPE: "Film Type",
  FILM_LENGTH: "Film Length",
  SORT_BY:"Sort By",
  EDITOR:"Editor",
  ARCHITECTURAL_STYLE:"Architectural Style",
  ARCHITECTURAL_MATERIAL:"Architectural Material",
  COMISSIONED_BY:"Comissioned By",
  MEDIUM:"Medium",
  PHOTOGRAPHERS_STUDIO:"Photographers & Studios",
  PRICE:"Price",
  YEAR_OF_ARTWORK:"Year of Artwork",
  YEAR_OF_SALE:"Year of Sale",
  VENUE:"Venue"
};

export const artheritage = {
  ART_HERITAGE_ANNUAL:" Art Heritage Annual",
  PREFACE:"Preface",
  ESTABLISHMENT:"Establishment",
  TEAM: "Team",
  PUBLISHERS_AND_EDITORS:"Publishers & Editors",
  HIGHLIGHTS:"Highlights",
  ANNUAL_EDITION: "Annual Editions",
  EXHIBITIONS: "Exhibitions",
  ESSAYS: "Essays",
  EXHIBITS: "Exhibits",
  ABOUT_THE_ESSAY:"About the Essay",
};

export const research_category = {
  INTRODUCTION_TAB_TITLE: "Introduction",
  CHRONOLOGY_TAB_TITLE: "Chronology",
  MASTERLISTS_TAB_TITLE: "Masterlists",
  POSTS_TAB_TITLE: "Posts",
  INSIGHTS_TAB_TITLE: "INSIGHTS",
  RESEARCH_CENTRE_TAB_TITLE: "Research Centre",
  VISIT_MASTERLIST:"Visit Masterlist",
  YEAR:"Year",
  MONTH:"Month",
  PERSONALITIES:"Personalities",
  INSTITUTIONS:"Institutions",
  EVENTS:"Events",
  STUDIO:"Studio",
  CLASSIFICATIONS:"Classifications",
  DATE:"Date",
  MEDIUM:"Medium",
  LOCATION:"Location",
};

export const userRegistrationForm = {
  REGISTRATION_FORM_TITLE: "Register to Tuli Research Centre",
  REGISTRATION_FORM_FIELD_NAME_LABEL: "User Name",
  REGISTRATION_FORM_FIELD_FULL_NAME_LABEL: "Full Name",
  REGISTRATION_FORM_FIELD_USERNAME_LABEL: "Username",
  REGISTRATION_FORM_BIRTHDATE_LABEL:"Date of Birth",
  REGISTRATION_FORM_FIELD_ADDRESS_LABEL:"Address",
  REGISTRATION_FORM_FIELD_EMAIL_ADDRESS_LABEL: "Email Address",
  REGISTRATION_FORM_FIELD_PASSWORD_LABEL: "Password",
  REGISTRATION_FORM_FIELD_CONFIRM_PASSWORD_LABEL: "Confirm Password",
  REGISTRATION_FORM_BUTTON_SUBMIT_LABEL: "Register",
  REGISTRATION_FORM_BUTTON_LOGIN_LABEL: "Log in",
  REGISTRATION_FORM_ALREADY_HAVE_ACCOUNT: "Already have an account?",
  LOGIN_FORM_TITLE: "Tuli Research Centre for India Studies",
  LOGIN_FORM_FORGOT:"Forget Password?",
  LOGIN_FORM_NEW_USER_REGISTRATION_LINK: "New User? Register Here",
  LOGIN_FORM_WELCOME_TO:"Welcome to",
  DO_NoT_HAVE_ACC_YET:"Don’t have account yet? Subscribe",
  SUBSCRIBE_HERE:"here",
  REGISTRATION_FORM_BUTTON_SUBSCRIBE_LABEL: "Subscribe",
  ALREADY_A_MEMBER:"Already a member? Log in",
  WELCOME:"Welcome",
  NEXT:"Next",
  PREVIEW:"Preview",
  CHOOSE:"Choose",
  FOUR_RESEARCH_CATEGORYS:"4 Research Categories",
  FOUR:"4",
  ONE_FROM_EACH_TYPE:"one from each Type - to start your journey of Self-Discovery",
  RESEARCH_CATEGORY_CORE:"Core",
  RESEARCH_CATEGORY_CRITICAL:"Critical",
  RESEARCH_CATEGORY_MEANINGFUL:"Meaningful",
  RESEARCH_CATEGORY_ESSENTIAL:"Essential",
  THE_MASTERLISTS:"the Masterlists",
  FOR_EACH_RESEARCH_CATEGORY:"for each Research Category",
  CONFIRM:"Confirm",
  GO_BACK:"Go Back",
  RESEARCH_CATEGORYS:"Research Categories",
  MASTERLISTS:"Masterlists",
  FIRST_NAME:"First Name(s)",
  LAST_NAME:"Last Name",
  AGE:"Age",
  EMAIL:"Email",
  EDUCTION_1:"Education 1",
  EDUCTION_2:"Education 2",
  EDUCTION_3:"Education 3",
  ALMOST_DONE_FORM:"Almost done! Please verify your details below ...",
  STEP:"Step",
  YES:"Yes",
  NO:"No",
  DO_YOU_WISH_TO_CONFIRM:"Do you wish to Confirm?",
  LOGIN_FORM_USERNAME_VALIDATION_MESSAGE:"Username/Email is required",
  LOGIN_FORM_PASSWORD_VALIDATION_MESSAGE:"Password is required",
  YOU_HAVE_CHOOSEN:"You have chosen the following Research Categories",
  PHONE:"Phone Number",
  CITY:"City of Residence",
  ADDRESS:"Address"
}

export const location = {
  MONUMNETS_AND_HERITAGE:"Monuments & Heritage Sites",
  OVERVIEW:"Overview",
  RELATED_ARTWORKS:"Related Artworks",
  PATRON:"Patron",
  STRUCTURE:"Structure",
  LAYOUT:"Layout",
  MATERIALS_USED:"Materials Used",
  EVENT:"Event",
  GEOGRAPHICAL_DETAILS:"Geographical Details",
  LANDMARK:"Landmark"
}
export const userProfile = {
  LOGIN_INSTRUCTION: "Please login to access your profile",
  CLICK_HERE:"Click here"
}
export const eventExhibition = {
  ARTWORKS_FROM_CATALOUGES: "Comparable Artworks in the Market",
  COMPARABLE_ARTWORKS:"Comparable Artworks"
} 
export const Profile = {
  POINTS: "points",
  MORE_POINTS_REQUIRED:"more points needed to become an Explorer",
  CURRENT_POINTS:"Current points: ",
  TOTAL_POINTS_REQUIRED:"Total points required: ",
  BOOKMARKS:"Bookmarks"
}
export const Search = {
  SEARCH_RESULT_TEXT: "You have searched for the word ",
  MASTERLIST:"Masterlist"
} 
export const Quizs = {
  SHARE:"Share",
  PROFILE:"Profile",
  RETRY:"Retry",
  READ_MORE_ABOUT:"Read More about",
  YOU_ARE:"You're",
  POINTS_AWAY:"points away from the next rank in today puzzle"
}
export const routeType={
 PERSON_ACTOR: "person-actor",
 PERSON_ARTIST: "person-artist",
 PERSON_POLYMATH: "person-poly",
 PERSON_FILMMAKER: "person-filmmaker",
 FILM: "film",
 LOCATION:"location"
}
export const userProfileStats={
  INSIGHT_COMPLETED:"INSIGHTS Completed",
  QUESTION_ANSWERED:"Questions Answered",
  READINGS:"Readings",
  TIME_SPENT:"Time Spent",
  POINTS:"Points"
}
export const osiansCinefan={
  PREFACE:"Preface",
  PERSONALIA: "Personalia",
  ASIAN_ARAB_COMPETITION_JURY: "Asian Arab Competition Jury",
  INDIAN_COMPETITION_JURY: "Indian Competition Jury",
  FIRST_FEATURES_COMPETITION_JURY: "First Features Competition Jury",
  OVERVIEW:"Overview",
  MEDIA_REPORTAGE:"Media & Reportage",
  CATALOGUE:"Catalogue",
  PROGRAMMES:"Programmes",
  SCREENINGS:"Screenings",
  SEMINARS_AND_CONFERENCES:"Seminars and Conferences",
  TALENT_CAMPUS_AND_WORKSHOPS:"Talent Campus and Workshops",
  AUCTIONS:"Auctions",
  PRINT:"Print",
  RADIO:"Radio",
  TELEVISION:"Television",
  SOCIAL_MEDIA:"Social Media",
  EVENT_PHOTOGRAPHS:"Event Photographs",
  AUCTION_CATALOGUE:"Auction Catalogue",
  FILM_FESTIVAL_BOOKLET:"Film Festival Booklet",
}
export const monument={
  PREFACE:"Preface",
  LOCATION:"Location",
  STATUS:"Status",
  ARCHITECTURAL_STYLE:"Architectual Style",
  HISTORICAL_BACKGROUND:"Historical Background",
  ARCHITECTURE:"Architecture",
  CULTURAL_SIGNIFICANCE:"Cultural Significance"
}