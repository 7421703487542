import {
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";

//Core files
import React from "react";

//Component files
import Synopsis from "../../Film/BodyOfWork/Synopsis"
import { LyricsSoundtrack } from "../../Film/BodyOfWork/Soundtrack"

//Language files
import { explore } from "../../../constants/constants";

function BookletSongs({masterlistType,masterlist,bookletAlias}) {
    
  return (
    <Tabs py={{base:1,md:4}}>
    <TabList
      position="sticky"
      zIndex={99}
      width={{base:"100%",md:"100%"}}
      top={{base:"64px",md:"90px"}}
      px="0px"
      py="2"
      bg="white"
    >
      <Tab
        className="Timeline"
        p="0"
        mr="4"
        fontSize={"16px"}
        fontWeight={"400"}
        _selected={{
          borderBottom: "4px",
          color: "black",
          borderBottomColor: "black",
          fontWeight: "700",
          borderRadius: "4px",
        }}
      >
        {explore.SYNOPSIS}
      </Tab>
      <Tab
        className="Timeline"
        p="0"
        ml="4"
        fontSize={"md"}
        fontWeight={"400"}
        _selected={{
          borderBottom: "4px",
          color: "black",
          borderBottomColor: "black",
          fontWeight: "700",
          borderRadius: "4px",
        }}
      >
        {explore.LYRICS_AND_SOUNDTRACK}
      </Tab>
    </TabList>
    <TabPanels height={{base:"100vh",md:"calc(100vh - 130px)"}}>
      <TabPanel px="0px" py={0}>
      <Synopsis masterlist={bookletAlias} tableName={"film"}/>
      </TabPanel>
      <TabPanel px="0px" py={0}>
      <LyricsSoundtrack winnerName={bookletAlias} />
      </TabPanel>
    </TabPanels>
  </Tabs>
  );
}

export default BookletSongs;