import { isTransformationNotAllowed } from "../util/isTranformationAllowed";
import api from "../services/api";
import { APIS } from "../endpoints";
export const genericIntroductionByAliasAPI = async (masterlistType, masterlist) => {
    const result = await api.get(`${APIS.BASE_URL}${masterlistType}/${masterlist}/isMasterlist`)
    if (result) {
      return result?.data?.data;
    } else {
      return [];
    }
  };
  
  export const genericIntroductionByAlias = async (masterlistType, masterlist) => {
    if (isTransformationNotAllowed() === true) {
      const response = await genericIntroductionByAliasdummydata(masterlistType, masterlist);
      return response;
    }else if (isTransformationNotAllowed() === false)  {
      const response = await genericIntroductionByAliasAPI(masterlistType, masterlist);
      try {
        if (response) {
          return response;
        } else {
          console.error("Invalid response data structure:", response);
          return [];
        }
      } catch (e) {
        console.error("Something is wrong", e);
      }
    }
  };
  
  export const genericIntroductionByAliasdummydata = (masterlistType, masterlist) => {

    // Check if the masterlist exists in the map, if not, use a default value
    const introductionData =  [];
    return introductionData;
  };