import { Stack } from "@chakra-ui/react";

//Core files
import React, { useState } from "react";

//Component files
import GridView from "./Library/GridView";
import TabularView from "./Library/TabularView";

export const Library = ({
  visible,
  handleBookClick,
  setleftFlag,
  openDrawer,
  setOpenDrawer,
  tableName,
  masterlist,
}) => {
  const [contentClicked, setContentClicked] = useState(false);
  const [scanClicked, setScanClicked] = useState(false);

  return (
    <Stack position={"relative"}>
      {visible ? (
        <>
          <GridView
            openDrawer={openDrawer}
            setOpenDrawer={setOpenDrawer}
            handleBookClick={handleBookClick}
            contentClicked={contentClicked}
            scanClicked={scanClicked}
            setContentClicked={setContentClicked}
            setScanClicked={setScanClicked}
            setleftFlag={setleftFlag}
            tableName={tableName}
            masterlist={masterlist}
          />
        </>
      ) : (
        <>
          <TabularView
            openDrawer={openDrawer}
            setOpenDrawer={setOpenDrawer}
            handleBookClick={handleBookClick}
            contentClicked={contentClicked}
            scanClicked={scanClicked}
            setContentClicked={setContentClicked}
            setScanClicked={setScanClicked}
            setleftFlag={setleftFlag}
            tableName={tableName}
            masterlist={masterlist}
          />
          {/* Need in future */}
          {/* {
          contentClicked ? <DemoModal contentClicked={contentClicked} setContentClicked={setContentClicked}/>:
          scanClicked && <SliderModal scanClicked={scanClicked} setScanClicked={setScanClicked}/>
        } */}
        </>
      )}
    </Stack>
  );
};
