import { Text } from "@chakra-ui/react";
import React from "react";

const H5 = ({ children, ...props }) => {
  return (
    <Text
      as="h5"
      {...props}
      fontSize="14px"
      fontWeight="700"
      lineHeight={"16px"}
    >
      {children}
    </Text>
  );
};
export default React.memo(H5);
