export const satyajit_masterlist_options = [
  { type: "C.D.T" },
  { type: "Event" },
  { type: "Film" },
  { type: "Idea" },
  { type: "Institution" },
  { type: "Location" },
  { type: "Personality" },
  { type: "Price" },
  { type: "Research Category" },
  { type: "Song" },
  { type: "Year" },
];
export const skk_masterlist_options = [
  { type: "Event" },
  { type: "Film" },
  { type: "Idea" },
  { type: "Institution" },
  { type: "Location" },
  { type: "Object", dropdown: true },
  { type: "Personality", dropdown: true },
  { type: "Price" },
  { type: "Song" },
];
export const art_heritage_masterlist_options = [
  { type: "Event" },
  { type: "Film" },
  { type: "Idea" },
  { type: "Institution" },
  { type: "Location" },
  { type: "Object", dropdown: true },
  { type: "Personality", dropdown: true },
  { type: "Price" },
  { type: "Song" },
];

export const mughal_e_azam_masterlist_options = [
  "Event",
  "Film",
  "Idea",
  "Institution",
  "Location",
  "Object",
  "Personality",
  "Price",
  "Song",
];
export const sanjeev_kumar_masterlist_options = [
  { type: "C.D.T" },
  { type: "Event" },
  { type: "Film" },
  { type: "Idea" },
  { type: "Institution" },
  { type: "Location" },
  { type: "Personality" },
  { type: "Price" },
  { type: "Research Category" },
  { type: "Song" },
  { type: "Year" },
];
