  import _ from "lodash";
import {
  gallery_Chemould_catalouge_data,
  kg_subramanym_Authored_Edited_Books_data,
  kg_subramanym_awards_data,
  kg_subramanym_exhibitions_data,
  kg_subramanym_illustrated_data,
  kg_subramanym_top_auctions_data,
  national_galley_of_moder_art_catalouge_data,
  sh_raza_Art_Market_Analysis,
  sh_raza_awards_data,
  sh_raza_exhibitions_data,
  sh_raza_exhibitions_filter_options,
  sh_raza_top_auctions_data,
} from "../MockData/PersonalityArtist";
import { isTransformationNotAllowed } from "../util/isTranformationAllowed";
import api from "../services/api";
import { APIS } from "../endpoints";

//Exhibitions
export const getExhibitionsFilterOptionsAPI = async (tableName, masterlist) => {
  return [];
};

export const getExhibitionsFilterOptions = async (tableName, masterlist) => {
  if (isTransformationNotAllowed() === true) {
    const FilterOptions = await getExhibitionsFilterOptionsDummyData(
      masterlist
    );
    return FilterOptions;
  } else if (isTransformationNotAllowed() === false) {
    const response = await getExhibitionsFilterOptionsAPI(
      tableName,
      masterlist
    );
    const FilterOptions = {
      filmTypeFilter: [
        ...new Set(response.map((item) => item.film_type.trim())),
      ].filter((value) => value !== ""),
      filmLengthFilter: [
        ...new Set(response.map((item) => item.film_length.trim())),
      ].filter((value) => value !== ""),
      languageFilter: [
        ...new Set(
          response.flatMap((item) =>
            item.language.map((lang) => lang.name.trim())
          )
        ),
      ].filter((value) => value !== ""),
    };

    return FilterOptions;
  }
};

const getExhibitionsFilterOptionsDummyData = (tableName, alias) => {
  const exhibitionsDataMap = {
    "sayed-haider-raza": sh_raza_exhibitions_filter_options,
    // Add more cases as needed
  };
  // Check if the masterlist exists in the map, if not, use a default value
  const exhibitionsData =
    exhibitionsDataMap[alias] || sh_raza_exhibitions_filter_options;
  return exhibitionsData;
};

// export const getExhibitionsDataByMasterlistAPI = async (
//   tableName,
//   masterlist,
//   filterOptions = {},
//   searchTerm = ""
// ) => {
//   return [];
// };


export const getExhibitionsDataByMasterlistAPI = async (
  masterlistType,masterlist,filterOptions, page
) => {
  const formData = new FormData();
  formData.append('filters', JSON.stringify(filterOptions));
  formData.append('page_number', page);
  const params = new URLSearchParams(formData);
  const result = await api.get(`${APIS.BASE_URL}${masterlistType}/${masterlist}/${APIS.exhibitions}`,{
    params: params
})

console.log("resFafult", result?.data);
  if (result) {
    return result?.data;
  } else {
    return [];
  }
};



export const getExhibitionsDataByMasterlist = async (
  masterlistType,masterlist,filterOptions, page
) => {
  if (isTransformationNotAllowed() === true) {
    const { mockData, filterMockData } =
      await getExhibitionsDataByMasterlistDummy(masterlist);
    return { mockData, filterMockData };
  } else {
    // const filteredData = Object.fromEntries(
    //   Object.entries(filterOptions).filter(([_, v]) => v.length > 0)
    // );
    const response = await getExhibitionsDataByMasterlistAPI(
      masterlistType,masterlist,filterOptions, page
    );

    try {
      if (response) {
        return response;
      } else {
        console.error("Invalid response data structure:", response);
        return [];
      }
    } catch (e) {
      console.error("Something is wrong", e);
    }
  }
};

export const getExhibitionsDataByMasterlistDummy = async (masterlist) => {
  const exhibitionsData = {
    "sayed-haider-raza": sh_raza_exhibitions_data,
    "k.g-Subramanyan":kg_subramanym_exhibitions_data

    // Add more cases as needed
  };
  // Check if the masterlist exists in the map, if not, use a default value
  const mockData = exhibitionsData[masterlist] || sh_raza_exhibitions_data;
  const filterMockData = (filterCategories, searchTerm) => {
    if (
      filterCategories.languages_v.length > 0 ||
      filterCategories.filmTypes_v.length > 0 ||
      filterCategories.filmLength_v.length > 0 ||
      searchTerm !== "" ||
      searchTerm !== undefined
    ) {
      const filteredData = _.filter(mockData, (entry) => {
        const languageCheck =
          filterCategories.languages_v.length > 0
            ? entry
                .find((item) => item.key === "language")
                .value.map((it) => it.name)
                .some((lang) => filterCategories.languages_v.includes(lang))
            : true;
        const filmTypeCheck =
          filterCategories.filmTypes_v.length > 0
            ? filterCategories.filmTypes_v.includes(
                entry.find((item) => item.key === "film_type").value
              )
            : true;

        const filmLengthCheck =
          filterCategories.filmLength_v.length > 0
            ? filterCategories.filmLength_v.includes(
                entry.find((item) => item.key === "film_length").value
              )
            : true;

        const searchTermCheck =
          searchTerm !== ""
            ? entry
                .find((item) => item.key === "language")
                .value.map((it) => it.name)
                .some((lang) =>
                  lang.toLowerCase().includes(searchTerm.toLowerCase())
                ) ||
              entry
                .find((item) => item.key === "film_type")
                .value.toLowerCase()
                .includes(searchTerm.toLowerCase()) ||
              entry
                .find((item) => item.key === "film_length")
                .value.toLowerCase()
                .includes(searchTerm.toLowerCase())
            : true;
        return (
          languageCheck && filmTypeCheck && filmLengthCheck && searchTermCheck
        );
      });
      return filteredData;
    } else {
      return mockData;
    }
  };

  return {
    mockData,
    filterMockData,
  };
};


//Awards Honours Service
export const getAwardHonoursDataAPI = async (masterlistType, winner, page) => {
  const formData = new FormData();
  formData.append('page_number', page);
  const params = new URLSearchParams(formData);

  const result = await api.get(`${APIS.BASE_URL}${masterlistType}/${winner}/${APIS.awards}`, 
    {
      params: params
    })
  if (result) {
    return result?.data;
  } else {
    return [];
  }
};

export const getAwardHonoursData = async (masterlistType, masterlist, page) => {
  if (isTransformationNotAllowed() === true) {
    const FilterOptions = await getAwardHonoursDataDummyData(masterlist);
    return FilterOptions;
  } else if (isTransformationNotAllowed() === false) {
    // const response = await getAwardHonoursDataAPI(tableName, masterlist, page);
    // const FilterOptions = {
    //   filmTypeFilter: [
    //     ...new Set(response.map((item) => item.film_type.trim())),
    //   ].filter((value) => value !== ""),
    //   filmLengthFilter: [
    //     ...new Set(response.map((item) => item.film_length.trim())),
    //   ].filter((value) => value !== ""),
    //   languageFilter: [
    //     ...new Set(
    //       response.flatMap((item) =>
    //         item.language.map((lang) => lang.name.trim())
    //       )
    //     ),
    //   ].filter((value) => value !== ""),
    // };

    //return FilterOptions;
    const response = await getAwardHonoursDataAPI(masterlistType, masterlist, page);
    try {
      if (response) {
        return response;
      } else {
        console.error("Invalid response data structure:", response);
        return [];
      }
    } catch (e) {
      console.error("Something is wrong", e);
    }
  }
};

const getAwardHonoursDataDummyData = (alias) => {
  const AwardsHonoursMap = {
    "sayed-haider-raza": sh_raza_awards_data,
    "k.g-Subramanyan":kg_subramanym_awards_data
    // Add more cases as needed
  };
  // Check if the masterlist exists in the map, if not, use a default value
  const AwardsHonours = AwardsHonoursMap[alias] || sh_raza_awards_data;
  return AwardsHonours;
};

// Top Auction Sales

export const getTopAuctionSalesbyMasterlistAPI = async (
  tableName,
  masterlist,
  filterOptions = {},
  searchTerm = ""
) => {
  return [];
};

export const getTopAuctionSalesbyMasterlist = async (
  tableName,
  masterlist,
  filterOptions = {},
  searchTerm = ""
) => {
  if (isTransformationNotAllowed() === true) {
    const { mockData, filterMockData } =
      await getTopAuctionSalesbyMasterlistDummy(masterlist);
    return { mockData, filterMockData };
  } else {
    const filteredData = Object.fromEntries(
      Object.entries(filterOptions).filter(([_, v]) => v.length > 0)
    );
    const response = await getTopAuctionSalesbyMasterlistAPI(
      tableName,
      masterlist,
      filteredData,
      searchTerm
    );

    try {
      if (response) {
        return [];
      } else {
        console.error("Invalid response data structure:", response);
        return [];
      }
    } catch (e) {
      console.error("Something is wrong", e);
    }
  }
};

export const getTopAuctionSalesbyMasterlistDummy = async (masterlist) => {
  const topAuctionSales = {
    "sayed-haider-raza": sh_raza_top_auctions_data,
    "k.g-Subramanyan":kg_subramanym_top_auctions_data
    // Add more cases as needed
  }
  const mockData = topAuctionSales[masterlist] || sh_raza_awards_data;
    const filterMockData = (filterCategories, searchTerm) => {
    if (
      filterCategories.languages_v.length > 0 ||
      filterCategories.filmTypes_v.length > 0 ||
      filterCategories.filmLength_v.length > 0 ||
      searchTerm !== "" ||
      searchTerm !== undefined
    ) {
      const filteredData = _.filter(mockData, (entry) => {
        const languageCheck =
          filterCategories.languages_v.length > 0
            ? entry
                .find((item) => item.key === "language")
                .value.map((it) => it.name)
                .some((lang) => filterCategories.languages_v.includes(lang))
            : true;
        const filmTypeCheck =
          filterCategories.filmTypes_v.length > 0
            ? filterCategories.filmTypes_v.includes(
                entry.find((item) => item.key === "film_type").value
              )
            : true;

        const filmLengthCheck =
          filterCategories.filmLength_v.length > 0
            ? filterCategories.filmLength_v.includes(
                entry.find((item) => item.key === "film_length").value
              )
            : true;

        const searchTermCheck =
          searchTerm !== ""
            ? entry
                .find((item) => item.key === "language")
                .value.map((it) => it.name)
                .some((lang) =>
                  lang.toLowerCase().includes(searchTerm.toLowerCase())
                ) ||
              entry
                .find((item) => item.key === "film_type")
                .value.toLowerCase()
                .includes(searchTerm.toLowerCase()) ||
              entry
                .find((item) => item.key === "film_length")
                .value.toLowerCase()
                .includes(searchTerm.toLowerCase())
            : true;
        return (
          languageCheck && filmTypeCheck && filmLengthCheck && searchTermCheck
        );
      });
      return filteredData;
    } else {
      return mockData;
    }
  };

  return {
    mockData,
    filterMockData,
  };
};

export const getTopAuctionSalesDataAPI = async (masterlistType, masterlist, filterOptions, page) => {
  const formData = new FormData();
  formData.append('filters', JSON.stringify(filterOptions));
  formData.append('page_number', page);
  const params = new URLSearchParams(formData);

  const result = await api.get(`${APIS.BASE_URL}${masterlistType}/${masterlist}/${APIS.editions}`, {
    params: params
  })
  if (result) {
    return result?.data;
  } else {
    return [];
  }
};

export const getTopAuctionSalesData = async (masterlistType, masterlist, filterOptions, page) => {
    const response = await getTopAuctionSalesDataAPI(masterlistType, masterlist, filterOptions, page);
    return response;
  };

// const getTopAuctionSalesFilterOptionsDummyData = (tableName, alias) => {
//   const exhibitionsDataMap = {
//     "sayed-haider-raza": sh_raza_top_auctions_filter_options,
//     // Add more cases as needed
//   };
//   // Check if the masterlist exists in the map, if not, use a default value
//   const exhibitionsData =
//     exhibitionsDataMap[alias] || sh_raza_top_auctions_filter_options;
//   return exhibitionsData;
// };

//Art MArket Analysis Service
export const getArtMArketAnalysisDataAPI = async (tableName, masterlist) => {
  return [];
};

export const getArtMArketAnalysisData = async (tableName, masterlist) => {
  if (isTransformationNotAllowed() === true) {
    const data = await getArtMArketAnalysisDataDummyData(masterlist);
    return data;
  } else if (isTransformationNotAllowed() === false) {
   

  }
};

const getArtMArketAnalysisDataDummyData = (tableName, alias) => {
  const ArtMArketAnalysisMap = {
    "sayed-haider-raza": sh_raza_Art_Market_Analysis,
    // Add more cases as needed
  };
  // Check if the masterlist exists in the map, if not, use a default value
  const ArtMArketAnalysis = ArtMArketAnalysisMap[alias] || sh_raza_Art_Market_Analysis;
  return ArtMArketAnalysis;
};



//History of Highs
export const getToprecordsbyMasterlistAPI = async (
  tableName,
  masterlist,
  filterOptions = {},
  searchTerm = ""
) => {
  return [];
};

export const getToprecordsbyMasterlist = async (
  tableName,
  masterlist,
  filterOptions = {},
  searchTerm = ""
) => {
  if (isTransformationNotAllowed() === true) {
    return getToprecordsbyMasterlistDummy(masterlist);
  } else {
    return getToprecordsbyMasterlistAPI(masterlist)
  }
};

export const getToprecordsbyMasterlistDummy = async (masterlist) => {
   const ToprecordsMap = {
    "sayed-haider-raza": sh_raza_top_auctions_data,
    // Add more cases as needed
  };
  // Check if the masterlist exists in the map, if not, use a default value
  const Toprecords = ToprecordsMap[masterlist] || sh_raza_top_auctions_data;
  return Toprecords;
  
};


//illustrated Book Data

export const illustratedBookData = async (tableName, alias) => {
  if (isTransformationNotAllowed() === true) {
    const response = await illustratedBookDataDummy(tableName, alias);
    return response;
  }else if (isTransformationNotAllowed() === false) {
    
};
}

export const illustratedBookDataDummy = async (tableName, alias) => {  
  const illustratedBookDataMap = {
      "k.g-Subramanyan":kg_subramanym_illustrated_data
      // Add more cases as needed
    };
    // Check if the masterlist exists in the map, if not, use a default value
    const illustratedBookData = illustratedBookDataMap[alias] || kg_subramanym_illustrated_data;
    return illustratedBookData;
};


// kg_subramanym_Authored_Edited_Books_data

export const AuthoredEditedBooks = async (tableName, alias) => {
  if (isTransformationNotAllowed() === true) {
    const response = await AuthoredEditedBooksDataDummy(tableName, alias);
    return response;
  }else if (isTransformationNotAllowed() === false) {
    
};
}

export const AuthoredEditedBooksDataDummy = async (tableName, alias) => {  
  const AuthoredEditedBooksDataMap = {
      "k.g-Subramanyan":kg_subramanym_Authored_Edited_Books_data
      // Add more cases as needed
    };
    // Check if the masterlist exists in the map, if not, use a default value
    const AuthoredEditedBooksData = AuthoredEditedBooksDataMap[alias] || kg_subramanym_Authored_Edited_Books_data;
    return AuthoredEditedBooksData;
};



// catalouge

export const catalougeDatabyExhibition = async (tableName, masterlist,galleryName) => {
  if (isTransformationNotAllowed() === true) {
    const response = await catalougeDataDummy(tableName, masterlist,galleryName);
    return response;
  }else if (isTransformationNotAllowed() === false) {
    
};
}

export const catalougeDataDummy = async (tableName, masterlist,galleryName) => {  
  const catalougeDataMap = {
      // Add more cases as needed
      "Gallery Chemould":gallery_Chemould_catalouge_data,
      "National Gallery of Modern Art":national_galley_of_moder_art_catalouge_data

    };
    // Check if the masterlist exists in the map, if not, use a default value
    const catalougeData = catalougeDataMap[galleryName] || gallery_Chemould_catalouge_data;
    return catalougeData;
};