import { HStack } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import MediumBold from "../Theme/Typography/MediumBold";

export const VerticalMenu=({sections})=>{
    return(
      <>
      {sections.map((section) => {
        let isActive = window.location.href.includes(section.title);
        return (
          <HStack
            key={section.to}
            justifyContent={isActive === true ? "space-between" : ""}
            bg={isActive === true ? "#f6f6f6" : "white"}
            borderLeft={isActive === true ? "4px" : ""}
            borderColor={isActive === true ? "#FF4E4E" : ""}
            borderRadius={isActive === true ? "0px" : ""}
          >
            {isActive ? (
              <MediumBold lineHeight={"16.44px"} py="2" px="3" color={"black"}>
                <Link
                  to={section.to}
                  _hover={{
                    textDecoration: "none",
                  }}
                >
                  {section.text}
                </Link>
              </MediumBold>
            ) : (
              <MediumBold fontWeight="400" lineHeight={"16.44px"}  py="1" px="4" color={"black"}>
                <Link
                  to={section.to}
                  _hover={{
                    textDecoration: "none",
                  }}
                >
                  {section.text}
                </Link>
              </MediumBold>
            )}
          </HStack>
        );
      })}
      </>
    )
   }