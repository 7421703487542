import { Text } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { CopyPasteService } from "../../services/CopyPaste";

export const ChakraLink = ({ href, children }) => (
    <Link to={`/${href}`} >
      <Text
        as={"span"}
        fontSize={"16px"}
        fontWeight="400"
        textAlign="justify"
        lineHeight={"18.78px"}
        color={"color.blue"}
        onCopy={(e) => CopyPasteService(e, `${window.location.href}`)}
      > 
        {children}
      </Text>
    </Link>
  );