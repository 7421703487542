import { Box, HStack, Image, Modal, ModalBody, ModalContent, ModalOverlay, Stack, Text } from "@chakra-ui/react";

//Core files
import React, { useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectFade, EffectFlip, Navigation, Pagination } from "swiper";

// Service files
import * as getAwardData from "../../../services/AwardsHonours";

//Component files
import TimeLine from "../../../FunctionalComponents/TimeLine/TimeLine";
import TimelineWithImage1 from "../../../FunctionalComponents/TimeLine/TimelineWithImage1";

//Language files
import { common } from "../../../constants/constants";
import InfiniteScroll from "react-infinite-scroll-component";
import NoMoreToLoad from "../../../CommonComponents/NoMoreToLoad";
import { CgArrowsExpandRight } from "@react-icons/all-files/cg/CgArrowsExpandRight";
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;
const loaderImg = `${IMAGE_BASE_PATH}/ui/Chevron/loader.gif`

function AwardsHonours({ CopyPasteService, masterlistType, masterlist ,setAwardCount}) {
  const [swiper, setSwiper] = useState(null);
  const [iconClicked, setIconClicked] = useState(false);
  const [imageData, setImageData] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const initialLoadComplete = useRef(false);
  const [loading, setLoading] = useState(true);
  const [fullScreenImage, setFullScreenImage] = useState(false);
  const [imgData, setImgData] = useState(null);
  const [clickedImageIndex, setClickedImageIndex] = useState(null);


  const showFullImages = (image, flag, index) => {
    if (image) {
      setClickedImageIndex(index);
      setFullScreenImage(flag);
      setImgData(image);
    } else {
      setFullScreenImage(flag);
      setImgData(null);
    }
  };
  const showImage = (index) => {
    swiper.slideTo(index);
    setTimeout(() => {
      setIconClicked(!iconClicked);
    }, 500);
  };
  
  const extractedImages = (data) => {
    let extractImg = data?.flatMap((entry) => {
      const awardImage = entry?.find(
        (item) => item.key === "awardImage"
      );
      if (awardImage && awardImage?.value.length > 0) {
        return awardImage?.value?.map((image) => image);
      }
      return [];
    });
    return extractImg;
  };
  const [awardsHonours, setAwardsHonours] = useState([]);

  const getData = async () => {
    setLoading(true)
    try {
    const result = await getAwardData.awardshonoursByWinner(
      masterlistType,
      masterlist,
      page
    );
    setLoading(false)

    if (result && result?.data?.length > 0) {
      setAwardsHonours(result?.data);
      setImageData(extractedImages(result?.data));
      if (!initialLoadComplete.current) {
        setPage((prevPage) => prevPage + 1);
        initialLoadComplete.current = true;
      }
      setAwardCount(result?.total)
    }
  }catch (error) {
    console.error('Error fetching data:', error);
    setLoading(false);
    setAwardsHonours([])

}
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, []);

  const fetchMoreData = async () => {
    try {
          const result = await getAwardData.awardshonoursByWinner(
            masterlistType,
            masterlist,
            page
          );
  
          if (result && result?.data) {
            const processedData = result?.data;
            setAwardsHonours((prevData) => [...prevData, ...processedData]);
            setPage(prevPage => prevPage + 1);

            if (awardsHonours.length >= result?.total) {
              setHasMore(false);
            }            
          }
        } catch (error) {
            console.error('Error fetching data:', error);
      }
  }

  return (
    <>
   {!loading ? <>
      {awardsHonours && awardsHonours.length !== 0 ? (
        <Stack py="4" justifyContent={"space-between"} flexDirection={{base:"column",md:"row"}} px="0px">
          <HStack
            flexDirection={"column"}
            width={{ base: "100%", md: "1035px" }}
            alignItems={"baseline"}
            onCopy={(e) => CopyPasteService(e, `${window.location.href}/1`)}
          >
            <Stack width={"100%"} flexDirection={"row"}>
            <InfiniteScroll
                  dataLength={awardsHonours.length}
                  next={fetchMoreData}
                  hasMore={hasMore}
                  loader={<Box textAlign="center" marginLeft={"50%"}>
                  <Stack marginTop={"20px"}>
                     <img src={loaderImg} width={"100px"} height={"100px"} alt="Loading" />
                  </Stack>
                </Box>}
                  endMessage={<NoMoreToLoad/>}
            >
            <TimeLine
            data={awardsHonours}
            showImage={showImage}
            component={TimelineWithImage1}
          />
            </InfiniteScroll>
            </Stack>
          </HStack>
          <HStack
            px={"0px"}
            width={{ base: "100%", md: "320px" }}
            alignItems={"flex-start"}
            justifyContent={"flex-end"}
          >
            <Stack
              className="col-12 mt-2"
              id="allslidersatyajitAward"
              position={"relative"}
            >
              <HStack className={!iconClicked ? "imageZoomOut" : "imageZoomIn"}>
                <Swiper
                  grabCursor={true}
                  navigation={true}
                  modules={[EffectFlip, Navigation, Pagination]}
                  onSwiper={setSwiper}
                >
                  {imageData && imageData?.length>0 &&
                    imageData?.map((it,id) => {
                      return (
                        <SwiperSlide>
                      <Box height={"auto"} width={"244px"}>
                        <Image
                          onClick={(e) => showFullImages(it, true, id)}
                          src={it}
                          cursor={"pointer"}
                        />
                      </Box>
                    </SwiperSlide>
                      );
                    })}
                </Swiper>
              </HStack>
            </Stack>
          </HStack>
          <ImagePopupModal
        isOpen={fullScreenImage}
        onClose={() => showFullImages(null, false)}
        imageData={imageData}
        imgData={imgData}
        clickedImageIndex={clickedImageIndex}
        imageHeight={{ base: "100%", md: "90vh" }}
      />
        </Stack>
      ) : (
        <Stack alignItems={"center"} height={"100vh"}>
          <Text py={"40"} fontSize={"45px"} fontWeight="700" textAlign="center">
            {common.NO_DATA}
          </Text>
        </Stack>
      )}
    </> :
    <>
    <HStack justifyContent={"center"} minH={"100vh"} alignItems={"flex-start"} mt={4}>
          <Image src={loaderImg} width={"100px"} height={"100px"} alt="Loading" />
      </HStack>
    </>
}
    </>
  );
}

export default AwardsHonours;

export const ImagePopupModal = ({
  isOpen,
  onClose,
  imageData,
  data,
  clickedImageIndex,
  imageHeight,
}) => {
  return (
    <Modal
      size={"full"}
      isCentered
      isOpen={isOpen}
      onClick={onClose}
      Id="modalfullscreen"
    >
      <ModalOverlay />
      <ModalContent zIndex={"99999"}>
        <ModalBody>
          <Box bg="white" mx={{ base: "0", md: "10" }}>
            <Stack>
              <Swiper
                slidesPerView={1}
                navigation
                initialSlide={clickedImageIndex}
                id="allsliderskk1"
                modules={[EffectFade, Navigation, Pagination]}
              >
                {imageData?.map((item, index) => {
                  return (
                    <SwiperSlide key={index + 1}>
                      <HStack
                        justifyContent={"center"}
                        flexDirection={"column"}
                        marginTop={"15px"}
                        height={"100vh"}
                      >
                        <Image
                          src={
                            item
                          }
                          height={imageHeight ? imageHeight : "100%"}
                          alt=""
                        />
                        <HStack paddingTop="10px">
                          <Text
                            pt={"8px"}
                            fontSize={"15px"}
                            fontWeight={"400"}
                            lineHeight={"17px"}
                            color="#035DA1"
                          >
                            {item.title}
                          </Text>
                        </HStack>
                      </HStack>
                      <HStack
                        justifyContent="flex-end"
                        position="absolute"
                        right="00"
                        top={{ base: "80%", md: "95%" }}
                        marginRight={{ base: "0px", md: "160px" }}
                        zIndex={"999"}
                        transform="translateY(-50%)"
                        paddingRight={"30px"}
                      >
                        <Text
                          fontSize={"xl"}
                          bg={"black"}
                          opacity={{ base: "1", md: "0.6" }}
                          color={"white"}
                          fontWeight={"400"}
                          padding="2"
                          cursor={"pointer"}
                          borderRadius={"35px"}
                          width="max-content"
                          onClick={onClose}
                        >
                          <CgArrowsExpandRight />
                        </Text>
                      </HStack>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </Stack>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};