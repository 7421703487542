import React from "react";
import { HStack, Stack } from "@chakra-ui/react";

import { Link } from "react-router-dom";
import { button } from "../../constants/constants";
// import SearchCar from "./SearchCar";
import { LibraryAndArchive } from "./ResearchCentre/LibraryAndArchive";
import H1 from "../../Theme/Typography/H1";
import H3 from "../../Theme/Typography/H3";

const Carousal = ({ data,searchsTerm,allCount, setAllCount }) => {

  return (
    <HStack py={2}>
      <Stack width={"100%"} position={"relative"} id="allslider">
        {data &&
          // data.map((data, index) => {
          //   return (
              <>
              {data.hits?.length > 0 && (<Stack pt={2}>
                <Stack flexDirection={"row"} gap={"15px"}>
                  <HStack alignItems={"flex-end"}>
                  <H1 fontSize={"22px"} lineHeight="26px">
                      {`${data.index} `}
                    </H1>
                    <Link to={`/search-result?query=${encodeURIComponent(searchsTerm)}&type=${encodeURIComponent(data.index)}`}>
                      <H1 fontSize={"22px"} lineHeight="26px">{data?.index!=="Research Centre" ?`(${data.count})`:allCount>0 && `(${allCount})`}</H1>
                      </Link>
                  </HStack>
                  <HStack>
                    {data?.index!=="Research Centre"  && data.count>10 && <Link to={`/search-result?query=${encodeURIComponent(searchsTerm)}&type=${encodeURIComponent(data.index)}`}>
                      <H3
                        cursor={"pointer"}
                        _hover={{
                          color: "#035DA1",
                        }}
                      >
                        {button.VIEW_ALL}
                      </H3>
                    </Link>}
                  </HStack>
                </Stack>

                {data?.hits && data?.hits?.length > 0 &&
                 (
                  <>
                  {
                    data?.index==="Research Centre" &&
                    <LibraryAndArchive masterlist={searchsTerm} SearchTerm={searchsTerm} setAllCount={setAllCount} allCount={allCount}/>
                    // :
                    // <SearchCar data={data}/>
                  }

                  </>
                )}
              </Stack>)}
              </>
          //   );
          // })
          }
      </Stack>
    </HStack>
  );
};

export default Carousal;
