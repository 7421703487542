import {
  Box,
  HStack,
  Image,
  Stack,
  Text,
} from "@chakra-ui/react";

//Core files
import React, { useEffect, useRef, useState } from "react";
import { TbFoldersOff } from "react-icons/tb";

// Service files
import * as getScreeningMasterlistData from "../../services/Screenings";

//Component files
import { FilterButton } from "../../CommonComponents/FilterButton";
import TimeLine from "../../FunctionalComponents/TimeLine/TimeLine";
import TimelineWithSignatureImage from "../../FunctionalComponents/TimeLine/TimelineWithSignatureImage";

//Language files
import { common, explore } from "../../constants/constants";
import { FilterBox } from "../../FunctionalComponents/Filter/FilterBox";
import InfiniteScroll from "react-infinite-scroll-component";
import NoMoreToLoad from "../../CommonComponents/NoMoreToLoad";
import { totalFilterCount, updateFacetsData } from "../../util/helper";
import FilterPills from "../FilterPills/FilterPills";
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;
const loaderImg = `${IMAGE_BASE_PATH}/ui/Chevron/loader.gif`

export default function Screenings({
  CopyPasteService,
  masterlistType,
  masterlist,
  setScreeningCount
}) {
  const [showFilterBox, setShowFilterBox] = useState(false);
  const [showSignature, setShowSignature] = useState(false);
  const [signatureImg, setSignatureImg] = useState(null);
  const [apply, setApply] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [filterOptions, setFilterOptions] = useState({ search: "", filters: {} });
  const [loading, setLoading] = useState(true);
  const initialLoadComplete = useRef(false);
  const [filterPills, setFilterPills] = useState(null);
  const [filterCount, setFilterCount] = useState(0);
  const [filteredResultCount, setFilteredResultCount] = useState(0);

  // Show/Hide Filter box
  const handleFilterClick = () => {
    setShowFilterBox(!showFilterBox);
    setShowSignature(false);
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleReset = () => {
    const initializedFilters = facets.reduce((acc, facet) => {
      acc[facet.key] = [];
      return acc;
    }, {});
    setFilterOptions({
      search: "",
      filters: initializedFilters
    });
    setFilterCount(0)
    setSearchTerm("");
  }

  const handleCheckboxChange = (value, type) => {
    // Determine the array based on the type
    let selectedArray = [];
    let selectedString = "";

    if (value === "searches") {
      setFilterOptions(prevFilterOptions => ({
        ...prevFilterOptions,
        search: selectedString,
      }));
    } else {
      selectedArray = filterOptions.filters[value] || [];

      if (selectedArray.includes(type)) {
        selectedArray = selectedArray.filter((checkbox) => checkbox !== type);
      } else {
        selectedArray = [...selectedArray, type];
      }

      setFilterOptions(prevFilterOptions => ({
        ...prevFilterOptions,
        filters: {
          ...prevFilterOptions.filters,
          [value]: selectedArray,
        },
      }));
    }
  };

  const showSignatureImages = (image) => {
    setShowSignature((prev) => !prev);
    setSignatureImg(image);
  };

  // Need to refactor the function to bring real data for filter.
  const citationFactor = (data) => {
    return data.map((item) => ({
      year: `${item.sortable_date}`,
      value: `${item.bibliographic_caption}`,
      images: `${item.image_alias}`
    }));
  };

  const [facets, setFacets] = useState([]);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  async function fetchData() {
    try {
      setLoading(true)
      const result = await getScreeningMasterlistData.getScreeningByMasterList(masterlistType, masterlist, filterOptions, 1)
      setLoading(false)
      if (result && result?.data?.length > 0) {
        const processedData = citationFactor(result?.data);
        setData(processedData);
        if (!initialLoadComplete.current) {
          setPage((prevPage) => prevPage + 1);
          initialLoadComplete.current = true;
        }        const formattedFacets = result?.facetsHeaders?.map(header => {
          const key = Object.keys(header)[0];
          const display = header[key];
          const value = result?.facets[0][key]; // Assuming facetsData has only one object
          return {
            key,
            display,
            value
          };
        });
        setScreeningCount(result?.total)
        if (processedData.length >= result?.total) {
          setHasMore(false);
        }
        setFacets(formattedFacets);
        if (formattedFacets.length > 0) {
          setFilterOptions(prevFilterOptions => ({
            ...prevFilterOptions,
            filters: {
              ...prevFilterOptions.filters,
              ...formattedFacets.reduce((acc, facet) => {
                acc[facet.key] = [];
                return acc;
              }, {})
            }
          }));
        }
      }

    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false)
      setData([])
    }
  }
  useEffect(() => {
    fetchData();
    setFilterPills(filterOptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const fetchMoreData = async () => {
    try {
      const result = await getScreeningMasterlistData.getScreeningByMasterList(masterlistType, masterlist, filterOptions, page)
      if (result && result?.data.length>0) {
        const processedData = citationFactor(result?.data);
        setData(prevData => [...prevData, ...processedData]);
        setPage(prevPage => prevPage + 1);
      }
      if (data?.length >= result?.total) {
        setHasMore(false);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }
  async function fetchFilterData(page = 1) {
    try {
      setLoading(true)

      const result = await getScreeningMasterlistData.getScreeningByMasterList(masterlistType, masterlist, filterOptions, page)
      setLoading(false)

      if (result) {
        const processedData = citationFactor(result?.data);
        setData(processedData);
        if (data.length >= result?.total || processedData?.length >= result?.total ) {
          setHasMore(false);
        }
        const filtercnt= totalFilterCount(filterOptions?.filters)
        
        if(filtercnt>0 || filterOptions?.search){
          setFilteredResultCount(result?.total)
        }else{
          setFilteredResultCount(0);
        }
        if (result?.facetsHeaders && result?.facets) {
          const formattedFacets = updateFacetsData(result);
          setFacets(formattedFacets);
        }
      }

    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false)

    }
  }
  useEffect(() => {
    if (apply) {
      setApply(false);
      setHasMore(true);
      setPage(1)
      fetchFilterData();
      setFilterPills(filterOptions)
      const filtercnt= totalFilterCount(filterOptions?.filters)
      setFilterCount(filtercnt)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apply]);

  return (
    <>
      {!loading ? (
        <>
          <Stack flexDirection={"row-reverse"} justifyContent="space-between">
            {!showFilterBox && (
              <FilterButton onClick={handleFilterClick} marginRight={"-56px"} />
            )}
            
            <Stack
              flexDirection={{ base: "column", md: "row" }}
              justifyContent={"space-between"}
              marginTop={"0px !important"}
              pb={{ base: "8", md: "0px" }}
              width={"100%"}
              minHeight={"calc(100vh - 150px)"}
            >
              <HStack flexDirection={"column"} px="0px" width={{ base: "100%", md: "1035px" }} alignItems={"flex-start"}>
              {filterPills &&  filterPills!==undefined && filterCount>0&&
              <FilterPills
                 filters={filterPills}
                 setFilterOptions={setFilterOptions}
                 setApply={setApply}
                 setSearchTerm={setSearchTerm}
                 setFilterPills={setFilterPills}
                 filteredResultCount={filteredResultCount}
               />
              }
                <>
                
                  {data && data?.length > 0 ?
                    <InfiniteScroll
                      dataLength={data?.length}
                      next={fetchMoreData}
                      hasMore={hasMore}
                      loader={<Box textAlign="center" marginLeft={"50%"}>
                        <Stack marginTop={"20px"}>
                          <img src={loaderImg} width={"100px"} height={"100px"} alt="Loading" />
                        </Stack>
                      </Box>}
                      endMessage={<NoMoreToLoad/>}
                    >
                      <TimeLine
                        data={data}
                        showSignatureImages={showSignatureImages}
                        component={TimelineWithSignatureImage}
                      />
                    </InfiniteScroll>
                    : (
                      <HStack w={"100%"}>
                        <Box
                          display={"flex"}
                          alignItems={"center"}
                          w={"inherit"}
                          justifyContent={"right"}
                          gap={4}
                        >
                          <TbFoldersOff size={40} />
                          <Text
                            py={"40"}
                            fontSize={"30px"}
                            fontWeight="700"
                            textAlign="center"
                            display={"flex"}
                            alignItems={"center"}
                          >
                            {common.NO_DATA}
                          </Text>
                        </Box>
                      </HStack>
                    )}
                </>
              </HStack>
              {showSignature && (
                <HStack width={{ base: "100%", md: "320px" }}>
                  <Box pt={{ base: 2, md: 2 }}>
                    <Image
                      src={signatureImg}
                      width={"336px"}
                      height={"132px"}
                    />
                  </Box>
                </HStack>
              )}
              <HStack
                marginLeft={"4"}
                marginRight={"-56px!important"}
                alignItems={"flex-start"}
              >
                {showFilterBox && (
                  <FilterBox
                    setShowFilterBox={setShowFilterBox}
                    setApply={setApply}
                    explore={explore}
                    handleCheckboxChange={handleCheckboxChange}
                    handleSearch={handleSearch}
                    filterOptions={filterOptions}
                    filteroptionData={facets}
                    setFilterOptions={setFilterOptions}
                    setSearchTerm={setSearchTerm}
                    searchTerm={searchTerm}
                    handleReset={handleReset}
                    className="common-box-filter"
                  />
                )}
              </HStack>
            </Stack>
          </Stack>
        </>
      ) : (
        <Stack
          flexDirection={"row-reverse"}
          justifyContent="space-between"
        >
          {!showFilterBox && (
            <FilterButton onClick={handleFilterClick} marginRight={"-55px"} />
          )}
          <Stack
            flexDirection={"row"}
            justifyContent={"space-between"}
            marginLeft={"24px !important"}
            marginTop={"0px !important"}
            width={"100%"}
          >
            <HStack flexDirection={"column"} className="col-8">
            <Image src={loaderImg} width={"100px"} height={"100px"} alt="Loading" />
            </HStack>
            <HStack
              marginLeft={"4"}
              marginRight={"-55px!important"}
              alignItems={"flex-start"}
            >
              {showFilterBox && (
                <FilterBox
                  setShowFilterBox={setShowFilterBox}
                  setApply={setApply}
                  explore={explore}
                  handleCheckboxChange={handleCheckboxChange}
                  handleSearch={handleSearch}
                  filterOptions={filterOptions}
                  filteroptionData={facets}
                  setSearchTerm={setSearchTerm}
                  // Need for future use
                  filterCount={filterCount}
                  searchTerm={searchTerm}
                  handleReset={handleReset}
                  className="common-box-filter"
                />
              )}
            </HStack>
          </Stack>
        </Stack>
      )}
    </>
  );
}
