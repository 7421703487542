// EconomicsOfCinema
import React, { useEffect, useState } from "react";
import {
  Box,
  HStack,
  useColorModeValue,
  Stack,
  Container,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  IconButton,
  Text,
} from "@chakra-ui/react";
import { explore } from "../../../constants/constants";
import { Bibliography } from "./Bibliography/Bibliography";
import { RiMenuLine } from "@react-icons/all-files/ri/RiMenuLine";
import { BsGrid } from "@react-icons/all-files/bs/BsGrid";
import { useNavigate, useParams } from "react-router-dom";
import { Archive } from "./Archive";
import All from "./All";

export default function ResearchCentre({ children }) {
  const winnerName = "shatranj-ke-khilari-1977";
  const [bookFlag, setBookFlag] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);

  return (
    <Box minH="100%" bg={useColorModeValue("white", "gray.900")}>
      <Box bg="white">
        {children}
        <LibraryAndArchive
          winnerName={winnerName}
          bookFlag={bookFlag}
          setBookFlag={setBookFlag}
          openDrawer={openDrawer}
          setOpenDrawer={setOpenDrawer}
        />
      </Box>
    </Box>
  );
}

const LibraryAndArchive = ({
  filmographyData,
  winnerName,
  setResearchCentreFlag,
  setleftFlag,
  bookFlag,
  setBookFlag,
  openDrawer,
  setOpenDrawer,
  name,
}) => {
  const [visible, setVisible] = React.useState(true);
  // const [bookFlag, setBookFlag] = useState(false);
  const [bookData, setBookData] = useState({});
  const [listGridIcon, setListGridIcon] = useState(true);
  const navigate = useNavigate();
  let { tabIndex, gridview } = useParams();
  console.log(bookData);
  useEffect(() => {
    if (tabIndex) {
      navigate("/film/shatranj-ke-khilari-1977/research-centre", { replace: true });

      if (parseInt(tabIndex) === 1) {
        setListGridIcon(true);
      }
      if (parseInt(gridview) === 0) {
        setVisible(false);
      }
      if (parseInt(gridview) === 1) {
        setVisible(true);
      }
    }
  }, [tabIndex, navigate, gridview]);

  const handleBookClick = (flag, data) => {
    setBookData(data);
    setBookFlag(flag);
    setResearchCentreFlag(false);
  };

  return (
    <Stack width={"1280px"}>
      <Container minW={"100%"} pb="4" px="0px">
        <Stack>
          <HStack pb={"44px"}>
            <Text fontSize={"30px"} fontWeight={700}>
              {explore.RESEARCH_CENTRE}
            </Text>
          </HStack>
          {bookFlag ? (
            // <Book bookData={bookData} />
            <></>
          ) : (
            <HStack>
              <Tabs
                align={{ xs: "end", sm: "" }}
                w={"100%"}
                defaultIndex={parseInt(tabIndex) ? parseInt(tabIndex) : 1}
              >
                <Stack
                  flexDirection={"row"}
                  display={"flex"}
                  justifyContent={"space-between"}
                  w={"100%"}
                >
                  <HStack bg="white" w={"100%"}>
                    <TabList
                      overflowX="auto"
                      borderBottom={"1px"}
                      borderBottomColor={"#f1f1f1"}
                      gap={12}
                      className="verticalscroll"
                    >
                      <Tab
                        className="Timeline"
                        p="0"
                        color="#333333"
                        fontSize={"md"}
                        fontWeight={"400"}
                        _selected={{
                          borderBottomWidth: "6px",
                          color: "black",
                          borderBottomColor: "#333333",
                          fontWeight: "700",
                        }}
                        onClick={() => setListGridIcon(false)}
                      >
                        {explore.ALL}
                      </Tab>
                      <Tab
                        className="Timeline"
                        p="0"
                        color="#333333"
                        fontSize={"md"}
                        fontWeight={"400"}
                        _selected={{
                          borderBottomWidth: "6px",
                          color: "black",
                          borderBottomColor: "#333333",
                          fontWeight: "700",
                        }}
                        onClick={() => setListGridIcon(true)}
                      >
                        {explore.LIBRARY}
                      </Tab>
                      <Tab
                        className="Timeline"
                        p="0"
                        color="#333333"
                        fontSize={"md"}
                        fontWeight={"400"}
                        _selected={{
                          borderBottomWidth: "6px",
                          color: "black",
                          borderBottomColor: "#333333",
                          fontWeight: "700",
                        }}
                        onClick={() => setListGridIcon(true)}
                      >
                        {explore.ARCHIVE}
                      </Tab>
                    </TabList>
                  </HStack>
                  <HStack mr={"42px !important"}>
                    {listGridIcon && (
                      <Stack
                        flexDirection={"row"}
                        gap={0}
                        id="listgridviewbtn"
                        marginRight={"20px !important"}
                        marginTop={"-16px !important"}
                      >
                        <HStack marginTop={"0px!important"}>
                          <IconButton
                            variant="unstyled"
                            onClick={() => setVisible(false)}
                            color={!visible ? "black" : "#989898"}
                            fontSize="20px"
                            display="flex"
                            borderTopLeftRadius="6px"
                            borderBottomLeftRadius="6px"
                            borderTopRightRadius="0px"
                            borderBottomRightRadius="0px"
                            border="1px"
                            borderColor={"#989898"}
                            height="32px"
                          >
                            <RiMenuLine ml="4" />
                          </IconButton>
                        </HStack>
                        <HStack marginTop={"0px!important"}>
                          <IconButton
                            variant="unstyled"
                            onClick={() => setVisible(true)}
                            color={visible ? "black" : "#989898"}
                            fontSize="16px"
                            display="flex"
                            borderTopLeftRadius="0px"
                            borderBottomLeftRadius="0px"
                            borderTopRightRadius="6px"
                            borderBottomRightRadius="6px"
                            border="1px"
                            borderColor={"#989898"}
                            height="32px"
                          >
                            <BsGrid />
                          </IconButton>
                        </HStack>
                      </Stack>
                    )}
                  </HStack>
                </Stack>
                <TabPanels>
                  <TabPanel px="0px">
                    <All visible={visible} />
                  </TabPanel>
                  <TabPanel px="0px">
                    <HStack minH={"60vh"}>
                      <Bibliography
                        visible={visible}
                        handleBookClick={handleBookClick}
                        openDrawer={openDrawer}
                        setOpenDrawer={setOpenDrawer}
                        setleftFlag={setleftFlag}
                        tableName={"bibliographies"}
                        masterlist={winnerName}
                      />
                    </HStack>
                  </TabPanel>
                  <TabPanel px="0px">
                    <Archive
                      visible={visible}
                      tableName={"artworks"}
                      masterlist={winnerName}
                    />
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </HStack>
          )}
        </Stack>
      </Container>
    </Stack>
  );
};
