import api from "../services/api";
import { APIS } from "../endpoints";
export const seminarData = async (masterlistType, masterlist, page) => {
      const response = await seminarDataAPI(masterlistType, masterlist, page);
      try {
        if (response) {
          return response;
        } else {
          console.error("Invalid response data structure:", response);
          return [];
        }
      } catch (e) {
        console.error("Something is wrong", e);
      }
}
export const seminarDataAPI = async (masterlistType, masterlist, page) => {
  const formData = new FormData();
  formData.append('page_number', page);
  const params = new URLSearchParams(formData);
  const result = await api.get(`${APIS.BASE_URL}${masterlistType}/${masterlist}/${APIS.econSeminars}`,{
    params: params
})
  // const result = await api.get(`${APIS.BASE_URL}${masterlistType}/${masterlist}/${APIS.econSeminars}`)
  if (result) {
    return result?.data;
  } else {
    return [];
  }
};