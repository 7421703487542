import React, { useEffect, useState } from "react";
import * as getIntroductionData from "../../services/GenericIntroduction";
import {
  Box,
  Container,
  Drawer,
  DrawerContent,
  HStack,
  Image,
  Stack,
  Text,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import { IoMdCalendar } from "@react-icons/all-files/io/IoMdCalendar";
import { RiLayoutGridFill } from "@react-icons/all-files/ri/RiLayoutGridFill";
import { MdMap } from "@react-icons/all-files/md/MdMap";
import { MdHomeFilled } from "react-icons/md";
import { Link, useNavigate, useParams } from "react-router-dom";
import LeftVmenu from "../../FunctionalComponents/LeftVmenu";
import Post from "./Post/Post";
import ResearchCentre from "./ResearchCentre/ResearchCentre";
import Large from "../../Theme/Typography/Large";
import H2 from "../../components/layouts/Typography/H2";
import H3 from "../../Theme/Typography/H3";
import nonSubscriberProfile from "./../../components/UI/Profile/nonsubscriberImg.png";
import { common, routeType } from "../../constants/constants";
import _ from "lodash";
import { format } from "date-fns";
import { useSelector } from "react-redux";
import { masterlistTypes } from "../../util/getMasterlistType";
import MediumRegular from "../../Theme/Typography/MediumRegular";
import { BookDetails } from "../../components/LibraryBook/BookDetails";

export default function GenericMasterlistPage() {
  const { alias } = useParams();
  const masterlistTitle = alias;
  const [name, setName] = useState("");
  const [desc, setDesc] = useState("");
  const [personality, setPersonality] = useState(null);
  const currentEndpoint = window.location.pathname;
  const parts = currentEndpoint.split("/");
  const masterlistType = parts[1];
  const session = useSelector(state => state.session);
  const [aGraphyLink, setAGraphyLink] = useState("")
  const allMasterlistTypes=masterlistTypes;
  const navigate=useNavigate();
  function getCategory(keyword) {
    if (allMasterlistTypes.find(category => category.toLowerCase().includes(keyword.toLowerCase())) === routeType.PERSON_FILMMAKER) {
      return routeType.PERSON_POLYMATH;
    } else {
      return allMasterlistTypes.find(category => category.toLowerCase().includes(keyword.toLowerCase())) || undefined;
    }
  }
  useEffect(() => {
    const getIntroData = async () => {
      const result = await getIntroductionData.genericIntroductionByAlias(
        masterlistType,
        masterlistTitle
      );
      if (result) {
        if (result?.route_type === "film") {
          setName(result?.name);
          setDesc(result?.film[0]?.short_description)
          const researchCategory = result?.research_categories[0]?.value;
          const alinks = `${result?.film[0]?.subject}.${result?.film[0]?.masterlist_type}`
          setPersonality({
            deathYear: null,
            birthYear: null,
            researchCategory,
            masterlistType: null,
            alinks
          })
          let link = getCategory(result?.route_type);
          setAGraphyLink(link)
        } else if (result?.route_type === "event-exhibition") {
          setName(result?.name);
          setDesc(result?.event[0]?.preface)
          const researchCategory = result?.research_categories[0]?.value;
          const alinks = `${result?.event[0]?.subject}.${result?.event[0]?.masterlist_type}`
          setPersonality({
            deathYear: null,
            birthYear: null,
            researchCategory,
            masterlistType: null,
            alinks
          })
          // let link = getCategory(result?.route_type);
          setAGraphyLink(`${result?.route_type}`)
        }else {
          const personalityData = result?.personality?.[0] || {};
          const deathYear = personalityData.date_of_death ?? "";
          const birthYear = personalityData.date_of_birth ?? "";
          const researchCategory = result?.research_categories?.[0]?.value ?? "";
          const subject = personalityData.subject ?? "";
          const masterlistType = personalityData.masterlist_type ?? "";
          const alinks = subject && masterlistType ? `${subject}.${masterlistType}` : "";

          const link = getCategory(result?.route_type);
          setAGraphyLink(link);

          setPersonality({
            deathYear,
            birthYear,
            researchCategory,
            masterlistType,
            alinks,
          });

          setName(result?.name ?? "");
          setDesc(personalityData.description ?? "");
        }
      }
    };
    getIntroData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [masterlistTitle]);
  let {bookDetails}=useParams();

  return (
    <SidebarWithHeader>
      {bookDetails !==undefined ? (
              <>
              <Stack alignItems={"center"} width={"100%"}>
                <BookDetails />
              </Stack>
              </>
            ) :
      (<Stack width={"100%"} height={"100%"}>
        <Container
          maxW={"auto"}
          px={"0px"}
          paddingX={{ base: "0px", md: "64px" }}
          paddingY={"32px"}
        >
          <HStack justifyContent={"space-between"}>
            <Box textAlign="left">
              <HStack>
                <Large cursor={"pointer"} textStyle="tertiary" onClick={() => aGraphyLink && navigate(`/${aGraphyLink}/${alias}/`)}>
                  {name ? name : _.startCase(alias.replace(/-/g, ' '))}
                </Large>
                {(personality?.birthYear || personality?.deathYear) && <Stack as="span" px="1" style={{ borderRight: "7px solid", height: "45px", marginTop:"15px" }}>
                </Stack>}
                <H2 pt="2" px="1" as="span">
                  {personality?.birthYear && `(${personality?.birthYear && format(new Date(personality?.birthYear), 'yyyy')}${personality?.deathYear && "-" + format(new Date(personality?.deathYear), 'yyyy')
                    })`}
                </H2>
              </HStack>
             
            </Box>
            {!session.isAuthenticated &&
              <Link to={`/register`}>
                <HStack marginTop={"-30px"}>
                  <Box width={"43px"} height={"60px"}>
                    <Image width={"100%"} src={nonSubscriberProfile} fallbackSrc="" />
                  </Box>
                  <H3 as="span" fontWeight={500} color="color.lightblue1">{`| ${common.SUBSCRIBE}`}</H3>
                </HStack>
              </Link>
            }
          </HStack>
          <Box mt="2" display={"flex"}>
                <Link>
                  <H3
                    fontWeight={500}
                    color="color.lightblue1"
                  >{personality?.researchCategory && `${personality?.researchCategory && personality?.researchCategory} ${` `}`}</H3>
                </Link>
                  {personality?.researchCategory && personality?.alinks && (
                    <H3>
                      <Text  color="color.lightblue1" as="span" px="1">|</Text>
                    </H3>
                  )}
                <Link>
                  <H3 fontWeight={500} color="color.lightblue1">{personality?.alinks && `${personality?.alinks
                    }`}</H3>
                </Link>
              </Box>
          {desc && <HStack pt={"40px"}>
            <MediumRegular lineHeight={"18.78px"} textAlign="justify">{desc}</MediumRegular>
          </HStack>}
          <HStack background={"white"} zIndex={9} paddingTop={"20px"}>
            <Post masterlistTitle={masterlistTitle} masterlistType={masterlistType} pageName="masterlist" />
          </HStack>
          <HStack paddingTop={"20px"} background={"white"} zIndex={9}>
            <ResearchCentre className="top-to-btm1" width="1213px" pb="5px" marginX={"0px"} listId="listgridbutton2" />
          </HStack>
        </Container>
      </Stack>)}
    </SidebarWithHeader>
  );
}
const SidebarWithHeader = ({ children }) => {
  const { isOpen, onClose } = useDisclosure();
  return (
    <Box minH="100vh" bg={useColorModeValue("gray.100", "gray.900")}>
      <SidebarContent
        onClose={() => onClose}
        display={{ base: "none", md: "block" }}
      />
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
      >
        <DrawerContent>
          <SidebarContent onClose={onClose} />
        </DrawerContent>
      </Drawer>
      <Box ml={{ base: 0, md: 14 }} bg="white">
        {children}
      </Box>
    </Box>
  );
};
const SidebarContent = ({ onClose, display, ...rest }) => {
  const [error, setError] = useState(false);
  const toggleModal = () => {
    setError(!error);
  };
  const LeftVmenuData = [
    {
      link: "/",
      id: "homeicon",
      icon: MdHomeFilled,
    },
    {
      link: "/explore",
      id: "exploreicon",
      icon: RiLayoutGridFill,
      active: true,
    },
    {
      link: "/calendar",
      id: "calendaricon",
      icon: IoMdCalendar,
    },
    {
      link: "/map",
      id: "mapicon",
      icon: MdMap,
    },
  ];
  const { drawerId } = useParams();
  const [nav, setNav] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    if (drawerId) {
      setNav(drawerId);
    }
  }, [drawerId, navigate]);
  const handleNavigation = () => {
    if (nav) {
      navigate(`/shatranj-ke-khilari-1977/body-of-work/2/${nav}`);
    } else {
      navigate(-1);
    }
  };
  return (
    <LeftVmenu
      backArrow={true}
      display={display}
      onClose={onClose}
      toggleModal={toggleModal}
      handleNavigation={handleNavigation}
      LeftVmenuData={LeftVmenuData}
    />
  );
};
