import { Box, Container, HStack, Stack } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { seminarData } from '../../../../../services/Seminar';
import { useParams } from 'react-router-dom';
import TimeLine from '../../../../../FunctionalComponents/TimeLine/TimeLine';
import { DataNotFound } from '../../../../../CommonComponents/DataNotFound';
import NoMoreToLoad from '../../../../../CommonComponents/NoMoreToLoad';
import InfiniteScroll from 'react-infinite-scroll-component';
import { CopyPasteService } from '../../../../../services/CopyPaste';
import TimelineWithText1 from '../../../../../FunctionalComponents/TimeLine/TimelineWithText1';

const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;
const loaderImg = `${IMAGE_BASE_PATH}/ui/Chevron/loader.gif`

export default function SeminarsAndConferences() {
  const [data, setData] = useState([]); 
  const {alias}=useParams();
  const masterlist=alias;
  const currentEndpoint = window.location.pathname;
  const parts = currentEndpoint.split("/");
  const masterlistType = parts[1];
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  async function fetchData() {
    try {
    const result = await seminarData(masterlistType, masterlist,page );  
    if (result && result?.data?.length > 0) {
        setData(result?.data);
        setPage(prevPage => prevPage + 1);
        if (result?.data.length === result?.total) {
          setHasMore(false);
        }
    }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); 
  const fetchMoreData = async () => {
    try {
    const result = await seminarData(masterlistType, masterlist,page );  
    if (result) {
        const processedData = result?.data;
        setData(prevData => [...prevData, ...processedData]);
        setPage(prevPage => prevPage + 1);
        if (data.length === result?.total) {
          setHasMore(false);
        }
      }

    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }
  return (
    <>
        <Stack width={"100%"} flexDirection={"row"}>
       <Container maxW="auto" pb="4" px="0px">
            <HStack
              flexDirection={"column"}
              alignItems={"baseline"}
              p={"0px !important"}
              onCopy={(e) => CopyPasteService(e, window.location.href)}
              margin={!data.length > 0 && "0px auto"}
            >
              {data && data.length > 0 ?
              <InfiniteScroll
              dataLength={data.length}
              next={fetchMoreData}
              hasMore={hasMore}
              loader={<Box textAlign="center" marginLeft={"50%"}>
              <Stack marginTop={"20px"}>
                 <img src={loaderImg} width={"100px"} height={"100px"} alt="Loading" />
              </Stack>
            </Box>}
              endMessage={<NoMoreToLoad/>}
              >
            <TimeLine
               data={data}
               textColor="color.blue"
               component={TimelineWithText1}
             />
                </InfiniteScroll>
                : (
                  <HStack w={"100%"}>
                  <Box
                    display={"flex"}
                    alignItems={"center"}
                    w={"inherit"}
                    justifyContent={"right"}
                    gap={4}
                  >
                    <DataNotFound />
                  </Box>
                </HStack>
              )}
            </HStack>
        </Container>
        </Stack>
    </>
  );
}
