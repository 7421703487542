import brand1 from "./../components/UI/AmitabhBachchan/BrandAmbassodarShip/brand1.png";
import brand2 from "./../components/UI/AmitabhBachchan/BrandAmbassodarShip/brand2.png";
import brand3 from "./../components/UI/AmitabhBachchan/BrandAmbassodarShip/brand3.png";
import brand4 from "./../components/UI/AmitabhBachchan/BrandAmbassodarShip/brand4.png";
import brandfull1 from "./../components/UI/AmitabhBachchan/BrandAmbassodarShip/brandfull1.png";
import brandfull2 from "./../components/UI/AmitabhBachchan/BrandAmbassodarShip/brandfull2.png";
import brandfull3 from "./../components/UI/AmitabhBachchan/BrandAmbassodarShip/brandfull3.png";

export const amitabh_bachchan_ambassdorship_data = [
  {
    designerdesc:
      "Tech, Ace (Brand). Ace with Amitabh | Advertisements | .PRNT.adv | 1_CinemaEducation",
    desc: "Ace Tech. Ace with Amitabh,",
    designerData: [
      {
        key: "date_of_artwork",
        display: "Date of Artwork",
        value: "2018",
      },
      {
        key: "published_in",
        display: "Published In",
        value: "Digital Print",
      },
      {
        key: "published_in",
        display: "Published In",
        value: "Digital Print",
      },
      {
        key: "Personality Featured",
        display: "Personality Featured",
        value: "Amitabh Bachchan",
      },
      {
        key: "company",
        display: "Company",
        value: "Ace Tech",
      },
    ],
    fullImg: brandfull1,
    image: brand1,
    content:"Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.Lorem ipsum is placeholder text commonly used in the ",
    link: true,
  },
  {
    designerdesc:
      "Alexa, Amazon (Brand). Alexa Gets Amitabh Bachchan's Voice | Advertisements | .PRNT.adv | 1_CinemaEducation",
    desc: "Amazon Alexa. Alexa Gets Amitabh Bachchan's Voice,",
    designerData: [
      {
        key: "date_of_artwork",
        display: "Date of Artwork",
        value: "2018",
      },
      {
        key: "published_in",
        display: "Published In",
        value: "Digital Print",
      },
      {
        key: "Personality Featured",
        display: "Personality Featured",
        value: "Amitabh Bachchan",
      },
      {
        key: "company",
        display: "Company",
        value: "Amazon Alexa",
      },
    ],
    fullImg: brandfull2,
    image: brand2,
    content:"Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.Lorem ipsum is placeholder text commonly used in the ",
    link: true,
  },
  {
    designerdesc:
      "Limited, Apollo Tubes (Brand). Apollo Steel Pipes | Advertisements | .PRNT.adv | 1_CinemaEducation",
    desc: "Apollo Tubes Limited. Apollo Steel Pipes,",
    designerData: [
      {
        key: "date_of_artwork",
        display: "Date of Artwork",
        value: "2018",
      },
      {
        key: "published_in",
        display: "Published In",
        value: "Digital Print",
      },
      {
        key: "Personality Featured",
        display: "Personality Featured",
        value: "Amitabh Bachchan",
      },
      {
        key: "company",
        display: "Company",
        value: "Apollo Tubes Limited",
      },
    ],
    fullImg: brandfull3,
    image: brand3,
    content:"Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.Lorem ipsum is placeholder text commonly used in the ",
    link: true,
  },
  {
    designerdesc:
      "Tech, Ace (Brand). Ace with Amitabh | Advertisements | .PRNT.adv | 1_CinemaEducation",
    desc: "Ace Tech. Ace with Amitabh,",
    designerData: [
      {
        key: "date_of_artwork",
        display: "Date of Artwork",
        value: "2018",
      },
      {
        key: "published_in",
        display: "Published In",
        value: "Digital Print",
      },
      {
        key: "published_in",
        display: "Published In",
        value: "Digital Print",
      },
      {
        key: "Personality Featured",
        display: "Personality Featured",
        value: "Amitabh Bachchan",
      },
      {
        key: "company",
        display: "Company",
        value: "Ace Tech",
      },
    ],
    fullImg: brandfull3,
    image: brand4,
    content:"Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.Lorem ipsum is placeholder text commonly used in the ",
    link: true,
  },
];
