import { Text } from "@chakra-ui/react";
import React from "react";

const H1 = ({ children, ...props }) => {
  return (
    <Text as="h1" {...props} fontSize="24px" fontWeight="700">
      {children}
    </Text>
  );
};
export default React.memo(H1);
