import {
  Box,
  HStack,
  useColorModeValue,
  Drawer,
  DrawerContent,
  useDisclosure,
  Stack,
  Container,
  DrawerOverlay,
  Image,
  Text,
} from "@chakra-ui/react";

// Core file
import React, { useState } from "react";
import CommonLeftSidebar from "../../FunctionalComponents/LeftSidebar/CommonLeftSidebar";
import { useNavigate, useParams } from "react-router-dom";

// Service file
import * as getIntroductionData from "../../services/Introduction";
import * as getLandingMasterlistData from "../../services/SectionService";

// Component file
import { EffectFade, Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

// Language Constant
import { explore } from "../../constants/constants";
import { BottomMainMenu } from "../../CommonComponents/BottomMainMenu";

export default function Introduction({ children }) {
  const [title, setTitle] = React.useState();
  const [date, setDate] = React.useState();
  const [datedod, setDateDod] = React.useState();
  const { isOpen, onClose } = useDisclosure();

  const [introData, setIntroData] = useState([]);
  const [horislinkData, setHorislinkData] = useState([]);
  const [journalDetails, setJournalDetails] = useState({});
  const [name, setName] = useState("");
  const {alias } = useParams();
  const aliasAPI = alias;
  const currentEndpoint = window.location.pathname;
  const parts = currentEndpoint.split("/");
  const masterlistType = parts[1];

  React.useEffect(() => {
    const getIntroData = async () => {
      const result = await getIntroductionData.introductionByAlias(
        masterlistType,
        aliasAPI
      );
      if (result) {
        setHorislinkData([
          {
            link: `/research-categories/${
              result[0]?.find((item) => item?.key === "researchCategories")
                .value[0].link
            }`,
            title: result[0]?.find((item) => item?.key === "researchCategories")
              .value[0].value,
          },
          {
            link: `/categories/indian-cinema`,
            title: result[0]?.find((item) => item?.key === "subject")?.value,
          },
        ]);
        setName(() => result[0]?.find((item) => item?.key === "name")?.value);

        setJournalDetails(
          result?.reduce((acc, it) => {
            acc.title = it?.find((item) => item?.key === "name")?.value;
            acc.date_Range = it?.find(
              (item) => item?.key === "song_from"
            )?.value+' - '+ it?.find(
              (item) => item?.key === "song_till"
            )?.value;
            return acc;
          }, {})
        );
      }
    };
    const getLandingData = async () => {
      const result = await getLandingMasterlistData.getLandingPageInfoByAlias(
        aliasAPI
      );
      if (result) {
        setIntroData(
          result?.map((it) => ({
            text: it.find((item) => item?.key === "landing_page_title").value,
            to: `/${masterlistType}/${it.find((item) => item?.key === "masterlist").value}/${
              it.find((item) => item?.key === "landing_page_abbreviation").value
            }`,
            title: it.find((item) => item?.key === "landing_page_abbreviation")
              .value,
          }))
        );
      }
    };
    getIntroData();
    getLandingData();
    window.scrollTo(0, 0);
  }, [masterlistType, aliasAPI]);
  return (
    <Container maxW={"1400px"} px="0px">
      <Box minH="100%" bg={useColorModeValue("white", "gray.900")}>
        <SidebarContent
          title={title}
          date={date}
          datedod={datedod}
          onClose={() => onClose}
          display={{ base: "none", md: "block" }}
          height="calc(100vh - 64px)"
          aliasAPI={aliasAPI}
          horislinkData={horislinkData}
          journalDetails={journalDetails}
          introData={introData}
          masterlistType={masterlistType}
        />
        <Drawer
          autoFocus={false}
          isOpen={isOpen}
          placement="left"
          onClose={onClose}
          returnFocusOnClose={false}
          onOverlayClick={onClose}
          size="full"
        >
          <DrawerOverlay maxW={"1400px"} />
          <DrawerContent>
            <SidebarContent
              onClose={onClose}
              title={title}
              date={date}
              datedod={datedod}
              aliasAPI={aliasAPI}
              horislinkData={horislinkData}
              journalDetails={journalDetails}
              introData={introData}
              masterlistType={masterlistType}
            />
          </DrawerContent>
        </Drawer>

        <Box ml={{ base: 0, md: 52 }} bg="white">
          {children}
          <IntroductionBody
            setTitle={setTitle}
            setDate={setDate}
            setDateDod={setDateDod}
            aliasAPI={aliasAPI}
            horislinkData={horislinkData}
            journalDetails={journalDetails}
            introData={introData}
            name={name}
          />
        </Box>
      </Box>
    </Container>
  );
}

const SidebarContent = ({
  title,
  date,
  datedod,
  onClose,
  aliasAPI,
  horislinkData,
  journalDetails,
  introData,
  masterlistType,
  ...rest
}) => {
  const navigate = useNavigate();
  const handleNavigation = () => {
    navigate(-1);
  };

  const oSections = [
    {
      to: `/${masterlistType}/${aliasAPI}/1`,
      title: "",
      text: explore.POSTS,
    },
    {
      to: `/${masterlistType}/${aliasAPI}/2`,
      title: "",
      text: explore.INSIGHTS,
    },
  ];
  return (
    <>
    <BottomMainMenu />
    <CommonLeftSidebar
      sections={introData}
      oSections={oSections}
      onClose={onClose}
      hMenu={horislinkData}
      journalDetails={journalDetails}
      leftFlag={true}
      handleNavigation={handleNavigation}
    />
    </>
  );
};

const IntroductionBody = ({ setTitle, setDate, setDateDod,aliasAPI, name }) => {
  const [description, setDescription] = useState("");
  const [imagesData, setImagesData] = useState([]);

  React.useEffect(() => {
    const getData = async () => {
      const result = await getIntroductionData.getSongsSynopsisBookletData(aliasAPI);
      if (result) {
        setDescription(result[0]?.text);
        setImagesData(result[0]?.sliderImage);
      }
    };
    getData();
  }, [aliasAPI]);

  return (
    <>
      <Container
        flexDirection={{ base: "column", md: "row" }}
        justifyContent={"space-between"}
        maxW={{ base: "100%", md: "1170px" }}
        height={{ base: "100%", md: "100%", xl:"100vh"}}
        pb={4}
      >
        <Stack
          height={"100%"}
          marginTop={"32px"}
          alignItems={{base:"center",md:"flex-start"}}
        >
          <>
            <Text
              fontSize={{ base: "32px", md: "48px" }}
              fontWeight={"700"}
              color="#000000"
              marginLeft={{ base: "0", md: "70px" }}
            >
              {name}
            </Text>

            <HStack
              alignItems={"flex-start"}
              width={{ base: "100%", md: "1170px" }}
              pt={"24px"}
              flexDirection={{ base: "column", md: "row" }}
            >
              <Stack position={"relative"} id="allsliderahj" className="col-md-7 col-sm-12">
                <Swiper
                  navigation={true}
                  modules={[EffectFade, Navigation, Pagination]}
                >
                  {imagesData.length > 0 &&
                    imagesData.map((item, index) => {
                      return (
                        <>
                          <SwiperSlide key={index + 1}>
                            <Box>
                              <Image src={item} width={"100%"} height={"auto"} objectFit={"contain"} />
                            </Box>
                          </SwiperSlide>
                        </>
                      );
                    })}
                </Swiper>
              </Stack>
              <Stack className="col-md-5 col-sm-12">
                <Box w={{base:"100%",md:"390px"}} pt={"20px"} px={{base:"0px",md:"20px"}} pb={{base:"20",md:"20px"}}>
                  <Text fontSize={"16px"} lineHeight={"18.78px"}>{description}</Text>
                </Box>
              </Stack>
            </HStack>
          </>
        </Stack>
        
      </Container>
    </>
  );
};