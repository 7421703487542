import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Container,
  Divider,
  HStack,
  Image,
  Stack,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper";
import { common } from "../../../../../constants/constants";
import { useParams } from "react-router-dom";
import * as getResearchCentreBook from "../../../../../services/book";
import Small from "../../../../../components/layouts/Typography/Small";

export const Book = ({ thumbnailWidth }) => {
  const { bookDetails } = useParams();
  const [bookData, setBookData] = useState([]);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isExpandedAuthor, setIsExpandedAuthor] = useState(false);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };
  const toggleExpandAuthor = () => {
    setIsExpandedAuthor(!isExpandedAuthor);
  };
  useEffect(() => {
    const fetchData = async () => {
      let result;
      result = await getResearchCentreBook.getResearchCentreBookbyBookAlias(
        "tableName",
        bookDetails
      );
      setBookData(result);
      if (bookData?.length > 0) {
        setSelectedImg({
          image: bookData[0].slider_data[0].image,
          desc: "",
          index: 0,
          slideImage: bookData[0]?.slider_data[0].image,
        });
      }
    };
    fetchData();
  }, [bookDetails, bookData]);

  const [selectedImg, setSelectedImg] = useState({
    image: "",
    desc: "",
    index: 0,
    slideImage: "",
  });

  const swiperRef = useRef(null);

  const handleNextButtonClick = () => {
    // const activeIndex = swiperRef.current.swiper.activeIndex;
    const activeIndex = selectedImg.index + 1;
    const nextSlide = bookData[0]?.slider_data[activeIndex];
    if (nextSlide) {
      setSelectedImg({
        image: nextSlide.image,
        index: activeIndex,
        slideImage: nextSlide.image,
      });
    }
  };

  const handlePreviousButtonClick = () => {
    // const activeIndex = swiperRef.current.swiper.activeIndex;
    const activeIndex = selectedImg.index - 1;
    const nextSlide = bookData[0]?.slider_data[activeIndex];

    if (nextSlide) {
      setSelectedImg({
        image: nextSlide.image,
        index: activeIndex,
        slideImage: nextSlide.image,
      });
    }
  };
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Stack
        flexDirection={{ base: "column", md: "row" }}
        position={"absolute"}
        top={"-150px"}
        justifyContent={"space-between"}
        py={{ base: "40px", md: "60px" }}
        bgColor={"white"}
        gap={"20px"}
        zIndex={99999}
        width={{ base: "100%", md: "1192px" }}
        minH={"100vh"}
        ml={"-40px"}
      >
        <HStack
          alignItems={"center"}
          flexDirection={"column"}
          width={{ base: "100%", md: "100%" }}
          className="col-6"
        >
          <Box width={"100%"} height={{ base: "100%", md: "450px" }}>
            <Image
              src={selectedImg.image}
              width={"100%"}
              height={"inherit"}
            ></Image>
          </Box>
          {selectedImg.index === 7 && (
            <Text
              position={"absolute"}
              top={"35%"}
              left={"38%"}
              fontSize={"18px"}
            >
              Image Awaited
            </Text>
          )}
          <Stack width={"100%"} position={"relative"} id="allsliderskkBook">
            <Swiper
              ref={swiperRef}
              grabCursor={true}
              navigation={true}
              gap={{ base: 4, md: 0 }}
              modules={[Navigation, Pagination]}
              style={{ marginTop: "30px" }}
              slidesPerView={4}
              slidesPerGroup={1}

              onSlideNextTransitionEnd={() => {
                handleNextButtonClick();
              }}
              onSlidePrevTransitionEnd={() => {
                handlePreviousButtonClick();
              }}
              //   loop={true}
            >
              {bookData[0]?.slider_data?.map((item, index) => {
                return (
                  <SwiperSlide>
                    <div style={{ position: "relative" }}>
                      <Image
                        height={"90px"}
                        width={thumbnailWidth ? thumbnailWidth : "auto"}
                        src={item.image}
                        onClick={() => {
                          swiperRef.current.swiper.slideTo(
                            swiperRef.current.swiper.activeIndex
                          );
                          setSelectedImg({
                            image: item.image,
                            index: index,
                            slideImage: item.image,
                          });
                        }}
                      />
                      {index === 7 && (
                        <Text
                          position={"absolute"}
                          top={"35%"}
                          left={"11%"}
                          fontSize={"14px"}
                        >
                          Image Awaited
                        </Text>
                      )}
                      {swiperRef !== null && index !== selectedImg.index && (
                        <div
                          style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "100%",
                            background: "rgba(0, 0, 0, 0.8)",
                          }}
                          onClick={() => {
                            swiperRef.current.swiper.slideTo(
                              swiperRef.current.swiper.activeIndex
                            );
                            setSelectedImg({
                              image: item.image,
                              desc: item.desc,
                              index: index,
                            });
                          }}
                        />
                      )}
                    </div>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </Stack>
        </HStack>
        <HStack
          flexDirection={"column"}
          alignItems={"flex-start"}
          height={"100%"}
          width={"100%"}
          px={{ base: "4", md: "8" }}
        >
          <Text
            fontSize={"20px"}
            fontWeight={"700"}
            lineHeight={"22px"}
            color="#333333"
          >
            {bookData[0]?.bookName}
          </Text>
          <Box width={{ base: "100%", md: "392px" }} py={5}>
            <Accordion allowToggle>
              <AccordionItem>
                <AccordionButton className="px-0">
                  <Box flex="1" textAlign="left" px={5}>
                    <Text
                      fontSize={"16px"}
                      fontWeight={"700"}
                      lineHeight={"24px"}
                      color={"#000000"}
                    >
                      {common.CONTENTS}
                    </Text>
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
                <Box maxH="160px" overflowY="auto">
                  <AccordionPanel pb={4} className="px-1">
                    {bookData?.length > 0 &&
                      bookData?.contentsData?.length > 0 &&
                      bookData[0]?.contentsData.map((item) => {
                        return (
                          <>
                            <Text
                              fontSize={"14px"}
                              pt={"4px"}
                              pb={"4px"}
                              px={5}
                            >
                              {item.title}
                            </Text>
                            <Divider color="#909090" />
                          </>
                        );
                      })}
                  </AccordionPanel>
                </Box>
              </AccordionItem>
              <AccordionItem>
                <AccordionButton className="px-0">
                  <Box flex="1" textAlign="left" px={5}>
                    <Text
                      fontSize={"16px"}
                      fontWeight={"700"}
                      lineHeight={"24px"}
                      color={"#000000"}
                    >
                      {common.INDEX}
                    </Text>
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel pb={4} className="px-1">
                  <Small px={5}>{common.CONTENT_AWAITED}</Small>
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          </Box>
          <Container px="0px">
            <Text
              fontSize={"15px"}
              fontWeight={"700"}
              lineHeight={"22px"}
              paddingLeft={"20px"}
            >
              <li>{common.ABOUT_THE_BOOK}</li>
            </Text>
            <Text
              fontSize={"15px"}
              fontWeight={"500"}
              // lineHeight={"22px"}
              marginTop={"25px"}
              textAlign={"justify"}
            >
              {isExpanded
                ? bookData?.length > 0 && bookData[0]?.bookText
                : `${
                    bookData?.length > 0 && bookData[0]?.bookText.slice(0, 360)
                  }`}
              <span
                style={{
                  color: "#035DA1",
                  cursor: "pointer",
                  fontWeight: "700",
                }}
                onClick={toggleExpand}
              >
                {isExpanded ? " Read Less" : " Read More"}
              </span>
            </Text>
          </Container>
          <Container px="0px" py={5}>
            <Text
              fontSize={"15px"}
              fontWeight={"700"}
              lineHeight={"22px"}
              paddingLeft={"20px"}
            >
              <li>{common.ABOUT_THE_AUTHOR}</li>
            </Text>
            <Text
              fontSize={"15px"}
              fontWeight={"500"}
              // lineHeight={"22px"}
              marginTop={"25px"}
              textAlign={"justify"}
            >
              {isExpandedAuthor
                ? bookData?.length > 0 && bookData[0]?.authorText
                : `${
                    bookData?.length > 0 &&
                    bookData[0]?.authorText.slice(0, 263)
                  }`}
              <span
                style={{
                  color: "#035DA1",
                  cursor: "pointer",
                  fontWeight: "700",
                }}
                onClick={toggleExpandAuthor}
              >
                {isExpandedAuthor ? " Read Less" : " Read More"}
              </span>
            </Text>
          </Container>
        </HStack>
      </Stack>
    </>
  );
};
