//Core files
import React from "react";

//Component files
import { WebpageComingSoon } from "../../../CommonComponents/WebpageComingSoon";
import { Stack } from "@chakra-ui/react";

function BoxOffice({masterlist}) {
  return (
    <>
    <Stack height={"100vh"}>
    <WebpageComingSoon/>
    </Stack>
    </>
  );
}

export default BoxOffice;
