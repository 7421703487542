import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getAccess } from "../services/userAccess";
import _ from "lodash";
export const HasAccessLink = (props) => {
  const {
    children,
    to,
    onClick,
    key,
    pageName = "",
    masterlist = "",
    componentName = "",
  } = props;
  const [accessible, setAccessible] = useState(0);
  const checkAccess = async (pageName, masterlist, componentName) => {
    const access = await getAccess(pageName, masterlist);
    const data = (access && access.data) || [];

    if (!data) {
      setAccessible(false);
    }

    if (data && data.length) {
      const row = _.find(data, { name: componentName });

      if (row) {
        setAccessible(row.access ? true : false);
      }
    }
    return false;
  };
  useEffect(() => {
    const main = async () => {
      await checkAccess(pageName, masterlist, componentName);
    };
    main();
  }, [pageName, masterlist, componentName]);

  return (
    <Link
      to={to}
      style={to === '' ? { color: 'initial' } : {}}
      key={key || Math.random() * 100000}
      onClick={(e) => {
        if (accessible) {
          onClick(e, to);
        }else{
          onClick(e, false);
        }
        e.preventDefault();
      }}
    >
      {children}
    </Link>
  );
};
