import { Stack, Box } from "@chakra-ui/react";
import React from "react";
import { DataNotFound } from "../../CommonComponents/DataNotFound";

export default function TimeLine({
  data,
  component: Component,
  handleDrawer,
  btnRef,
  showImage,
  showSignatureImages,
  setOpenDrawer,
  setCatalogue,
  handleImageClick,
  handleBooklet,
  textColor,
  cursor,
  marginBottom
}) {

  return (
    <Box>
      {data && data.length > 0 ? (
        <Component
          data={data}
          handleDrawer={handleDrawer}
          btnRef={btnRef}
          showImage={showImage}
          showSignatureImages={showSignatureImages}
          setOpenDrawer={setOpenDrawer}
          setCatalogue={setCatalogue}
          handleImageClick={handleImageClick}
          handleBooklet={handleBooklet}
          textColor={textColor}
          cursor={cursor}
          marginBottom={marginBottom}
        />
      ) : (
        <Stack alignItems={"center"} height={{base:"100%",md:"100vh"}}>
        <DataNotFound />
        </Stack>
      )}
    </Box>
  );
}
