//S3 Bucket osw-dev-images
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;

const img1942 = `${IMAGE_BASE_PATH}/ui/PersonalityArtist/SayedHaiderRaza/BodyofWork/IntegratedTimeline/1942.png`
const img1943 = `${IMAGE_BASE_PATH}/ui/PersonalityArtist/SayedHaiderRaza/BodyofWork/IntegratedTimeline/1943.png`
const img1944 = `${IMAGE_BASE_PATH}/ui/PersonalityArtist/SayedHaiderRaza/BodyofWork/IntegratedTimeline/1944.png`
const img1945 = `${IMAGE_BASE_PATH}/ui/PersonalityArtist/SayedHaiderRaza/BodyofWork/IntegratedTimeline/1945.png`
const img1946 = `${IMAGE_BASE_PATH}/ui/PersonalityArtist/SayedHaiderRaza/BodyofWork/IntegratedTimeline/1946.png`
const img1947 = `${IMAGE_BASE_PATH}/ui/PersonalityArtist/SayedHaiderRaza/BodyofWork/IntegratedTimeline/1947.png`
const img1947b = `${IMAGE_BASE_PATH}/ui/PersonalityArtist/SayedHaiderRaza/BodyofWork/IntegratedTimeline/1947b.jpg`
const img1948 = `${IMAGE_BASE_PATH}/ui/PersonalityArtist/SayedHaiderRaza/BodyofWork/IntegratedTimeline/1948.png`
const img1950 = `${IMAGE_BASE_PATH}/ui/PersonalityArtist/SayedHaiderRaza/BodyofWork/IntegratedTimeline/1950.png`
const img1951 = `${IMAGE_BASE_PATH}/ui/PersonalityArtist/SayedHaiderRaza/BodyofWork/IntegratedTimeline/1951.png`
const img1952 = `${IMAGE_BASE_PATH}/ui/PersonalityArtist/SayedHaiderRaza/BodyofWork/IntegratedTimeline/1952.png`
const img1953 = `${IMAGE_BASE_PATH}/ui/PersonalityArtist/SayedHaiderRaza/BodyofWork/IntegratedTimeline/1953.png`
const img1954 = `${IMAGE_BASE_PATH}/ui/PersonalityArtist/SayedHaiderRaza/BodyofWork/IntegratedTimeline/1954.png`
const img1960 = `${IMAGE_BASE_PATH}/ui/PersonalityArtist/SayedHaiderRaza/BodyofWork/IntegratedTimeline/1960.png`
const img1961 = `${IMAGE_BASE_PATH}/ui/PersonalityArtist/SayedHaiderRaza/BodyofWork/IntegratedTimeline/1961.png`
const img1962 = `${IMAGE_BASE_PATH}/ui/PersonalityArtist/SayedHaiderRaza/BodyofWork/IntegratedTimeline/1962.png`
const img1963 = `${IMAGE_BASE_PATH}/ui/PersonalityArtist/SayedHaiderRaza/BodyofWork/IntegratedTimeline/1963.png`
const img1964 = `${IMAGE_BASE_PATH}/ui/PersonalityArtist/SayedHaiderRaza/BodyofWork/IntegratedTimeline/1964.png`
const img1965 = `${IMAGE_BASE_PATH}/ui/PersonalityArtist/SayedHaiderRaza/BodyofWork/IntegratedTimeline/1965.png`
const img1966 = `${IMAGE_BASE_PATH}/ui/PersonalityArtist/SayedHaiderRaza/BodyofWork/IntegratedTimeline/1966.png`
const img1967 = `${IMAGE_BASE_PATH}/ui/PersonalityArtist/SayedHaiderRaza/BodyofWork/IntegratedTimeline/1967.png`
const img1968 = `${IMAGE_BASE_PATH}/ui/PersonalityArtist/SayedHaiderRaza/BodyofWork/IntegratedTimeline/1968.png`
const img1970 = `${IMAGE_BASE_PATH}/ui/PersonalityArtist/SayedHaiderRaza/BodyofWork/IntegratedTimeline/1970.png`
const img1971 = `${IMAGE_BASE_PATH}/ui/PersonalityArtist/SayedHaiderRaza/BodyofWork/IntegratedTimeline/1971.png`
const img1972 = `${IMAGE_BASE_PATH}/ui/PersonalityArtist/SayedHaiderRaza/BodyofWork/IntegratedTimeline/1972.png`
const img1973 = `${IMAGE_BASE_PATH}/ui/PersonalityArtist/SayedHaiderRaza/BodyofWork/IntegratedTimeline/1973.png`
const img1974 = `${IMAGE_BASE_PATH}/ui/PersonalityArtist/SayedHaiderRaza/BodyofWork/IntegratedTimeline/1974.png`
const img1975 = `${IMAGE_BASE_PATH}/ui/PersonalityArtist/SayedHaiderRaza/BodyofWork/IntegratedTimeline/1975.png`
const img1976 = `${IMAGE_BASE_PATH}/ui/PersonalityArtist/SayedHaiderRaza/BodyofWork/IntegratedTimeline/1976.png`
const img1961_d = `${IMAGE_BASE_PATH}/ui/PersonalityArtist/SayedHaiderRaza/BodyofWork/IntegratedTimeline/1961_d.png`
const img1973_d = `${IMAGE_BASE_PATH}/ui/PersonalityArtist/SayedHaiderRaza/BodyofWork/IntegratedTimeline/1973_d.png`
const img1975_d = `${IMAGE_BASE_PATH}/ui/PersonalityArtist/SayedHaiderRaza/BodyofWork/IntegratedTimeline/1975_d.png`
const img1977 = `${IMAGE_BASE_PATH}/ui/PersonalityArtist/SayedHaiderRaza/BodyofWork/IntegratedTimeline/1977.png`
const img1978 = `${IMAGE_BASE_PATH}/ui/PersonalityArtist/SayedHaiderRaza/BodyofWork/IntegratedTimeline/1978.png`
const img1981 = `${IMAGE_BASE_PATH}/ui/PersonalityArtist/SayedHaiderRaza/BodyofWork/IntegratedTimeline/1981.png`
const img1983 = `${IMAGE_BASE_PATH}/ui/PersonalityArtist/SayedHaiderRaza/BodyofWork/IntegratedTimeline/1983.png`
const img1983b = `${IMAGE_BASE_PATH}/ui/PersonalityArtist/SayedHaiderRaza/BodyofWork/IntegratedTimeline/1983b.png`
const full1943 = `${IMAGE_BASE_PATH}/ui/PersonalityArtist/SayedHaiderRaza/BodyofWork/IntegratedTimeline/FullScreenImage/1943.png`
const full1944 = `${IMAGE_BASE_PATH}/ui/PersonalityArtist/SayedHaiderRaza/BodyofWork/IntegratedTimeline/FullScreenImage/1944.png`
const full1945 = `${IMAGE_BASE_PATH}/ui/PersonalityArtist/SayedHaiderRaza/BodyofWork/IntegratedTimeline/FullScreenImage/1945.png`
const full1946 = `${IMAGE_BASE_PATH}/ui/PersonalityArtist/SayedHaiderRaza/BodyofWork/IntegratedTimeline/FullScreenImage/1946.png`
const full1947 = `${IMAGE_BASE_PATH}/ui/PersonalityArtist/SayedHaiderRaza/BodyofWork/IntegratedTimeline/FullScreenImage/1947.png`
const full1948 = `${IMAGE_BASE_PATH}/ui/PersonalityArtist/SayedHaiderRaza/BodyofWork/IntegratedTimeline/FullScreenImage/1948.png`
const imgsatyajit_1921 = `${IMAGE_BASE_PATH}/ui/PersonalityPolymath/SatyajitRay/BodyOfWork/IntegratedTimeline/satyajit_1921.png`
const imgsatyajit_1922 = `${IMAGE_BASE_PATH}/ui/PersonalityPolymath/SatyajitRay/BodyOfWork/IntegratedTimeline/satyajit_1922.png`
const imgsatyajit_1934 = `${IMAGE_BASE_PATH}/ui/PersonalityPolymath/SatyajitRay/BodyOfWork/IntegratedTimeline/satyajit_1934.png`

//songs booklet
const imgsongbooklet_1931 = `${IMAGE_BASE_PATH}/ui/ObjectBookSsb/SongsSynopsisBooklet/BodyOfWork/IntegratedTimeline/1931.png`
const imgsongbooklet_1932 = `${IMAGE_BASE_PATH}/ui/ObjectBookSsb/SongsSynopsisBooklet/BodyOfWork/IntegratedTimeline/1932.png`
const imgsongbooklet_1934a = `${IMAGE_BASE_PATH}/ui/ObjectBookSsb/SongsSynopsisBooklet/BodyOfWork/IntegratedTimeline/1934a.png`
const imgsongbooklet_1934b = `${IMAGE_BASE_PATH}/ui/ObjectBookSsb/SongsSynopsisBooklet/BodyOfWork/IntegratedTimeline/1934b.png`
const imgsongbooklet_1935a = `${IMAGE_BASE_PATH}/ui/ObjectBookSsb/SongsSynopsisBooklet/BodyOfWork/IntegratedTimeline/1935a.png`
const imgsongbooklet_1935b = `${IMAGE_BASE_PATH}/ui/ObjectBookSsb/SongsSynopsisBooklet/BodyOfWork/IntegratedTimeline/1935b.png`
const imgsongbooklet_1936 = `${IMAGE_BASE_PATH}/ui/ObjectBookSsb/SongsSynopsisBooklet/BodyOfWork/IntegratedTimeline/1936.png`
const imgsongbooklet_1937 = `${IMAGE_BASE_PATH}/ui/ObjectBookSsb/SongsSynopsisBooklet/BodyOfWork/IntegratedTimeline/1937.png`
const imgsongbooklet_1939 = `${IMAGE_BASE_PATH}/ui/ObjectBookSsb/SongsSynopsisBooklet/BodyOfWork/IntegratedTimeline/1939.png`
const imgsongbooklet_1940a = `${IMAGE_BASE_PATH}/ui/ObjectBookSsb/SongsSynopsisBooklet/BodyOfWork/IntegratedTimeline/1940a.png`
const imgsongbooklet_1940b = `${IMAGE_BASE_PATH}/ui/ObjectBookSsb/SongsSynopsisBooklet/BodyOfWork/IntegratedTimeline/1940b.png`
const imgsongbooklet_1941a = `${IMAGE_BASE_PATH}/ui/ObjectBookSsb/SongsSynopsisBooklet/BodyOfWork/IntegratedTimeline/1941a.png`
const imgsongbooklet_1941b = `${IMAGE_BASE_PATH}/ui/ObjectBookSsb/SongsSynopsisBooklet/BodyOfWork/IntegratedTimeline/1941b.png`
const imgsongbooklet_1941c = `${IMAGE_BASE_PATH}/ui/ObjectBookSsb/SongsSynopsisBooklet/BodyOfWork/IntegratedTimeline/1941c.png`
const imgsongbooklet_1941d = `${IMAGE_BASE_PATH}/ui/ObjectBookSsb/SongsSynopsisBooklet/BodyOfWork/IntegratedTimeline/1941d.png`
const imgsongbooklet_1943 = `${IMAGE_BASE_PATH}/ui/ObjectBookSsb/SongsSynopsisBooklet/BodyOfWork/IntegratedTimeline/1943.png`
const imgsongbooklet_1948a = `${IMAGE_BASE_PATH}/ui/ObjectBookSsb/SongsSynopsisBooklet/BodyOfWork/IntegratedTimeline/1948a.png`
const imgsongbooklet_1948b = `${IMAGE_BASE_PATH}/ui/ObjectBookSsb/SongsSynopsisBooklet/BodyOfWork/IntegratedTimeline/1948b.png`
const imgsongbooklet_1948c = `${IMAGE_BASE_PATH}/ui/ObjectBookSsb/SongsSynopsisBooklet/BodyOfWork/IntegratedTimeline/1948c.png`
const imgsongbooklet_1949a = `${IMAGE_BASE_PATH}/ui/ObjectBookSsb/SongsSynopsisBooklet/BodyOfWork/IntegratedTimeline/1949a.png`
const imgsongbooklet_1949b = `${IMAGE_BASE_PATH}/ui/ObjectBookSsb/SongsSynopsisBooklet/BodyOfWork/IntegratedTimeline/1949b.png`
const placeholder = `${IMAGE_BASE_PATH}/ui/ObjectBookSsb/SongsSynopsisBooklet/BodyOfWork/IntegratedTimeline/placeholder.png`

  //KG Subramanyam 
  const kgimg1944 = `${IMAGE_BASE_PATH}/ui/PersonalityArtist/KGSubramanyan/BodyofWork/IntegratedTimeline/1.png`
  const kgfullimg1944 = `${IMAGE_BASE_PATH}/ui/PersonalityArtist/KGSubramanyan/BodyofWork/IntegratedTimeline/fullImg1.png`
  const kgimg1947 = `${IMAGE_BASE_PATH}/ui/PersonalityArtist/KGSubramanyan/BodyofWork/IntegratedTimeline/2.png`
  const kgfullimg1947 = `${IMAGE_BASE_PATH}/ui/PersonalityArtist/KGSubramanyan/BodyofWork/IntegratedTimeline/fullImg2.png`
  const kgimg1949 = `${IMAGE_BASE_PATH}/ui/PersonalityArtist/KGSubramanyan/BodyofWork/IntegratedTimeline/3.png`
  const kgfullimg1949 = `${IMAGE_BASE_PATH}/ui/PersonalityArtist/KGSubramanyan/BodyofWork/IntegratedTimeline/fullImg3.png`
  const kgimg1950 = `${IMAGE_BASE_PATH}/ui/PersonalityArtist/KGSubramanyan/BodyofWork/IntegratedTimeline/4.png`
  const kgimg1951 = `${IMAGE_BASE_PATH}/ui/PersonalityArtist/KGSubramanyan/BodyofWork/IntegratedTimeline/5.png`
  const kgimg1952 = `${IMAGE_BASE_PATH}/ui/PersonalityArtist/KGSubramanyan/BodyofWork/IntegratedTimeline/6.png`
  const kgimg1953 = `${IMAGE_BASE_PATH}/ui/PersonalityArtist/KGSubramanyan/BodyofWork/IntegratedTimeline/7.png`
  const kgimg1954 = `${IMAGE_BASE_PATH}/ui/PersonalityArtist/KGSubramanyan/BodyofWork/IntegratedTimeline/8.png`

export const integratedTimelineDataByMasterlist=(alias)=>{
const data=[
  {
    "Sr_No": "",
    "time_period": "1922 – 1942",
    "title_of_time_period": "01_Early Years",
    "sortable_date": "1942",
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "Image1",
    "image1": img1942,
    "text_1": "1927-32 Education: Primary schooling, studied with teacher Nandalal Jharia\n1939 -42 Nagpur School of Art, Nagpur, India.",
    "caption_1": "S.H. Raza, seated to the left of the Principal of the Nagpur School of Art, 2nd Row",
    "Second ImageNumber (Internal for A-Graphy)": "",
    "Image Code(s)2": "",
    "text_2": "Raza has said: \"The most tenacious memory of my childhood is the fear and fascination of the Indian forest. We lived near the source of the Narmada river in the center of the densest forests of Madhya Pradesh. Nights in the forest were hallucinating I  sometimes the only humanizing influence was the dancing of the Gond tribes. Daybreak brought back a sentiment of security and well-being. On market-day, under the radiant sun, the village wasa  fairyland of colours. And then, the night again. Even today I find that these two aspects of my life dominate me and are an integral part of my painting. There are a multude of variations, but it has its departure point in an experienced feeling, even if the real problems are of a plastic nature.\" Extracted from Bartholomew, Richard (Curator)/Group ExC 1973 (9a)\nOne of the most oft repeated and famous statements of S.H. Raza, deeply influencing the interpretations of his artistic motivations and journey, after being first quoted and contextualised by eminent art critic Richard Bartholomew in 1973.",
    "Image Caption2": "",
    "Text3": "",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "1943 – 1948",
    "title_of_time_period": "02_Travel and an Academic Art Grounding",
    "sortable_date": "1943",
    "Specific Dates (with Day, Month)": "Nov. 1943",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "Image2",
    "image1": img1943,
    "fullImage":full1943,
    "fullId":0,
    "text_1": "Nov. 1943 Two Watercolours - Bombay Street Scene & Parsi Fire Temple - exhibited at the B.A.S. November Exhibition, each selling for 40 rupees. Rudi von Leyden reviews Exhibition and highlights the paintings by Raza. Thereafter introductions to Emmanuel Schlesinger and Walter Langhammer, so establishing constant relationships which were to nurture his early artistic journey into the late 1950s.",
    "caption_1": "S.H. Raza | Girgaon Town Scene | Watercolour on paper, 1943 | 14.6 x 13.8 in (37 x 35 cm)",
    "Second ImageNumber (Internal for A-Graphy)": "",
    "Image Code(s)2": "",
    "text_2": "Early 1940s Role of various emigrants from Germany & Austria during WW II, especially Walter Langhammer, Emmanuel Schlesinger & Rudi von Leyden played a pivotal role in nurturing Raza's early artistic dialogues with European modernism. Further, over the 1940s & beyond, the influence of Rajput and Jain miniatures were constant on his artistic sensibility. From the Indian modern masters the art of Rabindranath Tagore, Jamini Roy, Amrita Sher Gil, and later N.S. Bendre and S.B. Palsikar seemed relevant. Cezanne, contemporary French art, Gauguin and van Gogh, and later Oscar Kokoskcha, German Expressionism, Lorenzetti, the early Sienese School of Italian Primitives along with Byzantine art forms were the main international sources of inspiration into the early 1950s.",
    "Image Caption2": "",
    "Text3": "1943-1947 Completed Diploma of Paintings and Drawings from Sir J.J. School of Art, Bombay, India after Part-time study from 1943 I  J. Ahivasi was one of his teachers during this last and only full-time period of study at the Sir J.J. School of Art., 1946-7",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "1943 – 1948",
    "title_of_time_period": "02_Travel and an Academic Art Grounding",
    "sortable_date": "1944",
    "fullImage":full1944,
    "fullId":1,
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "Image3",
    "image1": img1944,
    "text_1": "",
    "caption_1": "S.H. Raza | Benaras | Watercolour on paper, 1944 | 24.2 x 28.9 in (61.4 x 73.5 cm)",
    "Second ImageNumber (Internal for A-Graphy)": "",
    "Image Code(s)2": "",
    "text_2": "",
    "Image Caption2": "",
    "Text3": "",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "1943 – 1948",
    "title_of_time_period": "02_Travel and an Academic Art Grounding",
    "sortable_date": "1945",
    "fullImage":full1945,
    "fullId":2,
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "Image4",
    "image1": img1945,
    "text_1": "",
    "caption_1": "S.H. Raza | Street Scene | Watercolour on paper, 1945 | 11.4 x 13 in (29 x 33 cm)",
    "Second ImageNumber (Internal for A-Graphy)": "",
    "Image Code(s)2": "",
    "text_2": "",
    "Image Caption2": "",
    "Text3": "",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "1943 – 1948",
    "title_of_time_period": "02_Travel and an Academic Art Grounding",
    "sortable_date": "1946",
    "fullImage":full1946,
    "fullId":3,
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "Image5",
    "image1": img1946,
    "text_1": "1946 Wins Silver Medal at the 55th Annual Bombay Art Society, Exhibition, Bombay, India",
    "caption_1": "S.H. Raza | Market Scene | Gouache on paper, 1946 | 13.8 x 5.9 in (35 x 15 cm)",
    "Second ImageNumber (Internal for A-Graphy)": "",
    "Image Code(s)2": "",
    "text_2": "Attends evening classes at the Mohan Art Club with the artist Mohan Kulkarni leading the group of students including Raza",
    "Image Caption2": "",
    "Text3": "",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "1943 – 1948",
    "title_of_time_period": "02_Travel and an Academic Art Grounding",
    "sortable_date": "1947",
    "fullImage":full1947,
    "fullId":4,
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "Image6",
    "image1": img1947,
    "text_1": "1947-48 First Solo Exhibition at the Bombay Art Society, Bombay, India, opens Thursday 20th November 1947.",
    "caption_1": "Cover of Bombay Art Society\n57th Annual Exhibition\nDecember 1947",
    "Second ImageNumber (Internal for A-Graphy)": "Image7",
    "Image Code(s)2": "0007-s-h-raza-IT.jpg",
    "Image Caption2": "S.H. Raza | Bori Bunder | Gouache on paper, 1947",
    "Text3": "Dec. 1947 S.H. Raza awarded a Gold Medal at the 57th Annual Exhibition, Presented by Bombay Art Society, Bombay, India",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },{
    "Sr_No": "",
    "time_period": "1943 – 1948",
    "title_of_time_period": "02_Travel and an Academic Art Grounding",
    "sortable_date": "1947",
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "Image6",
    "image1": img1947b,
    "scaption_1": "Cover of Bombay Art Society\n57th Annual Exhibition\nDecember 1947",
    "Second ImageNumber (Internal for A-Graphy)": "Image7",
    "Image Code(s)2": "0007-s-h-raza-IT.jpg",
    "text_1": "Nov. 1947 \"He is a romantic painter for whom there is more behind the every-day face of nature than what meets the eye. With an almost poetic penetration he can transform the grey vistas of Bori Bunder or Flora Fountain on a monsoon day into little rhapsodies and yet retain the intrinsic realities of his subjects…His vehicle of expression is colour. His forms, his compositon take their cue from colour. His whole understanding of nature and his emotional reaction are colour. This has led to the unlimited variety of colour association on which his fantasy can play. From the pastel shades through the dark Prussian blues and sepias of Omkareshwar, the salmon pink and blue composition of the Nasik River, practically each of his pictures brings new pleasures of colour…His compostion has improved over the years and most of his paintings have now a solid structure, although in some of them the foreground has not been solved and runs out into emptiness.\" von Leyden, Rudi, In The Times of India, November 1947, rpt. In Dalmia, Yashodhara/BKA 2001 (32) I  p.266",
    "caption_1": "S.H. Raza | Bori Bunder | Gouache on paper, 1947",
    "text_2": "Dec. 1947 S.H. Raza awarded a Gold Medal at the 57th Annual Exhibition, Presented by Bombay Art Society, Bombay, India",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "1943 – 1948",
    "title_of_time_period": "02_Travel and an Academic Art Grounding",
    "sortable_date": "1948",
    "fullImage":full1948,
    "fullId":5,
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "Carousel#02 (Kashmir Series | Image 8 to 19)",
    "image1": img1948,
    "text_1": "July-Aug. 1948 Kashmir Series exhibited in Srinagar, Kashmir. K.A. Abbas states that S.H. Raza's Kashmir Series 1948 paintings were exhibited (probably an informal display) in Srinagar on his Houseboat and mentions the presence of the following personalities at the Opening along with himself and his wife: Shaikh Abdullah, Bakshi Ghulam Mohammed, Henri Cartier-Bresson and his Indonesian dancer wife Ratna, General K.S. Thimmaya (a Major-General in 1948), Prem Nath Pardesi, among others. Rpt. in Abbas, Khwaja Ahmad/AutoBiography 1977 (11) I  p.316. K.A. Abbas and his wife would visit Raza and Janine in Paris post 1960.",
    "caption_1": "S.H. Raza | Srinagar, Kashmir Series | Gouache on paper, 1948 | 21.6 x 29.7 in (54.9 x 75.4 cm) |",
    "Second ImageNumber (Internal for A-Graphy)": "Image20",
    "Image Code(s)2": "0020-s-h-raza-IT.jpg",
    "text_2": "1st Sept. 1948 100 Kashmir Paintings, Exhibition Hall, Parliament Street,  Delhi I  inaugurated by Rudi von Leyden. 19th Oct. 1948 Kashmir Paintings Exhibition, Bombay Art Society Salon, Bombay, India I  inaugurated by K.G. Sauyiddain, Educational Advisor to Govt. of Bombay",
    "Image Caption2": "S.H. Raza | Landscape  | Oil on board, 1948 | 17.5 x 22 in (44.5 x 56 cm)",
    "Text3": "Founder Member of Progressive Artists' Group which was established with F.N. Souza's vision and efforts who had invited M.F. Husain to join, while H.A. Gade invited S.H. Raza to join and K.H. Ara invited S.N. Bakre to join. The Group changed and embraced various artists at different stages of its evolution until it was disbanded in 1954. Mohan Samant (left for USA in 1952), Krishen Khanna & V.S. Gaitonde were invited to join after 1950 once Souza, Raza and Bakre had left India. Others such as G.M. Hazarnis joined for for a very brief period, as did Bhanu Rajapadhya (Athaiya), but she never exhibited or participated in any P.A.G. Exhibition.",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  // {
  //   "Sr_No": "",
  //   "time_period": "1949 – 1953",
  //   "title_of_time_period": "03_Briefly a 'Progressive' and towards a full life in France",
  //   "sortable_date": 1949,
  //   "Specific Dates (with Day, Month)": "21st February 1949 - 21st March 1949",
  //   "First ImageNumber (Internal for A-Graphy), incl. Carousel": "Image21",
  //   "image1": "0021-s-h-raza-IT.jpg",
  //   "text_1": "21st February 1949 - 21st March 1949: Bombay Progressives Artists Group: Exhibition of Paintings & Sculptures by K.H. Ara, S.N. Bakre, M.F.Husain, F.N. Souza, H.A. Gade & S.H. Raza at Baroda State Picture Gallery, (Baroda, India).\n\nThe competitive exhibition for the 1950 French Scholarship which S.H. Raza was awarded was held at the Calcutta Government Art School, in which the artworks by H.A. Gade, Ramkumar, Amina Ahmed, S.N. Butt, A. Paidaraju, K.S. Kulkarni, Vidya Bhusan, among others were included.",
  //   "caption_1": "S.H. Raza | Untitled | Gouache on paper, 1949 | 14 x 16.9 in (35.5 x 43 cm)",
  //   "Second ImageNumber (Internal for A-Graphy)": "",
  //   "Image Code(s)2": "",
  //   "text_2": "",
  //   "Image Caption2": "",
  //   "Text3": "",
  //   "Masterlist1": "",
  //   "Masterlist2": "",
  //   "Masterlist3": "",
  //   "Keywords": "",
  //   "Research Category1": "",
  //   "Research Category2": "",
  //   "Research Category3": ""
  // },
  {
    "Sr_No": "",
    "time_period": "1949 – 1953",
    "title_of_time_period": "03_Briefly a 'Progressive' and towards a full life in France",
    "sortable_date": "1950",
    "image1": img1950,
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "Image22",
    "text_1": "Scholarship from French Government, Ecole Nationale Superieure des Beaux-Arts, Paris, France.",
    "caption_1": "S.H. Raza | Kashmir Houseboats | Watercolour on paper, 1950 | 17.2 x 24.5 in (43.7 x 62.3 cm)",
    "Second ImageNumber (Internal for A-Graphy)": "",
    "Image Code(s)2": "",
    "text_2": "13th to 19th Sept. 1950 Last Exhibition at the Charles Petras Institute of Foreign Languages, Bombay, of approx. 80 paintings (ExC. illustrates only 44), displaying works from the 1943-1950 period, before leaving for France. Exhibition closes on 19th Sept., and Raza leaves on 21st Sept. 1950 for Marseilles, thereafter to Paris by train, reaching on 3rd October 1950.",
    "Image Caption2": "",
    "text_3": "“In his farewell exhibition in Bombay, organized by the Institute of Foreign Languages, were to be seen one or two paintings which anticipated this turn to clarity and order after the emotional upheaval of the expressionist phase. There began to appear now out of his studio, after long and arduous work, a new type of landscape. Stylised houses, towers, spires meticulously assembled in paintings where they lived their own mysterious life. They did not seem to belong to any age of man…Over these works Raza had taken infinite pains. Each shape was carefully related to another, weighed, balanced till it had found its place in the composition which would appear as unshakeable. Colour had undergone the most intricate studies to be able to express the finest overtones of a poetic situation… They were as austere and as sensitive as the landscape backgrounds in the paintings of the Sienese primitives with their garlands of houses, walls and towers strung across the horizon.” Extracted from von Leyden, Rudolf/BKA 1959 (3) I  p.18.",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "1949 – 1953",
    "title_of_time_period": "03_Briefly a 'Progressive' and towards a full life in France",
    "sortable_date": "1951",
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "Image23",
    "image1": img1951,
    "text_1": "S.H. Raza’s First International Group Exhibition, at Galerie Saint-Placide (Paris, France) with F.N. Newton and Akbar Padamsee.",
    "caption_1": "S.H. Raza | Carcassonne | Mixed media on panel, 1951 | 18 x 21 in (45.7 x 53.3 cm)",
    "Second ImageNumber (Internal for A-Graphy)": "",
    "Image Code(s)2": "",
    "text_2": "First Solo Exhibition in France at the Palais Carnoles, Menton, which at the time was under the Presidency of Henri Matisse, and over the next few years would exhibit many of the great modern masters from Picasso to Dali, Dufy to Sutherland.",
    "Image Caption2": "",
    "Text3": "",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "1949 – 1953",
    "title_of_time_period": "03_Briefly a 'Progressive' and towards a full life in France",
    "sortable_date": "1952",
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "Image24",
    "image1": img1952,
    "text_1": "Galerie Raymond Creuze Group Exhibition with F.N. Souza, S.H. Raza & Akbar Padamsee, Paris, France. Raza had by now taken to using oil paints as his main medium I  he was still financially struggling, hence part-time work in designing book covers for a leading French publisher who represented writers such as Emile Zola and Edgar Allan Poe.",
    "caption_1": "Caption Awaited",
    "Second ImageNumber (Internal for A-Graphy)": "Image25",
    "Image Code(s)2": "0025-s-h-raza-IT.jpg",
    "text_2": "",
    "Image Caption2": "S.H. Raza | Cagnes-Sur-Mer | Gouache and ink on cardboard, 1952 | 19.7 x 22 in (50 x 56 cm)",
    "Text3": "",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "1949 – 1953",
    "title_of_time_period": "03_Briefly a 'Progressive' and towards a full life in France",
    "sortable_date": "1953",
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "Image26",
    "image1": img1953,
    "text_1": "“The new phase which was to break in about 1953, was one of melting down and fusion. In the furnace of an entirely new passion for colour Raza’s new style was born that was to bring to him international fame in a few short years. The gouache technique in tempera was to give way to impasto in oils. The change of medium and manner were not merely technical but signified a fundamental change of attitude. The scholar, who had measured and calculated, burst through the confines of limited understanding of colour and space-created-by-colour into a sphere of full realization. The transformation created such passion that one could best describe this age of Raza as the age of the Lover. This triumphant handling of paint, this living in paint can only be understood as an act of Love. His colours take on an entirely new complexion. Brilliant reds and yellows stand out against large looming forces of black and deep Prussian blue. Shapes dissolve in seas of colours which are by no means unorganized and fluid but seem to move and evolve within the space of his painting.” Extracted from von Leyden, Rudolf/BKA 1959 (3) I  p.19.",
    "caption_1": "S.H. Raza | La Tour | Oil on isorel board, 1953 | 33.1 x 19.7 in (84 x 50 cm)",
    "Second ImageNumber (Internal for A-Graphy)": "Image27",
    "Image Code(s)2": "0027-s-h-raza-IT.jpg",
    "text_2": "",
    "Image Caption2": "S.H. Raza | Italian Village | Oil on isorel board, 1953 | 39.4 x 47.2 in (100 x 120 cm)",
    "Text3": "",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "1954 – 1959",
    "title_of_time_period": "04_Emergence of critical respect and financial stability",
    "sortable_date": "1954",
    "Specific Dates (with Day, Month)": "20th September to 6th October 1955",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "Image28",
    "image1": img1954,
    "text_1": "20th September to 6th October 1955 Solo Exhibition at Galerie Lara Vincy, Paris, France, with a new contract with the Gallery, bringing a degree of financial stability with advances against monthly purchases of his art.",
    "caption_1": "S.H. Raza | Les Toits | Oil on canvas, 1955 | 19.5 x 39.4 in (49.5 x 100 cm)",
    "Second ImageNumber (Internal for A-Graphy)": "",
    "Image Code(s)2": "",
    "text_2": "",
    "Image Caption2": "",
    "Text3": "",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "1954 – 1959",
    "title_of_time_period": "04_Emergence of critical respect and financial stability",
    "sortable_date": "1956",
    "Specific Dates (with Day, Month)": "14th May to 5th June 1956",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "Image29",
    "image1": "0029-s-h-raza-IT.jpg",
    "text_1": "14th May to 5th June 1956 Galerie Lara Vincy, Paris, France July - Prix de la Critique d’Art awarded to S.H. Raza from a final selected list of 18, the first non-French artist to win the prestigous honour I  the Awarding Jury of 14 Artists was presided over by Le Figaro art critic - M. Claude Roger Marx, Paris, France, among other members of the foreign press in France & Belgium.",
    "caption_1": "S.H. Raza | Nuage Blanc | Oil on canvas, 1956 | 35.6 x 45.2 in (90.5 x 114.9 cm)",
    "Second ImageNumber (Internal for A-Graphy)": "",
    "Image Code(s)2": "",
    "text_2": "July to October 1956 Participated at the 28th Biennale Internazionnale d’Arte di Venezia, (Venice, Italy) with his major painting Ville Provencale (1956).",
    "Image Caption2": "",
    "Text3": "October : Solo Exhibition at Galerie Saint-Placide, Paris, France I  6th to 31st December : Saint-Germian-des-Pres, vu par vingt peintures, Galerie Barbizon, Paris, France.\n\nLes Arts en France et dans Ie Monde, Musee d’ Art Moderne, Paris, France.",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "1954 – 1959",
    "title_of_time_period": "04_Emergence of critical respect and financial stability",
    "sortable_date": "1957",
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "Image30",
    "image1": "0030-s-h-raza-IT.jpg",
    "text_1": "“I have invitations for several exhibitions (e.g. The Biennales at Venice, Tokyo Sao Paulo, London, Brussels, New York among others) - to which in the past I had no access. Remember - the canvas we carried to Café dome in the metro - and well so many others - where constantly my work was refused.” Raza to his friend Rajesh Rawat, in a letter dt. 2nd May 1957 I  rpt. in Dalmia, Yashodhara/BKA 2021 (61) I  p.84.",
    "caption_1": "S.H. Raza | Untitled | Oil on canvas, 1957 | 18.2 x 21.8 in (46.3 x 55.3 cm)",
    "Second ImageNumber (Internal for A-Graphy)": "Image31",
    "Image Code(s)2": "0031-s-h-raza-IT.jpg",
    "text_2": "Senior art critics and connoisseurs such as Claude Roger-Marx, Jaques Lassaigne, Waldemar George started appreciating and writing on Raza’s art.\n\n“Shelters perched on the edge of the volcanoes are swept by flames of hell…An orgy of colour is unleashed against a chimeric background of gulfs and crevasses. The mind boggles before this tornado of incandescent tones. Here are the ruins of little alpine towns, dead towns, deserted by their population.The church steeple, with its leaning tower, the cross outlined against a firmament of lead or basalt... Calvaries rise up like gibbets in a landscape of winter and crystal. Raza’s palette is made up of powdered gems, molten metals and embers in which the fire still lives.” Extracted from George, Waldemar. Peintures et Gouaches. Montreal: Galerie Dresdnere 1959 [Raza Solo ExC., 4a].",
    "Image Caption2": "S.H. Raza | Vue de Partinello | Oil on canvas, 1957 | 31.5 x 72.4 in (80 x 184 cm)",
    "Text3": "",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "1954 – 1959",
    "title_of_time_period": "04_Emergence of critical respect and financial stability",
    "sortable_date": "1958",
    "Specific Dates (with Day, Month)": "18th April to 14th May 1958",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "Image32",
    "image1": "0032-s-h-raza-IT.jpg",
    "text_1": "18th April to 14th May 1958 First Solo Exhibition at the Galerie Lara Vincy, Paris, France, which would establish a significant artist-gallery relationship until the early 1970s.",
    "caption_1": "S.H. Raza | Paysage Corse Series | Oil on canvas, 1958 | 30.3 x 36.1 in (76.962 x 91.694 cm)",
    "Second ImageNumber (Internal for A-Graphy)": "Image33",
    "Image Code(s)2": "0033-s-h-raza-IT.jpg",
    "text_2": "Transferences, Zwemmer Gallery, London, UK.\n\nBiennale de Bruges, Bruges, Belgium      Biennale Sao Paulo, Sao Paulo, Brazil",
    "Image Caption2": "1958 Cover of S.H. Raza’s Solo Exhibition at Galerie Lara Vincy, Paris.",
    "Text3": "Henri Cartier-Bresson taking Raza’s photograph at his studio also a sign of Raza’s growing fame, after ten years of their meeting at Srinagar, India.",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "1954 – 1959",
    "title_of_time_period": "04_Emergence of critical respect and financial stability",
    "sortable_date": "1959",
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "Image34",
    "image1": "0034-s-h-raza-IT.jpg",
    "text_1": "April : First Solo Exhibition at the Jehangir Art Gallery (Bombay) & AIFACS (New Delhi), and the first in India since 1950. Sadanga’s Monograph on Raza published with text by Rudi von Leyden.",
    "caption_1": "S.H. Raza | Temple | Oil on canvas, 1959 | 24 x 22.5 in (61 x 57.2 cm)",
    "Second ImageNumber (Internal for A-Graphy)": "",
    "Image Code(s)2": "",
    "text_2": "May : First Solo Exhibition at Galerie Dresdnere, Montreal, Canada, which would represent Raza for a decade (with further Solo Exhibitions in 1960, 1962 & 1968) along with Galerie Lara Vincy (1961, 1962, 1964, 1967 & 1969) I  Trends in Contemporary Painting from India: 10th Feb. to 5th Sept. 1959 - Graham Gallery, New York, USA > American University, Washington DC > West Virginia Institute of Technology, West Virginia > Speed Museum, Kentucky > Dorothy Yepez Gallery, New York, USA.",
    "Image Caption2": "",
    "Text3": "3rd September S.H. Raza marries artist Janine Mongillat in Paris after divorcing his first wife Mukhtar Fatima, who had moved to Pakistan from India as growing incompatibility developed with Raza.",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "1960 – 1968",
    "title_of_time_period": "05_Post-Marriage, with a major shift in his artistic abstraction",
    "sortable_date": "1960",
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "Image35",
    "image1": img1960,
    "text_1": "Trends in Contemporary Painting from India: Travelling Exhibition at Western Michigan University, Michigan, USA 4th to 25th January 1960 > Eastern Tennessee State College, Tennesse 6th to 27th February 1960 > Denison College, Ohio 3rd to 23rd March 1960 > Chatham College, Pennsylvania 12th April to 3rd May 1960.",
    "caption_1": "S.H. Raza | Zameen | Oil on canvas, 1960 | 39.2 x 32 in (99.6 x 81.3 cm)",
    "Second ImageNumber (Internal for A-Graphy)": "",
    "Image Code(s)2": "",
    "text_2": "",
    "Image Caption2": "",
    "Text3": "",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "1960 – 1968",
    "title_of_time_period": "05_Post-Marriage, with a major shift in his artistic abstraction",
    "sortable_date": "1961",
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "Image36",
    "image1": img1961,
    "text_1": "19th April to 18th May 1961 Galerie Lara Vincy, Paris\n“… his paintings were shown as forming part of the ‘Ecole de Paris’ in three consecutive exhibitions held from 1960 to 1962, organised by Galerie Charpentier... AS one critic put it in 1967, “ this is an Indian truly from our country!” Cet Indien varaiment bien de chez nous.” Rpt. in Sen, Dr. Geeti/BKA 1997 (23) I  p.71 Ecole de Paris 61, Galerie Charpentier, Paris, France Galerie Semiha Huber, Zurich, Switzerlan.\n\nThe experimenting with script inscribed on the painting begins, though it only starts to take on a deliberate painterly quality by the late 1960s.",
    "caption_1": "S.H. Raza | Foret Noir (Black Forest) | Oil on canvas, 1961 | 11.2 x 23 in (28.4 x 58.4 cm)",
    "Second ImageNumber (Internal for A-Graphy)": "",
    "Image Code(s)2": "",
    "text_2": "",
    "Image Caption2": "",
    "Text3": "",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "1960 – 1968",
    "title_of_time_period": "05_Post-Marriage, with a major shift in his artistic abstraction",
    "sortable_date": "1962",
    "Specific Dates (with Day, Month)": "30th July to 7th September",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "Image37",
    "image1": img1962,
    "text_1": "30th July to 7th September Visiting Lecturer at the Painting Department of University of California, Berkeley, USA, upon the invitation of Prof. Karl Kasten I  and thereafter a one month visit to New York & Washington on the invitation of the Rockefeller Foundation. The USA Visit also introduced Raza to acrylic colours which was a significant turning point, as it complemented his work in oil given his sensibilities and his previous love for watercolours during the 1940s.",
    "caption_1": "S.H. Raza | Route De Chomerac | Oil on canvas, 1962 | 26 x 32 in (66 x 81.3 cm)",
    "Second ImageNumber (Internal for A-Graphy)": "",
    "Image Code(s)2": "",
    "text_2": "First USA Solo Exhibitions at Lanyon Gallery, Palo Alto, California & Worth Ryder Art Gallery, Berkeley University, California.",
    "Image Caption2": "",
    "Text3": "Gallery 63, New York, USA.\n\nCommonwealth Exhibition, Commonwealth Institute, London, UK.\n\nThomas B. Keehn helps Raza get an one month stay in New York during October on invitation of the Rockefeller Foundation, where he met Mr & Mrs John D. Rockefeller, who had pioneered many of the international cultural institution-building efforts, especially with Asia and India.",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "1960 – 1968",
    "title_of_time_period": "05_Post-Marriage, with a major shift in his artistic abstraction",
    "sortable_date": "1963",
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "Image38",
    "image1": img1963,
    "text_1": "June First Solo Exhibition at Dom Gallery, Cologne, Germany.",
    "caption_1": "S.H. Raza | Hut | Oil on canvas, 1963 | 16 x 13 in (40.6 x 33 cm)",
    "Second ImageNumber (Internal for A-Graphy)": "",
    "Image Code(s)2": "",
    "text_2": "Biennale du Maroc, Rabat, Morocco.",
    "Image Caption2": "",
    "Text3": "",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "1960 – 1968",
    "title_of_time_period": "05_Post-Marriage, with a major shift in his artistic abstraction",
    "sortable_date": "1964",
    "Specific Dates (with Day, Month)": "18th November 1964",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "Image39",
    "image1": img1964,
    "text_1": "18th November 1964 - 10th January 1965 Solo Exhibition at Galerie Lara Vincy, titled RAZA: Peintures récentes, Paris, France.",
    "caption_1": "S.H. Raza at his Studio, Paris\nImage from Gallery Chemould Exhibition Catalogue 1968",
    "Second ImageNumber (Internal for A-Graphy)": "",
    "Image Code(s)2": "",
    "text_2": "Biennale de Menton, Menton, France.",
    "Image Caption2": "",
    "Text3": "",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "1960 – 1968",
    "title_of_time_period": "05_Post-Marriage, with a major shift in his artistic abstraction",
    "sortable_date": "1965",
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "Image40",
    "image1": img1965,
    "text_1": "Art Now in India, Newcastle, UK - Travelling Group Exhibition.",
    "caption_1": "S.H. Raza | La Provence Noir | Acrylic on canvas, 1965 | 79.5 x 72 in (201.9 x 182.9 cm)",
    "Second ImageNumber (Internal for A-Graphy)": "",
    "Image Code(s)2": "",
    "text_2": "",
    "Image Caption2": "",
    "Text3": "",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "1960 – 1968",
    "title_of_time_period": "05_Post-Marriage, with a major shift in his artistic abstraction",
    "sortable_date": "1966",
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "Image41",
    "image1": img1966,
    "text_1": "",
    "caption_1": "S.H. Raza | Untitled | Acrylic on canvas, 1966 | 51.2 x 53.9 in (130 x 137 cm)",
    "Second ImageNumber (Internal for A-Graphy)": "",
    "Image Code(s)2": "",
    "text_2": "",
    "Image Caption2": "",
    "Text3": "",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "1960 – 1968",
    "title_of_time_period": "05_Post-Marriage, with a major shift in his artistic abstraction",
    "sortable_date": "1967",
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "Image42",
    "image1":img1967,
    "text_1": "",
    "caption_1": "S.H. Raza | Col De Castillon | Oil on canvas, 1967 | 24 x 19.5 in (61 x 49.5 cm)",
    "Second ImageNumber (Internal for A-Graphy)": "Image43",
    "Image Code(s)2": "0043-s-h-raza-IT.jpg",
    "text_2": "",
    "Image Caption2": "S.H. Raza | Gods Dwell Where Women is Adored | Acrylic on canvas, 1967 | 28.7 x 21.3 in (73 x 54 cm)",
    "Text3": "",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "1960 – 1968",
    "title_of_time_period": "05_Post-Marriage, with a major shift in his artistic abstraction",
    "sortable_date": "1968",
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "Image44",
    "image1": img1968,
    "text_1": "Solo Exhibitions in Germany at Dom Gallery (Cologne) & Tecta Gallery (Dusseldorf) I  Galerie Dresdnere (Toronto, Canada).",
    "caption_1": "S.H. Raza | Black Moon | Oil on canvas, 1968 | 36.2 x 28.7 in (92 x 73 cm)",
    "Second ImageNumber (Internal for A-Graphy)": "Image45",
    "Image Code(s)2": "0045-s-h-raza-IT.jpg",
    "text_2": "15th to 27th April : First Solo Exhibition at Gallery Chemould (Bombay, India), who would be Raza’s main Indian Gallery during the next forty years displaying some of his very best work (Further Solo Exhibitions in 1976, 1984, 1988 & 2002).",
    "Image Caption2": "S.H. Raza | Black Sun | Oil on canvas, 1968 | 59.1 x 59.1 in (150 x 150 cm)",
    "Text3": "",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "1969 – 1976",
    "title_of_time_period": "06_A new artistic maturity as the whole finds a diverse embrace",
    "sortable_date": "1970",
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "Image46",
    "image1": img1970,
    "text_1": "",
    "caption_1": "S.H. Raza | Aj | Oil on canvas, 1970 | 18 x 15 in (45.7 x 38 cm)",
    "Second ImageNumber (Internal for A-Graphy)": "",
    "Image Code(s)2": "",
    "text_2": "",
    "Image Caption2": "",
    "Text3": "",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "1969 – 1976",
    "title_of_time_period": "06_A new artistic maturity as the whole finds a diverse embrace",
    "sortable_date": "1971",
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "Image47",
    "image1": img1971,
    "text_1": "",
    "caption_1": "S.H. Raza | L'Inconnu | Acrylic on canvas, 1971 | 78.7 x 39.4 in (200 x 100 cm)",
    "Second ImageNumber (Internal for A-Graphy)": "",
    "Image Code(s)2": "",
    "text_2": "",
    "Image Caption2": "",
    "Text3": "",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "1969 – 1976",
    "title_of_time_period": "06_A new artistic maturity as the whole finds a diverse embrace",
    "sortable_date": "1972",
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "Image48",
    "image1": img1972,
    "text_1": "1972 First Solo Exhibition at the Abbaye du Pommier, Anncy, Le Grenier, Roquebrune Village, France.",
    "caption_1": "S.H. Raza | Tapovan | Acrylic on canvas, 1972 | 62.5 x 74 in (158.8 x 188 cm)",
    "Second ImageNumber (Internal for A-Graphy)": "",
    "Image Code(s)2": "",
    "text_2": "Tapovan is till date the most expensive painting sold in public auctions by the artist.",
    "Image Caption2": "",
    "Text3": "",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "1969 – 1976",
    "title_of_time_period": "06_A new artistic maturity as the whole finds a diverse embrace",
    "sortable_date": "1973",
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "Image49",
    "image1": img1973,
    "text_1": "The impact of the American Abstract Expressionist movement (especially the work and ideas of Hans Hoffman, Sam Francis and Mark Rothko) along with the adoption of acrylic paints hence a more fluid brush movement post his USA visit had inspired a change in Raza’s abstract forms, as he states: Thereafter, a visual reality, the aim to construct a “tangible” world, receded. In its place there was a preoccuptaion with evoking the essence, the mood of places and of people. day and night, summer and winter, joy and anguish - these elementary experiences that are felt rather than seen, became my subjects.” Extracted from Sen, Dr. Geeti/BKA 1997 (23) I  p.59.",
    "caption_1": "S.H. Raza | La Terre | Oil on canvas, 1973 | 74.4 x 74.4 in (189 x 189 cm)",
    "Second ImageNumber (Internal for A-Graphy)": "",
    "Image Code(s)2": "",
    "text_2": "",
    "Image Caption2": "",
    "Text3": "",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "1969 – 1976",
    "title_of_time_period": "06_A new artistic maturity as the whole finds a diverse embrace",
    "sortable_date": "1974",
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "Image50",
    "image1": img1974,
    "text_1": "Solo Exhibition at La Palette, Trouville, France.\n\nGroup Exhibitions Premier Salon International d’Art Contemporain, Paris, France.\n\nSalon Grands et Jeunes d’ Aujourd’hui, Paris, France.\n\nLalit Kala Akademi, Rabindra Bhawan Galleries, New Delhi, India.",
    "caption_1": "S.H. Raza | LA MER | Acrylic on canvas, 1974 | 59.8 x 59.8 in (152 x 152 cm)",
    "Second ImageNumber (Internal for A-Graphy)": "",
    "Image Code(s)2": "",
    "text_2": "",
    "Image Caption2": "",
    "Text3": "",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "1969 – 1976",
    "title_of_time_period": "06_A new artistic maturity as the whole finds a diverse embrace",
    "sortable_date": "1975",
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "Image51",
    "image1": img1975,
    "text_1": "1975 First Solo Exhibition at Galleria Matuzia (San Remo, Italy) where La Mer, among other constant paintings, was exhibited, purchased by Italian Collector - Luciano G. Lupi, later exhibited at Jehangir Art Gallery & Gallery Chemould Exhibition (1976) and thereafter auctioned in Sotheby’s (24th March 2010) and Christie’s (20th March 2019) once the Indian art market starting to grow.",
    "caption_1": "S.H. Raza | Oasis | Acrylic on canvas, 1975 | 39 x 39 in (99.1 x 99.1 cm)",
    "Second ImageNumber (Internal for A-Graphy)": "",
    "Image Code(s)2": "",
    "text_2": "His description of a painting such as Oasis (1975) is insightful: “The whole fabric of the painting appears to be a collated vision of a continuous expanse of phenomena we have experienced as nature - night fires, the glow of sunlight, green fields at daytime, and in twilight, trees and their forms merging with the night. A loose sectional design is deployed, never rigidly, and sections enclose triangular forms, fully or partially formulated, which thrust out in many directions. A nervous life is generated by these active forms and live colours, and there is the suggestion of the presence of a vast, fecund force, a “dark inscrutable force”, as Wordsworth said, “that reconciles discordant elements and makes them cling together in one society.” Extracted from Bartholomew, Richard. ‘Nature and Abstraction: An Enquiry into Their Interaction’. New Delhi: Lalit Kala Contemporary Journal (#23) for Lalit Kala Akademi 1977 [Essay: JOU, 12].",
    "Image Caption2": "",
    "Text3": "",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "1969 – 1976",
    "title_of_time_period": "06_A new artistic maturity as the whole finds a diverse embrace",
    "sortable_date": "1976",
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "Image52",
    "image1": img1976,
    "text_1": "“Raza always painted an imaginary world, traversed by tragic intensities, with his energies concentrated at the points where his coloured planes overlapped. In the thickness of his matter, a whole network of coloured veins circulated I  flashing reds and yellows pierced deep blacks. Effects of tension and nervous agitation upset shadowy zones. The composition itself was affected by this, and in a given work, the compressed pulsations of the forms, the character of which could be defined as anguishing, were in opposition to immense, light and calm surfaces. Thus, ever faithful to his deep sentiments, Raza sought to free himself of the oppression of the night and to glorify the serenity rediscovered in the light of dawn.” Extracted from Jacques Lassaigne, rpt. in Gallery Chemould. Raza. Bombay: Gallery Chemould 1976 [Raza Solo ExC., 10].",
    "caption_1": "S.H. Raza | Untitled (Matheran) | Acrylic on canvas, 1976 | 39.5 x 39.5 in (100.3 x 100.3 cm)",
    "Second ImageNumber (Internal for A-Graphy)": "",
    "Image Code(s)2": "",
    "text_2": "",
    "Image Caption2": "",
    "Text3": "",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "1961 – 1983",
    "title_of_time_period": "07_A Focus on the Rajasthan Series",
    "sortable_date": "1961",
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "Image53",
    "image1": img1961_d,
    "text_1": "“A fact has hardly been noticed: that from 1970, for fifteen years, Raza did not hold a single exhibition in Paris. (Fn.13: Geeti Sen thanks Dominique Rey for bringing this fact to her notice). His first exhibition thereafter was held at Galerie Pierre Parat and titled significantly, “Raza: Quinze Ans de Peinture” or Raza’s Fifteen Years of Painting”.",
    "caption_1": "S.H. Raza | Rajasthan I | Oil on canvas, 1961 | 19.8 x 19.6 in (50.2 x 49.8 cm)",
    "Second ImageNumber (Internal for A-Graphy)": "",
    "Image Code(s)2": "",
    "text_2": "The first documented painting titled “Rajasthan” was in 1961 and not during his Berkeley visit as suggested in Sen, Dr. Geeti/BKA 2020 (60) I  p.128, but her insights on the Rajasthan Series are still the most deeply pursued: “Rajasthan becomes a metaphor for the colours of India: of vibrant greens and vermillion and ochres, as also blacks. Rajasthan is the mapping out of a metaphorical space in the mind which is then enclosed witha broad border in bold vermillion - as also happens to be the case in Rajput paintings from the sixteenth and seventeenth centuries. The image becomes thus enstrined as an icon, as sacred geography.”",
    "Image Caption2": "",
    "Text3": "",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "1961 – 1983",
    "title_of_time_period": "07_A Focus on the Rajasthan Series",
    "sortable_date": "1973",
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "Image54",
    "image1": img1973_d,
    "text_1": "",
    "caption_1": "S.H. Raza | Rajasthan | Acrylic on canvas, 1973 | 25.6 x 19.7 in (65 x 50 cm)",
    "Second ImageNumber (Internal for A-Graphy)": "",
    "Image Code(s)2": "",
    "text_2": "",
    "Image Caption2": "",
    "Text3": "",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "1961 – 1983",
    "title_of_time_period": "07_A Focus on the Rajasthan Series",
    "sortable_date": "1975",
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "Image55",
    "image1": img1975_d,
    "text_1": "",
    "caption_1": "S.H. Raza | Rajasthan | Acrylic on canvas, 1975 | 59.8 x 59.8 in (152 x 152 cm)",
    "Second ImageNumber (Internal for A-Graphy)": "",
    "Image Code(s)2": "",
    "text_2": "",
    "Image Caption2": "",
    "Text3": "",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "1961 – 1983",
    "title_of_time_period": "07_A Focus on the Rajasthan Series",
    "sortable_date": "1977",
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "Image56",
    "image1": img1977,
    "text_1": "",
    "caption_1": "S.H. Raza | Rajasthan | Acrylic on canvas, 1977 | 25.6 x 19.7 in (65 x 50 cm)",
    "Second ImageNumber (Internal for A-Graphy)": "",
    "Image Code(s)2": "",
    "text_2": "",
    "Image Caption2": "",
    "Text3": "",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "1961 – 1983",
    "title_of_time_period": "07_A Focus on the Rajasthan Series",
    "sortable_date": "1978",
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "",
    "image1": img1978,
    "text_1": "1978 An exhibition ‘Utsav’ at Madhya Pradesh Kala Parishad, Bhopal, the first ever in his homestate M.P. was organised with a Seminar on his art with Richard Bartholomew, Geeti Sen and other scholars. This Utsav was also constant for cementing a  life long friendship and creative collaboration between artist Raza and poet-bureaucrat Ashok Vajpeyi.",
    "caption_1": "CAPTION AWAITED",
    "Second ImageNumber (Internal for A-Graphy)": "",
    "Image Code(s)2": "",
    "text_2": "",
    "Image Caption2": "",
    "Text3": "",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "1961 – 1983",
    "title_of_time_period": "07_A Focus on the Rajasthan Series",
    "sortable_date": "1981",
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "Image57",
    "image1": img1981,
    "text_1": "",
    "caption_1": "S.H. Raza | Rajasthan | Acrylic on canvas, 1981 | 47.2 x 47.2 in (120 x 120 cm)",
    "Second ImageNumber (Internal for A-Graphy)": "",
    "Image Code(s)2": "",
    "text_2": "",
    "Image Caption2": "",
    "Text3": "",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "1961 – 1983",
    "title_of_time_period": "07_A Focus on the Rajasthan Series",
    "sortable_date": "1983",
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "Image58",
    "image1": img1983,
    "text_1": "“Raza’s semi-abstractions by contrast stem from the magical, transfixing power of the miniature. Rajasthan II (cat. No. 52) is modulary structured, with each module being the reduction of a miniature format. These modules reverberate with the miniature’s musicality. If Raza’s elegant astringency bespeaks his home in the south of France, his forms and colours bespeak his youth in the forests of Madhya Pradesh, whose fluid, inviting, and slightly terrifying pathways he has described as among the most profound influences of his life.” Daniel Herwitz, extracted from Grey Art Gallery & Study Center. Contemporary Indian Art: from the Chester and Davida Family Collection. New York: Grey Art Gallery & Study Center & New York University 1985 [General Art History I  Collection, 13] I  p.25.",
    "caption_1": "S.H. Raza | Rajastan I | Acrylic on canvas, 1983 | 60 x 60 in (152.4 x 152.4 cm)",
    "Second ImageNumber (Internal for A-Graphy)": "Image59",
    "Image Code(s)2": "0059-s-h-raza-IT.jpg",
    "text_2": "“Memory began to play a role in recalling the experience and mood of a situation. Paintings such as Rajasthan and Saurasthra summoned up that quality of light, patterns and textures and colours that had remained embedded in my mind. Instead of being constructions, my paintings from the 1970s are more gestural in technique and expression. In terms of colour too, they are expressionistic. the spontaneity was new and compulsive - I let the canvas grow.” S.H. Raza, rpt. in Sen, Dr. Geeti/BKA 1997 (23) I  p.59.",
    "Image Caption2": "S.H. Raza | Rajasthan | Acrylic on canvas, 1983 | 68.9 x 68.9 in (175 x 175 cm)",
    "Text3": "",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },{
    "Sr_No": "",
    "time_period": "1961 – 1983",
    "title_of_time_period": "07_A Focus on the Rajasthan Series",
    "sortable_date": "1983",
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "Image58",
    "image1": img1983b,
    "text_1": "",
    "caption_1": "S.H. Raza | Rajasthan | Acrylic on canvas, 1983 | 68.9 x 68.9 in (175 x 175 cm)",
    "Second ImageNumber (Internal for A-Graphy)": "Image59",
    "Image Code(s)2": "0059-s-h-raza-IT.jpg",
    "text_2": "",
    "Image Caption2": "S.H. Raza | Rajasthan | Acrylic on canvas, 1983 | 68.9 x 68.9 in (175 x 175 cm)",
    "Text3": "",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "1977 – 1989",
    "title_of_time_period": "08_A Focus on the Bindu : Early Variations",
    "sortable_date": "1977",
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "Image60",
    "image1": "0060-s-h-raza-IT.jpg",
    "text_1": "In the ‘sixties and ‘seventies visits to India re-sensitized his perceptiveness for a final supreme and universal viewing of nature, not as appearance, not as spectacle but as an integrated force of life and cosmic growth reflected in every elementary particle and in every fibre of a human being. The five elements which in Hindu thought build this and other worlds – earth, jala – water, pawak – fire, gagan – sky and samara – ether and their correspondence, on the one hand, to areas of consciousness in the human mind and, on the other, to the colours yellow – padma, white – sulka, red – tejas, blue – nila and black – Krishna captured Raza’s imagination to the point of complete identification of himself with his painted work. Nature became to Raza something not to be observed or to be imagined but something to be experienced in the very act of putting paint on canvas. Painting acts itself out as a natural force, struggling in darkness, breaking into light, shivering in cold, burning in heat, trying to find form and yet dissolving into chaos. Extracted from Rudolf von Leyden rpt. In Raza : Gorbio & Bombay I  Jehangir Nicholson Museum, NCPA ExC. 1978.",
    "caption_1": "S.H. Raza | Sourya | Acrylic on canvas, 1977 | 39.3 x 39.3 in (99.7 x 99.7 cm)",
    "Second ImageNumber (Internal for A-Graphy)": "",
    "Image Code(s)2": "",
    "text_2": "",
    "Image Caption2": "",
    "Text3": "",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "1977 – 1989",
    "title_of_time_period": "08_A Focus on the Bindu : Early Variations",
    "sortable_date": 1980,
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "Image61",
    "image1": "0061-s-h-raza-IT.jpg",
    "text_1": "“Raza’s concern with principles of pure geometry can easily be misconstrued to suggest the approach of pure formalism, or of structuralism, or even neo-tantrism. That he is aware of the possibility of misunderstanding is evident in a carefully worded letter. Invited to exhibit his works in a show to be held on neo-tantric imagery, Raza replied to Dr. Sihare, Director of the National Gallery of Modern Art in 1983, to decline the offer: ‘My using a “circle” or a “square” as key motifs do not make my paintings tantric, even if I have called them “Bindu”, “Sourya” or “Zamine”. I am aware, Tantric Darshan is far too complex. I know little about its beliefs and rituals. My involvement in work is essentially with the life of form and all my efforts are directed towards a coherent pictorial logic...” Extracted from Gaudibert, Pierre & Dr. Geeti Sen/Group ExC 1991 (16). Naturally the same misunderstanding was created regarding the artistic journeys of other modern masters such as Biren De and K.C.S. Paniker, who participated in the 1983 Exhibition for various reasons, but were equally far from neo-tantric in their categorisation and spirit.",
    "caption_1": "S.H. Raza | Untitled | Acrylic on canvas, 1980 | 39.4 x 39.4 in (100.1 x 100.1 cm)",
    "Second ImageNumber (Internal for A-Graphy)": "",
    "Image Code(s)2": "",
    "text_2": "",
    "Image Caption2": "",
    "Text3": "",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "1977 – 1989",
    "title_of_time_period": "08_A Focus on the Bindu : Early Variations",
    "sortable_date": 1981,
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "Image62",
    "image1": "0062-s-h-raza-IT.jpg",
    "text_1": "Refer to SH Raza: Catalogue Raisonne 1972-1989 (Volume II)",
    "caption_1": "S.H. Raza | Maa | Acrylic on canvas, 1981 | 68.9 x 102.4 in (175 x 260 cm)",
    "Second ImageNumber (Internal for A-Graphy)": "",
    "Image Code(s)2": "",
    "text_2": "",
    "Image Caption2": "",
    "Text3": "",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "1977 – 1989",
    "title_of_time_period": "08_A Focus on the Bindu : Early Variations",
    "sortable_date": 1982,
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "Image63-73\nYes : Cover of 5th Triennale plus 4 image pages (pp.87 to 90) plus Maa_1981 (Image53) plus 5 pages in Project #402",
    "image1": "0063-s-h-raza-IT.jpg\nto\n0073-s-h-raza-IT.jpg",
    "text_1": "In 1981 Richard Bartolomew invited S.H. Raza to participate in the 5th Triennale (1982). Maa & Bindu were S.H. Raza’s entries. Regarding Maa, Raza stated: “The idea emerged from a poem by Ashok Vajpeyi, addressed to his mother. It was tender, true and stimulating. I was inspired to conceive a painting that could be a letter to my mother country, India, revealing my experiences, discoveries and acquisitions. I hoped that the painting could be the evidence that I was never cut off from my sources. The memory conscious and unconscious were ever-present. Composed of several sections, yet a united whole, the painting conveyed the message in colour... I inscribed discreetly, the first line of the poem - ‘Ma, laut kar jab aunga kya laounga.’” S.H. Raza, rpt. in Sen, Dr. Geeti/BKA 1997 (23) I  p.82.",
    "caption_1": "Cover of Fifth Triennale-India 1982 | https://drive.google.com/drive/u/2/folders/1yNHigBxRDBMapmV6ZRD6S-_IkAFSGhn-",
    "Second ImageNumber (Internal for A-Graphy)": "Image74",
    "Image Code(s)2": "0074-s-h-raza-IT.jpg",
    "text_2": "",
    "Image Caption2": "S.H. Raza | Untitled | Acrylic on canvas, 1982 | 31.5 x 31.3 in (80 x 79.4 cm)",
    "Text3": "",
    "Masterlist1": "Sankho-Chaudhuri",
    "Masterlist2": "Lalit-Kala-Akademi",
    "Masterlist3": "Triennale",
    "Keywords": "Dhruva-Mistry|Bikash-Bhattacharjee|Mrinalini-Mukherjee|Biren-De",
    "Research Category1": "FineArts",
    "Research Category2": "Indo-World",
    "Research Category3": "Scholarship"
  },
  {
    "Sr_No": "",
    "time_period": "1977 – 1989",
    "title_of_time_period": "08_A Focus on the Bindu : Early Variations",
    "sortable_date": 1983,
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "Image75",
    "image1": "0075-s-h-raza-IT.jpg",
    "text_1": "",
    "caption_1": "S.H. Raza | Blue Bindu | Oil on canvas, 1983 | 34 x 23 in (86.4 x 58.4 cm)",
    "Second ImageNumber (Internal for A-Graphy)": "Image76",
    "Image Code(s)2": "0076-s-h-raza-IT.jpg",
    "text_2": "",
    "Image Caption2": "S.H. Raza | Untitled | Acrylic on canvas, 1983 | 36 x 29.8 in (91.44 x 75.692 cm)",
    "Text3": "",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "1977 – 1989",
    "title_of_time_period": "08_A Focus on the Bindu : Early Variations",
    "sortable_date": 1984,
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "Image77",
    "image1": "0077-s-h-raza-IT.jpg",
    "text_1": "",
    "caption_1": "S.H. Raza | Red Bindu | Acrylic on canvas, 1984 | 39.4 x 39.4 in (100 x 100 cm)",
    "Second ImageNumber (Internal for A-Graphy)": "",
    "Image Code(s)2": "",
    "text_2": "",
    "Image Caption2": "",
    "Text3": "",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "1977 – 1989",
    "title_of_time_period": "08_A Focus on the Bindu : Early Variations",
    "sortable_date": 1986,
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "Image78",
    "image1": "0078-s-h-raza-IT.jpg",
    "text_1": "“Just like sleep, silence is only an apparent pause in activity. While we sleep, many things essential to our psychic balance happen unconsciously. In silence, this is also true - but we are conscious of our activity. If we can really concentrate it is only when we are surrounded by silence that we can attain true perception of fundamental realities. And then, there is the silence within the painting itself. Silent spaces on a canvas emphasize the active areas. Nothing seems to happen in these spaces, but it is their very presence which retains and organizes the agitation surrounding them.” S.H. Raza in conversation with Francois-Yes Morin, extracted from Barbier, Helen (Curator). Coups de Coeur. Geneva: Edition Halle Sud 1987 [Group Exhibition Catalogue & Raza Interview (1987), 14] I  p.29.",
    "caption_1": "S.H. Raza | Sourya | Acrylic on canvas, 1986 | 79.9 x 74.8 in (203 x 190 cm)",
    "Second ImageNumber (Internal for A-Graphy)": "",
    "Image Code(s)2": "",
    "text_2": "",
    "Image Caption2": "",
    "Text3": "",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "1977 – 1989",
    "title_of_time_period": "08_A Focus on the Bindu : Early Variations",
    "sortable_date": 1989,
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "Image79",
    "image1": "0079-s-h-raza-IT.jpg",
    "text_1": "“My present work is the result of two parallel enquiries. Firstly, it aimed at pure plastic order, form-order. Secondly, it concerns the theme of Nature. Both have converged into a single point and become inseparable. The point, the bindu, symbolises the seed bearing the potential of all life, in a sense. It’s also a visible form containing all the essential requisites of line, tone, colour, texture and space. The black space is charged with latent forces aspiring for fulfilment.” S.H. Raza extracted from Bickelmann, Ursula & Nissim Ezekiel (Eds.)/BKE 1987 (15) I  p.18.",
    "caption_1": "S.H. Raza | Gestation | acrylic on canvas, 1989 | 39.4 x 78.7 in (100 x 200 cm)",
    "Second ImageNumber (Internal for A-Graphy)": "",
    "Image Code(s)2": "",
    "text_2": "",
    "Image Caption2": "",
    "Text3": "",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "1987 – 2006",
    "title_of_time_period": "09_A Focus on Bindu : ‘Black’ ‘Blue’ and ‘White’",
    "sortable_date": 1987,
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "Image80",
    "image1": "0080-s-h-raza-IT.jpg",
    "text_1": "",
    "caption_1": "S.H. Raza | Beeja | Acrylic on canvas, 1987 | 31.9 x 23.6 in (81 x 60 cm)",
    "Second ImageNumber (Internal for A-Graphy)": "",
    "Image Code(s)2": "",
    "text_2": "",
    "Image Caption2": "",
    "Text3": "",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "1987 – 2006",
    "title_of_time_period": "09_A Focus on Bindu : ‘Black’ ‘Blue’ and ‘White’",
    "sortable_date": 1990,
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "Image81",
    "image1": "0081-s-h-raza-IT.jpg",
    "text_1": "“Jala Bindu (1990) explores the same idea, but uses only one of the five elements - water. Fine horizontal lines move out from the central bindu, which seems to be rising. Painted in blues, this represents for me the female or the feminine aspect.",
    "caption_1": "S.H. Raza | Jal Bindu | Acrylic on canvas, 1990 | 39.5 x 39.5 in (100.3 x 100.3 cm)",
    "Second ImageNumber (Internal for A-Graphy)": "",
    "Image Code(s)2": "",
    "text_2": "",
    "Image Caption2": "",
    "Text3": "",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "1987 – 2006",
    "title_of_time_period": "09_A Focus on Bindu : ‘Black’ ‘Blue’ and ‘White’",
    "sortable_date": 1992,
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "Image82",
    "image1": "0082-s-h-raza-IT.jpg",
    "text_1": "",
    "caption_1": "S.H. Raza | Ankuran | Oil on canvas, 1992 | 79 x 39.5 in (200.7 x 100.3 cm)",
    "Second ImageNumber (Internal for A-Graphy)": "",
    "Image Code(s)2": "",
    "text_2": "",
    "Image Caption2": "",
    "Text3": "",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "1987 – 2006",
    "title_of_time_period": "09_A Focus on Bindu : ‘Black’ ‘Blue’ and ‘White’",
    "sortable_date": 1995,
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "Image83",
    "image1": "0083-s-h-raza-IT.jpg",
    "text_1": "",
    "caption_1": "S.H. Raza | Nad-Bindu | Acrylic on canvas, 1995 | 69 x 59 in (175.2 x 149.8 cm)",
    "Second ImageNumber (Internal for A-Graphy)": "Image84",
    "Image Code(s)2": "0084-s-h-raza-IT.jpg",
    "text_2": "",
    "Image Caption2": "S.H. Raza | NAd-Bindu (In Three Parts) | Acrylic on canvas, 1995 | 78.7 x 236.2 in (200 x 600 cm)",
    "Text3": "",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "1987 – 2006",
    "title_of_time_period": "09_A Focus on Bindu : ‘Black’ ‘Blue’ and ‘White’",
    "sortable_date": 1997,
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "Image85",
    "image1": "0085-s-h-raza-IT.jpg",
    "text_1": "“A colour spread over three-fourth parts of the earth. A transparent presence in the throat of “Shiva”. A colour with tranquil memory, with mysterious nature. With pacifying look, hiding the depth of ocean. This blue, exactly this blue is burning in Raza’s painting. This colour, this blue colour, this ‘Royal’ blue is burning. A colour known for its coolness and this categorically cold colour has become transparent in Raza’s painting ‘Ocean’ and its tranquility one could see flames, fire and the warmth...Raza cleans its monochromatic solitude and its calm cool sadness, finds a new born burning blue...Raza and colour are one. The colour behaves the way Raza thinks.” Akhilesh, in ‘The Blue which burns’, extracted from Vajpeyi, Ashok/BKA 2007 (43) I  p.338-40.",
    "caption_1": "S.H. Raza | Prakriti | Acrylic on canvas, 1997 | 29.5 x 59.1 in (75 x 150 cm)",
    "Second ImageNumber (Internal for A-Graphy)": "Image86",
    "Image Code(s)2": "0086-s-h-raza-IT.jpg",
    "text_2": "“Naad Bindu… according to Raza, has elements of sound resonating through its vital rotations…. The monochromatic canvas, which expands into a translucent space, can be appreciated for its exquisite delicacy. Yet the composition seems almost too precious to be a vibrating force of energy. Even as its tonal variations create an atmosphere of meditative spirituality, its ethereal quality is far removed from any sound vibrations…For all the dissonances in Raza’s work, however, it never fails to arouse at the sensuous level and it is in this that his compositions reach their element.” Extracted from Dalmia, Yashodhara/BKA 2001 (32) I  p.171.",
    "Image Caption2": "S.H. Raza | Nad-Bindu | Acrylic on canvas, 1997 | 39.4 x 31.5 in (100 x 80 cm)",
    "Text3": "",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "1987 – 2006",
    "title_of_time_period": "09_A Focus on Bindu : ‘Black’ ‘Blue’ and ‘White’",
    "sortable_date": 1998,
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "Image87",
    "image1": "0087-s-h-raza-IT.jpg",
    "text_1": "The Ocean I and The Ocean II\nInvited by the underground railway of Lisbon to do a painting for the Oriente station in the complex that was going to house the 1998 International Exhibition. His works The Ocean I and The Ocean II were reproduced and enlarged on cermic tiles, 3.5 x 13.5m for the walls of the subway.\n(http://razacatalogueraisonne.com/biography)",
    "caption_1": "The Ocean I and The Ocean II\nInvited by the underground railway of Lisbon to do a painting for the Oriente station in the complex that was going to house the 1998 International Exhibition. His works The Ocean I and The Ocean II were reproduced and enlarged on cermic tiles, 3.5 x 13.5m for the walls of the subway.\n(http://razacatalogueraisonne.com/biography)",
    "Second ImageNumber (Internal for A-Graphy)": "",
    "Image Code(s)2": "",
    "text_2": "",
    "Image Caption2": "",
    "Text3": "",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "1987 – 2006",
    "title_of_time_period": "09_A Focus on Bindu : ‘Black’ ‘Blue’ and ‘White’",
    "sortable_date": 2000,
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "Image88",
    "image1": "0088-s-h-raza-IT.jpg",
    "text_1": "",
    "caption_1": "S.H. Raza | Jalashaya | Acrylic on canvas, 2000 | 40 x 40 in (101.6 x 101.6 cm)",
    "Second ImageNumber (Internal for A-Graphy)": "",
    "Image Code(s)2": "",
    "text_2": "",
    "Image Caption2": "",
    "Text3": "",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "1987 – 2006",
    "title_of_time_period": "09_A Focus on Bindu : ‘Black’ ‘Blue’ and ‘White’",
    "sortable_date": 2002,
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "Image89",
    "image1": "0089-s-h-raza-IT.jpg",
    "text_1": "“In the next stage the Bindu and its various manifestations led Raza along the path to truth… This journey still goes on, his attempts at purification lately culminated in his White Period. White canvases are not un-painted canvases. On the contrary, they reveal a very sophisticated geometric construction, leading to a pure and serene harmony, to Peace or the Shanti Rasa. Of the nine emotions this is, according to Indian thought, the most difficult to achieve. It calls for years of sustained effort, research and expression, both in life and in artistic pursuits. However, Raza is still feeling ambiguous about his White Period...” Extracted from Imbert, Michel/BKA 2003 (37a).",
    "caption_1": "S.H. Raza | Nil Kanth | Acrylic on canvas, 2002 | 46.5 x 46.5 in (118.1 x 118.1 cm)",
    "Second ImageNumber (Internal for A-Graphy)": "",
    "Image Code(s)2": "",
    "text_2": "",
    "Image Caption2": "",
    "Text3": "",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "1987 – 2006",
    "title_of_time_period": "09_A Focus on Bindu : ‘Black’ ‘Blue’ and ‘White’",
    "sortable_date": 2006,
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "Image90",
    "image1": "0090-s-h-raza-IT.jpg",
    "text_1": "",
    "caption_1": "S.H. Raza | Om Shanti Shanti Shanti | Acrylic on canvas, 2006 | 76.4 x 44.9 in (194 x 114 cm)",
    "Second ImageNumber (Internal for A-Graphy)": "Image91",
    "Image Code(s)2": "0091-s-h-raza-IT.jpg",
    "text_2": "",
    "Image Caption2": "S.H. Raza | Genesis | Acrylic on canvas, 2006 | 39 x 39 in (99.1 x 99.1 cm)",
    "Text3": "",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "2007 – 2016",
    "title_of_time_period": "10_Last Years of Exploration",
    "sortable_date": 2007,
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "Image92",
    "image1": "0092-s-h-raza-IT.jpg",
    "text_1": "By 2007, at the age of eighty-five, Raza still aspired towards refining his life-long artistic journey, of wishing to find some inner realisation which deepened his search for a meaning of life…\n\n“Raza paints his new works as a homage of gratitude to the world, to nature and to powers that be. He celebrates them colourfully, in joy and tranquility I  he seems to be reaching out to an enriching and deeply contemplative silence. These later day works are also objects organically moving towards consonance, harmony and peace not only of colours but also of mind.” Ashok Vajpeyi, rpt. in Raza: A Life in Art. New Delhi: Art Alive Gallery 2007 I  p. 128.",
    "caption_1": "S.H. Raza | The Blue Moon | Acrylic on canvas, 2007 | 39.4 x 31.5 in (100 x 80 cm)",
    "Second ImageNumber (Internal for A-Graphy)": "",
    "Image Code(s)2": "",
    "text_2": "",
    "Image Caption2": "",
    "Text3": "",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "2007 – 2016",
    "title_of_time_period": "10_Last Years of Exploration",
    "sortable_date": 2008,
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "Image93",
    "image1": "0093-s-h-raza-IT.jpg",
    "text_1": "",
    "caption_1": "S.H. Raza | Satyam Shivam Sundaram | Acrylic on canvas, 2008 | 39.4 x 31.5 in (100 x 80 cm)",
    "Second ImageNumber (Internal for A-Graphy)": "",
    "Image Code(s)2": "",
    "text_2": "",
    "Image Caption2": "",
    "Text3": "",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "2007 – 2016",
    "title_of_time_period": "10_Last Years of Exploration",
    "sortable_date": 2009,
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "Image94",
    "image1": "0094-s-h-raza-IT.jpg",
    "text_1": "",
    "caption_1": "S.H. Raza | Tapovan | Acrylic on canvas, 2009 | 39.4 x 47.2 in (100 x 120 cm)",
    "Second ImageNumber (Internal for A-Graphy)": "",
    "Image Code(s)2": "",
    "text_2": "",
    "Image Caption2": "",
    "Text3": "",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "2007 – 2016",
    "title_of_time_period": "10_Last Years of Exploration",
    "sortable_date": 2010,
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "Image95",
    "image1": "0095-s-h-raza-IT.jpg",
    "text_1": "",
    "caption_1": "S.H. Raza | Nirvan | Acrylic on canvas, 2010 | 39.4 x 39.4 in (100 x 100 cm)",
    "Second ImageNumber (Internal for A-Graphy)": "",
    "Image Code(s)2": "",
    "text_2": "",
    "Image Caption2": "",
    "Text3": "",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "2007 – 2016",
    "title_of_time_period": "10_Last Years of Exploration",
    "sortable_date": 2011,
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "Image96",
    "image1": "0096-s-h-raza-IT.jpg",
    "text_1": "",
    "caption_1": "S.H. Raza | Surya Namaskar | Acrylic on canvas, 2013 | 59.1 x 47.2 in (150 x 120 cm)",
    "Second ImageNumber (Internal for A-Graphy)": "",
    "Image Code(s)2": "",
    "text_2": "",
    "Image Caption2": "",
    "Text3": "",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "2007 – 2016",
    "title_of_time_period": "10_Last Years of Exploration",
    "sortable_date": 2012,
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "Image97",
    "image1": "0097-s-h-raza-IT.jpg",
    "text_1": "",
    "caption_1": "S.H. Raza | Samavesh | Acrylic on canvas, 2012 | 47.2 x 47.2 in (120 x 120 cm)",
    "Second ImageNumber (Internal for A-Graphy)": "",
    "Image Code(s)2": "",
    "text_2": "",
    "Image Caption2": "",
    "Text3": "",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "2007 – 2016",
    "title_of_time_period": "10_Last Years of Exploration",
    "sortable_date": 2013,
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "Image98",
    "image1": "0098-s-h-raza-IT.jpg",
    "text_1": "",
    "caption_1": "S.H. Raza | Mahabharat | Acrylic on canvas, 2013 | 59.1 x 47.2 in (150 x 120 cm)",
    "Second ImageNumber (Internal for A-Graphy)": "",
    "Image Code(s)2": "",
    "text_2": "",
    "Image Caption2": "",
    "Text3": "",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "2007 – 2016",
    "title_of_time_period": "10_Last Years of Exploration",
    "sortable_date": 2014,
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "Image99",
    "image1": "0099-s-h-raza-IT.jpg",
    "text_1": "",
    "caption_1": "S.H. Raza | Samavesh | Acrylic on canvas, 2014 | 47.2 x 47.2 in (120 x 120 cm)",
    "Second ImageNumber (Internal for A-Graphy)": "",
    "Image Code(s)2": "",
    "text_2": "",
    "Image Caption2": "",
    "Text3": "",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "2007 – 2016",
    "title_of_time_period": "10_Last Years of Exploration",
    "sortable_date": 2015,
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "Image100",
    "image1": "0100-s-h-raza-IT.jpg",
    "text_1": "",
    "caption_1": "S.H. Raza | Udrishya | Acrylic on canvas, 2015 | 39.4 x 39.4 in (100 x 100 cm)",
    "Second ImageNumber (Internal for A-Graphy)": "",
    "Image Code(s)2": "",
    "text_2": "",
    "Image Caption2": "",
    "Text3": "",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "2007 – 2016",
    "title_of_time_period": "10_Last Years of Exploration",
    "sortable_date": 2016,
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "Image101",
    "image1": "0101-s-h-raza-IT.jpg",
    "text_1": "“Raza continued to paint from his wheelchair. He also carried on with the practice of uttering some poetic lines before beginning his work. It was usually a verse from Rilke,… It was something like ‘Listen to that voice buried in silence’ – we have it somewhere… It is the artist’s prerogative to subvert linear time and the compartmentalization of history and to recreate a universe of connectivity and seamless humanity… (He) was an artist who felt that his role was to make the unknown rise from its submerged depths of consciousness. An artist who never stopped chasing his dreams…”Yashodhara Dalmia, rpt. In Syed Haider Raza : The Journey of an Iconic Artist. New Delhi: HarperCollins India 2021 I  pp.218-220.",
    "caption_1": "S.H. Raza | Brahmaand | Acrylic on canvas, 2016 | 39.4 x 78.7 in (100 x 200 cm)",
    "Second ImageNumber (Internal for A-Graphy)": "",
    "Image Code(s)2": "",
    "text_2": "",
    "Image Caption2": "",
    "Text3": "",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "1987 – 2023",
    "title_of_time_period": "11_A growing art market & increased academic interest",
    "sortable_date": "1987-2023",
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "Image102",
    "image1": "0102-s-h-raza-IT.jpg",
    "text_1": "The first public auction for Contemporary Indian art was organized as a Charity auction by Christie’s and HelpAge India on 20th Dec. 1987 at the Taj Mahal Hotel, Bombay. ‘Sourya’ (1986, Lot 26) represented S.H. Raza, a painting which was displayed at the Salon Mai the year previously. Thereafter, Raza’s auction prices kept on rising, breaking his previous highs regularly in 1995 (with Bindu, 1982), 1997 (Paysage, 1979), 2000 (Terra Amata, 1984), 2004 (Rajputana, 2003), 2006 (Tapovan, 1972), 2008 (La Terre, 1973), 2010 (Saurasthra, 1983) and finally in 2018 when Tapovan was resold for US$4.45 Million at New York by Christie’s.",
    "caption_1": "S.H. Raza | Sourya | Acrylic on canvas, 1986 | 79.9 x 74.8 in (203 x 190 cm)",
    "Second ImageNumber (Internal for A-Graphy)": "Image103",
    "Image Code(s)2": "0103-s-h-raza-IT.jpg",
    "text_2": "Simultaneously with this respect from the art market came greater public awareness for his art and hence an increase in related academic and scholarly work.  Major publications by Ashok Vajpayi (2007) Alain Bonfand (2008) I  Mandalas (2005) was translated from French to English in 2009 and the first Volume of the Catalogue Raisonne 1958-71 was published in 2016 by Vadehra Gallery, with Raza’s first biography by Yashosdhara Dalmia in 2021.",
    "Image Caption2": "S.H. Raza | Bindu | Oil on canvas, 1982 | 31.3 x 31.3 in (79.4 x 79.4 cm)",
    "Text3": "",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "1987 – 2023",
    "title_of_time_period": "11_A growing art market & increased academic interest",
    "sortable_date": "1987-2023",
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "Image104",
    "image1": "0104-s-h-raza-IT.jpg",
    "text_1": "",
    "caption_1": "S.H. Raza | Paysage | Oil on canvas, 1979 | 31.9 x 66.5 in (81 x 168.9 cm)",
    "Second ImageNumber (Internal for A-Graphy)": "Image105",
    "Image Code(s)2": "0105-s-h-raza-IT.jpg",
    "text_2": "",
    "Image Caption2": "S.H. Raza | Terra Amata | Acrylic on canvas, 1984 | 69 x 59.1 in (175.3 x 150.2 cm)",
    "Text3": "",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "1987 – 2023",
    "title_of_time_period": "11_A growing art market & increased academic interest",
    "sortable_date": "1987-2023",
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "Image106",
    "image1": "0106-s-h-raza-IT.jpg",
    "text_1": "",
    "caption_1": "S.H. Raza | Rajputana | Acrylic on canvas, 2003 | 59.8 x 59.8 in (152 x 152 cm)",
    "Second ImageNumber (Internal for A-Graphy)": "Image107",
    "Image Code(s)2": "0107-s-h-raza-IT.jpg",
    "text_2": "",
    "Image Caption2": "S.H. Raza | Tapovan | Acrylic on canvas, 1972 | 62.5 x 74 in (158.8 x 188 cm)",
    "Text3": "",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "1987 – 2023",
    "title_of_time_period": "11_A growing art market & increased academic interest",
    "sortable_date": "1987-2023",
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "Image108",
    "image1": "0108-s-h-raza-IT.jpg",
    "text_1": "",
    "caption_1": "S.H. Raza | Life in Art: S.H.Raza | Ashok Vajpeyi (Written and Edited by)\nPublisher Art Alive Gallery, New Delhi I  2007\npp. 359 I  Hardback (ISBN 9788190184441), 2007 | 0 x 0 in ( x  cm)",
    "Second ImageNumber (Internal for A-Graphy)": "Image109",
    "Image Code(s)2": "0109-s-h-raza-IT.jpg",
    "text_2": "",
    "Image Caption2": "Germain-Thomas, Olivier. Sayed Haider Raza : Mandalas. New Delhi: ArtAlive Gallery 2009 [Raza Focus Book, 38a]",
    "Text3": "",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "1987 – 2023",
    "title_of_time_period": "11_A growing art market & increased academic interest",
    "sortable_date": "1987-2023",
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "Image110",
    "image1": "0110-s-h-raza-IT.jpg",
    "text_1": "",
    "caption_1": "S.H. Raza | La Terre | Oil on canvas, 1973 | 74.4 x 74.4 in (189 x 189 cm)",
    "Second ImageNumber (Internal for A-Graphy)": "Image111",
    "Image Code(s)2": "0111-s-h-raza-IT.jpg",
    "text_2": "",
    "Image Caption2": "S.H. Raza | Paysage | Oil on canvas, 1983 | 47 x 47 in (119.4 x 119.4 cm)",
    "Text3": "",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "1987 – 2023",
    "title_of_time_period": "11_A growing art market & increased academic interest",
    "sortable_date": "1987-2023",
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "Image112",
    "image1": "0112-s-h-raza-IT.jpg",
    "text_1": "",
    "caption_1": "S.H. Raza | Tapovan | Acrylic on canvas, 1972 | 62.5 x 74 in (158.8 x 188 cm)",
    "Second ImageNumber (Internal for A-Graphy)": "Image113",
    "Image Code(s)2": "0113-s-h-raza-IT.jpg",
    "text_2": "",
    "Image Caption2": "Macklin, Anne. SH Raza : Catalogue Raisonné 1958-1971 (Volume 1). New Delhi: Vadehra Art Gallery 2016 [Raza Focus Book I  Catalogue Raisonne 1958-1971, 54]",
    "Text3": "",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "1987 – 2023",
    "title_of_time_period": "11_A growing art market & increased academic interest",
    "sortable_date": "1987-2023",
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "Image114",
    "image1": "0114-s-h-raza-IT.jpg",
    "text_1": "",
    "caption_1": "Sen, Dr. Geeti. Bindu: Space and Time in Raza’s Vision. Ahmedabad: Mapin Publishing Pvt. Ltd. In association with The Raza Foundation 2020 [Raza Focus Book (Revised), 60]",
    "Second ImageNumber (Internal for A-Graphy)": "Image115",
    "Image Code(s)2": "0115-s-h-raza-IT.jpg",
    "text_2": "",
    "Image Caption2": "Dalmia, Yashodhara. Sayed Haider Raza: The Journey of an Iconic Artist. New Delhi: HarperCollins India 2021 [Raza Focus Book, 61]",
    "Text3": "",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  }
]
const satyajitdata=[
  {
    "Sr_No": "",
    "time_period": "1921 – 1929",
    "title_of_time_period": "01_Early Years",
    "sortable_date": "1921",
    "image1": imgsatyajit_1921,
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "n/a",
    "text_1": "Born on 2nd May 1921 in Calcutta; 100 Gorpar Road.",
    "caption_1": "Caption Awaited",
    "Second ImageNumber (Internal for A-Graphy)": "",
    "Image Code(s)2": "",
    "text_2": "",
    "Image Caption2": "",
    "Text3": "",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "1921 – 1929",
    "title_of_time_period": "01_Early Years",
    "sortable_date": "1922",
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "Image1",
    "image1": imgsatyajit_1922,
    "text_1": "Caption Awaited",
    "caption_1": "Caption Awaited",
    "Second ImageNumber (Internal for A-Graphy)": "",
    "Image Code(s)2": "",
    "text_2": "",
    "Image Caption2": "",
    "Text3": "",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "1931 – 1941",
    "title_of_time_period": "02_Travel and an Academic Art Grounding",
    "sortable_date": "1934",
    "Specific Dates (with Day, Month)": "Nov. 1943",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "Image2",
    "image1": imgsatyajit_1934,
    "text_1": "Moved to Beltola Road.",
    "caption_1": "Caption Awaited",
    "Second ImageNumber (Internal for A-Graphy)": "",
    "Image Code(s)2": "",
    "text_2": "Ray, Satyajit. Childhood Days. Edited by Bijoya Ray. : Penguin Books; Kindle Edition. 2021.[Satyajit Ray's Cinema, Memoirs,60.NA]",
    "Image Caption2": "",
    "Text3": "1943-1947 Completed Diploma of Paintings and Drawings from Sir J.J. School of Art, Bombay, India after Part-time study from 1943 I  J. Ahivasi was one of his teachers during this last and only full-time period of study at the Sir J.J. School of Art., 1946-7",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "1943 – 1948",
    "title_of_time_period": "02_Travel and an Academic Art Grounding",
    "sortable_date": "1944",
    "fullImage":full1944,
    "fullId":6,
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "Image3",
    "image1": img1944,
    "text_1": "",
    "caption_1": "S.H. Raza | Benaras | Watercolour on paper, 1944 | 24.2 x 28.9 in (61.4 x 73.5 cm)",
    "Second ImageNumber (Internal for A-Graphy)": "",
    "Image Code(s)2": "",
    "text_2": "",
    "Image Caption2": "",
    "Text3": "",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "1943 – 1948",
    "title_of_time_period": "02_Travel and an Academic Art Grounding",
    "sortable_date": "1945",
    "fullImage":full1945,
    "fullId":7,
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "Image4",
    "image1": img1945,
    "text_1": "",
    "caption_1": "S.H. Raza | Street Scene | Watercolour on paper, 1945 | 11.4 x 13 in (29 x 33 cm)",
    "Second ImageNumber (Internal for A-Graphy)": "",
    "Image Code(s)2": "",
    "text_2": "",
    "Image Caption2": "",
    "Text3": "",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "1943 – 1948",
    "title_of_time_period": "02_Travel and an Academic Art Grounding",
    "sortable_date": "1946",
    "fullImage":full1946,
    "fullId":8,
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "Image5",
    "image1": img1946,
    "text_1": "1946 Wins Silver Medal at the 55th Annual Bombay Art Society, Exhibition, Bombay, India",
    "caption_1": "S.H. Raza | Market Scene | Gouache on paper, 1946 | 13.8 x 5.9 in (35 x 15 cm)",
    "Second ImageNumber (Internal for A-Graphy)": "",
    "Image Code(s)2": "",
    "text_2": "Attends evening classes at the Mohan Art Club with the artist Mohan Kulkarni leading the group of students including Raza",
    "Image Caption2": "",
    "Text3": "",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "1943 – 1948",
    "title_of_time_period": "02_Travel and an Academic Art Grounding",
    "sortable_date": "1947",
    "fullImage":full1947,
    "fullId":9,
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "Image6",
    "image1": img1947,
    "text_1": "1947-48 First Solo Exhibition at the Bombay Art Society, Bombay, India, opens Thursday 20th November 1947.",
    "caption_1": "Cover of Bombay Art Society\n57th Annual Exhibition\nDecember 1947",
    "Second ImageNumber (Internal for A-Graphy)": "Image7",
    "Image Code(s)2": "0007-s-h-raza-IT.jpg",
    "Image Caption2": "S.H. Raza | Bori Bunder | Gouache on paper, 1947",
    "Text3": "Dec. 1947 S.H. Raza awarded a Gold Medal at the 57th Annual Exhibition, Presented by Bombay Art Society, Bombay, India",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },{
    "Sr_No": "",
    "time_period": "1943 – 1948",
    "title_of_time_period": "02_Travel and an Academic Art Grounding",
    "sortable_date": "1947",
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "Image6",
    "image1": img1947b,
    "scaption_1": "Cover of Bombay Art Society\n57th Annual Exhibition\nDecember 1947",
    "Second ImageNumber (Internal for A-Graphy)": "Image7",
    "Image Code(s)2": "0007-s-h-raza-IT.jpg",
    "text_1": "Nov. 1947 \"He is a romantic painter for whom there is more behind the every-day face of nature than what meets the eye. With an almost poetic penetration he can transform the grey vistas of Bori Bunder or Flora Fountain on a monsoon day into little rhapsodies and yet retain the intrinsic realities of his subjects…His vehicle of expression is colour. His forms, his compositon take their cue from colour. His whole understanding of nature and his emotional reaction are colour. This has led to the unlimited variety of colour association on which his fantasy can play. From the pastel shades through the dark Prussian blues and sepias of Omkareshwar, the salmon pink and blue composition of the Nasik River, practically each of his pictures brings new pleasures of colour…His compostion has improved over the years and most of his paintings have now a solid structure, although in some of them the foreground has not been solved and runs out into emptiness.\" von Leyden, Rudi, In The Times of India, November 1947, rpt. In Dalmia, Yashodhara/BKA 2001 (32) I  p.266",
    "caption_1": "S.H. Raza | Bori Bunder | Gouache on paper, 1947",
    "text_2": "Dec. 1947 S.H. Raza awarded a Gold Medal at the 57th Annual Exhibition, Presented by Bombay Art Society, Bombay, India",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "1943 – 1948",
    "title_of_time_period": "02_Travel and an Academic Art Grounding",
    "sortable_date": "1948",
    "fullImage":full1948,
    "fullId":10,
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "Carousel#02 (Kashmir Series | Image 8 to 19)",
    "image1": img1948,
    "text_1": "July-Aug. 1948 Kashmir Series exhibited in Srinagar, Kashmir. K.A. Abbas states that S.H. Raza's Kashmir Series 1948 paintings were exhibited (probably an informal display) in Srinagar on his Houseboat and mentions the presence of the following personalities at the Opening along with himself and his wife: Shaikh Abdullah, Bakshi Ghulam Mohammed, Henri Cartier-Bresson and his Indonesian dancer wife Ratna, General K.S. Thimmaya (a Major-General in 1948), Prem Nath Pardesi, among others. Rpt. in Abbas, Khwaja Ahmad/AutoBiography 1977 (11) I  p.316. K.A. Abbas and his wife would visit Raza and Janine in Paris post 1960.",
    "caption_1": "S.H. Raza | Srinagar, Kashmir Series | Gouache on paper, 1948 | 21.6 x 29.7 in (54.9 x 75.4 cm) |",
    "Second ImageNumber (Internal for A-Graphy)": "Image20",
    "Image Code(s)2": "0020-s-h-raza-IT.jpg",
    "text_2": "1st Sept. 1948 100 Kashmir Paintings, Exhibition Hall, Parliament Street,  Delhi I  inaugurated by Rudi von Leyden. 19th Oct. 1948 Kashmir Paintings Exhibition, Bombay Art Society Salon, Bombay, India I  inaugurated by K.G. Sauyiddain, Educational Advisor to Govt. of Bombay",
    "Image Caption2": "S.H. Raza | Landscape  | Oil on board, 1948 | 17.5 x 22 in (44.5 x 56 cm)",
    "Text3": "Founder Member of Progressive Artists' Group which was established with F.N. Souza's vision and efforts who had invited M.F. Husain to join, while H.A. Gade invited S.H. Raza to join and K.H. Ara invited S.N. Bakre to join. The Group changed and embraced various artists at different stages of its evolution until it was disbanded in 1954. Mohan Samant (left for USA in 1952), Krishen Khanna & V.S. Gaitonde were invited to join after 1950 once Souza, Raza and Bakre had left India. Others such as G.M. Hazarnis joined for for a very brief period, as did Bhanu Rajapadhya (Athaiya), but she never exhibited or participated in any P.A.G. Exhibition.",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  // {
  //   "Sr_No": "",
  //   "time_period": "1949 – 1953",
  //   "title_of_time_period": "03_Briefly a 'Progressive' and towards a full life in France",
  //   "sortable_date": 1949,
  //   "Specific Dates (with Day, Month)": "21st February 1949 - 21st March 1949",
  //   "First ImageNumber (Internal for A-Graphy), incl. Carousel": "Image21",
  //   "image1": "0021-s-h-raza-IT.jpg",
  //   "text_1": "21st February 1949 - 21st March 1949: Bombay Progressives Artists Group: Exhibition of Paintings & Sculptures by K.H. Ara, S.N. Bakre, M.F.Husain, F.N. Souza, H.A. Gade & S.H. Raza at Baroda State Picture Gallery, (Baroda, India).\n\nThe competitive exhibition for the 1950 French Scholarship which S.H. Raza was awarded was held at the Calcutta Government Art School, in which the artworks by H.A. Gade, Ramkumar, Amina Ahmed, S.N. Butt, A. Paidaraju, K.S. Kulkarni, Vidya Bhusan, among others were included.",
  //   "caption_1": "S.H. Raza | Untitled | Gouache on paper, 1949 | 14 x 16.9 in (35.5 x 43 cm)",
  //   "Second ImageNumber (Internal for A-Graphy)": "",
  //   "Image Code(s)2": "",
  //   "text_2": "",
  //   "Image Caption2": "",
  //   "Text3": "",
  //   "Masterlist1": "",
  //   "Masterlist2": "",
  //   "Masterlist3": "",
  //   "Keywords": "",
  //   "Research Category1": "",
  //   "Research Category2": "",
  //   "Research Category3": ""
  // },
  {
    "Sr_No": "",
    "time_period": "1949 – 1953",
    "title_of_time_period": "03_Briefly a 'Progressive' and towards a full life in France",
    "sortable_date": "1950",
    "image1": img1950,
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "Image22",
    "text_1": "Scholarship from French Government, Ecole Nationale Superieure des Beaux-Arts, Paris, France.",
    "caption_1": "S.H. Raza | Kashmir Houseboats | Watercolour on paper, 1950 | 17.2 x 24.5 in (43.7 x 62.3 cm)",
    "Second ImageNumber (Internal for A-Graphy)": "",
    "Image Code(s)2": "",
    "text_2": "13th to 19th Sept. 1950 Last Exhibition at the Charles Petras Institute of Foreign Languages, Bombay, of approx. 80 paintings (ExC. illustrates only 44), displaying works from the 1943-1950 period, before leaving for France. Exhibition closes on 19th Sept., and Raza leaves on 21st Sept. 1950 for Marseilles, thereafter to Paris by train, reaching on 3rd October 1950.",
    "Image Caption2": "",
    "text_3": "“In his farewell exhibition in Bombay, organized by the Institute of Foreign Languages, were to be seen one or two paintings which anticipated this turn to clarity and order after the emotional upheaval of the expressionist phase. There began to appear now out of his studio, after long and arduous work, a new type of landscape. Stylised houses, towers, spires meticulously assembled in paintings where they lived their own mysterious life. They did not seem to belong to any age of man…Over these works Raza had taken infinite pains. Each shape was carefully related to another, weighed, balanced till it had found its place in the composition which would appear as unshakeable. Colour had undergone the most intricate studies to be able to express the finest overtones of a poetic situation… They were as austere and as sensitive as the landscape backgrounds in the paintings of the Sienese primitives with their garlands of houses, walls and towers strung across the horizon.” Extracted from von Leyden, Rudolf/BKA 1959 (3) I  p.18.",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "1949 – 1953",
    "title_of_time_period": "03_Briefly a 'Progressive' and towards a full life in France",
    "sortable_date": "1951",
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "Image23",
    "image1": img1951,
    "text_1": "S.H. Raza’s First International Group Exhibition, at Galerie Saint-Placide (Paris, France) with F.N. Newton and Akbar Padamsee.",
    "caption_1": "S.H. Raza | Carcassonne | Mixed media on panel, 1951 | 18 x 21 in (45.7 x 53.3 cm)",
    "Second ImageNumber (Internal for A-Graphy)": "",
    "Image Code(s)2": "",
    "text_2": "First Solo Exhibition in France at the Palais Carnoles, Menton, which at the time was under the Presidency of Henri Matisse, and over the next few years would exhibit many of the great modern masters from Picasso to Dali, Dufy to Sutherland.",
    "Image Caption2": "",
    "Text3": "",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "1949 – 1953",
    "title_of_time_period": "03_Briefly a 'Progressive' and towards a full life in France",
    "sortable_date": "1952",
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "Image24",
    "image1": img1952,
    "text_1": "Galerie Raymond Creuze Group Exhibition with F.N. Souza, S.H. Raza & Akbar Padamsee, Paris, France. Raza had by now taken to using oil paints as his main medium I  he was still financially struggling, hence part-time work in designing book covers for a leading French publisher who represented writers such as Emile Zola and Edgar Allan Poe.",
    "caption_1": "Caption Awaited",
    "Second ImageNumber (Internal for A-Graphy)": "Image25",
    "Image Code(s)2": "0025-s-h-raza-IT.jpg",
    "text_2": "",
    "Image Caption2": "S.H. Raza | Cagnes-Sur-Mer | Gouache and ink on cardboard, 1952 | 19.7 x 22 in (50 x 56 cm)",
    "Text3": "",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "1949 – 1953",
    "title_of_time_period": "03_Briefly a 'Progressive' and towards a full life in France",
    "sortable_date": "1953",
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "Image26",
    "image1": img1953,
    "text_1": "“The new phase which was to break in about 1953, was one of melting down and fusion. In the furnace of an entirely new passion for colour Raza’s new style was born that was to bring to him international fame in a few short years. The gouache technique in tempera was to give way to impasto in oils. The change of medium and manner were not merely technical but signified a fundamental change of attitude. The scholar, who had measured and calculated, burst through the confines of limited understanding of colour and space-created-by-colour into a sphere of full realization. The transformation created such passion that one could best describe this age of Raza as the age of the Lover. This triumphant handling of paint, this living in paint can only be understood as an act of Love. His colours take on an entirely new complexion. Brilliant reds and yellows stand out against large looming forces of black and deep Prussian blue. Shapes dissolve in seas of colours which are by no means unorganized and fluid but seem to move and evolve within the space of his painting.” Extracted from von Leyden, Rudolf/BKA 1959 (3) I  p.19.",
    "caption_1": "S.H. Raza | La Tour | Oil on isorel board, 1953 | 33.1 x 19.7 in (84 x 50 cm)",
    "Second ImageNumber (Internal for A-Graphy)": "Image27",
    "Image Code(s)2": "0027-s-h-raza-IT.jpg",
    "text_2": "",
    "Image Caption2": "S.H. Raza | Italian Village | Oil on isorel board, 1953 | 39.4 x 47.2 in (100 x 120 cm)",
    "Text3": "",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "1954 – 1959",
    "title_of_time_period": "04_Emergence of critical respect and financial stability",
    "sortable_date": "1954",
    "Specific Dates (with Day, Month)": "20th September to 6th October 1955",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "Image28",
    "image1": img1954,
    "text_1": "20th September to 6th October 1955 Solo Exhibition at Galerie Lara Vincy, Paris, France, with a new contract with the Gallery, bringing a degree of financial stability with advances against monthly purchases of his art.",
    "caption_1": "S.H. Raza | Les Toits | Oil on canvas, 1955 | 19.5 x 39.4 in (49.5 x 100 cm)",
    "Second ImageNumber (Internal for A-Graphy)": "",
    "Image Code(s)2": "",
    "text_2": "",
    "Image Caption2": "",
    "Text3": "",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "1954 – 1959",
    "title_of_time_period": "04_Emergence of critical respect and financial stability",
    "sortable_date": "1956",
    "Specific Dates (with Day, Month)": "14th May to 5th June 1956",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "Image29",
    "image1": "0029-s-h-raza-IT.jpg",
    "text_1": "14th May to 5th June 1956 Galerie Lara Vincy, Paris, France July - Prix de la Critique d’Art awarded to S.H. Raza from a final selected list of 18, the first non-French artist to win the prestigous honour I  the Awarding Jury of 14 Artists was presided over by Le Figaro art critic - M. Claude Roger Marx, Paris, France, among other members of the foreign press in France & Belgium.",
    "caption_1": "S.H. Raza | Nuage Blanc | Oil on canvas, 1956 | 35.6 x 45.2 in (90.5 x 114.9 cm)",
    "Second ImageNumber (Internal for A-Graphy)": "",
    "Image Code(s)2": "",
    "text_2": "July to October 1956 Participated at the 28th Biennale Internazionnale d’Arte di Venezia, (Venice, Italy) with his major painting Ville Provencale (1956).",
    "Image Caption2": "",
    "Text3": "October : Solo Exhibition at Galerie Saint-Placide, Paris, France I  6th to 31st December : Saint-Germian-des-Pres, vu par vingt peintures, Galerie Barbizon, Paris, France.\n\nLes Arts en France et dans Ie Monde, Musee d’ Art Moderne, Paris, France.",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "1954 – 1959",
    "title_of_time_period": "04_Emergence of critical respect and financial stability",
    "sortable_date": "1957",
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "Image30",
    "image1": "0030-s-h-raza-IT.jpg",
    "text_1": "“I have invitations for several exhibitions (e.g. The Biennales at Venice, Tokyo Sao Paulo, London, Brussels, New York among others) - to which in the past I had no access. Remember - the canvas we carried to Café dome in the metro - and well so many others - where constantly my work was refused.” Raza to his friend Rajesh Rawat, in a letter dt. 2nd May 1957 I  rpt. in Dalmia, Yashodhara/BKA 2021 (61) I  p.84.",
    "caption_1": "S.H. Raza | Untitled | Oil on canvas, 1957 | 18.2 x 21.8 in (46.3 x 55.3 cm)",
    "Second ImageNumber (Internal for A-Graphy)": "Image31",
    "Image Code(s)2": "0031-s-h-raza-IT.jpg",
    "text_2": "Senior art critics and connoisseurs such as Claude Roger-Marx, Jaques Lassaigne, Waldemar George started appreciating and writing on Raza’s art.\n\n“Shelters perched on the edge of the volcanoes are swept by flames of hell…An orgy of colour is unleashed against a chimeric background of gulfs and crevasses. The mind boggles before this tornado of incandescent tones. Here are the ruins of little alpine towns, dead towns, deserted by their population.The church steeple, with its leaning tower, the cross outlined against a firmament of lead or basalt... Calvaries rise up like gibbets in a landscape of winter and crystal. Raza’s palette is made up of powdered gems, molten metals and embers in which the fire still lives.” Extracted from George, Waldemar. Peintures et Gouaches. Montreal: Galerie Dresdnere 1959 [Raza Solo ExC., 4a].",
    "Image Caption2": "S.H. Raza | Vue de Partinello | Oil on canvas, 1957 | 31.5 x 72.4 in (80 x 184 cm)",
    "Text3": "",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "1954 – 1959",
    "title_of_time_period": "04_Emergence of critical respect and financial stability",
    "sortable_date": "1958",
    "Specific Dates (with Day, Month)": "18th April to 14th May 1958",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "Image32",
    "image1": "0032-s-h-raza-IT.jpg",
    "text_1": "18th April to 14th May 1958 First Solo Exhibition at the Galerie Lara Vincy, Paris, France, which would establish a significant artist-gallery relationship until the early 1970s.",
    "caption_1": "S.H. Raza | Paysage Corse Series | Oil on canvas, 1958 | 30.3 x 36.1 in (76.962 x 91.694 cm)",
    "Second ImageNumber (Internal for A-Graphy)": "Image33",
    "Image Code(s)2": "0033-s-h-raza-IT.jpg",
    "text_2": "Transferences, Zwemmer Gallery, London, UK.\n\nBiennale de Bruges, Bruges, Belgium      Biennale Sao Paulo, Sao Paulo, Brazil",
    "Image Caption2": "1958 Cover of S.H. Raza’s Solo Exhibition at Galerie Lara Vincy, Paris.",
    "Text3": "Henri Cartier-Bresson taking Raza’s photograph at his studio also a sign of Raza’s growing fame, after ten years of their meeting at Srinagar, India.",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "1954 – 1959",
    "title_of_time_period": "04_Emergence of critical respect and financial stability",
    "sortable_date": "1959",
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "Image34",
    "image1": "0034-s-h-raza-IT.jpg",
    "text_1": "April : First Solo Exhibition at the Jehangir Art Gallery (Bombay) & AIFACS (New Delhi), and the first in India since 1950. Sadanga’s Monograph on Raza published with text by Rudi von Leyden.",
    "caption_1": "S.H. Raza | Temple | Oil on canvas, 1959 | 24 x 22.5 in (61 x 57.2 cm)",
    "Second ImageNumber (Internal for A-Graphy)": "",
    "Image Code(s)2": "",
    "text_2": "May : First Solo Exhibition at Galerie Dresdnere, Montreal, Canada, which would represent Raza for a decade (with further Solo Exhibitions in 1960, 1962 & 1968) along with Galerie Lara Vincy (1961, 1962, 1964, 1967 & 1969) I  Trends in Contemporary Painting from India: 10th Feb. to 5th Sept. 1959 - Graham Gallery, New York, USA > American University, Washington DC > West Virginia Institute of Technology, West Virginia > Speed Museum, Kentucky > Dorothy Yepez Gallery, New York, USA.",
    "Image Caption2": "",
    "Text3": "3rd September S.H. Raza marries artist Janine Mongillat in Paris after divorcing his first wife Mukhtar Fatima, who had moved to Pakistan from India as growing incompatibility developed with Raza.",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "1960 – 1968",
    "title_of_time_period": "05_Post-Marriage, with a major shift in his artistic abstraction",
    "sortable_date": "1960",
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "Image35",
    "image1": img1960,
    "text_1": "Trends in Contemporary Painting from India: Travelling Exhibition at Western Michigan University, Michigan, USA 4th to 25th January 1960 > Eastern Tennessee State College, Tennesse 6th to 27th February 1960 > Denison College, Ohio 3rd to 23rd March 1960 > Chatham College, Pennsylvania 12th April to 3rd May 1960.",
    "caption_1": "S.H. Raza | Zameen | Oil on canvas, 1960 | 39.2 x 32 in (99.6 x 81.3 cm)",
    "Second ImageNumber (Internal for A-Graphy)": "",
    "Image Code(s)2": "",
    "text_2": "",
    "Image Caption2": "",
    "Text3": "",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "1960 – 1968",
    "title_of_time_period": "05_Post-Marriage, with a major shift in his artistic abstraction",
    "sortable_date": "1961",
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "Image36",
    "image1": img1961,
    "text_1": "19th April to 18th May 1961 Galerie Lara Vincy, Paris\n“… his paintings were shown as forming part of the ‘Ecole de Paris’ in three consecutive exhibitions held from 1960 to 1962, organised by Galerie Charpentier... AS one critic put it in 1967, “ this is an Indian truly from our country!” Cet Indien varaiment bien de chez nous.” Rpt. in Sen, Dr. Geeti/BKA 1997 (23) I  p.71 Ecole de Paris 61, Galerie Charpentier, Paris, France Galerie Semiha Huber, Zurich, Switzerlan.\n\nThe experimenting with script inscribed on the painting begins, though it only starts to take on a deliberate painterly quality by the late 1960s.",
    "caption_1": "S.H. Raza | Foret Noir (Black Forest) | Oil on canvas, 1961 | 11.2 x 23 in (28.4 x 58.4 cm)",
    "Second ImageNumber (Internal for A-Graphy)": "",
    "Image Code(s)2": "",
    "text_2": "",
    "Image Caption2": "",
    "Text3": "",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "1960 – 1968",
    "title_of_time_period": "05_Post-Marriage, with a major shift in his artistic abstraction",
    "sortable_date": "1962",
    "Specific Dates (with Day, Month)": "30th July to 7th September",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "Image37",
    "image1": img1962,
    "text_1": "30th July to 7th September Visiting Lecturer at the Painting Department of University of California, Berkeley, USA, upon the invitation of Prof. Karl Kasten I  and thereafter a one month visit to New York & Washington on the invitation of the Rockefeller Foundation. The USA Visit also introduced Raza to acrylic colours which was a significant turning point, as it complemented his work in oil given his sensibilities and his previous love for watercolours during the 1940s.",
    "caption_1": "S.H. Raza | Route De Chomerac | Oil on canvas, 1962 | 26 x 32 in (66 x 81.3 cm)",
    "Second ImageNumber (Internal for A-Graphy)": "",
    "Image Code(s)2": "",
    "text_2": "First USA Solo Exhibitions at Lanyon Gallery, Palo Alto, California & Worth Ryder Art Gallery, Berkeley University, California.",
    "Image Caption2": "",
    "Text3": "Gallery 63, New York, USA.\n\nCommonwealth Exhibition, Commonwealth Institute, London, UK.\n\nThomas B. Keehn helps Raza get an one month stay in New York during October on invitation of the Rockefeller Foundation, where he met Mr & Mrs John D. Rockefeller, who had pioneered many of the international cultural institution-building efforts, especially with Asia and India.",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "1960 – 1968",
    "title_of_time_period": "05_Post-Marriage, with a major shift in his artistic abstraction",
    "sortable_date": "1963",
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "Image38",
    "image1": img1963,
    "text_1": "June First Solo Exhibition at Dom Gallery, Cologne, Germany.",
    "caption_1": "S.H. Raza | Hut | Oil on canvas, 1963 | 16 x 13 in (40.6 x 33 cm)",
    "Second ImageNumber (Internal for A-Graphy)": "",
    "Image Code(s)2": "",
    "text_2": "Biennale du Maroc, Rabat, Morocco.",
    "Image Caption2": "",
    "Text3": "",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "1960 – 1968",
    "title_of_time_period": "05_Post-Marriage, with a major shift in his artistic abstraction",
    "sortable_date": "1964",
    "Specific Dates (with Day, Month)": "18th November 1964",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "Image39",
    "image1": img1964,
    "text_1": "18th November 1964 - 10th January 1965 Solo Exhibition at Galerie Lara Vincy, titled RAZA: Peintures récentes, Paris, France.",
    "caption_1": "S.H. Raza at his Studio, Paris\nImage from Gallery Chemould Exhibition Catalogue 1968",
    "Second ImageNumber (Internal for A-Graphy)": "",
    "Image Code(s)2": "",
    "text_2": "Biennale de Menton, Menton, France.",
    "Image Caption2": "",
    "Text3": "",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "1960 – 1968",
    "title_of_time_period": "05_Post-Marriage, with a major shift in his artistic abstraction",
    "sortable_date": "1965",
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "Image40",
    "image1": img1965,
    "text_1": "Art Now in India, Newcastle, UK - Travelling Group Exhibition.",
    "caption_1": "S.H. Raza | La Provence Noir | Acrylic on canvas, 1965 | 79.5 x 72 in (201.9 x 182.9 cm)",
    "Second ImageNumber (Internal for A-Graphy)": "",
    "Image Code(s)2": "",
    "text_2": "",
    "Image Caption2": "",
    "Text3": "",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "1960 – 1968",
    "title_of_time_period": "05_Post-Marriage, with a major shift in his artistic abstraction",
    "sortable_date": "1966",
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "Image41",
    "image1": img1966,
    "text_1": "",
    "caption_1": "S.H. Raza | Untitled | Acrylic on canvas, 1966 | 51.2 x 53.9 in (130 x 137 cm)",
    "Second ImageNumber (Internal for A-Graphy)": "",
    "Image Code(s)2": "",
    "text_2": "",
    "Image Caption2": "",
    "Text3": "",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "1960 – 1968",
    "title_of_time_period": "05_Post-Marriage, with a major shift in his artistic abstraction",
    "sortable_date": "1967",
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "Image42",
    "image1":img1967,
    "text_1": "",
    "caption_1": "S.H. Raza | Col De Castillon | Oil on canvas, 1967 | 24 x 19.5 in (61 x 49.5 cm)",
    "Second ImageNumber (Internal for A-Graphy)": "Image43",
    "Image Code(s)2": "0043-s-h-raza-IT.jpg",
    "text_2": "",
    "Image Caption2": "S.H. Raza | Gods Dwell Where Women is Adored | Acrylic on canvas, 1967 | 28.7 x 21.3 in (73 x 54 cm)",
    "Text3": "",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "1960 – 1968",
    "title_of_time_period": "05_Post-Marriage, with a major shift in his artistic abstraction",
    "sortable_date": "1968",
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "Image44",
    "image1": img1968,
    "text_1": "Solo Exhibitions in Germany at Dom Gallery (Cologne) & Tecta Gallery (Dusseldorf) I  Galerie Dresdnere (Toronto, Canada).",
    "caption_1": "S.H. Raza | Black Moon | Oil on canvas, 1968 | 36.2 x 28.7 in (92 x 73 cm)",
    "Second ImageNumber (Internal for A-Graphy)": "Image45",
    "Image Code(s)2": "0045-s-h-raza-IT.jpg",
    "text_2": "15th to 27th April : First Solo Exhibition at Gallery Chemould (Bombay, India), who would be Raza’s main Indian Gallery during the next forty years displaying some of his very best work (Further Solo Exhibitions in 1976, 1984, 1988 & 2002).",
    "Image Caption2": "S.H. Raza | Black Sun | Oil on canvas, 1968 | 59.1 x 59.1 in (150 x 150 cm)",
    "Text3": "",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "1969 – 1976",
    "title_of_time_period": "06_A new artistic maturity as the whole finds a diverse embrace",
    "sortable_date": "1970",
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "Image46",
    "image1": img1970,
    "text_1": "",
    "caption_1": "S.H. Raza | Aj | Oil on canvas, 1970 | 18 x 15 in (45.7 x 38 cm)",
    "Second ImageNumber (Internal for A-Graphy)": "",
    "Image Code(s)2": "",
    "text_2": "",
    "Image Caption2": "",
    "Text3": "",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "1969 – 1976",
    "title_of_time_period": "06_A new artistic maturity as the whole finds a diverse embrace",
    "sortable_date": "1971",
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "Image47",
    "image1": img1971,
    "text_1": "",
    "caption_1": "S.H. Raza | L'Inconnu | Acrylic on canvas, 1971 | 78.7 x 39.4 in (200 x 100 cm)",
    "Second ImageNumber (Internal for A-Graphy)": "",
    "Image Code(s)2": "",
    "text_2": "",
    "Image Caption2": "",
    "Text3": "",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "1969 – 1976",
    "title_of_time_period": "06_A new artistic maturity as the whole finds a diverse embrace",
    "sortable_date": "1972",
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "Image48",
    "image1": img1972,
    "text_1": "1972 First Solo Exhibition at the Abbaye du Pommier, Anncy, Le Grenier, Roquebrune Village, France.",
    "caption_1": "S.H. Raza | Tapovan | Acrylic on canvas, 1972 | 62.5 x 74 in (158.8 x 188 cm)",
    "Second ImageNumber (Internal for A-Graphy)": "",
    "Image Code(s)2": "",
    "text_2": "Tapovan is till date the most expensive painting sold in public auctions by the artist.",
    "Image Caption2": "",
    "Text3": "",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "1969 – 1976",
    "title_of_time_period": "06_A new artistic maturity as the whole finds a diverse embrace",
    "sortable_date": "1973",
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "Image49",
    "image1": img1973,
    "text_1": "The impact of the American Abstract Expressionist movement (especially the work and ideas of Hans Hoffman, Sam Francis and Mark Rothko) along with the adoption of acrylic paints hence a more fluid brush movement post his USA visit had inspired a change in Raza’s abstract forms, as he states: Thereafter, a visual reality, the aim to construct a “tangible” world, receded. In its place there was a preoccuptaion with evoking the essence, the mood of places and of people. day and night, summer and winter, joy and anguish - these elementary experiences that are felt rather than seen, became my subjects.” Extracted from Sen, Dr. Geeti/BKA 1997 (23) I  p.59.",
    "caption_1": "S.H. Raza | La Terre | Oil on canvas, 1973 | 74.4 x 74.4 in (189 x 189 cm)",
    "Second ImageNumber (Internal for A-Graphy)": "",
    "Image Code(s)2": "",
    "text_2": "",
    "Image Caption2": "",
    "Text3": "",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "1969 – 1976",
    "title_of_time_period": "06_A new artistic maturity as the whole finds a diverse embrace",
    "sortable_date": "1974",
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "Image50",
    "image1": img1974,
    "text_1": "Solo Exhibition at La Palette, Trouville, France.\n\nGroup Exhibitions Premier Salon International d’Art Contemporain, Paris, France.\n\nSalon Grands et Jeunes d’ Aujourd’hui, Paris, France.\n\nLalit Kala Akademi, Rabindra Bhawan Galleries, New Delhi, India.",
    "caption_1": "S.H. Raza | LA MER | Acrylic on canvas, 1974 | 59.8 x 59.8 in (152 x 152 cm)",
    "Second ImageNumber (Internal for A-Graphy)": "",
    "Image Code(s)2": "",
    "text_2": "",
    "Image Caption2": "",
    "Text3": "",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "1969 – 1976",
    "title_of_time_period": "06_A new artistic maturity as the whole finds a diverse embrace",
    "sortable_date": "1975",
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "Image51",
    "image1": img1975,
    "text_1": "1975 First Solo Exhibition at Galleria Matuzia (San Remo, Italy) where La Mer, among other constant paintings, was exhibited, purchased by Italian Collector - Luciano G. Lupi, later exhibited at Jehangir Art Gallery & Gallery Chemould Exhibition (1976) and thereafter auctioned in Sotheby’s (24th March 2010) and Christie’s (20th March 2019) once the Indian art market starting to grow.",
    "caption_1": "S.H. Raza | Oasis | Acrylic on canvas, 1975 | 39 x 39 in (99.1 x 99.1 cm)",
    "Second ImageNumber (Internal for A-Graphy)": "",
    "Image Code(s)2": "",
    "text_2": "His description of a painting such as Oasis (1975) is insightful: “The whole fabric of the painting appears to be a collated vision of a continuous expanse of phenomena we have experienced as nature - night fires, the glow of sunlight, green fields at daytime, and in twilight, trees and their forms merging with the night. A loose sectional design is deployed, never rigidly, and sections enclose triangular forms, fully or partially formulated, which thrust out in many directions. A nervous life is generated by these active forms and live colours, and there is the suggestion of the presence of a vast, fecund force, a “dark inscrutable force”, as Wordsworth said, “that reconciles discordant elements and makes them cling together in one society.” Extracted from Bartholomew, Richard. ‘Nature and Abstraction: An Enquiry into Their Interaction’. New Delhi: Lalit Kala Contemporary Journal (#23) for Lalit Kala Akademi 1977 [Essay: JOU, 12].",
    "Image Caption2": "",
    "Text3": "",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "1969 – 1976",
    "title_of_time_period": "06_A new artistic maturity as the whole finds a diverse embrace",
    "sortable_date": "1976",
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "Image52",
    "image1": img1976,
    "text_1": "“Raza always painted an imaginary world, traversed by tragic intensities, with his energies concentrated at the points where his coloured planes overlapped. In the thickness of his matter, a whole network of coloured veins circulated I  flashing reds and yellows pierced deep blacks. Effects of tension and nervous agitation upset shadowy zones. The composition itself was affected by this, and in a given work, the compressed pulsations of the forms, the character of which could be defined as anguishing, were in opposition to immense, light and calm surfaces. Thus, ever faithful to his deep sentiments, Raza sought to free himself of the oppression of the night and to glorify the serenity rediscovered in the light of dawn.” Extracted from Jacques Lassaigne, rpt. in Gallery Chemould. Raza. Bombay: Gallery Chemould 1976 [Raza Solo ExC., 10].",
    "caption_1": "S.H. Raza | Untitled (Matheran) | Acrylic on canvas, 1976 | 39.5 x 39.5 in (100.3 x 100.3 cm)",
    "Second ImageNumber (Internal for A-Graphy)": "",
    "Image Code(s)2": "",
    "text_2": "",
    "Image Caption2": "",
    "Text3": "",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "1961 – 1983",
    "title_of_time_period": "07_A Focus on the Rajasthan Series",
    "sortable_date": "1961",
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "Image53",
    "image1": img1961_d,
    "text_1": "“A fact has hardly been noticed: that from 1970, for fifteen years, Raza did not hold a single exhibition in Paris. (Fn.13: Geeti Sen thanks Dominique Rey for bringing this fact to her notice). His first exhibition thereafter was held at Galerie Pierre Parat and titled significantly, “Raza: Quinze Ans de Peinture” or Raza’s Fifteen Years of Painting”.",
    "caption_1": "S.H. Raza | Rajasthan I | Oil on canvas, 1961 | 19.8 x 19.6 in (50.2 x 49.8 cm)",
    "Second ImageNumber (Internal for A-Graphy)": "",
    "Image Code(s)2": "",
    "text_2": "The first documented painting titled “Rajasthan” was in 1961 and not during his Berkeley visit as suggested in Sen, Dr. Geeti/BKA 2020 (60) I  p.128, but her insights on the Rajasthan Series are still the most deeply pursued: “Rajasthan becomes a metaphor for the colours of India: of vibrant greens and vermillion and ochres, as also blacks. Rajasthan is the mapping out of a metaphorical space in the mind which is then enclosed witha broad border in bold vermillion - as also happens to be the case in Rajput paintings from the sixteenth and seventeenth centuries. The image becomes thus enstrined as an icon, as sacred geography.”",
    "Image Caption2": "",
    "Text3": "",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "1961 – 1983",
    "title_of_time_period": "07_A Focus on the Rajasthan Series",
    "sortable_date": "1973",
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "Image54",
    "image1": img1973_d,
    "text_1": "",
    "caption_1": "S.H. Raza | Rajasthan | Acrylic on canvas, 1973 | 25.6 x 19.7 in (65 x 50 cm)",
    "Second ImageNumber (Internal for A-Graphy)": "",
    "Image Code(s)2": "",
    "text_2": "",
    "Image Caption2": "",
    "Text3": "",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "1961 – 1983",
    "title_of_time_period": "07_A Focus on the Rajasthan Series",
    "sortable_date": "1975",
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "Image55",
    "image1": img1975_d,
    "text_1": "",
    "caption_1": "S.H. Raza | Rajasthan | Acrylic on canvas, 1975 | 59.8 x 59.8 in (152 x 152 cm)",
    "Second ImageNumber (Internal for A-Graphy)": "",
    "Image Code(s)2": "",
    "text_2": "",
    "Image Caption2": "",
    "Text3": "",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "1961 – 1983",
    "title_of_time_period": "07_A Focus on the Rajasthan Series",
    "sortable_date": "1977",
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "Image56",
    "image1": img1977,
    "text_1": "",
    "caption_1": "S.H. Raza | Rajasthan | Acrylic on canvas, 1977 | 25.6 x 19.7 in (65 x 50 cm)",
    "Second ImageNumber (Internal for A-Graphy)": "",
    "Image Code(s)2": "",
    "text_2": "",
    "Image Caption2": "",
    "Text3": "",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "1961 – 1983",
    "title_of_time_period": "07_A Focus on the Rajasthan Series",
    "sortable_date": "1978",
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "",
    "image1": img1978,
    "text_1": "1978 An exhibition ‘Utsav’ at Madhya Pradesh Kala Parishad, Bhopal, the first ever in his homestate M.P. was organised with a Seminar on his art with Richard Bartholomew, Geeti Sen and other scholars. This Utsav was also constant for cementing a  life long friendship and creative collaboration between artist Raza and poet-bureaucrat Ashok Vajpeyi.",
    "caption_1": "CAPTION AWAITED",
    "Second ImageNumber (Internal for A-Graphy)": "",
    "Image Code(s)2": "",
    "text_2": "",
    "Image Caption2": "",
    "Text3": "",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "1961 – 1983",
    "title_of_time_period": "07_A Focus on the Rajasthan Series",
    "sortable_date": "1981",
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "Image57",
    "image1": img1981,
    "text_1": "",
    "caption_1": "S.H. Raza | Rajasthan | Acrylic on canvas, 1981 | 47.2 x 47.2 in (120 x 120 cm)",
    "Second ImageNumber (Internal for A-Graphy)": "",
    "Image Code(s)2": "",
    "text_2": "",
    "Image Caption2": "",
    "Text3": "",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "1961 – 1983",
    "title_of_time_period": "07_A Focus on the Rajasthan Series",
    "sortable_date": "1983",
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "Image58",
    "image1": img1983,
    "text_1": "“Raza’s semi-abstractions by contrast stem from the magical, transfixing power of the miniature. Rajasthan II (cat. No. 52) is modulary structured, with each module being the reduction of a miniature format. These modules reverberate with the miniature’s musicality. If Raza’s elegant astringency bespeaks his home in the south of France, his forms and colours bespeak his youth in the forests of Madhya Pradesh, whose fluid, inviting, and slightly terrifying pathways he has described as among the most profound influences of his life.” Daniel Herwitz, extracted from Grey Art Gallery & Study Center. Contemporary Indian Art: from the Chester and Davida Family Collection. New York: Grey Art Gallery & Study Center & New York University 1985 [General Art History I  Collection, 13] I  p.25.",
    "caption_1": "S.H. Raza | Rajastan I | Acrylic on canvas, 1983 | 60 x 60 in (152.4 x 152.4 cm)",
    "Second ImageNumber (Internal for A-Graphy)": "Image59",
    "Image Code(s)2": "0059-s-h-raza-IT.jpg",
    "text_2": "“Memory began to play a role in recalling the experience and mood of a situation. Paintings such as Rajasthan and Saurasthra summoned up that quality of light, patterns and textures and colours that had remained embedded in my mind. Instead of being constructions, my paintings from the 1970s are more gestural in technique and expression. In terms of colour too, they are expressionistic. the spontaneity was new and compulsive - I let the canvas grow.” S.H. Raza, rpt. in Sen, Dr. Geeti/BKA 1997 (23) I  p.59.",
    "Image Caption2": "S.H. Raza | Rajasthan | Acrylic on canvas, 1983 | 68.9 x 68.9 in (175 x 175 cm)",
    "Text3": "",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },{
    "Sr_No": "",
    "time_period": "1961 – 1983",
    "title_of_time_period": "07_A Focus on the Rajasthan Series",
    "sortable_date": "1983",
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "Image58",
    "image1": img1983b,
    "text_1": "",
    "caption_1": "S.H. Raza | Rajasthan | Acrylic on canvas, 1983 | 68.9 x 68.9 in (175 x 175 cm)",
    "Second ImageNumber (Internal for A-Graphy)": "Image59",
    "Image Code(s)2": "0059-s-h-raza-IT.jpg",
    "text_2": "",
    "Image Caption2": "S.H. Raza | Rajasthan | Acrylic on canvas, 1983 | 68.9 x 68.9 in (175 x 175 cm)",
    "Text3": "",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "1977 – 1989",
    "title_of_time_period": "08_A Focus on the Bindu : Early Variations",
    "sortable_date": "1977",
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "Image60",
    "image1": "0060-s-h-raza-IT.jpg",
    "text_1": "In the ‘sixties and ‘seventies visits to India re-sensitized his perceptiveness for a final supreme and universal viewing of nature, not as appearance, not as spectacle but as an integrated force of life and cosmic growth reflected in every elementary particle and in every fibre of a human being. The five elements which in Hindu thought build this and other worlds – earth, jala – water, pawak – fire, gagan – sky and samara – ether and their correspondence, on the one hand, to areas of consciousness in the human mind and, on the other, to the colours yellow – padma, white – sulka, red – tejas, blue – nila and black – Krishna captured Raza’s imagination to the point of complete identification of himself with his painted work. Nature became to Raza something not to be observed or to be imagined but something to be experienced in the very act of putting paint on canvas. Painting acts itself out as a natural force, struggling in darkness, breaking into light, shivering in cold, burning in heat, trying to find form and yet dissolving into chaos. Extracted from Rudolf von Leyden rpt. In Raza : Gorbio & Bombay I  Jehangir Nicholson Museum, NCPA ExC. 1978.",
    "caption_1": "S.H. Raza | Sourya | Acrylic on canvas, 1977 | 39.3 x 39.3 in (99.7 x 99.7 cm)",
    "Second ImageNumber (Internal for A-Graphy)": "",
    "Image Code(s)2": "",
    "text_2": "",
    "Image Caption2": "",
    "Text3": "",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "1977 – 1989",
    "title_of_time_period": "08_A Focus on the Bindu : Early Variations",
    "sortable_date": 1980,
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "Image61",
    "image1": "0061-s-h-raza-IT.jpg",
    "text_1": "“Raza’s concern with principles of pure geometry can easily be misconstrued to suggest the approach of pure formalism, or of structuralism, or even neo-tantrism. That he is aware of the possibility of misunderstanding is evident in a carefully worded letter. Invited to exhibit his works in a show to be held on neo-tantric imagery, Raza replied to Dr. Sihare, Director of the National Gallery of Modern Art in 1983, to decline the offer: ‘My using a “circle” or a “square” as key motifs do not make my paintings tantric, even if I have called them “Bindu”, “Sourya” or “Zamine”. I am aware, Tantric Darshan is far too complex. I know little about its beliefs and rituals. My involvement in work is essentially with the life of form and all my efforts are directed towards a coherent pictorial logic...” Extracted from Gaudibert, Pierre & Dr. Geeti Sen/Group ExC 1991 (16). Naturally the same misunderstanding was created regarding the artistic journeys of other modern masters such as Biren De and K.C.S. Paniker, who participated in the 1983 Exhibition for various reasons, but were equally far from neo-tantric in their categorisation and spirit.",
    "caption_1": "S.H. Raza | Untitled | Acrylic on canvas, 1980 | 39.4 x 39.4 in (100.1 x 100.1 cm)",
    "Second ImageNumber (Internal for A-Graphy)": "",
    "Image Code(s)2": "",
    "text_2": "",
    "Image Caption2": "",
    "Text3": "",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "1977 – 1989",
    "title_of_time_period": "08_A Focus on the Bindu : Early Variations",
    "sortable_date": 1981,
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "Image62",
    "image1": "0062-s-h-raza-IT.jpg",
    "text_1": "Refer to SH Raza: Catalogue Raisonne 1972-1989 (Volume II)",
    "caption_1": "S.H. Raza | Maa | Acrylic on canvas, 1981 | 68.9 x 102.4 in (175 x 260 cm)",
    "Second ImageNumber (Internal for A-Graphy)": "",
    "Image Code(s)2": "",
    "text_2": "",
    "Image Caption2": "",
    "Text3": "",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "1977 – 1989",
    "title_of_time_period": "08_A Focus on the Bindu : Early Variations",
    "sortable_date": 1982,
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "Image63-73\nYes : Cover of 5th Triennale plus 4 image pages (pp.87 to 90) plus Maa_1981 (Image53) plus 5 pages in Project #402",
    "image1": "0063-s-h-raza-IT.jpg\nto\n0073-s-h-raza-IT.jpg",
    "text_1": "In 1981 Richard Bartolomew invited S.H. Raza to participate in the 5th Triennale (1982). Maa & Bindu were S.H. Raza’s entries. Regarding Maa, Raza stated: “The idea emerged from a poem by Ashok Vajpeyi, addressed to his mother. It was tender, true and stimulating. I was inspired to conceive a painting that could be a letter to my mother country, India, revealing my experiences, discoveries and acquisitions. I hoped that the painting could be the evidence that I was never cut off from my sources. The memory conscious and unconscious were ever-present. Composed of several sections, yet a united whole, the painting conveyed the message in colour... I inscribed discreetly, the first line of the poem - ‘Ma, laut kar jab aunga kya laounga.’” S.H. Raza, rpt. in Sen, Dr. Geeti/BKA 1997 (23) I  p.82.",
    "caption_1": "Cover of Fifth Triennale-India 1982 | https://drive.google.com/drive/u/2/folders/1yNHigBxRDBMapmV6ZRD6S-_IkAFSGhn-",
    "Second ImageNumber (Internal for A-Graphy)": "Image74",
    "Image Code(s)2": "0074-s-h-raza-IT.jpg",
    "text_2": "",
    "Image Caption2": "S.H. Raza | Untitled | Acrylic on canvas, 1982 | 31.5 x 31.3 in (80 x 79.4 cm)",
    "Text3": "",
    "Masterlist1": "Sankho-Chaudhuri",
    "Masterlist2": "Lalit-Kala-Akademi",
    "Masterlist3": "Triennale",
    "Keywords": "Dhruva-Mistry|Bikash-Bhattacharjee|Mrinalini-Mukherjee|Biren-De",
    "Research Category1": "FineArts",
    "Research Category2": "Indo-World",
    "Research Category3": "Scholarship"
  },
  {
    "Sr_No": "",
    "time_period": "1977 – 1989",
    "title_of_time_period": "08_A Focus on the Bindu : Early Variations",
    "sortable_date": 1983,
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "Image75",
    "image1": "0075-s-h-raza-IT.jpg",
    "text_1": "",
    "caption_1": "S.H. Raza | Blue Bindu | Oil on canvas, 1983 | 34 x 23 in (86.4 x 58.4 cm)",
    "Second ImageNumber (Internal for A-Graphy)": "Image76",
    "Image Code(s)2": "0076-s-h-raza-IT.jpg",
    "text_2": "",
    "Image Caption2": "S.H. Raza | Untitled | Acrylic on canvas, 1983 | 36 x 29.8 in (91.44 x 75.692 cm)",
    "Text3": "",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "1977 – 1989",
    "title_of_time_period": "08_A Focus on the Bindu : Early Variations",
    "sortable_date": 1984,
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "Image77",
    "image1": "0077-s-h-raza-IT.jpg",
    "text_1": "",
    "caption_1": "S.H. Raza | Red Bindu | Acrylic on canvas, 1984 | 39.4 x 39.4 in (100 x 100 cm)",
    "Second ImageNumber (Internal for A-Graphy)": "",
    "Image Code(s)2": "",
    "text_2": "",
    "Image Caption2": "",
    "Text3": "",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "1977 – 1989",
    "title_of_time_period": "08_A Focus on the Bindu : Early Variations",
    "sortable_date": 1986,
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "Image78",
    "image1": "0078-s-h-raza-IT.jpg",
    "text_1": "“Just like sleep, silence is only an apparent pause in activity. While we sleep, many things essential to our psychic balance happen unconsciously. In silence, this is also true - but we are conscious of our activity. If we can really concentrate it is only when we are surrounded by silence that we can attain true perception of fundamental realities. And then, there is the silence within the painting itself. Silent spaces on a canvas emphasize the active areas. Nothing seems to happen in these spaces, but it is their very presence which retains and organizes the agitation surrounding them.” S.H. Raza in conversation with Francois-Yes Morin, extracted from Barbier, Helen (Curator). Coups de Coeur. Geneva: Edition Halle Sud 1987 [Group Exhibition Catalogue & Raza Interview (1987), 14] I  p.29.",
    "caption_1": "S.H. Raza | Sourya | Acrylic on canvas, 1986 | 79.9 x 74.8 in (203 x 190 cm)",
    "Second ImageNumber (Internal for A-Graphy)": "",
    "Image Code(s)2": "",
    "text_2": "",
    "Image Caption2": "",
    "Text3": "",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "1977 – 1989",
    "title_of_time_period": "08_A Focus on the Bindu : Early Variations",
    "sortable_date": 1989,
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "Image79",
    "image1": "0079-s-h-raza-IT.jpg",
    "text_1": "“My present work is the result of two parallel enquiries. Firstly, it aimed at pure plastic order, form-order. Secondly, it concerns the theme of Nature. Both have converged into a single point and become inseparable. The point, the bindu, symbolises the seed bearing the potential of all life, in a sense. It’s also a visible form containing all the essential requisites of line, tone, colour, texture and space. The black space is charged with latent forces aspiring for fulfilment.” S.H. Raza extracted from Bickelmann, Ursula & Nissim Ezekiel (Eds.)/BKE 1987 (15) I  p.18.",
    "caption_1": "S.H. Raza | Gestation | acrylic on canvas, 1989 | 39.4 x 78.7 in (100 x 200 cm)",
    "Second ImageNumber (Internal for A-Graphy)": "",
    "Image Code(s)2": "",
    "text_2": "",
    "Image Caption2": "",
    "Text3": "",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "1987 – 2006",
    "title_of_time_period": "09_A Focus on Bindu : ‘Black’ ‘Blue’ and ‘White’",
    "sortable_date": 1987,
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "Image80",
    "image1": "0080-s-h-raza-IT.jpg",
    "text_1": "",
    "caption_1": "S.H. Raza | Beeja | Acrylic on canvas, 1987 | 31.9 x 23.6 in (81 x 60 cm)",
    "Second ImageNumber (Internal for A-Graphy)": "",
    "Image Code(s)2": "",
    "text_2": "",
    "Image Caption2": "",
    "Text3": "",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "1987 – 2006",
    "title_of_time_period": "09_A Focus on Bindu : ‘Black’ ‘Blue’ and ‘White’",
    "sortable_date": 1990,
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "Image81",
    "image1": "0081-s-h-raza-IT.jpg",
    "text_1": "“Jala Bindu (1990) explores the same idea, but uses only one of the five elements - water. Fine horizontal lines move out from the central bindu, which seems to be rising. Painted in blues, this represents for me the female or the feminine aspect.",
    "caption_1": "S.H. Raza | Jal Bindu | Acrylic on canvas, 1990 | 39.5 x 39.5 in (100.3 x 100.3 cm)",
    "Second ImageNumber (Internal for A-Graphy)": "",
    "Image Code(s)2": "",
    "text_2": "",
    "Image Caption2": "",
    "Text3": "",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "1987 – 2006",
    "title_of_time_period": "09_A Focus on Bindu : ‘Black’ ‘Blue’ and ‘White’",
    "sortable_date": 1992,
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "Image82",
    "image1": "0082-s-h-raza-IT.jpg",
    "text_1": "",
    "caption_1": "S.H. Raza | Ankuran | Oil on canvas, 1992 | 79 x 39.5 in (200.7 x 100.3 cm)",
    "Second ImageNumber (Internal for A-Graphy)": "",
    "Image Code(s)2": "",
    "text_2": "",
    "Image Caption2": "",
    "Text3": "",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "1987 – 2006",
    "title_of_time_period": "09_A Focus on Bindu : ‘Black’ ‘Blue’ and ‘White’",
    "sortable_date": 1995,
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "Image83",
    "image1": "0083-s-h-raza-IT.jpg",
    "text_1": "",
    "caption_1": "S.H. Raza | Nad-Bindu | Acrylic on canvas, 1995 | 69 x 59 in (175.2 x 149.8 cm)",
    "Second ImageNumber (Internal for A-Graphy)": "Image84",
    "Image Code(s)2": "0084-s-h-raza-IT.jpg",
    "text_2": "",
    "Image Caption2": "S.H. Raza | NAd-Bindu (In Three Parts) | Acrylic on canvas, 1995 | 78.7 x 236.2 in (200 x 600 cm)",
    "Text3": "",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "1987 – 2006",
    "title_of_time_period": "09_A Focus on Bindu : ‘Black’ ‘Blue’ and ‘White’",
    "sortable_date": 1997,
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "Image85",
    "image1": "0085-s-h-raza-IT.jpg",
    "text_1": "“A colour spread over three-fourth parts of the earth. A transparent presence in the throat of “Shiva”. A colour with tranquil memory, with mysterious nature. With pacifying look, hiding the depth of ocean. This blue, exactly this blue is burning in Raza’s painting. This colour, this blue colour, this ‘Royal’ blue is burning. A colour known for its coolness and this categorically cold colour has become transparent in Raza’s painting ‘Ocean’ and its tranquility one could see flames, fire and the warmth...Raza cleans its monochromatic solitude and its calm cool sadness, finds a new born burning blue...Raza and colour are one. The colour behaves the way Raza thinks.” Akhilesh, in ‘The Blue which burns’, extracted from Vajpeyi, Ashok/BKA 2007 (43) I  p.338-40.",
    "caption_1": "S.H. Raza | Prakriti | Acrylic on canvas, 1997 | 29.5 x 59.1 in (75 x 150 cm)",
    "Second ImageNumber (Internal for A-Graphy)": "Image86",
    "Image Code(s)2": "0086-s-h-raza-IT.jpg",
    "text_2": "“Naad Bindu… according to Raza, has elements of sound resonating through its vital rotations…. The monochromatic canvas, which expands into a translucent space, can be appreciated for its exquisite delicacy. Yet the composition seems almost too precious to be a vibrating force of energy. Even as its tonal variations create an atmosphere of meditative spirituality, its ethereal quality is far removed from any sound vibrations…For all the dissonances in Raza’s work, however, it never fails to arouse at the sensuous level and it is in this that his compositions reach their element.” Extracted from Dalmia, Yashodhara/BKA 2001 (32) I  p.171.",
    "Image Caption2": "S.H. Raza | Nad-Bindu | Acrylic on canvas, 1997 | 39.4 x 31.5 in (100 x 80 cm)",
    "Text3": "",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "1987 – 2006",
    "title_of_time_period": "09_A Focus on Bindu : ‘Black’ ‘Blue’ and ‘White’",
    "sortable_date": 1998,
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "Image87",
    "image1": "0087-s-h-raza-IT.jpg",
    "text_1": "The Ocean I and The Ocean II\nInvited by the underground railway of Lisbon to do a painting for the Oriente station in the complex that was going to house the 1998 International Exhibition. His works The Ocean I and The Ocean II were reproduced and enlarged on cermic tiles, 3.5 x 13.5m for the walls of the subway.\n(http://razacatalogueraisonne.com/biography)",
    "caption_1": "The Ocean I and The Ocean II\nInvited by the underground railway of Lisbon to do a painting for the Oriente station in the complex that was going to house the 1998 International Exhibition. His works The Ocean I and The Ocean II were reproduced and enlarged on cermic tiles, 3.5 x 13.5m for the walls of the subway.\n(http://razacatalogueraisonne.com/biography)",
    "Second ImageNumber (Internal for A-Graphy)": "",
    "Image Code(s)2": "",
    "text_2": "",
    "Image Caption2": "",
    "Text3": "",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "1987 – 2006",
    "title_of_time_period": "09_A Focus on Bindu : ‘Black’ ‘Blue’ and ‘White’",
    "sortable_date": 2000,
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "Image88",
    "image1": "0088-s-h-raza-IT.jpg",
    "text_1": "",
    "caption_1": "S.H. Raza | Jalashaya | Acrylic on canvas, 2000 | 40 x 40 in (101.6 x 101.6 cm)",
    "Second ImageNumber (Internal for A-Graphy)": "",
    "Image Code(s)2": "",
    "text_2": "",
    "Image Caption2": "",
    "Text3": "",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "1987 – 2006",
    "title_of_time_period": "09_A Focus on Bindu : ‘Black’ ‘Blue’ and ‘White’",
    "sortable_date": 2002,
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "Image89",
    "image1": "0089-s-h-raza-IT.jpg",
    "text_1": "“In the next stage the Bindu and its various manifestations led Raza along the path to truth… This journey still goes on, his attempts at purification lately culminated in his White Period. White canvases are not un-painted canvases. On the contrary, they reveal a very sophisticated geometric construction, leading to a pure and serene harmony, to Peace or the Shanti Rasa. Of the nine emotions this is, according to Indian thought, the most difficult to achieve. It calls for years of sustained effort, research and expression, both in life and in artistic pursuits. However, Raza is still feeling ambiguous about his White Period...” Extracted from Imbert, Michel/BKA 2003 (37a).",
    "caption_1": "S.H. Raza | Nil Kanth | Acrylic on canvas, 2002 | 46.5 x 46.5 in (118.1 x 118.1 cm)",
    "Second ImageNumber (Internal for A-Graphy)": "",
    "Image Code(s)2": "",
    "text_2": "",
    "Image Caption2": "",
    "Text3": "",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "1987 – 2006",
    "title_of_time_period": "09_A Focus on Bindu : ‘Black’ ‘Blue’ and ‘White’",
    "sortable_date": 2006,
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "Image90",
    "image1": "0090-s-h-raza-IT.jpg",
    "text_1": "",
    "caption_1": "S.H. Raza | Om Shanti Shanti Shanti | Acrylic on canvas, 2006 | 76.4 x 44.9 in (194 x 114 cm)",
    "Second ImageNumber (Internal for A-Graphy)": "Image91",
    "Image Code(s)2": "0091-s-h-raza-IT.jpg",
    "text_2": "",
    "Image Caption2": "S.H. Raza | Genesis | Acrylic on canvas, 2006 | 39 x 39 in (99.1 x 99.1 cm)",
    "Text3": "",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "2007 – 2016",
    "title_of_time_period": "10_Last Years of Exploration",
    "sortable_date": 2007,
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "Image92",
    "image1": "0092-s-h-raza-IT.jpg",
    "text_1": "By 2007, at the age of eighty-five, Raza still aspired towards refining his life-long artistic journey, of wishing to find some inner realisation which deepened his search for a meaning of life…\n\n“Raza paints his new works as a homage of gratitude to the world, to nature and to powers that be. He celebrates them colourfully, in joy and tranquility I  he seems to be reaching out to an enriching and deeply contemplative silence. These later day works are also objects organically moving towards consonance, harmony and peace not only of colours but also of mind.” Ashok Vajpeyi, rpt. in Raza: A Life in Art. New Delhi: Art Alive Gallery 2007 I  p. 128.",
    "caption_1": "S.H. Raza | The Blue Moon | Acrylic on canvas, 2007 | 39.4 x 31.5 in (100 x 80 cm)",
    "Second ImageNumber (Internal for A-Graphy)": "",
    "Image Code(s)2": "",
    "text_2": "",
    "Image Caption2": "",
    "Text3": "",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "2007 – 2016",
    "title_of_time_period": "10_Last Years of Exploration",
    "sortable_date": 2008,
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "Image93",
    "image1": "0093-s-h-raza-IT.jpg",
    "text_1": "",
    "caption_1": "S.H. Raza | Satyam Shivam Sundaram | Acrylic on canvas, 2008 | 39.4 x 31.5 in (100 x 80 cm)",
    "Second ImageNumber (Internal for A-Graphy)": "",
    "Image Code(s)2": "",
    "text_2": "",
    "Image Caption2": "",
    "Text3": "",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "2007 – 2016",
    "title_of_time_period": "10_Last Years of Exploration",
    "sortable_date": 2009,
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "Image94",
    "image1": "0094-s-h-raza-IT.jpg",
    "text_1": "",
    "caption_1": "S.H. Raza | Tapovan | Acrylic on canvas, 2009 | 39.4 x 47.2 in (100 x 120 cm)",
    "Second ImageNumber (Internal for A-Graphy)": "",
    "Image Code(s)2": "",
    "text_2": "",
    "Image Caption2": "",
    "Text3": "",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "2007 – 2016",
    "title_of_time_period": "10_Last Years of Exploration",
    "sortable_date": 2010,
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "Image95",
    "image1": "0095-s-h-raza-IT.jpg",
    "text_1": "",
    "caption_1": "S.H. Raza | Nirvan | Acrylic on canvas, 2010 | 39.4 x 39.4 in (100 x 100 cm)",
    "Second ImageNumber (Internal for A-Graphy)": "",
    "Image Code(s)2": "",
    "text_2": "",
    "Image Caption2": "",
    "Text3": "",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "2007 – 2016",
    "title_of_time_period": "10_Last Years of Exploration",
    "sortable_date": 2011,
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "Image96",
    "image1": "0096-s-h-raza-IT.jpg",
    "text_1": "",
    "caption_1": "S.H. Raza | Surya Namaskar | Acrylic on canvas, 2013 | 59.1 x 47.2 in (150 x 120 cm)",
    "Second ImageNumber (Internal for A-Graphy)": "",
    "Image Code(s)2": "",
    "text_2": "",
    "Image Caption2": "",
    "Text3": "",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "2007 – 2016",
    "title_of_time_period": "10_Last Years of Exploration",
    "sortable_date": 2012,
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "Image97",
    "image1": "0097-s-h-raza-IT.jpg",
    "text_1": "",
    "caption_1": "S.H. Raza | Samavesh | Acrylic on canvas, 2012 | 47.2 x 47.2 in (120 x 120 cm)",
    "Second ImageNumber (Internal for A-Graphy)": "",
    "Image Code(s)2": "",
    "text_2": "",
    "Image Caption2": "",
    "Text3": "",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "2007 – 2016",
    "title_of_time_period": "10_Last Years of Exploration",
    "sortable_date": 2013,
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "Image98",
    "image1": "0098-s-h-raza-IT.jpg",
    "text_1": "",
    "caption_1": "S.H. Raza | Mahabharat | Acrylic on canvas, 2013 | 59.1 x 47.2 in (150 x 120 cm)",
    "Second ImageNumber (Internal for A-Graphy)": "",
    "Image Code(s)2": "",
    "text_2": "",
    "Image Caption2": "",
    "Text3": "",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "2007 – 2016",
    "title_of_time_period": "10_Last Years of Exploration",
    "sortable_date": 2014,
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "Image99",
    "image1": "0099-s-h-raza-IT.jpg",
    "text_1": "",
    "caption_1": "S.H. Raza | Samavesh | Acrylic on canvas, 2014 | 47.2 x 47.2 in (120 x 120 cm)",
    "Second ImageNumber (Internal for A-Graphy)": "",
    "Image Code(s)2": "",
    "text_2": "",
    "Image Caption2": "",
    "Text3": "",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "2007 – 2016",
    "title_of_time_period": "10_Last Years of Exploration",
    "sortable_date": 2015,
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "Image100",
    "image1": "0100-s-h-raza-IT.jpg",
    "text_1": "",
    "caption_1": "S.H. Raza | Udrishya | Acrylic on canvas, 2015 | 39.4 x 39.4 in (100 x 100 cm)",
    "Second ImageNumber (Internal for A-Graphy)": "",
    "Image Code(s)2": "",
    "text_2": "",
    "Image Caption2": "",
    "Text3": "",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "2007 – 2016",
    "title_of_time_period": "10_Last Years of Exploration",
    "sortable_date": 2016,
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "Image101",
    "image1": "0101-s-h-raza-IT.jpg",
    "text_1": "“Raza continued to paint from his wheelchair. He also carried on with the practice of uttering some poetic lines before beginning his work. It was usually a verse from Rilke,… It was something like ‘Listen to that voice buried in silence’ – we have it somewhere… It is the artist’s prerogative to subvert linear time and the compartmentalization of history and to recreate a universe of connectivity and seamless humanity… (He) was an artist who felt that his role was to make the unknown rise from its submerged depths of consciousness. An artist who never stopped chasing his dreams…”Yashodhara Dalmia, rpt. In Syed Haider Raza : The Journey of an Iconic Artist. New Delhi: HarperCollins India 2021 I  pp.218-220.",
    "caption_1": "S.H. Raza | Brahmaand | Acrylic on canvas, 2016 | 39.4 x 78.7 in (100 x 200 cm)",
    "Second ImageNumber (Internal for A-Graphy)": "",
    "Image Code(s)2": "",
    "text_2": "",
    "Image Caption2": "",
    "Text3": "",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "1987 – 2023",
    "title_of_time_period": "11_A growing art market & increased academic interest",
    "sortable_date": "1987-2023",
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "Image102",
    "image1": "0102-s-h-raza-IT.jpg",
    "text_1": "The first public auction for Contemporary Indian art was organized as a Charity auction by Christie’s and HelpAge India on 20th Dec. 1987 at the Taj Mahal Hotel, Bombay. ‘Sourya’ (1986, Lot 26) represented S.H. Raza, a painting which was displayed at the Salon Mai the year previously. Thereafter, Raza’s auction prices kept on rising, breaking his previous highs regularly in 1995 (with Bindu, 1982), 1997 (Paysage, 1979), 2000 (Terra Amata, 1984), 2004 (Rajputana, 2003), 2006 (Tapovan, 1972), 2008 (La Terre, 1973), 2010 (Saurasthra, 1983) and finally in 2018 when Tapovan was resold for US$4.45 Million at New York by Christie’s.",
    "caption_1": "S.H. Raza | Sourya | Acrylic on canvas, 1986 | 79.9 x 74.8 in (203 x 190 cm)",
    "Second ImageNumber (Internal for A-Graphy)": "Image103",
    "Image Code(s)2": "0103-s-h-raza-IT.jpg",
    "text_2": "Simultaneously with this respect from the art market came greater public awareness for his art and hence an increase in related academic and scholarly work.  Major publications by Ashok Vajpayi (2007) Alain Bonfand (2008) I  Mandalas (2005) was translated from French to English in 2009 and the first Volume of the Catalogue Raisonne 1958-71 was published in 2016 by Vadehra Gallery, with Raza’s first biography by Yashosdhara Dalmia in 2021.",
    "Image Caption2": "S.H. Raza | Bindu | Oil on canvas, 1982 | 31.3 x 31.3 in (79.4 x 79.4 cm)",
    "Text3": "",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "1987 – 2023",
    "title_of_time_period": "11_A growing art market & increased academic interest",
    "sortable_date": "1987-2023",
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "Image104",
    "image1": "0104-s-h-raza-IT.jpg",
    "text_1": "",
    "caption_1": "S.H. Raza | Paysage | Oil on canvas, 1979 | 31.9 x 66.5 in (81 x 168.9 cm)",
    "Second ImageNumber (Internal for A-Graphy)": "Image105",
    "Image Code(s)2": "0105-s-h-raza-IT.jpg",
    "text_2": "",
    "Image Caption2": "S.H. Raza | Terra Amata | Acrylic on canvas, 1984 | 69 x 59.1 in (175.3 x 150.2 cm)",
    "Text3": "",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "1987 – 2023",
    "title_of_time_period": "11_A growing art market & increased academic interest",
    "sortable_date": "1987-2023",
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "Image106",
    "image1": "0106-s-h-raza-IT.jpg",
    "text_1": "",
    "caption_1": "S.H. Raza | Rajputana | Acrylic on canvas, 2003 | 59.8 x 59.8 in (152 x 152 cm)",
    "Second ImageNumber (Internal for A-Graphy)": "Image107",
    "Image Code(s)2": "0107-s-h-raza-IT.jpg",
    "text_2": "",
    "Image Caption2": "S.H. Raza | Tapovan | Acrylic on canvas, 1972 | 62.5 x 74 in (158.8 x 188 cm)",
    "Text3": "",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "1987 – 2023",
    "title_of_time_period": "11_A growing art market & increased academic interest",
    "sortable_date": "1987-2023",
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "Image108",
    "image1": "0108-s-h-raza-IT.jpg",
    "text_1": "",
    "caption_1": "S.H. Raza | Life in Art: S.H.Raza | Ashok Vajpeyi (Written and Edited by)\nPublisher Art Alive Gallery, New Delhi I  2007\npp. 359 I  Hardback (ISBN 9788190184441), 2007 | 0 x 0 in ( x  cm)",
    "Second ImageNumber (Internal for A-Graphy)": "Image109",
    "Image Code(s)2": "0109-s-h-raza-IT.jpg",
    "text_2": "",
    "Image Caption2": "Germain-Thomas, Olivier. Sayed Haider Raza : Mandalas. New Delhi: ArtAlive Gallery 2009 [Raza Focus Book, 38a]",
    "Text3": "",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "1987 – 2023",
    "title_of_time_period": "11_A growing art market & increased academic interest",
    "sortable_date": "1987-2023",
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "Image110",
    "image1": "0110-s-h-raza-IT.jpg",
    "text_1": "",
    "caption_1": "S.H. Raza | La Terre | Oil on canvas, 1973 | 74.4 x 74.4 in (189 x 189 cm)",
    "Second ImageNumber (Internal for A-Graphy)": "Image111",
    "Image Code(s)2": "0111-s-h-raza-IT.jpg",
    "text_2": "",
    "Image Caption2": "S.H. Raza | Paysage | Oil on canvas, 1983 | 47 x 47 in (119.4 x 119.4 cm)",
    "Text3": "",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "1987 – 2023",
    "title_of_time_period": "11_A growing art market & increased academic interest",
    "sortable_date": "1987-2023",
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "Image112",
    "image1": "0112-s-h-raza-IT.jpg",
    "text_1": "",
    "caption_1": "S.H. Raza | Tapovan | Acrylic on canvas, 1972 | 62.5 x 74 in (158.8 x 188 cm)",
    "Second ImageNumber (Internal for A-Graphy)": "Image113",
    "Image Code(s)2": "0113-s-h-raza-IT.jpg",
    "text_2": "",
    "Image Caption2": "Macklin, Anne. SH Raza : Catalogue Raisonné 1958-1971 (Volume 1). New Delhi: Vadehra Art Gallery 2016 [Raza Focus Book I  Catalogue Raisonne 1958-1971, 54]",
    "Text3": "",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "1987 – 2023",
    "title_of_time_period": "11_A growing art market & increased academic interest",
    "sortable_date": "1987-2023",
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "Image114",
    "image1": "0114-s-h-raza-IT.jpg",
    "text_1": "",
    "caption_1": "Sen, Dr. Geeti. Bindu: Space and Time in Raza’s Vision. Ahmedabad: Mapin Publishing Pvt. Ltd. In association with The Raza Foundation 2020 [Raza Focus Book (Revised), 60]",
    "Second ImageNumber (Internal for A-Graphy)": "Image115",
    "Image Code(s)2": "0115-s-h-raza-IT.jpg",
    "text_2": "",
    "Image Caption2": "Dalmia, Yashodhara. Sayed Haider Raza: The Journey of an Iconic Artist. New Delhi: HarperCollins India 2021 [Raza Focus Book, 61]",
    "Text3": "",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  }
]
const songsBookletData=[
  {
    "Sr_No": "",
    "time_period": "1930 – 1939",
    "title_of_time_period": "01_Early Years",
    "sortable_date": "1931",
    "image1": imgsongbooklet_1931,
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "n/a",
    "text_1": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    "caption_1": "Daulat Ka Nasha | 1931 | BOOK.ssb",
    "Second ImageNumber (Internal for A-Graphy)": "",
    "Image Code(s)2": "",
    "text_2": "",
    "Image Caption2": "",
    "Text3": "",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "1930 – 1939",
    "title_of_time_period": "01_Early Years",
    "sortable_date": "1932",
    "image1": imgsongbooklet_1932,
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "n/a",
    "text_1": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    "caption_1": "Ayodhaya Ka Raja | 1932 | BOOK.ssb",
    "Second ImageNumber (Internal for A-Graphy)": "",
    "Image Code(s)2": "",
    "text_2": "",
    "Image Caption2": "",
    "Text3": "",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "1930 – 1939",
    "title_of_time_period": "01_Early Years",
    "sortable_date": "1934",
    "image1": imgsongbooklet_1934a,
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "n/a",
    "text_1": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    "caption_1": "Indira M. A. | 1934 | BOOK.ssb",
    "Second ImageNumber (Internal for A-Graphy)": "",
    "Image Code(s)2": "",
    "text_2": "",
    "Image Caption2": "",
    "Text3": "",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "1930 – 1939",
    "title_of_time_period": "01_Early Years",
    "sortable_date": "1934",
    "image1": imgsongbooklet_1934b,
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "n/a",
    "text_1": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    "caption_1": "Majdoor | 1934 | BOOK.ssb",
    "Second ImageNumber (Internal for A-Graphy)": "",
    "Image Code(s)2": "",
    "text_2": "",
    "Image Caption2": "",
    "Text3": "",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "1930 – 1939",
    "title_of_time_period": "01_Early Years",
    "sortable_date": "1935",
    "image1": imgsongbooklet_1935a,
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "n/a",
    "text_1": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    "caption_1": "Indira M. A. | 1934 | BOOK.ssb",
    "Second ImageNumber (Internal for A-Graphy)": "",
    "Image Code(s)2": "",
    "text_2": "",
    "Image Caption2": "",
    "Text3": "",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "1930 – 1939",
    "title_of_time_period": "01_Early Years",
    "sortable_date": "1935",
    "image1": imgsongbooklet_1935b,
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "n/a",
    "text_1": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    "caption_1": "Majdoor | 1934 | BOOK.ssb",
    "Second ImageNumber (Internal for A-Graphy)": "",
    "Image Code(s)2": "",
    "text_2": "",
    "Image Caption2": "",
    "Text3": "",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "1930 – 1939",
    "title_of_time_period": "01_Early Years",
    "sortable_date": "1936",
    "image1": imgsongbooklet_1936,
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "n/a",
    "text_1": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    "caption_1": "Madam Fashion | 1936 | BOOK.ssb",
    "Second ImageNumber (Internal for A-Graphy)": "",
    "Image Code(s)2": "",
    "text_2": "",
    "Image Caption2": "",
    "Text3": "",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "1930 – 1939",
    "title_of_time_period": "01_Early Years",
    "sortable_date": "1937",
    "image1": imgsongbooklet_1937,
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "n/a",
    "text_1": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    "caption_1": "Gangavataran | 1937 | BOOK.ssb",
    "Second ImageNumber (Internal for A-Graphy)": "",
    "Image Code(s)2": "",
    "text_2": "",
    "Image Caption2": "",
    "Text3": "",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },{
    "Sr_No": "",
    "time_period": "1930 – 1939",
    "title_of_time_period": "01_Early Years",
    "sortable_date": "1939",
    "image1": imgsongbooklet_1939,
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "n/a",
    "text_1": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    "caption_1": "Swastik | 1939 | BOOK.ssb",
    "Second ImageNumber (Internal for A-Graphy)": "",
    "Image Code(s)2": "",
    "text_2": "",
    "Image Caption2": "",
    "Text3": "",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "1940 – 1949",
    "title_of_time_period": "02_",
    "sortable_date": "1940",
    "image1": imgsongbooklet_1940a,
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "n/a",
    "text_1": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    "caption_1": "Achhut | 1940 | BOOK.ssb",
    "Second ImageNumber (Internal for A-Graphy)": "",
    "Image Code(s)2": "",
    "text_2": "",
    "Image Caption2": "",
    "Text3": "",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "1940 – 1949",
    "title_of_time_period": "02_",
    "sortable_date": "1940",
    "image1": imgsongbooklet_1940b,
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "n/a",
    "text_1": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    "caption_1": "Main Haari | 1940 | BOOK.ssb",
    "Second ImageNumber (Internal for A-Graphy)": "",
    "Image Code(s)2": "",
    "text_2": "",
    "Image Caption2": "",
    "Text3": "",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "1940 – 1949",
    "title_of_time_period": "02_",
    "sortable_date": "1941",
    "image1": imgsongbooklet_1941a,
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "n/a",
    "text_1": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    "caption_1": "Jhoola | 1941 | BOOK.ssb",
    "Second ImageNumber (Internal for A-Graphy)": "",
    "Image Code(s)2": "",
    "text_2": "",
    "Image Caption2": "",
    "Text3": "",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "1940 – 1949",
    "title_of_time_period": "02_",
    "sortable_date": "1941",
    "image1": imgsongbooklet_1941b,
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "n/a",
    "text_1": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    "caption_1": "Raj Nartaki | 1941 | BOOK.ssb",
    "Second ImageNumber (Internal for A-Graphy)": "",
    "Image Code(s)2": "",
    "text_2": "",
    "Image Caption2": "",
    "Text3": "",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "1940 – 1949",
    "title_of_time_period": "02_",
    "sortable_date": "1941",
    "image1": imgsongbooklet_1941c,
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "n/a",
    "text_1": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    "caption_1": "Sikandar | 1941 | BOOK.ssb",
    "Second ImageNumber (Internal for A-Graphy)": "",
    "Image Code(s)2": "",
    "text_2": "",
    "Image Caption2": "",
    "Text3": "",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "1940 – 1949",
    "title_of_time_period": "02_",
    "sortable_date": "1941",
    "image1": imgsongbooklet_1941d,
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "n/a",
    "text_1": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    "caption_1": "Padosi | 1941 | BOOK.ssb",
    "Second ImageNumber (Internal for A-Graphy)": "",
    "Image Code(s)2": "",
    "text_2": "",
    "Image Caption2": "",
    "Text3": "",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "1940 – 1949",
    "title_of_time_period": "02_",
    "sortable_date": "1943",
    "image1": imgsongbooklet_1943,
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "n/a",
    "text_1": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    "caption_1": "Turab | H P Mitra, Adab Arz | 1943_BOOK.ssb |",
    "Second ImageNumber (Internal for A-Graphy)": "",
    "Image Code(s)2": "",
    "text_2": "",
    "Image Caption2": "",
    "Text3": "",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "1940 – 1949",
    "title_of_time_period": "02_",
    "sortable_date": "1948",
    "image1": imgsongbooklet_1948a,
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "n/a",
    "text_1": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    "caption_1": "Anjangarh | 1948 | BOOK.ssb",
    "Second ImageNumber (Internal for A-Graphy)": "",
    "Image Code(s)2": "",
    "text_2": "",
    "Image Caption2": "",
    "Text3": "",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "1940 – 1949",
    "title_of_time_period": "02_",
    "sortable_date": "1948",
    "image1": imgsongbooklet_1948b,
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "n/a",
    "text_1": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    "caption_1": "Gopinath | 1948 | BOOK.ssb",
    "Second ImageNumber (Internal for A-Graphy)": "",
    "Image Code(s)2": "",
    "text_2": "",
    "Image Caption2": "",
    "Text3": "",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "1940 – 1949",
    "title_of_time_period": "02_",
    "sortable_date": "1948",
    "image1": imgsongbooklet_1948c,
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "n/a",
    "text_1": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    "caption_1": "Kalpana | 1948 | BOOK.ssb",
    "Second ImageNumber (Internal for A-Graphy)": "",
    "Image Code(s)2": "",
    "text_2": "",
    "Image Caption2": "",
    "Text3": "",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "1940 – 1949",
    "title_of_time_period": "02_",
    "sortable_date": "1949",
    "image1": imgsongbooklet_1949a,
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "n/a",
    "text_1": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    "caption_1": "Barsaat | 1949 | BOOK.ssb",
    "Second ImageNumber (Internal for A-Graphy)": "",
    "Image Code(s)2": "",
    "text_2": "",
    "Image Caption2": "",
    "Text3": "",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "1940 – 1949",
    "title_of_time_period": "02_",
    "sortable_date": "1949",
    "image1": imgsongbooklet_1949b,
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "n/a",
    "text_1": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    "caption_1": "Sunhere Din | 1949 | BOOK.ssb",
    "Second ImageNumber (Internal for A-Graphy)": "",
    "Image Code(s)2": "",
    "text_2": "",
    "Image Caption2": "",
    "Text3": "",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "1950 – 1959",
    "title_of_time_period": "03_",
    "sortable_date": "1950",
    "image1": placeholder,
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "n/a",
    "text_1": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    "caption_1": "Content Awaited",
    "Second ImageNumber (Internal for A-Graphy)": "",
    "Image Code(s)2": "",
    "text_2": "",
    "Image Caption2": "",
    "Text3": "",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "1960 – 1969",
    "title_of_time_period": "04_",
    "sortable_date": "1960",
    "image1": placeholder,
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "n/a",
    "text_1": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    "caption_1": "Content Awaited",
    "Second ImageNumber (Internal for A-Graphy)": "",
    "Image Code(s)2": "",
    "text_2": "",
    "Image Caption2": "",
    "Text3": "",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "1970 – 1979",
    "title_of_time_period": "05_",
    "sortable_date": "1970",
    "image1": placeholder,
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "n/a",
    "text_1": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    "caption_1": "Content Awaited",
    "Second ImageNumber (Internal for A-Graphy)": "",
    "Image Code(s)2": "",
    "text_2": "",
    "Image Caption2": "",
    "Text3": "",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "1980 – 1989",
    "title_of_time_period": "06_",
    "sortable_date": "1980",
    "image1": placeholder,
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "n/a",
    "text_1": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    "caption_1": "Content Awaited",
    "Second ImageNumber (Internal for A-Graphy)": "",
    "Image Code(s)2": "",
    "text_2": "",
    "Image Caption2": "",
    "Text3": "",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "1990 – 1999",
    "title_of_time_period": "07_",
    "sortable_date": "1990",
    "image1": placeholder,
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "n/a",
    "text_1": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    "caption_1": "Content Awaited",
    "Second ImageNumber (Internal for A-Graphy)": "",
    "Image Code(s)2": "",
    "text_2": "",
    "Image Caption2": "",
    "Text3": "",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "2000 – 2010",
    "title_of_time_period": "08_",
    "sortable_date": "2000",
    "image1": placeholder,
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "n/a",
    "text_1": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    "caption_1": "Content Awaited",
    "Second ImageNumber (Internal for A-Graphy)": "",
    "Image Code(s)2": "",
    "text_2": "",
    "Image Caption2": "",
    "Text3": "",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "1976 – 1980",
    "title_of_time_period": "09_",
    "sortable_date": "1976",
    "image1": placeholder,
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "n/a",
    "text_1": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    "caption_1": "Content Awaited",
    "Second ImageNumber (Internal for A-Graphy)": "",
    "Image Code(s)2": "",
    "text_2": "",
    "Image Caption2": "",
    "Text3": "",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "1980 – 1981",
    "title_of_time_period": "10_",
    "sortable_date": "1980",
    "image1": placeholder,
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "n/a",
    "text_1": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    "caption_1": "Content Awaited",
    "Second ImageNumber (Internal for A-Graphy)": "",
    "Image Code(s)2": "",
    "text_2": "",
    "Image Caption2": "",
    "Text3": "",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
]

const kg_subramanyamData=[
  {
    "Sr_No": "",
    "time_period": "1924 – 1944",
    "title_of_time_period": "01_Early Years",
    "sortable_date": "1944",
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "Image1",
    "image1": kgimg1944,
    "fullImage":kgfullimg1944,
    "text_1": "1944- Nandalal Bose calls him to Santiniketan on hhis brother's request. Enters Kala Bhavana as a student. Participates in the informal academiic community which stresses personal initiative and independence. The contact with Nandalal is constant; Binode Behari Mukherjee and Ram Kinkar Baij become close personal friends.",
    "caption_1": "Mural Wall at Kala Bhawan | Shantiniketan | 1990",
    "Second ImageNumber (Internal for A-Graphy)": "",
    "Image Code(s)2": "",
    "text_2": "At a later date, in a lecture, he remarked Artists like Nandalal Bose, Benode Behari Mukherjee, Ramkinker Baij, pushed, by their integrity and dedication, towards basic solutions. They were not strictly part of the situation surrounded by it; and they are, in a sense, the anchorites of the modern Indian art scene. One has only to remember that they never sought their public or came to terms with them; all their exhibitons were sponsored by their admirers and not by themselves. The work of these three artists, however different from each other, was in the nature of a search for the terms of a new art; the cards and sketch books of Nandalal Bose are a veritable thesaurus of visual enquiry; the works of Benode Behari Mukherjee and Ramkinker Baij foreshadow various developments in later Indian painting and sculpture, although this is hardly ever acknowledged. They all worked in terms of an integral solution. ('Modern Art in India and the West', Universty Extension lectures, Vishwa Bharati Circle, Shantiniketan, Nov. 1970, publ. March 1972, Dept. History of Art, Kala Bhavan, Vishwa Bharati University.",
    "Image Caption2": "",
    "Text3": "In 1945 he helps put up the first Delhi exhibition of the work of Benode Behari and Ram Kinkar. Further readings on Coomaraswamy and E.B. Havell further shape his idea of the art-craft contnuum.",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "1944 – 1951",
    "title_of_time_period": "02_Shantiniketan Years",
    "sortable_date": "1947",
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "Image1",
    "image1": kgimg1947,
    "fullImage":kgfullimg1947,
    "text_1": "1947- Assists Binode Behari on the Hiindi Bhavana mural at Santiniketa",
    "caption_1": "K. G. Subramanyan and Ramkinkar Baij | Shantiniketan | c.1947",
    "Second ImageNumber (Internal for A-Graphy)": "",
    "Image Code(s)2": "",
    "text_2": "Upon completing his diploma in 1947 , stays at Shantiniketan for 6 months, works at a rehablitation centre for post-partition refugees, joins a school as an art teacher but quits it soon due to the strict regimentation and discipline imposed on students.",
    "Image Caption2": "",
    "Text3": "Joins the Indian Cooperative UnionDoes odd professional jobs in 1948 such as designing shop window, jewellery, toys etc. Also imparts vocational training to partition refugees.",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "1944 – 1951",
    "title_of_time_period": "02_Shantiniketan Years",
    "sortable_date": "1949",
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "Image1",
    "image1": kgimg1949,
    "fullImage":kgfullimg1949,
    "text_1": "A group of oil paintings done in 1949 that resemble the expressionistic paintings of Ramkinker Baij",
    "caption_1": "K. G. Subramanyan | Woman at Tap | Oil on canvas mounted on board, 1949 | 62x91.5cm",
    "Second ImageNumber (Internal for A-Graphy)": "",
    "Image Code(s)2": "",
    "text_2": "He remarks, about his early works that 'I was of a generation that was not satisfied with either academic realism or romantic narrative…we were seeking a kind of poetry of the real. . ",
    "Image Caption2": "",
    "Text3": "We had to have a motif…but we wanted to take them beyond the documentary into something iconic, You can call it apotheosis of the ordinary. (Extracted-From the artist's interview in the catalogue of his retrospectiive exhibition at the Birla Academy of Art and Culture, Calcutta 1983)",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "1944 – 1951",
    "title_of_time_period": "02_Shantiniketan Years",
    "sortable_date": "1950",
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "Image1",
    "image1": kgimg1950,
    "text_1": "1950- Marries Susheela Jasra, artist and social worker. Moves to Delhi. Supports himself with doing copes of old paintings, posters, cinema hoardings. Gets to know artists of the Silpi Chakra group. Starts paintings in oils.",
    "caption_1": "Subramanyan with his wife Susheela and daughter  Uma, (1950s)",
    "Second ImageNumber (Internal for A-Graphy)": "",
    "Image Code(s)2": "",
    "text_2": "",
    "Image Caption2": "",
    "Text3": "",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "1944 – 1951",
    "title_of_time_period": "02_Shantiniketan Years",
    "sortable_date": "1951",
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "Image1",
    "image1": kgimg1951,
    "text_1": "1951: Joins the Faculty of Fine Arts, M.S. University, Baroda as a junior colleague in the close-knit team alongside Markand Bhatt, Sankho Chaudhuri, and N.S. Bendre.",
    "caption_1": "K. G. Subramanyan | Woman with Lamp II | Oil on Canvas, 1951 | (91.5x67cm)",
    "Second ImageNumber (Internal for A-Graphy)": "",
    "Image Code(s)2": "",
    "text_2": "Woman with Lamp marks Subramanyan's tryst with the western modernism. The forms, contours and colours are hark back to Matisse's practice of simplifying form and flattening of image plain. The multiple perspectives and dissections of figures refer to Picasso's cubist practices.",
    "Image Caption2": "",
    "Text3": "",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "1944 – 1951",
    "title_of_time_period": "03_Move to Baroda",
    "sortable_date": "1951",
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "Image1",
    "image1": kgimg1951,
    "text_1": "1951: Joins the Faculty of Fine Arts, M.S. University, Baroda as a junior colleague in the close-knit team alongside Markand Bhatt, Sankho Chaudhuri, and N.S. Bendre.",
    "caption_1": "K. G. Subramanyan | Woman with Lamp II | Oil on Canvas, 1951 | (91.5x67cm)",
    "Second ImageNumber (Internal for A-Graphy)": "",
    "Image Code(s)2": "",
    "text_2": "Woman with Lamp marks Subramanyan's tryst with the western modernism. The forms, contours and colours are hark back to Matisse's practice of simplifying form and flattening of image plain. The multiple perspectives and dissections of figures refer to Picasso's cubist practices.",
    "Image Caption2": "",
    "Text3": "",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "1951 – 1955",
    "title_of_time_period": "03_Move to Baroda",
    "sortable_date": "1952",
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "Image1",
    "image1": kgimg1952,
    "text_1": "1949-53-Executes small watercolour paintings of figures and landscapes on sketchpads in a 'post-Cezannian' manner and upon rendering them into large scale oil painting, resemble a post-cubist style",
    "caption_1": "K. G. Subramanyan | Girl with Sunflower | Watercolour on paper, 1952 | (29 x 23.5 cm)",
    "Second ImageNumber (Internal for A-Graphy)": "",
    "Image Code(s)2": "",
    "text_2": "",
    "Image Caption2": "",
    "Text3": "",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "1951 – 1955",
    "title_of_time_period": "03_Move to Baroda",
    "sortable_date": "1953",
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "Image1",
    "image1": kgimg1953,
    "text_1": "Subramanyan's interest in the neutrality of subject comes close to the cubists. Though apparently indifferent to subject matter, the cubists make their man with pipe, woman with mandolin pictures, at once personal and emblematic. There is an inclusion of souvenirs which refer the viewer, through a personal code, to a bohemian sense of community still cherished by the cubists. The structuring principle, however, points towards a kind of futurist manifesto about the changing perception of reality. Or rather, changing perception as such, wherein art and reality are telescoped and the picture surface seen as shifting interface between the two. The cubists are interested in the technques of fabricating this elusive interface: so is Subramanyan. (Extracted from Geeta Kapur, pg. 22)",
    "caption_1": "K. G. Subramanyan |Mother and Child|Oil on board,1953 | (90 x 60cm)",
    "Second ImageNumber (Internal for A-Graphy)": "",
    "Image Code(s)2": "",
    "text_2": "",
    "Image Caption2": "",
    "Text3": "",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "1951 – 1955",
    "title_of_time_period": "03_Move to Baroda",
    "sortable_date": "1954",
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "Image1",
    "image1": kgimg1954,
    "text_1": "1953-55- A third group of paintings revolve around a single motif of a bird, animal or flowering plant painted against background of a single colour",
    "caption_1": "K. G. Subramanyan | | Bird | Oil on Board| 1954 | 59.5x43.5 cm",
    "Second ImageNumber (Internal for A-Graphy)": "",
    "Image Code(s)2": "",
    "text_2": "",
    "Image Caption2": "",
    "Text3": "",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "1955 – 1958",
    "title_of_time_period": "03_Move to Baroda",
    "sortable_date": "1955",
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "Image1",
    "image1": placeholder,
    "text_1": "Caption Awaited",
    "caption_1": "Caption Awaited",
    "Second ImageNumber (Internal for A-Graphy)": "",
    "Image Code(s)2": "",
    "text_2": "",
    "Image Caption2": "",
    "Text3": "",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "1959-64",
    "title_of_time_period": "03_Move to Baroda",
    "sortable_date": "1959",
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "Image1",
    "image1": placeholder,
    "text_1": "Caption Awaited",
    "caption_1": "Caption Awaited",
    "Second ImageNumber (Internal for A-Graphy)": "",
    "Image Code(s)2": "",
    "text_2": "",
    "Image Caption2": "",
    "Text3": "",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "1964-69",
    "title_of_time_period": "03_Move to Baroda",
    "sortable_date": "1964",
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "Image1",
    "image1": placeholder,
    "text_1": "Caption Awaited",
    "caption_1": "Caption Awaited",
    "Second ImageNumber (Internal for A-Graphy)": "",
    "Image Code(s)2": "",
    "text_2": "",
    "Image Caption2": "",
    "Text3": "",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "1969 – 78",
    "title_of_time_period": "03_Move to Baroda",
    "sortable_date": "1969",
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "Image1",
    "image1": placeholder,
    "text_1": "Caption Awaited",
    "caption_1": "Caption Awaited",
    "Second ImageNumber (Internal for A-Graphy)": "",
    "Image Code(s)2": "",
    "text_2": "",
    "Image Caption2": "",
    "Text3": "",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "1978-80",
    "title_of_time_period": "03_Move to Baroda",
    "sortable_date": "1978",
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "Image1",
    "image1": placeholder,
    "text_1": "Caption Awaited",
    "caption_1": "Caption Awaited",
    "Second ImageNumber (Internal for A-Graphy)": "",
    "Image Code(s)2": "",
    "text_2": "",
    "Image Caption2": "",
    "Text3": "",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "1980-87",
    "title_of_time_period": "03_Move to Baroda",
    "sortable_date": "1980",
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "Image1",
    "image1": placeholder,
    "text_1": "Caption Awaited",
    "caption_1": "Caption Awaited",
    "Second ImageNumber (Internal for A-Graphy)": "",
    "Image Code(s)2": "",
    "text_2": "",
    "Image Caption2": "",
    "Text3": "",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "1987-94",
    "title_of_time_period": "03_Move to Baroda",
    "sortable_date": "1987",
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "Image1",
    "image1": placeholder,
    "text_1": "Caption Awaited",
    "caption_1": "Caption Awaited",
    "Second ImageNumber (Internal for A-Graphy)": "",
    "Image Code(s)2": "",
    "text_2": "",
    "Image Caption2": "",
    "Text3": "",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "1994-2003",
    "title_of_time_period": "03_Move to Baroda",
    "sortable_date": "1994",
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "Image1",
    "image1": placeholder,
    "text_1": "Caption Awaited",
    "caption_1": "Caption Awaited",
    "Second ImageNumber (Internal for A-Graphy)": "",
    "Image Code(s)2": "",
    "text_2": "",
    "Image Caption2": "",
    "Text3": "",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
  {
    "Sr_No": "",
    "time_period": "2003-2016",
    "title_of_time_period": "03_Move to Baroda",
    "sortable_date": "2003",
    "Specific Dates (with Day, Month)": "",
    "First ImageNumber (Internal for A-Graphy), incl. Carousel": "Image1",
    "image1": placeholder,
    "text_1": "Caption Awaited",
    "caption_1": "Caption Awaited",
    "Second ImageNumber (Internal for A-Graphy)": "",
    "Image Code(s)2": "",
    "text_2": "",
    "Image Caption2": "",
    "Text3": "",
    "Masterlist1": "",
    "Masterlist2": "",
    "Masterlist3": "",
    "Keywords": "",
    "Research Category1": "",
    "Research Category2": "",
    "Research Category3": ""
  },
]
switch (alias) {
  case "satyajit-ray":
      return satyajitdata;
  case "sh-raza":
      return data;
  case "song-synopsis-booklet":
     return songsBookletData;
  case "k.g-Subramanyan":
    return kg_subramanyamData;
  default:
      // Handle the default case if needed
      return data;
}
}