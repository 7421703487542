import { APIS } from "../endpoints";
import { isTransformationNotAllowed } from "../util/isTranformationAllowed";
import api from "../services/api";

export const getSearchDataAPI = async (query) => {
  try{
    const result = await api.get(`${APIS.BASE_URL}search?search=${query}`)
  // const result = await api.get(`${APIS.BASE_URL}search?search=mar&type=Film`)
  if (result) {
    return result?.data;
  }
  }catch (error) {
    console.error("Error getSearchDataAPI data:", error);
    throw new Error(error)
  }
  
  };
  
  export const getSearchData = async (query) => {
    try{

    if (isTransformationNotAllowed() === true) {
      const data = await getSearchDummyData();
      return data;
    } else{
      const data = await getSearchDataAPI(query);
      return data;
    }
          
  }catch(error){
    throw new Error(error)
  }
  };
  
  const getSearchDummyData = (type) => {
    return [];
  };



  export const getSearchByTypeDataAPI = async (query,type,filterOptions,page) => {
    const formData = new FormData();
    formData.append('filters', JSON.stringify(filterOptions));
    formData.append('page_number', page);
    const params = new URLSearchParams(formData);
    const result = await api.get(`${APIS.BASE_URL}search?search=${query}&type=${type}`,{
      params: params
  })
    if (result) {
      return result?.data;
    }
    };
    
    export const getSearchByTypeData = async (query,type,filterOptions,page) => {
      if (isTransformationNotAllowed() === true) {
        const data = await getSearchByTypeDummyData();
        return data;
      } else{
        const data = await getSearchByTypeDataAPI(query,type,filterOptions,page);
        return data;
      }
    };
    
    const getSearchByTypeDummyData = (query,type) => {
      return [];
    };

    export const getSuggestionData = async (query) => {
      if (isTransformationNotAllowed() === true) {
        const data = await getSuggestionDataDummyData();
        return data;
      } else{
        const data = await getSuggestionDataDataAPI(query);
        return data;
      }
    };
    const getSuggestionDataDummyData = (query,type) => {
      return [];
    };
    export const getSuggestionDataDataAPI = async (query) => {
      const result = await api.get(`${APIS.BASE_URL}${APIS.searchAutosuggest}=${query}`)
      if (result) {
        return result?.data;
      }
      };



// Search Research Centre

//All
export const searchResearchAllTabAPI = async (
  searchTerm,
  filterOptions,
  page,
) => {
  const formData = new FormData();
  formData.append('filters', JSON.stringify(filterOptions));
  formData.append('page_number', page);
  formData.append('search', searchTerm);

  const params = new URLSearchParams(formData);

  const result = await api.get(`${APIS.BASE_URL}search/research-centre/all`, {
    params: params
});
  if (result?.data) {
    return result?.data;
  } else {
    return [];
  }
};

// This function return Personality search Research centre All Tab data
export const searchResearchAllTab = async (
  searchTerm,
  filterOptions,
  page,
) => {
  if (isTransformationNotAllowed() === true) {
    try{
      const data = await searchResearchAllTabDummyData();
      return data;
    }catch(e){
        return [];
    }
    
  } else if (isTransformationNotAllowed() === false) {
    const response = await searchResearchAllTabAPI(
      searchTerm,
      filterOptions,
      page,
    );

    try {
      if (response) {

        return response;
      } else {
        console.error("Invalid response data structure:", response);
        return [];
      }
    } catch (e) {
      console.error("Something is wrong", e);
    }
  }
};

export const searchResearchAllTabDummyData = ( searchTerm) => {

  return [];
};


// This function return Personality search Research centre Highlight Tab data
export const searchResearchHighlightTabAPI = async (
  searchTerm,
  filterOptions,
  page,
) => {
  const formData = new FormData();
  formData.append('filters', JSON.stringify(filterOptions));
  formData.append('page_number', page);
  formData.append('search', searchTerm);

  const params = new URLSearchParams(formData);

  const result = await api.get(`${APIS.BASE_URL}search/research-centre/highlight`, {
    params: params
});
  if (result?.data) {
    return result?.data;
  } else {
    return [];
  }
};
export const searchResearchHighlightTab = async (
  searchTerm,
  filterOptions,
  page,
) => {
  if (isTransformationNotAllowed() === true) {
    try{
      const data = await searchResearchHighlightTabDummyData();
      return data;
    }catch(e){
        return [];
    }
    
  } else if (isTransformationNotAllowed() === false) {
    const response = await searchResearchHighlightTabAPI(
      searchTerm,
      filterOptions,
      page,
    );

    try {
      if (response) {

        return response;
      } else {
        console.error("Invalid response data structure:", response);
        return [];
      }
    } catch (e) {
      console.error("Something is wrong", e);
    }
  }
};

export const searchResearchHighlightTabDummyData = ( searchTerm) => {

  return [];
};


//Library
export const searchResearchLibraryTabAPI = async (
  searchTerm,
  filterOptions,
  page,
) => {
  const formData = new FormData();
  formData.append('filters', JSON.stringify(filterOptions));
  formData.append('page_number', page);
  formData.append('search', searchTerm);

  const params = new URLSearchParams(formData);

  const result = await api.get(`${APIS.BASE_URL}search/research-centre/library`, {
    params: params
});
  if (result?.data) {
    return result?.data;
  } else {
    return [];
  }
};

// This function return Personality search Research centre All Tab data
export const searchResearchLibraryTab = async (
  searchTerm,
  filterOptions,
  page,
) => {
  if (isTransformationNotAllowed() === true) {
    try{
      const data = await searchResearchLibraryTabDummyData();
      return data;
    }catch(e){
        return [];
    }
    
  } else if (isTransformationNotAllowed() === false) {
    const response = await searchResearchLibraryTabAPI(
      searchTerm,
      filterOptions,
      page,
    );

    try {
      if (response) {

        return response;
      } else {
        console.error("Invalid response data structure:", response);
        return [];
      }
    } catch (e) {
      console.error("Something is wrong", e);
    }
  }
};

export const searchResearchLibraryTabDummyData = ( searchTerm) => {

  return [];
};


//Archive
export const searchResearchArchiveTabAPI = async (
  searchTerm,
  filterOptions,
  page,
) => {
  const formData = new FormData();
  formData.append('filters', JSON.stringify(filterOptions));
  formData.append('page_number', page);
  formData.append('search', searchTerm);

  const params = new URLSearchParams(formData);

  const result = await api.get(`${APIS.BASE_URL}search/research-centre/archive`, {
    params: params
});
  if (result?.data) {
    return result?.data;
  } else {
    return [];
  }
};

// This function return Personality search Research centre All Tab data
export const searchResearchArchiveTab = async (
  searchTerm,
  filterOptions,
  page,
) => {
  if (isTransformationNotAllowed() === true) {
    try{
      const data = await searchResearchArchiveTabDummyData();
      return data;
    }catch(e){
        return [];
    }
    
  } else if (isTransformationNotAllowed() === false) {
    const response = await searchResearchArchiveTabAPI(
      searchTerm,
      filterOptions,
      page,
    );

    try {
      if (response) {

        return response;
      } else {
        console.error("Invalid response data structure:", response);
        return [];
      }
    } catch (e) {
      console.error("Something is wrong", e);
    }
  }
};

export const searchResearchArchiveTabDummyData = ( searchTerm) => {

  return [];
};