import { Box, HStack, Stack, Text } from "@chakra-ui/react";

//Core files
import React, { useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectFlip, Navigation, Pagination } from "swiper";

// Service files
import * as getExhibitions from "../../../services/PersonArtist";

//Component files
import TimeLine from "../../../FunctionalComponents/TimeLine/TimeLine";
import TimelineWithImage1 from "../../../FunctionalComponents/TimeLine/TimelineWithImage1";

//Language files
import { common } from "../../../constants/constants";
import InfiniteScroll from "react-infinite-scroll-component";
import NoMoreToLoad from "../../../CommonComponents/NoMoreToLoad";
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;
const loaderImg = `${IMAGE_BASE_PATH}/ui/Chevron/loader.gif`

function AwardsHonours({ CopyPasteService, masterlistType, masterlist }) {
  const [swiper, setSwiper] = useState(null);
  const [iconClicked, setIconClicked] = useState(false);
  const [imageData, setImageData] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const initialLoadComplete = useRef(false);

  const showImage = (index) => {
    swiper.slideTo(index);
    setTimeout(() => {
      setIconClicked(!iconClicked);
    }, 500);
  };
  
  const extractedImages = (data) => {
    let extractImg = data?.flatMap((entry) => {
      const awardImage = entry?.find(
        (item) => item.key === "awardImage"
      );
      if (awardImage && awardImage?.value.length > 0) {
        return awardImage?.value?.map((image) => image);
      }
      return [];
    });
    return extractImg;
  };
  const [awardsHonours, setAwardsHonours] = useState([]);

  const getData = async () => {
    const result = await getExhibitions.getAwardHonoursData(
      masterlistType,
      masterlist,
      page
    );

    if (result && result?.data?.length > 0) {
      setAwardsHonours(result?.data);
      setImageData(extractedImages(result?.data));

      if (result?.data?.length >= result?.total) {
        setHasMore(false);
      }

      if (!initialLoadComplete.current) {
        setPage((prevPage) => prevPage + 1);
        initialLoadComplete.current = true;
      }
    }
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, []);

  const fetchMoreData = async () => {
    try {
          const result = await getExhibitions.getAwardHonoursData(
            masterlistType,
            masterlist,
            page
          );
          
          if (result && result?.data) {
            const processedData = result?.data;
            setAwardsHonours((prevData) => [...prevData, ...processedData]);
            setPage(prevPage => prevPage + 1);

            if (awardsHonours.length >= result?.total) {
              setHasMore(false);
            }
          }
        } catch (error) {
            console.error('Error fetching data:', error);
      }
  }

  return (
    <>
      {awardsHonours.length !== 0 ? (
        <Stack py="25px" justifyContent={"space-between"} flexDirection={{base:"column",md:"row"}} height={"100vh"} px="0px">
          <HStack
            flexDirection={"column"}
            className="col-8 pl-0"
            width={"100%"}
            alignItems={"baseline"}
            onCopy={(e) => CopyPasteService(e, `${window.location.href}/1`)}
          >
            <Stack width={"100%"} flexDirection={"column"}>
            <InfiniteScroll
                  dataLength={awardsHonours.length}
                  next={fetchMoreData}
                  hasMore={hasMore}
                  loader={<Box textAlign="center" marginLeft={"50%"}>
                  <Stack marginTop={"20px"}>
                     <img src={loaderImg} width={"100px"} height={"100px"} alt="Loading" />
                  </Stack>
                </Box>}
                  endMessage={<NoMoreToLoad/>}
            >
            <TimeLine
            data={awardsHonours}
            showImage={showImage}
            component={TimelineWithImage1}
          />
            </InfiniteScroll>
            </Stack>
          </HStack>
          <HStack
            px={"0px"}
            className="col-4"
            alignItems={"flex-start"}
            justifyContent={"flex-end"}
            paddingLeft={"50px"}
          >
            <Stack
              className="col-12 mt-2"
              id="allslidersatyajitAward"
              position={"relative"}
            >
              <HStack className={!iconClicked ? "imageZoomOut" : "imageZoomIn"}>
                <Swiper
                  grabCursor={true}
                  navigation={true}
                  modules={[EffectFlip, Navigation, Pagination]}
                  onSwiper={setSwiper}
                >
                  {imageData?.length>0 &&
                    imageData?.map((it) => {
                      return (
                        <SwiperSlide key={it}>
                          <Box
                            height={"330px"}
                            width={"297px"}
                            border={"1px solid #C4C4C4"}
                            justifyContent={"center"}
                            alignItems={"center"}
                            textAlign={"center"}
                            display={"flex"}
                            bg={"#C4C4C4"}
                          >
                            <Text fontSize={"14px"} fontWeight={"700"}>
                              {common.IMAGE_AWAITED}
                            </Text>
                          </Box>
                        </SwiperSlide>
                      );
                    })}
                </Swiper>
              </HStack>
            </Stack>
          </HStack>
        </Stack>
      ) : (
        <Stack alignItems={"center"} height={"100vh"}>
          <Text py={"40"} fontSize={"45px"} fontWeight="700" textAlign="center">
            {common.NO_DATA}
          </Text>
        </Stack>
      )}
    </>
  );
}

export default AwardsHonours;
