import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Input,
  Stack,
  Text,
  Checkbox,
  InputRightElement,
  InputGroup,
} from "@chakra-ui/react";
import { IoSearchSharp } from "react-icons/io5";
import H2 from "../../Theme/Typography/H2";
import H3 from "../../Theme/Typography/H3";

export default function CustomDrawer({
  masterlistData,
  isOpen,
  onClose,
  selectedValues,
  setSelectedMasterlists,
  rcType,
  setSelectedCount,
  setFinalMasterlists,
  finalMasterlists,
}) {
  const [selectedMasterlistsState, setSelectedMasterlistsState] = useState({
    masterlist: [],
  });

  const drawerData = masterlistData.reduce((acc, item) => {
    const nameObj = item.find((obj) => obj.key === "name");
    const aliasObj = item.find((obj) => obj.key === "alias");
    if (nameObj) {
      const name = nameObj?.value;
      const initial = name.charAt(0).toUpperCase();
      const category = acc.find((cat) => cat.category === initial);
      if (category) {
        category.items.push({
          name: name,
          alias: aliasObj?.value + ":" + name,
        });
      } else {
        acc.push({
          category: initial,
          items: [{ name: name, alias: aliasObj?.value + ":" + name }],
        });
      }
    }
    return acc;
  }, []);

  drawerData.sort((a, b) => a.category.localeCompare(b.category));
  const [results, setResults] = useState(drawerData);
  const [query, setQuery] = useState('');
  
  const handleSelectedMasterlists = () => {
    if (rcType === "Core") {
      setSelectedMasterlistsState((prevSelectedValues) => ({
        ...prevSelectedValues,
        masterlist: selectedValues?.coreMasterlist,
      }));
    } else if (rcType === "Material") {
      setSelectedMasterlistsState((prevSelectedValues) => ({
        ...prevSelectedValues,
        masterlist: selectedValues?.materialMasterlist,
      }));
    } else if (rcType === "Critical") {
      setSelectedMasterlistsState((prevSelectedValues) => ({
        ...prevSelectedValues,
        masterlist: selectedValues?.criticalMasterlist,
      }));
    } else if (rcType === "Meaningful") {
      setSelectedMasterlistsState((prevSelectedValues) => ({
        ...prevSelectedValues,
        masterlist: selectedValues?.meaningfulMasterlist,
      }));
    }
  };
useEffect(()=>{
  if(drawerData){
    setResults(drawerData)
  }
// eslint-disable-next-line react-hooks/exhaustive-deps
},[rcType])

  useEffect(() => {
    handleSelectedMasterlists();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rcType,masterlistData, selectedValues]);

  const handleChange = (alias, category, index) => {
    if(selectedMasterlistsState.masterlist.includes(alias) && finalMasterlists.includes(alias)){
      setFinalMasterlists(finalMasterlists.filter(item => item !== alias));
    }else if (finalMasterlists.includes(alias)) {
      alert(`Already selected in another category!`);
      return false;
    }
    setSelectedMasterlistsState((prevselectedMasterlists) => {
      const isAlreadySelected =
        prevselectedMasterlists.masterlist.includes(alias);
      const newMasterlist = isAlreadySelected
        ? prevselectedMasterlists.masterlist.filter((item) => item !== alias)
        : selectedMasterlistsState?.masterlist?.length <
          (rcType === "Core" ? 20 : 4)
        ? [...prevselectedMasterlists.masterlist, alias]
        : [...prevselectedMasterlists.masterlist];

      return { ...prevselectedMasterlists, masterlist: newMasterlist };
    });
  };

  const handleDone = () => {
    setSelectedMasterlists(selectedMasterlistsState.masterlist);
    setFinalMasterlists((prevState) => [
      ...prevState,
      ...selectedMasterlistsState?.masterlist,
    ]);
    setQuery('')
    setSelectedMasterlistsState({ masterlist: [] });
    onClose();
  };
  const searchItems = (query, data) => {
    const lowerCaseQuery = query.toLowerCase();
    if (query === '') {
        return data; // Return all data if query is empty
    }
    return data.map(category => {
        // Filter items within each category
        const filteredItems = category.items.filter(item =>
            item.name.toLowerCase().includes(lowerCaseQuery) ||
            item.alias.toLowerCase().includes(lowerCaseQuery)
        );
        // Return the category if it has any matching items
        if (filteredItems.length > 0) {
            return { ...category, items: filteredItems };
        }
        return null;
    }).filter(category => category !== null); // Remove categories with no matching items
};

  const handleSearch = (e) => {
    const value = e.target.value;
    setQuery(value);
    if (value) {
        setResults(searchItems(value, drawerData));
    } else {
        setResults(drawerData);
    }
};

  return (
    <Drawer
      isOpen={isOpen}
      placement='right'
      onClose={onClose}
      id="rightsidebarsmall"
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton mt="4" onClick={()=> setQuery('')} className="closeicon" />
        <Box
          p={4}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <InputGroup size="md" width={"85%"}>
            <Input
              className="inputfield"
              placeholder="Search..."
              width="100%"
              value={query}
              onChange={handleSearch}
            />
            <InputRightElement width="2.5rem">
              <IoSearchSharp
                fontSize={"20px"}
              />
            </InputRightElement>
          </InputGroup>
        </Box>
        <Box
         px={4}
         py={{base:'0',md:"4"}}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          flexDir={"row-reverse"}
        >
          <H3 fontWeight="700" lineHeight={"18.78px"}>
            {`${selectedMasterlistsState?.masterlist?.length}/${
              rcType === "Core" ? 20 : 4
            }`}
          </H3>
        </Box>
        <DrawerBody>
          {results && results.length>0 && results.map((section) => (
            <Box key={section.category} mb={4}>
              <Text fontWeight="bold">{section.category}</Text>
              <Stack pl={4}>
                {section.items.map((item, index) => (
                  <Checkbox
                    key={item?.alias}
                    isChecked={
                      selectedMasterlistsState?.masterlist?.length <=
                        (rcType === "Core" ? 20 : 4) &&
                      selectedMasterlistsState?.masterlist?.includes(item.alias)
                    }
                    onChange={() =>
                      selectedMasterlistsState?.masterlist?.length <=
                        (rcType === "Core" ? 20 : 4) &&
                      handleChange(item?.alias, section.category, index)
                    }
                  >
                    {item?.name}
                  </Checkbox>
                ))}
              </Stack>
            </Box>
          ))}
        </DrawerBody>

        <DrawerFooter justifyContent={"center"}>
          <Button variant="custom" px={"40px"} onClick={handleDone}>
            <H2 fontWeight={"700"}>Done</H2>
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
}
