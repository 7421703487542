import { 
  Box, 
  Container, 
  HStack, 
  Image, 
  Stack, 
  Tab, 
  TabList, 
  Tabs, 
  Text 
} from "@chakra-ui/react";

//Core file
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

//Service file
import { getChronologyByAlias, getIntroductionByAlias } from "../../../services/ResearchCategoryDetails";

//Component file
import TimeLine from "../../../FunctionalComponents/TimeLine/TimeLine";
import TimelineWithText2 from "../../../FunctionalComponents/TimeLine/TimelineWithText2";

//Language file
import { explore, research_category } from "../../../constants/constants";
import { totalFilterCount, updateFacetsData } from "../../../util/helper";
import NoMoreToLoad from "../../../CommonComponents/NoMoreToLoad";
import InfiniteScroll from "react-infinite-scroll-component";
import { DataNotFound } from "../../../CommonComponents/DataNotFound";
import { FilterBox } from "../../../FunctionalComponents/Filter/FilterBox";
import { FilterButton } from "../../../CommonComponents/FilterButton";
import FilterPills from "../../../components/FilterPills/FilterPills";
import { CopyPasteService } from "../../../services/CopyPaste";
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;
const loaderImg = `${IMAGE_BASE_PATH}/ui/Chevron/loader.gif`

function Chronology({ main, setChronologyViewAll }) {
  const [chronologyData, setChronologyData] = useState([]);
  const [showFilterBox, setShowFilterBox] = useState(false);
  const [rcIntroduction, setRCIntroduction] = useState([]);
  const { alias } = useParams();
  const currentEndpoint = window.location.pathname;
  const parts = currentEndpoint.split("/");
  const masterlistType = parts[1];
  const [page, setPage] = useState(1);
  const [filterOptions, setFilterOptions] = useState({
    search: "",
    filters: {}
  });
  const [loading, setLoading] = useState(true);
  const initialLoadComplete = useRef(false);
  const [hasMore, setHasMore] = useState(true);
  const [facets, setFacets] = useState([]);
  const [apply, setApply] = useState(false);
  const [filterPills, setFilterPills] = useState(null);
  const [filterCount, setFilterCount] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredResultCount, setFilteredResultCount] = useState(0);

  const navigate = useNavigate();
  const url = window.location.pathname.lastIndexOf("/");
  const mainUrl = window.location.pathname.slice(0, url);

  async function fetchData() {

    try {
      setLoading(true);
      const result = await getChronologyByAlias(masterlistType, alias, filterOptions, page);
      setLoading(false);

      if (result?.data?.length > 0 && main)
      {
        setChronologyViewAll(true);
      }

      if (result && result?.data?.length > 0) {
        const processedData = citationFactor(result?.data);

        if (main) {
          setChronologyData(processedData.slice(0, 8));
        }
        else
        {
          setChronologyData(processedData); 
        }

        if (!initialLoadComplete.current) {
          setPage((prevPage) => prevPage + 1);
          initialLoadComplete.current = true;
        }

        if (result?.total <= result?.data.length) {
          setHasMore(false)
        }

        if (result?.facetsHeaders) {
          const formattedFacets = updateFacetsData(result);
          setFacets(formattedFacets);
          if (formattedFacets && formattedFacets.length > 0) {
            setFilterOptions(prevFilterOptions => ({
              ...prevFilterOptions,
              filters: {
                ...prevFilterOptions.filters,
                ...formattedFacets.reduce((acc, facet) => {
                  acc[facet.key] = [];
                  return acc;
                }, {})
              }
            }));
          }
        }
      }

    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  useEffect(() => {
    fetchData();
    setFilterPills(filterOptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchMoreData = async () => {

    try {
      const result = await getChronologyByAlias(masterlistType, alias, filterOptions, page);

      if (result) {
        const processedData = citationFactor(result?.data);
        setChronologyData(prevData => [...prevData, ...processedData]);

        setPage(prevPage => prevPage + 1);

        if (chronologyData && chronologyData.length >= result?.total) {
          setHasMore(false);
        }
      }

    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  async function fetchFilterData(page = 1) {

    try {
      const result = await getChronologyByAlias(masterlistType, alias, filterOptions, page);

      if (result) {
        const processedData = citationFactor(result?.data);
        setChronologyData(processedData);
        if (chronologyData && chronologyData.length >= result?.total) {
          setHasMore(false);
        }

        const filtercnt= totalFilterCount(filterOptions?.filters)
        
        if(filtercnt>0 || filterOptions?.search){
          setFilteredResultCount(result?.total)
        }else{
          setFilteredResultCount(0);
        }

        if (result?.facetsHeaders && result?.facets) {
          const formattedFacets = updateFacetsData(result);
          setFacets(formattedFacets);
        }
      }

    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  useEffect(() => {
    if (apply) {
      setApply(false);
      fetchFilterData();
      setPage(1);
      setFilterPills(filterOptions)
      const filtercnt = totalFilterCount(filterOptions?.filters)
      setFilterCount(filtercnt)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apply]);

  const handleReset = () => {
    const initializedFilters = facets.reduce((acc, facet) => {
      acc[facet.key] = [];
      return acc;
    }, {});
    setFilterOptions({
      search: "",
      filters: initializedFilters
    });
    setFilterCount(0)
    setSearchTerm("");
  }

  const handleCheckboxChange = (value, type) => {
    let selectedArray = [];
    let selectedString = "";
    switch (value) {
      case "sort":
        selectedString = filterOptions.sortBy_v;
        break;
      case "searches":
        selectedString = filterOptions.search;
        break;
      default:
        if (value in filterOptions.filters) {
          selectedArray = filterOptions.filters[value];
        }
        break;
    }

    if (selectedArray.includes(type)) {
      selectedArray = selectedArray.filter((checkbox) => checkbox !== type);
    } else {
      selectedArray = [...selectedArray, type];
    }

    if (selectedString !== type) {
      selectedString = type;
    }

    switch (value) {
      case "sort":
        setFilterOptions(prevFilterOptions => ({
          ...prevFilterOptions,
          sortBy_v: selectedString,
        }));
        break;
      case "searches":
        setFilterOptions(prevFilterOptions => ({
          ...prevFilterOptions,
          search: selectedString,
        }));
        break;
      default:
        if (value in filterOptions.filters) {
          setFilterOptions(prevFilterOptions => ({
            ...prevFilterOptions,
            filters: {
              ...prevFilterOptions.filters,
              [value]: selectedArray,
            },
          }));
        }
        break;
    }
  };

  const handleFilterClick = () => {
    setShowFilterBox(!showFilterBox);
  };
  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    handleCheckboxChange("searches", e.target.value)
  };

  useEffect(() => {
    const getData = async () => {
      try {
        const result = await getIntroductionByAlias("", alias);
        if (result) {
          setRCIntroduction(result?.data[0]);        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    getData();
  }, [alias]);

  const citationFactor = (data) => {
    return data.map((item) => {
      const getValue = (key) => item.find((nestedItem) => nestedItem.key === key)?.value || "";
  
      const year = getValue("sortable_year");
      const desc = getValue("bibliographic_caption");
      const country = getValue("country_of_publication");
      const place = getValue("place_of_publication_artwork");
  
      const shortDesc = [year, place, country].filter(Boolean).join(", ");
  
      return {
        year,
        desc,
        country,
        place,
        shortDesc
      };
    });
  };

  return (
    <>
      {!main && (
        <Box
          position={"fixed"}
          px={{ base: "4", md: "16" }}
          py={{ base: "4", md: "8" }}
          zIndex={999}
          bg="white"
          width={"1344px"}
        >
          <Tabs variant={"unstyled"} display={"flex"} align="end" pos={"none"} alignItems={"center"} justifyContent={"space-between"} defaultIndex={-1}>
            <Box>
            <Text
              pos={"absolute"}
              top={"23px"}
              borderBottom={"4px"}
              borderBottomColor={"black"}
              textAlign={"left"}
              width={{base:"100%",md:"max-content"}}
              fontSize={{ base: "22px", md: "22px" }}
              fontWeight={700}
              cursor={"pointer"}
              onClick={() => navigate(`${mainUrl}/scrollToIntroduction`)}
            >
            {rcIntroduction && rcIntroduction?.find((nestedItem) => nestedItem?.key === "name")?.value}                    
            </Text>
            </Box>
            <TabList gap={"25px"}>
              <Tab
                onClick={() => navigate(`${mainUrl}/Introduction`)}
                p="0px"
                fontSize={"16"}
                fontWeight={600}
                _selected={{ borderBottom: "2px" }}
              >
                {research_category.INTRODUCTION_TAB_TITLE}
              </Tab>
              <Tab
                onClick={() => navigate(`${mainUrl}/scrollToChronology`)}
                p="0px"
                fontSize={"16"}
                borderBottom="2px" 
                fontWeight={600}
                _selected={{ borderBottom: "2px" }}
              >
                {research_category.CHRONOLOGY_TAB_TITLE}
              </Tab>
              <Tab
                onClick={() => navigate(`${mainUrl}/scrollToMasterlists`)}
                p="0px"
                fontSize={"16"}
                fontWeight={600}
                _selected={{ borderBottom: "2px" }}
              >
                {research_category.MASTERLISTS_TAB_TITLE}
              </Tab>
              <Tab
                onClick={() => navigate(`${mainUrl}/scrollToPosts`)}
                p="0px"
                fontSize={"16"}
                fontWeight={600}
                _selected={{ borderBottom: "2px" }}
              >
               {research_category.POSTS_TAB_TITLE}
              </Tab>
              <Tab
                onClick={() => navigate(`${mainUrl}/scrollToInsights`)}
                p="0px"
                fontSize={"16"}
                fontWeight={600}
                _selected={{ borderBottom: "2px" }}
              >
              {research_category.INSIGHTS_TAB_TITLE}
              </Tab>
              <Tab
                onClick={() => navigate(`${mainUrl}/scrollToResearchCentre`)}
                p="0px"
                fontSize={"16"}
                fontWeight={600}
                _selected={{ borderBottom: "2px" }}
              >
                {research_category.RESEARCH_CENTRE_TAB_TITLE}
              </Tab>
            </TabList>
          </Tabs>
        </Box>
      )}
    {  !loading ? 
   <Stack marginTop={!main && { base: "10", md: "75px" }}   paddingTop={!main && {base: "4", md: "50px" }}  flexDirection={"row-reverse"}>
     <>
        {!showFilterBox && <FilterButton marginTop="40px" masterlistType={masterlistType} onClick={handleFilterClick} />}
        <Container
          maxW="auto"
          className="songs"
          px={!main && {base: "4", md: "64px" }}
          pt={!main && {base: "4", md: "8" }}

          position={"relative"}
        >
          <Stack flexDirection={"row"} gap={4} justifyContent="space-between">

            {chronologyData && chronologyData.length > 0 ? (
              <Stack
                flexDirection={"column"}
                gap={0}
                height="100%"
                onCopy={(e) => CopyPasteService(e, `${window.location.href}/0/0`)}
                width={"90%"}
              >
                <InfiniteScroll
                 dataLength={chronologyData.length}
                  next={fetchMoreData}
                  hasMore={hasMore}
                  loader={
                    <Box textAlign="center" marginLeft={"50%"}>
                      <Stack marginTop={"20px"}>
                        <img
                          src={loaderImg}
                          width={"100px"}
                          height={"100px"}
                          alt="Loading"
                        />
                      </Stack>
                    </Box>
                  }
                  endMessage={<NoMoreToLoad />}
                >
                {filterPills &&  filterPills!==undefined && filterCount>0&&
                <FilterPills
                filters={filterPills}
                setFilterOptions={setFilterOptions}
                setApply={setApply}
                setFilterPills={setFilterPills}
                            filteredResultCount={filteredResultCount}
                />
                }
                  <TimeLine
                  data={chronologyData}
                  component={TimelineWithText2}
                  />
                </InfiniteScroll>
              </Stack>
            )
              : (
                <Stack
                onCopy={(e) =>
                  CopyPasteService(e, `${window.location.href}/0/1`)
                }
                margin={"auto"}
              >
                <Box position={"relative"} top="-70px" right={"auto"} left={"0px"}>
                  {filterPills &&  filterPills!==undefined && filterCount>0&&
                  <FilterPills
                  filters={filterPills}
                  setFilterOptions={setFilterOptions}
                  setApply={setApply}
                  setFilterPills={setFilterPills}
                  />
                  }
                </Box>
                <DataNotFound />
              </Stack>
              )}
            <HStack
              marginLeft={"4"}
              marginRight={"-64px!important"}
              className="mt-0"
              alignItems={"flex-start"}
              pos={masterlistType === "generic" && "relative"}
              right={masterlistType === "generic" && "-5px"}
            >
              {showFilterBox && (
                <FilterBox
                setShowFilterBox={setShowFilterBox}
                setApply={setApply}
                explore={explore}
                apply={apply}
                handleCheckboxChange={handleCheckboxChange}
                handleSearch={handleSearch}
                filterOptions={filterOptions}
                filteroptionData={facets}
                setFilterOptions={setFilterOptions}
                setSearchTerm={setSearchTerm}
                filterCount={filterCount}
                searchTerm={searchTerm}
                handleReset={handleReset}
                  className="common-box-filter"
                />
              )}
            </HStack>
          </Stack>
        </Container>
        </>
      </Stack>
       :
       (
         <HStack justifyContent={"center"} minH={"100vh"} alignItems={"center"} mt={"5%"}>
             <Image src={loaderImg} width={"100px"} height={"100px"} alt="Loading" />
         </HStack>
       )
         }
    </>
    
  );
}
export default Chronology;