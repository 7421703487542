//S3 Bucket osw-dev-images
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;

//slider images
const Img1 = `${IMAGE_BASE_PATH}/ui/ResearchCategories/PhotographyInIndia/Introduction/1.png`
const Img2 = `${IMAGE_BASE_PATH}/ui/ResearchCategories/PhotographyInIndia/Introduction/2.png`
const Img3 = `${IMAGE_BASE_PATH}/ui/ResearchCategories/PhotographyInIndia/Introduction/3.png`
const Img4 = `${IMAGE_BASE_PATH}/ui/ResearchCategories/PhotographyInIndia/Introduction/4.png`
const Img5 = `${IMAGE_BASE_PATH}/ui/ResearchCategories/PhotographyInIndia/Introduction/5.png`

const GridImg0 = `${IMAGE_BASE_PATH}/ui/ResearchCategories/PhotographyInIndia/Masterlists/1.png`
const GridImg1 = `${IMAGE_BASE_PATH}/ui/ResearchCategories/PhotographyInIndia/Masterlists/2.png`
const GridImg2 = `${IMAGE_BASE_PATH}/ui/ResearchCategories/PhotographyInIndia/Masterlists/3.png`
const GridImg3 = `${IMAGE_BASE_PATH}/ui/ResearchCategories/PhotographyInIndia/Masterlists/4.png`
const GridImg4 = `${IMAGE_BASE_PATH}/ui/ResearchCategories/PhotographyInIndia/Masterlists/5.png`
const GridImg5 = `${IMAGE_BASE_PATH}/ui/ResearchCategories/PhotographyInIndia/Masterlists/6.png`
const GridImg6 = `${IMAGE_BASE_PATH}/ui/ResearchCategories/PhotographyInIndia/Masterlists/7.png`
const GridImg7 = `${IMAGE_BASE_PATH}/ui/ResearchCategories/PhotographyInIndia/Masterlists/8.png`

const gridImg01 = `${IMAGE_BASE_PATH}/ui/ResearchCategories/PhotographyInIndia/Masterlists/RaghuRai/1.png`
const gridImg02 = `${IMAGE_BASE_PATH}/ui/ResearchCategories/PhotographyInIndia/Masterlists/RaghuRai/2.png`
const gridImg03 = `${IMAGE_BASE_PATH}/ui/ResearchCategories/PhotographyInIndia/Masterlists/RaghuRai/3.png`
const gridImg04 = `${IMAGE_BASE_PATH}/ui/ResearchCategories/PhotographyInIndia/Masterlists/RaghuRai/4.png`
const gridImg05 = `${IMAGE_BASE_PATH}/ui/ResearchCategories/PhotographyInIndia/Masterlists/RaghuRai/5.png`
const gridImg06 = `${IMAGE_BASE_PATH}/ui/ResearchCategories/PhotographyInIndia/Masterlists/RaghuRai/6.png`
const gridImg07 = `${IMAGE_BASE_PATH}/ui/ResearchCategories/PhotographyInIndia/Masterlists/RaghuRai/7.png`
const gridImg08 = `${IMAGE_BASE_PATH}/ui/ResearchCategories/PhotographyInIndia/Masterlists/RaghuRai/8.png`
const gridImg09 = `${IMAGE_BASE_PATH}/ui/ResearchCategories/PhotographyInIndia/Masterlists/RaghuRai/9.png`

const gridImg11 = `${IMAGE_BASE_PATH}/ui/ResearchCategories/PhotographyInIndia/Masterlists/PhotoInk/1.png`
const gridImg12 = `${IMAGE_BASE_PATH}/ui/ResearchCategories/PhotographyInIndia/Masterlists/PhotoInk/2.png`
const gridImg13 = `${IMAGE_BASE_PATH}/ui/ResearchCategories/PhotographyInIndia/Masterlists/PhotoInk/3.png`
const gridImg14 = `${IMAGE_BASE_PATH}/ui/ResearchCategories/PhotographyInIndia/Masterlists/PhotoInk/4.png`
const gridImg15 = `${IMAGE_BASE_PATH}/ui/ResearchCategories/PhotographyInIndia/Masterlists/PhotoInk/5.png`
const gridImg16 = `${IMAGE_BASE_PATH}/ui/ResearchCategories/PhotographyInIndia/Masterlists/PhotoInk/6.png`
const gridImg17 = `${IMAGE_BASE_PATH}/ui/ResearchCategories/PhotographyInIndia/Masterlists/PhotoInk/7.png`
const gridImg18 = `${IMAGE_BASE_PATH}/ui/ResearchCategories/PhotographyInIndia/Masterlists/PhotoInk/8.png`

const PostImg1 = `${IMAGE_BASE_PATH}/ui/ResearchCategories/PhotographyInIndia/Posts/post1.png`
const PostImg2 = `${IMAGE_BASE_PATH}/ui/ResearchCategories/PhotographyInIndia/Posts/post2.png`
const PostImg3 = `${IMAGE_BASE_PATH}/ui/ResearchCategories/PhotographyInIndia/Posts/post3.png`
const PostImg4 = `${IMAGE_BASE_PATH}/ui/ResearchCategories/PhotographyInIndia/Posts/post4.png`
const PostImg5 = `${IMAGE_BASE_PATH}/ui/ResearchCategories/PhotographyInIndia/Posts/post5.png`
const fullpost1 = `${IMAGE_BASE_PATH}/ui/ResearchCategories/PhotographyInIndia/Posts/fullpost1.jpg`
const fullpost2 = `${IMAGE_BASE_PATH}/ui/ResearchCategories/PhotographyInIndia/Posts/fullpost2.jpg`
const fullpost3 = `${IMAGE_BASE_PATH}/ui/ResearchCategories/PhotographyInIndia/Posts/fullpost3.jpg`
const fullpost4 = `${IMAGE_BASE_PATH}/ui/ResearchCategories/PhotographyInIndia/Posts/fullpost4.jpg`
const fullpost5 = `${IMAGE_BASE_PATH}/ui/ResearchCategories/PhotographyInIndia/Posts/fullpost5.jpg`

const gridImg21 = `${IMAGE_BASE_PATH}/ui/ResearchCategories/PhotographyInIndia/Masterlists/StudioClifton/1.png`
const gridImg22 = `${IMAGE_BASE_PATH}/ui/ResearchCategories/PhotographyInIndia/Masterlists/StudioClifton/2.png`
const gridImg23 = `${IMAGE_BASE_PATH}/ui/ResearchCategories/PhotographyInIndia/Masterlists/StudioClifton/3.png`
const gridImg24 = `${IMAGE_BASE_PATH}/ui/ResearchCategories/PhotographyInIndia/Masterlists/StudioClifton/4.png`
const gridImg25 = `${IMAGE_BASE_PATH}/ui/ResearchCategories/PhotographyInIndia/Masterlists/StudioClifton/5.png`
const gridImg26 = `${IMAGE_BASE_PATH}/ui/ResearchCategories/PhotographyInIndia/Masterlists/StudioClifton/6.png`
const gridImg27 = `${IMAGE_BASE_PATH}/ui/ResearchCategories/PhotographyInIndia/Masterlists/StudioClifton/7.png`
const gridImg28 = `${IMAGE_BASE_PATH}/ui/ResearchCategories/PhotographyInIndia/Masterlists/StudioClifton/8.png`
const gridImg29 = `${IMAGE_BASE_PATH}/ui/ResearchCategories/PhotographyInIndia/Masterlists/StudioClifton/9.png`

const gridImg31 = `${IMAGE_BASE_PATH}/ui/ResearchCategories/PhotographyInIndia/Masterlists/RRBharadwaj/1.png`
const gridImg32 = `${IMAGE_BASE_PATH}/ui/ResearchCategories/PhotographyInIndia/Masterlists/RRBharadwaj/2.png`
const gridImg33 = `${IMAGE_BASE_PATH}/ui/ResearchCategories/PhotographyInIndia/Masterlists/RRBharadwaj/3.png`
const gridImg34 = `${IMAGE_BASE_PATH}/ui/ResearchCategories/PhotographyInIndia/Masterlists/RRBharadwaj/4.png`
const gridImg35 = `${IMAGE_BASE_PATH}/ui/ResearchCategories/PhotographyInIndia/Masterlists/RRBharadwaj/5.png`
const gridImg36 = `${IMAGE_BASE_PATH}/ui/ResearchCategories/PhotographyInIndia/Masterlists/RRBharadwaj/6.png`
const gridImg37 = `${IMAGE_BASE_PATH}/ui/ResearchCategories/PhotographyInIndia/Masterlists/RRBharadwaj/7.png`
const gridImg38 = `${IMAGE_BASE_PATH}/ui/ResearchCategories/PhotographyInIndia/Masterlists/RRBharadwaj/8.png`
const gridImg39 = `${IMAGE_BASE_PATH}/ui/ResearchCategories/PhotographyInIndia/Masterlists/RRBharadwaj/9.png`

const gridImg41 = `${IMAGE_BASE_PATH}/ui/ResearchCategories/PhotographyInIndia/Masterlists/IndiaChainaAustralia/1.png`
const gridImg42 = `${IMAGE_BASE_PATH}/ui/ResearchCategories/PhotographyInIndia/Masterlists/IndiaChainaAustralia/2.png`
const gridImg43 = `${IMAGE_BASE_PATH}/ui/ResearchCategories/PhotographyInIndia/Masterlists/IndiaChainaAustralia/3.png`
const gridImg44 = `${IMAGE_BASE_PATH}/ui/ResearchCategories/PhotographyInIndia/Masterlists/IndiaChainaAustralia/4.png`
const gridImg45 = `${IMAGE_BASE_PATH}/ui/ResearchCategories/PhotographyInIndia/Masterlists/IndiaChainaAustralia/5.png`
const gridImg46 = `${IMAGE_BASE_PATH}/ui/ResearchCategories/PhotographyInIndia/Masterlists/IndiaChainaAustralia/6.png`
const gridImg47 = `${IMAGE_BASE_PATH}/ui/ResearchCategories/PhotographyInIndia/Masterlists/IndiaChainaAustralia/7.png`
const gridImg48 = `${IMAGE_BASE_PATH}/ui/ResearchCategories/PhotographyInIndia/Masterlists/IndiaChainaAustralia/8.png`
const gridImg49 = `${IMAGE_BASE_PATH}/ui/ResearchCategories/PhotographyInIndia/Masterlists/IndiaChainaAustralia/9.png`

const gridImg51 = `${IMAGE_BASE_PATH}/ui/ResearchCategories/PhotographyInIndia/Masterlists/SamuelBourne/1.png`
const gridImg52 = `${IMAGE_BASE_PATH}/ui/ResearchCategories/PhotographyInIndia/Masterlists/SamuelBourne/2.png`
const gridImg53 = `${IMAGE_BASE_PATH}/ui/ResearchCategories/PhotographyInIndia/Masterlists/SamuelBourne/3.png`
const gridImg54 = `${IMAGE_BASE_PATH}/ui/ResearchCategories/PhotographyInIndia/Masterlists/SamuelBourne/4.png`
const gridImg55 = `${IMAGE_BASE_PATH}/ui/ResearchCategories/PhotographyInIndia/Masterlists/SamuelBourne/5.png`
const gridImg56 = `${IMAGE_BASE_PATH}/ui/ResearchCategories/PhotographyInIndia/Masterlists/SamuelBourne/6.png`
const gridImg57 = `${IMAGE_BASE_PATH}/ui/ResearchCategories/PhotographyInIndia/Masterlists/SamuelBourne/7.png`
const gridImg58 = `${IMAGE_BASE_PATH}/ui/ResearchCategories/PhotographyInIndia/Masterlists/SamuelBourne/8.png`
const gridImg59 = `${IMAGE_BASE_PATH}/ui/ResearchCategories/PhotographyInIndia/Masterlists/SamuelBourne/9.png`

const gridImg61 = `${IMAGE_BASE_PATH}/ui/ResearchCategories/PhotographyInIndia/Masterlists/KetakiSheth/1.png`
const gridImg62 = `${IMAGE_BASE_PATH}/ui/ResearchCategories/PhotographyInIndia/Masterlists/KetakiSheth/2.png`
const gridImg63 = `${IMAGE_BASE_PATH}/ui/ResearchCategories/PhotographyInIndia/Masterlists/KetakiSheth/3.png`
const gridImg64 = `${IMAGE_BASE_PATH}/ui/ResearchCategories/PhotographyInIndia/Masterlists/KetakiSheth/4.png`
const gridImg65 = `${IMAGE_BASE_PATH}/ui/ResearchCategories/PhotographyInIndia/Masterlists/KetakiSheth/5.png`
const gridImg66 = `${IMAGE_BASE_PATH}/ui/ResearchCategories/PhotographyInIndia/Masterlists/KetakiSheth/6.png`
const gridImg67 = `${IMAGE_BASE_PATH}/ui/ResearchCategories/PhotographyInIndia/Masterlists/KetakiSheth/7.png`
const gridImg68 = `${IMAGE_BASE_PATH}/ui/ResearchCategories/PhotographyInIndia/Masterlists/KetakiSheth/8.png`
const gridImg69 = `${IMAGE_BASE_PATH}/ui/ResearchCategories/PhotographyInIndia/Masterlists/KetakiSheth/9.png`

const fullImg1 = `${IMAGE_BASE_PATH}/ui/ResearchCategories/PhotographyInIndia/Masterlists/RaghuRai/fullImg1.png`
const fullImg2 = `${IMAGE_BASE_PATH}/ui/ResearchCategories/PhotographyInIndia/Masterlists/RaghuRai/fullImg2.png`
const fullImg3 = `${IMAGE_BASE_PATH}/ui/ResearchCategories/PhotographyInIndia/Masterlists/RaghuRai/fullImg3.png`

const fullImg11 = `${IMAGE_BASE_PATH}/ui/ResearchCategories/PhotographyInIndia/Masterlists/PhotoInk/fullImg1.png`
const fullImg12 = `${IMAGE_BASE_PATH}/ui/ResearchCategories/PhotographyInIndia/Masterlists/PhotoInk/fullImg2.png`
const fullImg13 = `${IMAGE_BASE_PATH}/ui/ResearchCategories/PhotographyInIndia/Masterlists/PhotoInk/fullImg3.png`

const fullImg21 = `${IMAGE_BASE_PATH}/ui/ResearchCategories/PhotographyInIndia/Masterlists/RRBharadwaj/fullImg1.png`
const fullImg22 = `${IMAGE_BASE_PATH}/ui/ResearchCategories/PhotographyInIndia/Masterlists/RRBharadwaj/fullImg2.png`
const fullImg23 = `${IMAGE_BASE_PATH}/ui/ResearchCategories/PhotographyInIndia/Masterlists/RRBharadwaj/fullImg3.png`

const fullImg31 = `${IMAGE_BASE_PATH}/ui/ResearchCategories/PhotographyInIndia/Masterlists/IndiaChainaAustralia/fullImg1.png`
const fullImg32 = `${IMAGE_BASE_PATH}/ui/ResearchCategories/PhotographyInIndia/Masterlists/IndiaChainaAustralia/fullImg2.png`
const fullImg33 = `${IMAGE_BASE_PATH}/ui/ResearchCategories/PhotographyInIndia/Masterlists/IndiaChainaAustralia/fullImg3.png`

const fullImg41 = `${IMAGE_BASE_PATH}/ui/ResearchCategories/PhotographyInIndia/Masterlists/KetakiSheth/fullImg1.png`
const fullImg42 = `${IMAGE_BASE_PATH}/ui/ResearchCategories/PhotographyInIndia/Masterlists/KetakiSheth/fullImg2.png`
const fullImg43 = `${IMAGE_BASE_PATH}/ui/ResearchCategories/PhotographyInIndia/Masterlists/KetakiSheth/fullImg3.png`

const fullImg51 = `${IMAGE_BASE_PATH}/ui/ResearchCategories/PhotographyInIndia/Masterlists/StudioClifton/fullimg1.png`
const fullImg52 = `${IMAGE_BASE_PATH}/ui/ResearchCategories/PhotographyInIndia/Masterlists/StudioClifton/fullimg2.png`
const fullImg53 = `${IMAGE_BASE_PATH}/ui/ResearchCategories/PhotographyInIndia/Masterlists/StudioClifton/fullimg3.png`

const KGSubra = `${IMAGE_BASE_PATH}/ui/Explore/Explore/KGsubramanyan.png`
const SHRaza = `${IMAGE_BASE_PATH}/ui/Explore/Explore/6.png`
const SatyajitRay = `${IMAGE_BASE_PATH}/ui/Explore/Explore/7.png`
const skk1 = `${IMAGE_BASE_PATH}/ui/Film/ShatranjKeKhilari/LandingPage/SliderImage/1.png`
const dummy=`${IMAGE_BASE_PATH}/ui/Film/ShatranjKeKhilari/DummyImage/1.png`
//RC_Photography_In_India slider images
export const photographyInIndiaIntroduction = [
  {
    alias: "photography-in-india_3",
    title: "Photography in India",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Lorem ipsum dolor sit",
    images: [Img1, Img2, Img3, Img4, Img5],
  },
];

export const photographyInIndiaChronology = [
  {
    id: "1",
    sortable_date: "02 may",
    title: "Lorem ipsum dolor sit amet",
    subtitle: "consectetur adipiscing elit",
    description: "sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation.",
    sortable_year: "1965",
    place: "New Delhi",
    country: "USA",
  },
  {
    id: "2",
    sortable_date: "16 June",
    title: "Lorem ipsum dolor sit amet",
    subtitle: "consectetur adipiscing elit",
    description: "consectetur adipiscing sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
    sortable_year: "1971",
    place: "New Delhi",
    country: "India",
  },
  {
    id: "3",
    sortable_date: "16 June",
    title: "Lorem ipsum dolor sit amet",
    subtitle: "consectetur adipiscing elit",
    description: "sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation.",
    sortable_year: "1971",
    place: "New Delhi",
    country: "India",
  },
  {
    id: "4",
    sortable_date: "16 June",
    title: "Lorem ipsum dolor sit amet",
    subtitle: "consectetur adipiscing elit",
    description: "consectetur adipiscing sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
    sortable_year: "1971",
    place: "New Delhi",
    country: "India",
  },
  {
    id: "5",
    sortable_date: "16 June",
    title: "Lorem ipsum dolor sit amet",
    subtitle: "consectetur adipiscing elit",
    description: "consectetur adipiscing sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation.",
    sortable_year: "1971",
    place: "New Delhi",
    country: "India",
  },
  {
    id: "6",
    sortable_date: "16 June",
    title: "Lorem ipsum dolor sit amet",
    subtitle: "consectetur adipiscing elit",
    description: "sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation.",
    sortable_year: "1971",
    place: "New Delhi",
    country: "India",
  },
  {
    id: "7",
    sortable_date: "16 June",
    title: "Lorem ipsum dolor sit amet",
    subtitle: "consectetur adipiscing elit",
    description: "consectetur adipiscing sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
    sortable_year: "1971",
    place: "New Delhi",
    country: "India",
  },
  {
    id: "8",
    sortable_date: "16 June",
    title: "Lorem ipsum dolor sit amet",
    subtitle: "consectetur adipiscing elit",
    description: "sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation.",
    sortable_year: "1971",
    place: "New Delhi",
    country: "India",
  },
  {
    id: "9",
    sortable_date: "16 June",
    title: "Lorem ipsum dolor sit amet",
    subtitle: "consectetur adipiscing elit",
    description: "consectetur adipiscing",
    sortable_year: "1971",
    place: "New Delhi",
    country: "India",
  },
  {
    id: "10",
    sortable_date: "16 June",
    title: "Lorem ipsum dolor sit amet",
    subtitle: "consectetur adipiscing elit",
    description: "consectetur adipiscing sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
    sortable_year: "1971",
    place: "New Delhi",
    country: "India",
  },
  {
    id: "11",
    sortable_date: "16 June",
    title: "Lorem ipsum dolor sit amet",
    subtitle: "consectetur adipiscing elit",
    description: "sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation.",
    sortable_year: "1971",
    place: "New Delhi",
    country: "India",
  },
  {
    id: "12",
    sortable_date: "16 June",
    title: "Lorem ipsum dolor sit amet",
    subtitle: "consectetur adipiscing elit",
    description: "consectetur adipiscing sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
    sortable_year: "1971",
    place: "New Delhi",
    country: "India",
  },
  {
    id: "13",
    sortable_date: "16 June",
    title: "Lorem ipsum dolor sit amet",
    subtitle: "consectetur adipiscing elit",
    description: "sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation.",
    sortable_year: "1971",
    place: "New Delhi",
    country: "India",
  },
  {
    id: "14",
    sortable_date: "16 June",
    title: "Lorem ipsum dolor sit amet",
    subtitle: "consectetur adipiscing elit",
    description: "consectetur adipiscing sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
    sortable_year: "1971",
    place: "New Delhi",
    country: "India",
  },
];

//RC_Photography_In_India Masterlist data
export const photographyInIndiaMasterlist = [
  {
    id: "1",
    alias:"raghu-rai",
    researchCategoryAlias:"photography-in-india_3",
    name: "Raghu Rai",
    image: GridImg0,
    description:"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    content:"Content Awaited",
    images:[
      {
        img:gridImg01,
        fullImg:fullImg1,
        title_of_photograph:"Darjeeling Train (India)",
        photographer:"Raghu Rai",
        medium:"Ilford-Multigrade RC– Pearl Warmtone",
        date_of_photograph:"1995",
        size:"20.0 x 24.0 in (50.8 x 61.0cm;508.0 x 610.0 mm)",
        width_in_cm:"",
        height_in_inch:"",
        width_in_inch:"",
        height_in_mm:"",
        width_in_mm:"",
        location:"Darjeeling",
        quotation:"Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.Lorem ipsum is placeholder text commonly used in the ",
      },
      {
        img:gridImg02,
        fullImg:fullImg2,
        title_of_photograph:"Darjeeling Train (India)",
        photographer:"Raghu Rai",
        medium:"Ilford-Multigrade RC– Pearl Warmtone",
        date_of_photograph:"1995",
        size:"20.0 x 24.0 in (50.8 x 61.0cm;508.0 x 610.0 mm)",
        width_in_cm:"",
        height_in_inch:"",
        width_in_inch:"",
        height_in_mm:"",
        width_in_mm:"",
        location:"Darjeeling",
        quotation:"Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.Lorem ipsum is placeholder text commonly used in the ",
      },
      {
        img:gridImg03,
        fullImg:fullImg3,
        title_of_photograph:"Darjeeling Train (India)",
        photographer:"Raghu Rai",
        medium:"Ilford-Multigrade RC– Pearl Warmtone",
        date_of_photograph:"1995",
        size:"20.0 x 24.0 in (50.8 x 61.0cm;508.0 x 610.0 mm)",
        width_in_cm:"",
        height_in_inch:"",
        width_in_inch:"",
        height_in_mm:"",
        width_in_mm:"",
        location:"Darjeeling",
        quotation:"Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.Lorem ipsum is placeholder text commonly used in the ",
      },
      {
        img:gridImg04,
        fullImg:"",
        title_of_photograph:"Content Awaited",
        photographer:"Content Awaited",
        medium:"Content Awaited",
        date_of_photograph:"Content Awaited",
        size:"Content Awaited",
        width_in_cm:"",
        height_in_inch:"",
        width_in_inch:"",
        height_in_mm:"",
        width_in_mm:"",
        location:"Content Awaited",
        quotation:"Content Awaited",
      },
      {
        img:gridImg05,
        fullImg:"",
        title_of_photograph:"Content Awaited",
        photographer:"Content Awaited",
        medium:"Content Awaited",
        date_of_photograph:"Content Awaited",
        size:"Content Awaited",
        width_in_cm:"",
        height_in_inch:"",
        width_in_inch:"",
        height_in_mm:"",
        width_in_mm:"",
        location:"Content Awaited",
        quotation:"Content Awaited",
      },
      {
        img:gridImg06,
        fullImg:"",
        title_of_photograph:"Content Awaited",
        photographer:"Content Awaited",
        medium:"Content Awaited",
        date_of_photograph:"Content Awaited",
        size:"Content Awaited",
        width_in_cm:"",
        height_in_inch:"",
        width_in_inch:"",
        height_in_mm:"",
        width_in_mm:"",
        location:"Content Awaited",
        quotation:"Content Awaited",
      },
      {
        img:gridImg07,
        fullImg:"",
        title_of_photograph:"Content Awaited",
        photographer:"Content Awaited",
        medium:"Content Awaited",
        date_of_photograph:"Content Awaited",
        size:"Content Awaited",
        width_in_cm:"",
        height_in_inch:"",
        width_in_inch:"",
        height_in_mm:"",
        width_in_mm:"",
        location:"Content Awaited",
        quotation:"Content Awaited",
      },{
        img:gridImg08,
        fullImg:"",
        title_of_photograph:"Content Awaited",
        photographer:"Content Awaited",
        medium:"Content Awaited",
        date_of_photograph:"Content Awaited",
        size:"Content Awaited",
        width_in_cm:"",
        height_in_inch:"",
        width_in_inch:"",
        height_in_mm:"",
        width_in_mm:"",
        location:"Content Awaited",
        quotation:"Content Awaited",
      },
      {
        img:gridImg09,
        fullImg:"",
        title_of_photograph:"Content Awaited",
        photographer:"Content Awaited",
        medium:"Content Awaited",
        date_of_photograph:"Content Awaited",
        size:"Content Awaited",
        width_in_cm:"",
        height_in_inch:"",
        width_in_inch:"",
        height_in_mm:"",
        width_in_mm:"",
        location:"Content Awaited",
        quotation:"Content Awaited",
      }
    ]
  },
  {
    id: "2",
    alias:"photoink",
    researchCategoryAlias:"photography-in-india_3",
    name: "PhotoInk",
    image: GridImg1,
    description:"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    content:"Content Awaited",
    images:[
      {
        img:gridImg11,
        fullImg:fullImg11,
        title_of_photograph:"Content Awaited",
        photographer:"Content Awaited",
        medium:"Content Awaited",
        date_of_photograph:"Content Awaited",
        size:"Content Awaited",
        width_in_cm:"",
        height_in_inch:"",
        width_in_inch:"",
        height_in_mm:"",
        width_in_mm:"",
        location:"Content Awaited",
        quotation:"Content Awaited",
      },
      {
        img:gridImg12,
        fullImg:fullImg12,
        title_of_photograph:"Content Awaited",
        photographer:"Content Awaited",
        medium:"Content Awaited",
        date_of_photograph:"Content Awaited",
        size:"Content Awaited",
        width_in_cm:"",
        height_in_inch:"",
        width_in_inch:"",
        height_in_mm:"",
        width_in_mm:"",
        location:"Content Awaited",
        quotation:"Content Awaited",
      },
      {
        img:gridImg13,
        fullImg:fullImg13,
        title_of_photograph:"Content Awaited",
        photographer:"Content Awaited",
        medium:"Content Awaited",
        date_of_photograph:"Content Awaited",
        size:"Content Awaited",
        width_in_cm:"",
        height_in_inch:"",
        width_in_inch:"",
        height_in_mm:"",
        width_in_mm:"",
        location:"Content Awaited",
        quotation:"Content Awaited",
      },
      {
        img:gridImg14,
        fullImg:"",
        title_of_photograph:"Content Awaited",
        photographer:"Content Awaited",
        medium:"Content Awaited",
        date_of_photograph:"Content Awaited",
        size:"Content Awaited",
        width_in_cm:"",
        height_in_inch:"",
        width_in_inch:"",
        height_in_mm:"",
        width_in_mm:"",
        location:"Content Awaited",
        quotation:"Content Awaited",
      },
      {
        img:gridImg15,
        fullImg:"",
        title_of_photograph:"Content Awaited",
        photographer:"Content Awaited",
        medium:"Content Awaited",
        date_of_photograph:"Content Awaited",
        size:"Content Awaited",
        width_in_cm:"",
        height_in_inch:"",
        width_in_inch:"",
        height_in_mm:"",
        width_in_mm:"",
        location:"Content Awaited",
        quotation:"Content Awaited",
      },
      {
        img:gridImg16,
        fullImg:"",
        title_of_photograph:"Content Awaited",
        photographer:"Content Awaited",
        medium:"Content Awaited",
        date_of_photograph:"Content Awaited",
        size:"Content Awaited",
        width_in_cm:"",
        height_in_inch:"",
        width_in_inch:"",
        height_in_mm:"",
        width_in_mm:"",
        location:"Content Awaited",
        quotation:"Content Awaited",
      },
      {
        img:gridImg17,
        fullImg:"",
        title_of_photograph:"Content Awaited",
        photographer:"Content Awaited",
        medium:"Content Awaited",
        date_of_photograph:"Content Awaited",
        size:"Content Awaited",
        width_in_cm:"",
        height_in_inch:"",
        width_in_inch:"",
        height_in_mm:"",
        width_in_mm:"",
        location:"Content Awaited",
        quotation:"Content Awaited",
      },{
        img:gridImg18,
        fullImg:"",
        title_of_photograph:"Content Awaited",
        photographer:"Content Awaited",
        medium:"Content Awaited",
        date_of_photograph:"Content Awaited",
        size:"Content Awaited",
        width_in_cm:"",
        height_in_inch:"",
        width_in_inch:"",
        height_in_mm:"",
        width_in_mm:"",
        location:"Content Awaited",
        quotation:"Content Awaited",
      },
      {
        img:gridImg18,
        fullImg:"",
        title_of_photograph:"Content Awaited",
        photographer:"Content Awaited",
        medium:"Content Awaited",
        date_of_photograph:"Content Awaited",
        size:"Content Awaited",
        width_in_cm:"",
        height_in_inch:"",
        width_in_inch:"",
        height_in_mm:"",
        width_in_mm:"",
        location:"Content Awaited",
        quotation:"Content Awaited",
      }
    ]
  },
  {
    id: "3",
    alias:"studio-clifton-co",
    researchCategoryAlias:"photography-in-india_3",
    name: "Studio Clifton & Co",
    image: GridImg2,
    description:"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    content:"Content Awaited",
    images:[
      {
        img:gridImg21,
        fullImg:fullImg51,
        title_of_photograph:"Content Awaited",
        photographer:"Content Awaited",
        medium:"Content Awaited",
        date_of_photograph:"Content Awaited",
        size:"Content Awaited",
        width_in_cm:"",
        height_in_inch:"",
        width_in_inch:"",
        height_in_mm:"",
        width_in_mm:"",
        location:"Content Awaited",
        quotation:"Content Awaited",
      },
      {
        img:gridImg22,
        fullImg:"",
        title_of_photograph:"Content Awaited",
        photographer:"Content Awaited",
        medium:"Content Awaited",
        date_of_photograph:"Content Awaited",
        size:"Content Awaited",
        width_in_cm:"",
        height_in_inch:"",
        width_in_inch:"",
        height_in_mm:"",
        width_in_mm:"",
        location:"Content Awaited",
        quotation:"Content Awaited",
      },
      {
        img:gridImg23,
        fullImg:fullImg53,
        title_of_photograph:"Content Awaited",
        photographer:"Content Awaited",
        medium:"Content Awaited",
        date_of_photograph:"Content Awaited",
        size:"Content Awaited",
        width_in_cm:"",
        height_in_inch:"",
        width_in_inch:"",
        height_in_mm:"",
        width_in_mm:"",
        location:"Content Awaited",
        quotation:"Content Awaited",
      },
      {
        img:gridImg24,
        fullImg:"",
        title_of_photograph:"Content Awaited",
        photographer:"Content Awaited",
        medium:"Content Awaited",
        date_of_photograph:"Content Awaited",
        size:"Content Awaited",
        width_in_cm:"",
        height_in_inch:"",
        width_in_inch:"",
        height_in_mm:"",
        width_in_mm:"",
        location:"Content Awaited",
        quotation:"Content Awaited",
      },
      {
        img:gridImg25,
        fullImg:"",
        title_of_photograph:"Content Awaited",
        photographer:"Content Awaited",
        medium:"Content Awaited",
        date_of_photograph:"Content Awaited",
        size:"Content Awaited",
        width_in_cm:"",
        height_in_inch:"",
        width_in_inch:"",
        height_in_mm:"",
        width_in_mm:"",
        location:"Content Awaited",
        quotation:"Content Awaited",
      },
      {
        img:gridImg26,
        fullImg:"",
        title_of_photograph:"Content Awaited",
        photographer:"Content Awaited",
        medium:"Content Awaited",
        date_of_photograph:"Content Awaited",
        size:"Content Awaited",
        width_in_cm:"",
        height_in_inch:"",
        width_in_inch:"",
        height_in_mm:"",
        width_in_mm:"",
        location:"Content Awaited",
        quotation:"Content Awaited",
      },
      {
        img:gridImg27,
        fullImg:"",
        title_of_photograph:"Content Awaited",
        photographer:"Content Awaited",
        medium:"Content Awaited",
        date_of_photograph:"Content Awaited",
        size:"Content Awaited",
        width_in_cm:"",
        height_in_inch:"",
        width_in_inch:"",
        height_in_mm:"",
        width_in_mm:"",
        location:"Content Awaited",
        quotation:"Content Awaited",
      },{
        img:gridImg28,
        fullImg:"",
        title_of_photograph:"Content Awaited",
        photographer:"Content Awaited",
        medium:"Content Awaited",
        date_of_photograph:"Content Awaited",
        size:"Content Awaited",
        width_in_cm:"",
        height_in_inch:"",
        width_in_inch:"",
        height_in_mm:"",
        width_in_mm:"",
        location:"Content Awaited",
        quotation:"Content Awaited",
      },
      {
        img:gridImg29,
        fullImg:"",
        title_of_photograph:"Content Awaited",
        photographer:"Content Awaited",
        medium:"Content Awaited",
        date_of_photograph:"Content Awaited",
        size:"Content Awaited",
        width_in_cm:"",
        height_in_inch:"",
        width_in_inch:"",
        height_in_mm:"",
        width_in_mm:"",
        location:"Content Awaited",
        quotation:"Content Awaited",
      }
    ]
  },
  {
    id: "4",
    alias:"rr-bharadwaj",
    researchCategoryAlias:"photography-in-india_3",
    name: "R.R.Bharadwaj",
    image: GridImg3,
    description:"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    content:"Content Awaited",
    images:[
      {
        img:gridImg31,
        fullImg:fullImg21,
        title_of_photograph:"Content Awaited",
        photographer:"Content Awaited",
        medium:"Content Awaited",
        date_of_photograph:"Content Awaited",
        size:"Content Awaited",
        width_in_cm:"",
        height_in_inch:"",
        width_in_inch:"",
        height_in_mm:"",
        width_in_mm:"",
        location:"Content Awaited",
        quotation:"Content Awaited",
      },
      {
        img:gridImg32,
        fullImg:fullImg22,
        title_of_photograph:"Content Awaited",
        photographer:"Content Awaited",
        medium:"Content Awaited",
        date_of_photograph:"Content Awaited",
        size:"Content Awaited",
        width_in_cm:"",
        height_in_inch:"",
        width_in_inch:"",
        height_in_mm:"",
        width_in_mm:"",
        location:"Content Awaited",
        quotation:"Content Awaited",
      },
      {
        img:gridImg33,
        fullImg:fullImg23,
        title_of_photograph:"Content Awaited",
        photographer:"Content Awaited",
        medium:"Content Awaited",
        date_of_photograph:"Content Awaited",
        size:"Content Awaited",
        width_in_cm:"",
        height_in_inch:"",
        width_in_inch:"",
        height_in_mm:"",
        width_in_mm:"",
        location:"Content Awaited",
        quotation:"Content Awaited",
      },
      {
        img:gridImg34,
        fullImg:"",
        title_of_photograph:"Content Awaited",
        photographer:"Content Awaited",
        medium:"Content Awaited",
        date_of_photograph:"Content Awaited",
        size:"Content Awaited",
        width_in_cm:"",
        height_in_inch:"",
        width_in_inch:"",
        height_in_mm:"",
        width_in_mm:"",
        location:"Content Awaited",
        quotation:"Content Awaited",
      },
      {
        img:gridImg35,
        fullImg:"",
        title_of_photograph:"Content Awaited",
        photographer:"Content Awaited",
        medium:"Content Awaited",
        date_of_photograph:"Content Awaited",
        size:"Content Awaited",
        width_in_cm:"",
        height_in_inch:"",
        width_in_inch:"",
        height_in_mm:"",
        width_in_mm:"",
        location:"Content Awaited",
        quotation:"Content Awaited",
      },
      {
        img:gridImg36,
        fullImg:"",
        title_of_photograph:"Content Awaited",
        photographer:"Content Awaited",
        medium:"Content Awaited",
        date_of_photograph:"Content Awaited",
        size:"Content Awaited",
        width_in_cm:"",
        height_in_inch:"",
        width_in_inch:"",
        height_in_mm:"",
        width_in_mm:"",
        location:"Content Awaited",
        quotation:"Content Awaited",
      },
      {
        img:gridImg37,
        fullImg:"",
        title_of_photograph:"Content Awaited",
        photographer:"Content Awaited",
        medium:"Content Awaited",
        date_of_photograph:"Content Awaited",
        size:"Content Awaited",
        width_in_cm:"",
        height_in_inch:"",
        width_in_inch:"",
        height_in_mm:"",
        width_in_mm:"",
        location:"Content Awaited",
        quotation:"Content Awaited",
      },{
        img:gridImg38,
        fullImg:"",
        title_of_photograph:"Content Awaited",
        photographer:"Content Awaited",
        medium:"Content Awaited",
        date_of_photograph:"Content Awaited",
        size:"Content Awaited",
        width_in_cm:"",
        height_in_inch:"",
        width_in_inch:"",
        height_in_mm:"",
        width_in_mm:"",
        location:"Content Awaited",
        quotation:"Content Awaited",
      },
      {
        img:gridImg39,
        fullImg:"",
        title_of_photograph:"Content Awaited",
        photographer:"Content Awaited",
        medium:"Content Awaited",
        date_of_photograph:"Content Awaited",
        size:"Content Awaited",
        width_in_cm:"",
        height_in_inch:"",
        width_in_inch:"",
        height_in_mm:"",
        width_in_mm:"",
        location:"Content Awaited",
        quotation:"Content Awaited",
      }
    ]
  },
  {
    id: "5",
    alias:"india-china-australia",
    researchCategoryAlias:"photography-in-india_3",
    name: "India, China, Australia",
    image: GridImg4,
    description:"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    content:"Content Awaited",
    images:[
      {
        img:gridImg41,
        fullImg:fullImg31,
        title_of_photograph:"Content Awaited",
        photographer:"Content Awaited",
        medium:"Content Awaited",
        date_of_photograph:"Content Awaited",
        size:"Content Awaited",
        width_in_cm:"",
        height_in_inch:"",
        width_in_inch:"",
        height_in_mm:"",
        width_in_mm:"",
        location:"Content Awaited",
        quotation:"Content Awaited",
      },
      {
        img:gridImg42,
        fullImg:fullImg32,
        title_of_photograph:"Content Awaited",
        photographer:"Content Awaited",
        medium:"Content Awaited",
        date_of_photograph:"Content Awaited",
        size:"Content Awaited",
        width_in_cm:"",
        height_in_inch:"",
        width_in_inch:"",
        height_in_mm:"",
        width_in_mm:"",
        location:"Content Awaited",
        quotation:"Content Awaited",
      },
      {
        img:gridImg43,
        fullImg:fullImg33,
        title_of_photograph:"Content Awaited",
        photographer:"Content Awaited",
        medium:"Content Awaited",
        date_of_photograph:"Content Awaited",
        size:"Content Awaited",
        width_in_cm:"",
        height_in_inch:"",
        width_in_inch:"",
        height_in_mm:"",
        width_in_mm:"",
        location:"Content Awaited",
        quotation:"Content Awaited",
      },
      {
        img:gridImg44,
        fullImg:"",
        title_of_photograph:"Content Awaited",
        photographer:"Content Awaited",
        medium:"Content Awaited",
        date_of_photograph:"Content Awaited",
        size:"Content Awaited",
        width_in_cm:"",
        height_in_inch:"",
        width_in_inch:"",
        height_in_mm:"",
        width_in_mm:"",
        location:"Content Awaited",
        quotation:"Content Awaited",
      },
      {
        img:gridImg45,
        fullImg:"",
        title_of_photograph:"Content Awaited",
        photographer:"Content Awaited",
        medium:"Content Awaited",
        date_of_photograph:"Content Awaited",
        size:"Content Awaited",
        width_in_cm:"",
        height_in_inch:"",
        width_in_inch:"",
        height_in_mm:"",
        width_in_mm:"",
        location:"Content Awaited",
        quotation:"Content Awaited",
      },
      {
        img:gridImg46,
        fullImg:"",
        title_of_photograph:"Content Awaited",
        photographer:"Content Awaited",
        medium:"Content Awaited",
        date_of_photograph:"Content Awaited",
        size:"Content Awaited",
        width_in_cm:"",
        height_in_inch:"",
        width_in_inch:"",
        height_in_mm:"",
        width_in_mm:"",
        location:"Content Awaited",
        quotation:"Content Awaited",
      },
      {
        img:gridImg47,
        fullImg:"",
        title_of_photograph:"Content Awaited",
        photographer:"Content Awaited",
        medium:"Content Awaited",
        date_of_photograph:"Content Awaited",
        size:"Content Awaited",
        width_in_cm:"",
        height_in_inch:"",
        width_in_inch:"",
        height_in_mm:"",
        width_in_mm:"",
        location:"Content Awaited",
        quotation:"Content Awaited",
      },{
        img:gridImg48,
        fullImg:"",
        title_of_photograph:"Content Awaited",
        photographer:"Content Awaited",
        medium:"Content Awaited",
        date_of_photograph:"Content Awaited",
        size:"Content Awaited",
        width_in_cm:"",
        height_in_inch:"",
        width_in_inch:"",
        height_in_mm:"",
        width_in_mm:"",
        location:"Content Awaited",
        quotation:"Content Awaited",
      },
      {
        img:gridImg49,
        fullImg:"",
        title_of_photograph:"Content Awaited",
        photographer:"Content Awaited",
        medium:"Content Awaited",
        date_of_photograph:"Content Awaited",
        size:"Content Awaited",
        width_in_cm:"",
        height_in_inch:"",
        width_in_inch:"",
        height_in_mm:"",
        width_in_mm:"",
        location:"Content Awaited",
        quotation:"Content Awaited",
      }
    ]
  },
  {
    id: "6",
    alias:"samuel-bourne",
    researchCategoryAlias:"photography-in-india_3",
    name: "Samuel Bourne",
    image: GridImg5,
    description:"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    content:"Content Awaited",
    images:[
      {
        img:gridImg51,
        fullImg:"",
        title_of_photograph:"Content Awaited",
        photographer:"Content Awaited",
        medium:"Content Awaited",
        date_of_photograph:"Content Awaited",
        size:"Content Awaited",
        width_in_cm:"",
        height_in_inch:"",
        width_in_inch:"",
        height_in_mm:"",
        width_in_mm:"",
        location:"Content Awaited",
        quotation:"Content Awaited",
      },
      {
        img:gridImg52,
        fullImg:fullImg52,
        title_of_photograph:"Content Awaited",
        photographer:"Content Awaited",
        medium:"Content Awaited",
        date_of_photograph:"Content Awaited",
        size:"Content Awaited",
        width_in_cm:"",
        height_in_inch:"",
        width_in_inch:"",
        height_in_mm:"",
        width_in_mm:"",
        location:"Content Awaited",
        quotation:"Content Awaited",
      },
      {
        img:gridImg53,
        fullImg:"",
        title_of_photograph:"Content Awaited",
        photographer:"Content Awaited",
        medium:"Content Awaited",
        date_of_photograph:"Content Awaited",
        size:"Content Awaited",
        width_in_cm:"",
        height_in_inch:"",
        width_in_inch:"",
        height_in_mm:"",
        width_in_mm:"",
        location:"Content Awaited",
        quotation:"Content Awaited",
      },
      {
        img:gridImg54,
        fullImg:"",
        title_of_photograph:"Content Awaited",
        photographer:"Content Awaited",
        medium:"Content Awaited",
        date_of_photograph:"Content Awaited",
        size:"Content Awaited",
        width_in_cm:"",
        height_in_inch:"",
        width_in_inch:"",
        height_in_mm:"",
        width_in_mm:"",
        location:"Content Awaited",
        quotation:"Content Awaited",
      },
      {
        img:gridImg55,
        fullImg:"",
        title_of_photograph:"Content Awaited",
        photographer:"Content Awaited",
        medium:"Content Awaited",
        date_of_photograph:"Content Awaited",
        size:"Content Awaited",
        width_in_cm:"",
        height_in_inch:"",
        width_in_inch:"",
        height_in_mm:"",
        width_in_mm:"",
        location:"Content Awaited",
        quotation:"Content Awaited",
      },
      {
        img:gridImg56,
        fullImg:"",
        title_of_photograph:"Content Awaited",
        photographer:"Content Awaited",
        medium:"Content Awaited",
        date_of_photograph:"Content Awaited",
        size:"Content Awaited",
        width_in_cm:"",
        height_in_inch:"",
        width_in_inch:"",
        height_in_mm:"",
        width_in_mm:"",
        location:"Content Awaited",
        quotation:"Content Awaited",
      },
      {
        img:gridImg57,
        fullImg:"",
        title_of_photograph:"Content Awaited",
        photographer:"Content Awaited",
        medium:"Content Awaited",
        date_of_photograph:"Content Awaited",
        size:"Content Awaited",
        width_in_cm:"",
        height_in_inch:"",
        width_in_inch:"",
        height_in_mm:"",
        width_in_mm:"",
        location:"Content Awaited",
        quotation:"Content Awaited",
      },{
        img:gridImg58,
        fullImg:"",
        title_of_photograph:"Content Awaited",
        photographer:"Content Awaited",
        medium:"Content Awaited",
        date_of_photograph:"Content Awaited",
        size:"Content Awaited",
        width_in_cm:"",
        height_in_inch:"",
        width_in_inch:"",
        height_in_mm:"",
        width_in_mm:"",
        location:"Content Awaited",
        quotation:"Content Awaited",
      },
      {
        img:gridImg59,
        fullImg:"",
        title_of_photograph:"Content Awaited",
        photographer:"Content Awaited",
        medium:"Content Awaited",
        date_of_photograph:"Content Awaited",
        size:"Content Awaited",
        width_in_cm:"",
        height_in_inch:"",
        width_in_inch:"",
        height_in_mm:"",
        width_in_mm:"",
        location:"Content Awaited",
        quotation:"Content Awaited",
      }
    ]
  },
  {
    id: "7",
    alias:"ketaki-sheth",
    researchCategoryAlias:"photography-in-india_3",
    name: "Ketaki Sheth",
    image: GridImg6,
    description:"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    content:"Content Awaited",
    images:[
      {
        img:gridImg61,
        fullImg:fullImg41,
        title_of_photograph:"Content Awaited",
        photographer:"Content Awaited",
        medium:"Content Awaited",
        date_of_photograph:"Content Awaited",
        size:"Content Awaited",
        width_in_cm:"",
        height_in_inch:"",
        width_in_inch:"",
        height_in_mm:"",
        width_in_mm:"",
        location:"Content Awaited",
        quotation:"Content Awaited",
      },
      {
        img:gridImg62,
        fullImg:fullImg42,
        title_of_photograph:"Content Awaited",
        photographer:"Content Awaited",
        medium:"Content Awaited",
        date_of_photograph:"Content Awaited",
        size:"Content Awaited",
        width_in_cm:"",
        height_in_inch:"",
        width_in_inch:"",
        height_in_mm:"",
        width_in_mm:"",
        location:"Content Awaited",
        quotation:"Content Awaited",
      },
      {
        img:gridImg63,
        fullImg:fullImg43,
        title_of_photograph:"Content Awaited",
        photographer:"Content Awaited",
        medium:"Content Awaited",
        date_of_photograph:"Content Awaited",
        size:"Content Awaited",
        width_in_cm:"",
        height_in_inch:"",
        width_in_inch:"",
        height_in_mm:"",
        width_in_mm:"",
        location:"Content Awaited",
        quotation:"Content Awaited",
      },
      {
        img:gridImg64,
        fullImg:"",
        title_of_photograph:"Content Awaited",
        photographer:"Content Awaited",
        medium:"Content Awaited",
        date_of_photograph:"Content Awaited",
        size:"Content Awaited",
        width_in_cm:"",
        height_in_inch:"",
        width_in_inch:"",
        height_in_mm:"",
        width_in_mm:"",
        location:"Content Awaited",
        quotation:"Content Awaited",
      },
      {
        img:gridImg65,
        fullImg:"",
        title_of_photograph:"Content Awaited",
        photographer:"Content Awaited",
        medium:"Content Awaited",
        date_of_photograph:"Content Awaited",
        size:"Content Awaited",
        width_in_cm:"",
        height_in_inch:"",
        width_in_inch:"",
        height_in_mm:"",
        width_in_mm:"",
        location:"Content Awaited",
        quotation:"Content Awaited",
      },
      {
        img:gridImg66,
        fullImg:"",
        title_of_photograph:"Content Awaited",
        photographer:"Content Awaited",
        medium:"Content Awaited",
        date_of_photograph:"Content Awaited",
        size:"Content Awaited",
        width_in_cm:"",
        height_in_inch:"",
        width_in_inch:"",
        height_in_mm:"",
        width_in_mm:"",
        location:"Content Awaited",
        quotation:"Content Awaited",
      },
      {
        img:gridImg67,
        fullImg:"",
        title_of_photograph:"Content Awaited",
        photographer:"Content Awaited",
        medium:"Content Awaited",
        date_of_photograph:"Content Awaited",
        size:"Content Awaited",
        width_in_cm:"",
        height_in_inch:"",
        width_in_inch:"",
        height_in_mm:"",
        width_in_mm:"",
        location:"Content Awaited",
        quotation:"Content Awaited",
      },{
        img:gridImg68,
        fullImg:"",
        title_of_photograph:"Content Awaited",
        photographer:"Content Awaited",
        medium:"Content Awaited",
        date_of_photograph:"Content Awaited",
        size:"Content Awaited",
        width_in_cm:"",
        height_in_inch:"",
        width_in_inch:"",
        height_in_mm:"",
        width_in_mm:"",
        location:"Content Awaited",
        quotation:"Content Awaited",
      },
      {
        img:gridImg69,
        fullImg:"",
        title_of_photograph:"Content Awaited",
        photographer:"Content Awaited",
        medium:"Content Awaited",
        date_of_photograph:"Content Awaited",
        size:"Content Awaited",
        width_in_cm:"",
        height_in_inch:"",
        width_in_inch:"",
        height_in_mm:"",
        width_in_mm:"",
        location:"Content Awaited",
        quotation:"Content Awaited",
      }
    ]
  },
  {
    id: "8",
    alias:"",
    researchCategoryAlias:"photography-in-india_3",
    name: "",
    image: GridImg7,
    description:"",
    content:"Content Awaited",
    gridImages: [],
  },
  {
    id: "9",
    alias:"",
    researchCategoryAlias:"photography-in-india_3",
    name: "",
    image: GridImg7,
    description:"",
    content:"Content Awaited",
    gridImages: [],
  },
  {
    id: "10",
    alias:"",
    researchCategoryAlias:"photography-in-india_3",
    name: "",
    image: GridImg7,
    description:"",
    content:"Content Awaited",
    images:[
      {
        img:"",
        fullImg:"",
        title_of_photograph:"Content Awaited",
        photographer:"Content Awaited",
        medium:"Content Awaited",
        date_of_photograph:"Content Awaited",
        size:"Content Awaited",
        width_in_cm:"",
        height_in_inch:"",
        width_in_inch:"",
        height_in_mm:"",
        width_in_mm:"",
        location:"Content Awaited",
        quotation:"Content Awaited",
      },
    ]
  },
  {
    id: "11",
    alias:"",
    researchCategoryAlias:"photography-in-india_3",
    name: "",
    image: GridImg7,
    description:"",
    content:"Content Awaited",
    images:[
      {
        img:"",
        fullImg:"",
        title_of_photograph:"Content Awaited",
        photographer:"Content Awaited",
        medium:"Content Awaited",
        date_of_photograph:"Content Awaited",
        size:"Content Awaited",
        width_in_cm:"",
        height_in_inch:"",
        width_in_inch:"",
        height_in_mm:"",
        width_in_mm:"",
        location:"Content Awaited",
        quotation:"Content Awaited",
      },
    ]
  },
  {
    id: "12",
    alias:"",
    researchCategoryAlias:"photography-in-india_3",
    name: "",
    image: GridImg7,
    description:"",
    content:"Content Awaited",
    images:[
      {
        img:"",
        fullImg:"",
        title_of_photograph:"Content Awaited",
        photographer:"Content Awaited",
        medium:"Content Awaited",
        date_of_photograph:"Content Awaited",
        size:"Content Awaited",
        width_in_cm:"",
        height_in_inch:"",
        width_in_inch:"",
        height_in_mm:"",
        width_in_mm:"",
        location:"Content Awaited",
        quotation:"Content Awaited",
      },
    ]
  },
];

//RC_Photography_In_India Post data
export const photographyInIndiaPosts = [
  {
    id: "1",
    heading1: "Heading 1",
    heading2: "Heading 2",
    title:"Heading 1",
    image: [PostImg1],
    fullImage:fullpost1,
    desc:"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in ",
    desc1:"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in ",
    
    // desc: "Due to its sensitive nature and bold subject, the film Garam Hawa naturally  attracted  varied reactions from Indian politicians. They ranged from helping comment, to an accusation level,and even reaching to violent threat.In 1972-3 Censor Board of  India was dithered in giving the permission to release the film, as its members were unable to decide what will happen if  it is released and  the implying the abdication of the responsibility given to the Board.  Director of the film M S Sathyu approached to then Information and Broadcasting Minister I K Gujral and matter reached to the office of Prime Minister of India.",
    // desc1:"Most constant stand was taken by the then prime minister Indira Gandhi, whose involvement helped in clearing the censor certificate for the film, struck-up with Censor  Board of  India for a long time, nearly 11 months.",
    // desc2:"A quote from the book, India's Film Society Movement: The Journey and its Impact by VK Cherian (Pp 51-52) gives us the idea what tribulations the film-makers in free India have to face :",
    // desc3:"According to Delhi Film society’s last president, Gautam Kaul, it was her personal intervention that saw the film getting released. ",
    // desc4:"I got a call from her saying that, ‘you are a film society man’ come and see what is so objectionable in Garam Hawa. The film was shown in the basement theatre at the Rashtrapati Bhavan, which was the favorite screening place of the prime minister. After the screening Gandhi looked back and told me, ‘what is objectionable in this film may be the noble family Muslim girl romancing on the shores of Yamuna at Agra. That we will be able to handle it .. is it not !’A waiting Sathyu asked Gautam Kaul, what her response was. Kaul said, ‘It is done’, ending the long uncertainty over the film’s release.",
    // desc5:"Another politician, of Jana Sangh (before BJP),  L K Advani accused MS Sathyu and then later apologized. Talking to Sidharth Bhatia of The Wire magazine in July 2020,  M S Sathyu says :Even L K Advani had written wrongly about the film in their newspaper, the party paper. He  said that it looks as if this film has been financed by Pakistan. I mean, this is a comment which Advani made. Later on, when I met him sometime in Madras at a film festival, he said, “I’m very sorry. I made a mistake. I had not seen the film but wrote it because I got a report from somebody about the film”. So he apologized for having written that. Before its release in Mumbai in 1974, right-wing political party, Shiv Sena’s chief Bal Thakrey had threatened to burn down the cinema hall, if the film was not shown to him beforehand.",
    primaryButton: [
      { name: "Garm Hava", link: `/garm-hava` },
      { name: "Balraj Sahani", link: "/balraj-sahani" },
    ],
    secondaryButton: [
      { name: "MS Sathyu", link: false },
    ],
    keyWordButtons: [{ name: "IPTA", link: `/IPTA` }],
    alias:"post1"
  },
  {
    id: "2",
    heading1: "Heading 1",
    heading2: "Heading 2",
    title:"Heading 1",
    image: [PostImg2],
    fullImage:fullpost2,
    desc:"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in ",
    desc1:"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in ",
    primaryButton: [
      { name: "Garm Hava", link: `/garm-hava` },
      { name: "Balraj Sahani", link: "/balraj-sahani" },
    ],
    secondaryButton: [
      { name: "MS Sathyu", link: false },
    ],
    keyWordButtons: [{ name: "IPTA", link: `/IPTA` }],
    alias:"post2"
  },
  {
    id: "3",
    heading1: "Heading 1",
    heading2: "Heading 2",
    title:"Heading 1",
    image: [PostImg3],
    fullImage:fullpost3,
    desc:"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in ",
    desc1:"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in ",
    // desc: "Due to its sensitive nature and bold subject, the film Garam Hawa naturally  attracted  varied reactions from Indian politicians. They ranged from helping comment, to an accusation level,and even reaching to violent threat.In 1972-3 Censor Board of  India was dithered in giving the permission to release the film, as its members were unable to decide what will happen if  it is released and  the implying the abdication of the responsibility given to the Board.  Director of the film M S Sathyu approached to then Information and Broadcasting Minister I K Gujral and matter reached to the office of Prime Minister of India.",
    // desc1:"Most constant stand was taken by the then prime minister Indira Gandhi, whose involvement helped in clearing the censor certificate for the film, struck-up with Censor  Board of  India for a long time, nearly 11 months.",
    // desc2:"A quote from the book, India's Film Society Movement: The Journey and its Impact by VK Cherian (Pp 51-52) gives us the idea what tribulations the film-makers in free India have to face :",
    // desc3:"According to Delhi Film society’s last president, Gautam Kaul, it was her personal intervention that saw the film getting released. ",
    // desc4:"I got a call from her saying that, ‘you are a film society man’ come and see what is so objectionable in Garam Hawa. The film was shown in the basement theatre at the Rashtrapati Bhavan, which was the favorite screening place of the prime minister. After the screening Gandhi looked back and told me, ‘what is objectionable in this film may be the noble family Muslim girl romancing on the shores of Yamuna at Agra. That we will be able to handle it .. is it not !’A waiting Sathyu asked Gautam Kaul, what her response was. Kaul said, ‘It is done’, ending the long uncertainty over the film’s release.",
    // desc5:"Another politician, of Jana Sangh (before BJP),  L K Advani accused MS Sathyu and then later apologized. Talking to Sidharth Bhatia of The Wire magazine in July 2020,  M S Sathyu says :Even L K Advani had written wrongly about the film in their newspaper, the party paper. He  said that it looks as if this film has been financed by Pakistan. I mean, this is a comment which Advani made. Later on, when I met him sometime in Madras at a film festival, he said, “I’m very sorry. I made a mistake. I had not seen the film but wrote it because I got a report from somebody about the film”. So he apologized for having written that. Before its release in Mumbai in 1974, right-wing political party, Shiv Sena’s chief Bal Thakrey had threatened to burn down the cinema hall, if the film was not shown to him beforehand.",
    primaryButton: [
      { name: "Garm Hava", link: `/garm-hava` },
      { name: "Balraj Sahani", link: "/balraj-sahani" },
    ],
    secondaryButton: [
      { name: "MS Sathyu", link: false },
    ],
    keyWordButtons: [{ name: "IPTA", link: `/IPTA` }],
    alias:"post3"
  },
  {
    id: "4",
    heading1: "Heading 1",
    heading2: "Heading 2",
    title:"Heading 1",
    image: [PostImg4],
    fullImage:fullpost4,
    desc:"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in ",
    desc1:"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in ",
    // desc: "Due to its sensitive nature and bold subject, the film Garam Hawa naturally  attracted  varied reactions from Indian politicians. They ranged from helping comment, to an accusation level,and even reaching to violent threat.In 1972-3 Censor Board of  India was dithered in giving the permission to release the film, as its members were unable to decide what will happen if  it is released and  the implying the abdication of the responsibility given to the Board.  Director of the film M S Sathyu approached to then Information and Broadcasting Minister I K Gujral and matter reached to the office of Prime Minister of India.",
    // desc1:"Most constant stand was taken by the then prime minister Indira Gandhi, whose involvement helped in clearing the censor certificate for the film, struck-up with Censor  Board of  India for a long time, nearly 11 months.",
    // desc2:"A quote from the book, India's Film Society Movement: The Journey and its Impact by VK Cherian (Pp 51-52) gives us the idea what tribulations the film-makers in free India have to face :",
    // desc3:"According to Delhi Film society’s last president, Gautam Kaul, it was her personal intervention that saw the film getting released. ",
    // desc4:"I got a call from her saying that, ‘you are a film society man’ come and see what is so objectionable in Garam Hawa. The film was shown in the basement theatre at the Rashtrapati Bhavan, which was the favorite screening place of the prime minister. After the screening Gandhi looked back and told me, ‘what is objectionable in this film may be the noble family Muslim girl romancing on the shores of Yamuna at Agra. That we will be able to handle it .. is it not !’A waiting Sathyu asked Gautam Kaul, what her response was. Kaul said, ‘It is done’, ending the long uncertainty over the film’s release.",
    // desc5:"Another politician, of Jana Sangh (before BJP),  L K Advani accused MS Sathyu and then later apologized. Talking to Sidharth Bhatia of The Wire magazine in July 2020,  M S Sathyu says :Even L K Advani had written wrongly about the film in their newspaper, the party paper. He  said that it looks as if this film has been financed by Pakistan. I mean, this is a comment which Advani made. Later on, when I met him sometime in Madras at a film festival, he said, “I’m very sorry. I made a mistake. I had not seen the film but wrote it because I got a report from somebody about the film”. So he apologized for having written that. Before its release in Mumbai in 1974, right-wing political party, Shiv Sena’s chief Bal Thakrey had threatened to burn down the cinema hall, if the film was not shown to him beforehand.",
    primaryButton: [
      { name: "Garm Hava", link: `/garm-hava` },
      { name: "Balraj Sahani", link: "/balraj-sahani" },
    ],
    secondaryButton: [
      { name: "MS Sathyu", link: false },
    ],
    keyWordButtons: [{ name: "IPTA", link: `/IPTA` }],
    alias:"post4"
  },
  {
    id: "5",
    heading1: "Heading 1",
    heading2: "Heading 2",
    title:"Heading 1",
    image: [PostImg5],
    fullImage:fullpost5,
    desc:"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in ",
    desc1:"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in ",
    // desc: "Due to its sensitive nature and bold subject, the film Garam Hawa naturally  attracted  varied reactions from Indian politicians. They ranged from helping comment, to an accusation level,and even reaching to violent threat.In 1972-3 Censor Board of  India was dithered in giving the permission to release the film, as its members were unable to decide what will happen if  it is released and  the implying the abdication of the responsibility given to the Board.  Director of the film M S Sathyu approached to then Information and Broadcasting Minister I K Gujral and matter reached to the office of Prime Minister of India.",
    // desc1:"Most constant stand was taken by the then prime minister Indira Gandhi, whose involvement helped in clearing the censor certificate for the film, struck-up with Censor  Board of  India for a long time, nearly 11 months.",
    // desc2:"A quote from the book, India's Film Society Movement: The Journey and its Impact by VK Cherian (Pp 51-52) gives us the idea what tribulations the film-makers in free India have to face :",
    // desc3:"According to Delhi Film society’s last president, Gautam Kaul, it was her personal intervention that saw the film getting released. ",
    // desc4:"I got a call from her saying that, ‘you are a film society man’ come and see what is so objectionable in Garam Hawa. The film was shown in the basement theatre at the Rashtrapati Bhavan, which was the favorite screening place of the prime minister. After the screening Gandhi looked back and told me, ‘what is objectionable in this film may be the noble family Muslim girl romancing on the shores of Yamuna at Agra. That we will be able to handle it .. is it not !’A waiting Sathyu asked Gautam Kaul, what her response was. Kaul said, ‘It is done’, ending the long uncertainty over the film’s release.",
    // desc5:"Another politician, of Jana Sangh (before BJP),  L K Advani accused MS Sathyu and then later apologized. Talking to Sidharth Bhatia of The Wire magazine in July 2020,  M S Sathyu says :Even L K Advani had written wrongly about the film in their newspaper, the party paper. He  said that it looks as if this film has been financed by Pakistan. I mean, this is a comment which Advani made. Later on, when I met him sometime in Madras at a film festival, he said, “I’m very sorry. I made a mistake. I had not seen the film but wrote it because I got a report from somebody about the film”. So he apologized for having written that. Before its release in Mumbai in 1974, right-wing political party, Shiv Sena’s chief Bal Thakrey had threatened to burn down the cinema hall, if the film was not shown to him beforehand.",
    primaryButton: [
      { name: "Garm Hava", link: `/garm-hava` },
      { name: "Balraj Sahani", link: "/balraj-sahani" },
    ],
    secondaryButton: [
      { name: "MS Sathyu", link: false },
    ],
    keyWordButtons: [{ name: "IPTA", link: `/IPTA` }],
    alias:"post5"
  },
];

export const economicsOfCultureIntroduction = [
  {
    alias: "economics-of-culture",
    title: "Economics of Culture",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Lorem ipsum dolor sit",
    images: [dummy, dummy],
  },
];

export const economicsOfCultureMasterlist = [
  {
    id: "1",
    alias:"sh-raza",
    researchCategoryAlias:"photography-in-india_3",
    name: "Sh Raza",
    image: SHRaza,
    link:"/person-artist/sayed-haider-raza",
    description:"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    content:"Content Awaited",
    images:[
      {
        img:gridImg01,
        fullImg:fullImg1,
        title_of_photograph:"Darjeeling Train (India)",
        photographer:"Raghu Rai",
        medium:"Ilford-Multigrade RC– Pearl Warmtone",
        date_of_photograph:"1995",
        size:"20.0 x 24.0 in (50.8 x 61.0cm;508.0 x 610.0 mm)",
        width_in_cm:"",
        height_in_inch:"",
        width_in_inch:"",
        height_in_mm:"",
        width_in_mm:"",
        location:"Darjeeling",
        quotation:"Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.Lorem ipsum is placeholder text commonly used in the ",
      },
      {
        img:gridImg02,
        fullImg:fullImg2,
        title_of_photograph:"Darjeeling Train (India)",
        photographer:"Raghu Rai",
        medium:"Ilford-Multigrade RC– Pearl Warmtone",
        date_of_photograph:"1995",
        size:"20.0 x 24.0 in (50.8 x 61.0cm;508.0 x 610.0 mm)",
        width_in_cm:"",
        height_in_inch:"",
        width_in_inch:"",
        height_in_mm:"",
        width_in_mm:"",
        location:"Darjeeling",
        quotation:"Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.Lorem ipsum is placeholder text commonly used in the ",
      },
      {
        img:gridImg03,
        fullImg:fullImg3,
        title_of_photograph:"Darjeeling Train (India)",
        photographer:"Raghu Rai",
        medium:"Ilford-Multigrade RC– Pearl Warmtone",
        date_of_photograph:"1995",
        size:"20.0 x 24.0 in (50.8 x 61.0cm;508.0 x 610.0 mm)",
        width_in_cm:"",
        height_in_inch:"",
        width_in_inch:"",
        height_in_mm:"",
        width_in_mm:"",
        location:"Darjeeling",
        quotation:"Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.Lorem ipsum is placeholder text commonly used in the ",
      },
      {
        img:gridImg04,
        fullImg:"",
        title_of_photograph:"Content Awaited",
        photographer:"Content Awaited",
        medium:"Content Awaited",
        date_of_photograph:"Content Awaited",
        size:"Content Awaited",
        width_in_cm:"",
        height_in_inch:"",
        width_in_inch:"",
        height_in_mm:"",
        width_in_mm:"",
        location:"Content Awaited",
        quotation:"Content Awaited",
      },
      {
        img:gridImg05,
        fullImg:"",
        title_of_photograph:"Content Awaited",
        photographer:"Content Awaited",
        medium:"Content Awaited",
        date_of_photograph:"Content Awaited",
        size:"Content Awaited",
        width_in_cm:"",
        height_in_inch:"",
        width_in_inch:"",
        height_in_mm:"",
        width_in_mm:"",
        location:"Content Awaited",
        quotation:"Content Awaited",
      },
      {
        img:gridImg06,
        fullImg:"",
        title_of_photograph:"Content Awaited",
        photographer:"Content Awaited",
        medium:"Content Awaited",
        date_of_photograph:"Content Awaited",
        size:"Content Awaited",
        width_in_cm:"",
        height_in_inch:"",
        width_in_inch:"",
        height_in_mm:"",
        width_in_mm:"",
        location:"Content Awaited",
        quotation:"Content Awaited",
      },
      {
        img:gridImg07,
        fullImg:"",
        title_of_photograph:"Content Awaited",
        photographer:"Content Awaited",
        medium:"Content Awaited",
        date_of_photograph:"Content Awaited",
        size:"Content Awaited",
        width_in_cm:"",
        height_in_inch:"",
        width_in_inch:"",
        height_in_mm:"",
        width_in_mm:"",
        location:"Content Awaited",
        quotation:"Content Awaited",
      },{
        img:gridImg08,
        fullImg:"",
        title_of_photograph:"Content Awaited",
        photographer:"Content Awaited",
        medium:"Content Awaited",
        date_of_photograph:"Content Awaited",
        size:"Content Awaited",
        width_in_cm:"",
        height_in_inch:"",
        width_in_inch:"",
        height_in_mm:"",
        width_in_mm:"",
        location:"Content Awaited",
        quotation:"Content Awaited",
      },
      {
        img:gridImg09,
        fullImg:"",
        title_of_photograph:"Content Awaited",
        photographer:"Content Awaited",
        medium:"Content Awaited",
        date_of_photograph:"Content Awaited",
        size:"Content Awaited",
        width_in_cm:"",
        height_in_inch:"",
        width_in_inch:"",
        height_in_mm:"",
        width_in_mm:"",
        location:"Content Awaited",
        quotation:"Content Awaited",
      }
    ]
  },
  {
    id: "2",
    alias:"kg-subramanyan",
    researchCategoryAlias:"photography-in-india_3",
    link:"/person-artist/k.g-Subramanyan",
    name: "KG Subramanyan",
    image: KGSubra,
    description:"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    content:"Content Awaited",
    images:[
      {
        img:gridImg11,
        fullImg:fullImg11,
        title_of_photograph:"Content Awaited",
        photographer:"Content Awaited",
        medium:"Content Awaited",
        date_of_photograph:"Content Awaited",
        size:"Content Awaited",
        width_in_cm:"",
        height_in_inch:"",
        width_in_inch:"",
        height_in_mm:"",
        width_in_mm:"",
        location:"Content Awaited",
        quotation:"Content Awaited",
      },
      {
        img:gridImg12,
        fullImg:fullImg12,
        title_of_photograph:"Content Awaited",
        photographer:"Content Awaited",
        medium:"Content Awaited",
        date_of_photograph:"Content Awaited",
        size:"Content Awaited",
        width_in_cm:"",
        height_in_inch:"",
        width_in_inch:"",
        height_in_mm:"",
        width_in_mm:"",
        location:"Content Awaited",
        quotation:"Content Awaited",
      },
      {
        img:gridImg13,
        fullImg:fullImg13,
        title_of_photograph:"Content Awaited",
        photographer:"Content Awaited",
        medium:"Content Awaited",
        date_of_photograph:"Content Awaited",
        size:"Content Awaited",
        width_in_cm:"",
        height_in_inch:"",
        width_in_inch:"",
        height_in_mm:"",
        width_in_mm:"",
        location:"Content Awaited",
        quotation:"Content Awaited",
      },
      {
        img:gridImg14,
        fullImg:"",
        title_of_photograph:"Content Awaited",
        photographer:"Content Awaited",
        medium:"Content Awaited",
        date_of_photograph:"Content Awaited",
        size:"Content Awaited",
        width_in_cm:"",
        height_in_inch:"",
        width_in_inch:"",
        height_in_mm:"",
        width_in_mm:"",
        location:"Content Awaited",
        quotation:"Content Awaited",
      },
      {
        img:gridImg15,
        fullImg:"",
        title_of_photograph:"Content Awaited",
        photographer:"Content Awaited",
        medium:"Content Awaited",
        date_of_photograph:"Content Awaited",
        size:"Content Awaited",
        width_in_cm:"",
        height_in_inch:"",
        width_in_inch:"",
        height_in_mm:"",
        width_in_mm:"",
        location:"Content Awaited",
        quotation:"Content Awaited",
      },
      {
        img:gridImg16,
        fullImg:"",
        title_of_photograph:"Content Awaited",
        photographer:"Content Awaited",
        medium:"Content Awaited",
        date_of_photograph:"Content Awaited",
        size:"Content Awaited",
        width_in_cm:"",
        height_in_inch:"",
        width_in_inch:"",
        height_in_mm:"",
        width_in_mm:"",
        location:"Content Awaited",
        quotation:"Content Awaited",
      },
      {
        img:gridImg17,
        fullImg:"",
        title_of_photograph:"Content Awaited",
        photographer:"Content Awaited",
        medium:"Content Awaited",
        date_of_photograph:"Content Awaited",
        size:"Content Awaited",
        width_in_cm:"",
        height_in_inch:"",
        width_in_inch:"",
        height_in_mm:"",
        width_in_mm:"",
        location:"Content Awaited",
        quotation:"Content Awaited",
      },{
        img:gridImg18,
        fullImg:"",
        title_of_photograph:"Content Awaited",
        photographer:"Content Awaited",
        medium:"Content Awaited",
        date_of_photograph:"Content Awaited",
        size:"Content Awaited",
        width_in_cm:"",
        height_in_inch:"",
        width_in_inch:"",
        height_in_mm:"",
        width_in_mm:"",
        location:"Content Awaited",
        quotation:"Content Awaited",
      },
      {
        img:gridImg18,
        fullImg:"",
        title_of_photograph:"Content Awaited",
        photographer:"Content Awaited",
        medium:"Content Awaited",
        date_of_photograph:"Content Awaited",
        size:"Content Awaited",
        width_in_cm:"",
        height_in_inch:"",
        width_in_inch:"",
        height_in_mm:"",
        width_in_mm:"",
        location:"Content Awaited",
        quotation:"Content Awaited",
      }
    ]
  },
  {
    id: "3",
    alias:"satyajit-ray",
    researchCategoryAlias:"photography-in-india_3",
    link:"/person-poly/satyajit-ray",
    name: "Satyajit Ray",
    image: SatyajitRay,
    description:"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    content:"Content Awaited",
    images:[
      {
        img:gridImg21,
        fullImg:fullImg51,
        title_of_photograph:"Content Awaited",
        photographer:"Content Awaited",
        medium:"Content Awaited",
        date_of_photograph:"Content Awaited",
        size:"Content Awaited",
        width_in_cm:"",
        height_in_inch:"",
        width_in_inch:"",
        height_in_mm:"",
        width_in_mm:"",
        location:"Content Awaited",
        quotation:"Content Awaited",
      },
      {
        img:gridImg22,
        fullImg:"",
        title_of_photograph:"Content Awaited",
        photographer:"Content Awaited",
        medium:"Content Awaited",
        date_of_photograph:"Content Awaited",
        size:"Content Awaited",
        width_in_cm:"",
        height_in_inch:"",
        width_in_inch:"",
        height_in_mm:"",
        width_in_mm:"",
        location:"Content Awaited",
        quotation:"Content Awaited",
      },
      {
        img:gridImg23,
        fullImg:fullImg53,
        title_of_photograph:"Content Awaited",
        photographer:"Content Awaited",
        medium:"Content Awaited",
        date_of_photograph:"Content Awaited",
        size:"Content Awaited",
        width_in_cm:"",
        height_in_inch:"",
        width_in_inch:"",
        height_in_mm:"",
        width_in_mm:"",
        location:"Content Awaited",
        quotation:"Content Awaited",
      },
      {
        img:gridImg24,
        fullImg:"",
        title_of_photograph:"Content Awaited",
        photographer:"Content Awaited",
        medium:"Content Awaited",
        date_of_photograph:"Content Awaited",
        size:"Content Awaited",
        width_in_cm:"",
        height_in_inch:"",
        width_in_inch:"",
        height_in_mm:"",
        width_in_mm:"",
        location:"Content Awaited",
        quotation:"Content Awaited",
      },
      {
        img:gridImg25,
        fullImg:"",
        title_of_photograph:"Content Awaited",
        photographer:"Content Awaited",
        medium:"Content Awaited",
        date_of_photograph:"Content Awaited",
        size:"Content Awaited",
        width_in_cm:"",
        height_in_inch:"",
        width_in_inch:"",
        height_in_mm:"",
        width_in_mm:"",
        location:"Content Awaited",
        quotation:"Content Awaited",
      },
      {
        img:gridImg26,
        fullImg:"",
        title_of_photograph:"Content Awaited",
        photographer:"Content Awaited",
        medium:"Content Awaited",
        date_of_photograph:"Content Awaited",
        size:"Content Awaited",
        width_in_cm:"",
        height_in_inch:"",
        width_in_inch:"",
        height_in_mm:"",
        width_in_mm:"",
        location:"Content Awaited",
        quotation:"Content Awaited",
      },
      {
        img:gridImg27,
        fullImg:"",
        title_of_photograph:"Content Awaited",
        photographer:"Content Awaited",
        medium:"Content Awaited",
        date_of_photograph:"Content Awaited",
        size:"Content Awaited",
        width_in_cm:"",
        height_in_inch:"",
        width_in_inch:"",
        height_in_mm:"",
        width_in_mm:"",
        location:"Content Awaited",
        quotation:"Content Awaited",
      },{
        img:gridImg28,
        fullImg:"",
        title_of_photograph:"Content Awaited",
        photographer:"Content Awaited",
        medium:"Content Awaited",
        date_of_photograph:"Content Awaited",
        size:"Content Awaited",
        width_in_cm:"",
        height_in_inch:"",
        width_in_inch:"",
        height_in_mm:"",
        width_in_mm:"",
        location:"Content Awaited",
        quotation:"Content Awaited",
      },
      {
        img:gridImg29,
        fullImg:"",
        title_of_photograph:"Content Awaited",
        photographer:"Content Awaited",
        medium:"Content Awaited",
        date_of_photograph:"Content Awaited",
        size:"Content Awaited",
        width_in_cm:"",
        height_in_inch:"",
        width_in_inch:"",
        height_in_mm:"",
        width_in_mm:"",
        location:"Content Awaited",
        quotation:"Content Awaited",
      }
    ]
  },
  {
    id: "4",
    alias:"shatranj-ke-khilari",
    researchCategoryAlias:"photography-in-india_3",
    name: "Shatranj ke Khilari",
    link:"/film/shatranj-ke-khilari-1977",
    image: skk1,
    description:"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    content:"Content Awaited",
    images:[
      {
        img:gridImg31,
        fullImg:fullImg21,
        title_of_photograph:"Content Awaited",
        photographer:"Content Awaited",
        medium:"Content Awaited",
        date_of_photograph:"Content Awaited",
        size:"Content Awaited",
        width_in_cm:"",
        height_in_inch:"",
        width_in_inch:"",
        height_in_mm:"",
        width_in_mm:"",
        location:"Content Awaited",
        quotation:"Content Awaited",
      },
      {
        img:gridImg32,
        fullImg:fullImg22,
        title_of_photograph:"Content Awaited",
        photographer:"Content Awaited",
        medium:"Content Awaited",
        date_of_photograph:"Content Awaited",
        size:"Content Awaited",
        width_in_cm:"",
        height_in_inch:"",
        width_in_inch:"",
        height_in_mm:"",
        width_in_mm:"",
        location:"Content Awaited",
        quotation:"Content Awaited",
      },
      {
        img:gridImg33,
        fullImg:fullImg23,
        title_of_photograph:"Content Awaited",
        photographer:"Content Awaited",
        medium:"Content Awaited",
        date_of_photograph:"Content Awaited",
        size:"Content Awaited",
        width_in_cm:"",
        height_in_inch:"",
        width_in_inch:"",
        height_in_mm:"",
        width_in_mm:"",
        location:"Content Awaited",
        quotation:"Content Awaited",
      },
      {
        img:gridImg34,
        fullImg:"",
        title_of_photograph:"Content Awaited",
        photographer:"Content Awaited",
        medium:"Content Awaited",
        date_of_photograph:"Content Awaited",
        size:"Content Awaited",
        width_in_cm:"",
        height_in_inch:"",
        width_in_inch:"",
        height_in_mm:"",
        width_in_mm:"",
        location:"Content Awaited",
        quotation:"Content Awaited",
      },
      {
        img:gridImg35,
        fullImg:"",
        title_of_photograph:"Content Awaited",
        photographer:"Content Awaited",
        medium:"Content Awaited",
        date_of_photograph:"Content Awaited",
        size:"Content Awaited",
        width_in_cm:"",
        height_in_inch:"",
        width_in_inch:"",
        height_in_mm:"",
        width_in_mm:"",
        location:"Content Awaited",
        quotation:"Content Awaited",
      },
      {
        img:gridImg36,
        fullImg:"",
        title_of_photograph:"Content Awaited",
        photographer:"Content Awaited",
        medium:"Content Awaited",
        date_of_photograph:"Content Awaited",
        size:"Content Awaited",
        width_in_cm:"",
        height_in_inch:"",
        width_in_inch:"",
        height_in_mm:"",
        width_in_mm:"",
        location:"Content Awaited",
        quotation:"Content Awaited",
      },
      {
        img:gridImg37,
        fullImg:"",
        title_of_photograph:"Content Awaited",
        photographer:"Content Awaited",
        medium:"Content Awaited",
        date_of_photograph:"Content Awaited",
        size:"Content Awaited",
        width_in_cm:"",
        height_in_inch:"",
        width_in_inch:"",
        height_in_mm:"",
        width_in_mm:"",
        location:"Content Awaited",
        quotation:"Content Awaited",
      },{
        img:gridImg38,
        fullImg:"",
        title_of_photograph:"Content Awaited",
        photographer:"Content Awaited",
        medium:"Content Awaited",
        date_of_photograph:"Content Awaited",
        size:"Content Awaited",
        width_in_cm:"",
        height_in_inch:"",
        width_in_inch:"",
        height_in_mm:"",
        width_in_mm:"",
        location:"Content Awaited",
        quotation:"Content Awaited",
      },
      {
        img:gridImg39,
        fullImg:"",
        title_of_photograph:"Content Awaited",
        photographer:"Content Awaited",
        medium:"Content Awaited",
        date_of_photograph:"Content Awaited",
        size:"Content Awaited",
        width_in_cm:"",
        height_in_inch:"",
        width_in_inch:"",
        height_in_mm:"",
        width_in_mm:"",
        location:"Content Awaited",
        quotation:"Content Awaited",
      }
    ]
  },

];