//S3 Bucket osw-dev-images
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;

const Banner1 = `${IMAGE_BASE_PATH}/ui/Film/ShatranjKeKhilari/Posts/PostBanner/banner4.png`
const Banner2 = `${IMAGE_BASE_PATH}/ui/Film/ShatranjKeKhilari/Posts/PostBanner/banner1.png`
const img1 = `${IMAGE_BASE_PATH}/ui/Film/ShatranjKeKhilari/Insights/DetailInsight/FullImage/1.png`
const smImg1 = `${IMAGE_BASE_PATH}/ui/Film/ShatranjKeKhilari/Insights/DetailInsight/SmallImage/1.png`
const img2 = `${IMAGE_BASE_PATH}/ui/Film/ShatranjKeKhilari/Insights/DetailInsight/FullImage/2.png`
const smImg2 = `${IMAGE_BASE_PATH}/ui/Film/ShatranjKeKhilari/Insights/DetailInsight/SmallImage/2.png`
const img3 = `${IMAGE_BASE_PATH}/ui/Film/ShatranjKeKhilari/Insights/DetailInsight/FullImage/3.png`
const smImg3 = `${IMAGE_BASE_PATH}/ui/Film/ShatranjKeKhilari/Insights/DetailInsight/SmallImage/3.png`
const img4 = `${IMAGE_BASE_PATH}/ui/Film/ShatranjKeKhilari/Insights/DetailInsight/FullImage/4.png`
const smImg4 = `${IMAGE_BASE_PATH}/ui/Film/ShatranjKeKhilari/Insights/DetailInsight/SmallImage/4.png`
const img5 = `${IMAGE_BASE_PATH}/ui/Film/ShatranjKeKhilari/Insights/DetailInsight/FullImage/5.png`
const smImg5 = `${IMAGE_BASE_PATH}/ui/Film/ShatranjKeKhilari/Insights/DetailInsight/SmallImage/5.png`
const img6 = `${IMAGE_BASE_PATH}/ui/Film/ShatranjKeKhilari/Insights/DetailInsight/FullImage/6.png`
const smImg6 = `${IMAGE_BASE_PATH}/ui/Film/ShatranjKeKhilari/Insights/DetailInsight/SmallImage/6.png`
const img7 = `${IMAGE_BASE_PATH}/ui/Film/ShatranjKeKhilari/Insights/DetailInsight/FullImage/7.png`
const smImg7 = `${IMAGE_BASE_PATH}/ui/Film/ShatranjKeKhilari/Insights/DetailInsight/SmallImage/7.png`
const img8 = `${IMAGE_BASE_PATH}/ui/Film/ShatranjKeKhilari/DummyImage/1.png`
const smImg8 = `${IMAGE_BASE_PATH}/ui/Film/ShatranjKeKhilari/Insights/DetailInsight/SmallImage/8.png`
const img9 = `${IMAGE_BASE_PATH}/ui/Film/ShatranjKeKhilari/DummyImage/1.png`
const smImg9 = `${IMAGE_BASE_PATH}/ui/Film/ShatranjKeKhilari/Insights/DetailInsight/SmallImage/8.png`
const img10 = `${IMAGE_BASE_PATH}/ui/Film/ShatranjKeKhilari/DummyImage/1.png`
const smImg10 = `${IMAGE_BASE_PATH}/ui/Film/ShatranjKeKhilari/Insights/DetailInsight/SmallImage/8.png`
const img11 = `${IMAGE_BASE_PATH}/ui/Film/ShatranjKeKhilari/DummyImage/1.png`
const smImg11 = `${IMAGE_BASE_PATH}/ui/Film/ShatranjKeKhilari/Insights/DetailInsight/SmallImage/8.png`
const img12 = `${IMAGE_BASE_PATH}/ui/Film/ShatranjKeKhilari/DummyImage/1.png`
const smImg12 = `${IMAGE_BASE_PATH}/ui/Film/ShatranjKeKhilari/Insights/DetailInsight/SmallImage/8.png`
const KGBanner1 = `${IMAGE_BASE_PATH}/ui/PersonalityArtist/KGSubramanyan/Posts/PostBanner/post1.png`
const kgsmImg1 = `${IMAGE_BASE_PATH}/ui/PersonalityArtist/KGSubramanyan/Insights/1.png`
const kgsmImg2 = `${IMAGE_BASE_PATH}/ui/PersonalityArtist/KGSubramanyan/Insights/2.png`
const kgsmImg3 = `${IMAGE_BASE_PATH}/ui/PersonalityArtist/KGSubramanyan/Insights/3.png`
const kgsmplace = `${IMAGE_BASE_PATH}/ui/PersonalityArtist/KGSubramanyan/Insights/place.png`

export const skk_insight = [
  {
    id: 1,
    imgPath: Banner1,
    insight: "insight1",
    title: "Colonel Mordaunt's Cock-Fight",
    subtitle: "कर्नल मोर्डौंट की मुर्गों की लड़ाई",
    categories: [{
      rc_name:"1_CinemaE",
      link:"/research-categories/cinema-as-a-critical-educational-resource_1"
    },{
      rc_name:"6_EconArts",
      link:"/research-categories/economics-of-art-cinema-and-culture_6/scrollToMasterlists"
    },{
      rc_name:"4_PopCult",
      link:"/research-categories/the-printed-image-popular-arts-and-crafts_4"
    }],
    button1: "Satyajit Ray",
    button2: "Shatranj Ke Khilari",
    primaryButton: [
      { name: "Shatranj Ke Khilari", link: `/film/shatranj-ke-khilari-1977`,alias:"shatranj-ke-khilari-1977", masterlist_type:"film", button_type:"primary"},
      { name: "Satyajit Ray", link: "/person-poly/satyajit-ray", alias:"satyajit-ray", masterlist_type:"person-poly", button_type:"primary" },
      { name: "Animal welfare", link: false },
      { name: "Claude Martin", link: false },
      { name: "Warren Hastings", link: false },
      { name: "Lucknow", link: "/location/lucknow", alias:"lucknow", masterlist_type:"location", button_type:"primary" },
      { name: "Nawabs of Lucknow", link: false },
      { name: "Nemai Ghosh", link: `/nemai-ghosh`,alias:"nemai-ghosh", button_type:"keyword" }
    ],
    button3: "1977",
    subtitle1:"1786 to 1978: Depiction of an event across various mediums.",
    description1:
      "The 1786 painting of Colonel Mordaunt’s Cock Fight was commissioned by the British Governor-General of India, Warren Hastings when Britain was taking a growing commercial and military interest in the region. Painted by Johan Zoffany, the German painter based in Britain, but moved to India to seek his fortune. A friend of Claude Martin, an employee of the British East India Company and the supervisor of the nawab’s arsenal, Zoffany in his painting depicts the cockfight involving birds belonging to Asaf-ud-Daula, the Nawab Wazir of Awadh and Colonel John Mordaunt, an employee of the East India Company. A dynamic painting, it depicts the fight, along with Mordaunt, Asad, Claude Martin and even Zoffany becoming a part of the painting.",
    description2:
      "In 1790, a key of the Daylesford version of Zoffany’s painting was published with engraving by Richard Earlom, which identifies many key figures in the painting. Many versions of the painting were also made by various Indian artists, much later than the original. An 1840, Company School painting from Patna is said to have been possibly commissioned by John Elliot, the son of the first Earl of Minto who was the Governor-General of India in the early nineteenth century",
    description3:
      "The 1786 painting of Colonel Mordaunt’s Cock Fight was commissioned by the British Governor-General of India, Warren Hastings when Britain was taking a growing commercial and military interest in the region. Painted by Johan Zoffany, the German painter based in Britain, but moved to India to seek his fortune. A friend of Claude Martin, an employee of the British East India Company and the supervisor of the nawab’s arsenal, Zoffany in his painting depicts the cockfight involving birds belonging to Asaf-ud-Daula, the Nawab Wazir of Awadh and Colonel John Mordaunt, an employee of the East India Company. A dynamic painting, it depicts the fight, along with Mordaunt, Asad, Claude Martin and even Zoffany becoming a part of the painting.",
    mainImage:"https://tuliresearchcentre.canto.global/direct/image/5mk698cbop0ujejplih62dnb7h/CJeYFZzC0Z5056Y7ayacls6GVlo/m800/800",
    videolink:"https://www.youtube.com/watch?v=1faIoJ7CP-A",
    alias:"shatranj-ke-khilari-1977"
    },
];
export const skk_insight_essentialreading_list = [
  {
    id: 1,
    description:
      "Patel, Baburao(Editor) FilmIndia 11(11) Bmombay:Baburao Patel, 1945 | Accmag | CinemaE",
  },
  {
    id: 2,
    description:
      "Patel, Baburao(Editor) FilmIndia 25(5) Bmombay:Yusufali Nensey, 1959 | Accmag | CinemaE",
  },
  {
    id: 3,
    description:
      "Patel, Baburao(Editor) FilmIndia 25(8) Bmombay:Yusufali Nensey, 1959 | Accmag | CinemaE",
  },
];
export const skk_insight_suggestedreading_list = [
  {
    id: 1,
    description:
      "Patel, Baburao(Editor) FilmIndia 11(11) Bmombay:Baburao Patel, 1945 | Accmag | CinemaE",
  },
  {
    id: 2,
    description:
      "Patel, Baburao(Editor) FilmIndia 25(5) Bmombay:Yusufali Nensey, 1959 | Accmag | CinemaE",
  },
  {
    id: 3,
    description:
      "Patel, Baburao(Editor) FilmIndia 25(8) Bmombay:Yusufali Nensey, 1959 | Accmag | CinemaE",
  },
];
export const skk_main_insight = [
  {
    id: 1,
    imgPath: Banner1,
    title: "",
    insight: "insight1",
  },
  {
    id: 2,
    imgPath: Banner2,
    title: "",
    insight: "insight1",
  },
];

//nemai-ghosh post
export const nemai_ghosh_main_insight = [
  {
    id: 1,
    title: "",
    button1: "Satyajit Ray",
    button2: "Shatranj Ke Khilari",
    primaryButton: [
      { name: "Shatranj Ke Khilari", link: "/film/shatranj-ke-khilari-1977" },
      { name: "Satyajit Ray", link: "/person-poly/satyajit-ray" },
    ],
    button3: "1977",
    description1:
      "Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.",
    description2:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Bibendum est ultricies integer quis. Iaculis urna id volutpat lacus laoreet. Mauris vitae ultricies leo integer malesuada.",
    description0: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
  },
];

// Insight Post Images data on insight detail page

export const skk_insightdetail_page_imagedata = [
  {
    options: [
      { image: smImg1, alt: "", side_image: img1 },
      { image: smImg2, alt: "", side_image: img2 },
      { image: smImg3, alt: "", side_image: img3 },
      { image: smImg4, alt: "", side_image: img4 },
      { image: smImg5, alt: "", side_image: img5 },
      { image: smImg6, alt: "", side_image: img6 },
    ],
    type: "image",
  },
  {
    options: [
      { image: smImg7, alt: "", side_image: img7 },
      { image: smImg8, alt: "", side_image: img8 },
      { image: smImg9, alt: "", side_image: img9 },
      { image: smImg10, alt: "", side_image: img10 },
      { image: smImg11, alt: "", side_image: img11 },
      { image: smImg12, alt: "", side_image: img12 },
    ],
    type: "image",
  },
];

export const skk_insight_quiz_data = [
  {
    question: "Who commissioned the Colonel Mordaunt's Cock Fight Painting?",
    options: [
      "Johan Zoffany",
      "Claude Martin",
      "Warren Hastings",
      "Richard Earlom",
    ],
    type: "button",
  },
  {
    question: "Arrange the images as per the sequence provided in the INSIGHT.",
    options: [
      { image: smImg1, alt: "" },
      { image: smImg2, alt: "" },
      { image: smImg3, alt: "" },
      { image: smImg4, alt: "" },
    ],
    type: "image",
  },
];


// kg_subramanayam_insight

export const kg_subramanayam_main_insight = [
  {
    id: 1,
    imgPath: KGBanner1,
    title: "",
    insight: "insight1",
  }
];
export const kg_subramanayam_insight = [
  {
    id: 1,
    imgPath: KGBanner1,
    insight: "insight1",
    title: "The Window of an American Skyscraper",
    subtitle: "K.G. Subramanyan's Structure of the Polytich",
    categories: "FineArts | EconArts | PopCult",
    button1: "KG Subramanyan",
    button2: "Polytichs",
    primaryButton: [
      { name: "KG Subramanyan", link: "/person-artist/k.g-Subramanyan" },
      { name: "Polytichs", link: "/" },
    ],
    secondaryButton: [
      { name: "New York", link: "/location/new-york" },
    ],
    keyWordButtons: [{ name: "JD Rockefeller", link: `/jdrockefeller` }],
    button3: "",
    description1:`While the concept of the earlier terracotta panels had a narrative element to them, the grid on the window suggest modular repetitiveness. The idea of fragmented grids evolved into windows and become the foundation for several of his later murals and canvases. Thus the view of an American Skyscraper marks the beginning of a series of polytichs from here on.  Siva Kumar says "By showing the figures and their backdrops in fragmentary close-ups and rendering them in bright and flattened colours, he made the images strikingly animate, yet tantalisingly unrevealing. Thus, though repeated and serialised so as to suggest a 'cartoon strip narration', the final work is pure visuallity dressed up as mock narrative. (Extracted from the NGMA retrospective, pg 44)`,
    description2:"",
    description3:"",
    alias:"k.g-Subramanyan"
    },
];
export const kg_subramanayam_insightdetail_page_imagedata = [
  {
    options: [
      { image: kgsmImg1, alt: "", side_image: KGBanner1 },
      { image: kgsmImg2, alt: "", side_image: KGBanner1 },
      { image: kgsmImg3, alt: "", side_image: KGBanner1 },
      { image: kgsmplace, alt: "", side_image: KGBanner1 },
      { image: kgsmplace, alt: "", side_image: KGBanner1 },
      { image: kgsmplace, alt: "", side_image: KGBanner1 },
    ],
    type: "image",
  }
];

export const kg_subramanayam_insight_essentialreading_list = [
  {
    id: 1,
    description:`Kumar, R Siva (Author). K. G. Subramanyan. New Delhi : National Gallery of Modern Art, 1997 | zzz.bka | FineArts`
  },
  {
    id: 2,
    description:`Kapur, Geeta (Author). K. G. Subramanyan. New Delhi : Lalit Kala Akademi, 1987 | zzz.bka | FineArts`
  },
  {
    id: 3,
    description:`Subramanyan, K. G. (Author). Living Tradition. Calcutta : Seagull, 1987 | zzz.bka | FineArts`
  },
];
export const kg_subramanayam_insight_suggestedreading_list = [
  {
    id: 1,
    description:`Subramanyan, K. G. (Author). Moving Focus. New Delhi : Lalit Kala Akademi, 1978 | zzz.bka | FineArts`
  },
  {
    id: 2,
    description:`Subramanyan, K. G. (Author). Creative Circuit. Calcutta : Seagull, 1992 | zzz.bka | FineArts`
  }
];