import { HStack, Stack, Text } from '@chakra-ui/react';
import React from 'react';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';

const TimelineWithText1 = ({ data, textColor="#000000" }) => {

    return (
        <VerticalTimeline layout={"1-column-left"} className="layout">
            {data?.map((item, index) => (
                <VerticalTimelineElement
                    id="timeline1"
                    contentStyle={{ fontSize: "sm" }}
                    iconStyle={{
                        background: "#fff",
                        color: "black",
                        fontSize: "16px",
                        fontWeight: "700",
                    }}
                    icon={item?.year || item?.sortable_year}
                >
                    <Stack
                        p="0"
                        flexDirection={"inherit"}
                        flexWrap="wrap"
                        gap="1"
                    >
                        <HStack>
                            <Text
                                color={textColor}
                                m="0"
                                fontSize={"16px"}
                                width={{ base: "100%", md: "650px" }}
                            >
                                {`${item?.desc || item?.bibliographic_caption} `}
                            </Text>
                        </HStack>
                    </Stack>
                </VerticalTimelineElement>
            ))}
        </VerticalTimeline>
    );
};

export default TimelineWithText1;
