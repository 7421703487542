export const IndexData = [
  {
    id: 1,
    name: "Satyajit Ray",
    type: "A-Graphy | CINE Personality",
    date_of_publishing: "Saturday 11 March, 2023",
    last_updated: "3:15 pm, Saturday 11 March, 2023",
  },
  {
    id: 2,
    name: "Sayed Haider Raza",
    type: "A-Graphy | Arts Personality",
    date_of_publishing: "Saturday 11 March, 2023",
    last_updated: "2:15 pm, Sunday 13 March, 2023",
  },
  {
    id: 3,
    name: "Shatranj Ke Khilari",
    type: "A-Graphy | CINE Personality",
    date_of_publishing: "Saturday 11 March, 2023",
    last_updated: "3:15 pm, Saturday 11 March, 2023",
  },
  {
    id: 4,
    name: "	Waheeda Rehman",
    type: "A-Graphy | CINE Personality",
    date_of_publishing: "Saturday 11 March, 2023",
    last_updated: "3:15 pm, Saturday 11 March, 2023",
  },
  {
    id: 5,
    name: "Mughal-e-Azam",
    type: "A-Graphy | CINE Personality",
    date_of_publishing: "Saturday 11 March, 2023",
    last_updated: "3:15 pm, Saturday 11 March, 2023",
  },
  {
    id: 6,
    name: "Amar Akbar Anthony",
    type: "A-Graphy | CINE Personality",
    date_of_publishing: "Saturday 11 March, 2023",
    last_updated: "3:15 pm, Saturday 11 March, 2023",
  },
  {
    id: 7,
    name: "Kaifi Azmi",
    type: "A-Graphy | CINE Personality",
    date_of_publishing: "Saturday 11 March, 2023",
    last_updated: "3:15 pm, Saturday 11 March, 2023",
  },
  {
    id: 8,
    name: "Gulzar",
    type: "A-Graphy | CINE Personality",
    date_of_publishing: "Saturday 11 March, 2023",
    last_updated: "3:15 pm, Saturday 11 March, 2023",
  },
  {
    id: 9,
    name: "Chidananda Dasgupta",
    type: "A-Graphy | CINE Personality",
    date_of_publishing: "Saturday 11 March, 2023",
    last_updated: "3:15 pm, Saturday 11 March, 2023",
  },
  {
    id: 10,
    name: "Mahatma Gandhi",
    type: "A-Graphy | CINE Personality",
    date_of_publishing: "Saturday 11 March, 2023",
    last_updated: "3:15 pm, Saturday 11 March, 2023",
  },
  {
    id: 11,
    name: "Netaji Subhas Chandra Bose",
    type: "A-Graphy | CINE Personality",
    date_of_publishing: "Saturday 11 March, 2023",
    last_updated: "3:15 pm, Saturday 11 March, 2023",
  },
];
