import { Box, Container, HStack, Stack, Text } from "@chakra-ui/react";
import React from "react";
import "react-vertical-timeline-component/style.min.css";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import { getDailyCalendarData } from "../../services/CalendarService";
import { common } from "../../constants/constants";

function Daily() {
  const [dailyData, setDailyData] = React.useState([]);
  const [showLabel, setShowLabel] = React.useState(false);

  React.useEffect(() => {
    const getData = async () => {
      const result = await getDailyCalendarData();
      if (result?.length > 0) {
        setDailyData(result);
        setShowLabel(false);
      }
      if (result?.length === 0) {
        setShowLabel(true);
      }
    };
    getData();
  }, []);
  return (
    <>
      <Container maxW="auto" className="px-0">
        {showLabel ? (
          <Text py={"40"} fontSize={"45px"} fontWeight="700" textAlign="center">
            {common.WEBPAGE_COMING_SOON}
          </Text>
        ) : (
          <>
            <Stack id="datecarosuel" position={"relative"}>
              <Swiper
                slidesPerView={{ base: 4, md: 7 }}
                centeredSlides={false}
                spaceBetween={8}
                grabCursor={true}
                className="calender"
                navigation={true}
                modules={[Navigation]}
                breakpoints={{
                  300: {
                    slidesPerView: 5,
                    spaceBetween: 20,
                  },
                  768: {
                    slidesPerView: 5,
                    spaceBetween: 20,
                  },
                }}
              >
                {dailyData.map((item, key) => (
                  <SwiperSlide key={key} style={{ alignItems: "baseline" }}>
                    <Box cursor="pointer">
                      <Box>
                        <Stack width={"100%"} flexDirection={"inherit"} gap={2}>
                          <HStack>
                            <Text
                              color={"#333"}
                              textStyle={"secondary"}
                              fontSize={{ base: "25px", md: "40px" }}
                              fontWeight={"600"}
                            >
                              {item?.date}
                            </Text>
                          </HStack>
                          <HStack
                            alignItems={"flex-start"}
                            flexDirection={"column"}
                          >
                            <Text
                              color={"black"}
                              fontSize={"16px"}
                              fontWeight={"500"}
                            >
                              {item?.month}
                            </Text>
                            <Text
                              className="ml-0"
                              fontSize={"12px"}
                              color={"black"}
                              textTransform={"uppercase"}
                            >
                              {item?.day}
                            </Text>
                          </HStack>
                        </Stack>
                      </Box>
                    </Box>
                  </SwiperSlide>
                ))}
              </Swiper>
            </Stack>
            <Stack py="8" position={"relative"} id="allslider">
              <Text
                py={"40"}
                fontSize={{ base: "24px", md: "45px" }}
                fontWeight="700"
                textAlign="center"
              >
                {common.WEBPAGE_COMING_SOON}
              </Text>
            </Stack>
          </>
        )}
      </Container>
    </>
  );
}

export default Daily;
