//Core files
import React from "react";

//Component files
import { WebpageComingSoon } from "../../../CommonComponents/WebpageComingSoon";

function All() {
  return (
    <>
    <WebpageComingSoon/>
    </>
  );
}

export default All;
