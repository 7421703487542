import { Navigation, Pagination, A11y, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import {
  Box,
  Container,
  HStack,
  Image,
  Stack,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import Tags from "../../FunctionalComponents/Tags";

import { getHomeData } from "../../services/HomeService";
import { common } from "../../constants/constants";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { getImageURL } from "../../util/getImage";

const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;
const placeholderImg = `${IMAGE_BASE_PATH}/ui/DummyImage/large.png`

function Slider({ sliderNumber }) {
  const swiperRef = useRef(null);
  const [sliderData, setSliderData] = useState([]);
  const [showLabel, setShowLabel] = useState(false);
  const { t } = useTranslation("");

  useEffect(() => {
    if (swiperRef.current && swiperRef.current.swiper && sliderNumber) {
      const number = parseInt(sliderNumber);
      swiperRef.current.swiper.slideTo(number);
    }
  }, [sliderNumber]);
  React.useEffect(() => {
    const getData = async () => {
      const result = await getHomeData();

      if (result?.length > 0) {
        setSliderData(result);
        setShowLabel(false);
      }
      if (result?.length === 0) {
        setShowLabel(true);
      }
    };
    getData();
  }, []);

  return (
    <>
      <Container
        maxW="auto"
        pr="0px"
        pl="0px"
        className="songs"
        id="allpostsslider"
      >
        <Stack width={"100%"} id="homeslider" position={"relative"}>
          {showLabel ? (
            <Text
              py={"40"}
              fontSize={"45px"}
              fontWeight="700"
              textAlign="center"
            >
              {t(common.WEBPAGE_COMING_SOON)}
            </Text>
          ) : (
            <Swiper
              modules={[Autoplay, Navigation, Pagination, A11y]}
              slidesPerView={1}
              loop={true}
              navigation
              height={"100%"}
              ref={swiperRef}
              pagination={{
                clickable: true,
              }}
              autoplay={{
                delay: 3000,
                disableOnInteraction: true,
              }}
              speed={800}
              onSwiper={(swiper) => {
              swiper.el.addEventListener('mouseenter', () => swiper.autoplay.stop()); 
              swiper.el.addEventListener('mouseleave', () => swiper.autoplay.start()); 
              }}
            >
              {sliderData.map((item, index) => {
                return (
                  <SwiperSlide>
                    <HStack justifyContent="space-evenly">
                    <Link to={`generic/${item?.masterlist_tag_alias[0]}/posts/${item?.alias}`} cursor={"pointer"}>
                          <Box width={"100%"} height={{base: "auto", md: "736px"}}>

                            <Image fallbackSrc={placeholderImg} src={getImageURL(item.image || item.image_alias, '1344', '736')} width={"100%"} objectFit={"contain"} height={"100%"} alt="" />
                          </Box>
                        </Link>
                    </HStack>
                    <HStack flexDirection={"row"}>
                      {/* Below commented code is for first slide where we needs buttons on bottom */}
                      {/* {index === 0 ? (
                        <Box
                          className="bottom-right"
                          zIndex={1}
                          pt="8"
                          pr="5"
                          pb="20"
                          width={"auto"}
                          height="22%"
                          position={"absolute"}
                        >
                          <Tags
                              tags={item?.masterlist_type}
                              alias={item?.alias}
                              onHover={false}
                          />
                        </Box>
                      ) : ( */}
                        <Box
                          className="top-right"
                          zIndex={1}
                          pt="8"
                          px="8"
                          pb="16"
                          id="DivForHoverItem"
                          width={"485px"}
                          height="500px"
                        >
                          <Text
                            className="title-on-slide"
                            fontSize={"36px"}
                            fontWeight={"700"}
                            lineHeight={"47.88px"}
                            textAlign={"left"}
                            id="HiddenText"
                            color={"white"}
                            textShadow={"1px 1px #91919194"}
                            textStyle={"tertiary"}
                          >
                            {item.title}
                          </Text>
                       {/* As of now comment the below subtile text as per instruction from content team */}
                          {/* <Text
                            fontSize={"16px"}
                            fontWeight={"400"}
                            lineHeight={"22px"}
                            teststyle="secondary"
                            textAlign={"left"}
                            py="2"
                            id="HiddenText"
                            color={"white"}
                          >
                            {item?.sub_title}
                          </Text> */}
                          <HStack flexWrap={"wrap"} width={"441px"} spacing={"5px"} alignItems={"baseline"}>
                            <Tags
                              tags={item?.masterlist_type}
                              onHover={true}
                              alias={item?.alias}
                            />
                          </HStack>
                        </Box>
                      {/* )} */}
                    </HStack>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          )}
        </Stack>
      </Container>
    </>
  );
}
export default Slider;