import {
  Box,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  HStack,
  Stack,
  Text,
} from "@chakra-ui/react";

// Core Files
import React from "react";

// Subsection Files
import QuizSlider from "./QuizSlider";
import { common } from "../../../constants/constants";

function QuizDrawer({ setDrawerOpen, drawerOpen }) {
  return (
    <>
      <Drawer
        isOpen={drawerOpen}
        placement="right"
        onClose={(e) => setDrawerOpen(false)}
        id="rightquizdrawer"
        size={"md"}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton
            _hover={{
              color: "white",
              bg: "black",
            }}
            className="closeicon"
            margin={"10px"}
          />
          <DrawerHeader px={6}>
            <Box>
              <HStack pb="4">
                <Text fontSize={"20px"} fontWeight={"700"}>
                  {common.SEARCHER}
                </Text>
              </HStack>
              <Stack>
                <HStack justifyContent={"space-between"}>
                  <Text fontSize={"16px"} fontWeight={"300"}>
                    {common.Q_REMAINING}
                  </Text>
                  <Text fontSize={"16px"} fontWeight={"700"}>
                    00
                  </Text>
                </HStack>
                <HStack className="mt-0" justifyContent={"space-between"}>
                  <Text fontSize={"16px"} fontWeight={"300"}>
                    {common.SCORE} :
                  </Text>
                  <Text fontSize={"16px"} fontWeight={"700"}>
                    00/00
                  </Text>
                </HStack>
                <HStack className="mt-0" justifyContent={"space-between"}>
                  <Text fontSize={"16px"} fontWeight={"300"}>
                    {common.TIMER} :
                  </Text>
                  <Text fontSize={"16px"} fontWeight={"700"}>
                    00:00
                  </Text>
                </HStack>
              </Stack>
            </Box>
          </DrawerHeader>
          <DrawerBody>
            <Stack>
              <QuizSlider />
            </Stack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
}

export default QuizDrawer;
