import React, { useEffect, useState } from 'react';
import {
  Box, Button, FormControl, HStack, Input, InputGroup, InputRightElement, Select, Stack, Text, useBreakpointValue, VStack
} from '@chakra-ui/react';
import { userRegistrationForm } from '../../constants/constants';
import Large from '../../Theme/Typography/Large';
import H1 from '../../Theme/Typography/H1';
import { Link } from 'react-router-dom';
import LoginSlider from '../../CommonComponents/LoginSlider';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { ChevronDownIcon } from "@chakra-ui/icons";
import { css } from "@emotion/react";
import { checkUserEmailAvailability, checkUsernameAvailability, getCountryData } from '../../services/User';
import DropdownWithSearch from '../../FunctionalComponents/SearchDropdown/SearchDropdown';

const customOptionStyle = css`
  option {
    color: black;
    font-size:16px;
    line-height:18.78px;
    font-weight:400;
  }
`;

const Step1 = ({ formData, setFormData, nextStep }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showCpassword, setShowCpassword] = useState(false);
  const [countries,setCountries]=useState([])
  const getData = async () => {
    const result = await getCountryData();
    if(result && result?.data?.length>0){
      setCountries(result?.data);
    }
  }
    useEffect(() => {
      getData();
      // eslint-disable-next-line
    }, []);
  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const toggleShowCPassword = () => {
    setShowCpassword(!showCpassword);
  };

  const validationSchema = Yup.object({
    firstName: Yup.string().trim()
    .matches(/^[A-Za-z]+$/, 'First Name can only contain letters')
    .required('First Name is required'),
    lastName: Yup.string().trim()
    .matches(/^[A-Za-z]+$/, 'Last Name can only contain letters')
    .required('Last Name is required'),
    username: Yup.string().trim()
    .required('User Name is required')
    .test('checkUsernameExists', 'Username already exists', async (value) => {
      if (!value) return false; // Required validation will catch this case
      return checkUsernameAvailability(value);
    }),
    email: Yup.string().trim().email('Invalid email address').required('Email is required').test('checkUserEmailExists', 'Email already exists', async (value) => {
      if (!value) return false; // Required validation will catch this case
      return checkUserEmailAvailability(value);
    }),
    age: Yup.string().required('Age is required'),
    nationality: Yup.string().required('Nationality is required'),
    education1: Yup.string().trim().required('Education 1 is required'),
    password: Yup.string().trim()
    .min(8, 'Password must be at least 8 characters long')
    .required('Password is required'),
    confirmPassword: Yup.string().trim()
      .oneOf([Yup.ref('password'), null], 'Passwords must match')
      .required('Confirm Password is required'),
  });

  const initialValues = {
    firstName: formData.firstName || '',
    lastName: formData.lastName || '',
    username: formData.username || '',
    email: formData.email || '',
    mobile:formData.mobile || '',
    address:formData.address || '',
    city: formData.city || '',
    age: formData.age || '',
    nationality: formData.nationality || '',
    education1: formData.education1 || '',
    education2: formData.education2 || '',
    education3: formData.education3 || '',
    password: formData.password || '',
    confirmPassword: formData.confirmPassword || '',
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      setFormData(values);
      nextStep();
    },
  });
  const isMobile = useBreakpointValue({ base: true, md: false });

  return (
    <Stack height={"100%"} px={{ base: "10px", md: "62px" }} py={{ base: "10px", md: "32px" }}>
        <VStack >
          <HStack alignItems={{base:"flex-start",md:"flex-end"}} flexDirection={{base:"column",md:"row"}} pb="5px" width={"100%"} gap={{base:"0px",md:"20px"}}>
          <Stack width={{ base: "100%", md: "565px" }}>
          <Large fontSize={{base:"24px",md:"30px"}} fontWeight="400" lineHeight={{base:"28.95px",md:"35.92px"}}>
            {userRegistrationForm.LOGIN_FORM_WELCOME_TO}
           
          </Large>
          <Large fontSize={{base:"24px",md:"30px"}} lineHeight={{base:"28.95px",md:"35.92px"}} fontWeight="600">
              {userRegistrationForm.LOGIN_FORM_TITLE}
            </Large>
          
          </Stack>
          <HStack justifyContent={"space-between"} flexDirection={{base:"column",md:"row"}} alignItems={{base:"flex-start",md:"center"}} marginInlineStart={"0.0rem!important"}>
          <H1 className="mt-0" paddingTop="10px" fontSize={{base:"24px",md:"30px"}} fontWeight="700"lineHeight={{base:"28.95px",md:"35.92px"}}>
            {userRegistrationForm.REGISTRATION_FORM_BUTTON_SUBSCRIBE_LABEL}
          </H1>
          <H1 marginInlineStart={{base:"0.0rem!important",md:"8.5rem!important"}} paddingY="3px" fontWeight="400"fontSize={{base:"18px",md:"24px"}} lineHeight='28px'>
            {userRegistrationForm.ALREADY_A_MEMBER}{" "}
            <Link to="/login">
              <H1 as="span" color={"color.blue"} fontSize={{base:"18px",md:"24px"}} lineHeight={{base:"24px",md:"28px"}} textDecor={"underline"} fontWeight="400">
                {userRegistrationForm.SUBSCRIBE_HERE}.
              </H1>
            </Link>
          </H1>
          </HStack>
          </HStack>
        </VStack>
        <HStack flexDirection={{base:"column",md:"row"}} alignItems={"flex-start"} >
          
          <Stack paddingBottom={{base:"80px",md:"0px"}}>
            <Box width={{ base: "100%", md: "565px" }}>
            <form onSubmit={formik.handleSubmit}>
            <FormControl id="username" 
                isInvalid={formik.errors.username && formik.touched.username}
             >
                <Input
                  size={'lg'}
                  height={"47px"}
                  className="inputfield"
                  placeholder="Username *"
                  _placeholder={{ color: "color.gray" }}
                  type="text"
                  {...formik.getFieldProps('username')}
                />
                {formik.errors.username && formik.touched.username && formik.errors.username && (
                  <Text color="red.500">{formik.errors.username}</Text>
                  )}
              </FormControl>

              <HStack spacing={4} alignItems={"baseline"}>
                <FormControl id="firstName" mt={3}
                isInvalid={formik.errors.firstName && formik.touched.firstName}
                >
                  <Input
                    size={'lg'}
                    height={"47px"}
                    className="inputfield"
                    placeholder="First Name (s) *"
                    _placeholder={{ color: "color.gray" }}
                    {...formik.getFieldProps('firstName')}
                    type="text"
                  />
                  {formik.touched.firstName && formik.errors.firstName && (
                  <Text color="red.500">{formik.errors.firstName}</Text>
                  )}
                </FormControl>
                <FormControl id="lastName" mt={3}
                isInvalid={formik.errors.lastName && formik.touched.lastName} 
                >
                  <Input
                    size={'lg'}
                    height={"47px"}
                    className="inputfield"
                    placeholder="Last Name *"
                    _placeholder={{ color: "color.gray" }}
                    {...formik.getFieldProps('lastName')}
                    type="text"
                  />
                  {formik.touched.lastName && formik.errors.lastName && (
                  <Text color="red.500">{formik.errors.lastName}</Text>
                  )}
                </FormControl>
              </HStack>
              <FormControl id="email" mt={3}
              isInvalid={formik.errors.email && formik.touched.email}
              >
                <Input
                  size={'lg'}
                  height={"47px"}
                  className="inputfield"
                  placeholder="Email *"
                  _placeholder={{ color: "color.gray" }}
                  type="email"
                  {...formik.getFieldProps('email')}
                />
                 {formik.touched.email && formik.errors.email && (
                  <Text color="red.500">{formik.errors.email}</Text>
                  )}
              </FormControl>

              <HStack spacing={4} alignItems={"baseline"}>
                <FormControl id="mobile" mt={3}>
                  <Input
                    size={'lg'}
                    height={"47px"}
                    className="inputfield"
                    placeholder="Phone Number"
                    _placeholder={{ color: "color.gray" }}
                    type="text"
                    {...formik.getFieldProps('mobile')}
                    />
                </FormControl>
              {/* </HStack> */}
              {/* <HStack spacing={4} alignItems={"baseline"}> */}
                <FormControl id="age" mt={3}
                isInvalid={formik.touched.age && formik.errors.age}
                >
                  <Select
                    placeholder="Age *"
                    _placeholder={{ color: "color.gray" }}
                    className='inputfield'
                    size={'lg'}
                    height={"47px"}
                    {...formik.getFieldProps('age')}
                    icon={<ChevronDownIcon boxSize={8} fontSize={"40px"} />}
                    css={customOptionStyle}
                  >
                   <option className="option" value="16-29">16-29</option>
                    <option value="30-39">30-39</option>
                    <option value="40-50">40-50</option>
                    <option value="More than 50">More than 50</option>
                  </Select>
                  {formik.touched.age && formik.errors.age && (
                    <Text color="red.500">{formik.errors.age}</Text>
                  )}
                </FormControl>
              </HStack>

              <HStack spacing={4} alignItems={"baseline"}>
              <FormControl id="city" mt={3}>
                  <Input
                    size={'lg'}
                    height={"47px"}
                    className="inputfield"
                    placeholder="City of Residence"
                    _placeholder={{ color: "color.gray" }}
                    type="text"
                    {...formik.getFieldProps('city')}
                    />
                </FormControl>
                <FormControl id="nationality" mt={3}
                  isInvalid={formik.touched.nationality && formik.errors.nationality}
                  >
                    {countries && countries?.length>0 && <DropdownWithSearch
                      items={countries} 
                      placeholder="Search" 
                      formikFieldProps={formik.getFieldProps('nationality')}
                      customOptionStyle={customOptionStyle}
                    />}
                  {formik.touched.nationality && formik.errors.nationality && (
                      <Text color="red.500">{formik.errors.nationality}</Text>
                    )}
                  </FormControl>
                </HStack>

              <FormControl id="address" mt={3}>
                <Input
                  size={'lg'}
                  height={"47px"}
                  className="inputfield"
                  placeholder="Address"
                  _placeholder={{ color: "color.gray" }}
                  type="text"
                  {...formik.getFieldProps('address')}
                  />
              </FormControl>

              <FormControl id="education1" mt={3}
              isInvalid={formik.touched.education1 && formik.errors.education1}
              >
                <Input
                  size={'lg'}
                  height={"47px"}
                  className="inputfield"
                  placeholder="Education 1 - Highest Qualification *"
                  _placeholder={{ color: "color.gray" }}
                  type="text"
                  {...formik.getFieldProps('education1')}
                />
                {formik.touched.education1 && formik.errors.education1 && (
                  <Text color="red.500">{formik.errors.education1}</Text>
                )}
                </FormControl>
              <FormControl id="education2" mt={3}>
                <Input
                  size={'lg'}
                  height={"47px"}
                  className="inputfield"
                  placeholder="Education 2"
                  _placeholder={{ color: "color.gray" }}
                  type="text"
                  {...formik.getFieldProps('education2')}
                  />
              </FormControl>

              <FormControl id="education3" mt={3}>
                <Input
                  size={'lg'}
                  height={"47px"}
                  className="inputfield"
                  placeholder="Education 3"
                  _placeholder={{ color: "color.gray" }}
                  type="text"
                  {...formik.getFieldProps('education3')}
                />
              </FormControl>

              <FormControl id="password" mt={3}
              isInvalid={formik.touched.password && formik.errors.password}
              >
                <InputGroup>
                  <Input
                    size={'lg'}
                    height={"47px"}
                    className="inputfield"
                    placeholder="Password *"
                    _placeholder={{ color: "color.gray" }}
                    {...formik.getFieldProps('password')}
                    type={showPassword ? "text" : "password"}
                  />
                  <InputRightElement>
                    <Text onClick={toggleShowPassword}>
                      {showPassword ? <FiEye size={"18px"} /> : <FiEyeOff size={"18px"} />}
                    </Text>
                  </InputRightElement>
                </InputGroup>
                {formik.touched.password && formik.errors.password && (
                  <Text color="red.500">{formik.errors.password}</Text>
                )}
              </FormControl>

              <FormControl id="confirmPassword" mt={3}
              isInvalid={formik.touched.confirmPassword && formik.errors.confirmPassword}
              >
                <InputGroup>
                  <Input
                    size={'lg'}
                    height={"47px"}
                    className="inputfield"
                    placeholder="Confirm Password"
                    _placeholder={{ color: "color.gray" }}
                    {...formik.getFieldProps('confirmPassword')}
                    type={showCpassword ? "text" : "password"}
                  />
                  <InputRightElement>
                    <Text onClick={toggleShowCPassword}>
                      {showCpassword ? <FiEye size={"18px"} /> : <FiEyeOff size={"18px"} />}
                    </Text>
                  </InputRightElement>
                </InputGroup>
                {formik.touched.confirmPassword && formik.errors.confirmPassword && (
                  <Text color="red.500">{formik.errors.confirmPassword}</Text>
                )}
              </FormControl>

              <Button
                size={"xl"}
                width={{ base: "100%", md: "565px" }}
                mt={"20px"}
                variant="custom"
                type="submit"
                zIndex={{base:"99",md:"999"}}
              >
                <H1 fontSize={{base:"18px",md:"24px"}}>{userRegistrationForm.NEXT}</H1>
              </Button>
              </form>
            </Box>
          </Stack>
          {!isMobile && (
          <Stack>
            <LoginSlider />
          </Stack>
        )}
        </HStack>
      </Stack>
  );
};

export default Step1;