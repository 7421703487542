//S3 Bucket osw-dev-images
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;

const Img1 = `${IMAGE_BASE_PATH}/ui/Film/ShatranjKeKhilari/BodyOfWork/CreditsCast/FullCreditView/1.png`
const Img2 = `${IMAGE_BASE_PATH}/ui/Film/ShatranjKeKhilari/BodyOfWork/CreditsCast/FullCreditView/2.png`
const fullcred1 = `${IMAGE_BASE_PATH}/ui/ObjectBookSsb/SongsSynopsisBooklet/BodyOfWork/Booklets/GridView/Silsila/CreditsCast/FullCreditView/1.png`

export const skk_vertical_layout_data = [
  {
    id: 1,
    image: Img1,
    desc:"Shatranj Ke Khilari | 1977 | Song Synopsis Booklet | CINE.ssb",
    newDesc:"Satyajit Ray | Song Synopsis Booklet, 1977_CINE.ssb | 000013d"
  },
  {
    id: 1,
    image: Img2,
    desc:"Shatranj Ke Khilari | 1977 | Song Synopsis Booklet | CINE.ssb",
    newDesc:"Satyajit Ray | Song Synopsis Booklet, 1977_CINE.ssb | 000013e"
  },
];
export const silsila_vertical_layout_data = [
  {
    id: 1,
    image: fullcred1,
    desc:"Silsila | 1981 | Song Synopsis Booklet | BOOK.ssb",
    newDesc:"Silsila | 1981 | Song Synopsis Booklet | BOOK.ssb"
  },
  {
    id: 1,
    image: Img2,
    desc:"Silsila | 1981 | Song Synopsis Booklet | BOOK.ssb",
    newDesc:"Satyajit Ray | Song Synopsis Booklet, 1977_CINE.ssb | 000013e"
  },
];
