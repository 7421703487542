import axios from 'axios';
import TokenService from './token';
import {APIS} from '../endpoints';

const instance = axios.create({
  baseURL: APIS.BASE_URL,
  debug: true,
  headers: {
    'content-type': 'application/json',
  },
});

const instance_ref = axios.create({
  baseURL: APIS.BASE_URL,
  debug: true,
  headers: {
    'content-type': 'application/json',
  },
});

instance.interceptors.request.use(
  async function (config) {
    // Do something before request is sent

    if (config.url === APIS.login || config.url === APIS.request_otp)
      return config;
    const token = await TokenService.getLocalAccessToken();
    const refToken= await TokenService.getLocalRefreshToken();
    if (token) {
      config.headers.Authorization = 'Bearer ' + token; // for Spring Boot back-end
      config.headers['x-access-token'] = token; // for Node.js Express back-end
    }
    if (config.url === APIS.refresh_token) {
      config.headers.Authorization = 'Bearer ' + refToken; // for Spring Boot back-end
      config.headers['x-access-token'] = refToken; 
    }
    return config;
  },
  function (error) {
    // Do something with request error
    console.log('request error',error);
    throw  new Error(error);
  },
);

instance.interceptors.response.use(
  async response => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    //Successful Login Store token
    if (
      response.config.url === APIS.login &&
      response.data 
    ) {
      await TokenService.updateLocalAccessToken(response.data);
    }

    return response;
  },
  async err => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    const originalConfig = err.config;
    //Need to navigate to login screen

    // On Successful Login store acces tokens in storage
    if (originalConfig.url !== APIS.login && err.response) {
      // Access Token was expired
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;
        const ref_token = await TokenService.getLocalRefreshToken();

        try {
          const rs = await instance_ref.post(APIS.refresh_token, {
            token: ref_token,
          });

          await TokenService.updateLocalAccessToken(rs.data.data);
          return instance(originalConfig);
        } catch (_error) {
          console.log('ref token expired', _error);
          //POssible refresh token is also expired
          _error.response.logout = true;
          return Promise.reject(_error);
        }
      }
    }

    console.log('response error', err.response);
    // return Promise.reject(err);
    throw err
  },
);
/*instance.interceptors.response.use(
  res => {
    console.log("response res", res);
    return res;
  },
  async err => {
    console.log("response error ***", err);
    const originalConfig = err.config;

    if (originalConfig.url !== APIS.request_otp && err.response) {
      // Access Token was expired
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;

        try {
          const rs = await instance.post(APIS.refresh_token, {
            refreshToken: TokenService.getLocalRefreshToken(),
          });

          const {accessToken} = rs.data;
          TokenService.updateLocalAccessToken(accessToken);

          return instance(originalConfig);
        } catch (_error) {
          return Promise.reject(_error);
        }
      }
    }

    return Promise.reject(err);
  },
);
*/

export default instance;
