import { HStack, Image, Stack } from '@chakra-ui/react'
import React, { useEffect, useRef, useState } from 'react'
import * as getScreeningMasterlistData from "../../../../../services/Screenings";
import GridView from './Screening/GridView';
import TabularView from './Screening/TabularView';
import { useNavigate } from 'react-router-dom';
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;
const loaderImg = `${IMAGE_BASE_PATH}/ui/Chevron/loader.gif`;

export default function Screenings({masterlistType,masterlist,visible}) {
  const [showFilterBox, setShowFilterBox] = useState(false);
  const [apply, setApply] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [filterOptions, setFilterOptions] = useState({ search: "", filters: {} });
  const [loading, setLoading] = useState(true);
  const initialLoadComplete = useRef(false);
  const navigate=useNavigate();
  // Show/Hide Filter box
  const handleFilterClick = () => {
    setShowFilterBox(!showFilterBox);
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleReset = () => {
    const initializedFilters = facets.reduce((acc, facet) => {
      acc[facet.key] = [];
      return acc;
    }, {});
    setFilterOptions({
      search: "",
      filters: initializedFilters
    });
    setSearchTerm("");
  }

  const handleCheckboxChange = (value, type) => {
    // Determine the array based on the type
    let selectedArray = [];
    let selectedString = "";

    if (value === "searches") {
      setFilterOptions(prevFilterOptions => ({
        ...prevFilterOptions,
        search: selectedString,
      }));
    } else {
      selectedArray = filterOptions.filters[value] || [];

      if (selectedArray.includes(type)) {
        selectedArray = selectedArray.filter((checkbox) => checkbox !== type);
      } else {
        selectedArray = [...selectedArray, type];
      }

      setFilterOptions(prevFilterOptions => ({
        ...prevFilterOptions,
        filters: {
          ...prevFilterOptions.filters,
          [value]: selectedArray,
        },
      }));
    }
  };


  // Need to refactor the function to bring real data for filter.
  const citationFactor = (data) => {
    return data.map((item) => ({
      year: `${item.sortable_date}`,
      value: `${item.bibliographic_caption}`,
      image: `${item.image_alias}`,
      citation:`${item?.event_name} (${item?.year_of_film}), ${item?.director}`,
      festivalSection:`${item?.festival_section ? item?.festival_section :'Festival Section'}`,
      date:`${item?.opening_date ? item?.opening_date:'Time | Date'}`,
      alias:item?.alias || ""
    }));
  };

  const [facets, setFacets] = useState([]);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  async function fetchData() {
    try {
      setLoading(true)
      const result = await getScreeningMasterlistData.getScreeningByMasterList(masterlistType, masterlist, filterOptions, 1)
      setLoading(false)
      if (result && result?.data?.length > 0) {
        const processedData = citationFactor(result?.data);
        setData(processedData);
        if (!initialLoadComplete.current) {
          setPage((prevPage) => prevPage + 1);
          initialLoadComplete.current = true;
        }       
        const formattedFacets = result?.facetsHeaders?.map(header => {
          const key = Object.keys(header)[0];
          const display = header[key];
          const value = result?.facets[0][key]; // Assuming facetsData has only one object
          return {
            key,
            display,
            value
          };
        });
        if (processedData.length === result?.total) {
          setHasMore(false);
        }
        setFacets(formattedFacets);
        if (formattedFacets.length > 0) {
          setFilterOptions(prevFilterOptions => ({
            ...prevFilterOptions,
            filters: {
              ...prevFilterOptions.filters,
              ...formattedFacets.reduce((acc, facet) => {
                acc[facet.key] = [];
                return acc;
              }, {})
            }
          }));
        }
      }

    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false)
      setData([])
    }
  }
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const fetchMoreData = async () => {
    try {
      const result = await getScreeningMasterlistData.getScreeningByMasterList(masterlistType, masterlist, filterOptions, page)
      if (result && result?.data.length>0) {
        const processedData = citationFactor(result?.data);
        setData(prevData => [...prevData, ...processedData]);
        setPage(prevPage => prevPage + 1);
      }
      if (data?.length >= result?.total) {
        setHasMore(false);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }
  async function fetchFilterData(page = 1) {
    try {
      const result = await getScreeningMasterlistData.getScreeningByMasterList(masterlistType, masterlist, filterOptions, page)

      if (result) {
        const processedData = citationFactor(result?.data);
        setData(processedData);
        const formattedFacets = result?.facetsHeaders?.map(header => {
          const key = Object.keys(header)[0];
          const display = header[key];
          const value = result?.facets[0][key]; // Assuming facetsData has only one object
          return {
            key,
            display,
            value
          };
        });
        if (processedData.length === result?.total) {
          setHasMore(false);
        }
        setFacets(formattedFacets);
        if (formattedFacets?.length > 0) {
          setFilterOptions(prevFilterOptions => ({
            ...prevFilterOptions,
            filters: {
              ...prevFilterOptions.filters,
              ...formattedFacets.reduce((acc, facet) => {
                acc[facet.key] = [];
                return acc;
              }, {})
            }
          }));
        }
        if (data.length >= result?.total) {
          setHasMore(false);
        }
      }

    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }
  useEffect(() => {

    if (apply) {
      setApply(false);
      setHasMore(true);
      setPage(1)
      fetchFilterData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apply, filterOptions]);
  const handleScreeningData=(item)=>{
    navigate(item?.alias);
  }
  return (
    <Stack minHeight={"100vh"}>
     {!loading ? <>
        {visible ? (
        <>
          <GridView
            // handleBookClick={handleBookClick}
            //new data props
            fetchMoreData={fetchMoreData}
            data={data}
            handleScreeningData={handleScreeningData}
            showFilterBox={showFilterBox}
            handleFilterClick={handleFilterClick}
            hasMore={hasMore}
            setShowFilterBox={setShowFilterBox}
            setApply={setApply}
            handleCheckboxChange={handleCheckboxChange}
            handleSearch={handleSearch}
            filterOptions={filterOptions}
            filteroptionData={facets}
            setFilterOptions={setFilterOptions}
            setSearchTerm={setSearchTerm}
            searchTerm={searchTerm}
            handleReset={handleReset}
          />
        </>
      ) : (
        <>
          <TabularView
            masterlist={masterlist}
            masterlistType={masterlistType}
            //new data props
            fetchMoreData={fetchMoreData}
            data={data}
            showFilterBox={showFilterBox}
            handleFilterClick={handleFilterClick}
            hasMore={hasMore}
            setShowFilterBox={setShowFilterBox}
            setApply={setApply}
            handleCheckboxChange={handleCheckboxChange}
            handleSearch={handleSearch}
            filterOptions={filterOptions}
            filteroptionData={facets}
            setFilterOptions={setFilterOptions}
            setSearchTerm={setSearchTerm}
            searchTerm={searchTerm}
            handleReset={handleReset}
            handleScreeningData={handleScreeningData}
          />
        </>
      )}
      </>:(
      <HStack justifyContent={"center"} minH={"100vh"} alignItems={"flex-start"} mt={4}>
          <Image src={loaderImg} width={"100px"} height={"100px"} alt="Loading" />
      </HStack>
    )}
    </Stack>
  )
}
