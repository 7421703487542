import {
  Box,
  Container,
  Image,
  SimpleGrid,
  Stack,
  Text,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import Layout from "../components/sections/SidebarLayout";
import React from "react";
import DataContext from "../context/DataContext";
import { common } from "../constants/constants";
import { getImageURL } from "../util/getImage";
import H3 from "../Theme/Typography/H3";
import Medium from "../Theme/Typography/Medium";
import H2 from "../Theme/Typography/H2";

function Research() {
  const { categories } = React.useContext(DataContext);
  const [categoriesData, setCategoriesData] = React.useState([]);
  const navigate = useNavigate();

  React.useEffect(() => {
    setCategoriesData(
      categories
        .map((item) => {
          const alias = item?.find((nestedItem) => nestedItem?.key === "alias")?.value;
          const name = item?.find((nestedItem) => nestedItem?.key === "name")?.value;
          const sub_name = item?.find((nestedItem) => nestedItem?.key === "sub_name")?.value;
          const research_category_as_in_website = item?.find((nestedItem) => nestedItem?.key === "research_category_as_in_website")?.value;
          
          // const imagesString = item?.find((nestedItem) => nestedItem?.key === "images")?.value;
          // const image = imagesString ? imagesString.split('|').map((img) => img.trim())[0] : null;
          const image = item?.find((nestedItem) => nestedItem?.key === "images")?.value?.[0] ?? null;
          return {
            alias,
            name,
            sub_name,
            research_category_as_in_website,
            image
          };
        })
        .filter(Boolean)
    );
  }, [categories]);

  return (
    <Layout>
      <Stack className="mobileviewpadding">
        <Container maxW="auto" py={{base:"15px",md:"34px"}} px={{base:"0px",md:"64px"}}>
          {categoriesData.length === 0 ? (
            <Text
              py={"40"}
              fontSize={"45px"}
              fontWeight="700"
              textAlign="center"
            >
              {common.WEBPAGE_COMING_SOON}
            </Text>
          ) : (
            <SimpleGrid columns={[1, null, 4]} spacing={{base:"10px",md:"25px"}}>
              {categoriesData ? (
                categoriesData.map((item, index) => (
                  <Box
                    onClick={() =>
                      navigate("/research-categories/" + item.alias)
                    }
                    key={index}
                    maxW={"auto"}
                    w={"full"}
                    overflow={"hidden"}
                    cursor={"pointer"}
                  >
                    <Box
                      h={"216px"}
                      width={"100%"}
                      bg={"white"}
                      pos={"relative"}
                    >
                      <Image
                        src={item?.image ? getImageURL(item?.image) : "https://d3f7r9v35rq5qh.cloudfront.net/ui/DummyImage/dummy.png"}
                        alt=""
                        w={"100%"}
                        objectFit={"contain"}
                        height={"inherit"}
                        border={"1px"}
                        borderColor={"#dbdbdb"}
                      />
                    </Box>
                    <Stack py={{base:'2',md:'2'}}>
                    
                    <Medium color="color.gray">{item?.research_category_as_in_website}</Medium>
                      <H2 marginTop="0.2rem!important" _hover={{color:"color.blue"}} fontSize="18px" fontWeight={"600"} lineHeight="26px" color="color.black">{item?.name}</H2>
                      <H3 fontSize="16px" fontWeight={"400"} lineHeight="18.78px" color="color.gray">{item?.sub_name}</H3>
                    </Stack>
                  </Box>
                ))
              ) : (
                <React.Fragment />
              )}
            </SimpleGrid>
          )}
        </Container>
      </Stack>
    </Layout>
  );
}
export default Research;
