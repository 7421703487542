import { HStack, Text } from "@chakra-ui/react";
import { Link } from "react-router-dom";

export const HorisLink = ({ links, px, fontSize, flexDirection, alignItems, aliasAPI, marginLeft="-4px !important", textStyle="secondary" }) => {
  
  return (
    <HStack flexDirection={aliasAPI ? flexDirection : "none"} alignItems={aliasAPI ? alignItems : "none"} justifyContent="left" className="mt-0" px={px}>
      {/* <Link to="/research-categories/cinemae"> */}
      {links &&
        links.length !== 0 &&
        links.map((it, index) => {
          return (
            <Link to={it.link} key={index}>
              <Text
                fontSize={fontSize ? fontSize : "16px"}
                fontWeight="600"
                lineHeight={"21.28px"}
                textStyle={textStyle}
                color="#035DA1"
                marginLeft={index !==0 && marginLeft}
              >
                {it.title}
                {index < links.length - 1 && (
                  <span style={{ color: "#035DA1" }}> | </span>
                )}
              </Text>
            </Link>
          );
        })}
    </HStack>
  );
};
