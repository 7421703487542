import {
  Box,
  useColorModeValue,
  useDisclosure,
  Stack,
  Container,
  Accordion,
  AccordionItem,
  AccordionButton,
  Text,
  AccordionIcon,
  Divider,
  AccordionPanel,
} from "@chakra-ui/react";

//Core file
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "react-vertical-timeline-component/style.min.css";

//Service file
import * as getIntroductionData from "../../services/Introduction";
import * as getLandingMasterlistData from "../../services/SectionService";
import * as getGeographicalMasterlistData from "../../services/Location";

//Component file
import { BottomMainMenu } from "../../CommonComponents/BottomMainMenu";
import CommonLeftSidebar from "../../FunctionalComponents/LeftSidebar/CommonLeftSidebar";
import CountryMap from "./Introduction/CountryMap";

//Language file
import {common, explore, location } from "../../constants/constants";


export default function Introduction({ children }) {
  const { onClose } = useDisclosure();
  const [introData, setIntroData] = useState([]);
  const [journalDetails, setJournalDetails] = useState({});
  const [name, setName] = useState("");
  const {alias } = useParams();
  const aliasAPI = alias;
  const currentEndpoint = window.location.pathname;
  const parts = currentEndpoint.split("/");
  const masterlistType = parts[1];

  React.useEffect(() => {
    const getIntroData = async () => {
      const result = await getIntroductionData.introductionByAlias(
        "",
        aliasAPI
      );
      if (result) {
        setName(() => result[0]?.find((item) => item?.key === "name")?.value);
        setJournalDetails(
          result?.reduce((acc, it) => {
            acc.title = it?.find((item) => item?.key === "name")?.value;
            return acc;
          }, {})
        );
      }
    };
    const getLandingData = async () => {
      const result = await getLandingMasterlistData.getLandingPageInfoByAlias(
        aliasAPI
      );
      if (result) {
        setIntroData(
          result?.map((it) => ({
            text: it.find((item) => item?.key === "landing_page_title").value,
            to: `/${masterlistType}/${it.find((item) => item?.key === "masterlist").value}/${
              it.find((item) => item?.key === "landing_page_abbreviation").value
            }`,
            title: it.find((item) => item?.key === "landing_page_abbreviation")
              .value,
          }))
        );
      }
    };
    getIntroData();
    getLandingData();
  }, [masterlistType, aliasAPI]);
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Container maxW={"1400px"} px="0px">
      <Box minH="100%" bg={useColorModeValue("white", "gray.900")}>
        <SidebarContent
          onClose={() => onClose}
          display={{ base: "none", md: "block" }}
          zIndex={99999}
          height="calc(100vh - 64px)"
          aliasAPI={aliasAPI}
          journalDetails={journalDetails}
          introData={introData}
          masterlistType={masterlistType}
          name={name}
        />
        <Box ml={{ base: 0, md: 52 }} bg="white">
          {children}
          <IntroductionBody aliasAPI={aliasAPI} />
        </Box>
      </Box>
    </Container>
  );
}
const SidebarContent = ({ 
  onClose,
  aliasAPI,
  journalDetails,
  introData,
  masterlistType,
   ...rest }) => {

  const navigate = useNavigate();
  const handleNavigation = () => {
    navigate(-1);
  };
  const oSections = [
    {
      to: `/${masterlistType}/${aliasAPI}/1`,
      title: "",
      text: explore.POSTS,
    },
    {
      to: `/${masterlistType}/${aliasAPI}/2`,
      title: "",
      text: explore.INSIGHTS,
    },
  ];
  return (
    <>
      <BottomMainMenu />
      <CommonLeftSidebar
        sections={introData}
        oSections={oSections}
        onClose={onClose}
        leftFlag={true}
        journalDetails={journalDetails}
        handleNavigation={handleNavigation}
      />
    </>
  );
};
const IntroductionBody = ({aliasAPI}) => {
  const [geographicaldetail, setGeographicaldetail] = useState([false])
  const [landmark, setLandmark] = useState([])
  React.useEffect(() => {
  const getGeographicalData = async () => {
    const result = await getGeographicalMasterlistData.getGeographicalDataByAlias(
      "geographical ",
      aliasAPI
    );
    result.map(
      (item) => item.key === "geographicaldata" && setGeographicaldetail(item.value)
    );
    result.map(
      (item) => item.key === "landmark" && setLandmark(item.value)
    );
  }
  getGeographicalData();
  },[aliasAPI]);
  
  const [showAccordian, setShowAccordian] = useState(true)

  return (
    <>
      <Container maxW={"100%"} position={"relative"} px="0px">
    <CountryMap setShowAccordian={setShowAccordian} />
    {showAccordian && <Stack
        flexDirection={{ base: "column", md: "row" }}
        justifyContent={"flex-end"}
        width={{ base: "100%", md: "100%" }}
        px={{ base: 4, md: 0 }}
        height={{ base: "auto", md: "calc(100vh - 75px)" }}
        pb={4}
      >
        <Stack  position={"fixed"}
        bg={"#ffffffa3"}
        zIndex={9999} marginTop="8">
          <Box width={{ base: "100%", md: "320px" }} px={{ base: 4, md: 4 }}>
            <Accordion allowToggle>
              <AccordionItem border={"none"}>
                <AccordionButton className="px-0">
                  <Box flex="1" textAlign="left">
                    <Text
                      fontSize={"16px"}
                      fontWeight={"700"}
                      lineHeight={"24px"}
                      color={"#000000"}
                    >
                     {location.GEOGRAPHICAL_DETAILS}
                    </Text>
                  </Box>
                  <AccordionIcon fontSize={"36px"}/>
                </AccordionButton>
                <Divider color={"#B5B5B5"} borderBottomWidth={"0.1px"} />
                <Box>
                  <AccordionPanel height={"500px"} overflowY={"scroll"} py={2} className="px-0">
                  {geographicaldetail.length > 0 ? 
                   (geographicaldetail.map((item) => {
                            return (
                              <Box py="1">
                                   <Box>
                                    <Text fontSize={"16px"} fontWeight={"700"}>
                                    {`${item.display}`}
                                  </Text>
                                  </Box>
                                  <Box>
                                    <Text fontSize={"16px"} fontWeight={"400"}>
                                    {`${item.value}`}
                                  </Text>
                                  </Box>
                                </Box>
                            );
                          })):
                          <Text>{common.CONTENT_AWAITED}</Text>
                        }
                  </AccordionPanel>
                </Box>
              </AccordionItem>
              <AccordionItem border={"none"}>
                <AccordionButton className="px-0">
                  <Box flex="1" textAlign="left">
                    <Text
                      fontSize={"16px"}
                      fontWeight={"700"}
                      lineHeight={"24px"}
                      color={"#000000"}
                    >
                      {location.LANDMARK  }
                    </Text>
                  </Box>
                  <AccordionIcon fontSize={"36px"} />
                </AccordionButton>
                <Divider color={"#B5B5B5"} borderBottomWidth={"0.1px"} />
                <AccordionPanel py={2} px={"1"}>
                {landmark.length > 0 ? 
                   (landmark.map((item) => {
                            return (
                              <Box py="1">
                                   <Box>
                                    <Text fontSize={"16px"} fontWeight={"700"}>
                                    {`${item.display}`}
                                  </Text>
                                  </Box>
                                  <Box>
                                    <Text fontSize={"16px"} fontWeight={"400"}>
                                    {`${item.value}`}
                                  </Text>
                                  </Box>
                                </Box>
                            );
                          })):
                          <Text>{common.CONTENT_AWAITED}</Text>
                        }
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          </Box>
        </Stack>
      </Stack>}
      </Container>
    </>
  );
};