import { IndexData } from "../MockData/IndexData";

export const getIndexData = async () => {
  const dummyDataEnabled = process.env.REACT_APP_DUMMY_DATA || "";
  if (dummyDataEnabled.toLowerCase() === "yes") {
    return IndexData;
  } else {
    return [];
  }
};
