import { HomeData } from "../MockData/HomeData";
import { isTransformationNotAllowed } from "../util/isTranformationAllowed";
import api from "../services/api";
import { APIS } from "../endpoints";


export const getHomeDataAPI = async () => {
  const result = await api.get(`${APIS.BASE_URL}${APIS.postsFeatured}`)
  if (result) {
    return result?.data;
  } else {
    return [];
  }
};

export const getHomeData = async () => {
  if (isTransformationNotAllowed() === true) {
    const response = await getHomeDataDummy();
    return response;
  }else if (isTransformationNotAllowed() === false) {
    const response = await getHomeDataAPI();
    try {
      if (response) {
        return response;
      } else {
        console.error("Invalid response data structure:", response);
        return [];
      }
    } catch (e) {
      console.error("Something is wrong", e);
    }
  }  
};

export const getHomeDataDummy = async () => {
  const dummyDataEnabled = process.env.REACT_APP_DUMMY_DATA || "";
  if (dummyDataEnabled.toLowerCase() === "yes") {
    return HomeData;
  } else {
    return [];
  }
};