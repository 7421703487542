import {
    Box,
    useColorModeValue,
    Drawer,
    DrawerContent,
    useDisclosure,
    Stack,
    Container,
  } from "@chakra-ui/react";
  
  //Core file
  import { useNavigate, useParams } from "react-router-dom";
  import React, { useEffect, useState } from "react";
  import { useTranslation } from "react-i18next";

  //Service file
  import * as getIntroductionData from "../../services/EventExhibition";
  import * as getLandingMasterlistData from "../../services/SectionService";
  
  //Component file
  import CommonLeftSidebar from "../../FunctionalComponents/LeftSidebar/CommonLeftSidebar";
  import { BottomMainMenu } from "../../CommonComponents/BottomMainMenu";
  import { WebpageComingSoon } from "../../CommonComponents/WebpageComingSoon";
  import ArtworksCatalogues from "./EconomicsofArts/ArtworksCatalogues";
  
  //Language file
  import { eventExhibition, explore } from "../../constants/constants";
  import TabMain from "../../FunctionalComponents/TabComponents/TabMain";
  
  
  export default function EconomicsofArts({ children }) {
    const { isOpen, onClose } = useDisclosure();
  
    const [introData, setIntroData] = useState([]);
    const [horislinkData, setHorislinkData] = useState([]);
    const [artistDetails, setArtistDetails] = useState(null);
  
    const {alias} = useParams();
    const aliasAPI = alias;
    const masterlistType="event-exhibition"
    React.useEffect(() => {
      const getIntroData = async () => {
        const result = await getIntroductionData.introductionByAlias(
          masterlistType,
          aliasAPI
        );
        if (result) {
          setHorislinkData([
            {
              link: `/research-categories/${
                result[0].find((item) => item?.key === "researchCategories")
                  ?.value[0]?.alias
              }`,
            title: result[0].find((item) => item?.key === "researchCategories")
              ?.value[0]?.value,
          },
          {
            link: `/categories/indian-cinema`,
            title: result[0].find((item) => item?.key === "subject")?.value,
          },
        ]);
        setArtistDetails(
          result.reduce((acc, it) => {
            acc.name = it?.find((item) => item?.key === "full_name")?.value;
            acc.date_of_birth = it?.find(
              (item) => item?.key === "start_date"
            )?.value;
            acc.date_of_death = it?.find(
              (item) => item?.key === "end_date"
            )?.value;
            return acc;
          }, {})
        );
      }
    };
    const getLandingData = async () => {
      const result = await getLandingMasterlistData.getLandingPageInfoByAlias(
        masterlistType,
        aliasAPI
      );

      if (result) {
        setIntroData(
          result.map((it) => ({
            text: it?.find((item) => item?.key === "landing_page_title")?.value,
            to: `/${masterlistType}/${it?.find((item) => item?.key === "masterlist_alias")?.value}/${it?.find((item) => item?.key === "landing_page_abbreviation")?.value
              }/${it.find(
                (item) =>
                  item.key === "landing_page_abbreviation"
              )?.value === "research-centre" ? `library` : ``}
                   `,
            title: it?.find((item) => item?.key === "landing_page_abbreviation")?.value
          }))
        );
      }
    };
    getIntroData();
    getLandingData();
  }, [aliasAPI]);

  const [readmoreNavigation, setReadmoreNavigation] = useState({});
  return (
    <Box minH="100%" bg={useColorModeValue("white", "gray.900")}>
      <SidebarContent
        onClose={() => onClose}
        display={{ base: "none", md: "block" }}
        height="calc(100vh - -64px)"
        readmoreNavigation={readmoreNavigation}
        setReadmoreNavigation={setReadmoreNavigation}
        introData={introData}
        aliasAPI={aliasAPI}
        horislinkData={horislinkData}
        artistDetails={artistDetails}
        masterlistType={masterlistType}
      />
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
      >
        <DrawerContent>
          <SidebarContent
            onClose={onClose}
            readmoreNavigation={readmoreNavigation}
            setReadmoreNavigation={setReadmoreNavigation}
            aliasAPI={aliasAPI}
            horislinkData={horislinkData}
            artistDetails={artistDetails}
            introData={introData}
            masterlistType={masterlistType}
          />
        </DrawerContent>
      </Drawer>

      <Box ml={{ base: 0, md: 52 }} bg="white">
        {children}
        {introData ? <EconomicsofArtsBody
          winnerName={aliasAPI}
          setReadmoreNavigation={setReadmoreNavigation}
          readmoreNavigation={readmoreNavigation}
        />
          :
          (
            <WebpageComingSoon />
          )
        }
      </Box>
    </Box>
  );
}

const SidebarContent = ({
  winnerName,
  onClose,
  aliasAPI,
  horislinkData,
  artistDetails,
  introData,
  masterlistType,
  ...rest
}) => {
  const navigate = useNavigate();
  const handleNavigation = () => {
    navigate(-1);
  }
  const oSections =  [
    {
      to: `/${masterlistType}/${aliasAPI}/posts`,
      title: "",
      text: `${explore.POSTS} | ${explore.INSIGHTS}`,
    }
  ];
  return (
    <>
      <BottomMainMenu />
      <CommonLeftSidebar
        sections={introData}
        oSections={oSections}
        onClose={onClose}
        hMenu={horislinkData}
        artistDetails={artistDetails}
        leftFlag={true}
        handleNavigation={handleNavigation}
        width={"51%"}
      />
    </>
  );
};

export const EconomicsofArtsBody = ({
  winnerName,
  setReadmoreNavigation,
  readmoreNavigation,
  hidden,
  setHidden,
  tableName,
  name,
}) => {
  const navigate = useNavigate();
  let { tabIndex } = useParams();
  const { alias } = useParams();
  const aliasAPI = alias;
  const masterlistType = "object-book-ssb"
  const { t } = useTranslation("");
    useEffect(() => {
      if (tabIndex) {
        navigate(`/${masterlistType}/${aliasAPI}/body-of-work`, { replace: true });
      }
    }, [tabIndex, navigate,aliasAPI]);

    const [tabPanelData, setTabPanelData] = useState([]);

    function formatData() {
      return [
        {
          name: `${t(eventExhibition.ARTWORKS_FROM_CATALOUGES)}`,
          component:<ArtworksCatalogues winnerName={winnerName}/>,
        }
      ];
    }
    useEffect(() => {
      if (winnerName) {
        setTabPanelData(formatData());
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [winnerName]);
    return (
      <Stack width={"100%"}>
          <Container
            maxW={"auto"}
            pb="4"
            paddingX={{ base: "4", md: "62px" }}
          >
            <Stack width={"100%"}>
          {tabPanelData && tabPanelData.length > 0 && (
            <TabMain data={tabPanelData} panelTop={"40px"}/>
          )}
            </Stack>
          </Container>
      </Stack>
    );
  };
  
