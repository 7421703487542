import {configureStore} from '@reduxjs/toolkit';
// import AsyncStorage from '@react-native-async-storage/async-storage';
import {setupListeners} from '@reduxjs/toolkit/query';
import createSagaMiddleware from 'redux-saga';
import {persistReducer, persistStore} from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web

import rootSaga from './../sagas/index';
import createReducer from '../reducers/index';
const persistConfig = {
  key: 'root',
  version: 1,
  storage,
};
const sagaMiddleware = createSagaMiddleware();
const red = createReducer();
const persistedReducer = persistReducer(persistConfig, red);
const store = configureStore({
  reducer: persistedReducer,
  middleware: gdm => [
    ...gdm({
      serializableCheck: false,
      immutableCheck: false,
    }).concat([sagaMiddleware]),
  ],
});

sagaMiddleware.run(rootSaga);

setupListeners(store.dispatch);

export default store;
export const persistor = persistStore(store);
