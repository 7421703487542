import {
  HStack,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { explore } from "../../../constants/constants";
import * as getCatalougeData from "./../../../services/PersonArtist";
import Catalouge from "./Catalouge/Catalouge";
import Exhibits from "./Catalouge/Exhibits";
import ExhibitionsPhotographs from "./Catalouge/ExhibitionsPhotographs";
import ArtCriticismMediaReportage from "./Catalouge/ArtCriticismMediaReportage";
import { FaUserAlt } from "react-icons/fa";
import TabMain from "../../../FunctionalComponents/TabComponents/TabMain";

export default function PersonArtistCatlouge({
  data,
  masterlist,
  galleryName,
}) {
  const [catalougeData, setCatalougeData] = useState([]);
  const [exhibitsData, setExhibitsData] = useState([]);
  const [exhibitionPhotoData, setExhibitionPhotoData] = useState([]);
  const [artCriticismData, setArtCriticismData] = useState([]);
  const createDataObject = (dataArray) => {
    const dataObject = {};
    if (dataArray.length > 0) {
      const dataItem = dataArray[0];
      for (const key in dataItem) {
        dataObject[key] = dataItem[key];
      }
    }
    return dataObject;
  };
  const citationFactor = (data) => {
    return data.map((item, index) => {
      const author = createDataObject(item.artist);
      const title_of_artwork = createDataObject(item.title_of_artwork);
      const medium_artwork = createDataObject(item.medium_artwork);
      return {
        desc:
          `${author?.name}.` +
          `${title_of_artwork?.value},` +
          ` ${medium_artwork?.value}  ${medium_artwork?.date_of_artwork}`,
          image: item.image,
        medium_artwork,
        author,
        title_of_artwork,
        content: item.content !== "" ? item.content : "Content Awaited",
        title: `${author?.name}`,
        fullImg: item?.fullImage,
        essayistData: [title_of_artwork, author, medium_artwork],
        link: item?.link,
      };
    });
  };
  useEffect(() => {
    const getData = async () => {
      const result = await getCatalougeData.catalougeDatabyExhibition(
        "catalouge",
        masterlist,
        data?.exhibition_centre
      );
      result.map(
        (item) => item.key === "catalogue" && setCatalougeData(item.value)
      );
      result.map(
        (item) =>
          item.key === "exhibits" && setExhibitsData(citationFactor(item.value))
      );
      result.map(
        (item) =>
          item.key === "exhibition-photographs" &&
          setExhibitionPhotoData(item.value)
      );
      result.map(
        (item) =>
          item.key === "art-criticism-media-reportage" &&
          setArtCriticismData(item.value)
      );
    };

    getData();
    // eslint-disable-next-line
  }, []);

  const [tabPanelData, setTabPanelData] = useState([])
  function formatData() {
    return [
      {
        name: `${explore.CATALOGUE}`,
        component:catalougeData.length > 0 && <Catalouge data={catalougeData} />,
      },
      {
        name: `${explore.EXHIBITS}`,
        component: exhibitsData.length > 0 && <Exhibits data={exhibitsData} />,
      },
      {
        name: `${explore.EXHIBITION_PHOTOGRAPHS}`,
        component: <ExhibitionsPhotographs data={exhibitionPhotoData} />,

      },
      {
        name: `${explore.ART_CRITICISM_MEDIA_REPORTAGE}`,
        component: <ArtCriticismMediaReportage data={artCriticismData} />,

      },

    ];
  }
  useEffect(()=>{
    if(masterlist!==undefined){
      setTabPanelData(formatData())
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[masterlist,exhibitsData])

  return (
    <>
      <HStack px={"40px"}>
        <HStack alignItems={"flex-start"}>
          <FaUserAlt
            color="black"
            display={"inline"}
            fontSize={"14px"}
            style={{ marginTop: "4px" }}
          />
          <Text
            w={"356px"}
            fontSize={"16px"}
            fontWeight={500}
            color={"#035DA1"}
          >
            {data?.catalogueTitle}{" "}
          </Text>
        </HStack>
      </HStack>
      {tabPanelData && tabPanelData.length > 0 && (
            <TabMain data={tabPanelData}/>
          )}
    </>
  );
}
