import React, { useEffect, useState } from "react";
import {
  Box,
  Container,
  HStack,
  Heading,
  ListItem,
  Stack,
  UnorderedList,
} from "@chakra-ui/react";
import * as getMarketAnalysis from "./../../../services/PersonArtist";
import VolumeandValueArtworkSold from "./ArtMaketAnalysis/VolumeandValueArtworkSold";
import AverageArtworkSale from "./ArtMaketAnalysis/AverageArtworkSale";
import ArtworkSalesVsMedium from "./ArtMaketAnalysis/ArtworkSalesVsMedium";
import PercentageDifferenceMidEstimate from "./ArtMaketAnalysis/PercentageDifferenceMidEstimate";
import { WebpageComingSoon } from "../../../CommonComponents/WebpageComingSoon";

function ArtMarketAnalysis({ tableName, masterlist }) {
  const [marketData, setmarketData] = useState([]);
  const currentEndpoint = window.location.pathname;
  const parts = currentEndpoint.split("/");
  const masterlistType = parts[1]
  
  function scrollToSection(id) {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }
  useEffect(() => {
    const fetchData = async () => {
      let result;
      result = await getMarketAnalysis.getArtMArketAnalysisData(
        masterlistType,
        masterlist
      );

      setmarketData(result);
    };
    fetchData();
  }, [masterlistType, masterlist]);
  return (
    <Container
      position={"relative"}
      maxW="auto"
      px="40px"
      className=""
      paddingTop={{ base: "40px", md: "0px" }}
    >
      <Stack
        flexDirection={{ base: "column", md: "column" }}
        justifyContent="space-between"
        gap={4}
        minH={{ base: "100vh", md: "82vh" }}
      >
        {marketData && marketData.length>0 ?
         (
          <>
         <HStack flexDirection={"column"} alignItems={"self-start"}>
          <Box>
            <Heading as="h3" size="lg" mb={4} pb={4}>
              Public Auction Highlights (F1 Category)
            </Heading>
            <UnorderedList>
              <ListItem py={4}>
                <Heading
                  as="h3"
                  cursor={"pointer"}
                  size="md"
                  lineHeight={"21.13px"}
                  color={"#035DA1"}
                  onClick={() =>
                    scrollToSection("volume-value-of-artwork-sold")
                  }
                >
                  Volume and Value of Artwork Sold (1987 - present)
                </Heading>
              </ListItem>
              <ListItem py={4}>
                <Heading
                  lineHeight={"21.13px"}
                  color={"#035DA1"}
                  as="h3"
                  cursor={"pointer"}
                  size="md"
                  onClick={() =>
                    scrollToSection("average-artwork-sale-value-over-years")
                  }
                >
                  Average Artwork Sale Value over the years
                </Heading>
              </ListItem>
              <ListItem py={4}>
                <Heading
                  lineHeight={"21.13px"}
                  color={"#035DA1"}
                  as="h3"
                  cursor={"pointer"}
                  size="md"
                  onClick={() =>
                    scrollToSection("artwork-sales-vs-medium-of-artwork")
                  }
                >
                  Artwork sales vs Medium of Artwork
                </Heading>
              </ListItem>
              <ListItem py={4}>
                <Heading
                  lineHeight={"21.13px"}
                  color={"#035DA1"}
                  as="h3"
                  cursor={"pointer"}
                  size="md"
                  onClick={() =>
                    scrollToSection("percentage-difference-mid-estimate")
                  }
                >
                  Percentage Difference from Mid Estimate{" "}
                </Heading>
              </ListItem>
            </UnorderedList>
          </Box>
        </HStack>
        <HStack
          flexDirection={"column"}
          alignItems={"flex-start"}
          id="volume-value-of-artwork-sold"
        >
          <VolumeandValueArtworkSold data={marketData} />
        </HStack>
        <HStack
          flexDirection={"column"}
          alignItems={"flex-start"}
          id="average-artwork-sale-value-over-years"
        >
          <AverageArtworkSale data={marketData} />
        </HStack>
        <HStack
          flexDirection={"column"}
          alignItems={"flex-start"}
          id="artwork-sales-vs-medium-of-artwork"
        >
          <ArtworkSalesVsMedium data={marketData} />
        </HStack>
        <HStack
          flexDirection={"column"}
          alignItems={"flex-start"}
          id="percentage-difference-mid-estimate"
        >
          <PercentageDifferenceMidEstimate data={marketData} />
        </HStack>
        </>
        ) : (
          <WebpageComingSoon/>
          )
        }
      </Stack>
    </Container>
  );
}

export default ArtMarketAnalysis;
