import { Text } from "@chakra-ui/react";
import React from "react";

const Subheading = ({ children, ...props }) => {
  return (
    <Text {...props} fontSize="48px" fontWeight="700" lineHeight={"58px"}>
      {children}
    </Text>
  );
};
export default React.memo(Subheading);
