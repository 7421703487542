import {
  Box,
  Container,
  HStack,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Stack,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { CgArrowsExpandRight } from "@react-icons/all-files/cg/CgArrowsExpandRight";
import { Swiper, SwiperSlide } from "swiper/react";

// Service Files
import { CopyPasteService } from "../../../../services/CopyPaste";
import * as getScreeningMasterlistData from "../../../../services/Screenings";

// Subsection Files
import { common, explore } from "../../../../constants/constants";
import { EffectFlip, Navigation, Pagination } from "swiper";
import { FilterButton } from "../../../../CommonComponents/FilterButton";
import TimeLine from "../../../../FunctionalComponents/TimeLine/TimeLine";
import TimelineWithImage from "../../../../FunctionalComponents/TimeLine/TimelineWithImage";
import { FilterBox } from "../../../../FunctionalComponents/Filter/FilterBox";
import InfiniteScroll from "react-infinite-scroll-component";
import NoMoreToLoad from "../../../../CommonComponents/NoMoreToLoad";
import FilterPills from "../../../../components/FilterPills/FilterPills";
import { totalFilterCount, updateFacetsData } from "../../../../util/helper";
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;
const loaderImg = `${IMAGE_BASE_PATH}/ui/Chevron/loader.gif`

function Timeline({ masterlistType, masterlist, setScreeningCount }) {
  const btnRef = React.useRef();
  const [showFilterBox, setShowFilterBox] = useState(false);
  const [fullScreenImage, setFullScreenImage] = useState(false);
  const [imgData, setImgData] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [apply, setApply] = useState(false);
  const [filterOptions, setFilterOptions] = useState({ search: "", filters: {} });
  const [filterPills, setFilterPills] = useState(null);
  const [filteredResultCount, setFilteredResultCount] = useState(0);
  const [filterCount, setFilterCount] = useState(0);

  const [loading, setLoading] = useState(true);

  const handleFilterClick = () => {
    setShowFilterBox(!showFilterBox);
  };
  const showFullImages = (image, flag) => {
    if (image) {
      setFullScreenImage(flag);
      setImgData(image);
    } else {
      setFullScreenImage(flag);
      setImgData(null);
    }
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    handleCheckboxChange("searches",e.target.value)
  };

  const handleReset=() => {
    const initializedFilters = facets.reduce((acc, facet) => {
      acc[facet.key] = [];
      return acc;
    }, {});
  
    setFilterOptions({
      search: "",
      filters: initializedFilters
    });
    setSearchTerm("");
    setFilterCount(0)
  }
  const handleCheckboxChange = (value, type) => {
    let selectedArray = [];
    let selectedString = "";  
    switch (value) {
      case "sort":
        selectedString = filterOptions.sortBy_v;
        break;
      case "searches":
        selectedString = filterOptions.search;
        break;
      default:
        if (value in filterOptions.filters) {
          selectedArray = filterOptions.filters[value];
        }
        break;
    }
  
    if (selectedArray.includes(type)) {
      selectedArray = selectedArray.filter((checkbox) => checkbox !== type);
    } else {
      selectedArray = [...selectedArray, type];
    }
  
    if (selectedString !== type) {
      selectedString = type;
    }
  
    switch (value) {
      case "sort":
        setFilterOptions(prevFilterOptions => ({
          ...prevFilterOptions,
          sortBy_v: selectedString,
        }));
        break;
      case "searches":
        setFilterOptions(prevFilterOptions => ({
          ...prevFilterOptions,
          search: selectedString,
        }));
        break;
      default:
        if (value in filterOptions.filters) {
          setFilterOptions(prevFilterOptions => ({
            ...prevFilterOptions,
            filters: {
              ...prevFilterOptions.filters,
              [value]: selectedArray,
            },
          }));
        }
        break;
    }
  };
  // need to refactor the function to bring real data for filter.
  const [imageData, setImageData] = useState([]);

  // const extractedImages = (data) => {
  //   let extractImg = data.flatMap((entry) => {
  //     const screeningImages = entry.find(
  //       (item) => item.key === "screeningImages"
  //     );
  //     if (screeningImages && screeningImages.value.length > 0) {
  //       return screeningImages.value.map((image) => image.value);
  //     }
  //     return [];
  //   });
  //   return extractImg;
  // }

  const [swiper, setSwiper] = useState(null);
  const [iconClicked, setIconClicked] = useState(false);
  const showImage = (index) => {
    swiper.slideTo(index);
    setTimeout(() => {
      setIconClicked(!iconClicked);
    }, 500);
  };
  const citationFactor = (data) => {
    return data.map((item) => ({
      year:`${item.sortable_date}`,
      value:`${item.bibliographic_caption}`,
      images:`${item.image_alias}`
    }));
  };

  const [facets, setFacets] = useState([]);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  async function fetchData() {
    try {
      setLoading(true);
      const result = await getScreeningMasterlistData.getScreeningByMasterList(masterlistType, masterlist, filterOptions,page )
      setLoading(false);

      if (result?.data?.length > 0) {
        const processedData = citationFactor(result?.data);
        setData(processedData);
        setPage(prevPage => prevPage + 1);
        const formattedFacets = result?.facetsHeaders?.map(header => {
          const key = Object.keys(header)[0];
          const display = header[key];
          const value = result?.facets[0][key]; // Assuming facetsData has only one object
          return {
            key,
            display,
            value
          };
        });
        setScreeningCount(result?.total)
        if (processedData.length === result?.total) {
          setHasMore(false);
        }
        setImageData([])
        setFacets(formattedFacets);
        if(formattedFacets.length>0){
          setFilterOptions(prevFilterOptions => ({
            ...prevFilterOptions,
            filters: {
              ...prevFilterOptions.filters,
              ...formattedFacets.reduce((acc, facet) => {
                acc[facet.key] = [];
                return acc;
              }, {})
            }
          }));
      }
    }

    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }
  }
  useEffect(() => {
    fetchData();
    setFilterPills(filterOptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); 
  const fetchMoreData = async () => {
    try {
      setLoading(true);
      const result = await getScreeningMasterlistData.getScreeningByMasterList(masterlistType, masterlist, filterOptions,page )
      setLoading(false);

      if (result) {
        const processedData = citationFactor(result?.data);
        setData(prevData => [...prevData, ...processedData]);
        setPage(prevPage => prevPage + 1);
        if (processedData.length === result?.total) {
          setHasMore(false);
        }
      }

    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);

    }
  }

  async function fetchFilterData(page=1) {
    try {
      const result = await getScreeningMasterlistData.getScreeningByMasterList(masterlistType, masterlist, filterOptions,page )

      if (result) {
        const processedData = citationFactor(result?.data);
        setData(processedData);
        if (data?.length >= result?.total) {
          setHasMore(false);
        }
        const filtercnt= totalFilterCount(filterOptions?.filters)
        
        if(filtercnt>0 || filterOptions?.search){
          setFilteredResultCount(result?.total)
        }else{
          setFilteredResultCount(0);
        }
        if (result?.facetsHeaders && result?.facets) {
          const formattedFacets = updateFacetsData(result);
          setFacets(formattedFacets);
        }
      }

    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }
  useEffect(() => {

    if (apply) {
      setApply(false);
      setHasMore(true);
      setPage(1);
      fetchFilterData();
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      setFilterPills(filterOptions)
      const filtercnt= totalFilterCount(filterOptions?.filters)
      setFilterCount(filtercnt)

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apply,filterOptions]);
  return (
    <>
     {!loading ?
     <>
     {filterPills &&  filterPills!==undefined && filterCount>0&&
      <FilterPills
         filters={filterPills}
         setFilterOptions={setFilterOptions}
         setApply={setApply}
         setSearchTerm={setSearchTerm}
         setFilterPills={setFilterPills}
         filteredResultCount={filteredResultCount}
       />
      }
      <Stack
        width={"1160px"}
        flexDirection={"row-reverse"}
        justifyContent={"space-between"}
        className="mt-0"
      >
        {!showFilterBox &&<FilterButton onClick={handleFilterClick} />}
        <Container maxW="auto" className="mt-0" pb="4" px="0px">
          <Stack
            flexDirection={"row"}
            justifyContent={"space-between"}
            marginTop={"0px !important"}
            width={"100%"}
            alignItems={"flex-start"}
            minH={"100vh"}
          >
            <HStack
              flexDirection={"column"}
              alignItems={"baseline"}
              p={"0px !important"}
              onCopy={(e) => CopyPasteService(e, window.location.href)}
              className="col-7"
              margin={!data.length > 0 && "0px auto"}
              ml={8}
            >
              {data.length > 0 ?
              <InfiniteScroll
              dataLength={data.length}
              next={fetchMoreData}
              hasMore={hasMore}
              loader={<Box textAlign="center" marginLeft={"50%"}>
              <Stack marginTop={"20px"}>
                 <img src={loaderImg} width={"100px"} height={"100px"} alt="Loading" />
              </Stack>
            </Box>}
              endMessage={<NoMoreToLoad/>}
              >
            <TimeLine
               data={data}
               showImage={showImage}
               btnRef={btnRef}
               component={TimelineWithImage}
             />
                </InfiniteScroll>
                : (
                  <HStack w={"100%"}>
                  <Box
                    display={"flex"}
                    alignItems={"center"}
                    w={"inherit"}
                    justifyContent={"right"}
                    gap={4}
                  >
                    <Text py={"40"} fontSize={"45px"} fontWeight="700" textAlign="center">
                    {common.NO_DATA}
                   </Text>
                  </Box>
                </HStack>
              )}
            </HStack>
            {!showFilterBox && imageData.length > 0 && (
              <HStack
                px={"0px"}
                alignItems={"flex-start"}
                justifyContent={"flex-end"}
                id="allsliderskkScreening"
                className={
                  !iconClicked ? "col-4 imageZoomOut" : "col-4 imageZoomIn"
                }
              >
                <Swiper
                  grabCursor={true}
                  navigation={true}
                  modules={[EffectFlip, Navigation, Pagination]}
                  onSwiper={setSwiper}
                >
                  {imageData.map((it) => (
                    <SwiperSlide key={it}>
                      <Box
                        height={"395px"}
                        width={"322px"}
                        bg={"#C4C4C4"}
                        border={"1px solid #C4C4C4"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        display={"flex"}
                        flexDirection={"column"}
                      >
                        <Text fontSize={"15px"} fontWeight={"700"}>
                          {common.IMAGE_AWAITED}
                        </Text>
                      </Box>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </HStack>
            )}
            <HStack
              className="col-4"
              alignItems={"flex-start"}
              flexDirection={"row-reverse"}
              p={"0px !important"}
            >
              {showFilterBox && (
                <FilterBox
                  setShowFilterBox={setShowFilterBox}
                  setApply={setApply}
                  explore={explore}
                  handleCheckboxChange={handleCheckboxChange}
                  handleSearch={handleSearch}
                  filterOptions={filterOptions}
                  filteroptionData={facets}
                  setFilterOptions={setFilterOptions}
                  setSearchTerm={setSearchTerm}
                  filterCount={filterCount}
                  searchTerm={searchTerm}
                  handleReset={handleReset}
                  className={"box-filterscreening"}
                />
              )}
            </HStack>
          </Stack>
        </Container>
      </Stack>
      <ImagePopupModal
        isOpen={fullScreenImage}
        onClose={() => showFullImages(null, false)}
        imageData={imgData}
      />
      </> :
      <HStack justifyContent={"center"} minH={"100vh"} alignItems={"flex-start"} >
      <Image src={loaderImg} width={"100px"} height={"100px"} alt="Loading" mt={12} />
    </HStack>
      }
    </>
  );
}
export default Timeline;

const ImagePopupModal = ({ isOpen, onClose, imageData }) => {
  return (
    <Modal
      size={"full"}
      isCentered
      isOpen={isOpen}
      onClick={onClose}
      // onClose={(e) => setFullscreen(false)}
      Id="modalfullscreen"
    >
      <ModalOverlay />
      <ModalContent zIndex={"999"}>
        <ModalBody>
          <Box bg="white">
            <Stack>
              <HStack justifyContent={"center"}>
                <Image src={imageData} height={"100vh"}></Image>
              </HStack>
              <HStack justifyContent={"flex-end"}>
                <Text
                  fontSize={"xl"}
                  bg={"black"}
                  opacity={0.7}
                  color={"white"}
                  fontWeight={"400"}
                  padding="2"
                  borderRadius={"35px"}
                  width="max-content"
                  marginTop="-200px"
                  marginRight={"15%"}
                  cursor="pointer"
                  position={"absolute"}
                  onClick={onClose}
                  // onClick={(e) => setFullscreen(false)}
                >
                  <CgArrowsExpandRight />
                </Text>
              </HStack>
            </Stack>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
