import React from 'react';
import { Stack } from '@chakra-ui/react';
import { userRegistrationForm } from '../../constants/constants';
import H1 from '../../Theme/Typography/H1';

const StepIndicator = ({ step, totalSteps }) => {
  return (
    <>
    
    <Stack  alignItems={"flex-end"} position="relative" bottom={{base:"80px",md:"72px"}} left={"auto"} right={{base:"3%",md:"1%"}}>
      <H1 fontSize={{base:"24px",md:"32px"}} lineHeight={{base:"28px",md:"32px"}} fontWeight="700">
      {userRegistrationForm.STEP} {step}/{totalSteps}
      </H1>
    </Stack>
    </>
  );
};

export default StepIndicator;
