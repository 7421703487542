import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { explore } from "../../constants/constants";
import { All } from "./All";
import { Archive } from "./Archive";
import { Box, Container, HStack, Image, Stack } from "@chakra-ui/react";
// import ReadMore from "../../pages/Film/ReadMore";
// import { BookDetails } from "../../pages/PersonalityArtist/ResearchCentre/BookDetails";
import TabMain from "../../FunctionalComponents/TabComponents/TabMain";
import { Library } from "./Library";
import { getImageURL } from "../../util/getImage";
import * as getBibliographyData from "../../services/Bibliography";
import * as getresearchCentreAll from "../../services/ResearchCentreAll";
import * as getArchiveData from "../../services/Archive";
import * as getresearchCentreHighlight from "../../services/Highlights";

import { BookDetails } from "../LibraryBook/BookDetails";
import { Highlights } from "./Highlights";

export const LibraryAndArchive = ({
    masterlist,
    setResearchCentreFlag,
    bookFlag,
    setBookFlag,
    openDrawer,
    setOpenDrawer,
    setleftFlag,
    bookReadmore,
    setBookReadmore,
    masterlistType,
    marginX="30px",
    width="1160px",
    name,
    listId,
    top,
    right,
    className,
    filerStyle
  }) => {
    const [visible, setVisible] = useState(true);
    const [listGridIcon, setListGridIcon] = useState(true);
    const navigate = useNavigate();
    let { bookAlias } = useParams();
    let {tabValue,bookDetails}=useParams();
    const [attr, setAttr] = useState(1)
    const [allCount, setAllCount] = useState(0)
    const [libraryCount, setLibraryCount] = useState(0)
    const [archiveCount, setArchiveCount] = useState(0)
    const [highlightCount, setHighlightCount] = useState(0)

    const pathToIndex = {
      'all': 0,
      'library': 1,
      'archive': 2,
      'highlights':3,
  
    };
    const location = useLocation();
    // const width = { base: "100%", md: "1160px" };
  
    useEffect(() => {
      setAttr(pathToIndex[tabValue] ?? 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location, attr]);
    /* Need to check after dynamic book data */
    async function fetchData() {
      try {
        const  allResult = await getresearchCentreAll.researchCentreAllByMasterlist(masterlistType, masterlist,{},1);
        const  libraryResult = await getBibliographyData.bibliographyByMasterlist(masterlist,
          masterlistType,
          {},
          1);
        const  archiveResult = await getArchiveData.getArchiveImages(masterlistType, masterlist, {}, 1);
        const  highlightResult = await getresearchCentreHighlight.researchCentreHighlightsByMasterlist(masterlistType, masterlist, {}, 1);

        if (allResult && allResult?.data.length>0 ) {
        setAllCount(allResult?.total)
      }
      if (libraryResult && libraryResult?.data.length>0 ) {
        setLibraryCount(libraryResult?.total)
      }
      if (archiveResult && archiveResult?.data.length>0 ) {
        setArchiveCount(archiveResult?.total)
      }

      if (highlightResult && highlightResult?.data.length>0 ) {
        setHighlightCount(highlightResult?.total)
      }

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }

    const handleBookClick = (flag, data) => {
      navigate(`bookDetails`)
      setResearchCentreFlag(false);
    };
  
    const [tabPanelData, setTabPanelData] = useState([]);
    function formatData() {
      if(tabValue==="all" || tabValue==="highlights"){
        setListGridIcon(false)
      }
      return [
        {
          name: `${explore.ALL}`,
          count:`${allCount}`,
          component:tabValue==="all" &&<All 
          openDrawer={openDrawer}
          setOpenDrawer={setOpenDrawer}
          visible={visible} masterlistType={masterlistType} masterlist={masterlist} setAllCount={setAllCount} handleBookClick={handleBookClick}/>,
          list:false,
          link:masterlistType==="film" || masterlistType==="person-actor" || masterlistType==="person-artist" || masterlistType==="person-poly" || masterlistType==="person-film-maker" || masterlistType==="event-exhibition" || masterlistType==="event-film-festival" ||  masterlistType==="object-book-jou"?`/${masterlistType}/${masterlist}/agraphy/research-centre/all`:`/${masterlistType}/${masterlist}/research-centre/all`
        },
        {
          name: `${explore.LIBRARY}`,
          count:`${libraryCount}`,
          component:tabValue==="library" && <Library
          visible={visible}
          handleBookClick={handleBookClick}
          openDrawer={openDrawer}
          setOpenDrawer={setOpenDrawer}
          setLibraryCount={setLibraryCount}
          setleftFlag={setleftFlag}
          tableName={"bibliographies"}
          masterlist={masterlist}
          masterlistType={masterlistType}
          filerStyle={filerStyle}
        />,
        list:true,
        link:masterlistType==="film" || masterlistType==="person-actor" || masterlistType==="person-artist" || masterlistType==="person-poly" || masterlistType==="person-film-maker" || masterlistType==="event-exhibition" || masterlistType==="event-film-festival" ||  masterlistType==="object-book-jou" ?`/${masterlistType}/${masterlist}/agraphy/research-centre/library`:`/${masterlistType}/${masterlist}/research-centre/library`
        },
        {
          name: `${explore.ARCHIVE}`,
          count:`${archiveCount}`,
          component:tabValue==="archive" &&<Archive filerStyle={filerStyle} visible={visible} masterlistType={masterlistType} masterlist={masterlist} setArchiveCount={setArchiveCount}
         />,
          list:true,
          link:masterlistType==="film" || masterlistType==="person-actor" || masterlistType==="person-artist" || masterlistType==="person-poly" || masterlistType==="person-film-maker" || masterlistType==="event-exhibition" || masterlistType==="event-film-festival" || masterlistType==="object-book-jou" ?`/${masterlistType}/${masterlist}/agraphy/research-centre/archive`:`/${masterlistType}/${masterlist}/research-centre/archive`
        },
        {
          name: `${explore.HIGHLIGHTS}`,
          count:`${highlightCount}`,
          component:tabValue==="highlights" &&<Highlights 
          openDrawer={openDrawer}
          setOpenDrawer={setOpenDrawer}
          visible={visible} masterlistType={masterlistType} masterlist={masterlist} setHighlightCount={setHighlightCount} handleBookClick={handleBookClick}/>,
          list:false,
          link:masterlistType==="film" || masterlistType==="person-actor" || masterlistType==="person-artist" || masterlistType==="person-poly" || masterlistType==="person-film-maker" || masterlistType==="event-exhibition" || masterlistType==="event-film-festival" ?`/${masterlistType}/${masterlist}/agraphy/research-centre/highlights`:`/${masterlistType}/${masterlist}/research-centre/highlights`
        },
      ];
    }
    useEffect(() => {
      if (masterlist) {
        setTabPanelData(formatData());
        fetchData()
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [masterlist,visible,openDrawer,bookAlias,archiveCount,libraryCount,allCount,tabValue]);
  
    const tabHandle=(item)=>{
      setListGridIcon(item)
    }
    return (
      <Stack position={"relative"} width={width} minH={"calc(100vh - 64px)"}>
        <Container minW={"100%"} pb="4" px={{base:"4",md:"0px"}} marginX={marginX}>
          <Stack>
          {name && <Box
            position={"fixed"}
            zIndex={999}
            bg="white"
            width={{ base: "100%%", md: "1150px" }}
          >
            <HStack paddingTop={{ base: "20px", md: "30px" }} paddingBottom={{ base: "20px", md: "14px" }}>
              <Box paddingRight={{base:"4",md:""}} width={{ base: "100%", md: "656px" }}>
                <Image src={getImageURL(name)} width={"100%"} height={"60px"}></Image>
              </Box>
            </HStack>
          </Box>}

          {bookDetails !==undefined ? (
              <>
                  <BookDetails name={name}/>
              </>
            ) : (
              <HStack position={"relative"}>
                {tabPanelData && tabPanelData.length > 0 && (
              <TabMain gap="50px" width={width} pos={name && "fixed"} tabTop={name && { base: "55px", sm: "75px", md: "85px" }} 
              panelTop={name && { base: "65px", sm: "85px", md: "100px" }} data={tabPanelData} listId={listId} right={right} listGridIcon={listGridIcon} tabHandle={tabHandle} attr={attr} setAttr={setAttr} setVisible={setVisible} visible={visible}/>
            )}

              </HStack>
            )}
          </Stack>
        </Container>
      </Stack>
    );
  };
  