import {
  Box,
  Container,
  Drawer,
  DrawerContent,
  Stack,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";

//Core file
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

//Service file
import * as getIntroductionData from "../../../../services/Introduction";
import * as getLandingMasterlistData from "../../../../services/SectionService";

//Component file
import { LyricsSoundtrack } from "./SongsSynopsisDetails/Soundtrack";
import CommonLeftSidebar from "../../../../FunctionalComponents/LeftSidebar/CommonLeftSidebar";
import { BottomMainMenu } from "../../../../CommonComponents/BottomMainMenu";
import SongsBook from "./SongsBook";
import { WebpageComingSoon } from "../../../../CommonComponents/WebpageComingSoon";

//Language file
import { explore } from "../../../../constants/constants";
import HorizontalLayout from "./SongsSynopsisDetails/CrewAndCast/HorizontalLayout";
import VerticalLayout from "./SongsSynopsisDetails/CrewAndCast/VerticalLayout";
import Synopsis from "./SongsSynopsisDetails/Synopsis";
import TabMain from "../../../../FunctionalComponents/TabComponents/TabMain";

export default function SongSynopsisDetails({ children }) {
  const { isOpen, onClose } = useDisclosure();

  const [introData, setIntroData] = useState([]);
  const [horislinkData, setHorislinkData] = useState([]);
  const [journalDetails, setJournalDetails] = useState({});
  const [castTabFlag, setCastTabFlag] = useState(false);

  const {alias } = useParams();
  const aliasAPI = alias;
  const masterlistType = "object-book-ssb"
  React.useEffect(() => {
    const getIntroData = async () => {
      const result = await getIntroductionData.introductionByAlias(
        masterlistType,
        aliasAPI
      );
      if (result) {
        setHorislinkData([
          {
            link: `/research-categories/${
              result[0]?.find((item) => item?.key === "researchCategories")
                ?.value[0]?.link
            }`,
            title: result[0]?.find((item) => item?.key === "researchCategories")
              ?.value[0]?.value,
          },
          {
            link: `/categories/indian-cinema`,
            title: result[0]?.find((item) => item?.key === "subject")?.value,
          },
        ]);

        setJournalDetails(
          result?.reduce((acc, it) => {
            acc.title = it?.find((item) => item?.key === "name")?.value;
            acc.date_Range = it?.find(
              (item) => item?.key === "song_from"
            )?.value+' - '+ it?.find(
              (item) => item?.key === "song_till"
            )?.value;
            return acc;
          }, {})
        );
      }
    };
    const getLandingData = async () => {
      const result = await getLandingMasterlistData.getLandingPageInfoByAlias(
        aliasAPI
      );
      if (result) {
        setIntroData(
          result?.map((it) => ({
            text: it.find((item) => item?.key === "landing_page_title").value,
            to: `/${masterlistType}/${it.find((item) => item?.key === "masterlist").value}/${
              it.find((item) => item?.key === "landing_page_abbreviation").value
            }`,
            title: it.find((item) => item?.key === "landing_page_abbreviation")
              .value,
          }))
        );
      }
    };
    getIntroData();
    getLandingData();
  }, [aliasAPI]);

  const [readmoreNavigation, setReadmoreNavigation] = useState({});
  return (
    <Box minH="100%" bg={useColorModeValue("white", "gray.900")}>
      <SidebarContent
        onClose={() => onClose}
        display={{ base: "none", md: "block" }}
        height="calc(100vh - -64px)"
        readmoreNavigation={readmoreNavigation}
        setReadmoreNavigation={setReadmoreNavigation}
        introData={introData}
        aliasAPI={aliasAPI}
        horislinkData={horislinkData}
        journalDetails={journalDetails}
        masterlistType={masterlistType}
      />
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
      >
        <DrawerContent>
          <SidebarContent
            onClose={onClose}
            readmoreNavigation={readmoreNavigation}
            setReadmoreNavigation={setReadmoreNavigation}
            aliasAPI={aliasAPI}
            horislinkData={horislinkData}
            journalDetails={journalDetails}
            introData={introData}
          />
        </DrawerContent>
      </Drawer>

      <Box ml={{ base: 0, md: 52 }} bg="white">
        {children}
       {introData? <SongSynopsis
          winnerName={aliasAPI}
          setReadmoreNavigation={setReadmoreNavigation}
          readmoreNavigation={readmoreNavigation}
          masterlistType={masterlistType}
          castTabFlag={castTabFlag}
          setCastTabFlag={setCastTabFlag}
        />
      :
      (
       <WebpageComingSoon />
      )
      }
      </Box>
    </Box>
  );
}

const SidebarContent = ({
  winnerName,
  onClose,
  aliasAPI,
  horislinkData,
  journalDetails,
  introData,
  masterlistType,
  ...rest
}) => {
  const navigate = useNavigate();
  const handleNavigation = () => {

      navigate(-1);
    }
    const oSections = [
      {
        to: `/${masterlistType}/${aliasAPI}/1`,
        title: "",
        text: explore.POSTS,
      },
      {
        to: `/${masterlistType}/${aliasAPI}/2`,
        title: "",
        text: explore.INSIGHTS,
      },
    ];
  return (
    <>
      <BottomMainMenu />
      <CommonLeftSidebar
        sections={introData}
        oSections={oSections}
        onClose={onClose}
        hMenu={horislinkData}
        journalDetails={journalDetails}
        leftFlag={true}
        handleNavigation={handleNavigation}
      />
    </>
  );
};

function SongSynopsis({winnerName,masterlistType,castTabFlag,setCastTabFlag,}) {
  const currentEndpoint = window.location.pathname;
  const parts = currentEndpoint.split("/");
  const songName = parts[4];
  const [creditsView, setCreditsView] = useState(false);
  const [fullCreditsView, setFullCreditsView] = useState(false);

  const tableName = "films";
  const handleCreditsView = (flag) => {
    setCreditsView(flag);
    setCastTabFlag(flag);
  };
  const [tabPanelData, setTabPanelData] = useState([]);

  function formatData() {
    return [
      {
        name: `${explore.BOOKLET}`,
        component: <SongsBook songName={songName}/>,
        list:false

      },
      {
        name: `${explore.SYNOPSIS}`,
        component: <Synopsis masterlist={songName}/>,
        list:false

      },
      {
        name: `${explore.CREDITS_CAST}`,
        component: fullCreditsView ? (
          <VerticalLayout
            winnerName={songName}
            setCastTabFlag={setCastTabFlag}
            tableName={tableName}
            masterlistType={masterlistType}
            bg="white"
          />
        ) : (
          <HorizontalLayout
            winnerName={songName}
            tableName={tableName}
            masterlistType={masterlistType}
          />
        ),
        list:true

      },
      {
        name: `${explore.LYRICS_AND_SOUNDTRACK}`,
        component: <LyricsSoundtrack winnerName={songName} />,
        list:false
      },
    ];
  }
  useEffect(() => {
    if (winnerName) {
      setTabPanelData(formatData());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [winnerName,fullCreditsView]);
  return (
    <Stack height={"100%"}>
      <Container maxW="auto" px={{ base: "4", md: "0" }} mx={"0"}>
        <Stack px={{ base: "0", md: "8" }}>
          {tabPanelData && tabPanelData.length > 0 && (
                <TabMain
                  data={tabPanelData}
                  pos={"fixed"}
                  creditsView={creditsView}
                  fullCreditsView={fullCreditsView}
                  setFullCreditsView={setFullCreditsView}
                  setCastTabFlag={setCastTabFlag}
                  tabHandle={handleCreditsView}
                  panelTop={{ base: "65px", sm: "85px", md: "60px" }}
                  id={"listgridviewSsbbtn1"}
                />
              )}
         </Stack>
        </Container>
    </Stack>
  );
}
