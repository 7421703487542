import {
  art_heritage_masterlist_options,
  mughal_e_azam_masterlist_options,
  sanjeev_kumar_masterlist_options,
  satyajit_masterlist_options,
  skk_masterlist_options,
} from "../MockData/MasterListMockupdata";
import { isTransformationNotAllowed } from "../util/isTranformationAllowed";

export const getMasterlistTypeOptionsAPI = async (tableName, masterlist) => {
  return []
}
export const getMasterlistTypeOptions = async (tableName,masterlist) => {
  if (isTransformationNotAllowed() === true) {
    return await getMasterlistTypeOptionsDummyData(masterlist);
  } else if (isTransformationNotAllowed() === false) {
    return await getMasterlistTypeOptionsAPI(tableName,masterlist);
  }
};

export const getMasterlistTypeOptionsDummyData = (masterlist) => {
      const masterlistOptionsDataMap = {
        "satyajit-ray": satyajit_masterlist_options,
        "shatranj-ke-khilari-1977": skk_masterlist_options,
        "art-heritage-annual": art_heritage_masterlist_options,
        "mughal-e-azam": mughal_e_azam_masterlist_options,
        "sanjeev-kumar":sanjeev_kumar_masterlist_options,
        "sayed-haider-raza":satyajit_masterlist_options,
        "suresh-jindal":satyajit_masterlist_options,
        "k.g-Subramanyan":satyajit_masterlist_options
        // Add more cases as needed
      };
      // Check if the masterlist exists in the map, if not, use a default value
      const masterlistOptionsData = masterlistOptionsDataMap[masterlist] || satyajit_masterlist_options;
      return masterlistOptionsData;
  // }
};
