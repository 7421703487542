import { Box, Image, Stack, HStack, Text } from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";
import { CopyPasteService } from "../../services/CopyPaste";

const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;
const placeholderImg = `${IMAGE_BASE_PATH}/ui/DummyImage/small.png`;

export default function ImageTextGrid({
  data,
  columns,
  spacing,
  height,
  fontSize,
  textStyle,
  fontWeight,
}) {
  return (
    <>
      {data?.map((item) => {
        return (
          <Box height="auto">
            {item.link !== "" ? <Link
              to={item.navigationLink === "" ? null : item.navigationLink}
              textDecoration={"none"}
            >
              <Box width={"100%"} height={height}
                >
                <Image
                  src={item.imgPath}
                  fallbackSrc={placeholderImg}
                  className={"img-border-radius hover14"}
                  height="100%"
                  objectFit={"cover"}
                  width="100%"
                  border={"1px"}
                  borderColor={"#e8e8e8"}
                />
              </Box>
            </Link>
              :
              <Box width={"100%"} height={height}>
                <Image
                  src={item.imgPath}
                  className={"img-border-radius hover14"}
                  height="100%"
                  objectFit={"cover"}
                  width="100%"
                  border={"1px"}
                  borderColor={"#e8e8e8"}
                /></Box>
            }
            <Stack py="2">
              <HStack
                onCopy={(e) =>
                  CopyPasteService(e, `${window.location.to}/4`)
                }
              >
                <Text fontSize={fontSize} textStyle={textStyle} fontWeight={fontWeight} lineHeight={{base:"14px",md:"18px"}} textAlign={"left"}>{item.title}</Text>
              </HStack>
              {item.published && (
                <HStack>
                  <Text fontSize={"12px"} textStyle={"secondary"} fontWeight={"350"} lineHeight={"15px"} textAlign={"left"} color={"#C41212"}>
                    Published
                  </Text>
                </HStack>
              )}
            </Stack>
          </Box>
        );
      })}
    </>
  );
}
