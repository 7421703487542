import React, { Fragment } from 'react';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { AiOutlinePicture } from '@react-icons/all-files/ai/AiOutlinePicture';
import { Box, Text } from '@chakra-ui/react';

const TimelineWithImage1 = ({ data, showImage }) => {

    return (
           <VerticalTimeline layout={"1-column-left"}>
                {data && data?.map((item, index) => {
                  return (
                    <>
                      {item?.find((item) => item?.key === "awardImage")?.value
                        .length > 0 ? (
                        <Box pos={"relative"} right={"20px"} top={"20px"}>
                          <AiOutlinePicture
                            onClick={() => showImage(0, true)}
                            cursor={"pointer"}
                            color="#1E90FF"
                            size={16}
                          />
                        </Box>
                      ) : (
                        <></>
                      )}
                      <>
                        <VerticalTimelineElement
                          key={index}
                          id="timeline1"
                          contentStyle={{ fontSize: "sm" }}
                          iconStyle={{
                            background: "#fff",
                            color: "black",
                            fontSize: "16px",
                            fontWeight: "700",
                          }}
                          icon={
                            item?.find((item) => item.key === "sortable_date")
                              ?.value
                          }
                        >
                          <Text color={"color.gray"} >
                            {item?.map((it, subIndex) => {
                              return (
                                <Fragment key={subIndex}>
                                  {it.key === "bibliographic_caption" && (
                                    <span>{`${it.value} `}</span>
                                  )}
                                </Fragment>
                              );
                            })}
                          </Text>
                        </VerticalTimelineElement>
                      </>
                    </>
                  );
                })}
              </VerticalTimeline>
    );
};

export default TimelineWithImage1;
