import api from "../services/api";
import { APIS } from "../endpoints";
import { isTransformationNotAllowed } from "../util/isTranformationAllowed";

/**
 * This function return editions data
 * @returns {Promise<Object>} - Function return Editions data
 */

export const editionsData = async (masterlistType, masterlist, filterOptions, page) => {
  if (isTransformationNotAllowed() === true) {
    const response= await getEditionsDummyData();
    return response;
  }else if (isTransformationNotAllowed() === false)  {
    const response= await getEditionsDataAPI(masterlistType, masterlist, filterOptions, page);
    return response;
  }
};

export const getEditionsDummyData = () => {
  return;
};

export const getEditionsDataAPI = async (masterlistType, masterlist, filterOptions, page) => {
  const formData = new FormData();
  formData.append('filters', JSON.stringify(filterOptions));
  formData.append('page_number', page);
  const params = new URLSearchParams(formData);

  const result = await api.get(`${APIS.BASE_URL}${masterlistType}/${masterlist}/${APIS.editions}`, {
    params: params
  })
  if (result) {
    return result?.data;
  } else {
    return [];
  }
};
