// Service functions
import { LoginSliderData } from "../MockData/login";

export const getLoginSliderDataAPI = async () => {
  return "";
};

export const getLoginSliderData = async () => {
    const response = await getLoginSliderDummyData();
    return response;
  
};

export const getLoginSliderDummyData = async () => {
    return LoginSliderData;
};