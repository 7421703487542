import { Stack } from "@chakra-ui/react";
//Core files
import React, { useEffect, useState } from "react";
import { EffectCreative, Navigation, Pagination } from "swiper";

//Service files
import * as getEssayData from "../../../../services/EventExhibition";

//Component file
import ImageSlider from "../../Component/ImageSlider";
import { FullScreenImage } from "../../Component/FullScreenImage";
import { WebpageComingSoon } from "../../../../CommonComponents/WebpageComingSoon";

export default function Catalogue({ alias, setReadMoreFlag, readmoreNavigation }) {
  const [essayData, setEssayData] = useState([{}]);
  const [fullScreenImage, setFullScreenImage] = useState(false);
  const [clickedImageIndex, setClickedImageIndex] = useState(null);
  const [ImgData, setImgData] = useState(null);
  const currentEndpoint = window.location.pathname;
  const parts = currentEndpoint.split("/");
  const masterlistType=parts[1];
  const aliasAPI = alias;
  const masterlist=aliasAPI;
  
  const showImages = (image, flag) => {
    if (image) {
      setFullScreenImage(flag);
      setImgData(image);
    } else {
      setFullScreenImage(flag);
      setImgData(null);
    }
  };

  const findItem = (items, key) => items.find(it => it.key === key) || {};

  const citationFactor = (data) => {
    return data.map((item) => {
      const fullImage = findItem(item, "direct_url_of_cover_image").value || "";
      const titleItem = findItem(item, "title");
      const author = findItem(item, "creator_name");
      const dateofArt = findItem(item, "sortable_year");
      const title_of_essay = titleItem.value ? titleItem : "";
  
      return {
        desc: findItem(item, "bibliographic_citation").value || "",
        imgPath: fullImage,
        content: findItem(item, "description").value || "",
        title: findItem(item, "bibliographic_caption").value || "",
        fullImg: fullImage,
        essayistData: [title_of_essay, author, dateofArt],
      };
    });
  };
  

  useEffect(() => {
    const getData = async () => {
      const result = await getEssayData.essayData(
        masterlistType,
        masterlist
      );

      if (result)
      {
        setEssayData(citationFactor(result));
      }
    };

    getData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!readmoreNavigation?.isReadMore) {
      setClickedImageIndex(readmoreNavigation?.clickedImageIndex);
    }
  }, [readmoreNavigation]);

  const handleImageClick = (item, index) => {
    setClickedImageIndex(index);
    setFullScreenImage(true);
  };
  
  return (
    <Stack position={"relative"} id="allslideressay" pt={8} width={{ base: "100%",  md: "1076px" }} height={"100vh"}>
    <ImageSlider
        sliderData={essayData}
        handleImageClick={handleImageClick}
        modules={[EffectCreative, Navigation, Pagination]}
        width={{ base: "100%", md: "auto" }}
        height={"100%"}
        sheight={"511px"}
      />
      {fullScreenImage && (
        <FullScreenImage
          isOpen={fullScreenImage}
          onClose={() => showImages(null, false)}
          imageData={ImgData}
          data={essayData}
          clickedImageIndex={clickedImageIndex}
          setClickedImageIndex={setClickedImageIndex}
          setReadMoreFlag={setReadMoreFlag}
          bannerFlag={false}
          title="banner"
        />
      )}
      <WebpageComingSoon />
    </Stack>
  );
}
