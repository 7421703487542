export const CopyPasteService = (e, url) => {
  const selectedText = window.getSelection().toString();
  const newText =
    selectedText?.length > 4 ? selectedText.substring(0, 20) : selectedText;
  const pastedText = `${newText}...\n\nRead more at:\n${url}`;
  if (selectedText.length > 0) {
    e.clipboardData.setData("text", pastedText);
  }
  navigator.clipboard
    .writeText(pastedText)
    .then(() => {
      console.log("Modified text copied to clipboard successfully!");
    })
    .catch((err) => {
      console.error("Error copying modified text to clipboard:", err);
    });
};
