export const SKKBibliography = [
  {
    wnn: "",
    alias: "edited-dutta-a-satyajit-ray-trilogy-of-film-masterpieces-1960",
    accession_number: 2,
    cover_image_code: "2996_A Satyajit Ray Trilogy of Film Masterpieces.jpg",
    cover_image: "2996_A Satyajit Ray Trilogy of Film Masterpieces.jpg",
    image:
      "https://tuliresearchcentre.canto.global/direct/image/jaq94k62bh4398j8oi30sd352q/izl3XoowqZx5CUjp3f55r31jACk/m800/800",
    creator:
      '[{"firstname":"Dulal", "lastname": "Dutta", "alias":"dulal-dutta", "type":"Editor"}]',
    place_of_publication: "New Delhi",
    "Country of Publication": "India",
    publisher: "The Bharatiya Natya Sangh",
    book_title_of_document: "A Satyajit Ray Trilogy of Film Masterpieces",
    book_sub_title_of_document: "Pather Panchali, Aparajita, Apur Sansar",
    research_category_1: "cinema-as-a-key-educational-resource",
    research_category_2: "changing-smile-of-childhood-and-its-second-coming",
    research_category_3: "india-and-her-relationship-with-the-world",
    research_category_4:
      "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities",
    keyword_1: "satyajit-ray",
    keyword_2: "apu-trilogy",
    keyword_3: "pather-panchali",
    other_keywords: "aparajito",
    object_of_focus: "",
    notes: "",
    pdf_scan_of_contents: "",
    pdf_scan_of_index: "",
    document_type: "bke",
    language_of_publication: "English",
    date_of_published_edition_in_library: "",
    dates_of_first_edition: null,
    binding_status: "PB",
    isbn_issn_number: "",
    location_of_publication_in_trcis: "NT's Study",
    sortable_year_date_of_publication: "1980",
    caption_info:
      "Gupta, Chidananda-Das (Author). The Cinema of Satyajit Ray - . New Delhi: Vikas, 1980 | 0000009.bka | CinemaE",
  },
  {
    wnn: "",
    alias: "authored-parrain-regards-sur-le-cinema-indien-1969",
    accession_number: 98,
    cover_image_code: "2928_Regards sur le Cinema Indien_1969.jpg",
    cover_image: "2928_Regards sur le Cinema Indien_1969.jpg",
    image:
      "https://tuliresearchcentre.canto.global/direct/image/hg9q4is0sl2p9fmio6k8sm3h6v/_Glt5lZlFHQB1AAxorKLPj0RIGI/m800/800",
    creator:
      '[{"firstname":"Philippe", "lastname": "Parrain", "alias":"philippe-parrain", "type":"Author"}]',
    sortable_year_date_of_publication: "1980",
    place_of_publication: "",
    "Country of Publication": "France",
    publisher: "Editions du Cerf",
    book_title_of_document: "Regards sur le Cinema Indien",
    book_sub_title_of_document: "",
    research_category_1: "cinema-as-a-key-educational-resource",
    research_category_2: "",
    research_category_3: "",
    research_category_4: "",
    keyword_1: "Satyajit-Ray",
    keyword_2: "",
    keyword_3: "",
    other_keywords: "indian-cinema",
    object_of_focus: "",
    notes: "",
    pdf_scan_of_contents: "",
    pdf_scan_of_index: "",
    document_type: "bka",
    language_of_publication: "French",
    date_of_published_edition_in_library: "1969",
    dates_of_first_edition: 1969,
    binding_status: "PB",
    isbn_issn_number: "",
    location_of_publication_in_trcis: "World Cinema Library",
    caption_info:
      "Rangoonwalla, Firoze (Author). Satyajit Ray's Art. Delhi: Clarion Books, 1980 | 0000055.bka | CinemaE",
  },
  {
    wnn: "",
    alias: "authored-seton-portrait-of-a-director-satyajit-ray-1971",
    accession_number: 64,
    cover_image_code: "2873_Portrait of a Director Satyajit Ray_1971.jpg",
    cover_image: "2873_Portrait of a Director Satyajit Ray_1971.jpg",
    image:
      "https://tuliresearchcentre.canto.global/direct/image/kg8hr0bnv55qffvgejqjauuk6h/mWPHOlkncErhiXVGDghja3jRFi8/m800/800",
    creator:
      '[{"firstname":"Marie", "lastname": "Seton", "alias":"marie-seton", "type":"Author"}]',
    sortable_year_date_of_publication: "1980",
    place_of_publication: "London",
    "Country of Publication": "UK",
    publisher: "Dobson Books",
    book_title_of_document: "Portrait of a Director Satyajit Ray",
    book_sub_title_of_document: "",
    research_category_1: "cinema-as-a-key-educational-resource",
    research_category_2:
      "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities",
    research_category_3: "",
    research_category_4: "",
    keyword_1: "satyajit-ray",
    keyword_2: "marie-seton",
    keyword_3: "",
    other_keywords: "satyajit-ray-as-a-director",
    object_of_focus: "",
    notes: "",
    pdf_scan_of_contents: "",
    pdf_scan_of_index: "",
    document_type: "bka",
    language_of_publication: "English",
    date_of_published_edition_in_library: "1971",
    dates_of_first_edition: 1971,
    binding_status: "HB",
    isbn_issn_number: "0-234772344",
    location_of_publication_in_trcis: "NT's Study",
    caption_info:
      "Barnouw, Erik (Author). Indian Film. New York: Oxford University, 1980 | 0000076.bka | CinemaE",
  },
  {
    wnn: "",
    alias: "authored-wood-the-apu-trilogy-1971",
    accession_number: 82,
    cover_image_code: "2888_The Apu Trilogy _1971.jpg",
    cover_image: "2888_The Apu Trilogy _1971.jpg",
    image:
      "https://tuliresearchcentre.canto.global/direct/image/30bahp6d857p5a0m5gvnmaii04/iFiXQSfk_qXegsoubXN5ciPLdTU/m800/800",
    creator:
      '[{"firstname":"Robin", "lastname": "Wood", "alias":"robin-wood", "type":"Author"}]',
    sortable_year_date_of_publication: "1981",
    place_of_publication: "New York",
    "Country of Publication": "USA",
    publisher: "Praeger",
    book_title_of_document: "The Apu Trilogy ",
    book_sub_title_of_document: "",
    research_category_1: "cinema-as-a-key-educational-resource",
    research_category_2: "changing-smile-of-childhood-and-its-second-coming",
    research_category_3: "",
    research_category_4: "",
    keyword_1: "satyajit-ray",
    keyword_2: "the-apu-trilogy",
    keyword_3: "childhood",
    other_keywords: "appreciation-on-three-films-based-on-the-character-apu",
    object_of_focus: "",
    notes: "",
    pdf_scan_of_contents: "",
    pdf_scan_of_index: "",
    document_type: "bka",
    language_of_publication: "English",
    date_of_published_edition_in_library: "1971",
    dates_of_first_edition: 1971,
    binding_status: "PB",
    isbn_issn_number: "",
    location_of_publication_in_trcis: "NT's Study",
    caption_info:
      "Gupta, Chidananda-Das (Author). Talking About Films - . Bombay: Orient Longman, 1981 | 0000010.bka | CinemaE",
  },
  {
    wnn: "",
    alias: "authored-seton-portrait-of-a-director-satyajit-ray-1972",
    accession_number: 65,
    cover_image_code: "2903_Portrait of A Director Satyajit Ray_1972.jpg",
    cover_image: "2903_Portrait of A Director Satyajit Ray_1972.jpg",
    image:
      "https://tuliresearchcentre.canto.global/direct/image/ief8vdinlp5v3f53imqaoc1d3v/ZfMPdfpQE7efjtWdkxAyQJO0RHQ/m800/800",
    creator:
      '[{"firstname":"Marie", "lastname": "Seton", "alias":"marie-seton", "type":"Author"}]',
    sortable_year_date_of_publication: "1981",
    place_of_publication: "Delhi",
    "Country of Publication": "India",
    publisher: "Vikas",
    book_title_of_document: "Portrait of A Director Satyajit Ray",
    book_sub_title_of_document: "",
    research_category_1: "cinema-as-a-key-educational-resource",
    research_category_2:
      "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities",
    research_category_3: "",
    research_category_4: "",
    keyword_1: "satyajit-ray",
    keyword_2: "marie-seton",
    keyword_3: "",
    other_keywords: "s-j-r-and-his-films",
    object_of_focus: "",
    notes: "",
    pdf_scan_of_contents: "",
    pdf_scan_of_index: "",
    document_type: "bka",
    language_of_publication: "English ",
    date_of_published_edition_in_library: "1972",
    dates_of_first_edition: 1972,
    binding_status: "HB",
    isbn_issn_number: "",
    location_of_publication_in_trcis: "NT's Study",
    caption_info:
      "Gupta, Chidananda-Das (Author). Talking About Films. Calcutta: Orient Longman, 1981 | 0000010.D1.bka | CinemaE",
  },
  {
    wnn: "",
    alias: "authored-bahadur-the-world-of-pather-panchali-1975",
    accession_number: 5,
    cover_image_code: "2922_The World of Pather Panchali.jpg",
    cover_image: "2922_The World of Pather Panchali.jpg",
    image:
      "https://tuliresearchcentre.canto.global/direct/image/hotv8vucvh2cd6uvubv10m7o3c/V6qKXVklU2x_oey0HKcIZtIIUGM/m800/800",
    creator:
      '[{"firstname":"Satish", "lastname": "Bahadur", "alias":"satish-bahadur", "type":"Author"}]',
    sortable_year_date_of_publication: "1981",
    place_of_publication: "Bombay",
    "Country of Publication": "India",
    publisher: "National Film Archive of India",
    book_title_of_document: "The World of Pather Panchali",
    book_sub_title_of_document: "",
    research_category_1: "cinema-as-a-key-educational-resource",
    research_category_2: "changing-smile-of-childhood-and-its-second-coming",
    research_category_3: "india-and-her-relationship-with-the-world",
    research_category_4:
      "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities",
    keyword_1: "satyajit-ray",
    keyword_2: "pather-panchali",
    keyword_3: "satish-bahadur",
    other_keywords: "n-f-a-i",
    object_of_focus: "",
    notes: "",
    pdf_scan_of_contents: "",
    pdf_scan_of_index: "",
    document_type: "bka",
    language_of_publication: "English",
    date_of_published_edition_in_library: "",
    dates_of_first_edition: null,
    binding_status: "PB",
    isbn_issn_number: "",
    location_of_publication_in_trcis: "NT's Study",
    caption_info:
      "Ray, Satyajit (Author). Film India - An Anthology of Statements on Ray and by Ray. New Delhi: Directorate of Film Festivals Ministry of Information and Broadcasting, 1981 | 0000011.bka | CinemaE",
  },
  {
    wnn: "",
    alias: "authored-sarkar-indian-cinema-today-1975",
    accession_number: 80,
    cover_image_code: "0111_Indian Cinema Today_1975.jpg",
    cover_image: "0111_Indian Cinema Today_1975.jpg",
    image:
      "https://tuliresearchcentre.canto.global/direct/image/gmknoimv2l1mtcble6c69pjb0u/0PALmAT9D2HnJegLMCtvSt-S0Wk/m800/800",
    creator:
      '[{"firstname":"Kobita", "lastname": "Sarkar", "alias":"kobita-sarkar", "type":"Author"}]',
    sortable_year_date_of_publication: "1981",
    place_of_publication: "New Delhi",
    "Country of Publication": "India",
    publisher: "Sterling",
    book_title_of_document: "Indian Cinema Today",
    book_sub_title_of_document: "An Analysis",
    research_category_1: "cinema-as-a-key-educational-resource",
    research_category_2:
      "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities",
    research_category_3: "",
    research_category_4: "",
    keyword_1: "indian-cinema",
    keyword_2: "satyajit-ray",
    keyword_3: "",
    other_keywords: "",
    object_of_focus: "",
    notes: "",
    pdf_scan_of_contents: "Project No 251",
    pdf_scan_of_index: "",
    document_type: "bka",
    language_of_publication: "English",
    date_of_published_edition_in_library: "1975",
    dates_of_first_edition: 1975,
    binding_status: "HB",
    isbn_issn_number: "",
    location_of_publication_in_trcis: "NT's Study",
    caption_info:
      "Ray, Satyajit (Author). Aro Baro. Calcutta: Ananda, 1981 | 0000012.bka | CinemaE",
  },
  {
    wnn: "",
    alias: "edited-satyajit-ray-1975",
    accession_number: 90,
    cover_image_code: "2923_Satyajit Ray_.jpg",
    cover_image: "2923_Satyajit Ray_.jpg",
    image:
      "https://tuliresearchcentre.canto.global/direct/image/ep6u6gjqgl0jbcushpe53rjb5s/rMjG7wddpabNgLA5cvRgzoYEzb4/m800/800",
    creator:
      '[{"firstname":"", "lastname": "", "alias":"", "type":"Organizer"}]',
    sortable_year_date_of_publication: "1982",
    place_of_publication: "Bombay",
    "Country of Publication": "India",
    publisher: "National Film Development Corporation",
    book_title_of_document: "Satyajit Ray",
    book_sub_title_of_document: "Twenty Five Films",
    research_category_1: "cinema-as-a-key-educational-resource",
    research_category_2: "",
    research_category_3: "",
    research_category_4: "",
    keyword_1: "Satyajit-Ray",
    keyword_2: "retrospective",
    keyword_3: "",
    other_keywords: "appreciation-on-the-first-twenty-five-films-of-s-j-r",
    object_of_focus: "",
    notes: "",
    pdf_scan_of_contents: "",
    pdf_scan_of_index: "",
    document_type: "bke",
    language_of_publication: "English",
    date_of_published_edition_in_library: "",
    dates_of_first_edition: null,
    binding_status: "PB",
    isbn_issn_number: "",
    location_of_publication_in_trcis: "NT's Study",
    caption_info:
      "Mohini-J, Bazaz( Editor). Festival of India. Delhi Diary, 1982 | 0000014.bke | CinemaE",
  },
  {
    wnn: "",
    alias: "authored-ray-our-films-their-films-1976",
    accession_number: 6,
    cover_image_code: "2869_Our Films Their Films_1976.jpg",
    cover_image: "2869_Our Films Their Films_1976.jpg",
    image:
      "https://tuliresearchcentre.canto.global/direct/image/2ua2o4r7hd6f92i4mffjkf7u42/vnKZy3nGtj78jXbE50suRyp5p-8/m800/800",
    creator:
      '[{"firstname":"Satyajit", "lastname": "Ray", "alias":"satyajit-ray", "type":"Author"}]',
    sortable_year_date_of_publication: "1982",
    place_of_publication: "",
    "Country of Publication": "",
    publisher: "",
    book_title_of_document: "Our Films Their Films",
    book_sub_title_of_document: "",
    research_category_1: "cinema-as-a-key-educational-resource",
    research_category_2:
      "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities",
    research_category_3: "",
    research_category_4: "",
    keyword_1: "satyajit-ray",
    keyword_2: "pather-panchali",
    keyword_3: "india-studies",
    other_keywords: "s-j-r-views-on-indian-and-western-cinema",
    object_of_focus: "",
    notes: "",
    pdf_scan_of_contents: "",
    pdf_scan_of_index: "",
    document_type: "bka",
    language_of_publication: "English",
    date_of_published_edition_in_library: "1976",
    dates_of_first_edition: 1976,
    binding_status: "",
    isbn_issn_number: "81-25015655",
    location_of_publication_in_trcis: "NT's Study",
    caption_info:
      "Ray, Satyajit (Author). Shatranj Ke Khilari - The Chess Players. Calcutta: Ekshan, 1982 | 0000015.bka | CinemaE",
  },
  {
    wnn: "",
    alias: "authored-ray-joi-baba-felunath-1976",
    accession_number: 7,
    cover_image_code: "2876_Joi Baba Felunath.jpg",
    cover_image: "2876_Joi Baba Felunath.jpg",
    image:
      "https://tuliresearchcentre.canto.global/direct/image/p4vc9t10h55a563bs951r4im7c/d1sA7vb3JxUDz43qetmZw1wNq-0/m800/800",
    creator:
      '[{"firstname":"Satyajit", "lastname": "Ray", "alias":"satyajit-ray", "type":"Author"}]',
    sortable_year_date_of_publication: "1982",
    place_of_publication: "Calcutta",
    "Country of Publication": "India",
    publisher: "Ananda",
    book_title_of_document: "Joi Baba Felunath",
    book_sub_title_of_document: "",
    research_category_1: "cinema-as-a-key-educational-resource",
    research_category_2: "changing-smile-of-childhood-and-its-second-coming",
    research_category_3: "",
    research_category_4: "",
    keyword_1: "satyajit-ray",
    keyword_2: "joi-baba-felunath",
    keyword_3: "childhood",
    other_keywords: "",
    object_of_focus: "",
    notes: "",
    pdf_scan_of_contents: "",
    pdf_scan_of_index: "",
    document_type: "bka",
    language_of_publication: "Bengali",
    date_of_published_edition_in_library: "",
    dates_of_first_edition: 1976,
    binding_status: "",
    isbn_issn_number: "",
    location_of_publication_in_trcis: "NT's Study",
    caption_info:
      "Shampa, Banerjee( Editor). New Indian Cinema. New Delhi: National Film Development Corporation, 1982 | 0000089.bke | CinemaE",
  },
  {
    wnn: "",
    alias: "authored-ray-nayak-1976",
    accession_number: 8,
    cover_image_code: "2927_Nayak_1976.jpg",
    cover_image: "2927_Nayak_1976.jpg",
    image:
      "https://tuliresearchcentre.canto.global/direct/image/qikv1c6uh509765199c00bde6k/fldVsObo8xP6Tn5_9V9HJ--vDfU/m800/800",
    creator:
      '[{"firstname":"Satyajit", "lastname": "Ray", "alias":"satyajit-ray", "type":"Author"}]',
    sortable_year_date_of_publication: "1983",
    place_of_publication: "Delhi",
    "Country of Publication": "India",
    publisher: "Rajpal",
    book_title_of_document: "Nayak",
    book_sub_title_of_document: "",
    research_category_1: "cinema-as-a-key-educational-resource",
    research_category_2: "",
    research_category_3: "",
    research_category_4: "",
    keyword_1: "satyajit-ray",
    keyword_2: "nayak",
    keyword_3: "uttam-kumar",
    other_keywords: "appreciation-on-the-film-nayak",
    object_of_focus: "",
    notes: "",
    pdf_scan_of_contents: "",
    pdf_scan_of_index: "",
    document_type: "bka",
    language_of_publication: "Hindi ",
    date_of_published_edition_in_library: "1976",
    dates_of_first_edition: 1976,
    binding_status: "HB",
    isbn_issn_number: "",
    location_of_publication_in_trcis: "NT's Study",
    caption_info:
      "Ray, Satyajit (Author). Phatik Chand. Delhi: Orient Paperback, 1983 | 0000016.bka | CinemaE",
  },
  {
    wnn: "",
    alias: "authored-government-of-india-satyajit-ray-1976",
    accession_number: 91,
    cover_image_code: "2880_Satyajit Ray_1976.jpg",
    cover_image: "2880_Satyajit Ray_1976.jpg",
    image:
      "https://tuliresearchcentre.canto.global/direct/image/cqbefd62792jf46upb1je8g87q/dG9Q2EgurSAsJvMAud4TzWlogKc/m800/800",
    creator:
      '[{"firstname":"Ministry of I & B", "lastname": "Government of India", "alias":"ministry-of-i-b-government-of-india", "type":"Author"}]',
    sortable_year_date_of_publication: "1984",
    place_of_publication: "New Delhi",
    "Country of Publication": "India",
    publisher: "Directorate of Advertising and Visual Publicity",
    book_title_of_document: "Satyajit Ray",
    book_sub_title_of_document: "",
    research_category_1: "cinema-as-a-key-educational-resource",
    research_category_2: "economics-of-art-cinema-culture",
    research_category_3: "",
    research_category_4: "",
    keyword_1: "Satyajit-Ray",
    keyword_2: "ministry-of-i-b",
    keyword_3: "",
    other_keywords: "life-and-works-of-s-j-r",
    object_of_focus: "",
    notes: "",
    pdf_scan_of_contents: "",
    pdf_scan_of_index: "",
    document_type: "bka",
    language_of_publication: "English ",
    date_of_published_edition_in_library: "1976",
    dates_of_first_edition: 1976,
    binding_status: "",
    isbn_issn_number: "",
    location_of_publication_in_trcis: "NT's Study",
    caption_info:
      "Ray, Satyajit (Author). Jab Main Chhota Tha. Delhi: Rajkamal, 1984 | 0000017.bka | CinemaE",
  },
  {
    wnn: "",
    alias: "authored-das-gupta-the-cinema-of-satyajit-ray-1980",
    accession_number: 9,
    cover_image_code: "2863_The Cinema of Satyajit Ray_1980.jpg",
    cover_image: "2863_The Cinema of Satyajit Ray_1980.jpg",
    image:
      "https://tuliresearchcentre.canto.global/direct/image/a5etin56bd74r0cdb0tqpqdk6v/ZcvEdaY5AhDJT-VGv-hKEHgzfNY/m800/800",
    creator:
      '[{"firstname":"Chidananda", "lastname": "Das-Gupta", "alias":"chidananda-das-gupta", "type":"Author"}]',
    sortable_year_date_of_publication: "1984",
    place_of_publication: "New Delhi",
    "Country of Publication": "India",
    publisher: "Vikas",
    book_title_of_document: "The Cinema of Satyajit Ray",
    book_sub_title_of_document: "",
    research_category_1: "cinema-as-a-key-educational-resource",
    research_category_2:
      "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities",
    research_category_3: "",
    research_category_4: "",
    keyword_1: "chidananda-dasgupta",
    keyword_2: "satyajit-ray",
    keyword_3: "india-studies",
    other_keywords: "satyajit-ray-contribution-to-cinema",
    object_of_focus: "",
    notes: "",
    pdf_scan_of_contents: "",
    pdf_scan_of_index: "",
    document_type: "bka",
    language_of_publication: "English",
    date_of_published_edition_in_library: "1980",
    dates_of_first_edition: 1980,
    binding_status: "HB",
    isbn_issn_number: "0-706910354",
    location_of_publication_in_trcis: "NT's Study",
    caption_info:
      "Parimal, Mukhopadhyay( Editor). Pather Panchali - A Film by Satyajit Ray. Calcutta: Cine Central, 1984 | 0000051.bke | CinemaE",
  },
  {
    wnn: "",
    alias: "authored-rangoonwalla-satyajit-ray-s-art-1980",
    accession_number: 55,
    cover_image_code: "2862_Satyajit Ray's Art_1980.jpg",
    cover_image: "2862_Satyajit Ray's Art_1980.jpg",
    image:
      "https://tuliresearchcentre.canto.global/direct/image/87tqucfojh6ld56aq6ur3gle1n/jUIWjMmBsZKQwlgwV0nXIZ606-M/m800/800",
    creator:
      '[{"firstname":"Firoze", "lastname": "Rangoonwalla", "alias":"firoze-rangoonwalla", "type":"Author"}]',
    sortable_year_date_of_publication: "1984",
    place_of_publication: "Delhi",
    "Country of Publication": "India",
    publisher: "Clarion Books",
    book_title_of_document: "Satyajit Ray's Art",
    book_sub_title_of_document: "",
    research_category_1: "cinema-as-a-key-educational-resource",
    research_category_2:
      "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities",
    research_category_3: "",
    research_category_4: "",
    keyword_1: "satyajit-ray",
    keyword_2: "firoze-rangoonwalla",
    keyword_3: "",
    other_keywords: "satyajit-ray-as-an-artist",
    object_of_focus: "",
    notes: "",
    pdf_scan_of_contents: "",
    pdf_scan_of_index: "",
    document_type: "bka",
    language_of_publication: "English",
    date_of_published_edition_in_library: "1980",
    dates_of_first_edition: 1980,
    binding_status: "",
    isbn_issn_number: "086186235X",
    location_of_publication_in_trcis: "NT's Study",
    caption_info:
      "Parimal, Mukhopadhyay( Editor). Pather Panchali - A Film by Satyajit Ray. 1984 | 0000051.DI.bke | CinemaE",
  },
  {
    wnn: "",
    alias: "authored-barnouw-indian-film-1980",
    accession_number: 76,
    cover_image_code: "1156_Indian Film_1980.jpg",
    cover_image: "1156_Indian Film_1980.jpg",
    image:
      "https://tuliresearchcentre.canto.global/direct/image/safealga8p61fb6cnppogdn16f/0el1FcMhki39Lfm1kZumxt_IfEY/m800/800",
    creator:
      '[{"firstname":"Erik", "lastname": "Barnouw", "alias":"erik-barnouw", "type":"Author"}]',
    sortable_year_date_of_publication: "1984",
    place_of_publication: "New York",
    "Country of Publication": "USA",
    publisher: "Oxford University",
    book_title_of_document: "Indian Film",
    book_sub_title_of_document: "",
    research_category_1: "cinema-as-a-key-educational-resource",
    research_category_2:
      "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities",
    research_category_3: "",
    research_category_4: "",
    keyword_1: "indian-cinema",
    keyword_2: "erik-barnouw",
    keyword_3: "satyajit-ray",
    other_keywords: "",
    object_of_focus: "",
    notes: "",
    pdf_scan_of_contents: "Project No 252",
    pdf_scan_of_index: "",
    document_type: "bka",
    language_of_publication: "English",
    date_of_published_edition_in_library: "1980",
    dates_of_first_edition: 1980,
    binding_status: "PB",
    isbn_issn_number: "195026829",
    location_of_publication_in_trcis: "NT's Study",
    caption_info:
      "Parimal, Mukhopadhyay( Editor). Pather Panchali - A Film by Satyajit Ray. Calcutta: Cine Central, 1984 | 0000083.bke | CinemaE",
  },
  {
    wnn: "",
    alias: "authored-das-gupta-talking-about-films-1981",
    accession_number: 10,
    cover_image_code: "0071_Talking About Films_1981.jpg",
    cover_image: "0071_Talking About Films_1981.jpg",
    image:
      "https://tuliresearchcentre.canto.global/direct/image/t1d6pn27b11c530hl9r4vjj90r/HWFegcQQp6QdetpSgR38zrINh6Q/m800/800",
    creator:
      '[{"firstname":"Chidananda", "lastname": "Das-Gupta", "alias":"chidananda-das-gupta", "type":"Author"}]',
    sortable_year_date_of_publication: "1985",
    place_of_publication: "Bombay",
    "Country of Publication": "India",
    publisher: "Orient Longman",
    book_title_of_document: "Talking About Films",
    book_sub_title_of_document: "",
    research_category_1: "cinema-as-a-key-educational-resource",
    research_category_2:
      "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities",
    research_category_3: "",
    research_category_4: "",
    keyword_1: "chidananda-dasgupta",
    keyword_2: "satyajit-ray",
    keyword_3: "p-c-barua",
    other_keywords: "bengali-cinema | russian-cinema | film-society",
    object_of_focus: "",
    notes: "",
    pdf_scan_of_contents: "Project No 251",
    pdf_scan_of_index: "",
    document_type: "bka",
    language_of_publication: "English",
    date_of_published_edition_in_library: "1981",
    dates_of_first_edition: 1981,
    binding_status: "HB",
    isbn_issn_number: "",
    location_of_publication_in_trcis: "NT's Study",
    caption_info:
      "Ray, Satyajit (Author). The Apu Trilogy - Pather Panchali, Aparajito, Apur Sansar. Calcutta: Seagull Books, 1985 | 0000018.bka | CinemaE",
  },
  {
    wnn: "",
    alias: "authored-ray-film-india-1981",
    accession_number: 11,
    cover_image_code: "2856_Film India_1981.jpg",
    cover_image: "2856_Film India_1981.jpg",
    image:
      "https://tuliresearchcentre.canto.global/direct/image/250kvopos911f3s6bnt2ane719/yeUiADMsnMRpq0AFrb-ktYzFXyk/m800/800",
    creator:
      '[{"firstname":"Satyajit", "lastname": "Ray", "alias":"satyajit-ray", "type":"Author"}]',
    sortable_year_date_of_publication: "1985",
    place_of_publication: "New Delhi",
    "Country of Publication": "India",
    publisher:
      "Directorate of Film Festivals, Ministry of Information and Broadcasting",
    book_title_of_document: "Film India",
    book_sub_title_of_document: "An Anthology of Statements on Ray and by Ray",
    research_category_1: "cinema-as-a-key-educational-resource",
    research_category_2: "",
    research_category_3: "",
    research_category_4: "",
    keyword_1: "satyajit-ray",
    keyword_2: "ministry-of-i-b",
    keyword_3: "",
    other_keywords: "statements-on-and-by-satyajit-ray",
    object_of_focus: "",
    notes:
      "Film India : an exhibition organized by The Asia Society, The Museum of Modern Art in New York, and the Directorate of Film Festivals- New Delhi",
    pdf_scan_of_contents: "",
    pdf_scan_of_index: "",
    document_type: "bka",
    language_of_publication: "English",
    date_of_published_edition_in_library: "1981",
    dates_of_first_edition: 1981,
    binding_status: "PB",
    isbn_issn_number: "",
    location_of_publication_in_trcis: "NT's Study",
    caption_info:
      "Ray, Satyajit (Author). Pikoor Diary O Onyanyo. Calcutta: Ananda, 1985 | 0000019.bka | CinemaE",
  },
  {
    wnn: "",
    alias: "authored-ray-aro-baro-1981",
    accession_number: 12,
    cover_image_code: "2931_Aro Baro.jpg",
    cover_image: "2931_Aro Baro.jpg",
    image:
      "https://tuliresearchcentre.canto.global/direct/image/7dglfkb47t329dn0ndsf0uj73h/ISmECyG2TcOTVekaWowtr6BsgfQ/m800/800",
    creator:
      '[{"firstname":"Satyajit", "lastname": "Ray", "alias":"satyajit-ray", "type":"Author"}]',
    sortable_year_date_of_publication: "1985",
    place_of_publication: "Calcutta",
    "Country of Publication": "India",
    publisher: "Ananda",
    book_title_of_document: "Aro Baro",
    book_sub_title_of_document: "",
    research_category_1: "cinema-as-a-key-educational-resource",
    research_category_2: "",
    research_category_3: "",
    research_category_4: "",
    keyword_1: "satyajit-ray",
    keyword_2: "",
    keyword_3: "",
    other_keywords: "",
    object_of_focus: "",
    notes: "",
    pdf_scan_of_contents: "",
    pdf_scan_of_index: "",
    document_type: "bka",
    language_of_publication: "Bengali",
    date_of_published_edition_in_library: "",
    dates_of_first_edition: 1981,
    binding_status: "",
    isbn_issn_number: "",
    location_of_publication_in_trcis: "NT's Study",
    caption_info:
      "Ray, Satyajit (Author). The Apu Trilogy. Calcutta: Seagull Books, 1985 | 0000020.bka | CinemaE",
  },
  {
    wnn: "",
    alias: "authored-bahadur-satyajit-ray-s-pather-panchali-1981",
    accession_number: 50,
    cover_image_code: "2897_Satyajit Ray's Pather Panchali_1981.jpg",
    cover_image: "2897_Satyajit Ray's Pather Panchali_1981.jpg",
    image:
      "https://tuliresearchcentre.canto.global/direct/image/isf99hp46d3kreolqlvlaaca0p/aBrRcLDENyYUeqj-TvoyI8aHwlQ/m800/800",
    creator:
      '[{"firstname":"Satish", "lastname": "Bahadur", "alias":"satish-bahadur", "type":"Author"}]',
    sortable_year_date_of_publication: "1985",
    place_of_publication: "New Delhi",
    "Country of Publication": "India",
    publisher: "National Film Archive of India",
    book_title_of_document: "Satyajit Ray's Pather Panchali",
    book_sub_title_of_document: "Screenplay with Analytical Notes",
    research_category_1: "cinema-as-a-key-educational-resource",
    research_category_2:
      "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities",
    research_category_3: "",
    research_category_4: "",
    keyword_1: "pather-panchali",
    keyword_2: "Satyajit-Ray",
    keyword_3: "satish-bahadur",
    other_keywords: "pather-panchali-critical-appreciation",
    object_of_focus: "",
    notes: "",
    pdf_scan_of_contents: "",
    pdf_scan_of_index: "",
    document_type: "bka",
    language_of_publication: "English",
    date_of_published_edition_in_library: "1981",
    dates_of_first_edition: 1981,
    binding_status: "PB",
    isbn_issn_number: "",
    location_of_publication_in_trcis: "NT's Study",
    caption_info:
      "T-M, Ramachandran( Editor). 70 Years of Indian Cinema -1913-1983. Bombay: Cinema India International, 1985 | 0000075.bke | CinemaE",
  },
  {
    wnn: "",
    alias: "authored-micciollo-satyajit-ray-1981",
    accession_number: 85,
    cover_image_code: "2871_Satyajit Ray_1981.jpg",
    cover_image: "2871_Satyajit Ray_1981.jpg",
    image:
      "https://tuliresearchcentre.canto.global/direct/image/2fdnva763t7qn7d62pqcaeh05e/mHhNIzMrunW0qXD9GT2kgewSRCM/m800/800",
    creator:
      '[{"firstname":"Henri", "lastname": "Micciollo", "alias":"henri-micciollo", "type":"Author"}]',
    sortable_year_date_of_publication: "1986",
    place_of_publication: "Lausanne",
    "Country of Publication": "France",
    publisher: "L'Age D'Homme",
    book_title_of_document: "Satyajit Ray",
    book_sub_title_of_document: "",
    research_category_1: "cinema-as-a-key-educational-resource",
    research_category_2: "",
    research_category_3: "",
    research_category_4: "",
    keyword_1: "satyajit-ray",
    keyword_2: "",
    keyword_3: "",
    other_keywords: "satyajit-ray-biographical-sketches",
    object_of_focus: "",
    notes: "",
    pdf_scan_of_contents: "",
    pdf_scan_of_index: "",
    document_type: "bka",
    language_of_publication: "French",
    date_of_published_edition_in_library: "1981",
    dates_of_first_edition: 1981,
    binding_status: "",
    isbn_issn_number: "",
    location_of_publication_in_trcis: "NT's Study",
    caption_info:
      "Banerjee, Tarasankar (Author). Jalsaghar. Kolkata: Indranath Majumdar, 1986 | 0000021.bka | CinemaE",
  },
  {
    wnn: "",
    alias: "edited-bazaz-festival-of-india-1982",
    accession_number: 14,
    cover_image_code: "2949_Festival of India_1982.jpg",
    cover_image: "2949_Festival of India_1982.jpg",
    image:
      "https://tuliresearchcentre.canto.global/direct/image/g30lbekt8p007a3dk7fjc8dt3l/txdcf0vElyzK-S90XZgbc9lrICM/m800/800",
    creator:
      '[{"firstname":"Mohini-J", "lastname": "Bazaz", "alias":"mohini-j-bazaz", "type":"Editor"}]',
    sortable_year_date_of_publication: "1986",
    place_of_publication: "",
    "Country of Publication": "UK",
    publisher: "Delhi Diary",
    book_title_of_document: "Festival of India",
    book_sub_title_of_document: "",
    research_category_1: "cinema-as-a-key-educational-resource",
    research_category_2: "",
    research_category_3: "",
    research_category_4: "",
    keyword_1: "Satyajit-Ray",
    keyword_2: "festival-of-india",
    keyword_3: "f-n-Souza",
    other_keywords:
      "contemporary-art | aupam-sud | j-swaminathan | photography-india | indian-films",
    object_of_focus: "",
    notes: "",
    pdf_scan_of_contents: "",
    pdf_scan_of_index: "",
    document_type: "bke",
    language_of_publication: "English",
    date_of_published_edition_in_library: "1982",
    dates_of_first_edition: 1982,
    binding_status: "PB",
    isbn_issn_number: "",
    location_of_publication_in_trcis: "NT's Study",
    caption_info:
      "Ray, Satyajit (Author). Bravo! Professor Shonku. New Delhi: Rupa Paperback, 1986 | 0000022.bka | CinemaE",
  },
  {
    wnn: "",
    alias: "authored-ray-shatranj-ke-khilari-1982",
    accession_number: 15,
    cover_image_code: "2906_Shatranj Ke Khilari_.jpg",
    cover_image: "2906_Shatranj Ke Khilari_.jpg",
    image:
      "https://tuliresearchcentre.canto.global/direct/image/9ntctdgqsp3hrd3gn7mpalbs0i/wuYtgcnOUjTpRKui-DI8E1x9Hu8/m800/800",
    creator:
      '[{"firstname":"Satyajit", "lastname": "Ray", "alias":"satyajit-ray", "type":"Author"}]',
    sortable_year_date_of_publication: "1988",
    place_of_publication: "Calcutta",
    "Country of Publication": "India",
    publisher: "Ekshan",
    book_title_of_document: "Shatranj Ke Khilari",
    book_sub_title_of_document: "The Chess Players",
    research_category_1: "cinema-as-a-key-educational-resource",
    research_category_2: "",
    research_category_3: "",
    research_category_4: "",
    keyword_1: "satyajit-ray",
    keyword_2: "shatranj-ke-khilari",
    keyword_3: "screenplay",
    other_keywords: "appreciation-on-the-urdu-film-shatranj-ke-khilari ",
    object_of_focus: "",
    notes:
      "First Draft of the Screenplay by Satyajit Ray A Supplimentory copy to EKSHAN, Vol. xiii : No.3-4",
    pdf_scan_of_contents: "",
    pdf_scan_of_index: "",
    document_type: "bka",
    language_of_publication: "English",
    date_of_published_edition_in_library: "",
    dates_of_first_edition: null,
    binding_status: "",
    isbn_issn_number: "",
    location_of_publication_in_trcis: "NT's Study",
    caption_info:
      "Datta, Alakananda (Author). Benegal on Ray - Satyajit Ray: A Film by Shyam Benegal. Calcutta: Seagull Books, 1988 | 0000003.bka | CinemaE",
  },
  {
    wnn: "",
    alias: "edited-banerjee-new-indian-cinema-1982",
    accession_number: 89,
    cover_image_code: "0173_New Indian Cinema_1982.jpg",
    cover_image: "0173_New Indian Cinema_1982.jpg",
    image:
      "https://tuliresearchcentre.canto.global/direct/image/pbv4hejvdt5hd3vn13l0jjpe01/6E0rFz82Bte2vqz_u2c-Dn4rkhE/m800/800",
    creator:
      '[{"firstname":"Shampa", "lastname": "Banerjee", "alias":"shampa-banerjee", "type":"Editor"}]',
    sortable_year_date_of_publication: "1988",
    place_of_publication: "New Delhi",
    "Country of Publication": "India",
    publisher: "National Film Development Corporation",
    book_title_of_document: "New Indian Cinema",
    book_sub_title_of_document: "",
    research_category_1: "cinema-as-a-key-educational-resource",
    research_category_2:
      "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities",
    research_category_3: "",
    research_category_4: "",
    keyword_1: "indian-cinema",
    keyword_2: "new-wave",
    keyword_3: "",
    other_keywords: "",
    object_of_focus: "",
    notes: "",
    pdf_scan_of_contents: "Project No 251",
    pdf_scan_of_index: "",
    document_type: "bke",
    language_of_publication: "English",
    date_of_published_edition_in_library: "1982",
    dates_of_first_edition: 1982,
    binding_status: "PB",
    isbn_issn_number: "",
    location_of_publication_in_trcis: "NT's Study",
    caption_info:
      "Datta, Alakananda (Author). Benegal on Ray - Satyajit Ray: A Film by Shyam Benegal. Calcutta: Seagull Books, 1988 | 0000003.D1.bka | CinemaE",
  },
  {
    wnn: "",
    alias: "authored-ray-phatik-chand-1983",
    accession_number: 16,
    cover_image_code: "2919_Phatik Chand_1983.jpg",
    cover_image: "2919_Phatik Chand_1983.jpg",
    image:
      "https://tuliresearchcentre.canto.global/direct/image/dqu18l0e3t3e3e5kepdv5eff1s/6ZI1oavu_22gONBCmDwtjT92GDo/m800/800",
    creator:
      '[{"firstname":"Satyajit", "lastname": "Ray", "alias":"satyajit-ray", "type":"Author"}]',
    sortable_year_date_of_publication: "1988",
    place_of_publication: "Delhi",
    "Country of Publication": "India",
    publisher: "Orient Paperback",
    book_title_of_document: "Phatik Chand",
    book_sub_title_of_document: "",
    research_category_1: "cinema-as-a-key-educational-resource",
    research_category_2: "",
    research_category_3: "",
    research_category_4: "",
    keyword_1: "satyajit-ray",
    keyword_2: "",
    keyword_3: "",
    other_keywords: "",
    object_of_focus: "",
    notes: "",
    pdf_scan_of_contents: "",
    pdf_scan_of_index: "",
    document_type: "bka",
    language_of_publication: "English",
    date_of_published_edition_in_library: "1983",
    dates_of_first_edition: 1983,
    binding_status: "PB",
    isbn_issn_number: "",
    location_of_publication_in_trcis: "NT's Study",
    caption_info:
      "Nyce, Ben (Author). Satyajit Ray - A Study of His Films. New York: Praeger, 1988 | 0000057.bka | CinemaE",
  },
  {
    wnn: "",
    alias: "authored-ray-jab-main-chhota-tha-1984",
    accession_number: 17,
    cover_image_code: "2878_Jab Main Chhota Tha_1984.jpg",
    cover_image: "2878_Jab Main Chhota Tha_1984.jpg",
    image:
      "https://tuliresearchcentre.canto.global/direct/image/0ru589u8u538n1ddgq7u0qk639/HRsXrxtud5x6bkp65OopMOmpryg/m800/800",
    creator:
      '[{"firstname":"Satyajit", "lastname": "Ray", "alias":"satyajit-ray", "type":"Author"}]',
    sortable_year_date_of_publication: "1988",
    place_of_publication: "Delhi",
    "Country of Publication": "India",
    publisher: "Rajkamal",
    book_title_of_document: "Jab Main Chhota Tha",
    book_sub_title_of_document: "",
    research_category_1: "cinema-as-a-key-educational-resource",
    research_category_2: "changing-smile-of-childhood-and-its-second-coming",
    research_category_3: "",
    research_category_4: "",
    keyword_1: "satyajit-ray",
    keyword_2: "childhood",
    keyword_3: "",
    other_keywords: "s-j-r-autobiographical-sketches",
    object_of_focus: "",
    notes: "",
    pdf_scan_of_contents: "",
    pdf_scan_of_index: "",
    document_type: "bka",
    language_of_publication: "Hindi ",
    date_of_published_edition_in_library: "1984",
    dates_of_first_edition: 1984,
    binding_status: "HB",
    isbn_issn_number: "",
    location_of_publication_in_trcis: "NT's Study",
    caption_info:
      "Datta, Alakananda (Author). Benegal on Ray - Satyajit Ray: A Film by Shyam Benegal. Calcutta: Seagull Books, 1988 | 0000003.D1.bka | CinemaE",
  },
  {
    wnn: "",
    alias: "edited-mukhopadhyay-pather-panchali-1984",
    accession_number: 51,
    cover_image_code: "2885_Pather Panchali_1984.jpg",
    cover_image: "2885_Pather Panchali_1984.jpg",
    image:
      "https://tuliresearchcentre.canto.global/direct/image/li844dmijl77p3f6phbhic7o6d/mu5_CrFFHK1whDpKYF2Sz5847Zk/m800/800",
    creator:
      '[{"firstname":"Parimal", "lastname": "Mukhopadhyay", "alias":"parimal-mukhopadhyay", "type":"Editor"}]',
    sortable_year_date_of_publication: "1988",
    place_of_publication: "Calcutta",
    "Country of Publication": "India",
    publisher: "Cine Central",
    book_title_of_document: "Pather Panchali",
    book_sub_title_of_document: "A Film by Satyajit Ray",
    research_category_1: "cinema-as-a-key-educational-resource",
    research_category_2: "changing-smile-of-childhood-and-its-second-coming",
    research_category_3: "",
    research_category_4: "",
    keyword_1: "Satyajit-Ray",
    keyword_2: "pather-panchali",
    keyword_3: "childhood",
    other_keywords: "pather-panchali-critical-appreciation",
    object_of_focus: "",
    notes: "",
    pdf_scan_of_contents: "",
    pdf_scan_of_index: "",
    document_type: "bke",
    language_of_publication: "English",
    date_of_published_edition_in_library: "1984",
    dates_of_first_edition: 1984,
    binding_status: "HB",
    isbn_issn_number: "",
    location_of_publication_in_trcis: "NT's Study",
    caption_info:
      "Nyce, Ben (Author). Satyajit Ray - A Study of His Films. New York: Praeger, 1988 | 0000057.bka | CinemaE",
  },
  {
    wnn: "",
    alias: "edited-mukhopadhyay-pather-panchali-1984-1",
    accession_number: 83,
    cover_image_code: "2884_Pather Panchali_1984.jpg",
    cover_image: "2884_Pather Panchali_1984.jpg",
    image:
      "https://tuliresearchcentre.canto.global/direct/image/l6sv5s4hh972368f50t74rk52v/avCuMUDBd-cJ4ZgFbax2FtiHMAc/m800/800",
    creator:
      '[{"firstname":"Parimal", "lastname": "Mukhopadhyay", "alias":"parimal-mukhopadhyay", "type":"Editor"}]',
    sortable_year_date_of_publication: "1988",
    place_of_publication: "Calcutta",
    "Country of Publication": "India",
    publisher: "Cine Central",
    book_title_of_document: "Pather Panchali",
    book_sub_title_of_document: "A Film by Satyajit Ray",
    research_category_1: "cinema-as-a-key-educational-resource",
    research_category_2: "changing-smile-of-childhood-and-its-second-coming",
    research_category_3: "",
    research_category_4: "",
    keyword_1: "satyajit-ray",
    keyword_2: "pather-panchali",
    keyword_3: "childhood",
    other_keywords: "pather-panchali-critical-appreciation",
    object_of_focus: "",
    notes: "",
    pdf_scan_of_contents: "",
    pdf_scan_of_index: "",
    document_type: "bke",
    language_of_publication: "English",
    date_of_published_edition_in_library: "1984",
    dates_of_first_edition: 1984,
    binding_status: "PB",
    isbn_issn_number: "",
    location_of_publication_in_trcis: "NT's Study",
    caption_info:
      "Valicha, Kishore (Dr & Author). The Moving Image - A Study of Indian Cinema. Bombay: Orient Longman, 1988 | 0000095.bka | CinemaE",
  },
  {
    wnn: "",
    alias: "authored-ray-the-apu-trilogy-1985",
    accession_number: 18,
    cover_image_code: "2887_The Apu Trilogy_1985.jpg",
    cover_image: "2887_The Apu Trilogy_1985.jpg",
    image:
      "https://tuliresearchcentre.canto.global/direct/image/3oug5tpeh179t9bl99kgl9sr7j/pI6vAq8TT-GQWEqMrb5GeJx_OlM/m800/800",
    creator:
      '[{"firstname":"Satyajit", "lastname": "Ray", "alias":"satyajit-ray", "type":"Author"}]',
    sortable_year_date_of_publication: "1988",
    place_of_publication: "Calcutta",
    "Country of Publication": "India",
    publisher: "Seagull Books",
    book_title_of_document: "The Apu Trilogy",
    book_sub_title_of_document: "Pather Panchali, Aparajito, Apur Sansar",
    research_category_1: "cinema-as-a-key-educational-resource",
    research_category_2: "changing-smile-of-childhood-and-its-second-coming",
    research_category_3: "",
    research_category_4: "",
    keyword_1: "satyajit-ray",
    keyword_2: "the-apu-trilogy",
    keyword_3: "childhood",
    other_keywords: "appreciation-on-three-films-based-on-the-character-apu",
    object_of_focus: "",
    notes: "",
    pdf_scan_of_contents: "",
    pdf_scan_of_index: "",
    document_type: "bka",
    language_of_publication: "English",
    date_of_published_edition_in_library: "1985",
    dates_of_first_edition: 1985,
    binding_status: "PB",
    isbn_issn_number: "",
    location_of_publication_in_trcis: "NT's Study",
    caption_info:
      "Valicha, Kishore (Dr & Author). The Moving Image - A Study of Indian Cinema. Bombay: Orient Longman, 1988 | 0000095.D1.bka | CinemaE",
  },
  {
    wnn: "",
    alias: "authored-ray-pikoor-diary-o-onyanyo-1985",
    accession_number: 19,
    cover_image_code: "2898_Pikoor Diary O Onyanyo.jpg",
    cover_image: "2898_Pikoor Diary O Onyanyo.jpg",
    image:
      "https://tuliresearchcentre.canto.global/direct/image/gb7p4hdvt910de33ii253os746/Cec8VtXnECuzoxnEAejOHIjgSvc/m800/800",
    creator:
      '[{"firstname":"Satyajit", "lastname": "Ray", "alias":"satyajit-ray", "type":"Author"}]',
    sortable_year_date_of_publication: "1989",
    place_of_publication: "Calcutta",
    "Country of Publication": "India",
    publisher: "Ananda",
    book_title_of_document: "Pikoor Diary O Onyanyo",
    book_sub_title_of_document: "",
    research_category_1: "cinema-as-a-key-educational-resource",
    research_category_2: "",
    research_category_3: "",
    research_category_4: "",
    keyword_1: "satyajit-ray",
    keyword_2: "",
    keyword_3: "",
    other_keywords: "",
    object_of_focus: "",
    notes: "",
    pdf_scan_of_contents: "",
    pdf_scan_of_index: "",
    document_type: "bka",
    language_of_publication: "Bengali",
    date_of_published_edition_in_library: "",
    dates_of_first_edition: 1985,
    binding_status: "",
    isbn_issn_number: "",
    location_of_publication_in_trcis: "NT's Study",
    caption_info:
      "Robinson, Andrew (Author). Satyajit Ray - The Inner Eye. London: Andrew Deutsch, 1989 | 0000004.bka | CinemaE",
  },
  {
    wnn: "",
    alias: "authored-ray-the-apu-trilogy-1985-1",
    accession_number: 20,
    cover_image_code: "2918_The Apu Trilogy_1985.jpg",
    cover_image: "2918_The Apu Trilogy_1985.jpg",
    image:
      "https://tuliresearchcentre.canto.global/direct/image/jpr7kf78ep3k7cfvr55hvi820o/wyaTVrxsBfOWawj0rt7X-eSAbJY/m800/800",
    creator:
      '[{"firstname":"Satyajit", "lastname": "Ray", "alias":"satyajit-ray", "type":"Author"}]',
    sortable_year_date_of_publication: "1989",
    place_of_publication: "Calcutta",
    "Country of Publication": "India",
    publisher: "Seagull Books",
    book_title_of_document: "The Apu Trilogy",
    book_sub_title_of_document: "",
    research_category_1: "cinema-as-a-key-educational-resource",
    research_category_2: "changing-smile-of-childhood-and-its-second-coming",
    research_category_3: "",
    research_category_4: "",
    keyword_1: "satyajit-ray",
    keyword_2: "the-apu-trilogy",
    keyword_3: "childhood",
    other_keywords: "appreciation-on-three-films-based-on-the-character-apu",
    object_of_focus: "",
    notes: "",
    pdf_scan_of_contents: "",
    pdf_scan_of_index: "",
    document_type: "bka",
    language_of_publication: "English",
    date_of_published_edition_in_library: "1985",
    dates_of_first_edition: 1985,
    binding_status: "PB",
    isbn_issn_number: "0-856471003",
    location_of_publication_in_trcis: "NT's Study",
    caption_info:
      "Andrew, Robinson( Editor). The Chess Players and Other Screenplays. London: Faber and Faber, 1989 |0000024.bke | CinemaE",
  },
  {
    wnn: "",
    alias: "edited-ramachandran-70-years-of-indian-cinema-1913-1983-1985",
    accession_number: 75,
    cover_image_code: "0040_70 Years of Indian Cinema_1985.jpg",
    cover_image: "0040_70 Years of Indian Cinema_1985.jpg",
    image:
      "https://tuliresearchcentre.canto.global/direct/image/1krdki917d6e56at2vrkto7s00/cU_ZNe_iC8LGpq7PR7CJ5ZhdC9I/m800/800",
    creator:
      '[{"firstname":"T-M", "lastname": "Ramachandran", "alias":"t-m-ramachandran", "type":"Editor"}]',
    sortable_year_date_of_publication: "1989",
    place_of_publication: "Bombay",
    "Country of Publication": "India",
    publisher: "Cinema India International",
    book_title_of_document: "70 Years of Indian Cinema 1913-1983",
    book_sub_title_of_document: "",
    research_category_1: "cinema-as-a-key-educational-resource",
    research_category_2:
      "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities",
    research_category_3: "",
    research_category_4: "",
    keyword_1: "silent-film-era",
    keyword_2: "dada-saheb-phalke",
    keyword_3: "hiralal-sen",
    other_keywords:
      "r-nataraja-mudaliar | ardeshir-m-irani | b-n-sircar | himansu-rai | devika-rani | satyajit-ray | v-shantaram | raj-kapoor | s-s-vasan | mrinal-sen | shyam-benegal | mani-kaul | national-film-archive-of-india | international-film-festival-of-india | children-s-film-society | regional-cinema",
    object_of_focus: "",
    notes: "",
    pdf_scan_of_contents: "Project No 251",
    pdf_scan_of_index: "",
    document_type: "bke",
    language_of_publication: "English",
    date_of_published_edition_in_library: "1985",
    dates_of_first_edition: 1985,
    binding_status: "HB",
    isbn_issn_number: "861320905",
    location_of_publication_in_trcis: "NT's Study",
    caption_info:
      "Robinson, Andrew (Author). Satyajit Ray - The Inner Eye: The Biography of a Master Film-Maker. New Delhi: Oxford University, 1989 | 0000025.bka | CinemaE",
  },
  {
    wnn: "",
    alias: "authored-banerjee-jalsaghar-1986",
    accession_number: 21,
    cover_image_code: "2894_Jalsaghar_1986.jpg",
    cover_image: "2894_Jalsaghar_1986.jpg",
    image:
      "https://tuliresearchcentre.canto.global/direct/image/pajsen347d26b8feglipg80u7i/N-dtAybZtt1LAAIl4bw2gdv57t0/m800/800",
    creator:
      '[{"firstname":"Tarasankar", "lastname": "Banerjee", "alias":"tarasankar-banerjee", "type":"Author"}]',
    sortable_year_date_of_publication: "1990",
    place_of_publication: "Kolkata",
    "Country of Publication": "India",
    publisher: "Indranath Majumdar",
    book_title_of_document: "Jalsaghar",
    book_sub_title_of_document: "",
    research_category_1: "cinema-as-a-key-educational-resource",
    research_category_2: "",
    research_category_3: "",
    research_category_4: "",
    keyword_1: "Satyajit-Ray",
    keyword_2: "jalsaghar",
    keyword_3: "screenplay",
    other_keywords: "bengali-literature | novel",
    object_of_focus: "",
    notes: "",
    pdf_scan_of_contents: "",
    pdf_scan_of_index: "",
    document_type: "bka",
    language_of_publication: "Bengali",
    date_of_published_edition_in_library: "1986",
    dates_of_first_edition: 1937,
    binding_status: "HB",
    isbn_issn_number: "",
    location_of_publication_in_trcis: "NT's Study",
    caption_info:
      "Robinson, Andrew (Author). Satyajit Ray - The Inner Eye. Calcutta: Rupa Paperback, 1990 | 0000027.bka | CinemaE",
  },
  {
    wnn: "",
    alias: "authored-ray-bravo-professor-shonku-1986",
    accession_number: 22,
    cover_image_code: "2929_Bravo! Professor Shonku_1986.jpg",
    cover_image: "2929_Bravo! Professor Shonku_1986.jpg",
    image:
      "https://tuliresearchcentre.canto.global/direct/image/5cv7o61d592vf5002qb98vqf2m/AbDOrARVuPLK1lOXnUI9MK-0g1I/m800/800",
    creator:
      '[{"firstname":"Satyajit", "lastname": "Ray", "alias":"satyajit-ray", "type":"Author"}]',
    sortable_year_date_of_publication: "1991",
    place_of_publication: "New Delhi",
    "Country of Publication": "India",
    publisher: "Rupa Paperback",
    book_title_of_document: "Bravo! Professor Shonku",
    book_sub_title_of_document: "",
    research_category_1: "cinema-as-a-key-educational-resource",
    research_category_2:
      "uncertainy-revealing-itself-death-magic-mystery-sci-fi-horror-gambling-ai",
    research_category_3: "",
    research_category_4: "",
    keyword_1: "satyajit-ray",
    keyword_2: "professor-shonku",
    keyword_3: "",
    other_keywords: "appreciation-on-the-film-professor-shonku",
    object_of_focus: "",
    notes: "",
    pdf_scan_of_contents: "",
    pdf_scan_of_index: "",
    document_type: "bka",
    language_of_publication: "English",
    date_of_published_edition_in_library: "1986",
    dates_of_first_edition: 1974,
    binding_status: "PB",
    isbn_issn_number: "",
    location_of_publication_in_trcis: "NT's Study",
    caption_info:
      "Nandi, Alok-B (Author). Satyajit Ray at 70 - As Writer, Designer, Actor, Director, Cameraman, Editor, Composer. Brussels: Eiffel Editions, 1991 | 0000058.bka | CinemaE",
  },
  {
    wnn: "",
    alias: "authored-datta-benegal-on-ray-1988",
    accession_number: 3,
    cover_image_code: "2892_Benegal On Ray_1988.jpg",
    cover_image: "2892_Benegal On Ray_1988.jpg",
    image:
      "https://tuliresearchcentre.canto.global/direct/image/qhrjp5ieoh7oj6lrf5al03rm5a/ys6mfvXYquMNUugkIJkytA0ubpY/m800/800",
    creator:
      '[{"firstname":"Alakananda", "lastname": "Datta", "alias":"alakananda-datta", "type":"Author"}]',
    sortable_year_date_of_publication: "1991",
    place_of_publication: "Calcutta",
    "Country of Publication": "India",
    publisher: "Seagull Books",
    book_title_of_document: "Benegal On Ray",
    book_sub_title_of_document: "Satyajit Ray : a film by Shyam Benegal",
    research_category_1: "cinema-as-a-key-educational-resource",
    research_category_2: "",
    research_category_3: "",
    research_category_4: "",
    keyword_1: "satyajit-ray",
    keyword_2: "shyam-benegal",
    keyword_3: "",
    other_keywords: "bengal-s-reciprocation-to-s-j-r",
    object_of_focus: "",
    notes: "",
    pdf_scan_of_contents: "",
    pdf_scan_of_index: "",
    document_type: "bka",
    language_of_publication: "English",
    date_of_published_edition_in_library: "1988",
    dates_of_first_edition: 1988,
    binding_status: "PB",
    isbn_issn_number: "8170460212",
    location_of_publication_in_trcis: "World Cinema Library",
    caption_info:
      "Gupta, Chidananda-Das (Author). The Painted Face - Studies in India's Popular Cinema. New Delhi: Roli Books, 1991 | 0000078.bka | CinemaE",
  },
  {
    wnn: "",
    alias: "authored-nyce-satyajit-ray-1988",
    accession_number: 57,
    cover_image_code: "2867_Satyajit Ray_1988.jpg",
    cover_image: "2867_Satyajit Ray_1988.jpg",
    image:
      "https://tuliresearchcentre.canto.global/direct/image/nmn2np2kkp17b4iighdq5tl47a/NCclisCyEuuIhywFvMU97oOPXXs/m800/800",
    creator:
      '[{"firstname":"Ben", "lastname": "Nyce", "alias":"ben-nyce", "type":"Author"}]',
    sortable_year_date_of_publication: "1991",
    place_of_publication: "New York",
    "Country of Publication": "USA",
    publisher: "Praeger",
    book_title_of_document: "Satyajit Ray",
    book_sub_title_of_document: "A Study of His Films",
    research_category_1: "cinema-as-a-key-educational-resource",
    research_category_2:
      "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities",
    research_category_3: "",
    research_category_4: "",
    keyword_1: "satyajit-ray",
    keyword_2: "",
    keyword_3: "",
    other_keywords: "satyajit-ray-and-his-films",
    object_of_focus: "",
    notes: "",
    pdf_scan_of_contents: "",
    pdf_scan_of_index: "",
    document_type: "bka",
    language_of_publication: "English",
    date_of_published_edition_in_library: "1988",
    dates_of_first_edition: 1988,
    binding_status: "HB",
    isbn_issn_number: "0-275926664",
    location_of_publication_in_trcis: "NT's Study",
    caption_info:
      "Gupta, Chidananda-Das (Author). The Painted Face - Studies in India's Popular Cinema. New Delhi: Roli Books, 1991 | 0000078.D1.bka | CinemaE",
  },
  {
    wnn: "",
    alias: "authored-valicha-the-moving-image-1988",
    accession_number: 95,
    cover_image_code: "0069_The Moving Image_1988.jpg",
    cover_image: "0069_The Moving Image_1988.jpg",
    image:
      "https://tuliresearchcentre.canto.global/direct/image/ate182883576b4aola99k9740g/BYcjvNMmvk1WdV1mG0iqYyY8eNw/m800/800",
    creator:
      '[{"firstname":"Kishore", "lastname": "Valicha", "alias":"kishore-valicha", "type":"Dr & Author"}]',
    sortable_year_date_of_publication: "1992",
    place_of_publication: "Bombay",
    "Country of Publication": "India",
    publisher: "Orient Longman",
    book_title_of_document: "The Moving Image",
    book_sub_title_of_document: "A Study of Indian Cinema",
    research_category_1: "cinema-as-a-key-educational-resource",
    research_category_2:
      "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities",
    research_category_3: "",
    research_category_4: "",
    keyword_1: "indian-cinema",
    keyword_2: "satyajit-ray",
    keyword_3: "bombay-cinema",
    other_keywords: "contemporary-analysis-of-indian-cinema",
    object_of_focus: "",
    notes: "",
    pdf_scan_of_contents: "Project No 251",
    pdf_scan_of_index: "",
    document_type: "bka",
    language_of_publication: "English",
    date_of_published_edition_in_library: "1988",
    dates_of_first_edition: 1988,
    binding_status: "HB",
    isbn_issn_number: "861316819",
    location_of_publication_in_trcis: "NT's Study",
    caption_info:
      "Sarkar, Bidyut (Author). The World of Satyajit Ray. New Delhi: UBSPD, 1992 | 0000071.bka | CinemaE",
  },
  {
    wnn: "",
    alias: "authored-robinson-satyajit-ray-1989",
    accession_number: 4,
    cover_image_code: "2912_Satyajit Ray_1989.jpg",
    cover_image: "2912_Satyajit Ray_1989.jpg",
    image:
      "https://tuliresearchcentre.canto.global/direct/image/mmr1aihhat0r75dr67us6gbp4v/Rwe6Ni6vJ5R9gLxqGJmu6Pk7urI/m800/800",
    creator:
      '[{"firstname":"Andrew", "lastname": "Robinson", "alias":"andrew-robinson", "type":"Author"}]',
    sortable_year_date_of_publication: "1992",
    place_of_publication: "London",
    "Country of Publication": "UK",
    publisher: "Andrew Deutsch",
    book_title_of_document: "Satyajit Ray",
    book_sub_title_of_document: "The Inner Eye",
    research_category_1: "cinema-as-a-key-educational-resource",
    research_category_2: "india-and-her-relationship-with-the-world",
    research_category_3: "",
    research_category_4: "",
    keyword_1: "satyajit-ray",
    keyword_2: "andrew-robinson",
    keyword_3: "rabindranath-tagore",
    other_keywords:
      "biographical-sketches-on-the-film-giant-based-on-extensive-interviews ",
    object_of_focus: "",
    notes: "Signature of Satyajit Ray in Bengali and English in frontispiece.",
    pdf_scan_of_contents: "YES",
    pdf_scan_of_index: "YES",
    document_type: "bka",
    language_of_publication: "English",
    date_of_published_edition_in_library: "1989",
    dates_of_first_edition: 1989,
    binding_status: "HB",
    isbn_issn_number: "0-233984739",
    location_of_publication_in_trcis: "NT's Study",
    caption_info:
      "Tesson, Charles (Author). Satyajit Ray. Paris: Cahiers Du Cine, 1992 | 0000086.bka | CinemaE",
  },
  {
    wnn: "",
    alias: "edited-robinson-the-chess-players-and-other-screenplays-1989",
    accession_number: 24,
    cover_image_code: "2905_The Chess Players and other screenplays_1989.jpg",
    cover_image: "2905_The Chess Players and other screenplays_1989.jpg",
    image:
      "https://tuliresearchcentre.canto.global/direct/image/sc3ksj6h1178386ols1492457k/v6aSlzUWLa-ZAlDy81Yh4k1iBUk/m800/800",
    creator:
      '[{"firstname":"Andrew", "lastname": "Robinson", "alias":"andrew-robinson", "type":"Editor"}]',
    sortable_year_date_of_publication: "1992",
    place_of_publication: "London",
    "Country of Publication": "United Kingdom",
    publisher: "Faber & Faber",
    book_title_of_document: "The Chess Players and other screenplays",
    book_sub_title_of_document: "",
    research_category_1: "cinema-as-a-key-educational-resource",
    research_category_2: "",
    research_category_3: "",
    research_category_4: "",
    keyword_1: "satyajit-ray",
    keyword_2: "shatranj-ke-khilari",
    keyword_3: "sadgati",
    other_keywords: "the-alien",
    object_of_focus: "",
    notes: "shatranj-ke-khilari | pp.3-62",
    pdf_scan_of_contents: "",
    pdf_scan_of_index: "n/a",
    document_type: "bke",
    language_of_publication: "English ",
    date_of_published_edition_in_library: "1989",
    dates_of_first_edition: 1989,
    binding_status: "PB",
    isbn_issn_number: "0-571140742",
    location_of_publication_in_trcis: "NT's Study",
    caption_info:
      "Sarkar, Bidyut (Author). The World of Satyajit Ray. New Delhi: UBSPD, 1992 | 0000071.bka | CinemaE",
  },
  {
    wnn: "",
    alias: "authored-robinson-satyajit-ray-1989-1",
    accession_number: 25,
    cover_image_code: "0984_Satyajit Ray_2004.jpg",
    cover_image: "0984_Satyajit Ray_2004.jpg",
    image:
      "https://tuliresearchcentre.canto.global/direct/image/d3ilk4tgv12mt05s86507j8p3b/Hbcy-dAcxFjkFSbHxiXJyWvt09s/m800/800",
    creator:
      '[{"firstname":"Andrew", "lastname": "Robinson", "alias":"andrew-robinson", "type":"Author"}]',
    sortable_year_date_of_publication: "1992",
    place_of_publication: "New Delhi",
    "Country of Publication": "India",
    publisher: "Oxford University",
    book_title_of_document: "Satyajit Ray",
    book_sub_title_of_document:
      "The Inner Eye : The Biography of a Master Film-Maker",
    research_category_1: "cinema-as-a-key-educational-resource",
    research_category_2:
      "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities",
    research_category_3: "",
    research_category_4: "",
    keyword_1: "Satyajit-Ray",
    keyword_2: "andrew-robinson",
    keyword_3: "rabindranath-tagore",
    other_keywords: "",
    object_of_focus: "",
    notes: "",
    pdf_scan_of_contents: "Project No 252",
    pdf_scan_of_index: "",
    document_type: "bka",
    language_of_publication: "English",
    date_of_published_edition_in_library: "2004",
    dates_of_first_edition: 1989,
    binding_status: "HB",
    isbn_issn_number: "019566812x",
    location_of_publication_in_trcis: "NT's Study",
    caption_info:
      "Tesson, Charles (Author). Satyajit Ray. Paris: Cahiers Du Cine, 1992 | 0000086.bka | CinemaE",
  },
  {
    wnn: "",
    alias: "authored-robinson-satyajit-ray-1990",
    accession_number: 27,
    cover_image_code: "2915_Satyajit Ray_.jpg",
    cover_image: "2915_Satyajit Ray_.jpg",
    image:
      "https://tuliresearchcentre.canto.global/direct/image/2t22bqd4rp0kd7396d6hl5v113/hqcYOOT5KAiAQ9Lr6qV3lWgORz8/m800/800",
    creator:
      '[{"firstname":"Andrew", "lastname": "Robinson", "alias":"andrew-robinson", "type":"Author"}]',
    sortable_year_date_of_publication: "1993",
    place_of_publication: "Calcutta",
    "Country of Publication": "India",
    publisher: "Rupa Paperback",
    book_title_of_document: "Satyajit Ray",
    book_sub_title_of_document: "The Inner Eye",
    research_category_1: "cinema-as-a-key-educational-resource",
    research_category_2:
      "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities",
    research_category_3: "",
    research_category_4: "",
    keyword_1: "satyajit-ray",
    keyword_2: "andrew-robinson",
    keyword_3: "rabindranath-tagore",
    other_keywords:
      "biographical-sketches-on-the-film-giant-based-on-extensive-interviews ",
    object_of_focus: "",
    notes: "",
    pdf_scan_of_contents: "Project No 252",
    pdf_scan_of_index: "YES",
    document_type: "bka",
    language_of_publication: "English",
    date_of_published_edition_in_library: "",
    dates_of_first_edition: null,
    binding_status: "PB",
    isbn_issn_number: "",
    location_of_publication_in_trcis: "NT's Study",
    caption_info:
      "Chakravarty, Sumita-S (Author). National Identity in Indian Popular Cinema - 1947-1987. New Delhi: Oxford University, 1993 | 0000068.bka | CinemaE",
  },
  {
    wnn: "",
    alias: "authored-nandi-satyajit-ray-at-70-1991",
    accession_number: 58,
    cover_image_code: "2881_Satyajit Ray at 70_1991.jpg",
    cover_image: "2881_Satyajit Ray at 70_1991.jpg",
    image:
      "https://tuliresearchcentre.canto.global/direct/image/9dnslph5pp33t38fnsr4o91r2q/WU0_4_uX2F_TwWMEzNfq2c7sKeE/m800/800",
    creator:
      '[{"firstname":"Alok-B", "lastname": "Nandi", "alias":"alok-b-nandi", "type":"Author"}]',
    sortable_year_date_of_publication: "1994",
    place_of_publication: "Brussels",
    "Country of Publication": "Belgium",
    publisher: "Eiffel Editions",
    book_title_of_document: "Satyajit Ray at 70",
    book_sub_title_of_document:
      "As Writer, Designer, Actor, Director, Cameraman, Editor, Composer",
    research_category_1: "cinema-as-a-key-educational-resource",
    research_category_2: "popular-arts-printed-image-crafts-of-india",
    research_category_3: "",
    research_category_4: "",
    keyword_1: "satyajit-ray",
    keyword_2: "",
    keyword_3: "",
    other_keywords: "s-j-r-and-his-achievements",
    object_of_focus: "",
    notes: "",
    pdf_scan_of_contents: "",
    pdf_scan_of_index: "",
    document_type: "bka",
    language_of_publication: "English ",
    date_of_published_edition_in_library: "1991",
    dates_of_first_edition: 1991,
    binding_status: "PB",
    isbn_issn_number: "29300100061",
    location_of_publication_in_trcis: "NT's Study",
    caption_info:
      "Ray, Satyajit (Author). My Years With Apu - A Memoir. New Delhi: Penguin Books India, 1994 | 0000028.bka | CinemaE",
  },
  {
    wnn: "",
    alias: "authored-das-gupta-the-painted-face-1991",
    accession_number: 78,
    cover_image_code: "0110_The Painted Face_1991.jpg",
    cover_image: "0110_The Painted Face_1991.jpg",
    image:
      "https://tuliresearchcentre.canto.global/direct/image/uiektppa3555pfe2h0l842un1m/eVyPuv00q2jg7yV6Xfnm83hxLyA/m800/800",
    creator:
      '[{"firstname":"Chandan", "lastname": "Das-Gupta", "alias":"chandan-das-gupta", "type":"Author"}]',
    sortable_year_date_of_publication: "1995",
    place_of_publication: "New Delhi",
    "Country of Publication": "India",
    publisher: "Roli Books",
    book_title_of_document: "The Painted Face",
    book_sub_title_of_document: "Studies in India's Popular Cinema",
    research_category_1: "cinema-as-a-key-educational-resource",
    research_category_2:
      "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities",
    research_category_3: "",
    research_category_4: "",
    keyword_1: "chidananda-dasgupta",
    keyword_2: "satyajit-ray",
    keyword_3: "",
    other_keywords: "",
    object_of_focus: "",
    notes: "",
    pdf_scan_of_contents: "Project No 251",
    pdf_scan_of_index: "",
    document_type: "bka",
    language_of_publication: "English",
    date_of_published_edition_in_library: "1991",
    dates_of_first_edition: 1991,
    binding_status: "HB",
    isbn_issn_number: "",
    location_of_publication_in_trcis: "NT's Study",
    caption_info:
      "Aruna, Vasudev( Editor). Frames of Mind - Reflections on Indian Cinema. New Delhi: UBS, 1995 | 0000093.bke | CinemaE",
  },
  {
    wnn: "",
    alias: "authored-sarkar-the-world-of-satyajit-ray-1992",
    accession_number: 71,
    cover_image_code: "2909_The World of Satyajit Ray_1992.jpg",
    cover_image: "2909_The World of Satyajit Ray_1992.jpg",
    image:
      "https://tuliresearchcentre.canto.global/direct/image/agkvgtd3j95k936ef3tnfhjb1j/czNxALR360OzrpqCeSPpydr1zDo/m800/800",
    creator:
      '[{"firstname":"Bidyut", "lastname": "Sarkar", "alias":"bidyut-sarkar", "type":"Author"}]',
    sortable_year_date_of_publication: "1996",
    place_of_publication: "New Delhi",
    "Country of Publication": "India",
    publisher: "UBSPD",
    book_title_of_document: "The World of Satyajit Ray",
    book_sub_title_of_document: "",
    research_category_1: "cinema-as-a-key-educational-resource",
    research_category_2:
      "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities",
    research_category_3: "",
    research_category_4: "",
    keyword_1: "satyajit-ray",
    keyword_2: "",
    keyword_3: "",
    other_keywords:
      "satyajit-ray | bengali-film | indian-film | mainstream-cinema",
    object_of_focus: "",
    notes: "",
    pdf_scan_of_contents: "",
    pdf_scan_of_index: "",
    document_type: "bka",
    language_of_publication: "English ",
    date_of_published_edition_in_library: "1992",
    dates_of_first_edition: 1992,
    binding_status: "HB",
    isbn_issn_number: "81-85674043",
    location_of_publication_in_trcis: "World Cinema Library",
    caption_info:
      "Chowdhury, Upendra-Kishor-Ray (Author). Goopy Gyne Bagha Byne. Calcutta: Ananda, 1996 | 0000026.bka | CinemaE",
  },
  {
    wnn: "",
    alias: "authored-tesson-satyajit-ray-1992",
    accession_number: 86,
    cover_image_code: "2858_Satyajit Ray_1992.jpg",
    cover_image: "2858_Satyajit Ray_1992.jpg",
    image:
      "https://tuliresearchcentre.canto.global/direct/image/jrdnpfuaj138v8rhidkg593449/rzd7GgACikU_nW7o1wFXCR5iklE/m800/800",
    creator:
      '[{"firstname":"Charles", "lastname": "Tesson", "alias":"charles-tesson", "type":"Author"}]',
    sortable_year_date_of_publication: "1998",
    place_of_publication: "Paris",
    "Country of Publication": "France",
    publisher: "Cahiers Du Cine",
    book_title_of_document: "Satyajit Ray",
    book_sub_title_of_document: "",
    research_category_1: "cinema-as-a-key-educational-resource",
    research_category_2: "",
    research_category_3: "",
    research_category_4: "",
    keyword_1: "satyajit-ray",
    keyword_2: "",
    keyword_3: "",
    other_keywords: "satyajit-ray-biographical-sketches",
    object_of_focus: "",
    notes: "",
    pdf_scan_of_contents: "",
    pdf_scan_of_index: "",
    document_type: "bka",
    language_of_publication: "French",
    date_of_published_edition_in_library: "1992",
    dates_of_first_edition: 1992,
    binding_status: "",
    isbn_issn_number: "2866421175",
    location_of_publication_in_trcis: "NT's Study",
    caption_info:
      "Santi, Das( Editor). Satyajit Ray - An Intimate Master. New Delhi: Allied, 1998 | 0000030.bke | CinemaE",
  },
  {
    wnn: "",
    alias:
      "authored-chakravarty-national-identity-in-indian-popular-cinema-1993",
    accession_number: 68,
    cover_image_code:
      "0225_National Identity in Indian Popular Cinema_1993.jpg",
    cover_image: "0225_National Identity in Indian Popular Cinema_1993.jpg",
    image:
      "https://tuliresearchcentre.canto.global/direct/image/mg8bjc8mb16j184l2c7hmcuv41/aO3Pm2CAoyTCAwFwQtoCcmFlzn8/m800/800",
    creator:
      '[{"firstname":"Sumita-S", "lastname": "Chakravarty", "alias":"sumita-s-chakravarty", "type":"Author"}]',
    sortable_year_date_of_publication: "1998",
    place_of_publication: "New Delhi",
    "Country of Publication": "India",
    publisher: "Oxford University",
    book_title_of_document: "National Identity in Indian Popular Cinema",
    book_sub_title_of_document: "1947-1987",
    research_category_1: "cinema-as-a-key-educational-resource",
    research_category_2:
      "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities",
    research_category_3: "",
    research_category_4: "",
    keyword_1: "indian-cinema",
    keyword_2: "national-identity",
    keyword_3: "",
    other_keywords: "",
    object_of_focus: "",
    notes: "",
    pdf_scan_of_contents: "Project No 250",
    pdf_scan_of_index: "",
    document_type: "bka",
    language_of_publication: "English",
    date_of_published_edition_in_library: "1993",
    dates_of_first_edition: 1993,
    binding_status: "PB",
    isbn_issn_number: "195647475",
    location_of_publication_in_trcis: "",
    caption_info:
      "Elena, Alberto (Author). Satyajit Ray. Madrid: Cátedra , 1998 | 0000087.bka | CinemaE",
  },
  {
    wnn: "",
    alias: "authored-ray-my-years-with-apu-1994",
    accession_number: 28,
    cover_image_code: "2868_My Years With Apu_1994.jpg",
    cover_image: "2868_My Years With Apu_1994.jpg",
    image:
      "https://tuliresearchcentre.canto.global/direct/image/ck1brc7e1h1e75d0emtcdp0875/uOgaVOAKmaPNzQccZcM7fSexyBc/m800/800",
    creator:
      '[{"firstname":"Satyajit", "lastname": "Ray", "alias":"satyajit-ray", "type":"Author"}]',
    sortable_year_date_of_publication: "1998",
    place_of_publication: "New Delhi",
    "Country of Publication": "India",
    publisher: "Penguine Books India",
    book_title_of_document: "My Years With Apu",
    book_sub_title_of_document: "A Memoir",
    research_category_1: "cinema-as-a-key-educational-resource",
    research_category_2: "changing-smile-of-childhood-and-its-second-coming",
    research_category_3: "",
    research_category_4: "",
    keyword_1: "satyajit-ray",
    keyword_2: "the-apu-trilogy",
    keyword_3: "pather-panchali",
    other_keywords: "autobiographical-sketches",
    object_of_focus: "",
    notes: "",
    pdf_scan_of_contents: "",
    pdf_scan_of_index: "",
    document_type: "bka",
    language_of_publication: "English",
    date_of_published_edition_in_library: "1994",
    dates_of_first_edition: 1994,
    binding_status: "HB",
    isbn_issn_number: "0-670862150",
    location_of_publication_in_trcis: "NT's Study",
    caption_info:
      "Prasad, M-Madhava (Author). Ideology of the Hindi Film - A Historical Construction. New Delhi: Oxford University, 1998 | 0000096.bka | CinemaE",
  },
  {
    wnn: "",
    alias: "edited-vasudev-frames-of-mind-1995",
    accession_number: 93,
    cover_image_code: "0037_Frames of Mind_1995.jpg",
    cover_image: "0037_Frames of Mind_1995.jpg",
    image:
      "https://tuliresearchcentre.canto.global/direct/image/5qhfeo9uop13j2mjhdf6t2q50o/0OrDPOB9sWBZIekXIFKbvJs4kZE/m800/800",
    creator:
      '[{"firstname":"Aruna", "lastname": "Vasudev", "alias":"aruna-vasudev", "type":"Editor"}]',
    sortable_year_date_of_publication: "1999",
    place_of_publication: "New Delhi",
    "Country of Publication": "India",
    publisher: "UBS",
    book_title_of_document: "Frames of Mind",
    book_sub_title_of_document: "Reflections on Indian Cinema",
    research_category_1: "cinema-as-a-key-educational-resource",
    research_category_2: "india-and-her-relationship-with-the-world",
    research_category_3: "",
    research_category_4: "",
    keyword_1: "silent-film-era",
    keyword_2: "indian-film-director",
    keyword_3: "pluralism",
    other_keywords:
      "hindi-commercial-cinema | sant-tukaram | south-indian-cinema | indian-panorama",
    object_of_focus: "",
    notes: "",
    pdf_scan_of_contents: "Project No 251",
    pdf_scan_of_index: "",
    document_type: "bke",
    language_of_publication: "English",
    date_of_published_edition_in_library: "1995",
    dates_of_first_edition: 1995,
    binding_status: "HB",
    isbn_issn_number: "8174760539",
    location_of_publication_in_trcis: "NT's Study",
    caption_info:
      "Elena, Alberto (Author). Los Cines Perifericos - Africa, Oriente Medio, India. Barcelona: Paidos, 1999 | 0000097.bka | CinemaE",
  },
  {
    wnn: "",
    alias: "authored-ray-chowdhury-goopy-gyne-bagha-byne-1996",
    accession_number: 26,
    cover_image_code: "2874_Goopy Gyne Bagha Byne_1996.jpg",
    cover_image: "2874_Goopy Gyne Bagha Byne_1996.jpg",
    image:
      "https://tuliresearchcentre.canto.global/direct/image/meg4vb5u6p4c969gd7ir2ll679/ApS4WJyeez-CGyCHuKeWn5uZlCM/m800/800",
    creator:
      '[{"firstname":"Upendra-Kishor", "lastname": "Ray-Chowdhury", "alias":"upendra-kishor-ray-chowdhury", "type":"Author"}]',
    sortable_year_date_of_publication: "2000",
    place_of_publication: "Calcutta",
    "Country of Publication": "India",
    publisher: "Ananda",
    book_title_of_document: "Goopy Gyne Bagha Byne",
    book_sub_title_of_document: "",
    research_category_1: "cinema-as-a-key-educational-resource",
    research_category_2: "changing-smile-of-childhood-and-its-second-coming",
    research_category_3: "",
    research_category_4: "",
    keyword_1: "Satyajit-Ray",
    keyword_2: "goopy-gyne-bagha-byne",
    keyword_3: "childhood",
    other_keywords: "",
    object_of_focus: "",
    notes: "",
    pdf_scan_of_contents: "",
    pdf_scan_of_index: "",
    document_type: "bka",
    language_of_publication: "Bengali",
    date_of_published_edition_in_library: "1996",
    dates_of_first_edition: 1996,
    binding_status: "",
    isbn_issn_number: "",
    location_of_publication_in_trcis: "NT's Study",
    caption_info:
      "Ghosh, Nemai (Author). Manikda - Memoire sur Satyajit Ray. Kolkata: Bingsha Shatabdi, 2000 | 0000034.bka | CinemaE",
  },
  {
    wnn: "",
    alias: "edited-das-satyajit-ray-1998",
    accession_number: 30,
    cover_image_code: "2879_Satyajit Ray_1998.jpg",
    cover_image: "2879_Satyajit Ray_1998.jpg",
    image:
      "https://tuliresearchcentre.canto.global/direct/image/hvno05ago95hrc89t2scm8ra7q/gbrmVVXXY6tbphQw5R8XVY02jjs/m800/800",
    creator:
      '[{"firstname":"Santi", "lastname": "Das", "alias":"santi-das", "type":"Editor"}]',
    sortable_year_date_of_publication: "2000",
    place_of_publication: "New Delhi",
    "Country of Publication": "India",
    publisher: "Allied",
    book_title_of_document: "Satyajit Ray",
    book_sub_title_of_document: "An Intimate Master",
    research_category_1: "cinema-as-a-key-educational-resource",
    research_category_2: "popular-arts-printed-image-crafts-of-india",
    research_category_3: "",
    research_category_4: "",
    keyword_1: "Satyajit-Ray",
    keyword_2: "graphic-design",
    keyword_3: "pather-panchali",
    other_keywords: "s-j-r-biographical-sketches",
    object_of_focus: "",
    notes: "",
    pdf_scan_of_contents: "",
    pdf_scan_of_index: "",
    document_type: "bke",
    language_of_publication: "English ",
    date_of_published_edition_in_library: "1998",
    dates_of_first_edition: 1998,
    binding_status: "HB",
    isbn_issn_number: "8170237483",
    location_of_publication_in_trcis: "NT's Study",
    caption_info:
      "Cooper, Darius (Author). The Cinema of Satyajit Ray - Between Tradition and Modernity. Cambridge: Cambridge University, 2000 | 0000062.bka | CinemaE",
  },
  {
    wnn: "",
    alias: "authored-elena-satyajit-ray-1998",
    accession_number: 87,
    cover_image_code: "2857_Satyajit Ray_1998.jpg",
    cover_image: "2857_Satyajit Ray_1998.jpg",
    image:
      "https://tuliresearchcentre.canto.global/direct/image/84mqhqsmjp1jd337h6jnlsfa3u/z0KbsOwv8PsHt7d6-F9gQk7qzls/m800/800",
    creator:
      '[{"firstname":"Alberto", "lastname": "Elena", "alias":"alberto-elena", "type":"Author"}]',
    sortable_year_date_of_publication: "2000",
    place_of_publication: "Madrid",
    "Country of Publication": "Spain",
    publisher: "Cátedra  ",
    book_title_of_document: "Satyajit Ray",
    book_sub_title_of_document: "",
    research_category_1: "cinema-as-a-key-educational-resource",
    research_category_2: "",
    research_category_3: "",
    research_category_4: "",
    keyword_1: "satyajit-ray",
    keyword_2: "",
    keyword_3: "",
    other_keywords: "satyajit-ray-biographical-sketches",
    object_of_focus: "",
    notes: "",
    pdf_scan_of_contents: "",
    pdf_scan_of_index: "",
    document_type: "bka",
    language_of_publication: "Spanish ",
    date_of_published_edition_in_library: "1998",
    dates_of_first_edition: 1998,
    binding_status: "",
    isbn_issn_number: "978-8437616476",
    location_of_publication_in_trcis: "NT's Study",
    caption_info:
      "Thoraval, Yves (Author). The Cinemas of India:1896-2000. New Delhi: Macmillan India, 2000 | 0000094.bka | CinemaE",
  },
  {
    wnn: "",
    alias: "authored-prasad-ideology-of-the-hindi-film-1998",
    accession_number: 96,
    cover_image_code: "0042_Ideology of the Hindi Film_2000.jpg",
    cover_image: "0042_Ideology of the Hindi Film_2000.jpg",
    image:
      "https://tuliresearchcentre.canto.global/direct/image/b6mdgmpg552170tl13ohk5ml1v/qZjguVcI8Ox1Vy2c5rNeSn4ocq4/m800/800",
    creator:
      '[{"firstname":"M-Madhava", "lastname": "Prasad", "alias":"m-madhava-prasad", "type":"Author"}]',
    sortable_year_date_of_publication: "2001",
    place_of_publication: "New Delhi",
    "Country of Publication": "India",
    publisher: "Oxford University",
    book_title_of_document: "Ideology of the Hindi Film",
    book_sub_title_of_document: "A Historical Construction",
    research_category_1: "cinema-as-a-key-educational-resource",
    research_category_2:
      "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities",
    research_category_3: "",
    research_category_4: "",
    keyword_1: "hindi-film",
    keyword_2: "satyajit-ray",
    keyword_3: "amitabh-bachchan",
    other_keywords: "popular-hindi-film | aesthetic | cinema-poster",
    object_of_focus: "",
    notes: "",
    pdf_scan_of_contents: "Project No 251",
    pdf_scan_of_index: "",
    document_type: "bka",
    language_of_publication: "English",
    date_of_published_edition_in_library: "2000",
    dates_of_first_edition: 1998,
    binding_status: "PB",
    isbn_issn_number: "195652959",
    location_of_publication_in_trcis: "NT's Study",
    caption_info:
      "Gupta, Chidananda-Das (Author). The Cinema of Satyajit Ray. New Delhi: National Book Trust, 2001 | 0000009.DI.bka | CinemaE",
  },
  {
    wnn: "",
    alias: "authored-elena-los-cines-perifericos-1999",
    accession_number: 97,
    cover_image_code: "2925_Los Cines Perifericos_1999.jpg",
    cover_image: "2925_Los Cines Perifericos_1999.jpg",
    image:
      "https://tuliresearchcentre.canto.global/direct/image/ecup88f81951hfb6sbbqns1040/moPs-UTn_maGD7TzBF8kN-sbFUw/m800/800",
    creator:
      '[{"firstname":"Alberto", "lastname": "Elena", "alias":"alberto-elena", "type":"Author"}]',
    sortable_year_date_of_publication: "2001",
    place_of_publication: "Barcelona",
    "Country of Publication": "Spain",
    publisher: "Paidos",
    book_title_of_document: "Los Cines Perifericos",
    book_sub_title_of_document: "Africa, Oriente Medio, India",
    research_category_1: "cinema-as-a-key-educational-resource",
    research_category_2: "",
    research_category_3: "",
    research_category_4: "",
    keyword_1: "Satyajit-Ray",
    keyword_2: "",
    keyword_3: "",
    other_keywords: "the-peripheral-cinemas",
    object_of_focus: "",
    notes: "",
    pdf_scan_of_contents: "",
    pdf_scan_of_index: "",
    document_type: "bka",
    language_of_publication: "Spanish ",
    date_of_published_edition_in_library: "1999",
    dates_of_first_edition: 1999,
    binding_status: "PB",
    isbn_issn_number: "84-49307104",
    location_of_publication_in_trcis: "World Cinema Library",
    caption_info:
      "Kurchi, Dasgupta( Publisher). Ray - A Glimpse. Kolkata: Kurchi Dasgupta, 2001 | 0000029.bke | CinemaE",
  },
  {
    wnn: "",
    alias: "authored-ghosh-manikda-2000",
    accession_number: 34,
    cover_image_code: "2865_Manikda_2000.jpg",
    cover_image: "2865_Manikda_2000.jpg",
    image:
      "https://tuliresearchcentre.canto.global/direct/image/gk79bl2n154ff5ngmvk62uqp7n/9ibPJZu-OXKZ7p3xOov345ToQaM/m800/800",
    creator:
      '[{"firstname":"Nemai", "lastname": "Ghosh", "alias":"nemai-ghosh", "type":"Author"}]',
    sortable_year_date_of_publication: "2001",
    place_of_publication: "Kolkata",
    "Country of Publication": "India",
    publisher: "Bingsha Shatabdi",
    book_title_of_document: "Manikda",
    book_sub_title_of_document: "Memoire sur Satyajit Ray",
    research_category_1: "cinema-as-a-key-educational-resource",
    research_category_2: "",
    research_category_3: "",
    research_category_4: "",
    keyword_1: "satyajit-ray",
    keyword_2: "manikda",
    keyword_3: "",
    other_keywords: "satyajit-ray-life-and-works",
    object_of_focus: "",
    notes: "",
    pdf_scan_of_contents: "",
    pdf_scan_of_index: "",
    document_type: "bka",
    language_of_publication: "French",
    date_of_published_edition_in_library: "2000",
    dates_of_first_edition: 2000,
    binding_status: "PB",
    isbn_issn_number: "81877570010",
    location_of_publication_in_trcis: "World Cinema Library",
    caption_info:
      "Vallachira, Aravindan (Author). Asian Cinema - A New Perspective. Thrissur: Dhrisyasahithi, 2001 | 0000031.bka | CinemaE",
  },
  {
    wnn: "",
    alias: "authored-cooper-the-cinema-of-satyajit-ray-2000",
    accession_number: 62,
    cover_image_code: "2916_The Cinema of Satyajit Ray_2000.jpg",
    cover_image: "2916_The Cinema of Satyajit Ray_2000.jpg",
    image:
      "https://tuliresearchcentre.canto.global/direct/image/6g871mhtid68vfb6tdjri9vc6o/omjs26yZrqF7oHOBQVxe_idNspc/m800/800",
    creator:
      '[{"firstname":"Darius", "lastname": "Cooper", "alias":"darius-cooper", "type":"Author"}]',
    sortable_year_date_of_publication: "2001",
    place_of_publication: "Cambridge",
    "Country of Publication": "UK",
    publisher: "Cambridge University",
    book_title_of_document: "The Cinema of Satyajit Ray",
    book_sub_title_of_document: "Between Tradition and Modernity ",
    research_category_1: "cinema-as-a-key-educational-resource",
    research_category_2:
      "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities",
    research_category_3: "",
    research_category_4: "",
    keyword_1: "satyajit-ray",
    keyword_2: "",
    keyword_3: "",
    other_keywords: "",
    object_of_focus: "",
    notes: "",
    pdf_scan_of_contents: "",
    pdf_scan_of_index: "",
    document_type: "bka",
    language_of_publication: "English",
    date_of_published_edition_in_library: "2000",
    dates_of_first_edition: 2000,
    binding_status: "PB",
    isbn_issn_number: "0-521629802",
    location_of_publication_in_trcis: "World Cinema Library",
    caption_info:
      "Ganguly, Suranjan (Author). Satyajit Ray - In Search of the Modern. New Delhi: Indialog, 2001 | 0000042.bka | CinemaE",
  },
  {
    wnn: "",
    alias: "authored-thoraval-the-cinemas-of-india-1896-2000-2000",
    accession_number: 94,
    cover_image_code: "0038_The Cinemas of India_2000.jpg",
    cover_image: "0038_The Cinemas of India_2000.jpg",
    image:
      "https://tuliresearchcentre.canto.global/direct/image/h3t7tpae1d16329tpg0opuke1l/h8y8OlZTDl1UjPhqvVoVVAKip_Q/m800/800",
    creator:
      '[{"firstname":"Yves", "lastname": "Thoraval", "alias":"yves-thoraval", "type":"Author"}]',
    sortable_year_date_of_publication: "2001",
    place_of_publication: "New Delhi",
    "Country of Publication": "India",
    publisher: "Macmillan India",
    book_title_of_document: "The Cinemas of India 1896-2000",
    book_sub_title_of_document: "",
    research_category_1: "cinema-as-a-key-educational-resource",
    research_category_2: "india-and-her-relationship-with-the-world",
    research_category_3: "",
    research_category_4: "",
    keyword_1: "silent-film-era",
    keyword_2: "dada-saheb-phalke",
    keyword_3: "satyajit-ray",
    other_keywords:
      "ardeshir-irani | alam-ara | baburao-painter | v-shantaram | hindi-cinema-study",
    object_of_focus: "",
    notes: "",
    pdf_scan_of_contents: "Project No 251",
    pdf_scan_of_index: "",
    document_type: "bka",
    language_of_publication: "English",
    date_of_published_edition_in_library: "2000",
    dates_of_first_edition: 2000,
    binding_status: "HB",
    isbn_issn_number: "333934105",
    location_of_publication_in_trcis: "NT's Study",
    caption_info:
      "Indrani, Majumdar( Compiler). RayTrospective 2001 - A Celebration. New Delhi: Indrani Majumdar, 2002 | 0000033.bke | CinemaE",
  },
  {
    wnn: "",
    alias: "edited-dasgupta-ray-a-glimpse-2001",
    accession_number: 29,
    cover_image_code: "2875_Ray_2001.jpg",
    cover_image: "2875_Ray_2001.jpg",
    image:
      "https://tuliresearchcentre.canto.global/direct/image/52164hlcjp4cv1qulg8up9502v/F_tR1W0oo1fMjqrhNN2F5qb18g8/m800/800",
    creator:
      '[{"firstname":"Kurchi", "lastname": "Dasgupta", "alias":"kurchi-dasgupta", "type":"Publisher"}]',
    sortable_year_date_of_publication: "2002",
    place_of_publication: "Kolkata",
    "Country of Publication": "India",
    publisher: "Kurchi Dasgupta",
    book_title_of_document: "Ray A Glimpse",
    book_sub_title_of_document: "",
    research_category_1: "cinema-as-a-key-educational-resource",
    research_category_2: "",
    research_category_3: "",
    research_category_4: "",
    keyword_1: "satyajit-ray",
    keyword_2: "the-alien",
    keyword_3: "peter-sellers",
    other_keywords: "hirak-sen",
    object_of_focus: "",
    notes: "",
    pdf_scan_of_contents: "n/a",
    pdf_scan_of_index: "YES",
    document_type: "bke",
    language_of_publication: "English-French",
    date_of_published_edition_in_library: "2001",
    dates_of_first_edition: 2001,
    binding_status: "HB",
    isbn_issn_number: "",
    location_of_publication_in_trcis: "NT's Study",
    caption_info:
      "Vasudev, Aruna (Author). Being & Becoming - The Cinemas of Asia. New Delhi: Macmillan India, 2002 | 0000081.bka | CinemaE",
  },
  {
    wnn: "",
    alias: "authored-vallachira-asian-cinema-2001",
    accession_number: 31,
    cover_image_code: "2926_Asian Cinema_2001.jpg",
    cover_image: "2926_Asian Cinema_2001.jpg",
    image:
      "https://tuliresearchcentre.canto.global/direct/image/lp40tc6c3951h6hh7rbopbft06/VfZ75t0bvomPx0reeaJ0XlyZQRE/m800/800",
    creator:
      '[{"firstname":"Aravindan", "lastname": "Vallachira", "alias":"aravindan-vallachira", "type":"Author"}]',
    sortable_year_date_of_publication: "2003",
    place_of_publication: "Thrissur",
    "Country of Publication": "India",
    publisher: "Dhrisyasahithi",
    book_title_of_document: "Asian Cinema",
    book_sub_title_of_document: "A New Perspective",
    research_category_1: "cinema-as-a-key-educational-resource",
    research_category_2: "",
    research_category_3: "",
    research_category_4: "",
    keyword_1: "Satyajit-Ray",
    keyword_2: "asian-cinema",
    keyword_3: "",
    other_keywords: "new-perspectives-on-asian-cinema",
    object_of_focus: "",
    notes: "",
    pdf_scan_of_contents: "",
    pdf_scan_of_index: "",
    document_type: "bka",
    language_of_publication: "Malayalam",
    date_of_published_edition_in_library: "2001",
    dates_of_first_edition: 2001,
    binding_status: "PB",
    isbn_issn_number: "",
    location_of_publication_in_trcis: "NT's Study",
    caption_info:
      "Ghosh, Nemai (Photographer). Satyajit Ray - From Script to Screen . New Delhi: National Gallery of Modern Art, 2003 | 0000084.bka | CinemaE",
  },
  {
    wnn: "",
    alias: "authored-ganguly-satyajit-ray-2001",
    accession_number: 42,
    cover_image_code: "2913_Satyajit Ray_2001.jpg",
    cover_image: "2913_Satyajit Ray_2001.jpg",
    image:
      "https://tuliresearchcentre.canto.global/direct/image/4aupoppujt13rf8tu3jl8k8f04/AwUN7ByPGSam2uBjpi675mGDcyg/m800/800",
    creator:
      '[{"firstname":"Suranjan", "lastname": "Ganguly", "alias":"suranjan-ganguly", "type":"Author"}]',
    sortable_year_date_of_publication: "2005",
    place_of_publication: "New Delhi",
    "Country of Publication": "India",
    publisher: "Indialog",
    book_title_of_document: "Satyajit Ray",
    book_sub_title_of_document: "In Search of the Modern",
    research_category_1: "cinema-as-a-key-educational-resource",
    research_category_2:
      "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities",
    research_category_3: "",
    research_category_4: "",
    keyword_1: "Satyajit-Ray",
    keyword_2: "indian-modernity",
    keyword_3: "",
    other_keywords: "modernity-in-s-j-r-films",
    object_of_focus: "",
    notes: "",
    pdf_scan_of_contents: "",
    pdf_scan_of_index: "",
    document_type: "bka",
    language_of_publication: "English",
    date_of_published_edition_in_library: "2001",
    dates_of_first_edition: 2001,
    binding_status: "PB",
    isbn_issn_number: "81-87981040",
    location_of_publication_in_trcis: "NT's Study",
    caption_info:
      "Andrew, Robinson( Editor). Satyajit Ray - A Vision of Cinema. London: I B Tauris, 2005 | 0000036.bke | CinemaE",
  },
  {
    wnn: "",
    alias: "edited-majumdar-raytrospective-2001-2002",
    accession_number: 33,
    cover_image_code: "2907_RayTrospective 2001_37438.jpg",
    cover_image: "2907_RayTrospective 2001_37438.jpg",
    image:
      "https://tuliresearchcentre.canto.global/direct/image/dms2lu81v14fbccc6sovsp3l38/Is2eVy5oB_P1YpDBYkoYlEZiGw8/m800/800",
    creator:
      '[{"firstname":"Indrani", "lastname": "Majumdar", "alias":"indrani-majumdar", "type":"Compiler"}]',
    sortable_year_date_of_publication: "2007",
    place_of_publication: "New Delhi",
    "Country of Publication": "India",
    publisher: "Indrani Majumdar",
    book_title_of_document: "RayTrospective 2001",
    book_sub_title_of_document: "A Celebration",
    research_category_1: "cinema-as-a-key-educational-resource",
    research_category_2: "",
    research_category_3: "",
    research_category_4: "",
    keyword_1: "Satyajit-Ray",
    keyword_2: "retrospective",
    keyword_3: "",
    other_keywords: "",
    object_of_focus: "",
    notes: "",
    pdf_scan_of_contents: "",
    pdf_scan_of_index: "",
    document_type: "bke",
    language_of_publication: "English-Bengali-Hindi",
    date_of_published_edition_in_library: "07/02",
    dates_of_first_edition: 2002,
    binding_status: "PB",
    isbn_issn_number: "",
    location_of_publication_in_trcis: "NT's Study",
    caption_info:
      "Roberge, Gaston (Author). Satyajit Ray. New Delhi: Manohar, 2007 | 0000038.bka | CinemaE",
  },
  {
    wnn: "",
    alias: "authored-vasudev-being-becoming-2002",
    accession_number: 81,
    cover_image_code: "0012_Being & Becoming_2002.jpg",
    cover_image: "0012_Being & Becoming_2002.jpg",
    image:
      "https://tuliresearchcentre.canto.global/direct/image/iejinh906537dcn50l6albv66b/AFDo4TaF3mF73NIcdoAiqq4x4PA/m800/800",
    creator:
      '[{"firstname":"Aruna", "lastname": "Vasudev", "alias":"aruna-vasudev", "type":"Author"}]',
    sortable_year_date_of_publication: "2011",
    place_of_publication: "New Delhi",
    "Country of Publication": "India",
    publisher: "Macmillan India",
    book_title_of_document: "Being & Becoming",
    book_sub_title_of_document: "The Cinemas of Asia",
    research_category_1: "cinema-as-a-key-educational-resource",
    research_category_2: "india-and-her-relationship-with-the-world",
    research_category_3: "",
    research_category_4: "",
    keyword_1: "aruna-vasudev",
    keyword_2: "asian-cinema",
    keyword_3: "japanese-cinema",
    other_keywords:
      "azerbaizani-cinema | kyrgyzstani-cinema | kazakhstani-cinema | chinese-cinema | hongkong-cinema | indian-cinema | indonesian-cinema | iranian-cinema | israeli-cinema |  japanese-cinema | korean-cinema | malaysian-cinema | pakistani-cinema | philippino-cinema | singapore-cinema | srilankan-cinema | taiwanese-cinema | thai-cinema | turkis-cinema | vietnamese-cinema | iraqi-cinema | lebanese-cinema | syrian-cinema",
    object_of_focus: "",
    notes: "",
    pdf_scan_of_contents: "Project No 251",
    pdf_scan_of_index: "",
    document_type: "bka",
    language_of_publication: "English",
    date_of_published_edition_in_library: "2002",
    dates_of_first_edition: 2002,
    binding_status: "HB",
    isbn_issn_number: "333938208",
    location_of_publication_in_trcis: "NT's Study",
    caption_info:
      "Bahadur, Satish (Author). A Textual Study of the Apu Trilogy. New Delhi: Vani, 2011 | 0000070.bka | CinemaE",
  },
  {
    wnn: "",
    alias: "authored-ghosh-satyajit-ray-2003",
    accession_number: 84,
    cover_image_code: "2882_Satyajit Ray_2003.jpg",
    cover_image: "2882_Satyajit Ray_2003.jpg",
    image:
      "https://tuliresearchcentre.canto.global/direct/image/37m5q6ruip1bfb3it7jv6sgl30/F3hJedI2_BC93q5GLMzFT3GO_UU/m800/800",
    creator:
      '[{"firstname":"Nemai", "lastname": "Ghosh", "alias":"nemai-ghosh", "type":"Photographer"}]',
    sortable_year_date_of_publication: "2012",
    place_of_publication: "New Delhi",
    "Country of Publication": "India",
    publisher: "National Gallery of Modern Art",
    book_title_of_document: "Satyajit Ray",
    book_sub_title_of_document: "From Script to Screen ",
    research_category_1: "cinema-as-a-key-educational-resource",
    research_category_2: "photography-in-india",
    research_category_3: "",
    research_category_4: "",
    keyword_1: "satyajit-ray",
    keyword_2: "nemai-ghosh",
    keyword_3: "",
    other_keywords: "s-j-r-contributions-to-indian-cinema",
    object_of_focus: "",
    notes: "",
    pdf_scan_of_contents: "",
    pdf_scan_of_index: "",
    document_type: "bka",
    language_of_publication: "English ",
    date_of_published_edition_in_library: "2003",
    dates_of_first_edition: 2003,
    binding_status: "",
    isbn_issn_number: "",
    location_of_publication_in_trcis: "NT's Study",
    caption_info:
      "Sen, Jayanti (Author). Looking Beyond - Graphics of Satyajit Ray. New Delhi: Roli Books, 2012 | 0000040.bka | CinemaE",
  },
  {
    wnn: "",
    alias: "edited-robinson-satyajit-ray-2005",
    accession_number: 36,
    cover_image_code: "2917_Satyajit Ray_2005.jpg",
    cover_image: "2917_Satyajit Ray_2005.jpg",
    image:
      "https://tuliresearchcentre.canto.global/direct/image/egpjgdchdp20p4h6skf9aq982g/BJKO1SueFEcBR0oef6J0QLtVcyg/m800/800",
    creator:
      '[{"firstname":"Andrew", "lastname": "Robinson", "alias":"andrew-robinson", "type":"Editor"}]',
    sortable_year_date_of_publication: "2013",
    place_of_publication: "London",
    "Country of Publication": "UK",
    publisher: "I.B. Tauris",
    book_title_of_document: "Satyajit Ray",
    book_sub_title_of_document: "A Vision of Cinema",
    research_category_1: "cinema-as-a-key-educational-resource",
    research_category_2: "popular-arts-printed-image-crafts-of-india",
    research_category_3: "",
    research_category_4: "",
    keyword_1: "Satyajit-Ray",
    keyword_2: "andrew-robinson",
    keyword_3: "vintage-posters",
    other_keywords: " conflicts-between-tradition-and-modernity-in-s-j-r-films",
    object_of_focus: "",
    notes: "",
    pdf_scan_of_contents: "",
    pdf_scan_of_index: "",
    document_type: "bke",
    language_of_publication: "English",
    date_of_published_edition_in_library: "2005",
    dates_of_first_edition: 2005,
    binding_status: "HB",
    isbn_issn_number: "1845110749",
    location_of_publication_in_trcis: "NT's Study",
    caption_info:
      "Ghosh, Nemai (Author). Satyajit Ray and Beyond. New Delhi: Delhi Art Gallery, 2013 | 0000032.bka | CinemaE",
  },
  {
    wnn: "",
    alias: "authored-roberge-satyajit-ray-2007",
    accession_number: 38,
    cover_image_code: "2861_Satyajit Ray_2007.jpg",
    cover_image: "2861_Satyajit Ray_2007.jpg",
    image:
      "https://tuliresearchcentre.canto.global/direct/image/invae7aiip6elb68p8tccces3r/06bEoKwVczWLYDTPIOIGPzpu5RM/m800/800",
    creator:
      '[{"firstname":"Gaston", "lastname": "Roberge", "alias":"gaston-roberge", "type":"Author"}]',
    sortable_year_date_of_publication: "2014",
    place_of_publication: "New Delhi",
    "Country of Publication": "India",
    publisher: "Manohar",
    book_title_of_document: "Satyajit Ray",
    book_sub_title_of_document: "",
    research_category_1: "cinema-as-a-key-educational-resource",
    research_category_2: "",
    research_category_3: "",
    research_category_4: "",
    keyword_1: "Satyajit-Ray",
    keyword_2: "gaston-roberge",
    keyword_3: "",
    other_keywords: "satyajit-ray-life-and-works",
    object_of_focus: "",
    notes: "",
    pdf_scan_of_contents: "",
    pdf_scan_of_index: "",
    document_type: "bka",
    language_of_publication: "English",
    date_of_published_edition_in_library: "2007",
    dates_of_first_edition: 2007,
    binding_status: "",
    isbn_issn_number: "81-73047359",
    location_of_publication_in_trcis: "NT's Study",
    caption_info:
      "Sandip, Ray( Editor). Satyajit Ray's Ravi Shankar - An Unfilimed Visual Script. Noida: Collins in association with the Society for the Preservation of Satyajit Ray Archives, 2014 | 0000044.bke | CinemaE",
  },
  {
    wnn: "",
    alias: "authored-bahadur-a-textual-study-of-the-apu-trilogy-2011",
    accession_number: 70,
    cover_image_code: "2997_A Textual Study of the Apu Trilogy_2011.jpg",
    cover_image: "2997_A Textual Study of the Apu Trilogy_2011.jpg",
    image:
      "https://tuliresearchcentre.canto.global/direct/image/bboako27dl5qt38cbj9phr3c62/scn5ShxbspIWo1kf-eUQ5a9yugk/m800/800",
    creator:
      '[{"firstname":"Satish", "lastname": "Bahadur", "alias":"satish-bahadur", "type":"Author"}]',
    sortable_year_date_of_publication: "2017",
    place_of_publication: "New Delhi",
    "Country of Publication": "India",
    publisher: "Vani",
    book_title_of_document: "A Textual Study of the Apu Trilogy",
    book_sub_title_of_document: "",
    research_category_1: "cinema-as-a-key-educational-resource",
    research_category_2:
      "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities",
    research_category_3: "",
    research_category_4: "",
    keyword_1: "satyajit-ray",
    keyword_2: "apu-trilogy",
    keyword_3: "",
    other_keywords: "films-of-s-j-r | film_analysis | ",
    object_of_focus: "",
    notes: "",
    pdf_scan_of_contents: "",
    pdf_scan_of_index: "",
    document_type: "bka",
    language_of_publication: "English",
    date_of_published_edition_in_library: "2011",
    dates_of_first_edition: 2011,
    binding_status: "HB",
    isbn_issn_number: "978-9350004289",
    location_of_publication_in_trcis: "World Cinema Library",
    caption_info:
      "Cherian, V-K (Author). India's Film Society Movement - The Journey and its Impact. New Delhi: Sage, 2017 | 0000037.bka | CinemaE",
  },
  {
    wnn: "",
    alias: "authored-sen-looking-beyond-2012",
    accession_number: 40,
    cover_image_code: "3000_Looking Beyond_2012.jpg",
    cover_image: "3000_Looking Beyond_2012.jpg",
    image:
      "https://tuliresearchcentre.canto.global/direct/image/ght4l47dvd1jt7fi2pigju2s6e/mCNVf8TsVW4WPTjOyYXwTKWREw0/m800/800",
    creator:
      '[{"firstname":"Jayanti", "lastname": "Sen", "alias":"jayanti-sen", "type":"Author"}]',
    sortable_year_date_of_publication: "2017",
    place_of_publication: "New Delhi",
    "Country of Publication": "India",
    publisher: "Roli Books",
    book_title_of_document: "Looking Beyond",
    book_sub_title_of_document: "Graphics of Satyajit Ray",
    research_category_1: "cinema-as-a-key-educational-resource",
    research_category_2: "popular-arts-printed-image-crafts-of-india",
    research_category_3: "",
    research_category_4: "",
    keyword_1: "Satyajit-Ray",
    keyword_2: "graphic-design",
    keyword_3: "vintage-posters",
    other_keywords: "satyajit-ray-s-design",
    object_of_focus: "",
    notes: "",
    pdf_scan_of_contents: "",
    pdf_scan_of_index: "",
    document_type: "bka",
    language_of_publication: "English",
    date_of_published_edition_in_library: "2012",
    dates_of_first_edition: 2012,
    binding_status: "PB",
    isbn_issn_number: "978-8174365651",
    location_of_publication_in_trcis: "NT's Study",
    caption_info:
      "Jindal, Suresh (Author). My Adventures with Satyajit Ray - The Making of Shatranj Ke Khilari. Gurugram: HarperCollins, 2017 | 0000039.bka | CinemaE",
  },
  {
    wnn: "",
    alias: "authored-ghosh-satyajit-ray-and-beyond-2013",
    accession_number: 32,
    cover_image_code: "0865_Satyajit Ray and Beyond_2013.jpg",
    cover_image: "0865_Satyajit Ray and Beyond_2013.jpg",
    image:
      "https://tuliresearchcentre.canto.global/direct/image/nasua0toht6e1ci7ivg3rkpj3g/WEoFWKCL41BnCculov5xFxDfGYQ/m800/800",
    creator:
      '[{"firstname":"Nemai", "lastname": "Ghosh", "alias":"nemai-ghosh", "type":"Author"}]',
    sortable_year_date_of_publication: "2018",
    place_of_publication: "New Delhi",
    "Country of Publication": "India",
    publisher: "Delhi Art Gallery",
    book_title_of_document: "Satyajit Ray and Beyond",
    book_sub_title_of_document: "",
    research_category_1: "cinema-as-a-key-educational-resource",
    research_category_2: "photography-in-india",
    research_category_3: "",
    research_category_4: "",
    keyword_1: "Satyajit-Ray",
    keyword_2: "nemai-ghosh",
    keyword_3: "delhi-art-gallery",
    other_keywords: "",
    object_of_focus: "",
    notes: "",
    pdf_scan_of_contents: "Project No 252",
    pdf_scan_of_index: "",
    document_type: "bka",
    language_of_publication: "English",
    date_of_published_edition_in_library: "2013",
    dates_of_first_edition: 2013,
    binding_status: "HB",
    isbn_issn_number: "978-9381217313",
    location_of_publication_in_trcis: "NT's Study",
    caption_info:
      "Sandip, Ray( Editor). Travails With the Alien - The Film That was Never Made and Other Adventures with Science Fiction : Satyajit Ray. Noida: Harper Collins, 2018 | 0000048.bke | CinemaE",
  },
  {
    wnn: "",
    alias: "edited-ray-satyajit-ray-s-ravi-shankar-2014",
    accession_number: 44,
    cover_image_code: "2933_Satyajit Ray's Ravi Shankar_2014.jpg",
    cover_image: "2933_Satyajit Ray's Ravi Shankar_2014.jpg",
    image:
      "https://tuliresearchcentre.canto.global/direct/image/f78n060ej57gn41lb3s9bck44l/aS82ufFCMmh_rI3gd1418JVbkno/m800/800",
    creator:
      '[{"firstname":"Sandip", "lastname": "Ray", "alias":"sandip-ray", "type":"Editor"}]',
    sortable_year_date_of_publication: "2020",
    place_of_publication: "Noida",
    "Country of Publication": "India",
    publisher:
      "Collins in association with the Society for the Preservation of Satyajit Ray Archives",
    book_title_of_document: "Satyajit Ray's Ravi Shankar",
    book_sub_title_of_document: "An Unfilimed Visual Script",
    research_category_1: "cinema-as-a-key-educational-resource",
    research_category_2: "modern-contemporary-fine-arts-in-india",
    research_category_3: "",
    research_category_4: "",
    keyword_1: "satyajit-ray",
    keyword_2: "ravi-shankar",
    keyword_3: "sandip-ray",
    other_keywords:
      "pather-panchali | apu-trilogy | aparajito | apu-sansar | paras-pathar",
    object_of_focus: "",
    notes: "",
    pdf_scan_of_contents: "",
    pdf_scan_of_index: "",
    document_type: "bke",
    language_of_publication: "English",
    date_of_published_edition_in_library: "2014",
    dates_of_first_edition: 2014,
    binding_status: "HB",
    isbn_issn_number: "978-9351361749",
    location_of_publication_in_trcis: "NT's Study",
    caption_info:
      "Sengupta, Arjun (Author). Soumitra Chatterjee - A Life in Cinema, Theatre, Poetry & Painting. New Delhi: Niyogi Books, 2020 | 0000077.bka | CinemaE",
  },
  {
    wnn: "",
    alias: "authored-cherian-india-s-film-society-movement-2017",
    accession_number: 37,
    cover_image_code: "0245_India's Film Society Movement_2017.jpg",
    cover_image: "0245_India's Film Society Movement_2017.jpg",
    image:
      "https://tuliresearchcentre.canto.global/direct/image/d4ib3ctkh9619e00hd6pu9sd10/aR0Ou22ieFX_ltrBnXgRzjP0UjE/m800/800",
    creator:
      '[{"firstname":"V-K", "lastname": "Cherian", "alias":"v-k-cherian", "type":"Author"}]',
    sortable_year_date_of_publication: "2022",
    place_of_publication: "New Delhi",
    "Country of Publication": "India",
    publisher: "Sage",
    book_title_of_document: "India's Film Society Movement",
    book_sub_title_of_document: "The Journey and its Impact",
    research_category_1: "cinema-as-a-key-educational-resource",
    research_category_2: "social-responsibility-of-the-creative-mind",
    research_category_3: "",
    research_category_4: "",
    keyword_1: "Satyajit-Ray",
    keyword_2: "film-society",
    keyword_3: "cinematic-culture",
    other_keywords: "",
    object_of_focus: "",
    notes: "",
    pdf_scan_of_contents: "Project No 250",
    pdf_scan_of_index: "",
    document_type: "bka",
    language_of_publication: "English",
    date_of_published_edition_in_library: "2017",
    dates_of_first_edition: 2017,
    binding_status: "HB",
    isbn_issn_number: "978-9385985638",
    location_of_publication_in_trcis: "NT's Study",
    caption_info:
      "Juhi, Saklani( Editor). Iti, Satyajit Da - Letters to a Friend from Satyajit Ray. New Delhi: Musui Art Foundation, 2022 | 0000041.bke | CinemaE",
  },
  {
    wnn: "",
    alias: "authored-jindal-my-adventures-with-satyajit-ray-2017",
    accession_number: 39,
    cover_image_code: "2955_My Adventures with Satyajit Ray_2017.jpg",
    cover_image: "2955_My Adventures with Satyajit Ray_2017.jpg",
    image:
      "https://tuliresearchcentre.canto.global/direct/image/q67095kdnp5fp2r8d2c02rtu53/4EmN-s3VNr8sxXJJ9tvEH8xwGnQ/m800/800",
    creator:
      '[{"firstname":"Suresh", "lastname": "Jindal", "alias":"suresh-jindal", "type":"Author"}]',
    sortable_year_date_of_publication: "2022",
    place_of_publication: "Gurugram",
    "Country of Publication": "India",
    publisher: "HarperCollins",
    book_title_of_document: "My Adventures with Satyajit Ray",
    book_sub_title_of_document: "The Making of Shatranj Ke Khilari",
    research_category_1: "cinema-as-a-key-educational-resource",
    research_category_2: "economics-of-art-cinema-culture",
    research_category_3: "",
    research_category_4: "",
    keyword_1: "Satyajit-Ray",
    keyword_2: "shatranj-ke-khilari",
    keyword_3: "suresh-jindal",
    other_keywords: "hindi-cinema | satyajit-ray-s-biography",
    object_of_focus: "",
    notes: "16 plates including both side contents",
    pdf_scan_of_contents: "Project No 252",
    pdf_scan_of_index: "",
    document_type: "bka",
    language_of_publication: "English",
    date_of_published_edition_in_library: "2017",
    dates_of_first_edition: 2017,
    binding_status: "PB",
    isbn_issn_number: "978-9352771025",
    location_of_publication_in_trcis: "NT's Study",
    link: true,
    caption_info:
      "Sandip, Ray( Editor). Satyajit Ray - Miscellany on Life, Cinema, People & Much More. Gurugram: Penguin Random House India, 2022 | 0000046.bke | CinemaE",
  },
  {
    wnn: "",
    alias: "edited-ray-travails-with-the-alien-2018",
    accession_number: 48,
    cover_image_code: "2998_Travails With the Alien_2018.jpg",
    cover_image: "2998_Travails With the Alien_2018.jpg",
    image:
      "https://tuliresearchcentre.canto.global/direct/image/d8s5l1is5h2lr8t5d3gvh8o766/kD27cH5am2JMgN8suQ_zMU74In8/m800/800",
    creator:
      '[{"firstname":"Sandip", "lastname": "Ray", "alias":"sandip-ray", "type":"Editor"}]',
    sortable_year_date_of_publication: "2022",
    place_of_publication: "Noida",
    "Country of Publication": "India",
    publisher: "Harper Collins",
    book_title_of_document: "Travails With the Alien",
    book_sub_title_of_document:
      "The Film That was Never Made and Other Adventures with Science Fiction : Satyajit Ray",
    research_category_1: "cinema-as-a-key-educational-resource",
    research_category_2:
      "uncertainy-revealing-itself-death-magic-mystery-sci-fi-horror-gambling-ai",
    research_category_3: "",
    research_category_4: "",
    keyword_1: "Satyajit-Ray",
    keyword_2: "the-alien",
    keyword_3: "peter-sellers",
    other_keywords: "columbia-films",
    object_of_focus: "",
    notes: "",
    pdf_scan_of_contents: "",
    pdf_scan_of_index: "",
    document_type: "bke",
    language_of_publication: "English",
    date_of_published_edition_in_library: "2018",
    dates_of_first_edition: 2018,
    binding_status: "PB",
    isbn_issn_number: "978-9352779154",
    location_of_publication_in_trcis: "NT's Study",
    caption_info:
      "Neepa, Majumdar( Editor). A Companion to Indian Cinema - . New Jursey: John Wiley, 2022 | 0000069.bke | CinemaE",
  },
  {
    wnn: "",
    alias: "edited-devasundaram-indian-cinema-beyond-bollywood-2018",
    accession_number: 79,
    cover_image_code: "0098_Indian Cinema Beyond Bollywood_2018.jpg",
    cover_image: "0098_Indian Cinema Beyond Bollywood_2018.jpg",
    image:
      "https://tuliresearchcentre.canto.global/direct/image/n2bf4e2u5t1t1f0nh1uc8hjh14/RCTgPLTg1pjtSdSk7HZrfVH4DEs/m800/800",
    creator:
      '[{"firstname":"Ashvin-Immanuel", "lastname": "Devasundaram", "alias":"ashvin-immanuel-devasundaram", "type":"Editor"}]',
    sortable_year_date_of_publication: "2023",
    place_of_publication: "New York",
    "Country of Publication": "USA",
    publisher: "Routledge",
    book_title_of_document: "Indian Cinema Beyond Bollywood",
    book_sub_title_of_document: "The New Indepenedent Cinema Revolution",
    research_category_1: "cinema-as-a-key-educational-resource",
    research_category_2:
      "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities",
    research_category_3: "",
    research_category_4: "",
    keyword_1: "indepenedent-cinema",
    keyword_2: "satyajit-ray",
    keyword_3: "amitabh-bachchan",
    other_keywords:
      "vishal-bhardwaj | film-bazaar |  indepenedent-cinema-in-india",
    object_of_focus: "",
    notes: "",
    pdf_scan_of_contents: "Project No 251",
    pdf_scan_of_index: "",
    document_type: "bke",
    language_of_publication: "English",
    date_of_published_edition_in_library: "2018",
    dates_of_first_edition: 2018,
    binding_status: "HB",
    isbn_issn_number: "978-0815368601",
    location_of_publication_in_trcis: "NT's Study",
    caption_info:
      "Sandip, Ray( Compiler). The Best of Satyajit Ray. Gurgaon: Penguin Random House India, 2023 | 0000047.bke | CinemaE",
  },
  {
    wnn: "",
    alias: "authored-sengupta-soumitra-chatterjee-2020",
    accession_number: 77,
    cover_image_code: "0268_Soumitra Chatterjee_2020.jpg",
    cover_image: "0268_Soumitra Chatterjee_2020.jpg",
    image:
      "https://tuliresearchcentre.canto.global/direct/image/57sfokvt252u347ce80cr3le7r/bhQV6urIurKCOfD281oI8mL8oOs/m800/800",
    creator:
      '[{"firstname":"Arjun", "lastname": "Sengupta", "alias":"arjun-sengupta", "type":"Author"}]',
    sortable_year_date_of_publication: "2023",
    place_of_publication: "New Delhi",
    "Country of Publication": "India",
    publisher: "Niyogi Books",
    book_title_of_document: "Soumitra Chatterjee",
    book_sub_title_of_document: "A Life in Cienma, Theatre, Poetry & Painting",
    research_category_1: "cinema-as-a-key-educational-resource",
    research_category_2: "",
    research_category_3: "",
    research_category_4: "",
    keyword_1: "soumitra-chatterjee",
    keyword_2: "satyajit-ray",
    keyword_3: "indian-theatre",
    other_keywords: "",
    object_of_focus: "",
    notes: "",
    pdf_scan_of_contents: "Project No 251",
    pdf_scan_of_index: "",
    document_type: "bka",
    language_of_publication: "English",
    date_of_published_edition_in_library: "2020",
    dates_of_first_edition: 2020,
    binding_status: "HB",
    isbn_issn_number: "978-9389136760",
    location_of_publication_in_trcis: "NT's Study",
    caption_info:
      "Sandip, Ray( Compiler). The Best of Satyajit Ray - . Gurgaon: Penguin Random House India, 2023 | 0000047.bke | CinemaE",
  },
  // {
  //   wnn: "",
  //   alias: "edited-saklani-iti-satyajit-da-2022",
  //   accession_number: 41,
  //   cover_image_code: "2999_Iti-Satyajit Da_2022.jpg",
  //   cover_image: "2999_Iti-Satyajit Da_2022.jpg",
  //   image:
  //     "https://tuliresearchcentre.canto.global/direct/image/q7cs9t0b6t22lasel75o4bje4h/Q38Rdxvzm3uNtyf6xkP0ejbu8HI/m800/800",
  //   creator:
  //     '[{"firstname":"Juhi", "lastname": "Saklani", "alias":"juhi-saklani", "type":"Editor"}]',
  //   sortable_year_date_of_publication: "2022",
  //   place_of_publication: "New Delhi",
  //   "Country of Publication": "India",
  //   publisher: "Musui Art Foundation",
  //   book_title_of_document: "Iti, Satyajit Da",
  //   book_sub_title_of_document: "Letters to a friend from Satyajit Ray",
  //   research_category_1: "cinema-as-a-key-educational-resource",
  //   research_category_2: "",
  //   research_category_3: "",
  //   research_category_4: "",
  //   keyword_1: "Satyajit-Ray",
  //   keyword_2: "nilanjana-sen",
  //   keyword_3: "bijoya-ray",
  //   other_keywords: "",
  //   object_of_focus: "",
  //   notes: "",
  //   pdf_scan_of_contents: "",
  //   pdf_scan_of_index: "",
  //   document_type: "bke",
  //   language_of_publication: "English",
  //   date_of_published_edition_in_library: "2022",
  //   dates_of_first_edition: 2022,
  //   binding_status: "HB",
  //   isbn_issn_number: "978-8185983325",
  //   location_of_publication_in_trcis: "NT's Study",
  // },
  // {
  //   wnn: "",
  //   alias: "edited-ray-satyajit-ray-2022",
  //   accession_number: 46,
  //   cover_image_code: "2793_Satyajit Ray_2022.jpg",
  //   cover_image: "2793_Satyajit Ray_2022.jpg",
  //   image:
  //     "https://tuliresearchcentre.canto.global/direct/image/3ldj1re1ll2khdaqno856dph2l/Jw5CQvtfuIZtyBni-37fNh-WBDM/m800/800",
  //   creator:
  //     '[{"firstname":"Sandip", "lastname": "Ray", "alias":"sandip-ray", "type":"Editor"}]',
  //   sortable_year_date_of_publication: "2022",
  //   place_of_publication: "Gurugram",
  //   "Country of Publication": "India",
  //   publisher: "Penguin Random House India",
  //   book_title_of_document: "Satyajit Ray",
  //   book_sub_title_of_document:
  //     "Miscellany on Life, Cinema, People & Much More",
  //   research_category_1: "cinema-as-a-key-educational-resource",
  //   research_category_2: "",
  //   research_category_3: "",
  //   research_category_4: "",
  //   keyword_1: "Satyajit-Ray",
  //   keyword_2: "",
  //   keyword_3: "",
  //   other_keywords: "",
  //   object_of_focus: "",
  //   notes: "",
  //   pdf_scan_of_contents: "Project No 252",
  //   pdf_scan_of_index: "",
  //   document_type: "bke",
  //   language_of_publication: "English",
  //   date_of_published_edition_in_library: "2022",
  //   dates_of_first_edition: 2022,
  //   binding_status: "PB",
  //   isbn_issn_number: "9780143448990",
  //   location_of_publication_in_trcis: "NT's Study",
  // },
  // {
  //   wnn: "",
  //   alias: "edited-majumdar-a-companion-to-indian-cinema-2022",
  //   accession_number: 69,
  //   cover_image_code: "2757_A Companion to Indian Cinema_2022.jpg",
  //   cover_image: "2757_A Companion to Indian Cinema_2022.jpg",
  //   image:
  //     "https://tuliresearchcentre.canto.global/direct/image/tc3osd8tbd2p9967efchufk71i/9dBwRpP4g_UUBMZu1m6solHpkxM/m800/800",
  //   creator:
  //     '[{"firstname":"Neepa", "lastname": "Majumdar", "alias":"neepa-majumdar", "type":"Editor"}]',
  //   sortable_year_date_of_publication: "2022",
  //   place_of_publication: "New Jursey",
  //   "Country of Publication": "USA",
  //   publisher: "John Wiley",
  //   book_title_of_document: "A Companion to Indian Cinema",
  //   book_sub_title_of_document: "",
  //   research_category_1: "cinema-as-a-key-educational-resource",
  //   research_category_2:
  //     "history-historiography-of-indian-scholarship-in-the-arts-social-sciences-humanities",
  //   research_category_3: "",
  //   research_category_4: "",
  //   keyword_1: "indian-cinema",
  //   keyword_2: "hindi-cinema",
  //   keyword_3: "",
  //   other_keywords: "",
  //   object_of_focus: "",
  //   notes: "",
  //   pdf_scan_of_contents: "Project No 252",
  //   pdf_scan_of_index: "",
  //   document_type: "bke",
  //   language_of_publication: "English",
  //   date_of_published_edition_in_library: "2022",
  //   dates_of_first_edition: 2022,
  //   binding_status: "HB",
  //   isbn_issn_number: "978-1119048268",
  //   location_of_publication_in_trcis: "",
  // },
  // {
  //   wnn: "",
  //   alias: "edited-ray-the-best-of-satyajit-ray-2023",
  //   accession_number: 47,
  //   cover_image_code: "2960_The Best of Satyajit Ray_2023.jpg",
  //   cover_image: "2960_The Best of Satyajit Ray_2023.jpg",
  //   image:
  //     "https://tuliresearchcentre.canto.global/direct/image/ertljmokad6llf6he465qdau4h/7elzYyy6jUJ8IVGffKimhbKNIJY/m800/800",
  //   creator:
  //     '[{"firstname":"Sandip", "lastname": "Ray", "alias":"sandip-ray", "type":"Compiler"}]',
  //   sortable_year_date_of_publication: "2023",
  //   place_of_publication: "Gurgaon",
  //   "Country of Publication": "India",
  //   publisher: "Penguin Random House India",
  //   book_title_of_document: "The Best of Satyajit Ray",
  //   book_sub_title_of_document: "",
  //   research_category_1: "cinema-as-a-key-educational-resource",
  //   research_category_2: "",
  //   research_category_3: "",
  //   research_category_4: "",
  //   keyword_1: "Satyajit-Ray",
  //   keyword_2: "sandip-ray",
  //   keyword_3: "",
  //   other_keywords: "satyajit-ray-s-cinema | bengali-director",
  //   object_of_focus: "",
  //   notes: "The Penguin Ray Library",
  //   pdf_scan_of_contents: "Project No 252",
  //   pdf_scan_of_index: "",
  //   document_type: "bke",
  //   language_of_publication: "English",
  //   date_of_published_edition_in_library: "2023",
  //   dates_of_first_edition: 2023,
  //   binding_status: "PB",
  //   isbn_issn_number: "978-0143459453",
  //   location_of_publication_in_trcis: "NT's Study",
  // },
  // {
  //   wnn: "",
  //   alias: "edited-ray-the-best-of-satyajit-ray-2023-1",
  //   accession_number: 47,
  //   cover_image_code: "2961_The Best of Satyajit Ray_2023.jpg",
  //   cover_image: "2961_The Best of Satyajit Ray_2023.jpg",
  //   image:
  //     "https://tuliresearchcentre.canto.global/direct/image/1h009fded91s3101q8730nt463/0SF03O7tUQJ7GgJoGiqeNHXvdLw/m800/800",
  //   creator:
  //     '[{"firstname":"Sandip", "lastname": "Ray", "alias":"sandip-ray", "type":"Compiler"}]',
  //   sortable_year_date_of_publication: "2023",
  //   place_of_publication: "Gurgaon",
  //   "Country of Publication": "India",
  //   publisher: "Penguin Random House India",
  //   book_title_of_document: "The Best of Satyajit Ray",
  //   book_sub_title_of_document: "",
  //   research_category_1: "cinema-as-a-key-educational-resource",
  //   research_category_2: "",
  //   research_category_3: "",
  //   research_category_4: "",
  //   keyword_1: "Satyajit-Ray",
  //   keyword_2: "sandip-ray",
  //   keyword_3: "",
  //   other_keywords: "satyajit-ray-s-cinema | bengali-director",
  //   object_of_focus: "",
  //   notes: "The Penguin Ray Library",
  //   pdf_scan_of_contents: "Project No 252",
  //   pdf_scan_of_index: "",
  //   document_type: "bke",
  //   language_of_publication: "English",
  //   date_of_published_edition_in_library: "2023",
  //   dates_of_first_edition: 2023,
  //   binding_status: "PB",
  //   isbn_issn_number: "978-0143459460",
  //   location_of_publication_in_trcis: "NT's Study",
  // },
  // {
  //   wnn: "",
  //   alias: "authored-tiwari-shatranj-ke-khilari-n-d",
  //   accession_number: 66,
  //   cover_image_code: "2891_Shatranj Ke Khilari_.jpg",
  //   cover_image: "2891_Shatranj Ke Khilari_.jpg",
  //   image:
  //     "https://tuliresearchcentre.canto.global/direct/image/69qcpkr3rh5q19vkeu9k48ci0c/MdqBl9t6ZdIncuscROu02mIfBUc/m800/800",
  //   creator:
  //     '[{"firstname":"Surendra", "lastname": "Tiwari", "alias":"surendra-tiwari", "type":"Author"}]',
  //   sortable_year_date_of_publication: "",
  //   place_of_publication: "Bhopal",
  //   "Country of Publication": "India",
  //   publisher: "Madhya Pradesh Film Vikash Nigam",
  //   book_title_of_document: "Shatranj Ke Khilari",
  //   book_sub_title_of_document: "",
  //   research_category_1: "cinema-as-a-key-educational-resource",
  //   research_category_2: "",
  //   research_category_3: "",
  //   research_category_4: "",
  //   keyword_1: "shatranj-ke-khilari",
  //   keyword_2: "satyajit-ray",
  //   keyword_3: "screenplay",
  //   other_keywords: "appreciation-on-the-urdu-film-shatranj-ke-khilari ",
  //   object_of_focus: "",
  //   notes: "",
  //   pdf_scan_of_contents: "",
  //   pdf_scan_of_index: "",
  //   document_type: "bka",
  //   language_of_publication: "Hindi ",
  //   date_of_published_edition_in_library: "",
  //   dates_of_first_edition: null,
  //   binding_status: "",
  //   isbn_issn_number: "",
  //   location_of_publication_in_trcis: "NT's Study",
  // },
];
