import { Container, HStack, Stack } from "@chakra-ui/react";

//Core file
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

//Service file
import * as getSongsSynopsisBooklet from "../../../../services/Booklet";
import { CopyPasteService } from "../../../../services/CopyPaste";

//Component file
import ImageGridList from "../../../Film/BodyOfWork/CrewAndCast/ImageGridList";
import { WebpageComingSoon } from "../../../../CommonComponents/WebpageComingSoon";

export default function SongSynopsisBooklet({ masterlist,tableName }) {
  const [songsBookletData, setSongsBookletData] = useState([]);
  const [loading, setLoading] = useState(true);
  const currentEndpoint = window.location.pathname;
  const parts = currentEndpoint.split("/");
  const masterlistType = parts[1];
  const navigate=useNavigate();
  const formatData =(data)=> {
    return data.map(item => {
        return {
            link: item?.link,
            title: `${item?.book_title_of_document} | ${item?.sortable_year_date_of_publication} | Book.${item?.document_type}`,
            image: item?.image,
            alias:item?.alias
        };
    });
}
  useEffect(() => {
    const getSongsBooklet = async () => {
      const result = await getSongsSynopsisBooklet.getSongsSynopsisBooklets(
        tableName,
        masterlist
      );

      if (result?.length > 0) {
        setLoading(false);
        setSongsBookletData(formatData(result));
      } else {
        setLoading(true);
      }
    };
    getSongsBooklet();
  }, [masterlist,tableName]);

  const handleImage=(item)=>{
    navigate(`/${masterlistType}/${masterlist}/body-of-work/${item}`)
  }

  return (
    <Stack flexDirection={"row-reverse"} height={"100%"} maxW={{base:"auto",md:"1160px"}}>

    <Container maxW={"auto"} px="0" py="4">
      <Stack flexDirection={"row"} justifyContent="flex-start" gap={12}>
        {!loading ? (
          <HStack marginRight={"10px"}>
            <ImageGridList
              columns={[0, null, 4]}
              spacing={"20px"}
              height={"auto"}
              width={"252px"}
              data={songsBookletData}
              text={true}
              transition="1s ease"
              className="card1"
              handleImage={(item) => () => handleImage(item)}
              />
          </HStack>
        ) : (
          <>
            <HStack
              onCopy={(e) => CopyPasteService(e, `${window.location.href}/0/1`)}
              margin={"auto"}
            >
              <WebpageComingSoon />
            </HStack>
          </>
        )}
      </Stack>
    </Container>
    </Stack>
  );
}
