import {
  Box,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  HStack,
  Image,
  Link,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";

// Core Files
import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { CgArrowsExpandRight } from "@react-icons/all-files/cg/CgArrowsExpandRight";
import { AiOutlineRight } from "@react-icons/all-files/ai/AiOutlineRight";
import { EffectFade, EffectFlip, Navigation, Pagination } from "swiper";

// Service Files
import * as getFilmCastData from "../../../../../../services/Film";
import { getCreditsImagesByMasterlistAlias } from "../../../../../../services/Archive";
import { getVerticalLayoutData } from "../../../../../../services/BoxOffice";

// Subsection Files
import H5 from "../../../../../../components/layouts/Typography/H5";
import Small from "../../../../../../components/layouts/Typography/Small";
import Large from "../../../../../../components/layouts/Typography/Large";
import H2 from "../../../../../../components/layouts/Typography/H2";
import { isArray } from "lodash";

//S3 Bucket osw-dev-images
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;

const closeIcon = `${IMAGE_BASE_PATH}/ui/Chevron/closeicon.svg`
const Img5 = `${IMAGE_BASE_PATH}/ui/Film/ShatranjKeKhilari/BodyOfWork/Editorial/5.png`
const Img = `${IMAGE_BASE_PATH}/ui/Film/ShatranjKeKhilari/BodyOfWork/Editorial/3.png`
const SKK1 = `${IMAGE_BASE_PATH}/ui/Film/ShatranjKeKhilari/TitleImage/small.png`
const dummyNew = `${IMAGE_BASE_PATH}/ui/Film/ShatranjKeKhilari/DummyImage/1.png`

function VerticalLayout({ winnerName, setCastTabFlag, masterlistType }) {
  const [slider, setSlider] = useState([]);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [drawerOpenRay, setDrawerOpenRay] = useState(false);
  const [drawerOpenShama, setDrawerOpenShama] = useState(false);
  React.useEffect(() => {
    const getData = async () => {
      const result = await getVerticalLayoutData(winnerName);
      if (result) {
        setSlider(result);
      }
    };
    getData();
  }, [winnerName]);

  // This function fetching image and citation data will confirm with content team then will fix it
  React.useEffect(() => {
    const getData = async () => {
      const result = await getCreditsImagesByMasterlistAlias(
        "artworks",
        winnerName,
        "Body of work_Credits & Cast"
      );
      if (result) {
        console.log("CreditCast Image data", result);
      }
    };
    getData();
  }, [winnerName]);

  const [clickedImageIndex, setClickedImageIndex] = useState(null);
  const [synopsisFullScreenImage, setSynopsisFullScreenImage] = useState(false);
  const [synopsisImgData, setsynopsisImgData] = useState(null);
  const showSynopsisImages = (image, flag, index) => {
    if (image) {
      setClickedImageIndex(index);
      setSynopsisFullScreenImage(flag);
      setsynopsisImgData(image);
    } else {
      setSynopsisFullScreenImage(flag);
      setsynopsisImgData(null);
    }
  };
  //key value
  const [castData, setCastData] = useState([{}]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const getData = async () => {
      const result = await getFilmCastData.filmCreditsData(
        masterlistType,
        winnerName
      );
      if (result.length > 0) {
        setLoading(false);
        setCastData(
          result
            .map((item) => {
              if (item.key !== "alias") {
                return {
                  display: item.display,
                  anothervalue: item.value,
                };
              }
              return null; // Skip the "alias" key
            })
            .filter(Boolean)
        );
      } else {
        setLoading(true);
      }
    };
    getData();
  }, [masterlistType, winnerName]);
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <ImagePopupModal
        isOpen={synopsisFullScreenImage}
        onClose={() => showSynopsisImages(null, false)}
        imageData={synopsisImgData}
        data={slider}
        clickedImageIndex={clickedImageIndex}
        setCastTabFlag={setCastTabFlag}
      />
      <Stack
        minHeight="1300px"
        flexDirection={{ base: "column", md: "row" }}
        alignItems={"flex-start"}
        gap={10}
      >
        {/* Drawer code start */}
        <Drawer
          isOpen={drawerOpen}
          placement="right"
          onClose={(e) => setDrawerOpen(false)}
          id="rightsidebarsmall1"
          size={"sm"}
        >
          <DrawerOverlay />
          <DrawerContent>
            <DrawerHeader>
              <HStack mt={"12px"}>
                <Image src={SKK1} />
                <H2>& Sanjeev Kumar</H2>
                <Image
                  src={closeIcon}
                  paddingLeft="20px"
                  cursor={"pointer"}
                  onClick={() => setDrawerOpen(false)}
                />
              </HStack>
            </DrawerHeader>
            <DrawerBody>
              <Stack
                pb="16px"
                justifyContent="space-between"
                flexDirection="row"
              >
                <HStack>
                  <Large
                    cursor="pointer"
                    color="#035DA1"
                  >
                    Working Still
                  </Large>
                </HStack>
                <HStack marginBottom={"0px"}>
                  <Large color="#035DA1">
                    <AiOutlineRight />
                  </Large>
                </HStack>
              </Stack>
              <Stack
                pb="16px"
                justifyContent="space-between"
                flexDirection="row"
              >
                <HStack>
                  <Large
                    cursor="pointer"
                    // onClick={toggleVisibility}
                    color="#035DA1"
                  >
                    Film Stills
                  </Large>
                </HStack>
                <HStack marginBottom={"0px"}>
                  <Large color="#035DA1">
                    <AiOutlineRight />
                  </Large>
                </HStack>
              </Stack>
              <Stack
                pb="16px"
                justifyContent="space-between"
                flexDirection="row"
              >
                <HStack>
                  <Large
                    cursor="pointer"
                    // onClick={toggleVisibility}
                    color="#035DA1"
                  >
                    Books
                  </Large>
                </HStack>
                <HStack marginBottom={"0px"}>
                  <Large color="#035DA1">
                    <AiOutlineRight />
                  </Large>
                </HStack>
              </Stack>
              <Stack>
                <HStack py={4}>
                  <Image
                    cursor={"pointer"}
                    // onClick={(e) => setFullscreen(true)}
                    src={Img}
                  />
                </HStack>
                <HStack px={2}>
                  <Text
                    fontSize="12px"
                    fontWeight="400"
                    lineHeight={"15px"}
                    // textAlign="centre"
                  >
                    Nemai Ghosh, Shatranj Ke Khilari | 1977 | Working Still |
                    CINE.wks
                  </Text>
                </HStack>
                <HStack justifyContent={"flex-start"} px={2}>
                  <Link href="/sanjeev-kumar">
                    <Small color="#035DA1">
                      Visit Sanjeev Kumar Masterlist →
                    </Small>
                  </Link>
                </HStack>
              </Stack>
            </DrawerBody>
          </DrawerContent>
        </Drawer>

        <Drawer
          isOpen={drawerOpenRay}
          placement="right"
          onClose={(e) => setDrawerOpenRay(false)}
          // finalFocusRef={btnRef}
          id="rightsidebarsmall1"
          size={"sm"}
        >
          <DrawerOverlay />
          <DrawerContent>
            <DrawerHeader>
              <HStack mt={"12px"}>
                <Image src={SKK1} height={"18px"} width={"180px"} />
                <H2>& Satyajit Ray</H2>
                <Image
                  src={closeIcon}
                  paddingLeft="48px"
                  cursor={"pointer"}
                  onClick={() => setDrawerOpenRay(false)}
                />
              </HStack>
            </DrawerHeader>
            <DrawerBody>
              <Stack
                pb="16px"
                justifyContent="space-between"
                flexDirection="row"
              >
                <HStack>
                  <Large
                    cursor="pointer"
                    // onClick={toggleVisibility}
                    color="#035DA1"
                  >
                    Working Still
                  </Large>
                </HStack>
                <HStack marginBottom={"0px"}>
                  <Large color="#035DA1">
                    <AiOutlineRight />
                  </Large>
                </HStack>
              </Stack>
              <Stack
                pb="16px"
                justifyContent="space-between"
                flexDirection="row"
              >
                <HStack>
                  <Large
                    cursor="pointer"
                    // onClick={toggleVisibility}
                    color="#035DA1"
                  >
                    Film Stills
                  </Large>
                </HStack>
                <HStack marginBottom={"0px"}>
                  <Large color="#035DA1">
                    <AiOutlineRight />
                  </Large>
                </HStack>
              </Stack>
              <Stack
                pb="16px"
                justifyContent="space-between"
                flexDirection="row"
              >
                <HStack>
                  <Large
                    cursor="pointer"
                    // onClick={toggleVisibility}
                    color="#035DA1"
                  >
                    Books
                  </Large>
                </HStack>
                <HStack marginBottom={"0px"}>
                  <Large color="#035DA1">
                    <AiOutlineRight />
                  </Large>
                </HStack>
              </Stack>
              <Stack>
                <HStack py={4}>
                  <Image
                    cursor={"pointer"}
                    // onClick={(e) => setFullscreen(true)}
                    src={Img5}
                    width={"600px"}
                  />
                </HStack>
                <HStack px={2}>
                  <Text fontSize="12px" fontWeight="400" lineHeight={"15px"}>
                    Nemai Ghosh, Shatranj Ke Khilari | 1977 | Working Still |
                    CINE.wks
                  </Text>
                </HStack>
                <HStack justifyContent={"flex-start"} px={2}>
                  <Link href="/satyajit-ray">
                    <Small color="#035DA1">
                      Visit Satyajit Ray Masterlist →
                    </Small>
                  </Link>
                </HStack>
              </Stack>
            </DrawerBody>
          </DrawerContent>
        </Drawer>

        <Drawer
          isOpen={drawerOpenShama}
          placement="right"
          onClose={(e) => setDrawerOpenShama(false)}
          // finalFocusRef={btnRef}
          id="rightsidebarsmall1"
          // maxW={"300px"}
          size={"sm"}
        >
          <DrawerOverlay />
          <DrawerContent>
            {/* <DrawerCloseButton /> */}
            <DrawerHeader>
              <HStack mt={"12px"}>
                <Image src={SKK1} />
                <H2>& Shama Zaidi</H2>
                <Image
                  src={closeIcon}
                  paddingLeft="50px"
                  cursor={"pointer"}
                  onClick={() => setDrawerOpenShama(false)}
                />
              </HStack>
            </DrawerHeader>
            <DrawerBody>
              <Stack
                pb="16px"
                justifyContent="space-between"
                flexDirection="row"
              >
                <HStack>
                  <Large
                    cursor="pointer"
                    // onClick={toggleVisibility}
                    color="#035DA1"
                  >
                    Working Still
                  </Large>
                </HStack>
                <HStack marginBottom={"0px"}>
                  <Large color="#035DA1">
                    <AiOutlineRight />
                  </Large>
                </HStack>
              </Stack>
              <Stack
                pb="16px"
                justifyContent="space-between"
                flexDirection="row"
              >
                <HStack>
                  <Large
                    cursor="pointer"
                    // onClick={toggleVisibility}
                    color="#035DA1"
                  >
                    Film Stills
                  </Large>
                </HStack>
                <HStack marginBottom={"0px"}>
                  <Large color="#035DA1">
                    <AiOutlineRight />
                  </Large>
                </HStack>
              </Stack>
              <Stack
                pb="16px"
                justifyContent="space-between"
                flexDirection="row"
              >
                <HStack>
                  <Large
                    cursor="pointer"
                    // onClick={toggleVisibility}
                    color="#035DA1"
                  >
                    Books
                  </Large>
                </HStack>
                <HStack marginBottom={"0px"}>
                  <Large color="#035DA1">
                    <AiOutlineRight />
                  </Large>
                </HStack>
              </Stack>
              <Stack>
                <HStack py={4}>
                  <Image
                    cursor={"pointer"}
                    // onClick={(e) => setFullscreen(true)}
                    src={dummyNew}
                  />
                </HStack>
                <HStack px={2}>
                  <Text
                    fontSize="12px"
                    fontWeight="400"
                    lineHeight={"15px"}
                    // textAlign="centre"
                  >
                    Caption Awaited
                  </Text>
                </HStack>
                <HStack justifyContent={"flex-start"} px={2}>
                  {/* <Link href="/sanjeev-kumar" */}
                  <Small color="#035DA1">Visit Shama Zaidi Masterlist →</Small>
                  {/* </Link> */}
                </HStack>
              </Stack>
            </DrawerBody>
          </DrawerContent>
          {/* ))} */}
        </Drawer>
        {/* Drawer code end */}

        <HStack
          marginTop="0.0rem!important"
          flexDirection={"column"}
          className="col-md-7 col-sm-12"
          pl="0px"
          paddingTop={{ base: "28px", md: "1" }}
          alignItems={"flex-start"}
        >
          {!loading &&
            castData.map((item) => {
              return (
                //need to worrk on Css
                <Stack flexDirection={"row"} className="ml-0" gap="10px">
                  <HStack className="ml-0 px-0">
                    <H5 width="185px">{item.display}</H5>
                  </HStack>
                  <HStack className="ml-0 mt-1 px-0" wrap={"wrap"}>
                    {isArray(item.anothervalue) ? (
                      item?.anothervalue.map((it, index) => {
                        return (
                          <React.Fragment key={index}>
                            {it.link ? (
                              <Text
                                onClick={() => setDrawerOpen(true)}
                                className={{base:"wrap",md:"textWrap"}}
                                marginInlineStart={"0px!important"}
                                fontSize={"15px"}
                                fontWeight="400"
                                color="#035DA1"
                                cursor={"pointer"}
                              >
                                {it?.value}
                                {index < item?.anothervalue.length - 1 &&
                                  " ,"}{" "}
                              </Text>
                            ) : (
                              <Text
                              className={{base:"wrap",md:"textWrap"}}
                                marginInlineStart={"0px!important"}
                                fontSize={"15px"}
                                fontWeight="400"
                                color="#035DA1"
                              >
                                {it?.value}
                                {index < item?.anothervalue.length - 1 &&
                                  " ,"}{" "}
                              </Text>
                            )}
                          </React.Fragment>
                        );
                      })
                    ) : (
                      <Text fontSize={"15px"} fontWeight="400" color="#035DA1">
                        {item.anothervalue && item.anothervalue}
                      </Text>
                    )}
                  </HStack>
                </Stack>
              );
            })}
        </HStack>
        <HStack
          id={"allsliderskkCast"}
          position={"sticky"}
          top={"240px"}
          // className="col-4"
          width={{ base: "100%", md: "400px" }}
          paddingBottom={{ base: "100px", md: "" }}
        >
          <Stack className="col-12" pl="0px">
            <Swiper
              // effect={"flip"}
              grabCursor={true}
              // pagination={true}
              navigation={true}
              modules={[EffectFlip, Navigation]}
              // className="mySwiper"
              // id="tablist1"
            >
              {slider.map((item, index) => {
                return (
                  <SwiperSlide key={index}>
                    <VStack>
                      <Image
                        height={"100%"}
                        cursor={"pointer"}
                        // onClick={(e) =>
                        //   setFullscreen({ image: item?.image, isOpen: true })
                        // }
                        onClick={(e) => {
                          setCastTabFlag(false);
                          showSynopsisImages(item?.image, true, index);
                        }}
                        src={item?.image}
                        borderRadius={"10px"}
                        alt=""
                        width="auto"
                      />
                      <Text
                        pt={"8px"}
                        fontSize={"15px"}
                        fontWeight={400}
                        lineHeight={"17px"}
                        color="#035DA1"
                        textAlign={"center"}
                        width="auto"
                      >
                        {item.desc}
                      </Text>
                    </VStack>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </Stack>
        </HStack>
      </Stack>
    </>
  );
}
export default VerticalLayout;

const ImagePopupModal = ({
  isOpen,
  onClose,
  imageData,
  data,
  clickedImageIndex,
  setCastTabFlag,
}) => {
  return (
    <Modal
      size={"full"}
      isCentered
      isOpen={isOpen}
      onClick={onClose}
      Id="modalfullscreen"
    >
      <ModalOverlay />
      <ModalContent zIndex={"999"}>
        <ModalBody>
          <Box bg="white" mx="10">
            <Stack>
              <Swiper
                slidesPerView={1}
                navigation
                initialSlide={clickedImageIndex}
                // loop={true}
                id="allsliderskk1"
                modules={[EffectFade, Navigation, Pagination]}
              >
                {data.map((item, index) => {
                  return (
                    <SwiperSlide key={index + 1}>
                      <HStack
                        justifyContent={"center"}
                        flexDirection={"column"}
                        marginTop={"15px"}
                      >
                        <Box
                          width={"100%"}
                          height={{ base: "100%", md: "90vh" }}
                        >
                          <Image
                            src={item.image}
                            width={"100%"}
                            height={"inherit"}
                            alt=""
                          />
                        </Box>
                        <HStack paddingTop="10px">
                          <Text
                            pt={"8px"}
                            fontSize={"15px"}
                            fontWeight={400}
                            lineHeight={"17px"}
                            color="#035DA1"
                          >
                            {item.newDesc}
                          </Text>
                        </HStack>
                      </HStack>
                      <HStack
                        justifyContent="flex-end"
                        position="absolute"
                        right="00"
                        top={{ base: "80%", md: "95%" }}
                        marginRight={{ base: "0px", md: "160px" }}
                        zIndex={"999"}
                        transform="translateY(-50%)"
                        paddingRight={{ base: "0px", md: "50px" }}
                      >
                        <Text
                          fontSize={"xl"}
                          bg={"black"}
                          opacity={"0.6"}
                          color={"white"}
                          fontWeight={"400"}
                          padding="2"
                          cursor={"pointer"}
                          borderRadius={"35px"}
                          width="max-content"
                          transform={""}
                          onClick={() => {
                            onClose();
                            setCastTabFlag(true);
                          }}
                        >
                          <CgArrowsExpandRight />
                        </Text>
                      </HStack>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </Stack>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
