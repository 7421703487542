import {
  DailyCalendarData,
  MonthlyCalendarData,
} from "../MockData/CalendarMockUp";
import { exploreDataForCalendar } from "../MockData/ExploreImageData";

export const getDailyCalendarData = async () => {
  const dummyDataEnabled = process.env.REACT_APP_DUMMY_DATA || "";
  if (dummyDataEnabled.toLowerCase() === "yes") {
    return DailyCalendarData;
  } else {
    return [];
  }
};

export const getWeeklyCalendarData = async () => {
  const dummyDataEnabled = process.env.REACT_APP_DUMMY_DATA || "";
  if (dummyDataEnabled.toLowerCase() === "yes") {
    return exploreDataForCalendar;
  } else {
    return [];
  }
};

export const getMonthlyCalendarData = async () => {
  const dummyDataEnabled = process.env.REACT_APP_DUMMY_DATA || "";
  if (dummyDataEnabled.toLowerCase() === "yes") {
    return MonthlyCalendarData;
  } else {
    return [];
  }
};
