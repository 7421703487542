import {
  Box,
  Container,
  Drawer,
  DrawerContent,
  HStack,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";

// Core files
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { IoMdCalendar } from "@react-icons/all-files/io/IoMdCalendar";
import { RiLayoutGridFill } from "@react-icons/all-files/ri/RiLayoutGridFill";
import { MdMap } from "@react-icons/all-files/md/MdMap";
import { MdHomeFilled } from "react-icons/md";

// Service Files
import * as getIntroductionData from "../../../services/Introduction";
import * as getPostData from "../../../services/Post";
import * as getLandingMasterlistData from "../../../services/SectionService";

// Subsection Files
import { common, explore } from "../../../constants/constants";
import LandingPageSlider from "../../../FunctionalComponents/LandingPageSlider/LandingPageSlider";
import { AgraphyLinks } from "../../../FunctionalComponents/AgraphyLinks";
import LeftVmenu from "../../../FunctionalComponents/LeftVmenu";
import Posts from "../../../FunctionalComponents/Posts/Posts";

// import ProtectedInsightsWithAccessControl from "./Insights";

//S3 Bucket osw-dev-images
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;

const member = `${IMAGE_BASE_PATH}/ui/Chevron/member.png`
const circle = `${IMAGE_BASE_PATH}/ui/Chevron/graycircle.png`

export default function LandingPage() {
  const navigate = useNavigate();
  let { tabIndex,alias } = useParams();
  const [initialSlide, setInitialSlide] = useState(0);
  const [landingData, setLandingData] = useState([]);
  const [postsCount, setPostsCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [agraphyLinks, setAgraphyLink] = useState([]);
  const currentEndpoint = window.location.pathname;
  const parts = currentEndpoint.split("/");
  const masterlistType = parts[1];
  const attr=parts[3];

  useEffect(() => {
    if (tabIndex) {
      navigate(`/${masterlistType}/${alias}`);
    }
  }, [masterlistType,tabIndex, alias, navigate]);

  useEffect(() => {

    const getLandingData = async () => {
      try {
        // Set loading state to true
        setLoading(true);

        const result = await getLandingMasterlistData.getLandingPageInfoByAlias(
          masterlistType,
          alias
        );
        if (result) {
          setLandingData(result);
        }
      } catch (error) {
        // Handle error if needed
        console.error("Error fetching landing data:", error);
      } finally {
        // Set loading state to false regardless of success or failure
        setLoading(false);
      }
    };

    const getIntroData = async () => {
      const result = await getIntroductionData.introductionByAlias(
        masterlistType,
        alias
      );

      if (result.length > 0) {
        setName(() => result[0].find((item) => item.key === "full_name")?.value);
        setAgraphyLink([
          {
            link: `/research-categories/${result[0].find((item) => item.key === "researchCategories")?.value[0]?.alias}`,  // replace with the actual link
            title: result[0].find((item) => item.key === "researchCategories")?.value[0]?.value,
          },
          {
            link: `/categories/indian-cinema`,
            title: result[0].find((item) => item.key === "masterlist_type").value,
          }
        ]);
      }
    };

    // Get post count
    const getPostsCount = async () => {
      const result = await getPostData.getPostsCountByMasterlist(
        masterlistType,
        alias
      );
      if (result) {
        setPostsCount(result);
      }
    };

    getLandingData();
    getIntroData();
    getPostsCount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <SidebarWithHeader>
      <Stack width={"100%"}>
        <Container maxW={"auto"} px={"0px"}>
          {!loading ? (
            landingData.length > 0 ? (
              <Tabs
                align={{ xs: "start", sm: "end" }}
                w="full"
                mt="20"
                marginTop={{ base: "20", md: "9" }}
                variant="unstyled"
                defaultIndex={attr==="posts" ? 1 : attr==="insights" ? 2 : 0}

              >
                <Stack
                  px="0px"
                  flexDirection={"row"}
                  justifyContent={"space-between"}
                  width={"100%"}
                >
                  <HStack
                    position={"fixed"}
                    background={"white"}
                    zIndex={9}
                    paddingTop={{ base: "16px", md: "42px" }}
                    paddingBottom={"16px"}
                    top={"64px"}
                    width={{ base: "100%", md: "container.xl" }}
                  >
                    <TabList
                      width={{ base: "100%", md: "container.xl" }}
                      justify-content={"flex-end"}
                      flexDirection={{ base: "column", md: "row" }}
                      pb={{ base: "0", md: "4" }}
                      alignItems={"center"}
                      className="explorepage-tablist"
                    >
                      <Link to={`/${masterlistType}/${alias}`}>
                      <Tab
                        p="0"
                        pl={{ base: "0px", md: "32px" }}
                        marginRight={{
                          base: "",
                          md: "30rem",
                        }}
                        textStyle={"tertiary"}
                        fontSize={"50px"}
                        fontWeight={"700"}
                        fontStyle="normal"
                        justifyContent={"flex-start"}
                        lineHeight={"67px"}
                        onClick={() => {
                          setInitialSlide(initialSlide + 1);
                        }}
                      >
                        <Box
                          width={{ base: "300px", md: "500px" }}
                          height={"100%"}
                        >
                         {name}

                        </Box>
                      </Tab>
                      </Link>
                      <Link to={`/${masterlistType}/${alias}/posts`}>
                      <Tab
                        p="0"
                        className="textWrap"
                        width={"max-content"}
                        color={"#333333"}
                        textStyle={"secondary"}
                        fontSize={"16px"}
                        fontWeight={"600"}
                        _selected={{
                          borderBottom: "4px",
                          marginRight: { base: "50px", md: "0px" },
                          borderColor: "#333333",
                        }}
                        onClick={() => {
                          setInitialSlide(0);
                        }}
                        >
                      {`${explore.POSTS} | ${explore.INSIGHTS} ${postsCount >0 ? `(${String(postsCount)})`:``}`}
                      </Tab>
                        </Link>
                    </TabList>
                  </HStack>
                </Stack>
                <TabPanels paddingTop={{ base: "92px", md: "10px" }}>
                  <TabPanel
                    px={"0"}
                    pt="90px"
                    p="0px"
                    className="masterlistslider"
                  >
                    {landingData.length > 0 && (
                      <LandingPageSlider
                        initialSlide={initialSlide}
                        className={"subleftsliderPhotographer"}
                        data={
                          landingData &&
                          landingData.flatMap((cat) => {
                            return cat.flatMap((it) => {
                              const { key, value } = it;
                              if (key === "landing_page_title") {
                                return {
                                  label: `${value}`,
                                  to: ``,
                                  image: `${
                                    cat.find((item) => item.key === "artwork")
                                      ?.value[0]?.value
                                  }`,
                                };
                              } else {
                                return [];
                              }
                            });
                          })
                        }
                        masterListType={masterlistType}
                      />
                    )}
                    <AgraphyLinks links={agraphyLinks && agraphyLinks} />
                  </TabPanel>
                  <TabPanel
                    px={"0"}
                    py="0"
                    width="100%"
                    paddingTop={{ base: "10px", md: "90px" }}
                  >
                    <Posts masterlist={alias} masterlistType={masterlistType} setPostsCount={setPostsCount}/>
                  </TabPanel>
                 
                </TabPanels>
              </Tabs>
            ) : (
              <Text
                py={"40"}
                fontSize={"45px"}
                fontWeight="700"
                textAlign="center"
              >
                {common.WEBPAGE_COMING_SOON}     
              </Text>
            )
          ) : (
            <Text
              py={"40"}
              fontSize={"45px"}
              fontWeight="700"
              textAlign="center"
            >
              Loading...
            </Text>
          )}
        </Container>
      </Stack>
    </SidebarWithHeader>
  );
}
const SidebarWithHeader = ({ children }) => {
  const { isOpen, onClose } = useDisclosure();
  return (
    <Box minH="100vh" bg={useColorModeValue("gray.100", "gray.900")}>
      <SidebarContent
        onClose={() => onClose}
        display={{ base: "none", md: "block" }}
      />
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
      >
        <DrawerContent>
          <SidebarContent onClose={onClose} />
        </DrawerContent>
      </Drawer>

      <Box ml={{ base: 0, md: 14 }} bg="white">
        {children}
      </Box>
    </Box>
  );
};
const SidebarContent = ({ onClose, display, ...rest }) => {
  const [error, setError] = useState(false);
  const toggleModal = () => {
    setError(!error);
  };
  const LeftVmenuData = [
    {
      link: "/",
      id: "homeicon",
      icon: MdHomeFilled,
    },
    {
      link: "/explore",
      id: "exploreicon",
      icon: RiLayoutGridFill,
      active: true,
    },
    {
      link: "/calendar",
      id: "calendaricon",
      icon: IoMdCalendar,
    },
    {
      link: "/map",
      id: "mapicon",
      icon: MdMap,
    },
  ];
  const navigate = useNavigate();
  const handleNavigation = () => {
      navigate(-1);
  };
  return (
    <LeftVmenu
      handleNavigation={handleNavigation}
      backArrow={true}
      display={display}
      onClose={onClose}
      toggleModal={toggleModal}
      member={member}
      circle={circle}
      LeftVmenuData={LeftVmenuData}
    />
  );
};
