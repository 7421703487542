import React, { useEffect, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Button } from "@chakra-ui/react";

function YearPicker({
  yearFilterOptions,
  handleYearFilter,
  toggleModal,
  handleTo,
  yearRangeFilterOptions,
  filter,
  setFilter,
  setSelectedItemIndex,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const datePickerRef = useRef(null);
  const availableYears = yearFilterOptions
    .map((item) => parseInt(item))
    .filter((year) => !isNaN(year)); // Filter out any invalid years

  const handleYearChange = (date) => {
    const selectedYear = date.getFullYear();
    handleYearFilter(selectedYear);
    toggleModal();
    setIsOpen(false);
    const findIndex = availableYears.indexOf(selectedYear);
    handleTo(findIndex);
    // setSelectedItemIndex(findIndex)
    // let matchingTimePeriod = null;
    let matchingTimePeriod = [];
    for (const item of yearRangeFilterOptions) {
      const sortableDateParts = item.time_period.split(" – ");
      const startYear = parseInt(sortableDateParts[0]);
      const endYear = parseInt(sortableDateParts[1]);

      if (
        parseInt(selectedYear) >= startYear &&
        parseInt(selectedYear) <= endYear
      ) {
        // matchingTimePeriod = item.time_period;
        matchingTimePeriod.push(item.time_period);
        // setFilter({ ...filter, time_period: `${matchingTimePeriod}` });
        // break;
      }
    }
    setFilter({ ...filter, time_period: matchingTimePeriod});

  };
// const yearpickerRange=yearRangeFilterOptions.filter((it)=>it.time_period===filter.time_period)
// const opentoDate= +yearpickerRange[0]?.sortable_date

  const CustomButton = ({ onClick }) => (
    <Button
      //   ref={ref}
      variant={"unstyled"}
      //   rightIcon={isOpen ? <BsChevronUp /> : <BsChevronDown />}
      className="custom-button"
      onClick={onClick}
    >
      All Years
    </Button>
  );

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  function findMissingYears(yearsArray) {
    const sortedYears = yearsArray.slice().sort((a, b) => a - b);
    const minYear = sortedYears[0];
    const maxYear = sortedYears[sortedYears.length - 1];

    const allYears = Array.from(
      { length: maxYear - minYear + 1 },
      (_, index) => minYear + index
    );

    const missingYears = allYears.filter((year) => !sortedYears.includes(year));

    return missingYears;
  }

  const missingYears = findMissingYears(availableYears);

  const isYearDisabled = (date) => {
    const year = date.getFullYear();
    return !missingYears.includes(year); // Check if the year is in the disabledYears array
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        datePickerRef.current &&
        !datePickerRef.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  return (
    <DatePicker
      onChange={handleYearChange}
      dateFormat="yyyy"
      showYearPicker
      minDate={
        availableYears.length > 0 ? new Date(availableYears[0], 0, 1) : null
      }
      maxDate={
        availableYears.length > 0
          ? new Date(availableYears[availableYears.length - 2], 11, 31)
          : null
      }
      customInput={<CustomButton />}
      dropdownMode="select"
      onInputClick={toggleDropdown}
      open={isOpen}
      //   onBlur={toggleDropdown}
      onClickOutside={toggleDropdown}
      filterDate={isYearDisabled}
      className="myDatePicker"
      openToDate={new Date(availableYears[0], 0, 1)}
      // openToDate={new Date(opentoDate, 0, 1)}

    />
  );
}

export default YearPicker;
