import React, { useEffect, useState } from "react";
import LeftVmenu from "../../FunctionalComponents/LeftVmenu";
import { IoMdCalendar } from "@react-icons/all-files/io/IoMdCalendar";
import { RiLayoutGridFill } from "@react-icons/all-files/ri/RiLayoutGridFill";
import { MdMap } from "@react-icons/all-files/md/MdMap";
import { MdHomeFilled } from "react-icons/md";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Drawer,
  DrawerContent,
  HStack,
  Image,
  Stack,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import Carousel from "./Carousel";
import { useQuery } from "../../util/helper";
import * as getSearchResultData from "../../services/Search";
import { button, Search } from "../../constants/constants";
import H1 from "../../Theme/Typography/H1";
import { DataNotFound } from "../../CommonComponents/DataNotFound";
import { BookDetails } from "../../components/LibraryBook/BookDetails";
import SearchCar from "./SearchCar";
import H4 from "../../Theme/Typography/H4";
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;
const loaderImg = `${IMAGE_BASE_PATH}/ui/Chevron/loader.gif`;
export default function SearchResults() {
  return (
    <SidebarWithHeader>
      <Stack width={"100%"} height={"100%"}>
        <SearchResultsBody />
      </Stack>
    </SidebarWithHeader>
  );
}
const SidebarWithHeader = ({ children }) => {
  const { isOpen, onClose } = useDisclosure();
  return (
    <Box minH="100vh" bg={useColorModeValue("gray.100", "gray.900")}>
      <SidebarContent
        onClose={() => onClose}
        display={{ base: "none", md: "block" }}
      />
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
      >
        <DrawerContent>
          <SidebarContent onClose={onClose} />
        </DrawerContent>
      </Drawer>
      <Box ml={{ base: 0, md: 14 }} bg="white">
        {children}
      </Box>
    </Box>
  );
};

const SidebarContent = ({ onClose, display, ...rest }) => {
  const [error, setError] = useState(false);
  const navigate = useNavigate();
  const toggleModal = () => {
    setError(!error);
  };
  const LeftVmenuData = [
    {
      link: "/",
      id: "homeicon",
      icon: MdHomeFilled,
    },
    {
      link: "/explore",
      id: "exploreicon",
      icon: RiLayoutGridFill,
    },
    {
      link: "/calendar",
      id: "calendaricon",
      icon: IoMdCalendar,
    },
    {
      link: "/map",
      id: "mapicon",
      icon: MdMap,
    },
  ];
  const handleNavigation = () => {
    navigate(-1);
  };
  return (
    <LeftVmenu
      backArrow={true}
      display={display}
      onClose={onClose}
      toggleModal={toggleModal}
      handleNavigation={handleNavigation}
      LeftVmenuData={LeftVmenuData}
    />
  );
};

const SearchResultsBody = () => {
  // const [searchData, setSearchData] = useState([]);
  const [allCount, setAllCount] = useState(0);
  const [masterlist,setMasterlist]=useState([])
  const [secondaryMasterlist,setSecondaryMasterlist]=useState([]);
  const [researchCentre,setResearchCentre]=useState([])

  const {bookDetails}=useParams();
  const [count, setCount] = useState(0);
  const query = useQuery();
  const [isLoading, setIsLoading] = useState(false);
  const searchsTerm = query.get("query");
  const getData = async () => {
    try {
      setIsLoading(true);
      const result = await getSearchResultData.getSearchData(searchsTerm);
      setIsLoading(false);
      if (result.data.length > 0) {
        console.log(result.data,"result.data")
        const primaryList = result.data.find((item) => item.index === "Primary Masterlist");
       const secondaryList = result.data.find(
      (item) => item.index === "Secondary Masterlist"
    );
    const researchCentre = result.data.find((item) => item.index === "Research Centre");
    setResearchCentre(researchCentre?researchCentre:[])
    // Setting the states
    setMasterlist(primaryList ? primaryList : []);
    setSecondaryMasterlist(secondaryList ? secondaryList : []);
        // setSearchData(result.data);
        let totalHits = result.data.reduce(
          (sum, index) =>
             sum + index.count ,
          0
        );
        if (allCount > 0) {
          setCount(totalHits);
        } else {
          setCount(totalHits);
        }
      }
    } catch (error) {
      setIsLoading(false);
      // setSearchData([]);
    }
  };
  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchsTerm]);
  return (
    <Stack width={"100%"} height={"100%"} px={4}>
      {bookDetails ? (
              <>
                <Stack alignItems={"center"} width={"100%"}>
                  <BookDetails/>
                  </Stack>
              </>
            ) : (
              <>
      {!isLoading ? (
        <>
         <HStack paddingX={{ base: "0px", md: "32px" }} pt={{ base: "15px", md: "32px"}} pb="2">
            <H1 fontSize={"22px"} lineHeight="28.8px">
              {Search.SEARCH_RESULT_TEXT} <H1 fontSize={"22px"} lineHeight="28.8px" color="color.blue" as="span">{`"${searchsTerm}" (${count})`}</H1>
            </H1>
          </HStack>
          <HStack paddingX={{ base: "0px", md: "32px" }}  pt={{ base: "15px", md: "0px"}} flexDirection={{base:"column",md:"row"}} alignItems={{base:"flex-start",md:"center"}}> 
          <Stack flexDirection={"row"} gap={"15px"}>
                  <HStack alignItems={"flex-end"}>
                  <H1 fontSize={"22px"} lineHeight="26px">
                  {Search.MASTERLIST}
                    </H1>
                    {/* <Link to={`/search-result?query=${encodeURIComponent(searchsTerm)}&type=${encodeURIComponent(data.index)}`}> */}
                      <H1 fontSize="22px" lineHeight="26px">
                       {(masterlist?.count > 0 || secondaryMasterlist?.count > 0) 
                       ? `(${(masterlist?.count || 0)})` : ''}</H1>
                      {/* </Link> */}
                  </HStack>
                </Stack> 
          {/* primaryMasterlistList */}
          {masterlist && masterlist?.hits?.length > 0 && (
            <Stack alignItems={"flex-end"} marginTop={{base:"-20px!important",md:"0px"}} marginInlineStart={{base:"0.0rem!important",md:"0px"}}>
            { masterlist.count>12 && 
           <Link to={`/search-result?query=${encodeURIComponent(searchsTerm)}&type=${encodeURIComponent(masterlist.index)}`}>
               <H4
                 width="max-content"
                 cursor={"pointer"}
                 _hover={{
                   color: "#035DA1",
                 }}
               >
                 {button.VIEW_ALL}
               </H4>
             </Link>
             }
             <Box maxW="100%" paddingX={{ base: "0px", md: "5px" }}>
              <SearchCar data={masterlist}/>
            </Box>
            </Stack>
          )}
          {/* secondaryMasterlist */}
          {/* {secondaryMasterlist && secondaryMasterlist?.hits?.length > 0 && (
            
            <Stack alignItems={"flex-end"} borderLeft={"1px"} pl="10px" borderColor={"color.lightgray3"}>
            { secondaryMasterlist.count>4 && <Link to={`/search-result?query=${encodeURIComponent(searchsTerm)}&type=${encodeURIComponent(secondaryMasterlist.index)}`}>
              <H4
                width="max-content"
                cursor={"pointer"}
                _hover={{
                  color: "#035DA1",
                }}
              >
                {button.VIEW_ALL}
              </H4>
            </Link>}
            <Box maxW="100%" paddingX={{ base: "0px", md: "5px" }}>
               <SearchCar data={secondaryMasterlist}/>
             </Box>
          </Stack>  
           )} */}
             
           </HStack>
          {researchCentre  ? (
           <Box maxW="100%" mx="auto" paddingX={{ base: "0px", md: "32px" }}>
              <Carousel
                data={researchCentre}
                searchsTerm={searchsTerm}
                setAllCount={setAllCount}
                allCount={allCount}
              />
            </Box>
          ) : (
            <HStack>
              <DataNotFound />
            </HStack>
          )}
        </>
      ) : (
        <HStack justifyContent={"center"}>
          <Image src={loaderImg} width={"100px"} height={"100px"} alt="Loading" />
        </HStack>
      )}
      </>
      )}
    </Stack>
  );
};
