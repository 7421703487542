import { Text } from "@chakra-ui/react";
import React from "react";

const Large = ({ children, ...props }) => {
  return (
    <Text
      as="p"
      {...props}
      fontSize="14px"
      fontWeight="400"
      lineHeight={"16px"}
    >
      {children}
    </Text>
  );
};
export default React.memo(Large);
