import {
  Text,
} from "@chakra-ui/react";
import React from "react";

export default function AccordianText({data,width="100%", py="0", textAlign="justify", fontSize="14px", fontWeight="500", lineHeight="16px"}) {
  return (
    <>
      {data &&
           (
            <Text
              fontSize={fontSize}
              fontWeight={fontWeight}
              color="black"
              lineHeight={lineHeight}
              textAlign={textAlign}
              width={width}
              py={py}
            >
              {data}
            </Text>
          )
        }
    </>
  );
}
