import {
  Box,
  HStack,
  Image,
  Stack,
  Text,
} from "@chakra-ui/react";
// Core Files
import React, { useEffect, useRef, useState } from "react";

// Service Files
import { getEditorialData } from "../../../services/Editorial";
import { CopyPasteService } from "../../../services/CopyPaste";
import * as getFilmData from "../../../services/Film";

// Subsection Files
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper";
import { common } from "../../../constants/constants";
import LargeRegular from "../../../Theme/Typography/LargeRegular";

function HistoricalBackground({ setReadMoreFlag, readmoreNavigation, masterlist, masterlistType }) {
  const [imgData, setImgData] = useState([]);
  const [desc, setDesc] = useState("");
  const [selectedImg, setSelectedImg] = useState({
    image: "",
    desc: "",
    index: 0,
    slideImage: "",
  });
 
  useEffect(() => {
    const getData = async () => {
      try {
        const result = await getEditorialData(
            masterlistType, 
            masterlist
        );
        console.log(result);
      } catch (error) {
        console.error('Error fetching editorial data:', error);
      }
    };
    getData();
    // eslint-disable-next-line
  }, []);

  //key value
  const [editorialData, setEditorialData] = useState([]);
  const citationFactor = (data) => {
    return data?.map((item) => {
      const editorial_data=data = item?.find((nestedItem) => nestedItem?.key === "image_alias")?.value[0];
      return {
        desc:`${editorial_data?.bibliographic_caption}`,
        image: editorial_data?.direct_url_of_cover_image,
        full_desc: `${editorial_data?.bibliographic_caption}` ,
        date_of_artwork:editorial_data?.year ,
      };
    });
  };
  useEffect(() => {
    const getData = async () => {
      const result = await getEditorialData(
        masterlistType, 
        masterlist
    );
      if (result) {
        setEditorialData(citationFactor(result));
        setImgData(citationFactor(result));
      }
    };
    const getDesc = async () => {
      const result = await getFilmData.filmByAlias(
    );
      if (result) {
        setDesc(result?.find((it) => it?.key === "editorial_text")?.value);
      }
    };
    getData();
    getDesc();
  }, [masterlist, masterlistType]);

  useEffect(() => {
    if (editorialData && editorialData.length > 0) {
      setSelectedImg({
        image: editorialData[0].image,
        desc: editorialData[0].desc,
        index: 0,
        slideImage: editorialData[0].image,
      });
    }
  }, [editorialData]);

  const swiperRef = useRef(null);
  const handleNextButtonClick = () => {
    // const activeIndex = swiperRef.current.swiper.activeIndex;
    const activeIndex = selectedImg.index + 1;
    const nextSlide = imgData[activeIndex];

    if (nextSlide) {
      setSelectedImg({
        image: nextSlide.image,
        desc: nextSlide.desc,
        index: activeIndex,
        slideImage: nextSlide.image,
      });
    }
  };

  const handlePreviousButtonClick = () => {
    // const activeIndex = swiperRef.current.swiper.activeIndex;
    const activeIndex = selectedImg.index - 1;
    const nextSlide = imgData[activeIndex];
    // swiperRef.current.swiper.slideTo(index);

    if (nextSlide) {
      setSelectedImg({
        image: nextSlide.image,
        desc: nextSlide.desc,
        index: activeIndex,
        slideImage: nextSlide.image,
      });
    }
  };

  return (
    <>
      <Stack
        width={{ base: "100%", md: "1130px" }}
        height={{lg:"100%", '2xl':"calc(100vh - 220px)"}}
      >
        <Stack  py={"25px"} width={{ base: "100%", md: "60%" }}>
          <LargeRegular onCopy={(e) => CopyPasteService(e, `${window.location.href}`)} textStyle={"primary"} lineHeight={"17.61px"}>{desc}</LargeRegular>
        </Stack>
        <Stack
          direction={"row"}
          borderWidth={"0.4px"}
          borderColor={"#e6e6e6"}
          bg={"white"}
          height={{ base: "100%", md: "478px" }}
          py={4}
        >
         { editorialData && editorialData?.length>0 ?
         <>
         
          {editorialData && editorialData?.length>0 && <Stack py={5} marginLeft={{ base: "10px", md: "25px" }}>
            <Box
              mx={{ base: 0, md: 12 }}
              display={"flex"}
              flexDirection={"column"}
              justifyContent={"center"}
              height={{ base: "auto", md: "419px" }}
              width={{ base: "100%", md: "100%" }}
              alignItems={"flex-start"}
              paddingLeft={"75px"}
            >
              <Image
                width={"auto"}
                height={"inherit"}
                objectFit={"contain"}
                objectPosition={"center"}
                src={selectedImg.image}
              />
              <LargeRegular textStyle={"primary"} lineHeight={"17.61px"} textAlign="center" mt={"5px"} color="color.blue"> {selectedImg.desc}</LargeRegular>
            </Box>
          </Stack>}
          {editorialData && editorialData?.length>0 && <Stack
            position={"relative"}
            id="allsliderskkEditorial"
            paddingLeft={{ base: "0px", md: "35px" }}
            py={4}
          >
            <Swiper
              ref={swiperRef}
              navigation={true}
              modules={[Navigation, Pagination]}
              direction="vertical"
              // loop={true}
              slidesPerView={4}
              slidesPerGroup={1}
              onSlideNextTransitionEnd={() => {
                handleNextButtonClick();
              }}
              onSlidePrevTransitionEnd={() => {
                handlePreviousButtonClick();
              }}
            >
              {editorialData &&
                editorialData.map((item, index) => (
                  <SwiperSlide key={index}>
                    <Stack
                      onClick={() => {
                        swiperRef.current.swiper.slideTo(
                          swiperRef.current.swiper.activeIndex
                        );
                        setSelectedImg({
                          image: item.image,
                          desc: item.desc,
                          index: index,
                          slideImage: item.image,
                        });
                      }}
                      style={{
                        border:
                          selectedImg.slideImage === item.image
                            ? "1px solid #A4A4A4"
                            : "none",
                        width: { base: "100px", md: "185px" },
                        height: { base: "auto", md: "98px" },
                      }}
                    >
                      <Box
                        display={"flex"}
                        justifyContent={"center"}
                        width={{ base: "100px", md: "170px" }}
                        mx={"auto"}
                        height={{ base: "100%", md: "95px" }}
                      >
                        <Image
                          width={"auto"}
                          height={"inherit"}
                          objectPosition={"center"}
                          cursor={"pointer"}
                          src={item?.image}
                        />
                      </Box>
                    </Stack>
                  </SwiperSlide>
                ))}
            </Swiper>
          </Stack>}
          </>
          :<>
          <HStack w={"100%"}>
            <Box
                    display={"flex"}
                    alignItems={"center"}
                    w={"inherit"}
                    justifyContent={"center"}
                    gap={4}
            >
              <Text py={"40"} fontSize={"45px"} fontWeight="700" textAlign="center">
                    {common.NO_DATA}
              </Text>
           </Box>
          </HStack>
          </>
          }
        </Stack>
      </Stack>
    </>
  );
}
export default HistoricalBackground;

