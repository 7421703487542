//S3 Bucket osw-dev-images
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;

const Dummy1 = `${IMAGE_BASE_PATH}/ui/OsianamaWorld/1.png`
const Dummy2 = `${IMAGE_BASE_PATH}/ui/OsianamaWorld/2.png`
const Dummy3 = `${IMAGE_BASE_PATH}/ui/OsianamaWorld/3.png`
const Dummy4 = `${IMAGE_BASE_PATH}/ui/OsianamaWorld/4.png`
const Dummy5 = `${IMAGE_BASE_PATH}/ui/OsianamaWorld/5.png`
const Dummy6 = `${IMAGE_BASE_PATH}/ui/OsianamaWorld/6.png`
const Dummy7 = `${IMAGE_BASE_PATH}/ui/OsianamaWorld/7.png`
const Dummy8 = `${IMAGE_BASE_PATH}/ui/OsianamaWorld/8.png`
const Dummy9 = `${IMAGE_BASE_PATH}/ui/OsianamaWorld/9.png`
const Dummy10 = `${IMAGE_BASE_PATH}/ui/OsianamaWorld/10.png`

export const osianamaCircleData = [
  {
    id: 1,
    imgPath: Dummy1,
    title: "Animal-Human-Nature Continuum",
  },
  {
    id: 2,
    imgPath: Dummy2,
    title: "Automobile, Travel & Heritage Tourism",
  },
  {
    id: 3,
    imgPath: Dummy3,
    title: "Changing Smile of Childhood & its Second Coming",
  },
  {
    id: 4,
    imgPath: Dummy4,
    title: "Cinema as a Key Educational Resource",
  },
  {
    id: 5,
    imgPath: Dummy5,
    title: "Economics of Arts & Culture",
  },
  {
    id: 6,
    imgPath: Dummy6,
    title:
      "Historiography of Indian Scholarship in the Arts, Social Sciences & the Humanities",
  },
  {
    id: 7,
    imgPath: Dummy7,
    title: "Occult > Magic > Mystery > Horror > Sci-Fi",
  },
  {
    id: 8,
    imgPath: Dummy8,
    title: "Social Responsibility & the Creative Mind",
  },
  {
    id: 9,
    imgPath: Dummy9,
    title: "Subalterns demanding Justice Again and Again",
  },
  {
    id: 10,
    imgPath: Dummy10,
    title: "India and the World",
  },
];
