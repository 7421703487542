import React, { useEffect, useState } from 'react'
import * as getIllustratedBookData from "./../../../../../services/PersonArtist"
import { Container, HStack, Stack } from '@chakra-ui/react';
import { CopyPasteService } from '../../../../../services/CopyPaste';
import { useNavigate, useParams } from 'react-router-dom';
import { Book } from './Book';
import ImageWithCitation2 from '../../../../../FunctionalComponents/SimpleGrid/ImageWithCitation2';
import Grid from '../../../../../FunctionalComponents/SimpleGrid/Grid';
import { WebpageComingSoon } from '../../../../../CommonComponents/WebpageComingSoon';

export default function IllustratedBooks({ masterlist }) {
  const [illustratedBookData, setIllustratedBookData] = useState([]);
  let { bookDetails } = useParams();
  const navigate = useNavigate();
  const currentEndpoint = window.location.pathname;
  const parts = currentEndpoint.split("/");
  const masterlistType = parts[1]

  const createDataObject = (dataArray) => {
    const dataObject = {};
    if (dataArray.length > 0) {
      const dataItem = dataArray[0];
      for (const key in dataItem) {
        dataObject[key] = dataItem[key];
      }
    }
    return dataObject;
  };
  const citationFactor = (data) => {

    return data && data?.map((item, index) => {
      const author = createDataObject(item?.author);
      const title = createDataObject(item?.name_of_publication);
      const year_of_publication = createDataObject(item?.year_of_publication);
      return {
        image: item?.img,
        desc: `${author?.value}, ${title?.value}, ${year_of_publication?.value}`,
        fullImg: item?.fullImg,
        link: item?.link,
        essayistData: [title, author, year_of_publication],
        alias: item?.alias

      };
    });
  };

  useEffect(() => {
    const getData = async () => {
      const result = await getIllustratedBookData.illustratedBookData(masterlistType, masterlist);
      setIllustratedBookData(citationFactor(result));
    };

    getData();
    // eslint-disable-next-line
  }, []);

  const handleImageClick = (item, index) => {
    navigate(`${item?.alias}`)

  };
  return (
    <>
      {bookDetails ? (
        <Book />
      ) : (
        <Stack flexDirection={"row-reverse"} minH={"100vh"}>
          <Container
            maxW="auto"
            className="songs"
            px={{ base: "4", md: "0px" }}
            pb="8"
          >
            <Stack flexDirection={"row"} gap={4} justifyContent="center">
              {illustratedBookData && illustratedBookData?.length > 0 ? (
                <HStack
                  onCopy={(e) => CopyPasteService(e, `${window.location.href}`)}
                  marginRight={{ base: "0px", md: "30px" }}
                >
                  <Grid
                    data={illustratedBookData}
                    columns={{ base: 1, md: 3, lg: 3, xl: 4 }}
                    spacing={{ base: "5px", md: "24px" }}
                    handleImageClick={handleImageClick}
                    component={ImageWithCitation2}
                  />
                </HStack>
              ) : (
                <WebpageComingSoon/>
              )}
            </Stack>
          </Container>

        </Stack>
      )}

    </>
  )
}
