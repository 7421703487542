import {
  HStack,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  Stack,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { BsArrowsAngleContract } from "react-icons/bs";
import { getImageURL } from "../../../../util/getImage";

export default function DrawerModalDetails({
  familyFullScreenImage,
  familyImgData,
  showFullImages,
  educationImgData,
}) {
  return (
    <>
      <Modal
        size={"full"}
        isCentered
        isOpen={familyFullScreenImage}
        onClick={() => showFullImages(null, false)}
        Id="modalfullscreen"
        familyImgData={familyImgData}
      >
        <ModalContent >
          <ModalBody
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
          >
              <Stack maxW={{base:"100%",md:"1400px"}}>
                <HStack justifyContent={"center"} margin={"65px auto"}>
                  <Image
                    src={
                      familyImgData !== null ||
                      familyImgData !== "undefined" ||
                      familyImgData !== undefined
                        ?
                          getImageURL(familyImgData) || getImageURL(educationImgData)
                        : ""
                    }
                    width={"auto"}
                    height={"100%"}
                    alt=""
                  />
                </HStack>
                <HStack justifyContent="flex-end">
                  {/* <Box position={"absolute"} bottom={"10%"} right={"15%"}> */}
                    <Text
                      fontSize={"xl"}
                      bg={"#525252"}
                      color={"white"}
                      fontWeight={"400"}
                      padding="2"
                      cursor={"pointer"}
                      borderRadius={"35px"}
                      width="max-content"
                      bottom={"10%"}
                      right={"15%"}
                       position={"absolute"}
                      onClick={() => showFullImages(false)}
                    >
                      <BsArrowsAngleContract />
                    </Text>
                  {/* </Box> */}
                </HStack>
              </Stack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
