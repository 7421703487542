import {
  Box,
  Container,
  Stack,
  Text,
  HStack,
  Image,
  SimpleGrid,
  Link,
} from "@chakra-ui/react";
import React from "react";
import "react-vertical-timeline-component/style.min.css";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Swiper, SwiperSlide } from "swiper/react";

import moment from "moment";
import Caption from "../../components/layouts/Typography/Caption";
import H4 from "../../components/layouts/Typography/H4";
import { getWeeklyCalendarData } from "../../services/CalendarService";
import { common } from "../../constants/constants";
import { Navigation } from "swiper";

//S3 Bucket osw-dev-images
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;

const Clock = `${IMAGE_BASE_PATH}/ui/Chevron/clock.png`

function Weekly() {
  const [dayFilterOptions, setDayFilterOptions] = React.useState([]);
  // // const [dayWiseData, setDaywiseData] = React.useState([]);
  // const [activeCard, setActiveCard] = React.useState(0);
  // // const [filter, setFilter] = React.useState({});
  // const sliderRef = React.useRef(null);
  // const sliderRef1 = React.useRef(null);

  // // DayFilterOptions
  React.useEffect(() => {
    const getDayFilterOptions = async () => {
      const currentDate = moment();
      const monthName = currentDate.format("MMM");
      const daysInMonth = currentDate.daysInMonth();
      const dayNames = [];

      for (let day = 1; day <= daysInMonth; day++) {
        const date = moment({
          year: currentDate.year(),
          month: currentDate.month(),
          day,
        });
        const dayName = date.format("ddd");
        dayNames.push({
          date: day,
          day: dayName,
          month: monthName,
        });
      }

      setDayFilterOptions(dayNames);
    };
    getDayFilterOptions();
  }, []);

  // const handleTo = React.useCallback((number) => {
  //   if (!sliderRef.current) return;
  //   setActiveCard(number);
  //   sliderRef.current.swiper.slideTo(number);
  //   sliderRef1.current.swiper.slideTo(number);
  // }, []);

  // React.useEffect(() => {
  //   const getData = () => {
  //     const currentDate = moment();
  //     handleTo(currentDate.format("D") - 1);

  //     // let newData = [];
  //     // const filterKeys = Object.keys(filter);

  //     // if (filterKeys?.length > 0) {
  //     //   let data = filters(newData, filter);
  //     //   setDaywiseData(data);
  //     // } else {
  //     //   let data = filters(newData);
  //     //   setDaywiseData(data);
  //     // }
  //   };
  //   getData();
  // }, [handleTo]);
  const [weekltData, setWeeklyData] = React.useState([]);
  const [showLabel, setShowLabel] = React.useState(false);

  React.useEffect(() => {
    const getData = async () => {
      const result = await getWeeklyCalendarData();
      if (result?.length > 0) {
        setWeeklyData(result);
        setShowLabel(false);
      }
      if (result?.length === 0) {
        setShowLabel(true);
      }
    };
    getData();
  }, []);
  return (
    <Stack>
      <Container className="px-0" maxW="auto">
        <Stack id="datecarosuel" position={"relative"}>
          <Swiper
            // ref={sliderRef1}
            slidesPerView={7}
            centeredSlides={false}
            spaceBetween={8}
            grabCursor={true}
            className="calender"
            navigation={true}
            modules={[Navigation]}
            breakpoints={{
              300: {
                slidesPerView: 5,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 5,
                spaceBetween: 20,
              },
            }}
          >
            {dayFilterOptions.map((item, key) => (
              <SwiperSlide key={key} style={{ alignItems: "baseline" }}>
                <Box cursor="pointer">
                  <Box>
                    {/* <Box onClick={(e) => handleTo(key)} cursor="pointer">
                  <Box
                    borderBottom={key === activeCard ? "5px solid" : ""}
                    borderColor={key === activeCard ? "#333333" : "#333"}
                  > */}
                    <Stack width={"100%"} flexDirection={"inherit"} gap={2}>
                      <HStack>
                        <Text
                          color={"#333"}
                          textStyle={"secondary"}
                          fontSize={{ base: "25px", md: "40px" }}
                          fontWeight={"600"}
                        >
                          {item?.date}
                        </Text>
                      </HStack>
                      <HStack
                        alignItems={"flex-start"}
                        flexDirection={"column"}
                      >
                        <Text
                          color={"black"}
                          fontSize={"16px"}
                          textTransform={"uppercase"}
                          fontWeight={"500"}
                        >
                          {item?.month}
                        </Text>
                        <Text
                          className="ml-0"
                          fontSize={"12px"}
                          color={"black"}
                        >
                          {item?.day}
                        </Text>
                      </HStack>
                    </Stack>
                  </Box>
                </Box>
              </SwiperSlide>
            ))}
          </Swiper>
        </Stack>
        {showLabel ? (
          <Text py={"40"} fontSize={"45px"} fontWeight="700" textAlign="center">
            {common.WEBPAGE_COMING_SOON}
          </Text>
        ) : (
          <>
            <Stack py="8">
              <SimpleGrid columns={[2, null, 5]} spacing="16px">
                {weekltData.map((item) => {
                  return (
                    <Link
                      key={item.id}
                      href={item.id > 8 ? null : item.navigationLink}
                      cursor={item.id > 8 ? "" : "pointer"}
                    >
                      <Box height="auto">
                        <Image
                          src={item.imgPath}
                          className="img-border-radius"
                          height="auto"
                          width="100%"
                        />
                        <H4 color={"black"} mt={"10px"}>
                          {item.title}
                        </H4>
                        <Box display={"flex"}>
                          <HStack>
                            <Image src={Clock} objectPosition="center" />
                          </HStack>
                          <HStack>
                            <Caption color={"black"} py="2" pl="1">
                              {item.dateTime}
                            </Caption>
                          </HStack>
                        </Box>
                      </Box>
                    </Link>
                  );
                })}
              </SimpleGrid>
            </Stack>
          </>
        )}
      </Container>
    </Stack>
  );
}

export default Weekly;
