import { Box, Image, Text } from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";

export default function ImageWithCitation4({
  data,
  handleImage
}) {
  console.log("ImageWithCitation4 data:", data); // Debugging log

  return (
    <>
      {data?.length > 0 &&
        data.map((item, index) => {
          return (
            <Box
              height="auto"
              display={"flex"}
              flexDirection={"column"}
              alignItems={"start"}
              alignContent={"center"}
            >
              {item.link ? (
                <>
                  <Box
                    justifyItems="center"
                    display="flex"
                    height={"auto"}
                    bg="white"
                    width={"100%"}
                    justifyContent="space-around"
                    cursor={"pointer"}
                    onClick={() => handleImage(item.alias)}
                  >
                    <Image
                      src={item.image}
                      alt=""
                      width={"100%"}
                      transition="1s ease"
                      height="100%"
                      objectPosition={"center"}
                    />
                  </Box>
                  <Link to={item.link} cursor={"pointer"}>
                    <Text
                      textAlign="left"
                      color="#0066B3"
                      py="4"
                      fontSize={"16px"}
                      fontWeight={"400"}
                    >
                      {item.monument_name}{item.year}
                    </Text>
                  </Link>
                </>
              ) : (
                <>
                  <Box
                    justifyItems="center"
                    display="flex"
                    height={"auto"}
                    width={"100%"}
                    bg="white"
                    className="card1"
                    justifyContent="space-around"
                    cursor={"pointer"}
                  >
                    <Image
                      src={item.image}
                      alt=""
                      transition="1s ease"
                      height="100%"
                      objectPosition={"center"}
                    />
                  </Box>
                  <Text
                    textAlign="left"
                    color="#0066B3"
                    py="4"
                    fontSize={"16px"}
                    fontWeight={"400"}
                  >
                    {item.monument_name},{item.year}
                  </Text>
                </>
              )}
            </Box>
          );
        })}
    </>
  );
}
