import { Box, HStack, Stack } from '@chakra-ui/react'
import React from 'react'
import { userProfileStats } from '../../constants/constants'
import H1 from '../../Theme/Typography/H1'

export default function UserStats({UserStat}) {
  return (
    <Stack width={"100%"}>
    <Box
          width={{base:"100%",md:"440px"}}
          height="auto"
          border="1px solid #000000"
          borderRadius="10px"
          boxSizing="border-box"
          px="16px"
          pt="16px"
         pb="9px"
        >
          <HStack gap={"20px"}><H1  width={{base:"100%",md:"255px"}} fontSize={{base:"18px",md:"22px"}} lineHeight={"25.83px"}>
            {userProfileStats.INSIGHT_COMPLETED}</H1>
          <H1 fontSize={{base:"18px",md:"24px"}}>{UserStat && `${UserStat?.insights?.score}/${UserStat?.insights?.total}`}</H1>
          </HStack>
          <HStack gap={"20px"}><H1 width={{base:"100%",md:"255px"}} fontSize={{base:"18px",md:"22px"}} lineHeight={"25.83px"}>
            {userProfileStats.QUESTION_ANSWERED}</H1>
          <H1 fontSize={{base:"18px",md:"24px"}}>{UserStat && `${UserStat?.questions?.score}/${UserStat?.questions?.total}`}</H1>
          </HStack>

          <HStack gap={"20px"}><H1 width={{base:"100%",md:"255px"}} fontSize={{base:"18px",md:"22px"}} lineHeight={"25.83px"}>
            {userProfileStats.READINGS}</H1>
          <H1 fontSize={{base:"18px",md:"24px"}}>{UserStat && `${UserStat?.readings?.score}/${UserStat?.readings?.total}`}</H1>
          </HStack>
         
          <HStack gap={"20px"}><H1 width={{base:"100%",md:"255px"}}fontSize={{base:"18px",md:"22px"}} lineHeight={"25.83px"}>
            {userProfileStats.TIME_SPENT}</H1>
          <H1 fontSize={{base:"18px",md:"24px"}}  display="flex" width="100%" justifyContent={{base:"flex-end",md:""}} >{UserStat && `${UserStat?.time_spent?.hours.toString() + ":00"} hours`}</H1>
          </HStack>

          <HStack gap={"20px"}><H1 width={{base:"100%",md:"255px"}} fontSize={{base:"18px",md:"22px"}} lineHeight={"25.83px"}>
            {userProfileStats.POINTS}</H1>
            <H1 fontSize={{base:"18px",md:"24px"}} >{UserStat && `${UserStat?.points?.score}/${UserStat?.points?.total}`}</H1>
          </HStack>
        </Box>
        </Stack>
  )
}
