import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Button, Checkbox, Container, HStack, Stack, Text } from "@chakra-ui/react";

//Core file
import React, { useEffect, useRef, useState } from "react";

//Service file
import { getInsight } from "../../services/Insight";
import Layout from "../../components/sections/SidebarLayout";
import { button, common } from "../../constants/constants";
import { Link, useNavigate, useParams } from "react-router-dom";
import InsightPageSlider from "./InsightPageSlider";
import { IoBookmarks, IoBookmarksOutline } from "react-icons/io5";

//Component file
// import { ContentAwaited } from "../../CommonComponents/ContentAwaited";
import {formatName}  from "../../util/helper";

function InsightDetails() {
  const { alias, insightAlias } = useParams();
  const masterlistTitle = alias;
  const insight = insightAlias;
  const currentEndpoint = window.location.pathname;
  const parts = currentEndpoint.split("/");
  const [ setDrawerOpen] = useState(false);
  const [essentialbookmarkedItems, setEssentialBookmarkedItems] = useState([]);
  const [suggestingBookmarkedItems, setSuggestingBookmarkedItems] = useState([]);
  const [showContent, setShowContent] = useState(false);
  const [insightDetailsData, setInsightDetailsData] = useState([]);
  const masterlistType = parts[1];
  const contentRef = useRef(null);

  const handleShowContent = () => {
    setShowContent(prev => !prev);
    if (!showContent) {
      setTimeout(() => {
        if (contentRef.current) {
          contentRef.current.scrollIntoView({ behavior: 'smooth' });
        }
      }, 0); // Use a timeout to ensure the content has rendered before scrolling
    }
  };

  const toggleBookmark = (index) => {
    if (essentialbookmarkedItems.includes(index)) {
      setEssentialBookmarkedItems(essentialbookmarkedItems.filter((item) => item !== index));
    } else {
      setEssentialBookmarkedItems([...essentialbookmarkedItems, index]);
    }

  };

  const suggestingToggleBookmark = (index) => {
    if (suggestingBookmarkedItems.includes(index)) {
      setSuggestingBookmarkedItems(suggestingBookmarkedItems.filter((item) => item !== index));
    } else {
      setSuggestingBookmarkedItems([...suggestingBookmarkedItems, index]);
    }

  };

  const isItemBookmarked = (index) => {
    return essentialbookmarkedItems.includes(index);
  };

  const isItemSuggestingBookmarked = (index) => {
    return suggestingBookmarkedItems.includes(index);
  };

  useEffect(() => {
    const getData = async () => {
      const result = await getInsight(masterlistType,
        masterlistTitle,
        insight);

      if (result) {
        setInsightDetailsData(result);
      }
    };
    getData();
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const navigate = useNavigate();

  return (
    <Layout>
      {/* <QuizDrawer setDrawerOpen={setDrawerOpen} drawerOpen={drawerOpen} /> */}
      <Container
        maxW={"auto"}
        paddingTop={{ base: "15px", md: "30px" }}
        paddingX={{ base: "15px", md: "30px" }}
        id="allslider"
      >
        {insightDetailsData && insightDetailsData.length > 0 &&
          insightDetailsData?.map((item, index) => (
            <Stack>
              <Stack justifyContent={"space-between"} flexDirection={{ base: "column", md: "row" }} >
                <HStack flexDirection={"column"} alignItems={"flex-start"}>
                  <Text
                    pb="2"
                    fontSize={"25px"}
                    fontWeight={"700"}
                    lineHeight={"30px"}
                  >
                    {item?.insight_heading}
                  </Text>
                  <Text
                    marginInlineStart={"0rem!important"}
                    fontSize={"20px"}
                    fontWeight={"500"}
                    lineHeight={"30px"}
                  >
                    {item?.insight_heading_in_hindi}
                  </Text>
                </HStack>
                <HStack pl={{base:"0px",md:"60px"}} marginBottom={{base:"0px !important",md:"-35px !important"}} width={{ base: "100%", md: "30%" }}  gap={"10px"} wrap={"wrap"}>
                  <Button
                    border={"1px"}
                    borderColor={"#FFC015"}
                    className="keyword-btn"
                    marginInlineStart={"0rem!important"}
                    onClick={handleShowContent}
                  >
                    {button.INSIGHT_GYAN}
                  </Button>
                  <Button
                    as={!item?.insight_video_links ? "span" : undefined}
                    border={"1px"}
                    borderColor={"#FFC015"}
                    className="keyword-btn"
                    marginInlineStart={"0rem!important"}
                    onClick={() => item?.insight_video_links && navigate(`insight-video`)}
                  >
                    {button.INSIGHT_VIDEO}
                  </Button>
                </HStack>
              </Stack>
              <Stack flexDirection={{ base: "column", md: "row" }}>
                <HStack width={{ base: "100%", md: "60%" }} pb="2" gap={"10px"} wrap={"wrap"}>
                {item?.masterlist_type &&
                  item?.masterlist_type
                  .map((it, index) => {
                    const masterlistAlias =
                    it?.alias || "";
                    const isPrimary = it?.alias === alias

                    return (
                    <Button
                    className={`keyword-btn ${isPrimary ? 'primary-color' : 'secondary-color'}`}
                        marginInlineStart={"0rem!important"}
                        onClick={() => navigate(`/${it.type}/${masterlistAlias}`)}
                        width={"max-content"}
                      >
                        {it.name}
                      </Button>
                    );
                    })}
                    {item?.keyword_masterlist_tag_alias &&
                    item?.keyword_masterlist_tag_alias.map((it, index) => (
                    <Button
                        className={`keyword-btn ${
                          it?.link ? `keyword-color` : ``
                        }`}
                        onClick={() => navigate(`/generic/${it}`)}
                        width={"max-content"}
                        marginInlineStart={"0rem!important"}
                      >
                        {formatName(it)}
                      </Button>
                    ))}
                    {item?.provisional_masterlists_tag_alias &&
                    item?.provisional_masterlists_tag_alias.map((it, index) => (
                      <Button
                        as="span"
                        className={`keyword-btn`}
                        width={"max-content"}
                        marginInlineStart={"0rem!important"}
                      >
                        {formatName(it)}
                      </Button>
                    ))}
                </HStack>
                <HStack pl={{base:"0px",md:"45px"}} marginInlineStart={"0rem!important"} flexDir={"row"} flexWrap={"wrap"}>
                  {item?.research_category_tag?.length > 0 &&
                    item?.research_category_tag?.map((it, index) => {
                      if (!it.trim()) return null;

                      return (<>
                        {item?.research_category_tag_alias ?
                          <Link to={`/research-categories/${item?.research_category_tag_alias[index]}`}>
                            <Text
                              marginInlineStart={"0rem!important"}
                              fontSize={"12px"}
                              fontWeight={"500"}
                              lineHeight={"30px"}
                              color={"#0066B3"}
                            >
                              {` ${index === item?.research_category_tag.length - 1 ? ` ` + it : ` ` + it + ` |`} `}
                            </Text>
                          </Link>
                          :
                          <Text
                            marginInlineStart={"0rem!important"}
                            fontSize={"12px"}
                            fontWeight={"500"}
                            lineHeight={"30px"}
                            color={"#0066B3"}
                          >
                            {` ${index === item?.research_category_tag.length - 1 ? " " + it : it + ` | `} `}
                          </Text>
                        }
                      </>
                      )
                    })}
                </HStack>
              </Stack>
              <InsightPageSlider images={item?.insight_images_alias}/>
              <Stack
                gap="21px"
                paddingY={{ base: "20px", md: "0" }}
                marginInlineStart={"0.0rem!important"}
                flexDirection={{ base: "column-reverse", md: "row" }}
                alignItems={{ base: "center", md: "flex-start" }}
              >
                <HStack
                  alignItems={"flex-start"}
                  width={{ base: "100%", md: "762px" }}
                  py="4"
                  px="0"
                  flexDirection={"column"}
                >

                  {showContent && (
                    <Box ref={contentRef} >
                      <Text
                        textStyle={"primary"}
                        fontSize={"20px"}
                        fontWeight={"500"}
                        color={"black"}
                        lineHeight={"23px"}
                        pb="2"
                      >
                        {item?.insight_sub_heading}
                      </Text>
                      <Text
                        textStyle={"primary"}
                        fontSize={"16px"}
                        fontWeight={"400"}
                        color={"black"}
                      >
                        {/* {item?.insight_text_notes} */}

                        {Array.isArray(item?.insight_text_notes) ? (
                        item.insight_text_notes.map((note, index) => (
                        <Text key={index} style={{ paddingBottom: 10 }}>
                        {note}
                        </Text>
                        ))
                        ) : (
                        <Text>
                        {item?.insight_text_notes}
                        </Text>
                        )}

                        <Button
                          color={"#035DA1"}
                          variant={"unstyled"}
                          fontSize={"16px"}
                          fontWeight={"700"}
                          onClick={() => setShowContent(false)}
                        >
                          {common.READ_LESS}
                        </Button>
                      </Text>
                    </Box>
                  )}

                </HStack>
                <Stack
                  width={{ base: "100%", md: "355px" }}
                  flexDirection={"column"}
                  justifyContent={{base:"center",md:"flex-start"}}
                  marginTop={{ base: "0px!important", md: "-80px!important" }}
                  zIndex={{base:"9",md:"999"}}
                  pl={{base:"0px",md:"35px"}}
                  alignItems={{base:"center",md:"flex-start"}}
                >
                  <HStack
                    gap={{ base: 0, md: 1 }}
                    alignItems={"flex-start"}
                    flexDirection={{ base: "column", md: "column" }}
                    marginInlineStart={"0rem!important"}
                  >
                    {item?.essential_reading?.length > 0 && (
                    <Accordion
                      width={{ base: "355px", md: "355px" }}
                      className="ml-0"
                      allowToggle
                      borderColor={"transparent"}
                    >
                      <AccordionItem>
                        <Text fontSize={"16px"}>
                          <AccordionButton
                            as="text"
                            p="0"
                            variant="unstyled"
                            justifyContent={"space-between"}
                          >
                            <Box
                              fontSize={"16px"}
                              fontWeight={"400"}
                              textAlign="left"
                            >
                              {common.E_READING}
                            </Box>
                            <AccordionIcon fontSize={"34px"} />
                          </AccordionButton>
                        </Text>
                        {item?.essential_reading?.length > 0 &&
                          item?.essential_reading?.map((item, index) => (
                            <AccordionPanel key={index} py="0" px="0" pb={4}>
                              <Stack
                                alignItems={"flex-start"}
                                gap={"8px"}
                                flexDirection={"row"}
                                py="2"
                              >
                                <HStack pt="1">
                                  <Checkbox
                                    borderColor={"#616161"}
                                    borderRadius={"0px"}
                                    _checked={{
                                      "& .chakra-checkbox__control": {
                                        background: "black",
                                      },
                                    }}
                                    size={"sm"}
                                    iconColor="white"
                                    cursor="pointer"
                                  />
                                </HStack>
                                <HStack pt="1" marginTop={"0rem!important"}>
                                  <Stack
                                    cursor={"pointer"}
                                    onClick={() => toggleBookmark(index)}
                                  >
                                    {isItemBookmarked(index) ? (
                                      <IoBookmarks size={"14px"} />
                                    ) : (
                                      <IoBookmarksOutline size={"14px"} />
                                    )}
                                  </Stack>
                                </HStack>
                                <HStack marginTop={"0rem!important"}>
                                  <Text color={"#035DA1"}>
                                    {item?.title}
                                  </Text>
                                </HStack>
                              </Stack>
                            </AccordionPanel>
                          ))}
                      </AccordionItem>
                    </Accordion>
                    )}

                    {item?.suggested_reading?.length > 0 && (
                                          <Accordion
                      width={{ base: "355px", md: "355px" }}
                      className="ml-0"
                      allowToggle
                      pb="2"
                      borderColor={"transparent"}
                    >
                      <AccordionItem>
                        <h2>
                          <AccordionButton
                            as="text"
                            p="0"
                            variant="unstyled"
                            justifyContent={"space-between"}
                          >
                            <Box
                              fontSize={"16px"}
                              fontWeight={"400"}
                              textAlign="left"
                            >
                              {" "}
                              {common.S_READING}
                            </Box>
                            <AccordionIcon fontSize={"34px"} />
                          </AccordionButton>
                        </h2>
                        {item?.suggested_reading?.length > 0 &&
                          item?.suggested_reading?.map((item, index) => (
                            <AccordionPanel key={index} py="0" px="0" pb={4}>
                              <Stack
                                alignItems={"flex-start"}
                                gap={"8px"}
                                flexDirection={"row"}
                                py="2"
                              >
                                <HStack pt="1">
                                  <Checkbox
                                    borderColor={"#616161"}
                                    borderRadius={"0px"}
                                    _checked={{
                                      "& .chakra-checkbox__control": {
                                        background: "black",
                                      },
                                    }}
                                    size={"sm"}
                                    iconColor="white"
                                    cursor="pointer"
                                  />
                                </HStack>
                                <HStack pt="1" marginTop={"0rem!important"}>
                                  <Stack
                                    cursor={"pointer"}
                                    onClick={() => suggestingToggleBookmark(index)}
                                  >
                                    {isItemSuggestingBookmarked(index) ? (
                                      <IoBookmarks size={"14px"} />
                                    ) : (
                                      <IoBookmarksOutline size={"14px"} />
                                    )}
                                  </Stack>
                                </HStack>
                                <HStack marginTop={"0rem!important"}>
                                  <Text color={"#035DA1"}>
                                    {item?.title}
                                  </Text>
                                </HStack>
                              </Stack>
                            </AccordionPanel>
                          )
                          )
                          }
                      </AccordionItem>
                    </Accordion>
                    )}
                  </HStack>
                  <HStack className="ml-0" gap="5">
                    <Text
                      as={"button"}
                      fontWeight={"700"}
                      variant={"unstyled"}
                      px="8"
                      py="3"
                      width={"200px"}
                      border={"1px"}
                      bg="#FFC015"
                      onClick={() => setDrawerOpen(true)}
                    >
                      {common.QA_BUTTON}
                    </Text>
                  </HStack>
                </Stack>
              </Stack>
            </Stack>
          ))}
      </Container>
    </Layout>
     );
}
export default InsightDetails;