import { HStack, Image } from "@chakra-ui/react";
import React from "react";
import Large from "../../components/layouts/Typography/Large";

//S3 Bucket osw-dev-images
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;

const linkarrow = `${IMAGE_BASE_PATH}/ui/Chevron/linkarrow.png`

function ArtistLinks({setDrawerOpen,isActive2,artistLinks,artistLinksData,setDrawerName}) {
  return (
    <>
      {artistLinksData.length>0 && artistLinksData.map((it)=>
      <>
      <HStack
        py="2"
        px="4"
        justifyContent={"space-between"}
        cursor={"pointer"}
        onClick={() => {
          // navigate('/film/shatranj-ke-khilari-1977/body-of-work/2/satyajit-ray')
          setDrawerOpen(true);
          setDrawerName(it.name)
        }
        }
      >
        <Large color={"black"}>{it.name}</Large>
        <Image src={linkarrow}></Image>
      </HStack>
      </>
    )}
      {/* </Link> */}
     
    </>
  );
}

export default ArtistLinks;
