//S3 Bucket osw-dev-images
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;

const Img1 = `${IMAGE_BASE_PATH}/ui/Login/SliderImage/1.png`
const Img2 = `${IMAGE_BASE_PATH}/ui/Login/SliderImage/2.png`
const Img3 = `${IMAGE_BASE_PATH}/ui/Login/SliderImage/3.png`
const Img4 = `${IMAGE_BASE_PATH}/ui/Login/SliderImage/4.png`
const Img5 = `${IMAGE_BASE_PATH}/ui/Login/SliderImage/5.png`
const Img6 = `${IMAGE_BASE_PATH}/ui/Login/SliderImage/6.png`

export const LoginSliderData = [
  {
    id: 1,
    image: Img1,
  },
  {
    id: 2,
    image: Img2,
  },
  {
    id: 3,
    image: Img3,
  },
  {
    id: 4,
    image: Img4,
  },
  {
    id: 5,
    image: Img5,
  },
  {
    id: 6,
    image: Img6,
  },
];
