// Core Files
import { Box, Stack } from "@chakra-ui/react";
import YouTube from "react-youtube";
import { CopyPasteService } from "../../../services/CopyPaste";

function WatchFullFilm({ watchFilm }) {
  const onReady = (event) => {
    event.target.pauseVideo();
  };
  const opts = {
    height: "630px",
    width: "100%",
    playerVars: {
      autoplay: 2, // Change 2 to 1 for boolean value
      rel: 0,
    },
  };
  return (
    <Stack
        justifyContent={"space-between"}
        width={{ base: "100%",  md: "1135px" }}
        pb={"14"}
        // flexDirection={{ base: "column", md: "row" }}
        onCopy={(e) => CopyPasteService(e, `${window.location.href}/3`)}
      >
    <Box
      paddingTop={{ base: "30px", md: "30px" }}
    >
      <YouTube videoId={watchFilm.slice(-11)} opts={opts} onReady={onReady} />
    </Box>
    </Stack>
  );
}

export default WatchFullFilm;
