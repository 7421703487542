import {
  Box,
  Container,
  Grid,
  GridItem,
  HStack,
  Image,
  Stack,
  Text,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import H1 from "./layouts/Typography/H1";
import Large from "./layouts/Typography/Large";
import Caption from "./layouts/Typography/Caption";

import Weekly from "../pages/Calendar/Weekly";
import Layout from "./sections/SidebarLayout";
import { common, whatsnew } from "../constants/constants";
import {
  getComingSoonData,
  getWhatsnewArchivedData,
  getWhatsnewData,
} from "../services/WhatsnewService";

function WhatsNew() {
  const [whatsnewdata, setWhatsnewData] = useState([]);
  const [showLabel, setShowLabel] = React.useState(false);

  React.useEffect(() => {
    const getData = async () => {
      const result = await getWhatsnewData();
      if (result?.length > 0) {
        setWhatsnewData(result);
        setShowLabel(false);
      }
      if (result?.length === 0) {
        setShowLabel(true);
      }
    };
    getData();
  }, []);
  const [comingdata, setComingData] = useState([]);
  React.useEffect(() => {
    const getData = async () => {
      const result = await getComingSoonData();
      if (result?.length > 0) {
        setComingData(result);
        setShowLabel(false);
      }
      if (result?.length === 0) {
        setShowLabel(true);
      }
    };
    getData();
  }, []);
  const [archivedata, setArchiveData] = useState([]);
  React.useEffect(() => {
    const getData = async () => {
      const result = await getWhatsnewArchivedData();
      if (result?.length > 0) {
        setArchiveData(result);
        setShowLabel(false);
      }
      if (result?.length === 0) {
        setShowLabel(true);
      }
    };
    getData();
  }, []);
  return (
    <Layout>
      <Container maxW={"1280px"}>
        {showLabel ? (
          <Text py={"40"} fontSize={"45px"} fontWeight="700" textAlign="center">
             {common.WEBPAGE_COMING_SOON}
          </Text>
        ) : (
          <>
            <Stack mx="4" my="36px">
              <HStack>
                <Text lineHeight={"30px"} fontWeight={"700"} fontSize={"48px"}>
                  {whatsnew.HEADING}
                </Text>
              </HStack>
              <HStack>
                <H1> {whatsnew.SUB_HEADING}</H1>
              </HStack>

              <Grid
                templateRows="repeat(1, 1fr)"
                templateColumns="repeat(5, 1fr)"
                gap={4}
                pt="39px"
              >
                <GridItem colSpan={3}>
                  <Stack pb="2">
                    <HStack>
                      <Text
                        lineHeight={"24px"}
                        fontWeight={"700"}
                        fontSize={"20px"}
                      >
                        {whatsnew.PUBLISHED}{" "}
                      </Text>
                      <Text
                        lineHeight={"24px"}
                        fontWeight={"500"}
                        fontSize={"20px"}
                      >
                        {whatsnew.DATE}
                      </Text>
                    </HStack>
                  </Stack>
                  <Stack>
                    <HStack id="allslider" position={"relative"}>
                      <Swiper
                        slidesPerView={3}
                        spaceBetween={10}
                        navigation={true}
                        breakpoints={{
                          640: {
                            slidesPerView: 2,
                            spaceBetween: 16,
                          },
                          768: {
                            slidesPerView: 3,
                            spaceBetween: 16,
                          },
                        }}
                        modules={[Navigation]}
                        className="mySwiper"
                      >
                        {whatsnewdata.map((item, index) => {
                          return (
                            <SwiperSlide key={index}>
                              <Box h={"auto"}>
                                <Image
                                  src={item?.image}
                                  h={"100%"}
                                  py="10px"
                                  objectPosition="center"
                                />
                                <Large color={"black"}>{item?.name}</Large>
                                <Box display={"flex"}>
                                  <HStack>
                                    <Image
                                      src={item?.clockImage}
                                      objectPosition="center"
                                    />
                                  </HStack>
                                  <HStack>
                                    <Caption color={"black"} py="2" pl="1">
                                      {item?.date_and_time}{" "}
                                    </Caption>
                                  </HStack>
                                </Box>
                              </Box>
                            </SwiperSlide>
                          );
                        })}
                      </Swiper>
                    </HStack>
                  </Stack>
                  <Stack
                    pt="30px"
                    justifyContent={"space-between"}
                    flexDirection="row"
                    pb="2"
                  >
                    <HStack>
                      <Text
                        lineHeight={"24px"}
                        fontWeight={"700"}
                        fontSize={"20px"}
                      >
                        {whatsnew.COMING_SOON_LATER}
                      </Text>
                    </HStack>
                  </Stack>
                  <Stack>
                    <HStack id="allslider" position={"relative"}>
                      <Swiper
                        slidesPerView={3}
                        spaceBetween={10}
                        //   pagination={{
                        //     clickable: false,
                        //   }}
                        loop={false}
                        navigation={true}
                        breakpoints={{
                          640: {
                            slidesPerView: 2,
                            spaceBetween: 16,
                          },
                          768: {
                            slidesPerView: 3,
                            spaceBetween: 16,
                          },
                        }}
                        modules={[Navigation]}
                        className="mySwiper"
                        bg="white"
                      >
                        {comingdata.map((item, index) => {
                          return (
                            <SwiperSlide key={index}>
                              <Box h={"auto"}>
                                <Image
                                  src={item?.image}
                                  h={"100%"}
                                  py="10px"
                                  objectPosition="center"
                                />

                                <Large color={"black"}>{item?.name}</Large>
                                <Box display={"flex"}>
                                  <HStack>
                                    <Image
                                      src={item?.clockImage}
                                      objectPosition="center"
                                    />
                                  </HStack>
                                  <HStack>
                                    <Caption color={"black"} py="2" pl="1">
                                      {item?.date_and_time}{" "}
                                    </Caption>
                                  </HStack>
                                </Box>
                              </Box>
                            </SwiperSlide>
                          );
                        })}
                      </Swiper>
                    </HStack>
                  </Stack>
                 
                </GridItem>
                <GridItem marginLeft={"60px"} rowSpan={1} colSpan={2}>
                  <Stack pb="2">
                    <HStack>
                      <Text
                        marginLeft={"88px"}
                        lineHeight={"24px"}
                        fontWeight={"700"}
                        fontSize={"20px"}
                      >
                        {whatsnew.ARCHIVED}
                      </Text>
                    </HStack>
                  </Stack>
                  <Stack>
                    <HStack id="allslider" position={"relative"}>
                      <Swiper
                        slidesPerView={1}
                        spaceBetween={10}
                        loop={false}
                        navigation={true}
                        modules={[Navigation]}
                        className="mySwiper"
                        bg="white"
                      >
                        {archivedata.map((item, index) => {
                          return (
                            <SwiperSlide>
                              <Box h={"auto"}>
                                <Image
                                  src={item?.image}
                                  h={"100%"}
                                  py="10px"
                                  objectPosition="center"
                                />

                                <Large color={"black"}>{item?.name}</Large>
                                <Box display={"flex"}>
                                  <HStack>
                                    <Image
                                      src={item?.clockImage}
                                      objectPosition="center"
                                    />
                                  </HStack>
                                  <HStack>
                                    <Caption color={"black"} py="2" pl="1">
                                      {item?.date_and_time}{" "}
                                    </Caption>
                                  </HStack>
                                </Box>
                              </Box>
                            </SwiperSlide>
                          );
                        })}
                      </Swiper>
                    </HStack>
                  </Stack>
                </GridItem>
              </Grid>
            </Stack>
            <Stack
                    pt="30px"
                    justifyContent={"space-between"}
                    flexDirection="row"
                    pb="2"
                   
                  >
                    <HStack>
                      <Text
                        lineHeight={"24px"}
                        fontWeight={"700"}
                        fontSize={"20px"}
                      >
                        {whatsnew.COMING_SOON}
                      </Text>
                    </HStack>
                  </Stack>

                  <Stack  className="col-11">
                    <Weekly px="0px" />
                  </Stack>
          </>
        )}
      </Container>
    </Layout>
  );
}
export default WhatsNew;
