import {
  AspectRatio,
  Box,
  Button,
  Heading,
  HStack,
  Icon,
  SimpleGrid,
  Stack,
  Text,
} from "@chakra-ui/react";
// import { useParams } from "react-router-dom";
import Layout from "../components/sections/SidebarLayout";
import axios from "axios";
import React from "react";
import { pagination } from "../util/helper";
import { ArrowLeftIcon, ArrowRightIcon } from "@chakra-ui/icons";

function Allsongs(props) {
  const [paginationData, setPaginationData] = React.useState([]);
  const [songs, setSongs] = React.useState([]);
  const [page, setPage] = React.useState(0);

  React.useEffect(() => {
    const getData = async () => {
      const result = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/rest/songs`
      );
      if (result?.data?.songs) {
        const dataCategories = result?.data?.songs.slice(0);
        setSongs(dataCategories);
      }
      setPage(1);
    };
    getData();
    return () => {
      console.log("This will be logged on unmount");
    };
  }, []);
  React.useEffect(() => {
    setPaginationData(pagination(songs, 8, page));
  }, [songs, page]);
  return (
    <Layout>
      <Stack
        m="5"
        pr={"8"}
        pl={"8"}
        width={"100%"}
        className="mobileviewpadding"
      >
        {paginationData.length === 0 ? (
          <Text py={"40"} fontSize={"45px"} fontWeight="700" textAlign="center">
            Content Awaited
          </Text>
        ) : (
          <SimpleGrid columns={[1, null, 4]} spacing="20px">
            {paginationData?.data ? (
              paginationData.data.map((item, index) => (
                <HStack>
                  <Box
                    key={index}
                    maxW={"auto"}
                    w={"full"}
                    bg={"white"}
                    boxShadow={"xl"}
                    rounded={"md"}
                    p={6}
                    overflow={"hidden"}
                    h={"450px"}
                  >
                    <Box
                      h={"210px"}
                      bg={"white"}
                      mt={-6}
                      mx={-6}
                      mb={6}
                      pos={"relative"}
                    >
                      <AspectRatio maxW="auto" height={"220px"} ratio={1}>
                        <iframe
                          title="naruto"
                          // src={"https://www.youtube.com/embed/w_BmHDD8rSM"}
                          src={
                            item?.youtube_link
                              ? item?.youtube_link.replace("watch?v=", "embed/")
                              : "https://www.youtube.com/embed/w_BmHDD8rSM"
                          }
                          allowFullScreen
                        />
                      </AspectRatio>
                    </Box>
                    <Stack>
                      <Heading
                        color={"gray.700"}
                        fontSize={"lg"}
                        fontFamily={"body"}
                      >
                        {item?.name}
                      </Heading>
                      <Text color={"gray.500"}>{item?.song_code}</Text>
                    </Stack>
                    <Stack
                      mt={2}
                      direction={"row"}
                      spacing={4}
                      align={"center"}
                    >
                      <Stack direction={"column"} spacing={0} fontSize={"sm"}>
                        <Text fontWeight={600}>{item?.film_id.name}</Text>
                        {/* <Text>{item?.date}</Text>
                      <Text color={"gray.500"}>{item.date.getFullYear()}</Text> */}
                      </Stack>
                    </Stack>
                  </Box>
                </HStack>
              ))
            ) : (
              <React.Fragment />
            )}
          </SimpleGrid>
        )}
        <Stack p="8" alignContent={"center"}>
          <HStack justifyContent={"center"}>
            <Button
              variant={"link"}
              textDecoration="none"
              onClick={() => setPage(page - 1)}
              isDisabled={!paginationData?.previewPage}
            >
              <Icon as={ArrowLeftIcon} />
            </Button>
            <Box w="35px" h="35px" borderRadius={"30px"} bg="blue.300">
              <Text color={"white"} textAlign="center" p="2">
                {page}
              </Text>
            </Box>

            <Button
              variant={"link"}
              textDecoration="none"
              onClick={() => setPage(page + 1)}
              isDisabled={!paginationData?.nextPage}
            >
              <Icon as={ArrowRightIcon} />
            </Button>
          </HStack>
        </Stack>
      </Stack>
    </Layout>
  );
}
export default Allsongs;
