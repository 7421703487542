import { filterAttribute } from "../constants/constants";

//S3 Bucket osw-dev-images
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;

const PictorialExhibits1 = `${IMAGE_BASE_PATH}/ui/EventExhibition/PictorialSpace/BodyofWork/ExhibitionCatlouge/Exhibits/1.png`
const PictorialExhibits2 = `${IMAGE_BASE_PATH}/ui/EventExhibition/PictorialSpace/BodyofWork/ExhibitionCatlouge/Exhibits/2.png`
const PictorialExhibits3 = `${IMAGE_BASE_PATH}/ui/EventExhibition/PictorialSpace/BodyofWork/ExhibitionCatlouge/Exhibits/3.png`
const PictorialExhibits4 = `${IMAGE_BASE_PATH}/ui/EventExhibition/PictorialSpace/BodyofWork/ExhibitionCatlouge/Exhibits/4.png`
const PictorialExhibits5 = `${IMAGE_BASE_PATH}/ui/EventExhibition/PictorialSpace/BodyofWork/ExhibitionCatlouge/Exhibits/5.png`
const PictorialExhibits6 = `${IMAGE_BASE_PATH}/ui/EventExhibition/PictorialSpace/BodyofWork/ExhibitionCatlouge/Exhibits/6.png`
const PictorialExhibits7 = `${IMAGE_BASE_PATH}/ui/EventExhibition/PictorialSpace/BodyofWork/ExhibitionCatlouge/Exhibits/7.png`
const PictorialExhibits8 = `${IMAGE_BASE_PATH}/ui/EventExhibition/PictorialSpace/BodyofWork/ExhibitionCatlouge/Exhibits/8.png`
const PictorialExhibits9 = `${IMAGE_BASE_PATH}/ui/EventExhibition/PictorialSpace/BodyofWork/ExhibitionCatlouge/Exhibits/9.png`
const PictorialExhibits10 = `${IMAGE_BASE_PATH}/ui/EventExhibition/PictorialSpace/BodyofWork/ExhibitionCatlouge/Exhibits/10.png`
const PictorialExhibits11 = `${IMAGE_BASE_PATH}/ui/EventExhibition/PictorialSpace/BodyofWork/ExhibitionCatlouge/Exhibits/11.png`
const PictorialExhibits12 = `${IMAGE_BASE_PATH}/ui/EventExhibition/PictorialSpace/BodyofWork/ExhibitionCatlouge/Exhibits/12.png`
const PictorialExhibits13 = `${IMAGE_BASE_PATH}/ui/EventExhibition/PictorialSpace/BodyofWork/ExhibitionCatlouge/Exhibits/13.png`
const PictorialExhibits14 = `${IMAGE_BASE_PATH}/ui/EventExhibition/PictorialSpace/BodyofWork/ExhibitionCatlouge/Exhibits/14.png`
const PictorialExhibits15 = `${IMAGE_BASE_PATH}/ui/EventExhibition/PictorialSpace/BodyofWork/ExhibitionCatlouge/Exhibits/15.png`
const PictorialExhibits16 = `${IMAGE_BASE_PATH}/ui/EventExhibition/PictorialSpace/BodyofWork/ExhibitionCatlouge/Exhibits/16.png`
const PictorialExhibits17 = `${IMAGE_BASE_PATH}/ui/EventExhibition/PictorialSpace/BodyofWork/ExhibitionCatlouge/Exhibits/17.png`
const PictorialExhibits18 = `${IMAGE_BASE_PATH}/ui/EventExhibition/PictorialSpace/BodyofWork/ExhibitionCatlouge/Exhibits/18.png`
const PictorialExhibits19 = `${IMAGE_BASE_PATH}/ui/EventExhibition/PictorialSpace/BodyofWork/ExhibitionCatlouge/Exhibits/19.png`
const PictorialExhibits20 = `${IMAGE_BASE_PATH}/ui/EventExhibition/PictorialSpace/BodyofWork/ExhibitionCatlouge/Exhibits/20.png`
const PictorialExhibitsfull1 = `${IMAGE_BASE_PATH}/ui/EventExhibition/PictorialSpace/BodyofWork/ExhibitionCatlouge/Exhibits/full1.png`
const PictorialExhibitsfull2 = `${IMAGE_BASE_PATH}/ui/EventExhibition/PictorialSpace/BodyofWork/ExhibitionCatlouge/Exhibits/full2.png`
const PictorialExhibitsfull3 = `${IMAGE_BASE_PATH}/ui/EventExhibition/PictorialSpace/BodyofWork/ExhibitionCatlouge/Exhibits/full3.png`
const PictorialExhibitsfull4 = `${IMAGE_BASE_PATH}/ui/EventExhibition/PictorialSpace/BodyofWork/ExhibitionCatlouge/Exhibits/full4.png`

export const pictorial_space_exhibit_data = [
  {
    image: PictorialExhibits1,
    fullimg:PictorialExhibitsfull1,
    medium_artwork: {
      key: "medium",
      value: "Oil and Sand on Canvas and 1965",
      display: "Medium, Date",
      date_of_artwork: "1965",
    },
    artist: {
      name: "Mohan Samant",
      full_name: "Mohan Samant",
      first_name: "Mohan",
      last_name: "Samant",
      alias: "mohan-samant",
    },
    title_of_artwork: {
      key: "title-of-artwork",
      value: "Organ of Creation",
      display: "Title of Artwork",
    },
    desc: "Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.Lorem ipsum is placeholder text commonly used in the ",
    link:true
  },
  {
    image: PictorialExhibits2,
    fullimg:PictorialExhibitsfull2,
    medium_artwork: {
      key: "medium",
      value: "Oil on Canvas and 1968",
      display: "Medium, Date",
      date_of_artwork: "1968",
    },
    artist: {
      name: "Rajesh Mehra",
      full_name: "Rajesh Mehra",
      first_name: "Rajesh",
      last_name: "Mehra",
      alias: "rajesh-mehra",
    },
    title_of_artwork: {
      key: "title-of-artwork",
      value: "The Miracle",
      display: "Title of the artwork",
    },
    desc: "Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.Lorem ipsum is placeholder text commonly used in the ",
    link:true

  },
  {
    image: PictorialExhibits3,
    fullimg:PictorialExhibitsfull3,
    medium_artwork: {
      key: "medium",
      value: "Oil on Canvas and 1975",
      display: "Medium, Date",
      date_of_artwork: "1975",
    },
    artist: {
      name: "Madhvi Parekh",
      full_name: "Madhvi Parekh",
      first_name: "Madhvi",
      last_name: "Parekh",
      alias: "madhvi-parekh",
    },
    title_of_artwork: {
      key: "title-of-artwork",
      value: "Sea-Opera",
      display: "Title of the artwork",
    },
    desc: "Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.Lorem ipsum is placeholder text commonly used in the ",
    link:true
  },
  {
    image: PictorialExhibits4,
    fullimg:PictorialExhibitsfull4,
    medium_artwork: {
      key: "medium",
      value: "Etching and Silk Screen and 1977",
      display: "Medium, Date",
      date_of_artwork: "1977",
    },
    artist: {
      name: "R.B. Bhaskaran",
      full_name: "R.B. Bhaskaran",
      first_name: "R.B.",
      last_name: "Bhaskaran",
      alias: "r-b-bhaskaran",
    },
    title_of_artwork: {
      key: "title-of-artwork",
      value: "Owl-I",
      display: "Title of the artwork",
    },
    desc: "Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.Lorem ipsum is placeholder text commonly used in the ",
    link:true
  },
  {
    image: PictorialExhibits5,
    medium_artwork: {
      key: "medium",
      value: "Oil on Canvas and 1971",
      display: "Medium, Date",
      date_of_artwork: "1971",
    },
    artist: {
      name: "K.C.S. Paniker",
      full_name: "K.C.S. Paniker",
      first_name: "K.C.S.",
      last_name: "Paniker",
      alias: "k-c-s-paniker",
    },
    title_of_artwork: {
      key: "title-of-artwork",
      value: "Words and Symbols",
      display: "Title of the artwork",
    },
    desc: "Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.Lorem ipsum is placeholder text commonly used in the ",
  },
  {
    image: PictorialExhibits6,
    medium_artwork: {
      key: "medium",
      value: "Oil on Canvas and 1976",
      display: "Medium, Date",
      date_of_artwork: "1976",
    },
    artist: {
      name: "Mona Rai",
      full_name: "Mona Rai",
      first_name: "Mona",
      last_name: "Rai",
      alias: "mona-rai",
    },
    title_of_artwork: {
      key: "title-of-artwork",
      value: "Conversing Trees-II",
      display: "Title of the artwork",
    },
    desc: "Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.Lorem ipsum is placeholder text commonly used in the ",
  },
  {
    image: PictorialExhibits7,
    medium_artwork: {
      key: "medium",
      value: "Ink and Poster-Colour and 1977",
      display: "Medium, Date",
      date_of_artwork: "1977",
    },
    artist: {
      name: "Arpita Singh",
      full_name: "Arpita Singh",
      first_name: "Arpita",
      last_name: "Singh",
      alias: "arpita-singh",
    },
    title_of_artwork: {
      key: "title-of-artwork",
      value: "Untitled",
      display: "Title of the artwork",
    },
    desc: "Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.Lorem ipsum is placeholder text commonly used in the ",
  },
  {
    image: PictorialExhibits8,
    medium_artwork: {
      key: "medium",
      value: "Oil on Canvas and 1974",
      display: "Medium, Date",
      date_of_artwork: "1974",
    },
    artist: {
      name: "R.K. Dhawan",
      full_name: "R.K. Dhawan",
      first_name: "R.K.",
      last_name: "Dhawan",
      alias: "r-k-dhawan",
    },
    title_of_artwork: {
      key: "title-of-artwork",
      value: "Untitled",
      display: "Title of the artwork",
    },
    desc: "Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.Lorem ipsum is placeholder text commonly used in the ",
  },
  {
    image: PictorialExhibits9,
    medium_artwork: {
      key: "medium",
      value: "Ink and Poster-Colour and 1971",
      display: "Medium, Date",
      date_of_artwork: "1971",
    },
    artist: {
      name: "Jeram Patel",
      full_name: "Jeram Patel",
      first_name: "Jeram",
      last_name: "Patel",
      alias: "jeram-patel",
    },
    title_of_artwork: {
      key: "title-of-artwork",
      value: "Untitled",
      display: "Title of the artwork",
    },
    desc: "Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.Lorem ipsum is placeholder text commonly used in the ",
  },
  {
    image: PictorialExhibits10,
    medium_artwork: {
      key: "medium",
      value: "Pulp-print and 1977",
      display: "Medium, Date",
      date_of_artwork: "1977",
    },
    artist: {
      name: "Somnath Hore",
      full_name: "Somnath Hore",
      first_name: "Somnath",
      last_name: "Hore",
      alias: "somnath-hore",
    },
    title_of_artwork: {
      key: "title-of-artwork",
      value: "Wounds",
      display: "Title of the artwork",
    },
    desc: "Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.Lorem ipsum is placeholder text commonly used in the ",
  },
  {
    image: PictorialExhibits11,
    medium_artwork: {
      key: "medium",
      value: "Paper and Acrylic and 1975",
      display: "Medium, Date",
      date_of_artwork: "1975",
    },
    artist: {
      name: "Ved Nayar",
      full_name: "Ved Nayar",
      first_name: "Ved",
      last_name: "Nayar",
      alias: "ved-nayar",
    },
    title_of_artwork: {
      key: "title-of-artwork",
      value: "Untitled",
      display: "Title of the artwork",
    },
    desc: "Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.Lorem ipsum is placeholder text commonly used in the ",
  },
  {
    image: PictorialExhibits12,
    medium_artwork: {
      key: "medium",
      value: "Ink Drawing and 1976",
      display: "Medium, Date",
      date_of_artwork: "1976",
    },
    artist: {
      name: "Nasreen Mohamedi",
      full_name: "Nasreen Mohamedi",
      first_name: "Nasreen",
      last_name: "Mohamedi",
      alias: "nasreen-mohamedi",
    },
    title_of_artwork: {
      key: "title-of-artwork",
      value: "Untitled",
      display: "Title of the artwork",
    },
    desc: "Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.Lorem ipsum is placeholder text commonly used in the ",
  },
  {
    image: PictorialExhibits13,
    medium_artwork: {
      key: "medium",
      value: "Oil on Canvas and 1974",
      display: "Medium, Date",
      date_of_artwork: "1974",
    },
    artist: {
      name: "J. Swaminathan",
      full_name: "J. Swaminathan",
      first_name: "J.",
      last_name: "Swaminathan",
      alias: "j-swaminathan",
    },
    title_of_artwork: {
      key: "title-of-artwork",
      value: "Dik-Nritya",
      display: "Title of the artwork",
    },
    desc: "Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.Lorem ipsum is placeholder text commonly used in the ",
  },
  {
    image: PictorialExhibits14,
    medium_artwork: {
      key: "medium",
      value: "Ink Drawing and 1972",
      display: "Medium, Date",
      date_of_artwork: "1972",
    },
    artist: {
      name: "Ramanujam",
      full_name: "Ramanujam",
      first_name: "Ramanujam",
      last_name: "",
      alias: "ramanujam",
    },
    title_of_artwork: {
      key: "title-of-artwork",
      value: "Untitled",
      display: "Title of the artwork",
    },
    desc: "Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.Lorem ipsum is placeholder text commonly used in the ",
  },
  {
    image: PictorialExhibits15,
    medium_artwork: {
      key: "medium",
      value: "Enamel on Canvas and 1975",
      display: "Medium, Date",
      date_of_artwork: "1975",
    },
    artist: {
      name: "Prabhakar Barwe",
      full_name: "Prabhakar Barwe",
      first_name: "Prabhakar",
      last_name: "Barwe",
      alias: "prabhakar-barwe",
    },
    title_of_artwork: {
      key: "title-of-artwork",
      value: "Confrontation",
      display: "Title of the artwork",
    },
    desc: "Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.Lorem ipsum is placeholder text commonly used in the ",
  },
  {
    image: PictorialExhibits16,
    medium_artwork: {
      key: "medium",
      value: "Oil on Canvas and 1972",
      display: "Medium, Date",
      date_of_artwork: "1972",
    },
    artist: {
      name: "Arpita Singh",
      full_name: "Arpita Singh",
      first_name: "Arpita",
      last_name: "Singh",
      alias: "arpita-singh",
    },
    title_of_artwork: {
      key: "title-of-artwork",
      value: "Flowers and Figures",
      display: "Title of the artwork",
    },
    desc: "Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.Lorem ipsum is placeholder text commonly used in the ",
  },
  {
    image: PictorialExhibits17,
    medium_artwork: {
      key: "medium",
      value: "Plaster, Tin, Terracotta and Aluminium Paint on Board and 1973",
      display: "Medium, Date",
      date_of_artwork: "1973",
    },
    artist: {
      name: "Himmat Shah",
      full_name: "Himmat Shah",
      first_name: "Himmat",
      last_name: "Shah",
      alias: "himmat-shah",
    },
    title_of_artwork: {
      key: "title-of-artwork",
      value: "Untitled (detail)",
      display: "Title of the artwork",
    },
    desc: "Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.Lorem ipsum is placeholder text commonly used in the ",
  },

  {
    image: PictorialExhibits18,
    medium_artwork: {
      key: "medium",
      value: "Tempera and 1975",
      display: "Medium, Date",
      date_of_artwork: "1975",
    },
    artist: {
      name: "Ganesh Pyne",
      full_name: "Ganesh Pyne",
      first_name: "Ganesh",
      last_name: "Pyne",
      alias: "ganesh-pyne",
    },
    title_of_artwork: {
      key: "title-of-artwork",
      value: "The Footprint",
      display: "Title of the artwork",
    },
    desc: "Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.Lorem ipsum is placeholder text commonly used in the ",
  },
  {
    image: PictorialExhibits19,
    medium_artwork: {
      key: "medium",
      value: "Pencil and Ink and 1976",
      display: "Medium, Date",
      date_of_artwork: "1976",
    },
    artist: {
      name: "Amitava Das",
      full_name: "Amitava Das",
      first_name: "Amitava",
      last_name: "Das",
      alias: "amitava-das",
    },
    title_of_artwork: {
      key: "title-of-artwork",
      value: "Man in the Field",
      display: "Title of the artwork",
    },
    desc: "Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.Lorem ipsum is placeholder text commonly used in the ",
  },
  {
    image: PictorialExhibits20,
    medium_artwork: {
      key: "medium",
      value: "Tempera and 1977",
      display: "Medium, Date",
      date_of_artwork: "1977",
    },
    artist: {
      name: "P. Gopinath",
      full_name: "P. Gopinath",
      first_name: "P.",
      last_name: "Gopinath",
      alias: "p-gopinath",
    },
    title_of_artwork: {
      key: "title-of-artwork",
      value: "Biomorphic Images-C",
      display: "Title of the artwork",
    },
    desc: "Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.Lorem ipsum is placeholder text commonly used in the ",
  },
];

export const pictorial_space_exhibition_filter_options = [
    {
      key:"creatorType",
      display:filterAttribute.AUTHOR_EDITOR_CONTRIBUTOR,
      value:[]},
   {key:"medium",
    display:filterAttribute.MEDIUM, value: []},
    {key:"year",
      display:filterAttribute.YEAR,value: []},
  ];


