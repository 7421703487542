import { Stack } from "@chakra-ui/react";
import React from "react";
import H1 from "../components/layouts/Typography/H1";

import Subheading from "../components/layouts/Typography/Subheading";

export default function MapTitle() {
  return (
    <Stack ml="10">
      <Subheading color="#adadad">1901</Subheading>
      <H1 color="#adadad">Indo-Japanese Dialogue</H1>
    </Stack>
  );
}
