import api from "../services/api";
import { APIS } from "../endpoints";
import { ResearchCategorydummyData } from "../MockData/Research_Category";
import { isTransformationNotAllowed } from "../util/isTranformationAllowed";

export const getResearchCategoriesAPI = async () => {
  const result = await api.get(`${APIS.BASE_URL}${APIS.allResearchCategory}`)
  if (result) {
    return result?.data?.data;
  }
};

export const getResearchCategoriesMasterlistsAPI = async (rc) => {
  const result = await api.get(`${APIS.BASE_URL}${APIS.allResearchCategoryMasterlist}?researchcategories=${rc}`)
  if (result) {
    return result?.data?.data;
  }
};

export const getResearchCategories = async () => {
  if (isTransformationNotAllowed() === true) {
    const response = await getResearchCategoriesDummyData();
    return response;
  }else if (isTransformationNotAllowed() === false) {
    const response = await getResearchCategoriesAPI();
    try{
      if (response) {  
        return response;
      } else {
        console.error("Invalid response data structure:", response);
        return [];
      }
    } catch (e){
      console.error("Something is wrong", e)
    }
  }
};

export const getResearchCategoriesDummyData = async () => {
  return ResearchCategorydummyData;
}
