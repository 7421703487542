import {
  Box,
  useColorModeValue,
  Container,
  HStack,
  Text,
  Stack,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerBody,
  useDisclosure,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  OrderedList,
  ListItem,

} from "@chakra-ui/react";

//Core file
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

//Service files
import * as getIntroductionData from "../../../../services/Introduction";
import * as getLandingMasterlistData from "../../../../services/SectionService";
import * as getMonumentDetailData from "../../../../services/Location";

//Component file
import { BottomMainMenu } from "../../../../CommonComponents/BottomMainMenu";
import CommonLeftSidebar from "../../../../FunctionalComponents/LeftSidebar/CommonLeftSidebar";
import { FilterButton } from "../../../../CommonComponents/FilterButton";
import ReadMore from "../../ReadMore";
import { FullScreenView } from "./FullScreenView";

//Language file
import { common, explore, location } from "../../../../constants/constants";
import ImageWithCitation5 from "../../../../FunctionalComponents/SimpleGrid/ImageWithCitation5";
import Grid from "../../../../FunctionalComponents/SimpleGrid/Grid";
import { FilterBox } from "../../../../FunctionalComponents/Filter/FilterBox";

export default function DetailPage() {
  const [introData, setIntroData] = useState([]);
  const [journalDetails, setJournalDetails] = useState({});
  const [name, setName] = useState("");
  const { alias, monumentAlias } = useParams();
  const aliasAPI = alias;
  const currentEndpoint = window.location.pathname;
  const parts = currentEndpoint.split("/");
  const masterlistType = parts[1];

  useEffect(() => {
    const fetchData = async () => {
      const introductionResult = await getIntroductionData.introductionByAlias(
        "",
        aliasAPI
      );
      if (introductionResult) {
        setName(
          introductionResult[0]?.find((item) => item?.key === "name")?.value
        );
        setJournalDetails(
          introductionResult?.reduce((acc, it) => {
            acc.title = it?.find((item) => item?.key === "name")?.value;
            return acc;
          }, {})
        );
      }
      const landingResult = await getLandingMasterlistData.getLandingPageInfoByAlias(
        aliasAPI
      );
      if (landingResult) {
        setIntroData(
          landingResult?.map((it) => ({
            text: it.find((item) => item?.key === "landing_page_title").value,
            to: `/${masterlistType}/${it.find((item) => item?.key === "masterlist").value}/${it.find((item) => item?.key === "landing_page_abbreviation").value
              }`,
            title: it.find((item) => item?.key === "landing_page_abbreviation")
              .value,
          }))
        );
      }
    };
    fetchData();
  }, [aliasAPI, masterlistType]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container maxW={"1400px"} px="0px">
      <Box minH="100%" bg={useColorModeValue("white", "gray.900")}>
        <SidebarContent
          aliasAPI={aliasAPI}
          journalDetails={journalDetails}
          introData={introData}
          masterlistType={masterlistType}
          name={name}
        />
        <Box ml={{ base: 0, md: 52 }} bg="white">
          <DetailPageBody aliasAPI={aliasAPI} monumentAlias={monumentAlias} />
        </Box>
      </Box>
    </Container>
  );
}

const SidebarContent = ({
  aliasAPI,
  journalDetails,
  introData,
  masterlistType,
}) => {
  const navigate = useNavigate();
  const handleNavigation = () => {
    navigate(-1);
  };

  const sections = [
    {
      to: `/${masterlistType}/${aliasAPI}/1`,
      text: explore.POSTS,
    },
    {
      to: `/${masterlistType}/${aliasAPI}/2`,
      text: explore.INSIGHTS,
    },
  ];

  return (
    <>
      <BottomMainMenu />
      <CommonLeftSidebar
        sections={introData}
        oSections={sections}
        journalDetails={journalDetails}
        handleNavigation={handleNavigation}
        leftFlag={true}
      />
    </>
  );
};

const DetailPageBody = ({ aliasAPI, monumentAlias }) => {
  const [showFilterBox, setShowFilterBox] = useState(false);
  const [monumentData, setMonumentData] = useState([]);
  const [readMore, setReadMore] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure()
  const btnRef = React.useRef()
  const [clickedImageIndex, setClickedImageIndex] = useState(null);
  const [FullScreenImage, setFullScreenImage] = useState(false);
  const [ImgData, setImgData] = useState(null);
  const showImages = (image, flag) => {
    if (image) {
      setFullScreenImage(flag);
      setImgData(image);
    } else {
      setFullScreenImage(flag);
      setImgData(null);
    }
  };
  const handleImageClick = (item, index) => {
    setClickedImageIndex(index);
    setFullScreenImage(true);
  };

  useEffect(() => {
    const getData = async () => {
      try {
        const result = await getMonumentDetailData.getMonumentDataByAlias(
          "tableName",
          aliasAPI,
          monumentAlias

        );
        if (result) {
          setMonumentData(result[0]);
        }
      } catch (error) {
        console.error("Error fetching monument data:", error);
      }
    };

    getData();
    window.scrollTo(0, 0);
  }, [aliasAPI, monumentAlias]);

  const handleFilterClick = () => {
    setShowFilterBox(!showFilterBox);
  };
  const [filteroptionData, setFilteroptionsData] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [filterCount, setfilterCount] = useState(0);
  const [filterOptions, setFilterOptions] = useState({
    docType: [],
    phtographers_studio: [],
    year: [],
    medium:[],
    comissioned_by: [],
  });
  const [apply, setApply] = useState(false);
  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleCheckboxChange = (value, type) => {
    // Determine the array based on the type
    let selectedArray = [];
    switch (value) {
      case "docType":
        selectedArray = filterOptions.docType;
        break;
      case "phtographers_studio":
        selectedArray = filterOptions.phtographers_studio;
        break;
      case "year":
        selectedArray = filterOptions.year;
        break;
        case "medium":
        selectedArray = filterOptions.medium;
        break;
      case "comissioned_by":
        selectedArray = filterOptions.comissioned_by;
        break;
      default:
        break;
    }
    if (selectedArray.includes(type)) {
      selectedArray = selectedArray.filter((checkbox) => checkbox !== type);
    } else {
      selectedArray = [...selectedArray, type];
    }
    switch (value) {
      case "docType":
        setFilterOptions({
          ...filterOptions,
          docType: selectedArray,
        });
        break;
      case "phtographers_studio":
        setFilterOptions({
          ...filterOptions,
          phtographers_studio: selectedArray,
        });
        break;
      case "year":
        setFilterOptions({
          ...filterOptions,
          year: selectedArray,
        });
        break;
        case "medium":
        setFilterOptions({
          ...filterOptions,
          medium: selectedArray,
        });
        break;
        case "comissioned_by":
        setFilterOptions({
          ...filterOptions,
          comissioned_by: selectedArray,
        });
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    const getFilterOptionsData = async () => {
      const result = await getMonumentDetailData.getMonumentFilterOptions(
        "tableName",
        aliasAPI
      );
      if (result) {
        const dataIntro = result;
        setFilteroptionsData(dataIntro);
      }
    };

    getFilterOptionsData();
  }, [aliasAPI]);

  useEffect(() => {
    setfilterCount(0);
    for (const key in filterOptions) {
      if (filterOptions[key].length > 0) {
        setfilterCount((prev) => prev + 1);
      }
    }
  }, [filterOptions]);
  
  const handleReset=() => {
    setFilterOptions({
      docType: [],
      phtographers_studio: [],
      year: [],
      medium: [],
      comissioned_by: []
    });
    setSearchTerm("");
  }
  return (
    <>
      {/* Read More back navigation Remaining */}
      {readMore ? (<ReadMore />) :
        <>
          <Drawer
            isOpen={isOpen}
            onClose={onClose}
            finalFocusRef={btnRef}
            placement="right" id="rightsidebar">
            <DrawerOverlay />
            <DrawerContent>
              <DrawerCloseButton />
              <DrawerBody pt="12">
                <Accordion defaultIndex={[0]} allowMultiple>
                  <AccordionItem borderTopWidth={"0px"}>
                    <AccordionButton className="px-0">
                      <Box flex="1" textAlign="left">
                        <Text
                          fontSize={"16px"}
                          fontWeight={"700"}
                          lineHeight={"24px"}
                        >
                          {location.PATRON}
                        </Text>
                      </Box>
                      <AccordionIcon fontSize={"30px"} />
                    </AccordionButton>
                    <AccordionPanel className="px-0">
                      <OrderedList lineHeight={"16px"} fontSize={"14px"} fontWeight={"400"}>
                        {monumentData?.patron?.length > 0 &&
                          monumentData?.patron?.map((it, index) => (
                            <ListItem pb="4">{it.list_content}</ListItem>
                          ))}
                      </OrderedList>
                    </AccordionPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionButton className="px-0">
                      <Box flex="1" textAlign="left">
                        <Text
                          fontSize={"16px"}
                          fontWeight={"700"}
                          lineHeight={"24px"}
                        >
                          {location.STRUCTURE}
                        </Text>
                      </Box>
                      <AccordionIcon fontSize={"30px"} />
                    </AccordionButton>
                    <AccordionPanel className="px-0">
                      <OrderedList lineHeight={"16px"} fontSize={"14px"} fontWeight={"400"}>
                        {monumentData?.structure?.length > 0 &&
                          monumentData?.structure?.map((it, index) => (
                            <ListItem pb="4">{it.list_content}</ListItem>
                          ))}
                      </OrderedList>
                    </AccordionPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionButton className="px-0">
                      <Box flex="1" textAlign="left">
                        <Text
                          fontSize={"16px"}
                          fontWeight={"700"}
                          lineHeight={"24px"}
                        > {location.LAYOUT}</Text>
                      </Box>
                      <AccordionIcon fontSize={"30px"} />
                    </AccordionButton>
                    <AccordionPanel className="px-0">
                      <OrderedList lineHeight={"16px"} fontSize={"14px"} fontWeight={"400"}>
                        {monumentData?.layout?.length > 0 &&
                          monumentData?.layout?.map((it, index) => (
                            <ListItem pb="4">{it.list_content}</ListItem>
                          ))}
                      </OrderedList>
                    </AccordionPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionButton className="px-0">
                      <Box flex="1" textAlign="left">
                        <Text
                          fontSize={"16px"}
                          fontWeight={"700"}
                          lineHeight={"24px"}
                        > {location.MATERIALS_USED}</Text>
                      </Box>
                      <AccordionIcon fontSize={"30px"} />
                    </AccordionButton>
                    <AccordionPanel className="px-0">
                      <OrderedList lineHeight={"16px"} fontSize={"14px"} fontWeight={"400"}>
                        {monumentData?.materialsUsed?.length > 0 &&
                          monumentData?.materialsUsed?.map((it, index) => (
                            <ListItem pb="4">{it.list_content}</ListItem>
                          ))}
                      </OrderedList>
                    </AccordionPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionButton className="px-0">
                      <Box flex="1" textAlign="left">
                        <Text
                          fontSize={"16px"}
                          fontWeight={"700"}
                          lineHeight={"24px"}
                        > {location.EVENT}</Text>
                      </Box>
                      <AccordionIcon fontSize={"30px"} />
                    </AccordionButton>
                    <AccordionPanel className="px-0">
                      {monumentData?.event?.length > 0 &&
                        monumentData?.event?.map((it, index) => (
                          <Text fontSize={"14px"} fontWeight={"400"}>{it.list_content}</Text>
                        ))}
                    </AccordionPanel>
                  </AccordionItem>
                </Accordion>
              </DrawerBody>
            </DrawerContent>
          </Drawer>
          <Container maxW="auto" px={{ base: "4", md: "16" }} py={{ base: "4", md: "8" }} mx={"0"}>
            <Text pb="6" textAlign={"center"} color={"#333333"} fontSize={"24px"} fontWeight={"700"} lineHeight={"28px"} >
              {monumentData?.name}
            </Text>

            <HStack pb="3" justifyContent={"space-between"}>
              <Text color={"#333333"} textAlign={"center"} fontSize={"16px"} fontWeight={"700"} lineHeight={"18px"} >
                {monumentData?.comissioned_by}
              </Text>
              <Text color={"#333333"} textAlign={"center"} fontSize={"16px"} fontWeight={"700"} lineHeight={"18px"} >
                {monumentData?.architectural_material}
              </Text>
              <Text color={"#333333"} textAlign={"center"} fontSize={"16px"} fontWeight={"700"} lineHeight={"18px"} >
                {monumentData?.architectural_style}
              </Text>
            </HStack>
            <Text color={"#333333"} pb="3" textAlign={"left"} fontSize={"16px"} fontWeight={"500"} lineHeight={"20px"} >
              {monumentData?.description}
              <Text px="1" as={"span"} cursor={"pointer"} fontSize={"16px"} fontWeight={"700"} color={"#035DA1"} marginInlineStart={"0rem!important"}
                onClick={() => setReadMore(true)}
              >
                {common.READ_MORE}
              </Text>
            </Text>
            <Box>
              <Text color={"#333333"} width={"max-content"} _hover={{ color: "#035DA1" }} ref={btnRef} onClick={onOpen} py="6" textAlign={"left"} fontSize={"20px"} fontWeight={"700"} cursor={"pointer"} lineHeight={"24px"} >
                {location.OVERVIEW}
              </Text>
            </Box>
            <Stack>
              <Box>
                <Text color={"#333333"} textAlign={"left"} fontSize={"16px"} fontWeight={"700"} lineHeight={"18px"} >
                  {location.RELATED_ARTWORKS}
                </Text>
              </Box>
              <Stack flexDirection={"row-reverse"}>
                <FilterButton marginRight={"-63px"} onClick={handleFilterClick} />
                <Stack py="4" flexDirection={"row"} gap={4} justifyContent="center">
                  <HStack >
                    {monumentData?.gridImages?.length > 0 &&
                      <Grid
                        data={monumentData?.gridImages}
                        handleImageClick={handleImageClick}
                        columns={{ base: 2, md: 3, lg: 3, xl: 4 }}
                        spacing="40px"
                        component={ImageWithCitation5}
                      />
                    }
                  </HStack>
                  <HStack className="mt-0" alignItems={"flex-start"}>
                    {showFilterBox && (
                      <FilterBox
                      setShowFilterBox={setShowFilterBox}
                      setApply={setApply}
                      explore={explore}
                      handleCheckboxChange={handleCheckboxChange}
                      handleSearch={handleSearch}
                      filterOptions={filterOptions}
                      filteroptionData={filteroptionData}
                      setFilterOptions={setFilterOptions}
                      setSearchTerm={setSearchTerm}
                      filterCount={filterCount}
                      searchTerm={searchTerm}
                      handleReset={handleReset}
                      className="common-box-filter"
                      marginRight={"-65px!important"}
                      apply={apply}
                      />
                    )}
                  </HStack>
                </Stack>
                {FullScreenImage && <FullScreenView
                  isOpen={FullScreenImage}
                  onClose={() => showImages(null, false)}
                  ImgData={ImgData}
                  imageData={monumentData}
                  bannerFlag={true}
                  title="banner"
                  setReadMoreFlag={""}
                  clickedImageIndex={clickedImageIndex}
                  setClickedImageIndex={setClickedImageIndex}
                />}
              </Stack>
            </Stack>
          </Container>
        </>}
    </>
  );
};
