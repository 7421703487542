import {
  Box,
  CloseButton,
  Drawer,
  DrawerContent,
  Flex,
  HStack,
  IconButton,
  Image,
  Stack,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import { IoMdCalendar } from "@react-icons/all-files/io/IoMdCalendar";
import { MdMap } from "@react-icons/all-files/md/MdMap";
import { RiLayoutGridFill } from "@react-icons/all-files/ri/RiLayoutGridFill";
import React, { useState } from "react";
import { Link } from "react-router-dom";

//S3 Bucket osw-dev-images
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;

const HomeIcon = `${IMAGE_BASE_PATH}/ui/Chevron/home.png`
const member = `${IMAGE_BASE_PATH}/ui/Chevron/member.png`
const circle = `${IMAGE_BASE_PATH}/ui/Chevron/graycircle.png`

const ArtSteps = () => {
  return (
    <SidebarWithHeader>
      <Stack py={6} px={4}>
        <iframe
          width="100%"
          height="600px"
          src="https://www.artsteps.com/embed/64339fe1d658e5a33dc0d79d/560/315"
          frameborder="0"
          allowfullscreen
          title="Hidden URL"
        ></iframe>
      </Stack>
    </SidebarWithHeader>
  );
};

export default ArtSteps;

const SidebarWithHeader = ({ children }) => {
  const { isOpen, onClose } = useDisclosure();

  return (
    <Box minH="100vh" bg={useColorModeValue("gray.100", "gray.900")}>
      <SidebarContent
        onClose={() => onClose}
        display={{ base: "none", md: "block" }}
      />
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
      >
        <DrawerContent>
          <SidebarContent onClose={onClose} />
        </DrawerContent>
      </Drawer>
      <Box ml={{ base: 0, md: 14 }} bg="white">
        {children}
      </Box>
    </Box>
  );
};
const SidebarContent = ({ onClose, ...rest }) => {
  const [error, setError] = useState(false);
  const toggleModal = () => {
    setError(!error);
  };
  return (
    <Box
      transition="3s ease"
      bg={useColorModeValue("black", "gray.900")}
      // borderRight="1px"
      // borderRightColor={useColorModeValue("gray.200", "gray.700")}
      w={{ base: "full", md: 14 }}
      pos="fixed"
      h="full"
      {...rest}
      color={"white"}
    >
      <Flex
        h="20"
        alignItems="flex-end"
        m="6"
        justifyContent="space-between"
        flexDirection="row-reverse"
      >
        <CloseButton display={{ base: "flex", md: "none" }} onClick={onClose} />
      </Flex>
      <HStack justifyContent="center">
        <Link
          to="/"
          id="homeicon"
          _hover={{
            textDecoration: "none",
          }}
          py="4"
        >
          <Image
            filter={"opacity(0.34)"}
            _hover={{
              filter: "opacity(1)",
            }}
            src={HomeIcon}
            width="16px"
            height={"17px"}
          ></Image>
        </Link>
      </HStack>
      <HStack justifyContent="center">
        <Link to="/explore" id="exploreicon">
          <IconButton
            my="2"
            py="4"
            fontSize="22px"
            bg={"black"}
            color={"#4C4C4C"}
            _hover={{
              color: "white",
            }}
          >
            <RiLayoutGridFill />
          </IconButton>
        </Link>
      </HStack>
      <HStack justifyContent="center">
        <Link to="/calendar" id="calendaricon">
          <IconButton
            my="2"
            py="4"
            fontSize="24px
  
  "
            bg={"black"}
            color={"#4C4C4C"}
            _hover={{
              color: "white",
            }}
          >
            <IoMdCalendar />
          </IconButton>
        </Link>
      </HStack>
      <HStack
        justifyContent="space-around"
        borderLeft="4px"
        borderColor="#FF4E4E"
        borderRadius="0px"
      >
        <Link to="/map" id="mapicon">
          <IconButton
            my="6px"
            py="3"
            pr="2"
            fontSize="22px"
            bg={"black"}
            color={"white"}
            _hover={{
              color: "white",
            }}
          >
            <MdMap />
          </IconButton>
        </Link>
      </HStack>
      <HStack justifyContent="center">
        <Link my="5px" py="3" id="membericon">
          <Image
            src={member}
            onClick={toggleModal}
            width="auto"
            height={"100%"}
          ></Image>
        </Link>
      </HStack>
      <HStack justifyContent="center">
        <Link to="/osianamaworld" my="5px" py="3" id="osianamacircleicon">
          <Image
            src={circle}
            // onClick={toggleModal}
            width="auto"
            height={"100%"}
            px="1"
            pb="1"
          ></Image>
          {/* <Text
                fontSize={"10px"}
                fontWeight="700"
                textStyle={"secondary"}
                color={"#0066B3"}
                textAlign="center"
                id="HiddenText"
              >
                Subscribe
              </Text> */}
        </Link>
      </HStack>
    </Box>
  );
};
