import React from 'react';
import H1 from "../../Theme/Typography/H1";
import { Link } from "react-router-dom";
import { common } from "../../constants/constants";
import { Button, Container, Stack } from '@chakra-ui/react';
import Layout from '../../components/sections/SidebarLayout';


const RegisterDone = () => {
  return (
    <Layout>
    <Container py="10" maxW={"750px"} >
      <Stack alignItems={"center"}>
     <H1 textStyle={"primary"}>{common.THANK_YOU_REGISTRATION_MESSAGE}</H1>
      <Link to="/login">
        <Button px="40px" variant={"custom"}>
          <H1 fontSize={{base:"18px",md:"24px"}} cursor="pointer">{common.LOGIN}</H1>
          </Button>
      </Link>
      </Stack>
    </Container>
    </Layout>
  );
};

export default RegisterDone;