import React from 'react'
import { common } from '../constants/constants'
import Large from '../Theme/Typography/Large'

export default function NoMoreToLoad() {
  return (
    <>
    <Large fontSize="16px" fontWeight='400' py="2">
        {common.NO_MORE_ITEM_TO_LOAD}
    </Large>
    </>
  )
}
