export const data = [
  {
    alias: "cinema_education",
    rc_name: "1_CinemaEducation",
    masterlist: [
      {
        alias: "satyajit-ray",
        image: "path/to/image1.jpg",
        title: "Satyajit Ray",
        status: "compelete",
      },
      {
        alias: "shatranj-ke-khilari",
        image: "path/to/image1.jpg",
        title: "Shatranj Ke Khilari",
        status: "compelete",
      },
      {
        alias: "mera-naam-joker",
        image: "path/to/image1.jpg",
        title: "Mera Naam Joker",
        status: "compelete",
      },
      {
        alias: "mughal-e-azam",
        image: "path/to/image1.jpg",
        title: "Mughal-E-Azam",
        status: "compelete",
      },
      {
        alias: "raj-kapoor",
        image: "path/to/image1.jpg",
        title: "Raj Kapoor",
        status: "compelete",
      },
      {
        alias: "amitabh-bachchan",
        image: "path/to/image1.jpg",
        title: "Amitabh Bachchan",
        status: "compelete",
      },
      {
        alias: "waheeda-rehman",
        image: "path/to/image1.jpg",
        title: "Waheeda Rehman",
        status: "Inprogress",
      },
      {
        alias: "song-synopsis-booklet",
        image: "path/to/image1.jpg",
        title: "Song Synopsis Booklet",
        status: "Inprogress",
      },
      {
        alias: "9th-osians-film-festival",
        image: "path/to/image1.jpg",
        title: "9th Osian's Film Festival",
        status: "Inprogress",
      },
      {
        alias: "leonard-schrader-collection",
        image: "path/to/image1.jpg",
        title: "Leonard Schrader Collection",
        status: "Inprogress",
      },
      {
        alias: "akira-kurosawa",
        image: "path/to/image1.jpg",
        title: "Akira Kurosawa",
        status: "Inprogress",
      },
      {
        alias: "lon-chaney",
        image: "path/to/image1.jpg",
        title: "Lon Chaney",
        status: "Inprogress",
      },
      {
        alias: "tod-browning",
        image: "path/to/image1.jpg",
        title: "Tod Browning",
        status: "",
      },
      {
        alias: "girish-karnad",
        image: "path/to/image1.jpg",
        title: "Girish Karnad",
        status: "",
      },
      {
        alias: "shammi-kapoor",
        image: "path/to/image1.jpg",
        title: "Shammi Kapoor",
      },
      {
        alias: "alfred-hitchcock",
        image: "path/to/image1.jpg",
        title: "Alfred Hitchcock",
        status: "",
      },
    ],
  },
  {
    alias: "photography",
    rc_name: "3_Photography",
    masterlist: [
      {
        alias: "r-r-bharadwaj",
        image: "path/to/image1.jpg",
        title: "R R Bharadwaj",
        status: "",
      },
      {
        alias: "raghu-rai",
        image: "path/to/image1.jpg",
        title: "Raghu Rai",
        status: "",
      },
      {
        alias: "henri-cartier-bresson",
        image: "path/to/image1.jpg",
        title: "Henri-Cartier Bresson",
        status: "",
      },
      {
        alias: "samuel-bourne",
        image: "path/to/image1.jpg",
        title: "Samuel Bourne",
        status: "",
      },
      {
        alias: "photoink",
        image: "path/to/image1.jpg",
        title: "PhotoInk",
        status: "",
      },
      {
        alias: "sunil-jannah",
        image: "path/to/image1.jpg",
        title: "Sunil Jannah",
        status: "",
      },
      {
        alias: "studio-clifton-co",
        image: "path/to/image1.jpg",
        title: "Studio Clifton & Co",
        status: "",
      },
      {
        alias: "abaneri-kund",
        image: "path/to/image1.jpg",
        title: "Abaneri Kund",
        status: "",
      },
    ],
  },
  {
    alias: "travel",
    rc_name: "7_Travel",
    masterlist: [
      {
        alias: "christies",
        image: "path/to/image1.jpg",
        title: "Christie's",
        status: "",
      },
      {
        alias: "heart",
        image: "path/to/image1.jpg",
        title: "HEART",
        status: "",
      },
      {
        alias: "osians-connisseurs-of-arts",
        image: "path/to/image1.jpg",
        title: "Osian's Connisseurs of Arts",
        status: "",
      },
      {
        alias: "chester-davida-herwitz-collection",
        image: "path/to/image1.jpg",
        title: "The Chester and Davida Herwitz Collection",
        status: "",
      },
    ],
  },
  {
    alias: "scholarship",
    rc_name: "12_Scholarship",
    masterlist: [
      {
        alias: "journal-of-arts-ideas",
        image: "path/to/image1.jpg",
        title: "Journal of Arts & Ideas",
        status: "",
      },
      {
        alias: "art-heritage-annual",
        image: "path/to/image1.jpg",
        title: "Art Heritage Annual",
        status: "",
      },
      {
        alias: "sight-sound",
        image: "path/to/image1.jpg",
        title: "Sight & Sound",
        status: "",
      },
      {
        alias: "lalit-kala-contemporary-journal",
        image: "path/to/image1.jpg",
        title: "Lalit Kala Contemporary Journal",
        status: "",
      },
    ],
  },
];

export const bookmarks_data = [
  "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
  "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
  "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
  "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
  "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
  "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
];
