// Core Files
import { Box, Image, Stack } from "@chakra-ui/react";

//S3 Bucket osw-dev-images
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;

const closeIcon = `${IMAGE_BASE_PATH}/ui/Chevron/closeicon.svg`

function ContentModal({ contentClicked, setContentClicked ,width,contentImage,masterlistType}) {
  return (
    <Box position={"relative"} left={masterlistType==="search"?-4:-8}>
      <Box
        position={"fixed"}
        left={"auto"}
        right={{base:"0",md:"auto"}}
        top={"64px"}
        ml={(masterlistType==="search" || masterlistType === "generic" || masterlistType === "research-categories") ? "-30px":"1px"}
        zIndex={99999}
        background={"white"}
        width={width ? width :{base:"100%",md:(masterlistType === "search" || masterlistType === "generic" || masterlistType === "research-categories") ? "923px" : "774px"}}
        height={{base:"100%",md:"780px"}}
        borderRight={"1px"}
        borderColor={"color.lightgray4"}
      >
        <Image
          src={closeIcon}
          paddingLeft="5px"
          position="absolute"
          top={"12px"}
          right="15px"
          cursor={"pointer"}
          onClick={() => {
            setContentClicked(false);
          }}
        />
        <Stack
          justifyContent={"space-evenly"}
          width={"100%"}
          alignItems={"flex-start"}
          // marginTop={"64px"}
          flexDirection={"row"}
        >
          <Box width={"100%"} height={{base:"auto",md:"737px"}}>
          <Image src={contentImage} width={"100%"} height={"inherit"} objectFit={"contain"} />
          </Box>
        </Stack>
      </Box>
    </Box>
  );
}

export default ContentModal;
