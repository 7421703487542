import React from "react";
import Layout from "../components/sections/SidebarLayout";
import { Stack, Text, HStack } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import {  userProfile } from "../constants/constants";
// import ProfilePage from "./Profile/ProfilePage";
import { useSelector } from "react-redux";
import ProfilePagenew from "./Profile/ProfilePagenew";

function MyProfile() {
  const session = useSelector(state => state.session);
  return (
    <Layout>
      {session?.isAuthenticated ? (
          <Stack>
          <ProfilePagenew/>
          </Stack> 
      ) : (
          <HStack border={"1px"} borderColor="#e8e8e8" borderRadius={"2px"} p="1">
          <Text>{userProfile.LOGIN_INSTRUCTION}</Text>
          <Link to="/login">
            <Text color="blue.500" textDecoration="underline">
              {userProfile.CLICK_HERE}
            </Text>
          </Link>
        </HStack>
               
      )}
    </Layout>
  );
}

export default MyProfile;
