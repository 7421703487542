import { Box, Image, SimpleGrid, Tooltip } from "@chakra-ui/react";
import React from "react";
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;
const placeholder = `${IMAGE_BASE_PATH}/ui/EventExhibition/PictorialSpace/EconomicsofArts/placeholder.png`;

export default function MasterlistChessBoard({ data, handleSuccesCard }) {
  return (
    <Box boxShadow='xl' bg="white"  marginTop={"10px!important"}>
      {data.length > 0 ? (
        <SimpleGrid columns={8} spacing={0}>
          {Array.from({ length: 64 }, (_, index) => (
             <Tooltip
             key={index}
             label={data[index]?.name || ""}
             hasArrow
             placement="top"
           >
            <Box
              key={index}
              border={
                data[index]?.status === "compelete"
                  ? "1px solid green"
                  : data[index]?.status === "Inprogress"
                  ? "1px solid orange"
                  : "0px solid black"
              }
              w="100%"
              h={{base:"50px",md:"60px"}}
              zIndex={99}
              onClick={() => handleSuccesCard(data[index])}
              bg={index % 2 === Math.floor(index / 8) % 2 ? "black" : "white"}
            >
              {(data[index]?.image || data[index]?.image==="") && (
                <Image
                  cursor={"pointer"}
                  border={"1px solid black"}
                  src={data[index]?.image}
                  fallbackSrc={placeholder}
                  alt={`image-${index}`}
                  boxSize={{base:"100%",md:"69px"}}
                />
              )}
            </Box>
            </Tooltip>
          ))}
        </SimpleGrid>
      ) : (
        <SimpleGrid columns={8} spacing={0}>
          {Array.from({ length: 64 }, (_, index) => (
            <Box
              key={index}
              w="70px"
              h="60px"
              zIndex={99}
              bg={index % 2 === Math.floor(index / 8) % 2 ? "black" : "white"}
            ></Box>
          ))}
        </SimpleGrid>
      )}
    </Box>
  );
}
