import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Container,
  HStack,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  SimpleGrid,
  Stack,
  Tab,
  TabList,
  Tabs,
  Text,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";

//Core file
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { RiCloseCircleFill } from "@react-icons/all-files/ri/RiCloseCircleFill";
import { BiSearch } from "@react-icons/all-files/bi/BiSearch";

//Service file
import {
  getIntroductionByAlias,
  getMasterListsByResearchCategoryAlias,
} from "../../../../services/ResearchCategoryDetails";

//Component file
import LeftVmenu from "../../../../FunctionalComponents/LeftVmenu";
import { MdHomeFilled, MdMap } from "react-icons/md";
import { RiLayoutGridFill } from "@react-icons/all-files/ri/RiLayoutGridFill";
import { IoMdCalendar } from "react-icons/io";
// import { FilterButton } from "../../../../CommonComponents/FilterButton";
import SmallBold from "../../../../components/layouts/Typography/SmallBold";
import Small from "../../../../components/layouts/Typography/Small";

//Language file
import {
  button,
  common,
  explore,
  research_category,
} from "../../../../constants/constants";
import { DataNotFound } from "../../../../CommonComponents/DataNotFound";
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;
const loaderImg = `${IMAGE_BASE_PATH}/ui/Chevron/loader.gif`;

export default function RCMasterlists() {
  const [masterlistsData, setMasterlistsData] = useState([]);
  const [showFilterBox, setShowFilterBox] = useState(false);
  const [rcIntroduction, setRCIntroduction] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const { alias } = useParams();
  const navigate = useNavigate();
  const url = window.location.pathname.lastIndexOf("/");
  const mainUrl = window.location.pathname.slice(0, url);

  const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;
  const placeholderImg = `${IMAGE_BASE_PATH}/ui/DummyImage/small.png`;
  const getData = async () => {
    try {
      const result = await getMasterListsByResearchCategoryAlias("", alias);
      if (result) {
        setMasterlistsData(result);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getData();
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alias]);
  useEffect(() => {
    const getData = async () => {
      try {
        const result = await getIntroductionByAlias("", alias);
        if (result) {
          setRCIntroduction(result?.data[0]);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    getData();
  }, [alias]);
  // const handleFilterClick = () => {
  //   setShowFilterBox(!showFilterBox);
  // };
  return (
    <SidebarWithHeader>
      {!isLoading ? (
        <Stack>
          <Container maxW="auto" px="0" className="songs">
            <Box
             position={{base:"relative",md:"fixed"}}
              px={{ base: "4", md: "16" }}
              py={{ base: "4", md: "8" }}
              zIndex={999}
              bg="white"
              width={{base:"100%",md:"1344px"}}
            >
              <Box  display={{base:"flex",md:"none"}}>
                 <Text
                      textAlign={"left"}
                      width={{base:"100%",md:"max-content"}}
                      fontSize={{ base: "22px", md: "22px" }}
                      lineHeight={"28px"}
                      fontWeight={700}
                      cursor={"pointer"}
                    >
                      {rcIntroduction && rcIntroduction?.find((nestedItem) => nestedItem?.key === "name")?.value}
                      </Text>
                  </Box>
              <Tabs
                variant={"unstyled"}
                align="end"
                pos={"none"}
                alignItems={"center"}
                justifyContent={"space-between"}
                defaultIndex={-1}
                display={{base:"none",md:"flex"}}
              >
                <Box>
                  <Text
                    pos={"absolute"}
                    top={"23px"}
                    borderBottom={"4px"}
                    borderBottomColor={"black"}
                    textAlign={"left"}
                    width={{ base: "100%", md: "max-content" }}
                    fontSize={{ base: "22px", md: "22px" }}
                    fontWeight={700}
                    cursor={"pointer"}
                    onClick={() => navigate(`${mainUrl}/scrollToIntroduction`)}
                  >
                    {rcIntroduction &&
                      rcIntroduction?.find(
                        (nestedItem) => nestedItem?.key === "name"
                      )?.value}
                  </Text>
                </Box>
                <TabList gap={"25px"}>
                  <Tab
                    onClick={() => navigate(`${mainUrl}/scrollToIntroduction`)}
                    p="0px"
                    fontSize={"16"}
                    fontWeight={600}
                    _selected={{ borderBottom: "2px" }}
                  >
                    {research_category.INTRODUCTION_TAB_TITLE}
                  </Tab>
                  <Tab
                    onClick={() => navigate(`${mainUrl}/scrollToChronology`)}
                    p="0px"
                    fontSize={"16"}
                    fontWeight={600}
                    _selected={{ borderBottom: "2px" }}
                  >
                    {research_category.CHRONOLOGY_TAB_TITLE}
                  </Tab>
                  <Tab
                    onClick={() => navigate(`${mainUrl}/scrollToMasterlists`)}
                    p="0px"
                    fontSize={"16"}
                    fontWeight={600}
                    borderBottom="2px"
                    _selected={{ borderBottom: "2px" }}
                  >
                    {research_category.MASTERLISTS_TAB_TITLE}
                  </Tab>
                  <Tab
                    onClick={() => navigate(`${mainUrl}/scrollToPosts`)}
                    p="0px"
                    fontSize={"16"}
                    fontWeight={600}
                    _selected={{ borderBottom: "2px" }}
                  >
                    {research_category.POSTS_TAB_TITLE}
                  </Tab>
                  <Tab
                    onClick={() => navigate(`${mainUrl}/scrollToInsights`)}
                    p="0px"
                    fontSize={"16"}
                    fontWeight={600}
                    _selected={{ borderBottom: "2px" }}
                  >
                    {research_category.INSIGHTS_TAB_TITLE}
                  </Tab>
                  <Tab
                    onClick={() =>
                      navigate(`${mainUrl}/scrollToResearchCentre`)
                    }
                    p="0px"
                    fontSize={"16"}
                    fontWeight={600}
                    _selected={{ borderBottom: "2px" }}
                  >
                    {research_category.RESEARCH_CENTRE_TAB_TITLE}
                  </Tab>
                </TabList>
              </Tabs>
            </Box>
            <Stack paddingTop={{base:'2',md:"20"}} flexDirection={"row-reverse"}>
              {/* <FilterButton paddingTop={"8"} onClick={handleFilterClick} /> */}
              
                <HStack  px={{ base: "4", md: "16" }}
                marginY={{ base: "0", md: "10" }}
                flexDirection={"row"}
                gap={4}
                justifyContent="center">
                  <SimpleGrid
                    columns={{ base: 2, md: 3, lg: 3, xl: 4 }}
                    spacing={{base:"10px",md:"24px"}}
                  >
                    {masterlistsData?.data?.length > 0 ? (
                      masterlistsData?.data?.map((it, index) => {
                        return (
                          <>
                          <Stack display={{base:'none',md:'flex'}} className="content" key={index}>
                            <Link
                              to={
                                it.link
                                  ? it.link
                                  : `/${
                                      it?.find(
                                        (nestedItem) =>
                                          nestedItem?.key === "route_type"
                                      ).value !== ""
                                        ? `${
                                            it?.find(
                                              (nestedItem) =>
                                                nestedItem?.key === "route_type"
                                            ).value
                                          }`
                                        : `generic`
                                    }/${
                                      it?.find(
                                        (nestedItem) =>
                                          nestedItem?.key === "alias"
                                      ).value
                                    }/`
                              }
                            >
                              <Stack className="content-overlay"></Stack>
                              <Box
                                display={{base:"contents",md:"flex"}}
                                justifyContent={"center"}
                                width={{ base: "100%", md: "287px" }}
                                height={{base:"165px",md:"216px"}}
                              >
                                <Image
                                  src={
                                    it?.find(
                                      (nestedItem) =>
                                        nestedItem?.key === "image"
                                    ).value
                                  }
                                  className="content-image"
                                  height="100%"
                                  width="auto"
                                  objectFit={"contain"}
                                  fallbackSrc={placeholderImg}
                                />
                              </Box>
                              <Stack
                                className="content-details fadeIn-bottom"
                                alignItems={"center"}
                              >
                                <Text
                                  fontSize={"22px"}
                                  fontWeight={"700"}
                                  textStyle={"scondary"}
                                  color="white"
                                >
                                  {
                                    it?.find(
                                      (nestedItem) => nestedItem?.key === "name"
                                    ).value
                                  }
                                </Text>
                              </Stack>
                            </Link>
                          </Stack>

                          {/* For Mobile view */}
                          <Stack display={{base:'flex',md:'none'}} key={index}>
                            <Link
                              to={
                                it.link
                                  ? it.link
                                  : `/${
                                      it?.find(
                                        (nestedItem) =>
                                          nestedItem?.key === "route_type"
                                      ).value !== ""
                                        ? `${
                                            it?.find(
                                              (nestedItem) =>
                                                nestedItem?.key === "route_type"
                                            ).value
                                          }`
                                        : `generic`
                                    }/${
                                      it?.find(
                                        (nestedItem) =>
                                          nestedItem?.key === "alias"
                                      ).value
                                    }/`
                              }
                            >
                              <Box
                                width={{ base: "100%", md: "287px" }}
                                height={{base:"138px",md:"216px"}}
                                border={"1px"}
                                display={"flex"}
                                justifyContent={"center"}
                                borderColor={"#e8e8e8"}
                              >
                                <Image
                                  src={
                                    it?.find(
                                      (nestedItem) =>
                                        nestedItem?.key === "image"
                                    ).value
                                  }
                                  height="100%"
                                  width="auto"
                                  objectFit={"contain"}
                                  
                                  fallbackSrc={placeholderImg}
                                />
                              </Box>
                                <Text
                                py="1"
                                  fontSize={"14px"}
                                  fontWeight={"400"}
                                  textStyle={"scondary"}
                                  color="color.blue"
                                >
                                  {
                                    it?.find(
                                      (nestedItem) => nestedItem?.key === "name"
                                    ).value
                                  }
                                </Text>
                            </Link>
                          </Stack>
                          </>
                        );
                      })
                    ) : (
                      <Stack alignItems={"center"} height={"100vh"}>
                        <DataNotFound />
                      </Stack>
                    )}
                  </SimpleGrid>
                </HStack>
                <HStack
                  className="mt-0"
                  marginRight={"-15px!important"}
                  alignItems={"flex-start"}
                >
                  {showFilterBox && (
                    <Box
                      className="box-filterArtHeritage"
                      marginRight={"-51px!important"}
                    >
                      <Stack
                        flexDirection={"row"}
                        justifyContent={"space-between"}
                      >
                        <HStack>
                          <SmallBold as="u" color={"black"} cursor="pointer">
                            {button.CLEAR_ALL}
                          </SmallBold>
                        </HStack>
                        <HStack className="mt-0">
                          <RiCloseCircleFill
                            size={"22px"}
                            cursor="pointer"
                            onClick={(e) => setShowFilterBox(false)}
                          />
                        </HStack>
                      </Stack>
                      <HStack
                        alignItems={"left"}
                        flexDirection={"column"}
                        marginTop={"25px"}
                      >
                        <InputGroup className="ml-0" pb="4">
                          <Input
                            placeholder="Search"
                            height={"32px"}
                            pb={"4px"}
                            _placeholder={{
                              fontSize: "12px",
                              fontWeight: "400",
                            }}
                          />
                          <InputRightElement pb={"6px"}>
                            <BiSearch color="green.500" fontSize={"16px"} />
                          </InputRightElement>
                        </InputGroup>
                      </HStack>
                      <Accordion allowToggle>
                        <AccordionItem>
                          <AccordionButton className="px-0">
                            <Box flex="1" textAlign="left">
                              <Text
                                fontSize={"14px"}
                                fontWeight={"700"}
                                lineHeight={"24px"}
                              >
                                {research_category.STUDIO}
                              </Text>
                            </Box>
                            <AccordionIcon fontSize={"30px"} />
                          </AccordionButton>
                          <AccordionPanel pb={4} className="px-1">
                            <Small color="#035DA1">
                              {common.CONTENT_AWAITED}
                            </Small>
                          </AccordionPanel>
                        </AccordionItem>
                        <AccordionItem>
                          <AccordionButton className="px-0">
                            <Box flex="1" textAlign="left">
                              <Text
                                fontSize={"14px"}
                                fontWeight={"700"}
                                lineHeight={"24px"}
                              >
                                {research_category.CLASSIFICATIONS}
                              </Text>
                            </Box>
                            <AccordionIcon fontSize={"30px"} />
                          </AccordionButton>
                          <AccordionPanel pb={4} className="px-1">
                            <Small color="#035DA1">
                              {common.CONTENT_AWAITED}
                            </Small>
                          </AccordionPanel>
                        </AccordionItem>
                        <AccordionItem>
                          <AccordionButton className="px-0">
                            <Box flex="1" textAlign="left">
                              <Text
                                fontSize={"14px"}
                                fontWeight={"700"}
                                lineHeight={"24px"}
                              >
                                {research_category.DATE}
                              </Text>
                            </Box>
                            <AccordionIcon fontSize={"30px"} />
                          </AccordionButton>
                          <AccordionPanel pb={4} className="px-1">
                            <Small color="#035DA1">
                              {common.CONTENT_AWAITED}
                            </Small>
                          </AccordionPanel>
                        </AccordionItem>
                        <AccordionItem>
                          <AccordionButton className="px-0">
                            <Box flex="1" textAlign="left">
                              <Text
                                fontSize={"14px"}
                                fontWeight={"700"}
                                lineHeight={"24px"}
                              >
                                {research_category.MEDIUM}
                              </Text>
                            </Box>
                            <AccordionIcon fontSize={"30px"} />
                          </AccordionButton>
                          <AccordionPanel pb={4} className="px-1">
                            <Small color="#035DA1">
                              {common.CONTENT_AWAITED}
                            </Small>
                          </AccordionPanel>
                        </AccordionItem>
                        <AccordionItem>
                          <AccordionButton className="px-0">
                            <Box flex="1" textAlign="left">
                              <Text
                                fontSize={"14px"}
                                fontWeight={"700"}
                                lineHeight={"24px"}
                              >
                                {research_category.LOCATION}
                              </Text>
                            </Box>
                            <AccordionIcon fontSize={"30px"} />
                          </AccordionButton>
                          <AccordionPanel pb={4} className="px-1">
                            <AccordionPanel pb={4} className="px-1">
                              <Small color="#035DA1">
                                {common.CONTENT_AWAITED}
                              </Small>
                            </AccordionPanel>
                          </AccordionPanel>
                        </AccordionItem>
                        <HStack marginTop={"10px"}>
                          <Button
                            color={"#ffffff"}
                            width={"199px"}
                            height={"36px"}
                            bg={"#000000"}
                            fontSize={"16px"}
                            fontWeight={"400"}
                            variant={"unstyled"}
                          >
                            {explore.APPLY}
                          </Button>
                        </HStack>
                      </Accordion>
                    </Box>
                  )}
                </HStack>
            </Stack>
          </Container>
        </Stack>
      ) : (
        <HStack
          justifyContent={"center"}
          minH={"100vh"}
          alignItems={"flex-start"}
          mt={4}
        >
          <Image
            src={loaderImg}
            width={"100px"}
            height={"100px"}
            alt="Loading"
          />
        </HStack>
      )}
    </SidebarWithHeader>
  );
}
const SidebarWithHeader = ({ children }) => {
  const { onClose } = useDisclosure();
  return (
    <Container maxW="auto" px="0px">
      <Box minH="100vh" bg={useColorModeValue("gray.100", "gray.900")}>
        <SidebarContent
          onClose={() => onClose}
          display={{ base: "none", md: "block" }}
        />
        <Box ml={{ base: 0, md: 14 }} bg="white">
          {children}
        </Box>
      </Box>
    </Container>
  );
};

const SidebarContent = ({ onClose, display, ...rest }) => {
  const [error, setError] = useState(false);
  const toggleModal = () => {
    setError(!error);
  };
  const LeftVmenuData = [
    {
      link: "/",
      id: "homeicon",
      icon: MdHomeFilled,
    },
    {
      link: "/explore",
      id: "exploreicon",
      icon: RiLayoutGridFill,
    },
    {
      link: "/calendar",
      id: "calendaricon",
      icon: IoMdCalendar,
    },
    {
      link: "/map",
      id: "mapicon",
      icon: MdMap,
    },
  ];
  const navigate = useNavigate();
  const handleNavigation = () => {
    navigate(-1);
  };
  return (
    <LeftVmenu
      handleNavigation={handleNavigation}
      backArrow={true}
      display={display}
      onClose={onClose}
      toggleModal={toggleModal}
      LeftVmenuData={LeftVmenuData}
    />
  );
};
