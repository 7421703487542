import React from 'react';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { AiOutlinePicture } from '@react-icons/all-files/ai/AiOutlinePicture';
import { Box, HStack, Stack } from '@chakra-ui/react';
import { CopyPasteService } from '../../services/CopyPaste';
import H4 from '../../Theme/Typography/H4';

const TimelineWithSignatureImage = ({ data, showSignatureImages,handleImageClick ,textColor="color.gray",cursor="none" }) => {
    return (
        <VerticalTimeline
            layout={"1-column-left"}
            className="layout"
        >
            {data?.map((item, index) => (
                <>
                    {item?.images && (
                        <Box pos={"relative"} right={"20px"} top={"20px"}>
                            <AiOutlinePicture
                                onClick={() =>
                                    showSignatureImages(item?.images)
                                }
                                cursor={"pointer"}
                                color="#1E90FF"
                                size={16}
                            />
                        </Box>
                    )}
                    <VerticalTimelineElement
                        key={index}
                        id="timeline1"
                        contentStyle={{ fontSize: "sm" }}
                        iconStyle={{
                            background: "#fff",
                            color: "black",
                            fontSize: "16px",
                            fontWeight: "700",
                        }}
                        icon={item?.year}
                    >
                        <Stack
                            p="0"
                            flexDirection={"inherit"}
                            flexWrap="wrap"
                            gap="1"
                            onCopy={(e) =>
                                CopyPasteService(e, window.location.href)
                            }
                            cursor={cursor}
                            onClick={()=>handleImageClick && handleImageClick(item)}
                        >
                            <HStack>
                                <H4 color={textColor} lineHeight={"18.78px"} m="0">
                                    {item.value}
                                </H4>
                            </HStack>
                        </Stack>
                    </VerticalTimelineElement>
                </>
            ))}
        </VerticalTimeline>

    );
};

export default TimelineWithSignatureImage;
