//Core files
import React, { useEffect, useState } from "react";

//Language files
import { explore } from "../../../constants/constants";


import TabMain from "../../../FunctionalComponents/TabComponents/TabMain";
import Essay from "./Essay";
import { Exhibits } from "./Exhibits";
import { useLocation, useParams } from "react-router-dom";
import { Box, HStack, Stack} from "@chakra-ui/react";
import H3 from "../../../Theme/Typography/H3";
import * as getExhibitions from "../../../services/Journal";
import { BiUser } from "react-icons/bi";

function ExhibitionCatlouge({masterlistType, masterlist, artAlias, innerTab}) {

  const { childTab } = useParams();
  const [attr, setAttr] = useState(0)
  const [exhibitionTitle1, setExhibitionTitle1] = useState(null);
  const [exhibitionTitle2, setExhibitionTitle2] = useState(null);
  const [group, setGroup] = useState(null);

  const pathToIndex = {
    'essays': 0,
    'exhibits': 1
  };
  const location = useLocation();
  // const width = { base: "100%", md: "1160px" };

  useEffect(() => {
    setAttr(pathToIndex[childTab] ?? 0);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, attr]);


  async function fetchTitle() {
    try {
      const result = await getExhibitions.getExhibitionTitleAPI(masterlistType, masterlist, innerTab);
      const processedData = citationFactor(result?.data);
      setExhibitionTitle1(processedData[0]?.exhibitionTitle1);
      setExhibitionTitle2(processedData[0]?.exhibitionTitle2);
      setGroup(processedData[0]?.group);
    }
    catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  const citationFactor = (data) => {
    const findAndGetValue = (items, key, defaultValue = "") => 
      items.find(it => it.key === key)?.value || defaultValue;
  
    const formatTitle = (title, subTitle, datePublished) => {
      let formattedTitle = title;
      if (subTitle) {
        formattedTitle += `: ${subTitle}`;
      }
      if (datePublished) {
        formattedTitle += `, ${datePublished}`;
      }
      return formattedTitle;
    };
  
    const formatCreatorLocation = (creatorName, cityOfEvent) => {
      let formattedLocation = creatorName;
      if (cityOfEvent) {
        formattedLocation += `, ${cityOfEvent}`;
      }
      return formattedLocation;
    };
  
    return data.map(item => {
      const title = findAndGetValue(item, "title");
      const subTitle = findAndGetValue(item, "sub_title");
      const datePublished = findAndGetValue(item, "date_of_published_edition_in_library");
      const creatorName = findAndGetValue(item, "creator_name");
      const cityOfEvent = findAndGetValue(item, "city_of_event");
      const eventType = findAndGetValue(item, "type_of_event");
  
      return {
        exhibitionTitle1: formatTitle(title, subTitle, datePublished),
        exhibitionTitle2: formatCreatorLocation(creatorName, cityOfEvent),
        group: eventType
      };
    });
  };
  

  function formatData() {
    return [
      {
        name: `${explore.ESSAYS}`,
        link:`/object-book-jou/art-heritage-annual/agraphy/body-of-work/${artAlias}/${innerTab}/essays`,
        component: childTab === "essays" && <Essay masterlist={masterlist} masterlistType={masterlistType} artAlias={artAlias} innerTab={innerTab}/>,
      },
      {
        name: `${explore.EXHIBITS}`,
        link:`/object-book-jou/art-heritage-annual/agraphy/body-of-work/${artAlias}/${innerTab}/exhibits`,
        component: childTab === "exhibits" && <Exhibits masterlist={masterlist} masterlistType={masterlistType} artAlias={artAlias} innerTab={innerTab}/>,
      }
    ];
}

const [tabPanelData, setTabPanelData] = useState([])
  useEffect(()=>{
    if(masterlist){
      setTabPanelData(formatData())
    }
    fetchTitle();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[masterlist, childTab])

return (
  <>
  <Stack>
    <Box width={"1080px"} pos={"fixed"} pt="32px" bg="white" zIndex={99}> 
  <H3 pb="4" fontSize="18px" fontWeight="500">{explore.EXHIBITIONS}</H3>

  <HStack>
  {group && group === 'Group' ? (
    <BiUser
    color="black"
    display="inline"
    fontSize="16px"
    />
    ) : group === 'Triennale'?
    (
    <BiUser
        color="black"
        display="inline"
        fontSize="11px"
    />
    )
    : (
    <BiUser
        color="black"
        display="inline"
        fontSize="16px"
    />
    )} 
  <Box>
    <H3 fontWeight="500" color="color.blue">{exhibitionTitle1}</H3>
    <H3 fontWeight="500" color="color.blue">{exhibitionTitle2}</H3>
  </Box>
  </HStack>
  </Box>
  <TabMain  paddingTop="10px" tabTop={"170px"} gap="50px" width={"1055px"} pos={"fixed"}
  panelTop={{ base: "65px", sm: "85px", md: "175px" }} top="0px" topTogleIcon="125px" listId={"listgridbutton5"} data={tabPanelData}  attr={attr}/>
  </Stack>
  </>
);
}

export default ExhibitionCatlouge;