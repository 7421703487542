import { Text } from "@chakra-ui/react";
import React from "react";

const H2 = ({ children, fontWeight, lineHeight, textStyle, fontSize="20px", ...props }) => {
  return (
    <Text
      as="h2"
      {...props}
      fontSize={fontSize}
      fontWeight={fontWeight}
      lineHeight={lineHeight}
      textStyle={textStyle}
    >
      {children}
    </Text>
  );
};
export default React.memo(H2);
