import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Divider,
  Text,
} from "@chakra-ui/react";
import React from "react";

export default function Accordian({ data, marginLeft, maxH="auto",fontSize="16px", paddingRight}) {
  return (
    <>
      <Accordion className="mt-0" paddingRight={paddingRight} defaultIndex={[0]} allowMultiple>
        {data.length > 0 &&
          data.map((item, index) => (
            <AccordionItem border={"none"} key={index}>
              <AccordionButton className="px-0">
                <Box flex="1" textAlign="left">
                  <Text
                    fontSize={fontSize}
                    fontWeight={"700"}
                    lineHeight={"24px"}
                    color={"color.primary"}
                  >
                    {item.name}
                  </Text>
                </Box>
                <AccordionIcon width={"32px"} height={"32px"} />
              </AccordionButton>
              <Divider color={"color.lightgray7"} borderBottomWidth={"0.1px"} />
              <Box>
                <AccordionPanel className="accordian-filter-body-scrollbar px-0" py={2} maxH={maxH} overflowY={maxH!=="auto" ? "auto":"hidden"}>
                  {item.component}
                </AccordionPanel>
              </Box>
            </AccordionItem>
          ))}
      </Accordion>
    </>
  );
}
