import {combineReducers} from 'redux';
import userAccessSlice from './app/userAccess';

//import headerReducer from './app/header';
import authReducer from './app/login';
//import transactReducer from './app/transact';
//import profileReducer from './app/profile';

const staticReducers = {
  session: authReducer,
  userAccess:userAccessSlice,
};
const createReducer = asyncReducers => {
  return combineReducers({
    ...staticReducers,
    ...asyncReducers,
  });
};

export default createReducer;
