import {
  artworksExhibited,
  exhibitionCatalogue,
  exhibitionPhotographs,
} from "../MockData/Exhibitions";
import api from "../services/api";
import { APIS } from "../endpoints";

export const getExhibitionCatalogue = async () => {
  return exhibitionCatalogue;
};

export const getArtworksExhibited = async () => {
  return artworksExhibited;
};
export const getExhibitionPhotographs = async () => {
  return exhibitionPhotographs;
};

export const getExhibitionsData = async (masterlistType, masterlist) => {
  const result = await api.get(`${APIS.BASE_URL}${masterlistType}/${masterlist}/${APIS.exhibitions}`)
  if (result) {
    return result?.data?.data;
  } else {
    return [];
  }
};


export const getEssayDataByExhibition = async (masterlistType, masterlist, alias) => {
  const response= await getEssayDataByExhibitionAPI(masterlistType, masterlist, alias);
  return response;
};

export const getEssayDataByExhibitionAPI = async (masterlistType, masterlist, alias) => {
  const result = await api.get(`${APIS.BASE_URL}${masterlistType}/${masterlist}/${APIS.exhibitions}/${alias}/essays`)

  if (result) {
    return result?.data?.data;
  } else {
    return [];
  }
};

export const getExhibitsDataByExhibition = async (masterlistType, masterlist, filterOptions, alias, page) => {
  const response= await getExhibitsDataByExhibitionAPI(masterlistType, masterlist, filterOptions, alias, page);
  return response;
};

export const getExhibitsDataByExhibitionAPI = async (masterlistType, masterlist, filterOptions, alias, page) => {
  const formData = new FormData();
  formData.append('filters', JSON.stringify(filterOptions));
  formData.append('page_number', page);
  const params = new URLSearchParams(formData);
    
  const result = await api.get(`${APIS.BASE_URL}${masterlistType}/${masterlist}/${APIS.exhibitions}/${alias}/exhibits`, {
    params: params
  })

  if (result) {
    return result?.data;
  } else {
    return [];
  }
};
