import { useEffect, useState } from "react";
import {  useLocation, useNavigate, useParams } from "react-router-dom";
import { explore } from "../../../constants/constants";
import { All } from "./All";
import { Box, Container, HStack, Image, Stack } from "@chakra-ui/react";
// import ReadMore from "../../../pages/Film/ReadMore";
// import { BookDetails } from "../../../pages/PersonalityArtist/ResearchCentre/BookDetails";
import TabMain from "../../../FunctionalComponents/TabComponents/TabMain";
import { Library } from "./Library";
import { getImageURL } from "../../../util/getImage";
import { Archive } from "./Archive";
import * as getresearchCentreAll from "../../../services/Search";
import { BookDetails } from "../../../components/LibraryBook/BookDetails";
import { Highlights } from "./Highlights";

export const LibraryAndArchive = ({
    masterlist,
    SearchTerm,
    setResearchCentreFlag,
    bookFlag,
    setBookFlag,
    setleftFlag,
    bookReadmore,
    setBookReadmore,
    masterlistType,
    marginX="0px",
    width="1160px",
    name,
    listId,
    top,
    right,
    className,
    allCount, setAllCount
  }) => {
    const [visible, setVisible] = useState(true);
    const [listGridIcon, setListGridIcon] = useState(true);
    const navigate = useNavigate();
    const [openDrawer, setOpenDrawer] = useState(false);

    let { tabIndex, bookAlias,gridview } = useParams();
    let {tabValue,bookDetails}=useParams();
    const [attr, setAttr] = useState(1)
    const [libraryCount, setLibraryCount] = useState(0)
    const [archiveCount, setArchiveCount] = useState(0)
    const [highlightCount, setHighlightCount] = useState(0)

    // const width = { base: "100%", md: "1160px" };
    const pathToIndex = {
      'all': 0,
      'library': 1,
      'archive': 2,
      'highlights':3,
  
    };
    const location = useLocation();
    // const width = { base: "100%", md: "1160px" };
  
    useEffect(() => {
      setAttr(pathToIndex[tabValue] ?? 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location, attr]);
    /* Need to check after dynamic book data */
  
  
    useEffect(() => {
      if (tabIndex) {
        navigate(`/${masterlistType}/${masterlist}/research-centre`, { replace: true });
  
  
        if (parseInt(tabIndex) === 1) {
          setListGridIcon(true);
        }
        if (parseInt(gridview) === 0) {
          setVisible(false);
        }
        if (parseInt(gridview) === 1) {
          setVisible(true);
        }
      }
    }, [tabIndex, navigate, gridview, masterlistType, masterlist]);
  
    const handleBookClick = (flag, data) => {
      navigate(`bookDetails?query=${encodeURIComponent(SearchTerm)}`)
      setBookFlag(flag);
      setResearchCentreFlag(false);
    };
    async function fetchData() {
      try {
        const  allResult = await getresearchCentreAll.searchResearchAllTab(SearchTerm,{},1);
        const  libraryResult = await getresearchCentreAll.searchResearchLibraryTab(SearchTerm,{},1);
        const  archiveResult = await getresearchCentreAll.searchResearchArchiveTab(SearchTerm,{},1);
        const  highlightResult = await getresearchCentreAll.searchResearchHighlightTab(SearchTerm,{},1);

        if (allResult && allResult?.data.length>0 ) {
        setAllCount(allResult?.total)
      }
      if (libraryResult && libraryResult?.data.length>0 ) {
        setLibraryCount(libraryResult?.total)
      }
      if (archiveResult && archiveResult?.data.length>0 ) {
        setArchiveCount(archiveResult?.total)
      }
      if (highlightResult && highlightResult?.data.length>0 ) {
        setHighlightCount(highlightResult?.total)
      }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
    const [tabPanelData, setTabPanelData] = useState([]);
    function formatData() {
      if(tabValue==="all" || tabValue==="highlights"){
        setListGridIcon(false)
      }
      return [
        {
          name: `${explore.ALL}`,
          count:`${allCount}`,
          component:tabValue==="all" && <All 
          openDrawer={openDrawer}
          setOpenDrawer={setOpenDrawer}
          SearchTerm={SearchTerm}
          visible={visible} masterlistType={masterlistType} masterlist={masterlist} setAllCount={setAllCount} handleBookClick={handleBookClick}/>,
          list:false,
          link:`/search/research-centre/all?query=${encodeURIComponent(SearchTerm)}`
        },
        {
          name: `${explore.LIBRARY}`,
          count:`${libraryCount}`,
          component:tabValue==="library" && <Library
          visible={visible}
          handleBookClick={handleBookClick}
          openDrawer={openDrawer}
          setOpenDrawer={setOpenDrawer}
          setLibraryCount={setLibraryCount}
          setleftFlag={setleftFlag}
          tableName={"bibliographies"}
          masterlist={masterlist}
          SearchTerm={SearchTerm}
        />,
        list:true,
        link:`/search/research-centre/library?query=${encodeURIComponent(SearchTerm)}`
      },
        {
          name: `${explore.ARCHIVE}`,
          count:`${archiveCount}`,
          component:tabValue==="archive" && <Archive SearchTerm={SearchTerm} visible={visible} masterlistType={masterlistType} masterlist={masterlist} setArchiveCount={setArchiveCount}
  />,
          list:true,
          link:`/search/research-centre/archive?query=${encodeURIComponent(SearchTerm)}`
        },
        {
          name: `${explore.HIGHLIGHTS}`,
          count:`${highlightCount}`,
          component:tabValue==="highlights" && <Highlights 
          openDrawer={openDrawer}
          setOpenDrawer={setOpenDrawer}
          SearchTerm={SearchTerm}
          visible={visible} masterlistType={masterlistType} masterlist={masterlist} setHighlightCount={setHighlightCount} handleBookClick={handleBookClick}/>,
          list:false,
          link:`/search/research-centre/highlights?query=${encodeURIComponent(SearchTerm)}`
        },
  
      ];
    }
    useEffect(() => {
      if (masterlist) {
        setTabPanelData(formatData());
        fetchData()
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [masterlist,visible,openDrawer,bookAlias,archiveCount,SearchTerm,libraryCount,allCount,highlightCount,tabValue]);
  
    const tabHandle=(item)=>{
      setListGridIcon(item)
    }
    return (
      <Stack position={"relative"} className="mt-0" width={"100%"}>
        <Container minW={"100%"} pb="4" px={{base:"0px",md:"0px"}} marginX={marginX}>
          <Stack>
          {name && <Box
            position={"fixed"}
            zIndex={999}
            bg="white"
            width={{ base: "100%", md: "1150px" }}
          >
            <HStack paddingTop={{ base: "20px", md: "30px" }} paddingBottom={{ base: "20px", md: "14px" }}>
              <Box paddingRight={{base:"4",md:""}} width={{ base: "100%", md: "656px" }}>
                <Image src={getImageURL(name)} width={"100%"} height={"60px"}></Image>
              </Box>
            </HStack>
          </Box>}

          {bookDetails ? (
              <>
                  <BookDetails/>
               
              </>
            ) : (
              <HStack position={"relative"}>
                {tabPanelData && tabPanelData.length > 0 && (
             <TabMain top="0px" paddingTop={{base:"8",md:"2"}} gap={{base:"30px",md:"50px"}} width={{base:"100%",md:"1245px"}} pos={name && "fixed"} tabTop={name && { base: "55px", sm: "75px", md: "100px" }} 
             panelTop={name && { base: "65px", sm: "85px", md: "140px" }} topTogleIcon="0px" data={tabPanelData} listId={"listgridbutton4"} right={right} listGridIcon={listGridIcon} tabHandle={tabHandle} attr={attr} setAttr={setAttr} setVisible={setVisible} visible={visible}/>
            )}

              </HStack>
            )}
          </Stack>
        </Container>
      </Stack>
    );
  };
  