import {
  Box,
  Container,
  HStack,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";

//Core files
import { Swiper, SwiperSlide } from "swiper/react";
import {
  EffectCreative,
  EffectFade,
  EffectFlip,
  Navigation,
  Pagination,
} from "swiper";
import { isArray } from "lodash";

//Component files
import H3 from "../../../components/layouts/Typography/H3";
import { common } from "../../../constants/constants";

//S3 Bucket osw-dev-images
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;
const placeholderImg = `${IMAGE_BASE_PATH}/ui/DummyImage/small.png`
const closeIcon = `${IMAGE_BASE_PATH}/ui/Chevron/closeicon.svg`

export const FullScreenImage = ({
  isOpen,
  onClose,
  clickedImageIndex,
  data,
  setReadMoreFlag,
  bannerFlag,
  title,
}) => {
  return (
    <Container maxW={"auto"} px="0px" >
      <Modal
        size={"full"}
        isCentered
        isOpen={isOpen}
        onClick={onClose}
        Id="modalfullscreen"
        motionPreset="none"
      >
        <ModalOverlay />
        <ModalContent zIndex={"999"} >
          <ModalBody margin={{ base: "0", md: "8" }}>
            <Box
              bg={"white"}
              justifyContent={"space-between"}
              position={"relative"}
            >
              <Stack >
                <Swiper
                  effect={{ sm: "fade", md: "creative" }}
                  slidesPerView={1}
                  navigation
                  initialSlide={clickedImageIndex}
                  // loop={true}
                  id="allsliderskk1"
                  modules={[EffectCreative, EffectFade, Navigation, Pagination]}
                >
                  {data?.length > 0 &&
                    data?.map((item, index) => {
                      return (
                        <SwiperSlide key={index + 1}>
                          <Stack
                            maxW={"1230px"}
                            marginTop={{ base: "250px", md: "0" }}
                            flexDirection={{ base: "column", md: "row" }}
                            className="row"
                            alignItems={"flex-start"}
                            gap={"0"}
                            // overflowY={{ base: "scroll", md: "hidden" }}
                          >
                            <HStack
                              className="col-sm-12 col-md-7"
                              justifyContent={"center"}
                              border={"0.01rem solid #d7d7d7"}
                              borderRadius={"5px"}
                              background={"white"}
                              height={title==="ArtworksCatalogues" && "690px"}
                              alignItems={title==="ArtworksCatalogues" && "flex-start"}
                            >
                              <Stack mb={4}>
                                <Box
                                  display={"block"}
                                  justifyContent={"center"}
                                  width={"619px"}
                                  minHeight={{ base: "auto", md: "570px" }}
                                >
                                  {isArray(item?.fullImg) &&
                                  item?.fullImg.length > 0 ? (
                                    <Swiper
                                      grabCursor={true}
                                      navigation={true}
                                      modules={[
                                        EffectFlip,
                                        Navigation,
                                        Pagination,
                                      ]}
                                    >
                                      {item?.fullImg?.map((it, ind) => (
                                        <>
                                          <SwiperSlide key={ind + 1}>
                                            <VStack>
                                              <Stack
                                                justifyContent={"center"}
                                                width={{
                                                  base: "auto",
                                                  md: "100%",
                                                }}
                                                height={{
                                                  base: "275px",
                                                  md: "100%",
                                                }}
                                              >
                                                <Image
                                                  src={
                                                    it?.image
                                                  }
                                                  fallbackSrc={placeholderImg}
                                                  width={"100%"}
                                                  height={"inherit"}
                                                  objectFit={"contain"}
                                                  alt=""
                                                />
                                              </Stack>
                                              <HStack>
                                                <Text
                                                  fontSize={"16px"}
                                                  color={"#035DA1"}
                                                >
                                                  {`${it?.value} | ${it?.imageName} | ${it?.medium_of_artwork} | ${it?.year}`}
                                                </Text>
                                              </HStack>
                                            </VStack>
                                          </SwiperSlide>
                                        </>
                                      ))}
                                    </Swiper>
                                  ) : (
                                    <>
                                      <Image
                                        src={item?.fullImg}
                                        width={"100%"}
                                        height={"inherit"}
                                        objectFit={"contain"}
                                        alt=""
                                      />
                                      {item?.auction_estimates && (
                                        <HStack flexDirection={"row"} pt={4}>
                                          {item?.auction_estimates.length > 0 &&
                                            item?.auction_estimates.map(
                                              (it) => (
                                                <Stack pl={"32px"}>
                                                  <Text>{it?.display}</Text>
                                                  <H3>{it?.value_in_inr}</H3>
                                                  <Text>{it?.valuein_dollar}</Text>
                                                </Stack>
                                              )
                                            )}
                                        </HStack>
                                      )}
                                    </>
                                  )}
                                </Box>
                              </Stack>
                            </HStack>
                            <HStack
                              alignItems={"flex-start"}
                              className="col-sm-12 col-md-5"
                              flexDirection={"column"}
                              pl={"50px"}
                            >
                              {item?.title && <Box
                                paddingBottom={"10px"}
                                width={"100%"}
                                borderBottom={"1px"}
                                borderColor={"#e8e8e8"}
                              >
                                <Text
                                  fontSize={"20px"}
                                  fontWeight={"700"}
                                  // color="#0066B3"
                                  color={"#035DA1"}
                                >
                                  {item?.title}
                                </Text>
                              </Box>}
                              <Stack
                                pb="4"
                                marginInlineStart={"0rem!important"}
                                borderBottom={"1px solid #e8e8e8"}
                                width={"100%"}
                                gap={0}
                              >
                                {item.essayistData.length > 0 &&
                                  item.essayistData.map((it) => (
                                    <Stack
                                      marginInlineStart={"0rem!important"}
                                      py="1"
                                      gap={"0"}
                                      className="mt-0 px-0"
                                      flexDirection={"column"}

                                    >
                                      <HStack className="mt-0 px-0">
                                        <Text
                                          fontSize={"14px"}
                                          fontWeight={"700"}
                                          color="black"
                                        >
                                          {it.display}
                                        </Text>
                                      </HStack>
                                      {it.value && (
                                        <HStack className="mt-0">
                                          <Text
                                            fontSize={"14px"}
                                            fontWeight={"400"}
                                            // color="#035DA1"
                                          >
                                            {`${it.value}`}
                                          </Text>
                                        </HStack>
                                      )}
                                    </Stack>
                                  ))}
                              </Stack>
                              {item.auctionData && (
                                <Stack
                                  pb="4"
                                  marginInlineStart={"0rem!important"}
                                  borderBottom={"1px solid #e8e8e8"}
                                  width={"100%"}
                                >
                                  {item.auctionData.length > 0 &&
                                    item.auctionData.map((it) => (
                                      <Stack
                                        marginInlineStart={"0rem!important"}
                                        py="1"
                                        gap={"2"}
                                        className="mt-0 px-0"
                                      >
                                        <HStack className="mt-0 px-0">
                                          <Text
                                            fontSize={"14px"}
                                            fontWeight={"700"}
                                            color="black"
                                          >
                                            {it.display}
                                          </Text>
                                        </HStack>
                                        {it.value && (
                                          <HStack className="mt-0">
                                            <Text
                                              fontSize={"14px"}
                                              fontWeight={"400"}
                                              // color="#035DA1"
                                            >
                                              {it.value}
                                            </Text>
                                          </HStack>
                                        )}
                                      </Stack>
                                    ))}
                                </Stack>
                              )}
                              <Box
                                py="2"
                                className="ml-0"
                                alignItems={"flex-start"}
                                flexDirection={"column"}
                              >
                                {isArray(item.content) ? (
                                  item.content.map((it) => (
                                    <Stack
                                      marginInlineStart={"0rem!important"}
                                      py="1"
                                      gap={"2"}
                                      className="mt-0 px-0"
                                    >
                                      <HStack className="mt-0 px-0">
                                        <Text
                                          fontSize={"14px"}
                                          fontWeight={"700"}
                                          color="black"
                                        >
                                          {it.display}
                                        </Text>
                                      </HStack>
                                      {it.value && (
                                        <HStack className="mt-0">
                                          <Text
                                            fontSize={"14px"}
                                            fontWeight={"400"}
                                          >
                                            {it.value}
                                          </Text>
                                        </HStack>
                                      )}
                                    </Stack>
                                  ))
                                ) : (
                                  <Text>{item?.content}</Text>
                                )}
                                <Text
                                  pt="4"
                                  marginInlineStart={"0px!important"}
                                  fontSize={"14px"}
                                  fontWeight={"700"}
                                  color="#035DA1"
                                  cursor={"pointer"}
                                  onClick={() => {
                                    onClose();
                                    setReadMoreFlag(
                                      true,
                                      isOpen,
                                      index,
                                      bannerFlag,
                                      title
                                    );
                                  }}
                                >
                                  {common.READ_MORE}
                                </Text>
                              </Box>
                            </HStack>
                          </Stack>
                        </SwiperSlide>
                      );
                    })}
                </Swiper>
                <HStack>
                  <Image
                    className="eventExhibitclosebtn"
                    src={closeIcon}
                    onClick={() => {
                      onClose();
                    }}
                    zIndex={99999}
                    cursor={"pointer"}
                  ></Image>
                </HStack>
              </Stack>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Container>
  );
};
