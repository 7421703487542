import { Text } from "@chakra-ui/react";
import React from "react";

const Small = ({ children, textStyle="primary",fontWeight="400",lineHeight="14px",  ...props }) => {
  return (
    <Text
      as="p"
      {...props}
      fontSize="12px!important"
      fontWeight={fontWeight}
      lineHeight={lineHeight}
      textStyle={textStyle}
    >
      {children}
    </Text>
  );
};
export default React.memo(Small);
