//S3 Bucket osw-dev-images
// const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;

// const Img1 = `${IMAGE_BASE_PATH}/ui/PersonalityPolymath/SatyajitRay/EconomicsOfCinema/AwardsnHonours/1.jpeg`
export const satyajit_awardsData=[
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "award_name",
            value: "Best Human Document"
        },
        {
            key: "id",
            value: "dedbb065-5327-4a68-be84-39a0d72851bd"
        },
        {
            key: "issuing_authority",
            value: "9th Cannes Film Festival"
        },
        {
            key: "sortable_date",
            value: "1956"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "satyajit-ray"
        },
        {
            key: "awardcategory",
            value: []
        },
        {
            key: "awardImage",
            value: ["https://s3-alpha-sig.figma.com/img/1c5e/67c7/69b1738653067f99cd1852fe52717a80?Expires=1716163200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=TXrzenY5svElE3bx1cKSoOEYii9TfIX1UVwtR-UNhZBLzdY6Gdj0i8ic6uVXJZFpR423671SfhaOvVTslPQM4k3xF0XKhKo0ZtBxqnkgIkxrY9KdSs46u55enCtfkgTfJjRNIijVqJDc7uR9iCiNtP3FrIgn2vubLx31VwwNZFfYzH3AEMRfeBONkzpwsFENGRMyxXm0C5nudySNwcESaVfI0yhdHAOi0leQ5CZ9fyQqG5mEQOpfvBqY4oc9lpAqL~ie4N-bE4maY5HTbFFYDnhWSVugBTSj19Fv5iPKvFTEgQM9PjkDXgChZ-OfS5nQ~X~-cMSfnF-PcafPdqREyg__"]
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "award_name",
            value: "OCIC Catholic Award"
        },
        {
            key: "id",
            value: "1fda84d9-a835-4fb3-872d-3e1e014fcc5a"
        },
        {
            key: "issuing_authority",
            value: "International Catholic Organization for Cinema and Audiovisual | 9th Cannes Film Festival"
        },
        {
            key: "sortable_date",
            value: "1956"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "satyajit-ray"
        },
        {
            key: "awardcategory",
            value: []
        },
        {
            key: "awardImage",
            value: ["https://s3-alpha-sig.figma.com/img/1c5e/67c7/69b1738653067f99cd1852fe52717a80?Expires=1716163200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=TXrzenY5svElE3bx1cKSoOEYii9TfIX1UVwtR-UNhZBLzdY6Gdj0i8ic6uVXJZFpR423671SfhaOvVTslPQM4k3xF0XKhKo0ZtBxqnkgIkxrY9KdSs46u55enCtfkgTfJjRNIijVqJDc7uR9iCiNtP3FrIgn2vubLx31VwwNZFfYzH3AEMRfeBONkzpwsFENGRMyxXm0C5nudySNwcESaVfI0yhdHAOi0leQ5CZ9fyQqG5mEQOpfvBqY4oc9lpAqL~ie4N-bE4maY5HTbFFYDnhWSVugBTSj19Fv5iPKvFTEgQM9PjkDXgChZ-OfS5nQ~X~-cMSfnF-PcafPdqREyg__"]
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "award_name",
            value: "Palme d'Or, Nominated"
        },
        {
            key: "id",
            value: "74301f98-ad67-41d3-980d-36852713eaaf"
        },
        {
            key: "issuing_authority",
            value: "9th Cannes Film Festival"
        },
        {
            key: "sortable_date",
            value: "1956"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "satyajit-ray"
        },
        {
            key: "awardcategory",
            value: []
        },
        {
            key: "awardImage",
            value: []
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "award_name",
            value: "Vatican Award"
        },
        {
            key: "id",
            value: "8dbd463a-bb19-4265-ad83-0a81d15f6bcf"
        },
        {
            key: "issuing_authority",
            value: "Rome Film Festival"
        },
        {
            key: "sortable_date",
            value: "1956"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "satyajit-ray"
        },
        {
            key: "awardcategory",
            value: []
        },
        {
            key: "awardImage",
            value: []
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "award_name",
            value: "Diploma Of Merit"
        },
        {
            key: "id",
            value: "827dfc97-3f42-448e-be07-16ac90954df8"
        },
        {
            key: "issuing_authority",
            value: "9th Edinburgh International Film Festival"
        },
        {
            key: "sortable_date",
            value: "1956"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "satyajit-ray"
        },
        {
            key: "awardcategory",
            value: []
        },
        {
            key: "awardImage",
            value: []
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "award_name",
            value: "Golden Carbao"
        },
        {
            key: "id",
            value: "bb3079bf-ef11-48b1-a49d-a8922e6927c3"
        },
        {
            key: "issuing_authority",
            value: "Manila Film Festival"
        },
        {
            key: "sortable_date",
            value: "1956"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "satyajit-ray"
        },
        {
            key: "awardcategory",
            value: []
        },
        {
            key: "awardImage",
            value: []
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "award_name",
            value: "Golden Gate Award"
        },
        {
            key: "id",
            value: "c049e478-fab5-4372-8f28-b9a03234d31c"
        },
        {
            key: "issuing_authority",
            value: "1st San Francisco International Film Festival "
        },
        {
            key: "sortable_date",
            value: "1956"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "satyajit-ray"
        },
        {
            key: "awardcategory",
            value: [
                {
                    key: null,
                    link: "Best Film"
                }
            ]
        },
        {
            key: "awardImage",
            value: ["https://s3-alpha-sig.figma.com/img/1c5e/67c7/69b1738653067f99cd1852fe52717a80?Expires=1716163200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=TXrzenY5svElE3bx1cKSoOEYii9TfIX1UVwtR-UNhZBLzdY6Gdj0i8ic6uVXJZFpR423671SfhaOvVTslPQM4k3xF0XKhKo0ZtBxqnkgIkxrY9KdSs46u55enCtfkgTfJjRNIijVqJDc7uR9iCiNtP3FrIgn2vubLx31VwwNZFfYzH3AEMRfeBONkzpwsFENGRMyxXm0C5nudySNwcESaVfI0yhdHAOi0leQ5CZ9fyQqG5mEQOpfvBqY4oc9lpAqL~ie4N-bE4maY5HTbFFYDnhWSVugBTSj19Fv5iPKvFTEgQM9PjkDXgChZ-OfS5nQ~X~-cMSfnF-PcafPdqREyg__"]
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "award_name",
            value: "Golden Gate Award"
        },
        {
            key: "id",
            value: "2650b3c7-2267-4089-9f3c-a7f3634ff547"
        },
        {
            key: "issuing_authority",
            value: "1st San Francisco International Film Festival"
        },
        {
            key: "sortable_date",
            value: "1956"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "satyajit-ray"
        },
        {
            key: "awardcategory",
            value: [
                {
                    key: null,
                    link: "Best Direction"
                }
            ]
        },
        {
            key: "awardImage",
            value: []
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "award_name",
            value: "Selznick Golden Laurel"
        },
        {
            key: "id",
            value: "2ec8dae3-4940-40a1-bd65-1b75c4e7d92d"
        },
        {
            key: "issuing_authority",
            value: "7th Berlin International Film Festival"
        },
        {
            key: "sortable_date",
            value: "1957"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "satyajit-ray"
        },
        {
            key: "awardcategory",
            value: [
                {
                    key: null,
                    link: "Best Film"
                }
            ]
        },
        {
            key: "awardImage",
            value: []
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "award_name",
            value: "Golden Lion"
        },
        {
            key: "id",
            value: "ef73751c-1a03-4435-b139-b8a29065c903"
        },
        {
            key: "issuing_authority",
            value: "18th Venice Film Festival"
        },
        {
            key: "sortable_date",
            value: "1957"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "satyajit-ray"
        },
        {
            key: "awardcategory",
            value: [
                {
                    key: null,
                    value: "Best Film"
                }
            ]
        },
        {
            key: "awardImage",
            value: []
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "award_name",
            value: "Cinema Nuovo Award"
        },
        {
            key: "id",
            value: "b4f5b5f7-b04f-4541-952e-226c30208cc0"
        },
        {
            key: "issuing_authority",
            value: "18th Venice Film Festival"
        },
        {
            key: "sortable_date",
            value: "1957"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "satyajit-ray"
        },
        {
            key: "awardcategory",
            value: []
        },
        {
            key: "awardImage",
            value: []
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "award_name",
            value: "FIPRESCI Critics Award"
        },
        {
            key: "id",
            value: "5004c00e-09b0-4362-afff-92802944897f"
        },
        {
            key: "issuing_authority",
            value: "FIPRESCI | 18th Venice Film Festival"
        },
        {
            key: "sortable_date",
            value: "1957"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "satyajit-ray"
        },
        {
            key: "awardcategory",
            value: [
                {
                    key: null,
                    value: "Best Film"
                }
            ]
        },
        {
            key: "awardImage",
            value: []
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "award_name",
            value: "FIPRESCI Critics Award"
        },
        {
            key: "id",
            value: "248f9ca8-8554-49da-ad8d-f5dff3b6d6c9"
        },
        {
            key: "issuing_authority",
            value: "FIPRESCI | BFI London Film Festival"
        },
        {
            key: "sortable_date",
            value: "1957"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "satyajit-ray"
        },
        {
            key: "awardcategory",
            value: [
                {
                    key: null,
                    value: "Best Film"
                }
            ]
        },
        {
            key: "awardImage",
            value: []
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "award_name",
            value: "Padma Shri"
        },
        {
            key: "id",
            value: "dda67e59-b4f0-4c8f-b444-ea06392590cf"
        },
        {
            key: "issuing_authority",
            value: "Government of India"
        },
        {
            key: "sortable_date",
            value: "1958"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "satyajit-ray"
        },
        {
            key: "awardcategory",
            value: []
        },
        {
            key: "awardImage",
            value: []
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "award_name",
            value: "Best Film Award"
        },
        {
            key: "id",
            value: "defcffcf-bf23-4464-9058-b74810d92f0a"
        },
        {
            key: "issuing_authority",
            value: "Vancouver International Film Festival"
        },
        {
            key: "sortable_date",
            value: "1958"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "satyajit-ray"
        },
        {
            key: "awardcategory",
            value: [
                {
                    key: null,
                    value: "Best Film"
                }
            ]
        },
        {
            key: "awardImage",
            value: []
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "award_name",
            value: "11th British Academy Film Awards, Nominated"
        },
        {
            key: "id",
            value: "6f47e24d-a2ec-4f7f-a9ec-6843d8abb66e"
        },
        {
            key: "issuing_authority",
            value: "British Academy of Film and Television Arts"
        },
        {
            key: "sortable_date",
            value: "1958"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "satyajit-ray"
        },
        {
            key: "awardcategory",
            value: [
                {
                    key: null,
                    value: "Best Film from Any Source"
                }
            ]
        },
        {
            key: "awardImage",
            value: []
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "award_name",
            value: "Award for Best Foreign Language Film"
        },
        {
            key: "id",
            value: "6ce70371-8ae4-4371-b27d-afb78cc94ce4"
        },
        {
            key: "issuing_authority",
            value: " National Board of Review of Motion Pictures, USA"
        },
        {
            key: "sortable_date",
            value: "1958"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "satyajit-ray"
        },
        {
            key: "awardcategory",
            value: [
                {
                    key: null,
                    value: "Best Foreign Language Film"
                }
            ]
        },
        {
            key: "awardImage",
            value: []
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "award_name",
            value: "Critics' Award"
        },
        {
            key: "id",
            value: "2c93d6af-1981-4676-8f8f-ae3322cb4412"
        },
        {
            key: "issuing_authority",
            value: "Stratford Film Festival "
        },
        {
            key: "sortable_date",
            value: "1958"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "satyajit-ray"
        },
        {
            key: "awardcategory",
            value: [
                {
                    key: null,
                    value: "Best Film of the Year"
                }
            ]
        },
        {
            key: "awardImage",
            value: []
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "award_name",
            value: "International Critic’ Award"
        },
        {
            key: "id",
            value: "e8380a7b-3a18-4aa7-b417-d826c26fb2db"
        },
        {
            key: "issuing_authority",
            value: "2nd San Francisco International Film Festival"
        },
        {
            key: "sortable_date",
            value: "1958"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "satyajit-ray"
        },
        {
            key: "awardcategory",
            value: [
                {
                    key: null,
                    value: "Best Film"
                }
            ]
        },
        {
            key: "awardImage",
            value: []
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "award_name",
            value: "Golden Gate Award"
        },
        {
            key: "id",
            value: "c0617542-e960-4c9e-96fd-ef097ca6f5af"
        },
        {
            key: "issuing_authority",
            value: "2nd San Francisco International Film Festival"
        },
        {
            key: "sortable_date",
            value: "1958"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "satyajit-ray"
        },
        {
            key: "awardcategory",
            value: [
                {
                    key: null,
                    value: "Best Film"
                }
            ]
        },
        {
            key: "awardImage",
            value: []
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "award_name",
            value: "Golden Gate Award"
        },
        {
            key: "id",
            value: "67765776-5f16-4102-af5d-e9128f6b788c"
        },
        {
            key: "issuing_authority",
            value: "2nd San Francisco International Film Festival"
        },
        {
            key: "sortable_date",
            value: "1958"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "satyajit-ray"
        },
        {
            key: "awardcategory",
            value: [
                {
                    key: null,
                    value: "Best Direction"
                }
            ]
        },
        {
            key: "awardImage",
            value: []
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "award_name",
            value: "Palme d'Or, Nominated"
        },
        {
            key: "id",
            value: "4c8fece3-be88-499a-abc4-06a617114863"
        },
        {
            key: "issuing_authority",
            value: "11th Cannes Film Festival"
        },
        {
            key: "sortable_date",
            value: "1958"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "satyajit-ray"
        },
        {
            key: "awardcategory",
            value: [
                {
                    key: null,
                    value: "Best Film"
                }
            ]
        },
        {
            key: "awardImage",
            value: []
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "award_name",
            value: "National Film Awards"
        },
        {
            key: "id",
            value: "8ce27534-c8ec-4349-a950-363dad4c89a2"
        },
        {
            key: "issuing_authority",
            value: "Directorate of Film Festivals"
        },
        {
            key: "sortable_date",
            value: "1958"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "satyajit-ray"
        },
        {
            key: "awardcategory",
            value: [
                {
                    key: null,
                    value: "Second Best Feature Film"
                }
            ]
        },
        {
            key: "awardImage",
            value: []
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "award_name",
            value: "National Film Awards"
        },
        {
            key: "id",
            value: "6d449a3c-1163-4fb5-a968-29fa989b12cc"
        },
        {
            key: "issuing_authority",
            value: "Directorate of Film Festivals"
        },
        {
            key: "sortable_date",
            value: "1958"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "satyajit-ray"
        },
        {
            key: "awardcategory",
            value: [
                {
                    key: null,
                    value: "Best Feature Film in Bengali"
                }
            ]
        },
        {
            key: "awardImage",
            value: []
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "award_name",
            value: "Sangeet Natak Akademi Puraskar"
        },
        {
            key: "id",
            value: "97e8afab-5af9-4b08-83e6-75711680337a"
        },
        {
            key: "issuing_authority",
            value: "Sangeet Natak Akademi"
        },
        {
            key: "sortable_date",
            value: "1959"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "satyajit-ray"
        },
        {
            key: "awardcategory",
            value: []
        },
        {
            key: "awardImage",
            value: []
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "award_name",
            value: "Cultural Award"
        },
        {
            key: "id",
            value: "bdc3309a-b456-4176-8ee1-358a914ed3d1"
        },
        {
            key: "issuing_authority",
            value: "New York Film Festival"
        },
        {
            key: "sortable_date",
            value: "1959"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "satyajit-ray"
        },
        {
            key: "awardcategory",
            value: [
                {
                    key: null,
                    value: "Best Foreign Film"
                }
            ]
        },
        {
            key: "awardImage",
            value: []
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "award_name",
            value: "12th British Academy Film Awards, Nominated"
        },
        {
            key: "id",
            value: "57577fc2-cade-48e8-8b6d-f1d496641456"
        },
        {
            key: "issuing_authority",
            value: "British Academy of Film and Television Arts"
        },
        {
            key: "sortable_date",
            value: "1959"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "satyajit-ray"
        },
        {
            key: "awardcategory",
            value: [
                {
                    key: null,
                    value: "Best Film from Any Source"
                }
            ]
        },
        {
            key: "awardImage",
            value: []
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "award_name",
            value: "Golden Laurel, USA"
        },
        {
            key: "id",
            value: "c47915e4-8bbf-414e-95af-19fd738c23d6"
        },
        {
            key: "issuing_authority",
            value: "USA Film Festival"
        },
        {
            key: "sortable_date",
            value: "1959"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "satyajit-ray"
        },
        {
            key: "awardcategory",
            value: [
                {
                    key: null,
                    value: "Best Foreign Film of 1958-59"
                }
            ]
        },
        {
            key: "awardImage",
            value: []
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "award_name",
            value: "Silver Medal for Music, Moscow"
        },
        {
            key: "id",
            value: "adcef2b8-af97-4b99-b18d-f8d229c39a54"
        },
        {
            key: "issuing_authority",
            value: ""
        },
        {
            key: "sortable_date",
            value: "1959"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "satyajit-ray"
        },
        {
            key: "awardcategory",
            value: []
        },
        {
            key: "awardImage",
            value: []
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "award_name",
            value: "Grand Prix, Nominated"
        },
        {
            key: "id",
            value: "c71154a2-1906-4638-ad80-0861d3cac076"
        },
        {
            key: "issuing_authority",
            value: "1st Moscow International Film Festival"
        },
        {
            key: "sortable_date",
            value: "1959"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "satyajit-ray"
        },
        {
            key: "awardcategory",
            value: [
                {
                    key: null,
                    value: "Best Feature Film"
                }
            ]
        },
        {
            key: "awardImage",
            value: []
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "award_name",
            value: "National Film Awards"
        },
        {
            key: "id",
            value: "abb24860-5c69-4bdd-95e0-62b9c12837c7"
        },
        {
            key: "issuing_authority",
            value: "Directorate of Film Festivals"
        },
        {
            key: "sortable_date",
            value: "1959"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "satyajit-ray"
        },
        {
            key: "awardcategory",
            value: [
                {
                    key: null,
                    value: "Best Feature Film"
                }
            ]
        },
        {
            key: "awardImage",
            value: []
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "award_name",
            value: "Selznick Golden Laurel"
        },
        {
            key: "id",
            value: "064bafee-e31a-45bf-86eb-9ebf8bb2e0b5"
        },
        {
            key: "issuing_authority",
            value: "10th Berlin International Film Festival"
        },
        {
            key: "sortable_date",
            value: "1960"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "satyajit-ray"
        },
        {
            key: "awardcategory",
            value: [
                {
                    key: null,
                    value: "Best Film"
                }
            ]
        },
        {
            key: "awardImage",
            value: []
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "award_name",
            value: "Sutherland Award"
        },
        {
            key: "id",
            value: "58197aba-5608-42f3-a223-cb7c61651d2c"
        },
        {
            key: "issuing_authority",
            value: "7th BFI London Film Festival"
        },
        {
            key: "sortable_date",
            value: "1960"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "satyajit-ray"
        },
        {
            key: "awardcategory",
            value: [
                {
                    key: null,
                    value: "Best Original And Imaginative Film"
                }
            ]
        },
        {
            key: "awardImage",
            value: []
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "award_name",
            value: "Diploma Of Merit"
        },
        {
            key: "id",
            value: "4d3bcb6e-e00a-402a-86f2-1df6d16a3fdd"
        },
        {
            key: "issuing_authority",
            value: "14th International Film festival, Edinburgh"
        },
        {
            key: "sortable_date",
            value: "1960"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "satyajit-ray"
        },
        {
            key: "awardcategory",
            value: []
        },
        {
            key: "awardImage",
            value: []
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "award_name",
            value: "Award for Best Foreign Language Film"
        },
        {
            key: "id",
            value: "3ec1763e-783c-4014-860b-ab9922d55605"
        },
        {
            key: "issuing_authority",
            value: "National Board of Review of Motion Pictures, USA"
        },
        {
            key: "sortable_date",
            value: "1960"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "satyajit-ray"
        },
        {
            key: "awardcategory",
            value: [
                {
                    key: null,
                    value: "Best Foreign Language Film"
                }
            ]
        },
        {
            key: "awardImage",
            value: []
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "award_name",
            value: "National Film Awards"
        },
        {
            key: "id",
            value: "cf57579b-9d53-4fd2-a205-8c35a6245a65"
        },
        {
            key: "issuing_authority",
            value: "Directorate of Film Festivals"
        },
        {
            key: "sortable_date",
            value: "1960"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "satyajit-ray"
        },
        {
            key: "awardcategory",
            value: [
                {
                    key: null,
                    value: "Best Feature Film in Bengali"
                }
            ]
        },
        {
            key: "awardImage",
            value: []
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "award_name",
            value: "National Film Awards"
        },
        {
            key: "id",
            value: "74365a92-5c88-4548-80a0-eec867565be0"
        },
        {
            key: "issuing_authority",
            value: "Directorate of Film Festivals"
        },
        {
            key: "sortable_date",
            value: "1961"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "satyajit-ray"
        },
        {
            key: "awardcategory",
            value: [
                {
                    key: null,
                    value: "Best Feature Film in Bengali"
                }
            ]
        },
        {
            key: "awardImage",
            value: []
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "award_name",
            value: "BFJA Awards"
        },
        {
            key: "id",
            value: "58a7b2b2-7013-46c0-b0ec-4f0042e37355"
        },
        {
            key: "issuing_authority",
            value: "The Bengal Film Journalists' Association"
        },
        {
            key: "sortable_date",
            value: "1961"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "satyajit-ray"
        },
        {
            key: "awardcategory",
            value: [
                {
                    key: null,
                    value: "Best Director"
                }
            ]
        },
        {
            key: "awardImage",
            value: []
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "award_name",
            value: "BFJA Awards"
        },
        {
            key: "id",
            value: "01b4bfaa-bb39-46e1-811b-58464d94f43f"
        },
        {
            key: "issuing_authority",
            value: "The Bengal Film Journalists' Association"
        },
        {
            key: "sortable_date",
            value: "1961"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "satyajit-ray"
        },
        {
            key: "awardcategory",
            value: [
                {
                    key: null,
                    value: "Best Indian Films"
                }
            ]
        },
        {
            key: "awardImage",
            value: []
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "award_name",
            value: "National Film Awards"
        },
        {
            key: "id",
            value: "0cae7209-e110-4db7-8e9c-ef17fa0a775d"
        },
        {
            key: "issuing_authority",
            value: "Directorate of Film Festivals"
        },
        {
            key: "sortable_date",
            value: "1961"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "satyajit-ray"
        },
        {
            key: "awardcategory",
            value: [
                {
                    key: null,
                    value: "Best Documentary Film"
                }
            ]
        },
        {
            key: "awardImage",
            value: []
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "award_name",
            value: "Golden Seal, Locarno"
        },
        {
            key: "id",
            value: "c9fd7f10-4ff1-49f7-8646-1b5fe4161ce3"
        },
        {
            key: "issuing_authority",
            value: ""
        },
        {
            key: "sortable_date",
            value: "1961"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "satyajit-ray"
        },
        {
            key: "awardcategory",
            value: []
        },
        {
            key: "awardImage",
            value: []
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "award_name",
            value: "Palme d'Or, Nominated"
        },
        {
            key: "id",
            value: "e1f36904-bfbe-4085-bd66-255f5aee2571"
        },
        {
            key: "issuing_authority",
            value: "15th Cannes Film Festival"
        },
        {
            key: "sortable_date",
            value: "1962"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "satyajit-ray"
        },
        {
            key: "awardcategory",
            value: [
                {
                    key: null,
                    value: "Best Film"
                }
            ]
        },
        {
            key: "awardImage",
            value: []
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "award_name",
            value: "Golden Boomerang, Melbourne (Two Daughters)"
        },
        {
            key: "id",
            value: "11eec8b0-980c-49de-b255-103051ac7f73"
        },
        {
            key: "issuing_authority",
            value: ""
        },
        {
            key: "sortable_date",
            value: "1962"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "satyajit-ray"
        },
        {
            key: "awardcategory",
            value: []
        },
        {
            key: "awardImage",
            value: []
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "award_name",
            value: "Special Mention, Montevideo"
        },
        {
            key: "id",
            value: "d5c0e125-b251-4b6b-86a1-71475dd24cdb"
        },
        {
            key: "issuing_authority",
            value: ""
        },
        {
            key: "sortable_date",
            value: "1962"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "satyajit-ray"
        },
        {
            key: "awardcategory",
            value: []
        },
        {
            key: "awardImage",
            value: []
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "award_name",
            value: "BFJA Awards"
        },
        {
            key: "id",
            value: "edd5654a-7ef5-4f43-a1a4-6a5e0de1f839"
        },
        {
            key: "issuing_authority",
            value: "The Bengal Film Journalists' Association"
        },
        {
            key: "sortable_date",
            value: "1962"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "satyajit-ray"
        },
        {
            key: "awardcategory",
            value: [
                {
                    key: null,
                    value: "Best Indian Film"
                }
            ]
        },
        {
            key: "awardImage",
            value: []
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "award_name",
            value: "BFJA Awards"
        },
        {
            key: "id",
            value: "42d76d3b-6412-475a-9bcf-894d9415cb83"
        },
        {
            key: "issuing_authority",
            value: "The Bengal Film Journalists' Association"
        },
        {
            key: "sortable_date",
            value: "1962"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "satyajit-ray"
        },
        {
            key: "awardcategory",
            value: [
                {
                    key: null,
                    value: "Best Dialogue"
                }
            ]
        },
        {
            key: "awardImage",
            value: []
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "award_name",
            value: "National Film Awards"
        },
        {
            key: "id",
            value: "acfb9448-4758-4906-86fd-28f9dfe62ccb"
        },
        {
            key: "issuing_authority",
            value: "Directorate of Film Festivals"
        },
        {
            key: "sortable_date",
            value: "1962"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "satyajit-ray"
        },
        {
            key: "awardcategory",
            value: [
                {
                    key: null,
                    value: "Second Best Feature Film"
                }
            ]
        },
        {
            key: "awardImage",
            value: []
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "award_name",
            value: "BFJA Awards"
        },
        {
            key: "id",
            value: "1d72a463-0fb0-4302-87a8-c89af886fb26"
        },
        {
            key: "issuing_authority",
            value: "The Bengal Film Journalists' Association"
        },
        {
            key: "sortable_date",
            value: "1962"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "satyajit-ray"
        },
        {
            key: "awardcategory",
            value: [
                {
                    key: null,
                    value: "Best Director"
                }
            ]
        },
        {
            key: "awardImage",
            value: []
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "award_name",
            value: "Selznik Golden Laurel"
        },
        {
            key: "id",
            value: "2e15a89f-992e-4625-b61b-d4d087401a14"
        },
        {
            key: "issuing_authority",
            value: "13th Berlin International Film Festival"
        },
        {
            key: "sortable_date",
            value: "1963"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "satyajit-ray"
        },
        {
            key: "awardcategory",
            value: [
                {
                    key: null,
                    value: "Best Film"
                }
            ]
        },
        {
            key: "awardImage",
            value: []
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "award_name",
            value: "National Film Award"
        },
        {
            key: "id",
            value: "bb4466eb-2d88-450b-bd19-25923b64106f"
        },
        {
            key: "issuing_authority",
            value: "Directorate of Film Festivals"
        },
        {
            key: "sortable_date",
            value: "1963"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "satyajit-ray"
        },
        {
            key: "awardcategory",
            value: [
                {
                    key: null,
                    value: "Third Best Feature Film"
                }
            ]
        },
        {
            key: "awardImage",
            value: []
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "award_name",
            value: "BFJA Awards"
        },
        {
            key: "id",
            value: "a2762d0b-9e9e-445d-9e68-68a793875879"
        },
        {
            key: "issuing_authority",
            value: "The Bengal Film Journalists' Association"
        },
        {
            key: "sortable_date",
            value: "1963"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "satyajit-ray"
        },
        {
            key: "awardcategory",
            value: [
                {
                    key: null,
                    value: "Best Indian Films"
                }
            ]
        },
        {
            key: "awardImage",
            value: []
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "award_name",
            value: "BFJA Awards"
        },
        {
            key: "id",
            value: "a598fc21-bb8e-4d8a-8b63-e2c7772c7c81"
        },
        {
            key: "issuing_authority",
            value: "The Bengal Film Journalists' Association"
        },
        {
            key: "sortable_date",
            value: "1963"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "satyajit-ray"
        },
        {
            key: "awardcategory",
            value: [
                {
                    key: null,
                    value: "Best Dialogue"
                }
            ]
        },
        {
            key: "awardImage",
            value: []
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "award_name",
            value: "Certificate of Merit, New Delhi"
        },
        {
            key: "id",
            value: "f26423e8-fa3a-47e6-95a9-44eef43f0b60"
        },
        {
            key: "issuing_authority",
            value: ""
        },
        {
            key: "sortable_date",
            value: "1964"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "satyajit-ray"
        },
        {
            key: "awardcategory",
            value: []
        },
        {
            key: "awardImage",
            value: []
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "award_name",
            value: "Silver Bear"
        },
        {
            key: "id",
            value: "b7d42328-8f44-4d03-8479-6de11a22af99"
        },
        {
            key: "issuing_authority",
            value: "14th Berlin International Film Festival"
        },
        {
            key: "sortable_date",
            value: "1964"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "satyajit-ray"
        },
        {
            key: "awardcategory",
            value: [
                {
                    key: null,
                    value: "Best Director"
                }
            ]
        },
        {
            key: "awardImage",
            value: []
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "award_name",
            value: "National Film Awards"
        },
        {
            key: "id",
            value: "83108062-fad4-4b42-b3f0-922f410089db"
        },
        {
            key: "issuing_authority",
            value: "Directorate of Film Festivals"
        },
        {
            key: "sortable_date",
            value: "1964"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "satyajit-ray"
        },
        {
            key: "awardcategory",
            value: [
                {
                    key: null,
                    value: "Best Feature Film"
                }
            ]
        },
        {
            key: "awardImage",
            value: []
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "award_name",
            value: "BFJA Awards"
        },
        {
            key: "id",
            value: "db443b53-a090-4d35-8b84-822ee949d30e"
        },
        {
            key: "issuing_authority",
            value: "The Bengal Film Journalists' Association"
        },
        {
            key: "sortable_date",
            value: "1964"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "satyajit-ray"
        },
        {
            key: "awardcategory",
            value: [
                {
                    key: null,
                    value: "Best Director"
                }
            ]
        },
        {
            key: "awardImage",
            value: []
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "award_name",
            value: "BFJA Awards"
        },
        {
            key: "id",
            value: "b4b92424-7578-4dfb-aec7-cd14a7109ab8"
        },
        {
            key: "issuing_authority",
            value: "The Bengal Film Journalists' Association"
        },
        {
            key: "sortable_date",
            value: "1964"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "satyajit-ray"
        },
        {
            key: "awardcategory",
            value: [
                {
                    key: null,
                    value: "Best Indian Films"
                }
            ]
        },
        {
            key: "awardImage",
            value: []
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "award_name",
            value: "BFJA Awards"
        },
        {
            key: "id",
            value: "18b66a90-f281-41e7-8b01-d0de001b5051"
        },
        {
            key: "issuing_authority",
            value: "The Bengal Film Journalists' Association"
        },
        {
            key: "sortable_date",
            value: "1964"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "satyajit-ray"
        },
        {
            key: "awardcategory",
            value: [
                {
                    key: null,
                    value: "Best Screenplay"
                }
            ]
        },
        {
            key: "awardImage",
            value: []
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "award_name",
            value: "BFJA Awards"
        },
        {
            key: "id",
            value: "c261dd7c-eca3-4fc6-884a-d1e21491bec0"
        },
        {
            key: "issuing_authority",
            value: "The Bengal Film Journalists' Association"
        },
        {
            key: "sortable_date",
            value: "1964"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "satyajit-ray"
        },
        {
            key: "awardcategory",
            value: [
                {
                    key: null,
                    value: "Best Music Director"
                }
            ]
        },
        {
            key: "awardImage",
            value: []
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "award_name",
            value: "Padma Bhushan"
        },
        {
            key: "id",
            value: "42cb52e8-04c1-4d3d-bc94-09724ea7d1b9"
        },
        {
            key: "issuing_authority",
            value: "Government of India"
        },
        {
            key: "sortable_date",
            value: "1965"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "satyajit-ray"
        },
        {
            key: "awardcategory",
            value: []
        },
        {
            key: "awardImage",
            value: ["https://s3-alpha-sig.figma.com/img/1c5e/67c7/69b1738653067f99cd1852fe52717a80?Expires=1716163200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=TXrzenY5svElE3bx1cKSoOEYii9TfIX1UVwtR-UNhZBLzdY6Gdj0i8ic6uVXJZFpR423671SfhaOvVTslPQM4k3xF0XKhKo0ZtBxqnkgIkxrY9KdSs46u55enCtfkgTfJjRNIijVqJDc7uR9iCiNtP3FrIgn2vubLx31VwwNZFfYzH3AEMRfeBONkzpwsFENGRMyxXm0C5nudySNwcESaVfI0yhdHAOi0leQ5CZ9fyQqG5mEQOpfvBqY4oc9lpAqL~ie4N-bE4maY5HTbFFYDnhWSVugBTSj19Fv5iPKvFTEgQM9PjkDXgChZ-OfS5nQ~X~-cMSfnF-PcafPdqREyg__"]
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "award_name",
            value: "Silver Bear"
        },
        {
            key: "id",
            value: "057a2675-9eab-4900-8aab-df839e42d8ac"
        },
        {
            key: "issuing_authority",
            value: "15th Berlin International Film Festival"
        },
        {
            key: "sortable_date",
            value: "1965"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "satyajit-ray"
        },
        {
            key: "awardcategory",
            value: [
                {
                    key: null,
                    value: "Best Direction"
                }
            ]
        },
        {
            key: "awardImage",
            value: []
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "award_name",
            value: "OCIC Catholic Award"
        },
        {
            key: "id",
            value: "749a4149-c3ff-44a7-af6f-47352158a7c1"
        },
        {
            key: "issuing_authority",
            value: "International Catholic Organization for Cinema and Audiovisual | 15th Berlin International Film Festival"
        },
        {
            key: "sortable_date",
            value: "1965"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "satyajit-ray"
        },
        {
            key: "awardcategory",
            value: []
        },
        {
            key: "awardImage",
            value: []
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "award_name",
            value: "Best Film Award"
        },
        {
            key: "id",
            value: "78b72935-54fd-4954-b8f5-29e7d33555d3"
        },
        {
            key: "issuing_authority",
            value: "Acapulco Film Festival"
        },
        {
            key: "sortable_date",
            value: "1965"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "satyajit-ray"
        },
        {
            key: "awardcategory",
            value: [
                {
                    key: null,
                    value: "Best Film"
                }
            ]
        },
        {
            key: "awardImage",
            value: []
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "award_name",
            value: "Golden Lion, Nominated"
        },
        {
            key: "id",
            value: "41408dfb-8a7e-49b8-9e03-7bd408c3db75"
        },
        {
            key: "issuing_authority",
            value: "28th Venice International Film Festival"
        },
        {
            key: "sortable_date",
            value: "1965"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "satyajit-ray"
        },
        {
            key: "awardcategory",
            value: [
                {
                    key: null,
                    value: "Best Film"
                }
            ]
        },
        {
            key: "awardImage",
            value: []
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "award_name",
            value: "BFJA Awards"
        },
        {
            key: "id",
            value: "36c32d96-2506-423e-adbc-a0e50c9b76ff"
        },
        {
            key: "issuing_authority",
            value: "The Bengal Film Journalists' Association"
        },
        {
            key: "sortable_date",
            value: "1965"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "satyajit-ray"
        },
        {
            key: "awardcategory",
            value: [
                {
                    key: null,
                    value: "Best Dialogue"
                }
            ]
        },
        {
            key: "awardImage",
            value: []
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "award_name",
            value: "BFJA Awards"
        },
        {
            key: "id",
            value: "af3c9ebf-5a11-4750-9d9e-46c27782784b"
        },
        {
            key: "issuing_authority",
            value: "The Bengal Film Journalists' Association"
        },
        {
            key: "sortable_date",
            value: "1965"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "satyajit-ray"
        },
        {
            key: "awardcategory",
            value: [
                {
                    key: null,
                    value: "Best Indian Films"
                }
            ]
        },
        {
            key: "awardImage",
            value: []
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "award_name",
            value: "Kinema Jumpo Award"
        },
        {
            key: "id",
            value: "12b64b9c-b5d8-4109-bf1b-c64599914781"
        },
        {
            key: "issuing_authority",
            value: "Kinema Jumpo, Tokyo"
        },
        {
            key: "sortable_date",
            value: "1966"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "satyajit-ray"
        },
        {
            key: "awardcategory",
            value: [
                {
                    key: null,
                    value: "Best Foreign Language Film"
                }
            ]
        },
        {
            key: "awardImage",
            value: []
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "award_name",
            value: "National Film Awards"
        },
        {
            key: "id",
            value: "ac29a67b-0f6a-4e2e-b816-99d2f2af653c"
        },
        {
            key: "issuing_authority",
            value: "Directorate of Film Festivals"
        },
        {
            key: "sortable_date",
            value: "1966"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "satyajit-ray"
        },
        {
            key: "awardcategory",
            value: [
                {
                    key: null,
                    value: "Best Screenplay"
                }
            ]
        },
        {
            key: "awardImage",
            value: []
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "award_name",
            value: "BFJA Awards"
        },
        {
            key: "id",
            value: "7796200f-e485-4ed8-ab14-75ce534d08b2"
        },
        {
            key: "issuing_authority",
            value: "The Bengal Film Journalists' Association"
        },
        {
            key: "sortable_date",
            value: "1966"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "satyajit-ray"
        },
        {
            key: "awardcategory",
            value: [
                {
                    key: null,
                    value: "Best Director"
                }
            ]
        },
        {
            key: "awardImage",
            value: []
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "award_name",
            value: "BFJA Awards"
        },
        {
            key: "id",
            value: "3de7b5a8-2211-4e8f-8604-cb1c15478f6d"
        },
        {
            key: "issuing_authority",
            value: "The Bengal Film Journalists' Association"
        },
        {
            key: "sortable_date",
            value: "1966"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "satyajit-ray"
        },
        {
            key: "awardcategory",
            value: [
                {
                    key: null,
                    value: "Best Indian Films"
                }
            ]
        },
        {
            key: "awardImage",
            value: []
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "award_name",
            value: "BFJA Awards"
        },
        {
            key: "id",
            value: "32736984-a485-4dd6-afda-80a91b594256"
        },
        {
            key: "issuing_authority",
            value: "The Bengal Film Journalists' Association"
        },
        {
            key: "sortable_date",
            value: "1966"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "satyajit-ray"
        },
        {
            key: "awardcategory",
            value: [
                {
                    key: null,
                    value: "Best Screenplay"
                }
            ]
        },
        {
            key: "awardImage",
            value: []
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "award_name",
            value: "BFJA Awards"
        },
        {
            key: "id",
            value: "04b733d4-4e44-403c-a843-ef9cb9d7a5db"
        },
        {
            key: "issuing_authority",
            value: "The Bengal Film Journalists' Association"
        },
        {
            key: "sortable_date",
            value: "1966"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "satyajit-ray"
        },
        {
            key: "awardcategory",
            value: [
                {
                    key: null,
                    value: "Best Dialogue"
                }
            ]
        },
        {
            key: "awardImage",
            value: []
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "award_name",
            value: "Golden Bear, Nominated"
        },
        {
            key: "id",
            value: "68075c72-be4c-4edb-8634-9c2909fdc775"
        },
        {
            key: "issuing_authority",
            value: "16th Berlin International Film Festival"
        },
        {
            key: "sortable_date",
            value: "1966"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "satyajit-ray"
        },
        {
            key: "awardcategory",
            value: [
                {
                    key: null,
                    value: "Best Film"
                }
            ]
        },
        {
            key: "awardImage",
            value: []
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "award_name",
            value: "Special Recognition"
        },
        {
            key: "id",
            value: "6d57e1d8-550a-4fb5-ae5d-4dc4a31a8b86"
        },
        {
            key: "issuing_authority",
            value: "16th Berlin International Film Festival"
        },
        {
            key: "sortable_date",
            value: "1966"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "satyajit-ray"
        },
        {
            key: "awardcategory",
            value: []
        },
        {
            key: "awardImage",
            value: []
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "award_name",
            value: "Critics' Prize (UNICRIT Award)"
        },
        {
            key: "id",
            value: "608db309-5b27-44ef-a9e0-5e57f4d13426"
        },
        {
            key: "issuing_authority",
            value: "16th Berlin International Film Festival"
        },
        {
            key: "sortable_date",
            value: "1966"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "satyajit-ray"
        },
        {
            key: "awardcategory",
            value: []
        },
        {
            key: "awardImage",
            value: []
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "award_name",
            value: "JLCCA Ramon Magsaysay Award"
        },
        {
            key: "id",
            value: "88e268a2-f3b1-4b62-a610-681851563d27"
        },
        {
            key: "issuing_authority",
            value: "Rockefeller Brothers Fund, Philippine Government"
        },
        {
            key: "sortable_date",
            value: "1967"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "satyajit-ray"
        },
        {
            key: "awardcategory",
            value: []
        },
        {
            key: "awardImage",
            value: []
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "award_name",
            value: "Bodil Award"
        },
        {
            key: "id",
            value: "5c3783f1-c639-457a-8097-1d80e35f455d"
        },
        {
            key: "issuing_authority",
            value: "Danish Film Critics Association"
        },
        {
            key: "sortable_date",
            value: "1967"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "satyajit-ray"
        },
        {
            key: "awardcategory",
            value: [
                {
                    key: null,
                    value: "Best Non-European Film of the Year"
                }
            ]
        },
        {
            key: "awardImage",
            value: []
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "award_name",
            value: "National Film Awards"
        },
        {
            key: "id",
            value: "855fc057-7ac6-4c05-adf4-4de53d759ffc"
        },
        {
            key: "issuing_authority",
            value: "Directorate of Film Festivals"
        },
        {
            key: "sortable_date",
            value: "1967"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "satyajit-ray"
        },
        {
            key: "awardcategory",
            value: [
                {
                    key: null,
                    value: "Best Director"
                }
            ]
        },
        {
            key: "awardImage",
            value: []
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "award_name",
            value: "West Bengal Government Film Awards"
        },
        {
            key: "id",
            value: "539c51b8-d313-475d-a91e-0bbc56c2ed85"
        },
        {
            key: "issuing_authority",
            value: ""
        },
        {
            key: "sortable_date",
            value: "1967"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "satyajit-ray"
        },
        {
            key: "awardcategory",
            value: [
                {
                    key: null,
                    value: "Best Director"
                }
            ]
        },
        {
            key: "awardImage",
            value: []
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "award_name",
            value: "National Film Awards"
        },
        {
            key: "id",
            value: "b53ad4a3-d6e8-445c-91e5-d0aac93e92a7"
        },
        {
            key: "issuing_authority",
            value: "Directorate of Film Festivals"
        },
        {
            key: "sortable_date",
            value: "1968"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "satyajit-ray"
        },
        {
            key: "awardcategory",
            value: [
                {
                    key: null,
                    value: "Best Feature Film"
                }
            ]
        },
        {
            key: "awardImage",
            value: []
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "award_name",
            value: "National Film Awards"
        },
        {
            key: "id",
            value: "cb102afc-4ed3-4b59-bf13-2f936f95dc34"
        },
        {
            key: "issuing_authority",
            value: "Directorate of Film Festivals"
        },
        {
            key: "sortable_date",
            value: "1968"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "satyajit-ray"
        },
        {
            key: "awardcategory",
            value: [
                {
                    key: null,
                    value: "Best Director"
                }
            ]
        },
        {
            key: "awardImage",
            value: []
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "award_name",
            value: "BFJA Awards"
        },
        {
            key: "id",
            value: "028ae552-4f8d-4c02-badd-9d4ef3817d89"
        },
        {
            key: "issuing_authority",
            value: "The Bengal Film Journalists' Association"
        },
        {
            key: "sortable_date",
            value: "1968"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "satyajit-ray"
        },
        {
            key: "awardcategory",
            value: [
                {
                    key: null,
                    value: "Best Director"
                }
            ]
        },
        {
            key: "awardImage",
            value: []
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "award_name",
            value: "BFJA Awards"
        },
        {
            key: "id",
            value: "9501149f-c375-4261-b9df-4e26ac467db7"
        },
        {
            key: "issuing_authority",
            value: "The Bengal Film Journalists' Association"
        },
        {
            key: "sortable_date",
            value: "1968"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "satyajit-ray"
        },
        {
            key: "awardcategory",
            value: [
                {
                    key: null,
                    value: "Best Indian Films"
                }
            ]
        },
        {
            key: "awardImage",
            value: []
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "award_name",
            value: "BFJA Awards"
        },
        {
            key: "id",
            value: "c6d54f6a-5e0e-4135-a904-ed4bec1ba6c5"
        },
        {
            key: "issuing_authority",
            value: "The Bengal Film Journalists' Association"
        },
        {
            key: "sortable_date",
            value: "1968"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "satyajit-ray"
        },
        {
            key: "awardcategory",
            value: [
                {
                    key: null,
                    value: "Best Screenplay"
                }
            ]
        },
        {
            key: "awardImage",
            value: []
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "award_name",
            value: "BFJA Awards"
        },
        {
            key: "id",
            value: "6b58726c-35ee-4b60-83a2-6a6519a24274"
        },
        {
            key: "issuing_authority",
            value: "The Bengal Film Journalists' Association"
        },
        {
            key: "sortable_date",
            value: "1968"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "satyajit-ray"
        },
        {
            key: "awardcategory",
            value: [
                {
                    key: null,
                    value: "Best Music Director"
                }
            ]
        },
        {
            key: "awardImage",
            value: []
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "award_name",
            value: "BFJA Awards"
        },
        {
            key: "id",
            value: "f776bcc5-d6c4-47fa-8fd4-29ed0be6937b"
        },
        {
            key: "issuing_authority",
            value: "The Bengal Film Journalists' Association"
        },
        {
            key: "sortable_date",
            value: "1968"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "satyajit-ray"
        },
        {
            key: "awardcategory",
            value: [
                {
                    key: null,
                    value: "Best Lyricist"
                }
            ]
        },
        {
            key: "awardImage",
            value: []
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "award_name",
            value: "BFJA Awards"
        },
        {
            key: "id",
            value: "7c8c67d7-79ee-4223-913a-1b39d08a1f10"
        },
        {
            key: "issuing_authority",
            value: "The Bengal Film Journalists' Association"
        },
        {
            key: "sortable_date",
            value: "1968"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "satyajit-ray"
        },
        {
            key: "awardcategory",
            value: [
                {
                    key: null,
                    value: "Best Dialogue"
                }
            ]
        },
        {
            key: "awardImage",
            value: []
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "award_name",
            value: "Bodil Award"
        },
        {
            key: "id",
            value: "0eee243c-a6ae-420c-90d0-de774a69b77b"
        },
        {
            key: "issuing_authority",
            value: "Danish Film Critics Association"
        },
        {
            key: "sortable_date",
            value: "1969"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "satyajit-ray"
        },
        {
            key: "awardcategory",
            value: [
                {
                    key: null,
                    value: "Best Non-European Film of the Year"
                }
            ]
        },
        {
            key: "awardImage",
            value: []
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "award_name",
            value: "Silver Cross Award"
        },
        {
            key: "id",
            value: "a95a8ce1-4b07-402a-a75c-ed5c4cfd150a"
        },
        {
            key: "issuing_authority",
            value: "Auckland International Film Festival"
        },
        {
            key: "sortable_date",
            value: "1969"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "satyajit-ray"
        },
        {
            key: "awardcategory",
            value: [
                {
                    key: null,
                    value: "Best Direction and Originality"
                }
            ]
        },
        {
            key: "awardImage",
            value: []
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "award_name",
            value: "BFJA Awards"
        },
        {
            key: "id",
            value: "0a061a1b-63d8-46b6-90e4-61c208eb3aea"
        },
        {
            key: "issuing_authority",
            value: "The Bengal Film Journalists' Association"
        },
        {
            key: "sortable_date",
            value: "1969"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "satyajit-ray"
        },
        {
            key: "awardcategory",
            value: [
                {
                    key: null,
                    value: "Best Indian Films"
                }
            ]
        },
        {
            key: "awardImage",
            value: []
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "award_name",
            value: "Best Film Award"
        },
        {
            key: "id",
            value: "3ff27c63-6406-4c7b-ad31-b5d3e6fe3927"
        },
        {
            key: "issuing_authority",
            value: "18th Melbourne International Film Festival"
        },
        {
            key: "sortable_date",
            value: "1970"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "satyajit-ray"
        },
        {
            key: "awardcategory",
            value: [
                {
                    key: null,
                    value: "Best Film"
                }
            ]
        },
        {
            key: "awardImage",
            value: []
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "award_name",
            value: "Merit Award"
        },
        {
            key: "id",
            value: "263be9e7-a1bb-4b5a-a7e4-b3120eee85b3"
        },
        {
            key: "issuing_authority",
            value: "Tokyo Film Festival"
        },
        {
            key: "sortable_date",
            value: "1970"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "satyajit-ray"
        },
        {
            key: "awardcategory",
            value: []
        },
        {
            key: "awardImage",
            value: []
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "award_name",
            value: "National Film Awards"
        },
        {
            key: "id",
            value: "e703cb68-f5a5-4cbf-b304-be483c04c14d"
        },
        {
            key: "issuing_authority",
            value: "Directorate of Film Festivals"
        },
        {
            key: "sortable_date",
            value: "1970"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "satyajit-ray"
        },
        {
            key: "awardcategory",
            value: [
                {
                    key: null,
                    value: "Best Director"
                }
            ]
        },
        {
            key: "awardImage",
            value: []
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "award_name",
            value: "National Film Awards"
        },
        {
            key: "id",
            value: "2a869373-ac0f-4429-9e1d-d791981e7faf"
        },
        {
            key: "issuing_authority",
            value: "Directorate of Film Festivals"
        },
        {
            key: "sortable_date",
            value: "1970"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "satyajit-ray"
        },
        {
            key: "awardcategory",
            value: [
                {
                    key: null,
                    value: "Second Best Feature Film"
                }
            ]
        },
        {
            key: "awardImage",
            value: []
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "award_name",
            value: "National Film Awards"
        },
        {
            key: "id",
            value: "9c181f1b-d555-4ac9-8f50-8b052cad2c55"
        },
        {
            key: "issuing_authority",
            value: "Directorate of Film Festivals"
        },
        {
            key: "sortable_date",
            value: "1970"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "satyajit-ray"
        },
        {
            key: "awardcategory",
            value: [
                {
                    key: null,
                    value: "Best Screenplay"
                }
            ]
        },
        {
            key: "awardImage",
            value: []
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "award_name",
            value: "BFJA Awards"
        },
        {
            key: "id",
            value: "5ba08709-8ad5-4f75-8069-244ff66e9825"
        },
        {
            key: "issuing_authority",
            value: "The Bengal Film Journalists' Association"
        },
        {
            key: "sortable_date",
            value: "1970"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "satyajit-ray"
        },
        {
            key: "awardcategory",
            value: [
                {
                    key: null,
                    value: "Best Indian Films"
                }
            ]
        },
        {
            key: "awardImage",
            value: []
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "award_name",
            value: "BFJA Awards"
        },
        {
            key: "id",
            value: "05b4f313-e389-4d35-a9be-bfcccb074b97"
        },
        {
            key: "issuing_authority",
            value: "The Bengal Film Journalists' Association"
        },
        {
            key: "sortable_date",
            value: "1970"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "satyajit-ray"
        },
        {
            key: "awardcategory",
            value: [
                {
                    key: null,
                    value: "Best Director"
                }
            ]
        },
        {
            key: "awardImage",
            value: []
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "award_name",
            value: "BFJA Awards"
        },
        {
            key: "id",
            value: "eba73c51-261d-4f04-a2c5-24b0ac6f2e40"
        },
        {
            key: "issuing_authority",
            value: "The Bengal Film Journalists' Association"
        },
        {
            key: "sortable_date",
            value: "1970"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "satyajit-ray"
        },
        {
            key: "awardcategory",
            value: [
                {
                    key: null,
                    value: "Best Screenplay"
                }
            ]
        },
        {
            key: "awardImage",
            value: []
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "award_name",
            value: "BFJA Awards"
        },
        {
            key: "id",
            value: "d4f17f9e-9cde-4db0-a53e-d3507b789b8c"
        },
        {
            key: "issuing_authority",
            value: "The Bengal Film Journalists' Association"
        },
        {
            key: "sortable_date",
            value: "1970"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "satyajit-ray"
        },
        {
            key: "awardcategory",
            value: [
                {
                    key: null,
                    value: "Best Dialogue"
                }
            ]
        },
        {
            key: "awardImage",
            value: []
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "award_name",
            value: "Yugoslav Star with Golden Wreath"
        },
        {
            key: "id",
            value: "15180e62-06f3-464e-b4b2-a050b6672f6a"
        },
        {
            key: "issuing_authority",
            value: "Yugoslavia"
        },
        {
            key: "sortable_date",
            value: "1971"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "satyajit-ray"
        },
        {
            key: "awardcategory",
            value: []
        },
        {
            key: "awardImage",
            value: []
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "award_name",
            value: "National Film Awards"
        },
        {
            key: "id",
            value: "96959bbd-60a5-4577-aef9-027110cd4961"
        },
        {
            key: "issuing_authority",
            value: "Directorate of Film Festivals"
        },
        {
            key: "sortable_date",
            value: "1971"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "satyajit-ray"
        },
        {
            key: "awardcategory",
            value: [
                {
                    key: null,
                    value: "Best Feature Film"
                }
            ]
        },
        {
            key: "awardImage",
            value: []
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "award_name",
            value: "BFJA Awards"
        },
        {
            key: "id",
            value: "55b518da-e212-4992-a9f5-551e10af2268"
        },
        {
            key: "issuing_authority",
            value: "The Bengal Film Journalists' Association"
        },
        {
            key: "sortable_date",
            value: "1971"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "satyajit-ray"
        },
        {
            key: "awardcategory",
            value: [
                {
                    key: null,
                    value: "Best Indian Films"
                }
            ]
        },
        {
            key: "awardImage",
            value: []
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "award_name",
            value: "BFJA Awards"
        },
        {
            key: "id",
            value: "625e87bf-d01d-4b14-a6e2-3d0176e23f27"
        },
        {
            key: "issuing_authority",
            value: "The Bengal Film Journalists' Association"
        },
        {
            key: "sortable_date",
            value: "1971"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "satyajit-ray"
        },
        {
            key: "awardcategory",
            value: [
                {
                    key: null,
                    value: "Best Screenplay"
                }
            ]
        },
        {
            key: "awardImage",
            value: []
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "award_name",
            value: "BFJA Awards"
        },
        {
            key: "id",
            value: "8da8653d-0f7b-4f89-bf4d-86dd27a4188c"
        },
        {
            key: "issuing_authority",
            value: "The Bengal Film Journalists' Association"
        },
        {
            key: "sortable_date",
            value: "1971"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "satyajit-ray"
        },
        {
            key: "awardcategory",
            value: [
                {
                    key: null,
                    value: "Best Dialogue"
                }
            ]
        },
        {
            key: "awardImage",
            value: []
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "award_name",
            value: "FIPRESCI Critics' Award"
        },
        {
            key: "id",
            value: "5fdbd351-c214-478f-8818-c7982a090dfd"
        },
        {
            key: "issuing_authority",
            value: "FIPRESCI | 33rd Venice International Film Festival"
        },
        {
            key: "sortable_date",
            value: "1972"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "satyajit-ray"
        },
        {
            key: "awardcategory",
            value: [
                {
                    key: null,
                    value: "Best Film"
                }
            ]
        },
        {
            key: "awardImage",
            value: []
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "award_name",
            value: "National Film Awards"
        },
        {
            key: "id",
            value: "c42d7159-e819-4d72-b3c7-16ddd88605a3"
        },
        {
            key: "issuing_authority",
            value: "Directorate of Film Festivals"
        },
        {
            key: "sortable_date",
            value: "1972"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "satyajit-ray"
        },
        {
            key: "awardcategory",
            value: [
                {
                    key: null,
                    value: "Best Documentary"
                }
            ]
        },
        {
            key: "awardImage",
            value: []
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "award_name",
            value: "Doctor of Letters"
        },
        {
            key: "id",
            value: "1e276ce2-0869-4322-b891-2eb2539d90b1"
        },
        {
            key: "issuing_authority",
            value: "University of Delhi"
        },
        {
            key: "sortable_date",
            value: "1973"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "satyajit-ray"
        },
        {
            key: "awardcategory",
            value: []
        },
        {
            key: "awardImage",
            value: []
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "award_name",
            value: "Festival Honoree"
        },
        {
            key: "id",
            value: "1f32ceeb-8c8f-4a90-a76b-886bfb448b8d"
        },
        {
            key: "issuing_authority",
            value: "9th Chicago International Film Festival"
        },
        {
            key: "sortable_date",
            value: "1973"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "satyajit-ray"
        },
        {
            key: "awardcategory",
            value: []
        },
        {
            key: "awardImage",
            value: []
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "award_name",
            value: "BFJA Awards"
        },
        {
            key: "id",
            value: "d5b2dbbf-241c-42dd-89f6-b5785b6e608b"
        },
        {
            key: "issuing_authority",
            value: "The Bengal Film Journalists' Association"
        },
        {
            key: "sortable_date",
            value: "1973"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "satyajit-ray"
        },
        {
            key: "awardcategory",
            value: [
                {
                    key: null,
                    value: "Best Indian Films"
                }
            ]
        },
        {
            key: "awardImage",
            value: []
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "award_name",
            value: "National Film Awards"
        },
        {
            key: "id",
            value: "89d56d50-5e5c-479e-8ad7-e568811d84ff"
        },
        {
            key: "issuing_authority",
            value: "Directorate of Film Festivals"
        },
        {
            key: "sortable_date",
            value: "1973"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "satyajit-ray"
        },
        {
            key: "awardcategory",
            value: [
                {
                    key: null,
                    value: "Best Feature Film in Bengali"
                }
            ]
        },
        {
            key: "awardImage",
            value: []
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "award_name",
            value: "National Film Awards"
        },
        {
            key: "id",
            value: "41413252-2afb-4187-8279-915fefc05355"
        },
        {
            key: "issuing_authority",
            value: "Directorate of Film Festivals"
        },
        {
            key: "sortable_date",
            value: "1973"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "satyajit-ray"
        },
        {
            key: "awardcategory",
            value: [
                {
                    key: null,
                    value: "Best Music Director"
                }
            ]
        },
        {
            key: "awardImage",
            value: []
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "award_name",
            value: "West Bengal Government Film Awards"
        },
        {
            key: "id",
            value: "f6f9a97b-7387-4a25-b162-879d2973c646"
        },
        {
            key: "issuing_authority",
            value: "Government of West Bengal"
        },
        {
            key: "sortable_date",
            value: "1973"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "satyajit-ray"
        },
        {
            key: "awardcategory",
            value: [
                {
                    key: null,
                    value: "Best Film"
                }
            ]
        },
        {
            key: "awardImage",
            value: []
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "award_name",
            value: "Golden Bear"
        },
        {
            key: "id",
            value: "69de54d1-878a-4b73-a643-d386077ae8f4"
        },
        {
            key: "issuing_authority",
            value: "23rd Berlin International Film Festival"
        },
        {
            key: "sortable_date",
            value: "1973"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "satyajit-ray"
        },
        {
            key: "awardcategory",
            value: [
                {
                    key: null,
                    value: "Best Film"
                }
            ]
        },
        {
            key: "awardImage",
            value: []
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "award_name",
            value: "Gold Hugo"
        },
        {
            key: "id",
            value: "82ab8014-4d9a-4cca-9f2f-f6485ee6373a"
        },
        {
            key: "issuing_authority",
            value: "9th Chicago International Film Festival"
        },
        {
            key: "sortable_date",
            value: "1973"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "satyajit-ray"
        },
        {
            key: "awardcategory",
            value: [
                {
                    key: null,
                    value: "Best Feature Film"
                }
            ]
        },
        {
            key: "awardImage",
            value: []
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "award_name",
            value: "Honorary Doctors"
        },
        {
            key: "id",
            value: "6ff372fa-1497-4297-a1a3-ecc84b3561f0"
        },
        {
            key: "issuing_authority",
            value: "Royal College of Art, London"
        },
        {
            key: "sortable_date",
            value: "1974"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "satyajit-ray"
        },
        {
            key: "awardcategory",
            value: []
        },
        {
            key: "awardImage",
            value: []
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "award_name",
            value: "National Film Awards"
        },
        {
            key: "id",
            value: "dcf02ce2-7f38-490e-9408-d10d35262509"
        },
        {
            key: "issuing_authority",
            value: "Directorate of Film Festivals"
        },
        {
            key: "sortable_date",
            value: "1974"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "satyajit-ray"
        },
        {
            key: "awardcategory",
            value: [
                {
                    key: null,
                    value: "Best Director"
                }
            ]
        },
        {
            key: "awardImage",
            value: []
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "award_name",
            value: "National Film Awards"
        },
        {
            key: "id",
            value: "0f7ff975-ede8-4845-ab59-5d4cc0fc946d"
        },
        {
            key: "issuing_authority",
            value: "Directorate of Film Festivals"
        },
        {
            key: "sortable_date",
            value: "1974"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "satyajit-ray"
        },
        {
            key: "awardcategory",
            value: [
                {
                    key: null,
                    value: "Best Screenplay"
                }
            ]
        },
        {
            key: "awardImage",
            value: []
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "award_name",
            value: "National Film Awards"
        },
        {
            key: "id",
            value: "b1d6d670-33c8-4762-bfe4-a14fbc825721"
        },
        {
            key: "issuing_authority",
            value: "Directorate of Film Festivals"
        },
        {
            key: "sortable_date",
            value: "1974"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "satyajit-ray"
        },
        {
            key: "awardcategory",
            value: [
                {
                    key: null,
                    value: "Best Feature Film in Bengali"
                }
            ]
        },
        {
            key: "awardImage",
            value: []
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "award_name",
            value: "BFJA Awards"
        },
        {
            key: "id",
            value: "6be1536e-3ea1-4ce9-a829-0ff846a90fef"
        },
        {
            key: "issuing_authority",
            value: "The Bengal Film Journalists' Association"
        },
        {
            key: "sortable_date",
            value: "1974"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "satyajit-ray"
        },
        {
            key: "awardcategory",
            value: [
                {
                    key: null,
                    value: "Best Indian Films"
                }
            ]
        },
        {
            key: "awardImage",
            value: []
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "award_name",
            value: "West Bengal Government Film Awards"
        },
        {
            key: "id",
            value: "eb99a6b5-2de8-4fdc-8032-e9b84c9362c9"
        },
        {
            key: "issuing_authority",
            value: "Government of West Bengal"
        },
        {
            key: "sortable_date",
            value: "1974"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "satyajit-ray"
        },
        {
            key: "awardcategory",
            value: [
                {
                    key: null,
                    value: "Best Film"
                }
            ]
        },
        {
            key: "awardImage",
            value: []
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "award_name",
            value: "West Bengal Government Film Awards"
        },
        {
            key: "id",
            value: "82482da9-9438-4c99-810e-97bd2bed4acc"
        },
        {
            key: "issuing_authority",
            value: "Government of West Bengal"
        },
        {
            key: "sortable_date",
            value: "1974"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "satyajit-ray"
        },
        {
            key: "awardcategory",
            value: [
                {
                    key: null,
                    value: "Best Direction"
                }
            ]
        },
        {
            key: "awardImage",
            value: []
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "award_name",
            value: "West Bengal Government Film Awards"
        },
        {
            key: "id",
            value: "a76f3930-7be8-4bff-aa19-81f398c88c58"
        },
        {
            key: "issuing_authority",
            value: "Government of West Bengal"
        },
        {
            key: "sortable_date",
            value: "1974"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "satyajit-ray"
        },
        {
            key: "awardcategory",
            value: [
                {
                    key: null,
                    value: "Best Screenplay"
                }
            ]
        },
        {
            key: "awardImage",
            value: []
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "award_name",
            value: "Golden Statue"
        },
        {
            key: "id",
            value: "b795bfe4-bbe8-40ff-9a1d-07ee9b571458"
        },
        {
            key: "issuing_authority",
            value: "10th Tehran International Festival of Films for Children and Young Adults"
        },
        {
            key: "sortable_date",
            value: "1975"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "satyajit-ray"
        },
        {
            key: "awardcategory",
            value: [
                {
                    key: null,
                    value: "Best Live Feature Film"
                }
            ]
        },
        {
            key: "awardImage",
            value: []
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "award_name",
            value: "National Film Awards"
        },
        {
            key: "id",
            value: "b88dc9ed-8b28-4e37-b11b-d2d184c5ab23"
        },
        {
            key: "issuing_authority",
            value: "Directorate of Film Festivals"
        },
        {
            key: "sortable_date",
            value: "1975"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "satyajit-ray"
        },
        {
            key: "awardcategory",
            value: [
                {
                    key: null,
                    value: "Best Director"
                }
            ]
        },
        {
            key: "awardImage",
            value: []
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "award_name",
            value: "West Bengal Government Film Awards"
        },
        {
            key: "id",
            value: "cbbc1e29-94c0-4803-a85c-c47b31078c09"
        },
        {
            key: "issuing_authority",
            value: "Government of West Bengal"
        },
        {
            key: "sortable_date",
            value: "1975"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "satyajit-ray"
        },
        {
            key: "awardcategory",
            value: [
                {
                    key: null,
                    value: "Best Film"
                }
            ]
        },
        {
            key: "awardImage",
            value: []
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "award_name",
            value: "West Bengal Government Film Awards"
        },
        {
            key: "id",
            value: "47b33f22-3ea8-43f8-bebe-a97438ff7f64"
        },
        {
            key: "issuing_authority",
            value: "Government of West Bengal"
        },
        {
            key: "sortable_date",
            value: "1975"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "satyajit-ray"
        },
        {
            key: "awardcategory",
            value: [
                {
                    key: null,
                    value: "Best Direction"
                }
            ]
        },
        {
            key: "awardImage",
            value: []
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "award_name",
            value: "West Bengal Government Film Awards"
        },
        {
            key: "id",
            value: "61d08058-29e7-4666-8155-c0191a38db16"
        },
        {
            key: "issuing_authority",
            value: "Government of West Bengal"
        },
        {
            key: "sortable_date",
            value: "1975"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "satyajit-ray"
        },
        {
            key: "awardcategory",
            value: [
                {
                    key: null,
                    value: "Best Screenplay"
                }
            ]
        },
        {
            key: "awardImage",
            value: []
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "award_name",
            value: "Padma Vibhushan"
        },
        {
            key: "id",
            value: "ac6f0164-bab0-4329-a0da-50b55c51850b"
        },
        {
            key: "issuing_authority",
            value: "Government of India"
        },
        {
            key: "sortable_date",
            value: "1976"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "satyajit-ray"
        },
        {
            key: "awardcategory",
            value: []
        },
        {
            key: "awardImage",
            value: []
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "award_name",
            value: "Desikottam"
        },
        {
            key: "id",
            value: "5e19d810-e543-45ee-a948-14b0944e77ee"
        },
        {
            key: "issuing_authority",
            value: "Visva Bharati University"
        },
        {
            key: "sortable_date",
            value: "1976"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "satyajit-ray"
        },
        {
            key: "awardcategory",
            value: []
        },
        {
            key: "awardImage",
            value: []
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "award_name",
            value: "Norton Scholarship"
        },
        {
            key: "id",
            value: "aae97662-12ef-4647-962a-7ad46dd9a4b5"
        },
        {
            key: "issuing_authority",
            value: "Harvard University"
        },
        {
            key: "sortable_date",
            value: "1976"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "satyajit-ray"
        },
        {
            key: "awardcategory",
            value: []
        },
        {
            key: "awardImage",
            value: []
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "award_name",
            value: "Karlovy Vary Prize"
        },
        {
            key: "id",
            value: "689af3d9-7904-4027-88bb-032031f52dc3"
        },
        {
            key: "issuing_authority",
            value: "30th Karlovy Vary International Film Festival, Czech Republic"
        },
        {
            key: "sortable_date",
            value: "1976"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "satyajit-ray"
        },
        {
            key: "awardcategory",
            value: []
        },
        {
            key: "awardImage",
            value: []
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "award_name",
            value: "National Film Awards"
        },
        {
            key: "id",
            value: "788748fe-602f-471b-aa91-404bbc415f58"
        },
        {
            key: "issuing_authority",
            value: "Directorate of Film Festivals"
        },
        {
            key: "sortable_date",
            value: "1977"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "satyajit-ray"
        },
        {
            key: "awardcategory",
            value: [
                {
                    key: null,
                    value: "Best Feature Film in Hindi"
                }
            ]
        },
        {
            key: "awardImage",
            value: []
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "award_name",
            value: "Filmfare Awards"
        },
        {
            key: "id",
            value: "36c72fcb-a110-4318-9fe7-ab6c750df909"
        },
        {
            key: "issuing_authority",
            value: "Filmfare"
        },
        {
            key: "sortable_date",
            value: "1977"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "satyajit-ray"
        },
        {
            key: "awardcategory",
            value: [
                {
                    key: null,
                    value: "Critics Award for Best Movie"
                }
            ]
        },
        {
            key: "awardImage",
            value: []
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "award_name",
            value: "Filmfare Awards"
        },
        {
            key: "id",
            value: "1378b4d2-8e74-4e90-921a-3baa62d759f7"
        },
        {
            key: "issuing_authority",
            value: "Filmfare"
        },
        {
            key: "sortable_date",
            value: "1977"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "satyajit-ray"
        },
        {
            key: "awardcategory",
            value: [
                {
                    key: null,
                    value: "Best Director"
                }
            ]
        },
        {
            key: "awardImage",
            value: []
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "award_name",
            value: "Filmfare Awards"
        },
        {
            key: "id",
            value: "64ddac41-6d0f-4fc1-afed-10438dcb255d"
        },
        {
            key: "issuing_authority",
            value: "Filmfare"
        },
        {
            key: "sortable_date",
            value: "1977"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "satyajit-ray"
        },
        {
            key: "awardcategory",
            value: [
                {
                    key: null,
                    value: "Best Supporting Actor"
                }
            ]
        },
        {
            key: "awardImage",
            value: []
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "award_name",
            value: "Doctor of Letters"
        },
        {
            key: "id",
            value: "0277b5b2-877e-4d46-88b0-7253b6420b0b"
        },
        {
            key: "issuing_authority",
            value: "Oxford"
        },
        {
            key: "sortable_date",
            value: "1978"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "satyajit-ray"
        },
        {
            key: "awardcategory",
            value: []
        },
        {
            key: "awardImage",
            value: []
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "award_name",
            value: "Special Award"
        },
        {
            key: "id",
            value: "e24f2c82-ac67-4ea2-83ff-ccbd3ed54332"
        },
        {
            key: "issuing_authority",
            value: "Berlin International Film Festival"
        },
        {
            key: "sortable_date",
            value: "1978"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "satyajit-ray"
        },
        {
            key: "awardcategory",
            value: []
        },
        {
            key: "awardImage",
            value: []
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "award_name",
            value: "National Film Awards"
        },
        {
            key: "id",
            value: "05f1268e-badd-463e-8c1f-c5b4e4bb411b"
        },
        {
            key: "issuing_authority",
            value: "Directorate of Film Festivals"
        },
        {
            key: "sortable_date",
            value: "1978"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "satyajit-ray"
        },
        {
            key: "awardcategory",
            value: [
                {
                    key: null,
                    value: "Best Children's Film"
                }
            ]
        },
        {
            key: "awardImage",
            value: []
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "award_name",
            value: "Special Award"
        },
        {
            key: "id",
            value: "ff326cc8-9c99-4fde-9f2a-af44bd178561"
        },
        {
            key: "issuing_authority",
            value: "Moscow International Film Festival"
        },
        {
            key: "sortable_date",
            value: "1979"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "satyajit-ray"
        },
        {
            key: "awardcategory",
            value: []
        },
        {
            key: "awardImage",
            value: []
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "award_name",
            value: "Best Feature Film Award"
        },
        {
            key: "id",
            value: "8e5b704a-c21a-40d1-955a-50045183508c"
        },
        {
            key: "issuing_authority",
            value: "3rd Hong Kong International Film Festival"
        },
        {
            key: "sortable_date",
            value: "1979"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "satyajit-ray"
        },
        {
            key: "awardcategory",
            value: [
                {
                    key: null,
                    value: " Best Feature Film"
                }
            ]
        },
        {
            key: "awardImage",
            value: []
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "award_name",
            value: "Doctor of Letters"
        },
        {
            key: "id",
            value: "3e3130e8-b0c4-47ba-965d-0720fb8ad538"
        },
        {
            key: "issuing_authority",
            value: "University of Burdwan"
        },
        {
            key: "sortable_date",
            value: "1980"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "satyajit-ray"
        },
        {
            key: "awardcategory",
            value: []
        },
        {
            key: "awardImage",
            value: []
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "award_name",
            value: "Doctor of Letters"
        },
        {
            key: "id",
            value: "62102bf0-242d-4b89-8c89-e8fb532b5b3c"
        },
        {
            key: "issuing_authority",
            value: "Jadavpur University"
        },
        {
            key: "sortable_date",
            value: "1980"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "satyajit-ray"
        },
        {
            key: "awardcategory",
            value: []
        },
        {
            key: "awardImage",
            value: []
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "award_name",
            value: "National Film Awards"
        },
        {
            key: "id",
            value: "5312b15f-6739-42a4-896e-b6f9e38243be"
        },
        {
            key: "issuing_authority",
            value: "Directorate of Film Festivals"
        },
        {
            key: "sortable_date",
            value: "1980"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "satyajit-ray"
        },
        {
            key: "awardcategory",
            value: [
                {
                    key: null,
                    value: "Best Music Director"
                }
            ]
        },
        {
            key: "awardImage",
            value: []
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "award_name",
            value: "National Film Awards"
        },
        {
            key: "id",
            value: "c3d5e73e-7d89-492f-91b7-4428a7fe1c77"
        },
        {
            key: "issuing_authority",
            value: "Directorate of Film Festivals"
        },
        {
            key: "sortable_date",
            value: "1980"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "satyajit-ray"
        },
        {
            key: "awardcategory",
            value: [
                {
                    key: null,
                    value: "Best Lyrics"
                }
            ]
        },
        {
            key: "awardImage",
            value: []
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "award_name",
            value: "National Film Awards"
        },
        {
            key: "id",
            value: "d2f37659-e28a-4898-a941-69ccf6929e63"
        },
        {
            key: "issuing_authority",
            value: "Directorate of Film Festivals"
        },
        {
            key: "sortable_date",
            value: "1980"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "satyajit-ray"
        },
        {
            key: "awardcategory",
            value: [
                {
                    key: null,
                    value: "Best Feature Film in Bengali"
                }
            ]
        },
        {
            key: "awardImage",
            value: []
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "award_name",
            value: "Wington Award"
        },
        {
            key: "id",
            value: "500f9d68-2005-4159-833e-a7f394670e0b"
        },
        {
            key: "issuing_authority",
            value: "27th BFI London Film Festival"
        },
        {
            key: "sortable_date",
            value: "1980"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "satyajit-ray"
        },
        {
            key: "awardcategory",
            value: []
        },
        {
            key: "awardImage",
            value: []
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "award_name",
            value: "Doctorate"
        },
        {
            key: "id",
            value: "0432d30a-f2df-4c11-a48f-d099ed4fc99c"
        },
        {
            key: "issuing_authority",
            value: "Banaras Hindu University"
        },
        {
            key: "sortable_date",
            value: "1981"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "satyajit-ray"
        },
        {
            key: "awardcategory",
            value: []
        },
        {
            key: "awardImage",
            value: []
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "award_name",
            value: "Doctor of Letters"
        },
        {
            key: "id",
            value: "c4128fa3-140a-42ef-b486-e6506709d036"
        },
        {
            key: "issuing_authority",
            value: "University of North Bengal"
        },
        {
            key: "sortable_date",
            value: "1981"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "satyajit-ray"
        },
        {
            key: "awardcategory",
            value: []
        },
        {
            key: "awardImage",
            value: []
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "award_name",
            value: "National Film Awards"
        },
        {
            key: "id",
            value: "8d7cdac2-d4ef-41d7-9a82-28f2f4ea45b3"
        },
        {
            key: "issuing_authority",
            value: "Directorate of Film Festivals"
        },
        {
            key: "sortable_date",
            value: "1981"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "satyajit-ray"
        },
        {
            key: "awardcategory",
            value: [
                {
                    key: null,
                    value: "Special Jury Award"
                }
            ]
        },
        {
            key: "awardImage",
            value: []
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "award_name",
            value: "Hommage à Satyajit Ray"
        },
        {
            key: "id",
            value: "51db7f4e-ddd7-4327-b206-cbd12fb84e58"
        },
        {
            key: "issuing_authority",
            value: "35th Cannes Film Festival"
        },
        {
            key: "sortable_date",
            value: "1982"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "satyajit-ray"
        },
        {
            key: "awardcategory",
            value: []
        },
        {
            key: "awardImage",
            value: []
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "award_name",
            value: "Golden Lion Honorary Award"
        },
        {
            key: "id",
            value: "e74e02d0-e445-4039-b786-5841dedb0b17"
        },
        {
            key: "issuing_authority",
            value: "39th Venice Film Festival"
        },
        {
            key: "sortable_date",
            value: "1982"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "satyajit-ray"
        },
        {
            key: "awardcategory",
            value: []
        },
        {
            key: "awardImage",
            value: []
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "award_name",
            value: "Vidyasagar Smriti Puraskar (Literature)"
        },
        {
            key: "id",
            value: "fec81de1-ffc6-4c14-bb31-f882a42d06af"
        },
        {
            key: "issuing_authority",
            value: "Government of West Bengal"
        },
        {
            key: "sortable_date",
            value: "1982"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "satyajit-ray"
        },
        {
            key: "awardcategory",
            value: []
        },
        {
            key: "awardImage",
            value: []
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "award_name",
            value: "British Film Institute Fellowship"
        },
        {
            key: "id",
            value: "230d1d5e-8201-43dd-b1a7-ca2bef2904aa"
        },
        {
            key: "issuing_authority",
            value: "British Film Institute"
        },
        {
            key: "sortable_date",
            value: "1983"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "satyajit-ray"
        },
        {
            key: "awardcategory",
            value: []
        },
        {
            key: "awardImage",
            value: []
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "award_name",
            value: "Dadasaheb Phalke Award"
        },
        {
            key: "id",
            value: "5244d934-baa6-4d32-8713-6cd4112ed5e7"
        },
        {
            key: "issuing_authority",
            value: "Directorate of Film Festivals, India"
        },
        {
            key: "sortable_date",
            value: "1984"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "satyajit-ray"
        },
        {
            key: "awardcategory",
            value: []
        },
        {
            key: "awardImage",
            value: []
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "award_name",
            value: "Special Award, Cyprus"
        },
        {
            key: "id",
            value: "ae97b017-ab27-499b-8da9-17bbd7b998ba"
        },
        {
            key: "issuing_authority",
            value: ""
        },
        {
            key: "sortable_date",
            value: "1984"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "satyajit-ray"
        },
        {
            key: "awardcategory",
            value: []
        },
        {
            key: "awardImage",
            value: []
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "award_name",
            value: "National Film Awards"
        },
        {
            key: "id",
            value: "ba072db0-1b53-493b-9b97-1f95831b4534"
        },
        {
            key: "issuing_authority",
            value: "Directorate of Film Festivals"
        },
        {
            key: "sortable_date",
            value: "1984"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "satyajit-ray"
        },
        {
            key: "awardcategory",
            value: [
                {
                    key: null,
                    value: "Best Feature Film in Bengali"
                }
            ]
        },
        {
            key: "awardImage",
            value: []
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "award_name",
            value: "Palme d'Or, Nominated"
        },
        {
            key: "id",
            value: "cf844e74-22d3-40f0-8ffb-8d41704082f4"
        },
        {
            key: "issuing_authority",
            value: "37th Cannes Film Festival"
        },
        {
            key: "sortable_date",
            value: "1984"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "satyajit-ray"
        },
        {
            key: "awardcategory",
            value: [
                {
                    key: null,
                    value: "Best Film"
                }
            ]
        },
        {
            key: "awardImage",
            value: []
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "award_name",
            value: "BFJA Awards"
        },
        {
            key: "id",
            value: "aa295625-d1fa-4b2c-839e-683144f64c25"
        },
        {
            key: "issuing_authority",
            value: "The Bengal Film Journalists' Association"
        },
        {
            key: "sortable_date",
            value: "1984"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "satyajit-ray"
        },
        {
            key: "awardcategory",
            value: [
                {
                    key: null,
                    value: "Best Indian Films"
                }
            ]
        },
        {
            key: "awardImage",
            value: []
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "award_name",
            value: "Doctor of Literature"
        },
        {
            key: "id",
            value: "97e68ca7-aa0f-4c5b-a21f-7c2fb2d30a6b"
        },
        {
            key: "issuing_authority",
            value: "University of Calcutta"
        },
        {
            key: "sortable_date",
            value: "1985"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "satyajit-ray"
        },
        {
            key: "awardcategory",
            value: []
        },
        {
            key: "awardImage",
            value: []
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "award_name",
            value: "Soviet Land Nehru Award"
        },
        {
            key: "id",
            value: "1818a723-259f-466c-8422-bccbd7cc93ed"
        },
        {
            key: "issuing_authority",
            value: "Soviet Union"
        },
        {
            key: "sortable_date",
            value: "1985"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "satyajit-ray"
        },
        {
            key: "awardcategory",
            value: []
        },
        {
            key: "awardImage",
            value: []
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "award_name",
            value: "Sangeet Natak Akademi Fellowship"
        },
        {
            key: "id",
            value: "9c46c991-f3c7-446f-b950-16f1db06ff2e"
        },
        {
            key: "issuing_authority",
            value: "Sangeet Natak Akademi"
        },
        {
            key: "sortable_date",
            value: "1986"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "satyajit-ray"
        },
        {
            key: "awardcategory",
            value: []
        },
        {
            key: "awardImage",
            value: []
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "award_name",
            value: "Dadabhai Naoroji Memorial Award"
        },
        {
            key: "id",
            value: "355b435b-4fa6-4956-9089-49f4ba83f027"
        },
        {
            key: "issuing_authority",
            value: "Government of India (Ministry of Science and Technology)"
        },
        {
            key: "sortable_date",
            value: "1987"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "satyajit-ray"
        },
        {
            key: "awardcategory",
            value: []
        },
        {
            key: "awardImage",
            value: []
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "award_name",
            value: "Commander of the Legion of Honour"
        },
        {
            key: "id",
            value: "79b21b58-3a81-46d9-8fdf-a99abad0932d"
        },
        {
            key: "issuing_authority",
            value: "Government of France, President Francois Mitterrand"
        },
        {
            key: "sortable_date",
            value: "1987"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "satyajit-ray"
        },
        {
            key: "awardcategory",
            value: []
        },
        {
            key: "awardImage",
            value: []
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "award_name",
            value: "Doctor of Letters"
        },
        {
            key: "id",
            value: "aa44deca-5bf2-415a-8035-f7d400ca3c7e"
        },
        {
            key: "issuing_authority",
            value: "Rabindra Bharati University"
        },
        {
            key: "sortable_date",
            value: "1987"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "satyajit-ray"
        },
        {
            key: "awardcategory",
            value: []
        },
        {
            key: "awardImage",
            value: []
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "award_name",
            value: "National Film Awards"
        },
        {
            key: "id",
            value: "29886159-bc9a-446e-a145-45ac4f16cd2a"
        },
        {
            key: "issuing_authority",
            value: "Directorate of Film Festivals"
        },
        {
            key: "sortable_date",
            value: "1989"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "satyajit-ray"
        },
        {
            key: "awardcategory",
            value: [
                {
                    key: null,
                    value: "Best Feature Film in Bengali"
                }
            ]
        },
        {
            key: "awardImage",
            value: []
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "award_name",
            value: "Shiromani Award"
        },
        {
            key: "id",
            value: "e2ea20d6-fc9d-429d-8363-b363a14b7e33"
        },
        {
            key: "issuing_authority",
            value: ""
        },
        {
            key: "sortable_date",
            value: "1990"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "satyajit-ray"
        },
        {
            key: "awardcategory",
            value: []
        },
        {
            key: "awardImage",
            value: []
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "award_name",
            value: "Academy Honorary Award"
        },
        {
            key: "id",
            value: "bc67daf0-d2a3-4dd7-8000-d35be0cbf7ed"
        },
        {
            key: "issuing_authority",
            value: "Academy of Motion Picture Arts and Sciences (64th)"
        },
        {
            key: "sortable_date",
            value: "1991"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "satyajit-ray"
        },
        {
            key: "awardcategory",
            value: []
        },
        {
            key: "awardImage",
            value: []
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "award_name",
            value: "Special Achievement Award"
        },
        {
            key: "id",
            value: "7c007b1e-2d76-492f-80ac-cc5f28d7ed06"
        },
        {
            key: "issuing_authority",
            value: "Tokyo International Film Festival (4th)"
        },
        {
            key: "sortable_date",
            value: "1991"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "satyajit-ray"
        },
        {
            key: "awardcategory",
            value: []
        },
        {
            key: "awardImage",
            value: []
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "award_name",
            value: "National Film Awards"
        },
        {
            key: "id",
            value: "65618b28-83c8-47ca-bfb1-ba2cb8909a0b"
        },
        {
            key: "issuing_authority",
            value: "Directorate of Film Festivals"
        },
        {
            key: "sortable_date",
            value: "1991"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "satyajit-ray"
        },
        {
            key: "awardcategory",
            value: [
                {
                    key: null,
                    value: "Best Feature Film"
                }
            ]
        },
        {
            key: "awardImage",
            value: []
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "award_name",
            value: "National Film Awards"
        },
        {
            key: "id",
            value: "3d074657-9a98-4567-a81c-ac9d7f518b1e"
        },
        {
            key: "issuing_authority",
            value: "Directorate of Film Festivals"
        },
        {
            key: "sortable_date",
            value: "1991"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "satyajit-ray"
        },
        {
            key: "awardcategory",
            value: [
                {
                    key: null,
                    value: "Best Director"
                }
            ]
        },
        {
            key: "awardImage",
            value: []
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "award_name",
            value: "Akira Kurosawa Award"
        },
        {
            key: "id",
            value: "946e01ea-2bf7-43c6-a049-828f2f5bee49"
        },
        {
            key: "issuing_authority",
            value: "San Francisco International Film Festival (35th)"
        },
        {
            key: "sortable_date",
            value: "1992"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "satyajit-ray"
        },
        {
            key: "awardcategory",
            value: []
        },
        {
            key: "awardImage",
            value: []
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "award_name",
            value: "Bharat Ratna"
        },
        {
            key: "id",
            value: "ae8634c1-7387-476d-b878-deec89dc01f3"
        },
        {
            key: "issuing_authority",
            value: "Government of India"
        },
        {
            key: "sortable_date",
            value: "1992"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "satyajit-ray"
        },
        {
            key: "awardcategory",
            value: []
        },
        {
            key: "awardImage",
            value: []
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "award_name",
            value: "BFJA Awards"
        },
        {
            key: "id",
            value: "4a1bca59-8f0f-49ad-b5c0-b2e884c96a82"
        },
        {
            key: "issuing_authority",
            value: "The Bengal Film Journalists' Association"
        },
        {
            key: "sortable_date",
            value: "1993"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "satyajit-ray"
        },
        {
            key: "awardcategory",
            value: [
                {
                    key: null,
                    value: "Best Director"
                }
            ]
        },
        {
            key: "awardImage",
            value: []
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "award_name",
            value: "BFJA Awards"
        },
        {
            key: "id",
            value: "7a9d0aaa-93df-4c87-8d46-ac0791e48756"
        },
        {
            key: "issuing_authority",
            value: "The Bengal Film Journalists' Association"
        },
        {
            key: "sortable_date",
            value: "1993"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "satyajit-ray"
        },
        {
            key: "awardcategory",
            value: [
                {
                    key: null,
                    value: "Best Indian Films"
                }
            ]
        },
        {
            key: "awardImage",
            value: []
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "award_name",
            value: "BFJA Awards"
        },
        {
            key: "id",
            value: "da33cdef-0c9c-4052-8960-c70d3bfafb48"
        },
        {
            key: "issuing_authority",
            value: "The Bengal Film Journalists' Association"
        },
        {
            key: "sortable_date",
            value: "1993"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "satyajit-ray"
        },
        {
            key: "awardcategory",
            value: [
                {
                    key: null,
                    value: "Best Screenplay"
                }
            ]
        },
        {
            key: "awardImage",
            value: []
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "award_name",
            value: "BFJA Awards"
        },
        {
            key: "id",
            value: "157574d2-f0f6-42f7-b26d-2217c4f4b920"
        },
        {
            key: "issuing_authority",
            value: "The Bengal Film Journalists' Association"
        },
        {
            key: "sortable_date",
            value: "1993"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "satyajit-ray"
        },
        {
            key: "awardcategory",
            value: [
                {
                    key: null,
                    value: "Best Original Story"
                }
            ]
        },
        {
            key: "awardImage",
            value: []
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "award_name",
            value: "BFJA Awards"
        },
        {
            key: "id",
            value: "fa0f7ead-4371-46ee-8e04-5a9ee886370a"
        },
        {
            key: "issuing_authority",
            value: "The Bengal Film Journalists' Association"
        },
        {
            key: "sortable_date",
            value: "1993"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "satyajit-ray"
        },
        {
            key: "awardcategory",
            value: [
                {
                    key: null,
                    value: "Best Music Director"
                }
            ]
        },
        {
            key: "awardImage",
            value: []
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "award_name",
            value: "BFJA Awards"
        },
        {
            key: "id",
            value: "d1fc747a-e331-4501-a8c5-30fc28858c0a"
        },
        {
            key: "issuing_authority",
            value: "The Bengal Film Journalists' Association"
        },
        {
            key: "sortable_date",
            value: "1993"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "satyajit-ray"
        },
        {
            key: "awardcategory",
            value: [
                {
                    key: null,
                    value: "Best Lyricist"
                }
            ]
        },
        {
            key: "awardImage",
            value: []
        }
    ]
]
export const skk_awardsData=[
    [
        {
            "key": "Exhibition",
            "value": null,
            "display": "Exhibition"
        },
        {
            "key": "award_name",
            "value": "National Film Awards",
            "display": "Award name"
        },
        {
            "key": "id",
            "value": "c9403a2f-e6ee-4f2c-9421-dc403de425b2",
            "display": "Id"
        },
        {
            "key": "issuing_authority",
            "value": "Directorate of Film Festivals",
            "display": "Issuing authority"
        },
        {
            "key": "sortable_date",
            "value": "1977",
            "display": "Sortable date"
        },
        {
            "key": "type",
            "value": null,
            "display": "Type"
        },
        {
            "key": "winner",
            "value": "shatranj-ke-khilari-1977",
            "display": "Winner"
        },
        {
            "key": "awardcategory",
            "value": [{
                "key":"award",
                "value":"Best Feature Film in Hindi"
            }],
            "display": "Award category"
        },
        {
            "key": "awardImage",
            "value": [],
            "display": "Award image"
        }
    ],
    [
        {
            "key": "Exhibition",
            "value": null,
            "display": "Exhibition"
        },
        {
            "key": "award_name",
            "value": "Filmfare Awards",
            "display": "Award name"
        },
        {
            "key": "id",
            "value": "5c6b6c6d-84f6-4ae2-ac6b-a1366186a27b",
            "display": "Id"
        },
        {
            "key": "issuing_authority",
            "value": "Filmfare - The Times Group",
            "display": "Issuing authority"
        },
        {
            "key": "sortable_date",
            "value": "1978",
            "display": "Sortable date"
        },
        {
            "key": "type",
            "value": null,
            "display": "Type"
        },
        {
            "key": "winner",
            "value": "shatranj-ke-khilari-1977",
            "display": "Winner"
        },
        {
            "key": "awardcategory",
            "value": [{
                "key":"award",
                "value":"Critics Award for Best Movie "
            },{
                "key":"award",
                "value":"Best Director"
            }
        ],
            "display": "Award category"
        },
        {
            "key": "awardImage",
            "value": [],
            "display": "Award image"
        }
    ],
    [
        {
            "key": "Exhibition",
            "value": null,
            "display": "Exhibition"
        },
        {
            "key": "award_name",
            "value": "Filmfare Awards",
            "display": "Award name"
        },
        {
            "key": "id",
            "value": "32b77fd2-82a3-4cdd-ab1c-0336b6656977",
            "display": "Id"
        },
        {
            "key": "issuing_authority",
            "value": "Filmfare - The Times Group",
            "display": "Issuing authority"
        },
        {
            "key": "sortable_date",
            "value": "1979",
            "display": "Sortable date"
        },
        {
            "key": "type",
            "value": null,
            "display": "Type"
        },
        {
            "key": "winner",
            "value": "shatranj-ke-khilari-1977",
            "display": "Winner"
        },
        {
            "key": "awardcategory",
            "value": [{
                "key":"award",
                "value":"Best Director"
            }],
            "display": "Award category"
        },
        {
            "key": "awardImage",
            "value": [
                {
                    "key": "filmfare-a-times-of-india-publication-1978-0000222",
                    "value": "https://tuliresearchcentre.canto.global/direct/image/7ue5385hed4nddkj6tgafo842b/qroioW-9j8uzgwqTzyLbPy80Wy0/m800/800",
                    "date_of_artwork": "",
                    "wnn": "0000222",
                    "accession_number": "Filmfare",
                    "name_of_publication": "Filmfare: A Times of India Publication",
                    "publication_year": "1-15 April 1978",
                    "sortable_year_of_publication": "1978",
                    "artist_name": "",
                    "medium_of_artwork": "",
                    "revised_medium": ""
                }
            ],
            "display": "Award image"
        }
    ],
    [
        {
            "key": "Exhibition",
            "value": null,
            "display": "Exhibition"
        },
        {
            "key": "award_name",
            "value": "Filmfare Awards",
            "display": "Award name"
        },
        {
            "key": "id",
            "value": "dbbf97e0-b40a-4fc9-9f72-4a1b0d83baff",
            "display": "Id"
        },
        {
            "key": "issuing_authority",
            "value": "Filmfare - The Times Group",
            "display": "Issuing authority"
        },
        {
            "key": "sortable_date",
            "value": "1979",
            "display": "Sortable date"
        },
        {
            "key": "type",
            "value": null,
            "display": "Type"
        },
        {
            "key": "winner",
            "value": "shatranj-ke-khilari-1977",
            "display": "Winner"
        },
        {
            "key": "awardcategory",
            "value": [{
                "key":"award",
                "value":"Best Supporting Actor"
            }],
            "display": "Award category"
        },
        {
            "key": "awardImage",
            "value": [{
                "key": "filmfare-a-times-of-india-publication-1978-0000222",
                "value": "https://tuliresearchcentre.canto.global/direct/image/7ue5385hed4nddkj6tgafo842b/qroioW-9j8uzgwqTzyLbPy80Wy0/m800/800",
                "date_of_artwork": "",
                "wnn": "0000222",
                "accession_number": "Filmfare",
                "name_of_publication": "Filmfare: A Times of India Publication",
                "publication_year": "1-15 April 1978",
                "sortable_year_of_publication": "1978",
                "artist_name": "",
                "medium_of_artwork": "",
                "revised_medium": ""
            }],
            "display": "Award image"
        }
    ]
]

export const mughal_awardsData=[
    [
        {
            "key": "Exhibition",
            "value": null,
            "display": "Exhibition"
        },
        {
            "key": "award_name",
            "value": "National Film Awards",
            "display": "Award name"
        },
        {
            "key": "id",
            "value": "c9403a2f-e6ee-4f2c-9421-dc403de425b2",
            "display": "Id"
        },
        {
            "key": "issuing_authority",
            "value": "Directorate of Film Festivals",
            "display": "Issuing authority"
        },
        {
            "key": "sortable_date",
            "value": "1977",
            "display": "Sortable date"
        },
        {
            "key": "type",
            "value": null,
            "display": "Type"
        },
        {
            "key": "winner",
            "value": "shatranj-ke-khilari-1977",
            "display": "Winner"
        },
        {
            "key": "awardcategory",
            "value": [{
                "key":"award",
                "value":"Best Feature Film in Hindi"
            }],
            "display": "Award category"
        },
        {
            "key": "awardImage",
            "value": [],
            "display": "Award image"
        }
    ],
    [
        {
            "key": "Exhibition",
            "value": null,
            "display": "Exhibition"
        },
        {
            "key": "award_name",
            "value": "Filmfare Awards",
            "display": "Award name"
        },
        {
            "key": "id",
            "value": "5c6b6c6d-84f6-4ae2-ac6b-a1366186a27b",
            "display": "Id"
        },
        {
            "key": "issuing_authority",
            "value": "Filmfare - The Times Group",
            "display": "Issuing authority"
        },
        {
            "key": "sortable_date",
            "value": "1978",
            "display": "Sortable date"
        },
        {
            "key": "type",
            "value": null,
            "display": "Type"
        },
        {
            "key": "winner",
            "value": "shatranj-ke-khilari-1977",
            "display": "Winner"
        },
        {
            "key": "awardcategory",
            "value": [{
                "key":"award",
                "value":"Critics Award for Best Movie "
            },{
                "key":"award",
                "value":"Best Director"
            }
        ],
            "display": "Award category"
        },
        {
            "key": "awardImage",
            "value": [],
            "display": "Award image"
        }
    ],
    [
        {
            "key": "Exhibition",
            "value": null,
            "display": "Exhibition"
        },
        {
            "key": "award_name",
            "value": "Filmfare Awards",
            "display": "Award name"
        },
        {
            "key": "id",
            "value": "32b77fd2-82a3-4cdd-ab1c-0336b6656977",
            "display": "Id"
        },
        {
            "key": "issuing_authority",
            "value": "Filmfare - The Times Group",
            "display": "Issuing authority"
        },
        {
            "key": "sortable_date",
            "value": "1979",
            "display": "Sortable date"
        },
        {
            "key": "type",
            "value": null,
            "display": "Type"
        },
        {
            "key": "winner",
            "value": "shatranj-ke-khilari-1977",
            "display": "Winner"
        },
        {
            "key": "awardcategory",
            "value": [{
                "key":"award",
                "value":"Best Director"
            }],
            "display": "Award category"
        },
        {
            "key": "awardImage",
            "value": [
                {
                    "key": "filmfare-a-times-of-india-publication-1978-0000222",
                    "value": "https://tuliresearchcentre.canto.global/direct/image/7ue5385hed4nddkj6tgafo842b/qroioW-9j8uzgwqTzyLbPy80Wy0/m800/800",
                    "date_of_artwork": "",
                    "wnn": "0000222",
                    "accession_number": "Filmfare",
                    "name_of_publication": "Filmfare: A Times of India Publication",
                    "publication_year": "1-15 April 1978",
                    "sortable_year_of_publication": "1978",
                    "artist_name": "",
                    "medium_of_artwork": "",
                    "revised_medium": ""
                }
            ],
            "display": "Award image"
        }
    ],
    [
        {
            "key": "Exhibition",
            "value": null,
            "display": "Exhibition"
        },
        {
            "key": "award_name",
            "value": "Filmfare Awards",
            "display": "Award name"
        },
        {
            "key": "id",
            "value": "dbbf97e0-b40a-4fc9-9f72-4a1b0d83baff",
            "display": "Id"
        },
        {
            "key": "issuing_authority",
            "value": "Filmfare - The Times Group",
            "display": "Issuing authority"
        },
        {
            "key": "sortable_date",
            "value": "1979",
            "display": "Sortable date"
        },
        {
            "key": "type",
            "value": null,
            "display": "Type"
        },
        {
            "key": "winner",
            "value": "shatranj-ke-khilari-1977",
            "display": "Winner"
        },
        {
            "key": "awardcategory",
            "value": [{
                "key":"award",
                "value":"Best Supporting Actor"
            }],
            "display": "Award category"
        },
        {
            "key": "awardImage",
            "value": [],
            "display": "Award image"
        }
    ]
]

export const raj_kapoor_awardsData=[
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "bibliographic_caption",
            value: "Certificate of Merit | International Film Festival of India"
        },
        {
            key: "award_name",
            value: "Certificate of Merit"
        },
        {
            key: "id",
            value: "dedbb065-5327-4a68-be84-39a0d72851bd"
        },
        {
            key: "issuing_authority",
            value: "International Film Festival of India"
        },
        {
            key: "sortable_date",
            value: "1952"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "raj-kapoor"
        },
        {
            key: "awardcategory",
            value: [ ]
        },
        {
            key: "awardImage",
            value: []
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "bibliographic_caption",
            value: "Filmfare Award | Best Film | Filmfare"
        },
        {
            key: "award_name",
            value: "Filmfare Award"
        },
        {
            key: "id",
            value: "dedbb065-5327-4a68-be84-39a0d72851bd"
        },
        {
            key: "issuing_authority",
            value: "Filmfare"
        },
        {
            key: "sortable_date",
            value: "1955"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "raj-kapoor"
        },
        {
            key: "awardcategory",
            value: "Best Film"
        },
        {
            key: "awardImage",
            value: [ ]
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "bibliographic_caption",
            value: "Filmfare Award | Best Actor in Supporting Role (David) | Filmfare"
        },
        {
            key: "award_name",
            value: "Filmfare Award"
        },
        {
            key: "id",
            value: "dedbb065-5327-4a68-be84-39a0d72851bd"
        },
        {
            key: "issuing_authority",
            value: "Filmfare"
        },
        {
            key: "sortable_date",
            value: "1955"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "raj-kapoor"
        },
        {
            key: "awardcategory",
            value: "Best Actor in Supporting Role (David)"
        },
        {
            key: "awardImage",
            value: [ ]
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "bibliographic_caption",
            value: "Filmfare Award | Best Cinematographer (Tara Dutt) | Filmfare"
        },
        {
            key: "award_name",
            value: "Filmfare Award"
        },
        {
            key: "id",
            value: "dedbb065-5327-4a68-be84-39a0d72851bd"
        },
        {
            key: "issuing_authority",
            value: "Filmfare"
        },
        {
            key: "sortable_date",
            value: "1955"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "raj-kapoor"
        },
        {
            key: "awardcategory",
            value: "Best Cinematographer (Tara Dutt)"
        },
        {
            key: "awardImage",
            value: [ ]
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "bibliographic_caption",
            value: "Special Mention (Baby Naaz) | Cannes International Film Festival"
        },
        {
            key: "award_name",
            value: ""
        },
        {
            key: "id",
            value: "dedbb065-5327-4a68-be84-39a0d72851bd"
        },
        {
            key: "issuing_authority",
            value: "Cannes International Film Festival"
        },
        {
            key: "sortable_date",
            value: "1955"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "raj-kapoor"
        },
        {
            key: "awardcategory",
            value: "Special Mention (Baby Naaz)"
        },
        {
            key: "awardImage",
            value: [ ]
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "bibliographic_caption",
            value: "Grand Prix Music (Shankar-Jaikishan) | South East Asian Film Fewstival"
        },
        {
            key: "award_name",
            value: ""
        },
        {
            key: "id",
            value: "dedbb065-5327-4a68-be84-39a0d72851bd"
        },
        {
            key: "issuing_authority",
            value: "South East Asian Film Fewstival"
        },
        {
            key: "sortable_date",
            value: "1955"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "raj-kapoor"
        },
        {
            key: "awardcategory",
            value: "Grand Prix Music (Shankar-Jaikishan)"
        },
        {
            key: "awardImage",
            value: [ ]
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "bibliographic_caption",
            value: "Crystal Globe Award | Karlovy Vary International Film Festival"
        },
        {
            key: "award_name",
            value: ""
        },
        {
            key: "id",
            value: "dedbb065-5327-4a68-be84-39a0d72851bd"
        },
        {
            key: "issuing_authority",
            value: "Karlovy Vary International Film Festival"
        },
        {
            key: "sortable_date",
            value: "1956"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "raj-kapoor"
        },
        {
            key: "awardcategory",
            value: "Crystal Globe Award"
        },
        {
            key: "awardImage",
            value: [ ]
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "bibliographic_caption",
            value: "National Film Awards | Regional Certificate of Merit (Hindi) | Directorate of Film Festivals India"
        },
        {
            key: "award_name",
            value: "National Film Awards"
        },
        {
            key: "id",
            value: "dedbb065-5327-4a68-be84-39a0d72851bd"
        },
        {
            key: "issuing_authority",
            value: "Directorate of Film Festivals India"
        },
        {
            key: "sortable_date",
            value: "1956"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "raj-kapoor"
        },
        {
            key: "awardcategory",
            value: "Regional Certificate of Merit (Hindi)"
        },
        {
            key: "awardImage",
            value: [ ]
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "bibliographic_caption",
            value: "Filmfare Award | Best Cinematographer (Radhu Karmakar) | Filmfare"
        },
        {
            key: "award_name",
            value: "Filmfare Award"
        },
        {
            key: "id",
            value: "dedbb065-5327-4a68-be84-39a0d72851bd"
        },
        {
            key: "issuing_authority",
            value: "Filmfare"
        },
        {
            key: "sortable_date",
            value: "1957"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "raj-kapoor"
        },
        {
            key: "awardcategory",
            value: "Best Cinematographer (Radhu Karmakar)"
        },
        {
            key: "awardImage",
            value: [ ]
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "bibliographic_caption",
            value: "Filmfare Award | Best Editor (G.G. Mayekar) | Filmfare"
        },
        {
            key: "award_name",
            value: "Filmfare Award"
        },
        {
            key: "id",
            value: "dedbb065-5327-4a68-be84-39a0d72851bd"
        },
        {
            key: "issuing_authority",
            value: "Filmfare"
        },
        {
            key: "sortable_date",
            value: "1957"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "raj-kapoor"
        },
        {
            key: "awardcategory",
            value: "Best Editor (G.G. Mayekar)"
        },
        {
            key: "awardImage",
            value: [ ]
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "bibliographic_caption",
            value: "National Film Awards | Regional Certificate of Merit (Bengali) | Directorate of Film Festivals India"
        },
        {
            key: "award_name",
            value: "National Film Awards"
        },
        {
            key: "id",
            value: "dedbb065-5327-4a68-be84-39a0d72851bd"
        },
        {
            key: "issuing_authority",
            value: "Directorate of Film Festivals India"
        },
        {
            key: "sortable_date",
            value: "1957"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "raj-kapoor"
        },
        {
            key: "awardcategory",
            value: "Regional Certificate of Merit (Bengali)"
        },
        {
            key: "awardImage",
            value: [ ]
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "bibliographic_caption",
            value: "Festival's Premier Award, Grand Prix | International Film Festival Karlovy Vary"
        },
        {
            key: "award_name",
            value: ""
        },
        {
            key: "id",
            value: "dedbb065-5327-4a68-be84-39a0d72851bd"
        },
        {
            key: "issuing_authority",
            value: "International Film Festival Karlovy Vary"
        },
        {
            key: "sortable_date",
            value: "1957"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "raj-kapoor"
        },
        {
            key: "awardcategory",
            value: "Festival's Premier Award, Grand Prix"
        },
        {
            key: "awardImage",
            value: [ ]
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "bibliographic_caption",
            value: "Award on Release | Union Government of Burma"
        },
        {
            key: "award_name",
            value: ""
        },
        {
            key: "id",
            value: "dedbb065-5327-4a68-be84-39a0d72851bd"
        },
        {
            key: "issuing_authority",
            value: "Union Government of Burma"
        },
        {
            key: "sortable_date",
            value: "1958"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "raj-kapoor"
        },
        {
            key: "awardcategory",
            value: "Award on Release"
        },
        {
            key: "awardImage",
            value: [ ]
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "bibliographic_caption",
            value: "Filmfare Award | Best Actor | Filmfare"
        },
        {
            key: "award_name",
            value: "Filmfare Award"
        },
        {
            key: "id",
            value: "dedbb065-5327-4a68-be84-39a0d72851bd"
        },
        {
            key: "issuing_authority",
            value: "Filmfare"
        },
        {
            key: "sortable_date",
            value: "1960"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "raj-kapoor"
        },
        {
            key: "awardcategory",
            value: "Best Actor"
        },
        {
            key: "awardImage",
            value: [ ]
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "bibliographic_caption",
            value: "National Film Awards | Regional Certificate of Merit (Hindi) | Directorate of Film Festivals India"
        },
        {
            key: "award_name",
            value: "Rashtriya Chal Chitra Saman Yogyata Parman Patra"
        },
        {
            key: "id",
            value: "dedbb065-5327-4a68-be84-39a0d72851bd"
        },
        {
            key: "issuing_authority",
            value: "Suchna aur Prasar Mantralaya"
        },
        {
            key: "sortable_date",
            value: "1961"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "raj-kapoor"
        },
        {
            key: "awardcategory",
            value: ""
        },
        {
            key: "awardImage",
            value: [ ]
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "bibliographic_caption",
            value: "Rashtriya Chal Chitra Saman Yogyata Parman Patra | Suchna aur Prasar Mantralaya"
        },
        {
            key: "award_name",
            value: "Filmfare Award"
        },
        {
            key: "id",
            value: "dedbb065-5327-4a68-be84-39a0d72851bd"
        },
        {
            key: "issuing_authority",
            value: "Filmfare"
        },
        {
            key: "sortable_date",
            value: "1962"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "raj-kapoor"
        },
        {
            key: "awardcategory",
            value: "Best Film"
        },
        {
            key: "awardImage",
            value: [ ]
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "bibliographic_caption",
            value: "Filmfare Award | Best Film | Filmfare"
        },
        {
            key: "award_name",
            value: "Filmfare Award"
        },
        {
            key: "id",
            value: "dedbb065-5327-4a68-be84-39a0d72851bd"
        },
        {
            key: "issuing_authority",
            value: "Filmfare"
        },
        {
            key: "sortable_date",
            value: "1962"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "raj-kapoor"
        },
        {
            key: "awardcategory",
            value: "Best Actor"
        },
        {
            key: "awardImage",
            value: [ ]
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "bibliographic_caption",
            value: "Filmfare Award | Best Film | Filmfare"
        },
        {
            key: "award_name",
            value: "Filmfare Award"
        },
        {
            key: "id",
            value: "dedbb065-5327-4a68-be84-39a0d72851bd"
        },
        {
            key: "issuing_authority",
            value: "Filmfare"
        },
        {
            key: "sortable_date",
            value: "1962"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "raj-kapoor"
        },
        {
            key: "awardcategory",
            value: "Best Editor (G.G. Mayekar)"
        },
        {
            key: "awardImage",
            value: [ ]
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "bibliographic_caption",
            value: "Filmfare Award | Best Editor (G.G. Mayekar) | Filmfare"
        },
        {
            key: "award_name",
            value: "BFJA Award"
        },
        {
            key: "id",
            value: "dedbb065-5327-4a68-be84-39a0d72851bd"
        },
        {
            key: "issuing_authority",
            value: "The Bengal Film Journalists Association"
        },
        {
            key: "sortable_date",
            value: "1964"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "raj-kapoor"
        },
        {
            key: "awardcategory",
            value: "Best Director"
        },
        {
            key: "awardImage",
            value: [ ]
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "bibliographic_caption",
            value: "Filmfare Award | Best Art Director (M.R. Achrekar) | Filmfare"
        },
        {
            key: "award_name",
            value: "BFJA Award"
        },
        {
            key: "id",
            value: "dedbb065-5327-4a68-be84-39a0d72851bd"
        },
        {
            key: "issuing_authority",
            value: "The Bengal Film Journalists Association"
        },
        {
            key: "sortable_date",
            value: "1964"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "raj-kapoor"
        },
        {
            key: "awardcategory",
            value: "Best Editor"
        },
        {
            key: "awardImage",
            value: [ ]
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "bibliographic_caption",
            value: "BFJA Award | Best Director | The Bengal Film Journalists Association"
        },
        {
            key: "award_name",
            value: "Certificate of Merit"
        },
        {
            key: "id",
            value: "dedbb065-5327-4a68-be84-39a0d72851bd"
        },
        {
            key: "issuing_authority",
            value: "Association of Film Editors Bombay"
        },
        {
            key: "sortable_date",
            value: "1964"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "raj-kapoor"
        },
        {
            key: "awardcategory",
            value: "Best Edited Film"
        },
        {
            key: "awardImage",
            value: [ ]
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "bibliographic_caption",
            value: "BFJA Award | Best Editor | The Bengal Film Journalists Association"
        },
        {
            key: "award_name",
            value: "BFJA Award"
        },
        {
            key: "id",
            value: "dedbb065-5327-4a68-be84-39a0d72851bd"
        },
        {
            key: "issuing_authority",
            value: "The Bengal Film Journalists' Association"
        },
        {
            key: "sortable_date",
            value: "1964"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "raj-kapoor"
        },
        {
            key: "awardcategory",
            value: "Certificate of Merit"
        },
        {
            key: "awardImage",
            value: [ ]
        }
    ],
]

export const amitabh_bachchan_awardsData=[
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "bibliographic_caption",
            value: "Saraswati Award"
        },
        {
            key: "award_name",
            value: "Saraswati Award"
        },
        {
            key: "id",
            value: ""
        },
        {
            key: "issuing_authority",
            value: ""
        },
        {
            key: "sortable_date",
            value: "1970"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "amitabh-bachchan"
        },
        {
            key: "awardcategory",
            value: [ ]
        },
        {
            key: "awardImage",
            value: [ ]
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "bibliographic_caption",
            value: "Filmfare Award | Best Supporting Actor | The Times Group"
        },
        {
            key: "award_name",
            value: "Filmfare Award"
        },
        {
            key: "id",
            value: "dedbb065-5327-4a68-be84-39a0d72851bd"
        },
        {
            key: "issuing_authority",
            value: "The Times Group"
        },
        {
            key: "sortable_date",
            value: "1972"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "amitabh-bachchan"
        },
        {
            key: "awardcategory",
            value: "Best Supporting Actor"
        },
        {
            key: "awardImage",
            value: [ ]
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "bibliographic_caption",
            value: "Filmfare Award | Best Supporting Actor | The Times Group"
        },
        {
            key: "award_name",
            value: "Filmfare Award"
        },
        {
            key: "id",
            value: "dedbb065-5327-4a68-be84-39a0d72851bd"
        },
        {
            key: "issuing_authority",
            value: "The Times Group"
        },
        {
            key: "sortable_date",
            value: "1974"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "amitabh-bachchan"
        },
        {
            key: "awardcategory",
            value: "Best Supporting Actor"
        },
        {
            key: "awardImage",
            value: [ ]
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "bibliographic_caption",
            value: "BFJA Awards | Best Actor (Hindi) | Bengal Film Journalists Association"
        },
        {
            key: "award_name",
            value: "BFJA Awards"
        },
        {
            key: "id",
            value: "dedbb065-5327-4a68-be84-39a0d72851bd"
        },
        {
            key: "issuing_authority",
            value: "Bengal Film Journalists Association"
        },
        {
            key: "sortable_date",
            value: "1976"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "amitabh-bachchan"
        },
        {
            key: "awardcategory",
            value: "Best Actor (Hindi)"
        },
        {
            key: "awardImage",
            value: [ ]
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "bibliographic_caption",
            value: "Filmfare Award | Best Actor | The Times Group"
        },
        {
            key: "award_name",
            value: "Filmfare Award"
        },
        {
            key: "id",
            value: "dedbb065-5327-4a68-be84-39a0d72851bd"
        },
        {
            key: "issuing_authority",
            value: "The Times Group"
        },
        {
            key: "sortable_date",
            value: "1978"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "amitabh-bachchan"
        },
        {
            key: "awardcategory",
            value: "Best Actor"
        },
        {
            key: "awardImage",
            value: [ ]
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "bibliographic_caption",
            value: "Filmfare Award | Best Actor | The Times Group"
        },
        {
            key: "award_name",
            value: "Filmfare Award"
        },
        {
            key: "id",
            value: "dedbb065-5327-4a68-be84-39a0d72851bd"
        },
        {
            key: "issuing_authority",
            value: "The Times Group"
        },
        {
            key: "sortable_date",
            value: "1979"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "amitabh-bachchan"
        },
        {
            key: "awardcategory",
            value: "Best Actor"
        },
        {
            key: "awardImage",
            value: [ ]
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "bibliographic_caption",
            value: "Awadh Samman | Government of Uttar Pradesh"
        },
        {
            key: "award_name",
            value: "Awadh Samman"
        },
        {
            key: "id",
            value: "dedbb065-5327-4a68-be84-39a0d72851bd"
        },
        {
            key: "issuing_authority",
            value: "Government of Uttar Pradesh"
        },
        {
            key: "sortable_date",
            value: "1980"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "amitabh-bachchan"
        },
        {
            key: "awardcategory",
            value: ""
        },
        {
            key: "awardImage",
            value: [ ]
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "bibliographic_caption",
            value: "Padma Shri | Government of India"
        },
        {
            key: "award_name",
            value: "Padma Shri"
        },
        {
            key: "id",
            value: "dedbb065-5327-4a68-be84-39a0d72851bd"
        },
        {
            key: "issuing_authority",
            value: "Government of India"
        },
        {
            key: "sortable_date",
            value: "1984"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "amitabh-bachchan"
        },
        {
            key: "awardcategory",
            value: ""
        },
        {
            key: "awardImage",
            value: [ ]
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "bibliographic_caption",
            value: "Lifetime Achievement Award | Rotary Club Bombay"
        },
        {
            key: "award_name",
            value: "Lifetime Achievement Award"
        },
        {
            key: "id",
            value: "dedbb065-5327-4a68-be84-39a0d72851bd"
        },
        {
            key: "issuing_authority",
            value: "Rotary Club Bombay"
        },
        {
            key: "sortable_date",
            value: "1989"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "amitabh-bachchan"
        },
        {
            key: "awardcategory",
            value: ""
        },
        {
            key: "awardImage",
            value: [ ]
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "bibliographic_caption",
            value: "National Film Award | Best Actor | Government of India"
        },
        {
            key: "award_name",
            value: "National Film Awards"
        },
        {
            key: "id",
            value: "dedbb065-5327-4a68-be84-39a0d72851bd"
        },
        {
            key: "issuing_authority",
            value: "Government of India"
        },
        {
            key: "sortable_date",
            value: "1990"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "amitabh-bachchan"
        },
        {
            key: "awardcategory",
            value: "Best Actor"
        },
        {
            key: "awardImage",
            value: [ ]
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "bibliographic_caption",
            value: "Order of Afghanistan | Government of Afghanistan"
        },
        {
            key: "award_name",
            value: "Order of Afghanistan"
        },
        {
            key: "id",
            value: "dedbb065-5327-4a68-be84-39a0d72851bd"
        },
        {
            key: "issuing_authority",
            value: "Government of Afghanistan"
        },
        {
            key: "sortable_date",
            value: "1991"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "amitabh-bachchan"
        },
        {
            key: "awardcategory",
            value: ""
        },
        {
            key: "awardImage",
            value: [ ]
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "bibliographic_caption",
            value: "Filmfare Award | Lifetime Achievement Award | The Times Group"
        },
        {
            key: "award_name",
            value: "Filmfare Award"
        },
        {
            key: "id",
            value: "dedbb065-5327-4a68-be84-39a0d72851bd"
        },
        {
            key: "issuing_authority",
            value: "The Times Group"
        },
        {
            key: "sortable_date",
            value: "1991"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "amitabh-bachchan"
        },
        {
            key: "awardcategory",
            value: "Lifetime Achievement Award"
        },
        {
            key: "awardImage",
            value: [ ]
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "bibliographic_caption",
            value: "Filmfare Award | Best Actor | The Times Group"
        },
        {
            key: "award_name",
            value: "Filmfare Award"
        },
        {
            key: "id",
            value: "dedbb065-5327-4a68-be84-39a0d72851bd"
        },
        {
            key: "issuing_authority",
            value: "The Times Group"
        },
        {
            key: "sortable_date",
            value: "1992"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "amitabh-bachchan"
        },
        {
            key: "awardcategory",
            value: "Best Actor"
        },
        {
            key: "awardImage",
            value: [ ]
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "bibliographic_caption",
            value: "Yash Bharti | Government of Uttar Pradesh"
        },
        {
            key: "award_name",
            value: "Distinguished Alumni"
        },
        {
            key: "id",
            value: "dedbb065-5327-4a68-be84-39a0d72851bd"
        },
        {
            key: "issuing_authority",
            value: "Central University"
        },
        {
            key: "sortable_date",
            value: "1997"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "amitabh-bachchan"
        },
        {
            key: "awardcategory",
            value: ""
        },
        {
            key: "awardImage",
            value: []
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "bibliographic_caption",
            value: "Distinguished Alumni | Central University"
        },
        {
            key: "award_name",
            value: "Omega Award for Excellence"
        },
        {
            key: "id",
            value: "dedbb065-5327-4a68-be84-39a0d72851bd"
        },
        {
            key: "issuing_authority",
            value: ""
        },
        {
            key: "sortable_date",
            value: "1998"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "amitabh-bachchan"
        },
        {
            key: "awardcategory",
            value: "Lifetime Achievement Award"
        },
        {
            key: "awardImage",
            value: [ ]
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "bibliographic_caption",
            value: "Omega Award for Excellence | Lifetime Achievement Award"
        },
        {
            key: "award_name",
            value: "BBC Online Poll"
        },
        {
            key: "id",
            value: "dedbb065-5327-4a68-be84-39a0d72851bd"
        },
        {
            key: "issuing_authority",
            value: "BBC Network"
        },
        {
            key: "sortable_date",
            value: "1999"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "amitabh-bachchan"
        },
        {
            key: "awardcategory",
            value: "Greatest Star of Stage or Screen of the Millennium"
        },
        {
            key: "awardImage",
            value: [ ]
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "bibliographic_caption",
            value: "BBC Online Poll | Greatest Star of Stage or Screen of the Millennium | BBC Network"
        },
        {
            key: "award_name",
            value: "Filmfare Award"
        },
        {
            key: "id",
            value: "dedbb065-5327-4a68-be84-39a0d72851bd"
        },
        {
            key: "issuing_authority",
            value: "The Times Group"
        },
        {
            key: "sortable_date",
            value: "2000"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "amitabh-bachchan"
        },
        {
            key: "awardcategory",
            value: "Superstar of the Millenium"
        },
        {
            key: "awardImage",
            value: [ ]
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "bibliographic_caption",
            value: "Filmfare Award | Superstar of the Millenium | The Times Group"
        },
        {
            key: "award_name",
            value: "International Indian Film Academy Awards"
        },
        {
            key: "id",
            value: "dedbb065-5327-4a68-be84-39a0d72851bd"
        },
        {
            key: "issuing_authority",
            value: "Wizcraft International Entertainment Pvt Ltd"
        },
        {
            key: "sortable_date",
            value: "2000"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "amitabh-bachchan"
        },
        {
            key: "awardcategory",
            value: "IIFA Special Honorary Award"
        },
        {
            key: "awardImage",
            value: [ ]
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "bibliographic_caption",
            value: "International Indian Film Academy Awards | IIFA Special Honorary Award | Wizcraft International Entertainment Pvt Ltd"
        },
        {
            key: "award_name",
            value: "Stardust Award"
        },
        {
            key: "id",
            value: "dedbb065-5327-4a68-be84-39a0d72851bd"
        },
        {
            key: "issuing_authority",
            value: "Stardust Magazine"
        },
        {
            key: "sortable_date",
            value: "2000"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "amitabh-bachchan"
        },
        {
            key: "awardcategory",
            value: "Best Artist of Millenium"
        },
        {
            key: "awardImage",
            value: [ ]
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "bibliographic_caption",
            value: "Stardust Award | Best Artist of Millenium | Stardust Magazine"
        },
        {
            key: "award_name",
            value: "Indian Television Awards"
        },
        {
            key: "id",
            value: "dedbb065-5327-4a68-be84-39a0d72851bd"
        },
        {
            key: "issuing_authority",
            value: "Stardust Magazine"
        },
        {
            key: "sortable_date",
            value: "2000"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "amitabh-bachchan"
        },
        {
            key: "awardcategory",
            value: "Screen Videocon Awards: Best Anchor"
        },
        {
            key: "awardImage",
            value: [ ]
        }
    ],
    [
        {
            key: "Exhibition",
            value: null
        },
        {
            key: "bibliographic_caption",
            value: "Indian Television Awards | Screen Videocon Awards: Best Anchor"
        },
        {
            key: "award_name",
            value: "All India Critics Association Award"
        },
        {
            key: "id",
            value: "dedbb065-5327-4a68-be84-39a0d72851bd"
        },
        {
            key: "issuing_authority",
            value: "All India Crirics Association"
        },
        {
            key: "sortable_date",
            value: "2000"
        },
        {
            key: "type",
            value: null
        },
        {
            key: "winner",
            value: "amitabh-bachchan"
        },
        {
            key: "awardcategory",
            value: "Best Actor"
        },
        {
            key: "awardImage",
            value: [ ]
        }
    ],
]