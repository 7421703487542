//S3 Bucket osw-dev-images
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;

const essay1 = `${IMAGE_BASE_PATH}/ui/EventExhibition/PictorialSpace/BodyofWork/ExhibitionCatlouge/Essays/e1.jpg`
const essay2 = `${IMAGE_BASE_PATH}/ui/EventExhibition/PictorialSpace/BodyofWork/ExhibitionCatlouge/Essays/e2.jpg`
const essay3 = `${IMAGE_BASE_PATH}/ui/EventExhibition/PictorialSpace/BodyofWork/ExhibitionCatlouge/Essays/e3.jpg`
const essay4 = `${IMAGE_BASE_PATH}/ui/EventExhibition/PictorialSpace/BodyofWork/ExhibitionCatlouge/Essays/e4.jpg`

const ess1paint1 = `${IMAGE_BASE_PATH}/ui/EventExhibition/PictorialSpace/BodyofWork/ExhibitionCatlouge/Essays/Slider1/painter1.png`
const ess1paint2 = `${IMAGE_BASE_PATH}/ui/EventExhibition/PictorialSpace/BodyofWork/ExhibitionCatlouge/Essays/Slider1/painter2.png`
const ess1paint3 = `${IMAGE_BASE_PATH}/ui/EventExhibition/PictorialSpace/BodyofWork/ExhibitionCatlouge/Essays/Slider1/painter3.png`
const ess1paint4 = `${IMAGE_BASE_PATH}/ui/EventExhibition/PictorialSpace/BodyofWork/ExhibitionCatlouge/Essays/Slider1/painter4.png`
const ess1paint5 = `${IMAGE_BASE_PATH}/ui/EventExhibition/PictorialSpace/BodyofWork/ExhibitionCatlouge/Essays/Slider1/painter5.png`
const ess1paint6 = `${IMAGE_BASE_PATH}/ui/EventExhibition/PictorialSpace/BodyofWork/ExhibitionCatlouge/Essays/Slider1/painter6.png`
const ess1paint7 = `${IMAGE_BASE_PATH}/ui/EventExhibition/PictorialSpace/BodyofWork/ExhibitionCatlouge/Essays/Slider1/painter7.png`

const ess2paint1 = `${IMAGE_BASE_PATH}/ui/EventExhibition/PictorialSpace/BodyofWork/ExhibitionCatlouge/Essays/full2.png`
const ess3paint1 = `${IMAGE_BASE_PATH}/ui/EventExhibition/PictorialSpace/BodyofWork/ExhibitionCatlouge/Essays/full3.png`
const ess4paint1 = `${IMAGE_BASE_PATH}/ui/EventExhibition/PictorialSpace/BodyofWork/ExhibitionCatlouge/Essays/full4.png`

const PictorialSpaceImg = `${IMAGE_BASE_PATH}/ui/EventExhibition/PictorialSpace/BodyofWork/ExhibitionCatlouge/All/1.png`

export const pictorial_space_essay_data = [
  {
    key: "essayImages",
    value: [
      {
        key: "pictorial-space",
        value: essay1,
        date_of_artwork: [
          { key: "date_of_artwork", display: "Date of Artwork", value: "1978" },
        ],
        name_of_publication: "Geeta Kapur. Apparitions, 1978",
        revised_medium: "BOOK.exc.group",
        research_category: "2_FineArts",
        exhibition_detail: "Introduction, New Delhi: Lalit Kala Akademi",
        content:
          "Modern painting has repeatedly emphasised the flatness of the picture-surface as against the illusion of three-dimension- ality predominant in Western painting since the Renaiss- ance. This preoccupation is derived in part from non-Western traditions (Japanese prints, primitive painting, Persian miniatures, which started coming to the notice of the West from the late nineteenth century onwards), in part from Byzantine art, and in part from Cezanne, a staunch exponent of the Renaissance tradition but instrumental,",
        painters: [
          {
            key: "painter",
            display: "painter",
            value: "K.C.S. Paniker",
            imageName: "Words and Symbols",
            medium_of_artwork: "Oil on Canvas",
            year: "1971",
            image: ess1paint1,
          },
          {
            key: "painter",
            display: "painter",
            value: "K.C.S. Paniker",
            imageName: "Words and Symbols",
            medium_of_artwork: "Oil on Canvas",
            year: "1971",
            image: ess1paint2,
          },
          {
            key: "painter",
            display: "painter",
            value: "K.C.S. Paniker",
            imageName: "Words and Symbols",
            medium_of_artwork: "Oil on Canvas",
            year: "1971",
            image: ess1paint3,
          },
          {
            key: "painter",
            display: "painter",
            value: "K.C.S. Paniker",
            imageName: "Words and Symbols",
            medium_of_artwork: "Oil on Canvas",
            year: "1971",
            image: ess1paint4,
          },
          {
            key: "painter",
            display: "painter",
            value: "K.C.S. Paniker",
            imageName: "Words and Symbols",
            medium_of_artwork: "Oil on Canvas",
            year: "1971",
            image: ess1paint5,
          },
          {
            key: "painter",
            display: "painter",
            value: "K.C.S. Paniker",
            imageName: "Words and Symbols",
            medium_of_artwork: "Oil on Canvas",
            year: "1971",
            image: ess1paint6,
          },
          {
            key: "painter",
            display: "painter",
            value: "K.C.S. Paniker",
            imageName: "Words and Symbols",
            medium_of_artwork: "Oil on Canvas",
            year: "1971",
            image: ess1paint7,
          },
        ],

        author: [
          {
            key: "author",
            display: "Author",
            first_name: "Geeta",
            last_name: "Kapur",
            profession: "Essayist",
            value: "Geeta Kapur",
          },
        ],
        title_of_essay: [
          {
            key: "title_of_essay",
            display: "Title of the Essay",
            value: "Apparitions",
          },
        ],
      },
      {
        key: "pictorial-space",
        value: essay2,
        date_of_artwork: [
          { key: "date_of_artwork", display: "Date of Artwork", value: "1978" },
        ],
        name_of_publication: "Geeta Kapur. Apparitions, 1978",
        revised_medium: "BOOK.exc.group",
        research_category: "2_FineArts",
        exhibition_detail: "Introduction, New Delhi: Lalit Kala Akademi",
        content:
          "Modern painting has repeatedly emphasised the flatness of the picture-surface as against the illusion of three-dimension- ality predominant in Western painting since the Renaiss- ance. This preoccupation is derived in part from non-Western traditions (Japanese prints, primitive painting, Persian miniatures, which started coming to the notice of the West from the late nineteenth century onwards), in part from Byzantine art, and in part from Cezanne, a staunch exponent of the Renaissance tradition but instrumental,",
        painters: [
          {
            key: "painter",
            display: "painter",
            value: "Prabhakar Barwe",
            imageName: "Confrontation",
            medium_of_artwork: "Enamel on Canvas",
            year: "1975",
            image: ess2paint1,
          }
        ],

        author: [
          {
            key: "author",
            display: "Author",
            first_name: "Geeta",
            last_name: "Kapur",
            profession: "Essayist",
            value: "Geeta Kapur",
          },
        ],
        title_of_essay: [
          {
            key: "title_of_essay",
            display: "Title of the Essay",
            value: "Apparitions",
          },
        ],
      },
      {
        key: "pictorial-space",
        value: essay3,
        date_of_artwork: [
          { key: "date_of_artwork", display: "Date of Artwork", value: "1978" },
        ],
        name_of_publication: "Geeta Kapur. Apparitions, 1978",
        revised_medium: "BOOK.exc.group",
        research_category: "2_FineArts",
        exhibition_detail: "Introduction, New Delhi: Lalit Kala Akademi",
        content:
          "Modern painting has repeatedly emphasised the flatness of the picture-surface as against the illusion of three-dimension- ality predominant in Western painting since the Renaiss- ance. This preoccupation is derived in part from non-Western traditions (Japanese prints, primitive painting, Persian miniatures, which started coming to the notice of the West from the late nineteenth century onwards), in part from Byzantine art, and in part from Cezanne, a staunch exponent of the Renaissance tradition but instrumental,",
        painters: [
          {
            key: "painter",
            display: "painter",
            value: "Akbar Padamsee",
            imageName: "Metascape-II",
            medium_of_artwork: "Oil on Canvas",
            year: "1976",
            image: ess3paint1,
          }
        ],

        author: [
          {
            key: "author",
            display: "Author",
            first_name: "Geeta",
            last_name: "Kapur",
            profession: "Essayist",
            value: "Geeta Kapur",
          },
        ],
        title_of_essay: [
          {
            key: "title_of_essay",
            display: "Title of the Essay",
            value: "Apparitions",
          },
        ],
      },
      {
        key: "pictorial-space",
        value: essay4,
        date_of_artwork: [
          { key: "date_of_artwork", display: "Date of Artwork", value: "1978" },
        ],
        name_of_publication: "Geeta Kapur. Apparitions, 1978",
        revised_medium: "BOOK.exc.group",
        research_category: "2_FineArts",
        exhibition_detail: "Introduction, New Delhi: Lalit Kala Akademi",
        content:
          "Modern painting has repeatedly emphasised the flatness of the picture-surface as against the illusion of three-dimension- ality predominant in Western painting since the Renaiss- ance. This preoccupation is derived in part from non-Western traditions (Japanese prints, primitive painting, Persian miniatures, which started coming to the notice of the West from the late nineteenth century onwards), in part from Byzantine art, and in part from Cezanne, a staunch exponent of the Renaissance tradition but instrumental,",
        painters: [
          {
            key: "painter",
            display: "painter",
            value: "Jogen Choudhury",
            imageName: "Life-I",
            medium_of_artwork: "Ink and Pastel",
            year: "1974",
            image: ess4paint1,
          }
        ],

        author: [
          {
            key: "author",
            display: "Author",
            first_name: "Geeta",
            last_name: "Kapur",
            profession: "Essayist",
            value: "Geeta Kapur",
          },
        ],
        title_of_essay: [
          {
            key: "title_of_essay",
            display: "Title of the Essay",
            value: "Apparitions",
          },
        ],
      },
      
    ],
    display: "essayImages",
  },
];

export const pictorial_space_all_data=[
  {
    image:PictorialSpaceImg,
    desc:"Kapur, Geeta (Curator). Pictorial Space - A Point of View on Contemporary Indian Art. New Delhi: Lalit Kala Akademi, 1977 | AccNo.BOOK.exc.group | RC1"

  }
]