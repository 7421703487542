import api from "./api";
import { APIS } from "../endpoints";
import { pictorial_space_annual_data } from "../MockData/Introduction";
import { isTransformationNotAllowed } from "../util/isTranformationAllowed";
import { pictorial_space_all_data, pictorial_space_essay_data } from "../MockData/EssayData";
import { pictorial_space_exhibit_data, pictorial_space_exhibition_filter_options } from "../MockData/ExhibitsData";
import { kg_subramanym_essay_data } from "../MockData/PersonalityArtist";

  export const introductionByAliasAPI = async (masterlistType, masterlist) => {
    const result = await api.get(`${APIS.BASE_URL}${masterlistType}/${masterlist}/${APIS.object}`)

    if (result) {
      return result?.data?.data;
    } else {
      return [];
    }
  };
  
  /**
    This function return introduction data for respective masterlist.
    @param {string} tableName - Table name.
    @param {string} masterlist - Masterlist alias.
    @returns {Promise} - Function return introduction data.
  */
  export const introductionByAlias = async (masterlistType, masterlist) => {
    if (isTransformationNotAllowed() === true) {
      const response = await getIntroductiondummydata(masterlist);
      return response;
    }else if (isTransformationNotAllowed() === false)  {
      const response = await introductionByAliasAPI(masterlistType, masterlist);
      try {
        if (response) {
          return response;
        } else {
          console.error("Invalid response data structure:", response);
          return [];
        }
      } catch (e) {
        console.error("Something is wrong", e);
      }
    }
  };
  
  export const getIntroductiondummydata = (winner) => {

    const introductionDataMap = {

      "pictorial-space":pictorial_space_annual_data
  
      // Add more cases as needed
    };
    // Check if the masterlist exists in the map, if not, use a default value
    const introductionData = introductionDataMap[winner] || pictorial_space_annual_data;
    return introductionData;
  };

  // Get All Data
  /**
        This function return All tab data of Body of work section for respective masterlist.
        @param {string} tableName - Table name.
        @param {string} alias - Masterlist alias.
        @returns {Promise} - Function return All tab data of Body of work section.
  */
export const allData = async (masterlistType, masterlist) => {
    if (isTransformationNotAllowed() === true) {
      const response = await allDataDummy(masterlistType, masterlist);
      return response;
    }else if (isTransformationNotAllowed() === false) {

    const response = await allDataAPI(masterlistType, masterlist);
    try {
      if (response) {
        return response;
      } else {
        console.error("Invalid response data structure:", response);
        return [];
      }
    } catch (e) {
      console.error("Something is wrong", e);
    }

  };
}

export const allDataAPI = async (masterlistType, masterlist) => {
    const result = await api.get(`${APIS.BASE_URL}${masterlistType}/${masterlist}/${APIS.all}`)
    if (result) {
      return result?.data?.data;
    } else {
      return [];
    }
};

  export const allDataDummy = async (tableName, alias) => {  
    const allDataMap = {

        "pictorial-space":pictorial_space_all_data
    
        // Add more cases as needed
      };
      // Check if the masterlist exists in the map, if not, use a default value
      const allData = allDataMap[alias] || pictorial_space_all_data;
      return allData;
  };


  // Get Essay Data
  /**
        This function return Essay tab data of Body of work section for respective masterlist.
        @param {string} tableName - Table name.
        @param {string} alias - Masterlist alias.
        @returns {Promise} - Function return Essay tab data of Body of work section.
  */
export const essayData = async (masterlistType, masterlist) => {
    if (isTransformationNotAllowed() === true) {
      const response = await essayDataDummy(masterlistType, masterlist);
      return response;
    }
    else if (isTransformationNotAllowed() === false) {

      const response = await essayDataAPI(masterlistType, masterlist);
      try {
        if (response) {
          return response;
        } else {
          console.error("Invalid response data structure:", response);
          return [];
        }
      } catch (e) {
        console.error("Something is wrong", e);
      }
    };
}

export const essayDataAPI = async (masterlistType, masterlist) => {
  const result = await api.get(`${APIS.BASE_URL}${masterlistType}/${masterlist}/${APIS.essays}`)
  if (result) {
    return result?.data?.data;
  } else {
    return [];
  }
};

  export const essayDataDummy = async (tableName, alias) => {  
    const essayDataMap = {
        "pictorial-space":pictorial_space_essay_data ,
        "k.g-Subramanyan":kg_subramanym_essay_data
        // Add more cases as needed
      };
      // Check if the masterlist exists in the map, if not, use a default value
      const essayData = essayDataMap[alias] || pictorial_space_annual_data;
      return essayData;
  };
  
  // Get Exhibit Data
  /**
        This function return Exhibit tab data of Body of work section for respective masterlist.
        @param {string} tableName - Table name.
        @param {string} alias - Masterlist alias.
        @returns {Promise} - Function return Exhibit tab data of Body of work section.
  */
export const exhibitData = async (masterlistType, masterlist, artAlias, filterOptions, page) => {
  if (isTransformationNotAllowed() === true) {
    const response = await exhibitDataDummy(masterlist, masterlist);
    return response;
  }else if (isTransformationNotAllowed() === false) {

    const response = await exhibitDataAPI(masterlistType, masterlist, artAlias, filterOptions, page);
    try {
      if (response) {
        return response;
      } else {
        console.error("Invalid response data structure:", response);
        return [];
      }
    } catch (e) {
      console.error("Something is wrong", e);
    }
};
}

export const exhibitDataAPI = async (masterlistType, masterlist, artAlias, filterOptions, page) => {
  const formData = new FormData();
  formData.append('filters', JSON.stringify(filterOptions));
  formData.append('page_number', page);
  const params = new URLSearchParams(formData);

  const result = await api.get(`${APIS.BASE_URL}${masterlistType}/${masterlist}/${APIS.exhibitions}/${artAlias}`, {
    params: params
  })
  if (result) {
    return result?.data;
  } else {
    return [];
  }
};

export const exhibitDataDummy = async (tableName, alias) => {  
  const exhibitDataMap = {
      "pictorial-space":pictorial_space_exhibit_data   
      // Add more cases as needed
    };
    // Check if the masterlist exists in the map, if not, use a default value
    const exhibitData = exhibitDataMap[alias] || pictorial_space_exhibit_data;
    return exhibitData;
};

 // This function return Personality Bibliography Filter options data
export const getExhibitsFilterOptionsAPI = async (
  tableName,
  masterlist
) => {
  return []
};


export const getExhibitsFilterOptions = async (tableName, masterlist) => {
  if (isTransformationNotAllowed() === true) {
    const response = await getExhibitsFilterOptionsDummyData(masterlist);
    return response;
  } else if (isTransformationNotAllowed() === false) {
    const response = await getExhibitsFilterOptionsAPI(
      tableName,
      masterlist
    );
    const FilterOptions = {
      documentTypeFilter: [
        ...new Set(
          response
            .map((item) => item.document_type.trim())
            .filter((value) => value !== "")
        ),
      ],
      yearFilter: [
        ...new Set(
          response
            .map((item) => item.sortable_year_date_of_publication.trim())
            .filter((value) => value !== "")
        ),
      ],
      creatorTypeFilter: [
        ...new Set(
          response
            .map((item) => item.title_and_type_of_creator.trim())
            .filter((value) => value !== "")
        ),
      ],
      publisher: [
        ...new Set(
          response
            .map((item) => item.publisher.trim())
            .filter((value) => value !== "")
        ),
      ],
    };

    return FilterOptions;
  }
};

const getExhibitsFilterOptionsDummyData = (masterlist) => {
  const bibliographDataMap = {

    "pictorial-space":pictorial_space_exhibition_filter_options
  };
  return bibliographDataMap[masterlist];
};

export const getExhibitionTitleAPI = async (masterlistType, masterlist, artAlias) => {
  const result = await api.get(`${APIS.BASE_URL}${masterlistType}/${masterlist}/${APIS.exhibitions}/${artAlias}/bow`)
  if (result) {
    return result?.data;
  } else {
    return [];
  }
};