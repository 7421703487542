import React, { useEffect, useState } from "react";
import { Box, HStack, Image, SimpleGrid, Stack, Text } from "@chakra-ui/react";
import * as getPostData from "../../../services/Post";
import { common, explore } from "../../../constants/constants";
import { Link, useNavigate } from "react-router-dom";
import H3 from "../../../Theme/Typography/H3";
import SubscriptionModal from "../../../FunctionalComponents/SubscriptionModal/SubscriptionModal";
import { HasAccessLink } from "../../../FunctionalComponents/HasAccessLink";

export default function Post({ masterlistTitle, masterlistType, pageName }) {
  const [postData, setPostData] = useState([]);
  const [showSubscribeModal, setShowSubscribeModal] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const getData = async () => {
      const result = await getPostData.getPostsByMasterlist(masterlistType, masterlistTitle);

      if (result && result.length>0) {
        setPostData(result);
      }
    };
    getData();
  }, [masterlistTitle, masterlistType]);

  const clickHandler = (e, targetUrl) => {
    e.preventDefault();
    if(!targetUrl){
      // setShowSubscribeModal(true)
    }
    navigate(targetUrl);
  };
  return (
    <>
      {/* {postData && postData.length > 0 && ( */}
        <Stack>
          <HStack pb={"10px"} pt={"8px"}>
            <Text fontSize={"30px"} lineHeight={"28px"} fontWeight={700}>
              {explore.POSTS}
              {postData.length > 3 && (
                <Link to={`/generic/${masterlistTitle}/posts`}>
                  <Text as="span" fontSize={"14px"} ml={2} lineHeight={"19px"} color={"color.blue"}>
                    {` ${explore.SEE_ALL}`}
                  </Text>
                </Link>
              )}
            </Text>
          </HStack>
          {postData.length > 0 ? (
            <HStack pb="20px">
              <SimpleGrid columns={[1, null, 3]} spacing={"82px"}>
                {postData.slice(0, 3).map((it, index) => (
                  <HasAccessLink
                    key={index}
                    pageName={pageName || ""}
                    masterlist={masterlistTitle || ""}
                    to={it?.alias?`/${masterlistType}/${masterlistTitle}/posts/${it?.alias}`:``}
                    componentName={`masterlist.generic.posts`}
                    onClick={clickHandler}
                  >
                    <Box w={"348px"} h={"333px"} className="card1">
                      {it!==`` && <Image
                        w={"100%"}
                        height={"100%"}
                        src={it?.image}
                        objectFit={"contain"}
                        transition="1s ease"
                      />}
                    </Box>
                  </HasAccessLink>
                ))}
              </SimpleGrid>
            </HStack>
          ) : (
            <HStack>
              <H3>{common.NO_POST}</H3>
            </HStack>
          )}
          {showSubscribeModal && (
            <SubscriptionModal className="subscribe-modal-content" setShowSubscribeModal={setShowSubscribeModal} />
          )}
        </Stack>
      {/* )} */}
    </>
  );
}
