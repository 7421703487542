import { Box, HStack, Heading, Image, Stack, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import ReactEcharts from "echarts-for-react";
import { MdOutlineWatchLater } from "react-icons/md";

//S3 Bucket osw-dev-images
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;

const MoneyBag = `${IMAGE_BASE_PATH}/ui/Chevron/moneybag.png`

function AverageArtworkSale({ data }) {
  const [selectedData, setSelectedData] = useState(null);

  const key = "average-artwork-sale-value-over-years";
  useEffect(() => {
    const handleSelectData = () => {
      const selected = data.find((item) => item.key === key);
      setSelectedData(selected);
    };
    handleSelectData();
  }, [data]);
  const datas = [
    { name: "W19", value: 463 },
    { name: "W20", value: 495 },
    { name: "W21", value: 406 },
    { name: "W22", value: 427 },
    { name: "W23", value: 469 },
    { name: "W24", value: 465 },
    { name: "W25", value: 504 },
    { name: "W26", value: 539 },
    { name: "W27", value: 386 },
    { name: "W28", value: 404 },
    { name: "W29", value: 280 },
  ];
  const option = {
    xAxis: {
      type: "category",
      data: datas.map((item) => item.name),
    },
    yAxis: {
      type: "value",
      show: false,
    },
    series: [
      {
        data: datas.map((item) => item.value),
        type: "bar",
        label: {
          show: true,
          position: "top",
        },
      },
    ],
  };
  return (
    <>
      <HStack flexDirection={"column"} alignItems={"self-start"}>
        <Heading as="h3" size="lg" mb={4} pb={4}>
          {selectedData?.title}
        </Heading>
        <Text fontSize={"16px"}>{selectedData?.subtitle}</Text>
        <Text fontSize={"16px"}>{selectedData?.heading}</Text>
      </HStack>
      <Stack py="4" gap={"45px"} flexDirection={"row"} alignItems={"flex-start"}>
        <HStack
          gap={"15px"}
          flexDirection={"column"}
          alignItems={"flex-start"}
          p={"4"}
          width={"100%"}
          bg="#e8e8e8"
        >
          <Box width={"100%"} bg="white">
            <Stack flexDirection={"row"} gap="24px" justifyContent={"left"} ml={20} >
              <HStack>
                <MdOutlineWatchLater size={"60px"} color="#bfbfbf" />
              </HStack>
              <HStack flexDirection={"column"} alignItems={"flex-start"} p={2}>
                <Text
                  fontSize={"34px"}
                  textStyle={"secondary"}
                  fontWeight={"500"}
                  color={"blue.300"}
                >
                  18,8 months
                </Text>
                <Text
                  as={"span"}
                  className="ml-0"
                  fontSize={"20px"}
                  color={"gray.600"}
                >
                  Avg. Costumer Lifetime
                </Text>
              </HStack>
            </Stack>
          </Box>
          <Box className="ml-0" width={"100%"} bg="white">
            <Stack flexDirection={"row"} gap="24px" justifyContent={"left"} ml={20}>
              <HStack>
                <Image src={MoneyBag} width={"100%"} />
              </HStack>
              <HStack flexDirection={"column"} alignItems={"flex-start"}p={2}>
                <Text
                  fontSize={"34px"}
                  textStyle={"secondary"}
                  fontWeight={"500"}
                  color={"blue.300"}
                >
                 $ 440
                </Text>
                <Text
                  as={"span"}
                  className="ml-0"
                  fontSize={"20px"}
                  color={"gray.600"}
                >
                  Avg. Costumer Lifetime Value
                </Text>
              </HStack>
            </Stack>
          </Box>
          <Box className="ml-0" width={"100%"} bg="white">
            <ReactEcharts
              option={option}
              style={{ height: "200px", width: "484px" }}
            />
          </Box>
        </HStack>
        <HStack width={"100%"}>
          <Text>{selectedData?.desc}</Text>
        </HStack>
      </Stack>
    </>
  );
}

export default AverageArtworkSale;
