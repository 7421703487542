import { Box, Container, Stack, Text } from "@chakra-ui/react";
import React from "react";
import Layout from "../components/sections/SidebarLayout";
import { common } from "../constants/constants";

function ContentAwaited() {
  return (
    <Layout>
      <Stack>
        <Container>
          <Box py="20">
            <Text
              py={"40"}
              fontSize={{ base: "25px", md: "45px" }}
              fontWeight="700"
              textAlign="center"
            >
              {common.WEBPAGE_COMING_SOON}
            </Text>
          </Box>
        </Container>
      </Stack>
    </Layout>
  );
}
export default ContentAwaited;
