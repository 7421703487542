import { Text } from "@chakra-ui/react";
import React from "react";

const H2 = ({ children,fontWeight="700", ...props}) => {
  return (
    <Text
      as="h2"
      {...props}
      fontSize="20px"
      fontWeight={fontWeight}
      lineHeight={"24px"}
      color={"black"}
    >
      {children}
    </Text>
  );
};
export default React.memo(H2);
