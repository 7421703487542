import {
  Box,
  HStack,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Stack,
  Text,
} from "@chakra-ui/react";
// Core Files
import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";

// Service Files
import { CopyPasteService } from "../../../services/CopyPaste";
import * as getFilmSynopsisData from "../../../services/Film";

// Subsection Files
import { EffectFade, EffectFlip, Navigation, Pagination } from "swiper";
import { WebpageComingSoon } from "../../../CommonComponents/WebpageComingSoon";
import H3 from "../../../Theme/Typography/H3";
import H4 from "../../../Theme/Typography/H4";
import { BsArrowsAngleContract } from "react-icons/bs";
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;

const placeholderImg = `${IMAGE_BASE_PATH}/ui/DummyImage/small.png`

function Synopsis({ masterlist, tableName}) {
  const [showLabel, setShowLabel] = useState(true);
  const [clickedImageIndex, setClickedImageIndex] = useState(null);
  const [synopsisFullScreenImage, setSynopsisFullScreenImage] = useState(false);
  const [synopsisImgData, setsynopsisImgData] = useState(null);
  const [description, setDescription] = useState("");
  const [synopsisData, setSynopsisData] = useState([{}]);

  const showSynopsisImages = (image, flag, index) => {
    if (image) {
      setClickedImageIndex(index);
      setSynopsisFullScreenImage(flag);
      setsynopsisImgData(image);
    } else {
      setSynopsisFullScreenImage(flag);
      setsynopsisImgData(null);
    }
  };

  const citationFactor = (data) => {
    return data.map((item) => {
      return {
        desc:
          `${item?.bibliographic_caption}` ,
        imagePath: item?.direct_url_of_cover_image,
        wnn: item?.wnn,
        newDesc:
          `${item?.bibliographic_citation}`,
      };
    });
  };
  useEffect(() => {
    const getData = async () => {
      const result = await getFilmSynopsisData.filmSynopsisData(
        tableName,
        masterlist
      );
      if (result?.length > 0) {
        setShowLabel(false);
        setDescription(
          result?.find((nestedItem) => nestedItem.key === "synopsis")?.value
        );
        result?.map(
          (item) =>
            item.key === "synopsis_image_alias" &&
            setSynopsisData(citationFactor(item?.value))
        );
      }
    };
    getData();
  }, [tableName, masterlist]);
  return showLabel ? (
    <Stack height={"100vh"}>
     <WebpageComingSoon />
    </Stack>
  ) : (
    <>
      <ImagePopupModal
        isOpen={synopsisFullScreenImage}
        onClose={() => showSynopsisImages(null, false)}
        imageData={synopsisImgData}
        data={synopsisData}
        clickedImageIndex={clickedImageIndex}
        imageHeight={{ base: "100%", md: "90vh" }}
      />
      <Stack
        flexDirection={{ base: "column", md: "row" }}
        alignItems={"flex-start"}
        gap={{ base: 1, md: 12 }}
        minHeight={{lg:"100%", '2xl':"calc(100vh - 180px)"}}
        py="20px"
      >
        <HStack
          marginTop="0.0rem!important"
          flexDirection={"column"}
          width={{ base: "100%", md: "664px" }}
          px="0px"
          pb="4"
          alignItems={"flex-start"}
        >
          <H3 textAlign="justify" color="color.gray" lineHeight={{ base: "28px", md: "32px" }} fontWeight={"600"} onCopy={(e) => CopyPasteService(e, `${window.location.href}/1`)}>
           {description}
          </H3>
        </HStack>
        <HStack paddingRight={"5px"}>
          <Stack
            // className={{ base: "col-12", md: "col-11" }}
            width={{ base: "100%", md: "395px" }}
            id={"allsliderskk2"}
            position={"relative"}
          >
            <HStack pb="4">
              {synopsisData?.length > 0 && (
                <Swiper
                  grabCursor={true}
                  navigation={true}
                  modules={[EffectFlip, Navigation]}                  
                  // loop={true}
                >
                  {synopsisData?.map((item, index) => (
                    <>
                      <SwiperSlide className="myslide">
                        <Stack>
                          <Image
                            height={"100%"}
                            onClick={(e) =>
                              showSynopsisImages(item?.imagePath, true, index)
                            }
                            fallbackSrc={placeholderImg}
                            src={item?.imagePath}
                            cursor={"pointer"}
                          />
                          <H4  pt={"8px"} textAlign={"left"} lineHeight={"18.78px"} color="color.blue">{item?.desc}</H4>
                        </Stack>
                      </SwiperSlide>
                    </>
                  ))}
                </Swiper>
              )}
            </HStack>
          </Stack>
        </HStack>
      </Stack>
    </>
  );
}
export default Synopsis;

export const ImagePopupModal = ({
  isOpen,
  onClose,
  imageData,
  data,
  clickedImageIndex,
  imageHeight,
}) => {
  return (
    <Modal
      size={"full"}
      isCentered
      isOpen={isOpen}
      onClick={onClose}
      Id="modalfullscreen"
    >
      <ModalOverlay />
      <ModalContent zIndex={"99999"} alignItems={"center"}>
        <ModalBody>
          <Box bg="white" mx={{ base: "0", md: "10" }}>
            <Stack width={"1400px"}>
              <Swiper
                slidesPerView={1}
                navigation
                initialSlide={clickedImageIndex}
                id="allsliderskk1"
                modules={[EffectFade, Navigation, Pagination]}
              >
                {data?.map((item, index) => {
                  return (
                    <SwiperSlide key={index + 1}>
                      <HStack
                        justifyContent={"center"}
                        flexDirection={"column"}
                        marginTop={"15px"}
                    
                        height={"100vh"}
                      >
                        <Image
                          src={
                            index < 2
                              ? imageData !== null
                                ? item.imagePath
                                : ""
                              : item.imagePath
                          }
                          height={imageHeight ? imageHeight : "100%"}
                          alt=""
                        />
                        <HStack paddingTop="10px">
                          <Text
                            pt={"8px"}
                            fontSize={"15px"}
                            fontWeight={"400"}
                            lineHeight={"17px"}
                            color="#035DA1"
                          >
                            {item.newDesc}
                          </Text>
                        </HStack>
                      </HStack>
                      <HStack
                        justifyContent="flex-end"
                        position="absolute"
                        right="00"
                        top={{ base: "80%", md: "95%" }}
                        marginRight={{ base: "0px", md: "160px" }}
                        zIndex={"999"}
                        transform="translateY(-50%)"
                        paddingRight={"30px"}
                      >
                        <Text
                          fontSize={"xl"}
                          bg={"black"}
                          opacity={{ base: "1", md: "0.6" }}
                          color={"white"}
                          fontWeight={"400"}
                          padding="2"
                          cursor={"pointer"}
                          borderRadius={"35px"}
                          width="max-content"
                          onClick={onClose}
                        >
                          <BsArrowsAngleContract />
                        </Text>
                      </HStack>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </Stack>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
