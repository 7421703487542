import { OExplorerData } from "../MockData/OExplorerData";
import { isTransformationNotAllowed } from "../util/isTranformationAllowed";
import { APIS } from "../endpoints";
import api from "../services/api";

export const getOExplorerDataAPI = async () => {
  const result = await api.get(`${APIS.BASE_URL}${APIS.stages}`)
  if (result) {
    return result.data;
  }
};

export const getOExplorerData = async () => {
  if (isTransformationNotAllowed() === true) {
    const response = await getOExplorerDataDummy();
    return response;
  }else if (isTransformationNotAllowed() === false)  {
    try {
      const response = await getOExplorerDataAPI();

      return response;
    } catch (e) {
      console.error("Something is wrong", e);
    }
  }
};

export const getOExplorerDataDummy = async (tableName, masterlist) => {
  const dummyDataEnabled = process.env.REACT_APP_DUMMY_DATA || "";
  if (dummyDataEnabled.toLowerCase() === "yes") {
    return OExplorerData;
  } else {
    return [];
  }
};
