import {
  Box,
  useColorModeValue,
  useDisclosure,
  Stack,
  Container,
} from "@chakra-ui/react";

//Core file
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "react-vertical-timeline-component/style.min.css";

//Service file
import * as getIntroductionData from "../../services/Introduction";
import * as getLandingMasterlistData from "../../services/SectionService";

//Component file
import { BottomMainMenu } from "../../CommonComponents/BottomMainMenu";
import CommonLeftSidebar from "../../FunctionalComponents/LeftSidebar/CommonLeftSidebar";
import GridView from "./BodyOfWork/MonumentsAndHeritage/GridView";

//Language file
import {explore, location } from "../../constants/constants";
import { IntegratedTimelineBody } from "./BodyOfWork/IntegratedTimeline";
import TabMain from "../../FunctionalComponents/TabComponents/TabMain";

export default function BodyOfWork({ children }) {
  const { onClose } = useDisclosure();
  const [introData, setIntroData] = useState([]);
  const [journalDetails, setJournalDetails] = useState({});
  const [name, setName] = useState("");
  const {alias } = useParams();
  const aliasAPI = alias;
  const currentEndpoint = window.location.pathname;
  const parts = currentEndpoint.split("/");
  const masterlistType = parts[1];

  React.useEffect(() => {
    const getIntroData = async () => {
      const result = await getIntroductionData.introductionByAlias(
        "",
        aliasAPI
      );
      if (result) {
        setName(() => result[0]?.find((item) => item?.key === "name")?.value);
        setJournalDetails(
          result?.reduce((acc, it) => {
            acc.title = it?.find((item) => item?.key === "name")?.value;
            return acc;
          }, {})
        );
      }
    };
    const getLandingData = async () => {
      const result = await getLandingMasterlistData.getLandingPageInfoByAlias(
        aliasAPI
      );
      if (result) {
        setIntroData(
          result?.map((it) => ({
            text: it.find((item) => item?.key === "landing_page_title").value,
            to: `/${masterlistType}/${it.find((item) => item?.key === "masterlist").value}/${
              it.find((item) => item?.key === "landing_page_abbreviation").value
            }`,
            title: it.find((item) => item?.key === "landing_page_abbreviation")
              .value,
          }))
        );
      }
    };
    getIntroData();
    getLandingData();
  }, [masterlistType, aliasAPI]);
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  return (
    <Container maxW={"1400px"} px="0px">
      <Box minH="100%" bg={useColorModeValue("white", "gray.900")}>
        <SidebarContent
          onClose={() => onClose}
          display={{ base: "none", md: "block" }}
          zIndex={"99999"}
          height="calc(100vh - 64px)"
          aliasAPI={aliasAPI}
          journalDetails={journalDetails}
          introData={introData}
          masterlistType={masterlistType}
          name={name}
        />
        <Box ml={{ base: 0, md: 52 }} bg="white">
          {children}
          <BodyOfWorkBody aliasAPI={aliasAPI} />
        </Box>
      </Box>
    </Container>
  );
}
const SidebarContent = ({ 
  onClose,
  aliasAPI,
  journalDetails,
  introData,
  masterlistType,
   ...rest }) => {

  const navigate = useNavigate();
  const handleNavigation = () => {
    navigate(-1);
  };
  const oSections = [
    {
      to: `/${masterlistType}/${aliasAPI}/1`,
      title: "",
      text: explore.POSTS,
    },
    {
      to: `/${masterlistType}/${aliasAPI}/2`,
      title: "",
      text: explore.INSIGHTS,
    },
  ];
  return (
    <>
      <BottomMainMenu />
      <CommonLeftSidebar
        sections={introData}
        oSections={oSections}
        onClose={onClose}
        journalDetails={journalDetails}
        handleNavigation={handleNavigation}
        leftFlag={true}
      />
    </>
  );
};
const BodyOfWorkBody = ({aliasAPI}) => {
  const [modalcard, setModalcard] = useState(false);
  const [tabPanelData, setTabPanelData] = useState([]);

  function formatData() {
    return [
      {
        name: `${location.MONUMNETS_AND_HERITAGE}`,
        component: <GridView aliasAPI={aliasAPI} />,
      },
      {
        name: `${explore.INTEGRATED_TIMELINE}`,
        component: <IntegratedTimelineBody
        modalcard={modalcard}
        setModalcard={setModalcard}
        aliasAPI={aliasAPI}
        />,
      },
    ];
  }
  useEffect(() => {
    if (aliasAPI) {
      setTabPanelData(formatData());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [aliasAPI]);
  return (
  <>
    <Stack height={"100%"}>
      <Container maxW="auto" px={{ base: "4", md: "0" }} mx={"0"}>
        <Stack px={{ base: "0", md: "8" }}>
                {tabPanelData && tabPanelData.length > 0 && (
            <TabMain data={tabPanelData} />
          )}
        </Stack>
      </Container>
    </Stack>
  </>
  );
};