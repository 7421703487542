import React from "react";
import {
  Box,
  HStack,
  Stack,
  Container,
  useBreakpointValue,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
} from "@chakra-ui/react";

// Core files

import InfiniteScroll from "react-infinite-scroll-component";
import { FilterButton } from "../../../../CommonComponents/FilterButton";
import NoMoreToLoad from "../../../../CommonComponents/NoMoreToLoad";
import { CopyPasteService } from "../../../../services/CopyPaste";
import { DataNotFound } from "../../../../CommonComponents/DataNotFound";
import { FilterBox } from "../../../../FunctionalComponents/Filter/FilterBox";
import { explore } from "../../../../constants/constants";
import TimeLine from "../../../../FunctionalComponents/TimeLine/TimeLine";
import TimelineWithText from "../../../../FunctionalComponents/TimeLine/TimelineWithText";
// import { FullScreenImage } from "../../../../components/FullScreen/AuctionFullScreen";
import FilterPills from "../../../../components/FilterPills/FilterPills";

//ImageFiles
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;

const loaderImg = `${IMAGE_BASE_PATH}/ui/Chevron/loader.gif`;
export default function TabularView({
  showFilterBox,
  masterlistType,
  handleFilterClick,
  data,
  fetchMoreData,
  hasMore,
  showArchiveImages,
  setShowFilterBox,
  setApply,
  facets,
  sort,
  handleCheckboxChange,
  handleSearch,
  filterOptions,
  filteroptionData,
  filterCount,
  setFilterOptions,
  setSearchTerm,
  searchTerm,
  handleReset,
  archiveFullScreenImage,
  archiveImgData,
  clickedImageIndex,
  filterPills,
  setFilterPills,
  filterbuttonStyle,
  filteredResultCount
}) {
  const isMobile = useBreakpointValue({ base: true, md: false });
  return (
    <>
      <Stack flexDirection={"row-reverse"}>
        {!showFilterBox && (
          <FilterButton
          marginTop="40px"
          filterbuttonStyle={filterbuttonStyle}
          masterlistType={"generic"}
          onClick={handleFilterClick}
          />
        )}
        <Container   
        maxW="auto"
          className="songs"
          px={{ base: "4", md: "0px" }}
          py={{ base: "0", md: "8" }}
          position={"relative"}>
         <Stack flexDirection={{base:"column",md:"row"}} gap={0} justifyContent="space-between">
            {data && data.length > 0 ? (
               <Stack
               flexDirection={"column"}
               gap={0}
               height="100%"
               onCopy={(e) => CopyPasteService(e, `${window.location.href}/0/0`)}
               width={"90%"}
             >
          {((filterPills &&  filterPills!==undefined && filterCount>0) || filterOptions?.search) &&
          <FilterPills
          filters={filterPills}
          setFilterOptions={setFilterOptions}
          setApply={setApply}
          setSearchTerm={setSearchTerm}
          setFilterPills={setFilterPills}
          filteredResultCount={filteredResultCount}
          />
          }
                <InfiniteScroll
                dataLength={data.length}
                next={fetchMoreData}
                hasMore={hasMore}
                loader={
                  <Box textAlign="center" marginLeft={"50%"}>
                    <Stack marginTop={"20px"}>
                      <img
                        src={loaderImg}
                        width={"100px"}
                        height={"100px"}
                        alt="Loading"
                      />
                    </Stack>
                  </Box>
                }
                endMessage={<NoMoreToLoad />}
              >
                <TimeLine
                    data={data}
                    handleDrawer={showArchiveImages}
                    component={TimelineWithText}
                    facets={filteroptionData}
                    sort={sort}
                  />
              </InfiniteScroll>
              </Stack>
            ) : (
              <>
                <HStack
                  onCopy={(e) =>
                    CopyPasteService(e, `${window.location.href}/0/1`)
                  }
                  margin={"auto"}
                >
                  <DataNotFound />
                </HStack>
              </>
            )}

            <HStack
              marginLeft={"4"}
              marginRight={"0px!important"}
              className="mt-0"
              alignItems={"flex-start"}
              pos={masterlistType === "generic" && "relative"}
              right={masterlistType === "generic" && "-5px"}
            >
              {showFilterBox && (
                isMobile ? (
                  <Modal isOpen={showFilterBox} onClose={() => setShowFilterBox(false)}     returnFocusOnClose={false}>
                    <ModalOverlay />
                    <ModalContent>
                      <ModalBody px="15px">
                        <FilterBox
                          setShowFilterBox={setShowFilterBox}
                          setApply={setApply}
                          explore={explore}
                          facets={facets}
                          sort={sort}
                          handleCheckboxChange={handleCheckboxChange}
                          handleSearch={handleSearch}
                          filterOptions={filterOptions}
                          filteroptionData={facets}
                          filterCount={filterCount}
                          setFilterOptions={setFilterOptions}
                          setSearchTerm={setSearchTerm}
                          searchTerm={searchTerm}
                          handleReset={handleReset}
                          className="box-filter-film2"
                        />
                      </ModalBody>
                    </ModalContent>
                  </Modal>
                ):(
                <FilterBox
                  setShowFilterBox={setShowFilterBox}
                  setApply={setApply}
                  explore={explore}
                  facets={facets}
                  sort={sort}
                  handleCheckboxChange={handleCheckboxChange}
                  handleSearch={handleSearch}
                  filterOptions={filterOptions}
                  filteroptionData={facets}
                  filterCount={filterCount}
                  setFilterOptions={setFilterOptions}
                  setSearchTerm={setSearchTerm}
                  searchTerm={searchTerm}
                  handleReset={handleReset}
                  className={masterlistType === "search" || masterlistType !== "research-categories" ? "box-filter-film2" : "box-filter-film3"}
                />
                )
              )}
            </HStack>
          </Stack>
        </Container>
      </Stack>
    </>
  );
}