import {
  Box,
  Container,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  HStack,
  Image,
  SimpleGrid,
  Stack,
} from "@chakra-ui/react";
import React from "react";
import H1 from "../components/layouts/Typography/H1";
// import { chunk, filters } from "../util/helper";

import Subheading from "../components/layouts/Typography/Subheading";
import { Swiper, SwiperSlide } from "swiper/react";
import H2 from "../components/layouts/Typography/H2";
import Small from "../components/layouts/Typography/Small";
import H4 from "../components/layouts/Typography/H4";
import Caption from "../components/layouts/Typography/Caption";

//S3 Bucket osw-dev-images
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;

const Img = `${IMAGE_BASE_PATH}/ui/DummyImage/dummy.png`

function YearMapFilter() {
  const [isOpen, setIsOpen] = React.useState(false);
  const btnRef = React.useRef();
  return (
    <Stack zIndex={1} position={"relative"}>
      <Container maxW={"1400px"}>
        <Drawer
          isOpen={isOpen}
          placement="right"
          finalFocusRef={btnRef}
          id="humbergermenu"
          onClose={() => setIsOpen(false)}
        >
          <DrawerOverlay />
          <DrawerContent>
            {/* <DrawerCloseButton /> */}
            <DrawerHeader px="14px">
              <Stack>
                <HStack>
                  <H2>Indo Japanese Dialogue</H2>
                </HStack>
                <HStack>
                  <Small color="black">
                    Rabindranath Tagore was a Bengali polymath – poet, writer,
                    playwright, composer, philosopher, social reformer and
                    painter
                  </Small>
                </HStack>
              </Stack>
            </DrawerHeader>

            <DrawerBody px="14px">
              <Stack>
                <HStack>
                  <H4 color="black">Personalities (9)</H4>
                </HStack>
                <HStack>
                  <SimpleGrid columns={[3, null, 3]} spacing="16px">
                    <Box width="81px" height="auto">
                      <Image src={Img}></Image>
                      <Caption pt="2" textAlign={"center"}>
                        Rabindranath Tagore
                      </Caption>
                    </Box>
                    <Box width="81px" height="auto">
                      <Image src={Img}></Image>
                      <Caption pt="2" textAlign={"center"}>
                        Okakura Kakuzo
                      </Caption>
                    </Box>
                    <Box width="81px" height="auto">
                      <Image src={Img}></Image>
                      <Caption pt="2" textAlign={"center"}>
                        James HenryCousins
                      </Caption>
                    </Box>
                    <Box width="81px" height="auto">
                      <Image src={Img}></Image>
                      <Caption pt="2" textAlign={"center"}>
                        Margret Noble (Sister Nivedita)
                      </Caption>
                    </Box>
                    <Box width="81px" height="auto">
                      <Image src={Img}></Image>
                      <Caption pt="2" textAlign={"center"}>
                        Swami Vivekananda
                      </Caption>
                    </Box>
                    <Box width="81px" height="auto">
                      <Image src={Img}></Image>
                      <Caption pt="2" textAlign={"center"}>
                        Ernest Fenollosa
                      </Caption>
                    </Box>
                    <Box width="81px" height="auto">
                      <Image src={Img}></Image>
                      <Caption pt="2" textAlign={"center"}>
                        Abindranath Tagore
                      </Caption>
                    </Box>
                    <Box width="81px" height="auto">
                      <Image src={Img}></Image>
                      <Caption pt="2" textAlign={"center"}>
                        Ernest Binfield Havell
                      </Caption>
                    </Box>
                    <Box width="81px" height="auto">
                      <Image src={Img}></Image>
                      <Caption pt="2" textAlign={"center"}>
                        Margaret Cousins
                      </Caption>
                    </Box>
                  </SimpleGrid>
                </HStack>
              </Stack>
              <Stack py="4">
                <HStack>
                  <H4 color="black">Places (8)</H4>
                </HStack>
                <HStack>
                  <SimpleGrid columns={[2, null, 3]} spacing="16px">
                    <Box width="81px" height="auto">
                      <Image src={Img}></Image>
                      <Caption pt="2" textAlign={"center"}>
                        Calcutta, West Bengal, India
                      </Caption>
                    </Box>
                    <Box width="81px" height="auto">
                      <Image src={Img}></Image>
                      <Caption pt="2" textAlign={"center"}>
                        Shantiniketan, India
                      </Caption>
                    </Box>
                    <Box width="81px" height="auto">
                      <Image src={Img}></Image>
                      <Caption pt="2" textAlign={"center"}>
                        Kyoto, Japan
                      </Caption>
                    </Box>
                    <Box width="81px" height="auto">
                      <Image src={Img}></Image>
                      <Caption pt="2" textAlign={"center"}>
                        Calcutta, West Bengal, India
                      </Caption>
                    </Box>
                    <Box width="81px" height="auto">
                      <Image src={Img}></Image>
                      <Caption pt="2" textAlign={"center"}>
                        Shantiniketan, India
                      </Caption>
                    </Box>
                    <Box width="81px" height="auto">
                      <Image src={Img}></Image>
                      <Caption pt="2" textAlign={"center"}>
                        Kyoto, Japan
                      </Caption>
                    </Box>
                  </SimpleGrid>
                </HStack>
              </Stack>
            </DrawerBody>

            <DrawerFooter></DrawerFooter>
          </DrawerContent>
        </Drawer>

        <Swiper
          slidesPerView={10}
          centeredSlides={false}
          spaceBetween={10}
          // loop={true}
          grabCursor={true}
          className="mapyear1"
        >
          <SwiperSlide bg="#fff0" className="mapyear">
            <Box cursor="pointer" ref={btnRef} onClick={setIsOpen}>
              <Subheading color="#adadad">1901</Subheading>
            </Box>
          </SwiperSlide>
          <SwiperSlide bg="#fff0" className="mapyear">
            <Box cursor="pointer">
              <H1 color="#adadad">1902</H1>
            </Box>
          </SwiperSlide>
          <SwiperSlide bg="#fff0" className="mapyear">
            <Box cursor="pointer">
              <H1 color="#adadad">1903</H1>
            </Box>
          </SwiperSlide>
          <SwiperSlide bg="#fff0" className="mapyear">
            <Box cursor="pointer">
              <H1 color="#adadad">1904</H1>
            </Box>
          </SwiperSlide>
          <SwiperSlide bg="#fff0" className="mapyear">
            <Box cursor="pointer">
              <H1 color="#adadad">1905</H1>
            </Box>
          </SwiperSlide>
          <SwiperSlide bg="#fff0" className="mapyear">
            <Box cursor="pointer">
              <H1 color="#adadad">1906</H1>
            </Box>
          </SwiperSlide>
          <SwiperSlide bg="#fff0" className="mapyear">
            <Box cursor="pointer">
              <H1 color="#adadad">1907</H1>
            </Box>
          </SwiperSlide>
          <SwiperSlide bg="#fff0" className="mapyear">
            <Box cursor="pointer">
              <H1 color="#adadad">1908</H1>
            </Box>
          </SwiperSlide>
          <SwiperSlide bg="#fff0" className="mapyear">
            <Box cursor="pointer">
              <H1 color="#adadad">1909</H1>
            </Box>
          </SwiperSlide>
          <SwiperSlide bg="#fff0" className="mapyear">
            <Box cursor="pointer">
              <H1 color="#adadad">1910</H1>
            </Box>
          </SwiperSlide>
          <SwiperSlide bg="#fff0" className="mapyear">
            <Box cursor="pointer">
              <H1 color="#adadad">1911</H1>
            </Box>
          </SwiperSlide>
          <SwiperSlide bg="#fff0" className="mapyear">
            <Box cursor="pointer">
              <H1 color="#adadad">1912</H1>
            </Box>
          </SwiperSlide>
        </Swiper>
      </Container>
    </Stack>
  );
}

export default YearMapFilter;
