import api from "../services/api";
import { APIS } from "../endpoints";
import { isTransformationNotAllowed } from "../util/isTranformationAllowed";
import { bookmarks_data, data } from "../MockData/ProfileMockdata";

export const getProfileData = async () => {
  // Convert FormData to URLSearchParams
  const result = await api.get(`${APIS.BASE_URL}${APIS.profile}`)
  if (result) {
    return result?.data?.data;
  }
};
export const getRCProfileData = async () => {
  // Convert FormData to URLSearchParams
  if (isTransformationNotAllowed() === true) {
    const response = await getRCProfileDummyData();
    return response;
  } else if (isTransformationNotAllowed() === false) {

  }
};

export const getProfileMeData = async () => {
  // Convert FormData to URLSearchParams
  const result = await api.get(`${APIS.BASE_URL}${APIS.me}`)
  if (result) {
    return result?.data?.data;
  }
};

const getRCProfileDummyData=()=>{
  return data;
}

export const getBookmarkProfileData = async () => {
  // Convert FormData to URLSearchParams
  if (isTransformationNotAllowed() === true) {
    const response = await getBookmarkProfileDummyData();
    return response;
  } else if (isTransformationNotAllowed() === false) {

  }
};
const getBookmarkProfileDummyData=()=>{
  return bookmarks_data;
}