import {
  Box,
  HStack,
  Image,
  SimpleGrid,
  Stack,
} from "@chakra-ui/react";

//Core file
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

//Service file
import * as getPostsByResearchCategoryAlias from "../../../services/Post";
import { common } from "../../../constants/constants";
import H3 from "../../../Theme/Typography/H3";
// Image files
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;
const placeholderImg = `${IMAGE_BASE_PATH}/ui/DummyImage/blank.png`

function Posts({setPostViewAll}) {
  // const parts = url.split("/");
  const [posts, setPosts] = useState([]);
  const {alias}=useParams();
  const currentEndpoint = window.location.pathname;
  const parts = currentEndpoint.split("/");
  const masterlistType = parts[1];
  const navigate=useNavigate()
  useEffect(() => {
    const getData = async () => {
      // const result = await getPostsByResearchCategoryAlias("", parts[2]);
      const result = await getPostsByResearchCategoryAlias.getPostsByMasterlist(
        masterlistType,
        alias
      );
      if (result && result?.length > 3)
      {
        // Handle post and insights separately if needed
        setPostViewAll(true);
      }

      if (result) {
        setPosts(result);
      }
    };
    getData();
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Stack my="8">
      <Box>
        {posts.length > 0 ? (
            <HStack pb="20px">
              <SimpleGrid columns={[1, null, 3]} spacing={"82px"}>
                {posts.slice(0, 3).map((it, index) => (
                    <Box w={"348px"} h={"333px"} className="card1" onClick={()=>navigate(`posts/${it?.alias}`)}>
                      {it!==`` && 
                      <Image
                        w={"100%"}
                        height={"100%"}
                        src={it?.image}
                        fallbackSrc={placeholderImg}
                        objectFit={"contain"}
                        transition="1s ease"
                      />}
                    </Box>
                ))}
              </SimpleGrid>
            </HStack>
          ) : (
            <HStack>
              <H3>{common.NO_POST}</H3>
            </HStack>
          )}
      </Box>
    </Stack>
  );
}
export default Posts;
