import React, { useEffect, useState } from "react";
import { explore } from "../../../constants/constants";
import Essay from "./Scholarship/Essay";
import Publications from "./Scholarship/Publications";
import LetterScribbles from "./Scholarship/LetterScribbles";
import TabMain from "../../../FunctionalComponents/TabComponents/TabMain";

export default function Scholarship({ masterlistType, masterlist }) {
  function formatData() {
    return [
      {
        name: `${explore.ESSAYS}`,
        component:<Essay masterlist={masterlist}/>,
        link:`/${masterlistType}/${masterlist}/agraphy/body-of-work/scholarship/essays`,
      },
      {
        name: `${explore.LETTERS_SCRIBBLES_POETRY}`,
        component:<LetterScribbles masterlist={masterlist}/>,
        link:`/${masterlistType}/${masterlist}/agraphy/body-of-work/scholarship/letters-scribbles-poetry`,
      },
      {
        name: `${explore.PUBLICATIONS}`,
        component:<Publications masterlist={masterlist}/>,
        link:`/${masterlistType}/${masterlist}/agraphy/body-of-work/scholarship/publications`,
      }
    ];
}

const [tabPanelData, setTabPanelData] = useState([])
  useEffect(()=>{
    if(masterlist){
      setTabPanelData(formatData())
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[masterlist])
  return (
    <>
      {tabPanelData && tabPanelData.length>0 && <TabMain data={tabPanelData} />}
    </>
  );
}
