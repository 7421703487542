// Core Files
import { Box, Container, HStack, Image, Link, Stack } from "@chakra-ui/react";
import React from "react";
import { useParams } from "react-router-dom";

// Subsection Files
import Layout from "../../../../components/sections/SidebarLayout";
import Large from "../../../../components/layouts/Typography/Large";

// Service Files
import * as getBibliographyData from "../../../../services/Bibliography";

//S3 Bucket osw-dev-images
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;

const BackArrow = `${IMAGE_BASE_PATH}/ui/Chevron/backarrow.png`

function DetailPageShatranjKeKhilari() {
  const [detailpage, setDetailpage] = React.useState([]);
  const { id } = useParams();

  React.useEffect(() => {
    const getData = async () => {
      const result = await getBibliographyData.bibliographyByMasterlist(
        "bibliographies",
        "shatranj-ke-khilari-1977"
      );
      if (result) {
        const dataIntro = result?.filter((e) => e.id === parseInt(id));
        setDetailpage(dataIntro);
      }
    };
    getData();
    window.scrollTo(0, 0);
    return () => {};
  }, [id]);
  return (
    <Layout>
      <Container maxW="1080px">
        <Stack>
          <Box position={"sticky"} top={"90px"} zIndex={1}>
            <Link href="/shatranj-ke-khilari-1977/bibliography">
              <Image src={BackArrow}></Image>
            </Link>
          </Box>

          <Stack marginTop={"-1.5rem!important"}>
            {detailpage?.map((item, index) => (
              <Stack key={index}>
                <HStack justifyContent={"center"}>
                  <Image
                    src={
                      item?.image?.image[0]
                        ? `${IMAGE_BASE_PATH}/` +
                          item?.image?.image
                        : ""
                    }
                    alt=""
                    transition="1s ease"
                    width={"50%"}
                    height={"auto"}
                  />
                </HStack>
                <HStack>
                  <Large textAlign="left" color="black" py="4">
                    {item?.creator && item?.creator.creator && (
                      <>{` ${item?.creator.creator.surname}, `}</>
                    )}

                    {item?.creator && item?.creator.creator && (
                      <>{` ${item?.creator.creator.firstname} | `}</>
                    )}

                    {item?.sortable_year_date_of_publication && (
                      <>{` ${item?.sortable_year_date_of_publication} `}</>
                    )}

                    {item?.cdt && item?.cdt && <>{` ${item?.cdt} `}</>}
                  </Large>
                </HStack>
              </Stack>
            ))}
          </Stack>
        </Stack>
      </Container>
    </Layout>
  );
}
export default DetailPageShatranjKeKhilari;
