import _ from "lodash";
// import { json } from "react-router-dom";

const transformerFunc = {};
transformerFunc["aliasArray"] = (arr, params, json) => {
  const transformed = [];
  // eslint-disable-next-line array-callback-return
  arr.map((a) => {
    const rec = {};
    rec["key"] = a.name;
    rec["value"] = a.abbreviation;
    rec["link"] = a.alias;
    transformed.push(rec);
  });
  return transformed;
};

transformerFunc["propArray"] = (arr, params, json) => {
  const transformed = [];
  const newParams = _.sortBy(params, ["seq"]);
  // eslint-disable-next-line array-callback-return
  newParams.map((a) => {
    const rec = {};
    rec["key"] = a.display;
    rec["value"] = json[a.key];
    transformed.push(rec);
  });
  return transformed;
};

transformerFunc["familyAliasArray"] = (arr, params, json) => {
  const transformed = [];
  // eslint-disable-next-line array-callback-return
  arr.map((a) => {
    const rec = {};
    rec["key"] = a.alias;
    rec["first_name"] = a.first_name;
    rec["last_name"] = a.last_name;
    rec["value"] = a.full_name;
    rec["birth_date"] = a.date_of_birth;
    rec["death_date"] = a.date_of_death;
    transformed.push(rec);
  });
  return transformed;
};

transformerFunc["educationAliasArray"] = (arr, params, json) => {
  const transformed = [];
  // eslint-disable-next-line array-callback-return
  arr.map((a) => {
    const rec = {};
    rec["key"] = a.alias;
    rec["value"] = a.education;
    rec["year"] = a.year;
    rec["id"] = a.id;
    rec["image"] = a.image;
    transformed.push(rec);
  });
  return transformed;
};

transformerFunc["teachingInfraAliasArray"] = (arr, params, json) => {
  const transformed = [];
  // eslint-disable-next-line array-callback-return
  arr.map((a) => {
    const rec = {};
    rec["key"] = a.alias;
    rec["value"] = a.data;
    rec["display_date"] = a.display_date;
    rec["post"] = a.post;
    rec["image"] = a.image;
    transformed.push(rec);
  });

  return transformed;
};

transformerFunc["imageAliasArray"] = (arr, params, json) => {
  const transformed = [];
  // eslint-disable-next-line array-callback-return
  arr.map((a) => {
    const rec = {};
    rec["key"] = a.alias;
    rec["value"] = a.direct_url_of_preview_image;
    rec["date_of_artwork"] = a.date_of_artwork;
    rec["wnn"] = a.wnn;
    rec["accession_number"] = a.accession_number;
    rec["name_of_publication"] = a.name_of_publication;
    rec["publication_year"] = a.publication_year;
    rec["sortable_year_of_publication"] = a.sortable_year_of_publication;
    rec["artist_name"] = a.artist_name;
    rec["medium_of_artwork"] = a.medium_of_artwork;
    rec["revised_medium"] = a.revised_medium;
    rec["artist"] = a.artist;
    rec["featuredArtist"] = a.featuredArtist;
    rec["date_of_artwork"] = a.date_of_artwork;
    transformed.push(rec);
  });

  return transformed;
}

transformerFunc["creatorAliasArray"] = (arr, params, json) => {
  const transformed = [];
  // eslint-disable-next-line array-callback-return
  arr.map((a) => {
    const rec = {};
    rec["key"] = a.alias;
    rec["value"] = a.full_name? a.full_name : a.name;
    rec["first_name"] = a.first_name;
    rec["last_name"] = a.last_name;
    rec["full_name"] = a.full_name;
    transformed.push(rec);
  });
  return transformed;
};

transformerFunc["awardCategoryAliasArray"] = (arr, params, json) => {
  const transformed = [];
  // eslint-disable-next-line array-callback-return
  arr.map((a) => {
    const rec = {};
    rec["key"] = a.alias;
    rec["value"] = a.name? a.name : a.alias;
    transformed.push(rec);
  });
  return transformed;
};

export const transformJSON = (schema, isArray, jsonData) => {
  if (isArray) {
    const newSchema = _.sortBy(schema, ["seq"]);

    const transformed = jsonData.map((dataItem) => {
      const rec = [];
      newSchema.forEach((r) => {
        rec.push({
          key: r.key,
          value:
            r.processor && typeof transformerFunc[r.processor] === "function"
              ? transformerFunc[r.processor](
                  dataItem[r.key],
                  r.params,
                  dataItem
                )
              : dataItem[r.key],
          display:r.display
        })
      });
      return rec;
    });

    return transformed;
  }
  return [];
};
