import {HStack, Stack, Text} from '@chakra-ui/react';
import React from 'react';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import H4 from '../../Theme/Typography/H4';

const TimelineWithText2 = ({ data }) => {

    return (
        <>
            {data.length > 0 &&
                data?.map((it, index) => (
                    <VerticalTimeline layout={"1-column-left"} className="layout">
                        <VerticalTimelineElement
                            id="timeline2"
                            className="exhibition-timeline"
                            iconStyle={{
                                background: "#fff",
                                color: "#333",
                                fontSize: "16px",
                                fontWeight: "700",
                            }}
                            icon={
                                <>
                                    <Text fontSize={"16px"} fontWeight={"600"}>
                                        {`${it?.year}`}
                                    </Text>
                                    <Text fontSize={"11px"} fontWeight={"600"}>
                                        {`${it?.year}`}
                                    </Text>
                                </>
                            }
                        >
                            <Stack>
                                <HStack>
                                    <H4
                                    color="color.blue"
                                    >
                                        {`${it?.desc}`}
                                    </H4>
                                </HStack>
                                <HStack>
                                    <Text
                                    fontSize={"12px !important"}
                                    fontWeight={"400"}
                                    >
                                        {it?.shortDesc}
                                    </Text>
                                </HStack>
                            </Stack>
                        </VerticalTimelineElement>
                    </VerticalTimeline>
                ))}
        </>
    );
};

export default TimelineWithText2;
