import {
  Box,
  Container,
  HStack,
  Image,
  Stack,
  Text,
} from "@chakra-ui/react";

import Layout from "../components/sections/SidebarLayout";
import Large from "../components/layouts/Typography/Large";
import H4 from "../components/layouts/Typography/H4";
import { Link } from "react-router-dom";

//S3 Bucket osw-dev-images
const IMAGE_BASE_PATH = process.env.REACT_APP_DEV_IMAGE_BASE_PATH;

const BackArrow = `${IMAGE_BASE_PATH}/ui/Chevron/backarrow.png`
const Img1 = `${IMAGE_BASE_PATH}/ui/PhilosophicalFramework/VisionOct2021/1.jpg`
const Img2 = `${IMAGE_BASE_PATH}/ui/PhilosophicalFramework/VisionOct2021/2.jpg`
const Img3 = `${IMAGE_BASE_PATH}/ui/PhilosophicalFramework/VisionOct2021/3.jpg`
const Img4 = `${IMAGE_BASE_PATH}/ui/PhilosophicalFramework/VisionOct2021/4.jpg`
const Img5 = `${IMAGE_BASE_PATH}/ui/PhilosophicalFramework/VisionOct2021/5.jpg`
const Img6 = `${IMAGE_BASE_PATH}/ui/PhilosophicalFramework/VisionOct2021/6.jpg`
const Img7 = `${IMAGE_BASE_PATH}/ui/PhilosophicalFramework/VisionOct2021/7.jpg`
const Img8 = `${IMAGE_BASE_PATH}/ui/PhilosophicalFramework/VisionOct2021/8.jpg`

function Oldvision() {
  return (
    <Layout>
      <Stack m="5" pr={"1"} pl={"1"} >
        <Container paddingX={{base:"0px", md:"4"}} maxW="auto">
          <Box display={{base:"content", md:"none"}} position={"sticky"} top={"90px"} zIndex={1}>
            <Link to="/philosophical-framework">
              <Image src={BackArrow}></Image>
            </Link>
          </Box>
          <Box pt="4">
            <Large>Introducing</Large>
            <Text fontSize={"28px"} fontWeight={"700"}>
              OsianamaWorld.org
            </Text>
            <Text fontSize={"20px"} fontWeight="500" py="2">
              A Gift Long Overdue
            </Text>
          </Box>
          <Stack>
            <HStack>
              <Box>
                <Large py="2">Dear Friends,</Large>
                <Large py="2">
                  In the coming weeks my colleagues and I will be launching a
                  new web platform which represents a vision built over a
                  rollercoasting twenty-five years of research and experience.
                </Large>
                <Large py="2">
                  OsianamaWorld.org will engage on an array of subjects on India
                  and her relationships with the world since the late 19th
                  century.
                </Large>
                <Large py="2">
                  With the support of the foremost archive and library built on
                  the subject we will help to communicate a reinterpretation of
                  the existing arts, social sciences and humanities, focusing on
                  creating a new global framework for India studies.
                </Large>
              </Box>
            </HStack>
            <HStack>
              <Box flex={1} alignItems={"center"} py="4">
                <Image
                  boxSize="auto"
                  w="100%"
                  objectPosition="center"
                  src={Img1}
                  alt=""
                />
              </Box>
            </HStack>
          </Stack>

          <Box>
            <Large py="2">
              Across all the Indian and global institutions of education not one
              significant three-year undergraduate course on India studies
              exists which privileges a scholarly understanding of the subject.
              The same can be said of most post-graduate courses, where India
              has been posited within the (South and/or South East) Asian
              Context. To this neglect we can add the post-colonial
              inadequacies, a shallow foundation for grasping the
              interdisciplinarity, and relatively minor visual-text
              research-bases upon which much of the writing and study on India
              exists.
            </Large>
          </Box>

          <Box flex={1} alignItems={"center"} py="4">
            <Image boxSize="auto" w="100%" objectPosition="center" src={Img2} />
          </Box>
          <Stack>
            <HStack>
              <Box>
                <Large py="2">
                  Further, though developing a meaningful online India studies
                  framework is still in its infancy, with minimal progress at
                  sharing scholarly content, we at OsianamaWorld.org are hoping
                  to help change this scenario.
                </Large>
                <Large py="2">
                  For us a true education is that birth-right of a quietly
                  guided unending journey of self-discovery. It is a lifelong
                  quest, engaging constantly with our inner voice for new
                  avenues, refusing to leave any part of the day untouched.
                  However, society structures formal and even informal learning
                  in rigid and narrow sockets of pre-set time periods thereby
                  tilting askew our holistic inner-outer balance.
                </Large>
                <Large py="2">
                  During the last few decades the attempt to readjust the daily
                  routine has been more radical, exacerbated recently by the
                  pandemic and the reactions thereafter. This has brought into
                  focus much that ails the material systems of development upon
                  which we have relied. In a way, the pandemic is but a mirror
                  to that ailing system, taking the form of a viral rather than
                  a subtler catalyst.
                </Large>
              </Box>
            </HStack>
            <HStack>
              <Box flex={1} alignItems={"center"}>
                <Image
                  boxSize="auto"
                  w="100%"
                  objectPosition="center"
                  src={Img3}
                />
              </Box>
            </HStack>
          </Stack>
          <Box>
            <Large py="2">
              It feels like it is all breaking down – the belief in capitalism,
              the efforts towards building a fearless democratic ecology, our
              supposed deep love for freedom of expression, the intent to fight
              for subaltern equalities, the confidence in formal education,
              dissolving crimes of racism, keeping alive the passion and empathy
              for physical human interactions, and most importantly living the
              animal-human-nature-cosmic continuum with compassion. All these,
              and much more, require radical rethinking and rebuilding, implying
              a tectonic change in our values, priorities and way of life.
            </Large>
          </Box>
          <Box flex={1} alignItems={"center"} py="4">
            <Image
              boxSize="auto"
              w="100%"
              objectPosition="center"
              src={Img4}
              alt=""
            />
          </Box>
          <Box>
            <Large py="2">
              000019 Kranti 1981 Twelve Sheet Offset Print on Paper 1981.jpg It
              has been my belief that of all the nation-states and civilizations
              extant today, India finds herself uniquely placed to offer wisdom,
              meaningful insights and learning, though she herself may not be
              able to heed most lessons. Yet the time-averse universality within
              her sensibility while rooted in the local is still valid and ideal
              on many levels for our global fragmented-interconnectedness.
            </Large>
            <Large py="2">
              The eternal questions regarding who are we, how do we sustain a
              truthful path, the responsibilities to our inner voice and the
              world around us, ways to sustain the highest inner self while
              fulfilling our obligations with dignity, the need to respect all
              living souls with a similar integrity and relevance, and so on,
              still persist at the root of contemporary life.
            </Large>
          </Box>
          <Stack>
            <HStack>
              <Box>
                <Large py="2">
                  Yet little will be possible without nurturing the continual
                  need for unqualified love seeping into a myriad new ways at
                  every level of learning. In our own way we hope to inspire a
                  new source of confidence, where the image and text are deeply
                  entwined, rigour is reassembled into providing the
                  egalitarianism so essential for any learning atmosphere, so
                  nudging ideation, action and its quiet, where every answer
                  leads only to a deeper question.
                </Large>
              </Box>
            </HStack>
            <HStack>
              <Box flex={1} alignItems={"center"} py="4">
                <Image
                  boxSize="auto"
                  w="100%"
                  objectPosition="center"
                  src={Img5}
                />
              </Box>
            </HStack>
            <Box>
              <Large py="2">
                Yet little will be possible without nurturing the continual need
                for unqualified love seeping into a myriad new ways at every
                level of learning. In our own way we hope to inspire a new
                source of confidence, where the image and text are deeply
                entwined, rigour is reassembled into providing the
                egalitarianism so essential for any learning atmosphere, so
                nudging ideation, action and its quiet, where every answer leads
                only to a deeper question.
              </Large>
            </Box>
          </Stack>
          <Stack>
            <HStack>
              <Box flex={1} alignItems={"center"} py="4">
                <Image
                  boxSize="auto"
                  w="100%"
                  objectPosition="center"
                  src={Img6}
                />
              </Box>
            </HStack>
            <HStack>
              <Box flex={1} alignItems={"center"} py="4">
                <Image
                  boxSize="auto"
                  w="100%"
                  objectPosition="center"
                  src={Img7}
                />
              </Box>
            </HStack>
          </Stack>
          <Box>
            <Large py="2">
              Hopefully we will soon engage with OsianamaWorld.org where a
              rediscovery of oneself, through India and her relationships with
              the world, via a vast cinematic, artistic and cultural heritage,
              rooted within a unique animal-human-nature framework, and
              disseminated through a new web architecture with thousands of
              image-text formats shared for the first time, re-opening out
              interdisciplinarity anew, shall allow us fresh paths for research
              and a significant push in public learning and its joy.
            </Large>
            <Large py="2">
              In sustaining this journey of sharing such a vast and scholarly
              knowledge-base freely with all, as a loving gift…a long overdue
              promise, will also feel fulfilled. Until then, with affection
              Neville Tuli and Team OsianamaWorld
            </Large>
          </Box>
          <Box>
            <H4 py="2">Until then, with affection</H4>
            <H4 py="2">Neville Tuli and Team OsianamaWorld</H4>
          </Box>
          <Stack>
            <HStack>
              <Box flex={1} alignItems={"center"} py="4">
                <Image
                  boxSize="auto"
                  w="100%"
                  objectPosition="center"
                  src={Img8}
                />
              </Box>
            </HStack>
            <HStack pb="4">
              <Box>
                <Large py="2">
                  1. Ramendranath Chakravorty | Gandhiji at Dandi | Oil on
                  canvas pasted on board, c.1932
                </Large>
                <Large py="2">
                  2. Transforming cultural disciplines into Interdisciplinarity
                  a. Ravi Varma Press, Karla-Lonavala | Gangavataran | Oleograph
                  with embroidery & sequins, 1895-1906 b. Burgoyne, Burbridges &
                  Co. | Gangavataran | Lithographic Advertisement, 1914 c.
                  Dadsaheb Phalke (Dir) | Gangavataran | Black & white
                  photographic print on paper, 1937 d. Atul Dodiya |
                  Gangavataran | Oil, acrylic and marble dust on canvas, 1998
                </Large>
                <Large py="2">
                  3. OsianamaWorld.org | Digital collage with image from
                  Metropolis_1927 silver gelatin double-weight print
                </Large>
                <Large py="2">
                  4. Manoj Kumar (Dir) | Kranti_1981 | Twelve-sheet offset print
                  on paper, 1981
                </Large>
                <Large py="2">
                  5. Charlie Chaplin (Dir) | A Dog’s Life_1918 | First Release
                  Lobby Card, 1918
                </Large>
                <Large py="2">
                  6. Unknown German Artist of the Old Bengal School | Shiva’s
                  Holy Family | Oil on wood, c.1885
                </Large>
                <Large py="2">7. John Williams (Dir) | Star</Large>
              </Box>
            </HStack>
          </Stack>
        </Container>
      </Stack>
      {/* <Footer /> */}
    </Layout>
  );
}
export default Oldvision;
