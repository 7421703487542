import { skk_preface_data, skk_specification_data, skk_synopsis_data, skk_cast_view_data, skk_film_intro_data, skk_credits_view_data, mughal_film_intro_data, mughal_synopsis_data, mughal_cast_view_data, mughal_credits_view_data, silsila_cast_view_data, silsila_synopsis_data, silsila_credits_view_data } from "../MockData/FilmMockup";
import { isTransformationNotAllowed } from "../util/isTranformationAllowed";
import api from "../services/api";
import { APIS } from "../endpoints";

export const filmByAliasAPI = async (masterlistType, alias) => {
  const result = await api.get(`${APIS.BASE_URL}${masterlistType}/${alias}/${APIS.introductionFilm}`)
  if (result) {
    return result?.data?.data;
  } else {
    return [];
  }
};

export const filmByAlias = async (masterlistType, alias) => {
  if (isTransformationNotAllowed() === true) {
   const response = await filmByAliasDummyData(alias);
   return response;
  }else if (isTransformationNotAllowed() === false) {
    const response = await filmByAliasAPI(masterlistType, alias);
    try{
      if (response) {
        return response[0];
      } else {
        console.error("Invalid response data structure:", response);
        return [];
      }
    } catch (e){
      console.error("Something is wrong", e)
    }
  }
};

export const filmByAliasDummyData = async (masterlist) => {
  // return skk_film_intro_data
  const filmDataMap = {
    "satyajit-ray": skk_film_intro_data,
    "shatranj-ke-khilari-1977": skk_film_intro_data,
    "art-heritage-annual": skk_film_intro_data,
    "mughal-e-azam": mughal_film_intro_data,
    "sayed-haider-raza":skk_film_intro_data
    // Add more cases as needed
  };
  // Check if the masterlist exists in the map, if not, use a default value
  const FilmPageData = filmDataMap[masterlist] || [];
  return FilmPageData;
};

export const filmPrefaceDataAPI = async (masterlistType, alias) => {
  const result = await api.get(`${APIS.BASE_URL}${masterlistType}/${alias}/${APIS.filmPreface}`)
  if (result) {
    return result?.data?.data;
  } else {
    return [];
  }
};

// Get Film Preface Data
export const filmPrefaceData = async (masterlistType, alias) => {
  if (isTransformationNotAllowed() === true) {
    const response = await filmPrefaceDataDummy(alias);
    return response;
  }else if (isTransformationNotAllowed() === false) {
    const response = await filmPrefaceDataAPI(masterlistType, alias);
    try {
      if (response) {
        return response[0];
      } else {
        console.error("Invalid response data structure:", response);
        return [];
      }
    } catch (e) {
      console.error("Something is wrong", e);
    }
  }
};

export const filmPrefaceDataDummy = async (alias) => {  
  return skk_preface_data;
};

export const filmSpecificationDataAPI = async (masterlistType, alias) => {
  const result = await api.get(`${APIS.BASE_URL}${masterlistType}/${alias}/${APIS.filmSpecification}`)
  if (result) {
    return result?.data?.data;
  } else {
    return [];
  }
};

// Get Film Specification Data
export const filmSpecificationData = async (masterlistType, alias) => {
  if (isTransformationNotAllowed() === true) {
    const response = await filmSpecificationDataDummy(masterlistType, alias);
    return response;
  }else if (isTransformationNotAllowed() === false)  {
    const response = await filmSpecificationDataAPI(masterlistType, alias);
    try {
      if (response) {
        return response[0];
      } else {
        console.error("Invalid response data structure:", response);
        return [];
      }
    } catch (e) {
      console.error("Something is wrong", e);
    }
  }
};

export const filmSpecificationDataDummy = async (tableName, alias) => {
  return skk_specification_data;
};

export const filmSynopsisDataAPI = async (masterlistType, alias) => {
  const result = await api.get(`${APIS.BASE_URL}${masterlistType}/${alias}/${APIS.synopsis}`)
  if (result) {
    return result?.data?.data;
  } else {
    return [];
  }
};

// Get Film Synopsis Data
export const filmSynopsisData = async (masterlistType, alias) => {
  if (isTransformationNotAllowed() === true) {
    const response = await filmSynopsisDataDummy(alias);
    return response;
  }else if (isTransformationNotAllowed() === false) {
    const response = await filmSynopsisDataAPI(masterlistType, alias);
    try {
      if (response) {
        return response[0];
      } else {
        console.error("Invalid response data structure:", response);
        return [];
      }
    } catch (e) {
      console.error("Something is wrong", e);
    }
  }
};

export const filmSynopsisDataDummy = async (alias) => {
  // return skk_synopsis_data;
  const filmSynopsisDataMap = {
    "shatranj-ke-khilari-1977": skk_synopsis_data,
    "mughal-e-azam": mughal_synopsis_data,
    "silsila": silsila_synopsis_data,
  };
  // Check if the masterlist exists in the map, if not, use a default value
  const FilmSynopsisData = filmSynopsisDataMap[alias] || [];
  return FilmSynopsisData;
};

export const filmCastDataAPI = async (masterlistType, alias) => {
  const result = await api.get(`${APIS.BASE_URL}${masterlistType}/${alias}/${APIS.cast}`)
  if (result) {
    return result?.data?.data;
  } else {
    return [];
  }
};

export const filmCastData = async (masterlistType, alias) => {
  if (isTransformationNotAllowed() === true) {
    const response = await filmCastDataDummy(alias);
    return response
  }else if (isTransformationNotAllowed() === false) {
    const response = await filmCastDataAPI(masterlistType, alias);
    try {
      if (response) {
        return response[0];
      } else {
        console.error("Invalid response data structure:", response);
        return [];
      }
    } catch (e) {
      console.error("Something is wrong", e);
    }
  }
};

export const filmCastDataDummy = async (alias) => {
  const filmCastDataMap = {
    "shatranj-ke-khilari-1977": skk_cast_view_data,
    "mughal-e-azam": mughal_cast_view_data,
    "silsila":silsila_cast_view_data
  };
  // Check if the masterlist exists in the map, if not, use a default value
  const FilmCastData = filmCastDataMap[alias] || skk_cast_view_data;
  return FilmCastData;
};

export const filmCreditsDataAPI = async (masterlistType, alias) => {
  const result = await api.get(`${APIS.BASE_URL}${masterlistType}/${alias}/${APIS.credits}`)
  if (result) {
    return result?.data?.data;
  } else {
    return [];
  }
};

// Get Film Credits data
export const filmCreditsData = async (masterlistType, alias) => {
  if (isTransformationNotAllowed() === true) {
    const response = await filmCreditsDataDummy(alias);
    return response;
  }else if (isTransformationNotAllowed() === false) {
    const response = await filmCreditsDataAPI(masterlistType, alias);
    try {
      if (response) {
        return response[0];
      } else {
        console.error("Invalid response data structure:", response);
        return [];
      }
    } catch (e) {
      console.error("Something is wrong", e);
    }
  }
};

export const filmCreditsDataDummy = async (alias) => {
  const filmCreditsDataMap = {
    "shatranj-ke-khilari-1977": skk_credits_view_data,
    "mughal-e-azam": mughal_credits_view_data,
    "silsila":silsila_credits_view_data
  };
  const FilmCreditsData = filmCreditsDataMap[alias] || skk_credits_view_data;
  return FilmCreditsData;
};
