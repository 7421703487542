import React from 'react'
import { WebpageComingSoon } from '../../../../CommonComponents/WebpageComingSoon'

export default function ExhibitionsPhotographs() {
  return (
    <>
    <WebpageComingSoon/>
    </>
  )
}
